import cn from 'classnames';
import styles from 'components/ClientProduct/Block/index.scss';
import React from 'react';
import {FormattedMessage, MessageDescriptor} from 'react-intl';

type BlockProps = {
  children: React.ReactNode;
  noPadding?: boolean;
  testId?: string;
};

export function Block({children, noPadding, testId}: BlockProps): React.ReactElement {
  return (
    <div className={cn(styles.block, {[styles.blockNoPadding!]: noPadding})} data-testid={testId}>
      {children}
    </div>
  );
}

type BlockHeaderProps = {
  children?: React.ReactNode;
  message?: MessageDescriptor;
  testId?: string;
};

export function BlockHeader({children, message, testId}: BlockHeaderProps): React.ReactElement {
  return (
    <h2 className={styles.blockHeader} data-testid={testId}>
      {message ? <FormattedMessage {...message} /> : children}{' '}
    </h2>
  );
}
