import {requestActionCreator} from 'store/utils/requestActions';
import {PageMetadata} from 'types/PageMetadata';
import {FuncAction} from 'typesafe-actions';

import {load, LoadResponse} from './actions';

type LoadResponseBody = {
  payload: PageMetadata;
};

export const loadPageMetadata = (url: string): FuncAction =>
  requestActionCreator(load, {url}, (store, client) =>
    client.api
      .post<LoadResponseBody>(`/pageMetadata/get`, {body: {url}})
      .then((res): LoadResponse => res.body.payload),
  );
