import {Category} from 'types/Category';
import {Count} from 'types/ObfuscatedCount';

export type UserCategoryQueryFilterValue = {
  items?: Category[];
  totalCount?: Count;
  type: 'categories';
};

export type UserQueryFilterValue = UserCategoryQueryFilterValue;

export enum UserQueryFilterId {
  CATEGORY_ID = 'categoryId',
}

export type UserQueryFilter = {
  id: UserQueryFilterId;
  name?: string;
  value: UserQueryFilterValue;
};

export type UserCategoryQueryFilter = UserQueryFilter & {
  id: UserQueryFilterId.CATEGORY_ID;
  value: UserCategoryQueryFilterValue;
};

function isUseCategoryQueryFilter(filter: UserQueryFilter): filter is UserCategoryQueryFilter {
  return filter.id === UserQueryFilterId.CATEGORY_ID;
}

export type UserQuery = {
  filters?: UserQueryFilter[];
  query?: string;
};

export type UserCategoryQuery = UserQuery & {
  filters: [UserCategoryQueryFilter];
};

export function isUserCategoryQuery(query: UserQuery): query is UserCategoryQuery {
  const firstFilter = query.filters?.[0];

  return firstFilter ? isUseCategoryQueryFilter(firstFilter) : false;
}
