import PropTypes from 'prop-types';
import {ParcelLiteShape} from './ParcelLite';
import {OrderSupportShape} from './OrderSupport';
import {ParcelOrderShape} from './ParcelOrder';
import {ConsolidationBadgeShape} from './ConsolidationBadge';
import Warranty from './Warranty';
import {DeliveryConfirmationMode} from './ParcelDeliveryConfirmationMode';
import DeliveryPoint from './DeliveryPoint';
import {CartPricingShape} from './CartPricing';
import {CancelBehavior} from './CancelBehavior';

export const ParcelShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  lite: ParcelLiteShape.isRequired,
  orders: PropTypes.arrayOf(ParcelOrderShape).isRequired,
  support: OrderSupportShape.isRequired,
  isCancellable: PropTypes.bool,
  isAddressEditable: PropTypes.bool,
  warranty: Warranty.isRequired,
  deliveryConfirmationMode: DeliveryConfirmationMode,
  deliveryPoint: DeliveryPoint,
  appearance: PropTypes.shape({
    showAddress: PropTypes.bool.isRequired,
    consolidationBadge: ConsolidationBadgeShape,
  }),
  pricing: CartPricingShape,
  cancelBehavior: CancelBehavior,
  eventParams: PropTypes.string,
});
