import Arrow from 'components/icons/backArrow.jsx.svg';
import {useAnalytics} from 'hooks/useAnalytics';
import {useLanguage} from 'hooks/useLanguage';
import {useScope} from 'hooks/useScope';
import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import {CategoryFrom, getCategorySourceByPageSource} from 'types/analytics/Category';
import {PageSource} from 'types/PageSource';

import {AllInThisCategory} from './AllInThisCategory';
import {CatalogLink} from './CatalogLink';
import {Child} from './Child';
import {getCategoriesForAnalytics} from './getCategoriesForAnalytics';
import {getUrl} from './getUrl';
import styles from './index.scss';
import {BuildUrlFunc, Category, Params} from './types';
import {useCloseIfNoChildren} from './useCloseIfNoChildren';

type TopLevelCategory = {
  topLevelCategory: Category;
  buildUrl: BuildUrlFunc;
  loading: boolean;
  params: Params;
  onClose?: () => void;
  onCloseBeforeNavigate?: () => void;
  isTopLevelView: boolean;
  mobileSheetView: boolean;
  source?: PageSource;
  from: CategoryFrom;
};

export const TopLevelCategory = React.memo(function TopLevelCategoryFunc({
  topLevelCategory,
  buildUrl,
  loading,
  params,
  onClose,
  onCloseBeforeNavigate,
  isTopLevelView,
  mobileSheetView,
  source,
  from,
}: TopLevelCategory) {
  const lang = useLanguage();
  const scope = useScope();
  const analytics = useAnalytics();
  const isParent = topLevelCategory.behavior === 'showParent';
  const topCategoryChildren = topLevelCategory.children;
  const notClickableHeader = mobileSheetView && isParent;
  const arrow = notClickableHeader ? (
    <Link to={getUrl(scope, lang, topLevelCategory.id, params, buildUrl)}>
      <Arrow className={styles.back} />
    </Link>
  ) : (
    <Arrow className={styles.back} />
  );
  const handleClick = useCloseIfNoChildren({
    onClose: onCloseBeforeNavigate,
    loading,
    children: topCategoryChildren,
  });

  useEffect(() => {
    if (!isTopLevelView && topLevelCategory) {
      const initValue = topLevelCategory ? [topLevelCategory.id] : [];

      const categories = topLevelCategory.children
        ? getCategoriesForAnalytics(topLevelCategory.children, initValue)
        : initValue;

      analytics.sendEvent({
        type: 'categoriesPreview',
        payload: {
          from,
          categories,
          source: getCategorySourceByPageSource(source),
        },
      });
    }
  }, [isTopLevelView, topLevelCategory, topCategoryChildren, analytics, source, from]);

  return (
    <div>
      <CatalogLink
        buildUrl={buildUrl}
        categoryId={topLevelCategory.id}
        isParent={isParent}
        loading={loading}
        params={params}
        onClick={isParent ? undefined : handleClick}
        from={from}
        source={source}
      >
        {isParent && arrow}
        {isParent ? (
          <span className={styles.topLevelText}>{topLevelCategory.name}</span>
        ) : (
          topLevelCategory.name
        )}
      </CatalogLink>
      {topCategoryChildren ? (
        <>
          {!isParent && (
            <div className={styles.allInCategoryLink}>
              <CatalogLink
                buildUrl={buildUrl}
                categoryId={topLevelCategory.id}
                params={params}
                withMargin
                noHighlight
                onClick={onClose}
                loading={false}
                from={from}
                source={source}
              >
                <AllInThisCategory />
              </CatalogLink>
            </div>
          )}
          {topCategoryChildren.map((item: Category) => (
            <Child
              key={`top-category-child-${item.id}`}
              buildUrl={buildUrl}
              isTopLevelView={isTopLevelView}
              item={item}
              loading={loading}
              onCloseBeforeNavigate={onCloseBeforeNavigate}
              params={params}
              from={from}
              source={source}
            />
          ))}
        </>
      ) : null}
    </div>
  );
});
