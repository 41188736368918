import {Locator} from 'components/Locator';
import React from 'react';

import {SliderElementsSelected} from '../SliderElementsContext';
import {Dot} from './Dot';
import styles from './index.scss';

type Props = {
  selected: SliderElementsSelected;
  onDotClick: (index: number) => void;
};

export const Dots = React.memo(function Dots({selected, onDotClick}: Props) {
  /* eslint-disable react/no-array-index-key */
  return (
    <Locator id="ContentListSliderDots">
      <ul className={styles.dots}>
        {selected.map((isSelected, idx) => (
          <li key={`dot-wrapper-${idx}`}>
            <Dot index={idx} onClick={onDotClick} selected={isSelected} />
          </li>
        ))}
      </ul>
    </Locator>
  );
});
