import PropTypes from 'prop-types';
import Price from './Price';

export default PropTypes.shape({
  price: Price.isRequired,
  shippingPrice: Price.isRequired,
  totalPrice: Price.isRequired,
  msrPrice: Price,
  discount: PropTypes.number,
  pointAmountMoney: Price,
});
