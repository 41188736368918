import PostOfficeShape from 'shapes/PostOffice';
import classnames from 'classnames/bind';
import React, {memo} from 'react';
import {FormattedMessage} from 'react-intl';
import {ParcelPageCard, ParcelPageCardInner} from 'components/ParcelPageCard';
import {Map} from './Map';
import styles from './index.scss';
import {getMapCenter} from './getMapCenter';

const cn = classnames.bind(styles);

const getQuery = (country, city, street, zip) =>
  [country, city, street, zip].filter(Boolean).join(', ');

export const PostOffice = memo(({postOffice}) => {
  if (!postOffice.latitude || !postOffice.longitude || !postOffice.street) {
    return null;
  }

  const {country, city, street, latitude, longitude, zip} = postOffice;
  const coordinates = {
    latitude,
    longitude,
  };

  return (
    <ParcelPageCard>
      <ParcelPageCardInner>
        <h2 className={styles.title}>
          <FormattedMessage
            defaultMessage="Your post office"
            description="Post office title on parcel card"
          />
        </h2>
      </ParcelPageCardInner>
      <Map
        square
        coordinates={coordinates}
        query={getQuery(country, city, street, zip) || getMapCenter(latitude, longitude)}
      />
      <ParcelPageCardInner>
        <p className={cn('info', 'light')}>
          {postOffice.city}, {postOffice.zip}
        </p>
        <p className={styles.street}>{postOffice.street}</p>
        {Boolean(postOffice.workHours) && (
          <p className={cn('info', 'light', 'hours')}>{postOffice.workHours}</p>
        )}
        {Boolean(postOffice.phoneNumber) && (
          <p className={cn('info', 'light')}>
            <FormattedMessage
              defaultMessage="Phone number:"
              description="Phone label on parcel card"
            />{' '}
            <a className={styles.phoneLink} href={`tel:${postOffice.phoneNumber}`}>
              {postOffice.phoneNumber}
            </a>
          </p>
        )}
      </ParcelPageCardInner>
    </ParcelPageCard>
  );
});

PostOffice.propTypes = {
  postOffice: PostOfficeShape.isRequired,
};
