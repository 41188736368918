import {MutableRefObject, useRef, useState} from 'react';

import {useEffectOnce} from './useEffectOnce';

// Usually you want to know when your component is already in dom,
// but you can override `defaultValue` to avoid rerender.
// It can be useful for data fetchers.
export function useMounted(defaultValue = false): boolean {
  const [mounted, setMounted] = useState(defaultValue);
  useEffectOnce(() => {
    setMounted(true);
    return () => setMounted(false);
  });
  return mounted;
}

// Mutable way to get component mounted state.
// Returns mutable object. It can be profitable when
// you do not want to recreate momoized functions with
// async state side-effects.
export function useMountedRef(defaultValue = false): MutableRefObject<boolean> {
  const ref = useRef(defaultValue);
  useEffectOnce(() => {
    ref.current = true;
    return () => {
      ref.current = false;
    };
  });
  return ref;
}
