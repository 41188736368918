import classnames from 'classnames';
import {BannerWrapper} from 'components/ContentList/BannerWrapper';
import {Image} from 'components/Image';
import {Locator} from 'components/Locator';
import {Icon} from 'components/UIKit/Icon';
import {Timer} from 'components/UIKit/Timer';
import React, {useRef} from 'react';
import {SlimBanner as SlimBannerType, SlimBannerArrowType} from 'types/ContentList/SlimBanner';
import {getInlineColor} from 'utils/styles/color';

import {Background} from '../Background';
import {useBannerPreview} from '../hooks';
import {CompleteMessage} from '../TimerCompleteMessage';
import styles from './index.scss';

const BANNER_THRESHOLD = 0.9;

type Props = {
  content: SlimBannerType;
  id: string;
  source?: string;
  kind?: 'default' | 'productCardPinned';
};

export function SlimBanner({
  content: {background, image, subtitle, timer, title, url, timerMaxDuration, eventParams, arrow},
  kind = 'default',
  id,
  source,
}: Props): React.ReactElement {
  const contentRef = useRef(null);
  const clickRef = useRef(null);
  const expirationTimeMs = timer?.expirationTimeMs;
  let altSubtitle;

  if (subtitle || timer) {
    altSubtitle = {
      text: timer?.text?.text || subtitle?.text || '',
      color: timer?.text?.color || '',
    };
  }

  useBannerPreview({
    rootRef: contentRef,
    bannerId: id,
    expirationTimeMs,
    clickRefs: [clickRef],
    visibilityThreshold: BANNER_THRESHOLD,
    eventParams,
    source,
  });

  const arrowColor = getInlineColor(arrow?.color || title?.color);

  let arrowIcon;

  switch (arrow?.type) {
    case SlimBannerArrowType.CARET:
      arrowIcon = <Icon type="mono" name="chevron-right-linear-24" width="24px" height="24px" />;
      break;
    case SlimBannerArrowType.WITH_LINE:
    default:
      arrowIcon = <Icon type="mono" name="arrow-right-linear-24" width="24px" height="24px" />;
  }

  return (
    <BannerWrapper ref={clickRef} to={url} source={source}>
      <Locator id="ContentListSlimBanner">
        <div className={classnames(styles.banner, [styles[kind]])}>
          <Background background={background} />
          <div className={styles.inner} ref={contentRef}>
            {image && (
              <div className={styles.imageWrap}>
                <Image
                  className={styles.image}
                  contain
                  image={image}
                  loadingHidden
                  pxFit={24}
                  vwFit={24}
                />
              </div>
            )}
            <div className={styles.textWrap}>
              <div className={styles.mainText}>
                <Locator id="ContentListSlimBannerTitle">
                  <div className={styles.title} style={getInlineColor(title.color)}>
                    {title.text}
                  </div>
                </Locator>
                {altSubtitle && (
                  <div className={styles.altSubtitle} style={getInlineColor(altSubtitle.color)}>
                    {altSubtitle.text}
                  </div>
                )}
              </div>
              <div className={styles.otherText}>
                {timer && timer.text && (
                  <Locator id="ContentListSlimBannerTimerText">
                    <div className={styles.timerText} style={getInlineColor(timer.text.color)}>
                      {timer.text.text}
                    </div>
                  </Locator>
                )}
                {subtitle && (
                  <Locator id="ContentListSlimBannerSubtitle">
                    <div className={styles.subtitle} style={getInlineColor(subtitle.color)}>
                      {subtitle.text}
                    </div>
                  </Locator>
                )}
              </div>
            </div>
            {timer && !timer.isTimeRemainingHidden ? (
              <Locator id="ContentListSlimBannerTimer">
                <div className={styles.timerWrap}>
                  <Timer
                    timestamp={timer.expirationTimeMs}
                    customTheme={{
                      digitBackgroundColor: timer.digitBackgroundColor,
                      digitColor: timer.digitColor,
                    }}
                    changeFormatDurationBeforeMs={timerMaxDuration}
                    completeMessage={<CompleteMessage />}
                  />
                </div>
              </Locator>
            ) : null}
            {url && (
              <Locator id="ContentListSlimBannerArrow">
                <div className={styles.arrowWrap} style={arrowColor}>
                  <span className={styles.arrow}>{arrowIcon}</span>
                </div>
              </Locator>
            )}
          </div>
        </div>
      </Locator>
    </BannerWrapper>
  );
}
