import classnames from 'classnames';
import ErrorMessage from 'components/ErrorMessage';
import {Locator} from 'components/Locator';
import {Overlay} from 'components/Overlay';
import {Button} from 'components/UIKit/Button';
import {Popup} from 'components/UIKit/Popup';
import {Footer} from 'components/UIKit/Popup/Footer';
import {Header} from 'components/UIKit/Popup/Header';
import {PopupViewProps, PopupViewRender} from 'components/UIKit/Popup/PopupProvider';
import {PopupStack} from 'components/UIKit/Popup/PopupStack';
import {PopupUserActions} from 'components/UIKit/Popup/types';
import React, {useCallback} from 'react';
import {defineMessages, FormattedMessage, useIntl} from 'react-intl';
import {CancelBehavior} from 'types/CancelBehavior';
import {Error} from 'types/Error';
import {convertBackendColorToCSSValue, convertBackendGradientToCSSValue} from 'utils/styles/color';

import {ItemsSlider} from './ItemsSlider';
import Location from './location.jsx.svg';
import styles from './styles.scss';

type Props = {
  cancelBehavior?: CancelBehavior;
  error?: Error;
  loading?: boolean;
  onCancel(): void;
  onChangeAddress(): void;
};

const DEFAULT_UNDO_BUTTON_COLOR = {
  background: 'var(--color-accent)',
  color: 'var(--color-onAccentHigh)',
};

const DEFAULT_CANCEL_BUTTON_COLOR = {
  background: 'var(--color-primary10)',
  color: 'var(--color-primary100)',
};

const messages = defineMessages({
  header: {
    description: '[header] Заголовок попапа про отмену доставки',
    defaultMessage: 'Order cancellation',
  },
  text: {
    description: 'Текст попапа про отмену доставки',
    defaultMessage: 'Are you sure that you want to cancel this order?',
  },
  cancelButton: {
    description: '[button] Кнопка, отменяющая заказ',
    defaultMessage: 'Cancel order',
  },
  closeButton: {
    description: '[button] Кнопка, прерывающая процесс отмены заказа',
    defaultMessage: 'Dismiss',
  },
});

const ParcelCancellationPopupBase: React.FC<PopupViewProps & Props> = ({
  cancelBehavior,
  error,
  loading,
  onBack,
  onClose,
  onCancel,
  onChangeAddress,
}) => {
  const intl = useIntl();

  let content;

  if (cancelBehavior && 'changeAddressSuggestion' in cancelBehavior) {
    const {title, subtitle, suggestedAddress, cancelButton, changeAddressButton} =
      cancelBehavior.changeAddressSuggestion || {};

    content = (
      <>
        <Header onClose={onClose} onBack={onBack}>
          {title}
        </Header>
        <div className={styles.popupContent}>
          <div className={styles.subtitle}>{subtitle}</div>
          {error ? (
            <p className={styles.error}>
              <ErrorMessage error={error} internal />
            </p>
          ) : null}
          <Location className={styles.locationIcon} />
          <Locator id="CancellationPopupSuggestedAddress">
            <div className={styles.address}>{suggestedAddress?.description.full}</div>
          </Locator>
        </div>
        <Footer>
          <div className={styles.buttonContainer}>
            <Locator id="CancellationPopupConfirmButton">
              <Button
                tag="button"
                color={{
                  color: convertBackendColorToCSSValue(cancelButton?.textColor),
                  background: convertBackendGradientToCSSValue(cancelButton?.backgroundGradient),
                }}
                onClick={onCancel}
              >
                {cancelButton?.text}
              </Button>
            </Locator>
          </div>
          <div className={styles.buttonContainer}>
            <Locator id="ChangeAddressButton">
              <Button
                tag="button"
                color={{
                  color: convertBackendColorToCSSValue(changeAddressButton?.textColor),
                  background: convertBackendGradientToCSSValue(
                    changeAddressButton?.backgroundGradient,
                  ),
                }}
                onClick={onChangeAddress}
              >
                {changeAddressButton?.text}
              </Button>
            </Locator>
          </div>
        </Footer>
      </>
    );
  } else {
    let title;
    let subtitle;
    let undoButton;
    let cancelButton;
    let items;

    if (cancelBehavior && 'standard' in cancelBehavior) {
      title = cancelBehavior.standard?.title;
      subtitle = cancelBehavior.standard?.subtitle;
      undoButton = cancelBehavior.standard?.undoButton;
      cancelButton = cancelBehavior.standard?.cancelButton;
      items = cancelBehavior.standard?.items;
    }

    title ??= intl.formatMessage(messages.header);
    subtitle ??= intl.formatMessage(messages.text);
    undoButton ??= {text: intl.formatMessage(messages.closeButton)};
    cancelButton ??= {text: intl.formatMessage(messages.cancelButton)};

    const undoButtonColor =
      undoButton.textColor && undoButton.backgroundGradient
        ? {
            color: convertBackendColorToCSSValue(undoButton.textColor),
            background: convertBackendGradientToCSSValue(undoButton.backgroundGradient),
          }
        : DEFAULT_UNDO_BUTTON_COLOR;

    const cancelButtonColor =
      cancelButton.textColor && cancelButton.backgroundGradient
        ? {
            color: convertBackendColorToCSSValue(cancelButton.textColor),
            background: convertBackendGradientToCSSValue(cancelButton.backgroundGradient),
          }
        : DEFAULT_CANCEL_BUTTON_COLOR;

    content = (
      <>
        <Header onClose={onClose} onBack={onBack} />
        <div className={classnames(styles.popupContent, [items ? styles.withSlider : null])}>
          {items ? <ItemsSlider items={items} /> : null}
          <h1 className={styles.standardTitle}>
            {title || <FormattedMessage {...messages.header} />}
          </h1>
          <p className={classnames(styles.standardSubtitle)}>
            {subtitle || <FormattedMessage {...messages.text} />}
          </p>
          {error ? (
            <p className={styles.error}>
              <ErrorMessage error={error} internal />
            </p>
          ) : null}
        </div>
        <Footer>
          <div className={styles.buttonContainer}>
            <Locator id="CancellationPopupStandardBackButton">
              <Button tag="button" color={undoButtonColor} onClick={onClose} size="large">
                {undoButton?.text || <FormattedMessage {...messages.closeButton} />}
              </Button>
            </Locator>
          </div>
          <div className={styles.buttonContainer}>
            <Locator id="CancelOrderButtonInPopup">
              <Button tag="button" size="large" color={cancelButtonColor} onClick={onCancel}>
                {cancelButton?.text || <FormattedMessage {...messages.cancelButton} />}
              </Button>
            </Locator>
          </div>
        </Footer>
      </>
    );
  }

  return (
    <Popup width="452px">
      <Overlay loading={loading}>{content}</Overlay>
    </Popup>
  );
};

export function useParcelCancellationPopupRender({
  cancelBehavior,
  error,
  loading,
  onCancel,
  onChangeAddress,
}: Props): PopupViewRender {
  return useCallback(
    ({action, onClose, onBack}: PopupViewProps) => (
      <ParcelCancellationPopupBase
        action={action}
        onClose={onClose}
        onBack={onBack}
        cancelBehavior={cancelBehavior}
        error={error}
        loading={loading}
        onCancel={onCancel}
        onChangeAddress={onChangeAddress}
      />
    ),
    [cancelBehavior, error, loading, onCancel, onChangeAddress],
  );
}

export const ParcelCancellationPopup: React.FC<Props & PopupUserActions> = (props) => {
  const render = useParcelCancellationPopupRender(props);
  const {onBack, onClose} = props;
  return <PopupStack render={render} onBack={onBack} onClose={onClose} />;
};
