import {Locator} from 'components/Locator';
import {useBot} from 'hooks/useBot';
import {useCompanyName} from 'hooks/useCompanyName';
import React from 'react';
import {FormattedMessage} from 'react-intl';

import styles from './index.scss';

type Props = {
  name?: string;
};

export function UserName({name}: Props): React.ReactElement {
  const bot = useBot();
  const companyName = useCompanyName();
  let nameEl: React.ReactNode = name;

  if (bot) {
    nameEl = (
      <FormattedMessage
        description="User name placeholder for seo bots"
        defaultMessage="Customer"
      />
    );
  } else if (!name) {
    nameEl = (
      <FormattedMessage
        description="User fullName placeholder"
        defaultMessage="{companyName} buyer"
        values={{companyName}}
      />
    );
  }

  return (
    <Locator id="review-author">
      <div className={styles.name}>
        <span>{nameEl}</span>
      </div>
    </Locator>
  );
}
