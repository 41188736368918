import {BannerProps} from 'components/ContentList/BannersList/BannerProps';
import {
  SmallImageGridItem,
  SmallImageGridList,
  SmallImageGridLocator,
} from 'components/ContentList/BannersList/SmallImageGrid';
import {Mark} from 'create-locator';
import React, {ReactElement} from 'react';

type Props = BannerProps & Mark<SmallImageGridLocator>;

export function SmallCircle(props: Props): ReactElement {
  const {bannerAppearance, items, onScroll, source} = props;

  return (
    <SmallImageGridList onScroll={onScroll}>
      {items.map((item) => (
        <SmallImageGridItem
          imageType={bannerAppearance.imageType}
          item={item}
          key={item.id}
          source={source}
          mini
        />
      ))}
    </SmallImageGridList>
  );
}
