import Popup from 'components/Popup';
import React, {ReactElement} from 'react';
import {FormattedMessage} from 'react-intl';

import styles from './index.scss';

export class ReloadPopup extends Popup {
  handleReloadClick = (): void => {
    global.location.reload();
  };

  override render(): ReactElement {
    return (
      <Popup className={styles.popup} overlapOthers>
        <div className={styles.content} role="alert">
          <h2 className={styles.header}>
            <FormattedMessage
              description="Reload popup header"
              defaultMessage="Page is out of date"
            />
          </h2>
          <p className={styles.text}>
            <FormattedMessage
              description="Reload popup text"
              defaultMessage="The information on this page is out of date."
            />
          </p>

          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
          <span
            role="button"
            tabIndex={0}
            className={styles.button}
            onClick={this.handleReloadClick}
          >
            <FormattedMessage description="Reload popup button" defaultMessage="Reload page" />
          </span>
        </div>
      </Popup>
    );
  }
}
