import {RootState} from 'store/rootReducer';
import {createAction, createReducer} from 'typesafe-actions';

type MismatchPayload = {
  versionMismatch: string | undefined;
  criticalMismatch: boolean;
};

type State = {
  versionMismatch: string | undefined;
  criticalMismatch: boolean;
};

export const setVersionMismatch = createAction('version/MISMATCH')<MismatchPayload>();

const initialState: State = {
  versionMismatch: undefined,
  criticalMismatch: false,
};

export const reducer = createReducer(initialState).handleAction(
  setVersionMismatch,
  (state, {payload}) => ({
    ...state,
    versionMismatch: payload.versionMismatch,
    criticalMismatch: payload.criticalMismatch,
  }),
);

export function getVersionMismatch(globalState: RootState): string | undefined {
  return globalState.version.versionMismatch;
}

export function isVersionMismatch(globalState: RootState): boolean {
  return Boolean(getVersionMismatch(globalState));
}

export function isVersionMismatchCritical(globalState: RootState): boolean {
  return isVersionMismatch(globalState) && globalState.version.criticalMismatch;
}
