import {PassportFormPopup as PassportFormPopupComponent} from 'components/PassportFormPopup';
import {connect} from 'react-redux';
import {fetchFieldValue} from 'store/modules/personalData';
import {
  loadPersonalDataForm,
  updatePersonalDataForm,
  validatePartialPersonalDataForm,
  validatePersonalDataForm,
} from 'store/modules/personalData/form';
import {
  getPersonalDataForm,
  isPersonalDataFormLoading,
} from 'store/modules/personalData/form/selectors';
import {RootState} from 'store/rootReducer';

const mapStateToProps = (state: RootState) => ({
  loading: isPersonalDataFormLoading(state),
  data: getPersonalDataForm(state),
});

const mapDispatchToProps = {
  fetchFieldValue,
  loadPersonalDataForm,
  updatePersonalDataForm,
  validatePartialPersonalDataForm,
  validatePersonalDataForm,
};

export const PassportFormPopup = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PassportFormPopupComponent);
