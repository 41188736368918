import {Locator} from 'components/Locator';
import {Button} from 'components/UIKit/Button';
import {Footer} from 'components/UIKit/Popup/Footer';
import {PopupViewProps} from 'components/UIKit/Popup/PopupProvider';
import {PopupStack} from 'components/UIKit/Popup/PopupStack';
import {WithIllustrationPopup} from 'components/UIKit/Popup/WithIllustration';
import {CouponCard} from 'connectors/CouponCard';
import {useAnalytics} from 'hooks/useAnalytics';
import {useLanguage} from 'hooks/useLanguage';
import {useScope} from 'hooks/useScope';
import React, {useCallback, useEffect, useMemo} from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';
import {getUrl} from 'routes';
import {CouponCard as CouponCardType} from 'types/CouponCard';
import {confetti} from 'utils/confetti';

import Info from './info.jsx.svg';
import styles from './Popup.scss';

const CONFETTI_CONFIG = {
  startVelocity: 80,
  particleCount: 200,
  spread: 100,
  origin: {
    y: 0.6,
  },
};

type Props = {
  coupon?: CouponCardType;
  onClose?(): void;
  couponAnimationIsPlaying?: boolean;
  onShowAnimation?(): void;
  source?: string;
  'data-testid'?: string;
};

const messages = defineMessages({
  browseProducts: {
    description: 'Action button label',
    defaultMessage: 'Select products',
  },
  couponInfo: {
    description: 'Coupon description',
    defaultMessage:
      'A coupon allows you to have a discount on your purchase. Multiple coupons cannot be combined.',
  },
});

export const CouponPopupView = (props: Props & PopupViewProps): JSX.Element => {
  const {
    coupon,
    onClose,
    onBack,
    onShowAnimation,
    couponAnimationIsPlaying,
    source,
    'data-testid': testId,
  } = props;

  const analytics = useAnalytics();
  const lang = useLanguage();
  const scope = useScope();

  useEffect(() => {
    if (coupon?.id) {
      analytics.sendEvent({
        type: 'couponOpen',
        payload: {
          couponId: coupon.id,
          source: source || '',
        },
      });
    }

    return () => {
      if (coupon?.id) {
        analytics.sendEvent({
          type: 'couponClose',
          payload: {
            couponId: coupon.id,
            source: source || '',
          },
        });
      }
    };
  }, [analytics, coupon?.id, source]);

  useEffect(() => {
    if (couponAnimationIsPlaying) {
      confetti(CONFETTI_CONFIG);
      onShowAnimation?.();
    }
  }, [onShowAnimation, couponAnimationIsPlaying]);

  const illustration = useMemo(
    () => (
      <div className={styles.coupon}>
        {coupon && (
          <CouponCard
            big
            content={coupon}
            showInfoButton={false}
            source="newCouponPopup"
            onAfterApplyDeeplinkFollowed={onClose}
          />
        )}
      </div>
    ),
    [coupon, onClose],
  );

  return (
    <WithIllustrationPopup
      onClose={onClose}
      onBack={onBack}
      illustration={illustration}
      testId={testId}
    >
      <Locator id="CouponPopupTitle">
        <div className={styles.title}>{coupon?.texts.receiveTitle}</div>
      </Locator>

      <Locator id="CouponPopupSubtitle">
        <div className={styles.subtitle}>{coupon?.texts.previewDescription}</div>
      </Locator>

      <Locator id="CouponPopupInfo">
        <div className={styles.info}>
          <span className={styles.infoImage}>
            <Info width={20} height={20} />
          </span>
          <FormattedMessage {...messages.couponInfo} />
        </div>
      </Locator>

      <div className={styles.spacer} />

      <Footer>
        <Locator id="CouponPopupButton">
          <Button onClick={onClose} size="large" to={getUrl('main', {lang, scope})}>
            <FormattedMessage {...messages.browseProducts} />
          </Button>
        </Locator>
      </Footer>
    </WithIllustrationPopup>
  );
};

export const CouponPopup = ({onClose, ...props}: Props): JSX.Element => {
  return (
    <PopupStack
      render={useCallback(
        (viewProps) => (
          <CouponPopupView {...props} {...viewProps} />
        ),
        [props],
      )}
      onClose={onClose}
    />
  );
};
