import {requestActionCreator} from 'store/utils/requestActions';
import {FuncAction} from 'typesafe-actions';
import {digestMessage} from 'utils/digestMessage';

import {load, LoadResponse} from './actions';

const loadPageHash = (url: string): FuncAction =>
  requestActionCreator(
    load,
    {url},
    (): Promise<LoadResponse> => digestMessage(url).then((hash) => ({hash})),
  );

export {loadPageHash};
