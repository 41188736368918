import {getLangCodes} from 'helpers/language';
import {useApiClient} from 'hooks/useApiClient';
import {useLanguage} from 'hooks/useLanguage';
import React, {ReactElement, useCallback, useState} from 'react';

export function ForcedRtl(): ReactElement {
  const client = useApiClient();
  const lang = useLanguage();
  const [forced, setForced] = useState(() => client.cookiesRegistry.forcedRtl.restore());

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const nextForced = event.target.checked;
      if (nextForced) {
        window.document.dir = 'rtl';
      } else {
        window.document.dir = getLangCodes({rtl: true}).includes(lang) ? 'rtl' : 'ltr';
      }
      client.cookiesRegistry.forcedRtl.store(nextForced);
      setForced(nextForced);
    },
    [client.cookiesRegistry.forcedRtl, lang],
  );

  return (
    <label>
      <input type="checkbox" checked={forced} onChange={handleChange} />
      &nbsp;rtl
    </label>
  );
}
