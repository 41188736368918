import classnames from 'classnames/bind';
import {type LocatorOfElement, type Mark, createLocator} from 'create-locator';
import React, {AnchorHTMLAttributes, ButtonHTMLAttributes, LabelHTMLAttributes} from 'react';
import {Link, LinkProps as RouterLinkProps} from 'react-router-dom';
import {TypedObject} from 'utils/object/typed';

import styles from './index.scss';
import {Color as ColorType, Shape as ShapeType, Size as SizeType} from './types';

const cn = classnames.bind(styles);

export type Color = ColorType;

export type Shape = ShapeType;

export type Size = SizeType;

export type StyleProps = {
  tag?: 'link' | 'button' | 'a' | 'label';
  /**
   * accent [по умолчанию] — Основная кнопка: Используется для целевого действия. Не более одной кнопки в одном разделе.
   * primary — Кнопка по умолчанию: обозначает действие без приоритета.
   * gray — Используется для иных действий.
   * Также можно сконфигурировать вручную.
   */
  color?: Color;
  /**
   * rounded-rect [по умолчанию] — Прямоугольная со скругленным углами
   * round — Круглые края
   * circle — Кнопка-круг. контент внутри центрируется. Не может увеличиваться в ширину.
   */
  shape?: Shape;
  /**
   * Размер у одной и той же кнопки может меняться в зависимости от размера экрана.
   */
  size?: Size;
  /**
   * Растягивает кнопку на всю доступную ширину.
   */
  fullWidth?: boolean;
};

export type ButtonLocator = LocatorOfElement<void>;

type ButtonProps = Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'color' | 'size'> & {
  tag: 'button';
} & StyleProps;

type LinkProps = Omit<RouterLinkProps, 'color' | 'size'> & {
  tag?: 'link';
} & StyleProps;

type AnchorProps = Omit<AnchorHTMLAttributes<HTMLAnchorElement>, 'color' | 'size'> & {
  tag?: 'a';
} & StyleProps;

type LabelProps = Omit<LabelHTMLAttributes<HTMLLabelElement>, 'color' | 'size'> & {
  tag?: 'label';
} & StyleProps;

type Props = React.PropsWithChildren<ButtonProps | LinkProps | AnchorProps | LabelProps> &
  Partial<Mark<ButtonLocator>>;

function getSizeClassValue(size: StyleProps['size']): Record<string, true> | string | undefined {
  if (typeof size === 'object') {
    const result: Record<string, true> = {};
    TypedObject.keys(size).forEach((key) => {
      const value = size[key];
      if (value) {
        result[`${key}-${size[key]}`] = true;
      }
    });
    return result;
  }
  return size;
}

const getButtonProps = (props: Props): ButtonProps | null =>
  props.tag === 'button' ? props : null;

const getAnchorProps = (props: Props): AnchorProps | null => (props.tag === 'a' ? props : null);

const getLabelProps = (props: Props): LabelProps | null => (props.tag === 'label' ? props : null);

export const Button = React.forwardRef<
  HTMLAnchorElement | HTMLLabelElement | HTMLButtonElement,
  Props
>((props, ref): JSX.Element => {
  const locator = createLocator(props);
  const typedProps =
    getButtonProps(props) || getAnchorProps(props) || getLabelProps(props) || (props as LinkProps);
  const {
    tag = 'link',
    color = 'accent',
    shape = 'rounded-rect',
    size = 'medium',
    fullWidth = false,
    children,
    ...restProps
  } = typedProps;

  const colorClassName = typeof color === 'string' ? color : color.base;
  const className = cn('button', shape, size, colorClassName, getSizeClassValue(size), {fullWidth});
  const style =
    typeof color === 'string'
      ? undefined
      : {
          color: color.color,
          background: color.background,
        };
  const styleProps = {className, style};

  if (tag === 'button') {
    /* eslint-disable react/button-has-type */
    return (
      <button
        {...styleProps}
        {...(restProps as ButtonHTMLAttributes<HTMLButtonElement>)}
        ref={ref as React.Ref<HTMLButtonElement>}
        {...locator()}
      >
        {children}
      </button>
    );
    /* eslint-enable react/button-has-type */
  }

  if (tag === 'label') {
    return (
      <label
        {...styleProps}
        {...(restProps as LabelHTMLAttributes<HTMLLabelElement>)}
        ref={ref as React.Ref<HTMLLabelElement>}
        {...locator()}
      >
        {children}
      </label>
    );
  }

  if (tag === 'a') {
    return (
      <a
        {...styleProps}
        {...(restProps as ButtonHTMLAttributes<HTMLAnchorElement>)}
        ref={ref as React.Ref<HTMLAnchorElement>}
        {...locator()}
      >
        {children}
      </a>
    );
  }

  return (
    <Link
      {...styleProps}
      {...(restProps as RouterLinkProps)}
      ref={ref as React.Ref<HTMLAnchorElement>}
      {...locator()}
    >
      {children}
    </Link>
  );
});
