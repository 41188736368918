import React, {useCallback, useMemo, useState} from 'react';
import {isNotNullish} from 'utils/guards';

import {ErrorMessage} from './ErrorMessage';
import {useMediaUploader} from './hooks/useMediaUploader';
import styles from './index.scss';
import {Input} from './Input';
import {Item} from './Item';
import {MediaBundle, MediaFilter, UploaderError} from './types';
import {getSupportedMimeTypes} from './utils/getSupportedMimeTypes';

type Props = {
  disabled?: boolean;
  filters: MediaFilter[];
  maxCount?: number;
  onChange?: (bundles: MediaBundle[]) => void;
  onError?: (error: UploaderError) => void;
  showLabel?: boolean;
  size?: 'small' | 'medium';
  value?: MediaBundle[];
};

export function MediaUploader({
  disabled,
  filters,
  maxCount = 1,
  onChange,
  onError,
  showLabel,
  size,
  value,
}: Props): JSX.Element {
  const [error, setError] = useState<UploaderError>();
  const supportedMimeTypes = useMemo(() => getSupportedMimeTypes(filters), [filters]);
  const handleChange = useCallback(
    (bundles: MediaBundle[]) => {
      setError(undefined);
      onChange?.(bundles);
    },
    [onChange],
  );
  const handleError = useCallback(
    (error: UploaderError) => {
      setError(error);
      onError?.(error);
    },
    [onError],
  );
  const {addMedia, mediaStatesList, removeMedia, retryMediaUploading} = useMediaUploader({
    filters,
    maxCount,
    onChange: handleChange,
    onError: handleError,
    value,
  });

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const files = event.target.files ? Array.from(event.target.files) : [];

      files.map(addMedia);
      event.target.value = '';
    },
    [addMedia],
  );

  return (
    <div>
      {isNotNullish(error) && (
        <div className={styles.error}>
          <ErrorMessage error={error} />
        </div>
      )}
      <div className={styles.mediaList}>
        {mediaStatesList.map((mediaState) => (
          <div className={styles.mediaItem} key={mediaState.uuid}>
            <Item
              mediaState={mediaState}
              onRemove={removeMedia}
              onRetry={retryMediaUploading}
              size={size}
            />
          </div>
        ))}
        {mediaStatesList.length < maxCount && (
          <div className={styles.mediaItem}>
            <Input
              acceptedMimeTypes={supportedMimeTypes}
              disabled={disabled}
              multiple={maxCount > 1}
              onChange={handleInputChange}
              size={size}
              showLabel={showLabel && mediaStatesList.length === 0}
            />
          </div>
        )}
      </div>
    </div>
  );
}
