import PropTypes from 'prop-types';

export default PropTypes.shape({
  zip: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  street: PropTypes.string.isRequired,
  latitude: PropTypes.number.isRequired,
  longitude: PropTypes.number.isRequired,
  workHours: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string,
});
