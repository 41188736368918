import {
  GalleryImageItem,
  GalleryItem,
  GalleryItemType,
  isGalleryVideoItem,
} from 'types/GalleryItem';
import {ImageBundle} from 'types/Image';

import {getImageKey} from './image';
import {getVideoKey} from './video';

export function getGalleryItemImage(item: GalleryItem): ImageBundle | undefined {
  if (isGalleryVideoItem(item)) {
    return item.payload.thumbnail;
  }

  return item.payload;
}

export function getGalleryItemKey(item: GalleryItem): string | undefined {
  if (isGalleryVideoItem(item)) {
    return getVideoKey(item.payload);
  }

  return getImageKey(item.payload);
}

export function galleryImageItemFromImageBundle(image: ImageBundle): GalleryImageItem {
  return {
    type: GalleryItemType.IMAGE,
    payload: image,
  };
}
