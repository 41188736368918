import classnames from 'classnames/bind';
import {type Locator, type Mark, createLocator} from 'create-locator';
import React from 'react';
import {FormattedNumber} from 'react-intl';

import styles from './index.scss';

const cn = classnames.bind(styles);

export type DiscountBadgeLocator = Locator<void>;

type Props = {
  discount: number;
  bright?: boolean;
  greyscale?: boolean;
} & Partial<Mark<DiscountBadgeLocator>>;

export const DiscountBadge = React.memo(function DiscountBadge(props: Props) {
  const {discount, bright, greyscale} = props;
  const locator = createLocator(props);

  return (
    <div
      className={cn('badge', 'discountBadge', bright && 'bright', greyscale && 'greyscale')}
      {...locator()}
    >
      <FormattedNumber style="percent" minimumFractionDigits={0} value={discount / -100} />
    </div>
  );
});
