import {Locator} from 'components/Locator';
import {Scope} from 'config';
import {useDeviceVars} from 'hooks/useDeviceVars';
import {useLanguage} from 'hooks/useLanguage';
import {useScope} from 'hooks/useScope';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import {getUrl} from 'routes';

import {
  COOLBE_LEGAL_FAQ_ARTICLE,
  LEGAL_FAQ_ARTICLE,
  PRODUCT_SAFETY_COOLBE_FAQ_ARTICLE,
  PRODUCT_SAFETY_FAQ_ARTICLE,
} from '../constants';
import {FooterColumn} from '../FooterColumn';

export function FooterColumnCompany(): JSX.Element {
  const lang = useLanguage();
  const scope = useScope();
  const {joomAboutLink, showLegalInfoLinkInFooter} = useDeviceVars();

  const companyColumnTitle = (
    <FormattedMessage description="'Company' header in footer" defaultMessage="Company" />
  );

  return (
    <FooterColumn title={companyColumnTitle}>
      {scope.notDomain(Scope.COOLBE) && (
        <Locator id="FooterLinkAbout">
          <FooterColumn.Item
            to={joomAboutLink && scope.notJmt ? joomAboutLink : getUrl('about', {lang, scope})}
          >
            <FormattedMessage description="About page link in footer" defaultMessage="About us" />
          </FooterColumn.Item>
        </Locator>
      )}
      <Locator id="FooterLinkIprProtection">
        <FooterColumn.Item to={getUrl('ipr-protection', {scope, lang})}>
          <FormattedMessage
            description="IPR Protection link in footer"
            defaultMessage="Copyright protection"
          />
        </FooterColumn.Item>
      </Locator>
      {scope.notJmt && (
        <Locator id="FooterLinkProductSafety">
          <FooterColumn.Item
            to={getUrl('faqArticle', {
              scope,
              lang,
              articleId: scope.is(Scope.COOLBE, Scope.CBTREND)
                ? PRODUCT_SAFETY_COOLBE_FAQ_ARTICLE
                : PRODUCT_SAFETY_FAQ_ARTICLE,
            })}
          >
            <FormattedMessage
              description="Product Safety link in footer"
              defaultMessage="Product safety"
            />
          </FooterColumn.Item>
        </Locator>
      )}
      {scope.notJmt && scope.notDomain(Scope.COOLBE) && showLegalInfoLinkInFooter && (
        <FooterColumn.Item to={getUrl('faqArticle', {scope, lang, articleId: LEGAL_FAQ_ARTICLE})}>
          {lang === 'de' ? (
            'Impressum'
          ) : (
            <FormattedMessage
              description="Ссылка на юридическую информацию в футере."
              defaultMessage="Legal"
            />
          )}
        </FooterColumn.Item>
      )}
      {scope.isDomain(Scope.COOLBE) && (
        <FooterColumn.Item
          to={getUrl('faqArticle', {scope, lang, articleId: COOLBE_LEGAL_FAQ_ARTICLE})}
        >
          <FormattedMessage
            defaultMessage="Contact us"
            description={
              // prettier-ignore
              'Legal notice FAQ link in footer\nOriginal EN text:\nContact us'
            }
          />
        </FooterColumn.Item>
      )}
    </FooterColumn>
  );
}
