import {useSharePopupRender} from 'components/ClientProduct/SharePopup';
import {ModalOverlay} from 'components/UIKit/ModalOverlay';
import {Popup} from 'components/UIKit/Popup';
import {Content} from 'components/UIKit/Popup/Content';
import {Footer} from 'components/UIKit/Popup/Footer';
import {StandaloneControls} from 'components/UIKit/Popup/Header/StandaloneControls';
import {usePopup} from 'components/UIKit/Popup/PopupProvider';
import {WithIllustrationPopup} from 'components/UIKit/Popup/WithIllustration';
import {Illustration} from 'components/UIKit/Popup/WithIllustration/Illustration';
import {eventParamsList} from 'helpers/eventParams';
import {EventCloseCreator, useSendAnalyticsOpenClose} from 'hooks/useAnalytics';
import {usePlatformShare} from 'hooks/usePlatformShare';
import React, {useCallback, useMemo, useRef} from 'react';
import {Error} from 'types/Error';
import {ContentItem, PopupResponse, ShareData, ShareParams} from 'types/Popup';
import {voidFunction} from 'utils/function';
import {rootLocator} from 'utils/rootLocator';

import {Item} from './Item';

const locator = rootLocator.clientPopup;

type ClientPopupProps = {
  popup: PopupResponse;
  shareData?: ShareData;
  shareLoading?: boolean;
  shareError?: Error;
  loadShareData(sourceUrlOrShareParama?: string | ShareParams): void;
  onClose?: () => void;
  onBack?: () => void;
};

const MAX_FOOTER_SIZE = 2;

function getFooterSize(content: ContentItem[]): number {
  let count = 0;
  for (let i = content.length - 1; i > 0; i -= 1) {
    const item = content[i]!;
    if (item.button) {
      count += 1;
    } else {
      break;
    }
  }
  return count <= MAX_FOOTER_SIZE ? count : 0;
}

const ClientPopup: React.FC<ClientPopupProps> = React.memo((props: ClientPopupProps) => {
  const {
    onBack,
    onClose,
    loadShareData,
    popup,
    popup: {id, payload, eventParams},
    shareData,
    shareLoading,
    shareError,
  } = props;

  const [content, footerContent] = useMemo(() => {
    const footerSize = getFooterSize(payload.content);
    const footerIndex = payload.content.length - footerSize;
    return [payload.content.slice(0, footerIndex), payload.content.slice(footerIndex)];
  }, [payload.content]);

  const closeEvent = useRef<EventCloseCreator>(({sinceOpenMs}) => ({
    type: 'popupClose',
    payload: {popupId: id, sinceOpenMs},
    params: eventParamsList(eventParams),
  }));

  const handlePopupClose = useCallback(() => {
    closeEvent.current = () => ({
      type: 'popupCloseButtonClick',
      payload: {popupId: id},
      params: eventParamsList(eventParams),
    });
    if (onClose) {
      onClose();
    }
  }, [eventParams, id, onClose]);

  useSendAnalyticsOpenClose(
    () => ({
      type: 'popupOpen',
      payload: {popupId: id},
      params: eventParamsList(eventParams),
    }),
    closeEvent.current,
    [id],
  );

  const sharePopupView = useSharePopupRender({
    data: shareData,
    loading: shareLoading || (!shareData && !shareError),
    error: shareError,
  });
  const {open: sharePopupOpen} = usePopup({
    render: sharePopupView,
    // Prevent the close handler being called, when the back handler
    // is not defined.
    onBack: voidFunction,
    onClose: handlePopupClose,
  });

  const platformShare = usePlatformShare();

  const handleShareClick = useCallback(() => {
    if (platformShare && shareData) {
      platformShare({
        url: shareData.url,
        title: shareData.message,
      }).catch((error) => {
        if (error.code !== error.ABORT_ERR) {
          sharePopupOpen();
        }
      });
    } else {
      sharePopupOpen();
    }
  }, [platformShare, shareData, sharePopupOpen]);

  const headerRow = payload.content.find((item) => item.header);
  const hasBackground = Boolean(headerRow?.header?.backgroundImage);
  const hasImage = hasBackground || headerRow?.header?.iconImage || headerRow?.header?.qrLink;

  const renderItem = (item: ContentItem, index: number) => (
    <Item
      key={index}
      item={item}
      loadShareData={loadShareData}
      onClose={handlePopupClose}
      onShareClick={handleShareClick}
      popup={popup}
    />
  );

  if (hasImage) {
    const backroundImage = headerRow?.header?.backgroundImage
      ? {
          image: headerRow.header.backgroundImage,
          cover: true,
        }
      : undefined;
    const image = headerRow?.header?.iconImage
      ? {
          image: headerRow.header.iconImage,
        }
      : undefined;

    const illustration = (
      <Illustration
        backgroundImage={backroundImage}
        image={image}
        qrLink={headerRow?.header?.qrLink}
        {...locator.illustration()}
      />
    );

    return (
      <ModalOverlay onClose={onClose}>
        <WithIllustrationPopup
          onBack={onBack}
          onClose={handlePopupClose}
          illustration={illustration}
          testId="ClientPopupWithIllustration"
          popupId={popup.id}
        >
          {content.map(renderItem)}
          {footerContent.length ? (
            <Footer marginTop="auto">{footerContent.map(renderItem)}</Footer>
          ) : null}
        </WithIllustrationPopup>
      </ModalOverlay>
    );
  }

  const backgroundImage = payload.backgroundImage
    ? {
        image: payload.backgroundImage,
        cover: true,
      }
    : undefined;

  return (
    <ModalOverlay onClose={onClose}>
      <Popup width="600px" popupId={id} testId="ClientPopup">
        <Content backgroundImage={backgroundImage} backgroundColor={payload.backgroundColor}>
          <StandaloneControls onBack={onBack} onClose={handlePopupClose} />
          {content.map(renderItem)}
          {footerContent.length ? (
            <Footer transparent={Boolean(backgroundImage)} marginTop="auto">
              {footerContent.map(renderItem)}
            </Footer>
          ) : null}
        </Content>
      </Popup>
    </ModalOverlay>
  );
});

export {ClientPopup};
