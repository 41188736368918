import React from 'react';

import styles from './Scroll.scss';

type Props = {
  children: React.ReactNode;
};

export const Scroll: React.FC<Props> = ({children}: Props) => {
  return <div className={styles.scroll}>{children}</div>;
};

// eslint-disable-next-line import/no-default-export
export default Scroll;
