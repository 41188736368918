import {Banners} from 'components/OrderBanner/Banners';
import {ConsolidationHeader} from 'components/ParcelElements/ConsolidationHeader';
import {SHOW_DELIVERY_POINT_ACTION} from 'components/ParcelPage';
import {ParcelPageCardInner} from 'components/ParcelPageCard';
import TrackingCheckpoints from 'components/TrackingCheckpoints';
import {useLanguage} from 'hooks/useLanguage';
import {usePreviewEvent} from 'hooks/usePreviewEvent';
import {produce} from 'immer';
import PropTypes from 'prop-types';
import React, {memo, useCallback, useMemo, useRef} from 'react';
import {useHistory} from 'react-router-dom';
import {ParcelLiteShape} from 'shapes/ParcelLite';
import {getDeliveredTimeMs, getShippingEstimate, getStatusAppearance} from 'store/modules/parcels';
import {ClickArea} from 'types/analytics/Parcel';
import {isArrayNotEmpty} from 'utils/array';
import {getValueByPath} from 'utils/object';
import {getUrl} from 'routes';
import {useScope} from 'hooks/useScope';

import {rootLocator} from 'utils/rootLocator';
import styles from './index.scss';
import {Order} from './Order';

const locator = rootLocator.parcelsPage.parcel;

function ParcelComponent(props) {
  const {parcel, activeFilter} = props;
  const history = useHistory();
  const lang = useLanguage();
  const scope = useScope();
  const orders = (parcel && parcel.orders) || [];
  const checkpoint = getValueByPath(parcel, 'tracking', 'checkpoint');
  const consolidationBadge = getValueByPath(parcel, 'appearance', 'consolidationBadge');
  const banners = parcel && parcel.banners;
  const {eventParams, id: parcelId} = parcel;
  const ref = useRef(null);
  const trackingRef = useRef(null);
  const buttonRef = useRef(null);
  const imgRef = useRef(null);
  const idRef = useRef(null);

  const eventsData = useMemo(() => {
    const payload = {
      tabId: activeFilter,
      parcelId,
    };
    const params = eventParams ? [eventParams] : [];
    const clickEvent = {
      type: 'orderParcelClick',
      payload,
      params,
    };

    return [
      {
        rootRef: ref,
        previewEvent: {
          type: 'orderParcelPreview',
          payload,
          params,
        },
      },
      /* eslint-disable no-param-reassign */
      [
        {
          clickRef: trackingRef,
          clickEvent: produce(clickEvent, (draft) => {
            draft.payload.location = ClickArea.TRACKING;
          }),
        },
        {
          clickRef: buttonRef,
          clickEvent: produce(clickEvent, (draft) => {
            draft.payload.location = ClickArea.ABOUT_BUTTON;
          }),
        },
        {
          clickRef: imgRef,
          clickEvent: produce(clickEvent, (draft) => {
            draft.payload.location = ClickArea.ORDER;
          }),
        },
        {
          clickRef: idRef,
          clickEvent: produce(clickEvent, (draft) => {
            draft.payload.location = ClickArea.ORDER;
          }),
        },
      ],
    ];
  }, [activeFilter, eventParams, parcelId]);

  usePreviewEvent(...eventsData);

  const handleTrackingClick = useCallback(() => {
    history.push(getUrl('order', {lang, scope, parcelId}, {area: ClickArea.TRACKING}));
  }, [lang, scope, parcelId, history]);

  const handlePickupInfoBannerClick = useCallback(() => {
    history.push(getUrl('order', {lang, scope, parcelId, action: SHOW_DELIVERY_POINT_ACTION}));
  }, [lang, scope, parcelId, history]);

  return (
    <div ref={ref} {...locator({id: parcelId})}>
      <ParcelPageCardInner>
        <ConsolidationHeader
          consolidationBadge={consolidationBadge}
          shippingEstimate={getShippingEstimate(parcel)}
          statusAppearance={getStatusAppearance(parcel)}
          deliveredTimeMs={getDeliveredTimeMs(parcel)}
        />
      </ParcelPageCardInner>
      {isArrayNotEmpty(banners) && (
        <ParcelPageCardInner>
          <Banners banners={banners} showDeliveryInfo={handlePickupInfoBannerClick} />
        </ParcelPageCardInner>
      )}
      {orders.map((orderItem) => (
        <Order
          key={orderItem.order.id}
          order={orderItem.order}
          vatInfo={orderItem.vatInfo}
          orderAction={orderItem.action}
          quantity={orderItem.quantity}
          parcel={parcel}
          statusAppearance={orderItem.statusAppearance}
          buttonRef={buttonRef}
          imgRef={imgRef}
          idRef={idRef}
          showSpecialPriceIcon={orderItem.showSpecialPriceIcon}
        />
      ))}
      {Boolean(checkpoint) && (
        <div
          ref={trackingRef}
          className={styles.footer}
          onClick={handleTrackingClick}
          role="button"
          tabIndex="0"
        >
          <TrackingCheckpoints checkpoints={[checkpoint]} hasMore />
        </div>
      )}
    </div>
  );
}

ParcelComponent.propTypes = {
  parcel: ParcelLiteShape,
  activeFilter: PropTypes.string,
};

ParcelComponent.defaultProps = {
  parcel: null,
  activeFilter: undefined,
};

export const Parcel = memo(ParcelComponent);
