import {Review} from 'components/Review';
import {Scope} from 'config';
import {useBot} from 'hooks/useBot';
import {useDeepLink} from 'hooks/useDeepLink';
import {useDeviceVars} from 'hooks/useDeviceVars';
import {useLanguage} from 'hooks/useLanguage';
import {useLocationQuery} from 'hooks/useLocationQuery';
import {useMobWebAdInterlayer} from 'hooks/useMobWebAdInterlayer';
import {usePreviewEvent} from 'hooks/usePreviewEvent';
import {useScope} from 'hooks/useScope';
import _groupBy from 'lodash/groupBy';
import React, {useMemo, useRef} from 'react';
import {getUrl} from 'routes';
import {GalleryItem, GalleryItemType} from 'types/GalleryItem';
import {SocialPost} from 'types/SocialPost';

import {SORT_PARAM} from '../constants';

// Use author URL
type UseAuthorUrlProps = {
  review: SocialPost;
};
type UseAuthorUrlRenderProps = string | undefined;

const useAuthorUrl = ({review}: UseAuthorUrlProps): UseAuthorUrlRenderProps => {
  const bot = useBot();
  const lang = useLanguage();
  const scope = useScope();
  const {socialPostAuthorLinkEnabled} = useDeviceVars();
  const mobWebAdInterlayerEnabled = useMobWebAdInterlayer(['ios_nightmare']);
  const userId = review.author.id;

  if (
    !bot &&
    !mobWebAdInterlayerEnabled &&
    socialPostAuthorLinkEnabled &&
    scope.is(Scope.GLOBAL) &&
    userId
  ) {
    return getUrl('socialUser', {lang, scope, userId});
  }

  return undefined;
};

// Use variant label
type UseVariantLabelProps = {
  review: SocialPost;
};
type UseVariantLabelRenderProps = string | undefined;

const useVariantLabel = ({review}: UseVariantLabelProps): UseVariantLabelRenderProps => {
  return useMemo(() => {
    const reviewPayload = review.payload?.review;
    const sizeLabel = reviewPayload?.size;
    const colorLabel = reviewPayload?.colors?.[0]?.name;
    const labels = [sizeLabel, colorLabel].filter(Boolean);

    return labels.length ? labels.join(' | ') : undefined;
  }, [review]);
};

// Use variant review label
type UseVariantReviewLabelProps = {
  review: SocialPost;
};
type UseVariantReviewLabelRenderProps = string | undefined;

const useVariantReviewLabel = ({
  review,
}: UseVariantReviewLabelProps): UseVariantReviewLabelRenderProps => {
  return review?.payload?.review?.answers?.[0]?.text?.text;
};

type ReviewItemProps = {
  defaultSortingId?: string;
  onMediaClick: (item: GalleryItem, index: number) => void;
  review: SocialPost;
  rows?: number;
  withMedia?: boolean;
  withReport?: boolean;
  withVariant?: boolean;
};

export function ReviewItem({
  defaultSortingId,
  onMediaClick,
  review,
  rows,
  withMedia,
  withReport,
  withVariant,
}: ReviewItemProps): JSX.Element {
  const reviewRef = useRef<HTMLDivElement>(null);
  const authorUrl = useAuthorUrl({review});
  const variantLabel = useVariantLabel({review});
  const variantReviewLabel = useVariantReviewLabel({review});
  const [, feedbackDeeplickAction] = useDeepLink(review.feedback?.deeplink);

  const mediaByTypes = useMemo(() => _groupBy(review.media, (item) => item.type), [review.media]);
  const queryParams = useLocationQuery();

  usePreviewEvent({
    rootRef: reviewRef,
    previewEvent: {
      type: 'socialPostPreview',
      payload: {
        hasText: Boolean(review.text),
        hasLive: false,
        hasUserLive: false,
        imageCount: mediaByTypes[GalleryItemType.IMAGE]?.length ?? 0,
        videoCount: mediaByTypes[GalleryItemType.VIDEO]?.length ?? 0,
        postId: review.id,
        source: 'productReviews',
        design: 'compact',
        sortType: (queryParams[SORT_PARAM] as string) || defaultSortingId || undefined,
      },
    },
  });

  return (
    <Review
      authorAvatar={review.author.avatar}
      authorName={review.author.name}
      authorUrl={authorUrl}
      createdTime={review.createdTimeMs}
      innerRef={reviewRef}
      media={review.media}
      onMediaClick={onMediaClick}
      onReport={withReport ? feedbackDeeplickAction : undefined}
      originalText={review.originalText}
      rating={review.starRating}
      rows={rows}
      text={review.text}
      translationPolicy={review.translationPolicy}
      variantLabel={variantLabel}
      variantReviewLabel={variantReviewLabel}
      withMedia={withMedia}
      withVariant={withVariant}
    />
  );
}
