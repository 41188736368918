import classnames from 'classnames/bind';
import DotLoader from 'components/DotLoader';
import PropTypes from 'prop-types';
import React, {useRef} from 'react';
import {CSSTransition} from 'react-transition-group';
import Success from './Success';
import styles from './index.scss';

const cn = classnames.bind(styles);

export const Overlay = React.memo(function Overlay({children, cover, loading, success}) {
  const coverRef = useRef(null);

  if (!children && !cover) {
    // in this case overlay has content based size, no reason to render
    return null;
  }

  return (
    <div className={cn('overlay', {cover, active: loading || success})}>
      <div className={cn('children')}>{children}</div>
      <CSSTransition
        classNames={styles}
        in={loading || success}
        timeout={500}
        mountOnEnter
        unmountOnExit
        nodeRef={coverRef}
      >
        <div ref={coverRef} className={cn('cover')}>
          <div className={cn('content')}>
            {loading && !success ? (
              <div className={cn('loader')}>
                <DotLoader style="inherit" />
              </div>
            ) : null}
            <Success success={success} />
          </div>
        </div>
      </CSSTransition>
    </div>
  );
});

Overlay.propTypes = {
  children: PropTypes.node,
  cover: PropTypes.bool,
  loading: PropTypes.bool,
  success: PropTypes.bool,
};

Overlay.defaultProps = {
  children: null,
  cover: false,
  loading: false,
  success: false,
};
