import {Locator} from 'components/Locator';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Star} from './Star';
import styles from './Rating.scss';

const STARS = [1, 2, 3, 4, 5];

export class Rating extends Component {
  static propTypes = {
    onSelect: PropTypes.func,
    value: PropTypes.number,
  };

  static defaultProps = {
    onSelect: null,
    value: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (state.value !== props.value) {
      return {
        value: props.value,
      };
    }
    return null;
  }

  getValue() {
    return this.state.value;
  }

  handleChange = (evt) => {
    const value = parseInt(evt.target.value, 10);
    this.setState({value});
    if (this.props.onSelect) {
      this.props.onSelect(value);
    }
  };

  render() {
    const {value} = this.state;

    return (
      <div className={styles.stars}>
        {STARS.map((star, index) => (
          <Locator id="ReviewFormRating" rating={star} key={star}>
            <label className={styles.star}>
              <Star value={value - index} />
              <input
                className={styles.input}
                onChange={this.handleChange}
                type="radio"
                name="deliveryDurations"
                checked={value === star}
                value={star}
              />
            </label>
          </Locator>
        ))}
      </div>
    );
  }
}
