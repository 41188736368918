import {requestActionCreator} from 'store/utils/requestActions';
import {PopupResponse, ShareDataResponse, ShareParams} from 'types/Popup';
import {FuncAction} from 'typesafe-actions';
import {isString} from 'utils/guards';

import {
  getPopupById as getPopupByIdAction,
  getPopupForEvent as getPopupForEventAction,
  loadShareData as loadShareDataAction,
  popupMarkAsSeen as popupMarkAsSeenAction,
} from './actions';
import {getShareButtonParams} from './utils';

export * from './selectors';

export enum PopupEvent {
  OPEN_APP = 'openApp',
  OPEN_CART = 'openCart',
  ORDER_PAID = 'orderPaid',
  OPEN_CROWDNAMES = 'openCrowdnames',
  OPEN_POINTS = 'openPoints',
}

type PopupResponseBody = {
  payload: PopupResponse;
};

type OverlayResponseBody = {
  payload?: {popup?: PopupResponse};
};

const getPopupById = (popupId: string, params?: Record<string, string>): FuncAction =>
  requestActionCreator(getPopupByIdAction, {popupId}, (store, client) =>
    client.api
      .post<PopupResponseBody>(`/popups/${popupId}/get`, {body: {params}})
      .then(({body: {payload}}) => payload),
  );

const getPopupForEvent = (event: PopupEvent): FuncAction =>
  requestActionCreator(getPopupForEventAction, {event}, async (store, client) => {
    const useOverlayForPopups = client.device.getDeviceVar('useOverlayForPopups');

    if (useOverlayForPopups) {
      return client.api
        .post<OverlayResponseBody>('/overlay/get', {
          body: {
            event,
            pageData: {url: client.getOriginalUrl()},
          },
        })
        .then((response) => response.body.payload?.popup);
    }

    return client.api
      .get<PopupResponseBody>(`/popups/event/${event}`, {
        query: {
          url: client.getOriginalUrl(),
        },
      })
      .then(({body: {payload}}) => payload);
  });

const loadShareData = (sourceUrlOrShareParams: string | ShareParams): FuncAction =>
  requestActionCreator(loadShareDataAction, {}, (store, client) =>
    client.api
      .post<ShareDataResponse>('/share', {
        body: isString(sourceUrlOrShareParams)
          ? getShareButtonParams(sourceUrlOrShareParams)
          : sourceUrlOrShareParams,
      })
      .then((resp) => resp.body.payload),
  );

const popupMarkAsSeen = (popupId: string, params?: unknown): FuncAction =>
  requestActionCreator(popupMarkAsSeenAction, {popupId, params}, (store, client) =>
    client.api
      .post(`/popups/${popupId}/markAsSeen`, {
        body: {params},
      })
      .catch((error) => {
        if (error.status === 404) {
          // there might be popups with bogus id but we cannot know until the request is made.
          // let's just ignore the 404s
          return null;
        }

        throw error;
      }),
  );

export {getPopupById, getPopupForEvent, loadShareData, popupMarkAsSeen};
