import {UploadingStatus} from 'store/modules/media/actions';
import {checkNever} from 'utils/guards';

import {MediaStatus} from '../types';

export const getMediaStatusByUploadingStatus = (uploadingStatus: UploadingStatus): MediaStatus => {
  switch (uploadingStatus) {
    case 'error':
      return MediaStatus.ERROR;

    case 'inProgress':
      return MediaStatus.IN_PROGRESS;

    case 'success':
      return MediaStatus.SUCCESS;

    default:
      checkNever(uploadingStatus);
      return uploadingStatus;
  }
};
