import PropTypes from 'prop-types';
import React from 'react';

export type SvgProps = {
  children: React.ReactNode;
  className?: string;
  height?: string | number;
  preserveAspectRatio?: string;
  viewBox?: string;
  width?: string | number;
};

export function Svg({
  className,
  children,
  height,
  preserveAspectRatio,
  viewBox,
  width,
}: SvgProps): React.ReactElement {
  return (
    <svg
      className={className}
      height={height}
      preserveAspectRatio={preserveAspectRatio}
      viewBox={viewBox}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      {children}
    </svg>
  );
}

Svg.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  preserveAspectRatio: PropTypes.string,
  viewBox: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Svg.defaultProps = {
  children: null,
  className: '',
  height: null,
  preserveAspectRatio: null,
  width: null,
};

// eslint-disable-next-line import/no-default-export
export default Svg;
