import classnames from 'classnames';
import React from 'react';

import styles from './Row.scss';

type Props = {
  children?: React.ReactNode;
  mobileFullWidthOnly?: boolean;
};

export const Row = React.forwardRef<HTMLDivElement, Props>(function Row(
  {children, mobileFullWidthOnly = false}: Props,
  ref,
) {
  return (
    <div
      ref={ref}
      className={classnames(styles.row, {[styles.mobileFullWidthOnly!]: mobileFullWidthOnly})}
    >
      {children}
    </div>
  );
});
