import classnames from 'classnames/bind';
import React, {FC, ReactElement, ReactNode} from 'react';

import {sidebarRight} from '../appearance/default';
import {StandaloneControls} from '../Header/StandaloneControls';
import {Popup, PopupProps} from '../index';
import {PopupUserActions} from '../types';
import styles from './index.scss';

const cn = classnames.bind(styles);

type LayoutProps = {
  illustration: ReactNode;
};

type Props = Exclude<PopupProps, 'width' | 'appearance'> & PopupUserActions & LayoutProps;

const Layout: FC<LayoutProps & {sidebar?: boolean; children: ReactNode}> = ({
  illustration,
  children,
  sidebar,
}) => (
  <div
    data-layout={sidebar ? 'sidebar' : 'popup'}
    className={cn('layout', {sidebar, popup: !sidebar})}
  >
    <div className={styles.layoutIllustration}>{illustration}</div>
    <div className={styles.layoutContent}>
      <div className={styles.layoutContentReducer}>{children}</div>
    </div>
  </div>
);

export function WithIllustrationPopup({
  children,
  illustration,
  onBack,
  onClose,
  testId,
  popupId,
  ...restProps
}: Props): ReactElement {
  return (
    <Popup {...restProps} width="1000px" testId={testId} popupId={popupId}>
      <StandaloneControls onBack={onBack} onClose={onClose} background="tablet" />
      <Layout illustration={illustration}>{children}</Layout>
    </Popup>
  );
}

export function WithIllustrationSidebarRight({
  children,
  illustration,
  onBack,
  onClose,
  ...restProps
}: Props): ReactElement {
  return (
    <Popup {...restProps} appearance={sidebarRight} width="450px">
      <StandaloneControls onBack={onBack} onClose={onClose} background="always" />
      <Layout sidebar illustration={illustration}>
        {children}
      </Layout>
    </Popup>
  );
}
