import PropTypes from 'prop-types';
import React from 'react';
import TrackingCheckpointShape from 'shapes/TrackingCheckpoint';
import Checkpoint from './Checkpoint';

export default function TrackingCheckpoints({checkpoints, hasMore}) {
  if (!checkpoints || checkpoints.length === 0) {
    return null;
  }

  return (
    <div>
      {checkpoints.map((checkpoint, index) => (
        <Checkpoint
          // eslint-disable-next-line react/no-array-index-key
          key={checkpoint.location + checkpoint.timestamp + index}
          checkpoint={checkpoint}
          hasLine={hasMore || index !== checkpoints.length - 1}
        />
      ))}
    </div>
  );
}

TrackingCheckpoints.propTypes = {
  checkpoints: PropTypes.arrayOf(TrackingCheckpointShape.isRequired),
  hasMore: PropTypes.bool,
};

TrackingCheckpoints.defaultProps = {
  checkpoints: null,
  hasMore: false,
};
