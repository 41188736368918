import classnames from 'classnames/bind';
import {Locator} from 'components/Locator';
import {useStuckElement} from 'hooks/useStuckElement';
import React, {ReactElement, ReactNode} from 'react';
import {defineMessages, useIntl} from 'react-intl';

import {PopupUserActions} from '../types';
import BackIcon from './back.jsx.svg';
import CloseIcon from './close.jsx.svg';
import styles from './index.scss';

const cn = classnames.bind(styles);

type Props = PopupUserActions & {
  children?: ReactNode;
  transparent?: boolean;
  hasBoxShadow?: boolean;
  id?: string;
};

const messages = defineMessages({
  back: {
    description: '[label] Вернуться назад',
    defaultMessage: 'Go back',
  },
  close: {
    description: '[label] Закрыть',
    defaultMessage: 'Close',
  },
});

export function Header({
  children = null,
  onBack,
  onClose,
  hasBoxShadow = false,
  transparent,
  id,
}: Props): ReactElement {
  const intl = useIntl();
  const {stickyRef, isStuck} = useStuckElement<HTMLDivElement>({enabled: hasBoxShadow});

  return (
    <div
      ref={stickyRef}
      className={cn(styles.header, {
        transparent,
        hasBack: onBack,
        hasClose: onClose,
        isStuck,
        hasBoxShadow,
      })}
    >
      {onBack ? (
        <button
          type="button"
          className={styles.back}
          onClick={onBack}
          aria-label={intl.formatMessage(messages.back)}
        >
          <BackIcon aria-hidden />
        </button>
      ) : null}
      <h2 className={styles.content} id={id}>
        {children}
      </h2>
      {onClose ? (
        <Locator id="PopupCloseButton">
          <button
            type="button"
            className={styles.close}
            onClick={onClose}
            aria-label={intl.formatMessage(messages.close)}
          >
            <CloseIcon aria-hidden />
          </button>
        </Locator>
      ) : null}
    </div>
  );
}
