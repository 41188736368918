import {Image} from 'components/Image';
import {Locator} from 'components/Locator';
import {Icon} from 'components/UIKit/Icon';
import {useUniversalNavigate} from 'hooks/useUniversalNavigate';
import React, {useCallback} from 'react';
import {ParcelActionRow} from 'types/ParcelActionRow';

import styles from './index.scss';

// the design does not include an icon, but I'll just leave it turned off instead of removing
// just in case
const SHOW_ICON = false;

type Props = {
  actionRow: ParcelActionRow;
  'data-testid'?: string;
  'data-test-type'?: string;
};

export const ActionRow = ({
  actionRow: {action, title, subtitle, image},
  'data-testid': testId,
  'data-test-type': testType,
}: Props): JSX.Element | null => {
  const navigate = useUniversalNavigate();

  const handleClick = useCallback(() => {
    if ('deeplink' in action) {
      navigate(action?.deeplink.url);
    }
  }, [action, navigate]);

  return (
    <Locator id={testId} type={testType}>
      <button type="button" onClick={handleClick} className={styles.actionRow}>
        {SHOW_ICON && (
          <div className={styles.imageContainer}>
            <Image image={image} vwFit={{xs: '24px'}} />
          </div>
        )}
        <div className={styles.label}>
          <div className={styles.title}>{title}</div>
          {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
        </div>
        <div className={styles.chevron}>
          <Icon type="mono" name="chevron-right-linear-24" width={24} />
        </div>
      </button>
    </Locator>
  );
};
