import {Scope} from 'config';
import {ScopeConfig} from 'helpers/ApiClient/Scope/ScopeConfig';
import {HelmetProps} from 'react-helmet-async';
import {PageMetadata} from 'types/PageMetadata';
import {OpenGraph} from 'types/PageMetadata/OpenGraph';
import {createUrl} from 'utils/url';
import {deeplink} from 'utils/url/app';
import {enhanceTrackingParams} from 'utils/url/tracking';

import {ApiClient} from './ApiClient';

function createOpenGraph(metadata: PageMetadata): OpenGraph {
  return {
    title: metadata.title || '',
    description: metadata.description || '',
    url: metadata.canonical || '',
  };
}

function addLink(props: HelmetProps, link: JSX.IntrinsicElements['link']): void {
  if (props.link) {
    props.link.push(link);
  }
}

function addMeta(props: HelmetProps, meta: JSX.IntrinsicElements['meta']): void {
  if (props.meta) {
    props.meta.push(meta);
  }
}

function removePrefixScope(link: string | undefined, scope: ScopeConfig): string | undefined {
  if (typeof link === 'string' && scope.isDomain(Scope.GLOBAL) && scope.pathPrefix) {
    return link.replace(`/${scope.pathPrefix}`, '');
  }

  return link;
}

export function buildHelmetProps(
  metadata: PageMetadata,
  scope: ScopeConfig,
  client: ApiClient,
): HelmetProps {
  const result: HelmetProps = {
    link: [],
    meta: [],
    htmlAttributes: {},
  };

  if (metadata.title) {
    result.titleTemplate = '%s';
    result.title = metadata.title;
  }

  if (metadata.canonical) {
    addLink(result, {
      href: removePrefixScope(metadata.canonical, scope),
      rel: 'canonical',
    });
  }

  if (metadata.description) {
    addMeta(result, {
      content: metadata.description,
      name: 'description',
    });
  }

  if (metadata.relAlternate) {
    Object.keys(metadata.relAlternate).forEach((hrefLang) => {
      addLink(result, {
        rel: 'alternate',
        hrefLang,
        href: removePrefixScope(metadata.relAlternate![hrefLang], scope),
      });
    });
  }

  if (metadata.lang || metadata.dir) {
    if (!result.htmlAttributes) {
      result.htmlAttributes = {};
    }
    result.htmlAttributes.dir = metadata.dir;
    result.htmlAttributes.lang = metadata.lang;
  }

  if (metadata.robots) {
    addMeta(result, {
      content: metadata.robots,
      name: 'robots',
    });
  }

  if (typeof metadata.appTargetUrl === 'string' && scope.is(Scope.GLOBAL)) {
    if (scope.mobileApps?.ios) {
      addMeta(result, {
        property: 'al:ios:app_store_id',
        content: scope.mobileApps.ios.id,
      });

      // Smart App Banners metatag
      const tracking = enhanceTrackingParams(client.tracking.get(), {
        utm_device_id: client.device.getDeviceId(),
        utm_smart_banner: 'true',
      });
      const target = createUrl(metadata.appTargetUrl, tracking);

      addMeta(result, {
        name: 'apple-itunes-app',
        content: `app-id=${scope.mobileApps.ios.id}, app-argument=${target}`,
      });
    }

    if (scope.mobileApps?.android) {
      const target = deeplink(scope, metadata.appTargetUrl);

      addMeta(result, {
        property: 'al:ios:url',
        content: target,
      });
      addMeta(result, {
        property: 'al:android:url',
        content: target,
      });

      addMeta(result, {
        property: 'al:android:package',
        content: scope.mobileApps.android.id,
      });
    }
  }

  const openGraph = metadata.og || createOpenGraph(metadata);
  if (openGraph) {
    Object.keys(openGraph).forEach((property) => {
      if (openGraph[property]) {
        addMeta(result, {
          content: openGraph[property],
          property,
        });
      }
    });
  }

  if (metadata.search) {
    addLink(result, {
      rel: 'search',
      type: 'application/opensearchdescription+xml',
      href: metadata.search,
    });
  }

  return result;
}
