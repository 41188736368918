import {ImageBundle} from './Image';
import {PaymentMethodBadge} from './PaymentMethodBadge';

export enum PaymentMethodType {
  // virtual payment method to gather all bank cards
  CARDS = 'cards',

  APPLE_PAY = 'applePay',
  CARD = 'card',
  GOOGLE_PAY = 'googlePay',
  MBWAY = 'mbway',
  MULTIBANCO = 'multibanco',
  NEW_CARD = 'newCard',
  NEW_PAYPAL = 'newPayPal',
  POINTS = 'points',
  SAVED_PAYPAL = 'savedPayPal',
  WEB = 'web',
}

export type PaymentMethodBase<Type extends PaymentMethodType, Payload> = {
  type: Type;
  id: string;
  primary: boolean;
  inactive: boolean;
  appearance?: {
    title: string;
    subtitle?: string;
    image: ImageBundle;
    badge?: PaymentMethodBadge;
    color?: {rgb?: string};
  };
  payload: Payload;
};

// Apple Pay
export type ApplePayPaymentMethodPayload = {
  id: string;
  merchantId: string;
  countryCode: string;
  supportedNetworks: string[];
  separateCheckoutFlow?: boolean;
};

export type ApplePayPaymentMethod = PaymentMethodBase<
  PaymentMethodType.APPLE_PAY,
  ApplePayPaymentMethodPayload
>;

// Card
export type CardPaymentMethodPayload = {
  id: string;
  numberMask: string;
};

export type CardPaymentMethod = PaymentMethodBase<PaymentMethodType.CARD, CardPaymentMethodPayload>;

// Google Pay
export type GooglePayPaymentMethodPayload = {
  existingPaymentMethodRequired?: boolean;
  isReadyToPayRequest: google.payments.api.PaymentDataRequest;
};

export type GooglePayPaymentMethod = PaymentMethodBase<
  PaymentMethodType.GOOGLE_PAY,
  GooglePayPaymentMethodPayload
>;

// MBWay
export type MBWayPaymentPayload = unknown;

export type MBWayPaymentMethod = PaymentMethodBase<PaymentMethodType.MBWAY, MBWayPaymentPayload>;

// Multibanco
export type MultibancoPaymentMethod = PaymentMethodBase<PaymentMethodType.MULTIBANCO, unknown>;

// New Card
export type NewCardPaymentMethodPayload = {
  discountPreferences: {
    applicable: boolean;
    significantPrefixMinLength: number;
    significantPrefixMaxLength: number;
  };
};

export type NewCardPaymentMethod = PaymentMethodBase<
  PaymentMethodType.NEW_CARD,
  NewCardPaymentMethodPayload
>;

// New PayPal
export type NewPayPalPaymentPayload = {
  newPayPal: {
    saveCheckboxDefault: boolean;
    showSaveCheckbox: boolean;
  };
};

export type NewPayPalPaymentMethod = PaymentMethodBase<
  PaymentMethodType.NEW_PAYPAL,
  NewPayPalPaymentPayload
>;

// Points
export type PointsPaymentMethod = PaymentMethodBase<PaymentMethodType.POINTS, unknown>;

// Saved PayPal
export type SavedPayPalPaymentPayload = {
  payPalId: string;
};

export type SavedPayPalPaymentMethod = PaymentMethodBase<
  PaymentMethodType.SAVED_PAYPAL,
  SavedPayPalPaymentPayload
>;

// Web
export type WebPaymentMethodPayload = {
  id: string;
};

export type WebPaymentMethod = PaymentMethodBase<PaymentMethodType.WEB, WebPaymentMethodPayload>;

export type PaymentMethod =
  | ApplePayPaymentMethod
  | CardPaymentMethod
  | GooglePayPaymentMethod
  | MBWayPaymentMethod
  | MultibancoPaymentMethod
  | NewCardPaymentMethod
  | NewPayPalPaymentMethod
  | PointsPaymentMethod
  | SavedPayPalPaymentMethod
  | WebPaymentMethod;
