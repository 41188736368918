import {arrayToObject} from 'utils/array';
import {isRecord, isString} from 'utils/guards';

import {UploaderError, UploaderErrorType} from '../types';

const uploaderErrorTypesMap = arrayToObject(Object.values(UploaderErrorType));

export const isUploaderError = (error: unknown): error is UploaderError => {
  return isRecord(error) && isString(error.type) && error.type in uploaderErrorTypesMap;
};
