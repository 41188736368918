import {OnboardingTooltip} from 'components/OnboardingTooltip/loadable';
import {ThemeType} from 'components/Tooltip';
import React, {PropsWithChildren, ReactElement} from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';

import CouponIcon from './coupon.jsx.svg';
import styles from './CouponTooltip.scss';

const messages = defineMessages({
  coupon: {
    description: 'Onboarding tooltip on menu',
    defaultMessage: 'You can find coupons here',
  },
});

type Props = PropsWithChildren<{
  onClose?: () => void;
}>;

export function CouponTooltip({onClose, children}: Props): ReactElement {
  return (
    <OnboardingTooltip
      message={
        // eslint-disable-next-line react/jsx-wrap-multilines
        <div className={styles.tooltip}>
          <CouponIcon className={styles.icon} />
          <FormattedMessage {...messages.coupon} />
        </div>
      }
      onCloseClick={onClose}
      theme={ThemeType.DEFAULT}
      delayMs={300}
      round
      useBubble
      useForceReposition
      useCloseButton={false}
      shouldCloseOnOverlayClick={false}
      shouldNotOverlayWithPopups={false}
    >
      {children}
    </OnboardingTooltip>
  );
}
