import {ParcelPageCard, ParcelPageCardInner} from 'components/ParcelPageCard';
import {Preloader} from 'components/Preloader';
import {ProductSlider} from 'components/ProductSlider';
import PropTypes from 'prop-types';
import React, {memo} from 'react';
import {FormattedMessage} from 'react-intl';
import ErrorShape from 'shapes/Error';
import ProductLiteShape from 'shapes/ProductLite';

import {rootLocator} from 'utils/rootLocator';
import styles from './index.scss';

const locator = rootLocator.orderPage.productSlider;

export const RelatedProducts = memo((props) => {
  const {products, error, loading} = props;

  if (loading) {
    return (
      <div className={styles.preloader}>
        <Preloader />
      </div>
    );
  }

  if (!products || products.length === 0 || error) {
    return null;
  }

  const header = (
    <FormattedMessage
      description="Short button on address form"
      defaultMessage="Frequently bought together"
    />
  );
  return (
    <ParcelPageCard>
      <ParcelPageCardInner>
        <ProductSlider smallHeader products={products} header={header} {...locator()} />
      </ParcelPageCardInner>
    </ParcelPageCard>
  );
});

RelatedProducts.propTypes = {
  products: PropTypes.arrayOf(ProductLiteShape.isRequired),
  error: ErrorShape,
  loading: PropTypes.bool,
};

RelatedProducts.defaultProps = {
  products: [],
  error: null,
  loading: false,
};
