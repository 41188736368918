/* Response payload preparation */

export const prepareUnreadCountPayload = ({
  body: {
    payload: {count},
  },
}) => ({
  unreadCount: count,
});

export const prepareFiltersPayload = ({
  body: {
    payload: {items, totalUnreadCount},
  },
}) => ({
  items,
  unreadCount: totalUnreadCount,
});

export const prepareSettingsPayload = ({
  body: {
    payload: {items, nextPageToken},
  },
}) => ({
  items,
  nextPageToken,
});
