import React from 'react';

import {SplashMessageActions} from './Actions';
import {SplashMessageHR} from './Hr';
import {SplashMessageImage} from './Image';
import styles from './index.scss';
import {SplashMessageLink} from './Link';
import {SplashMessageText} from './Text';
import {SplashMessageTitle} from './Title';

export function SplashMessage({
  asPage,
  children,
}: {
  asPage?: boolean;
  children: React.ReactNode;
}): JSX.Element {
  return (
    <div className={`${styles.message} ${asPage ? styles.asPage : ''}`}>
      <div className={styles.content}>
        <div className={styles.inner}>{children}</div>
      </div>
    </div>
  );
}

SplashMessage.Actions = SplashMessageActions;
SplashMessage.HR = SplashMessageHR;
SplashMessage.Image = SplashMessageImage;
SplashMessage.Link = SplashMessageLink;
SplashMessage.Text = SplashMessageText;
SplashMessage.Title = SplashMessageTitle;
