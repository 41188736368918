import {ScopeConfig} from 'helpers/ApiClient/Scope/ScopeConfig';

import {BuildUrlFunc, Params} from './types';

export const getUrl = (
  scope: ScopeConfig,
  lang: string,
  categoryId: string | undefined,
  params: Params,
  buildUrl: BuildUrlFunc,
): string => {
  return buildUrl(scope, lang, {
    ...params,
    categoryId: categoryId || undefined,
  });
};
