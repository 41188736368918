import React from 'react';

import styles from './index.scss';

type FooterDebugProps = React.PropsWithChildren<{
  pageId?: string;
}>;

export function FooterDebug({children, pageId}: FooterDebugProps): JSX.Element {
  return (
    <div className={styles.debug}>
      {pageId && <div className={styles.debugPageId}>Page ID: {pageId}</div>}
      {React.Children.map(children, (child) => (
        <div className={styles.debugItem}>{child}</div>
      ))}
    </div>
  );
}
