import cn from 'classnames';
import {Image} from 'components/Image';
import React from 'react';
import {ColoredText} from 'types/ColoredText';
import {ImageBundle} from 'types/Image';
import {ContentItem} from 'types/Popup';
import {getInlineColor} from 'utils/styles/color';

import styles from './index.scss';

type Props = {
  iconList: ContentItem['iconList'];
};

const IconListRow = ({
  row,
}: {
  row: {title: ColoredText; subtitle?: ColoredText; image: ImageBundle};
}): JSX.Element => {
  const iconSize = row.subtitle ? '64px' : '24px';
  return (
    <li className={cn(styles.iconListRow, {[styles.hasSubtitle!]: !!row.subtitle})}>
      <span className={styles.icon}>
        <Image width={iconSize} height={iconSize} contain image={row.image} />
      </span>
      <span className={styles.captions}>
        <span className={styles.title} style={getInlineColor(row.title.color)}>
          {row.title.text}
        </span>
        {row.subtitle && (
          <span className={styles.subtitle} style={getInlineColor(row.subtitle?.color)}>
            {row.subtitle.text}
          </span>
        )}
      </span>
    </li>
  );
};

export const IconList = ({iconList}: Props): JSX.Element | null => {
  if (!iconList?.items) {
    return null;
  }

  return (
    <ul className={styles.iconList}>
      {iconList.items.map?.((row, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <IconListRow row={row} key={index} />
      ))}
    </ul>
  );
};
