import Button from 'components/WhiteList/Button';
import {createLocator} from 'create-locator';
import {deprecated} from 'utils/propTypes/deprecated';
import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';
import Caption from './Caption';
import ToggleHeader from './ToggleHeader';
import Header from './Header';
import Item from './Item';
import Select from './Select';
import Subheader from './Subheader';
import {HeaderClose} from './HeaderClose';
import styles from './index.scss';

const WhiteList = React.memo(
  React.forwardRef(function WhiteList(
    {children, className, testId, accentBackground, background, fullWidth, ...restProps},
    ref,
  ) {
    if (!children) {
      return null;
    }

    const locator = createLocator(restProps);

    const style = background ? {background} : {};

    return (
      <div
        ref={ref}
        className={cn(styles.whiteList, className, {
          [styles.accentBackground]: accentBackground,
          [styles.fullWidth]: fullWidth,
        })}
        style={style}
        {...restProps}
        {...locator()}
      >
        {children}
      </div>
    );
  }),
);

WhiteList.propTypes = {
  className: deprecated(PropTypes.string, 'Use wrapper or add new prop.'),
  children: PropTypes.node,
  testId: PropTypes.string,
  accentBackground: PropTypes.bool,
  background: PropTypes.string,
  fullWidth: PropTypes.bool,
};

WhiteList.defaultProps = {
  className: '',
  children: null,
  testId: '',
  accentBackground: false,
  background: '',
  fullWidth: false,
};

WhiteList.Button = Button;
WhiteList.Header = Header;
WhiteList.HeaderClose = HeaderClose;
WhiteList.ToggleHeader = ToggleHeader;
WhiteList.Caption = Caption;
WhiteList.Item = Item;
WhiteList.Select = Select;
WhiteList.Subheader = Subheader;

export default WhiteList;
