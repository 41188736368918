import PropTypes from 'prop-types';

export default PropTypes.shape({
  id: PropTypes.string.isRequired,
  metainfoId: PropTypes.string.isRequired,
  fields: PropTypes.shape({
    lastName: PropTypes.string,
    firstName: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,

    street: PropTypes.string,
    street1: PropTypes.string,
    street2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    country: PropTypes.string,
    zip: PropTypes.string,
    block: PropTypes.string,
    building: PropTypes.string,
    house: PropTypes.string,
    flat: PropTypes.string,
  }).isRequired,
  description: PropTypes.shape({
    address: PropTypes.string,
    contact: PropTypes.string,
    full: PropTypes.string,
    short: PropTypes.string,
  }).isRequired,

  // client adds this props via AddressItem shape on checkout flow
  isDisabled: PropTypes.bool, // opposite for AddressItem.isEnabled
  isReadonly: PropTypes.bool, // opposite for AddressItem.isEditable
  isUnremovable: PropTypes.bool, // opposite for AddressItem.isRemovable
});
