import {Color} from 'types/Color';
import {ColoredText} from 'types/ColoredText';
import {Gradient} from 'types/Gradient';

export enum VerticalPosition {
  BOTTOM = 'bottom',
  TOP = 'top',
}

export enum HorizontalPosition {
  END = 'end',
  START = 'start',
}

export enum CornerPosition {
  BOTTOM_END = 'bottomEnd',
  BOTTOM_START = 'bottomStart',
  TOP_START = 'topStart',
  TOP_END = 'topEnd',
}

export type Header = {
  action?: string;
  title: string;
  url?: string;
};

export type Button = {
  text: string;
  textColor?: Color;
  backgroundGradient?: Gradient;
  url?: string;
};

export type TimerRaw = {
  timeRemainingMs: number;
  digitBackgroundColor?: Color;
  digitColor?: Color;
  text?: ColoredText;
};

export type Timer = TimerRaw & {
  expirationTimeMs: number;
  isTimeRemainingHidden?: boolean;
};
