import products from './products';
import productsRecent from './productsRecent';
import suggest from './suggest';

export default {
  products,
  productsRecent,
  suggest,
};

export {
  buildSearchRequest,
  getSearchCategory,
  getSearchCategoryView,
  getSearchFiltersAndSortings,
  getSearchHeaderItems,
  getSearchState,
  getSearchHeader,
  getSearchHeaderWasLoadedAtLeastOnce,
  getSearchProducts,
  getSearchProductsEngine,
  getSearchProductsError,
  getSearchProductsNextPageToken,
  getSubCategory,
  hasSearchCategories,
  isSearchProductsEmpty,
  isSearchProductsLoaded,
  isSearchProductsLoading,
  isSearchProductsOutdated,
  needToUseSearchCategories,
  searchProducts,
  setSearchProductsEngine,
} from './products';

export {
  getSearchSuggest,
  getSearchSuggestError,
  isSearchSuggestLoaded,
  isSearchSuggestLoading,
  loadSearchSuggest,
} from './suggest';

export {
  deleteProductsRecent,
  getProductsRecent,
  getProductsRecentError,
  isProductsRecentLoaded,
  isProductsRecentLoading,
  loadProductsRecent,
} from './productsRecent';
