import {SliderElement, SliderElements, SliderElementsSelected} from './SliderElementsContext';

export const ROUND_ERROR = 2;

export enum SearchDirection {
  LEFT,
  RIGHT,
}

export function prepareDotsState(
  prevSelected: SliderElementsSelected,
  entries: IntersectionObserverEntry[],
  elements: SliderElements,
  threshold: number,
): SliderElementsSelected {
  if (elements.length <= 1) {
    return [];
  }

  const nextSelected: SliderElementsSelected = prevSelected.slice();

  entries.forEach((entry) => {
    for (let idx = 0; idx < elements.length; idx += 1) {
      if (entry.target === elements[idx]!.current) {
        nextSelected[idx] = entry.intersectionRatio >= threshold;
        break;
      }
    }
  });

  return nextSelected;
}

export function getNearestOutsideElement(
  content: HTMLElement,
  elements: SliderElements,
  direction = SearchDirection.LEFT,
  loopEnabled: boolean,
): [SliderElement | null, boolean] {
  const {left: contentLeft, right: contentRight} = content.getBoundingClientRect();
  let result: SliderElement | null = null;
  let nearest = true;

  if (direction === SearchDirection.LEFT) {
    let nearestLeft = -Infinity;
    let maxRight = -Infinity;
    let lastElement: SliderElement | null = null;

    elements.forEach((element) => {
      if (element.current) {
        const rect = element.current.getBoundingClientRect();

        if (contentLeft - rect.left > ROUND_ERROR && nearestLeft < rect.left) {
          nearestLeft = rect.left;
          result = element;
        }

        if (loopEnabled && maxRight < rect.right) {
          maxRight = rect.right;
          lastElement = element;
        }
      }
    });

    if (!result && lastElement) {
      result = lastElement;
      nearest = false;
    }
  } else {
    let nearestRight = +Infinity;
    let minLeft = +Infinity;
    let firstElement: SliderElement | null = null;

    elements.forEach((element) => {
      if (element.current) {
        const rect = element.current.getBoundingClientRect();

        if (rect.right - contentRight > ROUND_ERROR && nearestRight > rect.right) {
          nearestRight = rect.right;
          result = element;
        }

        if (loopEnabled && minLeft > rect.left) {
          minLeft = rect.left;
          firstElement = element;
        }
      }
    });

    if (!result && firstElement) {
      result = firstElement;
      nearest = false;
    }
  }

  return [result, nearest];
}

export function getLeftScrollRatio(el?: HTMLElement | null): number {
  return el ? el.scrollLeft / el.scrollWidth : 0;
}
