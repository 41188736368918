import {createSelectorFactory, createStateGetter} from 'store/utils/selectors';

const selector = createSelectorFactory(createStateGetter('popup'));

const getCurrentPopup = selector(({state}) => state.popupData);

const isPopupLoading = selector(({state}) => state.loading);
const isPopupLoaded = selector(({state}) => state.loaded);

const getShareData = selector(({state}) => state.shareData);
const isShareLoading = selector(({state}) => state.shareLoading);
const getShareError = selector(({state}) => state.shareError);

export {
  getCurrentPopup,
  getShareData,
  getShareError,
  isPopupLoading,
  isPopupLoaded,
  isShareLoading,
};
