import PropTypes from 'prop-types';
import ImageBundleShape from 'shapes/ImageBundle';

export const UserShape = PropTypes.shape({
  id: PropTypes.string.isRequired,

  anonymous: PropTypes.bool,
  ephemeral: PropTypes.bool,
  pointsDisabled: PropTypes.bool,

  fullName: PropTypes.string,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  avatar: ImageBundleShape,
  email: PropTypes.string,
  gender: PropTypes.oneOf(['male', 'female', 'unknown']),
  isPremium: PropTypes.bool,

  followingCount: PropTypes.number,

  facebookId: PropTypes.string,
  googleId: PropTypes.string,
  okId: PropTypes.string,
  socialUserId: PropTypes.string,
  vkId: PropTypes.string,
  referralProgramParticipant: PropTypes.bool,
  passportRequired: PropTypes.bool,
});
