import classnames from 'classnames/bind';
import {type Locator, type Mark, createLocator} from 'create-locator';
import React from 'react';
import {ProductBrand} from 'types/ProductBrand';
import {ProductLiteBrand} from 'types/ProductLiteBrand';

import styles from './index.scss';

const cn = classnames.bind(styles);

export type BrandBadgeLocator = Locator<void>;

type Props = {
  brand?: ProductBrand | ProductLiteBrand;
} & Mark<BrandBadgeLocator>;

export function BrandBadge(props: Props): JSX.Element {
  const {brand} = props;
  const locator = createLocator(props);

  const name = brand?.name || 'BRAND';
  return (
    <div className={cn('badge', 'brandBadge')} title={name} {...locator()}>
      {name}
    </div>
  );
}
