import {BannerWrapper} from 'components/ContentList/BannerWrapper';
import {Image} from 'components/Image';
import {Locator} from 'components/Locator';
import {Square} from 'components/Square';
import React, {Ref} from 'react';
import {SideBannerGroupBanner} from 'types/ContentList/SideBannerGroup';

import {Button} from '../../Button';
import styles from './index.scss';
import {Text} from './Text';

type Props = {
  banner: SideBannerGroupBanner;
  buttonRef: Ref<HTMLButtonElement>;
  bannerRef: Ref<HTMLDivElement>;
  source?: string;
};

export function TwoLineContent({banner, buttonRef, bannerRef, source}: Props): React.ReactElement {
  const {button, image, payload} = banner;
  const textVisible = Boolean(banner.title || banner.subtitle);
  const textInCenter = Boolean(button);

  return (
    <Locator id="TwoLinesContentBanner">
      <div className={styles.twoLineContent}>
        <Locator id="TwoLinesContentBannerWrapper">
          <BannerWrapper
            ref={bannerRef}
            stretch
            to={(payload && payload.url) || ''}
            source={source}
          >
            <div className={styles.inner}>
              <div className={styles.centerContent}>
                <div className={styles.imageWrap}>
                  <div className={styles.imageSquare}>
                    <Square>
                      <Image className={styles.image} contain image={image} loadingHidden />
                    </Square>
                  </div>
                </div>
                {textInCenter && textVisible && <Text banner={banner} />}
              </div>
              <div className={styles.sideContent}>
                {!textInCenter && textVisible && <Text banner={banner} />}
                {button && <Button ref={buttonRef} button={button} />}
              </div>
            </div>
          </BannerWrapper>
        </Locator>
      </div>
    </Locator>
  );
}
