import {EndpointsConfig} from 'helpers/ApiClient/Device';
import {useApiClient} from 'hooks/useApiClient';
import {useIntersectionObserverRef} from 'hooks/useIntersectionObserverRef';
import React, {ReactElement, useCallback, useRef, useState} from 'react';

export function ForcedEndpointsConfig(): ReactElement {
  const client = useApiClient();
  const ref = useRef<HTMLSelectElement>(null);
  const [preset, setPreset] = useState(() => client.device.getEndpointsConfigPreset());
  const [endpointsConfig, setEndpointsConfig] = useState<EndpointsConfig | undefined>(
    client.device.state.endpointsConfig,
  );

  const handleLoad = useCallback(
    (entry: IntersectionObserverEntry) => {
      if (entry.isIntersecting && !endpointsConfig) {
        client.device.getEndpointsConfig().then((config) => setEndpointsConfig(config));
      }
    },
    [endpointsConfig, client.device],
  );

  useIntersectionObserverRef(handleLoad, {
    targetRef: ref,
  });

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const nextPreset = event.target.value;
      setPreset(nextPreset);
      client.device.setEndpointsConfigPreset(nextPreset);
    },
    [client.device],
  );

  return (
    <select
      ref={ref}
      name="forcedEndpointsConfig"
      value={preset}
      onChange={handleChange}
      style={{width: '150px'}}
    >
      <option value="">Default API</option>
      {endpointsConfig?.map(({id, title}) => (
        <option value={id} key={id}>
          {title}
        </option>
      ))}
    </select>
  );
}
