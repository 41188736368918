import {Switch} from 'components/UIKit/Switch';
import {useAnalytics} from 'hooks/useAnalytics';
import {useSnowContext} from 'providers/SnowContext';
import React, {useCallback} from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';

import styles from './index.scss';

const messages = defineMessages({
  buttonText: {
    description: 'Текст кнопки, регулирующей анимацию снега',
    defaultMessage: 'Let it snow',
  },
});

export function Switcher(): JSX.Element {
  const analytics = useAnalytics();
  const {isSnowShown, toggleSnow} = useSnowContext();

  const handleToggle = useCallback(() => {
    const updated = !isSnowShown;
    toggleSnow(updated);
    analytics.sendEvent({type: 'christmasSnowToggleClick', payload: {enabled: updated}});
  }, [analytics, isSnowShown, toggleSnow]);

  return (
    <div className={styles.container}>
      <button onClick={handleToggle} type="button" className={styles.button}>
        ❄️&nbsp;
        <FormattedMessage {...messages.buttonText} />
        &nbsp;
      </button>
      <Switch id="snow" onToggle={handleToggle} size="small" checked={isSnowShown} />
    </div>
  );
}
