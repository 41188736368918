import {trueFunction} from 'utils/function';

import {promiseTimeout} from './promiseTimeout';

type Options = {
  checkError?: (error: unknown) => boolean;
  retriesLimit: number;
  retryTimeout?: number;
};

export const promiseRetry = <Response = void>(
  handle: () => Promise<Response>,
  {checkError = trueFunction, retriesLimit, retryTimeout}: Options,
): Promise<Response> => {
  let retry = 1;

  const handleRetry = (): Promise<Response> => {
    return handle().catch((error) => {
      if (checkError(error) && retry <= retriesLimit) {
        retry += 1;

        if (retryTimeout) {
          return promiseTimeout(retryTimeout).then(handleRetry);
        }

        return handleRetry();
      }

      throw error;
    });
  };

  return handleRetry();
};
