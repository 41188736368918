import {snowUserData} from 'components/Snow/storage';
import {useApiClient} from 'hooks/useApiClient';
import {useEffectOnce} from 'hooks/useEffectOnce';
import React, {ReactNode, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {voidFunction} from 'utils/function';

type SnowContextType = {
  isSnowShown?: boolean;
  toggleSnow: React.Dispatch<React.SetStateAction<boolean>>;
};

export const SnowContext = React.createContext<SnowContextType>({
  isSnowShown: undefined,
  toggleSnow: voidFunction,
});

type Props = {children: ReactNode};

export function useSnowContext(): SnowContextType {
  const context = useContext(SnowContext);
  return context;
}

export const SnowProvider: React.FC<Props> = ({children}: Props) => {
  const client = useApiClient();
  const snowData = useMemo(() => snowUserData(client), [client]);

  const [isSnowShown, toggleSnow] = useState(false);

  const prevToggled = useRef(isSnowShown);

  useEffectOnce(() => {
    const isSnowShown = snowData.isInitiallyShown();
    toggleSnow(isSnowShown);
  });

  useEffect(() => {
    if (prevToggled.current !== isSnowShown) {
      snowData.setChoice(isSnowShown);
    }

    prevToggled.current = isSnowShown;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSnowShown]);

  const context = useMemo(() => ({isSnowShown, toggleSnow}), [isSnowShown]);

  return <SnowContext.Provider value={context}>{children}</SnowContext.Provider>;
};

SnowProvider.displayName = 'SnowProvider';
