import {PopupType} from 'components/UIKit/Popup/PopupProvider';
import {Scope} from 'config';
import {useAdultPopup} from 'containers/AdultPopup';
import {eventParamsList} from 'helpers/eventParams';
import {useLanguage} from 'hooks/useLanguage';
import {usePreviewEvent} from 'hooks/usePreviewEvent';
import {useProductUrl} from 'hooks/useProductUrl';
import {useScope} from 'hooks/useScope';
import {useUserAdulthood} from 'hooks/useUserAdulthood';
import {KeepDeepLinkContext} from 'providers/DeepLinks';
import {RefObject, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {getUrl} from 'routes';
import type {AnalyticsEvent} from 'types/AnalyticsEvent';
import {ProductLite} from 'types/ProductLite';
import {getIsSensitiveProductShown} from 'utils/sensitiveContent';

export function useProductLiteAdultPopup(product: ProductLite): PopupType | undefined {
  const isUserAdult = useUserAdulthood();
  const showAdult = Boolean(!isUserAdult && product.forAdults);

  const adultPopup = useAdultPopup();
  return showAdult ? adultPopup : undefined;
}

export function useProductLiteShouldShowSensitive(product: ProductLite): boolean {
  const [shouldShowSensitive, setShouldShowSensitive] = useState(false);

  useEffect(
    () =>
      setShouldShowSensitive(
        Boolean(product.isSensitive && !getIsSensitiveProductShown(product.id)),
      ),
    [product.id, product.isSensitive],
  );

  return shouldShowSensitive;
}

export function useProductLiteUrl({
  product,
  product: {context, openPayload},
}: {
  product: ProductLite;
}): {
  handleInteraction(): void;
  url: string;
} {
  const keepDeepLink = useContext(KeepDeepLinkContext);
  const language = useLanguage();
  const scope = useScope();

  const [interactionEnabled, setInteractionEnabled] = useState(false);
  const hasAdditionalPayload = Boolean(context || openPayload);

  const baseUrl = useMemo(() => {
    return keepDeepLink && scope.is(Scope.GLOBAL) && scope.mobileApps
      ? `${scope.mobileApps.android.scheme}://products/${product.id}`
      : getUrl('product', {
          productId: product.id,
          lang: language,
          scope,
        });
  }, [language, scope, keepDeepLink, product.id]);
  const urlParams = useMemo(
    () => (hasAdditionalPayload && interactionEnabled ? {context, openPayload} : undefined),
    [context, hasAdditionalPayload, interactionEnabled, openPayload],
  );

  const url = useProductUrl(baseUrl, urlParams);

  const handleInteraction = useCallback(() => {
    if (hasAdditionalPayload) {
      setInteractionEnabled(true);
    }
  }, [hasAdditionalPayload]);

  return {handleInteraction, url};
}

export function useProductLiteAnalytics({
  product,
  rootRef,
  bodyRef = rootRef,
  buttonRef,
}: {
  product: ProductLite;
  rootRef: RefObject<HTMLElement>;
  bodyRef?: RefObject<HTMLElement>;
  buttonRef?: RefObject<HTMLElement>;
}): void {
  const analyticsContext = useMemo(
    () => ({
      productId: product.id,
      productContext: product.context,
      discountShown: Boolean(product.discount),
    }),
    [product.discount, product.context, product.id],
  );

  const previewEvent = useMemo((): AnalyticsEvent => {
    return {
      type: 'productPreview',
      payload: {
        productId: analyticsContext.productId,
        discountShown: analyticsContext.discountShown,
        context: analyticsContext.productContext?.value[0] || null,
      },
      params: eventParamsList(product.eventParams),
    };
  }, [
    product.eventParams,
    analyticsContext.discountShown,
    analyticsContext.productContext,
    analyticsContext.productId,
  ]);

  const clickData = useMemo(() => {
    const createClickEvent = (source: 'body' | 'buyButton') => ({
      type: 'productClick' as const,
      payload: {
        productId: analyticsContext.productId,
        discountShown: analyticsContext.discountShown,
        source,
        screenType: 'full' as const,
      },
      params: eventParamsList(product.eventParams),
    });

    return [
      {
        clickRef: bodyRef,
        clickEvent: createClickEvent('body'),
      },
      {
        clickRef: buttonRef,
        clickEvent: createClickEvent('buyButton'),
      },
    ];
  }, [
    analyticsContext.discountShown,
    analyticsContext.productId,
    bodyRef,
    buttonRef,
    product.eventParams,
  ]);

  usePreviewEvent({rootRef, previewEvent}, clickData, {
    sendPreviewDimensions: true,
  });
}
