import {RootState} from 'store/rootReducer';

function getState(globalState: RootState) {
  return globalState.cartCount;
}

export function isCartCountLoaded(globalState: RootState): boolean {
  return Boolean(getState(globalState).loaded);
}

export function getCartCount(globalState: RootState): number | null {
  return isCartCountLoaded(globalState) ? getState(globalState).count : null;
}
