import {useMountedRef} from 'hooks/useMounted';
import {ReactElement, useEffect, useState} from 'react';
import {cancelAnimationFrame, requestAnimationFrame} from 'utils/raf';
import {shallowDiffers} from 'utils/shallowDiffers';

type PropsHolderKeys = 'children' | 'hold' | 'skipRenderWhileHolding';

type Props<T extends Record<string, unknown>> = {
  hold: boolean;
  children: (
    props: Omit<Props<T>, PropsHolderKeys>,
  ) => ReactElement<Omit<Props<T>, PropsHolderKeys>> | null;
  skipRenderWhileHolding?: boolean;
} & T;

export function PropsHolder<T extends Record<string, unknown>>({
  children,
  hold = false,
  skipRenderWhileHolding = false,
  ...restProps
}: Props<T>): ReactElement<Omit<Props<T>, PropsHolderKeys>> | null {
  const mountedRef = useMountedRef(true);
  const [childProps, setChildProps] = useState<Omit<Props<T>, PropsHolderKeys>>(restProps);
  const [holdedChildren, setHoldedChildren] = useState<ReactElement<
    Omit<Props<T>, PropsHolderKeys>
  > | null>(() => (skipRenderWhileHolding ? children(childProps) : null));

  useEffect(() => {
    if (mountedRef.current && !hold && shallowDiffers(childProps, restProps)) {
      const timer = requestAnimationFrame(() => {
        setChildProps(restProps);
        if (skipRenderWhileHolding) {
          setHoldedChildren(children(restProps));
        }
      });
      return () => cancelAnimationFrame(timer);
    }
    return undefined;
  });

  return skipRenderWhileHolding ? holdedChildren : children(childProps);
}
