import {ModifierArguments, ModifierPhases} from '@popperjs/core';
import classnames from 'classnames/bind';
import {ConditionalWrapper} from 'components/ConditionalWrapper';
import {Locator} from 'components/Locator';
import {Portal} from 'components/Portal';
import {useDeviceVar} from 'hooks/useDeviceVars';
import {useOnClickOutside} from 'hooks/useOnClickOutside';
import {useUserAgent} from 'hooks/useUserAgent';
import mousetrap from 'mousetrap';
import maxSize from 'popper-max-size-modifier';
import React, {HTMLAttributes, ReactNode, useCallback, useEffect, useRef, useState} from 'react';
import {usePopper} from 'react-popper';
import {UserAgent} from 'types/UserAgent';
import {isElementOutOfViewport} from 'utils/dom';
import {DEFAULT_MODIFIERS, ROOT_MARGIN} from 'utils/popper';

import styles from './index.scss';
import {Item} from './Item';
import {Scroll} from './Scroll';

const cn = classnames.bind(styles);

export enum ContextMenuPlacement {
  BOTTOM = 'bottom',
  TOP = 'top',
}

const MIN_MENU_SIZE_PX = 200;

type applyMaxSizeOptions = Record<string, unknown>;
const applyMaxSizeModifier = {
  name: 'applyMaxSize',
  enabled: true,
  phase: 'beforeWrite' as ModifierPhases,
  requires: ['maxSize'],
  fn: ({state}: ModifierArguments<applyMaxSizeOptions>) => {
    const {
      applyMaxSize: {menuElement},
      maxSize: {height},
    } = state.modifiersData;
    let maxHeight = Math.min(height - ROOT_MARGIN, window.innerHeight / 2);
    maxHeight = Math.max(MIN_MENU_SIZE_PX, maxHeight);
    menuElement.style.maxHeight = `${maxHeight}px`;
  },
};

type ContextMenuItem = {
  key: string;
  value: string;
  disabled?: boolean;
  selected?: boolean;
  title?: string;
} & HTMLAttributes<HTMLElement>;

type Props = {
  triggerRefs?: React.RefObject<HTMLElement>[];
  referenceElement?: HTMLElement | null;
  children: React.ReactNode;
  hidden?: boolean;
  id?: string;
  placement?: ContextMenuPlacement;
  onClose?: () => void;
  onClick?: (value: string) => void;
  items?: ContextMenuItem[];
  widthByContent?: boolean;
  noPaddings?: boolean;
  disableFlip?: boolean;
  applyMaxSize?: boolean;
  usePortal?: boolean;
  'data-testid'?: string;
};

const ContextMenuItem = React.memo(
  ({item, onClick}: {item: ContextMenuItem; onClick: (value: string) => void}) => {
    const handleClick = useCallback(() => {
      onClick(item.value);
    }, [item.value, onClick]);

    if (!item) {
      return null;
    }

    return (
      <Item
        disabled={!!item.disabled}
        key={item.key || item.value}
        onClick={handleClick}
        selected={!!item.selected}
      >
        {item.title || item.value}
      </Item>
    );
  },
);

function canApplyMaxSize(userAgent: UserAgent) {
  // TODO(w84v2rhsq4@): check if the bug is still applicable after Safari updates WEB-2803
  // (the latest reproduce occurs in 14.0.2 on Big Sur)
  const {name} = userAgent.browser;
  return name !== 'Safari' && name !== 'Mobile Safari';
}

export const ContextMenu = ({
  referenceElement = null,
  children,
  hidden = false,
  id,
  placement = ContextMenuPlacement.BOTTOM,
  onClose,
  onClick,
  items,
  widthByContent = false,
  noPaddings = false,
  disableFlip = false,
  applyMaxSize = false,
  usePortal = true,
  triggerRefs = [],
  'data-testid': testId,
}: Props): React.ReactElement | null => {
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);
  const [menuElement, setMenuElement] = useState<HTMLDivElement | null>(null);
  const anchorRef = useRef<HTMLDivElement>(null);
  const userAgent = useUserAgent();
  const renderHiddenContextMenu = useDeviceVar('renderHiddenContextMenu');

  const reference = referenceElement || (anchorRef.current && anchorRef.current.parentElement);

  const applyMaxSizeEnabled = applyMaxSize && canApplyMaxSize(userAgent);
  const popper = usePopper(reference, popperElement, {
    placement,
    modifiers: [
      ...DEFAULT_MODIFIERS,
      {
        name: 'arrow',
        options: {element: arrowElement},
      },
      maxSize,
      {
        ...applyMaxSizeModifier,
        data: {menuElement},
        enabled: applyMaxSizeEnabled,
      },
      {
        name: 'flip',
        enabled: !disableFlip,
      },
    ],
  });
  const {styles: popperStyles, state, update: popperUpdate} = popper;

  const handleClose = useCallback(() => {
    if (onClose) {
      onClose();
    }
    mousetrap.unbind('esc');
  }, [onClose]);

  const handleObserveReference: IntersectionObserverCallback = useCallback(
    (entries) => {
      const {target} = entries[0]!;
      if (isElementOutOfViewport(target)) {
        handleClose();
      }
    },
    [handleClose],
  );

  useEffect(() => {
    const refObserver = new window.IntersectionObserver(handleObserveReference, {});
    if (popperElement) {
      refObserver.observe(popperElement);
    }
    if (!hidden) {
      mousetrap.bind('esc', handleClose);
    }

    return () => {
      mousetrap.unbind('esc');
      refObserver.disconnect();
    };
  }, [handleClose, handleObserveReference, hidden, popperElement]);

  useEffect(() => {
    if (!popperUpdate) {
      return;
    }
    popperUpdate().then(() => {
      if (hidden) {
        handleClose();
      } else {
        mousetrap.bind('esc', handleClose);
      }
    });
  }, [handleClose, hidden, popperUpdate]);

  const content =
    !items || !onClick
      ? children
      : items.map((item) => <ContextMenuItem item={item} onClick={onClick} key={item.key} />);

  const portalWrapper = useCallback(
    (innerChildren: ReactNode) => <Portal stopPropagation={['onClick']}>{innerChildren}</Portal>,
    [],
  );

  useOnClickOutside([popperElement, ...triggerRefs], handleClose);

  if (hidden && !renderHiddenContextMenu) {
    return null;
  }

  return (
    <div ref={anchorRef}>
      <ConditionalWrapper condition={usePortal} wrapper={portalWrapper}>
        <div
          id={id}
          ref={setPopperElement}
          style={popperStyles.popper}
          className={cn('contextMenu', state && state.placement, {
            hidden,
            noPaddings,
          })}
        >
          <Locator id={testId}>
            <div
              role="menu"
              ref={setMenuElement}
              className={cn('menu', {widthByContent, applyMaxSize})}
            >
              {content}
            </div>
          </Locator>
          <div ref={setArrowElement} style={popperStyles.arrow} className={styles.corner}>
            <div className={styles.cornerInner} />
          </div>
        </div>
      </ConditionalWrapper>
    </div>
  );
};

ContextMenu.Item = Item;
ContextMenu.Scroll = Scroll;
