import {useScope} from 'hooks/useScope';
import React from 'react';

import {AppGalleryBadge} from './AppGalleryBadge';
import {AppStoreBadge} from './AppStoreBadge';
import {GooglePlayBadge} from './GooglePlayBadge';
import styles from './index.scss';
import {RuStoreBadge} from './RuStoreBadge';

type Props = {
  deepUrl?: string;
  eventCategory: string;
  small?: boolean;
};

export function Badges({deepUrl = '', eventCategory, small = false}: Props): JSX.Element {
  const scope = useScope();

  return (
    <div className={styles.inner}>
      <AppStoreBadge deepUrl={deepUrl} eventCategory={eventCategory} small={small} />
      <GooglePlayBadge deepUrl={deepUrl} eventCategory={eventCategory} small={small} />
      <AppGalleryBadge deepUrl={deepUrl} eventCategory={eventCategory} small={small} />
      {scope.isJmt && (
        <RuStoreBadge deepUrl={deepUrl} eventCategory={eventCategory} small={small} />
      )}
    </div>
  );
}
