import React from 'react';
import {defineMessages, FormattedMessage, useIntl} from 'react-intl';
import {Price} from 'types/Price';

type Props = {
  pointAmountMoney: Price;
};

const messages = defineMessages({
  pointsMoney: {
    defaultMessage: `{formattedMoney} in points`,
    description: 'Строка с количеством баллов в валюте. Используется, например, рядом с ценой.',
  },
});

export function PointAmount({pointAmountMoney}: Props): JSX.Element {
  const intl = useIntl();
  return (
    <FormattedMessage
      {...messages.pointsMoney}
      values={{
        formattedMoney: intl.formatNumber(pointAmountMoney.amount, {
          style: 'currency',
          currency: pointAmountMoney.currency,
          minimumFractionDigits: pointAmountMoney.amount % 1 === 0 ? 0 : 2,
          maximumFractionDigits: pointAmountMoney.amount % 1 === 0 ? 0 : 2,
        }),
      }}
    />
  );
}
