import {type Locator, type Mark, createLocator} from 'create-locator';
import React, {ReactNode} from 'react';

import styles from './index.scss';

export type SectionHeaderLocator = Locator<void>;

type Props = {children: ReactNode} & Mark<SectionHeaderLocator>;

export const SectionHeader = React.memo(function SectionHeader(props: Props) {
  const {children} = props;
  const locator = createLocator(props);
  return (
    <div className={styles.sectionHeader} {...locator()}>
      {children}
    </div>
  );
});
