import {ApiClient} from 'helpers/ApiClient';

const STORAGE_KEY = 'snowflakes';

export function snowUserData(client: ApiClient): {
  setChoice: (choice: boolean) => void;
  choiceExists: () => boolean;
  increaseViewingTime: (timeDelta: number) => void;
  getViewingTime: () => number;
  setHiddenByTimeout: (hidden: boolean) => void;
  isHiddenByTimeout: () => boolean;
  isInitiallyShown: () => boolean;
  removeTimer: () => void;
} {
  const storage = client.storages.local;

  function parse() {
    const record = storage.getItem(STORAGE_KEY) as string;

    try {
      return JSON.parse(record);
    } catch {
      return {};
    }
  }

  function setValue(value: string) {
    storage.setItem(STORAGE_KEY, JSON.stringify(value));
  }

  function setChoice(choice: boolean): void {
    const value = parse();
    value.choice = choice;
    value.time = undefined;
    setValue(value);
  }

  function getChoice(): boolean | undefined {
    const value = parse();
    return value.choice;
  }

  function choiceExists(): boolean {
    return typeof getChoice() !== 'undefined';
  }

  function increaseViewingTime(timeDelta: number) {
    const value = parse();

    const {time = ''} = value;
    const timeNum = parseInt(time, 10);
    if (!Number.isNaN(timeNum)) {
      value.time = time + timeDelta;
    } else {
      value.time = timeDelta;
    }
    setValue(value);
  }

  function getViewingTime(): number {
    const value = parse();

    return value.time || 0;
  }

  function setHiddenByTimeout(hidden: boolean): void {
    const value = parse();

    value.hidden = hidden;
    setValue(value);
  }

  function isHiddenByTimeout(): boolean {
    const value = parse();

    if (typeof value.hidden === 'boolean') {
      return value.hidden;
    }
    return false;
  }

  function isInitiallyShown() {
    const choice = getChoice();
    if (typeof choice !== 'undefined') {
      return choice;
    }

    return !isHiddenByTimeout();
  }

  function removeTimer() {
    const value = parse();
    value.time = undefined;
    setValue(value);
  }

  return {
    setChoice,
    choiceExists,
    increaseViewingTime,
    getViewingTime,
    setHiddenByTimeout,
    isHiddenByTimeout,
    isInitiallyShown,
    removeTimer,
  };
}
