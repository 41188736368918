import {RefObject, useEffect, useMemo} from 'react';

type Common = RefObject<HTMLElement> | HTMLElement | null;

export function useOnClickOutside(
  refs: Common | Common[],
  handler: ((event: MouseEvent | TouchEvent | KeyboardEvent) => void) | undefined,
  options: {disabled?: boolean} = {},
): void {
  const refList = useMemo(() => (Array.isArray(refs) ? refs : [refs]), [refs]);
  useEffect(() => {
    if (options.disabled || !handler) return undefined;

    const listener = (event: MouseEvent | TouchEvent | KeyboardEvent): void => {
      const availableList = refList.filter((ref) => ref instanceof HTMLElement || ref?.current);

      if (event instanceof KeyboardEvent && !['Enter', ' '].includes(event.key)) {
        return;
      }

      if (
        !availableList.length ||
        availableList.some(
          (ref) =>
            (ref instanceof HTMLElement ? ref : ref?.current)?.contains(
              event.target as HTMLElement,
            ),
        )
      ) {
        return;
      }

      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    document.addEventListener('keydown', listener);

    return (): void => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
      document.removeEventListener('keydown', listener);
    };
  }, [refList, handler, options.disabled]);
}
