import cn from 'classnames';
import {CircleLoading} from 'components/CircleLoading';
import {Locator} from 'components/Locator';
import {AnimatedHeart, State} from 'components/UIKit/Animations/Heart';
import {Icon} from 'components/UIKit/Icon';
import {useDeviceVar} from 'hooks/useDeviceVars';
import React, {useCallback, useState} from 'react';

import styles from './index.scss';

type Props = {
  compact?: boolean;
  favorite: boolean;
  onChange: (favorite: boolean) => unknown;
};

export function FavoriteButton({compact = false, favorite, onChange}: Props): JSX.Element {
  const [loading, setLoading] = useState(false);

  const shouldAnimateLikeButton = useDeviceVar('likeButton8819')?.enabled || false;

  const handleClick = useCallback(
    async (event: React.MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();

      setLoading(true);

      try {
        await onChange(!favorite);
      } finally {
        setLoading(false);
      }
    },
    [favorite, onChange],
  );

  let icon = loading ? (
    <CircleLoading size="fontSize" />
  ) : (
    <Icon
      type="mono"
      name={favorite ? 'heart-filled-24' : 'heart-linear-24'}
      width="20px"
      height="20px"
    />
  );

  if (shouldAnimateLikeButton) {
    icon = (
      <AnimatedHeart width="32px" height="32px" state={favorite ? State.FILLED : State.EMPTY} />
    );
  }

  return (
    <Locator id="FavoriteButton" favorite={favorite}>
      <button
        className={cn(styles.root, {
          [styles.compact!]: compact,
          [styles.favorite!]: favorite,
          [styles.animated!]: shouldAnimateLikeButton,
        })}
        onClick={handleClick}
        type="button"
      >
        <span
          className={cn(styles.icon, {
            [styles.active!]: favorite,
          })}
        >
          {icon}
        </span>
      </button>
    </Locator>
  );
}
