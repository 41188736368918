import PropTypes from 'prop-types';
import React from 'react';
import TrackingCheckpointShape from 'shapes/TrackingCheckpoint';
import {FormattedDate, FormattedTime} from 'react-intl';
import classnames from 'classnames/bind';
import {Locator} from 'components/Locator';
import styles from './Checkpoint.scss';

const cn = classnames.bind(styles);

export default function Checkpoint({checkpoint, hasLine}) {
  return (
    <Locator id="parcel-tracking-checkpoint">
      <div className={cn('checkpoint', {hasLine})}>
        <div className={styles.date}>
          <FormattedDate day="2-digit" month="2-digit" value={checkpoint.timestamp} />
          <br />
          <span className={styles.time}>
            <FormattedTime value={checkpoint.timestamp} />
          </span>
        </div>
        <div className={styles.location}>{checkpoint.location}</div>
        {!checkpoint.message || <div className={styles.message}>{checkpoint.message}</div>}
        <div className={styles.outer}>
          {hasLine && <div className={styles.line} />}
          <Locator id="parcel-tracking-checkpoint-point">
            <div className={styles.point} />
          </Locator>
        </div>
      </div>
    </Locator>
  );
}

Checkpoint.propTypes = {
  checkpoint: TrackingCheckpointShape.isRequired,
  hasLine: PropTypes.bool,
};

Checkpoint.defaultProps = {
  hasLine: false,
};
