import classnames from 'classnames/bind';
import {Countdown} from 'components/Countdown';
import React, {useMemo} from 'react';
import {Color} from 'types/Color';
import {convertBackendColorToCSSValue} from 'utils/styles/color';

import styles from './index.scss';

const cn = classnames.bind(styles);

export enum Size {
  INLINE = 'inline',
  SMALL = 'small',
  SLIM = 'slim',
  NONE = 'none',
}

export enum Theme {
  ACCENT100 = 'accent100',
  ACCENT10 = 'accent10',
  WHITE10 = 'white10',
}

type CustomTheme = {
  digitBackgroundColor?: Color;
  digitColor?: Color;
};

type Props = {
  size?: Size;
  onComplete?(): void;
  timestamp: number;
  completeMessage?: React.ReactNode;
  changeFormatDurationBeforeMs?: number;
};

type CustomTimerProps = Props & {
  customTheme: CustomTheme;
  theme?: never;
};

type ThemedTimerProps = Props & {
  customTheme?: never;
  theme: Theme;
};

type TimerProps = CustomTimerProps | ThemedTimerProps;

export const Timer: React.FC<TimerProps> = (props: TimerProps) => {
  const {theme, size = Size.NONE, customTheme} = props;

  const className = cn('timer', {
    [`size-${size}`]: Boolean(size),
    [`theme-${theme}`]: Boolean(theme && !customTheme),
    customTheme: Boolean(customTheme),
  });

  const customStyle = useMemo(() => {
    if (!customTheme) {
      return {};
    }

    const {digitBackgroundColor, digitColor} = customTheme;
    return {
      '--timer-background-color': convertBackendColorToCSSValue(digitBackgroundColor),
      '--timer-text-color': convertBackendColorToCSSValue(digitColor),
      '--timer-separator-color': convertBackendColorToCSSValue(digitBackgroundColor),
    } as React.CSSProperties;
  }, [customTheme]);

  const {onComplete, timestamp, completeMessage, changeFormatDurationBeforeMs} = props;
  const passedProps = {
    onComplete,
    timestamp,
    completeMessage,
    changeFormatDurationBeforeMs,
  };

  return (
    <span className={className} style={customStyle}>
      <Countdown {...passedProps} />
    </span>
  );
};
