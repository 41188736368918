import React, {ReactElement} from 'react';
import {FormattedMessage} from 'react-intl';

import styles from './styles.scss';
import WarningIcon from './Warning.jsx.svg';

export function OldBrowserBanner(): ReactElement {
  return (
    <div className={styles.container}>
      <WarningIcon className={styles.icon} />
      <FormattedMessage
        description="Плашка в шапке страницы для неподдерживаемого браузера"
        defaultMessage={
          "The website doesn't support your browser. Please update your browser or download a different one"
        }
      />
    </div>
  );
}
