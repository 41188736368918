import ErrorShape from 'shapes/Error';
import {ParcelLiteShape} from 'shapes/ParcelLite';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {getServerTimestamp} from 'helpers/serverTime';
import {ParcelPageCard} from 'components/ParcelPageCard';
import {isParcelDeliveryConfirmed} from 'store/modules/parcel';
import {ApiClientContext} from 'providers/ApiClientContext';
import {DoYouReceive} from './DoYouReceive';
import {NotDeliveredForm} from './NotDeliveredForm';
import {ParcelDuration} from './ParcelDuration';
import {ThankYou} from './ThankYou';

const ScreenType = {
  THANKS: 'thanks',
  WAIT_FOR_WARRANTY_EXPIRE: 'waitForWarrantyExpire',
  NOT_DELIVERED_FORM: 'notDeliveredForm',
};

export class ParcelConfirmationDelivery extends Component {
  static propTypes = {
    onYesClick: PropTypes.func.isRequired,
    onNoClick: PropTypes.func.isRequired,
    markParcelNotDelivered: PropTypes.func.isRequired,
    parcel: ParcelLiteShape,
    parcelMarkingNotDelivered: PropTypes.bool.isRequired,
    parcelNotDeliveredError: ErrorShape,
    parcelDeliveredError: ErrorShape,
    parcelMarkingDelivered: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    parcel: null,
    parcelNotDeliveredError: null,
    parcelDeliveredError: null,
  };

  static contextType = ApiClientContext;

  constructor(props) {
    super(props);

    this.element = null;
    this.mounted = false;
    this.state = {
      now: getServerTimestamp(),
    };
  }

  get logger() {
    return this.context.device.log.getLogger('Device');
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  handleNotDeliveredFormSubmit = (email) => {
    const {markParcelNotDelivered, parcel} = this.props;

    markParcelNotDelivered(parcel.id, email).then(() => {
      if (!this.mounted) {
        return;
      }
      this.setState({
        screen: ScreenType.THANKS,
      });
    });
  };

  handleNotDeliveredFormCancel = () => {
    this.resetWizard();
  };

  waitForWarrantyExpireConfirm = () => {
    this.resetWizard();
  };

  resetWizard = () => {
    this.setState({
      screen: null,
    });
  };

  handleNo = () => {
    const {parcel, onNoClick} = this.props;
    const {now} = this.state;
    const startWarrantyTimeMs = parcel.warranty.startTimeMs;

    if (now < startWarrantyTimeMs) {
      this.setState({
        screen: ScreenType.WAIT_FOR_WARRANTY_EXPIRE,
      });
    } else {
      this.setState({
        screen: ScreenType.NOT_DELIVERED_FORM,
      });
    }
    onNoClick();
  };

  onYesClick = () => {
    this.setState({
      isYesClicked: true,
    });
    this.props.onYesClick();
  };

  render() {
    const {parcel, parcelMarkingDelivered, parcelDeliveredError} = this.props;

    if (
      (!isParcelDeliveryConfirmed(parcel) && this.state.screen === null) ||
      this.state.isYesClicked
    ) {
      return null;
    }

    if (this.state.screen === ScreenType.WAIT_FOR_WARRANTY_EXPIRE) {
      return (
        <ParcelPageCard>
          <ParcelDuration onConfirm={this.resetWizard} warranty={parcel.warranty} />
        </ParcelPageCard>
      );
    }

    if (this.state.screen === ScreenType.NOT_DELIVERED_FORM) {
      return (
        <ParcelPageCard>
          <NotDeliveredForm
            loading={this.props.parcelMarkingNotDelivered}
            error={this.props.parcelNotDeliveredError}
            onSubmit={this.handleNotDeliveredFormSubmit}
            onCancel={this.resetWizard}
          />
        </ParcelPageCard>
      );
    }

    if (this.state.screen === ScreenType.THANKS) {
      return (
        <ParcelPageCard>
          <ThankYou onClose={this.resetWizard} />
        </ParcelPageCard>
      );
    }

    return (
      <ParcelPageCard>
        <DoYouReceive
          deliveryConfirmationMode={parcel.deliveryConfirmationMode}
          onYes={this.onYesClick}
          onNo={this.handleNo}
          yesError={parcelDeliveredError}
          yesLoading={parcelMarkingDelivered}
        />
      </ParcelPageCard>
    );
  }
}
