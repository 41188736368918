import {FinishPart, UploadPart} from 'store/modules/media/actions';
import {ImageBundle} from 'types/Image';
import {VideoBundle} from 'types/Video';
import {Dispatch} from 'typesafe-actions';

export type UploaderSize = 'small' | 'medium';

// Errors

export enum UploaderErrorType {
  EXTERNAL_ERROR = 'externalError',
  FILE_TYPE_NOT_SUPPORTED = 'fileTypeNotSupported',
  INTERNAL_ERROR = 'internalError',
  TOO_BIG_FILE = 'tooBigFile',
  TOO_MANY_FILES = 'tooManyFiles',
  TOO_SMALL_FILE = 'tooSmallFile',
  UNKNOWN = 'unknown',
}

type UploaderBaseError<T extends UploaderErrorType, P> = {
  type: T;
  params: P;
};

export type UploaderExternalError = UploaderBaseError<
  UploaderErrorType.EXTERNAL_ERROR,
  {
    message?: string;
  }
>;

export type UploaderFileTypeNotSupportedError = UploaderBaseError<
  UploaderErrorType.FILE_TYPE_NOT_SUPPORTED,
  {
    supportedMimeTypes: string[];
  }
>;

export type UploaderInternalError = UploaderBaseError<
  UploaderErrorType.INTERNAL_ERROR,
  {
    message?: string;
  }
>;

export type UploaderTooBigFileError = UploaderBaseError<
  UploaderErrorType.TOO_BIG_FILE,
  {
    maxBytes: number;
  }
>;

export type UploaderTooManyFilesError = UploaderBaseError<
  UploaderErrorType.TOO_MANY_FILES,
  {
    maxCount: number;
  }
>;

export type UploaderTooSmallFileError = UploaderBaseError<
  UploaderErrorType.TOO_SMALL_FILE,
  {
    minBytes: number;
  }
>;

export type UploaderUnknownError = UploaderBaseError<
  UploaderErrorType.UNKNOWN,
  {
    message?: string;
  }
>;

export type UploaderError =
  | UploaderExternalError
  | UploaderFileTypeNotSupportedError
  | UploaderInternalError
  | UploaderTooBigFileError
  | UploaderTooManyFilesError
  | UploaderTooSmallFileError
  | UploaderUnknownError;

// Uploader Options

export type MediaFilter = {
  maxBytes?: number;
  minBytes?: number;
  supportedMimeTypes: string[];
};

export type UploaderState = {
  mediaStatesList: MediaStatesList;
  mediaStatesMap: MediaStatesMap;
};

export type UploaderOptions = {
  commit: <T extends MediaState>(uuid: string, mediaState: T) => T;
  dispatch: Dispatch;
  getState: () => UploaderState;
  filters: MediaFilter[];
  maxCount?: number;
};

export type MediaImageBundle = {
  image: ImageBundle;
};
export type MediaVideoBundle = {
  video: VideoBundle;
};
export type MediaBundle = MediaImageBundle | MediaVideoBundle;

export enum MediaType {
  IMAGE = 'image',
  VIDEO = 'video',
}

export enum MediaStatus {
  ERROR = 'error',
  IN_PROGRESS = 'inProgress',
  IN_PROGRESS_OF_DELETION = 'inProgressOfDeletion',
  SUCCESS = 'success',
  WILL_BE_DELETED = 'willBeDeleted',
  WILL_BE_UPLOADED = 'willBeUploaded',
}

type BaseMediaState<T extends MediaType, B extends MediaBundle, M> = {
  bundle?: B;
  bytesTotal: number;
  bytesUploaded: number;
  error?: UploaderError;
  file?: File;
  meta?: M;
  status: MediaStatus;
  type: T;
  uuid: string;
};
export type ImageMediaState = BaseMediaState<MediaType.IMAGE, MediaImageBundle, never>;
export type VideoMediaState = BaseMediaState<
  MediaType.VIDEO,
  MediaVideoBundle,
  {
    finishParts?: FinishPart[];
    uploadId?: string;
    uploadParts?: UploadPart[];
  }
>;
export type MediaState = ImageMediaState | VideoMediaState;
export type MediaStatesMap = Record<string, MediaState>;
export type MediaStatesList = MediaState[];
