import {useMergedRef} from 'hooks/useMergedRef';
import {usePreviewEvent} from 'hooks/usePreviewEvent';
import React, {forwardRef, memo, ReactElement, Ref, useRef} from 'react';
import {SDLNode, SDLNodeWithExtras} from 'types/ServerDrivenLayout';
import {MergeUnion} from 'types/utility';

import {SDLNodeView} from './SDLNodeView';

export const ServerDrivenLayout = memo(
  forwardRef(
    (
      {layout}: {layout: SDLNode | SDLNodeWithExtras | undefined},
      outerRef: Ref<HTMLDivElement>,
    ): ReactElement | null => {
      const node = layout && 'layout' in layout ? layout.layout : layout;
      const innerRef = useRef<HTMLDivElement>(null);

      const mergedRef = useMergedRef(outerRef, innerRef);

      usePreviewEvent({
        rootRef: innerRef,
        previewEvent: (layout as MergeUnion<typeof layout> | undefined)?.previewEvent,
      });

      if (!node) {
        return null;
      }

      return <SDLNodeView node={node} ref={mergedRef} />;
    },
  ),
);
