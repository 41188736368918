import {withLazyHydrate} from 'components/LazyHydrate/withLazyHydrate';
import {ProductLite as ProductLiteUI} from 'components/ProductLite';
import {useDeviceVar} from 'hooks/useDeviceVars';
import {connect} from 'react-redux';
import {isBot} from 'store/modules/preferences';
import {RootState} from 'store/rootReducer';

const mapStateToProps = (globalState: RootState) => ({
  bot: isBot(globalState),
});

export const ProductLite = connect(mapStateToProps)(
  withLazyHydrate(ProductLiteUI, function useOptions() {
    const productLiteLazyHydrateEnabled = useDeviceVar('productLiteLazyHydrateEnabled');
    return {
      disabled: !productLiteLazyHydrateEnabled,
      whenVisible: true,
      noWrapper: true,
    };
  }),
);
