import {Address} from 'components/Address';
import AddressShape from 'shapes/Address';
import HeaderButton from 'components/WhiteList/ActionHeader/Button';
import PropTypes from 'prop-types';
import React, {memo} from 'react';
import {FormattedMessage} from 'react-intl';
import {Locator} from 'components/Locator';
import styles from './index.scss';

export const ParcelAddress = memo(({address, onEdit}) => {
  return (
    <>
      {onEdit ? (
        <Locator id="ParcelDeliveryAddressEditButton">
          <HeaderButton className={styles.edit} onClick={onEdit}>
            <FormattedMessage
              defaultMessage="Edit"
              description="Address edit button in parcel card"
            />
          </HeaderButton>
        </Locator>
      ) : null}
      <h2 className={styles.header}>
        <FormattedMessage
          defaultMessage="Delivery address"
          description="Address header in parcel card"
        />
      </h2>
      <Locator id="ParcelDeliveryAddress">
        <div className={styles.text}>
          <Address address={address} />
        </div>
      </Locator>
    </>
  );
});

ParcelAddress.propTypes = {
  address: AddressShape.isRequired,
  onEdit: PropTypes.func,
};

ParcelAddress.defaultProps = {
  onEdit: null,
};
