import appstore from 'components/Badge/small/appstore.svg';
import googleplay from 'components/Badge/small/googleplay.svg';
import huawei from 'components/Badge/small/huawei.svg';
import rustore from 'components/Badge/small/rustore.svg';
import {Locator} from 'components/Locator';
import {createPublicPath} from 'helpers/publicPath';
import {useLocaleData} from 'hooks/useLocaleData';
import {useScope} from 'hooks/useScope';
import React, {ImgHTMLAttributes} from 'react';

type BadgeType = 'appstore' | 'googleplay' | 'huawei' | 'rustore';

const SMALL_BADGES: Partial<Record<BadgeType, string>> = {
  appstore,
  googleplay,
  huawei,
  rustore,
};

const ALT = {
  appstore: 'App Store',
  googleplay: 'Google Play',
  huawei: 'Huawei',
  rustore: 'RuStore',
};

type Badge = {
  small?: boolean;
  type: BadgeType;
};

type ImageAttrs = Omit<ImgHTMLAttributes<HTMLImageElement>, 'type'>;

type Props = ImageAttrs & Badge;

export function Badge({small = false, type, ...restProps}: Props): JSX.Element | null {
  const {badge} = useLocaleData();
  const scope = useScope();

  if (!badge?.default?.[type]) {
    return null;
  }

  const src = small ? SMALL_BADGES[type] : createPublicPath(badge.default[type]!, scope);

  return (
    <Locator id="AppBadgeInFooter" type={ALT[type]}>
      <img {...restProps} alt={restProps.alt || ALT[type]} src={src || ''} />
    </Locator>
  );
}
