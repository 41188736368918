export type HashSet<TType> = {
  readonly nodes: TType[];
  readonly ids: Record<string, number>;
};

export type Options<T> = {
  extractId: (item: T) => string;
};

export function create<TType>(nodes: TType[], extractId: (item: TType) => string): HashSet<TType> {
  const result: HashSet<TType> = {
    nodes,
    ids: {},
  };

  nodes.forEach((node, index) => {
    result.ids[extractId(node)] = index;
  });

  return result;
}

export function getNodes<TType>(hashSet: HashSet<TType>): TType[] {
  return hashSet.nodes;
}

function getIndex<TType>(hashSet: HashSet<TType>, id: string): number {
  const index = hashSet.ids[id];
  return index === undefined ? -1 : index;
}

export function getNode<TType>(hashSet: HashSet<TType>, id: string): TType | undefined {
  return hashSet.nodes[getIndex(hashSet, id)];
}
