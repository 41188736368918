import classnames from 'classnames/bind';
import {Image} from 'components/Image';
import {Overlay} from 'components/Overlay';
import React, {CSSProperties, ReactElement, ReactNode, useMemo} from 'react';
import {Color} from 'types/Color';
import {hexToRgb} from 'utils/colors';
import {convertBackendColorToCSSValue, getColorString} from 'utils/styles/color';

import {BackgroundImageProps} from '../types';
import styles from './index.scss';
import {useImageLoading} from './useImageLoading';

const cn = classnames.bind(styles);

export type Props = {
  children: ReactNode;
  backgroundColor?: Color;
  backgroundImage?: BackgroundImageProps;
  backgroundElement?: JSX.Element;
  color?: Color;
};

function set<T>(dict: Record<string, T>, key: string, value?: T): void {
  if (value) {
    dict[key] = value;
  }
}

function getStyles(backgroundColor?: Color, color?: Color): CSSProperties {
  const result = {};
  const backgroundColorHex = convertBackendColorToCSSValue(backgroundColor);
  if (backgroundColorHex) {
    set(result, '--popup-background-color', backgroundColorHex);

    const [r, g, b] = hexToRgb(getColorString(backgroundColor)!);
    set(result, '--popup-background-color-transparent', `rgba(${r}, ${g}, ${b}, 0)`);
  }
  set(result, 'color', convertBackendColorToCSSValue(color));
  return result;
}

export function Content({
  children,
  backgroundImage,
  backgroundElement,
  backgroundColor,
  color,
}: Props): ReactElement {
  const [imageLoading, handleComplete] = useImageLoading(backgroundImage?.image);
  const style = useMemo(() => getStyles(backgroundColor, color), [backgroundColor, color]);

  let element = backgroundElement;
  if (backgroundImage) {
    element = (
      <Image
        {...backgroundImage}
        onComplete={handleComplete}
        width="100%"
        height="100%"
        className={styles.image}
      />
    );
  }

  return (
    <div className={cn('parent', {imageLoading})} style={style}>
      <Overlay loading={imageLoading}>
        {element ? <div className={styles.element}>{element}</div> : null}
        <div className={styles.content}>{children}</div>
      </Overlay>
    </div>
  );
}
