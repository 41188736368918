import {Image} from 'components/Image';
import {useModalOverlayLabelledById} from 'components/UIKit/ModalOverlay/context';
import React from 'react';
import {ContentItem} from 'types/Popup';
import {getImageBundleAspectRatio} from 'utils/image';
import {convertBackendGradientToCSSValue} from 'utils/styles/color';

import {Text} from '../Text';
import styles from './index.scss';

type Props = {
  gradientHeader: NonNullable<ContentItem['gradientHeader']>;
};

export const GradientHeader: React.FC<Props> = ({gradientHeader}: Props) => {
  const headerUid = useModalOverlayLabelledById();

  const imageBundleAspectRatio = getImageBundleAspectRatio(gradientHeader.iconImage);

  return (
    <div className={styles.gradientHeader}>
      <div
        className={styles.background}
        style={{
          background: gradientHeader.gradient
            ? convertBackendGradientToCSSValue(gradientHeader.gradient)
            : undefined,
        }}
      />
      <div className={styles.iconImage}>
        <Image
          image={gradientHeader.iconImage}
          className={styles.image}
          vwFit={{sm: `${104 * imageBundleAspectRatio}px`, xs: `${72 * imageBundleAspectRatio}px`}}
        />
      </div>
      <div className={styles.info}>
        <div className={styles.title} id={headerUid}>
          <Text text={gradientHeader.title} />
        </div>
        <div className={styles.subtitle}>
          <Text text={gradientHeader.subtitle} />
        </div>
      </div>
    </div>
  );
};
