import {globalLog} from 'helpers/log';
import {ecsError} from 'helpers/log/ECS/ecsError';
import {ECSLog} from 'helpers/log/ECS/types';
import {useContextSeed} from 'hooks/useContextSeed';
import {useLog} from 'hooks/useLogger';
import {useMemo} from 'react';
import {ContextItem} from 'types/Context';
import {OpenPayload} from 'types/OpenPayload';
import {createUrl} from 'utils/url';

export type MakeProductUrlParams = {
  openPayload?: OpenPayload;
  context?: ContextItem;
  contextSeed?: string;
  variantId?: string;
};

export function makeProductUrl(
  baseUrl: string,
  {openPayload, context, contextSeed, variantId}: MakeProductUrlParams | undefined = {},
  log: ECSLog = globalLog,
): string {
  try {
    return createUrl(baseUrl, {
      ...(contextSeed && context && {contextSeed, context: JSON.stringify(context)}),
      ...(openPayload && {openPayload: JSON.stringify(openPayload)}),
      ...(variantId && {variant_id: variantId}),
    });
  } catch (error) {
    log.getLogger('makeProductUrl').error({error: ecsError(error)});
    return baseUrl;
  }
}

export type UseProductUrlParams = Omit<MakeProductUrlParams, 'contextSeed'>;

export function useProductUrl(baseUrl: string, params: UseProductUrlParams | undefined): string {
  const contextSeed = useContextSeed();
  const log = useLog();

  return useMemo(
    () => makeProductUrl(baseUrl, {...params, contextSeed}, log),
    [baseUrl, contextSeed, log, params],
  );
}
