import config from 'config';
import {useApiClient} from 'hooks/useApiClient';
import React, {ReactElement, useCallback, useState} from 'react';
import {RenderingType} from 'types/Rendering';

const AVAILABLE_RENDERING_TYPES: Partial<Record<RenderingType, string>> = {
  [RenderingType.BROWSER]: 'Browser rendering',
  // server and crawler rendering don't work in prod
  ...(config.releaseStage !== 'prod' && {
    [RenderingType.USER]: 'Server rendering',
    [RenderingType.CRAWLER]: 'Crawler rendering',
  }),
};

export const FORCED_RENDERING_TYPE_SELECT_ID = 'forcedRenderingTypeSelect';

export function ForcedRenderingType(): ReactElement {
  const client = useApiClient();
  const [renderingType, setRenderingType] = useState(() => client.device.getForcedRenderingType());

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const nextRenderingType = event.target.value;
      setRenderingType(nextRenderingType as RenderingType);
      client.device.setForcedRenderingType(nextRenderingType as RenderingType);
    },
    [client],
  );

  return (
    <select
      id={FORCED_RENDERING_TYPE_SELECT_ID}
      name="forcedRenderingType"
      value={renderingType}
      onChange={handleChange}
    >
      <option value="">Auto rendering type</option>
      {Object.entries(AVAILABLE_RENDERING_TYPES)?.map(([renderingType, title]) => (
        <option value={renderingType} key={renderingType}>
          {title}
        </option>
      ))}
    </select>
  );
}
