import Button from 'components/Button';
import {Locator} from 'components/Locator';
import {OrderLayout} from 'components/ParcelElements/OrderLayout';
import {deepLinks} from 'helpers/deepLink';
import {useLanguage} from 'hooks/useLanguage';
import PropTypes from 'prop-types';
import React, {memo, useMemo} from 'react';
import {FormattedMessage} from 'react-intl';
import OrderLite from 'shapes/OrderLite';
import {ParcelShape} from 'shapes/Parcel';
import {ParcelOrderActionShape} from 'shapes/ParcelOrderAction';
import {ParcelStatusAppearanceShape} from 'shapes/ParcelStatusAppearance';
import {RefShape} from 'shapes/Ref';
import {ClickArea} from 'types/analytics/Parcel';
import {createUrl} from 'utils/url';
import {useScope} from 'hooks/useScope';
import {getUrl} from 'routes';

import styles from './index.scss';

export const Order = memo(
  ({
    order,
    orderAction,
    quantity,
    statusAppearance,
    parcel,
    vatInfo,
    buttonRef,
    imgRef,
    idRef,
    showSpecialPriceIcon,
  }) => {
    const {id: orderId} = order;
    const {id: parcelId} = parcel;
    const lang = useLanguage();
    const scope = useScope();

    const actionTitle = orderAction && orderAction.title;
    const buttonColor = actionTitle ? 'darkblue' : 'lightgrey';

    const buttonLink = useMemo(() => {
      const actionButtonLink =
        (orderAction &&
          orderAction.url &&
          deepLinks.match(orderAction.url)?.reverse(scope, lang)) ||
        getUrl('order', {scope, lang, parcelId}, {id: orderId});

      return createUrl(actionButtonLink, {area: ClickArea.ABOUT_BUTTON});
    }, [orderAction, parcelId, orderId, scope, lang]);

    const to = useMemo(
      () => getUrl('order', {lang, scope, parcelId}, {area: ClickArea.ORDER}),
      [lang, scope, parcelId],
    );

    return (
      <OrderLayout
        order={order}
        vatInfo={vatInfo}
        quantity={quantity}
        imgTo={to}
        idTo={to}
        imgRef={imgRef}
        idRef={idRef}
        showSpecialPriceIcon={showSpecialPriceIcon}
        rightButton={
          <Locator id="order-openButton">
            <div ref={buttonRef} className={styles.button}>
              <Button block color={buttonColor} padding="wide" to={buttonLink}>
                {actionTitle || (
                  <FormattedMessage
                    description="Parcel detail button in parcel order"
                    defaultMessage="Show details"
                  />
                )}
              </Button>
            </div>
          </Locator>
        }
      />
    );
  },
);

Order.propTypes = {
  order: OrderLite.isRequired,
  parcel: ParcelShape.isRequired,
  quantity: PropTypes.number.isRequired,
  orderAction: ParcelOrderActionShape,
  statusAppearance: ParcelStatusAppearanceShape,
  imgRef: RefShape,
  idRef: RefShape,
  buttonRef: RefShape,
  vatInfo: PropTypes.shape({
    title: {
      text: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    },
  }),
  showSpecialPriceIcon: PropTypes.bool,
};

Order.defaultProps = {
  orderAction: null,
  statusAppearance: null,
  imgRef: null,
  idRef: null,
  buttonRef: null,
  vatInfo: null,
  showSpecialPriceIcon: false,
};
