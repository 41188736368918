import {WithLoadedTime} from 'store/utils/enhancers';
import {AppMigrationFooter} from 'types/AppMigrationFooter';
import {CartBadge} from 'types/CartBadge';
import {CartPricing} from 'types/CartPricing';
import {CheckoutSessionStepBundle} from 'types/CheckoutSessionStepBundle';
import {NamedColor} from 'types/Color';
import type {EmailConfirmation} from 'types/EmailConfirmation';
import {ImageBundle} from 'types/Image';
import {Price} from 'types/Price';

import {PointsCashbackBadge} from './ProductBadge';
import {CheckoutItemPatch} from './ProductPatch';

export enum PurchaseState {
  UNAVAILABLE = 'unavailable',
  CURRENTLY_UNAVAILABLE = 'currentlyUnavailable',
  AVAILABLE = 'available',
  COMPLETE = 'complete',
}

export type PayhubParams = {
  clientToken: string;
  customerPrice: {
    amount: number;
    ccy: string;
  };
};

export type CheckoutSessionOrderGroup = {
  id: string;
  isPaid?: boolean;
  payhubParams?: PayhubParams;
  totalPrice?: Price;
};

export type CheckoutSessionItem = {
  productId: string;
  productVariantId: string;
  badge?: CartBadge | PointsCashbackBadge;
  colors: NamedColor[];
  name: string;
  size?: string;
  quantity: number;
  image?: ImageBundle;
  price: Price;
  isPurchasable: boolean;
  loadedTimeMs?: number;
  showSpecialPriceIcon?: boolean;
  patch?: CheckoutItemPatch;
};

export type CheckoutSessionGroup = {
  id: string;
  title?: string;
  subtitle?: string;
  // Бейдж "Шаг 1", "Оплачено" и т.д.
  badge?: CartBadge;
  emailConfirmationBanner?: EmailConfirmation;
  // Товары внутри группы.
  items: CheckoutSessionItem[];
  // Разбивка по ценам.
  pricing?: CartPricing;
  // Результирующая цена группы с учётом скидок и доставки.
  totalPrice: Price;
  // Данные, которые нужно будет отправить бекенду при выполнении очередного шага.
  verificationData?: unknown;
  // Состояние покупки группы: недоступна для покупки, недоступна для покупки
  // при текущих параметрах чекаута, доступна для покупки, куплена.
  purchaseState?: PurchaseState;
  // Информация о шагах для покупки группы.
  stepBundle: CheckoutSessionStepBundle;
  // adds client for processing payment
  orderGroup?: CheckoutSessionOrderGroup;
  appearance: {
    showHeader: boolean;
  };
  disclaimer?: {
    id: string;
    text: string;
  };
  footer?: AppMigrationFooter;
  payhubParams?: PayhubParams;
};

export type OrderGroupPaymentStatus = {
  status: 'empty' | 'inProgress' | 'completed';
};

export type CheckoutSession = WithLoadedTime<{
  id: string;
  groups: CheckoutSessionGroup[];
}>;
