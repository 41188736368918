import baseLoadable, {DefaultComponent, OptionsWithResolver} from '@loadable/component';
import {requireModule} from 'utils/loadable';
import {nextTickPromise} from 'utils/nextTick';

let isEnabled = true;

export function disableNextTickLoadable(): void {
  isEnabled = false;
}

// by default loadable run import on didMount phase
// this affects hydrate performance
const nextTickLoadable = <Props, Module = DefaultComponent<Props>>(
  loadFn: () => Promise<Module>,
  options: OptionsWithResolver<Props, Module>,
) => {
  const nextTickLoadFn = () => {
    if (isEnabled) {
      return nextTickPromise(() => requireModule(loadFn));
    }
    return requireModule(loadFn);
  };

  return baseLoadable(nextTickLoadFn, options);
};

nextTickLoadable.lib = baseLoadable.lib;

// loadable babel plugin checks only `loadable` name
// see https://loadable-components.com/docs/babel-plugin/#loadable-detection
export const loadable = __CLIENT__ ? nextTickLoadable : baseLoadable;
