const LOAD = 'currencies/LOAD';
const LOAD_SUCCESS = 'currencies/LOAD_SUCCESS';
const LOAD_FAIL = 'currencies/LOAD_FAIL';

const initialState = {
  items: null,
  loading: false,
  error: null,
  loaded: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        error: null,
        loaded: false,
        loading: true,
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        error: null,
        items: action.result.items,
        loaded: true,
        loading: false,
      };
    case LOAD_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
}

export function isCurrenciesLoaded(globalState) {
  return !!globalState.currencies.loaded;
}

export function isCurrenciesLoading(globalState) {
  return !!globalState.currencies.loading;
}

export function getCurrenciesError(globalState) {
  return globalState.currencies.error;
}

export function getCurrencies(globalState) {
  return globalState.currencies.items;
}

export function loadCurrencies() {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client) =>
      client.api
        .get('/currencies/available')
        .then(({language, currency, body: {payload}}) => payload),
  };
}
