import {ContextList} from 'types/Context';
import type {EventParams} from 'types/EventParams';
import {Button} from 'types/Layout';
import {ProductLite} from 'types/ProductLite';

import {Header} from './common';

export type ProductCartGroupProductItem = {
  product: ProductLite;
};

export enum ProductCardGroupDesign {
  SMALL_CARD = 'smallCardWeb',
  LARGE_CARD = 'largeCardWeb',
}

export type ProductCardGroup = {
  appearance: {
    design: ProductCardGroupDesign;
  };
  contexts: Array<ContextList>;
  header?: Header;
  items: Array<ProductCartGroupProductItem>;
  button?: Button;
  eventParams?: EventParams;
};

export function productCardGroupProductItem(product: ProductLite): ProductCartGroupProductItem {
  return {product};
}

export function isProductCardGroupProductItem(
  item: Record<string, unknown>,
): item is ProductCartGroupProductItem {
  return Boolean((item as ProductCartGroupProductItem).product);
}
