const LOAD = 'tracking/LOAD';

export const initialState = {
  data: {},
  loaded: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loaded: true,
        data: action.result,
      };
    default:
      return state;
  }
}

export function isTrackingMarquesLoaded(globalState) {
  return globalState.tracking.loaded;
}

export function getTrackingMarquesData(globalState) {
  return globalState.tracking?.data || {};
}

export function loadTrackingMarques() {
  return {
    type: LOAD,
    result: (client) => client.tracking.get() || {},
  };
}
