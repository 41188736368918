import classnames from 'classnames/bind';
import {ConditionalWrapper} from 'components/ConditionalWrapper';
import {Image} from 'components/Image';
import {ImageProps} from 'components/Image/types';
import {Locator} from 'components/Locator';
import {Square} from 'components/Square';
import React, {useCallback} from 'react';
import {
  GalleryItem,
  isGalleryBrokenVideoItem,
  isGalleryProcessingVideoItem,
  isGalleryVideoItem,
} from 'types/GalleryItem';
import {getGalleryItemImage} from 'utils/gallery';

import styles from './index.scss';

const cn = classnames.bind(styles);

type Props = ImageProps & {
  active?: boolean;
  alt?: string;
  children?: React.ReactNode;
  contain?: boolean;
  cover?: boolean;
  index?: number;
  item?: GalleryItem;
  darkenVideo?: boolean;
  square?: boolean;
  moreAmount?: number;
  layoutBgInherit?: boolean;
  fixedIconSizes?: boolean;
};

export function MediaPreview({
  active = false,
  multiply = true,
  alt = '',
  contain = false,
  cover = false,
  children,
  item,
  darkenVideo = false,
  square = true,
  moreAmount,
  layoutBgInherit,
  fixedIconSizes,
  ...imageProps
}: Props): React.ReactElement {
  const isVideoItem = isGalleryVideoItem(item);
  const isBrokenVideoItem = isGalleryBrokenVideoItem(item);
  const isProcessingVideoItem = isGalleryProcessingVideoItem(item);

  const squareWrapper = useCallback(
    (children: React.ReactNode) => <Square className={cn('square')}>{children}</Square>,
    [],
  );

  const moreText = moreAmount ? `+${moreAmount}` : '';

  return (
    <ConditionalWrapper wrapper={squareWrapper} condition={square}>
      <>
        {item ? (
          <div
            className={cn('imageWrap', {
              active,
              video: isVideoItem && !moreText && (!isBrokenVideoItem || isProcessingVideoItem),
              darken: isVideoItem && darkenVideo,
              fixedIconSizes,
            })}
          >
            <Locator id="GalleryItem">
              <Image
                {...imageProps}
                alt={alt}
                className={cn('image')}
                contain={contain && !isVideoItem}
                cover={cover || isVideoItem}
                image={getGalleryItemImage(item)}
                multiply={multiply}
                height="100%"
                width="100%"
                layoutBgInherit={layoutBgInherit}
              />
            </Locator>
            {moreText ? <div className={styles.more}>{moreText}</div> : null}
          </div>
        ) : null}
        {children}
      </>
    </ConditionalWrapper>
  );
}
