import React, {ReactNode} from 'react';
import {defineMessages, FormattedMessage, IntlShape, useIntl} from 'react-intl';

import {Price} from './index';
import styles from './index.scss';

let lastLocale = '';
let currencySignBehind = false;
function isCurrencySignBehind(intl: IntlShape): boolean {
  if (intl.locale !== lastLocale) {
    lastLocale = intl.locale;
    const value = intl.formatNumber(3, {
      style: 'currency',
      currency: 'USD',
    });
    currencySignBehind = value[0] !== '3';
  }

  return currencySignBehind;
}

const messages = defineMessages({
  from: {
    description: 'Price range with min value',
    defaultMessage: 'from {min}',
  },
  fromCapitalized: {
    description: 'Price range with min value (capitalized)',
    defaultMessage: 'From {min}',
  },
  fromTo: {
    description: 'Price range with min and max value',
    defaultMessage: '{min} — {max}',
  },
  to: {
    description: 'Price range with max value',
    defaultMessage: 'to {max}',
  },
  toCapitalized: {
    description: 'Price range with max value (capitalized)',
    defaultMessage: 'To {max}',
  },
});

type Props = {
  capitalize?: boolean;
  children?: ReactNode;
  // currency code
  format: string;
  max?: number | null;
  min?: number | null;
};

export function Range(props: Props): ReactNode {
  const intl = useIntl();

  const {capitalize = false, children = null, max = null, min = null, format} = props;

  if (min && max) {
    const minFormat = isCurrencySignBehind(intl) ? format : '';
    const maxFormat = isCurrencySignBehind(intl) ? '' : format;
    return (
      <span className={styles.range}>
        <FormattedMessage
          {...messages.fromTo}
          values={{
            min: <Price key="min" noSeoCurrency value={min} format={minFormat} />,
            max: <Price key="max" noSeoLabel value={max} format={maxFormat} />,
          }}
        />
      </span>
    );
  }

  if (min) {
    const message = capitalize ? messages.fromCapitalized : messages.from;
    return (
      <FormattedMessage
        {...message}
        values={{
          min: <Price noSeoLabel format={format} value={min} />,
        }}
      />
    );
  }

  if (max) {
    const message = capitalize ? messages.toCapitalized : messages.to;
    return (
      <FormattedMessage
        {...message}
        values={{
          max: <Price noSeoLabel format={format} value={max} />,
        }}
      />
    );
  }

  // bad min and max
  return children;
}
