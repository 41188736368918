import React from 'react';

import styles from './index.scss';

type Props = {
  children: React.ReactNode;
};

export const TimerBox: React.FC<Props> = ({children}: Props) => {
  return (
    <div className={styles.countdown}>
      <div className={styles.numberGroup}>
        <div className={styles.number}>{children}</div>
      </div>
    </div>
  );
};
