import PropTypes from 'prop-types';
import React from 'react';
import styles from './Caption.scss';

const WhiteListCaption = ({children, icon, text, note}) => (
  <div className={`${styles.caption} ${icon ? styles.withIcon : ''}`}>
    {!icon || <img alt="" className={styles.icon} src={icon} />}
    <div className={styles.content}>
      {text ? <div className={styles.text}>{text}</div> : children}
      {!note || <div className={styles.note}>{note}</div>}
    </div>
  </div>
);

WhiteListCaption.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.string,
  note: PropTypes.node,
  text: PropTypes.node,
};

WhiteListCaption.defaultProps = {
  children: null,
  icon: null,
  note: null,
  text: null,
};

export default WhiteListCaption;
