function processValue<T>(value: T): T {
  if (Array.isArray(value)) {
    return value.map(processValue) as T & unknown[];
  }
  if (typeof value === 'object' && value) {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return sortedObject(value as T & Record<string | number, unknown>);
  }
  return value;
}

// use this util to compare objects by JSON.stringify
export function sortedObject<T extends Record<string | number, unknown>>(object: T): T {
  const resultObject = {} as T;

  (Object.keys(object).sort() as Array<keyof T>).forEach((key) => {
    resultObject[key] = processValue(object[key]);
  });

  return resultObject;
}
