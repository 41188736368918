import React from 'react';
import {ColoredText} from 'types/ColoredText';
import {convertBackendColorToCSSValue} from 'utils/styles/color';

type Props = {
  vatInfo: {
    title: ColoredText;
  };
};

export const VatBadge = ({
  vatInfo: {
    title: {color, text},
  },
}: Props): JSX.Element => {
  return <span style={{color: convertBackendColorToCSSValue(color)}}>{text}</span>;
};
