import throttle from 'lodash/throttle';
import {DependencyList, useEffect, useRef} from 'react';

type ResizeHandler = () => void;

const DEFAULT_THROTTLE_MS = 300;

export function useResize(effect: ResizeHandler, deps?: DependencyList): void {
  useEffect(() => {
    const handleResize = throttle(effect, DEFAULT_THROTTLE_MS);

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // Don't include callback into deps, because this hook works like the useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps || []);
}

export function useRafResize(effect: ResizeHandler, deps?: DependencyList): void {
  const rafId = useRef<number>(0);

  useEffect(() => {
    const handleResize = () => {
      cancelAnimationFrame(rafId.current);

      rafId.current = requestAnimationFrame(() => {
        effect();
      });
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // Don't include callback into deps, because this hook works like the useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps || []);
}
