import {assignSet} from 'utils/object';

export const LOAD = 'closeMemo/LOAD';
export const CLOSE = 'closeMemo/CLOSE';

export const initialState = {
  loaded: {},
  closed: {},
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loaded: assignSet(state.loaded, action.id, true),
        closed: assignSet(state.closed, action.id, action.result.closed),
      };
    case CLOSE:
      return {
        ...state,
        loaded: assignSet(state.loaded, action.id, true),
        closed: assignSet(state.closed, action.id, true),
      };
    default:
      return state;
  }
}

export function isCloseMemoLoaded(globalState, id) {
  return !!globalState.closeMemo.loaded[id];
}

export function getCloseMemo(globalState, id) {
  return isCloseMemoLoaded(globalState, id) && !!globalState.closeMemo.closed[id];
}

export function getCloseMemoById(id) {
  return (globalState) => getCloseMemo(globalState, id);
}

export function loadCloseMemo(id) {
  return {
    id,
    type: LOAD,
    result: (client) => ({
      closed: client.cookiesRegistry[id].restore() === '1',
    }),
  };
}

export function setMemoClosed(id) {
  return {
    id,
    type: CLOSE,
    result: (client) => client.cookiesRegistry[id].store('1'),
  };
}
