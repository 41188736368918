import {useRefEffect} from 'hooks/useRefEffect';
import throttle from 'lodash/throttle';
import {useState} from 'react';
import {requestAnimationFrame} from 'utils/raf';

import {useSliderContext} from './useSliderContext';

const directionDimensionsMap = {
  vertical: {
    scrollStart: 'scrollTop',
    scrollSize: 'scrollHeight',
    offsetSize: 'offsetHeight',
  },
  horizontal: {
    scrollStart: 'scrollTop',
    scrollSize: 'scrollWidth',
    offsetSize: 'offsetWidth',
  },
} as const;

export function useControls(deps: unknown[] = []): [boolean, boolean] {
  const {viewRef, direction} = useSliderContext();
  const [startShown, setStartShown] = useState(true);
  const [endShown, setEndShown] = useState(true);

  const dimensions = directionDimensionsMap[direction];

  useRefEffect(
    () => {
      const view = viewRef.current;
      if (view) {
        const handler = throttle(() => {
          setStartShown(Math.abs(view[dimensions.scrollStart]) <= 0);
          setEndShown(
            Math.abs(view[dimensions.scrollStart]) + view[dimensions.offsetSize] >=
              view[dimensions.scrollSize],
          );
        }, 50);
        view.addEventListener('scroll', handler, {passive: true});
        window.addEventListener('resize', handler);
        const rafId = requestAnimationFrame(handler);

        return () => {
          cancelAnimationFrame(rafId);
          view.removeEventListener('scroll', handler);
          window.removeEventListener('resize', handler);
        };
      }

      return undefined;
    },
    [viewRef],
    deps,
  );

  return [startShown, endShown];
}
