import {getQueryData} from 'utils/url';

export function getShareButtonParams(url = ''): Record<string, unknown> {
  const queryData = getQueryData(url);
  try {
    return JSON.parse(queryData.params as string);
  } catch (error) {
    return {};
  }
}
