import {getDocumentScrollElement, getScrollableParent} from './dom';
import {voidFunction} from './function';
import {getViewportHeight} from './viewport';

type StickyItem = {
  nodeRef: React.RefObject<HTMLElement>;
  margin?: number;
};

const registerStickyItem = (items: Set<StickyItem>, item: StickyItem): (() => void) => {
  if (__SERVER__) {
    return voidFunction;
  }

  items.add(item);

  return () => {
    items.delete(item);
  };
};

const computeStickyItemsMargin = (items: Set<StickyItem>): number => {
  let margin = 0;

  for (const item of items) {
    if (item.nodeRef.current) {
      margin += item.nodeRef.current.offsetHeight;
      margin += item.margin ?? 0;
    }
  }

  return margin;
};

const stickyHeaders = new Set<StickyItem>();
const stickyFooters = new Set<StickyItem>();

export const registerStickyHeader = (item: StickyItem): (() => void) =>
  registerStickyItem(stickyHeaders, item);

export const registerStickyFooter = (item: StickyItem): (() => void) =>
  registerStickyItem(stickyFooters, item);

export const computeStickyHeadersMargin = (): number => computeStickyItemsMargin(stickyHeaders);
export const computeStickyFootersMargin = (): number => computeStickyItemsMargin(stickyFooters);

type Options = {
  forced?: boolean;
  margin?: number;
  scrollTarget?: 'document' | 'scrollableParent';
};

export function verticalScrollToElement(
  element: Element | null | undefined,
  options: Options = {scrollTarget: 'document'},
): boolean {
  const {margin = 10, scrollTarget, forced = false} = options;

  if (!element) {
    return false;
  }

  const documentScrollable = getDocumentScrollElement(document);
  const scrollable =
    scrollTarget === 'scrollableParent'
      ? getScrollableParent(element, document)
      : documentScrollable;
  const rect = element.getBoundingClientRect();
  if (!rect || !scrollable) {
    return false;
  }

  const stickyHeadersMargin = computeStickyHeadersMargin();

  if (!forced) {
    if (
      scrollable === documentScrollable &&
      rect.top >= stickyHeadersMargin &&
      rect.bottom < getViewportHeight()
    ) {
      return false;
    }

    const scrollableRect = scrollable.getBoundingClientRect();

    if (
      scrollable !== documentScrollable &&
      rect.top >= scrollableRect.top + stickyHeadersMargin &&
      rect.bottom < scrollableRect.bottom
    ) {
      return false;
    }
  }

  const from = scrollable.scrollTop;
  const diff = rect.top - margin - computeStickyHeadersMargin();

  scrollable.scroll({
    top: from + diff,
    behavior: 'smooth',
  });

  return true;
}

export function verticalScrollToElementCentered(
  element: Element | null | undefined,
  options: Options = {},
): boolean {
  return verticalScrollToElement(element, {
    ...options,
    margin: (options.margin || 0) + window.innerHeight / 2,
  });
}
