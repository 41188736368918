import {useSsrOrHydration} from 'hooks/useSsrOrHydration';
import {QRCodeSVG} from 'qrcode.react';
import React, {ComponentProps, PropsWithChildren, ReactElement} from 'react';

class ErrorBoundary extends React.Component<PropsWithChildren, {hasError: boolean}> {
  constructor(props: PropsWithChildren) {
    super(props);
    this.state = {hasError: false};
  }

  static getDerivedStateFromError() {
    return {hasError: true};
  }

  override render() {
    const {hasError} = this.state;
    const {children} = this.props;

    return hasError ? null : children;
  }
}

export function QRCodeSVGCsr(props: ComponentProps<typeof QRCodeSVG>): ReactElement | null {
  const isSsrOrHydration = useSsrOrHydration();

  return isSsrOrHydration ? null : (
    <ErrorBoundary>
      <QRCodeSVG {...props} />
    </ErrorBoundary>
  );
}
