import {DeviceVarsContext} from 'providers/DeviceVarsContext';
import {useContext} from 'react';
import {DeviceVars} from 'types/deviceVars';

/**
 * usage:
 * ```
 * const deviceVars = useDeviceVars();
 * const {var1, var2} = useDeviceVars();
 * ```
 */
export function useDeviceVars(): DeviceVars {
  return useContext(DeviceVarsContext);
}

/**
 * usage:
 * ```
 * const var1 = useDeviceVar('var1', '');
 * ```
 */
export function useDeviceVar<K extends keyof DeviceVars>(
  name: K,
  defaultValue?: DeviceVars[K],
): DeviceVars[K] {
  const deviceVars = useContext(DeviceVarsContext);

  if (!(name in deviceVars) && typeof defaultValue !== 'undefined') {
    return defaultValue;
  }

  return deviceVars[name];
}
