import React, {ReactNode} from 'react';

import styles from './index.scss';

export type BadgeButtonStyle = {
  color?: string;
  backgroundColor?: string;
  backgroundImage?: string;
};

type Props = {
  style?: BadgeButtonStyle;
  children: ReactNode;
};

export function BadgeButton({children, style}: Props): JSX.Element {
  return (
    <span style={style} className={styles.BadgeButton}>
      {children}
    </span>
  );
}
