import React from 'react';
import {defineMessages, useIntl} from 'react-intl';

type Props = {
  title?: string;
} & React.SVGAttributes<SVGElement>;

const messages = defineMessages({
  title: {
    description: '[label] Вспомогательный текст на иконке «закрыть»',
    defaultMessage: 'Close',
  },
});

// eslint-disable-next-line import/no-default-export
export default function Close({title, ...props}: Props): JSX.Element {
  const intl = useIntl();

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14" {...props} role="img">
      <title>{title || intl.formatMessage(messages.title)}</title>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M13.7.3c-.4-.4-1-.4-1.4 0L7 5.6 1.7.3C1.3-.1.7-.1.3.3c-.4.4-.4 1 0 1.4L5.6 7 .3 12.3c-.4.4-.4 1 0 1.4.2.2.4.3.7.3.3 0 .5-.1.7-.3L7 8.4l5.3 5.3c.2.2.5.3.7.3.2 0 .5-.1.7-.3.4-.4.4-1 0-1.4L8.4 7l5.3-5.3c.4-.4.4-1 0-1.4z"
      />
    </svg>
  );
}
