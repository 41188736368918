import {Button} from 'components/UIKit/Button';
import {Icon} from 'components/UIKit/Icon';
import {ModalOverlay} from 'components/UIKit/ModalOverlay';
import {Popup} from 'components/UIKit/Popup';
import {Content} from 'components/UIKit/Popup/Content';
import {Footer} from 'components/UIKit/Popup/Footer';
import {Header} from 'components/UIKit/Popup/Header';
import {PopupViewProps} from 'components/UIKit/Popup/PopupProvider';
import {Scope} from 'config';
import styles from 'containers/WelcomePopups/JmtMigrationPopupController/styles.scss';
import {useScope} from 'hooks/useScope';
import React, {ReactElement} from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';

const messages = defineMessages({
  popupTitle: {
    description: '!DNT! [title] заголовок попапа для миграции пользователя с другого сайта',
    defaultMessage: 'Data successfully migrated',
  },
  title: {
    description: '!DNT! [title] заголовок попапа для миграции пользователя с другого сайта',
    defaultMessage: 'Welcome to Джум!',
  },
  description: {
    description: '!DNT! текст попапа для миграции пользователя с другого сайта',
    defaultMessage:
      "You've successfully logged in to the new Joom app for your country. You can keep buying products from all over the world.",
  },
  button: {
    description: '!DNT! [button] кнопка в попапе для миграции пользователя с другого сайта',
    defaultMessage: 'Shop now',
  },
});

export function JmtMigrationSuccessPopup({onClose}: Pick<PopupViewProps, 'onClose'>): ReactElement {
  const scope = useScope();

  return (
    <ModalOverlay onClose={onClose} closeDisabled>
      <Popup width="450px">
        <Header onClose={onClose}>
          <FormattedMessage {...messages.popupTitle} />
        </Header>
        <Content>
          {scope.is(Scope.GLOBAL) && (
            <div className={styles.icon}>
              <Icon name="3d-joom" type="illustration" />
            </div>
          )}
          <div className={styles.title}>
            <FormattedMessage {...messages.title} />
          </div>
          <div className={styles.description}>
            <FormattedMessage {...messages.description} />
          </div>
        </Content>
        <Footer>
          <div className={styles.button}>
            <Button tag="button" type="button" color="primary" onClick={onClose}>
              <FormattedMessage {...messages.button} />
            </Button>
          </div>
        </Footer>
      </Popup>
    </ModalOverlay>
  );
}
