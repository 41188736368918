import {useForceUpdate} from 'hooks/useForceUpdate';
import {useCallback, useMemo, useSyncExternalStore} from 'react';
import defaultBreakpointsMax from 'theme/gridBreakpointsMax.export.scss';
import defaultBreakpointsMin from 'theme/gridBreakpointsMin.export.scss';
import {TypedObject} from 'utils/object/typed';

type BreakpointName = string | null | undefined;
type MatchMediaHookReturn = MediaQueryList | null;

export function matchMedia(mediaQueryString: string | null | undefined): MatchMediaHookReturn {
  if (typeof window !== 'undefined' && mediaQueryString) {
    return window.matchMedia ? window.matchMedia(mediaQueryString) : null;
  }

  return null;
}

export function useMatchMedia(mediaQueryString: string | null): MatchMediaHookReturn {
  const forceUpdate = useForceUpdate();
  const mediaQueryList = useMemo(() => matchMedia(mediaQueryString), [mediaQueryString]);

  const subscribe = useCallback(() => {
    mediaQueryList?.addListener(forceUpdate);
    return () => mediaQueryList?.removeListener(forceUpdate);
  }, [forceUpdate, mediaQueryList]);

  const getSnapshot = useCallback(() => mediaQueryList, [mediaQueryList]);
  const getServerSnapshot = useCallback(() => null, []);

  return useSyncExternalStore(subscribe, getSnapshot, getServerSnapshot);
}

export function useMatchMediaBreakpointOnly(
  breakpointName: BreakpointName,
  breakpoints = defaultBreakpointsMin,
): MatchMediaHookReturn {
  const mediaQueryString = useMemo(() => {
    if (!breakpointName) {
      return null;
    }

    const breakpointNum = parseInt(breakpoints[breakpointName] || '', 10);

    const nextBreakpointNum = TypedObject.values(breakpoints).reduce((currentNum, value) => {
      const nextNum = parseInt(value, 10);

      return nextNum > breakpointNum && nextNum < currentNum ? nextNum : currentNum;
    }, Infinity);

    let result = `(min-width: ${breakpoints[breakpointName]})`;

    if (Number.isFinite(nextBreakpointNum)) {
      result = `${result} and (max-width: ${nextBreakpointNum - 0.1}px)`;
    }

    return result;
  }, [breakpointName, breakpoints]);

  return useMatchMedia(mediaQueryString);
}

export function useMatchMediaBreakpointDown(
  breakpointName: BreakpointName,
  breakpoints = defaultBreakpointsMax,
): MatchMediaHookReturn {
  const mediaQueryString = breakpointName ? `(max-width: ${breakpoints[breakpointName]})` : null;
  return useMatchMedia(mediaQueryString);
}

export function useMatchMediaBreakpointUp(
  breakpointName: BreakpointName,
  breakpoints = defaultBreakpointsMin,
): MatchMediaHookReturn {
  const mediaQueryString = breakpointName ? `(min-width: ${breakpoints[breakpointName]})` : null;
  return useMatchMedia(mediaQueryString);
}
