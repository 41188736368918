import {createRequestAction} from 'store/utils/requestActions';
import {PassportInfo} from 'types/Passport';
import {PersonalDataFormOrigin} from 'types/PersonalData';

export type LoadPassportDataRequest = {
  origin: PersonalDataFormOrigin;
};
export type LoadPassportDataResponse = PassportInfo;

export const loadPassportData = createRequestAction(
  '@personalDataPassport/LOAD_REQUEST',
  '@personalDataPassport/LOAD_RESPONSE',
  '@personalDataPassport/LOAD_FAILURE',
)<LoadPassportDataRequest, LoadPassportDataResponse>();
