import classnames from 'classnames/bind';
import React, {useCallback, useState} from 'react';

import styles from './index.scss';

const cn = classnames.bind(styles);

export type Props = {
  disabled?: boolean;
  error?: boolean;
  label: string | React.ReactElement;
  name: string;
  inputMode?: JSX.IntrinsicElements['input']['inputMode'];
  maxlength?: number;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
};

export const Input = React.memo(function Input({
  disabled = false,
  error = false,
  inputMode,
  label,
  name,
  maxlength,
  onBlur,
  onChange,
  value,
}: Props) {
  const [inputValue, setInputValue] = useState(value);
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const {target} = event;
      setInputValue(target.value);
      if (onChange) {
        onChange(event);
      }
    },
    [onChange],
  );

  return (
    <label className={styles.wrap} htmlFor={name}>
      <input
        className={cn('input', {withValue: inputValue, error})}
        inputMode={inputMode}
        name={name}
        value={value}
        disabled={disabled}
        maxLength={maxlength}
        onBlur={onBlur}
        onChange={handleChange}
      />
      <span className={styles.label}>{label}</span>
    </label>
  );
});
