import PropTypes from 'prop-types';
import ImageBundle from './ImageBundle';
import Gradient from './Gradient';

export const OrderBannerShape = PropTypes.shape({
  title: PropTypes.string,
  message: PropTypes.string,
  url: PropTypes.string,
  textColor: PropTypes.string.isRequired,
  backgroundStartColor: PropTypes.string.isRequired,
  backgroundEndColor: PropTypes.string.isRequired,
  payload: PropTypes.shape({
    pickupInfo: PropTypes.shape({
      items: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          value: PropTypes.string.isRequired,
        }),
      ),
      button: PropTypes.shape({
        text: PropTypes.string.isRequired,
        textColor: PropTypes.string,
        backgroundGradient: Gradient,
        url: PropTypes.string,
      }),
    }),
  }),
  icon: ImageBundle,
});
