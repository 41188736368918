import React from 'react';
import {FormattedNumber} from 'react-intl';
import {ObfuscatedCount as ObfuscatedCountType} from 'types/ObfuscatedCount';

type Props = {
  count?: ObfuscatedCountType;
};

export function ObfuscatedCount({count}: Props): React.ReactElement | null {
  if (!count) {
    return null;
  }

  if (typeof count === 'number') {
    return <FormattedNumber minimumFractionDigits={0} value={count} />;
  }

  switch (count.modifier) {
    case 'floor':
      return (
        <>
          <FormattedNumber minimumFractionDigits={0} value={count.value} />+
        </>
      );
    default: {
      return <FormattedNumber minimumFractionDigits={0} value={count.value} />;
    }
  }
}
