export const DEFAULT_LIMIT = 10;

export type CircularMap<TType> = {
  readonly ids: string[];
  readonly limit: number;
  readonly data: Record<string, TType>;
};

export function create<TType>(
  limit = DEFAULT_LIMIT,
  initialData?: Record<string, TType>,
): CircularMap<TType> {
  const ids = initialData ? Object.keys(initialData) : [];
  const data = initialData || {};
  return {ids, data, limit};
}

export function get<TType>(object: CircularMap<TType>, id: string): TType | null {
  return object.data[id] || null;
}

export function remove<TType, TCircularMap extends CircularMap<TType> = CircularMap<TType>>(
  object: TCircularMap,
  id: string,
): CircularMap<TType> {
  const ids = object.ids.filter((item) => item !== id);
  return {
    ids,
    limit: object.limit,
    data: ids.reduce(
      (acc, item) => {
        acc[item] = object.data[item]!;
        return acc;
      },
      {} as Record<string, TType>,
    ),
  };
}

export function set<TType, TData extends TType | null, TCircularMap extends CircularMap<TType>>(
  object: TCircularMap,
  id: string,
  data: TData,
): CircularMap<TType> {
  if (data === null) {
    return remove<TType>(object, id);
  }

  const ids = [id, ...object.ids.filter((item, index) => item !== id && index < object.limit - 1)];
  return {
    ids,
    limit: object.limit,
    data: ids.reduce(
      (acc, item) => {
        acc[item] = item === id ? data : object.data[item]!;
        return acc;
      },
      {} as Record<string, TType>,
    ),
  };
}
