import {Image} from 'components/Image';
import {Slider} from 'components/Slider';
import {useControls} from 'components/Slider/useControls';
import {useCounter} from 'components/Slider/useCounter';
import {useItem} from 'components/Slider/useItem';
import {useSliderContext} from 'components/Slider/useSliderContext';
import {Button} from 'components/UIKit/Button';
import {Icon as ButtonIcon} from 'components/UIKit/Button/Content';
import {Icon} from 'components/UIKit/Icon';
import React from 'react';
import {useIntl} from 'react-intl';
import {CancelBehaviorProductSliderItem} from 'types/CancelBehavior';

import styles from './index.scss';

const ITEMS_SLIDER_ITEM_PX_FIT = 80;
const ITEMS_SLIDER_ITEM_VW_FIT = {
  xs: '64px',
  sm: '80px',
};

type ItemsSliderItemProps = {
  index: number;
  item: CancelBehaviorProductSliderItem;
};

function ItemsSliderItem({index, item}: ItemsSliderItemProps): JSX.Element {
  const [itemRef, attrs] = useItem<HTMLLIElement>(index);
  const intl = useIntl();

  return (
    <li className={styles.item} ref={itemRef} {...attrs}>
      <Image
        contain
        height="100%"
        image={item.image}
        multiply
        pxFit={ITEMS_SLIDER_ITEM_PX_FIT}
        vwFit={ITEMS_SLIDER_ITEM_VW_FIT}
        width="100%"
      />
      <div className={styles.itemQuantity}>
        <span className={styles.quantityCaption}>{intl.formatNumber(item.quantity)}</span>
        {item.showSpecialPriceIcon ? (
          <span className={styles.specialPriceIcon}>
            <Icon height={12} name="lightning-filled-16" type="mono" width={12} />
          </span>
        ) : null}
      </div>
    </li>
  );
}

type ItemsSliderScrollerProps = {
  items: CancelBehaviorProductSliderItem[];
};

function ItemsSliderScroller({items}: ItemsSliderScrollerProps): JSX.Element {
  const {viewRef} = useSliderContext<HTMLUListElement, HTMLLIElement>();
  const {api} = useCounter();
  const [prevHidden, nextHidden] = useControls();

  return (
    <div className={styles.items}>
      <ul className={styles.itemsList} ref={viewRef}>
        {items.map((item, index) => (
          <ItemsSliderItem
            index={index}
            item={item}
            // eslint-disable-next-line react/no-array-index-key
            key={index}
          />
        ))}
      </ul>
      <div className={styles.itemsPrevButton} hidden={prevHidden}>
        <Button
          color="white"
          onClick={api.scrollPrev}
          shape="circle"
          size="small"
          tag="button"
          type="button"
        >
          <ButtonIcon>
            <Icon height={24} name="chevron-left-linear-24" type="mono" width={24} />
          </ButtonIcon>
        </Button>
      </div>
      <div className={styles.itemsNextButton} hidden={nextHidden}>
        <Button
          color="white"
          onClick={api.scrollNext}
          shape="circle"
          size="small"
          tag="button"
          type="button"
        >
          <ButtonIcon>
            <Icon height={24} name="chevron-right-linear-24" type="mono" width={24} />
          </ButtonIcon>
        </Button>
      </div>
    </div>
  );
}

type ItemsSliderProps = {
  items: CancelBehaviorProductSliderItem[];
};

export function ItemsSlider(props: ItemsSliderProps): JSX.Element {
  return (
    <Slider>
      <ItemsSliderScroller {...props} />
    </Slider>
  );
}
