import cn from 'classnames';
import {Slider} from 'components/Slider';
import {useCounter} from 'components/Slider/useCounter';
import {useItem} from 'components/Slider/useItem';
import {useSliderContext} from 'components/Slider/useSliderContext';
import {Button} from 'components/UIKit/Button';
import {Icon as ButtonIcon} from 'components/UIKit/Button/Content';
import {Icon} from 'components/UIKit/Icon';
import {useAnalytics} from 'hooks/useAnalytics';
import {useBot} from 'hooks/useBot';
import {usePreviewEvent} from 'hooks/usePreviewEvent';
import React, {useCallback, useRef} from 'react';
import {Link} from 'types/PageMetadata/Link';
import {LinksBlock} from 'types/PageMetadata/LinksBlock';

import styles from './index.scss';

type LinksSliderItemProps = {
  index: number;
  link: Link;
  onClick?: (link: Link) => void;
  withDarkTheme?: boolean;
};

function LinksSliderItem({
  index,
  link,
  onClick,
  withDarkTheme = false,
}: LinksSliderItemProps): JSX.Element {
  const [ref, attrs] = useItem<HTMLLIElement>(index);

  const handleClick = useCallback(() => {
    onClick?.(link);
  }, [link, onClick]);

  return (
    <li className={styles.item} ref={ref} {...attrs}>
      <Button
        color={withDarkTheme ? 'ghost' : 'gray'}
        onClick={handleClick}
        size="small"
        to={link.href}
      >
        {link.anchor}
      </Button>
    </li>
  );
}

type LinksSliderScrollerProps = {
  linksBlock: LinksBlock;
  productId?: string;
  withDarkTheme?: boolean;
};

function LinksSliderScroller({
  linksBlock,
  productId = '',
  withDarkTheme = false,
}: LinksSliderScrollerProps): JSX.Element | null {
  const rootRef = useRef<HTMLDivElement>(null);
  const {viewRef} = useSliderContext<HTMLUListElement, HTMLLIElement>();
  const {api, counter} = useCounter();
  const isBot = useBot();

  // Analytics
  const analytics = useAnalytics();
  const sendControlButtonClickEvent = useCallback(() => {
    analytics.sendEvent({
      type: 'productSeoButtonClick',
      payload: {productId},
    });
  }, [analytics, productId]);
  const sendLinkClickEvent = useCallback(
    (text: string) => {
      analytics.sendEvent({
        type: 'productSeoClick',
        payload: {
          productId,
          text,
        },
      });
    },
    [analytics, productId],
  );

  usePreviewEvent({
    rootRef,
    previewEvent: {
      type: 'productSeoPreview',
      payload: {productId},
    },
  });

  // Controls
  const hidePrevButton = Boolean(!counter || counter.index === 0);
  const hideNextButton = Boolean(
    !counter || counter.index + counter.inView.length === counter.total,
  );
  const handlePrevClick = useCallback(() => {
    api.scrollPrev();
    sendControlButtonClickEvent();
  }, [api, sendControlButtonClickEvent]);
  const handleNextClick = useCallback(() => {
    api.scrollNext();
    sendControlButtonClickEvent();
  }, [api, sendControlButtonClickEvent]);

  // Links
  const links = linksBlock?.links;
  const handleLinkClick = useCallback(
    (link: Link) => {
      sendLinkClickEvent(link.anchor);
    },
    [sendLinkClickEvent],
  );

  if (!Array.isArray(links) || links.length === 0) {
    return null;
  }

  return (
    <div
      className={cn(
        styles.root,
        isBot && styles.multiline,
        !hidePrevButton && styles.hideStart,
        !hideNextButton && styles.hideEnd,
      )}
      ref={rootRef}
    >
      <div className={styles.wrap}>
        <ul className={styles.list} ref={viewRef}>
          {links.map((link, index) => (
            <LinksSliderItem
              index={index}
              // Uniq key
              // eslint-disable-next-line react/no-array-index-key
              key={`${link.anchor}${index}`}
              link={link}
              onClick={handleLinkClick}
              withDarkTheme={withDarkTheme}
            />
          ))}
        </ul>
        <div className={styles.controlPrev} hidden={hidePrevButton}>
          <Button
            color="white"
            onClick={handlePrevClick}
            shape="circle"
            size="small"
            tag="button"
            type="button"
          >
            <ButtonIcon>
              <Icon height={24} name="chevron-left-linear-24" type="mono" width={24} />
            </ButtonIcon>
          </Button>
        </div>
        <div className={styles.controlNext} hidden={hideNextButton}>
          <Button
            color="white"
            onClick={handleNextClick}
            shape="circle"
            size="small"
            tag="button"
            type="button"
          >
            <ButtonIcon>
              <Icon height={24} name="chevron-right-linear-24" type="mono" width={24} />
            </ButtonIcon>
          </Button>
        </div>
      </div>
    </div>
  );
}

type Props = LinksSliderScrollerProps;

export function LinksSlider(props: Props): JSX.Element | null {
  return (
    <Slider>
      <LinksSliderScroller {...props} />
    </Slider>
  );
}
