import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {getData} from 'country-list';

export default function ForcedRegion(props) {
  const {currentRegion, onChange} = props;
  const regions = getData();
  const sortedRegions = useMemo(() => {
    const result = regions.slice();
    result.sort((a, b) => (a.name > b.name ? 1 : -1));

    return result;
  }, [regions]);
  const onSelectChange = (event) => {
    onChange(event.target.value);
  };
  return (
    <select
      name="forcedRegion"
      defaultValue={currentRegion}
      onChange={onSelectChange}
      style={{width: '200px'}}
    >
      {sortedRegions.map((region) => (
        <option value={region.code} key={region.code}>
          {region.name}
        </option>
      ))}
    </select>
  );
}

ForcedRegion.propTypes = {
  currentRegion: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
