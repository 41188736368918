import {ApiClient} from 'helpers/ApiClient';
import {discardableByAny} from 'helpers/discardable';
import produce from 'immer';
import {AnyAction} from 'redux';
import {enhanceCart} from 'store/enhancer/cart';
import {handleContentListProducts} from 'store/enhancer/contentList';
import {setCartCount} from 'store/modules/cartCount';
import {loadArbitraryContentList as loadArbitraryContentListAction} from 'store/modules/contentListArbitrary/actions';
import {RootState} from 'store/rootReducer';
import {requestActionCreator} from 'store/utils/requestActions';
import {Cart, CartRaw, CouponSelection} from 'types/Cart';
import {CartItem} from 'types/CartGroup/CartItem';
import {ClientBackendResponse} from 'types/ClientBackendResponse';
import {Coupon} from 'types/Coupon';
import {CouponCard} from 'types/CouponCard';
import {Error} from 'types/Error';
import {createReducer, FuncAction, isActionOf, Store} from 'typesafe-actions';
import {arrayToObject, pushOrUpdate} from 'utils/array';
import {SelectionType, setCartSelection} from 'utils/cartSelection';
import {createNetworkError} from 'utils/error';
import {identity, isDefAndNotNull} from 'utils/function';
import {assignSetByPath, getValueByPath, objectFilter} from 'utils/object';
import {takeAny, takeLast} from 'utils/promise';

import {
  addProductToCollection as addProductToCollectionAction,
  removeProductFromCollection as removeProductFromCollectionAction,
} from '../productCollections/actions';
import {
  CartItemError,
  clearCartItemError as clearCartItemErrorAction,
  clearCartItemsError as clearCartItemsErrorAction,
  loadCart as loadCartAction,
  patchCartItems as patchCartItemsAction,
  setCart as setCartAction,
  setCartError as setCartErrorAction,
  setCartItemError as setCartItemErrorAction,
  updateCart as updateCartAction,
} from './actions';
import {getCartSelectionRequestData} from './selection';

export type CartState = {
  data: Cart | null;
  loaded: boolean;
  error: Error | null;
  itemErrors: CartItemError[];
};

const initialState: CartState = {
  data: null,
  loaded: false,
  error: null,
  itemErrors: [],
};

const EMPTY_CART_BODY: ClientBackendResponse<CartRaw> = {
  payload: {
    isPurchasable: false,
    selectionInfo: {
      selectedItemIds: [],
    },
    extraData: {
      data: {
        cart: {
          items: [],
        },
      },
    },
    loadedTimeMs: 0,
  },
};

const innerReducer = createReducer(initialState)
  .handleAction(setCartAction, (state, {payload}) =>
    produce(state, (draft) => {
      draft.error = null;
      draft.data = payload;

      const prevRelatedProductsListMap = arrayToObject(
        state.data?.relatedProductsList,
        (item) => item.id,
      );
      draft.data.relatedProductsList?.forEach((item) => {
        const prevRelatedProductsList = prevRelatedProductsListMap[item.id];

        if (prevRelatedProductsList && prevRelatedProductsList.id === item.id) {
          const prevProductsList = prevRelatedProductsList.content.productsList;

          item.content.productsList.items = prevProductsList.items;
          item.content.productsList.nextPageToken = prevProductsList.nextPageToken;
        }
      });
    }),
  )
  .handleAction(setCartErrorAction, (state, {payload}) => ({
    ...state,
    error: payload,
  }))
  .handleAction(setCartItemErrorAction, (state, {payload}) => ({
    ...state,
    itemErrors: pushOrUpdate(state.itemErrors, payload, (a, b) => a.id === b.id),
  }))
  .handleAction(clearCartItemErrorAction, (state, {payload}) => ({
    ...state,
    itemErrors: state.itemErrors.filter((item) => item.id !== payload),
  }))
  .handleAction(clearCartItemsErrorAction, (state, {payload}) => ({
    ...state,
    itemErrors: payload ? state.itemErrors.filter((item) => !payload.includes(item.id)) : [],
  }))
  .handleAction(patchCartItemsAction, (state, {payload}) => {
    const path = ['data', 'cartItems'] as const;
    const list = getValueByPath(state, ...path);
    if (!list) {
      return state;
    }
    return assignSetByPath(
      state,
      path,
      list.map((item) =>
        payload[item.id]
          ? {
              ...item,
              ...payload[item.id],
            }
          : item,
      ),
    );
  })
  .handleAction(loadArbitraryContentListAction.success, (state, {meta, payload}) =>
    produce(state, (draft) => {
      draft.data?.relatedProductsList?.forEach((item) => {
        if (item.id === meta.itemId) {
          item.content.productsList.items.push(...payload.items);
          item.content.productsList.nextPageToken = payload.nextPageToken;
        }
      });
    }),
  )
  .handleAction(
    [addProductToCollectionAction.success, removeProductFromCollectionAction.success],
    (state, action) =>
      produce(state, (draft) => {
        handleContentListProducts(draft.data?.relatedProductsList, (product) => {
          if (product.id === action.meta.itemKey.productId) {
            product.favorite = isActionOf(addProductToCollectionAction.success, action);
          }
        });
      }),
  );

export const reducer = discardableByAny(innerReducer);

function getState(globalState: RootState): CartState {
  return globalState.groupedCart.cart;
}

export function getCart(globalState: RootState): Cart | null {
  const {data} = getState(globalState);

  return data || null;
}

export function getCartItems(globalState: RootState): CartItem[] | undefined | null {
  const cart = getCart(globalState);
  return cart ? cart.cartItems : null;
}

export function getCartSelectedItems(globalState: RootState): CartItem[] | undefined | null {
  const cart = getCart(globalState);
  return cart ? cart.cartItems?.filter(({isSelected}) => isSelected) : null;
}

export function getCartSelectedCoupon(globalState: RootState): Coupon | undefined | null {
  const cart = getCart(globalState);
  return cart && cart.coupons ? cart.coupons.find(({isSelected}) => isSelected) : null;
}

export function getCartError(globalState: RootState): Error | null {
  const {error} = getState(globalState);
  return error || null;
}

export const getCartItemError =
  (id: string) =>
  (globalState: RootState): CartItemError | undefined => {
    const {itemErrors} = getState(globalState);

    return itemErrors.find((item) => item && id === item.id);
  };

export function isCartLoaded(globalState: RootState): boolean {
  const cart = getCart(globalState);
  return Boolean(cart);
}

export function setCart(cart: CartRaw): FuncAction {
  return ({dispatch}, client) => {
    const count = getValueByPath(cart, 'cartItems', 'length') || 0;
    dispatch(setCartCount(count));

    dispatch(
      setCartAction(
        enhanceCart(cart, {
          language: client.device.getLanguage(),
          currency: client.device.getCurrency(),
        }),
      ),
    );
  };
}

export const setCartError = setCartErrorAction;

export function setCartItemsTemporarySelected(
  ids: string[],
  isTemporarySelected: boolean | undefined | null,
): AnyAction {
  const value = {isTemporarySelected};
  return patchCartItemsAction(arrayToObject(ids, identity, () => value));
}

export function setCartItemTemporaryQuantity(
  id: string,
  temporaryQuantity: number | undefined | null,
): AnyAction {
  return patchCartItemsAction({[id]: {temporaryQuantity}});
}

export const setCartItemError = setCartItemErrorAction;

export const clearCartItemError = clearCartItemErrorAction;

export const clearCartItemsError = clearCartItemsErrorAction;

const takeLoad = __SERVER__ ? takeAny() : takeLast();

function load(
  client: ApiClient,
  {dispatch, getState}: Store,
  couponSelection: CouponSelection | undefined | null,
  noCartItemSelection?: boolean,
) {
  const body = objectFilter(
    {
      cartItemSelection: noCartItemSelection
        ? null
        : getCartSelectionRequestData(client.storages.local),
      couponSelection,
    },
    isDefAndNotNull,
  );

  const isEphemeral = client.device?.isEphemeral();

  return takeLoad(
    // Ephemeral users can't really have any cart items, so we don't have to go for them to backend
    () =>
      !isEphemeral
        ? client.api.post<ClientBackendResponse<CartRaw>>('/cart/get', {body})
        : Promise.resolve({body: EMPTY_CART_BODY}),
    (promise): Promise<unknown> =>
      promise
        .then(({body: {payload}}) => dispatch(setCart(payload)))
        .then(() => getCart(getState()))
        .then((cart) => {
          if (client.device.getDeviceVar('backendCartItemsSelection')?.enabled) {
            setCartSelection(
              client.storages.local,
              SelectionType.IDS,
              cart?.selectionInfo?.selectedItemIds,
            );
          }
          return cart;
        })
        .catch((error) => dispatch(setCartError(error)))
        .finally(() => {
          const cartItems = getCartItems(getState());
          const cartItemsIds = cartItems ? cartItems.map(({id}) => id) : null;
          if (cartItemsIds) {
            dispatch(setCartItemsTemporarySelected(cartItemsIds, null));
          }
        }),
    () => Promise.resolve(null),
  );
}

export function loadCart(couponSelection: CouponSelection = {byDefault: {}}): FuncAction {
  return requestActionCreator(loadCartAction, couponSelection, (store, client) => {
    const noItemSelection =
      client.device.getDeviceVar('backendCartItemsSelection')?.enabled || false;
    return load(client, store, couponSelection, noItemSelection);
  });
}

export function getCouponRequestData(
  coupon: Coupon | CouponCard | undefined | null,
): CouponSelection {
  return coupon ? {byId: {couponId: coupon.id}} : {byDefault: {}};
}

export function getCouponSelection(getGlobalState: () => RootState): CouponSelection {
  const coupon = getCartSelectedCoupon(getGlobalState());
  return getCouponRequestData(coupon);
}

function getCartExtraData(getGlobalState: () => RootState): Cart['extraData'] {
  const cart = getCart(getGlobalState());
  return cart?.extraData;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getCartSelection(client: ApiClient, getGlobalState: () => RootState) {
  const noItemSelection = client.device.getDeviceVar('backendCartItemsSelection')?.enabled;
  return {
    cartItemSelection: noItemSelection ? null : getCartSelectionRequestData(client.storages.local),
    couponSelection: getCouponSelection(getGlobalState),
    extraData: getCartExtraData(getGlobalState),
  };
}

export function updateCart(
  couponSelection: CouponSelection | null = null,
  noCartItemSelection: boolean = false,
): FuncAction<Promise<unknown>> {
  return requestActionCreator(updateCartAction, couponSelection, (store, client) => {
    if (!isCartLoaded(store.getState())) {
      return Promise.reject(createNetworkError('cart.not_loaded'));
    }
    return load(
      client,
      store,
      couponSelection || getCouponSelection(store.getState),
      noCartItemSelection,
    );
  });
}

export function isCartItemSelected(item: CartItem): boolean | undefined {
  return typeof item.isTemporarySelected === 'boolean' ? item.isTemporarySelected : item.isSelected;
}

export function getCartItemQuantity(item: CartItem): number {
  return typeof item.temporaryQuantity === 'number' ? item.temporaryQuantity : item.quantity;
}
