import {IframeClickHandler} from 'components/IframeClickHandler';
import {ecsError} from 'helpers/log/ECS/ecsError';
import {useLocale} from 'hooks/useLocale';
import {useLogger} from 'hooks/useLogger';
import {useMergedRef} from 'hooks/useMergedRef';
import {useScope} from 'hooks/useScope';
import React, {useEffect, useMemo, useRef} from 'react';
import {defineMessages, FormattedMessage, useIntl} from 'react-intl';

import {
  TRUSTPILOT_CONFIG_DEFAULT,
  TRUSTPILOT_LOCALE_DEFAULT,
  TRUSTPILOT_REVIEW_SRC_BASE,
  TRUSTPILOT_SUPPORTED_LOCALES,
} from '../consts';
import {TrustpilotConfig} from '../types';
import {loadTrustpilot} from '../utils/loadTrustpilot';
import styles from './styles.scss';

type Props = {
  config?: TrustpilotConfig;
  templateId?: string;
  businessUnitId?: string;
  onClick?: () => void;
};

const messages = defineMessages({
  iframeTitle: {
    defaultMessage: 'Trustpilot',
    description: '[label] Trustpilot service name',
  },
});

export const TrustpilotWidget = React.forwardRef<HTMLElement, Props>(function TrustpilotWidget(
  {config = TRUSTPILOT_CONFIG_DEFAULT, businessUnitId, templateId, onClick},
  forwardedRef,
): JSX.Element {
  const scope = useScope();
  const logger = useLogger('TrustpilotBadge');
  const locale = useLocale();

  const widgetLocale = useMemo(
    () =>
      (config.locale ?? TRUSTPILOT_SUPPORTED_LOCALES.includes(locale))
        ? locale
        : TRUSTPILOT_LOCALE_DEFAULT,
    [config, locale],
  );

  const innerRef = useRef<HTMLElement>(null);
  const ref = useMergedRef(innerRef, forwardedRef);
  const intl = useIntl();

  useEffect(() => {
    if (!(templateId && businessUnitId)) {
      logger.warn('Trustpilot devicevar is not configured');
      return;
    }

    logger.info('Trustpilot will load');
    loadTrustpilot()
      .then(() => {
        logger.info('Trustpilot ready');

        const element = innerRef.current;

        if (window.Trustpilot && element) {
          logger.info('Trustpilot will mount');
          window.Trustpilot.loadFromElement(element, true);
        }
      })
      .catch((error) => {
        logger.error({
          error: ecsError(error),
          message: 'An unexpected error occurred while loading Trustpilot script',
        });
      });
    // It is necessary to only reload the widget if templateId or businessUnitId changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateId, businessUnitId]);

  const props = {
    'data-businessunit-id': businessUnitId,
    'data-font-family': config.fontFamily,
    'data-locale': widgetLocale,
    'data-style-height': config.styleHeight,
    'data-style-width': config.styleWidth,
    'data-template-id': templateId,
    'data-text-color': config.textColor,
    'data-theme': config.theme,
    className: styles.iframeWrapper,
    innerRef: ref,
    title: intl.formatMessage(messages.iframeTitle),
  };

  const content = (
    <a
      href={`${TRUSTPILOT_REVIEW_SRC_BASE}/${scope.hostname}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <FormattedMessage {...messages.iframeTitle} />
    </a>
  );

  return onClick ? (
    <IframeClickHandler {...props} onClick={onClick}>
      {content}
    </IframeClickHandler>
  ) : (
    <div {...props} ref={ref}>
      {content}
    </div>
  );
});
