import Checkbox from 'components/Checkbox';
import ErrorMessage from 'components/ErrorMessage';
import ErrorShape from 'shapes/Error';
import {PageError} from 'components/PageError';
import {PageLoading} from 'components/PageLoading';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import UserPreferencesShape from 'shapes/UserPreferences';
import {connect} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {
  getUserPreferences,
  isUserPreferencesLoading,
  getUserPreferencesError,
  getUserPreferencesSavingError,
  loadUserPreferences,
  saveUserPreferences,
} from 'store/modules/userPreferences';
import {isFacebookEnabled} from 'store/modules/preferences';
import {ThirdParty} from 'helpers/ApiClient/Analytics';
import styles from './index.scss';

class ThirdParties extends Component {
  static propTypes = {
    error: ErrorShape,
    loading: PropTypes.bool.isRequired,
    loadUserPreferences: PropTypes.func.isRequired,
    isFacebookEnabled: PropTypes.bool.isRequired,
    native: PropTypes.bool,
    preferences: UserPreferencesShape,
    saveUserPreferences: PropTypes.func.isRequired,
    savingError: ErrorShape,
  };

  static defaultProps = {
    error: null,
    native: false,
    preferences: null,
    savingError: null,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (!this.props.preferences && !this.props.loading) {
      this.props.loadUserPreferences();
    }
  }

  handleSave(type, enabled) {
    this.setState({[type]: true});
    this.props
      .saveUserPreferences(
        {
          thirdParty: {type, enabled},
        },
        true,
      )
      .catch((err) => this.setState({[type]: false}));
  }

  renderItem(item) {
    if (item.type === ThirdParty.FACEBOOK && !this.props.isFacebookEnabled) {
      return null;
    }

    const {native} = this.props;
    const loading = !!this.state[item.type];
    return (
      <div className={styles.item} key={item.type}>
        <span className={styles.title}>{item.title}</span>
        <span className={styles.checkbox}>
          <Checkbox
            onCheck={(checked) => this.handleSave(item.type, checked)}
            checked={item.enabled}
            loading={loading}
            theme={native ? 'darkblue' : undefined}
          />
        </span>
      </div>
    );
  }

  render() {
    const {native, error, loading, preferences, savingError} = this.props;

    if (error) {
      return <PageError error={error} noButton />;
    }

    if (loading || !preferences) {
      return <PageLoading />;
    }

    const {thirdParties} = preferences;
    return (
      <div className={`${styles.parent} ${native ? styles.native : ''}`}>
        <p className={styles.text}>
          <FormattedMessage
            description="English text from lawyer: In order to improve your user experience with JOOM we collaborate with other companies which help us make your customer experience better. You can opt-out sending events about your activity on our platform to those third parties:"
            defaultMessage="In order to improve your user experience and the quality of our content, we share analytical data about your activities with our partner companies. You can opt out of having information about your activities shared with:"
          />
        </p>
        {thirdParties && thirdParties.length ? (
          <div className={styles.list}>{thirdParties.map((item) => this.renderItem(item))}</div>
        ) : null}
        {savingError ? (
          <div className={styles.error}>
            <ErrorMessage error={savingError} internal noRequestId />
          </div>
        ) : null}
      </div>
    );
  }
}

export default connect(
  (state) => ({
    error: getUserPreferencesError(state),
    loading: isUserPreferencesLoading(state),
    preferences: getUserPreferences(state),
    savingError: getUserPreferencesSavingError(state),
    isFacebookEnabled: isFacebookEnabled(state),
  }),
  {
    loadUserPreferences,
    saveUserPreferences,
  },
)(ThirdParties);
