import cn from 'classnames';
import {ConditionalWrapper} from 'components/ConditionalWrapper';
import {configureSkeleton} from 'components/Skeleton/configureSkeleton';
import {useMergedRef} from 'hooks/useMergedRef';
import React, {
  createContext,
  CSSProperties,
  DetailedHTMLProps,
  forwardRef,
  HTMLAttributes,
  ReactElement,
  ReactNode,
  Ref,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';
import throttle from 'utils/throttle';

import styles from './styles.scss';

const messages = defineMessages({
  loading: {
    description: 'текст для людей с ограниченными возможностями',
    defaultMessage: 'Loading...',
  },
});

type SkeletonApi = {
  configure(element: HTMLElement): void;
};

type SkeletonConfig = {
  speed: number;
  backgroundColor: string;
  foregroundColor: string;
};

const DEFAULT_CONFIG: SkeletonConfig = {
  speed: 250,
  backgroundColor: '#F2F3F7',
  foregroundColor: '#EEEEF3',
};

const SkeletonContext = createContext<SkeletonApi | undefined>(undefined);

type SkeletonContainerProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  children?: ReactNode;
  config?: Partial<SkeletonConfig>;
};

export const SkeletonContainer = forwardRef(function SkeletonContainer(
  {
    children,
    config: {
      speed = DEFAULT_CONFIG.speed,
      foregroundColor = DEFAULT_CONFIG.foregroundColor,
      backgroundColor = DEFAULT_CONFIG.backgroundColor,
    } = {},
    ...props
  }: SkeletonContainerProps,
  outerRef: Ref<HTMLElement>,
): ReactElement {
  const containerRef = useRef<HTMLDivElement>(null);

  const api: SkeletonApi = useMemo(() => {
    return {
      configure(skeletonElement) {
        if (containerRef.current) {
          configureSkeleton(containerRef.current, skeletonElement, speed);
        }
      },
    };
  }, [speed]);

  const style = useMemo(
    () =>
      ({
        ...props.style,
        '--skeletonBackgroundColor': backgroundColor,
        '--skeletonForegroundColor': foregroundColor,
      }) as CSSProperties,
    [backgroundColor, foregroundColor, props.style],
  );

  const ref = useMergedRef(outerRef, containerRef);

  return (
    <SkeletonContext.Provider value={api}>
      <div
        {...props}
        ref={ref}
        style={style}
        data-skeleton-container
        data-skeleton-config-speed={speed}
      >
        {children}
      </div>
    </SkeletonContext.Provider>
  );
});

type MediaStyleConfig = {
  width?: number | string;
  height?: number | string;
  borderRadius?: number | string;
  marginTop?: number | string;
  marginRight?: number | string;
  marginBottom?: number | string;
  marginLeft?: number | string;
  display?: string;
};

function useStyle(
  style: MediaStyleConfig | undefined = {},
  mobileStyle: MediaStyleConfig | undefined = {},
): CSSProperties {
  const numValue = (value: number | string | undefined) =>
    typeof value === 'number' ? `${value}px` : value;

  return useMemo(
    () =>
      ({
        // use abbreviations to reduce html size
        '--w': numValue(style.width),
        '--h': numValue(style.height),
        '--br': numValue(style.borderRadius),
        '--mt': numValue(style.marginTop),
        '--mr': numValue(style.marginRight),
        '--mb': numValue(style.marginBottom),
        '--ml': numValue(style.marginLeft),
        '--d': style.display,
        '--wM': numValue(mobileStyle.width),
        '--hM': numValue(mobileStyle.height),
        '--brM': numValue(mobileStyle.borderRadius),
        '--mtM': numValue(mobileStyle.marginTop),
        '--mrM': numValue(mobileStyle.marginRight),
        '--mbM': numValue(mobileStyle.marginBottom),
        '--mlM': numValue(mobileStyle.marginLeft),
        '--dM': mobileStyle.display,
      }) as CSSProperties,
    // use all values as deps because style objects change on every render
    [
      style.width,
      style.height,
      style.borderRadius,
      style.marginTop,
      style.marginRight,
      style.marginBottom,
      style.marginLeft,
      style.display,
      mobileStyle.width,
      mobileStyle.height,
      mobileStyle.borderRadius,
      mobileStyle.marginTop,
      mobileStyle.marginRight,
      mobileStyle.marginBottom,
      mobileStyle.marginLeft,
      mobileStyle.display,
    ],
  );
}

type SkeletonProps = {
  style?: MediaStyleConfig;
  // inherits `style` and override it
  mobileStyle?: MediaStyleConfig;
  // 100% width and height
  contain?: boolean;
  // height and border radius from font size, with top and bottom indents like line-height
  text?: boolean;
};

export function Skeleton({style, mobileStyle, contain, text}: SkeletonProps): ReactElement {
  const api = useContext(SkeletonContext);

  if (__DEVELOPMENT__ && !api) {
    throw new Error('Skeleton component must be inside SkeletonContainer');
  }

  const ref = useRef<HTMLDivElement>(null);

  const configure = useCallback(() => {
    if (api && ref.current) {
      api.configure(ref.current);
    }
  }, [api]);

  useEffect(() => {
    const throttled = throttle(configure);

    window.addEventListener('resize', throttled);

    return () => window.removeEventListener('resize', throttled);
  }, [configure]);

  const resultStyle = useStyle(style, mobileStyle);

  // reconfigure when resultStyle changes
  useEffect(configure, [configure, resultStyle]);

  return (
    <ConditionalWrapper
      condition={Boolean(text)}
      // eslint-disable-next-line react/jsx-no-bind
      wrapper={(children) => (
        <div className={styles.textWrapper} style={resultStyle}>
          {children}
        </div>
      )}
    >
      <>
        <div
          ref={ref}
          className={cn(styles.skeleton, text && styles.text, contain && styles.contain)}
          style={text ? undefined : resultStyle}
          aria-live="polite"
          aria-busy
          data-skeleton-item
        >
          <span className={styles.loadingText}>
            <FormattedMessage {...messages.loading} />
          </span>
        </div>
        {text && <>&zwnj;</>}
      </>
    </ConditionalWrapper>
  );
}
