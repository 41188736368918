import {TrustpilotConfig} from './types';

export const TRUSTPILOT_SCRIPT_SRC =
  'https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';

export const TRUSTPILOT_REVIEW_SRC_BASE = `https://www.trustpilot.com/review`;

export const TRUSTPILOT_OBJECT = 'tp';

export const TRUSTPILOT_CONFIG_DEFAULT: TrustpilotConfig = {
  styleHeight: '77px',
  styleWidth: '110px',
};

export const TRUSTPILOT_LOCALE_DEFAULT = 'en-US';

export const TRUSTPILOT_SUPPORTED_LOCALES = [
  'da-DK',
  'de-AT',
  'de-CH',
  'de-DE',
  'en-AU',
  'en-CA',
  'en-GB',
  'en-IE',
  'en-NZ',
  'en-US',
  'es-ES',
  'fi-FI',
  'fr-BE',
  'fr-FR',
  'it-IT',
  'ja-JP',
  'nb-NO',
  'nl-BE',
  'nl-NL',
  'pl-PL',
  'pt-BR',
  'pt-PT',
  'sv-SE',
  'zh-CN',
  /**
   * Badge in Russian works, but Trustpilot site not
   * @see https://joom-team.slack.com/archives/C03JF6YHJTY/p1691756151384329
   */
  // 'ru-RU',
];

export const TRUSTPILOT_PRODUCT_CARD_FEATURE = {
  businessUnitId: '59f832a60000ff0005af2108',
  templateId: '5419b6ffb0d04a076446a9af',
  mobileTemplateId: '5419b732fbfb950b10de65e5',
};
