import {createApiError} from 'helpers/ApiClient/Transport/Response';
import {ApiError, isApiError} from 'types/Error';
import {createNetworkError, getErrorType} from 'utils/error';

const PAY_CANCELLATION_ERROR_TYPE = 'pay_cancellation';
const SESSION_NOT_LOADED_ERROR_TYPE = 'cart.session.not_loaded';
const SESSION_RETRY_ERROR_TYPES = ['checkout.price_was_changed', 'checkout.partial_invalid'];
const DB_NOT_FOUND_ERROR_TYPE = 'db.not_found';
const VALIDATION_ERRORS = ['payments.invalid_phone_number'];

export function createPayCancellationError(): ApiError {
  return createApiError(PAY_CANCELLATION_ERROR_TYPE, 400);
}

export function isPayCancellationError(error: unknown): boolean {
  return isApiError(error) && error.type === PAY_CANCELLATION_ERROR_TYPE;
}

export function createSessionNotLoadedError(): ApiError {
  return createNetworkError(SESSION_NOT_LOADED_ERROR_TYPE);
}

export function isSessionNotLoadedError(error: unknown): boolean {
  return isApiError(error) && error.type === SESSION_NOT_LOADED_ERROR_TYPE;
}

export function isSessionRetryError(error: unknown): boolean {
  return isApiError(error) && SESSION_RETRY_ERROR_TYPES.includes(getErrorType(error));
}

export function isDBNotFoundError(error: unknown): boolean {
  return isApiError(error) && error.type === DB_NOT_FOUND_ERROR_TYPE;
}

export function isValidationError(error: unknown): boolean {
  return isApiError(error) && VALIDATION_ERRORS.includes(error.type);
}
