import PropTypes from 'prop-types';
import {CartPricingValueShape} from './CartPricingValue';
import GradientShape from './Gradient';

const ItemShape = {
  // Название для цены.
  title: PropTypes.string.isRequired,
  // Значение цены.
  value: CartPricingValueShape,
  // Нужно ли рисовать разделитель над ценой.
  dividerAbove: PropTypes.bool,
  // Градиентная заливка для текста
  tintGradient: GradientShape,
  // Тип для цены.
  type: PropTypes.string,
};

// Дочерние строки для этой цены.
ItemShape.children = PropTypes.arrayOf(PropTypes.shape(ItemShape));

export const CartPricingItemShape = PropTypes.shape(ItemShape);
