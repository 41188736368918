import {ApiResponse} from 'helpers/ApiClient/Transport/Response';
import {requestActionCreator} from 'store/utils/requestActions';
import {Cart, CartRaw} from 'types/Cart';
import {CartItem} from 'types/CartGroup/CartItem';
import {ClientBackendResponse} from 'types/ClientBackendResponse';
import {ApiError, Error} from 'types/Error';
import {OpenPayload} from 'types/OpenPayload';
import {ProductPreOffer} from 'types/ProductPreOffer';
import {FuncAction, Store} from 'typesafe-actions';
import {SelectionType} from 'utils/cartSelection';
import {combineReducers} from 'utils/combineReducers';
import {createNetworkError, getErrorType, unwrapErrorObject} from 'utils/error';

import {
  addCartItem as addCartItemAction,
  changeItemQuantity as changeItemQuantityAction,
  removeCartItems as removeCartItemsAction,
  removePaymentMethod as removePaymentMethodAction,
  selectCartItems as selectCartItemsAction,
} from './actions';
import {
  clearCartItemsError,
  getCart,
  getCartItems,
  getCartSelection,
  isCartItemSelected,
  isCartLoaded,
  reducer as cartReducer,
  setCart,
  setCartError,
  setCartItemError,
  setCartItemsTemporarySelected,
  setCartItemTemporaryQuantity,
  updateCart,
} from './cart';
import {setCartSelection} from './selection';
import {reducer as sessionReducer, updateCartSession} from './session';

export const reducer = combineReducers({
  session: sessionReducer,
  cart: cartReducer,
});

export {sendSessionCompleteAnalytics, sendSessionGroupAnalytics} from './analytics';

export {
  getCart,
  getCartError,
  setCartItemsTemporarySelected,
  isCartLoaded,
  loadCart,
  updateCart,
} from './cart';

export {
  createCartSession,
  getCartSession,
  getCartSessionById,
  getCartSessionError,
  getCartSessionOverlay,
  loadCartSession,
  loadCartSessionStep,
  cartSessionPay,
  updateCartSession,
  getCartSessionCompletionItems,
  loadCartSessionCompletionItems,
  markDisclaimerAsSeen,
} from './session';

type CartUpdatePayload = {
  cartError?: Error;
  cartState?: CartRaw;
  cartItemIds?: string[];
};

// NB! Use only inside cart page. For selecting outside use `setCartSelection`
export function selectCartItems(ids: string[], select = true): FuncAction<Promise<unknown>> {
  return requestActionCreator(
    selectCartItemsAction,
    {ids, select},
    async ({getState, dispatch}) => {
      if (!isCartLoaded(getState())) {
        return Promise.reject(createNetworkError('cart.not_loaded'));
      }

      dispatch(setCartItemsTemporarySelected(ids, select));

      const cartIds =
        getCartItems(getState())
          ?.filter(isCartItemSelected)
          .map((item) => item.id) || [];

      dispatch(setCartSelection(SelectionType.IDS, select ? [...cartIds, ...ids] : cartIds));

      return dispatch(updateCart());
    },
  );
}

const createUpdateCartWrapper =
  (dispatch: Store['dispatch'], getState: Store['getState'], items: CartItem[]) =>
  (promise: Promise<ApiResponse<ClientBackendResponse<CartUpdatePayload>>>): Promise<Cart | null> =>
    promise
      .then(
        ({
          body: {
            payload: {cartError, cartState, cartItemIds},
          },
        }) => {
          if (cartItemIds) {
            dispatch(clearCartItemsError(cartItemIds));
          }
          if (cartState) {
            dispatch(setCart(cartState));
          }
          if (cartError) {
            const error = {
              ...cartError,
              cartItemIds,
            };
            throw error;
          }
        },
      )
      .catch((error) => {
        const errorBody = unwrapErrorObject(error) as ApiError & {
          cartItemIds?: string[];
          title?: {text?: string};
          subtitle?: {text?: string};
        };

        let itemId;
        if (items.length === 1) {
          itemId = items[0]?.id;
        } else if (errorBody.cartItemIds?.length === 1) {
          // eslint-disable-next-line prefer-destructuring
          itemId = errorBody.cartItemIds[0];
        }

        dispatch(
          itemId
            ? setCartItemError({
                id: itemId,
                message: [
                  errorBody.userTitle || errorBody.title?.text,
                  errorBody.userMessage || errorBody.subtitle?.text,
                ].join('. '),
                type: getErrorType(error),
              })
            : setCartError(error),
        );
      })
      .then(() => getCart(getState()));

export function addCartItem(
  productId: string,
  productVariantId: string,
  productOpenPayload?: OpenPayload,
  quantity?: number,
  preOfferType?: ProductPreOffer['type'],
): FuncAction<Promise<Cart | null>> {
  const cartItem = {productId, productVariantId, productOpenPayload, quantity, preOfferType};
  return requestActionCreator(addCartItemAction, cartItem, ({getState, dispatch}, client) => {
    const body = {
      selection: getCartSelection(client, getState),
      items: [cartItem],
    };

    const updateCartWrapper = createUpdateCartWrapper(dispatch, getState, []);

    return updateCartWrapper(
      client.api.post<ClientBackendResponse<CartUpdatePayload>>('/cart/add', {body}),
    );
  });
}

export function removeCartItems(items: CartItem[]): FuncAction<Promise<unknown>> {
  return requestActionCreator(removeCartItemsAction, items, ({getState, dispatch}, client) => {
    const body = {
      selection: getCartSelection(client, getState),
      items: items.map(({id, productId, productVariantId}) => ({
        cartItemId: id,
        productId,
        productVariantId,
      })),
    };

    const updateCartWrapper = createUpdateCartWrapper(dispatch, getState, items);

    return updateCartWrapper(
      client.api.post<ClientBackendResponse<CartUpdatePayload>>('/cart/remove', {body}),
    ).then((cart) => {
      dispatch(clearCartItemsError(items.map(({id}) => id)));

      return cart;
    });
  });
}

export function changeCartQuantity(item: CartItem, quantity = 1): FuncAction<Promise<unknown>> {
  return requestActionCreator(
    changeItemQuantityAction,
    {item, quantity},
    ({getState, dispatch}, client) => {
      dispatch(setCartItemTemporaryQuantity(item.id, quantity));

      const body = {
        selection: getCartSelection(client, getState),
        items: [
          {
            cartItemId: item.id,
            productId: item.productId,
            productVariantId: item.productVariantId,
            quantity,
          },
        ],
      };

      const updateCartWrapper = createUpdateCartWrapper(dispatch, getState, [item]);

      return updateCartWrapper(
        client.api.post<ClientBackendResponse<CartUpdatePayload>>('/cart/update', {body}),
      ).finally(() => dispatch(setCartItemTemporaryQuantity(item.id, null)));
    },
  );
}

export function removePaymentMethod(paymentMethodId: string): FuncAction<Promise<unknown>> {
  return requestActionCreator(removePaymentMethodAction, {paymentMethodId}, ({dispatch}, client) =>
    client.api
      .delete(`/paymentMethods/${encodeURIComponent(paymentMethodId)}`)
      .then(() => dispatch(updateCartSession())),
  );
}
