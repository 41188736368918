import {Locator} from 'components/Locator';
import {PopupAppearance} from 'components/UIKit/Appearance/PopupAppearance';
import {useMergedRef} from 'hooks/useMergedRef';
import {useUserAgent} from 'hooks/useUserAgent';
import React, {ContextType, CSSProperties, ReactElement, useContext, useMemo, useRef} from 'react';
import {CSSTransition} from 'react-transition-group';
import {UserAgent} from 'types/UserAgent';

import {appearance as defaultAppearance} from './appearance/default';
import {PopupContext} from './context';
import {PopupContentProps} from './types';

export type PopupProps = React.PropsWithChildren<{
  appearance?: PopupAppearance;
  autoFocus?: boolean;
  returnFocus?: boolean;
  width?: string;
  testId?: string;
  popupId?: string;
  popupType?: string;
}>;

function getContentStyle(width: string | undefined): CSSProperties | undefined {
  return width
    ? {
        flexBasis: width,
      }
    : undefined;
}

function isBuggyStickyOnTransition(userAgent: UserAgent): boolean {
  return userAgent.browser.name === 'Safari' || userAgent.browser.name === 'Mobile Safari';
}

const PopupContent = React.forwardRef(function PopupContent(
  {
    children,
    'data-testid': testId,
    'data-test-popup-id': popupId,
    'data-test-popup-type': popupType,
  }: PopupContentProps,
  outerRef?: React.Ref<HTMLDivElement>,
) {
  const {appearance, width} = useContext(PopupContext);
  const {className, contentClassName, transitionProps} = appearance || defaultAppearance;

  const style = useMemo(() => getContentStyle(width), [width]);
  const userAgent = useUserAgent();
  const buggyStickyClassName = useMemo(
    () => (isBuggyStickyOnTransition(userAgent) ? 'buggy-sticky' : ''),
    [userAgent],
  );

  const innerRef = useRef<HTMLDivElement>(null);

  const mergedRef = useMergedRef(outerRef, innerRef);

  return (
    <CSSTransition nodeRef={innerRef} {...transitionProps}>
      <div
        className={`${buggyStickyClassName} ${className}`}
        ref={mergedRef}
        style={style}
        data-testid={testId}
        data-test-popup-id={popupId}
        data-test-popup-type={popupType}
      >
        <div className={contentClassName}>{children}</div>
      </div>
    </CSSTransition>
  );
});

export function Popup({
  children,
  appearance,
  width,
  testId,
  popupId,
  popupType,
}: PopupProps): ReactElement {
  const lockProps: ContextType<typeof PopupContext> = useMemo(
    () => ({width, appearance}),
    [appearance, width],
  );

  return (
    <PopupContext.Provider value={lockProps}>
      <Locator id={testId} popup-id={popupId} popup-type={popupType}>
        <PopupContent>{children}</PopupContent>
      </Locator>
    </PopupContext.Provider>
  );
}
