import {PolychromeIconNames} from '../iconNames';

const map: Record<string, PolychromeIconNames> = {
  '🇦🇫': 'flags-afghanistan',
  '🇦🇽': 'flags-aland-islands',
  '🇦🇱': 'flags-albania',
  '🇩🇿': 'flags-algeria',
  '🇦🇸': 'flags-american-samoa',
  '🇦🇩': 'flags-andorra',
  '🇦🇴': 'flags-angola',
  '🇦🇮': 'flags-anguilla',
  '🇦🇬': 'flags-antigua-and-barbuda',
  '🇦🇷': 'flags-argentina',
  '🇦🇲': 'flags-armenia',
  '🇦🇼': 'flags-aruba',
  '🇦🇺': 'flags-australia',
  '🇦🇹': 'flags-austria',
  '🇦🇿': 'flags-azerbaijan',
  '🇧🇸': 'flags-bahamas',
  '🇧🇭': 'flags-bahrain',
  '🇧🇩': 'flags-bangladesh',
  '🇧🇧': 'flags-barbados',
  '🇧🇾': 'flags-belarus',
  '🇧🇪': 'flags-belgium',
  '🇧🇿': 'flags-belize',
  '🇧🇯': 'flags-benin',
  '🇧🇲': 'flags-bermuda',
  '🇧🇹': 'flags-bhutan',
  '🇧🇴': 'flags-bolivia',
  '🇧🇶': 'flags-bonaire',
  '🇧🇦': 'flags-bosnia-and-herzegovina',
  '🇧🇼': 'flags-botswana',
  '🇧🇷': 'flags-brazil',
  '🇮🇴': 'flags-british-indian-ocean-territory',
  '🇧🇬': 'flags-bulgaria',
  '🇧🇫': 'flags-burkina-faso',
  '🇧🇮': 'flags-burundi',
  '🇰🇭': 'flags-cambodia',
  '🇨🇲': 'flags-cameroon',
  '🇨🇦': 'flags-canada',
  '🇨🇻': 'flags-cape-verde',
  '🇰🇾': 'flags-cayman-islands',
  '🇨🇫': 'flags-central-african-republic',
  '🇹🇩': 'flags-chad',
  '🇨🇱': 'flags-chile',
  '🇨🇳': 'flags-china',
  '🇨🇽': 'flags-christmas-island',
  '🇨🇴': 'flags-colombia',
  '🇰🇲': 'flags-comoros',
  '🇨🇰': 'flags-cook-islands',
  '🇨🇷': 'flags-costa-rica',
  '🇭🇷': 'flags-croatia',
  '🇨🇺': 'flags-cuba',
  '🇨🇾': 'flags-cyprus',
  '🇨🇿': 'flags-czech-republic',
  '🇩🇰': 'flags-denmark',
  '🇩🇯': 'flags-djibouti',
  '🇩🇲': 'flags-dominica',
  '🇩🇴': 'flags-dominican-republic',
  '🇪🇨': 'flags-ecuador',
  '🇪🇬': 'flags-egypt',
  '🇬🇶': 'flags-equatorial-guinea',
  '🇪🇷': 'flags-eritrea',
  '🇪🇪': 'flags-estonia',
  '🇪🇹': 'flags-ethiopia',
  '🇫🇰': 'flags-falkland-islands',
  '🇫🇴': 'flags-faroe-islands',
  '🇫🇯': 'flags-fiji',
  '🇫🇮': 'flags-finland',
  '🇫🇷': 'flags-france',
  '🇵🇫': 'flags-french-polynesia',
  '🇬🇦': 'flags-gabon',
  '🇬🇲': 'flags-gambia',
  '🇬🇪': 'flags-georgia',
  '🇩🇪': 'flags-germany',
  '🇬🇭': 'flags-ghana',
  '🇬🇮': 'flags-gibraltar',
  '🇬🇷': 'flags-greece',
  '🇬🇱': 'flags-greenland',
  '🇬🇩': 'flags-grenada',
  '🇬🇺': 'flags-guam',
  '🇬🇹': 'flags-guatemala',
  '🇬🇬': 'flags-guernsey',
  '🇬🇳': 'flags-guinea',
  '🇬🇼': 'flags-guinea-bissau',
  '🇬🇾': 'flags-guyana',
  '🇭🇹': 'flags-haiti',
  '🇭🇳': 'flags-honduras',
  '🇭🇰': 'flags-hong-kong',
  '🇭🇺': 'flags-hungary',
  '🇮🇸': 'flags-iceland',
  '🇮🇳': 'flags-india',
  '🇮🇩': 'flags-indonesia',
  '🇮🇷': 'flags-iran',
  '🇮🇶': 'flags-iraq',
  '🇮🇪': 'flags-ireland',
  '🇮🇲': 'flags-isle-of-man',
  '🇮🇱': 'flags-israel',
  '🇮🇹': 'flags-italy',
  '🇯🇲': 'flags-jamaica',
  '🇯🇵': 'flags-japan',
  '🇯🇪': 'flags-jersey',
  '🇯🇴': 'flags-jordan',
  '🇰🇿': 'flags-kazakhstan',
  '🇰🇪': 'flags-kenya',
  '🇰🇮': 'flags-kiribati',
  '🇽🇰': 'flags-kosovo',
  '🇰🇼': 'flags-kuwait',
  '🇰🇬': 'flags-kyrgyzstan',
  '🇱🇻': 'flags-latvia',
  '🇱🇧': 'flags-lebanon',
  '🇱🇸': 'flags-lesotho',
  '🇱🇷': 'flags-liberia',
  '🇱🇾': 'flags-libya',
  '🇱🇮': 'flags-liechtenstein',
  '🇱🇹': 'flags-lithuania',
  '🇱🇺': 'flags-luxembourg',
  '🇲🇴': 'flags-macao',
  '🇲🇰': 'flags-macedonia',
  '🇲🇬': 'flags-madagascar',
  '🇲🇼': 'flags-malawi',
  '🇲🇾': 'flags-malaysia',
  '🇲🇻': 'flags-maldives',
  '🇲🇱': 'flags-mali',
  '🇲🇹': 'flags-malta',
  '🇲🇶': 'flags-martinique',
  '🇲🇷': 'flags-mauritania',
  '🇲🇺': 'flags-mauritius',
  '🇲🇽': 'flags-mexico',
  '🇲🇩': 'flags-moldova',
  '🇲🇨': 'flags-monaco',
  '🇲🇳': 'flags-mongolia',
  '🇲🇪': 'flags-montenegro',
  '🇲🇸': 'flags-montserrat',
  '🇲🇦': 'flags-morocco',
  '🇲🇿': 'flags-mozambique',
  '🇲🇲': 'flags-myanmar',
  '🇳🇦': 'flags-namibia',
  '🇳🇷': 'flags-nauru',
  '🇳🇵': 'flags-nepal',
  '🇳🇱': 'flags-netherlands',
  '🇳🇿': 'flags-new-zealand',
  '🇳🇮': 'flags-nicaragua',
  '🇳🇪': 'flags-niger',
  '🇳🇬': 'flags-nigeria',
  '🇳🇺': 'flags-niue',
  '🇳🇫': 'flags-norfolk-island',
  '🇳🇴': 'flags-norway',
  '🇴🇲': 'flags-oman',
  '🇵🇰': 'flags-pakistan',
  '🇵🇼': 'flags-palau',
  '🇵🇸': 'flags-palestine',
  '🇵🇦': 'flags-panama',
  '🇵🇬': 'flags-papua-new-guinea',
  '🇵🇾': 'flags-paraguay',
  '🇵🇪': 'flags-peru',
  '🇵🇭': 'flags-philippines',
  '🇵🇱': 'flags-poland',
  '🇵🇹': 'flags-portugal',
  '🇵🇷': 'flags-puerto-rico',
  '🇶🇦': 'flags-qatar',
  '🇷🇴': 'flags-romania',
  '🇷🇺': 'flags-russian-federation',
  '🇷🇼': 'flags-rwanda',
  '🇰🇳': 'flags-saint-kitts-and-nevis',
  '🇼🇸': 'flags-samoa',
  '🇸🇲': 'flags-san-marino',
  '🇸🇹': 'flags-sao-tome-and-principe',
  '🇸🇦': 'flags-saudi-arabia',
  '🇸🇳': 'flags-senegal',
  '🇷🇸': 'flags-serbia',
  '🇸🇨': 'flags-seychelles',
  '🇸🇱': 'flags-sierra-leone',
  '🇸🇬': 'flags-singapore',
  '🇸🇽': 'flags-sint-maarten',
  '🇸🇰': 'flags-slovakia',
  '🇸🇮': 'flags-slovenia',
  '🇸🇧': 'flags-solomon-islands',
  '🇸🇴': 'flags-somalia',
  '🇿🇦': 'flags-south-africa',
  '🇰🇷': 'flags-south-korea',
  '🇸🇸': 'flags-south-sudan',
  '🇪🇸': 'flags-spain',
  '🇱🇰': 'flags-sri-lanka',
  '🇸🇩': 'flags-sudan',
  '🇸🇷': 'flags-suriname',
  '🇸🇿': 'flags-swaziland',
  '🇸🇪': 'flags-sweden',
  '🇨🇭': 'flags-switzerland',
  '🇸🇾': 'flags-syria',
  '🇹🇼': 'flags-taiwan',
  '🇹🇯': 'flags-tajikistan',
  '🇹🇿': 'flags-tanzania',
  '🇹🇭': 'flags-thailand',
  '🇹🇬': 'flags-togo',
  '🇹🇰': 'flags-tokelau',
  '🇹🇴': 'flags-tonga',
  '🇹🇹': 'flags-trinidad-and-tobago',
  '🇹🇳': 'flags-tunisia',
  '🇹🇷': 'flags-turkey',
  '🇹🇲': 'flags-turkmenistan',
  '🇹🇻': 'flags-tuvalu',
  '🇺🇬': 'flags-uganda',
  '🇺🇦': 'flags-ukraine',
  '🇦🇪': 'flags-united-arab-emirates',
  '🇬🇧': 'flags-united-kingdom',
  '🇺🇾': 'flags-uruguay',
  '🇺🇸': 'flags-usa',
  '🇺🇿': 'flags-uzbekistan',
  '🇻🇺': 'flags-vanuatu',
  '🇻🇪': 'flags-venezuela',
  '🇻🇳': 'flags-vietnam',
  '🇪🇭': 'flags-western-sahara',
  '🇾🇪': 'flags-yemen',
  '🇿🇲': 'flags-zambia',
  '🇿🇼': 'flags-zimbabwe',
};

export const getFlagIconNameByEmoji = (emoji: string): PolychromeIconNames | undefined => {
  return map[emoji] || undefined;
};
