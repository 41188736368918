import styles from 'components/ClientProduct/Attributes/Item/index.scss';
import React from 'react';
import {ProductAttribute} from 'types/ProductAttributes';

type Props = {
  item: ProductAttribute;
};

export const Item = React.memo(function Item({
  item: {
    content: {text},
  },
}: Props): React.ReactElement | null {
  if (!text) {
    return null;
  }

  return (
    <div className={styles.item}>
      <div className={styles.name}>
        <span>{text.key}</span>
      </div>
      <div className={styles.dots} />
      <div className={styles.value}>
        <span>{text.value}</span>
      </div>
    </div>
  );
});
