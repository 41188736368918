import {useDispatch} from 'hooks/redux';
import {useApiClient} from 'hooks/useApiClient';
import {useCallback, useMemo} from 'react';
import {updateSearchSession} from 'store/modules/search/products';

import {AcrossTabsSession} from './AcrossTabSession';
import {PREVIOUS_SEARCH_SESSION_ID, SEARCH_SESSION_ID} from './consts';
import {getSearchSession, setPreviousSearchSessionId} from './helpers';
import {LocationToSession} from './LocationToSession';

type ReturnType = {
  getCurrentSearchSession: () => string | undefined;
  discardSearchSession: () => void;
  setPreviousSearchSession: (value?: string) => void;
  discardPreviousSearchSession: () => void;
  getSessionByLocation: (key: string | undefined) => string | undefined;
  setSessionForLocation: (key: string | undefined, value: string) => void;
  discardLocationToSession: () => void;
  setAcrossTabSession: (id: string) => void;
};

export function useSearchSessionUpdate(): ReturnType {
  const client = useApiClient();
  const sessionStorage = client.storages.session;
  const acrossTabSession = useMemo(() => new AcrossTabsSession(client), [client]);
  const locationToSession = useMemo(() => new LocationToSession(client), [client]);
  const dispatch = useDispatch();

  const discardSearchSession = useCallback(() => {
    sessionStorage.removeItem(SEARCH_SESSION_ID);
    dispatch(updateSearchSession({searchSessionId: undefined}));
  }, [dispatch, sessionStorage]);

  const discardPreviousSearchSession = useCallback(() => {
    sessionStorage.removeItem(PREVIOUS_SEARCH_SESSION_ID);
    dispatch(updateSearchSession({previousSearchSessionId: undefined}));
  }, [dispatch, sessionStorage]);

  const getCurrentSearchSession = useCallback(() => {
    return getSearchSession(client);
  }, [client]);

  const setPreviousSearchSession = useCallback(
    (value?: string) => {
      setPreviousSearchSessionId(client, value);
    },
    [client],
  );

  const setSessionForLocation = useCallback(
    (key: string | undefined, value: string) => {
      locationToSession.setSessionForLocation(key, value);
    },
    [locationToSession],
  );

  const getSessionByLocation = useCallback(
    (key: string | undefined) => {
      return locationToSession.getSessionByLocation(key);
    },
    [locationToSession],
  );

  const discardLocationToSession = useCallback(() => {
    locationToSession.discard();
  }, [locationToSession]);

  const setAcrossTabSession = useCallback(
    (id: string) => {
      acrossTabSession.set(id);
    },
    [acrossTabSession],
  );

  return {
    getCurrentSearchSession,
    discardSearchSession,
    setPreviousSearchSession,
    discardPreviousSearchSession,
    getSessionByLocation,
    setSessionForLocation,
    discardLocationToSession,
    setAcrossTabSession,
  };
}
