import {assignRemove, assignSet} from 'utils/object';
import {enhanceLanguage} from 'store/utils/enhancers';

const LOAD = 'categoryViews/LOAD';
const LOAD_SUCCESS = 'categoryViews/LOAD_SUCCESS';
const LOAD_FAIL = 'categoryViews/LOAD_FAIL';

export const ROOT_ID = 'root';

const initialState = {
  data: {},
  error: {},
  loaded: {},
  loading: {},
};

function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        error: assignRemove(state.error, action.id),
        loading: assignSet(state.loading, action.id, true),
      };
    case LOAD_SUCCESS: {
      const loaded = {...state.loaded};
      const data = {...state.data};
      Object.keys(action.result).forEach((id) => {
        loaded[id] = true;
        const child = action.result[id];
        const existed = state.data[id];
        if (
          existed &&
          existed.children.length &&
          !child.children.length &&
          existed.language === child.language
        ) {
          return;
        }
        data[id] = child;
      });

      return {
        ...state,
        data,
        loaded,
        loading: assignRemove(state.loading, action.id),
      };
    }
    case LOAD_FAIL:
      return {
        ...state,
        error: assignSet(state.error, action.id, action.error),
        loading: assignRemove(state.loading, action.id),
        loaded: assignRemove(state.loaded, action.id),
      };
    default:
      return state;
  }
}

export default reducer;

export function isCategoryLoaded(globalState, id) {
  const {loaded} = globalState.categoryViews;
  return !!loaded[id];
}

export function isCategoryLoading(globalState, id) {
  return !!globalState.categoryViews.loading[id];
}

export function getCategory(globalState, id) {
  return isCategoryLoaded(globalState, id) ? globalState.categoryViews.data[id] : null;
}

export function getCategoryParentId(category) {
  if (!category || (!category.parentId && !category.parent)) {
    return null;
  }

  // parent is synthetic field to remember right path for user
  return category.parent || category.parentId;
}

export function getCategoryError(globalState, id) {
  return globalState.categoryViews.error[id] || null;
}

export function isCategoryViewLoaded(globalState, id, level = 1, skipParent = false) {
  const category = getCategory(globalState, id);
  if (!category) {
    return false;
  }
  if (!skipParent && category.parent && !isCategoryLoaded(globalState, category.parent)) {
    return false;
  }
  if (level > 0) {
    if (!category.hasPublicChildren) {
      return true;
    }
    return (
      category.children.length !== 0 &&
      category.children.every((child) => isCategoryViewLoaded(globalState, child, level - 1, true))
    );
  }

  return true;
}

export function getCategoryView(globalState, id) {
  if (!isCategoryViewLoaded(globalState, id)) {
    return null;
  }

  const category = getCategory(globalState, id);
  const children = [];
  category.children.forEach((child) => {
    const childCategory = getCategory(globalState, child);
    if (childCategory) {
      children.push(childCategory);
    }
  });
  return {
    category: category.id ? category : null,
    parent: category.parent ? getCategory(globalState, category.parent) : null,
    children,
    root: !!category.root,
  };
}

export function getCategories(globalState, id, deep, check) {
  const root = getCategory(globalState, id);
  if (!root) {
    return null;
  }

  const result = {root};
  const queue = [{category: root, level: 0}];
  while (queue.length) {
    const {category, level} = queue.shift();
    if (category.id) {
      result[category.id] = category;
    }
    if ((category.root || category.hasPublicChildren) && level < deep) {
      const children = category.children.map((categoryId) => getCategory(globalState, categoryId));

      if (check && (children.length === 0 || children.some((item) => !item))) {
        return null;
      }

      children.forEach((child) => {
        if (child) {
          queue.push({
            category: child,
            level: level + 1,
          });
        }
      });
    }
  }

  return result;
}

export function getCategoryBreadcrumbs(globalState, id, includeCurrent = false) {
  const chain = [];

  let category = getCategory(globalState, id);
  while (category) {
    if (chain.includes(category)) {
      break;
    }
    if (includeCurrent || category.id !== id) {
      chain.unshift(category);
    }
    const parentId = getCategoryParentId(category);
    category = parentId ? getCategory(globalState, parentId) : null;
  }

  if (chain.length && !chain[0].parentId) {
    return chain;
  }

  return null;
}

export function isLeafCategory(category) {
  return !!category && !category.root && !category.hasPublicChildren;
}

export function convertHierarchyToGraph(categoryView, language, id, graph = {}) {
  const children = categoryView.children || [];
  const parents = categoryView.parents || [];

  const existed = graph[categoryView.id || ROOT_ID];
  if (existed && existed.children.length && !children.length && existed.language === language) {
    // Already have this non-reducted category. Skip.
    return graph;
  }

  /* eslint-disable no-param-reassign */
  categoryView.children = children.map((child) => child.id);
  if (categoryView && categoryView.id) {
    const parent = parents[0];
    categoryView.parent = parent ? parent.id : id || categoryView.parentId || null;
  } else {
    categoryView.parent = null;
    categoryView.root = true;
    categoryView.hasPublicChildren = true;
  }
  enhanceLanguage(categoryView, language);
  graph[categoryView.id || ROOT_ID] = categoryView;
  /* eslint-enable no-param-reassign */

  children.forEach((item) => convertHierarchyToGraph(item, language, categoryView.id, graph));
  parents.forEach((item, index) => {
    const parentId = parents[index + 1] ? parents[index + 1].id : undefined;
    convertHierarchyToGraph(item, language, parentId, graph);
  });

  return graph;
}

// NB! You can use levels > 1 and <=3 only for root.
// If you want to get more levels than 1, please ask backend developers about it.
export function loadCategoriesHierarchy(categoryId, levels = 1, parentLevels = 1) {
  const query = {levels};
  const id = categoryId || ROOT_ID;
  if (id !== ROOT_ID) {
    query.categoryId = id;
    query.parentLevels = parentLevels;
  }

  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    id,
    promise: (client) =>
      client.api
        .get('/categoriesHierarchy', {query})
        .then(({language, body: {payload}}) => convertHierarchyToGraph({...payload}, language)),
  };
}

export function loadCategoriesBreadcumbs(categoryId) {
  return loadCategoriesHierarchy(categoryId, 0, -1);
}
