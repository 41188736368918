import {createRequestAction} from 'store/utils/requestActions';
import {CategoryPromoLink} from 'types/CategoryPromoLink';

export type LoadRequest = Record<string, unknown>;

export type LoadResponse = {
  links: Array<CategoryPromoLink>;
  language: string;
};

export const load = createRequestAction(
  '@categoryPromoLinks/LOAD_REQUEST',
  '@categoryPromoLinks/LOAD_SUCCESS',
  '@categoryPromoLinks/LOAD_FAILURE',
)<LoadRequest, LoadResponse>();
