import PropTypes from 'prop-types';
import ProductReview from './ProductReview';
import OrderReviewQuestion from './OrderReviewQuestion';

export const OrderReviewInfoShape = PropTypes.shape({
  canReview: PropTypes.bool.isRequired,
  anonymousByDefault: PropTypes.bool,
  canBeAnonymous: PropTypes.bool.isRequired,
  maxPhotosCount: PropTypes.number.isRequired,
  deliveryDurations: OrderReviewQuestion,
  reasons: PropTypes.arrayOf(OrderReviewQuestion.isRequired),
  review: ProductReview,
});
