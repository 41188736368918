import {ServerDrivenLayout} from 'components/ServerDrivenLayout';
import {ContentListItemView} from 'helpers/contentList';
import React, {ReactElement, useMemo} from 'react';
import {ContentListLayout, getContentListLayoutView} from 'types/ContentList/ContentListLayout';

export function ContentListLayoutView({item}: {item: ContentListLayout}): ReactElement {
  const freeformWidth = useMemo(() => {
    const view = getContentListLayoutView(item);

    if (view === ContentListItemView.FREEFORM && item.content.layout.appearance) {
      return `${100 / item.content.layout.appearance.columnsCount}%`;
    }

    return undefined;
  }, [item]);

  const content = <ServerDrivenLayout layout={item.content.layout} />;

  return freeformWidth ? <div style={{width: freeformWidth}}>{content}</div> : content;
}
