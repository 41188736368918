import {AppLink} from 'components/AppLink';
import {useScope} from 'hooks/useScope';
import React from 'react';
import {appStoreUrl} from 'utils/url/app';

import {Badge} from '../Badge';
import styles from './index.scss';
import {useBadgeHandler} from './useBadgeHandler';

type Props = {
  deepUrl: string;
  eventCategory: string;
  small?: boolean;
};

export function AppStoreBadge({deepUrl, eventCategory, small = false}: Props): JSX.Element {
  const scope = useScope();
  const handleAppStore = useBadgeHandler(deepUrl, eventCategory, 'App Store Badge. Click');
  return (
    <AppLink
      className={styles.link}
      href={appStoreUrl(scope)}
      onClick={handleAppStore}
      role="button"
    >
      <Badge className={styles.badge} small={small} type="appstore" />
    </AppLink>
  );
}
