import {assignRemove, assignSet} from 'utils/object';
import {enhanceLanguage} from 'store/utils/enhancers';

const LOAD = 'search/suggest/LOAD';
const LOAD_SUCCESS = 'search/suggest/LOAD_SUCCESS';
const LOAD_FAIL = 'search/suggest/LOAD_FAIL';

const initialState = {
  data: {},
  loading: {},
  error: {},
  loaded: {},
};

function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        error: assignRemove(state.error, action.prefix),
        loading: assignSet(state.loading, action.prefix, true),
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        data: assignSet(state.data, action.prefix, action.result),
        loaded: assignSet(state.loaded, action.prefix, true),
        loading: assignRemove(state.loading, action.prefix),
      };
    case LOAD_FAIL:
      return {
        ...state,
        error: assignSet(state.error, action.prefix, action.error),
        loaded: assignRemove(state.loaded, action.prefix),
        loading: assignRemove(state.loading, action.prefix),
      };
    default:
      return state;
  }
}

export default reducer;

export function isSearchSuggestLoaded(suggestState, prefix) {
  return !!suggestState.loaded[prefix];
}

export function getSearchSuggest(suggestState, prefix) {
  return isSearchSuggestLoaded(suggestState, prefix) ? suggestState.data[prefix] : null;
}

export function isSearchSuggestLoading(suggestState, prefix) {
  return !!suggestState.loading[prefix];
}

export function getSearchSuggestError(globalState, prefix) {
  const {error} = globalState.search.suggest;
  return error[prefix] || null;
}

export function loadSearchSuggest(prefix) {
  const body = {
    categoriesCount: 5,
    prefix,
    queriesCount: 5,
  };

  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    prefix,
    promise: (client) =>
      client.api
        .post('/search/products/suggest', {body})
        .then(({language, body: {payload}}) => enhanceLanguage(payload, language)),
  };
}
