import React from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';

const messages = defineMessages({
  joomLogisticsLink: {
    defaultMessage: 'Logistics services',
    description: 'Текст ссылки на Joom Logistics',
  },
});

export const JoomLogisticsLinkMessage: React.FC = () => (
  <FormattedMessage {...messages.joomLogisticsLink} />
);
