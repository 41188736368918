import React from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';

const messages = defineMessages({
  joomProLink: {
    defaultMessage: 'Buy in bulk',
    description: 'Текст ссылки на Joom Pro',
  },
});

export const JoomProLinkMessage: React.FC = () => <FormattedMessage {...messages.joomProLink} />;
