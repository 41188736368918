import {useCallback, useState} from 'react';

type UseBoolean = {
  setFalse: () => void;
  setTrue: () => void;
  setValue: (value: boolean) => void;
  toggle: () => void;
  value: boolean;
};

export function useBoolean(initialState = false): UseBoolean {
  const [value, setValue] = useState(initialState);

  const setFalse = useCallback(() => setValue(false), []);
  const setTrue = useCallback(() => setValue(true), []);
  const toggle = useCallback(() => setValue((prevValue) => !prevValue), []);

  return {setFalse, setTrue, setValue, toggle, value};
}
