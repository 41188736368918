import {
  getInitialState as getInitialSimpleState,
  SimpleState,
} from 'store/utils/reducerCreators/simple';
import {
  getStateData,
  getStateError,
  isStateLoaded,
  isStateLoading,
  RequestMatcher,
} from 'store/utils/reducerCreators/simple/selectors';
import {SimpleMappedState} from 'store/utils/reducerCreators/simpleMapped/index';
import {Selector, SelectorFactory, SelectorMeta} from 'store/utils/selectors';
import {Error} from 'types/Error';
import {trueFunction} from 'utils/function';

export type SimpleMappedStateRequestMatcher<
  T,
  TArgs extends unknown[],
> = T extends SimpleMappedState<infer U, infer V> ? RequestMatcher<U, V, TArgs> : never;

function createSimpleMeta<TRequest, TData, Key extends string>(
  meta: SelectorMeta<SimpleMappedState<TRequest, TData>>,
  key: Key,
): SelectorMeta<SimpleState<TRequest, TData>> {
  return {
    ...meta,
    state: meta.state[key] || getInitialSimpleState(),
  };
}

type Selectors<TRequest, TData, TArgs extends unknown[]> = {
  isLoading: Selector<boolean, [string, ...TArgs]>;
  isLoaded: Selector<boolean, [string, ...TArgs]>;
  getError: Selector<Error | null, [string, ...TArgs]>;
  getData: Selector<TData | null, [string, ...TArgs]>;
  getRequest: Selector<TRequest | null, [string]>;
};

export function getSelectors<TArgs extends unknown[], TRequest, TData>(
  selector: SelectorFactory<SimpleMappedState<TRequest, TData>>,
  requestMatcher: RequestMatcher<TRequest, TData, TArgs> = trueFunction,
): Selectors<TRequest, TData, TArgs> {
  return {
    isLoading: selector((meta, key: string, ...args: TArgs) =>
      isStateLoading(createSimpleMeta(meta, key), requestMatcher, ...args),
    ),
    isLoaded: selector((meta, key: string, ...args: TArgs) =>
      isStateLoaded(createSimpleMeta(meta, key), requestMatcher, ...args),
    ),
    getError: selector((meta, key: string, ...args: TArgs) =>
      getStateError(createSimpleMeta(meta, key), requestMatcher, ...args),
    ),
    getData: selector((meta, key: string, ...args: TArgs) =>
      getStateData(createSimpleMeta(meta, key), requestMatcher, ...args),
    ),
    getRequest: selector((meta, key: string) => meta.state[key]?.request || null),
  };
}
