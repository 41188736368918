import {createRequestAction} from 'store/utils/requestActions';
import {ProductsList} from 'types/ContentList/ContentListProductsList';

export type LoadArbitraryContentListRequest = {
  endpointPath: string;
  endpointParams?: Record<string, unknown>;
  nextPageToken?: string;
  itemId?: string;
};

export type LoadArbitraryContentListResponse = ProductsList;

export const loadArbitraryContentList = createRequestAction(
  '@productsList/LOAD_ARBITRARY_CONTENT_LIST_REQUEST',
  '@productsList/LOAD_ARBITRARY_CONTENT_LIST_SUCCESS',
  '@productsList/LOAD_ARBITRARY_CONTENT_LIST_FAILURE',
)<LoadArbitraryContentListRequest, LoadArbitraryContentListResponse>();
