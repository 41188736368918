import {useApiClient} from 'hooks/useApiClient';
import {useCallback, useMemo, useState} from 'react';

export function useJmtMigration(): {
  startMigration: () => void;
  migrationLoading: boolean;
  migrationError: boolean;
} {
  const client = useApiClient();

  const [migrationLoading, setMigrationLoading] = useState(false);
  const [migrationError, setMigrationError] = useState(false);

  const startMigration = useCallback(() => {
    if (migrationLoading) {
      return;
    }

    setMigrationLoading(true);
    client.jmtMigration.startJmtMigration().catch(() => {
      setMigrationError(true);
      setMigrationLoading(false);
    });
  }, [client, migrationLoading]);

  return useMemo(
    () => ({startMigration, migrationLoading, migrationError}),
    [migrationError, migrationLoading, startMigration],
  );
}
