import ClientApi from '@joomcode/joom-event-types';
import classnames from 'classnames/bind';
import {Header} from 'components/ContentList/Header';
import {WIDE6} from 'components/ContentList/imageSizes';
import {Slider, SliderItem} from 'components/ContentList/Slider';
import {DotLoader} from 'components/DotLoader';
import {ProductLite} from 'components/ProductLite';
import {type Mark, createLocator} from 'create-locator';
import {eventParamsList} from 'helpers/eventParams';
import {useSelector} from 'hooks/redux';
import {useAnalytics} from 'hooks/useAnalytics';
import {useDispatchAction} from 'hooks/useDispatch';
import {useIntersectionObserverRef} from 'hooks/useIntersectionObserverRef';
import {PartialClickEvent, usePreviewEvent} from 'hooks/usePreviewEvent';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {loadArbitraryContentList} from 'store/modules/contentListArbitrary';
import {
  isContentListArbitraryLoaded,
  isContentListArbitraryLoading,
} from 'store/modules/contentListArbitrary/selectors';
import {RootState} from 'store/rootReducer';
import type {Header as HeaderType} from 'types/ContentList/common';
import type {ProductsList as ProductsListType} from 'types/ContentList/ContentListProductsList';
import type {ProductLite as ProductLiteType} from 'types/ProductLite';

import type {ProductsListLocator} from '../index';
import styles from './styles.scss';

type Props = {
  center?: boolean;
  content: ProductsListType;
  fullWidth?: boolean;
  id?: string;
  source?: 'success' | 'orderParcel' | 'cart';
  onFavorite?: (product: ProductLiteType) => Promise<void>;
  onUnfavorite?: (product: ProductLiteType) => Promise<void>;
} & Mark<ProductsListLocator>;

const cn = classnames.bind(styles);

const getHeader = (list: ProductsListType): HeaderType | null => {
  if (list.headerAppearance?.simple) {
    return list.headerAppearance.simple;
  }

  if (list.title) {
    return {title: list.title};
  }

  return null;
};

export const RowProductsList = React.memo(function ProductsList(
  props: Props,
): React.ReactElement | null {
  const {center, content, fullWidth, id, source, onFavorite, onUnfavorite} = props;
  const locator = createLocator(props);
  const rootRef = useRef<HTMLDivElement>(null);
  const linkRef = useRef(null);
  const titleRef = useRef(null);
  const {endpointPath, endpointParams} = content;
  const [items, setItems] = useState(content.items);
  const [nextPageToken, setNextPageToken] = useState(content.nextPageToken);
  const loading = useSelector((state: RootState) =>
    id ? isContentListArbitraryLoading(state, id) : false,
  );
  const loaded = useSelector((state: RootState) =>
    id ? isContentListArbitraryLoaded(state, id) : false,
  );
  const hasMoreItems = Boolean(nextPageToken || (!loaded && items.length === 0));

  const header = useMemo(() => getHeader(content), [content]);
  const products = useMemo(() => {
    return items.reduce((result, item) => {
      if (item.content.product) {
        result.push(item.content.product);
      }

      return result;
    }, [] as ProductLiteType[]);
  }, [items]);

  // Load
  const loadNextItems = useDispatchAction(loadArbitraryContentList);
  const handleLoad = useCallback(() => {
    if (!endpointPath || loading) {
      return;
    }

    loadNextItems({endpointPath, endpointParams, nextPageToken, itemId: id}).then((payload) => {
      setItems([...items, ...payload.items]);
      setNextPageToken(payload.nextPageToken);
    });
  }, [endpointPath, loading, loadNextItems, endpointParams, nextPageToken, id, items]);

  useEffect(() => {
    if (content.items !== items) {
      setItems(content.items);
      setNextPageToken(content.nextPageToken);
    }
  }, [content.items, content.nextPageToken, items]);

  // Autoload
  const autoloadRef = useRef<HTMLDivElement>(null);
  const handleAutoloadObserve = useCallback(
    (entry: IntersectionObserverEntry) => {
      if (entry.isIntersecting) {
        handleLoad();
      }
    },
    [handleLoad],
  );

  useIntersectionObserverRef(handleAutoloadObserve, {
    targetRef: autoloadRef,
  });

  // Analytics
  const analytics = useAnalytics();
  const {eventParams} = content;

  const handleActionClick = useCallback(() => {
    analytics.sendEvent({
      type: 'productsListAllButtonClick',
      params: eventParamsList(eventParams),
    });
  }, [analytics, eventParams]);

  usePreviewEvent(
    {
      rootRef,
      previewEvent: () => ({
        type: 'productsListPreview',
        payload: {
          productsListId: id,
          screenSource: source,
        },
        params: eventParamsList(eventParams),
      }),
    },
    [
      {
        clickRef: linkRef,
        clickEvent: {
          type: 'productsListActionClick',
          payload: {
            source,
            productsListId: id,
          },
          params: [eventParams],
        } as PartialClickEvent<ClientApi.AnalyticsEventProductsListActionClick>,
      },
      {
        clickRef: titleRef,
        clickEvent: {
          type: 'productsListActionClick',
          payload: {
            source,
            productsListId: id,
          },
          params: [eventParams],
        } as PartialClickEvent<ClientApi.AnalyticsEventProductsListActionClick>,
      },
    ],
  );

  return (
    <div className={cn(styles.root, {fullWidth})} ref={rootRef} {...locator()}>
      {header && (
        <div className={styles.header}>
          <Header
            center={center}
            header={header}
            titleRef={titleRef}
            linkRef={linkRef}
            onActionClick={handleActionClick}
          />
        </div>
      )}
      <div className={styles.smallCards}>
        <Slider>
          <div className={styles.inner}>
            {products.map((product) => (
              <SliderItem key={product.id}>
                {({a11yProps, ref, selected}): React.ReactElement => (
                  <div ref={ref} className={styles.cell} {...a11yProps}>
                    <ProductLite
                      focusable={selected}
                      product={product}
                      sizes={WIDE6.sizes}
                      width="100%"
                      onFavorite={onFavorite}
                      onUnfavorite={onUnfavorite}
                      {...locator.productLite()}
                    />
                  </div>
                )}
              </SliderItem>
            ))}
            {hasMoreItems && (
              <SliderItem key="auto-load">
                {({a11yProps, ref}): React.ReactElement => (
                  <div ref={ref} className={styles.cell} {...a11yProps}>
                    <div ref={autoloadRef} className={styles.autoLoad}>
                      <DotLoader />
                    </div>
                  </div>
                )}
              </SliderItem>
            )}
          </div>
        </Slider>
      </div>
    </div>
  );
});
