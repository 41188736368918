export const promiseAllWithParallelLimit = <T>(
  parallelLimit: number,
  getNext: (requestIndex: number) => Promise<T> | undefined,
): Promise<T[]> => {
  return new Promise((resolve, reject) => {
    const results: T[] = [];
    let promisesCount = 0;
    let activePromisesCount = 0;

    const getNextFew = () => {
      for (let i = activePromisesCount; i < parallelLimit; i += 1) {
        const promiseIndex = promisesCount;
        const nextPromise = getNext(promiseIndex);

        promisesCount += 1;

        if (!nextPromise) {
          break;
        }

        activePromisesCount += 1;
        nextPromise
          // eslint-disable-next-line no-loop-func
          .then((result) => {
            results[promiseIndex] = result;
            activePromisesCount -= 1;

            getNextFew();
          })
          .catch(reject);
      }

      if (activePromisesCount === 0) {
        resolve(results);
      }
    };

    getNextFew();
  });
};
