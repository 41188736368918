import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, {useCallback, useState, useRef, useId} from 'react';
import {defineMessages, FormattedMessage, useIntl} from 'react-intl';
import {ContextMenu} from 'components/ContextMenu';
import {useOnClickOutside} from 'hooks/useOnClickOutside';

import {HelpButtonWidth} from './HelpButtonWidth';
import styles from './index.scss';

export {HelpButtonWidth} from './HelpButtonWidth';

const cn = classnames.bind(styles);

const messages = defineMessages({
  button: {
    description: '[label] Открыть справку',
    defaultMessage: 'Open help',
  },
  close: {
    description: '[label] Закрыть справку',
    defaultMessage: 'Close help',
  },
});

export const HelpButton = React.memo(function HelpButton({
  block,
  children,
  message,
  onOpen,
  tooltipWidth,
}) {
  const tooltipRef = useRef();
  const buttonRef = useRef();
  const uid = useId();
  const [showTooltip, setShowTooltip] = useState(false);
  const handleTooltipToggle = useCallback(
    (evt) => {
      if (!showTooltip && onOpen) {
        onOpen(evt);
      }
      setShowTooltip(!showTooltip);
    },
    [onOpen, showTooltip],
  );
  const handleClose = useCallback(() => {
    setShowTooltip(false);
  }, []);

  const content = children || <FormattedMessage {...message} />;
  const intl = useIntl();

  useOnClickOutside(tooltipRef, handleClose);

  return (
    <div className={cn('helpButton', {block})} ref={tooltipRef}>
      <button
        ref={buttonRef}
        className={styles.icon}
        onClick={handleTooltipToggle}
        type="button"
        aria-label={intl.formatMessage(messages.button)}
        aria-controls={uid}
        aria-expanded={showTooltip}
      />
      {showTooltip && (
        <ContextMenu
          onClose={handleClose}
          width={tooltipWidth}
          id={uid}
          usePortal={false}
          placement="top"
        >
          <div className={styles.content}>{content}</div>
          <button
            type="button"
            className={styles.closeButton}
            onClick={handleClose}
            aria-label={intl.formatMessage(messages.close)}
          />
        </ContextMenu>
      )}
    </div>
  );
});

HelpButton.propTypes = {
  block: PropTypes.bool,
  children: PropTypes.node,
  message: PropTypes.shape(FormattedMessage.propTypes),
  onOpen: PropTypes.func,
  tooltipWidth: PropTypes.oneOf(Object.values(HelpButtonWidth)),
};

HelpButton.defaultProps = {
  block: false,
  children: null,
  message: null,
  onOpen: null,
  tooltipWidth: HelpButtonWidth.M,
};
