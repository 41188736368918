import {Button} from 'components/UIKit/Button';
import {Icon} from 'components/UIKit/Icon';
import {ModalOverlay} from 'components/UIKit/ModalOverlay';
import {Popup} from 'components/UIKit/Popup';
import {Content} from 'components/UIKit/Popup/Content';
import {Footer} from 'components/UIKit/Popup/Footer';
import {StandaloneControls} from 'components/UIKit/Popup/Header/StandaloneControls';
import {PopupViewProps} from 'components/UIKit/Popup/PopupProvider';
import {Scope} from 'config';
import {JmtMigrationErrorPopup} from 'containers/WelcomePopups/JmtMigrationPopupController/JmtMigrationErrorPopup';
import {JmtMigrationLoadingPopup} from 'containers/WelcomePopups/JmtMigrationPopupController/JmtMigrationLoadingPopup';
import styles from 'containers/WelcomePopups/JmtMigrationPopupController/styles.scss';
import {useJmtMigration} from 'containers/WelcomePopups/JmtMigrationPopupController/useJmtMigration';
import {isPageVisible} from 'helpers/pageVisibility';
import {useSendAnalytics, useSendAnalyticsOpenClose} from 'hooks/useAnalytics';
import {useApiClient} from 'hooks/useApiClient';
import {useScope} from 'hooks/useScope';
import {useVisibilityChange} from 'hooks/useVisibilityChange';
import React, {ReactElement, useCallback, useEffect, useState} from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';

export function JmtMigrationPopup({
  onClose,
  errorView,
}: {errorView?: boolean} & Pick<PopupViewProps, 'onClose'>): ReactElement | null {
  const client = useApiClient();
  const sendEvent = useSendAnalytics();

  const [pageVisible, setPageVisible] = useState(() => isPageVisible());

  useVisibilityChange(
    undefined,
    useCallback(() => setPageVisible(true), []),
  );

  useEffect(() => {
    if (pageVisible) {
      client.jmtMigration.setMigrationPopupShown();
    }
  }, [client.jmtMigration, pageVisible]);

  const handleClose = useCallback(() => {
    sendEvent('jmtMigrationFlowCancel');

    onClose();
  }, [onClose, sendEvent]);

  const {startMigration, migrationLoading, migrationError} = useJmtMigration();

  if (migrationLoading) {
    return <JmtMigrationLoadingPopup />;
  }

  if (migrationError || errorView) {
    return <JmtMigrationErrorPopup onClose={handleClose} onClick={startMigration} />;
  }

  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  return <JmtMigrationPopupEssential onClose={handleClose} onClick={startMigration} />;
}

const messages = defineMessages({
  title: {
    description: '!DNT! [title] заголовок попапа для миграции пользователя с другого сайта',
    defaultMessage: "We've moved to joom.ru",
  },
  description: {
    description: '!DNT! текст попапа для миграции пользователя с другого сайта',
    defaultMessage:
      "Your data, including your order information, favourites and collections, saved addresses and payment methods, as well as other settings, were all left on joom.com. Let's transfer this data so that you don't lose it. To do so, simply click on the button below.",
  },
  button: {
    description: '!DNT! [button] кнопка в попапе для миграции пользователя с другого сайта',
    defaultMessage: 'Transfer data',
  },
});

function JmtMigrationPopupEssential({
  onClose,
  onClick,
}: {onClick?: () => void} & Pick<PopupViewProps, 'onClose'>) {
  const scope = useScope();

  useSendAnalyticsOpenClose(
    () => ({type: 'jmtMigrationStartScreenOpen'}),
    (payload) => ({type: 'jmtMigrationStartScreenClose', payload}),
  );

  return (
    <ModalOverlay onClose={onClose} closeDisabled>
      <Popup width="450px">
        <StandaloneControls onClose={onClose} />
        <Content>
          {scope.is(Scope.GLOBAL) && (
            <div className={styles.icon}>
              <Icon name="3d-lock-key" type="illustration" />
            </div>
          )}
          <div className={styles.title}>
            <FormattedMessage {...messages.title} />
          </div>
          <div className={styles.description}>
            <FormattedMessage {...messages.description} />
          </div>
        </Content>
        <Footer>
          <div className={styles.button}>
            <Button tag="button" type="button" color="primary" onClick={onClick}>
              <FormattedMessage {...messages.button} />
            </Button>
          </div>
        </Footer>
      </Popup>
    </ModalOverlay>
  );
}
