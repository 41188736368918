import {eventParamsList} from 'helpers/eventParams';
import {Draft, produce} from 'immer';
import type {EventParams} from 'types/EventParams';
import type {Price} from 'types/Price';
import type {ProductBadge} from 'types/ProductBadge';
import type {ProductLitePatchData} from 'types/ProductPatch';

import {apply} from './apply';

export type ProductLiteLike = {
  badge?: ProductBadge;
  price: Price;
  msrPricePrefix?: string | null;
  msrPrice?: Price;
  discount?: number;
  eventParams?: EventParams;
};

const produceProductLite = (draft: Draft<ProductLiteLike>, patch: ProductLitePatchData) => {
  apply(draft, 'badge', patch.badge);
  apply(draft, 'eventParams', eventParamsList(draft.eventParams, patch.eventParams));
  apply(draft, 'price', patch.prices?.price);
  apply(
    draft,
    'msrPricePrefix',
    patch.prices?.msrPricePrefix || (draft.msrPricePrefix ? null : undefined),
  );
  apply(draft, 'msrPrice', patch.prices?.msrPrice);
  apply(draft, 'discount', patch.prices?.discount);
};

export const patchProductLite = <T extends ProductLiteLike>(
  product: T,
  patch: ProductLitePatchData,
): T => {
  return produce(product, (draft) => produceProductLite(draft, patch));
};
