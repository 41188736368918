import OrderReviewQuestionShape from 'shapes/OrderReviewQuestion';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Locator} from 'components/Locator';
import styles from './Select.scss';

export class Select extends Component {
  static propTypes = {
    onSelect: PropTypes.func,
    type: PropTypes.oneOf(['radio', 'checkbox']),
    name: PropTypes.string.isRequired,
    value: PropTypes.arrayOf(PropTypes.string.isRequired),
    question: OrderReviewQuestionShape.isRequired,
  };

  static defaultProps = {
    onSelect: null,
    type: 'radio',
    value: [],
  };

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      value: props.value,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (state.value !== props.value) {
      return {
        value: props.value,
      };
    }
    return null;
  }

  getValue() {
    return this.state.value;
  }

  handleChange(evt) {
    const inputValue = evt.target.value;
    const stateValue = this.state.value;
    let value = stateValue.filter((val) => val !== inputValue);
    if (value.length === this.state.value.length) {
      if (this.props.type === 'checkbox') {
        value.push(inputValue);
      } else {
        value = [inputValue];
      }
    }

    this.setState({value});
    if (this.props.onSelect) {
      this.props.onSelect(value);
    }
  }

  renderAnswer(answer) {
    const {name} = this.props;
    const {value} = this.state;
    return (
      <Locator id="reviewForm-reason">
        <label key={answer.id}>
          <input
            key="checkbox"
            className={styles.input}
            onChange={this.handleChange}
            type="checkbox"
            name={name}
            checked={value.indexOf(answer.id) !== -1}
            value={answer.id}
          />
          <span className={styles.item}>{answer.title}</span>
        </label>
      </Locator>
    );
  }

  render() {
    const {
      question: {answers},
    } = this.props;

    if (!answers || answers.length === 0) {
      return null;
    }

    return <div>{answers ? answers.map((answer) => this.renderAnswer(answer)) : null}</div>;
  }
}
