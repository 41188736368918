import React, {
  ComponentType,
  forwardRef,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import {shallowEqual} from 'react-redux';

import {LazyHydrate, LazyHydrateProps} from './LazyHydrate';

export type WithLazyHydrateOptions = Omit<LazyHydrateProps, 'children'>;

export function withLazyHydrate<Props>(
  Component: ComponentType<Props>,
  useOptions: WithLazyHydrateOptions | ((props: Props) => WithLazyHydrateOptions),
  compareFn: (a: unknown, b: unknown) => boolean = shallowEqual,
): typeof WithLazyHydrate {
  const WithLazyHydrate = forwardRef(function WithLazyHydrate(props: Props, ref) {
    const useOptionsHook = typeof useOptions === 'function' ? useOptions : () => useOptions;
    const {didHydrate, disabled, ...options} = useOptionsHook(props);

    const [isChanged, setIsChanged] = useState(disabled);
    const handleHydrated = useCallback(() => {
      setIsChanged(true);
      didHydrate?.();
    }, [didHydrate]);

    const prevProps = useRef(props);

    useLayoutEffect(() => {
      if (!disabled && !isChanged && !compareFn(prevProps.current, props)) {
        setIsChanged(true);
      }
      prevProps.current = props;
    }, [isChanged, disabled, props]);

    return (
      <LazyHydrate {...options} didHydrate={handleHydrated} disabled={disabled || isChanged}>
        <Component ref={ref} {...props} />
      </LazyHydrate>
    );
  });

  return WithLazyHydrate;
}
