import {Html} from 'components/HyperText/Html';
import {getFontSize, getFontWeight, toSize} from 'components/ServerDrivenLayout/utils';
import React, {CSSProperties, ReactElement, useMemo} from 'react';
import {SDLText} from 'types/ServerDrivenLayout';
import {convertBackendColorToCSSValue} from 'utils/styles/color';

import styles from './styles.scss';

export function SDLTextView({text}: {text: SDLText}): ReactElement {
  const style = useMemo(() => {
    const style: CSSProperties = {};

    if (text.color) {
      style.color = convertBackendColorToCSSValue(text.color);
    }
    style.fontSize = toSize(getFontSize(text));
    style.fontWeight = getFontWeight(text);
    style.textAlign = text.alignment;

    if (text.maxLines) {
      style.overflow = 'hidden';

      if (text.maxLines === 1) {
        style.whiteSpace = 'nowrap';
      } else {
        // from css
        const lineHeight = 1.2;
        style.display = '-webkit-box';
        style.WebkitBoxOrient = 'vertical';
        style.WebkitLineClamp = text.maxLines;
        style.maxHeight = getFontSize(text) * lineHeight * text.maxLines;
      }
    }

    return style;
  }, [text]);

  const content =
    'htmlText' in text.content ? <Html html={text.content.htmlText} /> : text.content.text;

  return (
    <div className={styles.text} style={style}>
      {content}
    </div>
  );
}
