import MediaBundleShape from 'shapes/MediaBundle';
import PropTypes from 'prop-types';
import React, {Component, createRef} from 'react';
import {MediaUploader} from 'components/MediaUploader';

const MEDIA_UPLOADER_FILTERS = [
  {
    supportedMimeTypes: ['image/png', 'image/jpeg', 'image/heic'],
    maxBytes: 1024 * 1024 * 10 /* 10 Mb */,
  },
  {
    supportedMimeTypes: ['video/*'],
    minBytes: 1024 * 10 /* 10 Kb */,
    maxBytes: 1024 * 1024 * 1024 /* 1 Gb */,
  },
];

export class Photos extends Component {
  static propTypes = {
    maxPhotosCount: PropTypes.number.isRequired,
    media: PropTypes.arrayOf(MediaBundleShape),
    onChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    media: undefined,
  };

  constructor(props) {
    super(props);
    this.uploader = createRef();
  }

  getPhotos() {
    return this.uploader.current?.getImages() || null;
  }

  render() {
    const {maxPhotosCount, media, onChange} = this.props;

    return (
      <MediaUploader
        filters={MEDIA_UPLOADER_FILTERS}
        maxCount={maxPhotosCount}
        onChange={onChange}
        value={media}
      />
    );
  }
}
