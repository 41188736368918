import {requestActionCreator} from 'store/utils/requestActions';
import {PersonalDataFormOrigin} from 'types/PersonalData';
import {FuncAction} from 'typesafe-actions';

import {loadPassportData as loadPassportDataAction, LoadPassportDataResponse} from './actions';

type LoadPassportDataResponseBody = {
  payload: LoadPassportDataResponse;
};

export function loadPassportData(origin: PersonalDataFormOrigin): FuncAction {
  return requestActionCreator(loadPassportDataAction, {origin}, (_, client) =>
    client.secureApi
      .post<LoadPassportDataResponseBody>('/personalData/getPassportData', {
        body: {origin},
      })
      .then(({body: {payload}}) => payload),
  );
}
