import PropTypes from 'prop-types';

import GalleryItem from './GalleryItem';
import ImageBundle from './ImageBundle';

export const TranslationPolicy = {
  ORIGINAL_FIRST: 'originalFirst',
  ORIGINAL_ONLY: 'originalOnly',
  TRANSLATED_FIRST: 'translatedFirst',
  TRANSLATED_ONLY: 'translatedOnly',
};

// eslint-disable-next-line import/no-default-export
export default PropTypes.shape({
  originalLanguage: PropTypes.string,
  originalText: PropTypes.string,
  media: PropTypes.arrayOf(GalleryItem.isRequired),
  starRating: PropTypes.number,
  text: PropTypes.string,
  translationPolicy: PropTypes.oneOf([
    TranslationPolicy.ORIGINAL_FIRST,
    TranslationPolicy.ORIGINAL_ONLY,
    TranslationPolicy.TRANSLATED_FIRST,
    TranslationPolicy.TRANSLATED_ONLY,
  ]),
  updatedTimeMs: PropTypes.number,
  user: PropTypes.shape({
    avatar: ImageBundle,
    fullName: PropTypes.string,
  }),
});
