import classnames from 'classnames/bind';
import Button from 'components/Button';
import {ContextMenu, ContextMenuPlacement} from 'components/ContextMenu';
import {Locator} from 'components/Locator';
import {VisuallyHidden} from 'components/VisuallyHidden';
import {AppLanguageCode} from 'config';
import {ecsError} from 'helpers/log/ECS/ecsError';
import {useAnalytics} from 'hooks/useAnalytics';
import {useApiClient} from 'hooks/useApiClient';
import {useLogger} from 'hooks/useLogger';
import {useRoute} from 'hooks/useRoute';
import React, {useCallback, useEffect, useMemo, useRef} from 'react';
import useDropdownMenu from 'react-accessible-dropdown-menu-hook';
import {defineMessages, FormattedMessage} from 'react-intl';
import {useLocation, useParams} from 'react-router-dom';
import {getUrl, RouteNamesUnion} from 'routes';
import {RouteParams} from 'routes/types';
import {Expandable} from 'types/utility';
import {getLocaleName} from 'utils/localeName';
import {createUrl, getQueryData} from 'utils/url';

import Arrow from './arrow.jsx.svg';
import styles from './index.scss';

const cn = classnames.bind(styles);

export enum LanguageControlType {
  BUTTON = 'button',
  SELECT = 'select',
}

type Props = {
  langPaths: Record<string, string>;
  onSelect(value: string): null;
  selected?: string;
  type?: LanguageControlType;
  from: 'header' | 'sidebar';
};

const messages = defineMessages({
  selectLanguage: {
    description: 'Подпись кнопки выбора языка для экранных читалок',
    defaultMessage: 'Choose a language',
  },
});

/* eslint-disable global-require */
const languageIconMap: Record<AppLanguageCode, unknown> = {
  en: require('./icons/en.svg'),
  cs: require('./icons/cs.svg'),
  de: require('./icons/de.svg'),
  el: require('./icons/el.svg'),
  es: require('./icons/es.svg'),
  et: require('./icons/et.svg'),
  fr: require('./icons/fr.svg'),
  hu: require('./icons/hu.svg'),
  it: require('./icons/it.svg'),
  lt: require('./icons/lt.svg'),
  lv: require('./icons/lv.svg'),
  nb: require('./icons/nb.svg'),
  nl: require('./icons/nl.svg'),
  pl: require('./icons/pl.svg'),
  pt: require('./icons/pt.svg'),
  'pt-br': require('./icons/pt-br.svg'),
  ro: require('./icons/ro.svg'),
  kk: require('./icons/kk.svg'),
  ru: require('./icons/ru.svg'),
  'ru-ua': require('./icons/ru.svg'),
  sk: require('./icons/sk.svg'),
  sv: require('./icons/sv.svg'),
  tr: require('./icons/tr.svg'),
  uk: require('./icons/uk.svg'),
  ka: require('./icons/ka.svg'),
  he: require('./icons/he.svg'),
  ar: require('./icons/ar.svg'),
  ja: require('./icons/ja.svg'),
  lol: require('./icons/lol.svg'),
  // tlh: require('./icons/tlh.svg'),
};
/* eslint-enable global-require */

export function Language({
  langPaths,
  onSelect,
  selected = '',
  type = LanguageControlType.SELECT,
  from,
}: Props): JSX.Element | null {
  const triggerRef = useRef(null);

  const route = useRoute();
  const params = useParams();
  const analytics = useAnalytics();
  const location = useLocation();
  const client = useApiClient();

  const languages = useMemo(() => client.device.getAvailableLanguages(), [client.device]);

  const logger = useLogger('Header/Language');

  const {buttonProps, itemProps, isOpen, setIsOpen, moveFocus} = useDropdownMenu(languages.length, {
    disableFocusFirstItemOnClick: true,
    handleItemKeyboardSelect: (e) => {
      e.preventDefault();
      e.currentTarget.click();
    },
  });

  const getLangUrl = useCallback(
    (lang: string): string => {
      const url = langPaths[lang];
      if (url) {
        return createUrl(url, getQueryData(location.search));
      }

      return getUrl(
        route.name as RouteNamesUnion,
        {...params, lang} as RouteParams<typeof route.name>,
        getQueryData(location.search),
      );
    },
    [langPaths, location, route, params],
  );

  const handleClick = useCallback(
    async (event: React.SyntheticEvent, language: string) => {
      event.preventDefault();

      analytics.dataLayer({
        event: 'Header. Change Language',
        language,
      });

      setIsOpen(false);

      try {
        await onSelect(language);
      } catch (error) {
        logger.error({error: ecsError(error)});
      }

      const url = getLangUrl(language);
      window.location.assign(url);
    },
    [analytics, getLangUrl, logger, onSelect, setIsOpen],
  );

  const handleIconClick = useCallback(
    (event: React.SyntheticEvent) => {
      event.preventDefault();
      analytics.sendEvent({
        type: 'languageClick',
        payload: {from},
      });
      setIsOpen(true);
    },
    [analytics, from, setIsOpen],
  );

  // this does two things.
  // first, it moves focus to currently selected item
  // second, it does it after Popper sets the position of the menu so the sidebar does not jump to the top, closing the menu
  // no, useLayoutEffect does nothing.
  // yes, this is madness.
  useEffect(() => {
    if (isOpen) {
      const index = languages?.findIndex((item) => item === selected);

      if (index !== -1) {
        setTimeout(() => {
          moveFocus(index);
        }, 16);
      }
    }
  }, [isOpen, languages, moveFocus, selected]);

  const handleContextMenuClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const getSrc = useCallback(() => {
    const icon = (languageIconMap as Expandable<typeof languageIconMap>)[selected];
    if (!icon) {
      logger.warn(`Undefined selected language ${selected}`);

      // eslint-disable-next-line global-require
      return require('./icons/undefined.svg');
    }

    return icon;
  }, [logger, selected]);

  const getText = useCallback(
    (lang: string) => {
      return getLocaleName(lang, logger.warn);
    },
    [logger],
  );

  const renderMenu = useCallback(
    (placement?: ContextMenuPlacement) => {
      return (
        <Locator id="LanguageMenu">
          <div>
            <ContextMenu
              placement={placement}
              usePortal={false}
              onClose={handleContextMenuClose}
              triggerRefs={[triggerRef]}
            >
              <div className={styles.languageList}>
                {languages.map((lang, index) => {
                  return (
                    <ContextMenu.Item
                      key={lang}
                      {...itemProps[index]}
                      lang={lang}
                      to={getLangUrl(lang)}
                      // eslint-disable-next-line react/jsx-no-bind
                      onClick={(event) => handleClick(event, lang)}
                      selected={lang === selected}
                    >
                      {getText(lang)}
                    </ContextMenu.Item>
                  );
                })}
              </div>
            </ContextMenu>
          </div>
        </Locator>
      );
    },
    [getLangUrl, getText, handleClick, handleContextMenuClose, itemProps, languages, selected],
  );

  const renderContent = useCallback(() => {
    if (type === LanguageControlType.BUTTON) {
      return (
        <Locator id="Language" isMobile="true">
          <div className={styles.buttonWrapper} ref={triggerRef}>
            <Button
              type="button"
              {...buttonProps}
              onClick={handleIconClick}
              block
              color="lightgrey"
            >
              <div className={styles.language}>
                <div className={styles.icon}>
                  <img
                    alt={(selected || '').toUpperCase()}
                    className={styles.image}
                    src={getSrc()}
                  />
                </div>
                {getText(selected)}
              </div>
            </Button>
            {isOpen && renderMenu(ContextMenuPlacement.TOP)}
          </div>
        </Locator>
      );
    }

    return (
      <Locator id="Language">
        <div className={styles.selectWrapper} ref={triggerRef}>
          <button
            {...buttonProps}
            type="button"
            onClick={handleIconClick}
            className={styles.selectButton}
          >
            <div className={styles.icon}>
              <img
                alt={(selected || '').toUpperCase()}
                className={cn('image', 'selectImage')}
                src={getSrc()}
              />
            </div>
            {getText(selected)}
            <Arrow aria-hidden className={cn('selectArrow', {hasContextMenu: isOpen})} />
          </button>
          {isOpen && renderMenu()}
        </div>
      </Locator>
    );
  }, [buttonProps, getSrc, getText, handleIconClick, isOpen, renderMenu, selected, type]);

  if (languages.length <= 1) {
    return null;
  }

  return (
    <>
      <VisuallyHidden>
        <FormattedMessage {...messages.selectLanguage} />
      </VisuallyHidden>
      {renderContent()}
    </>
  );
}
