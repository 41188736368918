import classnames from 'classnames/bind';
import {DotLoader, DotLoaderStyle} from 'components/DotLoader';
import {useAnalytics} from 'hooks/useAnalytics';
import {useLanguage} from 'hooks/useLanguage';
import {useLocationQuery} from 'hooks/useLocationQuery';
import {useScope} from 'hooks/useScope';
import React, {useCallback, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {CategoryFrom, getCategorySourceByPageSource} from 'types/analytics/Category';
import {PageSource} from 'types/PageSource';
import {createUrl} from 'utils/url';

import {getUrl} from './getUrl';
import styles from './index.scss';
import {BuildUrlFunc, Params} from './types';

const cn = classnames.bind(styles);

type CatalogLinkProps = {
  buildUrl: BuildUrlFunc;
  categoryId?: string;
  children: React.ReactNode;
  isParent?: boolean;
  noHighlight?: boolean;
  onClick?: () => void;
  params: Params;
  withMargin?: boolean;
  loading?: boolean;
  notClickable?: boolean;
  from: CategoryFrom;
  source?: PageSource;
};

export const CatalogLink = React.memo(function CatalogLink({
  buildUrl,
  categoryId = '',
  children,
  isParent = false,
  noHighlight = false,
  onClick,
  params,
  withMargin = false,
  loading = false,
  notClickable = false,
  from,
  source,
}: CatalogLinkProps) {
  const lang = useLanguage();
  const scope = useScope();
  const analytics = useAnalytics();
  const query = useLocationQuery();
  const [isLoading, setIsLoading] = useState(false);
  const isCurrentCategory = params.categoryId === categoryId;

  useEffect(() => {
    setIsLoading(isCurrentCategory);
  }, [isCurrentCategory]);

  useEffect(() => {
    if (!loading || !isCurrentCategory) {
      setIsLoading(false);
    }
  }, [loading, isCurrentCategory]);

  const handleClick = useCallback(() => {
    if (typeof onClick === 'function') {
      onClick();
    }

    analytics.sendEvent({
      type: 'categoriesClick',
      payload: {
        from,
        categoryId,
        source: getCategorySourceByPageSource(source),
      },
    });
  }, [onClick, analytics, categoryId, from, source]);

  const className = cn('link', {
    withMargin,
    isParent,
    highlighted: categoryId === params.categoryId && !noHighlight,
  });

  const inner = isLoading ? (
    <span className={styles.inlineLoading}>
      <DotLoader style={DotLoaderStyle.DARK} />
    </span>
  ) : (
    children
  );

  if (notClickable) {
    return <div className={className}>{inner}</div>;
  }

  const url = createUrl(getUrl(scope, lang, categoryId, params, buildUrl), query);
  return (
    <Link className={className} to={url} onClick={handleClick}>
      <span className={styles.linkInner}>{inner}</span>
    </Link>
  );
});
