import PropTypes from 'prop-types';
import React, {memo} from 'react';
import styles from './OkButton.scss';

export const OkButton = memo(
  ({
    onClick,
    children,
    // TODO(@uid11): Remove restProps after figuring out what to do with Locator
    ...restProps
  }) => {
    return (
      <div className={styles.parent}>
        <span {...restProps} className={styles.button} onClick={onClick} role="button" tabIndex={0}>
          {children}
        </span>
      </div>
    );
  },
);

OkButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};
