import {useDispatch} from 'hooks/redux';
import {useEffectOnce} from 'hooks/useEffectOnce';
import {useCallback, useMemo, useState} from 'react';
import {loadProductCollectionSelectionPopup} from 'store/modules/productCollections';
import {ProductCollectionSelectionPopup} from 'types/ProductCollection/ProductCollectionSelectionPopup';

export function useSelectionPopupData(productId?: string): {
  data: ProductCollectionSelectionPopup | undefined;
  error: unknown;
  loading: boolean;
  loadMore?(): Promise<void>;
} {
  const dispatch = useDispatch();
  const [data, setData] = useState<ProductCollectionSelectionPopup | undefined>();
  const [error, setError] = useState<unknown>();
  const itemKey = useMemo(() => (productId ? {productId} : undefined), [productId]);

  useEffectOnce(() => {
    Promise.resolve(dispatch(loadProductCollectionSelectionPopup({itemKey}))).then(
      setData,
      setError,
    );
  });

  const loadMore = useCallback(async () => {
    if (data?.nextPageToken) {
      await dispatch(loadProductCollectionSelectionPopup({itemKey, pageToken: data.nextPageToken}))
        .then((newData) =>
          setData(() => ({
            ...newData,
            productCollections: [...data.productCollections, ...newData.productCollections],
          })),
        )
        .catch(setError);
    }
  }, [data?.nextPageToken, data?.productCollections, dispatch, itemKey]);

  return {
    data: error ? undefined : data,
    error,
    loading: !data && !error,
    loadMore: data?.nextPageToken ? loadMore : undefined,
  };
}
