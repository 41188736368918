import PropTypes from 'prop-types';
import intersection from 'lodash/intersection';
import ErrorShape from 'shapes/Error';
import {WarrantyTextsShape} from 'shapes/WarrantyTexts';
import React, {useMemo} from 'react';
import {Locator} from 'components/Locator';
import {Warranty} from 'components/Warranty';
import {FormattedMessage} from 'react-intl';
import {ParcelPageCard, ParcelPageCardInner} from 'components/ParcelPageCard';
import {ParcelShape} from 'shapes/Parcel';
import styles from './index.scss';
import {ActionRow} from './ActionRow';

const HIDDEN_ACTIONS = ['cancel', 'return'];

export function Support({
  parcel,
  parcelWarrantyTexts,
  parcelWarrantyTextsLoading,
  parcelWarrantyTextsError,
}) {
  const filteredActionRows = useMemo(
    () =>
      parcel.actionRows?.filter(
        (actionRow) => !intersection(Object.keys(actionRow.action), HIDDEN_ACTIONS).length,
      ),
    [parcel.actionRows],
  );

  return (
    <>
      <ParcelPageCard>
        <Locator id="Warranty-block">
          <ParcelPageCardInner>
            <div className={styles.supportHeader}>
              <FormattedMessage description="Header on parcel page" defaultMessage="Warranty" />
            </div>
            <Warranty
              warrantyTexts={parcelWarrantyTexts}
              warrantyTextsLoading={parcelWarrantyTextsLoading}
              warrantyTextsError={parcelWarrantyTextsError}
            />
          </ParcelPageCardInner>
        </Locator>
      </ParcelPageCard>
      {filteredActionRows?.length ? (
        <ParcelPageCard>
          <Locator id="Support-block">
            <ParcelPageCardInner>
              <div className={styles.supportHeader}>
                <FormattedMessage
                  description="Header on parcel page"
                  defaultMessage="Want to ask us about your order?"
                />
              </div>
              {filteredActionRows.map((actionRow) => (
                <Locator id="ActionRow" type={actionRow.type} key={actionRow.title}>
                  <ActionRow actionRow={actionRow} />
                </Locator>
              ))}
            </ParcelPageCardInner>
          </Locator>
        </ParcelPageCard>
      ) : null}
    </>
  );
}

Support.propTypes = {
  parcel: ParcelShape,
  parcelWarrantyTexts: WarrantyTextsShape,
  parcelWarrantyTextsLoading: PropTypes.bool.isRequired,
  parcelWarrantyTextsError: ErrorShape,
};

Support.defaultProps = {
  parcel: null,
  parcelWarrantyTexts: null,
  parcelWarrantyTextsError: null,
};
