import classNamesBind from 'classnames/bind';
import {Locator} from 'components/Locator';
import throttle from 'lodash/throttle';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {defineMessages, useIntl} from 'react-intl';

import {Dots} from './Dots';
import {AlignmentSide, useControlsVisibility, useScroll, useSwipe} from './hooks';
import styles from './index.scss';
import {
  SliderElements,
  SliderElementsContext,
  SliderElementsSelected,
  SliderElementsSetter,
} from './SliderElementsContext';
import {
  getLeftScrollRatio,
  getNearestOutsideElement,
  prepareDotsState,
  SearchDirection,
} from './utils';

export {SliderItem} from './SliderItem';

export enum SliderScrollType {
  NATIVE = 'nativeScroll',
  SWIPE = 'swipeScroll',
}

type Props = {
  children: React.ReactElement;
  controlsDisabled?: boolean;
  controlsMobile?: boolean;
  loop?: boolean;
  onScroll?: () => void;
  scrollType?: SliderScrollType;
  withDots?: boolean;
};

const messages = defineMessages({
  scrollLeft: {
    description: '[label] Прокрутка влево',
    defaultMessage: 'Scroll left',
  },
  scrollRight: {
    description: '[label] Прокрутка вправо',
    defaultMessage: 'Scroll right',
  },
  region: {
    description: '[a11y] Описание региона с каруселью',
    defaultMessage: 'Carousel of elements',
  },
});

const cn = classNamesBind.bind(styles);

const ITEM_THRESHOLD = 0.5;
const RESIZE_TIMEOUT = 300;

export function Slider({
  children,
  controlsDisabled = false,
  controlsMobile = false,
  loop = false,
  onScroll,
  scrollType = SliderScrollType.NATIVE,
  withDots = false,
}: Props): JSX.Element {
  const intl = useIntl();
  const [selected, setSelected] = useState<SliderElementsSelected>([]);
  const [elements, setElements] = useState<SliderElements>([]);
  const elementsRef = useRef<SliderElements>([]);
  const contentRef = useRef<HTMLDivElement>(null);
  const observer = useRef<IntersectionObserver>();
  const [scrollIntoView, scrollTo, isScrollBusy] = useScroll(contentRef);
  const scrollRatio = useRef<number>(0);
  const loopEnabled = useRef<boolean>(Boolean(loop));
  const [prevVisible, nextVisible, updateControlsVisibility] = useControlsVisibility(
    contentRef,
    !controlsDisabled,
    loop,
  );
  const shouldShowDots = withDots && selected.length > 1;

  // Нужно, чтобы не пересоздавать зависимые от elements колбеки
  elementsRef.current = elements;

  const updateElements = useCallback((cb: SliderElementsSetter) => {
    setElements(cb);
  }, []);

  const updateDotsSelection = useCallback((entries: IntersectionObserverEntry[]) => {
    setSelected((prevSelected) =>
      prepareDotsState(prevSelected, entries, elementsRef.current, ITEM_THRESHOLD),
    );
  }, []);

  // Функция не должна изменяться
  const scrollToNearestElement = useCallback((direction: SearchDirection) => {
    if (!contentRef.current) {
      return;
    }

    const [element, nearest] = getNearestOutsideElement(
      contentRef.current,
      elementsRef.current,
      direction,
      loopEnabled.current,
    );

    if (element && element.current) {
      let align;
      if (nearest) {
        align = direction === SearchDirection.LEFT ? AlignmentSide.LEFT : AlignmentSide.RIGHT;
      } else {
        align = direction === SearchDirection.LEFT ? AlignmentSide.RIGHT : AlignmentSide.LEFT;
      }

      scrollIntoView(element.current, align);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleScroll = useCallback(() => {
    if (onScroll) {
      onScroll();
    }

    scrollRatio.current = getLeftScrollRatio(contentRef.current);
    updateControlsVisibility();
  }, [onScroll]); // eslint-disable-line react-hooks/exhaustive-deps

  const handlePrev = useCallback(() => {
    scrollToNearestElement(SearchDirection.LEFT);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleNext = useCallback(() => {
    scrollToNearestElement(SearchDirection.RIGHT);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleDotClick = useCallback((index: number) => {
    const element = elementsRef.current[index];

    if (element?.current) {
      scrollIntoView(element.current);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      switch (event.key) {
        case 'ArrowLeft':
          handlePrev();
          break;
        case 'ArrowRight':
          handleNext();
          break;
        default:
        // nothing
      }
    },
    [handleNext, handlePrev],
  );

  const elementsContext = useMemo(() => {
    return {
      selected,
      elements,
      updateElements,
      handleNext,
    };
  }, [elements, selected, updateElements, handleNext]);

  useEffect(() => {
    // Initialize observer
    observer.current = new IntersectionObserver(updateDotsSelection, {
      root: contentRef.current,
      threshold: ITEM_THRESHOLD,
    });

    // Initialize resize
    const handleResize = throttle(() => {
      const content = contentRef.current;

      if (content) {
        content.scrollLeft = scrollRatio.current * content.scrollWidth;
      }

      updateControlsVisibility();
    }, RESIZE_TIMEOUT);

    window.addEventListener('resize', handleResize);

    // Initialize controls
    updateControlsVisibility();

    return (): void => {
      // destroy observer
      if (observer.current) {
        observer.current.disconnect();
      }

      // destroy resize
      window.removeEventListener('resize', handleResize);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (observer.current) {
      const nextSelected: SliderElementsSelected = [];

      for (let idx = 0; idx < elements.length; idx += 1) {
        const el = elements[idx]!.current;

        if (el) {
          observer.current.observe(el);
          nextSelected.push(false);
        }
      }

      setSelected(nextSelected);

      return (): void => {
        if (observer.current) {
          observer.current.disconnect();
        }
      };
    }

    return undefined;
  }, [elements]);

  useSwipe(
    contentRef,
    scrollType === SliderScrollType.SWIPE,
    handlePrev,
    handleNext,
    scrollTo,
    isScrollBusy,
  );

  return (
    <Locator id="ContentListSliderItems" active={selected.indexOf(true)}>
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
      <div
        role="region"
        className={styles.slider}
        onKeyDown={handleKeyDown}
        aria-label={intl.formatMessage(messages.region)}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={0}
      >
        {prevVisible && (
          <Locator id="ContentListSliderPrevButton">
            <button
              aria-label={intl.formatMessage(messages.scrollLeft)}
              className={cn(styles.prev, controlsMobile && styles.mobile)}
              onClick={handlePrev}
              tabIndex={0}
              type="button"
            />
          </Locator>
        )}
        <div className={styles.contentWrap}>
          <div ref={contentRef} className={cn('content', scrollType)} onScroll={handleScroll}>
            <SliderElementsContext.Provider value={elementsContext}>
              {children}
            </SliderElementsContext.Provider>
          </div>
        </div>
        {nextVisible && (
          <Locator id="ContentListSliderNextButton">
            <button
              aria-label={intl.formatMessage(messages.scrollRight)}
              className={cn(styles.next, controlsMobile && styles.mobile)}
              onClick={handleNext}
              tabIndex={0}
              type="button"
            />
          </Locator>
        )}
        {shouldShowDots && <Dots selected={selected} onDotClick={handleDotClick} />}
      </div>
    </Locator>
  );
}
