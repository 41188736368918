import {createRequestAction} from 'store/utils/requestActions';
import {PageMetadata} from 'types/PageMetadata';

type LoadRequest = {
  url: string;
};

type LoadResponse = PageMetadata;

const load = createRequestAction(
  '@pageMetadata/LOAD_REQUEST',
  '@pageMetadata/LOAD_SUCCESS',
  '@pageMetadata/LOAD_FAILURE',
)<LoadRequest, LoadResponse>();

export {load, LoadRequest, LoadResponse};
