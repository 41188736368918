import {createSelectorFactory, createStateGetter} from 'store/utils/selectors';
import {get} from 'utils/circularMap';

const selector = createSelectorFactory(createStateGetter('pageMetadata'));

export const isPageMetadataLoading = selector(({state}, url: string) =>
  Boolean(get(state.loading, url)),
);

export const isPageMetadataLoaded = selector(({state}, url: string) =>
  Boolean(get(state.loaded, url)),
);

export const getPageMetadataError = selector(
  ({state}, url: string) => get(state.error, url) || null,
);

export const getPageMetadata = selector(({state, globalState}, url: string) =>
  isPageMetadataLoaded(globalState, url) ? get(state.data, url) : null,
);

export const getMetadataIds = selector(({state}) => state.data.ids);
