import {Icon} from 'components/UIKit/Icon';
import React from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';

import styles from './index.scss';

type Props = {
  onClick: (event: React.SyntheticEvent<HTMLButtonElement, MouseEvent>) => void;
};

const messages = defineMessages({
  label: {
    description: '[button] Кнопка добавления товара в подборку',
    defaultMessage: 'Add to collection',
  },
  mobileLabel: {
    description: '[button] Кнопка добавления товара в подборку',
    defaultMessage: 'Add to collection',
  },
});

export function AddToCollectionButton({onClick}: Props): JSX.Element {
  return (
    <button className={styles.button} type="button" onClick={onClick}>
      <span className={styles.icon}>
        <Icon type="mono" name="add-to-selections-filled-24" width={16} />
      </span>
      <span className={styles.text}>
        <FormattedMessage {...messages.label} />
      </span>
      <span className={styles.mobileText}>
        <FormattedMessage {...messages.mobileLabel} />
      </span>
    </button>
  );
}
