import {createRequestAction} from 'store/utils/requestActions';
import {OfferDescription} from 'types/Offers';

export type ApplyOffersRequest = {
  applyOffers: OfferDescription[];
};

export type ApplyOffersResponse = {
  appliedOffers: OfferDescription[];
};

export const applyOffers = createRequestAction(
  '@offers/applyOffersRequest',
  '@offers/applyOffersResponse',
  '@offers/applyOffersFailure',
)<ApplyOffersRequest, ApplyOffersResponse>();
