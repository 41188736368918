import type {ApiClient} from 'helpers/ApiClient';
import {deepLinks} from 'helpers/deepLink';
import {hasMobileAppForGivenOS, OS} from 'helpers/userAgent';
import {SyntheticEvent, useMemo} from 'react';
import {isDeepLinkAction} from 'types/DeepLinks';
import {UserAgent} from 'types/UserAgent';
import {
  deeplink,
  firebaseLink,
  getRelatedMobileApp,
  googlePlayUrl,
  intent,
  isIntentSupported,
} from 'utils/url/app';
import {enhanceTrackingParams} from 'utils/url/tracking';

import {useApiClient} from './useApiClient';
import {useFullUrl} from './useFullUrl';
import {useLanguage} from './useLanguage';
import {useScope} from './useScope';
import {useUserAgent} from './useUserAgent';

type Handler = (evt?: SyntheticEvent) => void;

type LinkOptions = {
  // Deep link to app, like `joom://cart`
  target: string;
  // Web link for given target, like `/en/cart`.
  // If ommited current current `location.href` will be used.
  link?: string;
};

type HandlerOptions = LinkOptions & {
  // The link to open on platforms beside Android and iOS. This is useful
  // to specify a different behavior on desktop, like displaying a full
  // web page of the app content/payload (as specified by param link)
  // with another dynamic link to install the app.
  // Read more at https://firebase.google.com/docs/dynamic-links/create-manually
  fallback?: string;
  // Will be called before navigation. Useful for analytics purposes.
  onClick?: Handler;
};

const DEEPLINK_DELAY = 500;

const createHandler =
  (client: ApiClient, ua: UserAgent, {target, link, onClick, fallback}: HandlerOptions): Handler =>
  (evt) => {
    evt?.preventDefault();

    if (onClick) {
      onClick(evt);
    }

    const tracking = enhanceTrackingParams(client.tracking.get(), {
      utm_device_id: client.device.getDeviceId(),
    });

    if (isIntentSupported(client.scope, ua)) {
      window.location.href = intent(
        client.scope,
        target,
        tracking,
        fallback || googlePlayUrl(client.scope, target, tracking),
      );
    } else if (ua.os.name === OS.IOS) {
      const {pathname, search} = window.location;
      const url = link || `${pathname}${search}`;
      window.location.href = firebaseLink(client.scope, url, tracking, {iOsFallbackUrl: fallback});
    } else {
      window.location.href = deeplink(client.scope, target, tracking);
      setTimeout(async () => {
        if (document.hasFocus()) {
          const relatedApp = await getRelatedMobileApp(client.scope);
          if (!relatedApp) {
            if (fallback) {
              window.location.href = fallback;
            } else {
              window.location.href = googlePlayUrl(client.scope, target, tracking);
            }
          }
        }
      }, DEEPLINK_DELAY);
    }
  };

export function useAppLinkHandler({
  target,
  link,
  onClick,
  fallback,
}: HandlerOptions): Handler | undefined {
  const client = useApiClient();
  const ua = useUserAgent();
  const fullUrl = useFullUrl();
  const lang = useLanguage();
  const scope = useScope();
  // Mobile clients don't support all kinds of links, only deeplinks
  const targetUrl = useMemo(() => {
    const match = deepLinks.match(`joom://${target}`);

    if (!match) {
      return undefined;
    }

    const result = match.reverse(scope, lang);

    if (isDeepLinkAction(result)) {
      return undefined;
    }

    return result;
  }, [lang, scope, target]);

  return useMemo(
    () =>
      hasMobileAppForGivenOS(ua.os.name, client.scope)
        ? createHandler(client, ua, {
            link: link ?? targetUrl,
            target,
            onClick,
            fallback: fullUrl(fallback),
          })
        : onClick,
    [ua, client, link, targetUrl, target, onClick, fullUrl, fallback],
  );
}
