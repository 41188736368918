import {PaginationRange} from 'components/PaginationRange';
import React, {ReactElement} from 'react';

type Props = {
  count: number;
  current?: number;
  showCurrent?: boolean;
  urlPrefix?: string;
  pagePrefix?: string;
};

export const Pagination = React.memo(function Pagination({
  count,
  current,
  showCurrent = true,
  urlPrefix = '',
  pagePrefix = '',
}: Props): ReactElement | null {
  return (
    <PaginationRange
      from={1}
      to={count}
      current={current}
      showCurrent={showCurrent}
      urlPrefix={urlPrefix}
      pagePrefix={pagePrefix}
    />
  );
});
