import {getSelectors} from 'store/utils/reducerCreators/simple/selectors';
import {createSelectorFactory, Selector} from 'store/utils/selectors';
import {isPersonalDataFormOriginWithFieldSetOnly, PersonalDataFormOrigin} from 'types/PersonalData';

export const {
  isLoading: isPersonalDataExistsLoading,
  isLoaded: isPersonalDataExistsLoaded,
  getData: getPersonalDataExists,
  getError: getPersonalDataExistsError,
  getRequest: getPersonalDataExistsRequest,
} = getSelectors(
  createSelectorFactory((globalState) => globalState.personalData.exists),
  (request, meta, maybeOrigin?: unknown) => {
    if (
      isPersonalDataFormOriginWithFieldSetOnly(request.origin) &&
      isPersonalDataFormOriginWithFieldSetOnly(maybeOrigin)
    ) {
      return request.origin.fieldSet.id === maybeOrigin.fieldSet.id;
    }

    return false;
  },
);

export const isPersonalDataExists: Selector<boolean, [PersonalDataFormOrigin]> = (state, origin) =>
  Boolean(getPersonalDataExists(state, origin)?.exists);
