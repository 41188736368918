import {PaymentMethodKnownType} from 'shapes/PaymentMethodItem';
import {CheckoutSession, CheckoutSessionGroup} from 'types/CheckoutSession';
import {CheckoutSessionParameter} from 'types/CheckoutSessionParameter';
import {CheckoutSessionParameterBanner} from 'types/CheckoutSessionParameterBanner';
import {
  CheckoutSessionParameterAddressItem,
  CheckoutSessionParameterDeliveryItem,
  CheckoutSessionParameterDeliveryPointItem,
  CheckoutSessionParameterItem,
  CheckoutSessionParameterPaymentMethodItem,
  CheckoutSessionPaymentMethod,
  CheckoutSessionPaymentMethodPoints,
  KnownCheckoutSessionPaymentMethods,
} from 'types/CheckoutSessionParameterItem';
import {CheckoutSessionParameterValue} from 'types/CheckoutSessionParameterValue';
import {
  CheckoutSessionSelectParameterValueStepPayload,
  CheckoutSessionStep,
} from 'types/CheckoutSessionStep';
import {CheckoutSessionStepBundle} from 'types/CheckoutSessionStepBundle';
import {PaymentMethod, PaymentMethodType} from 'types/PaymentMethod';
import {arrayToObject} from 'utils/array';
import {memoizeLastShallowEqual} from 'utils/memoize';
import {getAnyObjectValue, getValueByPath} from 'utils/object';

export enum ParameterType {
  DELIVERY = 'delivery',
  PAYMENT_METHOD = 'paymentMethod',
  POINTS = 'points',
}

export enum DeliveryParamaterType {
  COURIER = 'courier',
  MAIL = 'mail',
  FAST_MAIL = 'fastMail',
  DELIVERY_POINT = 'deliveryPoint',
  // coolbe fake mail
  LOCAL_MAIL = 'localMail',
  // not for ui, technical purpose only
  HINT_ADDRESS = 'hintAddress',
  ADDRESS_MAIL = 'addressMail',
  ADDRESS_FAST_MAIL = 'addressFastMail',
}

export function createParameterValue<ParameterValue extends CheckoutSessionParameterValue['value']>(
  parameterId: string,
  value: ParameterValue,
): {parameterId: string; value: ParameterValue} {
  return {parameterId, value};
}

export function createSelectParameterValueStep(parameterId: string): {
  payload: CheckoutSessionSelectParameterValueStepPayload;
} {
  return {payload: {selectParameterValue: {parameterId}}};
}

const getParameters = memoizeLastShallowEqual(
  (parameters: CheckoutSessionParameter[]) => arrayToObject(parameters, ({id}) => id) || {},
);

const getParameterValues = memoizeLastShallowEqual(
  (values: CheckoutSessionParameterValue[]) =>
    arrayToObject(values, ({parameterId}) => parameterId) || {},
);

export function getSessionCheckoutGroups(session: CheckoutSession): CheckoutSessionGroup[] {
  return getValueByPath(session, 'groups') ?? [];
}

export function getCheckoutGroupCurrentStep(
  checkoutGroup: CheckoutSessionGroup,
): CheckoutSessionStep | undefined {
  return getValueByPath(checkoutGroup, 'stepBundle', 'currentStep');
}

export function getParametersDict(
  stepBundle: CheckoutSessionStepBundle,
): Record<string, CheckoutSessionParameter> {
  return getParameters(getValueByPath(stepBundle, 'parameters'));
}

export function getParameterValuesDict(
  stepBundle: CheckoutSessionStepBundle,
): Record<string, CheckoutSessionParameterValue> {
  return getParameterValues(getValueByPath(stepBundle, 'values'));
}

export function findParameter(
  stepBundle: CheckoutSessionStepBundle | undefined,
  id: string,
): CheckoutSessionParameter | null {
  const dict = stepBundle ? getParametersDict(stepBundle) : {};
  return dict[id] || null;
}

export function findParameterValue(
  stepBundle: CheckoutSessionStepBundle | undefined,
  parameterId: string,
): CheckoutSessionParameterValue | null {
  const dict = stepBundle ? getParameterValuesDict(stepBundle) : {};
  return dict[parameterId] || null;
}

export function getParameterValueSelectedId(
  parameterValue: CheckoutSessionParameterValue | null,
  key: string,
): string | null {
  return getValueByPath(parameterValue, 'value', key, 'id') || null;
}

export function getParameterAddressesList(
  parameter: CheckoutSessionParameter | null,
): CheckoutSessionParameterAddressItem[] {
  return getValueByPath(parameter, 'payload', 'address', 'addresses') || [];
}

export function getParameterDeliveryPointsList(
  parameter: CheckoutSessionParameter | null,
): CheckoutSessionParameterDeliveryPointItem[] {
  return getValueByPath(parameter, 'payload', 'deliveryPoint', 'deliveryPoints') || [];
}

export function getParameterDeliveriesList(
  parameter: CheckoutSessionParameter | null,
): CheckoutSessionParameterDeliveryItem[] {
  return getValueByPath(parameter, 'payload', 'delivery', 'delivery') || [];
}

export function getParameterDeliveryInnerBanners(
  parameter: CheckoutSessionParameter | null,
): CheckoutSessionParameterBanner[] {
  return getValueByPath(parameter, 'payload', 'delivery', 'innerBanners') || [];
}

export function getParameterPaymentMethodsList(
  parameter: CheckoutSessionParameter | null,
): CheckoutSessionParameterPaymentMethodItem[] {
  return getValueByPath(parameter, 'payload', 'paymentMethod', 'paymentMethods') || [];
}

export const getParameterPaymentMethodPoints = memoizeLastShallowEqual(
  (parameter: CheckoutSessionParameter): CheckoutSessionPaymentMethodPoints | null => {
    const paymentMethods = getParameterPaymentMethodsList(parameter);

    const item = paymentMethods.find(
      ({payload: {paymentMethod}}) => paymentMethod.id === PaymentMethodKnownType.POINTS,
    );

    return (item?.payload?.paymentMethod as CheckoutSessionPaymentMethodPoints) || null;
  },
);

export function getPaymentMethod(
  stepBundle: CheckoutSessionStepBundle | undefined,
): PaymentMethod | null {
  return (
    (getValueByPath(
      stepBundle,
      'currentStep',
      'payload',
      'pay',
      'paymentMethod',
    ) as PaymentMethod) || null
  );
}

export function getPaymentMethodType(
  stepBundle: CheckoutSessionStepBundle | undefined,
): PaymentMethodType | undefined {
  return getPaymentMethod(stepBundle)?.type;
}

export function getCheckoutGroupCurrentStepIsPay(
  stepBundle: CheckoutSessionStepBundle | undefined,
): boolean {
  return Boolean(getValueByPath(stepBundle, 'currentStep', 'payload', 'pay'));
}

export function getConfirmation(
  stepBundle: CheckoutSessionStepBundle,
): CheckoutSessionParameterDeliveryItem['payload']['delivery']['confirmation'] | null {
  const parameter = findParameter(stepBundle, 'delivery');
  const value = findParameterValue(stepBundle, 'delivery');

  const deliveries = getParameterDeliveriesList(parameter);
  const parameterId = getParameterValueSelectedId(value, 'delivery');

  const parameterItem = deliveries.find((item) => item.payload.delivery.id === parameterId);

  return getValueByPath(parameterItem, 'payload', 'delivery', 'confirmation') || null;
}

export function getIsFullPaymentWithPoints(sessionGroup: CheckoutSessionGroup): boolean {
  if (!sessionGroup.stepBundle) {
    return false;
  }
  const pointsParameterValue = findParameterValue(sessionGroup.stepBundle, ParameterType.POINTS);
  const isPartialPointsPaymentEnabled =
    getValueByPath(pointsParameterValue, 'value', 'points', 'usePoints') === true;
  const isCostOfProductIsZero = sessionGroup.totalPrice?.amount === 0;

  return isPartialPointsPaymentEnabled && isCostOfProductIsZero;
}

export function findDeliveryParameterItem(
  stepBundle: CheckoutSessionStepBundle,
  parameterId: string,
): CheckoutSessionParameterItem | undefined | null {
  const parameter = findParameter(stepBundle, parameterId);
  const value = findParameterValue(stepBundle, parameterId);

  if (parameterId === DeliveryParamaterType.DELIVERY_POINT) {
    const id = getParameterValueSelectedId(value, 'deliveryPoint');
    if (id) {
      const list = getParameterDeliveryPointsList(parameter);
      return list.find((item) => item.payload.deliveryPoint.id === id);
    }
  } else {
    const id = getParameterValueSelectedId(value, 'address');
    if (id) {
      const list = getParameterAddressesList(parameter);
      return list.find((item) => item.payload.address.id === id);
    }
  }

  return null;
}

export function getDeliveryParameterItemStepId(
  stepBundle: CheckoutSessionStepBundle,
  id: string,
): string | undefined | null {
  const parameter = findParameter(stepBundle, ParameterType.DELIVERY);
  const list = getParameterDeliveriesList(parameter);
  const parameterItem = list.find((item) => item.payload.delivery.id === id);
  if (!parameterItem?.step?.payload) {
    return null;
  }

  // Step is an object with only one key.
  // Try to extract step payload data from parameter item to grab parameterId.
  const data = getAnyObjectValue(parameterItem.step.payload);
  return (data && 'parameterId' in data && (data.parameterId as string)) || null;
}

export function getDeliveryParameterItemStepItem(
  stepBundle: CheckoutSessionStepBundle,
  id: string,
): CheckoutSessionParameterItem | undefined | null {
  const parameterId = getDeliveryParameterItemStepId(stepBundle, id);
  return parameterId ? findDeliveryParameterItem(stepBundle, parameterId) : null;
}

type CheckoutSessionPaymentMethodById<Id extends string> = KnownCheckoutSessionPaymentMethods & {
  id: Id;
} extends never
  ? CheckoutSessionPaymentMethod
  : KnownCheckoutSessionPaymentMethods & {id: Id};

export function findPaymentMethod<const Id extends string>(
  stepBundle: CheckoutSessionStepBundle,
  paymentMethodId: Id,
): CheckoutSessionPaymentMethodById<Id> | undefined {
  const parameter = findParameter(stepBundle, ParameterType.PAYMENT_METHOD);
  const paymentMethods = getParameterPaymentMethodsList(parameter);
  const paymentMethod = paymentMethods.find(
    ({payload: {paymentMethod}}) => paymentMethod.id === paymentMethodId,
  );

  return paymentMethod?.payload.paymentMethod as CheckoutSessionPaymentMethodById<Id>;
}

export const findPaymentMethodInSessionGroup = <const Id extends string>(
  sessionGroup: CheckoutSessionGroup,
  paymentMethodId: Id,
): CheckoutSessionPaymentMethodById<Id> | undefined => {
  const {stepBundle} = sessionGroup;

  if (stepBundle) {
    return findPaymentMethod(stepBundle, paymentMethodId);
  }

  return undefined;
};
