import {requestActionCreator} from 'store/utils/requestActions';
import {PersonalDataFormOrigin} from 'types/PersonalData';
import {FuncAction} from 'typesafe-actions';

import {
  loadPersonalDataExists as loadPersonalDataExistsAction,
  LoadPersonalDataExistsResponse,
} from './actions';

type LoadPersonalDataExistsResponseBody = {
  payload: LoadPersonalDataExistsResponse;
};

export function loadPersonalDataExists(origin: PersonalDataFormOrigin): FuncAction {
  return requestActionCreator(loadPersonalDataExistsAction, {origin}, (_, client) =>
    client.secureApi
      .post<LoadPersonalDataExistsResponseBody>('/personalData/exists', {
        body: {origin},
      })
      .then(({body: {payload}}) => payload),
  );
}
