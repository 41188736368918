import {useAnalytics} from 'hooks/useAnalytics';
import {useCurrentUrlForAnalytics} from 'hooks/useCurrentUrlForAnalytics';
import {useObserveOnce} from 'hooks/useObserveOnce';
import React, {useCallback, useRef} from 'react';

type UseFooterPreviewAnalyticsProps = {
  productId?: string;
};

type UseFooterPreviewAnalyticsRenderProps = React.RefObject<HTMLElement>;

export const useFooterPreviewAnalytics = ({
  productId,
}: UseFooterPreviewAnalyticsProps): UseFooterPreviewAnalyticsRenderProps => {
  const ref = useRef<HTMLElement>(null);
  const url = useCurrentUrlForAnalytics();
  const analytics = useAnalytics();
  const handleObserve = useCallback(() => {
    analytics.sendEvent({
      type: 'footerPreview',
      payload: {
        url,
        ...(productId ? {productId} : {}),
      },
    });
  }, [analytics, productId, url]);

  useObserveOnce(handleObserve, {targetRef: ref});

  return ref;
};
