import PropTypes from 'prop-types';

import ErrorShape from './Error';

export const CoordinatesShape = PropTypes.shape({
  latitude: PropTypes.number.isRequired,
  longitude: PropTypes.number.isRequired,
});

export const DeliveryPointInfoShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  coordinates: CoordinatesShape.isRequired,
  requiresForm: PropTypes.bool.isRequired,
  address: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  additionalPhoneNumbers: PropTypes.arrayOf(PropTypes.string),
  workingHours: PropTypes.string.isRequired,
  shelfTime: PropTypes.string,
  extra: PropTypes.string,
  type: PropTypes.oneOf(['X5-CASHBOX', 'X5-POSTAMAT']),
});

export const DeliveryPointInfoStateShape = PropTypes.shape({
  data: PropTypes.arrayOf(DeliveryPointInfoShape),
  center: CoordinatesShape,
  loading: PropTypes.bool,
  loadingError: ErrorShape,
  pageLoading: PropTypes.bool,
  pageLoadingError: ErrorShape,
  nextPageToken: PropTypes.string,
});

export const ActiveDeliveryPointInfoStateShape = PropTypes.shape({
  ids: PropTypes.arrayOf(PropTypes.string),
  data: PropTypes.objectOf(DeliveryPointInfoShape),
  loading: PropTypes.bool,
  loadingError: ErrorShape,
});

export const DeliveryPointLocationShape = PropTypes.oneOf([
  PropTypes.shape({
    id: PropTypes.string.isRequired,
    coordinates: CoordinatesShape.isRequired,
  }),
  PropTypes.shape({
    coordinates: CoordinatesShape.isRequired,
    points: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
      }),
    ),
  }),
]);

export const DeliveryPointSelectionShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  info: DeliveryPointInfoShape.isRequired,
  fields: PropTypes.object.isRequired,
});

export const AddressInfoShape = PropTypes.shape({
  fields: PropTypes.object.isRequired,
  coordinates: CoordinatesShape.isRequired,
});

export const AddressSuggestionShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  details: PropTypes.string,
});

export const AddressSuggestionStateShape = PropTypes.shape({
  items: PropTypes.arrayOf(AddressSuggestionShape),
  loading: PropTypes.bool,
  loadingError: ErrorShape,
});

export const AddressSearchStateShape = PropTypes.shape({
  searchPhrase: PropTypes.string,
  item: AddressInfoShape,
  loading: PropTypes.bool,
  loadingError: ErrorShape,
});

export const AddressAppearanceShape = PropTypes.shape({
  hideAddressSearch: PropTypes.bool,
});
