import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Locator} from 'components/Locator';
import styles from './Textarea.scss';

export class Textarea extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    value: PropTypes.string,
  };

  static defaultProps = {
    onChange: null,
    placeholder: '',
    value: '',
  };

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = {value: props.value};
  }

  static getDerivedStateFromProps(props, state) {
    if (state.value !== props.value) {
      return {
        value: props.value,
      };
    }
    return null;
  }

  getValue() {
    return this.state.value;
  }

  handleChange(evt) {
    const {value} = evt.target;
    if (value !== this.state.value) {
      this.setState({value});
      if (this.props.onChange) {
        this.props.onChange(value);
      }
    }
  }

  render() {
    const {name, placeholder} = this.props;
    const {value} = this.state;

    return (
      <Locator id="orderReview-textarea">
        <textarea
          className={styles.textarea}
          name={name}
          onChange={this.handleChange}
          placeholder={placeholder}
          value={value}
        />
      </Locator>
    );
  }
}
