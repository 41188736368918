import without from 'lodash/without';
import React, {useCallback, useContext, useLayoutEffect, useMemo, useRef, useState} from 'react';
import {defineMessages, useIntl} from 'react-intl';

import {SliderElementsContext} from './SliderElementsContext';

type ChildProps = {
  a11yProps: Record<string, string | number | boolean>;
  selected: boolean;
  ref: React.MutableRefObject<HTMLDivElement> | React.RefObject<HTMLDivElement>;
  pauseAutoscroll?: () => void;
  unpauseAutoscroll?: () => void;
};

type Props = {
  children: (props: ChildProps) => React.ReactElement;
  timeout?: number;
};

const messages = defineMessages({
  slideLabel: {
    description: '[label] Счётчик слайдов',
    defaultMessage: 'Slide {from} of {number}',
  },
});

export function SliderItem({children, timeout = 0}: Props): React.ReactElement {
  const {elements, selected, updateElements, handleNext} = useContext(SliderElementsContext);
  const ref = useRef<HTMLDivElement>(null);
  const intl = useIntl();
  const index = useMemo(() => elements.indexOf(ref), [elements]);
  const isSelected = Boolean(selected[index]);

  useLayoutEffect(() => {
    if (!ref.current) {
      return undefined;
    }

    updateElements((elements) => {
      if (elements.indexOf(ref) === -1) {
        const nextElements = elements.slice();

        nextElements.push(ref);

        return nextElements;
      }

      return elements;
    });

    return (): void => {
      updateElements((elements) => without(elements, ref));
    };
  }, [updateElements]);

  const shouldAutoscroll = useMemo(() => Boolean(timeout) && timeout > 0, [timeout]);
  const autoscrollTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [paused, setPaused] = useState(false);

  useLayoutEffect(() => {
    if (shouldAutoscroll && isSelected && !paused) {
      autoscrollTimeoutRef.current = setTimeout(() => {
        handleNext();
      }, timeout);
    }

    return () => {
      if (autoscrollTimeoutRef.current) {
        clearInterval(autoscrollTimeoutRef.current);
        autoscrollTimeoutRef.current = null;
      }
    };
  }, [shouldAutoscroll, timeout, handleNext, isSelected, paused]);

  const handlePause = useCallback(() => {
    setPaused(true);
  }, [setPaused]);

  const handleUnpause = useCallback(() => {
    setPaused(false);
  }, [setPaused]);

  return children({
    ref,
    a11yProps: {
      'aria-hidden': !isSelected,
      'aria-label': intl.formatMessage(messages.slideLabel, {
        from: index + 1,
        number: elements.length,
      }),
      role: 'group',
    },
    pauseAutoscroll: shouldAutoscroll ? handlePause : undefined,
    unpauseAutoscroll: shouldAutoscroll ? handleUnpause : undefined,
    selected: isSelected,
  });
}
