import PropTypes from 'prop-types';
import {AuthProvider} from 'types/AuthProvider';

const OnOffValue = PropTypes.oneOf(['on', 'off']);

export const SocialAuthShape = PropTypes.shape({
  [AuthProvider.FACEBOOK]: OnOffValue,
  [AuthProvider.GOOGLE]: OnOffValue,
  [AuthProvider.APPLE]: OnOffValue,
  [AuthProvider.OK]: OnOffValue,
  [AuthProvider.VK]: OnOffValue,
  order: PropTypes.arrayOf(
    PropTypes.shape({
      provider: PropTypes.oneOf(Object.values(AuthProvider)),
      hidden: PropTypes.bool,
    }),
  ),
});

export const DeviceVarsShape = PropTypes.object;
