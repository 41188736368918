import {useCallback, useEffect, useState} from 'react';

import {Category} from './types';

export function useCloseIfNoChildren({
  onClose,
  loading,
  children,
}: {
  onClose?: () => void;
  loading: boolean;
  children?: Category[];
}): (() => void) | undefined {
  const [clicked, setClicked] = useState(false);

  const handleClick = useCallback(() => {
    setClicked(true);
  }, []);

  useEffect(() => {
    if (clicked && !loading && !children && onClose) {
      onClose();
      setClicked(false);
    }
  }, [loading, clicked, children, onClose]);

  if (!onClose) {
    return undefined;
  }

  return handleClick;
}
