import {getValueByPath} from 'utils/object';
import HorizontalPane from 'components/HorizontalPane';
import {ProductLite} from 'components/ProductLite';
import {createLocator} from 'create-locator';
import {ContentListItemShape} from 'shapes/ContentListItem';
import ProductLiteShape from 'shapes/ProductLite';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import styles from './index.scss';

export class ProductSlider extends Component {
  static propTypes = {
    header: PropTypes.node,
    products: PropTypes.arrayOf(
      PropTypes.oneOfType([
        ProductLiteShape,
        ContentListItemShape, // wrapped to content list content list
      ]).isRequired,
    ),
    small: PropTypes.bool,
    smallHeader: PropTypes.bool,
  };

  static defaultProps = {
    header: null,
    products: null,
    small: false,
    smallHeader: false,
  };

  renderPrev() {
    return <div className={styles.prev} />;
  }

  renderNext() {
    return <div className={styles.next} />;
  }

  renderList() {
    const locator = createLocator(this.props);
    const products = getValueByPath(this.props.products, 0, 'content', 'product')
      ? this.props.products.map(({content}) => content.product)
      : this.props.products;

    const itemProps = {
      bordered: true,
      compact: true,
      vwFit: {xs: '7.44rem'},
    };

    return (
      <div className={styles.container}>
        {products.map((product) => (
          <ProductLite
            {...itemProps}
            className={styles.rowItem}
            key={product.id}
            product={product}
            {...locator.productLite()}
          />
        ))}
      </div>
    );
  }

  render() {
    const locator = createLocator(this.props);
    const {header, products, small, smallHeader} = this.props;

    if (!products || products.length === 0) {
      return null;
    }

    return (
      <div className={small ? styles.productsSmall : styles.productsBig} {...locator()}>
        {Boolean(header) && (
          <h2 className={smallHeader ? styles.smallHeader : styles.header}>{header}</h2>
        )}
        <HorizontalPane prev={this.renderPrev()} next={this.renderNext()}>
          {this.renderList()}
        </HorizontalPane>
      </div>
    );
  }
}
