import {Background} from 'types/Background';
import {Color} from 'types/Color';
import {ColoredText} from 'types/ColoredText';
import {EventParamsList} from 'types/EventParams';
import {ImageBundle} from 'types/Image';

import {Timer} from './common';

export enum SlimBannerArrowType {
  'CARET' = 'caret',
  'WITH_LINE' = 'withLine',
}

export type SlimBannerArrow = {
  color?: Color;
  type?: SlimBannerArrowType;
};

export type SlimBanner = {
  title: ColoredText;
  subtitle?: ColoredText;
  background?: Background;
  image?: ImageBundle;
  timer?: Timer;
  url?: string;
  timerMaxDuration?: number;
  arrow?: SlimBannerArrow;
  eventParams?: EventParamsList;
};
