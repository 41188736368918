import {Overlay} from 'components/Overlay';
import {Button} from 'components/UIKit/Button';
import {eventParamsList} from 'helpers/eventParams';
import {useAnalytics} from 'hooks/useAnalytics';
import React, {useCallback, useState} from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';
import {CouponSelection} from 'types/Cart';
import type {EventParams} from 'types/EventParams';
import {ContentItem} from 'types/Popup';

import styles from './index.scss';

type Props = {
  popupId: string;
  eventParams?: EventParams;
  couponButton: NonNullable<ContentItem['couponButton']>;
  updateCart(couponSelection: CouponSelection): Promise<unknown>;
  loadCart(couponSelection: CouponSelection): Promise<unknown>;
  isCartLoaded: boolean;
  loadCouponCards(): Promise<unknown>;
  onChangeState?(): void;
};

const messages = defineMessages({
  apply: {
    description: 'Текст на кнопке применения купона',
    defaultMessage: 'Apply coupon',
  },
  applied: {
    description: 'Текст на кнопке, когда купон применен',
    defaultMessage: 'Cancel coupon',
  },
});

export const CouponButton: React.FC<Props> = ({
  popupId,
  couponButton: {id, applied},
  updateCart,
  loadCart,
  isCartLoaded,
  loadCouponCards,
  onChangeState,
  eventParams,
}: Props) => {
  const [updating, setIsUpdating] = useState(false);
  const [couponApplied, setApplied] = useState(applied);
  const analytics = useAnalytics();

  const handleApply = useCallback(() => {
    setIsUpdating(true);
    const params = eventParamsList(eventParams);
    analytics.sendEvent({
      type: 'popupButtonClick',
      payload: {
        popupId,
        couponId: id,
      },
      params,
    });
    analytics.sendEvent({
      type: couponApplied ? 'couponDeselect' : 'couponSelect',
      payload: {
        couponId: id,
        source: 'couponPopup',
      },
      params,
    });
    (isCartLoaded ? updateCart : loadCart)(
      couponApplied
        ? {none: {}}
        : {
            byId: {
              couponId: id,
            },
          },
    )
      .then(() => loadCouponCards())
      .then(() => setApplied(!couponApplied))
      .finally(() => {
        setIsUpdating(false);
        if (typeof onChangeState === 'function') {
          onChangeState();
        }
      });
  }, [
    analytics,
    popupId,
    id,
    eventParams,
    couponApplied,
    updateCart,
    loadCart,
    isCartLoaded,
    loadCouponCards,
    onChangeState,
  ]);

  return (
    <Overlay loading={updating}>
      <div className={styles.couponButton}>
        <Button size="large" color="primary" tag="button" onClick={handleApply}>
          <FormattedMessage {...(couponApplied ? messages.applied : messages.apply)} />
        </Button>
      </div>
    </Overlay>
  );
};
