import {Locator} from 'components/Locator';
import {isMobileOrTabletDevice} from 'helpers/userAgent';
import {useUserAgent} from 'hooks/useUserAgent';
import React, {AnchorHTMLAttributes} from 'react';
import {UserAgent} from 'types/UserAgent';

function getAppLinkRel(userAgent: UserAgent, target: string) {
  return target === '_blank' && isMobileOrTabletDevice(userAgent)
    ? undefined
    : 'noopener noreferrer';
}

export function AppLink(props: AnchorHTMLAttributes<HTMLAnchorElement>): React.ReactElement {
  const userAgent = useUserAgent();
  /* eslint-disable jsx-a11y/anchor-has-content, react/destructuring-assignment */
  return (
    <Locator id="AppLink">
      <a {...props} rel={getAppLinkRel(userAgent, props.target || '_blank')} />
    </Locator>
  );
  /* eslint-enable jsx-a11y/anchor-has-content */
}
