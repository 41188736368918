import cn from 'classnames';
import {Image} from 'components/Image';
import {Locator} from 'components/Locator';
import {Suggest} from 'components/Suggest';
import {Icon} from 'components/UIKit/Icon';
import React, {useMemo} from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';
import {
  isSuggestCategoryItem,
  SuggestCategoryItem as SuggestCategoryItemType,
  SuggestItem as SuggestItemType,
} from 'types/SearchSuggest';

import styles from './index.scss';

const messages = defineMessages({
  headerTitle: {
    defaultMessage: 'History',
    description: 'Title of the search history',
  },
  headerClearButtonLabel: {
    defaultMessage: 'Clear',
    description: 'Label of the button to clear the search history',
  },
  parentCategoryLabel: {
    defaultMessage: 'In {categoryName}',
    description: 'Pointer to the parent category in the suggest item',
  },
});

type SearchSuggestCategoryItemProps = {
  active?: boolean;
  item: SuggestCategoryItemType;
  onClick: (item: SuggestItemType) => void;
  onSetActive: (item: SuggestItemType) => void;
};

function SearchSuggestCategoryItem({
  active,
  item,
  onClick,
  onSetActive,
}: SearchSuggestCategoryItemProps): JSX.Element | null {
  const category = item.filters[0].value.items?.[0];

  if (!category) {
    return null;
  }

  const parentCategory = item.extraInfo?.category;

  return (
    <Suggest.Item
      active={active}
      className={cn(styles.item, styles.categoryItem)}
      item={item}
      onClick={onClick}
      onSetActive={onSetActive}
    >
      {category.mainImage ? (
        <div className={styles.categoryItemImage}>
          <Image block image={category.mainImage} pxFit={40} />
        </div>
      ) : null}
      <div className={styles.categoryItemContent}>
        <div className={styles.categoryItemTitle}>{category.name}</div>
        {parentCategory ? (
          <div className={styles.categoryItemSubtitle}>
            <FormattedMessage
              {...messages.parentCategoryLabel}
              values={{categoryName: parentCategory.name}}
            />
          </div>
        ) : null}
      </div>
      <div className={styles.categoryItemArrow}>
        <Icon height={24} name="chevron-right-linear-24" type="mono" width={24} />
      </div>
    </Suggest.Item>
  );
}

type SearchSuggestQueryItemProps = {
  active?: boolean;
  item: SuggestItemType;
  onClick: (item: SuggestItemType) => void;
  onSetActive: (item: SuggestItemType) => void;
  recent: boolean;
};

function SearchSuggestQueryItem({
  active,
  item,
  onClick,
  onSetActive,
  recent,
}: SearchSuggestQueryItemProps): JSX.Element | null {
  if (!item.query) {
    return null;
  }

  return (
    <Suggest.Item
      active={active}
      className={cn(styles.item, styles.queryItem)}
      item={item}
      onClick={onClick}
      onSetActive={onSetActive}
    >
      <div className={styles.queryItemIcon}>
        {recent ? (
          <Locator id="SearchSuggestItemIconRecent">
            <Icon height={24} name="time-linear-24" type="mono" width={24} />
          </Locator>
        ) : (
          <Locator id="SearchSuggestItemIcon">
            <Icon height={24} name="search-linear-24" type="mono" width={24} />
          </Locator>
        )}
      </div>
      <div className={styles.queryItemText}>{item.query}</div>
    </Suggest.Item>
  );
}

type SearchSuggestItemProps = {
  active?: boolean;
  item: SuggestItemType;
  onClick: (item: SuggestItemType) => void;
  onSetActive: (item: SuggestItemType) => void;
  recent?: boolean;
};

function SearchSuggestItem({
  active,
  item,
  onClick,
  onSetActive,
  recent = false,
}: SearchSuggestItemProps): JSX.Element {
  if (isSuggestCategoryItem(item)) {
    return (
      <SearchSuggestCategoryItem
        active={active}
        item={item}
        onClick={onClick}
        onSetActive={onSetActive}
      />
    );
  }

  return (
    <SearchSuggestQueryItem
      active={active}
      item={item}
      onClick={onClick}
      onSetActive={onSetActive}
      recent={recent}
    />
  );
}

type SearchSuggestRecentHeaderProps = {
  onClear?: () => void;
};

function SearchSuggestRecentHeader({onClear}: SearchSuggestRecentHeaderProps): JSX.Element {
  return (
    <div className={styles.header}>
      <Locator id="RecentSearchQueriesTitle">
        <div className={styles.headerTitle}>
          <FormattedMessage {...messages.headerTitle} />
        </div>
      </Locator>
      {onClear ? (
        <Locator id="ClearRecentSearchQueriesButton">
          <button className={styles.headerClearButton} onClick={onClear} type="button">
            <FormattedMessage {...messages.headerClearButtonLabel} />
          </button>
        </Locator>
      ) : null}
    </div>
  );
}

type SearchSuggestProps = {
  input: HTMLInputElement;
  items?: SuggestItemType[];
  mobile?: boolean;
  onClear?: () => void;
  onClose: () => void;
  onSelect: (item: SuggestItemType) => void;
  onSetActive: (item: SuggestItemType) => void;
  recentItems?: SuggestItemType[];
  uid: string;
  'data-testid'?: string;
};

export function SearchSuggest({
  input,
  items,
  mobile = false,
  onClear,
  onClose,
  onSelect,
  onSetActive,
  recentItems,
  uid,
  'data-testid': testId,
}: SearchSuggestProps): JSX.Element {
  const allItems = useMemo(() => {
    const result: SuggestItemType[] = [];
    const queryItems: SuggestItemType[] = [];
    const categoryItems: SuggestItemType[] = [];

    items?.forEach((item) => {
      if (isSuggestCategoryItem(item)) {
        categoryItems.push(item);
      } else {
        queryItems.push(item);
      }
    });

    return mobile
      ? result.concat(categoryItems ?? [], recentItems ?? [], queryItems ?? [])
      : result.concat(recentItems ?? [], queryItems ?? [], categoryItems ?? []);
  }, [items, mobile, recentItems]);
  const hasRecent = Boolean(recentItems?.length);

  return (
    <Suggest
      className={cn(styles.suggest, mobile && styles.isMobile)}
      data-testid={testId}
      input={input}
      items={allItems}
      onClose={onClose}
      onSelect={onSelect}
      uid={uid}
    >
      {(activeItem, items) => (
        <>
          {hasRecent && <SearchSuggestRecentHeader onClear={onClear} />}
          {items.map((item, index) => (
            <SearchSuggestItem
              // eslint-disable-next-line react/no-array-index-key
              key={`${item.query}${index}`}
              active={item === activeItem}
              item={item}
              onClick={onSelect}
              onSetActive={onSetActive}
              recent={recentItems?.includes(item)}
            />
          ))}
        </>
      )}
    </Suggest>
  );
}
