import cn from 'classnames';
import {Locator} from 'components/Locator';
import {Icon} from 'components/UIKit/Icon';
import React, {useCallback} from 'react';

import styles from './index.scss';

type RatingProps = {
  onSelect: (value: number) => void;
  value?: number;
};

const STARS = [1, 2, 3, 4, 5];

export function Rating({onSelect, value = 0}: RatingProps): JSX.Element {
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = parseInt(event.target.value, 10);

      onSelect(value);
    },
    [onSelect],
  );

  return (
    <div className={styles.root}>
      {STARS.map((star, index) => (
        <Locator id="ReviewFormRating" rating={star} key={star}>
          <label className={cn(styles.star, Math.floor(value - index) > 0 && styles.starFilled)}>
            <Icon height={24} name="star-filled-24" type="mono" width={24} />
            <input
              checked={value === star}
              className={styles.input}
              name="rating"
              onChange={handleChange}
              type="radio"
              value={star}
            />
          </label>
        </Locator>
      ))}
    </div>
  );
}
