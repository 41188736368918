import {Image} from 'components/Image';
import React, {memo} from 'react';
import {WarrantyTextsItem as WarrantyTextsItemType} from 'types/WarrantyTexts';

import styles from './index.scss';

type Props = {
  item: WarrantyTextsItemType;
};

export const WarrantyTextsItem = memo(function WarrantyTextsItem({
  item,
}: Props): React.ReactElement | null {
  const itemImageUrl = item.icon?.images[0]?.url;

  if (!item.text || !itemImageUrl) {
    return null;
  }

  return (
    <div className={styles.item}>
      <div className={styles.iconWrapper}>
        <Image
          contain
          loadingHidden
          height="100%"
          image={item.icon}
          sizes="25px"
          className={styles.icon}
        />
      </div>
      <span className={styles.text}>{item.text}</span>
    </div>
  );
});
