import {ContentListItem} from 'types/ContentList';
import {ProductLiteRaw} from 'types/ProductLite';
import {isRecord} from 'utils/guards';

import type {ProductFooter} from './Footer';
import type {ProductHeader, ProductHeaderIncomplete} from './Header';

export type ClientProductRaw = {
  header: ProductHeader;
  footer?: ProductFooter;
  items: ContentListItem[];
  nextPageToken?: string;
  lite?: ProductLiteRaw;
};

export type ClientProduct = ClientProductRaw & {
  loadedTimeMs: number;
  incomplete: boolean;
  ssr: boolean;
};

export type ClientProductIncomplete = {
  header: ProductHeaderIncomplete;
  footer?: ProductFooter;
  items: ContentListItem[];
  nextPageToken?: string;
  loadedTimeMs: number;
  incomplete: boolean;
  ssr: boolean;
};

export function isClientProduct(input: unknown): input is ClientProduct | ClientProductIncomplete {
  return (
    isRecord(input) &&
    Boolean(input.header) &&
    typeof (input as ClientProductIncomplete).header.id === 'string'
  );
}

export function isClientProductComplete(input: unknown): input is ClientProduct {
  return isClientProduct(input) && Boolean(!input.incomplete);
}

export function isClientProductIncomplete(input: unknown): input is ClientProductIncomplete {
  return isClientProduct(input) && Boolean(input.incomplete);
}
