import {useCallback, useRef} from 'react';

import {Options, useIntersectionObserverRef} from './useIntersectionObserverRef';

export function useObserveOnce(callback: () => void, options: Options): void {
  const observed = useRef(false);

  const handleObserve = useCallback(
    (entry: IntersectionObserverEntry) => {
      if (observed.current || !entry.isIntersecting) {
        return;
      }

      callback();
      observed.current = true;
    },
    [callback],
  );

  useIntersectionObserverRef(handleObserve, options);
}
