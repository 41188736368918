import {Block, BlockHeader} from 'components/ClientProduct/Block';
import React from 'react';
import type {RowList as RowListType} from 'types/ContentList/ContentListRowList';

import {Row} from '../Row';

export function RowList({rowList: {items, header}}: RowListType): JSX.Element {
  return (
    <Block>
      <BlockHeader>{header?.title}</BlockHeader>

      {items.map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Row content={item} key={index} />
      ))}
    </Block>
  );
}
