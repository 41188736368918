import DotLoader from 'components/DotLoader';
import ErrorMessage from 'components/ErrorMessage';
import Checkbox from 'components/Checkbox';
import {Hoverable} from 'components/Hoverable';
import Trash from 'components/icons/Trash';
import {Locator} from 'components/Locator';
import classnames from 'classnames/bind';
import ErrorShape from 'shapes/Error';
import AnalyticsShape from 'shapes/Analytics';
import {OrderReviewInfoShape} from 'shapes/OrderReviewInfo';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import Button from 'components/Button';
import {FormattedDate} from 'components/i18n/FormattedDate';
import {ErrorMessage as MediaUploaderErrorMessage} from 'components/MediaUploader/ErrorMessage';
import {isMediaVideoBundle} from 'components/MediaUploader/utils/isMediaVideoBundle';
import {FormattedMessage, defineMessages, useIntl} from 'react-intl';
import {useDeviceVars} from 'hooks/useDeviceVars';
import {useAnalytics} from 'hooks/useAnalytics';
import {PreviewEventWrapper} from 'hooks/usePreviewEvent';
import {Photos} from './Photos';
import {Select} from './Select';
import {Rating} from './Rating';
import {Textarea} from './Textarea';
import styles from './index.scss';
import {getSavingVideoBundleByVideoBundle} from '../utils/getSavingVideoBundleByVideoBundle';
import {LookingNowCounter} from './LookingNowCounter';
import {Rating as NewRating} from './NewRating';
import {Select as NewSelect} from './NewSelect';
import {Field} from './Field';

const cn = classnames.bind(styles);

const messages = defineMessages({
  textareaDefaultPlaceholder: {
    defaultMessage: 'Leave a review about your order',
    description: 'Placeholder in the review text field',
  },
});

const checkboxName = 'anonymous-review-checkbox';

class ReviewFormBase extends Component {
  static propTypes = {
    analytics: AnalyticsShape.isRequired,
    intl: PropTypes.object.isRequired,
    error: ErrorShape,
    removing: PropTypes.bool,
    loading: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    onRemove: PropTypes.func,
    onSubmit: PropTypes.func.isRequired,
    orderCreatedTimeMs: PropTypes.number,
    orderReview: OrderReviewInfoShape.isRequired,
    orderId: PropTypes.string.isRequired,
    parcelId: PropTypes.string.isRequired,
    productId: PropTypes.string.isRequired,
    redesign: PropTypes.shape({
      enabled: PropTypes.bool,
      showMandatoryQuestionAsterisk: PropTypes.bool,
      showFakeLookingNowCounter: PropTypes.bool,
    }),
  };

  static defaultProps = {
    error: null,
    loading: false,
    onRemove: null,
    orderCreatedTimeMs: null,
    removing: false,
    redesign: undefined,
  };

  constructor(props) {
    super(props);

    const isAnonymousChecked = this.getInitialAnonymity();

    this.state = {
      ...this.getReviewState(props),
      starRatingRequired: false,
      pristine: true,
      isAnonymousChecked,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.orderReview !== prevProps.orderReview) {
      this.setState(this.getReviewState(this.props));
    }
    if (this.props.error !== prevProps.error) {
      this.setState({
        error: this.props.error ? (
          <ErrorMessage error={this.props.error} internal noRequestId />
        ) : null,
      });
    }
  }

  getInitialAnonymity() {
    const {orderReview} = this.props;

    if (orderReview.review) {
      return orderReview.review.isAnonymous;
    }

    return orderReview && orderReview.anonymousByDefault;
  }

  getReasonByStarRating(starRating, orderReview) {
    if (!starRating) {
      return null;
    }

    const reasons = (orderReview && orderReview.reasons) || [];
    return reasons.find(
      ({minRating, maxRating}) => starRating >= minRating && starRating <= maxRating,
    );
  }

  getReviewState(props) {
    const {orderReview} = props;
    const review = orderReview?.review ?? {};
    const {sizeFit} = orderReview;
    const {starRating, deliveryDurationId, reasonAnswerIds, text, media, sizeFitAnswerId} = review;
    const preparedMedia = media?.map(({type, payload}) => ({[type]: payload})) ?? [];
    const sizeFitAnswer = sizeFit?.answers.find((answer) => answer.id === sizeFitAnswerId);

    return {
      starRating: starRating || null,
      deliveryDurationId: deliveryDurationId ? [deliveryDurationId] : [],
      reasonAnswerIds: reasonAnswerIds || [],
      sizeFitAnswerIds: [sizeFitAnswer?.id],
      hintInputMessage: sizeFitAnswer?.hintInputMessage,
      text: text || '',
      media: preparedMedia,
      reason: this.getReasonByStarRating(starRating, props.orderReview),
    };
  }

  handleRatingChange = (starRating) => {
    this.setState({
      reason: this.getReasonByStarRating(starRating, this.props.orderReview),
      starRating,
    });
  };

  handleReasonSelect = (value) => {
    this.setState({reasonAnswerIds: value});
  };

  handleDeliveryDurationsSelect = (value) => {
    this.setState({deliveryDurationId: value});
  };

  handleSizeFitSelect = (value) => {
    const {analytics, orderReview, orderId, parcelId, productId} = this.props;
    const {sizeFit} = orderReview;
    const sizeFitAnswer = sizeFit?.answers.find((answer) => value.includes(answer.id));

    this.setState({
      sizeFitAnswerIds: [sizeFitAnswer?.id],
      hintInputMessage: sizeFitAnswer?.hintInputMessage,
    });
    analytics.sendEvent({
      type: 'productFeedbackSizeClick',
      payload: {
        orderId,
        parcelId,
        productId,
        answer: sizeFitAnswer?.id,
      },
    });
  };

  handleReviewChange = (value) => {
    this.setState({text: value});
  };

  handleMediaChange = (media) => {
    this.setState({
      media,
      mediaError: undefined,
    });
  };

  handleMediaError = (error) => {
    this.setState({
      mediaError: <MediaUploaderErrorMessage error={error} />,
    });
  };

  handleSubmit = (evt) => {
    evt.preventDefault();

    if (this.props.loading || this.props.removing) {
      return;
    }

    if (this.state.pristine || this.state.error) {
      this.setState({
        error: null,
        pristine: false,
      });
    }

    if (this.validate()) {
      const {
        starRating,
        reason,
        reasonAnswerIds,
        text,
        media,
        deliveryDurationId,
        isAnonymousChecked,
        sizeFitAnswerIds,
      } = this.state;
      const result = {starRating};

      if (!starRating) {
        this.props.onSubmit(null);
        return;
      }

      if (deliveryDurationId.length) {
        [result.deliveryDurationId] = deliveryDurationId;
      }

      if (sizeFitAnswerIds.length) {
        [result.sizeFitAnswerId] = sizeFitAnswerIds;
      }

      if (text) {
        result.text = text;
      }

      if (reason && reasonAnswerIds.length > 0) {
        result.reasonAnswerIds = reasonAnswerIds;
      }

      if (media && media.length > 0) {
        result.media = media.map((item) => {
          if (isMediaVideoBundle(item)) {
            return {
              video: getSavingVideoBundleByVideoBundle(item.video),
            };
          }

          return item;
        });
      }

      if (isAnonymousChecked) {
        result.isAnonymous = true;
      }

      this.props.onSubmit(result);
    }
  };

  handleCheckAnonymous = () => {
    this.setState(({isAnonymousChecked: prev}) => ({
      isAnonymousChecked: !prev,
    }));
  };

  validate() {
    const {starRating, reasonAnswerIds, text, deliveryDurationId, media, mediaError} = this.state;

    if (mediaError) {
      this.setState({error: mediaError});
      return false;
    }

    if (!starRating) {
      const starRatingRequired =
        media.length > 0 || !!text || !!deliveryDurationId.length > 0 || reasonAnswerIds.length > 0;

      if (this.state.starRatingRequired !== starRatingRequired) {
        this.setState({starRatingRequired});
      }

      if (starRatingRequired) {
        return false;
      }
    }

    this.setState({error: null});
    return true;
  }

  renderError() {
    const {error, pristine, starRating, starRatingRequired} = this.state;
    if (error) {
      return (
        <Locator id="reviewForm-error">
          <p className={styles.error}>{error}</p>
        </Locator>
      );
    }

    if (!pristine && starRatingRequired && !starRating) {
      return (
        <Locator id="reviewForm-error">
          <p className={styles.error}>
            <FormattedMessage
              defaultMessage="Please rate the quality of your order."
              description="Review form rating required"
            />
          </p>
        </Locator>
      );
    }

    return null;
  }

  renderOrderCreatedTime() {
    const {orderCreatedTimeMs} = this.props;

    if (!orderCreatedTimeMs) {
      return null;
    }

    return (
      <FormattedMessage
        defaultMessage="Order date: {date}"
        description="Review form parcel date"
        values={{
          date: (
            <FormattedDate value={orderCreatedTimeMs} day="numeric" month="long" year="numeric" />
          ),
        }}
      />
    );
  }

  renderRemoveButton() {
    const {onRemove, removing} = this.props;

    if (!onRemove) {
      return null;
    }

    if (removing) {
      return (
        <span className={styles.removing}>
          <span className={styles.removingLoader}>
            <DotLoader style="link" />
          </span>
        </span>
      );
    }

    return (
      <Locator id="reviewForm-removeButton">
        <span className={styles.removing}>
          <Hoverable onClick={onRemove} className={styles.trash}>
            <Trash hoverable />
          </Hoverable>
        </span>
      </Locator>
    );
  }

  renderHeader() {
    const {orderReview, redesign} = this.props;
    const {pristine, starRatingRequired, starRating} = this.state;
    const hasReview = !!orderReview && !!orderReview.review;

    const RatingComponent = redesign?.enabled ? NewRating : Rating;

    return (
      <div className={styles.parcelReviewHeader}>
        <Field
          hasError={!starRating && !pristine && starRatingRequired}
          label={
            <>
              <FormattedMessage
                defaultMessage={"How would you rate the product's quality?"}
                description="Label of the rating field in the review form"
              />
              {redesign?.showMandatoryQuestionAsterisk ? <>&nbsp;*</> : null}
            </>
          }
        >
          <RatingComponent onSelect={this.handleRatingChange} value={starRating} />
        </Field>
        {hasReview ? this.renderRemoveButton() : null}
      </div>
    );
  }

  render() {
    const {orderId, orderReview, onCancel, parcelId, productId, loading, redesign, intl} =
      this.props;
    const {deliveryDurations, maxPhotosCount, canBeAnonymous, sizeFit} = orderReview;
    const {
      deliveryDurationId,
      reasonAnswerIds,
      reason,
      text,
      media,
      isAnonymousChecked,
      sizeFitAnswerIds,
      hintInputMessage,
    } = this.state;

    const SelectComponent = redesign?.enabled ? NewSelect : Select;

    /** @type{import('types/AnalyticsEvent').AnalyticsEvent} */
    const sizeFitFieldPreviewEvent = {
      type: 'productFeedbackSizePreview',
      payload: {
        orderId,
        parcelId,
        productId,
      },
    };

    return (
      <Locator id="reviewForm">
        <div className={styles.wrapper}>
          <form className={cn('form', {loading})} onSubmit={this.handleSubmit}>
            <div className={styles.reviewFormItem}>{this.renderHeader()}</div>
            {reason ? (
              <Locator id="reviewForm-whatsWrong">
                <div className={styles.reviewFormItem}>
                  <Field label={reason.title}>
                    <SelectComponent
                      name="reason"
                      type="checkbox"
                      question={reason}
                      onSelect={this.handleReasonSelect}
                      value={reasonAnswerIds}
                    />
                  </Field>
                </div>
              </Locator>
            ) : null}
            <Locator id="reviewForm-delivery">
              <div className={styles.reviewFormItem}>
                <Field notice={this.renderOrderCreatedTime()} label={deliveryDurations.title}>
                  <SelectComponent
                    name="deliveryDurations"
                    question={deliveryDurations}
                    onSelect={this.handleDeliveryDurationsSelect}
                    value={deliveryDurationId}
                  />
                </Field>
              </div>
            </Locator>
            {sizeFit ? (
              <Locator id="reviewForm-sizeFit">
                <div className={styles.reviewFormItem}>
                  <PreviewEventWrapper previewEvent={sizeFitFieldPreviewEvent}>
                    <Field
                      hint={
                        redesign?.showFakeLookingNowCounter ? (
                          <LookingNowCounter counterKey={productId} />
                        ) : null
                      }
                      label={sizeFit.title}
                    >
                      <SelectComponent
                        name="sizeFit"
                        question={sizeFit}
                        onSelect={this.handleSizeFitSelect}
                        value={sizeFitAnswerIds}
                      />
                    </Field>
                  </PreviewEventWrapper>
                </div>
              </Locator>
            ) : null}
            <div className={cn('reviewFormItem', 'noDelimeter')}>
              <Field
                label={
                  <FormattedMessage
                    defaultMessage="Your review"
                    description="Label of the review textarea in the review form"
                  />
                }
              >
                <Textarea
                  name="review"
                  onChange={this.handleReviewChange}
                  placeholder={
                    hintInputMessage ?? intl.formatMessage(messages.textareaDefaultPlaceholder)
                  }
                  value={text}
                />
              </Field>
            </div>
            <Locator id="reviewForm-photosAttach">
              <div className={cn('reviewFormItem', {noDelimeter: !canBeAnonymous})}>
                <Field
                  label={
                    <FormattedMessage
                      defaultMessage="Add photos and videos"
                      description="Label of the media input in the review form"
                    />
                  }
                >
                  <Photos
                    maxPhotosCount={maxPhotosCount}
                    media={media}
                    onChange={this.handleMediaChange}
                    onError={this.handleMediaError}
                  />
                </Field>
              </div>
            </Locator>
            {canBeAnonymous && (
              <div className={cn('reviewFormItem', 'noDelimeter')}>
                <div className={styles.anonymousForm}>
                  <div className={styles.anonymousLabel}>
                    <label htmlFor={checkboxName}>
                      <FormattedMessage
                        defaultMessage="Anonymous review"
                        description="Review Form anonymous checkbox label"
                      />
                    </label>
                  </div>
                  <Locator id="reviewForm-anonymousCheckbox" checked={isAnonymousChecked}>
                    <div className={styles.anonymousCheckbox}>
                      <Checkbox
                        id={checkboxName}
                        name={checkboxName}
                        size="m"
                        checked={isAnonymousChecked}
                        onCheck={this.handleCheckAnonymous}
                      />
                    </div>
                  </Locator>
                </div>
              </div>
            )}
            {this.renderError()}
            <div className={styles.buttons}>
              <div className={styles.button}>
                <Locator id="reviewForm-cancelButton">
                  <Button
                    color="darkblue"
                    block
                    padding="wide"
                    variant="outline"
                    onClick={onCancel}
                  >
                    <FormattedMessage
                      defaultMessage="Cancel"
                      description="Review Form cancel button"
                    />
                  </Button>
                </Locator>
              </div>
              <div className={styles.button}>
                <Locator id="reviewForm-submitButton">
                  <Button color="darkblue" block loading={loading} padding="wide" type="submit">
                    <FormattedMessage
                      defaultMessage="Send"
                      description="Review Form submit button"
                    />
                  </Button>
                </Locator>
              </div>
            </div>
          </form>
        </div>
      </Locator>
    );
  }
}

export function ReviewForm(props) {
  const analytics = useAnalytics();
  const intl = useIntl();
  const {webReviewFormRedesign8224} = useDeviceVars();

  return (
    <ReviewFormBase
      {...props}
      analytics={analytics}
      intl={intl}
      redesign={webReviewFormRedesign8224}
    />
  );
}
