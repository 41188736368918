import {
  getIsFullPaymentWithPoints,
  getPaymentMethod,
} from 'components/GroupedCart/CheckoutGroup/utils';
import {discardableByAny} from 'helpers/discardable';
import {ecsError} from 'helpers/log/ECS/ecsError';
import produce from 'immer';
import {AnyAction} from 'redux';
import {getUrl} from 'routes';
import {enhanceCheckoutSession} from 'store/enhancer/checkoutSession';
import {enhanceContentListItems} from 'store/enhancer/contentList';
import {isUnauthorized} from 'store/modules/auth';
import {discardPaymentRelatedData} from 'store/modules/discard';
import {
  makeApplePayPayment,
  PaymentRequestData as ApplePaymentRequestData,
} from 'store/modules/groupedCart/applePay';
import {getCart, getCartSelectedItems} from 'store/modules/groupedCart/cart';
import {getCurrency, getLanguage} from 'store/modules/preferences';
import {RootState} from 'store/rootReducer';
import {extractResponsePayload, extractResponsePayloadEnhanced} from 'store/utils';
import {requestActionCreator} from 'store/utils/requestActions';
import {AuthChallengeRequestData, CardPaymentResult} from 'types/CardPaymentResult';
import {CartItem} from 'types/CartGroup/CartItem';
import {
  CheckoutSession,
  CheckoutSessionGroup,
  CheckoutSessionOrderGroup,
  OrderGroupPaymentStatus,
} from 'types/CheckoutSession';
import {CheckoutSessionParameterValue} from 'types/CheckoutSessionParameterValue';
import {ClientBackendResponse} from 'types/ClientBackendResponse';
import {ContentListItem} from 'types/ContentList';
import {type Error} from 'types/Error';
import {PaymentMethodType} from 'types/PaymentMethod';
import {PaymentProfiling} from 'types/PaymentProfiling';
import {PopupResponse} from 'types/Popup';
import {createReducer, FuncAction} from 'typesafe-actions';
import {arrayToObject} from 'utils/array';
import {createNetworkError, unwrapErrorObject} from 'utils/error';
import {identity, isDefAndNotNull} from 'utils/function';
import {assignSet, assignSetByPath, getValueByPath, objectFilter} from 'utils/object';
import {promiseRetry, promiseTimeout} from 'utils/promise';
import {createUrl} from 'utils/url';
import uuid from 'uuid';

import {
  createSession as createSessionAction,
  loadSession as loadSessionAction,
  loadSessionCompletionItems as loadSessionCompletionItemsAction,
  loadSessionOrderGroup as loadSessionOrderGroupAction,
  loadSessionStep as loadSessionStepAction,
  markDisclaimerAsSeen as markDisclaimerAsSeenAction,
  resetSessionPricing as resetSessionPricingAction,
  sessionPay as sessionPayAction,
  setSession as setSessionAction,
  setSessionCompletionItems as setSessionCompletionItemsAction,
  setSessionError as setSessionErrorAction,
  setSessionOrderGroup as setSessionOrderGroupAction,
  setSessionPricing as setSessionPricingAction,
  updateSession as updateSessionAction,
  updateSessionPricing as updateSessionPricingAction,
} from './actions';
import {createSessionNotLoadedError, isDBNotFoundError} from './utils';

const DB_NOT_FOUND_ERROR_RETRIES_LIMIT = 3;
// 2 seconds
const DB_NOT_FOUND_ERROR_RETRIES_TIMEOUT = 2_000;

type SessionStartOverlay = {
  popup: PopupResponse;
};

type CheckoutSessionWithOverlay = CheckoutSession & {
  // from `/checkout/session/create`
  sessionStartOverlay?: SessionStartOverlay;
};

type StateData = CheckoutSessionWithOverlay & {
  completionItems?: ContentListItem[];
  language?: string;
  currency?: string;
};

export type SessionState = {
  data: StateData | null;
  loaded: boolean;
  error: Error | null;
};

const initialState: SessionState = {
  data: null,
  loaded: false,
  error: null,
};

type CheckoutItem = {
  productId: string;
  productVariantId: string;
  quantity?: number;
};

function convertCartItemToCheckoutItem({
  productId,
  productVariantId,
  quantity,
}: CartItem): CheckoutItem {
  return {productId, productVariantId, quantity};
}

const innerReducer = createReducer(initialState)
  .handleAction(setSessionAction, (state, {payload}) => ({
    ...state,
    error: null,
    data: payload,
  }))
  .handleAction(setSessionPricingAction, (state, {payload}) =>
    produce(state, (draft) => {
      const prevGroups = draft.data?.groups;
      const nextGroups = payload.groups;

      if (!prevGroups) {
        return;
      }

      const prevGroupsMap = arrayToObject(prevGroups, (group) => group.id, identity);

      nextGroups.forEach((nextGroup) => {
        const prevGroup = prevGroupsMap[nextGroup.id];

        if (prevGroup) {
          prevGroup.pricing = nextGroup.pricing;
          prevGroup.totalPrice = nextGroup.totalPrice;
        }
      });
    }),
  )
  .handleAction(setSessionOrderGroupAction, (state, {payload}) => {
    const path = ['data', 'groups'] as const;
    const groups = getValueByPath(state, ...path);
    if (groups) {
      return assignSetByPath(
        state,
        path,
        groups.map((group) =>
          group === payload.group ? assignSet(group, 'orderGroup', payload.orderGroup) : group,
        ),
      );
    }
    return state;
  })
  .handleAction(setSessionErrorAction, (state, {payload}) => ({
    ...state,
    error: payload,
  }))
  .handleAction(setSessionCompletionItemsAction, (state, {payload}) =>
    assignSetByPath(state, ['data', 'completionItems'] as const, payload),
  );

export const reducer = discardableByAny(innerReducer);

function getState(globalState: RootState): SessionState {
  return globalState.groupedCart.session;
}

export function getCartSession(globalState: RootState): StateData | null {
  const {data} = getState(globalState);
  const currency = getCurrency(globalState);
  const language = getLanguage(globalState);

  if (data && language === data.language && currency === data.currency) {
    return data;
  }

  return null;
}

export function getCartSessionGroupById(
  globalState: RootState,
  groupId: string,
): CheckoutSessionGroup | null {
  const stateSession = getCartSession(globalState);
  return (stateSession && stateSession.groups.find(({id}) => id === groupId)) || null;
}

export function getCartSessionCompletionItems(globalState: RootState): ContentListItem[] | null {
  const session = getCartSession(globalState);

  return session?.completionItems || null;
}

export function getCartSessionById(
  globalState: RootState,
  sessionId: string,
): CheckoutSession | null {
  const session = getCartSession(globalState);
  if (session && session.id === sessionId) {
    return session;
  }
  return null;
}

export function getCartSessionError(globalState: RootState): Error | null {
  return getState(globalState).error || null;
}

export function getCartSessionOverlay(globalState: RootState): SessionStartOverlay | null {
  return getCartSession(globalState)?.sessionStartOverlay || null;
}

export function isCartSessionLoaded(globalState: RootState): boolean {
  const cart = getCartSession(globalState);
  return !!cart;
}

export function setCartSession(session: CheckoutSession): AnyAction {
  return setSessionAction(enhanceCheckoutSession(session));
}

export function setCartSessionPricing(session: CheckoutSession): AnyAction {
  return setSessionPricingAction(enhanceCheckoutSession(session));
}

export function setCartSessionOrderGroup(
  group: CheckoutSessionGroup,
  orderGroup: CheckoutSessionOrderGroup,
): AnyAction {
  return setSessionOrderGroupAction({group, orderGroup});
}

export const setCartSessionError = setSessionErrorAction;

export const setCartSessionCompletionItems = setSessionCompletionItemsAction;

export function loadCartSession(
  sessionId: string,
  {force = false} = {},
): FuncAction<Promise<unknown>> {
  return requestActionCreator(loadSessionAction, {sessionId}, ({getState, dispatch}, client) => {
    const stateSession = getCartSession(getState());
    if (!force && stateSession && stateSession.id === sessionId) {
      // do not load session if it already exists in state
      return Promise.resolve(null);
    }

    // Backend can respond without waiting for migration. Try to load the session several times with a timeout.
    return promiseRetry(
      () =>
        client.api
          .get<ClientBackendResponse<CheckoutSession>>(`/checkout/session/${sessionId}`)
          .then(extractResponsePayloadEnhanced)
          .then((session) => dispatch(setCartSession(session))),
      {
        checkError: (error) => isDBNotFoundError(unwrapErrorObject(error as Error)),
        retriesLimit: DB_NOT_FOUND_ERROR_RETRIES_LIMIT,
        retryTimeout: DB_NOT_FOUND_ERROR_RETRIES_TIMEOUT,
      },
    ).catch((error) => {
      if (isDBNotFoundError(unwrapErrorObject(error))) {
        client.device.log.getLogger('store/modules/groupedCart').error({error: ecsError(error)});
      }

      dispatch(setCartSessionError(error));
    });
  });
}

export function createCartSession(): FuncAction<Promise<CheckoutSession>> {
  return requestActionCreator(
    createSessionAction,
    undefined,
    async ({getState, dispatch}, client) => {
      const cart = getCart(getState());
      if (!cart) {
        return Promise.reject(createNetworkError('cart.not_loaded'));
      }

      if (!client.device.getDeviceVar('webAllowAnonymousCheckout') && isUnauthorized(getState())) {
        return Promise.reject(createNetworkError('api.unauthorized'));
      }

      const {selectedCouponId, verificationData, extraData} = cart;
      const checkoutItems = getCartSelectedItems(getState())?.map(convertCartItemToCheckoutItem);

      if (!checkoutItems?.length) {
        return Promise.reject(createNetworkError('cart.no_items_to_buy'));
      }

      const body = objectFilter(
        {
          selectedCouponId,
          verificationData,
          checkoutItems,
          extraData,
        },
        isDefAndNotNull,
      );

      return client.api
        .post<ClientBackendResponse<CheckoutSessionWithOverlay>>('/checkout/session/create', {body})
        .then(extractResponsePayloadEnhanced)
        .then((session) => {
          return dispatch(setCartSession(session));
        })
        .then(() => getCartSession(getState()) as CheckoutSession)
        .catch((error) => {
          dispatch(setCartSessionError(error));
          throw error;
        });
    },
  );
}

export function loadCartSessionStep(
  groupId: string,
  values: CheckoutSessionParameterValue[] | null = null,
): FuncAction<Promise<unknown>> {
  return requestActionCreator(loadSessionStepAction, {groupId}, ({getState, dispatch}, client) => {
    const stateItemGroup = getCartSessionGroupById(getState(), groupId);
    if (!stateItemGroup) {
      return Promise.reject(createSessionNotLoadedError());
    }

    const body = objectFilter(
      {
        values,
        verificationData: stateItemGroup.verificationData,
      },
      isDefAndNotNull,
    );

    return client.api
      .post<ClientBackendResponse<CheckoutSession>>(`/checkout/group/${stateItemGroup.id}/update`, {
        body,
      })
      .then(extractResponsePayloadEnhanced)
      .then((session) => dispatch(setCartSession(session)))
      .then(() => getCartSession(getState()))
      .catch((error) => {
        dispatch(setCartSessionError(error));
      });
  });
}

export function updateCartSession(): FuncAction<Promise<unknown>> {
  return requestActionCreator(updateSessionAction, undefined, ({getState, dispatch}, client) => {
    const stateSession = getCartSession(getState());
    if (!stateSession) {
      return Promise.reject(createSessionNotLoadedError());
    }

    return client.api
      .get<ClientBackendResponse<CheckoutSession>>(`/checkout/session/${stateSession.id}`)
      .then(extractResponsePayloadEnhanced)
      .then((session) => dispatch(setCartSession(session)))
      .catch((error) => {
        dispatch(setCartSessionError(error));
        throw error;
      });
  });
}

export function updateCartSessionPricing(): FuncAction<Promise<unknown>> {
  return requestActionCreator(
    updateSessionPricingAction,
    undefined,
    ({getState, dispatch}, client) => {
      const stateSession = getCartSession(getState());
      if (!stateSession) {
        return Promise.reject(createSessionNotLoadedError());
      }

      return client.api
        .get<ClientBackendResponse<CheckoutSession>>(`/checkout/session/${stateSession.id}`)
        .then(extractResponsePayloadEnhanced)
        .then((session) => dispatch(setCartSessionPricing(session)))
        .catch((error) => {
          dispatch(setCartSessionError(error));
          throw error;
        });
    },
  );
}

export function resetCartSessionPricing(): FuncAction<Promise<unknown>> {
  return requestActionCreator(
    resetSessionPricingAction,
    undefined,
    ({getState, dispatch}, client) => {
      const stateSession = getCartSession(getState());
      if (!stateSession) {
        return Promise.reject(createSessionNotLoadedError());
      }

      return client.api
        .post<ClientBackendResponse<CheckoutSession>>(`/checkout/session/${stateSession.id}/reset`)
        .then(extractResponsePayloadEnhanced)
        .then((session) => dispatch(setCartSessionPricing(session)))
        .catch((error) => {
          dispatch(setCartSessionError(error));
          throw error;
        });
    },
  );
}

export function loadCartSessionOrderGroup(
  groupId: string,
): FuncAction<Promise<CheckoutSessionOrderGroup>> {
  return requestActionCreator(
    loadSessionOrderGroupAction,
    {groupId},
    ({getState, dispatch}, client) => {
      const stateItemGroup = getCartSessionGroupById(getState(), groupId);
      if (!stateItemGroup) {
        throw createSessionNotLoadedError();
      }

      const {verificationData} = stateItemGroup;

      const body = {verificationData};

      return client.api
        .post<ClientBackendResponse<CheckoutSessionOrderGroup>>(
          `/checkout/group/${stateItemGroup.id}/getOrderGroup`,
          {body},
        )
        .then(extractResponsePayload)
        .then((orderGroup) => {
          dispatch(setCartSessionOrderGroup(stateItemGroup, orderGroup));
          return orderGroup;
        })
        .catch((error) => {
          dispatch(setCartSessionError(error));
          throw error;
        });
    },
  );
}

const SYNC_STATUS_TIMEOUT = 1_000;
const SYNC_STATUS_MAX_RETRIES = 5;

export function syncOrderGroupPaymentStatus(
  orderGroupId: string,
): FuncAction<Promise<OrderGroupPaymentStatus['status']>> {
  return (store, client) => {
    let retryCount = 0;

    // wait until order group status changes from 'inProgress',
    // after that order status from usual api will be synchronized
    const doRequest = async (): Promise<OrderGroupPaymentStatus['status']> => {
      retryCount += 1;

      const {
        body: {
          payload: {status},
        },
      } = await client.api.get<ClientBackendResponse<OrderGroupPaymentStatus>>(
        `/orderGroups/${orderGroupId}/paymentStatus`,
      );

      if (status === 'inProgress' && retryCount < SYNC_STATUS_MAX_RETRIES) {
        await promiseTimeout(SYNC_STATUS_TIMEOUT);
        return doRequest();
      }

      return status;
    };

    return doRequest();
  };
}

export function cartSessionPay(
  groupId: string,
  profiling: PaymentProfiling,
  payhubProfiling: PaymentProfiling,
  payload: unknown,
): FuncAction<Promise<CardPaymentResult>> {
  return requestActionCreator(
    sessionPayAction,
    {groupId},
    ({getState, dispatch}, client): Promise<CardPaymentResult> =>
      Promise.resolve().then(async (): Promise<CardPaymentResult> => {
        const stateSession = getCartSession(getState());
        const stateItemGroup = getCartSessionGroupById(getState(), groupId);
        if (!stateSession || !stateItemGroup) {
          throw createSessionNotLoadedError();
        }

        const isFullPaymentWithPoints = getIsFullPaymentWithPoints(stateItemGroup);
        if (isFullPaymentWithPoints) {
          return dispatch(loadCartSessionOrderGroup(stateItemGroup.id))
            .then(() => dispatch(loadCartSessionStep(stateItemGroup.id)))
            .then(() => ({result: 'completed'}));
        }

        const paymentMethod = getPaymentMethod(stateItemGroup?.stepBundle);
        if (!paymentMethod) {
          throw createNetworkError('cart.can_not_pay');
        }

        const lang = client.device.getLanguage();
        const {scope} = client;

        // WEB-5884: turns out /update should be called before actual transaction to check if price is changed
        // in which case it will throw but update the price out of the promise chain
        await dispatch(loadCartSessionStep(groupId));

        const successUrl = `${client.getOrigin()}${getUrl('checkoutSession', {
          lang,
          scope,
          checkoutSessionId: stateSession.id,
        })}`;

        let paymentProcessor: (options: {
          orderGroup: CheckoutSessionOrderGroup;
          profilingId: string;
        }) => Promise<CardPaymentResult>;

        switch (paymentMethod.type) {
          case PaymentMethodType.WEB:
            paymentProcessor = async ({orderGroup, profilingId}) => {
              const failureUrl = createUrl(successUrl, {failure: stateItemGroup.id});

              const commonBody = {
                successUrl,
                failureUrl,
                profilingId,
              };

              const {
                body: {payload: authChallengeRequestData},
              } = orderGroup.payhubParams
                ? await client.payhubApi.post<ClientBackendResponse<AuthChallengeRequestData>>(
                    `/paymentMethods/web`,
                    {
                      body: {
                        ...commonBody,
                        ...orderGroup.payhubParams,
                        methodId: paymentMethod.payload.id,
                      },
                    },
                  )
                : await client.api.post<ClientBackendResponse<AuthChallengeRequestData>>(
                    `/orderGroups/${orderGroup.id}/payWeb`,
                    {
                      body: {...commonBody, id: paymentMethod.payload.id},
                    },
                  );

              return {
                result: 'authChallenge',
                authChallengeRequestData,
              };
            };
            break;

          case PaymentMethodType.CARD:
            paymentProcessor = async ({orderGroup, profilingId}) => {
              const failureUrl = createUrl(successUrl, {failure: null});
              const methodChallengeId = uuid();
              const methodChallengeCloseUrl = `${client.getOrigin()}/iframe-close/${methodChallengeId}`;

              const body = objectFilter(
                {
                  authChallengeFailureUrl: failureUrl,
                  authChallengeSuccessUrl: successUrl,
                  methodChallengeCloseUrl,
                  cardId: paymentMethod.payload.id,
                  profilingId,
                  ...orderGroup.payhubParams,
                },
                Boolean,
              );

              const paymentId = (payload as {paymentId?: string} | undefined)?.paymentId;
              const requestPath = paymentId
                ? `/orderGroups/${orderGroup.id}/token/payments/${paymentId}/methodChallenge/payCard`
                : `/orderGroups/${orderGroup.id}/payCard`;

              const {
                body: {payload: payPayload},
              } = orderGroup.payhubParams
                ? await client.payhubApi.post<ClientBackendResponse<CardPaymentResult>>(
                    '/paymentMethods/card',
                    {body},
                  )
                : await client.paymentApi.post<ClientBackendResponse<CardPaymentResult>>(
                    requestPath,
                    {body},
                  );

              return {...payPayload, methodChallengeId};
            };
            break;

          case PaymentMethodType.GOOGLE_PAY:
            paymentProcessor = async ({orderGroup, profilingId}) => {
              const failureUrl = createUrl(successUrl, {failure: null});

              const body = objectFilter(
                {
                  authChallengeFailureUrl: failureUrl,
                  authChallengeSuccessUrl: successUrl,
                  profilingId,
                  payload,
                  ...orderGroup.payhubParams,
                },
                Boolean,
              );

              const {
                body: {payload: payPayload},
              } = orderGroup.payhubParams
                ? await client.payhubApi.post<ClientBackendResponse<CardPaymentResult>>(
                    `/paymentMethods/googlePay`,
                    {body},
                  )
                : await client.paymentApi.post<ClientBackendResponse<CardPaymentResult>>(
                    `/orderGroups/${orderGroup.id}/payGooglePay`,
                    {body},
                  );

              return payPayload;
            };
            break;

          case PaymentMethodType.APPLE_PAY:
            /* global ApplePaySession:readonly */
            paymentProcessor = async ({orderGroup, profilingId}) => {
              const {session: paySession, merchantId, price} = payload as ApplePaymentRequestData;

              const applePayPayment = await makeApplePayPayment({
                client,
                session: paySession,
                merchantId,
                orderGroup,
                ...orderGroup.payhubParams,
              });

              const commonBody = {
                profilingId,
                merchantId,
                paymentToken: {
                  paymentData: JSON.stringify(applePayPayment.token.paymentData),
                  transactionId: applePayPayment.token.transactionIdentifier,
                  paymentInfo: applePayPayment.token.paymentMethod,
                },
              };

              try {
                if (orderGroup.payhubParams) {
                  await client.payhubApi.post(`/paymentMethods/applePay`, {
                    body: {...commonBody, ...orderGroup.payhubParams},
                  });
                } else {
                  await client.paymentApi.post(`/orderGroups/${orderGroup.id}/payApplePay`, {
                    body: {
                      ...commonBody,
                      price: {
                        amount: price.amount,
                        ccy: price.currency,
                      },
                    },
                  });
                }

                paySession.completePayment({status: ApplePaySession.STATUS_SUCCESS});

                return {result: 'completed'};
              } catch (error) {
                paySession.completePayment({status: ApplePaySession.STATUS_FAILURE});
                throw error;
              }
            };
            break;

          case PaymentMethodType.POINTS:
            paymentProcessor = async () => {
              await dispatch(loadCartSessionStep(stateItemGroup.id));

              return {
                result: 'completed',
              };
            };
            break;

          case PaymentMethodType.MULTIBANCO:
            paymentProcessor = async ({orderGroup, profilingId}) => {
              const body = objectFilter(
                {
                  profilingId,
                  ...orderGroup.payhubParams,
                },
                Boolean,
              );

              if (orderGroup.payhubParams) {
                await client.payhubApi.post(`/paymentMethods/multibanco`, {body});
                return {result: 'completed'};
              }

              const error = new Error('Multibanco payment method not supported');

              client.device.log
                .getLogger('store/modules/groupedCart')
                .error({error: ecsError(error)});

              throw error;
            };
            break;

          case PaymentMethodType.MBWAY:
            paymentProcessor = async ({orderGroup, profilingId}) => {
              const body = objectFilter(
                {
                  profilingId,
                  ...(payload as Record<string, unknown>),
                  ...orderGroup.payhubParams,
                },
                Boolean,
              );

              if (orderGroup.payhubParams) {
                await client.payhubApi.post(`/paymentMethods/mbway`, {body});
                return {result: 'completed'};
              }

              const error = new Error('MBWay payment method not supported');

              client.device.log
                .getLogger('store/modules/groupedCart')
                .error({error: ecsError(error)});

              throw error;
            };
            break;

          case PaymentMethodType.NEW_PAYPAL:
            paymentProcessor = async ({orderGroup, profilingId}) => {
              const failureUrl = createUrl(successUrl, {failure: null});
              const body = objectFilter(
                {
                  profilingId,
                  successUrl,
                  failureUrl,
                  ...(payload as Record<string, unknown>),
                  ...orderGroup.payhubParams,
                },
                Boolean,
              );

              if (orderGroup.payhubParams) {
                const {
                  body: {payload: authChallengeRequestData},
                } = await client.payhubApi.post<ClientBackendResponse<AuthChallengeRequestData>>(
                  `/paymentMethods/newPayPal`,
                  {body},
                );

                return {
                  result: 'authChallenge',
                  authChallengeRequestData,
                };
              }

              const error = new Error('PayPal payment method not supported');

              client.device.log
                .getLogger('store/modules/groupedCart')
                .error({error: ecsError(error)});

              throw error;
            };
            break;

          case PaymentMethodType.SAVED_PAYPAL:
            paymentProcessor = async ({orderGroup, profilingId}) => {
              const body = objectFilter(
                {
                  profilingId,
                  ...(payload as Record<string, unknown>),
                  ...orderGroup.payhubParams,
                },
                Boolean,
              );

              if (orderGroup.payhubParams) {
                await client.payhubApi.post(`/paymentMethods/savedPayPal`, {body});
                return {result: 'completed'};
              }

              const error = new Error('PayPal payment method not supported');

              client.device.log
                .getLogger('store/modules/groupedCart')
                .error({error: ecsError(error)});

              throw error;
            };
            break;

          default:
            throw new Error(`Unknown payment type: ${paymentMethod.type}`);
        }

        const orderGroup = await dispatch(loadCartSessionOrderGroup(stateItemGroup.id));
        const {profilingId} = orderGroup.payhubParams ? payhubProfiling : profiling;

        let paymentResult: CardPaymentResult | undefined;
        let paymentError: unknown;
        try {
          paymentResult = await paymentProcessor({orderGroup, profilingId});
        } catch (error) {
          paymentError = error;
        }

        const paymentStatus = orderGroup.payhubParams
          ? await dispatch(syncOrderGroupPaymentStatus(orderGroup.id))
          : undefined;

        // 'completed' order payment status has precedence to paymentProcessor error (WEB-8593)
        if (paymentError && paymentStatus === 'completed') {
          paymentResult = {result: 'completed'};
        }

        if (!paymentResult) {
          throw paymentError || new Error('Payment result is not defined');
        }

        if (paymentResult?.result === 'completed') {
          // clean up to apply new data from backend after purchase
          dispatch(discardPaymentRelatedData());
        }

        return paymentResult;
      }),
  );
}

export function loadCartSessionCompletionItems(sessionId: string): FuncAction<Promise<unknown>> {
  return requestActionCreator(
    loadSessionCompletionItemsAction,
    {sessionId},
    ({getState, dispatch}, client) => {
      const completionItems = getCartSessionCompletionItems(getState());
      if (completionItems) {
        return Promise.resolve();
      }

      return client.api
        .post<ClientBackendResponse<{items: ContentListItem[]}>>(
          `/checkout/session/${sessionId}/completion/items/get`,
          {
            body: {
              // API breaks if no `appearance` is passed
              appearance: {},
            },
          },
        )
        .then(extractResponsePayloadEnhanced)
        .then(({currency, items, language}) => {
          const nextCompletionItems = enhanceContentListItems(items, language, currency);
          dispatch(setCartSessionCompletionItems(nextCompletionItems));
        });
    },
  );
}

export function markDisclaimerAsSeen(disclaimerId: string): FuncAction<Promise<unknown>> {
  return (store, client) => {
    store.dispatch(markDisclaimerAsSeenAction(disclaimerId));

    return client.api.post(`/checkout/disclaimers/markAsSeen`, {
      body: {id: disclaimerId},
    });
  };
}
