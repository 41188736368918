import {
  DescriptionContent,
  preventDefault,
} from 'components/ClientProduct/Store/Description/Description';
import styles from 'components/ClientProduct/Store/Description/Description.scss';
import {ContextMenu} from 'components/ContextMenu';
import {Image} from 'components/Image';
import {Locator} from 'components/Locator';
import {useAnalytics} from 'hooks/useAnalytics';
import {usePreviewEvent} from 'hooks/usePreviewEvent';
import React, {useCallback, useId, useMemo, useRef, useState} from 'react';
import {ProductStore} from 'types/ProductStore';
import {StoreDescription} from 'types/StoreDescription';
import {rootLocator} from 'utils/rootLocator';
import {getColorStyles} from 'utils/styles/color';

export type FullDescriptionButtonProps = {
  description?: StoreDescription;
  fullDescriptionButton: ProductStore['fullDescriptionButton'];
  responsibilityInfo?: StoreDescription;
  productId: string;
  storeId: string;
};

const locator = rootLocator.productPage.infoAndResponsibility;

export function FullDescriptionButton({
  fullDescriptionButton,
  description,
  responsibilityInfo,
  productId,
  storeId,
}: FullDescriptionButtonProps): JSX.Element | null {
  const uid = useId();
  const [showTooltip, setShowTooltip] = useState(false);
  const analytics = useAnalytics();

  const handleOpen = useCallback(
    (evt: React.SyntheticEvent) => {
      preventDefault(evt);
      setShowTooltip(true);
      analytics.sendEvent({
        type: 'productCardFeatureClick',
        payload: {
          bannerButtonParams: {
            text: fullDescriptionButton?.text,
          },
          bannerType: 'sellersInfo',
          place: 'product',
          productId,
          storeId,
        },
      });
    },
    [analytics, fullDescriptionButton?.text, productId, storeId],
  );

  const handleClose = useCallback(() => setShowTooltip(false), []);
  const ref = useRef<HTMLButtonElement>(null);

  usePreviewEvent({
    rootRef: ref,
    previewEvent: useMemo(
      () => ({
        type: 'productCardFeaturePreview',
        payload: {
          bannerButtonParams: {
            text: fullDescriptionButton?.text,
          },
          bannerType: 'sellersInfo',
          place: 'product',
          productId,
          storeId,
        },
      }),
      [fullDescriptionButton?.text, productId, storeId],
    ),
  });

  if (!fullDescriptionButton || !description) {
    return null;
  }

  return (
    <>
      <button
        type="button"
        className={styles.fullDescriptionButton}
        style={getColorStyles(fullDescriptionButton.textColor)}
        onClick={handleOpen}
        ref={ref}
        {...locator()}
      >
        {fullDescriptionButton.icon ? (
          <Locator id="StoreInfoIconOnProductPage">
            <div className={styles.fullDescriptionButtonIcon}>
              <Image
                loadImmediately
                loadingHidden
                contain
                image={fullDescriptionButton.icon}
                pxFit={14}
                vwFit={{xs: 14}}
                width="14px"
                height="14px"
              />
            </div>
          </Locator>
        ) : null}
        {fullDescriptionButton.text}
      </button>
      {showTooltip && (
        <ContextMenu onClose={handleClose} id={uid} widthByContent noPaddings>
          <Locator id="StoreFullDescriptionExpanded">
            <div className={styles.fullDescriptionButtonMenu}>
              <DescriptionContent
                description={description}
                responsibilityInfo={responsibilityInfo}
              />
            </div>
          </Locator>
        </ContextMenu>
      )}
    </>
  );
}
