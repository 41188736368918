import PropTypes from 'prop-types';
import ImageBundle from './ImageBundle';

export default PropTypes.shape({
  banner: ImageBundle,
  description: PropTypes.string,
  expirationType: PropTypes.string.isRequired,
  fontBackgroundColor: PropTypes.string,
  id: PropTypes.string.isRequired,
  image: ImageBundle.isRequired,
  isAutoPromotion: PropTypes.bool,
  payload: PropTypes.shape({
    remainingTimeMs: PropTypes.number, // promotion start [sale]
  }),
  loadedTimeMs: PropTypes.number,
  subtitle: PropTypes.string,
  timeRemainingMs: PropTypes.number, // promotion end
  title: PropTypes.string,
  type: PropTypes.string.isRequired,
});
