import {Filter} from 'components/ClientProduct/Reviews/Filters/Filter';
import styles from 'components/ClientProduct/Reviews/Filters/index.scss';
import {Locator} from 'components/Locator';
import React, {useMemo} from 'react';
import {ProductReviewFilters, ProductReviewSorting} from 'types/ProductReviews';
import {getObfuscatedCountValue} from 'utils/obfuscatedCount';

import {Sorting} from './Sorting';

type Props = {
  productId?: string;
  filters?: ProductReviewFilters;
  href: string;
  selectedFilterId?: string;
  sorting?: ProductReviewSorting;
};

export function Filters({
  filters,
  href,
  selectedFilterId,
  productId,
  sorting,
}: Props): React.ReactElement | null {
  const availableFilters = useMemo(() => {
    return (filters || []).filter((filter) => getObfuscatedCountValue(filter.count) > 0);
  }, [filters]);

  if (availableFilters.length <= 1) {
    return null;
  }

  return (
    <Locator id="FiltersReviewsBlockProductPage">
      <div className={styles.filters}>
        {sorting ? <Sorting productId={productId} sorting={sorting} href={href} /> : null}
        {availableFilters.map((filter) => (
          <Filter
            filter={filter}
            href={href}
            key={filter.id}
            selected={filter.id === selectedFilterId}
          />
        ))}
      </div>
    </Locator>
  );
}
