import {Locator} from 'components/Locator';
import {Price} from 'components/Price';
import React, {memo} from 'react';
import {FormattedMessage} from 'react-intl';
import {PricingItem as PricingItemType, PricingItems as PricingItemsType} from 'types/Pricing';

import styles from './index.scss';

type ItemProps = {
  item: PricingItemType;
};

const Item = memo(function Item({item}: ItemProps) {
  const itemValuePrice = item.value?.price;

  return (
    <tr>
      <td className={styles.title}>{item.title}</td>
      {!!itemValuePrice && (
        <td className={styles.price}>
          <Price money={itemValuePrice} />
        </td>
      )}
    </tr>
  );
});

type Props = {
  items: PricingItemsType;
};

export const Pricing = memo(function Pricing({items}: Props) {
  return (
    <>
      <div className={styles.header}>
        <FormattedMessage
          defaultMessage="Order value"
          description="Pricing header on parcel page"
        />
      </div>
      <Locator id="ParcelPriceTable">
        <table className={styles.table}>
          <tbody>
            {items.map((item, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Item key={`row-${index}`} item={item} />
            ))}
          </tbody>
        </table>
      </Locator>
    </>
  );
});
