import {ApiClient} from 'helpers/ApiClient';
import {Store} from 'typesafe-actions';
import {
  addIdToCartSelection as addIdToStoreCartSelection,
  getCartSelection,
  SelectionType,
  setCartSelection as setStoreCartSelection,
} from 'utils/cartSelection';
import {IKeyValueStorage} from 'utils/KeyValueStorage/types';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getCartSelectionRequestData(localStorage: IKeyValueStorage) {
  const {type, ids} = getCartSelection(localStorage);
  if (type === SelectionType.IDS) {
    return {[type]: {itemIds: ids}};
  }
  return {[type]: {}};
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getCartIdsSelectionRequestData(ids: string[]) {
  return {[SelectionType.IDS]: {itemIds: ids}};
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getCartProductVariantIdsSelectionRequestData(ids: string[]) {
  return {[SelectionType.PRODUCT_VARIANT_IDS]: {productVariantIds: ids}};
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getCartAllSelectedRequestData() {
  return {[SelectionType.ALL]: {}};
}

export function setCartSelection(type: SelectionType, ids?: string[]) {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return (store: Store, client: ApiClient) =>
    setStoreCartSelection(client.storages.local, type, ids);
}

export function addIdToCartSelection(id: string) {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return (store: Store, client: ApiClient) => addIdToStoreCartSelection(client.storages.local, id);
}
