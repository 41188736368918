import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import {objectValues} from 'utils/object';
import styles from './Header.scss';

const cn = classnames.bind(styles);

const HeaderStyle = {
  DEFAULT: 'default',
  NARROW: 'narrow', // for headers on grouped cart page
};

const Header = React.memo(function Header({
  backgroundColor,
  centered,
  children,
  controls,
  style,
  textColor,
  ...restProps
}) {
  const inlineStyles = {
    backgroundColor: backgroundColor || null,
    color: textColor || null,
  };

  return (
    <div className={cn('header', style, {centered})} style={inlineStyles} {...restProps}>
      <div className={styles.content}>{children}</div>
      {controls ? <div className={styles.controls}>{controls}</div> : null}
    </div>
  );
});

Header.Style = HeaderStyle;

Header.propTypes = {
  backgroundColor: PropTypes.string,
  centered: PropTypes.bool,
  children: PropTypes.node,
  controls: PropTypes.node,
  style: PropTypes.oneOf(objectValues(HeaderStyle)),
  textColor: PropTypes.string,
};

Header.defaultProps = {
  backgroundColor: '',
  centered: false,
  children: null,
  controls: null,
  style: HeaderStyle.DEFAULT,
  textColor: '',
};

export default Header;
