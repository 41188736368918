import {useMatchMedia} from 'hooks/useMatchMedia';
import React, {useCallback, useContext, useMemo, useState} from 'react';

import {ApiClientContext} from './ApiClientContext';

type Props = React.PropsWithChildren<unknown>;

type A11ySettings = {
  highContrast: boolean;
  disableAutoload: boolean;
};

const defaultSettings: A11ySettings = {
  highContrast: false,
  disableAutoload: false,
};

type A11yContext = {
  settings: A11ySettings;
  updateSettings: (delta: Partial<A11ySettings>) => void;
};

export const A11yContext = React.createContext<A11yContext>({
  settings: defaultSettings,
  updateSettings: () => undefined,
});

export function A11yContextProvider({children}: Props): JSX.Element {
  const requiresHighContrast = useMatchMedia('(prefers-contrast: more)');

  const client = useContext(ApiClientContext);

  const [settings, setSettings] = useState<A11ySettings>(() => ({
    ...defaultSettings,
    highContrast: Boolean(requiresHighContrast?.matches),
    ...client?.cookiesRegistry.a11ySettings.restore(),
  }));

  const updateSettings = useCallback(
    (delta: Partial<A11ySettings>) => {
      const updatedSettings = {...settings, ...delta};

      client?.cookiesRegistry.a11ySettings.store(updatedSettings);

      return setSettings(updatedSettings);
    },
    [client, settings],
  );

  const contextValue = useMemo(() => ({settings, updateSettings}), [settings, updateSettings]);

  return <A11yContext.Provider value={contextValue}>{children}</A11yContext.Provider>;
}
