import cn from 'classnames';
import {Locator} from 'components/Locator';
import {Icon} from 'components/UIKit/Icon';
import React, {useMemo} from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';
import {matchMimeType} from 'utils/file';

import {UploaderSize} from '../types';
import styles from './index.scss';

type Props = {
  acceptedMimeTypes?: string[];
  disabled?: boolean;
  multiple?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  showLabel?: boolean;
  size?: UploaderSize;
};

// TODO: [nazarov] Узнать, можно ли оставить только "Загрузить медиафайл".
const messages = defineMessages({
  imageAndVideoAcceptedLabel: {
    defaultMessage: 'Upload photos or videos',
    description: 'Текст в инпуте загрузки медиафайла',
  },
  imageAcceptedLabel: {
    defaultMessage: 'Upload photo',
    description: 'Текст в инпуте загрузки медиафайла',
  },
  videoAcceptedLabel: {
    defaultMessage: 'Upload video',
    description: 'Текст в инпуте загрузки медиафайла',
  },
  anyAcceptedLabel: {
    defaultMessage: 'Upload media file',
    description: 'Текст в инпуте загрузки медиафайла',
  },
});

type InputLabelMessageProps = {
  acceptedMimeTypes?: string[];
};

function InputLabelMessage({acceptedMimeTypes = []}: InputLabelMessageProps): JSX.Element {
  const isImageAccepted = useMemo(
    () => acceptedMimeTypes.some((acceptedMimeType) => matchMimeType(acceptedMimeType, 'image/*')),
    [acceptedMimeTypes],
  );
  const isVideoAccepted = useMemo(
    () => acceptedMimeTypes.some((acceptedMimeType) => matchMimeType(acceptedMimeType, 'video/*')),
    [acceptedMimeTypes],
  );

  if (isImageAccepted && isVideoAccepted) {
    return <FormattedMessage {...messages.imageAndVideoAcceptedLabel} />;
  }

  if (isImageAccepted) {
    return <FormattedMessage {...messages.imageAcceptedLabel} />;
  }

  if (isVideoAccepted) {
    return <FormattedMessage {...messages.videoAcceptedLabel} />;
  }

  return <FormattedMessage {...messages.anyAcceptedLabel} />;
}

export function Input({
  acceptedMimeTypes,
  disabled,
  multiple,
  onChange,
  showLabel = false,
  size,
}: Props): JSX.Element {
  const accept = useMemo(() => acceptedMimeTypes?.join(','), [acceptedMimeTypes]);

  return (
    <label
      className={cn(styles.input, {
        [styles[`size-${size}`]!]: Boolean(size),
      })}
    >
      <span className={styles.control}>
        <span className={styles.content}>
          <Icon type="mono" name="image-add-filled-24" />
        </span>
        <Locator id="mediaUploader-input">
          <input
            accept={accept}
            className={styles.nativeInput}
            disabled={disabled}
            multiple={multiple}
            onChange={onChange}
            type="file"
          />
        </Locator>
      </span>
      {showLabel && (
        <span className={styles.label}>
          <InputLabelMessage acceptedMimeTypes={acceptedMimeTypes} />
        </span>
      )}
    </label>
  );
}
