import {loadAsyncScript} from 'utils/dom';
import {memoize} from 'utils/memoize';

import {TRUSTPILOT_SCRIPT_SRC} from '../consts';

export const loadTrustpilot = memoize(async (): Promise<void> => {
  if (__SERVER__) {
    throw new Error('Can not load Trustpilot on server');
  }

  await loadAsyncScript(TRUSTPILOT_SCRIPT_SRC);
});
