import {StickyColumns} from 'components/StickyColumns';
import ErrorShape from 'shapes/Error';
import {ParcelShape} from 'shapes/Parcel';
import {WarrantyTextsShape} from 'shapes/WarrantyTexts';
import {PageLoading} from 'components/PageLoading';
import ProductLiteShape from 'shapes/ProductLite';
import PropTypes from 'prop-types';
import React, {createRef, Component} from 'react';
import {DeliveryConfirmationModeType} from 'shapes/ParcelDeliveryConfirmationMode';
import TrackingInfoShape from 'shapes/TrackingInfo';
import {
  isParcelAddressCanBeChanged,
  getStatusAppearance,
  getDeliveredTimeMs,
  getParcelShowAddress,
} from 'store/modules/parcel';
import {deepLinks} from 'helpers/deepLink';
import {isArrayNotEmpty} from 'utils/array';
import {ParcelPageCardGroup, ParcelPageCard, ParcelPageCardInner} from 'components/ParcelPageCard';
import {ConsolidationHeader} from 'components/ParcelElements/ConsolidationHeader';
import {Banners} from 'components/OrderBanner/Banners';
import {getValueByPath} from 'utils/object';
import {DeliveryPointInfoShape} from 'shapes/DeliveryPoints';
import {Locator} from 'components/Locator';
import {ProductsList} from 'components/ContentList/ProductsList';
import {getShippingEstimate} from 'store/modules/parcels';
import {rootLocator} from 'utils/rootLocator';
import {verticalScrollToElement} from 'utils/pageScroll';
import {ParcelAddress} from './ParcelAddress';
import {OrderCard} from './OrderCard';
import {Cancellation} from './Cancellation';
import {Pricing} from './Pricing';
import {PostOffice} from './MapCard/PostOffice';
import {DeliveryPoint} from './MapCard/DeliveryPoint';
import {RelatedProducts} from './RelatedProducts';
import {ParcelConfirmationDelivery} from './ConfirmationDelivery/ParcelConfirmationDelivery';
import {ParcelShipping} from './Shipping';
import {Support} from './Support';
import {ParcelPageDeepLinkMiddlewareProvider} from './ParcelPageDeepLinkMiddlewareProvider';
import {EmailConfirmation} from './EmailConfirmation';

export const SHOW_DELIVERY_POINT_ACTION = 'showDeliveryPoint';

const locator = rootLocator.orderPage;

class ParcelPageComponent extends Component {
  static propTypes = {
    activeFilter: PropTypes.string.isRequired,
    markParcelNotDelivered: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    orderReviewRemoving: PropTypes.shape({
      [PropTypes.string]: PropTypes.bool,
    }),
    orderReviewSaving: PropTypes.shape({
      [PropTypes.string]: PropTypes.bool,
    }),
    params: PropTypes.shape({
      parcelId: PropTypes.string.isRequired,
      action: PropTypes.string,
    }).isRequired,
    parcel: ParcelShape,
    parcelCancelError: ErrorShape,
    parcelCancelling: PropTypes.bool.isRequired,
    parcelDeliveredError: ErrorShape,
    parcelMarkingDelivered: PropTypes.bool.isRequired,
    parcelMarkingNotDelivered: PropTypes.bool.isRequired,
    parcelNotDeliveredError: ErrorShape,
    parcelTracking: TrackingInfoShape,
    parcelTrackingLoading: PropTypes.bool.isRequired,
    parcelWarrantyTexts: WarrantyTextsShape,
    parcelWarrantyTextsLoading: PropTypes.bool.isRequired,
    parcelWarrantyTextsError: ErrorShape,
    relatedProducts: PropTypes.arrayOf(ProductLiteShape.isRequired),
    relatedProductsError: ErrorShape,
    relatedProductsLoading: PropTypes.bool,
    removeOrderReview: PropTypes.func.isRequired,
    renderAddressForm: PropTypes.func.isRequired,
    reviewRemoveError: PropTypes.objectOf(ErrorShape),
    reviewSaveError: PropTypes.objectOf(ErrorShape),
    saveOrderReview: PropTypes.func.isRequired,
    markParcelDelivered: PropTypes.func.isRequired,
    pointInfo: DeliveryPointInfoShape,
    isPointInfoLoading: PropTypes.bool,
    updateParcelAddress: PropTypes.func.isRequired,
    parcelAddressUpdating: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    orderReviewRemoving: {},
    orderReviewSaving: {},
    parcel: null,
    parcelCancelError: null,
    parcelDeliveredError: null,
    parcelNotDeliveredError: null,
    parcelTracking: null,
    parcelWarrantyTexts: null,
    parcelWarrantyTextsError: null,
    relatedProducts: null,
    relatedProductsError: null,
    relatedProductsLoading: false,
    reviewRemoveError: {},
    reviewSaveError: {},
    pointInfo: null,
    isPointInfoLoading: false,
  };

  constructor(props) {
    super(props);

    this.orderListRef = createRef();
    this.deliveryPointRef = createRef();
    this.ordersRefs = createRef();
    this.ordersRefs.current = {};

    this.state = {
      isYesClicked: false,
      isNoClicked: false,
      reviewOpenByOrderId: null,
    };
  }

  componentDidMount() {
    const {isPointInfoLoading} = this.props;
    if (!isPointInfoLoading && this.isShowDeliveryPointAction()) {
      this.showDeliveryInfoPoint();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      isPointInfoLoading,
      params: {action},
    } = this.props;

    const actionAppeared =
      action === SHOW_DELIVERY_POINT_ACTION && prevProps.params.action !== action;

    if (
      !isPointInfoLoading &&
      ((prevProps.isPointInfoLoading && this.isShowDeliveryPointAction()) || actionAppeared)
    ) {
      this.showDeliveryInfoPoint();
    }
  }

  deepLinkMiddleware = (targetUrl) => {
    return new Promise((resolve, reject) => {
      const match = deepLinks.match(targetUrl);
      const params = match?.params;

      if (match?.view !== 'orders') {
        resolve(targetUrl);
      }

      switch (params?.action) {
        case 'pickupInfo':
          this.showDeliveryInfoPoint();
          break;
        case 'review':
          this.showReviewForm(params.orderId);
          break;
        default:
          reject();
      }
    });
  };

  registerOrderRef(name) {
    this.ordersRefs.current[name] = createRef();

    return this.ordersRefs.current[name];
  }

  isShowDeliveryPointAction() {
    const {
      params: {action},
    } = this.props;
    return action === SHOW_DELIVERY_POINT_ACTION;
  }

  handleYesClick = () => {
    this.setState({
      isYesClicked: true,
    });
  };

  handleNoClick = () => {
    this.setState({
      isNoClicked: true,
    });
  };

  handleAddressEdit = () => {
    this.setState({
      showAddressForm: true,
    });
  };

  handleAddressFormClose = () => {
    this.setState({
      showAddressForm: false,
    });
  };

  handleCancel = () => {
    const {onCancel} = this.props;

    onCancel();
  };

  showDeliveryInfoPoint = () => {
    if (this.deliveryPointRef && this.deliveryPointRef.current) {
      verticalScrollToElement(this.deliveryPointRef.current, {margin: 16, forced: true});
    }
  };

  showReviewForm = (orderId) => {
    this.setState({
      reviewOpenByOrderId: orderId,
    });

    verticalScrollToElement(this.ordersRefs.current[orderId].current, {margin: 16, forced: true});
  };

  renderShipping() {
    const {parcel} = this.props;
    const {parcelTracking, parcelTrackingLoading} = this.props;

    if (parcelTrackingLoading) {
      return <PageLoading />;
    }

    return (
      <ParcelShipping
        parcel={parcel}
        parcelTracking={parcelTracking}
        checkpoints={(parcelTracking && parcelTracking.checkpoints) || null}
      />
    );
  }

  renderCancellation() {
    const {
      parcel,
      onCancel,
      parcelCancelError,
      parcelCancelling,
      updateParcelAddress,
      parcelAddressUpdating,
    } = this.props;

    return (
      <Cancellation
        parcel={parcel}
        onCancel={onCancel}
        error={parcelCancelError}
        loading={parcelCancelling}
        updateParcelAddress={updateParcelAddress}
        parcelAddressUpdating={parcelAddressUpdating}
      />
    );
  }

  renderPostOffice() {
    const {parcelTracking, parcelTrackingLoading} = this.props;

    if (parcelTrackingLoading) {
      return <PageLoading />;
    }

    if (!parcelTracking || !parcelTracking.postOffice) {
      return null;
    }

    return <PostOffice postOffice={parcelTracking.postOffice} />;
  }

  renderDeliveryPointInfo() {
    const {pointInfo, isPointInfoLoading, parcel} = this.props;

    if (!pointInfo || !parcel.deliveryPoint?.id) {
      return null;
    }

    const parcelInner = isPointInfoLoading ? (
      <PageLoading />
    ) : (
      <Locator id="DeliveryPointInfoBlock">
        <ParcelPageCardInner>
          <DeliveryPoint deliveryPointInfo={pointInfo} parcel={parcel} />
        </ParcelPageCardInner>
      </Locator>
    );

    return <ParcelPageCard ref={this.deliveryPointRef}>{parcelInner}</ParcelPageCard>;
  }

  renderRelatedProducts() {
    const {relatedProducts, relatedProductsError, relatedProductsLoading} = this.props;

    return (
      <RelatedProducts
        products={relatedProducts}
        error={relatedProductsError}
        loading={relatedProductsLoading}
      />
    );
  }

  renderSimilarProducts() {
    const {parcel} = this.props;
    const similarProducts = parcel.orders.map((order) => order.similarProducts).find(Boolean);

    if (!similarProducts) {
      return null;
    }

    return (
      <ParcelPageCard>
        <ParcelPageCardInner>
          <ProductsList
            content={similarProducts}
            fullWidth
            source="orderParcel"
            {...locator.similarProducts()}
          />
        </ParcelPageCardInner>
      </ParcelPageCard>
    );
  }

  renderAddress() {
    const {parcel} = this.props;
    const showAddress = getParcelShowAddress(parcel);

    if (this.state.showAddressForm) {
      return (
        <ParcelPageCard>
          <ParcelPageCardInner>
            {this.props.renderAddressForm({onCancel: this.handleAddressFormClose})}
          </ParcelPageCardInner>
        </ParcelPageCard>
      );
    }

    if (showAddress) {
      return (
        <ParcelPageCard>
          <ParcelPageCardInner>
            <ParcelAddress
              address={parcel.address}
              onEdit={isParcelAddressCanBeChanged(parcel) ? this.handleAddressEdit : null}
            />
          </ParcelPageCardInner>
        </ParcelPageCard>
      );
    }

    return null;
  }

  renderPricing() {
    const {parcel} = this.props;
    const pricingItems = getValueByPath(parcel, 'pricing', 'items');

    if (!pricingItems || !pricingItems.length) {
      return null;
    }

    return (
      <ParcelPageCard>
        <ParcelPageCardInner>
          <Pricing items={pricingItems} />
        </ParcelPageCardInner>
      </ParcelPageCard>
    );
  }

  render() {
    const {
      parcel,
      parcelMarkingDelivered,
      orderReviewSaving,
      orderReviewRemoving,
      parcelDeliveredError,
      reviewRemoveError,
      reviewSaveError,
      saveOrderReview,
      removeOrderReview,
      markParcelDelivered,
      markParcelNotDelivered,
      parcelMarkingNotDelivered,
      parcelNotDeliveredError,
      parcelWarrantyTexts,
      parcelWarrantyTextsLoading,
      parcelWarrantyTextsError,
      activeFilter,
    } = this.props;
    const {isYesClicked, isNoClicked, reviewOpenByOrderId} = this.state;

    const showDeliveryConfirmation =
      parcel.deliveryConfirmationMode === DeliveryConfirmationModeType.YES_NO ||
      parcel.deliveryConfirmationMode === DeliveryConfirmationModeType.YES;

    const banners = getValueByPath(parcel, 'lite', 'banners');
    const consolidationBadge = getValueByPath(parcel, 'appearance', 'consolidationBadge');
    const {emailConfirmationBanner} = parcel;

    return (
      <ParcelPageDeepLinkMiddlewareProvider middleware={this.deepLinkMiddleware}>
        <StickyColumns enabledFrom="xl">
          <StickyColumns.Column md="12" lg="8">
            <EmailConfirmation banner={emailConfirmationBanner} />
            <ParcelPageCard>
              <ParcelPageCardInner>
                <ConsolidationHeader
                  consolidationBadge={consolidationBadge}
                  shippingEstimate={getShippingEstimate(parcel)}
                  statusAppearance={getStatusAppearance(parcel)}
                  deliveredTimeMs={getDeliveredTimeMs(parcel)}
                />
              </ParcelPageCardInner>
              {isArrayNotEmpty(banners) && (
                <ParcelPageCardInner>
                  <Banners
                    banners={banners}
                    loadedTimeMs={parcel.loadedTimeMs}
                    showDeliveryInfo={this.showDeliveryInfoPoint}
                  />
                </ParcelPageCardInner>
              )}
            </ParcelPageCard>
            {showDeliveryConfirmation && (
              <ParcelConfirmationDelivery
                onYesClick={this.handleYesClick}
                onNoClick={this.handleNoClick}
                markParcelNotDelivered={markParcelNotDelivered}
                parcel={parcel}
                parcelMarkingNotDelivered={parcelMarkingNotDelivered}
                parcelNotDeliveredError={parcelNotDeliveredError}
              />
            )}
            <ParcelPageCardGroup ref={this.orderListRef}>
              {parcel.orders.map((item) => (
                <OrderCard
                  ref={this.registerOrderRef(item.order.id)}
                  key={item.order.id}
                  parcel={parcel}
                  activeFilter={activeFilter}
                  item={item}
                  isYesClicked={isYesClicked}
                  isNoClicked={isNoClicked}
                  parcelMarkingDelivered={parcelMarkingDelivered}
                  orderReviewSaving={orderReviewSaving}
                  orderReviewRemoving={orderReviewRemoving}
                  parcelDeliveredError={parcelDeliveredError}
                  reviewRemoveError={reviewRemoveError}
                  reviewSaveError={reviewSaveError}
                  saveOrderReview={saveOrderReview}
                  removeOrderReview={removeOrderReview}
                  markParcelDelivered={markParcelDelivered}
                  triggerReviewOpen={reviewOpenByOrderId === item.order.id}
                />
              ))}
            </ParcelPageCardGroup>
            {this.renderSimilarProducts()}
            {this.renderDeliveryPointInfo()}
            {this.renderAddress()}
            {this.renderShipping()}
            {this.renderCancellation()}
            {this.renderRelatedProducts()}
          </StickyColumns.Column>
          <StickyColumns.Column md="12" lg="4">
            {this.renderPricing()}
            <Support
              parcel={parcel}
              parcelWarrantyTexts={parcelWarrantyTexts}
              parcelWarrantyTextsLoading={parcelWarrantyTextsLoading}
              parcelWarrantyTextsError={parcelWarrantyTextsError}
            />
            {this.renderPostOffice()}
          </StickyColumns.Column>
        </StickyColumns>
      </ParcelPageDeepLinkMiddlewareProvider>
    );
  }
}

export function ParcelPage(props) {
  return <ParcelPageComponent {...props} />;
}
