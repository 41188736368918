import PropTypes from 'prop-types';
import React from 'react';
import {defineMessages, useIntl} from 'react-intl';
import {Locator} from 'components/Locator';
import styles from './index.scss';

const messages = defineMessages({
  region: {
    description: '[a11y] Описание региона с cookies и соглашением о конфиденциальности',
    defaultMessage: 'Cookies and Privacy Policy',
  },
});

export default function Disclaimer({buttonCaption, children, onClose}) {
  const intl = useIntl();

  return (
    <Locator id="CookieDisclaimer">
      <div
        className={styles.disclaimer}
        role="region"
        aria-label={intl.formatMessage(messages.region)}
      >
        <div className={styles.inner}>
          <div className={styles.table}>
            <Locator id="CookieDisclaimerText">
              <div className={styles.left}>{children}</div>
            </Locator>
            <div className={styles.right}>
              <Locator id="CookieDisclaimerAcceptPrivacy">
                <button type="button" onClick={onClose} className={styles.close}>
                  {buttonCaption}
                </button>
              </Locator>
            </div>
          </div>
        </div>
      </div>
    </Locator>
  );
}

Disclaimer.propTypes = {
  buttonCaption: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
};
