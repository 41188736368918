import classnames from 'classnames/bind';
import {Locator} from 'components/Locator';
import {useAnalytics} from 'hooks/useAnalytics';
import React, {useCallback} from 'react';
import {NavLink} from 'react-router-dom';

import styles from './Link.scss';

const cn = classnames.bind(styles);

type Props = {
  children: React.ReactNode;
  href?: string;
  to?: string;
  hasNoReferrer?: boolean;
  onClick?(): void;
  'data-testid'?: string;
};

export function FooterLink({
  href,
  to,
  onClick,
  children,
  'data-testid': testId,
  hasNoReferrer = true,
}: Props): React.ReactElement | null {
  const analytics = useAnalytics();

  const handleClick = useCallback(() => {
    analytics.sendEvent({
      type: 'supportLinkClick',
      payload: {from: 'footer'},
    });

    onClick?.();
  }, [analytics, onClick]);

  if (!href && !to && !onClick) {
    return null;
  }

  if (onClick && !href && !to) {
    return (
      <div className={cn('wrapper')}>
        <Locator id={testId}>
          <button type="button" onClick={handleClick} className={cn('link', 'button')}>
            {children}
          </button>
        </Locator>
      </div>
    );
  }

  return (
    <div className={cn('wrapper')}>
      {to ? (
        <Locator id={testId}>
          <NavLink
            onClick={handleClick}
            className={cn('link')}
            activeClassName={cn('active')}
            to={to}
          >
            {children}
          </NavLink>
        </Locator>
      ) : (
        // eslint-disable-next-line react/jsx-no-target-blank
        <a
          onClick={handleClick}
          className={cn('link')}
          target="_blank"
          rel={`noopener${hasNoReferrer ? ' noreferrer' : ''}`}
          href={href}
          data-testid={testId}
        >
          {children}
        </a>
      )}
    </div>
  );
}
