import cn from 'classnames';
import {useA11ySettingsPopupRender} from 'components/A11ySettingsPopup';
import {Badges} from 'components/Badges';
import {Copyright} from 'components/Copyright';
import {Reducer} from 'components/Layout/Reducer';
import {Locator} from 'components/Locator';
import {LinksSlider} from 'components/PageMetadata/LinksSlider';
import {TrustpilotBadge} from 'components/Trustpilot/TrustpilotBadge';
import {VisaSecurityBadge} from 'components/VisaSecurityBadge';
import {Scope} from 'config';
import {useAnalytics} from 'hooks/useAnalytics';
import {useBot} from 'hooks/useBot';
import {useCurrentUrlForAnalytics} from 'hooks/useCurrentUrlForAnalytics';
import {useDeviceVars} from 'hooks/useDeviceVars';
import {useLanguage} from 'hooks/useLanguage';
import {useObserveOnce} from 'hooks/useObserveOnce';
import {usePopup} from 'hooks/usePopup';
import {useScope} from 'hooks/useScope';
import {JoomLogisticsLinkMessage} from 'messages/JoomLogisticsLink';
import {JoomProLinkMessage} from 'messages/JoomProLink';
import React, {ReactNode, useCallback, useMemo, useRef} from 'react';
import {FormattedMessage} from 'react-intl';
import {getUrl} from 'routes';
import {LinksBlock} from 'types/PageMetadata/LinksBlock';
import {PageSource} from 'types/PageSource';

import styles from './index.scss';
import {FooterLink} from './Link';

export type FooterProps = {
  deepUrl?: string;
  pageId?: string;
  richMeta?: boolean;
  children?: ReactNode;
  productId?: string;
  source?: PageSource;
  linksBlock?: LinksBlock;
  mobileRounded?: boolean;
};

const LEGAL_FAQ_ARTICLE = '5e7201fdff117c3eb7d2cfd8';
const COOLBE_LEGAL_FAQ_ARTICLE = '660ac5a2d769c62ae487935b';
const COOLBE_BILLING_FAQ_ARTICLE = '66152622f81acb3cc030a98a';
const PRODUCT_SAFETY_FAQ_ARTICLE = '655cac7793e2e55e63a1a1bb';
const PRODUCT_SAFETY_COOLBE_FAQ_ARTICLE = '65f86e7385f7b64bae760a29';

export const Footer = React.memo(function Footer({
  deepUrl,
  pageId,
  richMeta = false,
  children,
  productId,
  source,
  linksBlock,
  mobileRounded,
}: FooterProps): React.ReactElement {
  const scope = useScope();
  const lang = useLanguage();
  const {
    footerJoomHBPromoLink,
    joomAboutLink,
    joomProLink,
    joomLogisticsLink,
    showLegalInfoLinkInFooter,
    trustpilot,
    visaSecurity,
  } = useDeviceVars();
  const {companyName} = scope.companyInfo;
  const bot = useBot();
  const showPageId = Boolean(bot && richMeta && pageId);
  const aboutLinkParams =
    joomAboutLink && scope.notJmt ? {href: joomAboutLink} : {to: getUrl('about', {lang, scope})};
  const url = useCurrentUrlForAnalytics();
  const analytics = useAnalytics();

  const ref = useRef<HTMLDivElement>(null);
  const a11yOptionsPopup = usePopup(useA11ySettingsPopupRender({}));

  const showTrustpilotBadge = Boolean(
    scope.notJmt && scope.is(Scope.GLOBAL) && trustpilot?.enabled && source !== PageSource.CART,
  );

  const showVisaSecurityBadge = Boolean(
    scope.notJmt && scope.is(Scope.GLOBAL) && visaSecurity && source !== PageSource.CART,
  );

  const handleObserve = useCallback(() => {
    analytics.sendEvent({
      type: 'footerPreview',
      payload: {
        url,
        ...(productId ? {productId} : {}),
      },
    });
  }, [analytics, productId, url]);

  useObserveOnce(handleObserve, {targetRef: ref});

  const buyersBlock = (
    <div className={styles.footerBlock}>
      <h4 className={styles.footerColumnHeader}>
        <FormattedMessage
          description="'For customers' header in footer"
          defaultMessage="Customers"
        />
      </h4>
      {scope.is(Scope.GLOBAL) ? (
        <Locator id="FooterLinkCategory">
          <FooterLink to={getUrl('catalog', {scope, lang})}>
            <FormattedMessage description="Catalog in footer" defaultMessage="Catalogue" />
          </FooterLink>
        </Locator>
      ) : null}
      <Locator id="FooterLinkSupport">
        <FooterLink to={getUrl('faq', {scope, lang})}>
          <FormattedMessage description="Support link in footer" defaultMessage="Help centre" />
        </FooterLink>
      </Locator>
      <Locator id="FooterLinkPrivacy">
        <FooterLink to={getUrl('privacy', {scope, lang})}>
          <FormattedMessage description="Privacy link in footer" defaultMessage="Privacy policy" />
        </FooterLink>
      </Locator>
      <Locator id="FooterLinkTermsInCustomersBlock">
        <FooterLink to={getUrl('terms', {scope, lang})}>
          <FormattedMessage description="Terms link in footer" defaultMessage="Terms of use" />
        </FooterLink>
      </Locator>
      <Locator id="FooterLinkAccessibility">
        <FooterLink onClick={a11yOptionsPopup.open}>
          <FormattedMessage
            description="Ссылка на настройки специальных возможностей"
            defaultMessage="Accessibility settings"
          />
        </FooterLink>
      </Locator>
      {scope.isDomain(Scope.COOLBE) && (
        <FooterLink to={getUrl('faqArticle', {scope, lang, articleId: COOLBE_BILLING_FAQ_ARTICLE})}>
          <FormattedMessage
            defaultMessage="Billing terms and conditions"
            description={
              'Billing terms FAQ link in footer\nOriginal EN text:\nBilling terms and conditions'
            }
          />
        </FooterLink>
      )}
      {scope.is(Scope.GLOBAL) ? (
        <>
          {scope.notJmt && footerJoomHBPromoLink ? (
            <Locator id="FooterLinkHBPromo">
              <FooterLink to={footerJoomHBPromoLink}>
                <FormattedMessage
                  description="Ссылка на описание акции ко дню рождения Joom"
                  defaultMessage="Promotion"
                />
              </FooterLink>
            </Locator>
          ) : null}
          <FooterLink to={getUrl('premiumSeoList', {scope, lang})}>
            <FormattedMessage
              description="Ссылка на список premium seo страниц в футере"
              defaultMessage="Top articles on {companyName}"
              values={{companyName}}
            />
          </FooterLink>
          <FooterLink to={getUrl('bests', {scope, lang})}>
            <FormattedMessage
              description="Ссылка на список популярных запросов"
              defaultMessage="Top searches on {companyName}"
              values={{companyName}}
            />
          </FooterLink>
        </>
      ) : null}
    </div>
  );

  const merchantLink = useMemo(() => {
    const domain = scope.hostname.replace('www.', '');
    return `https://merchant.${domain}`;
  }, [scope.hostname]);

  const partnersBlock =
    scope.notJmt &&
    scope.is(Scope.GLOBAL, Scope.GEEK, Scope.COOLBE) &&
    // for coolbe domains but not coolbe prefix
    scope.prefixScope !== Scope.COOLBE ? (
      <div className={styles.footerBlock}>
        <h4 className={styles.footerColumnHeader}>
          <FormattedMessage
            description="'For partners' header in footer"
            defaultMessage="Partners"
          />
        </h4>
        <Locator id="FooterLinkMerchant">
          <FooterLink href={`${merchantLink}/`}>
            <FormattedMessage
              description="Merchant link in footer"
              defaultMessage="Become a seller"
            />
          </FooterLink>
        </Locator>
        <Locator id="FooterLinkTermsInMerchantBlock">
          <FooterLink href={`${merchantLink}/terms`}>
            <FormattedMessage
              description="Merchant terms link in footer"
              defaultMessage="Terms of service"
            />
          </FooterLink>
        </Locator>
        {joomLogisticsLink ? (
          <Locator id="FooterLinkJL">
            <FooterLink href={joomLogisticsLink} hasNoReferrer={false}>
              <JoomLogisticsLinkMessage />
            </FooterLink>
          </Locator>
        ) : null}
        {joomProLink ? (
          <Locator id="FooterLinkJoomPro">
            <FooterLink href={joomProLink} hasNoReferrer={false}>
              <JoomProLinkMessage />
            </FooterLink>
          </Locator>
        ) : null}
      </div>
    ) : null;

  const companyBlock = (
    <div className={styles.footerBlock}>
      <h4 className={styles.footerColumnHeader}>
        <FormattedMessage description="'Company' header in footer" defaultMessage="Company" />
      </h4>
      {scope.notDomain(Scope.COOLBE) && (
        <Locator id="FooterLinkAbout">
          <FooterLink {...aboutLinkParams}>
            <FormattedMessage description="About page link in footer" defaultMessage="About us" />
          </FooterLink>
        </Locator>
      )}
      <Locator id="FooterLinkIprProtection">
        <FooterLink to={getUrl('ipr-protection', {scope, lang})}>
          <FormattedMessage
            description="IPR Protection link in footer"
            defaultMessage="Copyright protection"
          />
        </FooterLink>
      </Locator>
      {scope.notJmt && (
        <Locator id="FooterLinkProductSafety">
          <FooterLink
            to={getUrl('faqArticle', {
              scope,
              lang,
              articleId: scope.is(Scope.COOLBE, Scope.CBTREND)
                ? PRODUCT_SAFETY_COOLBE_FAQ_ARTICLE
                : PRODUCT_SAFETY_FAQ_ARTICLE,
            })}
          >
            <FormattedMessage
              description="Product Safety link in footer"
              defaultMessage="Product safety"
            />
          </FooterLink>
        </Locator>
      )}
      {scope.notJmt && scope.notDomain(Scope.COOLBE) && showLegalInfoLinkInFooter && (
        <FooterLink to={getUrl('faqArticle', {scope, lang, articleId: LEGAL_FAQ_ARTICLE})}>
          {lang === 'de' ? (
            'Impressum'
          ) : (
            <FormattedMessage
              description="Ссылка на юридическую информацию в футере."
              defaultMessage="Legal notice"
            />
          )}
        </FooterLink>
      )}
      {scope.isDomain(Scope.COOLBE) && (
        <FooterLink to={getUrl('faqArticle', {scope, lang, articleId: COOLBE_LEGAL_FAQ_ARTICLE})}>
          <FormattedMessage
            defaultMessage="Contact us"
            description={
              // prettier-ignore
              'Legal notice FAQ link in footer\nOriginal EN text:\nContact us'
            }
          />
        </FooterLink>
      )}
    </div>
  );

  const joomGroupBlock = scope.notJmt && scope.is(Scope.GLOBAL, Scope.GEEK) && (
    <div className={styles.footerBlock}>
      <h4 className={styles.footerColumnHeader}>
        <FormattedMessage
          description="Joom Group Companies header in footer"
          defaultMessage="Joom Group сompanies"
        />
      </h4>
      <Locator id="FooterLinkOnfy">
        <FooterLink href="https://onfy.de/">
          <FormattedMessage description="Onfy link text in footer" defaultMessage="Onfy" />
        </FooterLink>
      </Locator>
    </div>
  );

  const visaSecureBlock = showVisaSecurityBadge && (
    <Locator id="VisaSecureOnFooter">
      <div className={styles.footerBlock}>
        <h4 className={styles.footerColumnHeader}>
          <FormattedMessage
            description="Заголовок блока Visa Secure в подвале"
            defaultMessage="Visa Secure"
          />
        </h4>
        <VisaSecurityBadge />
      </div>
    </Locator>
  );

  const trustpilotBlock = showTrustpilotBadge && (
    <div className={styles.footerBlock}>
      <h4 className={styles.footerColumnHeader}>
        <FormattedMessage
          description="[header] Trustpilot service badge"
          defaultMessage="Trustpilot"
        />
      </h4>
      <TrustpilotBadge />
    </div>
  );

  const appBadgesBlock = scope.is(Scope.GLOBAL) ? (
    <Locator id="FooterBadges">
      <div className={styles.footerBlock}>
        <h4 className={styles.footerColumnHeader}>
          <FormattedMessage
            description="'Our applications' header in footer"
            defaultMessage="Mobile"
          />
        </h4>
        <Badges deepUrl={deepUrl} eventCategory="Footer" />
      </div>
    </Locator>
  ) : null;

  let footerLayout: Array<Array<ReactNode>>;

  const hasMoreThanOneBadge = [trustpilotBlock, visaSecureBlock].filter(Boolean).length > 1;

  if (hasMoreThanOneBadge) {
    footerLayout = [
      [buyersBlock, partnersBlock].filter(Boolean),
      [companyBlock, joomGroupBlock].filter(Boolean),
      [trustpilotBlock, visaSecureBlock].filter(Boolean),
      [appBadgesBlock].filter(Boolean),
    ];
  } else {
    footerLayout = [
      [buyersBlock].filter(Boolean),
      [partnersBlock].filter(Boolean),
      [companyBlock, joomGroupBlock].filter(Boolean),
      [trustpilotBlock, visaSecureBlock, appBadgesBlock].filter(Boolean),
    ];
  }

  // Links slider
  const shouldShowLinksSlider =
    scope.not(Scope.CBTREND) && source === PageSource.PRODUCT && Boolean(linksBlock?.links.length);

  return (
    <Locator id="Footer">
      <div
        className={cn(
          styles.footer,
          mobileRounded && scope.not(Scope.CBTREND) && styles.mobileRounded,
        )}
        ref={ref}
      >
        <Reducer noMarginBottom>
          <footer className={styles.footerColumns}>
            {footerLayout.map((nodeList, columnIndex) =>
              nodeList.length ? (
                <div
                  // eslint-disable-next-line react/no-array-index-key
                  key={columnIndex}
                  className={cn(styles.footerColumn, nodeList.length === 1 && styles.narrow)}
                >
                  {nodeList.map((node, elementIndex) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <React.Fragment key={elementIndex}>{node}</React.Fragment>
                  ))}
                </div>
              ) : null,
            )}
          </footer>
          <div className={styles.bottomBar}>
            {shouldShowLinksSlider && (
              <div className={styles.seoLinks}>
                <LinksSlider linksBlock={linksBlock as LinksBlock} productId={productId} />
              </div>
            )}
            <div className={styles.legalInfo}>
              <div className={styles.copy}>
                <Copyright />
              </div>
            </div>
          </div>
          {React.Children.map(children, (child) => (
            <div className={styles.debugPreferences}>{child}</div>
          ))}
          {showPageId && <div className={styles.pageId}>Page ID: {pageId}</div>}
        </Reducer>
      </div>
    </Locator>
  );
});
