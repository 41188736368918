import classnames from 'classnames/bind';
import CheckIcon from 'components/icons/check.jsx.svg';
import ErrorMessage from 'components/ErrorMessage';
import {Overlay} from 'components/Overlay';
import {useMounted} from 'hooks/useMounted';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';

import styles from './index.scss';

const cn = classnames.bind(styles);

export const SelectedIcon = React.memo(function SelectedIcon() {
  return <CheckIcon className={cn('check')} />;
});

const Select = React.memo(function Select(props) {
  const mounted = useMounted();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectingIndex, setSelectingIndex] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(props.selectedIndex);

  const ifMounted = (fn, ...args) => (mounted ? fn(...args) : null);

  useEffect(() => {
    if (selectedIndex !== props.selectedIndex) {
      setSelectedIndex(props.selectedIndex);
    }
    // disabled after enabling exhaustive deps rule
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedIndex]);

  function handleClick(index) {
    let newIndex = null;
    if (index !== selectedIndex) {
      newIndex = index;
    } else if (props.unselectable) {
      newIndex = -1;
    }

    if (newIndex === null || loading) {
      return;
    }

    setError(null);

    const promise = props.onSelect(newIndex);
    if (!promise) {
      setSelectedIndex(newIndex);
      return;
    }

    setSelectingIndex(newIndex);
    setLoading(true);

    promise
      .then(() => ifMounted(setSelectedIndex, newIndex))
      .catch((err) => ifMounted(setError, err))
      .finally(() => ifMounted(setLoading, false));
  }

  return (
    <div className={cn('select')}>
      {React.Children.map(props.children, (child, index) => {
        if (!child) {
          return null;
        }

        const selected = index === selectedIndex;
        const {unselectable} = props;
        const childLoading =
          loading && (selectingIndex === index || (selectingIndex === -1 && selected));

        const enabled = props.isItemEnabled(index);
        const onClick = enabled ? () => handleClick(index) : null;

        return (
          <div
            role="button"
            tabIndex={0}
            className={cn('item', {disabled: !enabled, selected, unselectable})}
            onClick={onClick}
          >
            <Overlay loading={childLoading}>
              <div className={cn('content')}>{child}</div>
              {props.renderSelectedIcon(selected, index)}
            </Overlay>
          </div>
        );
      })}
      {error ? (
        <div className={cn('error')}>
          <ErrorMessage error={error} internal noRequestId />
        </div>
      ) : null}
    </div>
  );
});

Select.propTypes = {
  children: PropTypes.node,
  isItemEnabled: PropTypes.func,
  onSelect: PropTypes.func.isRequired,
  selectedIndex: PropTypes.number,
  unselectable: PropTypes.bool,
  renderSelectedIcon: PropTypes.func,
};

Select.defaultProps = {
  children: null,
  isItemEnabled: () => true,
  selectedIndex: -1,
  unselectable: false,
  renderSelectedIcon: (selected, index) => (selected ? <SelectedIcon /> : null),
};

export default Select;
