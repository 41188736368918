import LegalityShape from 'shapes/Legality';
import PropTypes from 'prop-types';
import React from 'react';
import {Sidebar} from 'components/Sidebar/Sidebar';
import {FormattedMessage} from 'react-intl';
import styles from './index.scss';
import Content from '../Content';
import {ContentType, TypeWithCancellation} from '../Type';

export default class Popup extends React.Component {
  static propTypes = {
    alignLeft: PropTypes.bool,
    children: PropTypes.node,
    onButtonClick: PropTypes.func.isRequired,
    onBack: PropTypes.func,
    onClose: PropTypes.func,
    document: LegalityShape,
    contentType: PropTypes.string,
    disableAnimation: PropTypes.bool,
  };

  static defaultProps = {
    alignLeft: false,
    children: null,
    contentType: Content.DEFAULT,
    disableAnimation: false,
    document: null,
    onBack: null,
    onClose: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      content: ContentType.DEFAULT,
    };
  }

  getIcon() {
    if (this.props.contentType === ContentType.CONFIRMATION) {
      return null;
    }
    return require('./people.svg');
  }

  renderPopupTitle() {
    return (
      <FormattedMessage description="Legality popup title" defaultMessage="Your personal data" />
    );
  }

  renderChildren() {
    const {children} = this.props;
    if (children) {
      return children;
    }

    const icon = this.getIcon();

    return icon ? (
      <div className={styles.icon}>
        <img alt="" src={icon} />
      </div>
    ) : null;
  }

  render() {
    const {alignLeft, contentType, document, disableAnimation} = this.props;
    const {onBack, onButtonClick, onClose} = this.props;
    const content = (document && document.payload && document.payload[contentType]) || null;
    const backOnConsentStyle =
      !!document &&
      !!TypeWithCancellation[document.type] &&
      contentType === ContentType.CONFIRMATION;

    return (
      <div>
        <Sidebar
          disableAnimation={disableAnimation}
          isPopup
          onBack={onBack}
          onClose={onClose}
          title={this.renderPopupTitle()}
        >
          <div className={`${styles.content} ${alignLeft ? styles.alignLeft : ''}`}>
            {this.renderChildren()}
            {content ? (
              <Content
                backOnConsentStyle={backOnConsentStyle}
                content={content}
                onButtonClick={onButtonClick}
                type={this.state.content}
              />
            ) : null}
          </div>
        </Sidebar>
      </div>
    );
  }
}
