import {AppLink} from 'components/AppLink';
import {useScope} from 'hooks/useScope';
import React from 'react';
import {googlePlayUrl} from 'utils/url/app';

import {Badge} from '../Badge';
import styles from './index.scss';
import {useBadgeHandler} from './useBadgeHandler';

type Props = {
  deepUrl: string;
  eventCategory: string;
  small?: boolean;
};

export function GooglePlayBadge({deepUrl, eventCategory, small = false}: Props): JSX.Element {
  const scope = useScope();
  const handleGooglePlay = useBadgeHandler(deepUrl, eventCategory, 'Google Market Badge. Click');

  return (
    <AppLink
      className={styles.link}
      href={googlePlayUrl(scope)}
      onClick={handleGooglePlay}
      role="button"
    >
      <Badge className={styles.badge} small={small} type="googleplay" />
    </AppLink>
  );
}
