import cn from 'classnames';
import {AnimationConfig, CouponPopupById} from 'components/Header/CouponRedesign/CouponPopup';
import {Locator} from 'components/Locator';
import {Icon} from 'components/UIKit/Icon';
import {usePopup, usePopupManager} from 'components/UIKit/Popup/PopupProvider';
import {useCouponsPopupRender} from 'connectors/CouponsPopup';
import {getCouponCardStyle} from 'helpers/coupons';
import {useDispatch, useSelector} from 'hooks/redux';
import {useAnalytics} from 'hooks/useAnalytics';
import {useLayoutEffectOnce} from 'hooks/useEffectOnce';
import {usePreviewEvent} from 'hooks/usePreviewEvent';
import React, {CSSProperties, ReactElement, useCallback, useMemo, useRef, useState} from 'react';
import {flushSync} from 'react-dom';
import {defineMessages, FormattedMessage} from 'react-intl';
import {setViewedCouponCard} from 'store/modules/couponCards';
import {getCouponCards} from 'store/modules/couponCards/selectors';
import {CouponCard} from 'types/CouponCard';
import {registerStickyFooter} from 'utils/pageScroll';

import styles from './index.scss';

const messages = defineMessages({
  couponsButton: {
    defaultMessage: 'My coupons',
    description: "[button] Button that opens the user coupon's list",
  },
});

type StubProps = {
  coupon: CouponCard;
  source: string;
  mobile?: boolean;
  onViewed: () => void;
  onClose: () => void;
};

function HeaderCouponStub({coupon, source, mobile, onViewed, onClose}: StubProps): ReactElement {
  const analytics = useAnalytics();
  const popupManager = usePopupManager();

  const [animationStyle, setAnimationStyle] = useState<CSSProperties | undefined>();

  const rootRef = useRef<HTMLDivElement>(null);

  usePreviewEvent({
    rootRef,
    previewEvent: {type: 'couponStubShow', payload: {couponId: coupon.id, source}},
  });

  useLayoutEffectOnce(() => {
    const unregister = registerStickyFooter({
      nodeRef: rootRef,
    });

    return unregister;
  });

  const handleClick = useCallback(() => {
    analytics.sendEvent({type: 'couponStubClick', payload: {couponId: coupon.id, source}});

    let animationConfig: AnimationConfig | undefined;

    if (mobile && rootRef.current) {
      const rootRect = rootRef.current.getBoundingClientRect();

      animationConfig = {
        topStartPosition: rootRect.top,
        startAnimation: (couponRect) => {
          setAnimationStyle({
            '--startHeight': `${rootRect.height}px`,
            '--startWidth': `${rootRect.width}px`,
            '--height': `${couponRect.height}px`,
            '--width': `${couponRect.width}px`,
            '--left': `${couponRect.left}px`,
            '--top': `${couponRect.top}px`,
          } as CSSProperties);
        },
      };
    } else {
      onViewed();
    }

    popupManager.open(({onClose}) => (
      <CouponPopupById couponId={coupon.id} {...{source, animationConfig, onClose}} />
    ));
  }, [analytics, coupon.id, mobile, onViewed, popupManager, source]);

  const handleAnimationEnd = useCallback(() => {
    flushSync(() => {
      onViewed();
    });
    setAnimationStyle(undefined);
  }, [onViewed]);

  const cardStyle = useMemo(() => getCouponCardStyle(coupon), [coupon]);

  return (
    <div
      ref={rootRef}
      className={cn(styles.container, mobile && styles.mobile, animationStyle && styles.animation)}
      style={{...cardStyle, ...animationStyle}}
      onAnimationEnd={handleAnimationEnd}
    >
      <Locator id="CouponTabOnNavBar">
        <button type="button" className={styles.stub} onClick={handleClick}>
          {coupon.texts.previewDescription}
        </button>
      </Locator>
      {mobile && (
        <button type="button" className={styles.closeButton} onClick={onClose}>
          <Icon name="close-circle-filled-24" type="mono" loader={null} />
        </button>
      )}
    </div>
  );
}

function HeaderAllCouponsButton(): ReactElement {
  const couponListPopup = usePopup(
    useCouponsPopupRender({
      source: 'headerCoupon',
    }),
  );

  return (
    <div className={styles.container}>
      <Locator id="CouponTabOnNavBar">
        <button type="button" className={styles.stub} onClick={couponListPopup.open}>
          <FormattedMessage {...messages.couponsButton} />
        </button>
      </Locator>
    </div>
  );
}

type Props = {
  source: string;
  mobile?: boolean;
  onClose?: () => void;
};

export function HeaderCoupon({source, mobile, onClose}: Props): ReactElement | null {
  const dispatch = useDispatch();

  const [isMobileShown, setIsMobileShown] = useState(true);

  const coupons = useSelector(getCouponCards);
  const coupon = useMemo(() => coupons?.find(({viewed}) => !viewed), [coupons]);

  const handleViewed = useCallback(() => {
    setIsMobileShown(false);
    if (coupon) {
      dispatch(setViewedCouponCard(coupon.id));
    }
  }, [coupon, dispatch]);

  const handleClose = useCallback(() => {
    handleViewed();
    onClose?.();
  }, [handleViewed, onClose]);

  if (!coupons?.length) {
    return null;
  }
  if (mobile && (!coupon || !isMobileShown)) {
    return null;
  }

  return coupon ? (
    <HeaderCouponStub
      key={coupon.id}
      coupon={coupon}
      mobile={mobile}
      source={source}
      onViewed={handleViewed}
      onClose={handleClose}
    />
  ) : (
    <HeaderAllCouponsButton />
  );
}
