import PropTypes from 'prop-types';
import {keyKey} from 'utils/array';
import ImageBundle from './ImageBundle';
import PaymentMethodBadge from './PaymentMethodBadge';

export const PaymentMethodKnownType = {
  // virtual payment method to gather all bank cards
  CARDS: 'cards',

  APPLE_PAY: 'applePay',
  CARD: 'card',
  GOOGLE_PAY: 'googlePay',
  MBWAY: 'mbway',
  MULTIBANCO: 'multibanco',
  NEW_CARD: 'newCard',
  NEW_PAYPAL: 'newPayPal',
  POINTS: 'points',
  SAVED_PAYPAL: 'savedPayPal',
  WEB: 'web',
};

export const PaymentMethodSyntheticType = {
  POINTS: 'synthetic-points',
};

export const PaymentMethodWithoutUserInteraction = keyKey([
  PaymentMethodKnownType.CARD,
  PaymentMethodKnownType.WEB,
]);

export default PropTypes.shape({
  type: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  primary: PropTypes.bool.isRequired,
  inactive: PropTypes.bool.isRequired,
  synthetic: PropTypes.bool,
  appearance: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    image: ImageBundle,
    badge: PaymentMethodBadge,
  }),
  description: PropTypes.string,
});
