import React, {useMemo} from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';
import {hashCode} from 'utils/string';

import styles from './index.scss';

const messages = defineMessages({
  lookingNowCounterLabel: {
    description: 'Live counter of product views on the reviews page',
    defaultMessage: `{count, plural,
      one {# person is viewing}
      other {# people are viewing}} this product right now - your review might help them`,
  },
});

type LookingNowCounterProps = {
  counterKey?: string;
};

export function LookingNowCounter({counterKey = ''}: LookingNowCounterProps): JSX.Element {
  const count = useMemo(() => {
    const date = new Date();
    const hour = date.getHours();
    // Time-bound pseudo-random number generator
    const percent = ((hour % 5) / 4) * ((hour % 11) / 10);

    return Math.round(230 * percent) + (hashCode(counterKey) % 10) + 10;
  }, [counterKey]);

  return (
    <div className={styles.root}>
      <div className={styles.blob} />
      <div className={styles.content}>
        <FormattedMessage {...messages.lookingNowCounterLabel} values={{count}} />
      </div>
    </div>
  );
}
