import {DeepLinkMiddlewareContext} from 'providers/DeepLinks';
import React from 'react';

type Props = React.PropsWithChildren<{
  middleware(targetUrl: string): Promise<string>;
}>;

export function ParcelPageDeepLinkMiddlewareProvider({
  middleware,
  children,
}: Props): React.ReactElement {
  return (
    <DeepLinkMiddlewareContext.Provider value={middleware}>
      {children}
    </DeepLinkMiddlewareContext.Provider>
  );
}
