import {ApiClient} from 'helpers/ApiClient';

import {IKeyValueStorage} from '../KeyValueStorage/types';
import {PREVIOUS_SEARCH_SESSION_ID} from './consts';
import type {SearchSession} from './types';

type AcrossTabsValue = {
  time: number;
  value: SearchSession['searchSessionId'];
};

export class AcrossTabsSession {
  id = PREVIOUS_SEARCH_SESSION_ID;

  storage: IKeyValueStorage;

  value?: AcrossTabsValue;

  expireTime = 5000;

  constructor(client: ApiClient) {
    this.storage = client.storages.local;
  }

  set(value: string): void {
    this.storage?.setItem(this.id, JSON.stringify({value, time: Date.now()}));
  }

  remove(): void {
    this.storage?.removeItem(this.id);
  }

  get(): AcrossTabsValue['value'] | undefined {
    const item = this.storage?.getItem(this.id) as string;

    let parsedItem;
    try {
      parsedItem = JSON.parse(item);
    } catch {
      return undefined;
    }

    const {value, time} = parsedItem;

    if (Date.now() - time < this.expireTime) {
      return value;
    }

    this.remove();
    return undefined;
  }
}
