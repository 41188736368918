import {useSendProductCardGroupScroll} from 'components/ContentList/hooks';
import {Locator} from 'components/Locator';
import {ProductLite} from 'components/ProductLite';
import React from 'react';
import {ProductLite as ProductLiteType} from 'types/ProductLite';

import {WIDE6} from '../../imageSizes';
import {Slider, SliderItem} from '../../Slider';
import styles from './index.scss';

type Props = {
  id: string;
  items: Array<ProductLiteType>;
  onFavorite?: (product: ProductLiteType) => Promise<void>;
  onUnfavorite?: (product: ProductLiteType) => Promise<void>;
};

export const SmallCards = React.memo(function SmallCards({
  id,
  items,
  onFavorite,
  onUnfavorite,
}: Props): React.ReactElement {
  const handleScroll = useSendProductCardGroupScroll(id);

  return (
    <Locator id="ContentListProductGroupSmallCard">
      <div className={styles.smallCards}>
        <Slider loop onScroll={handleScroll}>
          <div className={styles.inner}>
            {items.map((item) => (
              <SliderItem key={item.id}>
                {({a11yProps, ref, selected}): React.ReactElement => (
                  <div ref={ref} className={styles.cell} {...a11yProps}>
                    <Locator id="SmallCardsProductCardGroupItem">
                      <ProductLite
                        focusable={selected}
                        product={item}
                        sizes={WIDE6.sizes}
                        width="100%"
                        onFavorite={onFavorite}
                        onUnfavorite={onUnfavorite}
                      />
                    </Locator>
                  </div>
                )}
              </SliderItem>
            ))}
          </div>
        </Slider>
      </div>
    </Locator>
  );
});
