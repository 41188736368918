export enum PageSource {
  AUTH = 'authorization',
  BEST = 'best',
  CART = 'cart',
  CHECKOUT = 'checkout',
  CHECKOUT_SUCCESS = 'checkoutSuccess',
  DEFAULT = 'other',
  FAVORITES = 'favorites',
  LIKED_PRODUCT_COLLECTIONS = 'likedProductCollections',
  MAIN = 'main',
  ORDERS = 'orders',
  PRODUCT = 'product',
  PRODUCT_OUT_OF_STOCK = 'productOutOfStock',
  PRODUCT_COLLECTIONS_DISCOVERY = 'productCollectionsDiscovery',
  PROMOTION = 'promotion',
  SEARCH = 'search',
  SELF_PRODUCT_COLLECTION = 'selfProductCollection',
  SELF_PRODUCT_COLLECTIONS = 'selfProductCollections',
  SOCIAL_POST = 'socialPost',
  SOCIAL_USER_PRODUCT_COLLECTIONS = 'socialUserProductCollections',
  STORE = 'store',
  SUCCESS = 'success' /* like checkoutSuccess */,
  TREND = 'trend',
}
