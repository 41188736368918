import {Overlay} from 'components/Overlay';
import {Icon} from 'components/UIKit/Icon';
import {Popup} from 'components/UIKit/Popup';
import {Header as PopupHeader} from 'components/UIKit/Popup/Header';
import {PopupView, PopupViewProps} from 'components/UIKit/Popup/PopupProvider';
import {useMountedRef} from 'hooks/useMounted';
import {usePopup} from 'hooks/usePopup';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';
import {TrackingBarcode} from 'types/Tracking';

import styles from './index.scss';
import {formatBarcodeType, isBarcodeTypeSupported} from './utils';

const messages = defineMessages({
  popupTitle: {
    defaultMessage: 'Barcode',
    description: 'Title of popup with the barcode for receiving the parcel',
  },
});

// Parcel Barcode Popup

type ParcelBarcodePopupProps = {
  barcode: TrackingBarcode;
};

function ParcelBarcodePopup({
  barcode,
  onClose,
}: ParcelBarcodePopupProps & PopupViewProps): JSX.Element {
  const svgRef = useRef<SVGSVGElement>(null);
  const mountedRef = useMountedRef();
  const [inProcess, setInProcess] = useState(true);

  useEffect(() => {
    setInProcess(true);
    import('jsbarcode')
      .then(({default: JSBarcode}) => {
        if (mountedRef.current) {
          JSBarcode(svgRef.current, barcode.value, {
            format: formatBarcodeType(barcode.type),
            margin: 0,
          });
          setInProcess(false);
        }
      })
      .catch(onClose);
  }, [barcode, mountedRef, onClose]);

  return (
    <Popup>
      <PopupHeader onClose={onClose}>
        <FormattedMessage {...messages.popupTitle} />
      </PopupHeader>
      <Overlay loading={inProcess}>
        <svg className={styles.barcode} ref={svgRef} />
      </Overlay>
    </Popup>
  );
}

const useParcelBarcodePopupView = ({barcode}: ParcelBarcodePopupProps): PopupView => {
  return useCallback(
    (popupViewProps: PopupViewProps) => (
      <ParcelBarcodePopup {...popupViewProps} barcode={barcode} />
    ),
    [barcode],
  );
};

// Parcel Barcode

type ParcelBarcodeProps = {
  barcode: TrackingBarcode;
};

export function ParcelBarcode({barcode}: ParcelBarcodeProps): JSX.Element | null {
  const barcodePopup = usePopup(useParcelBarcodePopupView({barcode}));

  if (!isBarcodeTypeSupported(barcode.type)) {
    return null;
  }

  return (
    <button className={styles.button} onClick={barcodePopup.open} type="button">
      <Icon name="barcode-filled-24" type="mono" />
    </button>
  );
}
