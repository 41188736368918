import classnames from 'classnames/bind';
import DropdownArrow from 'components/icons/DropdownArrow';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './ToggleHeader.scss';

const cn = classnames.bind(styles);

const ToggleHeader = React.memo(function ToggleHeader({children, expanded, onClick}) {
  return (
    <div
      key="header"
      className={cn('toggleHeader', {expanded})}
      onClick={onClick}
      role="button"
      tabIndex={0}
    >
      <div className={cn('content')}>{children}</div>
      <div className={cn('controls')}>
        <DropdownArrow preserveAspectRatio="xMidYMid meet" className={cn('icon')} />
      </div>
    </div>
  );
});

ToggleHeader.propTypes = {
  children: PropTypes.node.isRequired,
  expanded: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

ToggleHeader.defaultProps = {
  expanded: false,
};

export default ToggleHeader;
