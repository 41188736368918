import {LocaleDataContext} from 'providers/LocaleDataContext';
import {useContext} from 'react';
import {LocaleData} from 'types/LocaleData';

export function useLocaleData(): LocaleData {
  const context = useContext(LocaleDataContext);
  if (!context) {
    throw new Error('LocaleData is not defined');
  }
  return context;
}
