import classnames from 'classnames/bind';
import ErrorMessage from 'components/ErrorMessage';
import React, {FC, ReactNode} from 'react';
import {Error} from 'types/Error';

import styles from './Row.scss';

const cn = classnames.bind(styles);

type Props = {
  name: ReactNode;
  error?: Error;
  errorUid?: string;
  children: ReactNode;
  htmlFor?: string;
};

export const Row: FC<Props> = ({name, htmlFor, error, children, errorUid}: Props): JSX.Element => {
  return (
    <fieldset className={cn('row')}>
      <div className={styles.header}>
        <label className={styles.label} htmlFor={htmlFor}>
          {name}
        </label>
        {error && (
          <span className={styles.errorMessage} id={errorUid}>
            <ErrorMessage error={error} internal noRequestId />
          </span>
        )}
      </div>
      <div className={styles.content}>{children}</div>
    </fieldset>
  );
};
