import {discardCart} from 'store/modules/cartCount';
import {RootState} from 'store/rootReducer';
import {extractResponsePayloadEnhanced} from 'store/utils';
import {createRequestAction, requestActionCreator} from 'store/utils/requestActions';
import {ClientBackendResponse} from 'types/ClientBackendResponse';
import {Error} from 'types/Error';
import {OrderGroup} from 'types/OrderGroup';
import {createAction, createReducer, FuncAction} from 'typesafe-actions';

import {loadAction} from './customsDuty';

export const CLEAR = 'orderGroups/orderGroup/CLEAR';
export const LOAD = 'orderGroups/orderGroup/LOAD';
export const LOAD_SUCCESS = 'orderGroups/orderGroup/LOAD_SUCCESS';
export const LOAD_FAIL = 'orderGroups/orderGroup/LOAD_FAIL';

const clearOrderGroupAction = createAction(CLEAR)();
const loadOrderGroupAction = createRequestAction(LOAD, LOAD_SUCCESS, LOAD_FAIL)<
  {id: string},
  OrderGroup
>();

export const cancelOrderGroupAction = createRequestAction(
  '@orderGroups/orderGroup/CANCEL_REQUEST',
  '@orderGroups/orderGroup/CANCEL_SUCCESS',
  '@orderGroups/orderGroup/CANCEL_FAILURE',
)();

export const actions = {cancelOrderGroupAction, loadOrderGroupAction, clearOrderGroupAction};

type State = {
  data: OrderGroup | null;
  id?: string;
  loaded: boolean;
  loading: boolean;
  error: Error | null;
  cancelation: boolean;
  cancelationError: Error | undefined;
};

const initialState = {
  data: null,
  loaded: false,
  loading: false,
  error: null,
  cancelation: false,
  cancelationError: undefined,
};

export const reducer = createReducer<State>(initialState)
  .handleAction(loadOrderGroupAction.success, (state, {payload}) => {
    return {
      ...state,
      id: payload.id,
      error: null,
      loaded: false,
      loading: true,
    };
  })
  .handleAction(loadOrderGroupAction.success, (state, {payload, meta}) => {
    if (meta.id !== state.id) {
      return state;
    }
    return {
      ...state,
      data: payload,
      loaded: true,
      loading: false,
    };
  })
  .handleAction(loadAction.failure, (state, {payload, meta}) => {
    if (meta.id !== state.id) {
      return state;
    }

    return {
      ...state,
      error: payload,
      loading: false,
    };
  })
  .handleAction(clearOrderGroupAction, (state) => {
    return {
      ...state,
      ...initialState,
    };
  })
  .handleAction(cancelOrderGroupAction.request, (state) => {
    return {
      ...state,
      cancelation: true,
      cancelationError: undefined,
    };
  })
  .handleAction(cancelOrderGroupAction.success, (state) => {
    return {...state, cancelation: false};
  })
  .handleAction(cancelOrderGroupAction.failure, (state, {payload}) => {
    return {...state, cancelation: false, cancelationError: payload};
  });

function getState(globalState: RootState): State {
  return globalState.orderGroups.orderGroup;
}

export function isOrderGroupLoaded(globalState: RootState): boolean {
  const state = getState(globalState);
  return state.loaded;
}

export function getOrderGroup(globalState: RootState): OrderGroup | null {
  return isOrderGroupLoaded(globalState) ? getState(globalState).data : null;
}

export function getOrderGroupById(globalState: RootState, id: string): OrderGroup | null {
  const orderGroup = getOrderGroup(globalState);
  if (orderGroup && orderGroup.id === id) {
    return orderGroup;
  }
  return null;
}

export function getOrderGroupError(globalState: RootState): Error | null {
  return getState(globalState).error || null;
}

export function isOrderGroupLoading(globalState: RootState): boolean {
  return getState(globalState).loading;
}

// For backwards compatibility and naming consistency
export const clearOrderGroup = clearOrderGroupAction;

export function loadOrderGroup(id: string): FuncAction<Promise<OrderGroup>> {
  return requestActionCreator(loadOrderGroupAction, {id}, (store, client) =>
    client.api
      .get<ClientBackendResponse<OrderGroup>>(`/orderGroups/${id}`)
      .then(extractResponsePayloadEnhanced)
      .then((data) => {
        store.dispatch(discardCart(true));
        return data;
      }),
  );
}

export const cancelOrderGroup = (id: string): FuncAction<Promise<unknown>> =>
  requestActionCreator(cancelOrderGroupAction, {}, (_, client) => {
    return client.api
      .post<ClientBackendResponse<unknown>>(`/orderGroups/${id}/cancel`)
      .then(({body: {payload}}) => payload);
  });

export const getOrderGroupCancelationInProgress = (globalState: RootState): boolean =>
  Boolean(getState(globalState).cancelation);

export const getOrderGroupCancelationError = (globalState: RootState): Error | undefined =>
  getState(globalState).cancelationError;
