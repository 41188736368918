import {jsf32, randomFromArray, randomInRange} from 'utils/random';
import throttle from 'utils/throttle';

import {CanvasRect, Rect} from './types';
import {
  getCanvasRect,
  GetCanvasRectOptions,
  getDocumentRect,
  getViewRect,
  ShouldCanvasBeSynced,
  shouldCanvasBeSynced,
} from './utils';

const SCROLL_THROTTLE_THRESHHOLD = 100; /* ms */

const GET_CANVAS_RECT_OPTIONS: GetCanvasRectOptions = {
  safeAreaScaler: 2,
};
const SHOULD_CANVAS_BE_SYNCED_OPTIONS: ShouldCanvasBeSynced = {
  safeAreaScaler: 2,
  scrollTolerancePercent: 0.01 /* 1% */,
};

const FLAKES_DENSITY = 0.000128;
const FLAKES_OPACITY_SET = [1, 0.6, 0.4];
const FLAKES_RADIUS_SET = [4, 3, 2];
const FLAKES_SPEED_RANGE = [15, 40] as const;
const FLAKES_WAVES_RANGE = [10, 15] as const;
const FLAKES_WAVE_RANGE = [30, 50] as const;

export class SnowFlakes {
  private ctx: CanvasRenderingContext2D;

  private canvas: HTMLCanvasElement;

  private canvasRect!: CanvasRect;

  private viewRect!: Rect;

  private documentRect!: Rect;

  private rafId?: number;

  constructor({ctx, canvas}: {ctx: CanvasRenderingContext2D; canvas: HTMLCanvasElement}) {
    this.ctx = ctx;
    this.canvas = canvas;

    this.init();
  }

  destroy(): void {
    this.stop();

    window.removeEventListener('scroll', this.handleScroll);
  }

  init(): void {
    this.destroy();

    window.addEventListener('scroll', this.handleScroll, {passive: true});

    this.syncCanvas();
    this.start();
  }

  stop(): void {
    if (this.rafId) {
      cancelAnimationFrame(this.rafId);
      this.rafId = undefined;
    }
  }

  start(): void {
    if (!this.rafId) {
      this.update();
    }
  }

  // Handlers

  private handleScroll = throttle((): void => {
    this.syncCanvas();
  }, SCROLL_THROTTLE_THRESHHOLD);

  // Canvas

  private syncCanvas(): void {
    if (
      !this.canvasRect ||
      !this.viewRect ||
      !this.documentRect ||
      shouldCanvasBeSynced(
        this.canvasRect,
        this.viewRect,
        this.documentRect,
        SHOULD_CANVAS_BE_SYNCED_OPTIONS,
      )
    ) {
      // Set canvas size and position
      const viewRect = getViewRect();
      const documentRect = getDocumentRect();
      const canvasRect = getCanvasRect(viewRect, documentRect, GET_CANVAS_RECT_OPTIONS);

      this.canvas.width = canvasRect.scaledWidth;
      this.canvas.height = canvasRect.scaledHeight;
      this.canvas.style.width = `${canvasRect.width}px`;
      this.canvas.style.height = `${canvasRect.height}px`;
      this.canvas.style.transform = `translateY(${canvasRect.top.toFixed(3)}px)`;

      this.canvasRect = canvasRect;
      this.viewRect = viewRect;
      this.documentRect = documentRect;
    }
  }

  // Flakes

  private getMaxFlakesAmount(): number {
    const {canvasRect} = this;

    return Math.floor(canvasRect.initWidth * canvasRect.initHeight * FLAKES_DENSITY);
  }

  // Loop

  private update = (time: number = 0): void => {
    this.render(time);
    this.syncCanvas();

    this.rafId = window.requestAnimationFrame(this.update);
  };

  private render(time: number): void {
    const {canvasRect, ctx} = this;
    const rnd = jsf32(0x9e3779b9, 0x243f6a88, 0xb7e15162, 0xe151243f);
    const timeMs = time / 1000;
    const flakesCount = this.getMaxFlakesAmount();

    ctx.scale(canvasRect.scaler, canvasRect.scaler);
    ctx.clearRect(0, 0, canvasRect.scaledWidth, canvasRect.scaledHeight);

    for (let index = 0; index < flakesCount; index += 1) {
      const opacity = randomFromArray(FLAKES_OPACITY_SET, rnd);
      const radius = randomFromArray(FLAKES_RADIUS_SET, rnd);
      const speed = randomInRange(...FLAKES_SPEED_RANGE, rnd);
      const waves = randomInRange(...FLAKES_WAVES_RANGE, rnd);
      const wave = randomInRange(...FLAKES_WAVE_RANGE, rnd);

      const yOffset = speed * timeMs;
      const y = (randomInRange(0, canvasRect.initHeight, rnd) + yOffset) % canvasRect.initHeight;
      const xOffset = Math.cos((y / canvasRect.initHeight) * waves * Math.PI) * wave;
      const x = (randomInRange(0, canvasRect.initWidth, rnd) + xOffset) % canvasRect.initWidth;

      let scrolledY = (y - canvasRect.top) % canvasRect.initHeight;

      if (scrolledY < 0) {
        scrolledY += canvasRect.initHeight;
      }

      ctx.fillStyle = `rgba(255, 255, 255, ${opacity})`;
      ctx.beginPath();
      ctx.arc(x, scrolledY, radius, 0, Math.PI * 2, true);
      ctx.fill();
    }
    ctx.resetTransform();
  }
}
