import {ImageBundle} from 'types/Image';
import {ProductCollectionOwner} from 'types/ProductCollection/ProductCollectionOwner';
import {getIsSensitiveProductShown} from 'utils/sensitiveContent';

export type ProductCollectionLiteImageBundle = ImageBundle & {
  adult?: boolean;
  sensitive?: boolean;
  productId?: string;
};

export type ProductCollectionLite = {
  id: string;
  title: string;
  productsCount?: string;
  cover?: ImageBundle;
  productsImages?: Array<ProductCollectionLiteImageBundle>;
  canAddProducts?: boolean;
  isLikeable?: boolean;
  isLiked?: boolean;
  isReportable?: boolean;
  owner?: ProductCollectionOwner;
};

export function getProductCollectionLiteImageSensitive(
  image: ProductCollectionLiteImageBundle,
): boolean {
  return Boolean(
    image.sensitive && (!image.productId || !getIsSensitiveProductShown(image.productId)),
  );
}

export function getProductCollectionLiteImageAdult(
  image: ProductCollectionLiteImageBundle,
  isUserAdult = false,
): boolean {
  return Boolean(image.adult && !isUserAdult);
}

export function getProductCollectionLiteImageRestricted(
  image: ProductCollectionLiteImageBundle,
  isUserAdult?: boolean,
): boolean {
  return (
    getProductCollectionLiteImageSensitive(image) ||
    getProductCollectionLiteImageAdult(image, isUserAdult)
  );
}
