import {AddProductToCollectionsPopup} from 'components/ProductCollections/AddProductToCollectionsPopup';
import {PopupManagerContextType} from 'components/UIKit/Popup/PopupProvider';
import {Analytics} from 'helpers/ApiClient/Analytics';
import {useAnalytics} from 'hooks/useAnalytics';
import {usePopupManager} from 'hooks/usePopupManager';
import {Toast, useOpenToast} from 'providers/Toast';
import {ToastProps} from 'providers/Toast/Toast';
import {OpenToastFn} from 'providers/Toast/ToastContext';
import React from 'react';
import {defineMessages, IntlShape, useIntl} from 'react-intl';
import {
  CommonProduct,
  getCommonProductId,
  getCommonProductImageBundle,
  getCommonProductRestricted,
} from 'types/CommonProduct';
import {ProductCollection} from 'types/ProductCollection';
import {ProductCollectionLite} from 'types/ProductCollection/ProductCollectionLite';

const messages = defineMessages({
  addProduct: {
    defaultMessage: 'The product has been added to the collection',
    description: 'Текст в уведомлении о добавлении товара в подборку',
  },
  removeProduct: {
    defaultMessage: 'Product has been removed from the collection',
    description: 'Текст в уведомлении об удалении товара из подборки',
  },
  addProductMulti: {
    defaultMessage: `Product added to {collectionsCount, plural, one {# collection} other {# collections} }`,
    description: 'Текст в уведомлении о добавлении товара в несколько подборок',
  },
  removeProductMulti: {
    defaultMessage: `Product removed from {collectionsCount, plural, one {# collection} other {# collections} }`,
    description: 'Текст в уведомлении об удалении товара из нескольких подборок',
  },
  removeProducts: {
    defaultMessage: `{productsCount, plural,
    one {# product removed}
    other {# products removed}
  } from the {collectionName} collection`,
    description: 'Текст в уведомлении об удалении товаров из подборки',
  },
  moveProducts: {
    defaultMessage: `{productsCount, plural,
    one {# product moved}
    other {# products moved}
  } to the {collectionName} collection`,
    description: 'Текст в уведомлении о перемещении товаров в подборку',
  },
  moveProductsMulti: {
    defaultMessage: `{productsCount, plural,
    one {# product moved}
    other {# products moved}
  } to {collectionsCount, plural,
    one {# collection}
    other {# collections}
  }`,
    description: 'Текст в уведомлении о перемещении товаров в подборки',
  },
});

export type ProductCollectionToastChangesProductInCollection = {
  type: 'productInCollection';
  productCollections: ProductCollectionLite[];
  product: CommonProduct;
  isUserAdult?: boolean;
  isRemoving: boolean;
};

export type ProductCollectionToastChangesMoveProducts = {
  type: 'moveProductsToCollection';
  currentCollection?: ProductCollection;
  productCollections: ProductCollectionLite[];
  products: CommonProduct[];
  isRemoving: boolean;
  isRemovingFromCurrentCollection: boolean;
};

export type ProductCollectionToastChanges =
  | ProductCollectionToastChangesProductInCollection
  | ProductCollectionToastChangesMoveProducts;

export type OpenProductCollectionToastFn = (changes: ProductCollectionToastChanges) => void;

export function openProductCollectionToast({
  popupManager,
  analytics,
  openToast,
  intl,
  changes,
}: {
  popupManager: PopupManagerContextType;
  analytics: Analytics;
  openToast: OpenToastFn;
  intl: IntlShape;
  changes: ProductCollectionToastChanges;
}): void {
  const handleClick = () => {
    // eslint-disable-next-line default-case
    switch (changes?.type) {
      case 'productInCollection': {
        popupManager.open(({onClose, onBack}) => (
          <AddProductToCollectionsPopup
            products={[changes.product]}
            source="successfulAddNotification"
            onBack={onBack}
            onClose={onClose}
            onSave={onClose}
          />
        ));
        break;
      }

      case 'moveProductsToCollection': {
        popupManager.open(({onClose, onBack}) => (
          <AddProductToCollectionsPopup
            areProductsMoving
            currentCollection={
              changes.isRemovingFromCurrentCollection ? undefined : changes.currentCollection
            }
            products={changes.products}
            source="successfulAddNotification"
            onBack={onBack}
            onClose={onClose}
          />
        ));
        break;
      }
    }

    if (changes && 'product' in changes) {
      analytics.sendEvent({
        type: 'productCollectionNotificationEditClick',
        payload: {
          productId: getCommonProductId(changes.product),
          productCollectionId: changes.productCollections[0]?.id,
          isAddition: !changes.isRemoving,
        },
      });
    }
  };

  let toastProps: Pick<ToastProps, 'title' | 'subtitle' | 'image' | 'isRestricted'>;

  // eslint-disable-next-line default-case
  switch (changes?.type) {
    case 'productInCollection': {
      const isOneCollection = changes.productCollections.length === 1;
      const title = isOneCollection
        ? intl.formatMessage(changes.isRemoving ? messages.removeProduct : messages.addProduct)
        : intl.formatMessage(
            changes.isRemoving ? messages.removeProductMulti : messages.addProductMulti,
            {collectionsCount: changes.productCollections.length},
          );

      toastProps = {
        title,
        image: getCommonProductImageBundle(changes.product),
        subtitle: isOneCollection && changes.productCollections[0]!.title,
        isRestricted: getCommonProductRestricted(changes.product, changes.isUserAdult),
      };
      break;
    }

    case 'moveProductsToCollection': {
      const isOneCollection = changes.productCollections.length === 1;
      const title = isOneCollection
        ? intl.formatMessage(changes.isRemoving ? messages.removeProducts : messages.moveProducts, {
            collectionName: changes.productCollections[0]!.title,
            productsCount: changes.products.length,
          })
        : intl.formatMessage(messages.moveProductsMulti, {
            collectionsCount: changes.productCollections.length,
            productsCount: changes.products.length,
          });

      toastProps = {title};

      break;
    }
  }

  if (toastProps) {
    openToast((rendererProps) => (
      // eslint-disable-next-line react/jsx-no-bind
      <Toast {...rendererProps} {...toastProps} onClick={handleClick} />
    ));
  }
}

export function useOpenProductCollectionToast(): OpenProductCollectionToastFn {
  const popupManager = usePopupManager();
  const openToast = useOpenToast();
  const analytics = useAnalytics();
  const intl = useIntl();

  return (changes) =>
    openProductCollectionToast({
      popupManager,
      analytics,
      openToast,
      intl,
      changes,
    });
}
