import PropTypes from 'prop-types';

export const InputType = {
  CHECKBOX: 'checkbox',
  DATE: 'date',
  EMAIL: 'email',
  NUMBER: 'number',
  PHONE: 'phone',
  SELECTOR: 'selector',
  TEXT: 'text',
};

const PassportPartName = {
  DOCUMENT_TYPE: 'documentType',
  PASSPORT_FIRST_NAME: 'passportRU.firstName',
  PASSPORT_LAST_NAME: 'passportRU.lastName',
  PASSPORT_MIDDLE_NAME: 'passportRU.middleName',
  PASSPORT_NUMBER: 'passportRU.passportNumber',
  PASSPORT_ISSUED_DATE: 'passportRU.passportIssueDate',
  PASSPORT_BIRTH_DATE: 'passportRU.birthDate',
  PASSPORT_TAX_NUMBER: 'passportRU.taxNumber',
  PASSPORT_PHONE: 'passportRU.phone',
  PASSPORT_EMAIL: 'passportRU.email',
  PERSIST: 'persist',
};

export const PartName = {
  BLOCK: 'block',
  BUILDING: 'building',
  CITY: 'city',
  COUNTRY: 'country',
  CPF: 'cpf',
  EMAIL: 'email',
  FIRST_NAME: 'firstName',
  FLAT: 'flat',
  HOUSE: 'house',
  LAST_NAME: 'lastName',
  PHONE: 'phone',
  STATE: 'state',
  STREET: 'street',
  STREET1: 'street1',
  STREET2: 'street2',
  TAX_NUMBER: 'taxNumber',
  ZIP: 'zip',
  ...PassportPartName,
};

export const InputTypeList = Object.values(InputType);
export const PartNameList = Object.values(PartName);

export const PartDisabledAlert = PropTypes.shape({
  message: PropTypes.string,
});

export const Part = PropTypes.shape({
  disabled: PropTypes.bool,
  disabledAlert: PartDisabledAlert,
  inputType: PropTypes.oneOf(InputTypeList).isRequired,
  max: PropTypes.number,
  maxLength: PropTypes.number,
  min: PropTypes.number,
  minLength: PropTypes.number,
  name: PropTypes.string.isRequired,
  newLine: PropTypes.bool,
  title: PropTypes.string,
  type: PropTypes.string,
});

// eslint-disable-next-line import/no-default-export
export default PropTypes.shape({
  id: PropTypes.string.isRequired,
  parts: PropTypes.arrayOf(Part).isRequired,
});
