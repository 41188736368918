import {useAnalytics} from 'hooks/useAnalytics';
import {useAppLinkHandler} from 'hooks/useAppLink';
import {useCallback} from 'react';

export function useBadgeHandler(
  deepUrl: string,
  event: string,
  category: string,
): ReturnType<typeof useAppLinkHandler> {
  const analytics = useAnalytics();
  const onClick = useCallback(
    () =>
      analytics.dataLayer({
        event,
        category,
        deepUrl,
      }),
    [analytics, event, category, deepUrl],
  );
  return useAppLinkHandler({target: deepUrl, onClick});
}
