import {getDeviceVars, isBot} from 'store/modules/preferences';
import config from 'config';
import {
  enhancer,
  getCategory,
  getCategoryView,
  getError,
  getFiltersAndSortings,
  getNextPageToken,
  getProducts,
  getSearchEngine,
  hasCategories,
  isLoaded,
  isLoading,
  isOutdated,
  isEmpty,
  needToUseCategories,
  search,
  setSearchEngine,
  hasCroppedFilters,
  getSearchId,
  retrieveSubCategory,
  createCategoryGraph,
  getSearchResultHelp,
} from './utils';

export const LOAD = 'search/products/LOAD';
export const LOAD_SUCCESS = 'search/products/LOAD_SUCCESS';
export const LOAD_FAIL = 'search/products/LOAD_FAIL';
export const SET_ENGINE = 'search/products/SET_SEARCH_ENGINE';
export const UPDATE_SEARCH_SESSION = 'search/products/UPDATE_SEARCH_SESSION';

const types = {
  LOAD,
  LOAD_SUCCESS,
  LOAD_FAIL,
  SET_ENGINE,
  UPDATE_SEARCH_SESSION,
};

export const LOAD_FILTERS = 'search/products/LOAD_FILTERS';
export const LOAD_FILTERS_SUCCESS = 'search/products/LOAD_FILTERS_SUCCESS';
export const LOAD_FILTERS_FAIL = 'search/products/LOAD_FILTERS_FAIL';

function reducer(state, action) {
  switch (action.type) {
    case LOAD_FILTERS_SUCCESS: {
      const isSameRequest = state.id === action.id;
      if (!isSameRequest) return state;

      const nextData = {
        ...state.data,
        availableFilters: action.result.availableFilters,
        availableSorting: action.result.availableSorting,
      };

      return {
        ...state,
        subCategory: retrieveSubCategory(nextData),
        categoryGraph: createCategoryGraph(
          nextData,
          action.result.language,
          action.result.addSiblingsToLeafCategory,
        ),
        data: nextData,
      };
    }
    default:
      return state;
  }
}

export default enhancer(types, reducer);

function getState(globalState) {
  return globalState.search.products;
}

export function hasSearchCroppedFilters(globalState, searchRequest) {
  return hasCroppedFilters(getState(globalState), searchRequest);
}

export function hasSearchCategories(globalState, params) {
  return hasCategories(getState(globalState), params);
}

export function needToUseSearchCategories(globalState) {
  return needToUseCategories(getState(globalState));
}

export function getSearchCategory(globalState, params, id) {
  return getCategory(getState(globalState), params, id);
}

export function getSearchCategoryView(globalState, params, id) {
  return getCategoryView(getState(globalState), params, id);
}

export function isSearchProductsLoaded(globalState, searchRequest, pageToken) {
  return isLoaded(getState(globalState), searchRequest, pageToken);
}

export function isSearchProductsOutdated(globalState, searchRequest) {
  return isOutdated(getState(globalState), searchRequest);
}

export function isSearchProductsLoading(globalState, searchRequest) {
  return isLoading(getState(globalState), searchRequest);
}

export function getSearchProductsError(globalState, searchRequest) {
  return getError(getState(globalState), searchRequest);
}

export function getSearchProducts(globalState, searchRequest) {
  return getProducts(getState(globalState), searchRequest);
}

export function getSearchState(globalState, searchRequest) {
  return isLoaded(getState(globalState), searchRequest) ? getState(globalState).data : undefined;
}

export function getSearchHeader(globalState, searchRequest) {
  const searchState = getSearchState(globalState, searchRequest);

  return searchState ? searchState.header : undefined;
}

export function getSearchHeaderWasLoadedAtLeastOnce(globalState, searchRequest) {
  const searchState = getSearchState(globalState, searchRequest);

  return searchState ? searchState.headerWasLoadedAtLeastOnce : undefined;
}

export function getSearchHeaderItems(globalState, searchRequest) {
  return isLoaded(getState(globalState), searchRequest)
    ? getState(globalState).data.headerItems
    : undefined;
}

export function getSubCategory(globalState) {
  return getState(globalState).subCategory;
}

export function getSearchSession(globalState) {
  return getState(globalState).searchSession;
}

export function isSearchProductsEmpty(globalState, searchRequest) {
  return isEmpty(getState(globalState), searchRequest);
}

export function getSearchProductsNextPageToken(globalState, searchRequest) {
  return getNextPageToken(getState(globalState), searchRequest);
}

export function getSearchProductsSearchResultHelp(globalState, searchRequest) {
  return getSearchResultHelp(getState(globalState), searchRequest);
}

export function getSearchFiltersAndSortings(globalState, searchRequest) {
  return getFiltersAndSortings(getState(globalState), searchRequest);
}

export function getSearchProductsEngine(globalState) {
  return getSearchEngine(getState(globalState));
}

export function setSearchProductsEngine(engine) {
  return setSearchEngine(SET_ENGINE, engine);
}

export function searchProducts(
  searchRequest,
  pageToken = null,
  count = null,
  searchSession = {},
  endpoint = '/search/content',
  otherParams = {},
) {
  return search(
    endpoint,
    [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    searchRequest,
    pageToken,
    count,
    undefined,
    searchSession,
    otherParams,
  );
}

export function updateSearchSession(searchSession) {
  return {
    type: UPDATE_SEARCH_SESSION,
    payload: searchSession,
  };
}

export function loadSearchFilters(searchRequest) {
  const id = getSearchId(searchRequest);

  return {
    types: [LOAD_FILTERS, LOAD_FILTERS_SUCCESS, LOAD_FILTERS_FAIL],
    id,
    promise: (client) =>
      client.api
        .post('/search/products/availableFilters', {body: searchRequest, retry: true})
        .then(({language, body: {payload}}) => ({
          ...payload,
          language,
          addSiblingsToLeafCategory: client.device.getDeviceVar(
            'searchAddSiblingsToLeafCategoryFilters',
          ),
        })),
  };
}

export function getSearchFirstPageItemsCount({
  store,
  defaultItemsCount = config.productsPageSize,
  botItemsCount = undefined,
  page = undefined,
}) {
  const {trendAndSearchPageFirstPageItemsCount} = getDeviceVars(store.getState());

  if (isBot(store.getState())) {
    return page ? botItemsCount : defaultItemsCount;
  }
  return trendAndSearchPageFirstPageItemsCount || defaultItemsCount;
}

export function loadNextSearchPageIfNeeded({
  initialAfterSsr,
  store,
  searchRequest,
  defaultItemsCount = config.productsPageSize,
  endpoint,
  otherParams = {},
}) {
  // next page already loaded from ssr
  if (initialAfterSsr) {
    return undefined;
  }

  const itemsCount = getSearchFirstPageItemsCount({store, defaultItemsCount});

  if (!isBot(store.getState())) {
    const page = getSearchProductsNextPageToken(store.getState(), searchRequest);
    const count = defaultItemsCount - itemsCount;

    if (page && count > 0) {
      return store.dispatch(
        searchProducts(searchRequest, page, count, undefined, endpoint, otherParams),
      );
    }
  }

  return undefined;
}

export {buildSearchRequest} from './utils';
