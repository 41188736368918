import {DEFAULT_FILTER} from 'components/ClientProduct/Reviews/constants';
import styles from 'components/ClientProduct/Reviews/Controls/index.scss';
import {createFilterUrl} from 'components/ClientProduct/Reviews/utils';
import React from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';
import {Link} from 'react-router-dom';
import {SocialPost} from 'types/SocialPost';

const messages = defineMessages({
  prevPage: {
    description: 'Кнопка перехода на предыдущую страницу комментариев на странице продукта',
    defaultMessage: 'Previous',
  },
  nextPage: {
    description: 'Кнопка перехода на следующую страницу комментариев на странице продукта',
    defaultMessage: 'Next',
  },
  reset: {
    description: 'Кнопка сброса фильтров в блоке комментариев на странице продукта',
    defaultMessage: 'Reset filters',
  },
});

type Props = {
  href: string;
  loading?: boolean;
  nextPage?: string;
  onClick?: () => void;
  page?: string;
  prevPage?: string;
  reviews?: SocialPost[];
  selectedFilterId?: string;
};

export function Controls({
  href,
  loading,
  nextPage,
  onClick,
  page,
  prevPage,
  reviews,
  selectedFilterId,
}: Props): React.ReactElement | null {
  let resetUrl;
  let nextUrl;
  let prevUrl;

  if (!loading && !reviews?.length && (page || selectedFilterId !== DEFAULT_FILTER)) {
    resetUrl = createFilterUrl(href, {filterId: '', page: ''});
  } else {
    if (nextPage) {
      nextUrl = createFilterUrl(href, {page: nextPage});
    }

    if (prevPage || page) {
      prevUrl = createFilterUrl(href, {page: prevPage || ''});
    }
  }

  if (!resetUrl && !prevUrl && !nextUrl) {
    return null;
  }

  return (
    <div className={styles.controls}>
      {resetUrl && (
        <Link className={styles.control} onClick={onClick} to={resetUrl}>
          <FormattedMessage {...messages.reset} />
        </Link>
      )}
      {prevUrl && (
        <Link className={styles.control} onClick={onClick} to={prevUrl}>
          <FormattedMessage {...messages.prevPage} />
        </Link>
      )}
      {nextUrl && (
        <Link className={styles.control} onClick={onClick} to={nextUrl}>
          <FormattedMessage {...messages.nextPage} />
        </Link>
      )}
    </div>
  );
}
