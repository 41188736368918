import {EmailConfirmationBanner} from 'components/EmailConfirmationBanner';
import React from 'react';
import {EmailConfirmation as EmailConfirmationType} from 'types/EmailConfirmation';

import styles from './styles.scss';

type Props = {
  banner?: EmailConfirmationType;
};

export function EmailConfirmation({banner}: Props): JSX.Element | null {
  return banner ? (
    <div className={styles.banner}>
      <EmailConfirmationBanner banner={banner} />
    </div>
  ) : null;
}
