import {jsonToHyperText} from 'components/HyperText/Json';
import {HyperText} from 'components/HyperText';
import PropTypes from 'prop-types';
import React from 'react';
import Sticky from 'components/Sticky';
import {Content as LegalityContentShape} from 'shapes/Legality';
import styles from './index.scss';

export default class Content extends React.Component {
  static propTypes = {
    backOnConsentStyle: PropTypes.bool,
    content: LegalityContentShape.isRequired,
    onButtonClick: PropTypes.func.isRequired,
    marginTop: PropTypes.bool,
    standalone: PropTypes.bool,
    type: PropTypes.string.isRequired,
  };

  static defaultProps = {
    backOnConsentStyle: false,
    marginTop: false,
    standalone: false,
  };

  constructor(props) {
    super(props);
    this.sticky = null;
    this.handleNegativeButton = this.handleButton.bind(this, false);
    this.handlePositiveButton = this.handleButton.bind(this, true);
  }

  componentDidUpdate() {
    if (this.sticky) {
      this.sticky.update(true);
    }
  }

  handleButton(positive) {
    const {negativeButton, positiveButton} = this.props.content;
    const button = positive ? positiveButton : negativeButton;
    return this.props.onButtonClick(button.id, positive);
  }

  renderTextContent(text, inverseColor) {
    if (typeof text === 'string') {
      return text;
    }
    return (
      <HyperText inverseColor={inverseColor}>
        {jsonToHyperText(this.props.type, text, {target: '_blank'})}
      </HyperText>
    );
  }

  renderText(text) {
    if (!text) {
      return null;
    }

    return <div className={styles.text}>{this.renderTextContent(text)}</div>;
  }

  renderButton(className, button, onClick) {
    if (button) {
      return (
        <div role="button" tabIndex={0} className={className} onClick={onClick}>
          {button.text}
        </div>
      );
    }
    return null;
  }

  render() {
    const {backOnConsentStyle, content, marginTop, standalone, type} = this.props;

    const negativeButton = this.renderButton(
      styles.negativeButton,
      content.negativeButton,
      this.handleNegativeButton,
    );
    const positiveButton = this.renderButton(
      styles.positiveButton,
      content.positiveButton,
      this.handlePositiveButton,
    );

    const className = [
      styles[type] || '',
      marginTop ? styles.marginTop : '',
      backOnConsentStyle ? styles.backOnConsent : styles.defaultConsent,
    ];

    return (
      <div className={className.join(' ')}>
        {content.title ? <h2 className={styles.header}>{content.title}</h2> : null}
        {this.renderText(content.text)}
        {negativeButton || positiveButton ? (
          <div className={styles.buttons}>
            {negativeButton}
            {standalone && positiveButton ? (
              <Sticky
                ref={(sticky) => {
                  this.sticky = sticky;
                }}
                inverse
                fixedClassName={styles.sticky}
              >
                {positiveButton}
              </Sticky>
            ) : (
              positiveButton
            )}
          </div>
        ) : null}
      </div>
    );
  }
}
