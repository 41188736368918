import {enhanceLoadedTime} from 'store/utils/enhancers';
import {CheckoutSessionItem} from 'types/CheckoutSession';

export function enhanceCheckoutSessionItem(item: CheckoutSessionItem): CheckoutSessionItem {
  const result = item;

  // For patching
  enhanceLoadedTime(result);

  return result;
}
