import React, {useCallback, useEffect, useRef, useState} from 'react';
import {animate, ANIMATION_MAX_PROGRESS, cancelAnimation} from 'utils/animation';
import {Hammer} from 'utils/hammer';
import {SUPPORTS_SCROLL_BEHAVIOR} from 'utils/scrollBehavior';

import {ROUND_ERROR} from './utils';

export enum AlignmentSide {
  LEFT = 'left',
  RIGHT = 'right',
}

type UpdateControlsVisibility = () => void;

export function useControlsVisibility(
  viewRef: React.RefObject<HTMLElement>,
  enabled: boolean,
  loop: boolean,
): [boolean, boolean, UpdateControlsVisibility] {
  const loopEnabled = useRef<boolean>(loop);
  const controlsEnabled = useRef<boolean>(enabled);
  const [prevVisible, setPrevVisible] = useState<boolean>(false);
  const [nextVisible, setNextVisible] = useState<boolean>(false);

  controlsEnabled.current = enabled;

  const updateVisibility = useCallback<UpdateControlsVisibility>(() => {
    if (!controlsEnabled.current) {
      return;
    }

    const view = viewRef.current;

    if (view) {
      const leftVisible = view.scrollLeft > ROUND_ERROR;
      const rightVisible = view.scrollWidth - view.scrollLeft > view.clientWidth + ROUND_ERROR;

      if (loopEnabled.current) {
        const bothVisible = leftVisible || rightVisible;

        setPrevVisible(bothVisible);
        setNextVisible(bothVisible);
      } else {
        setPrevVisible(leftVisible);
        setNextVisible(rightVisible);
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return [prevVisible, nextVisible, updateVisibility];
}

type ScrollTo = (scrollLeft: number) => void;

type ScrollIntoView = (target: HTMLElement, align?: AlignmentSide) => void;

type IsScrollBusy = () => boolean;

export type ScrollHook = [ScrollIntoView, ScrollTo, IsScrollBusy];

const setScrollSnapType = (element: HTMLElement | null, value: string): void => {
  if (element) {
    // ts doesn't know about scrollSnapType :(
    (element.style as unknown as Record<string, string>).scrollSnapType = value;
  }
};

// Данный хук должен гарантировать неизменность возвращаемой функции
export function useScroll(viewRef: React.RefObject<HTMLElement>): ScrollHook {
  const animationId = useRef<number>(0);
  const busy = useRef<boolean>(false);
  const [scrollSnapRelease, setScrollSnapRelease] = useState<() => void | undefined>();
  const view = viewRef.current;

  useEffect(() => {
    if (view && scrollSnapRelease) {
      view.addEventListener('scroll', scrollSnapRelease);
      return () => view.removeEventListener('scroll', scrollSnapRelease);
    }
    return undefined;
  }, [scrollSnapRelease, view]);

  const cancelScrollAnimation = useCallback(() => {
    if (viewRef.current) {
      setScrollSnapType(viewRef.current, '');
    }
    setScrollSnapRelease(undefined);
    cancelAnimation(animationId.current);
  }, [viewRef, animationId]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => (): void => cancelScrollAnimation(), []);

  const isBusy: IsScrollBusy = useCallback(() => busy.current, []);

  const scrollTo = useCallback<ScrollTo>((targetScrollLeft) => {
    if (!viewRef.current || isBusy()) {
      return;
    }

    const view = viewRef.current;
    if (SUPPORTS_SCROLL_BEHAVIOR) {
      view.scroll({
        left: targetScrollLeft,
        behavior: 'smooth',
      });
      return;
    }

    const from = view.scrollLeft;
    const delta = targetScrollLeft - from;

    busy.current = true;

    cancelScrollAnimation();
    setScrollSnapType(view, 'none');

    animationId.current = animate((progress) => {
      view.scrollLeft = from + delta * progress;
      if (progress === ANIMATION_MAX_PROGRESS) {
        // need to use timeout to stop scroll reset in safari
        setTimeout(() => {
          busy.current = false;
          setScrollSnapRelease(() => () => {
            setScrollSnapType(view, '');
            setScrollSnapRelease(undefined);
          });
        }, 30);
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const scrollIntoView = useCallback((target: HTMLElement, align = AlignmentSide.LEFT) => {
    if (!viewRef.current || isBusy()) {
      return;
    }

    const view = viewRef.current;
    const contentPos = view.getBoundingClientRect()[align];
    const targetPos = target.getBoundingClientRect()[align];

    scrollTo(view.scrollLeft + targetPos - contentPos);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return [scrollIntoView, scrollTo, isBusy];
}

const SWIPE_VELOCITY_THRESHOLD = 0.8;
const MIN_DISTANCE_TO_SCROLL = 0.25;

type SwipeHandler = () => void;

export function useSwipe(
  viewRef: React.RefObject<HTMLElement>,
  enabled: boolean,
  onLeft: SwipeHandler,
  onRight: SwipeHandler,
  scrollTo: ScrollTo,
  isScrollBusy: IsScrollBusy,
): void {
  useEffect(() => {
    if (!viewRef.current || !enabled) {
      return undefined;
    }

    const view = viewRef.current;

    let scrollLeft: number;

    const handleStart = (): void => {
      scrollLeft = view.scrollLeft;
    };

    const handleMove = ({deltaX}: HammerInput): void => {
      view.scrollLeft = scrollLeft - deltaX;
    };

    const handleEnd = ({deltaX, velocityX}: HammerInput): void => {
      if (
        deltaX === 0 ||
        view.scrollLeft === 0 ||
        view.scrollLeft + view.clientWidth === view.scrollWidth
      ) {
        return;
      }

      const minDistance = view.clientWidth * MIN_DISTANCE_TO_SCROLL;

      if (velocityX >= SWIPE_VELOCITY_THRESHOLD || deltaX > minDistance) {
        onLeft();
      } else if (velocityX <= -SWIPE_VELOCITY_THRESHOLD || deltaX < -minDistance) {
        onRight();
      } else {
        scrollTo(scrollLeft);
      }
    };

    const hammer = new Hammer(view);
    hammer.on('panstart', handleStart);
    hammer.on('panmove', handleMove);
    hammer.on('panend', handleEnd);
    hammer.on('pancancel', handleEnd);

    return (): void => {
      hammer.destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewRef.current, enabled, onLeft, onRight, scrollTo, isScrollBusy]);
}
