import classnames from 'classnames/bind';
import {useIsBreakpointMobile} from 'hooks/useIsBreakpointMobile';
import React from 'react';

import CoolbeIcon from './coolbe.jsx.svg';
import CoolbeMobileIcon from './coolbeMobile.jsx.svg';
import styles from './index.scss';

const cn = classnames.bind(styles);

type Props = {
  color?: string;
  isSmall?: boolean;
  title?: string;
};

export const Coolbe: React.FC<Props> = ({isSmall = false, color = '#000', title}: Props) => {
  const isMobile = useIsBreakpointMobile('sm');

  const props = {alt: title, style: {color}, className: cn('logo', 'coolbe', {isSmall})};
  return !isMobile ? <CoolbeIcon {...props} /> : <CoolbeMobileIcon {...props} />;
};
