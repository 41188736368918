const SET_COUPON_VIEW = 'couponViews/SET_COUPON_VIEW';
const SYNC_COUPON_VIEWS = 'couponViews/SYNC_COUPON_VIEWS';

export const initialState = {};
const STORAGE_KEY = 'lastSeenCouponTime';
const STORAGE_MAX_SIZE = 10;

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SYNC_COUPON_VIEWS:
      return action.views;
    case SET_COUPON_VIEW:
      return {
        ...state,
        [action.userId]: action.lastSeenTimeMs,
      };
    default:
      return state;
  }
}

export function setCouponViewTimeForUser(userId, lastSeenTimeMs) {
  return (store, client) => {
    const storedData = client.storages.local.getItem(STORAGE_KEY);
    let data = Array.isArray(storedData) ? storedData : [];
    data = data.filter((item) => item[0] !== userId);

    data.unshift([userId, lastSeenTimeMs]);
    data.length = Math.min(data.length, STORAGE_MAX_SIZE);
    client.storages.local.setItem(STORAGE_KEY, data);

    return store.dispatch({
      type: SET_COUPON_VIEW,
      userId,
      lastSeenTimeMs,
    });
  };
}

export function syncCouponViews() {
  return (store, client) => {
    let views = {};
    const storedData = client.storages.local.getItem(STORAGE_KEY);
    if (Array.isArray(storedData) && Object.keys(storedData).length > 0) {
      views = {};
      for (let i = 0; i < storedData.length; i++) {
        const [userId, lastSeenTime] = storedData[i];
        views[userId] = lastSeenTime;
      }
    }

    return store.dispatch({
      type: SYNC_COUPON_VIEWS,
      views,
    });
  };
}

export function getLastCouponViewTimeForCurrentUser(globalState) {
  return globalState.couponViews[globalState.auth.user.id];
}
