import {createLocator} from 'create-locator';
import React from 'react';
import classnames from 'classnames/bind';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {limitCountValue} from 'utils/string';
import {VisuallyHidden} from 'components/VisuallyHidden';
import styles from './index.scss';

const cn = classnames.bind(styles);

export function HeaderButtonContainer({children}) {
  return <div className={styles.container}>{children}</div>;
}

HeaderButtonContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export const HeaderButton = React.forwardRef(function HeaderButton(props, ref) {
  const {children, icon, indicatorCount, indicatorNumeral, onClick, text, to, buttonAttributes} =
    props;
  const locator = createLocator(props);
  const inner = (
    <div className={styles.inner}>
      <div className={styles.text}>{text}</div>
      <div className={styles.headerButtonIcon}>
        {icon}
        {Boolean(indicatorCount) && (
          <span className={styles.indicator} {...locator.indicator()}>
            {limitCountValue(indicatorCount, 99)}
            {indicatorNumeral && <VisuallyHidden>{indicatorNumeral}</VisuallyHidden>}
          </span>
        )}
      </div>
    </div>
  );

  if (to) {
    return (
      <>
        <Link ref={ref} to={to} onClick={onClick} className={cn('link', 'button')} {...locator()}>
          {inner}
        </Link>
        {children}
      </>
    );
  }

  return (
    <>
      <button
        type="button"
        ref={ref}
        className={styles.button}
        onClick={onClick}
        {...buttonAttributes}
        {...locator()}
      >
        {inner}
      </button>
      {children}
    </>
  );
});

HeaderButton.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.node.isRequired,
  indicatorCount: PropTypes.number,
  indicatorNumeral: PropTypes.string,
  onClick: PropTypes.func,
  text: PropTypes.string.isRequired,
  to: PropTypes.string,
  'data-testid': PropTypes.string,
  buttonAttributes: PropTypes.object,
};

HeaderButton.defaultProps = {
  children: null,
  indicatorCount: 0,
  indicatorNumeral: null,
  onClick: null,
  to: '',
  'data-testid': '',
  buttonAttributes: {},
};
