import {matchMimeType} from 'utils/file';

import {MediaFilter} from '../types';

export const isMimeTypeSupported = (
  {supportedMimeTypes}: MediaFilter,
  actualMimeType: string,
): boolean => {
  return supportedMimeTypes.some((supportedMimeType) =>
    matchMimeType(actualMimeType, supportedMimeType),
  );
};
