import classnames from 'classnames';
import styles from 'components/ClientProduct/Reviews/Filters/index.scss';
import {Icon} from 'components/UIKit/Icon';
import {usePicker} from 'components/UIKit/Picker';
import {useAnalytics} from 'hooks/useAnalytics';
import React, {useCallback, useMemo, useState} from 'react';
import {useHistory} from 'react-router';
import {ProductReviewSorting} from 'types/ProductReviews';
import {getQueryData} from 'utils/url';

import {SORT_PARAM} from '../constants';
import {createFilterUrl} from '../utils';

type Props = {
  sorting: ProductReviewSorting;
  href: string;
  productId?: string;
};

export function Sorting({sorting, href, productId}: Props): React.ReactElement | 0 {
  const history = useHistory();

  const {selectedItemId: defaultSortingId} = sorting;

  const initialValue = useMemo(() => {
    const query = getQueryData(href);
    const fromQuery = query[SORT_PARAM] as string;

    return fromQuery ?? defaultSortingId ?? sorting.items[0]!.id;
  }, [defaultSortingId, sorting.items, href]);

  const [selectedItem, setSelectedItem] = useState<string | number>(initialValue);

  const analytics = useAnalytics();

  const handleChange = useCallback(
    (value: string | number) => {
      setSelectedItem(value);
      if (productId) {
        analytics.sendEvent({
          type: 'reviewsSortPopupButtonClick',
          payload: {
            productId,
            sortType: String(value),
          },
        });
      }
      history.push(
        createFilterUrl(href, {
          sort: value !== defaultSortingId ? String(value) : '',
        }),
      );
    },
    [history, defaultSortingId, setSelectedItem, href, analytics, productId],
  );

  const caption = useMemo(
    () => sorting.items.find((item) => item.id === selectedItem)!.title,
    [selectedItem, sorting.items],
  );

  const [openMs, setOpenMs] = useState<number>(0);

  const handlePickerOpen = useCallback(() => {
    if (productId) {
      setOpenMs(Date.now());
      analytics.sendEvent({
        type: 'reviewsSortPopupOpen',
        payload: {
          productId,
        },
      });
    }
  }, [productId, setOpenMs, analytics]);

  const handlePickerClose = useCallback(() => {
    if (productId) {
      const sinceOpenMs = Date.now() - openMs;
      setOpenMs(0);
      analytics.sendEvent({
        type: 'reviewsSortPopupClose',
        payload: {
          productId,
          sinceOpenMs,
        },
      });
    }
  }, [productId, openMs, setOpenMs, analytics]);

  const {buttonProps, pickerElement, pickerProps} = usePicker({
    options: sorting.items.map(({id, title}) => ({id, name: title})),
    value: selectedItem,
    onChange: handleChange,
    onOpen: handlePickerOpen,
    onClose: handlePickerClose,
    bottomSheetTitle: <>Sort by</>,
  });

  if (!sorting?.items) {
    return 0;
  }

  return (
    <>
      <button
        type="button"
        className={classnames(styles.button, {
          [styles.selected!]: selectedItem !== (defaultSortingId ?? initialValue),
        })}
        {...buttonProps}
      >
        <span className={styles.sortingSelector}>
          {caption}
          <span
            className={classnames(styles.sortingChevron, {
              [styles.isOpen!]: pickerProps.dropdownProps.isOpen,
            })}
          >
            <Icon name="chevron-down-linear-16" type="mono" width="16px" height="16px" />
          </span>
        </span>
      </button>
      {pickerElement}
    </>
  );
}
