import React from 'react';
import {leftPad} from 'utils/string';

import styles from './index.scss';

type Props = {
  value: number;
  last?: boolean;
  separator?: string;
  plain?: boolean;
};

export const NumberGroup: React.FC<Props> = (props: Props) => {
  const {value, last = false, separator = ':', plain} = props;
  const string = leftPad(value, 2);

  if (plain) {
    return (
      <>
        {string}
        {last || <span className={styles.separator}>{separator}</span>}
      </>
    );
  }
  return (
    <span className={styles.numberGroup}>
      {[...string].map((number, index) => (
        /* eslint-disable-next-line react/no-array-index-key */
        <span key={index} className={styles.number}>
          {number}
        </span>
      ))}
      {last || <span className={styles.separator}>{separator}</span>}
    </span>
  );
};
