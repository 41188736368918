import React from 'react';
import {FormattedMessage} from 'react-intl';

export const AllInThisCategory: React.FC<Record<string, unknown>> = () => {
  return (
    <FormattedMessage
      description="Ссылка «Все в этой категории» в многоуровневом каталоге"
      defaultMessage="Everything in this category"
    />
  );
};
