import {ScopeConfig} from 'helpers/ApiClient/Scope/ScopeConfig';
import {defineMessages, MessageDescriptor} from 'react-intl';
import {isApiError} from 'types/Error';
import {capitalizeFirstLetter} from 'utils/string';

import {getErrorType} from './getErrorType';
import {Type} from './Type';
import {ExtendedErrorObject, unwrapErrorObject} from './unwrapErrorObject';

const messages = defineMessages({
  authError: {
    description: 'Server error message',
    defaultMessage: 'Login error.',
  },
  alreadySignedIn: {
    description: '"User already signed in" message',
    defaultMessage: "You're already logged in.",
  },
  badPaymentMethod: {
    description: 'Payment error message',
    defaultMessage:
      "You can't complete this purchase with the selected method. Please choose another payment method.",
  },
  invalidToken: {
    description: 'Server error message',
    defaultMessage: 'Please try reloading the page.',
  },
  unauthorized: {
    description: 'Server error message',
    defaultMessage: "You're not logged in. Please log in to continue.",
  },
  wrongClientType: {
    description: 'Server error message',
    defaultMessage: 'Authentication error (client type).',
  },
  tokenExpired: {
    description: 'Server error message',
    defaultMessage: 'Please try reloading the page.',
  },
  forbidden: {
    description: 'Server error message',
    defaultMessage: 'Not allowed when logged out. Please log in and try again.',
  },
  cartEmpty: {
    description: 'Server error message',
    defaultMessage: 'Your cart is empty.',
  },
  variantDisabled: {
    description: 'Server error message',
    defaultMessage: 'Some products are not available for purchase.',
  },
  forbiddenInRegion: {
    description: 'Server error message',
    defaultMessage: 'Unfortunately, you cannot buy these items from your location',
  },
  forbiddenInRegionWithSuggest: {
    description: 'Server error message',
    defaultMessage:
      "The products you've selected cannot be delivered to the specified address. Please select different products if you want them delivered to your most recent address.",
  },
  orderAlreadyPaid: {
    description: 'Server error message',
    defaultMessage: 'This order has already been paid for.',
  },
  orderCouponsExpired: {
    description: 'Server error message',
    defaultMessage: 'Sorry, the coupon has expired.',
  },
  orderInvalidAddress: {
    description: 'Server error message',
    defaultMessage: 'Unfortunately, we cannot deliver your order to this address.',
  },
  orderPricesChanged: {
    description: 'Server error message',
    defaultMessage: 'Some prices have changed. Please review your order and try again.',
  },
  invalidCardNumber: {
    description: 'Server error message',
    defaultMessage: 'Invalid card number',
  },
  cardExpired: {
    description: 'Server error message',
    defaultMessage: 'Card or other information linked to profile has expired',
  },
  cartNotLoaded: {
    description: 'Server error message',
    defaultMessage: 'An error occurred while loading your cart.',
  },
  cartSessionNotLoaded: {
    description: 'Server error message',
    defaultMessage: 'An error occurred while loading payment data.',
  },
  cartSessionTerminated: {
    description: 'Server error message',
    defaultMessage: 'Order checkout failed, please return to your cart and try again.',
  },
  cardNotServed: {
    description: 'Server error message',
    defaultMessage: 'This card is not accepted by our payment provider',
  },
  cardCannotPay: {
    description: 'Can not pay error',
    defaultMessage: 'Cannot buy selected items. Please review your order and try again.',
  },
  cartInvalidQuantity: {
    description: 'Server error message',
    defaultMessage: 'Invalid number of items in cart.',
  },
  cartInvalidQuantityUpdate: {
    description: 'Server error message',
    defaultMessage: 'Not enough items in stock',
  },
  cartUnauthorized: {
    description: 'Server error message',
    defaultMessage: 'You need to log in to continue with your purchase.',
  },
  cartUnknownDeliveryType: {
    description: 'Client error message',
    defaultMessage: "Unfortunately, the delivery option you've chosen is not supported.",
  },
  cartNoSelectedAddress: {
    description: 'Server error message',
    defaultMessage: 'You need to enter a delivery address to continue with your purchase.',
  },
  cartNoSelectedItems: {
    description: 'Client error message',
    defaultMessage: 'Select one or more items in your cart to continue with your purchase.',
  },
  deliveryPointUnavailableInCountry: {
    description: 'Delivery points are unavailable in country',
    defaultMessage: 'Pickup points are unavailable for the selected country',
  },
  deliveryPointAddressSearchEmptyResult: {
    description: 'Address not found',
    defaultMessage: 'Sorry, your search returned no results',
  },
  insufficientFunds: {
    description: 'Server error message',
    defaultMessage: 'Insufficient funds',
  },
  paymentFailure: {
    description: 'Server error message',
    defaultMessage: 'Unfortunately, your payment was unsuccessful.',
  },
  canNotReviewOrder: {
    description: 'Server error message',
    defaultMessage: 'You cannot review this order now. Please try again later.',
  },
  invalidFormData: {
    description: 'Server error message',
    defaultMessage: 'Some fields are incorrect.',
  },
  insufficientPointBalance: {
    description: 'Cart error message',
    defaultMessage: 'Not enough points to pay for this order.',
  },
  internal: {
    description: 'Default error message',
    defaultMessage: 'Oops! Something went wrong.',
  },
  legalityNeedConsent: {
    description: 'Legality consent required error',
    defaultMessage:
      'We can not process this information because you did not accept our Terms of Use',
  },
  tooManyRequests: {
    description: 'Too many requests error',
    defaultMessage: "You've made too many attempts, try again later.",
  },
  noItemsToBuy: {
    description: 'No items to buy in cart',
    defaultMessage: "There aren't any items in your shopping cart.",
  },
  unknownPromocodeType: {
    description: 'Unknown promocode reward type',
    defaultMessage:
      'This promo code is not supported on the website. Try using it in the {companyName} mobile app.',
  },
  userChanged: {
    description: 'Error when user was unexpectadly changed (from another tab, probably)',
    defaultMessage: "You're logged in as another user. Please refresh the page or log in again.",
  },
  invalidAmount: {
    description: 'User entered invalid price value',
    defaultMessage: 'Please enter a valid price.',
  },
  invalidUrl: {
    description: 'User entered invalid URL',
    defaultMessage: 'Please enter a valid link.',
  },
  formValidation: {
    description: 'Ошибка валидации формы. Обычно этот текст никогда не показывается.',
    defaultMessage: 'Validation error',
  },
  networkOffline: {
    description: 'Ошибка запроса из-за отсутствия подключения к интернету',
    defaultMessage: 'Check your internet connection and try again.',
  },
  superagent: {
    description: 'Ошибка запроса из-за ошибки сети',
    defaultMessage: 'Network error.',
  },
  paymentRefundError: {
    description: 'Текст при неудачном рефанде на карту',
    defaultMessage: 'We were unable to process a refund to the card provided. Please try again.',
  },
  paymentRefundNotFound: {
    description: 'Текст при отсутствии возможного рефанда для пользователя',
    defaultMessage:
      "We haven't found any unprocessed refunds from you. If you've requested a refund, the money will soon be credited to your card.",
  },
});

const byErrorCodes: Partial<Record<string, [MessageDescriptor, Type]>> = {
  network_offline: [messages.networkOffline, Type.CLIENT_MESSAGE],
  superagent_error: [messages.superagent, Type.CLIENT_MESSAGE],
  'babylone.error': [messages.internal, Type.API_MESSAGE],
  // client error type
  'babylone.client_error': [messages.internal, Type.API_MESSAGE],
  'product_review.forbidden': [messages.forbidden, Type.CLIENT_MESSAGE],
  'product_review.ineligible': [messages.canNotReviewOrder, Type.CLIENT_MESSAGE],
  'product_review.does_not_exist': [messages.canNotReviewOrder, Type.CLIENT_MESSAGE],
  'product_review.invalid': [messages.invalidFormData, Type.API_MESSAGE],
  'api.unauthorized': [messages.unauthorized, Type.CLIENT_MESSAGE],
  'api.too_many_requests': [messages.tooManyRequests, Type.CLIENT_MESSAGE],
  'app.too_many_requests': [messages.tooManyRequests, Type.CLIENT_MESSAGE],
  'auth.forbidden': [messages.forbidden, Type.CLIENT_MESSAGE],
  'auth.general': [messages.authError, Type.CLIENT_MESSAGE],
  'auth.invalid_token': [messages.invalidToken, Type.CLIENT_MESSAGE],
  'auth.token_expired': [messages.tokenExpired, Type.CLIENT_MESSAGE],
  'auth.unauthorized': [messages.unauthorized, Type.CLIENT_MESSAGE],
  'auth.already_signed_in': [messages.alreadySignedIn, Type.CLIENT_MESSAGE],
  'auth.wrong_client_type': [messages.wrongClientType, Type.CLIENT_MESSAGE],
  // client error type
  'auth.user_changed': [messages.userChanged, Type.CLIENT_MESSAGE],
  'branch.too_many_requests': [messages.tooManyRequests, Type.CLIENT_MESSAGE],
  'cart.empty': [messages.cartEmpty, Type.API_MESSAGE],
  'cart.forbidden_in_region': [messages.forbiddenInRegion, Type.API_MESSAGE],
  'cart.forbidden_in_region_with_suggest': [
    messages.forbiddenInRegionWithSuggest,
    Type.API_MESSAGE,
  ],
  'cart.invalid_quantity_update': [messages.cartInvalidQuantityUpdate, Type.API_MESSAGE],
  'cart.variant_disabled': [messages.variantDisabled, Type.API_MESSAGE],
  'cart.no_items_to_buy': [messages.noItemsToBuy, Type.API_MESSAGE],
  'cart.no_selected_address': [messages.cartNoSelectedAddress, Type.CLIENT_MESSAGE],
  'cart.no_selected_items': [messages.cartNoSelectedItems, Type.CLIENT_MESSAGE],
  // client error type
  'cart.can_not_pay': [messages.cardCannotPay, Type.CLIENT_MESSAGE],
  // client error type
  'cart.not_loaded': [messages.cartNotLoaded, Type.CLIENT_MESSAGE],
  // client error type
  'cart.session.not_loaded': [messages.cartSessionNotLoaded, Type.CLIENT_MESSAGE],
  // client error type
  'cart.session.terminated': [messages.cartSessionTerminated, Type.API_MESSAGE],
  // client error type
  'cart.session.unknown_delivery_type': [messages.cartUnknownDeliveryType, Type.CLIENT_MESSAGE],
  'cart.invalid_quantity': [messages.cartInvalidQuantity, Type.API_MESSAGE],
  'deliveryPoint.unavailable_in_country': [
    messages.deliveryPointUnavailableInCountry,
    Type.API_MESSAGE,
  ],
  'deliveryPoint.err_suggest_not_found': [
    messages.deliveryPointAddressSearchEmptyResult,
    Type.API_MESSAGE,
  ],
  'deliveryPoint.err_no_coordinates': [
    messages.deliveryPointAddressSearchEmptyResult,
    Type.API_MESSAGE,
  ],
  // client error type
  'form.validation': [messages.formValidation, Type.API_MESSAGE],
  'imageupload.bad_request': [messages.internal, Type.API_MESSAGE],
  'ivs.err_channel_not_broadcasting': [messages.internal, Type.API_MESSAGE],
  'livevideo.invalid_argument': [messages.internal, Type.API_MESSAGE],
  'livevideo.scheduled_time_too_far': [messages.internal, Type.API_MESSAGE],
  'livevideo.update_livevideo_starting_soon': [messages.internal, Type.API_MESSAGE],
  'order.already_paid': [messages.orderAlreadyPaid, Type.API_MESSAGE],
  'order.coupons_expired': [messages.orderCouponsExpired, Type.API_MESSAGE],
  'order.insufficient_point_balance': [messages.insufficientPointBalance, Type.API_MESSAGE],
  'order.prices_changed': [messages.orderPricesChanged, Type.CLIENT_MESSAGE],
  'order.invalid_address': [messages.orderInvalidAddress, Type.CLIENT_MESSAGE],
  'order.checkout_session_already_paid': [messages.orderAlreadyPaid, Type.API_MESSAGE],
  'order.invalid_payment_method': [messages.badPaymentMethod, Type.CLIENT_MESSAGE],
  'order.payment_method_inactive': [messages.badPaymentMethod, Type.CLIENT_MESSAGE],
  'legality.need_consent': [messages.legalityNeedConsent, Type.CLIENT_MESSAGE],
  'payments.card.incorrect_number': [messages.invalidCardNumber, Type.CLIENT_MESSAGE],
  'payments.card.expired': [messages.cardExpired, Type.CLIENT_MESSAGE],
  'payments.card.insufficient_funds': [messages.insufficientFunds, Type.CLIENT_MESSAGE],
  'payments.card.not_served': [messages.cardNotServed, Type.CLIENT_MESSAGE],
  'payments.decline': [messages.paymentFailure, Type.CLIENT_MESSAGE],
  // client error type
  'payments.failure': [messages.paymentFailure, Type.CLIENT_MESSAGE],
  'payments.refund.failure': [messages.paymentRefundError, Type.CLIENT_MESSAGE],
  'payments.refund.not_found': [messages.paymentRefundNotFound, Type.CLIENT_MESSAGE],
  'web.auth.json_parse': [messages.internal, Type.CLIENT_MESSAGE],
  'web.auth.bad_format': [messages.internal, Type.CLIENT_MESSAGE],
  'promocode.unkonwn_type': [messages.unknownPromocodeType, Type.CLIENT_MESSAGE],
  'found_cheaper.invalid_amount': [messages.invalidAmount, Type.CLIENT_MESSAGE],
  'found_cheaper.invalid_url': [messages.invalidUrl, Type.CLIENT_MESSAGE],
};

export function getLocalizedErrorMessage(
  scope: ScopeConfig,
  intl: import('react-intl').IntlShape,
  error: ExtendedErrorObject,
  internal = true,
): string {
  const errorObject = unwrapErrorObject(error);
  const errorType = getErrorType(errorObject);

  if (errorType) {
    const messageArr = byErrorCodes[errorType];
    const message = messageArr ? messageArr[0] : null;
    const type = messageArr ? messageArr[1] : Type.DEFAULT;

    if (type !== Type.CLIENT_MESSAGE) {
      if (isApiError(errorObject) && errorObject.userMessage) {
        return errorObject.userMessage;
      }
      if (type === Type.API_MESSAGE && errorObject.message) {
        capitalizeFirstLetter(errorObject.message);
      }
    }

    if (message) {
      return intl.formatMessage(message, {companyName: scope.companyInfo.companyName});
    }
  }

  return internal ? intl.formatMessage(messages.internal) : '';
}
