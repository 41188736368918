import {SeoReview} from 'components/SeoReview';
import React, {useMemo} from 'react';
import {Review} from 'types/PageMetadata/Review';

import styles from './index.scss';

export type SeoReviewsListProps = {
  items: Review[];
};

export function SeoReviewsList({items}: SeoReviewsListProps): JSX.Element {
  const slicedList = useMemo(() => items.slice(0, 4), [items]);

  return (
    <div className={styles.list}>
      {slicedList.map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={index} className={styles.item}>
          <SeoReview {...item} />
        </div>
      ))}
    </div>
  );
}
