import classnames from 'classnames/bind';
import DotLoader from 'components/DotLoader';
import {createLocator} from 'create-locator';
import PropTypes from 'prop-types';
import React from 'react';
import {Link} from 'react-router-dom';
import {ColorShape} from 'shapes/Color';
import {
  convertAnyBackendGradientToCSSValue,
  convertBackendColorToCSSValue,
} from 'utils/styles/color';
import {objectMap} from 'utils/object';
import Gradient from 'shapes/Gradient';
import styles from './index.scss';

const cn = classnames.bind(styles);

const Button = React.forwardRef(function Button(props, ref) {
  const {
    className,
    active,
    block,
    boxShadow,
    color,
    customColors,
    children,
    focus,
    hover,
    loading,
    nowrap,
    padding,
    rounded,
    circle,
    variant,
    type,
    ...restProps
  } = props;

  const locator = createLocator(restProps);

  let Tag = 'div';

  if (props.to) {
    Tag = Link;
  } else if (props.href) {
    Tag = 'a';
  } else if (props.onClick || type === 'submit') {
    Tag = 'button';
  }

  const variantClassName = `${variant}-${color}`;
  const paddingClassName = `padding-${padding}`;

  const customStyles = customColors
    ? {
        ...objectMap(
          {
            color: customColors.text,
            backgroundColor: customColors.background,
            borderColor: customColors.background,
          },
          convertBackendColorToCSSValue,
        ),
        ...(customColors?.backgroundGradient
          ? {
              background: customColors?.backgroundGradient
                ? convertAnyBackendGradientToCSSValue(customColors.backgroundGradient)
                : null,
            }
          : {}),
      }
    : null;

  return (
    <Tag
      type={type}
      className={cn(
        'button',
        className,
        variantClassName,
        paddingClassName,
        customStyles && 'custom',
        {
          active,
          block,
          disabled: props.disabled,
          focus,
          hover,
          loading: props.loading,
          rounded: !circle && rounded,
          circle,
        },
      )}
      style={{
        ...customStyles,
        boxShadow,
      }}
      ref={ref}
      {...restProps}
      {...locator()}
    >
      <span className={cn('children', {nowrap})}>{children}</span>

      {loading && (
        <span className={cn('loader')}>
          <DotLoader style="inherit" />
        </span>
      )}
    </Tag>
  );
});

Button.propTypes = {
  boxShadow: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  to: PropTypes.string,
  href: PropTypes.string,
  onClick: PropTypes.func,

  color: PropTypes.oneOf(['brand', 'danger', 'green', 'notice', 'lightgrey', 'darkblue']),
  customColors: PropTypes.shape({
    text: ColorShape,
    background: ColorShape,
    backgroundGradient: Gradient,
  }),
  variant: PropTypes.oneOf(['fill', 'outline']),
  padding: PropTypes.oneOf(['narrow', 'none', 'normal', 'wide']),

  active: PropTypes.bool,
  block: PropTypes.bool,
  disabled: PropTypes.bool,
  focus: PropTypes.bool,
  hover: PropTypes.bool,
  loading: PropTypes.bool,

  nowrap: PropTypes.bool,
  rounded: PropTypes.bool,
  circle: PropTypes.bool,
  type: PropTypes.string,
};

Button.defaultProps = {
  boxShadow: '',
  className: null,
  to: null,
  href: null,
  onClick: null,

  color: 'brand',
  customColors: null,
  variant: 'fill',
  padding: 'normal',

  active: false,
  block: false,
  disabled: false,
  focus: false,
  hover: false,
  loading: false,

  nowrap: false,
  rounded: true,
  circle: false,
  type: '',
};

export default Button;
