import {AddressForm} from 'components/AddressForm/loadable';
import Popup from 'components/Popup';
import {useDeviceVars} from 'hooks/useDeviceVars';
import React, {useCallback, useEffect, useMemo} from 'react';
import {defineMessages, useIntl} from 'react-intl';
import {PartName} from 'shapes/AddressMetainfo';
import {PersonalDataForm} from 'store/modules/personalData/form/actions';
import {Address} from 'types/Address';
import {Fields, ValidationMessages} from 'types/AddressMetainfo';
import {PersonalDataFormOrigin} from 'types/PersonalData';

import styles from './index.scss';

type Props = {
  data: PersonalDataForm | null;
  fetchFieldValue: (
    field: string,
    parts: Fields,
    origin: PersonalDataFormOrigin,
  ) => Promise<Record<string, unknown>>;
  loading?: boolean;
  loadPersonalDataForm: (origin: PersonalDataFormOrigin) => Promise<void>;
  metainfoId: string;
  onClose(): void;
  onFormSave: (metainfoId: string) => Promise<void> | void;
  onITNReceive?: (isSuccessful: boolean) => void;
  onITNRequest?: () => void;
  personalDataFormOrigin: PersonalDataFormOrigin;
  updatePersonalDataForm: (
    parts: Fields,
    origin: PersonalDataFormOrigin,
  ) => Promise<Record<string, unknown>>;
  validatePartialPersonalDataForm: (
    parts: Fields,
    origin: PersonalDataFormOrigin,
  ) => Promise<ValidationMessages>;
  validatePersonalDataForm: (
    parts: Fields,
    origin: PersonalDataFormOrigin,
  ) => Promise<ValidationMessages>;
};

const messages = defineMessages({
  title: {
    description: 'Заголовок в форме ввода паспортных данных',
    defaultMessage: 'Personal details',
  },
  button: {
    description: 'Кнопка отправки формы ввода паспортных данных',
    defaultMessage: 'Save',
  },
});

export const PassportFormPopup = React.memo(function PassportFormPopup({
  data,
  fetchFieldValue,
  loading,
  loadPersonalDataForm,
  metainfoId,
  onClose,
  onFormSave,
  onITNReceive,
  onITNRequest,
  personalDataFormOrigin,
  updatePersonalDataForm,
  validatePartialPersonalDataForm,
  validatePersonalDataForm,
}: Props): React.ReactElement | null {
  const intl = useIntl();
  const handleLoadSuggest = useCallback(() => Promise.resolve(null), []);
  const onCountryChange = useCallback(() => null, []);
  const {addressEditorLiveValidation} = useDeviceVars();

  const handleValidate = useCallback(
    (payload: Address) => validatePersonalDataForm(payload.fields, personalDataFormOrigin),
    [validatePersonalDataForm, personalDataFormOrigin],
  );

  const handleValidatePartial = useCallback(
    (payload: Address) => validatePartialPersonalDataForm(payload.fields, personalDataFormOrigin),
    [validatePartialPersonalDataForm, personalDataFormOrigin],
  );

  const handleSubmit = useCallback(
    ({metainfoId, fields}: Address) => {
      updatePersonalDataForm(fields, personalDataFormOrigin).then(() => onFormSave(metainfoId));
    },
    [updatePersonalDataForm, personalDataFormOrigin, onFormSave],
  );

  const metainfo = useMemo(
    () => (data?.parts ? {parts: data.parts, id: metainfoId} : undefined),
    [data?.parts, metainfoId],
  );

  const preparedInitialValues = useMemo(() => ({fields: data?.fields}), [data?.fields]);

  const actionButtonHandlers = {
    [PartName.PASSPORT_TAX_NUMBER]: useCallback(
      async (payload: Address) => {
        const actionPayloadPromise = fetchFieldValue(
          PartName.PASSPORT_TAX_NUMBER,
          payload.fields,
          personalDataFormOrigin,
        );

        onITNRequest?.();

        const isSuccessful = await actionPayloadPromise
          .then((actionPayload) => Boolean(actionPayload?.value))
          .catch(() => false);

        onITNReceive?.(isSuccessful);

        return actionPayloadPromise;
      },
      [fetchFieldValue, onITNReceive, onITNRequest, personalDataFormOrigin],
    ),
  };

  useEffect(() => {
    loadPersonalDataForm(personalDataFormOrigin);
  }, [loadPersonalDataForm, personalDataFormOrigin]);

  return (
    <Popup>
      <div className={styles.popup}>
        <AddressForm
          title={intl.formatMessage(messages.title)}
          loading={loading}
          loadSuggest={handleLoadSuggest}
          onCountryChange={onCountryChange}
          onSubmit={handleSubmit}
          onCancel={onClose}
          validateAddress={handleValidate}
          validateAddressPartial={
            addressEditorLiveValidation?.enabled ? handleValidatePartial : undefined
          }
          metainfo={metainfo}
          initialValues={preparedInitialValues}
          banners={data?.banners}
          onBannerClick={onClose}
          submitLabel={intl.formatMessage(messages.button)}
          actionButtonHandlers={actionButtonHandlers}
          asPopup
        />
      </div>
    </Popup>
  );
});
