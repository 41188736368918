import {ScopeConfig} from 'helpers/ApiClient/Scope/ScopeConfig';
import {AllHTMLAttributes} from 'react';
import {isExternalUrl, isJoomUrl} from 'utils/url';

export const attributeMapper =
  (scope: ScopeConfig) =>
  (element: Element): AllHTMLAttributes<unknown> => {
    if (element.tagName.toLowerCase() === 'a') {
      const href = element.attributes?.getNamedItem('href')?.value;

      if (href && !isJoomUrl(scope, href) && isExternalUrl(href)) {
        return {
          target: '_blank',
          rel: 'nofollow',
        };
      }
    }

    return {};
  };

export const attributeMapperWithAdditionalParameters =
  (
    scope: ScopeConfig,
    options: {
      [key: string]: AllHTMLAttributes<unknown>;
    },
  ) =>
  (element: Element): AllHTMLAttributes<typeof element> => {
    return {...attributeMapper(scope)(element), ...options[element.tagName.toLowerCase()]};
  };
