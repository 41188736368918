/* eslint-disable react/destructuring-assignment */
import classnames from 'classnames/bind';
import {ConditionalWrapper} from 'components/ConditionalWrapper';
import {DialogModal} from 'components/DialogModal';
import {Locator} from 'components/Locator';
import mousetrap from 'mousetrap';
import React, {Component, ReactElement, ReactNode, SyntheticEvent, useId} from 'react';

import {Animation} from './Animation';
import {Close} from './Close';
import {Header} from './Header';
import styles from './Sidebar.scss';

const cn = classnames.bind(styles);

type Props = {
  children?: ReactNode;
  className?: string;
  disableAnimation?: boolean;
  fullWidth?: boolean;
  isPopup?: boolean;
  isPopupOnDesktop?: boolean;
  isRight?: boolean;
  narrowPopupContent?: boolean;
  onBack?: () => void;
  onClose?: () => void;
  roundedPopup?: boolean;
  title?: ReactNode;
  whiteSidebarBackground?: boolean;
  label?: string;
  titleUid: string;
};

type State = {
  focusLocked?: boolean;
};

export function Sidebar(props: Omit<Props, 'titleUid'>): ReactElement {
  const titleUid = useId();

  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  return <SidebarComponent titleUid={titleUid} {...props} />;
}

class SidebarComponent extends Component<Props, State> {
  contentRef = React.createRef<HTMLDivElement>();

  constructor(props: Props) {
    super(props);
    this.handleOverlayClick = this.handleOverlayClick.bind(this);
  }

  override componentDidMount() {
    const {onClose} = this.props;
    if (__CLIENT__ && onClose) {
      mousetrap.bind('esc', () => onClose());
    }
  }

  override componentWillUnmount() {
    if (__CLIENT__) {
      mousetrap.unbind('esc');
    }
  }

  handleOverlayClick(evt: SyntheticEvent) {
    if (evt.target === evt.currentTarget && this.props.onClose) {
      this.props.onClose();
    }
  }

  renderInner() {
    const {children, title, narrowPopupContent, label, titleUid} = this.props;

    if (!title) {
      return (
        <div className={styles.inner} aria-modal="true" role="dialog" aria-label={label}>
          {children}
        </div>
      );
    }

    const props = {...this.props};
    delete props.title;
    delete props.narrowPopupContent;

    return (
      <Locator id="SignInSidebar" popup-type="AuthPopupFromHeader">
        <div className={cn(styles.inner, {narrowPopupContent})} aria-describedby={titleUid}>
          <div className={styles.header}>
            <Header {...props} headerUid={titleUid}>
              {title}
            </Header>
          </div>
          <div className={styles.content}>{children}</div>
        </div>
      </Locator>
    );
  }

  override render() {
    const {
      fullWidth,
      isPopup,
      isPopupOnDesktop,
      isRight,
      roundedPopup,
      whiteSidebarBackground,
      disableAnimation,
    } = this.props;

    return (
      <DialogModal className={styles.dialog}>
        <ConditionalWrapper
          condition={!disableAnimation}
          // eslint-disable-next-line react/jsx-no-bind
          wrapper={(children) => <Animation nodeRef={this.contentRef}>{children}</Animation>}
        >
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <div
            ref={this.contentRef}
            key="sidebar"
            className={cn(styles.sidebar, {
              isRight,
              isPopup,
              isPopupOnDesktop,
              isLeftSidebar: !isRight && !isPopup && !isPopupOnDesktop,
              fullWidth,
              roundedPopup,
              whiteSidebarBackground,
            })}
            onClick={this.handleOverlayClick}
          >
            {this.renderInner()}
          </div>
        </ConditionalWrapper>
      </DialogModal>
    );
  }
}

Sidebar.Close = Close;
Sidebar.Header = Header;
