import {isLocalStorageAvailable} from './KeyValueStorage/WindowStorage';
import {memoize} from './memoize';
import {getPrefixiedKey} from './storage';

// 30 minutes
const SENSITIVE_EXPIRES = 1000 * 60 * 30;

const getSensitiveProductShownKey = memoize((productId) =>
  getPrefixiedKey(`sensitiveProductShown-${productId}`),
);

function clearSensitivityStamp(productId: string): void {
  localStorage.removeItem(getSensitiveProductShownKey(productId));
}

export function getIsSensitiveProductShown(productId: string): boolean {
  if (!isLocalStorageAvailable()) {
    return false;
  }

  const sensitivityStamp = Number(localStorage.getItem(getSensitiveProductShownKey(productId)));
  const currentTime = Number(new Date());

  if (currentTime - sensitivityStamp >= SENSITIVE_EXPIRES) {
    clearSensitivityStamp(productId);
    return false;
  }

  return true;
}

export function setIsSensitiveProductShown(productId: string): boolean {
  if (!isLocalStorageAvailable()) {
    return false;
  }

  localStorage.setItem(getSensitiveProductShownKey(productId), String(Number(new Date())));
  return true;
}
