import PropTypes from 'prop-types';

export const PreOfferType = {
  INSTANT: 'instant',
};

const InstantPayload = PropTypes.shape({
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
});

export default PropTypes.shape({
  type: PropTypes.string.isRequired,
  timeRemainingMs: PropTypes.number.isRequired,
  payload: PropTypes.oneOfType([InstantPayload]),
});
