import PropTypes from 'prop-types';
import React, {PureComponent, createRef, useRef, useMemo} from 'react';
import {UserShape} from 'shapes/User';
import {FormattedMessage} from 'react-intl';
import {Portal} from 'components/Portal';
import Coupon, {getCouponColors, getCouponStyle} from 'components/Coupon';
import classnames from 'classnames/bind';
import {getAngleBetweenPoints, getDistanceBetweenPoints} from 'utils/math';
import {requestAnimationFrame} from 'utils/raf';
import {supportsCSSVariables} from 'utils/styles';
import {getPassiveEventOptions} from 'utils/dom';
import throttle from 'utils/throttle';
import noop from 'lodash/noop';
import {RefShape} from 'shapes/Ref';
import {usePreviewEvent} from 'hooks/usePreviewEvent';
import {Locator} from 'components/Locator';
import {omit} from 'lodash';
import {BadgeButton} from '../BadgeButton';
import {CouponPopup} from './Popup';
import styles from './index.scss';
import popupStyles from './Popup.scss';
import {COUPON_BANNER_TYPE} from './consts';

const cn = classnames.bind(styles);
const popupCn = classnames.bind(popupStyles);

const COUPON_ANNOUNCE_DELAY = 1000;
const canShowCouponAnimation = supportsCSSVariables();

const getSelectedCoupon = (couponCards) => (couponCards && couponCards[0]) || null;

const HeaderCouponAnalytics = (props) => {
  const stubRef = useRef();

  const {couponCards, source} = props;

  const coupon = useMemo(() => getSelectedCoupon(couponCards), [couponCards]);

  usePreviewEvent(
    {
      rootRef: stubRef,
      previewEvent: {
        type: 'couponStubShow',
        payload: {
          couponId: coupon?.id,
          source,
        },
      },
    },
    {
      clickEvent: {
        type: 'couponStubClick',
        payload: {
          couponId: coupon?.id,
          source,
        },
      },
    },
  );

  return <HeaderCoupon {...props} stubRef={stubRef} />;
};

class HeaderCoupon extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    couponCards: PropTypes.array,
    user: UserShape,
    onCouponView: PropTypes.func.isRequired,
    lastCouponViewTime: PropTypes.number,
    type: PropTypes.oneOf([COUPON_BANNER_TYPE]),
    stubRef: RefShape.isRequired,
    shouldForceAnnounceCoupon: PropTypes.func,
  };

  static defaultProps = {
    className: undefined,
    couponCards: [],
    user: null,
    lastCouponViewTime: 0,
    type: null,
    shouldForceAnnounceCoupon: noop,
  };

  constructor(props) {
    super(props);

    this.btnRef = createRef();
    this.placeholderRef = createRef();
    this.couponRef = createRef();

    this.handleResize = throttle(this.handleResize);

    const shouldAnnounceCoupon = this.shouldAnnounceCoupon();

    this.state = {
      couponAnimationIsPlaying: shouldAnnounceCoupon,
      hasPopup: false,
      isButtonHidden: shouldAnnounceCoupon,
      isCouponPlaceholderShown: false,
      isCouponPlaceholderAnimating: false,
      hasButton: this.hasButton(),
    };
  }

  componentDidMount() {
    global.addEventListener('resize', this.handleResize, getPassiveEventOptions());

    this.verifyAndShowAnimation();
  }

  componentDidUpdate(prevProps) {
    const {couponCards: prevCouponCards} = prevProps;
    const {couponCards} = this.props;

    if (prevCouponCards !== couponCards) {
      this.setState({
        hasButton: this.hasButton(),
      });

      this.verifyAndShowAnimation();
    }
  }

  componentWillUnmount() {
    global.removeEventListener('resize', this.handleResize, getPassiveEventOptions());
  }

  getSelectedCoupon() {
    const {couponCards} = this.props;
    return (couponCards && couponCards[0]) || null;
  }

  handleResize = () => {
    this.setState({
      hasButton: this.hasButton(),
    });
  };

  handleClick = () => {
    const {hasPopup} = this.state;
    this.setState({hasPopup: !hasPopup});
  };

  handleClose = () => {
    const {couponAnimationIsPlaying} = this.state;
    if (couponAnimationIsPlaying) {
      this.setState({
        isCouponPlaceholderShown: true,
      });
      requestAnimationFrame(() => {
        this.setState(
          {
            isCouponPlaceholderAnimating: true,
          },
          () => {
            this.setState({
              couponAnimationIsPlaying: false,
              hasPopup: false,
            });
          },
        );
      });
    } else {
      this.setState({
        hasPopup: false,
      });
    }
  };

  handleAnimationEnd = () => {
    this.couponRef = null;
    this.placeholderRef = null;
    this.setState({
      isCouponPlaceholderShown: false,
      isCouponPlaceholderAnimating: false,
      isButtonHidden: false,
    });
  };

  handleAnimationShow = () => {
    const coupon = getSelectedCoupon(this.props.couponCards);

    if (coupon && coupon.createdTimeMs) {
      this.props.onCouponView(this.props.user.id, coupon.createdTimeMs);
    }
  };

  shouldAnnounceCoupon() {
    if (!this.hasButton()) {
      return false;
    }

    const {user} = this.props;
    const isAuthorized = user && !user.anonymous;

    if (!canShowCouponAnimation || __SERVER__ || !isAuthorized) {
      return false;
    }

    const {lastCouponViewTime, shouldForceAnnounceCoupon, couponCards} = this.props;
    const selectedCoupon = getSelectedCoupon(couponCards);
    if (!selectedCoupon) {
      return false;
    }

    const forced = shouldForceAnnounceCoupon?.();

    const selectedCouponTime = selectedCoupon.createdTimeMs;

    return forced || Boolean(selectedCouponTime && selectedCouponTime > lastCouponViewTime);
  }

  verifyAndShowAnimation() {
    const shouldAnnounceCoupon = this.shouldAnnounceCoupon();
    const forced = this.props?.shouldForceAnnounceCoupon();

    if (shouldAnnounceCoupon) {
      this.setState(
        {
          hasButton: true,
          isButtonHidden: !forced,
        },
        () => {
          setTimeout(() => {
            this.setState({
              couponAnimationIsPlaying: true,
              hasPopup: true,
              isButtonHidden: !forced,
            });
          }, COUPON_ANNOUNCE_DELAY);
        },
      );
    }
  }

  hasButton() {
    const {type, couponCards} = this.props;
    const coupon = getSelectedCoupon(couponCards);
    const matchMediaMd = global.matchMedia ? global.matchMedia('(min-width: 768px)') : false;
    const matchesMd = matchMediaMd && matchMediaMd.matches;
    const isBanner = type === COUPON_BANNER_TYPE;

    if (!coupon) {
      return false;
    }

    return (isBanner && !matchesMd) || (!isBanner && matchesMd);
  }

  renderCouponPlaceholder() {
    const {btnRef, placeholderRef} = this;

    const coupon = getSelectedCoupon(this.props.couponCards);

    const {isCouponPlaceholderShown, isCouponPlaceholderAnimating} = this.state;
    let couponStyle = {};

    if (!this.couponRef || !this.couponRef.current) {
      return null;
    }

    const couponRect = this.couponRef.current.getBoundingClientRect();

    const {height: couponHeight, left: couponLeft, top: couponTop, width: couponWidth} = couponRect;
    couponStyle = {
      left: `${couponLeft}px`,
      top: `${couponTop}px`,
    };

    if (placeholderRef && placeholderRef.current && btnRef && btnRef.current) {
      const {
        height: btnHeight,
        left: btnLeft,
        top: btnTop,
        width: btnWidth,
      } = btnRef.current.getBoundingClientRect();
      const btnCenter = {x: btnLeft + btnWidth / 2, y: btnTop + btnHeight / 2};
      const couponCenter = {x: couponLeft + couponWidth / 2, y: couponTop + couponHeight / 2};
      const middle = {x: (btnCenter.x + couponCenter.x) / 2, y: (btnCenter.y + couponCenter.y) / 2};
      const dist = getDistanceBetweenPoints(btnCenter, couponCenter);

      couponStyle = {
        left: `${middle.x - dist / 2}px`,
        top: `${middle.y - couponHeight / 2}px`,
        width: `${dist}px`,
      };
      const finalAngle = getAngleBetweenPoints(btnCenter, couponCenter);
      const startAngle = finalAngle + 180;

      const placeholderStyle = placeholderRef.current.style;
      placeholderStyle.setProperty('--start-rotate', `${startAngle}deg`);
      placeholderStyle.setProperty('--final-rotate', `${finalAngle}deg`);
      placeholderStyle.setProperty('--inner-rotate', `${360 - startAngle}deg`);
    }

    return (
      isCouponPlaceholderShown && (
        <Portal>
          <div
            className={popupCn('couponWrapper', {
              animated: isCouponPlaceholderAnimating,
            })}
            style={couponStyle}
            ref={placeholderRef}
            onAnimationEnd={this.handleAnimationEnd}
          >
            <div className={popupStyles.couponPlaceholder}>
              <Coupon big coupon={coupon} isPlaceholder />
            </div>
          </div>
        </Portal>
      )
    );
  }

  render() {
    if (!__CLIENT__ || !this.state.hasButton) {
      return null;
    }

    const {isButtonHidden, couponAnimationIsPlaying} = this.state;
    const coupon = getSelectedCoupon(this.props.couponCards);
    const {className, type, stubRef} = this.props;

    if (!coupon) {
      return null;
    }

    const style = getCouponStyle(getCouponColors(coupon));
    const inner = (
      <span className={styles.inner} ref={stubRef}>
        {coupon.receiveStubTitle || (
          <FormattedMessage
            description="Favorites button in header"
            defaultMessage={"You've got a coupon!"}
          />
        )}
      </span>
    );

    return (
      <>
        <Locator id="CouponTabOnNavBar">
          <button
            type="button"
            className={cn('coupons', className, {
              hidden: isButtonHidden,
              block: type === COUPON_BANNER_TYPE,
            })}
            ref={this.btnRef}
            onClick={this.handleClick}
          >
            {type === COUPON_BANNER_TYPE ? (
              <span className={styles.banner} style={style}>
                {inner}
              </span>
            ) : (
              <BadgeButton style={style}>{inner}</BadgeButton>
            )}
          </button>
        </Locator>
        {this.state.hasPopup ? (
          <Locator id="CouponPopup">
            <CouponPopup
              coupon={coupon}
              onClose={this.handleClose}
              btnRef={this.btnRef.current}
              ref={{
                couponRef: this.couponRef,
              }}
              source="header"
              couponAnimationIsPlaying={couponAnimationIsPlaying}
              onShowAnimation={this.handleAnimationShow}
            />
          </Locator>
        ) : null}
        {canShowCouponAnimation && this.renderCouponPlaceholder()}
      </>
    );
  }
}

HeaderCouponAnalytics.propTypes = {
  ...omit(HeaderCoupon.propTypes, 'stubRef'),
  source: PropTypes.string,
};
HeaderCouponAnalytics.defaultProps = {source: null};

export default HeaderCouponAnalytics;
