import {useAnalytics} from 'hooks/useAnalytics';
import {usePreviewEvent} from 'hooks/usePreviewEvent';
import {useCallback, useRef} from 'react';
import {useLocation} from 'react-router';

export function useTrustpilotAnalytics(): {onClick: () => void; ref: React.RefObject<HTMLElement>} {
  const location = useLocation();
  const analytics = useAnalytics();

  const ref = useRef<HTMLElement>(null);

  usePreviewEvent({
    rootRef: ref,
    previewEvent: {
      type: 'trustedBadgePreview',
      payload: {
        type: 'pilot',
        url: location.pathname,
      },
    },
  });

  const onClick = useCallback(() => {
    analytics.sendEvent({
      type: 'trustedBadgeClick',
      payload: {
        type: 'pilot',
        url: location.pathname,
      },
    });
  }, [analytics, location.pathname]);

  return {onClick, ref};
}
