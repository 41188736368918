import {Icon} from 'components/UIKit/Icon';
import {useModalOverlayLabel} from 'components/UIKit/ModalOverlay/context';
import {Scope} from 'config';
import {useScope} from 'hooks/useScope';
import React, {FC} from 'react';
import {defineMessages, FormattedMessage, useIntl} from 'react-intl';

import styles from './Success.scss';

const messages = defineMessages({
  illustrationAlt: {
    description: 'Альтернативный текст для иллюстарции при успешной отправке формы поддержки',
    defaultMessage: 'Request successfully sent',
  },
  success: {
    description: 'Сообщение об успешной отправки формы поддержки',
    defaultMessage: 'Thank you for contacting us, please wait for our reply at {email}',
  },
});

type Props = {
  email: string;
};

export const Success: FC<Props> = ({email}: Props): JSX.Element => {
  const intl = useIntl();
  const scope = useScope();

  useModalOverlayLabel(intl.formatMessage(messages.illustrationAlt));

  return (
    <div className={styles.success}>
      {scope.is(Scope.GLOBAL) && (
        <div className={styles.image}>
          <Icon type="illustration" name="3d-megaphone" />
        </div>
      )}
      <p className={styles.text}>
        <FormattedMessage {...messages.success} values={{email}} />
      </p>
    </div>
  );
};
