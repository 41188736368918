import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, {memo} from 'react';
import {FormattedMessage} from 'react-intl';
import {Link} from 'react-router-dom';

import {Icon} from 'components/UIKit/Icon';
import {CopyContainer} from 'components/CopyContainer';
import {Locator} from 'components/Locator';
import styles from './orderId.scss';

const cn = classnames.bind(styles);

export const OrderId = memo(function OrderId(props) {
  const {onClick, id, to} = props;
  const testId = props['data-testid'];

  const message = (
    <FormattedMessage defaultMessage="No. {id}" description="Parcel order id" values={{id}} />
  );

  if (!to) {
    return (
      <Locator id={testId}>
        <div>
          <div className={cn('inline')}>
            <CopyContainer value={id}>
              <span className={cn('orderId', 'inline')}>{message}</span>
              <span className={cn('copyIcon')}>
                <Icon type="mono" name="copy-linear-16" />
              </span>
            </CopyContainer>
          </div>
        </div>
      </Locator>
    );
  }

  return (
    <Locator id={testId}>
      <Link to={to} className={cn('orderId', 'link')} onClick={onClick}>
        {message}
      </Link>
    </Locator>
  );
});

OrderId.propTypes = {
  'data-testid': PropTypes.string,
  onClick: PropTypes.func,
  id: PropTypes.string.isRequired,
  to: PropTypes.string,
};

OrderId.defaultProps = {
  'data-testid': undefined,
  onClick: null,
  to: '',
};
