import classnames from 'classnames';
import {PowerShopCard} from 'components/ContentList/PowerShopCard';
import {useDeepLink} from 'hooks/useDeepLink';
import React, {FunctionComponent, useMemo} from 'react';
import {
  FoundStore,
  SearchStoreList as StoreList,
  transformStoreToPowerShop,
} from 'types/ContentList/SearchStoreList';

import styles from './index.scss';

type Props = {
  storeList: StoreList;
};

const LIST_LENGTH = 3;
const ANALYTICS_SOURCE = 'search';

type ListItemProps = {
  store: FoundStore;
};

const StoreListItem: FunctionComponent<ListItemProps> = ({store}) => {
  const powerShop = transformStoreToPowerShop(store);
  const [url] = useDeepLink(store.url);

  return <PowerShopCard powerShop={powerShop} size="small" href={url} source={ANALYTICS_SOURCE} />;
};

export const SearchStoreList: FunctionComponent<Props> = ({storeList}) => {
  const storesToShow = useMemo(
    () => storeList.storeList.items.slice(0, LIST_LENGTH),
    [storeList.storeList.items],
  );
  const itemsShouldBeFixed = storesToShow.length < LIST_LENGTH;

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <h1 className={styles.title}>{storeList.storeList.headerAppearance.simple.title}</h1>
      </div>
      <div className={styles.list}>
        {storesToShow.map((store) => (
          <div
            className={classnames(styles.item, {[styles.fixed!]: itemsShouldBeFixed})}
            key={store.store.id}
          >
            <StoreListItem store={store} />
          </div>
        ))}
      </div>
    </div>
  );
};
