import ViewportListener from 'components/ViewportListener';
import PropTypes from 'prop-types';
import React from 'react';
import {getPageOffsetTop} from 'utils/dom';

export default class Sticky extends React.Component {
  static propTypes = {
    fixedClassName: PropTypes.string.isRequired,
    children: PropTypes.element.isRequired,
    inverse: PropTypes.bool,
    listenImages: PropTypes.bool,
  };

  static defaultProps = {
    inverse: false,
    listenImages: false,
  };

  constructor(props) {
    super(props);

    this.update = this.update.bind(this);

    this.child = null;
    this.parent = null;
    this.childViewportOffset = null;
    this.parentPageOffsetTop = null;

    this.state = {
      fixed: false,
    };
  }

  update(force) {
    if (!this.child) {
      return;
    }

    const {fixedClassName, inverse} = this.props;
    const {fixed} = this.state;

    if (force) {
      const childClassName = this.child.className;

      if (!fixed) {
        this.child.className += ` ${fixedClassName}`;
      }

      this.childViewportOffset = this.child.offsetTop;
      this.parentPageOffsetTop = getPageOffsetTop(this.parent);
      this.parent.style.height = `${this.child.offsetHeight}px`;

      if (!fixed) {
        this.child.className = childClassName;
      }
    }

    const scrollTop = ViewportListener.getScrollTop();
    const isParentUpperThenFixedChild =
      this.parentPageOffsetTop - scrollTop <= this.childViewportOffset;
    const needToFix = isParentUpperThenFixedChild ^ inverse; // eslint-disable-line no-bitwise

    if (needToFix !== fixed) {
      this.setState({fixed: needToFix});
    }
  }

  render() {
    const {children, listenImages, fixedClassName} = this.props;
    const {fixed} = this.state;
    const child = children;

    const props = {...this.props};
    delete props.fixedClassName;
    delete props.inverse;
    delete props.listenImages;

    return (
      <ViewportListener listenImages={listenImages} onChange={this.update}>
        <div
          {...props}
          ref={(node) => {
            this.parent = node;
          }}
        >
          <child.type
            {...child.props}
            ref={(node) => {
              this.child = node;
            }}
            className={`${child.props.className || ''} ${fixed ? fixedClassName : ''}`}
          />
        </div>
      </ViewportListener>
    );
  }
}
