import classnames from 'classnames/bind';
import React, {useCallback, useEffect, useId} from 'react';

import styles from './Item.scss';

const cn = classnames.bind(styles);

export type ItemProps<T> = {
  children?: React.ReactNode;
  className?: string;
  item: T;
  active?: boolean;
  onClick: (item: T) => void;
  onSetActive?: (item: T, itemUid: string) => void;
};

export function Item<T>({
  active = false,
  className,
  item,
  onClick,
  onSetActive,
  ...otherProps
}: ItemProps<T>): JSX.Element {
  const itemUid = useId();

  const handleClick = useCallback(() => {
    onClick(item);
  }, [onClick, item]);

  useEffect(() => {
    if (active) {
      onSetActive?.(item, itemUid);
    }
  }, [onSetActive, item, itemUid, active]);

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      id={itemUid}
      role="button"
      tabIndex={-1}
      {...otherProps}
      className={cn(styles.item, className, {[styles.active!]: active})}
      onClick={handleClick}
    />
  );
}

Item.activeClassName = styles.active;
