import {ApiClient} from 'helpers/ApiClient';
import type {Location} from 'history';
import {stripLanguage} from 'utils/language';
import uuidv4 from 'uuid/v4';

import {PREVIOUS_SEARCH_SESSION_ID, SEARCH_SESSION_ID} from './consts';
import {LocationToSession} from './LocationToSession';
import {SearchSession} from './types';

export function generateSearchSessionUuid(): string {
  const arr = uuidv4(undefined, []);

  if (__CLIENT__) {
    return btoa(arr.map((v) => String.fromCharCode(v)).join(''));
  }

  return Buffer.from(arr).toString('base64');
}

export function getPath(pathname: string): string {
  return stripLanguage(pathname).split('/').filter(Boolean)[0] || '';
}

function isSamePathLocations(currentLocation: Location, nextLocation: Location): boolean {
  const currentPath = getPath(currentLocation.pathname);
  const nextPath = getPath(nextLocation.pathname);

  return currentPath === nextPath;
}

const searchContextRoutesList = ['search', 'stores'];

function isSearchContextLocation(location: Location): boolean {
  const path = getPath(location.pathname);

  return searchContextRoutesList.includes(path);
}

export function isSearchRouteLeave(currentLocation: Location, nextLocation: Location): boolean {
  const isSamePath = isSamePathLocations(currentLocation, nextLocation);
  return !isSamePath && isSearchContextLocation(currentLocation);
}

export function isWithinSession(currentLocation: Location, nextLocation: Location): boolean {
  const isSamePath = isSamePathLocations(currentLocation, nextLocation);
  return (
    isSamePath && isSearchContextLocation(currentLocation) && isSearchContextLocation(nextLocation)
  );
}

function isPreviousSessionPreservingLocation(location: Location): boolean {
  const path = getPath(location.pathname);

  return isSearchContextLocation(location) || path === 'products';
}

export function shouldDiscardPreviousSession(
  currentLocation: Location,
  nextLocation: Location,
): boolean {
  return (
    isPreviousSessionPreservingLocation(currentLocation) &&
    !isPreviousSessionPreservingLocation(nextLocation)
  );
}

export function isRouteChange(currentLocation: Location, nextLocation: Location): boolean {
  return currentLocation.key !== nextLocation.key;
}

export function getSearchSession(client: ApiClient): SearchSession['searchSessionId'] | undefined {
  const sessionStorage = client.storages.session;
  return sessionStorage?.getItem(SEARCH_SESSION_ID) as string;
}

export function setSearchSession(client: ApiClient, value: string): void {
  const sessionStorage = client.storages.session;
  sessionStorage?.setItem(SEARCH_SESSION_ID, value);
}

export function getPreviousSearchSessionId(
  client: ApiClient,
): SearchSession['previousSearchSessionId'] | undefined {
  const sessionStorage = client.storages.session;
  return sessionStorage?.getItem(PREVIOUS_SEARCH_SESSION_ID) as string;
}

export function setPreviousSearchSessionId(client: ApiClient, value?: string): void {
  const sessionStorage = client.storages.session;
  sessionStorage?.setItem(PREVIOUS_SEARCH_SESSION_ID, value);
}

export function setSessionForLocation(
  client: ApiClient,
  key: string | undefined,
  value: string,
): void {
  const locationToSession = new LocationToSession(client);
  locationToSession.setSessionForLocation(key, value);
}

export function getSessionByLocation(
  client: ApiClient,
  key: string | undefined,
): string | undefined {
  const locationToSession = new LocationToSession(client);
  return locationToSession.getSessionByLocation(key);
}
