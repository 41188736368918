import {CouponCard as CouponComponent} from 'components/CouponCard';
import {connect} from 'react-redux';
import {loadCouponCards} from 'store/modules/couponCards';
import {loadCouponsFull} from 'store/modules/couponsFull';
import {isCartLoaded, loadCart, updateCart} from 'store/modules/groupedCart';
import {RootState} from 'store/rootReducer';

const mapDispatchToProps = {
  loadCart,
  updateCart,
  loadCouponsFull,
  loadCouponCards,
};

const mapStateToProps = (globalState: RootState) => ({
  isCartLoaded: isCartLoaded(globalState),
});

export const CouponCard = connect(mapStateToProps, mapDispatchToProps)(CouponComponent);
