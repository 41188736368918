import config from 'config';
import {produce} from 'immer';
import {isActionOf} from 'typesafe-actions';
import {isContentListWithoutProducts} from 'helpers/contentList';
import {assignSet, getValueByPath} from 'utils/object';
import {convertHierarchyToGraph, isLeafCategory} from 'store/modules/categoryViews';
import {hashCode} from 'utils/string';
import {memoizeLastShallowEqual} from 'utils/memoize';
import {applyOffers} from 'store/modules/offers/actions';
import {OfferBannerState} from 'types/Banner';
import {START_PAGE_TOKEN} from 'store/consts';
import {
  appendContentListItems,
  enhanceContentListItems,
  getItemsToShow,
  handleContentListProducts,
} from 'store/enhancer/contentList';
import cloneDeep from 'lodash/cloneDeep';
import {sortedObject} from 'utils/object/sortedObject';
import {
  addProductToCollection,
  editProductsInCollections,
  removeProductFromCollection,
  addCollectionToFavorites,
  removeCollectionFromFavorites,
} from 'store/modules/productCollections/actions';
import {arrayToObject} from 'utils/array';
import {identity} from 'utils/function';

const SHOW_OWN_CATEGORIES = '###';

export {buildSearchRequest} from 'utils/search';

export const initialState = {
  categoryGraph: null,
  data: {},
  engine: '',
  error: null,
  id: '',
  loaded: {},
  loading: false,
  subCategory: null,
};

function isSameSearchWithDifferentCategoryId(id1, id2) {
  if (id1 && id2) {
    const index1 = id1.indexOf(':');
    const index2 = id2.indexOf(':');
    // cut categoryId part from id
    return (index1 === -1 && index2 === -1) || id1.substr(index1) === id2.substr(index2);
  }
  return false;
}

function mergeFilters(prevFilters, nextFilters) {
  if (!prevFilters) {
    return nextFilters;
  }

  if (!nextFilters) {
    return prevFilters;
  }

  const prevFiltersMap = arrayToObject(prevFilters, (filter) => filter.id, identity);

  return nextFilters.map((nextFilter) => {
    const prevFilter = prevFiltersMap[nextFilter.id];

    return !prevFilter || prevFilter.value?.isCropped || !nextFilter.value?.isCropped
      ? nextFilter
      : prevFilter;
  });
}

export const enhancer =
  (types, reducer) =>
  (state = initialState, action = {}) => {
    switch (action.type) {
      case types.SET_ENGINE:
        return {
          ...state,
          engine: action.engine,
        };
      case types.LOAD: {
        const isFirstRequest =
          state.id !== action.id || action.pageToken !== state.data.nextPageToken;
        // initial data loading has precedence over next items data loading
        if (
          state.loading &&
          state.pageToken === START_PAGE_TOKEN &&
          action.pageToken !== START_PAGE_TOKEN
        ) {
          return state;
        }
        return {
          ...state,
          categoryGraph: isSameSearchWithDifferentCategoryId(action.id, state.id)
            ? state.categoryGraph
            : null,
          data: isFirstRequest ? {} : state.data,
          error: null,
          id: action.id,
          pageToken: action.pageToken,
          loaded: isFirstRequest ? null : state.loaded,
          loading: true,
          searchSession: action.searchSession,
        };
      }
      case types.LOAD_SUCCESS: {
        const isLastRequest = state.id === action.id && state.pageToken === action.pageToken;
        const isFirstRequest =
          action.pageToken !== state.data.nextPageToken || action.pageToken === START_PAGE_TOKEN;
        const {items, headerItems, header, ...restResultData} = action.result.data;

        const filtersInHeader = header?.filters || undefined;

        return isLastRequest
          ? {
              ...state,
              ...(isFirstRequest && {categoryGraph: action.result.categoryGraph}),
              data: {
                ...state.data,
                ...restResultData,
                ...(header?.filters ?? {}),
                header: isFirstRequest ? header : header || state.data.header,
                ...(!isFirstRequest && {
                  availableFilters: mergeFilters(
                    state.data.availableFilters,
                    header?.filters?.availableFilters,
                  ),
                  availableSorting: filtersInHeader
                    ? header.filters.availableSorting
                    : state.data.availableSorting,
                }),
                nextPageToken: restResultData.nextPageToken,
                itemsData: appendContentListItems(
                  state.data.itemsData,
                  items,
                  action.pageToken === state.data.nextPageToken,
                  !restResultData.nextPageToken,
                ),
                headerItems: isFirstRequest ? headerItems : state.data?.headerItems,
              },
              loading: false,
              loaded: assignSet(state.loaded || {}, action.pageToken, true),
              subCategory: action.result.subCategory,
            }
          : state;
      }
      case types.LOAD_FAIL:
        return {
          ...state,
          error: action.error,
          loading: false,
        };
      case types.UPDATE_SEARCH_SESSION:
        return {
          ...state,
          searchSession: {
            ...state.searchSession,
            ...action.payload,
          },
        };
      case addProductToCollection.success.getType():
      case removeProductFromCollection.success.getType(): {
        return produce(state, (draft) => {
          const items = [
            ...(draft.data?.itemsData?.items || []),
            ...(draft.data?.headerItems || []),
          ];

          handleContentListProducts(items, (product) => {
            if (product.id === action.meta.itemKey.productId) {
              // eslint-disable-next-line no-param-reassign
              product.favorite = isActionOf(addProductToCollection.success, action);
            }
          });
        });
      }
      case editProductsInCollections.success.getType(): {
        return produce(state, (draft) => {
          action.payload.collectionsUpdatedProductInfoList.forEach(({productId, isFavorite}) => {
            const items = [
              ...(draft.data?.itemsData?.items || []),
              ...(draft.data?.headerItems || []),
            ];

            handleContentListProducts(items, (product) => {
              if (product.id === productId) {
                // eslint-disable-next-line no-param-reassign
                product.favorite = isFavorite;
              }
            });
          });
        });
      }

      case addCollectionToFavorites.success.getType(): {
        return produce(state, (draft) => {
          if (draft.data?.header) {
            // eslint-disable-next-line no-param-reassign
            draft.data.header.isLiked = true;
            Object.keys(draft.data.header.buttons || {}).forEach((place) => {
              const likeButtonData =
                (
                  draft.data.header.buttons[place].find(
                    (button) => 'likeProductCollectionButton' in button,
                  ) || {}
                ).likeProductCollectionButton || {};

              likeButtonData.isLiked = true;
            });
          }
        });
      }

      case removeCollectionFromFavorites.success.getType(): {
        return produce(state, (draft) => {
          if (draft.data?.header) {
            // eslint-disable-next-line no-param-reassign
            draft.data.header.isLiked = false;
            Object.keys(draft.data.header.buttons).forEach((place) => {
              const likeButtonData =
                (
                  draft.data.header.buttons[place].find(
                    (button) => 'likeProductCollectionButton' in button,
                  ) || {}
                ).likeProductCollectionButton || {};
              likeButtonData.isLiked = false;
            });
          }
        });
      }

      case applyOffers.success.getType(): {
        return produce(state, (draft) => {
          action.meta.applyOffers.forEach(({id}) => {
            const header = draft.data?.header;

            if (header.id === id) {
              const promotionOfferBanner = header?.background?.defaultWeb?.offerBanner;

              if (promotionOfferBanner) {
                promotionOfferBanner.state = OfferBannerState.APPLIED;
              }
            }
          });
        });
      }

      default:
        return reducer(state, action);
    }
  };

function getSearchFilter(searchRequest, id) {
  return (
    searchRequest &&
    searchRequest.filters &&
    searchRequest.filters.find((filter) => id === filter.id)
  );
}

export const getSearchId = memoizeLastShallowEqual((searchRequest) => {
  const hash = hashCode(JSON.stringify(sortedObject(searchRequest))).toString(36);
  const categoryFilter = getSearchFilter(searchRequest, 'categoryId');

  const categoryId = getValueByPath(categoryFilter, 'value', 'items', 0, 'id');
  const showOwnCategories =
    categoryId ||
    getSearchFilter(searchRequest, 'productTags') ||
    getSearchFilter(searchRequest, 'brand') ||
    searchRequest.origin ||
    searchRequest.query;

  const ownCategoriesStr = showOwnCategories ? `:${SHOW_OWN_CATEGORIES}` : '';
  return `${categoryId ? `${categoryId}:${hash}` : hash}${ownCategoriesStr}`;
});

export function isLoaded(state, searchRequest, pageToken) {
  const {loaded, id} = state;
  if (loaded && id === getSearchId(searchRequest)) {
    return !pageToken || !!loaded[pageToken];
  }
  return false;
}

export function isOutdated(state, searchRequest) {
  return isLoaded(state, searchRequest) && !isLoaded(state, searchRequest, START_PAGE_TOKEN);
}

export function isLoading(state) {
  return !!state.loading;
}

export function getError(state) {
  return state.error || null;
}

export function getProducts(state, searchRequest) {
  return isLoaded(state, searchRequest) ? getItemsToShow(state.data.itemsData) : null;
}

export function hasCroppedFilters(state, searchRequest) {
  if (!isLoaded(state, searchRequest)) {
    return false;
  }

  return state.data?.availableFilters?.some((filter) => filter?.value?.isCropped) || false;
}

export function hasCategories(state) {
  return !!state.categoryGraph;
}

export function needToUseCategories(state) {
  const {id} = state;
  return !!id && id.indexOf(SHOW_OWN_CATEGORIES) !== -1;
}

export function getCategory(state, params, id) {
  if (!hasCategories(state, params)) {
    return null;
  }

  return state.categoryGraph[id] || null;
}

function getCategoryViewFromAppliedFilters(state, params) {
  if (!isLoaded(state, params)) {
    return null;
  }

  const appliedFilters = getValueByPath(state, 'data', 'appliedFilters');
  if (!appliedFilters) {
    return null;
  }

  const categoryFilter = appliedFilters.find((filter) => filter.id === 'categoryId');
  const category = getValueByPath(categoryFilter, 'value', 'items', 0);
  if (!category) {
    return null;
  }

  return {
    category,
    parent: category.parentId ? {id: category.parentId, name: ''} : null,
    children: [],
    root: !category.parentId,
  };
}

export function getCategoryView(state, params, id) {
  const {categoryGraph} = state;

  if (!categoryGraph) {
    return getCategoryViewFromAppliedFilters(state, params);
  }

  const category = categoryGraph[id];
  if (!category) {
    return getCategoryViewFromAppliedFilters(state, params);
  }

  return {
    category: category.id ? category : null,
    parent: (category.parent && categoryGraph[category.parent]) || null,
    children: category.children.map((child) => categoryGraph[child]),
    childrenLoading: Boolean(category.childrenLoading),
    root: !!category.root,
  };
}

export function getNextPageToken(state, searchRequest) {
  return isLoaded(state, searchRequest) ? state.data.nextPageToken : null;
}

export function getSearchResultHelp(state, searchRequest) {
  return isLoaded(state, searchRequest) ? state.data.searchResultHelp : null;
}

export function getItemsData(state) {
  return state.data.itemsData || null;
}

export function isEmpty(state, searchRequest) {
  return (
    isLoaded(state, searchRequest) &&
    !getNextPageToken(state, searchRequest) &&
    isContentListWithoutProducts(state.data.itemsData.items)
  );
}

export function getFiltersAndSortings(state, searchRequest) {
  return isLoaded(state, searchRequest)
    ? {
        availableFilters: state.data.availableFilters,
        availableSorting: state.data.availableSorting,
        appliedFilters: state.data.appliedFilters,
        appliedSorting: state.data.appliedSorting && state.data.appliedSorting[0],
        featuredCategories: state.data?.featuredCategories,
      }
    : null;
}

export function createCategoryGraph(
  {availableFilters = [], appliedFilters = []},
  language,
  addSiblingsToLeafCategory,
) {
  const categoryFilter = appliedFilters.find((filter) => filter.id === 'categoryId');
  const subCategoryFilter = availableFilters.find((filter) => filter.id === 'subCategoryId');
  if (!subCategoryFilter) {
    return null;
  }

  const categoryData = categoryFilter && categoryFilter.value.items[0];
  const {value} = subCategoryFilter;

  if (value?.isCropped) {
    return convertHierarchyToGraph(
      {
        ...(categoryData || {}),
        parents: categoryData ? [{id: categoryData.parentId, name: ''}] : null,
        children: [],
        childrenLoading: true,
      },
      language,
    );
  }
  if (!value || !value.items || !value.items.length) {
    return null;
  }

  let graph;

  // getting parent category with siblings for leaf category
  if (addSiblingsToLeafCategory && isLeafCategory(categoryData) && value.items.length === 1) {
    const data = value.items[0];
    graph = convertHierarchyToGraph(
      {
        ...data,
        parents: [{id: data.parentId, name: ''}],
      },
      language,
    );
  } else {
    graph = convertHierarchyToGraph(
      {
        ...(categoryData || {}),
        parents: categoryData ? [{id: categoryData.parentId, name: ''}] : null,
        hasPublicChildren: true,
        children: value.items,
      },
      language,
    );
  }

  Object.keys(graph).forEach((id) => {
    const category = graph[id];
    if (!category.children || category.children.length === 0) {
      category.hasPublicChildren = false;
    }
  });

  return graph;
}

export function retrieveSubCategory({availableFilters = []}) {
  const subCategoryFilter = availableFilters.find((filter) => filter.id === 'subCategoryId');

  if (!subCategoryFilter) {
    return null;
  }

  const categoryData = subCategoryFilter && subCategoryFilter.value.items;

  return cloneDeep(categoryData);
}

export function getSearchEngine(state) {
  return state.engine || '';
}

export function setSearchEngine(type, engine) {
  return {
    type,
    engine,
  };
}

export function search(
  endpoint,
  types,
  searchRequest,
  pageToken = null,
  count = null,
  productHandler,
  searchSession,
  otherParams,
) {
  const id = getSearchId(searchRequest);
  const {searchRequestId, searchSessionId, previousSearchSessionId} = searchSession;
  const body = {
    ...otherParams,
    ...searchRequest,
    count: count || config.productsPageSize,
    searchRequestId,
    searchSessionId,
    previousSearchSessionId,
  };

  if (pageToken && pageToken !== START_PAGE_TOKEN) {
    body.pageToken = pageToken;
  }

  return {
    types,
    id,
    pageToken: pageToken || START_PAGE_TOKEN,
    searchSession,
    promise: (client) =>
      client.api
        .post(endpoint, {body, retry: true})
        .then(({language, currency, body: {contexts, payload}}) => ({
          subCategory: retrieveSubCategory(payload),
          categoryGraph: createCategoryGraph(
            payload?.header?.filters ?? payload,
            language,
            client.device.getDeviceVar('searchAddSiblingsToLeafCategoryFilters'),
          ),
          data: {
            ...payload,
            headerWasLoadedAtLeastOnce: Boolean(payload?.header),
            items: enhanceContentListItems(
              payload.items,
              language,
              currency,
              pageToken,
              contexts,
              productHandler,
            ),
            headerItems: payload?.header?.items
              ? enhanceContentListItems(
                  payload?.header?.items,
                  language,
                  currency,
                  undefined,
                  contexts,
                  productHandler,
                )
              : undefined,
            currency,
            language,
          },
        })),
  };
}
