import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import {Locator} from 'components/Locator';
import {ErrorIllustration} from 'components/ErrorIllustration';
import {useScope} from 'hooks/useScope';
import ErrorShape from 'shapes/Error';
import {useSupportEmail} from 'hooks/useSupportEmail';
import {Scope} from 'config';
import styles from './index.scss';
import {SplashMessage} from '../SplashMessage';

export function Oops({asPage, children, title, message, error}) {
  const scope = useScope();
  const supportEmail = useSupportEmail();

  return (
    <SplashMessage asPage={asPage}>
      {scope.is(Scope.GLOBAL) && (
        <SplashMessage.Image>
          <Locator id="OopsImage">
            <div className={styles.illustration}>
              <ErrorIllustration source="oops" error={error} />
            </div>
          </Locator>
        </SplashMessage.Image>
      )}
      <SplashMessage.Title>
        {title || (
          <FormattedMessage
            description="Header of Oops block"
            defaultMessage="Oops! Something went wrong."
          />
        )}
      </SplashMessage.Title>
      <SplashMessage.Text>
        {message || (
          <FormattedMessage
            description="Text of PageNotFound block"
            defaultMessage="Try again or contact our support team: {email}"
            values={{
              email: (
                <a key="email" className={styles.link} href={`mailto:${supportEmail}`}>
                  {supportEmail}
                </a>
              ),
            }}
          />
        )}
      </SplashMessage.Text>
      {!!children && <SplashMessage.Actions>{children}</SplashMessage.Actions>}
    </SplashMessage>
  );
}

Oops.propTypes = {
  asPage: PropTypes.bool,
  children: PropTypes.node,
  title: PropTypes.node,
  message: PropTypes.node,
  error: ErrorShape,
};

Oops.defaultProps = {
  asPage: false,
  children: null,
  title: null,
  message: null,
  error: null,
};
