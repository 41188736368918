import {Locator} from 'components/Locator';
import React from 'react';
import {FormattedMessage} from 'react-intl';

import {FooterColumn} from '../FooterColumn';

export function FooterColumnJoomGroup(): JSX.Element {
  const joomGroupColumnTitle = (
    <FormattedMessage
      description="Joom Group Companies header in footer"
      defaultMessage="Joom Group сompanies"
    />
  );

  return (
    <FooterColumn title={joomGroupColumnTitle}>
      <Locator id="FooterLinkJoomLogistics">
        <FooterColumn.Item to="https://joomlogistics.com">
          <FormattedMessage
            description="Joom Logistics link text in footer"
            defaultMessage="Joom Logistics"
          />
        </FooterColumn.Item>
      </Locator>
      <Locator id="FooterLinkOnfy">
        <FooterColumn.Item to="https://onfy.de/">
          <FormattedMessage description="Onfy link text in footer" defaultMessage="Onfy" />
        </FooterColumn.Item>
      </Locator>
    </FooterColumn>
  );
}
