import {CSSProperties, useMemo} from 'react';
import {Color} from 'types/Color';
import {Gradient} from 'types/Gradient';
import {
  convertBackendColorToCSSValue,
  convertBackendGradientToCSSValue,
  isGradient,
} from 'utils/styles/color';

export enum StyleProperty {
  BACKGROUND = 'background',
  COLOR = 'color',
}

type Props = {
  color?: Color;
  background?: Gradient | Color;
};

export function useColorStyles(props: Props): CSSProperties | undefined {
  return useMemo(() => {
    const color = convertBackendColorToCSSValue(props.color);
    const background = isGradient(props.background)
      ? convertBackendGradientToCSSValue(props.background)
      : convertBackendColorToCSSValue(props.background);

    if (!color && !background) {
      return undefined;
    }
    return {color, background};
  }, [props.color, props.background]);
}
