import {AllAttributes} from 'components/ClientProduct/Attributes/AllAttributes';
import {Group} from 'components/ClientProduct/Attributes/Group';
import styles from 'components/ClientProduct/Attributes/index.scss';
import {Block, BlockHeader} from 'components/ClientProduct/Block';
import {useAnalytics} from 'hooks/useAnalytics';
import React, {useCallback, useState} from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';
import {ProductHeaderIncomplete} from 'types/ProductNext/Header';

const messages = defineMessages({
  showFeatured: {
    description: 'Кнопка для скрытия всех характеристик на странице продукта',
    defaultMessage: 'Show main characteristics only',
  },
  showAll: {
    description: 'Кнопка для отображения всех характеристик на странице продукта',
    defaultMessage: 'Show all characteristics',
  },
});

export type Props = {
  createTime: number;
  productHeader: ProductHeaderIncomplete;
};

export const Attributes = React.memo(function Attributes({
  createTime,
  productHeader,
}: Props): React.ReactElement | null {
  const {attributes} = productHeader;
  const analytics = useAnalytics();
  const [expanded, setExpanded] = useState(false);

  const handleClick = useCallback(() => {
    const sinceOpenMs = Date.now() - createTime;
    if (!productHeader) {
      return;
    }

    const payload = {productId: productHeader.id, sinceOpenMs};

    if (expanded) {
      analytics.sendEvent({type: 'productAttributesClose', payload});
    } else {
      analytics.sendEvent({type: 'productAttributesOpen', payload});
    }

    setExpanded(!expanded);
  }, [analytics, createTime, expanded, productHeader]);

  if (!attributes) {
    return null;
  }

  const {featured, all} = attributes;
  const actionsVisible = Boolean(all);

  return (
    <>
      {!expanded && <Group group={featured} />}
      {expanded && <AllAttributes attributes={attributes} />}
      {actionsVisible && (
        <div className={styles.actions}>
          {all && (
            <button className={styles.expandButton} onClick={handleClick} type="button">
              <FormattedMessage {...(expanded ? messages.showFeatured : messages.showAll)} />
            </button>
          )}
        </div>
      )}
    </>
  );
});

export const AttributesBlock: React.FC<Props> = (props) => {
  const {productHeader} = props;
  const attributes = productHeader?.attributes;

  if (!attributes) {
    return null;
  }

  return (
    <Block testId="AttributesBlockOnProductPage">
      <BlockHeader>{attributes.title}</BlockHeader>
      <Attributes {...props} />
    </Block>
  );
};
