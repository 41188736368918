import {SmallCircle} from 'components/ContentList/BannersList/SmallCircle';
import type {ImageProps} from 'components/Image/types';
import {type Locator, type Mark, createLocator} from 'create-locator';
import React, {type ComponentType, useRef} from 'react';
import {defineMessages, useIntl} from 'react-intl';
import {
  type BannersList as BannersListType,
  BannersListDesign,
} from 'types/ContentList/BannersList';
import {isContentListBannersListFreeForm} from 'types/ContentList/ContentListBannersList';

import {Header} from '../Header';
import {useBannersPreview, useSendBannersScroll} from '../hooks';
import type {BannerProps} from './BannerProps';
import {CardGrid} from './CardGrid';
import {FullSheetLong} from './FullSheetLong';
import {type SmallImageGridLocator, SmallImageGrid} from './SmallImageGrid';
import {ThreeInRow} from './ThreeInRow';
import {normalizeBannerAppearance} from './utils';

export type BannersListLocator = Locator<{
  banner: SmallImageGridLocator;
}>;

type BannersListProps = {
  imageProps?: Partial<ImageProps>;
  content: BannersListType;
  id: string;
  source?: string;
  notClickable?: boolean;
  small: boolean;
  kind?: 'default' | 'promotionBanner';
} & Partial<Mark<BannersListLocator>>;

type BannersListRenderProps = {
  mobile?: boolean;
};

const BANNER_MAP: Record<
  BannersListDesign,
  ComponentType<BannerProps & Mark<SmallImageGridLocator>>
> = {
  [BannersListDesign.FULL_SHEET_LONG]: FullSheetLong,
  [BannersListDesign.SMALL_IMAGE_GRID]: SmallImageGrid,
  [BannersListDesign.SMALL_CIRCLE]: SmallCircle,
  [BannersListDesign.THREE_IN_ROW]: ThreeInRow,
  [BannersListDesign.CARD_GRID]: CardGrid,
};

const messages = defineMessages({
  region: {
    description: '[a11y] Обозначение региона с каруселью акций',
    defaultMessage: 'Promo carousel',
  },
});

export function canRenderBannersList(
  {design, items}: BannersListType,
  {mobile}: BannersListRenderProps,
): boolean {
  switch (design) {
    case BannersListDesign.FULL_SHEET_LONG:
    case BannersListDesign.THREE_IN_ROW:
    case BannersListDesign.CARD_GRID:
      return true;

    case BannersListDesign.SMALL_IMAGE_GRID:
    case BannersListDesign.SMALL_CIRCLE:
      return mobile ? items.length >= 3 : items.length >= 5;

    default:
      return false;
  }
}

export const BannersList = React.memo(function BannersList(
  props: BannersListProps,
): JSX.Element | null {
  const {
    content: {bannerAppearance, design, headerAppearance, items, eventParams},
    imageProps,
    id,
    source,
    small,
  } = props;
  const locator = createLocator(props);
  const intl = useIntl();
  const rootRef = useRef<HTMLDivElement>(null);
  const linkRef = useRef(null);
  const titleRef = useRef(null);
  const handleScroll = useSendBannersScroll(id);
  const normalizedBannerAppearance = normalizeBannerAppearance(bannerAppearance);

  useBannersPreview({
    bannersBlockId: id,
    clickRefs: [linkRef, titleRef],
    eventParams,
    rootRef,
  });

  const Banner = BANNER_MAP[design];

  const content = Banner && (
    <Banner
      imageProps={imageProps}
      bannerAppearance={normalizedBannerAppearance}
      items={items}
      onScroll={handleScroll}
      source={source}
      small={small}
      notClickable={props.notClickable}
      {...locator.banner()}
    />
  );

  if (isContentListBannersListFreeForm(props.content)) {
    return React.cloneElement(content, {ref: rootRef});
  }

  return (
    <div
      ref={rootRef}
      role="region"
      aria-label={intl.formatMessage(messages.region)}
      {...locator()}
    >
      {headerAppearance && headerAppearance.simple && (
        <Header header={headerAppearance.simple} titleRef={titleRef} linkRef={linkRef} />
      )}
      {content}
    </div>
  );
});
