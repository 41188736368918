import React from 'react';

import styles from './index.scss';

type Props = React.PropsWithChildren<{
  type?: 'flex' | 'grid';
  groupId?: string;
}>;

export const Group = React.memo(function Row({type = 'flex', children}: Props): React.ReactElement {
  return <div className={styles[type]}>{children}</div>;
});
