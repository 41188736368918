import {useColorStyles} from 'hooks/useColorStyles';
import React from 'react';
import {DiagonalGalleryOverlay as DiagonalGalleryOverlayType} from 'types/ProductBadge';

import styles from './index.scss';

type Props = {
  overlay: DiagonalGalleryOverlayType;
};

export function DiagonalGalleryOverlay({overlay: {diagonal}}: Props): JSX.Element | null {
  const diagonalStyles = useColorStyles({
    color: diagonal?.title?.color,
    background: diagonal?.background,
  });

  return (
    <div className={styles.ribbon}>
      <div style={diagonalStyles} className={styles.ribbonInner}>
        <div className={styles.ribbonText}>{diagonal.title.text}</div>
      </div>
    </div>
  );
}
