import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import CheckIcon from 'components/icons/check.jsx.svg';
import {Locator} from 'components/Locator';

import styles from './index.scss';

const cn = classnames.bind(styles);

export const CheckboxShape = {
  NONE: 'none',
  ROUNDED: 'rounded',
  CIRCLE: 'circle',
};

export const CheckboxType = {
  CHECKBOX: 'checkbox',
  RADIO: 'radio',
};

export const CheckboxLabelPosition = {
  BEFORE: 'before',
  AFTER: 'after',
};

const defaultShapeByType = {
  [CheckboxType.CHECKBOX]: CheckboxShape.ROUNDED,
  [CheckboxType.RADIO]: CheckboxShape.CIRCLE,
};

export default class Checkbox extends Component {
  static propTypes = {
    block: PropTypes.bool,
    centered: PropTypes.bool,
    checked: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    inline: PropTypes.bool,
    labelPosition: PropTypes.oneOf(Object.values(CheckboxLabelPosition)),
    invisible: PropTypes.bool,
    loading: PropTypes.bool,
    id: PropTypes.string,
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onCheck: PropTypes.func,
    onFocus: PropTypes.func,
    shape: PropTypes.oneOf(Object.values(CheckboxShape)),
    size: PropTypes.oneOf(['s', 'm', 'l']),
    theme: PropTypes.oneOf(['normal', 'lightgray', 'darkblue']),
    type: PropTypes.oneOf(Object.values(CheckboxType)),
    value: PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.string]),
    attrs: PropTypes.object,
    'data-testid': PropTypes.string,
  };

  static defaultProps = {
    block: false,
    centered: false,
    checked: false,
    children: null,
    className: '',
    disabled: false,
    inline: false,
    labelPosition: CheckboxLabelPosition.AFTER,
    loading: false,
    id: null,
    name: '',
    onBlur: null,
    onCheck: null,
    onFocus: null,
    shape: null,
    size: 'm',
    theme: 'normal',
    type: CheckboxType.CHECKBOX,
    value: undefined,
    attrs: {},
    'data-testid': '',
    invisible: false,
  };

  constructor(props) {
    super(props);

    this.inputRef = React.createRef();

    this.state = {
      checked: props.checked,
    };
  }

  componentDidUpdate(prevProps) {
    const {checked, loading} = this.props;
    if (checked !== prevProps.checked || (prevProps.loading && !loading)) {
      if (this.inputRef.current) {
        this.inputRef.current.checked = checked;
      }

      this.setState({checked});
    }
  }

  getInput() {
    return this.inputRef.current;
  }

  handleChange = (evt) => {
    const {onCheck} = this.props;
    const checked = !!evt.target.checked;

    this.setState({checked}, onCheck ? () => onCheck(checked) : null);
  };

  render() {
    const {
      className,
      disabled,
      name,
      type,
      value,
      id,
      children,
      labelPosition,
      centered,
      inline,
      onBlur,
      onFocus,
      shape,
      size,
      theme,
      block,
      attrs,
      invisible,
      'data-testid': testId,
    } = this.props;
    const {checked} = this.state;
    const hasLabel = !!children;
    const label = hasLabel && (
      <div className={cn(styles.label, `position-${invisible ? 'none' : labelPosition}`)}>
        {children}
      </div>
    );

    const checkboxClassName = cn(
      'checkbox',
      `size-${size}`,
      `theme-${theme}`,
      `shape-${shape || defaultShapeByType[type]}`,
      {[styles.invisible]: invisible},
    );

    const testIdAttribute = testId ? {'data-testid': testId} : {};

    return (
      <Locator id="CheckboxLabel" value={value}>
        <label className={cn('parent', className, {disabled, inline, block})}>
          <div className={cn('container', {centered})}>
            <input
              /**
               * A mousetrap class prevents blocking of events for inputs.
               * @see https://github.com/ccampbell/mousetrap/blob/2f9a476ba6158ba69763e4fcf914966cc72ef433/mousetrap.js#L977
               * @see https://craig.is/killing/mice
               */
              className={cn('input', 'mousetrap')}
              defaultChecked={checked}
              disabled={disabled}
              id={id}
              name={name}
              onBlur={onBlur}
              onChange={this.handleChange}
              onFocus={onFocus}
              ref={this.inputRef}
              type={type}
              value={value}
              {...attrs}
              {...testIdAttribute}
            />

            {labelPosition === CheckboxLabelPosition.BEFORE && label}
            <div className={checkboxClassName}>
              <Locator id="CheckboxCheckIconContextMenu">
                <div className={styles.border} />
              </Locator>
              <Locator id="CheckboxCheckIcon">
                <CheckIcon className={styles.checkIcon} />
              </Locator>
            </div>
            {labelPosition === CheckboxLabelPosition.AFTER && label}
          </div>
        </label>
      </Locator>
    );
  }
}
