import {ecsError} from 'helpers/log/ECS/ecsError';
import {useDispatch} from 'hooks/redux';
import {useEffectOnce} from 'hooks/useEffectOnce';
import {useLogger} from 'hooks/useLogger';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {isDefAndNotNull} from 'utils/function';
import {isNotNullish, isNumber} from 'utils/guards';
import uuid from 'uuid/v4';

import {
  MediaBundle,
  MediaFilter,
  MediaState,
  MediaStatesList,
  MediaStatesMap,
  MediaStatus,
  UploaderError,
  UploaderErrorType,
  UploaderOptions,
  UploaderState,
} from '../types';
import {createMediaStateByFile} from '../utils/createMediaStateByFile';
import {createMediaStateByMediaBundle} from '../utils/createMediaStateByMediaBundle';
import {createUploaderError} from '../utils/createUploaderError';
import {createUploaderErrorByError} from '../utils/createUploaderErrorByError';
import {startMediaUploading} from '../utils/startMediaUploading';
import {stopMediaUploading} from '../utils/stopMediaUploading';

export type UseMediaUploaderOptions = {
  filters: MediaFilter[];
  maxCount?: number;
  onChange?: (mediaBundlesList: MediaBundle[]) => void;
  onError?: (error: UploaderError) => void;
  value?: MediaBundle[];
};

export type UseMediaUploaderReturnType = {
  addMedia: (file: File) => void;
  mediaStatesList: MediaStatesList;
  mediaStatesMap: MediaStatesMap;
  removeMedia: (mediaUuid: string) => void;
  retryMediaUploading: (mediaUuid: string) => Promise<void>;
};

export const useMediaUploader = (options: UseMediaUploaderOptions): UseMediaUploaderReturnType => {
  const {onChange, onError} = options;
  const logger = useLogger('useMediaUploader');
  const dispatch = useDispatch();
  const [mediaUuidList, setMediaUuidList] = useState<string[]>([]);
  const [mediaStatesMap, setMediaStatesMap] = useState<Record<string, MediaState>>({});

  const mediaStatesList = useMemo(
    () => mediaUuidList.map((uid) => mediaStatesMap[uid]).filter(isDefAndNotNull),
    [mediaStatesMap, mediaUuidList],
  );

  const commitMediaInState = useCallback(
    <T extends MediaState>(mediaUuid: string, mediaState: T): T => {
      setMediaStatesMap((prevMediaStatesMap) => ({
        ...prevMediaStatesMap,
        [mediaUuid]: mediaState,
      }));

      return mediaState;
    },
    [],
  );
  const patchMediaInState = useCallback(
    <T extends MediaState>(mediaUuid: string, patchMediaState: Partial<T>): void => {
      setMediaStatesMap((prevMediaStatesMap) => {
        const prevMediaState = prevMediaStatesMap[mediaUuid];

        if (prevMediaState) {
          return {
            ...prevMediaStatesMap,
            [mediaUuid]: {
              ...prevMediaState,
              ...patchMediaState,
            },
          };
        }

        return prevMediaStatesMap;
      });
    },
    [],
  );
  const addMediaToState = useCallback((mediaUuid: string, mediaState: MediaState): void => {
    setMediaUuidList((prevMediaUuidList) => prevMediaUuidList.concat(mediaUuid));
    setMediaStatesMap((prevMediaStatesMap) => ({
      ...prevMediaStatesMap,
      [mediaUuid]: mediaState,
    }));
  }, []);
  const removeMediaFromState = useCallback((mediaUuid: string): void => {
    setMediaUuidList((prevMediaUuidList) =>
      prevMediaUuidList.filter((prevMediaUuid) => prevMediaUuid !== mediaUuid),
    );
    setMediaStatesMap((prevMediaStatesMap) => {
      const nextMediaStatesMap = {...prevMediaStatesMap};

      delete nextMediaStatesMap[mediaUuid];
      return nextMediaStatesMap;
    });
  }, []);

  const addMedia = useCallback(
    (file: File) => {
      try {
        const mediaUuid = uuid();
        const mediaState = createMediaStateByFile(mediaUuid, file);

        addMediaToState(mediaUuid, mediaState);
      } catch (error) {
        logger.error({
          error: ecsError(error),
        });
      }
    },
    [addMediaToState, logger],
  );
  const removeMedia = useCallback(
    async (mediaUuid: string) => {
      patchMediaInState(mediaUuid, {
        status: MediaStatus.WILL_BE_DELETED,
      });
    },
    [patchMediaInState],
  );
  const retryMediaUploading = useCallback(
    async (mediaUuid: string) => {
      patchMediaInState(mediaUuid, {
        status: MediaStatus.WILL_BE_UPLOADED,
      });
    },
    [patchMediaInState],
  );
  const catchError = useCallback(
    (error: unknown) => {
      const uploaderError = createUploaderErrorByError(error);

      logger.error({
        error: ecsError(uploaderError),
      });
      onError?.(uploaderError);
    },
    [logger, onError],
  );
  const catchMediaError = useCallback(
    (mediaUuid: string, error: unknown) => {
      const uploaderError = createUploaderErrorByError(error);

      patchMediaInState(mediaUuid, {
        error: uploaderError,
        meta: undefined,
        status: MediaStatus.ERROR,
      });
      catchError(error);
    },
    [catchError, patchMediaInState],
  );

  // Init
  const prevMediaBundlesListRef = useRef<MediaBundle[]>([]);
  useEffectOnce(() => {
    const nextMediaBundlesList = options.value;

    if (nextMediaBundlesList) {
      const nextMediaUidList: string[] = [];
      const nextMediaStatesMap: Record<string, MediaState> = {};

      nextMediaBundlesList.forEach((mediaBundle) => {
        const mediaUid = uuid();
        const mediaState = createMediaStateByMediaBundle(mediaUid, mediaBundle);

        nextMediaUidList.push(mediaUid);
        nextMediaStatesMap[mediaUid] = mediaState;
      });

      prevMediaBundlesListRef.current = nextMediaBundlesList;
      setMediaUuidList(nextMediaUidList);
      setMediaStatesMap(nextMediaStatesMap);
    }
  });

  // Handle change bundles
  useEffect(() => {
    const prevMediaBundlesList = prevMediaBundlesListRef.current;
    const mediaBundlesList = mediaStatesList.map(({bundle}) => bundle).filter(isNotNullish);
    const isChanged =
      mediaBundlesList.length !== prevMediaBundlesList.length ||
      mediaBundlesList.some((mediaBundle, index) => mediaBundle !== prevMediaBundlesList[index]);

    if (isChanged) {
      prevMediaBundlesListRef.current = mediaBundlesList;
      onChange?.(mediaBundlesList);
    }
  }, [mediaStatesList, onChange]);

  // Handle add and remove medias
  const uploaderOptionsValue: UploaderOptions = {
    commit: commitMediaInState,
    dispatch,
    getState: (): UploaderState => ({
      mediaStatesList,
      mediaStatesMap,
    }),
    filters: options.filters,
    maxCount: options.maxCount,
  };
  const uploaderOptionsRef = useRef(uploaderOptionsValue);
  uploaderOptionsRef.current = uploaderOptionsValue;

  useEffect(() => {
    const {maxCount} = uploaderOptionsRef.current;

    // Expect the number of media states to be less than or equal to the maximum possible number.
    if (isNumber(maxCount) && mediaStatesList.length > maxCount) {
      catchError(
        createUploaderError(UploaderErrorType.TOO_MANY_FILES, {
          maxCount,
        }),
      );
    }

    // Start uploading some media
    mediaStatesList.forEach((mediaState) => {
      if (mediaState.status === MediaStatus.WILL_BE_UPLOADED) {
        startMediaUploading(mediaState.uuid, uploaderOptionsRef.current).catch((error) =>
          catchMediaError(mediaState.uuid, error),
        );
      }
    });

    // Stop uploading some media
    mediaStatesList.forEach((mediaState) => {
      if (mediaState.status === MediaStatus.WILL_BE_DELETED) {
        stopMediaUploading(mediaState.uuid, uploaderOptionsRef.current)
          .then(() => removeMediaFromState(mediaState.uuid))
          .catch((error) => catchMediaError(mediaState.uuid, error));
      }
    });
  }, [catchError, catchMediaError, mediaStatesList, removeMediaFromState]);

  return {
    addMedia,
    mediaStatesList,
    mediaStatesMap,
    removeMedia,
    retryMediaUploading,
  };
};
