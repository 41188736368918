import cn from 'classnames';
import {ContextMenu} from 'components/ContextMenu';
import ErrorMessage from 'components/ErrorMessage';
import {Overlay} from 'components/Overlay';
import {Text} from 'components/Text';
import {Button} from 'components/UIKit/Button';
import {Icon as ButtonIcon} from 'components/UIKit/Button/Content';
import {Icon} from 'components/UIKit/Icon';
import {Popup} from 'components/UIKit/Popup';
import {Footer as PopupFooter} from 'components/UIKit/Popup/Footer';
import {Header as PopupHeader} from 'components/UIKit/Popup/Header';
import {PopupViewProps, PopupViewRender, usePopup} from 'components/UIKit/Popup/PopupProvider';
import {PassportFormPopup} from 'connectors/Passport/PassportFormPopup';
import {useDispatch, useSelector} from 'hooks/redux';
import {useEffectOnce} from 'hooks/useEffectOnce';
import {Toast, useOpenToast} from 'providers/Toast';
import React, {useCallback, useMemo, useState} from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';
import {deletePersonalData} from 'store/modules/personalData';
import {loadPassportData} from 'store/modules/personalData/passport';
import {
  getPassportData,
  getPassportDataError,
  isPassportDataLoaded,
  isPassportDataLoading,
} from 'store/modules/personalData/passport/selectors';

import {PASSPORT_FORM_ORIGIN, PASSPORT_METAINFO_ID} from './constants';
import styles from './index.scss';

const messages = defineMessages({
  deletionPopupTitle: {
    defaultMessage: 'Delete passport',
    description: '[title] Title of the popup with passport delete confirmation',
  },
  deletionPopupText: {
    defaultMessage:
      'Are you sure you want to delete your passport? A new passport can be added when ordering to a delivery point.',
    description: 'Content of the popup with passport delete confirmation',
  },
  deletionPopupCancelButtonLabel: {
    defaultMessage: 'Cancel',
    description: '[label] Button to cancel the deletion of passport information',
  },
  deletionPopupConfirmButtonLabel: {
    defaultMessage: 'Delete',
    description: '[label] Button to confirm the deletion of passport information',
  },
  deletionPopupConfirmationMessage: {
    defaultMessage: 'Passport information deleted',
    description: 'Message confirming the deletion of passport information',
  },
  infoPopupTitle: {
    defaultMessage: 'My passport',
    description: '[title] Title of the popup with passport information',
  },
  infoPopupCloseButtonLabel: {
    defaultMessage: 'OK',
    description: '[label] Button to close the popup with passport information',
  },
  infoPopupDeleteButtonLabel: {
    defaultMessage: 'Delete',
    description: '[label] Button to delete passport information',
  },
  infoPopupEditButtonLabel: {
    defaultMessage: 'Edit',
    description: '[label] Button to edit passport information',
  },
  infoPopupNoDataMessage: {
    defaultMessage: 'No passport information',
    description: 'Message about the absence of data in the popup with passport information',
  },
});

// Passport Info Editing Popup

const createPassportInfoEditingPopupView = (): PopupViewRender => {
  return ({onBack, onClose}: PopupViewProps) => (
    <PassportFormPopup
      metainfoId={PASSPORT_METAINFO_ID}
      onClose={onBack ?? onClose}
      onFormSave={onBack ?? onClose}
      personalDataFormOrigin={PASSPORT_FORM_ORIGIN}
    />
  );
};

// Passport Info Deletion Popup

const PASSPORT_INFO_DELETION_POPUP_WIDTH = '400px';

function PassportInfoDeletionPopup({onBack, onClose}: PopupViewProps): JSX.Element {
  const dispatch = useDispatch();
  const openToast = useOpenToast();
  const [deletion, setDeletion] = useState(false);

  const handleDelete = useCallback(async () => {
    setDeletion(true);

    try {
      await dispatch(deletePersonalData(PASSPORT_FORM_ORIGIN));
      openToast((toastProps) => (
        <Toast
          {...toastProps}
          title={<FormattedMessage {...messages.deletionPopupConfirmationMessage} />}
        />
      ));
      onClose();
    } catch {
      setDeletion(false);
    }
  }, [dispatch, onClose, openToast]);

  return (
    <Popup width={PASSPORT_INFO_DELETION_POPUP_WIDTH}>
      <Overlay loading={deletion}>
        <div className={styles.deletionPopupContent}>
          <h2 className={styles.deletionPopupTitle}>
            <FormattedMessage {...messages.deletionPopupTitle} />
          </h2>
          <div className={styles.deletionPopupMessage}>
            <FormattedMessage {...messages.deletionPopupText} />
          </div>
          <div className={styles.deletionPopupFooter}>
            <div className={styles.deletionPopupFooterItem}>
              <Button color="gray" onClick={onBack} size="large" tag="button">
                <FormattedMessage {...messages.deletionPopupCancelButtonLabel} />
              </Button>
            </div>
            <div className={styles.deletionPopupFooterItem}>
              <Button color="accent" onClick={handleDelete} size="large" tag="button">
                <FormattedMessage {...messages.deletionPopupConfirmButtonLabel} />
              </Button>
            </div>
          </div>
        </div>
      </Overlay>
    </Popup>
  );
}

const createPassportInfoDeletionPopupView = (): PopupViewRender => {
  return (popupViewProps) => <PassportInfoDeletionPopup {...popupViewProps} />;
};

// Passport Info Popup

const PASSPORT_INFO_POPUP_WIDTH = '530px';

export function PassportInfoPopup({onClose}: PopupViewProps): JSX.Element | null {
  const dispatch = useDispatch();
  const loaded = useSelector(isPassportDataLoaded);
  const loading = useSelector(isPassportDataLoading);
  const info = useSelector(getPassportData);
  const error = useSelector(getPassportDataError);
  const [actionsVisible, setActionsVisibility] = useState(false);
  const shouldShowNoData = Boolean(loaded && !error && !info?.fields?.length);
  const shouldShowError = Boolean(error && !info?.fields?.length);

  const editingPopup = usePopup(useMemo(createPassportInfoEditingPopupView, [onClose]));
  const deletionPopup = usePopup(useMemo(createPassportInfoDeletionPopupView, []));

  const handleToggleActions = useCallback(() => {
    setActionsVisibility((prevActionsVisibility) => !prevActionsVisibility);
  }, []);
  const handleDeleteClick = useCallback(() => {
    setActionsVisibility(false);
    deletionPopup.open();
  }, [deletionPopup]);
  const handleEditClick = useCallback(() => {
    setActionsVisibility(false);
    editingPopup.open();
  }, [editingPopup]);

  useEffectOnce(() => {
    if (!loaded || error) {
      dispatch(loadPassportData(PASSPORT_FORM_ORIGIN));
    }
  });

  return (
    <Popup width={PASSPORT_INFO_POPUP_WIDTH}>
      <PopupHeader onClose={onClose}>
        <span className={styles.infoPopupTitle}>
          <FormattedMessage {...messages.infoPopupTitle} />
        </span>
      </PopupHeader>
      <Overlay loading={loading}>
        <div className={styles.infoPopupContent}>
          {shouldShowNoData && <FormattedMessage {...messages.infoPopupNoDataMessage} />}
          {shouldShowError && <ErrorMessage error={error} />}
          {info?.fields?.length ? (
            <>
              <ul className={styles.infoPopupFields}>
                {info.fields.map((field) => (
                  <li className={styles.infoPopupField} key={field.title.text}>
                    <div className={styles.infoPopupFieldTitle}>
                      <Text text={field.title} />
                    </div>
                    <div className={styles.infoPopupFieldValue}>
                      <Text text={field.value} />
                    </div>
                  </li>
                ))}
              </ul>
              <div className={styles.infoPopupActions}>
                <Button
                  color="ghost"
                  onClick={handleToggleActions}
                  shape="circle"
                  size="x-small"
                  tag="button"
                >
                  <ButtonIcon>
                    <span className={styles.infoPopupActionsIcon}>
                      <Icon height={24} name="more-vertical-filled-24" type="mono" width={24} />
                    </span>
                  </ButtonIcon>
                </Button>
                {actionsVisible && (
                  <ContextMenu onClose={handleToggleActions} usePortal={false} widthByContent>
                    {info.isEditable && (
                      <ContextMenu.Item onClick={handleEditClick}>
                        <span className={styles.infoPopupActionsMenuItem}>
                          <FormattedMessage {...messages.infoPopupEditButtonLabel} />
                        </span>
                      </ContextMenu.Item>
                    )}
                    <ContextMenu.Item onClick={handleDeleteClick}>
                      <span className={cn(styles.infoPopupActionsMenuItem, styles.accent)}>
                        <FormattedMessage {...messages.infoPopupDeleteButtonLabel} />
                      </span>
                    </ContextMenu.Item>
                  </ContextMenu>
                )}
              </div>
            </>
          ) : null}
        </div>
        <PopupFooter>
          <Button color="primary" onClick={onClose} size="large" tag="button">
            <FormattedMessage {...messages.infoPopupCloseButtonLabel} />
          </Button>
        </PopupFooter>
      </Overlay>
    </Popup>
  );
}

export const createPassportInfoPopupView = (): PopupViewRender => {
  return (popupViewProps) => <PassportInfoPopup {...popupViewProps} />;
};
