import classNamesBind from 'classnames/bind';
import React, {useCallback} from 'react';
import {defineMessages, useIntl} from 'react-intl';

import styles from './index.scss';

const cn = classNamesBind.bind(styles);

const messages = defineMessages({
  dot: {
    description: '[label] Номер слайда в слайдере',
    defaultMessage: 'Go to slide {index}',
  },
});

type Props = {
  index: number;
  onClick: (index: number) => void;
  selected: boolean;
};

export const Dot = React.memo(function Dot({index, onClick, selected}: Props) {
  const intl = useIntl();

  const handleClick = useCallback(() => onClick(index), [index, onClick]);

  return (
    <button
      key={`dot-${index}`}
      className={cn('dot', {selected})}
      onClick={handleClick}
      tabIndex={0}
      type="button"
      aria-label={intl.formatMessage(messages.dot, {index: index + 1})}
      aria-current={selected || undefined}
    />
  );
});
