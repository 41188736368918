import connectDuck from 'store/connectDuck';
import {jsonToHyperText} from 'components/HyperText/Json';
import {Locator} from 'components/Locator';
import {HyperText} from 'components/HyperText';
import LegalityShape from 'shapes/Legality';
import {PageError} from 'components/PageError';
import {PageLoading} from 'components/PageLoading';
import PropTypes from 'prop-types';
import React from 'react';
import ThirdParties from 'components/PersonalDataActions/ThirdParties';
import {cancelLegalityConsent, sendLegalityConsent} from 'store/modules/legality';
import Disclaimer from './Disclaimer';
import Loader from './Loader';
import Popup from './Popup';
import Type, {
  ContentType,
  RelatedDocumentOnDisagree,
  TypeModal,
  TypeWithCancellation,
  TypeWithConfirmation,
} from './Type';

class Legality extends React.Component {
  static propTypes = {
    cancelLegalityConsent: PropTypes.func.isRequired,
    disableAnimation: PropTypes.bool,
    document: LegalityShape,
    doNotShowModal: PropTypes.bool,
    onBack: PropTypes.func,
    onSignout: PropTypes.func.isRequired,
    sendLegalityConsent: PropTypes.func.isRequired,
    hcaptchaTransport: PropTypes.object.isRequired,
  };

  static defaultProps = {
    disableAnimation: false,
    document: null,
    doNotShowModal: false,
    onBack: null,
  };

  constructor(props) {
    super(props);

    this.handleBack = this.handleBack.bind(this);
    this.handleButton = this.handleButton.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleBackRelatedDocument = this.handleBackRelatedDocument.bind(this);

    this.state = {
      content: ContentType.DEFAULT,
      disableAnimation: this.props.disableAnimation,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.document !== this.props.document) {
      this.setState({content: ContentType.DEFAULT});
    }
  }

  handleButton(buttonId, agree = null) {
    const {
      document: {id, type},
    } = this.props;
    const {content} = this.state;
    if (TypeWithConfirmation[type] && content === ContentType.DEFAULT && !agree) {
      this.setState({content: ContentType.CONFIRMATION});
    } else if (TypeWithCancellation[type] && content === ContentType.CONFIRMATION && agree) {
      this.setState({content: ContentType.DEFAULT});
    } else if (!agree && RelatedDocumentOnDisagree[type]) {
      this.setState({
        relatedDocument: RelatedDocumentOnDisagree[type],
        disableAnimation: true,
      });
    } else {
      this.props.hcaptchaTransport.verifyProtection(() =>
        this.props
          .sendLegalityConsent(id, buttonId, agree, true)
          .then(TypeWithConfirmation[type] && agree === false ? this.props.onSignout() : null),
      );
    }
  }

  handleBack() {
    this.setState({content: ContentType.DEFAULT});
  }

  handleClose() {
    this.props.cancelLegalityConsent();
  }

  handleBackRelatedDocument() {
    this.setState({relatedDocument: null});
  }

  hasBack() {
    return this.state.content !== ContentType.DEFAULT;
  }

  hasNegativeButton() {
    const {
      document: {type},
    } = this.props;
    return !!TypeWithConfirmation[type];
  }

  hasClose() {
    return !this.hasNegativeButton();
  }

  renderPopup(document, children) {
    const hasThirdParties =
      !!document &&
      document.type === Type.TOS_POPUP_SETTINGS &&
      this.state.content === ContentType.DEFAULT;

    let popupChildren = children;
    if (hasThirdParties) {
      popupChildren = <ThirdParties />;
    }

    return (
      <Popup
        alignLeft={hasThirdParties}
        disableAnimation={this.state.disableAnimation}
        contentType={this.state.content}
        document={document}
        onBack={(this.hasBack() ? this.handleBack : null) || this.props.onBack}
        onClose={this.hasClose() ? this.handleClose : null}
        onButtonClick={this.handleButton}
      >
        {popupChildren}
      </Popup>
    );
  }

  renderRelatedDocument(props) {
    const {document, documentLoading, documentError} = props;

    if (documentError) {
      return this.renderPopup(null, <PageError error={documentError} />);
    }

    if (documentLoading || !document) {
      return this.renderPopup(null, <PageLoading />);
    }

    return (
      <Legality
        {...this.props}
        disableAnimation
        document={document}
        onBack={this.handleBackRelatedDocument}
      />
    );
  }

  renderDisclaimerContent(id, text) {
    if (typeof text === 'string') {
      return text;
    }
    return (
      <Locator id="CookieDisclaimerHyperText" consent-id={id}>
        <HyperText inverseColor>{jsonToHyperText(id, text, {target: '_blank'})}</HyperText>
      </Locator>
    );
  }

  render() {
    const {document} = this.props;
    if (!document || !document.payload) {
      return null;
    }

    const {id, payload} = document;
    const content = payload[this.state.content];

    if (document.type === Type.DISCLAIMER) {
      return (
        <Disclaimer
          buttonCaption={content.positiveButton.text}
          onClose={() => this.handleButton(content.positiveButton.id)}
        >
          {this.renderDisclaimerContent(id, content.text)}
        </Disclaimer>
      );
    }

    if (this.props.doNotShowModal && TypeModal[document.type]) {
      return null;
    }

    if (this.state.relatedDocument) {
      return (
        <Loader id={this.state.relatedDocument}>
          {(props) => this.renderRelatedDocument(props)}
        </Loader>
      );
    }

    return this.renderPopup(document);
  }
}

export default connectDuck({cancelLegalityConsent, sendLegalityConsent})(Legality);
