import {Image} from 'components/Image';
import GalleryItemShape from 'shapes/GalleryItem';
import mousetrap from 'mousetrap';
import Popup from 'components/Popup';
import PropTypes from 'prop-types';
import React from 'react';
import {Swipe} from 'components/Swipe/Swipe';
import {Video} from 'components/Video/async';
import {defineMessages, useIntl} from 'react-intl';
import {
  isGalleryBrokenVideoItem,
  isGalleryValidVideoItem,
  isGalleryVideoItem,
} from 'types/GalleryItem';
import {getGalleryItemKey} from 'utils/gallery';
import {GalleryBrokenVideo} from 'components/Social/Gallery/BrokenVideo/GalleryBrokenVideo';
import {GalleryPopupChrome} from './GalleryPopupChrome';
import styles from './index.scss';

const PRELOADING_GAP = 2;

const messages = defineMessages({
  alt: {
    description: '[label] Альт-текст для изображения',
    defaultMessage: 'Image {index} of {total}',
  },
});

class GalleryPopupComponent extends React.Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    onChange: PropTypes.func,
    items: PropTypes.arrayOf(GalleryItemShape).isRequired,
    index: PropTypes.number,
    intl: PropTypes.objectOf(PropTypes.any).isRequired,
    popupLabel: PropTypes.string,
    lastItem: PropTypes.node,
  };

  static defaultProps = {
    index: 0,
    onChange: null,
    popupLabel: '',
    lastItem: null,
  };

  constructor(props) {
    super(props);

    this.swipe = null;
    this.state = {
      index: props.index || 0,
      videoPlaying: true,
    };
  }

  componentDidMount() {
    mousetrap.bind('left', this.handlePrev);
    mousetrap.bind('right', this.handleNext);
  }

  componentWillUnmount() {
    mousetrap.unbind('left', this.handlePrev);
    mousetrap.unbind('right', this.handleNext);
  }

  componentDidUpdate(prevProps) {
    const index = this.props.index || 0;
    const lastIndex = this.getNumItems() - 1;

    if (prevProps.index !== index && index !== this.state.index) {
      this.setSlide(index);
      if (isGalleryVideoItem(this.props.items[index]) && !this.state.videoPlaying) {
        this.setState({videoPlaying: true});
      }
    }

    if (this.state.index > lastIndex) {
      this.setSlide(lastIndex);
    }
  }

  getNumItems() {
    const {items, lastItem} = this.props;

    if (!lastItem) {
      return items.length;
    }

    return items.length + 1;
  }

  setSlide(index) {
    this.setState({index});
    if (this.swipe) {
      this.swipe.slide(index);
    }
  }

  handlePrev = () => {
    if (this.swipe) {
      this.swipe.prev();
    }
  };

  handleNext = () => {
    if (this.swipe) {
      this.swipe.next();
    }
  };

  handleSwipe = () => {
    if (this.swipe) {
      const index = this.swipe.getPos();
      this.setState({index});
      if (this.props.onChange) {
        this.props.onChange(index);
      }
    }
  };

  handleContentClick = ({target}) => {
    /** @type {HTMLElement} */
    let node = target;

    while (node) {
      if (node.dataset?.gallerySlideContent || node.dataset?.gallerySliderAction) {
        return;
      }

      node = node.parentNode;
    }

    this.props.onClose();
  };

  render() {
    const {onClose, items, intl, popupLabel, lastItem} = this.props;

    if (!items || items.length === 0) {
      return null;
    }

    return (
      <Popup className={styles.popup} onClose={onClose} aria-label={popupLabel}>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div onClick={this.handleContentClick} className={styles.content}>
          <GalleryPopupChrome
            count={this.getNumItems()}
            index={this.state.index}
            onPrev={this.handlePrev}
            onNext={this.handleNext}
            onClose={onClose}
          >
            <Swipe
              ref={(swipe) => {
                this.swipe = swipe;
              }}
              fullscreen
              swipeOptions={{
                callback: this.handleSwipe,
                continuous: true,
                startSlide: this.state.index,
              }}
              items={items}
              render={(item, {index, active}) => (
                <div key={getGalleryItemKey(item)} aria-hidden={!active}>
                  <div className={styles.slide}>
                    {Math.abs(this.state.index - index) <= PRELOADING_GAP &&
                      /* eslint-disable no-nested-ternary */
                      (isGalleryValidVideoItem(item) ? (
                        <Video
                          video={item.payload}
                          playing={active && this.state.videoPlaying}
                          data-gallery-slide-content
                        />
                      ) : isGalleryBrokenVideoItem(item) ? (
                        <GalleryBrokenVideo data-gallery-slide-content item={item} />
                      ) : (
                        <Image
                          className={styles.image}
                          image={item.payload}
                          pxFit={400}
                          vwFit={100}
                          loadImmediately
                          setSizesWhenLoaded
                          alt={intl.formatMessage(messages.alt, {
                            index: index + 1,
                            total: this.getNumItems(),
                          })}
                          data-gallery-slide-content
                        />
                        /* eslint-enable no-nested-ternary */
                      ))}
                  </div>
                </div>
              )}
              renderLast={
                lastItem
                  ? ({active}) => (
                      <div key="lastItem" aria-hidden={!active}>
                        <div className={styles.slide}>{lastItem}</div>
                      </div>
                    )
                  : null
              }
            />
          </GalleryPopupChrome>
        </div>
      </Popup>
    );
  }
}

export function GalleryPopup({...props}) {
  const intl = useIntl();

  return <GalleryPopupComponent {...props} intl={intl} />;
}
