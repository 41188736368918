import {getSelectors} from 'store/utils/reducerCreators/simple/selectors';
import {createSelectorFactory} from 'store/utils/selectors';

export const {
  getData: getRewardWheelData,
  getError: getRewardWheelError,
  getRequest: getRewardWheelRequest,
  isLoaded: isRewardWheelLoaded,
  isLoading: isRewardWheelLoading,
} = getSelectors(createSelectorFactory((state) => state.rewardWheel.wheel));
