import {isExternalUrl} from 'utils/url';

import {useLanguage} from './useLanguage';
import {useScope} from './useScope';

export function useFullUrl(): (urlOrPath?: string) => string | undefined {
  const lang = useLanguage();
  const scope = useScope();

  return (urlOrPath) => {
    if (!urlOrPath) {
      return undefined;
    }

    if (isExternalUrl(urlOrPath)) {
      return urlOrPath;
    }

    const {origin = ''} = __SERVER__ ? {} : window.location;
    return [origin, lang, scope, urlOrPath].filter(Boolean).join('/');
  };
}
