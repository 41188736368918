import {CheckoutSessionGroup, CheckoutSessionItem} from 'types/CheckoutSession';
import {removeDuplicates} from 'utils/array';

type EventCheckoutSessionItem = Omit<CheckoutSessionItem, 'price'> & {
  price: number;
};

type AnalyticsPurchaseCompleteEvent = {
  event: string;
  transactionId: string;
  productIds: string[];
  currency: string;
  totalPrice: number;
  items: EventCheckoutSessionItem[];
};

const getPurchasableProductIds = (
  sessionGroup: CheckoutSessionGroup,
): CheckoutSessionItem['productId'][] => {
  return removeDuplicates(
    sessionGroup.items
      .filter((sessionItem) => sessionItem.isPurchasable)
      .map((sessionItem) => sessionItem.productId),
  );
};

const getSessionItems = (sessionGroup: CheckoutSessionGroup): EventCheckoutSessionItem[] => {
  return sessionGroup.items.map((sessionItem) => {
    return {
      ...sessionItem,
      price: sessionItem.price?.amount,
    };
  });
};

export const getAnalyticsPurchaseCompleteEvent = (
  sessionGroup: CheckoutSessionGroup,
): AnalyticsPurchaseCompleteEvent => {
  const transactionId = sessionGroup.id;
  const totalPrice = sessionGroup.totalPrice.amount;
  const {currency} = sessionGroup.totalPrice;
  const productIds = getPurchasableProductIds(sessionGroup);
  const items = getSessionItems(sessionGroup);

  return {
    event: 'Cart. Purchase Complete',
    transactionId,
    productIds,
    totalPrice,
    currency,
    items,
  };
};
