import {useStore} from 'hooks/redux';
import type {ComponentType, ReactElement} from 'react';
import React from 'react';
import {Provider} from 'react-redux';
import {isStoreWithTransactions} from 'utils/redux';

// this HOC allows using `useSelector` hook with original store
export function withOriginalStore<Props>(Component: ComponentType<Props>): ComponentType<Props> {
  return function ComponentWithOriginalStore(props: Props): ReactElement {
    const store = useStore();

    if (isStoreWithTransactions(store)) {
      return (
        <Provider store={store.originalStore}>
          <Component {...(props as Props & JSX.IntrinsicAttributes)} />
        </Provider>
      );
    }

    return <Component {...(props as Props & JSX.IntrinsicAttributes)} />;
  };
}
