import PropTypes from 'prop-types';
import React from 'react';
import styles from './index.scss';

const Illustration = (props) => {
  const {className, src} = props;
  return (
    <img {...props} alt={props.alt} className={`${styles.illustration} ${className}`} src={src} />
  );
};

Illustration.propTypes = {
  alt: PropTypes.string,
  src: PropTypes.string.isRequired,
  className: PropTypes.string,
};

Illustration.defaultProps = {
  alt: '',
  className: '',
};

export default Illustration;

export const JoomGirlHearts = (props) => (
  <Illustration {...props} src={require('./joomGirlHearts.svg')} />
);
