import {useEffect} from 'react';

import {ObserverCallback, useSliderContext} from './useSliderContext';

export function useObserverCallback(callback?: ObserverCallback): void {
  const {observerCallbacksRef} = useSliderContext();

  useEffect(() => {
    const set = observerCallbacksRef.current;
    if (set && callback) {
      set.add(callback);
      return () => {
        set.delete(callback);
      };
    }
    return undefined;
  }, [callback, observerCallbacksRef]);
}
