import React from 'react';
import {ContentItem} from 'types/Popup';

import styles from './index.scss';

type Props = {
  secondaryText: NonNullable<ContentItem['secondaryText']>;
};

export const SecondaryText: React.FC<Props> = ({secondaryText}: Props) => {
  return <div className={styles.secondaryText}>{secondaryText.text}</div>;
};
