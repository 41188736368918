import {Image} from 'components/Image';
import {ImageProps} from 'components/Image/types';
import {FavoriteButton} from 'components/ProductCollections/FavoriteButton';
import {Square} from 'components/Square';
import {Icon} from 'components/UIKit/Icon';
import {eventParamsList} from 'helpers/eventParams';
import {useDispatch, useSelector} from 'hooks/redux';
import {useDeepLink} from 'hooks/useDeepLink';
import {useDeviceVars} from 'hooks/useDeviceVars';
import {useLanguage} from 'hooks/useLanguage';
import {useLinkTarget} from 'hooks/useLinkTarget';
import {usePreviewEvent} from 'hooks/usePreviewEvent';
import {useScope} from 'hooks/useScope';
import {useSigninVerifyAction} from 'hooks/useSignin';
import {useUserAdulthood} from 'hooks/useUserAdulthood';
import React, {ReactElement, useCallback, useRef} from 'react';
import {defineMessages, FormattedMessage, MessageDescriptor} from 'react-intl';
import {Link} from 'react-router-dom';
import {getUrl} from 'routes';
import {getUser} from 'store/modules/auth';
import {
  addCollectionToFavorites,
  removeCollectionFromFavorites,
} from 'store/modules/productCollections';
import {ProductCollectionBanner as ProductCollectionBannerType} from 'types/ContentList/ContentListProductCollectionBanner';
import {PageSource} from 'types/PageSource';
import {
  getProductCollectionLiteImageAdult,
  getProductCollectionLiteImageSensitive,
} from 'types/ProductCollection/ProductCollectionLite';
import {
  getProductCollectionOwnerId,
  isProductCollectionOwner,
} from 'types/ProductCollection/ProductCollectionOwner';

import CounterIcon from './counterIcon.jsx.svg';
import styles from './index.scss';

type Props = {
  content: ProductCollectionBannerType;
  source?: string;
} & Pick<ImageProps, 'sizes' | 'vwFit' | 'pxFit'>;

const messages = defineMessages({
  adultWarning: {
    defaultMessage: 'Adult product',
    description: 'Предупреждение на товарах для совершеннолетних пользователей',
  },
  sensitiveWarning: {
    defaultMessage: 'Sensitive content',
    description: 'Предупреждение для впечатлительных пользователей',
  },
});

type RestrictedOverlayProps = {
  message: MessageDescriptor;
};

function RestrictedOverlay({message}: RestrictedOverlayProps): JSX.Element {
  return (
    <div className={styles.restricted}>
      <div className={styles.restrictedIcon}>
        <Icon type="mono" name="eye-off-filled-24" />
      </div>
      <div className={styles.restrictedText}>
        <FormattedMessage {...message} />
      </div>
    </div>
  );
}

export function ProductCollectionBanner({
  content: {
    productCollectionLite: {id, isLiked, productsImages, title, productsCount, owner},
    deeplink,
    eventParams,
    avatar,
  },
  sizes,
  vwFit = 25,
  pxFit = 220,
  source,
}: Props): ReactElement {
  const lang = useLanguage();
  const scope = useScope();
  const dispatch = useDispatch();
  const isUserAdult = useUserAdulthood();
  const signinVerifyAction = useSigninVerifyAction(source ?? PageSource.DEFAULT);
  const {productCollectionsConfig} = useDeviceVars();

  // Link
  const linkTarget = useLinkTarget('_blank');
  const [deepLinkUrl] = useDeepLink(deeplink);
  const url = deepLinkUrl || getUrl('productCollection', {lang, scope, productCollectionId: id});

  // Changing in favorites
  const userId = useSelector(getUser)?.id;
  const ownerId = isProductCollectionOwner(owner)
    ? getProductCollectionOwnerId(owner, userId)
    : undefined;
  const favoriteButtonEnabled = Boolean(
    // and favorite collections is enabled
    productCollectionsConfig?.favoriteCollectionsEnabled &&
      // and this is liked product collections page
      (source === PageSource.LIKED_PRODUCT_COLLECTIONS ||
        // or this is product collections discovery page
        source === PageSource.PRODUCT_COLLECTIONS_DISCOVERY ||
        // or this is someone's else social user page
        (source === PageSource.SOCIAL_USER_PRODUCT_COLLECTIONS && userId !== ownerId)) &&
      // and collection has owner id
      ownerId,
  );

  const handleFavoriteChange = useCallback(
    (favorite: boolean) => {
      if (ownerId) {
        const collectionId = id;
        const action = favorite ? addCollectionToFavorites : removeCollectionFromFavorites;

        return signinVerifyAction(() => dispatch(action({ownerId, collectionId})));
      }

      return undefined;
    },
    [dispatch, id, ownerId, signinVerifyAction],
  );

  // Analytics
  const ref = useRef<HTMLAnchorElement>(null);

  usePreviewEvent(
    {
      rootRef: ref,
      previewEvent: {
        type: 'productCollectionPreview',
        payload: {productCollectionId: id},
        params: eventParamsList(eventParams),
      },
    },
    {
      clickEvent: {
        type: 'productCollectionClick',
        payload: {productCollectionId: id},
        params: eventParamsList(eventParams),
      },
    },
  );

  return (
    <Link to={url} className={styles.root} target={linkTarget} ref={ref}>
      <Square>
        {productsImages?.length ? (
          <div className={styles.productList}>
            {Array.from({length: 4}).map((_, index) => {
              const productImage = productsImages[index];
              const showAdultsWarning = Boolean(
                productImage && getProductCollectionLiteImageAdult(productImage, isUserAdult),
              );
              const showSensitiveWarning = Boolean(
                productImage &&
                  getProductCollectionLiteImageSensitive(productImage) &&
                  !showAdultsWarning,
              );

              return (
                // eslint-disable-next-line react/no-array-index-key
                <div key={index} className={styles.product}>
                  {productImage && (
                    <>
                      <Image
                        blurred={showSensitiveWarning || showAdultsWarning}
                        multiply
                        contain
                        image={productImage}
                        sizes={sizes}
                        vwFit={vwFit}
                        pxFit={pxFit}
                        width="100%"
                        height="100%"
                        lcpImageCandidate
                      />
                      {showSensitiveWarning && (
                        <RestrictedOverlay message={messages.sensitiveWarning} />
                      )}
                      {showAdultsWarning && <RestrictedOverlay message={messages.adultWarning} />}
                    </>
                  )}
                </div>
              );
            })}
          </div>
        ) : (
          <div className={styles.emptyProducts}>
            <div className={styles.emptyIcon}>
              <Icon name="empty-filled-24" type="mono" />
            </div>
          </div>
        )}
        {favoriteButtonEnabled && (
          <div className={styles.actionButton}>
            <FavoriteButton favorite={Boolean(isLiked)} onChange={handleFavoriteChange} />
          </div>
        )}
      </Square>
      <div className={styles.footer}>
        <div className={styles.info}>
          <div className={styles.name}>{title}</div>
          {avatar ? (
            <div className={styles.avatar}>
              <Image image={avatar} width="100%" height="100%" />
            </div>
          ) : null}
        </div>
        {productsCount ? (
          <div className={styles.counter}>
            <CounterIcon className={styles.counterIcon} />
            <div className={styles.counterText}>{productsCount}</div>
          </div>
        ) : null}
      </div>
    </Link>
  );
}
