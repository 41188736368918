import {isWebkit, parseUserAgent} from 'helpers/userAgent';
import {trueFunction} from 'utils/function';
import memoize, {memoizeLastByEqualFunc} from 'utils/memoize';
import {findVendorByProp, findStylePropByProp, findCSSPropByProp} from './styleProp';
import Vendor from './vendor';

export const getStyleProp = findStylePropByProp;
export const getCSSProp = findCSSPropByProp;

export const getAnimationStartEvent = memoize(() => {
  const eventName = {
    [Vendor.WEBKIT]: 'webkitAnimationStart',
    [Vendor.OPERA]: 'oAnimationStart',
    [Vendor.MS]: 'MSAnimationStart',
  };

  const vendor = findVendorByProp('animationName');
  if (vendor && eventName[vendor]) {
    return eventName[vendor];
  }

  return 'animationstart';
});

export const getTransitionEndEvent = memoize(() => {
  const eventName = {
    [Vendor.WEBKIT]: 'webkitTransitionEnd',
    [Vendor.OPERA]: 'oTransitionEnd',
    [Vendor.MS]: 'msTransitionEnd',
  };

  const vendor = findVendorByProp('transition');
  if (vendor && eventName[vendor]) {
    return eventName[vendor];
  }

  return 'transitionend';
});

export function setStyleProp(element, property, value) {
  if (element) {
    // eslint-disable-next-line no-param-reassign
    element.style[findStylePropByProp(property)] = value;
  }
}

export const cssSupports = memoize(
  (str) => Boolean(global.CSS && global.CSS.supports && global.CSS.supports(str)),
  ([arg1, arg2]) => (arg2 ? `${arg1}|${arg2}` : arg1),
);

export const supportsCSSVariables = () => cssSupports('color: var(--var)');

export const supportsPositionSticky = memoizeLastByEqualFunc(
  () => cssSupports('position: -webkit-sticky') || cssSupports('position: sticky'),
  trueFunction,
);

// https://bugs.webkit.org/show_bug.cgi?id=199915
export const hasStickyInStickyBug = memoizeLastByEqualFunc(
  () =>
    __CLIENT__ &&
    isWebkit(parseUserAgent(global.navigator.userAgent)) &&
    cssSupports('position: -webkit-sticky') &&
    !cssSupports('position: sticky'),
  trueFunction,
);
