import classnames from 'classnames/bind';
import {JoomGirlHearts} from 'components/Illustration';
import {Image} from 'components/Image';
import React from 'react';
import {TermsList as TermsListType} from 'types/ContentList/ContentListTermsList';

import styles from './styles.scss';

const cn = classnames.bind(styles);

type Props = {
  content: TermsListType;
};

export const TermsList: React.FC<Props> = ({content: {title, items}}: Props) => {
  return (
    <div className={styles.container}>
      {title && (
        <div className={cn('column', 'imageBox')}>
          <JoomGirlHearts className={styles.illustration} />
        </div>
      )}

      <div className={styles.column}>
        {title ? <h1 className={styles.title}>{title.title.text}</h1> : null}

        {items?.length && (
          <ul className={styles.list}>
            {items.map((item) => (
              <li className={styles.listItem} key={item.title?.text}>
                <Image image={item.icon} className={styles.listItemImage} />
                <div className={styles.listItemTitle}>{item.title?.text}</div>
                <div className={styles.listItemText}>{item.text.text}</div>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};
