import cn from 'classnames';
import WarningFilledIcon from 'components/icons/warningFilled.jsx.svg';
import React, {ReactElement} from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';
import {GalleryItem, isGalleryProcessingVideoItem} from 'types/GalleryItem';

import styles from './GalleryBrokenVideo.scss';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  absolute?: boolean;
  item: GalleryItem;
};

const messages = defineMessages({
  title: {
    description: 'Текст ошибки обработки видео',
    defaultMessage: 'Error processing video',
  },
  text: {
    description: 'Текст под ошибкой обработки видео',
    defaultMessage: 'Try again',
  },
  processingTitle: {
    description: 'Video uploading status',
    defaultMessage: 'The video is being processed',
  },
});

export function GalleryBrokenVideo({absolute, item, ...otherProps}: Props): ReactElement {
  return (
    <div
      {...otherProps}
      className={cn(styles.root, absolute && styles.absolute, otherProps.className)}
    >
      <WarningFilledIcon className={styles.icon} />
      {isGalleryProcessingVideoItem(item) ? (
        <div className={styles.title}>
          <FormattedMessage {...messages.processingTitle} />
        </div>
      ) : (
        <>
          <div className={styles.title}>
            <FormattedMessage {...messages.title} />
          </div>
          <div className={styles.text}>
            <FormattedMessage {...messages.text} />
          </div>
        </>
      )}
    </div>
  );
}
