import {Locator} from 'components/Locator';
import {Review} from 'components/Review';
import React, {useMemo} from 'react';
import {FormattedMessage} from 'react-intl';
import {OrderReviewInfo} from 'types/OrderReviewInfo';

import styles from './Review.scss';

type UseVariantLabelProps = {
  reviewInfo: OrderReviewInfo;
};
type UseVariantLabelRenderProps = string | undefined;

const useVariantLabel = ({reviewInfo}: UseVariantLabelProps): UseVariantLabelRenderProps => {
  return useMemo(() => {
    const {review} = reviewInfo;
    const sizeLabel = review?.productVariant?.size;
    const colorLabel = review?.productVariant?.colors?.[0]?.name;
    const labels = [sizeLabel, colorLabel].filter(Boolean);

    return labels.length ? labels.join(' | ') : undefined;
  }, [reviewInfo]);
};

type UseVariantReviewLabelProps = {
  reviewInfo: OrderReviewInfo;
};
type UseVariantReviewLabelRenderProps = string | undefined;

const useVariantReviewLabel = ({
  reviewInfo,
}: UseVariantReviewLabelProps): UseVariantReviewLabelRenderProps => {
  return useMemo(() => {
    const {review, sizeFit} = reviewInfo;
    const answers = sizeFit?.answers ?? [];
    const answerId = review?.sizeFitAnswerId;

    return answers.find((answer) => answer.id === answerId)?.title;
  }, [reviewInfo]);
};

type OrderReviewProps = {
  onEdit?: () => void;
  reviewInfo: OrderReviewInfo;
};

export const OrderReview = React.memo(function OrderReview({
  onEdit,
  reviewInfo,
}: OrderReviewProps): JSX.Element | null {
  const {review} = reviewInfo;
  const variantLabel = useVariantLabel({reviewInfo});
  const variantReviewLabel = useVariantReviewLabel({reviewInfo});

  if (!review) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <h2 className={styles.header}>
        <FormattedMessage
          defaultMessage="Your review:"
          description="Order review header on order page"
        />
        {onEdit ? (
          <div className={styles.edit}>
            <Locator id="ProductCardReviewEditButton">
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
              <span onClick={onEdit} role="button" tabIndex={0}>
                <FormattedMessage description="Edit review button" defaultMessage="Edit" />
              </span>
            </Locator>
          </div>
        ) : null}
      </h2>
      <Review
        authorAvatar={review.user?.avatar}
        authorName={review.user?.fullName}
        createdTime={review.createdTimeMs}
        media={review.media}
        originalLanguage={review.originalLanguage}
        originalText={review.originalText}
        rating={review.starRating}
        text={review.text}
        translationPolicy={review.translationPolicy}
        variantLabel={variantLabel}
        variantReviewLabel={variantReviewLabel}
      />
    </div>
  );
});
