import React, {CSSProperties} from 'react';
import {FormattedNumber} from 'react-intl';

import styles from './index.scss';

export type StarsProps = {
  hasNumber?: boolean;
  rating: number;
};

export function Stars({hasNumber = false, rating}: StarsProps): React.ReactElement | null {
  if (rating === 0) {
    return null;
  }

  const percent = (Math.floor(5 - rating) * 2 + 1 + (5 - rating) * 10) / 60;

  return (
    <div className={styles.rating}>
      <div>
        <div
          className={styles.stars}
          style={{'--padding-inline-end': `${percent * 100}%`} as CSSProperties}
        />
      </div>
      {hasNumber && (
        <div className={styles.label}>
          <FormattedNumber minimumFractionDigits={0} maximumFractionDigits={1} value={rating} />
        </div>
      )}
    </div>
  );
}
