import {
  getVisibilityChangeEventName,
  isPageVisibilitySupported,
  isPageVisible,
} from 'helpers/pageVisibility';
import {useEffect} from 'react';

export function useVisibilityChange(
  handleLeavePage?: () => void,
  handleEnterPage?: () => void,
): void {
  useEffect(() => {
    if (!isPageVisibilitySupported()) {
      return undefined;
    }
    const eventName = getVisibilityChangeEventName();

    const handleVisibilityChange = () => {
      if (!isPageVisible()) {
        handleLeavePage?.();
      } else {
        handleEnterPage?.();
      }
    };

    document.addEventListener(eventName, handleVisibilityChange);

    return () => {
      document.removeEventListener(eventName, handleVisibilityChange);
    };
  }, [handleLeavePage, handleEnterPage]);
}
