// setTimeout limit is MAX_INT32=(2^31-1)
export const MAX_TIMER_DELAY = 0x7fffffff;

type ClearTimeoutSafe = () => void;

type TimerId = ReturnType<typeof setTimeout>;

function runAtTimestamp(timestamp: number, fn: () => void, setTimerId: (id: TimerId) => void) {
  const diff = Math.max(0, timestamp - Date.now());
  if (diff > MAX_TIMER_DELAY) {
    setTimerId(setTimeout(() => runAtTimestamp(timestamp, fn, setTimerId), MAX_TIMER_DELAY));
  } else {
    setTimerId(setTimeout(fn, diff));
  }
}

/**
 * Reschedules timer with delay > (2^31-1). When delay for `setTimeout`
 * is larger than 2147483647 or less than 1, the delay will be set to 1.
 * Use it wise on server side.
 */
export function setTimeoutSafe(fn: () => void, delayMs: number): ClearTimeoutSafe {
  if (delayMs <= MAX_TIMER_DELAY) {
    // delay is not so big, just call the usual one
    const timer = setTimeout(fn, delayMs);
    return () => clearTimeout(timer);
  }

  let timer: TimerId | undefined;
  runAtTimestamp(Date.now() + delayMs, fn, (id) => {
    timer = id;
  });

  return () => (timer ? clearTimeout(timer) : undefined);
}
