import {AppLink} from 'components/AppLink';
import {Button} from 'components/UIKit/Button';
import {useAppLinkHandler} from 'hooks/useAppLink';
import {useScope} from 'hooks/useScope';
import {useUserAgent} from 'hooks/useUserAgent';
import React from 'react';
import {ContentItemAppLinkButton} from 'types/Popup';
import {convertBackendGradientToCSSValue} from 'utils/styles/color';
import {getPlatformUrl} from 'utils/url/app';

import styles from './index.scss';

type Props = {
  appLinkButton: ContentItemAppLinkButton;
  onClick: () => void;
};

export const AppLinkButton: React.FC<Props> = ({appLinkButton, onClick}: Props) => {
  const userAgent = useUserAgent();
  const scope = useScope();
  const appLinkHref = getPlatformUrl(scope, userAgent);
  const handleClick = useAppLinkHandler({target: 'orders', onClick});

  if (!appLinkHref) {
    return null;
  }

  return (
    <AppLink className={styles.appLink} href={appLinkHref} role="button" onClick={handleClick}>
      <Button
        size="large"
        tag="button"
        color={{
          base: 'primary',
          background: convertBackendGradientToCSSValue(appLinkButton.backgroundGradient),
        }}
      >
        {appLinkButton.text.text}
      </Button>
    </AppLink>
  );
};
