import React from 'react';

import styles from './index.scss';

type Props = {
  header: string;
  type?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
};

export const Header = React.memo(function Header({header, type = 'h2'}: Props): JSX.Element {
  return React.createElement(
    type,
    {
      className: styles.header,
    },
    header,
  );
});
