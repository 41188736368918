import {getServerTimestamp} from 'helpers/serverTime';
import {useEffect, useState} from 'react';
import {Patch} from 'types/ProductPatch';
import {setTimeoutSafe} from 'utils/setTimeoutSafe';

/**
 * Calculates expiration timestamp. If patch is not exists will return `0`.
 */
export function getProductPatchExpireTimeMs<T>(loadedTimeMs: number, patch?: Patch<T>): number {
  if (!patch?.timeRemainingMs) {
    return 0;
  }
  return loadedTimeMs + patch.timeRemainingMs;
}

/**
 * Calculates the difference between now and expiration timestamp. Can not be negative number.
 */
export function getProductPatchReminingMs<T>(loadedTimeMs: number, patch?: Patch<T>): number {
  const timestamp = getProductPatchExpireTimeMs(loadedTimeMs, patch);
  return Math.max(0, timestamp - getServerTimestamp());
}

/**
 * Returns `patch` if it has not expired yet.
 */
export const useProductPatch = <T>(loadedTimeMs: number, patch?: Patch<T>): T | undefined => {
  const [value, setValue] = useState(() =>
    getProductPatchReminingMs(loadedTimeMs, patch) ? patch?.data : undefined,
  );

  useEffect(() => {
    const reminingMs = getProductPatchReminingMs(loadedTimeMs, patch);
    if (reminingMs) {
      setValue(patch?.data);
      return setTimeoutSafe(() => setValue(undefined), reminingMs);
    }
    setValue(undefined);
    return undefined;
  }, [loadedTimeMs, patch]);

  return value;
};
