import {Background} from 'types/Background';
import {ColoredText} from 'types/ColoredText';
import {ImageBundle} from 'types/Image';
import {ProductLite} from 'types/ProductLite';

import {Button, Header, HorizontalPosition, Timer} from './common';

export enum SideBannerGroupBannerDesign {
  ONE_LINE = 'oneLine',
  TWO_LINE = 'twoLine',
}

export enum SideBannerGroupItemDesign {
  LARGE_CARD = 'largeCardWeb',
  SMALL_CARD = 'smallCardWeb',
}

export type SideBannerGroupProductItem = {
  product: ProductLite;
};

export function sideBannerGroupProductItem(product: ProductLite): SideBannerGroupProductItem {
  return {product};
}

export function isSideBannerGroupProductItem(
  item: Record<string, unknown>,
): item is SideBannerGroupProductItem {
  return Boolean((item as SideBannerGroupProductItem).product);
}

export type SideBannerGroupBanner = {
  background: Background;
  button?: Button;
  image: ImageBundle;
  subtitle?: ColoredText;
  title?: ColoredText;
  payload?: {
    url?: string;
  };
  timer?: Timer;
};

export type SideBannerGroup = {
  appearance: {
    bannerDesign: SideBannerGroupBannerDesign;
    bannerPosition: HorizontalPosition;
    itemDesign: SideBannerGroupItemDesign;
  };
  banner: SideBannerGroupBanner;
  header?: Header;
  items: Array<SideBannerGroupProductItem>;
};
