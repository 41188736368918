import {RouteContext} from 'providers/RouteContext';
import {useContext} from 'react';
import {PoorRoute} from 'routes/types';

export function useRoute(): PoorRoute {
  const context = useContext(RouteContext);

  if (!context) {
    throw new Error('Route is not provided.');
  }

  return context;
}
