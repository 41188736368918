import {getServerTimestamp} from 'helpers/serverTime';
import React, {ComponentProps, useMemo} from 'react';
import {FormattedDate as ReactIntlFormattedDate} from 'react-intl';

type Props = ComponentProps<typeof ReactIntlFormattedDate> & {
  currentYear?: boolean;
};

/**
 * Same as `FormattedDate` in `react-intl`, but hides the current year by default.
 */
export function FormattedDate({currentYear = false, ...props}: Props): JSX.Element {
  const year = useMemo(() => {
    if (!currentYear && props.value && props.year) {
      if (new Date(props.value).getFullYear() === new Date(getServerTimestamp()).getFullYear()) {
        return undefined;
      }
    }
    return props.year;
  }, [currentYear, props.year, props.value]);

  return <ReactIntlFormattedDate {...props} year={year} />;
}
