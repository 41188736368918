import {getSelectors} from 'store/utils/reducerCreators/simple/selectors';
import {createSelectorFactory} from 'store/utils/selectors';

export const {
  isLoading: isPersonalDataFormLoading,
  isLoaded: isPersonalDataFormLoaded,
  getData: getPersonalDataForm,
  getError: getPersonalDataFormError,
  getRequest: getPersonalDataFormRequest,
} = getSelectors(createSelectorFactory((globalState) => globalState.personalData.form));
