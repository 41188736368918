import ErrorMessage from 'components/ErrorMessage';
import {Overlay} from 'components/Overlay';
import {Button} from 'components/UIKit/Button';
import {Icon} from 'components/UIKit/Icon';
import {Popup} from 'components/UIKit/Popup';
import {Footer} from 'components/UIKit/Popup/Footer';
import {StandaloneControls} from 'components/UIKit/Popup/Header/StandaloneControls';
import {PopupViewProps, PopupViewRender} from 'components/UIKit/Popup/PopupProvider';
import {PopupStack} from 'components/UIKit/Popup/PopupStack';
import {PopupUserActions} from 'components/UIKit/Popup/types';
import React, {MouseEvent, ReactNode, useCallback, useMemo, useState} from 'react';
import {Error} from 'types/Error';

import styles from './styles.scss';

const WIDTH = '452px';

const DEFAULT_BUTTON_PROPS: React.ComponentProps<typeof Button> = {
  tag: 'button',
  type: 'button',
  color: 'gray',
  shape: 'rounded-rect',
  size: 'medium',
};

type ContentProps = React.PropsWithChildren<{
  title?: ReactNode;
  text?: ReactNode;
  icon?: React.ComponentProps<typeof Icon>;
  error?: Error;
}>;

type Buttons = Omit<React.ComponentProps<typeof Button>, keyof typeof DEFAULT_BUTTON_PROPS>[];

type ButtonsProps = {
  buttons?: Buttons;
  setLoading?(value: boolean): void;
};

type ButtonsInitializer = ({onClose, onBack}: {onClose?(): void; onBack?(): void}) => Buttons;

export type Props = React.PropsWithChildren<
  ContentProps & {
    buttons?: ButtonsInitializer;
  }
>;

function ButtonWithAsyncHandler(
  props: React.ComponentProps<typeof Button> & {setLoading?: (value: boolean) => void},
) {
  const {onClick, setLoading, ...restProps} = props;

  const handleClick = useCallback(
    async (
      event: MouseEvent<HTMLButtonElement, MouseEvent> &
        MouseEvent<HTMLAnchorElement, MouseEvent> &
        MouseEvent<HTMLLabelElement>,
    ) => {
      try {
        setLoading?.(true);
        await onClick?.(event);
      } finally {
        setLoading?.(false);
      }
    },
    [onClick, setLoading],
  );
  return <Button {...restProps} onClick={handleClick} />;
}

export function DialogContent({children, error, title, text, icon}: ContentProps): JSX.Element {
  return (
    <div className={styles.content}>
      {icon ? (
        <div className={styles.iconContainer}>
          <div className={styles.icon}>
            <Icon {...icon} />
          </div>
        </div>
      ) : (
        <div className={styles.noIcon} />
      )}
      {title ? <h2 className={styles.title}>{title}</h2> : null}
      {error ? (
        <div className={styles.error}>
          <ErrorMessage error={error} internal />
        </div>
      ) : null}
      {text ? <p className={styles.text}>{text}</p> : null}
      {children}
    </div>
  );
}

export function DialogButtons({buttons, setLoading}: ButtonsProps): JSX.Element | null {
  return buttons ? (
    <>
      {buttons.map((props, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div className={styles.buttonContainer} key={index}>
          <ButtonWithAsyncHandler {...DEFAULT_BUTTON_PROPS} {...props} setLoading={setLoading} />
        </div>
      ))}
    </>
  ) : null;
}

export function DialogBase({
  onBack,
  onClose,
  title,
  text,
  icon,
  buttons,
  error,
  children,
}: PopupViewProps & Props): JSX.Element {
  const [loading, setLoading] = useState(false);

  return (
    <Popup width={WIDTH}>
      <StandaloneControls onBack={onBack} onClose={onClose} />
      <Overlay loading={loading}>
        <DialogContent title={title} text={text} icon={icon} error={error}>
          {children}
        </DialogContent>
        {typeof buttons === 'function' && (
          <Footer>
            <DialogButtons buttons={buttons({onClose, onBack})} setLoading={setLoading} />
          </Footer>
        )}
      </Overlay>
    </Popup>
  );
}

export function useDialogRender({title, text, icon, buttons, error}: Props): PopupViewRender {
  return useMemo(
    () =>
      ({action, onClose, onBack}: PopupViewProps) => (
        <DialogBase
          action={action}
          onClose={onClose}
          onBack={onBack}
          title={title}
          text={text}
          icon={icon}
          buttons={buttons}
          error={error}
        />
      ),
    [buttons, error, icon, text, title],
  );
}

export function Dialog(props: Props & PopupUserActions): React.ReactElement {
  const render = useDialogRender(props);
  const {onBack, onClose} = props;
  return <PopupStack render={render} onBack={onBack} onClose={onClose} />;
}
