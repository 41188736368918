import {useEffectOnce} from 'hooks/useEffectOnce';
import React, {ReactNode, useMemo, useRef, useState} from 'react';

import {useApi} from './useApi';
import {
  ObserverCallback,
  SliderContext,
  SliderContextType,
  SliderItemType,
} from './useSliderContext';

type Props = {
  direction?: 'horizontal' | 'vertical';
  children: ReactNode;
};

export function Slider({children, direction = 'horizontal'}: Props): JSX.Element {
  const viewRef = useRef<HTMLElement>(null);
  const itemsRef = useRef<SliderItemType<HTMLElement>[]>([]);
  const [observer, setObserver] = useState<IntersectionObserver>();
  const observerCallbacksRef = useRef<Set<ObserverCallback>>(new Set());
  const api = useApi(viewRef, itemsRef, direction);
  const context = useMemo<SliderContextType>(
    () => ({
      viewRef,
      observer,
      observerCallbacksRef,
      itemsRef,
      api,
      direction,
    }),
    [observer, api, direction],
  );

  useEffectOnce(() => {
    const observer = new IntersectionObserver(
      (entries, observer) => {
        const callbacks = observerCallbacksRef.current;
        if (callbacks?.size) {
          callbacks.forEach((fn) => fn(entries, observer));
        }
      },
      {root: viewRef.current, threshold: 0.99},
    );

    setObserver(observer);

    return () => {
      observer.disconnect();
    };
  });

  return <SliderContext.Provider value={context}>{children}</SliderContext.Provider>;
}
