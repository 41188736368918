import React from 'react';

import styles from './index.scss';

type Props = {
  list: string[];
  type: 'ol' | 'ul';
};

export function List({list, type}: Props): JSX.Element | null {
  if (list.length === 0) {
    return null;
  }

  return React.createElement(
    type,
    {
      className: styles.list,
    },
    list.map((item, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <li key={`${item}${index}`}>{item}</li>
    )),
  );
}
