import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {OrderBannerShape} from 'shapes/OrderBanner';
import {ReturnInfoShape} from 'shapes/ReturnInfo';
import {OrderBanner} from 'components/OrderBanner';

export const Banners = memo(({banners, loadedTimeMs, returnInfo, showDeliveryInfo}) => {
  if (!banners || !banners.some(Boolean)) {
    return null;
  }

  return banners.map(
    (banner, i) =>
      Boolean(banner) && (
        <OrderBanner
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          banner={banner}
          loadedTimeMs={loadedTimeMs}
          returnInfo={returnInfo}
          showDeliveryInfo={showDeliveryInfo}
        />
      ),
  );
});

Banners.propTypes = {
  banners: PropTypes.arrayOf(OrderBannerShape),
  loadedTimeMs: PropTypes.number.isRequired,
  returnInfo: ReturnInfoShape,
  showDeliveryInfo: PropTypes.func,
};

Banners.defaultProps = {
  banners: [],
  returnInfo: null,
  showDeliveryInfo: null,
};
