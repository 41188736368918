import React from 'react';

import styles from './index.scss';

type Props = {
  inline?: boolean;
  // [0...1]
  value?: number;
};

export function Star({inline = false, value = 0}: Props): React.ReactElement {
  let className;

  if (value > 0.6666) {
    className = styles.full;
  } else if (value > 0.3333) {
    className = styles.half;
  } else {
    className = styles.empty;
  }

  return <div className={`${className} ${inline ? styles.inline : ''}`} />;
}
