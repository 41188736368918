import PropTypes from 'prop-types';

import ImageBundle from './ImageBundle';

// FYI! you can find utils for this shape in `utils/styles/color`

const NamedColorShape = PropTypes.shape({
  name: PropTypes.string,
  rgb: PropTypes.string,
  image: ImageBundle,
});

const ColorStringShape = PropTypes.string.isRequired;

export const ColorShape = PropTypes.oneOfType([NamedColorShape, ColorStringShape]);
