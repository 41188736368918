/* eslint-disable no-bitwise */
/**
 * Pseudorandom number generator
 * @see https://stackoverflow.com/questions/521295/seeding-the-random-number-generator-in-javascript
 * @see https://www.pcg-random.org/posts/bob-jenkins-small-prng-passes-practrand.html
 */
export const jsf32 = (
  initA: number,
  initB: number,
  initC: number,
  initD: number,
): (() => number) => {
  let [a, b, c, d] = [initA, initB, initC, initD];

  return (): number => {
    a |= 0;
    b |= 0;
    c |= 0;
    d |= 0;

    const t = (a - ((b << 27) | (b >>> 5))) | 0;

    a = b ^ ((c << 17) | (c >>> 15));
    b = (c + d) | 0;
    c = (d + t) | 0;
    d = (a + t) | 0;
    return (d >>> 0) / 4294967296;
  };
};

export const randomInRange = (a: number, b: number, rnd = Math.random): number => {
  const max = Math.max(a, b);
  const min = Math.min(a, b);

  return Math.floor(rnd() * (max - min + 1)) + min;
};

export const randomFromArray = <T>(items: Array<T>, rnd = Math.random): T => {
  return items[Math.floor(rnd() * items.length)]!;
};
