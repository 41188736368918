import range from 'lodash/range';
import React from 'react';
import {Link} from 'react-router-dom';

import styles from './index.scss';

type Props = {
  from: number;
  to: number;
  current?: number;
  showCurrent?: boolean;
  urlPrefix?: string;
  pagePrefix?: string;
};

export const PaginationRange = React.memo(function PaginationRange({
  from,
  to,
  current,
  showCurrent = true,
  urlPrefix = '',
  pagePrefix = '',
}: Props): JSX.Element | null {
  if (to <= from) {
    return null;
  }
  // don't call useMemo, because React.memo is enough here
  const pages = range(from, to + 1);

  return (
    <div className={styles.pagination}>
      {pages.map((page) =>
        showCurrent || page !== current ? (
          <div key={page} className={styles.page}>
            <Link className={styles.link} to={`${urlPrefix}/${pagePrefix}${page}`}>
              {page}
            </Link>
          </div>
        ) : null,
      )}
    </div>
  );
});
