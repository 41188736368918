import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import {Link} from 'react-router-dom';
import {Locator} from 'components/Locator';
import styles from './Button.scss';

const cn = classnames.bind(styles);

const Button = React.memo(function Button({children, to, onClick, disabled, ...restProps}) {
  const TagName = to ? Link : 'button';
  const props = disabled ? {} : {onClick, to};

  return (
    <Locator id="WhiteListButton">
      <div className={cn('button')}>
        <TagName className={cn('control', {disabled})} {...props} {...restProps}>
          {children}
        </TagName>
      </div>
    </Locator>
  );
});

Button.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  to: PropTypes.string,
  onClick: PropTypes.func,
};

Button.defaultProps = {
  disabled: false,
  onClick: null,
  to: '',
};

export default Button;
