import AnalyticsShape from 'shapes/Analytics';
import {Image} from 'components/Image';
import {Avatar} from 'components/Avatar';
import {ContextMenu} from 'components/ContextMenu';
import PropTypes from 'prop-types';
import React, {Component, createRef, useCallback, useRef} from 'react';
import {UserShape} from 'shapes/User';
import {FormattedMessage, defineMessages, useIntl} from 'react-intl';
import {useAnalytics} from 'hooks/useAnalytics';
import {useDeviceVar} from 'hooks/useDeviceVars';
import {CircleLoading} from 'components/CircleLoading';
import {Locator} from 'components/Locator';
import {useUniversalNavigate} from 'hooks/useUniversalNavigate';
import {eventParamsList} from 'helpers/eventParams';
import {usePreviewEvent} from 'hooks/usePreviewEvent';
import {useScope} from 'hooks/useScope';
import {useLanguage} from 'hooks/useLanguage';
import {getUrl} from 'routes';
import {usePopupManager} from 'hooks/usePopupManager';
import {useSelector} from 'hooks/redux';
import {getCouponCardsCount} from 'store/modules/couponCards/selectors';
import {usePopup} from 'hooks/usePopup';
import {useCouponsPopupRender} from 'connectors/CouponsPopup';
import {rootLocator} from 'utils/rootLocator';
import {createPassportInfoPopupView} from 'components/PassportInfoPopup';
import {isPersonalDataExists} from 'store/modules/personalData/exists/selectors';
import {PASSPORT_FORM_ORIGIN} from 'components/PassportInfoPopup/constants';
import {useMobWebAdInterlayerHardcoreModeLink} from 'hooks/useMobWebAdInterlayer';
import {Scope} from 'config';
import {ScopeConfig} from 'helpers/ApiClient/Scope/ScopeConfig';
import {Icon} from 'components/UIKit/Icon';
import {getGradientStyles, getInlineColor} from 'utils/styles/color';
import {AnyLink} from 'components/AnyLink';
import {CommonProfileBannerPlace} from 'types/User';
import {getJmtMigrationSignoutPopupRender} from './getJmtMigrationSignoutPopupRender';
import {HeaderButton, HeaderButtonContainer} from '../HeaderButton';
import PremiumIcon from './premium.jsx.svg';

import styles from './index.scss';

const messages = defineMessages({
  login: {
    defaultMessage: 'Log in',
    description: 'Header login label',
  },
  orders: {
    defaultMessage: 'My orders',
    description: 'Header context menu item',
  },
  passport: {
    defaultMessage: 'My passport',
    description: 'Header context menu item',
  },
  pointsMoney: {
    defaultMessage: 'My balance',
    description: 'Header context menu item',
  },
  coupons: {
    defaultMessage: 'My coupons <span>({count})</span>',
    description: 'Header context menu item',
  },
  security: {
    defaultMessage: 'Security',
    description: 'Header context menu item',
  },
  signout: {
    defaultMessage: 'Log out',
    description: 'Header context menu item',
  },
  profile: {
    defaultMessage: 'Profile',
    description: 'Header profile icon label',
  },
  favorites: {
    defaultMessage: 'My favourites',
    description: 'Header, Favorites context menu item',
  },
  socialProfile: {
    defaultMessage: 'My reviews',
    description: 'текст в выпадающем меню в шапке',
  },
  premiumInfo: {
    defaultMessage: '{companyName} Premium',
    description: 'Header premium info menu item',
  },
});

function CouponCounter(chunks) {
  return <span className={styles.couponsCount}>{chunks}</span>;
}

function CommonProfileBanner({banner, onClick}) {
  const {
    icon,
    title,
    button: {url},
    id,
    description,
    background,
    eventParams,
  } = banner;
  const analytics = useAnalytics();
  const rootRef = useRef();

  usePreviewEvent({
    rootRef,
    previewEvent: {
      type: 'bannerPreview',
      payload: {
        bannerId: id,
        place: 'profile',
      },
      params: eventParamsList(eventParams),
    },
  });

  const handleNavigate = useCallback(() => {
    analytics.sendEvent(
      {
        type: 'bannerClick',
        payload: {
          bannerId: id,
          place: 'profile',
        },
        params: eventParamsList(eventParams),
      },
      {
        immediately: true,
      },
    );

    onClick?.(url);
  }, [analytics, eventParams, id, onClick, url]);

  return (
    <ContextMenu.Item ref={rootRef}>
      <AnyLink className={styles.commonProfileBannerLinkWrapper} to={url} onClick={handleNavigate}>
        <div className={styles.commonProfileBanner} style={getGradientStyles(background)}>
          {icon ? (
            <span className={styles.commonProfileBannerIcon}>
              <Image
                image={icon}
                alt={title.text}
                vwFit={{xs: '24px'}}
                width="100%"
                height="100%"
                contain
              />
            </span>
          ) : null}
          <div className={styles.commonProfileBannerContent}>
            {title?.text ? (
              <div className={styles.commonProfileBannerTitle} style={getInlineColor(title.color)}>
                {title.text}
              </div>
            ) : null}
            {description?.text ? (
              <div
                className={styles.commonProfileBannerDescription}
                style={getInlineColor(description.color)}
              >
                {description.text}
              </div>
            ) : null}
          </div>
          {url ? (
            <div
              className={styles.commonProfileBannerArrow}
              style={getInlineColor(title?.color || description?.color)}
            >
              <Icon type="mono" name="chevron-right-linear-16" width="16px" height="16px" />
            </div>
          ) : null}
        </div>
      </AnyLink>
    </ContextMenu.Item>
  );
}

CommonProfileBanner.propTypes = {
  banner: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default function Profile(props) {
  const analytics = useAnalytics();
  const lang = useLanguage();
  const intl = useIntl();
  const scope = useScope();
  const navigate = useUniversalNavigate();
  const favoritesInHeader = useDeviceVar('favoritesInHeader');
  const showFavorites = !favoritesInHeader && scope.is(Scope.GLOBAL);
  const popupManager = usePopupManager();
  const couponsCount = useSelector(getCouponCardsCount);
  const passportDataExists = useSelector((state) =>
    isPersonalDataExists(state, PASSPORT_FORM_ORIGIN),
  );
  const couponRedesign = useDeviceVar('webCouponRedesign2023');

  const couponsPopup = usePopup(useCouponsPopupRender({source: 'profile'}));

  const mobileInterlayerActionOverrideLink = useMobWebAdInterlayerHardcoreModeLink();

  return (
    <ProfileBase
      lang={lang}
      scope={scope}
      {...props}
      analytics={analytics}
      showFavorites={showFavorites}
      intl={intl}
      navigate={navigate}
      passportDataExists={passportDataExists}
      mobileInterlayerActionOverrideLink={mobileInterlayerActionOverrideLink}
      popupManager={popupManager}
      couponsCount={couponsCount}
      openCoupons={couponRedesign ? couponsPopup.open : undefined}
    />
  );
}

const locator = rootLocator.commonPage.profile;

class ProfileBase extends Component {
  static propTypes = {
    user: UserShape,
    lang: PropTypes.string.isRequired,
    scope: PropTypes.instanceOf(ScopeConfig).isRequired,
    signingIn: PropTypes.bool,
    onSignin: PropTypes.func.isRequired,
    onSignout: PropTypes.func.isRequired,
    analytics: AnalyticsShape.isRequired,
    showFavorites: PropTypes.bool,
    intl: PropTypes.objectOf(PropTypes.any).isRequired,
    navigate: PropTypes.func.isRequired,
    passportDataExists: PropTypes.bool.isRequired,
    popupManager: PropTypes.object.isRequired,
    mobileInterlayerActionOverrideLink: PropTypes.func,
    couponsCount: PropTypes.number,
    openCoupons: PropTypes.func,
    shouldShowOrders: PropTypes.bool,
  };

  static defaultProps = {
    signingIn: false,
    user: null,
    showFavorites: true,
    couponsCount: undefined,
    openCoupons: undefined,
    mobileInterlayerActionOverrideLink: undefined,
    shouldShowOrders: undefined,
  };

  constructor(props) {
    super(props);

    this.state = {
      hasContextMenu: false,
    };

    this.userButtonRef = createRef();

    this.mounted = false;
  }

  handleAvatarClick = (evt) => {
    const {mobileInterlayerActionOverrideLink} = this.props;

    if (mobileInterlayerActionOverrideLink) {
      evt?.preventDefault();
      evt?.stopPropagation();
      mobileInterlayerActionOverrideLink(evt);
      return;
    }

    this.setState({
      hasContextMenu: true,
    });
  };

  handleContextMenuClose = () => {
    this.setState({
      hasContextMenu: false,
    });
  };

  handleContextMenuItemClick = (eventType) => {
    this.props.analytics.sendEvent({
      type: eventType,
      payload: {from: 'profileMenu'},
    });
    this.setState({
      hasContextMenu: false,
    });
  };

  handleFavoritesClick = () => {
    this.handleContextMenuItemClick('favoritesLinkClick');
  };

  handleOrdersClick = () => {
    this.handleContextMenuItemClick('ordersLinkClick');
  };

  handlePointsClick = () => {
    this.handleContextMenuItemClick('pointsLinkClick');
  };

  handlePassportClick = () => {
    this.handleContextMenuItemClick('passportLinkClick');
    this.props.popupManager.open(createPassportInfoPopupView());
  };

  handleCouponsClick = () => {
    this.setState({
      hasContextMenu: false,
    });

    this.props.openCoupons?.();
  };

  handleSignin = (evt) => {
    const {onSignin, signingIn, analytics, mobileInterlayerActionOverrideLink} = this.props;
    evt?.preventDefault();
    evt?.stopPropagation();

    if (mobileInterlayerActionOverrideLink) {
      mobileInterlayerActionOverrideLink(evt);
      return;
    }

    if (!signingIn) {
      onSignin();
      analytics.sendEvent({
        type: 'profileSignInClick',
        payload: {signInVariant: 'firstSignIn'},
      });
    }
  };

  handleSignout = (evt) => {
    evt.preventDefault();
    this.setState({
      hasContextMenu: false,
    });

    if (this.props.user.jmtMigrationRecovery) {
      const onContinue = () => this.props.navigate(this.props.user.jmtMigrationRecovery.url);
      const onSignout = () => this.props.onSignout();

      this.props.popupManager.open(getJmtMigrationSignoutPopupRender({onContinue, onSignout}));
    } else {
      this.props.onSignout();
    }
  };

  handleBannerNavigate = (url) => {
    const {navigate} = this.props;

    navigate(url);
  };

  renderCommonProfileBanner = (banner) => (
    <CommonProfileBanner
      banner={banner}
      onClick={this.handleBannerNavigate}
      rootRef={this.userButtonRef}
    />
  );

  renderContextMenu() {
    if (!this.state.hasContextMenu) {
      return null;
    }

    const {
      user,
      showFavorites,
      shouldShowOrders,
      lang,
      scope,
      openCoupons,
      couponsCount,
      passportDataExists,
    } = this.props;
    const {companyName} = scope.companyInfo;

    return (
      <Locator id="ProfileMenuHeaderButton">
        <ContextMenu
          onClose={this.handleContextMenuClose}
          referenceElement={this.userButtonRef.current}
        >
          {user?.banners ? (
            <div className={styles.userProfileBanners}>
              {user.banners
                .filter(
                  (banner) =>
                    !banner.places || banner.places.includes(CommonProfileBannerPlace.PROFILE_MENU),
                )
                .map(this.renderCommonProfileBanner)}
            </div>
          ) : null}
          {user?.socialUser?.id && (
            <ContextMenu.Item
              to={getUrl('socialUser', {lang, scope, userId: user.socialUser.id})}
              onClick={this.handleContextMenuClose}
              largeText
              testId="HeaderMenuSocialButton"
            >
              <FormattedMessage {...messages.socialProfile} />
            </ContextMenu.Item>
          )}
          {showFavorites ? (
            <ContextMenu.Item
              to={getUrl('favorites', {lang, scope})}
              largeText
              onClick={this.handleFavoritesClick}
              testId="HeaderMenuFavoritesButton"
            >
              <FormattedMessage {...messages.favorites} />
            </ContextMenu.Item>
          ) : null}
          {!showFavorites || shouldShowOrders ? (
            <ContextMenu.Item
              to={getUrl('orders', {lang, scope})}
              onClick={this.handleOrdersClick}
              largeText
              testId="HeaderMenuOrdersButton"
            >
              <FormattedMessage {...messages.orders} />
            </ContextMenu.Item>
          ) : null}
          {openCoupons && couponsCount ? (
            <ContextMenu.Item largeText onClick={this.handleCouponsClick}>
              <span className={styles.couponsText}>
                <FormattedMessage
                  {...messages.coupons}
                  values={{
                    count: couponsCount,
                    span: CouponCounter,
                  }}
                />
              </span>
            </ContextMenu.Item>
          ) : null}
          {user && !user.pointsDisabled ? (
            <ContextMenu.Item
              to={getUrl('points', {lang, scope})}
              largeText
              onClick={this.handlePointsClick}
              testId="HeaderMenuPointsButton"
            >
              <FormattedMessage {...messages.pointsMoney} />
            </ContextMenu.Item>
          ) : null}
          {passportDataExists && (
            <ContextMenu.Item
              largeText
              onClick={this.handlePassportClick}
              testId="HeaderMenuPassportButton"
            >
              <FormattedMessage {...messages.passport} />
            </ContextMenu.Item>
          )}
          <ContextMenu.Item
            largeText
            to={getUrl('profileSecurity', {lang, scope})}
            onClick={this.handleContextMenuClose}
            testId="HeaderMenuSecurityButton"
          >
            <FormattedMessage {...messages.security} />
          </ContextMenu.Item>
          {user && user.isPremium && (
            <ContextMenu.Item to={getUrl('premium-info', {lang, scope})} largeText>
              <FormattedMessage {...messages.premiumInfo} values={{companyName}} />
            </ContextMenu.Item>
          )}
          <ContextMenu.Item largeText onClick={this.handleSignout} testId="HeaderMenuSignOutButton">
            <FormattedMessage {...messages.signout} />
          </ContextMenu.Item>
        </ContextMenu>
      </Locator>
    );
  }

  renderUser() {
    const {user, intl} = this.props;

    return (
      <>
        <HeaderButtonContainer>
          <HeaderButton
            icon={
              <>
                <Avatar
                  pxFit={32}
                  vwFit={{xs: '32px'}}
                  loadImmediately
                  image={user.avatar}
                  useOutlineIconAsDefault
                  noBackground
                  small
                />
                {user.isPremium && <PremiumIcon className={styles.premiumIcon} />}
              </>
            }
            indicatorCount={user.jmtMigrationRecovery ? '!' : undefined}
            onClick={this.handleAvatarClick}
            text={intl.formatMessage(messages.profile)}
            ref={this.userButtonRef}
            {...locator.authorizedButton()}
          >
            {this.renderContextMenu()}
          </HeaderButton>
        </HeaderButtonContainer>
      </>
    );
  }

  renderSignIn() {
    const {signingIn, intl, scope, lang} = this.props;

    return (
      <HeaderButtonContainer>
        <HeaderButton
          icon={
            signingIn ? (
              <CircleLoading aria-hidden />
            ) : (
              <Icon aria-hidden type="mono" name="bar-user-linear-24" width="24px" height="24px" />
            )
          }
          to={getUrl('entrance', {lang, scope})}
          onClick={this.handleSignin}
          text={intl.formatMessage(messages.login)}
          {...locator.unauthorizedButton()}
        />
      </HeaderButtonContainer>
    );
  }

  render() {
    const {user} = this.props;

    if (!user) {
      return null;
    }

    return user.anonymous ? this.renderSignIn() : this.renderUser();
  }
}
