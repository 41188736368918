import {Image} from 'components/Image';
import React, {useMemo} from 'react';
import {
  isProductListPurchaseItemsSimpleHeaderAppearance,
  ProductListPurchaseItems as ProductListPurchaseItemsType,
  ProductListPurchaseItemsSimpleHeaderAppearance,
} from 'types/ContentList/ContentListProductListPurchaseItems';

import styles from './index.scss';

type ProductListPurchaseItemsSimpleHeaderProps = {
  headerAppearance: ProductListPurchaseItemsSimpleHeaderAppearance;
};

function ProductListPurchaseItemsSimpleHeader({
  headerAppearance,
}: ProductListPurchaseItemsSimpleHeaderProps): JSX.Element {
  const {title} = headerAppearance.simple;

  return (
    <div className={styles.simpleHeader}>
      <div className={styles.simpleHeaderTitle}>{title}</div>
    </div>
  );
}

type ProductListPurchaseItemsHeaderProps = {
  productListPurchaseItems: ProductListPurchaseItemsType;
};

function ProductListPurchaseItemsHeader({
  productListPurchaseItems,
}: ProductListPurchaseItemsHeaderProps): JSX.Element | null {
  const {headerAppearance} = productListPurchaseItems;
  const content = useMemo(() => {
    if (isProductListPurchaseItemsSimpleHeaderAppearance(headerAppearance)) {
      return <ProductListPurchaseItemsSimpleHeader headerAppearance={headerAppearance} />;
    }

    return undefined;
  }, [headerAppearance]);

  if (!content) {
    return null;
  }

  return <div className={styles.header}>{content}</div>;
}

type ProductListPurchaseItemsProductsProps = {
  productListPurchaseItems: ProductListPurchaseItemsType;
};

const PRODUCT_IMAGE_PX_FIT = 64;
const PRODUCT_IMAGE_VW_FIT = 64;

function ProductListPurchaseItemsProducts({
  productListPurchaseItems,
}: ProductListPurchaseItemsProductsProps): JSX.Element | null {
  const {products} = productListPurchaseItems;

  if (!products?.length) {
    return null;
  }

  return (
    <div className={styles.products}>
      {products.map((item) =>
        item.image ? (
          <div className={styles.productsItem} key={item.productId}>
            <Image
              className={styles.productsImage}
              contain
              height="100%"
              image={item.image}
              multiply
              pxFit={PRODUCT_IMAGE_PX_FIT}
              vwFit={PRODUCT_IMAGE_VW_FIT}
              width="100%"
            />
          </div>
        ) : null,
      )}
    </div>
  );
}

type ProductListPurchaseItemsProps = {
  productListPurchaseItems: ProductListPurchaseItemsType;
};

export function ProductListPurchaseItems({
  productListPurchaseItems,
}: ProductListPurchaseItemsProps): JSX.Element {
  return (
    <div className={styles.root}>
      <ProductListPurchaseItemsHeader productListPurchaseItems={productListPurchaseItems} />
      <ProductListPurchaseItemsProducts productListPurchaseItems={productListPurchaseItems} />
    </div>
  );
}
