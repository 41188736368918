export type Tree<TType> = {
  readonly nodes: TType[];
  readonly ids: Record<string, number>;
  readonly parent: Record<number, number>;
  readonly children: Record<number, number[]>;
};

function add(arr: number[] | undefined, index: number): number[] {
  const list = arr || [];
  list.push(index);
  return list;
}

export type Options<T> = {
  extractId: (item: T) => string;
  extractParentId?: (item: T) => string | void;
  extractChildrenIds?: (item: T) => string[];
};

export function create<TType>(
  nodes: TType[],
  {extractId, extractParentId, extractChildrenIds}: Options<TType>,
): Tree<TType> {
  const result: Tree<TType> = {
    nodes,
    ids: {},
    parent: {},
    children: {},
  };

  nodes.forEach((node, index) => {
    result.ids[extractId(node)] = index;
  });

  nodes.forEach((node, index) => {
    if (extractParentId) {
      const parentId = extractParentId(node);
      if (parentId !== undefined) {
        const parentIndex = result.ids[parentId]!;
        result.parent[index] = parentIndex;
        result.children[parentIndex] = add(result.children[parentIndex], index);
      }
    }
    if (extractChildrenIds) {
      extractChildrenIds(node).forEach((id) => {
        const childIndex = result.ids[id]!;
        result.parent[childIndex] = index;
        result.children[index] = add(result.children[index], childIndex);
      });
    }
  });

  return result;
}

export function getNodes<TType>(tree: Tree<TType>): TType[] {
  return tree.nodes;
}

function getIndex<TType>(tree: Tree<TType>, id: string): number {
  const index = tree.ids[id];
  return index === undefined ? -1 : index;
}

export function getNode<TType>(tree: Tree<TType>, id: string): TType | undefined {
  return tree.nodes[getIndex(tree, id)];
}

export function getRootChildren<TType>(tree: Tree<TType>): TType[] {
  return tree.nodes.filter((_, index) => tree.parent[index] === undefined);
}

export function getChildren<TType>(tree: Tree<TType>, id: string): TType[] {
  const indexes = tree.children[getIndex(tree, id)] || [];
  return indexes.map((index) => tree.nodes[index]!);
}

export function getParent<TType>(tree: Tree<TType>, id: string): TType | undefined {
  return tree.nodes[tree.parent[getIndex(tree, id)]!];
}

export function getAncestors<TType>(
  tree: Tree<TType>,
  id: string,
  fromRootToNode = false,
  includeCurrent = false,
): TType[] {
  const result: TType[] = [];

  const current = tree.nodes[getIndex(tree, id)];
  if (!current) {
    return result;
  }

  let index = tree.parent[getIndex(tree, id)]!;
  while (index >= 0) {
    const node = tree.nodes[index]!;
    if (fromRootToNode) {
      result.unshift(node);
    } else {
      result.push(node);
    }
    index = tree.parent[index]!;
  }

  if (includeCurrent) {
    if (fromRootToNode) {
      result.push(current);
    } else {
      result.unshift(current);
    }
  }

  return result;
}
