import {useApiClient} from 'hooks/useApiClient';
import {useDeepLink} from 'hooks/useDeepLink';
import {useMemo} from 'react';
import {objectFilter} from 'utils/object';
import {firebaseLink} from 'utils/url/app';
import {enhanceTrackingParams} from 'utils/url/tracking';

type UseQrCodeUrlProps = {
  deepUrl: string;
};

type UseQrCodeUrlRenderProps = string;

export const useQrCodeUrl = ({deepUrl}: UseQrCodeUrlProps): UseQrCodeUrlRenderProps => {
  const client = useApiClient();
  const [url = '/'] = useDeepLink(`joom://${deepUrl}`);

  return useMemo(() => {
    const trackingParams = enhanceTrackingParams(
      objectFilter(client.tracking.get(), (_, key) => key.startsWith('utm_')),
      {
        utm_device_id: client.device.getDeviceId(),
      },
    );

    return firebaseLink(client.scope, url, trackingParams);
  }, [client.device, client.scope, client.tracking, url]);
};
