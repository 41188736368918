import {useA11ySettingsPopupRender} from 'components/A11ySettingsPopup';
import {Locator} from 'components/Locator';
import {Scope} from 'config';
import {useDeviceVars} from 'hooks/useDeviceVars';
import {useLanguage} from 'hooks/useLanguage';
import {usePopup} from 'hooks/usePopup';
import {useScope} from 'hooks/useScope';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import {getUrl} from 'routes';

import {COOLBE_BILLING_FAQ_ARTICLE} from '../constants';
import {FooterColumn} from '../FooterColumn';

export function FooterColumnBuyers(): JSX.Element {
  const lang = useLanguage();
  const scope = useScope();
  const {companyName} = scope.companyInfo;
  const {footerJoomHBPromoLink} = useDeviceVars();

  const a11yOptionsPopup = usePopup(useA11ySettingsPopupRender({}));

  const buyersColumnTitle = (
    <FormattedMessage description="'For customers' header in footer" defaultMessage="Customers" />
  );

  return (
    <FooterColumn title={buyersColumnTitle}>
      {scope.is(Scope.GLOBAL) ? (
        <Locator id="FooterLinkCategory">
          <FooterColumn.Item to={getUrl('catalog', {scope, lang})}>
            <FormattedMessage description="Catalog in footer" defaultMessage="Catalogue" />
          </FooterColumn.Item>
        </Locator>
      ) : null}
      <Locator id="FooterLinkSupport">
        <FooterColumn.Item to={getUrl('faq', {scope, lang})}>
          <FormattedMessage description="Support link in footer" defaultMessage="Help сentre" />
        </FooterColumn.Item>
      </Locator>
      <Locator id="FooterLinkPrivacy">
        <FooterColumn.Item to={getUrl('privacy', {scope, lang})}>
          <FormattedMessage description="Privacy link in footer" defaultMessage="Privacy policy" />
        </FooterColumn.Item>
      </Locator>
      <Locator id="FooterLinkTermsInCustomersBlock">
        <FooterColumn.Item to={getUrl('terms', {scope, lang})}>
          <FormattedMessage description="Terms link in footer" defaultMessage="Terms of use" />
        </FooterColumn.Item>
      </Locator>
      <Locator id="FooterLinkAccessibility">
        <FooterColumn.Item onClick={a11yOptionsPopup.open}>
          <FormattedMessage
            description="Ссылка на настройки специальных возможностей"
            defaultMessage="Accessibility settings"
          />
        </FooterColumn.Item>
      </Locator>
      {scope.isDomain(Scope.COOLBE) && (
        <FooterColumn.Item
          to={getUrl('faqArticle', {scope, lang, articleId: COOLBE_BILLING_FAQ_ARTICLE})}
        >
          <FormattedMessage
            defaultMessage="Billing terms and conditions"
            description={
              'Billing terms FAQ link in footer\nOriginal EN text:\nBilling terms and conditions'
            }
          />
        </FooterColumn.Item>
      )}
      {scope.is(Scope.GLOBAL) ? (
        <>
          {scope.notJmt && footerJoomHBPromoLink ? (
            <Locator id="FooterLinkHBPromo">
              <FooterColumn.Item to={footerJoomHBPromoLink}>
                <FormattedMessage
                  description="Ссылка на описание акции ко дню рождения Joom"
                  defaultMessage="Promotion"
                />
              </FooterColumn.Item>
            </Locator>
          ) : null}
          <FooterColumn.Item to={getUrl('premiumSeoList', {scope, lang})}>
            <FormattedMessage
              description="Ссылка на список premium seo страниц в футере"
              defaultMessage="Top articles on {companyName}"
              values={{companyName}}
            />
          </FooterColumn.Item>
          <FooterColumn.Item to={getUrl('bests', {scope, lang})}>
            <FormattedMessage
              description="Ссылка на список популярных запросов"
              defaultMessage="Top searches on {companyName}"
              values={{companyName}}
            />
          </FooterColumn.Item>
        </>
      ) : null}
    </FooterColumn>
  );
}
