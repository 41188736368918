import {Countdown} from 'components/Countdown';
import {Locator} from 'components/Locator';
import {useColorStyles} from 'hooks/useColorStyles';
import {useTimer} from 'hooks/useTimer';
import React, {FC} from 'react';
import {DiscountBadge} from 'types/ProductBadge';

import styles from './index.scss';

type Props = {
  loadedTimeMs: number;
  badge: DiscountBadge;
  short?: boolean;
};

export const DiscountHorizontalBadge: FC<Props> = ({
  loadedTimeMs,
  short,
  badge: {discount},
}: Props) => {
  const timerStyle = useColorStyles({color: discount.timerColor});
  const offerStyle = useColorStyles({color: discount.titleColor, background: discount.background});
  const [timerShown, timestamp] = useTimer(loadedTimeMs, discount.timer?.remainingMs);
  const titleShown = !short || !timerShown;

  return (
    <Locator id="ProductLiteOffer">
      <div className={styles.offer} style={offerStyle}>
        {titleShown && <span className={styles.title}>{discount.title}</span>}
        {timerShown ? (
          <Locator id="ProductLiteOfferTimer">
            <span className={styles.timer} style={timerStyle}>
              <Countdown timestamp={timestamp} />
            </span>
          </Locator>
        ) : null}
      </div>
    </Locator>
  );
};
