import {isDeepLink} from 'helpers/deepLink';
import {useCallback} from 'react';
import {useHistory} from 'react-router-dom';
import {getUrl, matchRoute, RouteNamesUnion} from 'routes';
import {getQueryData, isExternalUrl, isJoomUrl, trimJoomUrl} from 'utils/url';

import {useDeepLinkNavigate} from './useDeepLink';
import {useLanguage} from './useLanguage';
import {useScope} from './useScope';

export function useUniversalNavigate(): (target: string, replace?: boolean) => void {
  const deeplinkNavigate = useDeepLinkNavigate();
  const scope = useScope();
  const lang = useLanguage();
  const history = useHistory();

  const navigate = useCallback(
    (target: string, replace?: boolean) => {
      if (isDeepLink(target)) {
        deeplinkNavigate(target, replace);
      } else if (isExternalUrl(target)) {
        if (isJoomUrl(scope, target)) {
          if (replace) {
            history.replace(trimJoomUrl(scope, target));
          } else {
            history.push(trimJoomUrl(scope, target));
          }
        } else {
          const openedWindow = window.open(target, '_blank');
          openedWindow?.focus();
        }
      } else if (target.startsWith('/')) {
        const {route, result} = matchRoute(scope, target);
        if (route && result) {
          history.push(
            getUrl(
              route.name as RouteNamesUnion,
              {
                ...result.params,
                scope,
                lang,
              },
              getQueryData(target),
            ),
          );
        } else {
          history.push(target);
        }
      }
    },
    [deeplinkNavigate, history, lang, scope],
  );

  return navigate;
}
