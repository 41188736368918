import {
  abortAllUploadRequests,
  stopVideoUploading as stopVideoUploadingAction,
} from 'store/modules/media';
import {isString} from 'utils/guards';

import {UploaderOptions, VideoMediaState} from '../types';

export const stopVideoUploading = async (
  mediaUuid: string,
  prevMediaState: VideoMediaState,
  {commit, dispatch}: UploaderOptions,
): Promise<void> => {
  let mediaState = prevMediaState;
  const {uploadId, uploadParts = []} = mediaState.meta ?? {};

  if (isString(uploadId)) {
    await dispatch(stopVideoUploadingAction(uploadId));
  }

  if (uploadParts.length > 0) {
    await dispatch(abortAllUploadRequests(uploadParts));
  }

  mediaState = commit(mediaUuid, {
    ...mediaState,
    meta: undefined,
  });
};
