export const ROOT_MARGIN = 8;

export const DEFAULT_MODIFIERS = [
  {
    name: 'preventOverflow',
    options: {
      rootBoundary: 'viewport',
      padding: ROOT_MARGIN,
    },
  },
  {
    name: 'offset',
    options: {
      offset: [0, 12],
    },
  },
];
