import type {PopupEvent} from 'store/modules/popup/index';
import {createRequestAction} from 'store/utils/requestActions';
import {EmptyObject} from 'types/EmptyObject';
import {PopupResponse, ShareData} from 'types/Popup';
import {createAction} from 'typesafe-actions';

const getPopupById = createRequestAction(
  '@popup/GET_BY_ID_REQUEST',
  '@popup/GET_BY_ID_SUCCESS',
  '@popup/GET_BY_ID_FAILURE',
)<{popupId: string}, PopupResponse>();

const getPopupForEvent = createRequestAction(
  '@popup/GET_BY_EVENT_REQUEST',
  '@popup/GET_BY_EVENT_SUCCESS',
  '@popup/GET_BY_EVENT_FAILURE',
)<{event: PopupEvent}, PopupResponse | undefined>();

const loadShareData = createRequestAction(
  '@popup/LOAD_SHARE_BUTTON_REQUEST',
  '@popup/LOAD_SHARE_BUTTON_SUCCESS',
  '@popup/LOAD_SHARE_BUTTON_FAILURE',
)<EmptyObject, ShareData>();

const popupMarkAsSeen = createRequestAction(
  '@popup/MARK_AS_SEEN_REQUEST',
  '@popup/MARK_AS_SEEN_SUCCESS',
  '@popup/MARK_AS_SEEN_FAILURE',
)<{popupId: string; params: unknown}, unknown>();

const showPopup = createAction('@popup/SHOW')<PopupResponse>();

export {getPopupById, getPopupForEvent, loadShareData, popupMarkAsSeen, showPopup};
