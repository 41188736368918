import PropTypes from 'prop-types';
import React, {memo} from 'react';
import TrackingCheckpoints from 'components/TrackingCheckpoints';
import TrackingCheckpointShape from 'shapes/TrackingCheckpoint';
import WhiteList from 'components/WhiteList';
import {FormattedMessage} from 'react-intl';
import {Icon} from 'components/UIKit/Icon';
import {useScope} from 'hooks/useScope';
import {Scope} from 'config';
import styles from './Checkpoints.scss';

export const Checkpoints = memo(({checkpoints}) => {
  const scope = useScope();

  if (checkpoints.length === 0) {
    return (
      <WhiteList.Item>
        <div className={styles.empty}>
          {scope.is(Scope.GLOBAL) && (
            <div className={styles.emptyImage}>
              <Icon type="illustration" name="3d-local-pin" />
            </div>
          )}
          <div className={styles.emptyText}>
            <FormattedMessage
              defaultMessage={"You'll see your order's tracking information here soon."}
              description="No tracking checkpoints message"
            />
          </div>
        </div>
      </WhiteList.Item>
    );
  }

  return (
    <WhiteList.Item>
      <TrackingCheckpoints checkpoints={checkpoints} />
    </WhiteList.Item>
  );
});

Checkpoints.propTypes = {
  checkpoints: PropTypes.arrayOf(TrackingCheckpointShape.isRequired).isRequired,
};
