import {type ContentListScreenOrigin} from 'types/ContentListScreen';
import {FeedbackContext} from 'types/Feedback';
import {RewardWheelSource} from 'types/RewardWheel';

export enum DeepLinkActionType {
  POPUP = 'popup',
  FEEDBACK = 'feedback',
  REWARD_WHEEL = 'rewardWheel',
  REWARD_WHEEL_ACTIVATE = 'rewardWheelActivate',
  IDLER = 'idler',
  STORE_SUBSCRIBE = 'storeSubscribe',
  COUPON = 'coupon',
  PROMOCODE = 'promocode',
  AUTH_MIGRATE = 'authMigrate',
  AUTH_RECOVERY = 'authRecovery',
  SUPPORT = 'support',
  CONTENT_LIST_SCREEN = 'contentListScreen',
  EMAIL_CONFIRM = 'emailConfirmation',
  ENTITY_REDIRECT = 'entityRedirect',
}

type DeepLinkPopupAction = {
  readonly type: typeof DeepLinkActionType.POPUP;
  params: {
    popupId: string;
    popupParams?: Record<string, string>;
  };
};

type DeepLinkFeedbackAction = {
  readonly type: typeof DeepLinkActionType.FEEDBACK;
  params: {
    context: FeedbackContext;
  };
};

type DeepLinkRewardWheelActivateAction = {
  readonly type: typeof DeepLinkActionType.REWARD_WHEEL_ACTIVATE;
  params: {
    rewardWheelId: string;
  };
};

type DeepLinkRewardWheelAction = {
  readonly type: typeof DeepLinkActionType.REWARD_WHEEL;
  params?: {
    source?: RewardWheelSource;
  };
};

type DeepLinkIdlerAction = {
  readonly type: typeof DeepLinkActionType.IDLER;
};

type DeepLinkStoreSubscribeAction = {
  readonly type: typeof DeepLinkActionType.STORE_SUBSCRIBE;
  params: {
    storeId: string;
    payload?: {
      // Use strings union here
      place: 'storePage';
      requestDiscount: boolean;
    };
  };
};

type DeepLinkCouponAction = {
  readonly type: typeof DeepLinkActionType.COUPON;
  params: {
    couponId: string;
  };
};

type DeepLinkPromocodeAction = {
  readonly type: typeof DeepLinkActionType.PROMOCODE;
  params: {
    code: string;
  };
};

type DeepLinkAuthMigrateAction = {
  readonly type: typeof DeepLinkActionType.AUTH_MIGRATE;
};

type DeepLinkAuthRecoveryAction = {
  readonly type: typeof DeepLinkActionType.AUTH_RECOVERY;
  params: {
    preferredProvider?: string;
  };
};

type DeepLinkSupportAction = {
  readonly type: typeof DeepLinkActionType.SUPPORT;
  params?: {
    payload?: {
      metadata?: {
        type?: string;
        origin?: string;
        orderId?: string;
      };
    };
  };
};

type DeepLinkContentListScreenAction = {
  readonly type: typeof DeepLinkActionType.CONTENT_LIST_SCREEN;
  params: {
    origin: ContentListScreenOrigin;
  };
};

type DeepLinkEntityRedirectAction = {
  readonly type: typeof DeepLinkActionType.ENTITY_REDIRECT;
};

export type DeepLinkAction = (
  | DeepLinkPopupAction
  | DeepLinkFeedbackAction
  | DeepLinkRewardWheelActivateAction
  | DeepLinkRewardWheelAction
  | DeepLinkIdlerAction
  | DeepLinkStoreSubscribeAction
  | DeepLinkCouponAction
  | DeepLinkPromocodeAction
  | DeepLinkAuthMigrateAction
  | DeepLinkAuthRecoveryAction
  | DeepLinkSupportAction
  | DeepLinkContentListScreenAction
  | DeepLinkEntityRedirectAction
) & {
  // this is needed to make `/ln` links work
  readonly fallbackUrl?: string;
};

export type DeepLinkActionDispatch = (action: DeepLinkAction) => void;

export function isDeepLinkAction(action: unknown): action is DeepLinkAction {
  return Boolean(
    action &&
      typeof action === 'object' &&
      typeof (action as Record<string, unknown>).type === 'string',
  );
}
