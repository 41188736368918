import {areApproximatelyEqual, clamp} from 'utils/math';

import {CanvasRect, Rect} from './types';

export const getDocumentRect = (): Rect => {
  const rect = document.body.getBoundingClientRect();

  const {width, height} = rect;

  const top = document.documentElement.scrollTop + rect.top;
  const left = document.documentElement.scrollLeft + rect.left;
  const right = left + width;
  const bottom = top + height;

  return {width, height, top, left, right, bottom};
};

export const getViewRect = (): Rect => {
  const width = window.innerWidth;
  const height = window.innerHeight;

  const top = document.documentElement.scrollTop;
  const left = document.documentElement.scrollLeft;
  const right = left + width;
  const bottom = top + height;

  return {width, height, top, left, right, bottom};
};

export type GetCanvasRectOptions = {
  safeAreaScaler?: number;
};

export const getCanvasRect = (
  viewRect: Rect,
  documentRect: Rect,
  {safeAreaScaler = 1}: GetCanvasRectOptions = {},
): CanvasRect => {
  const initWidth = viewRect.width * safeAreaScaler;
  const initHeight = viewRect.height * safeAreaScaler;

  const width = Math.min(initWidth, documentRect.width);
  const height = Math.min(initHeight, documentRect.height);

  const top = clamp(
    viewRect.top + viewRect.height / 2 - height / 2,
    documentRect.top,
    documentRect.bottom - height,
  );
  const left = clamp(
    viewRect.left + viewRect.width / 2 - width / 2,
    documentRect.left,
    documentRect.right - width,
  );
  const right = left + width;
  const bottom = top + height;

  const scaler = window.devicePixelRatio;
  const scaledWidth = width * scaler;
  const scaledHeight = height * scaler;

  return {
    width,
    height,

    top,
    left,
    right,
    bottom,

    initWidth,
    initHeight,

    scaler,
    scaledWidth,
    scaledHeight,
  };
};

export type ShouldCanvasBeSynced = {
  safeAreaScaler?: number;
  scrollTolerancePercent?: number;
};

export const shouldCanvasBeSynced = (
  canvasRect: CanvasRect,
  viewRect: Rect,
  documentRect: Rect,
  {safeAreaScaler = 1, scrollTolerancePercent = 0.01}: ShouldCanvasBeSynced = {},
): boolean => {
  const nextDocumentRect = getDocumentRect();
  const nextViewRect = getViewRect();
  const nextCanvasRect = getCanvasRect(viewRect, nextDocumentRect, {safeAreaScaler});

  // Horizontal resize of document
  if (
    !areApproximatelyEqual(documentRect.width, nextDocumentRect.width) &&
    !areApproximatelyEqual(canvasRect.width, nextCanvasRect.width)
  ) {
    return true;
  }

  // Vertical resize of document
  if (
    !areApproximatelyEqual(documentRect.height, nextDocumentRect.height) &&
    !areApproximatelyEqual(canvasRect.height, nextCanvasRect.height)
  ) {
    return true;
  }

  // Resize of view
  // Do not check the height, because in mobile Safari it changes when scrolling.
  if (!areApproximatelyEqual(viewRect.width, nextViewRect.width)) {
    return true;
  }

  // Visibility of canvas
  const scrollToleranceX = canvasRect.width * scrollTolerancePercent;
  const scrollToleranceY = canvasRect.height * scrollTolerancePercent;

  if (
    (!areApproximatelyEqual(nextDocumentRect.top, canvasRect.top) &&
      nextViewRect.top - canvasRect.top <= scrollToleranceY) ||
    (!areApproximatelyEqual(nextDocumentRect.left, canvasRect.left) &&
      nextViewRect.left - canvasRect.left <= scrollToleranceX) ||
    (!areApproximatelyEqual(nextDocumentRect.right, canvasRect.right) &&
      canvasRect.right - nextViewRect.right <= scrollToleranceX) ||
    (!areApproximatelyEqual(nextDocumentRect.bottom, canvasRect.bottom) &&
      canvasRect.bottom - nextViewRect.bottom <= scrollToleranceY)
  ) {
    return true;
  }

  return false;
};
