import {Popup} from 'components/UIKit/Popup';
import {StandaloneControls} from 'components/UIKit/Popup/Header/StandaloneControls';
import {PopupViewProps} from 'components/UIKit/Popup/PopupProvider';
import React from 'react';

import {Success} from '../Form/Success';

export type Props = PopupViewProps & {
  email: string;
};

export const SuccessPopup = ({onBack, onClose, email}: Props): JSX.Element => {
  return (
    <Popup width="435px" testId="Support-success-popup">
      <StandaloneControls background="stuck" onBack={onBack} onClose={onClose} />
      <Success email={email} />
    </Popup>
  );
};
