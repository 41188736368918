import {getLanguage, getOrigin} from 'store/modules/preferences';
import {RootState} from 'store/rootReducer';
import {extractResponsePayloadEnhanced} from 'store/utils';
import {createRequestAction, requestActionCreator} from 'store/utils/requestActions';
import {ClientBackendResponse} from 'types/ClientBackendResponse';
import {Error} from 'types/Error';
import {createReducer, FuncAction} from 'typesafe-actions';
import {assignRemove, assignSet} from 'utils/object';

export const LOAD = 'orderGroups/customsDuty/LOAD';
export const LOAD_SUCCESS = 'orderGroups/customsDuty/LOAD_SUCCESS';
export const LOAD_FAIL = 'orderGroups/customsDuty/LOAD_FAIL';

type OrderGroupCustomsDuty = {
  challenge: {method: string; url: string; body: unknown};
  price?: {amount: number; currency: string};
};

type CustomsDutyResponse = {
  id: string;
  result: OrderGroupCustomsDuty;
};

export const loadAction = createRequestAction(LOAD, LOAD_SUCCESS, LOAD_FAIL)<
  {id: string},
  CustomsDutyResponse
>();

export const actions = {loadAction};

type State = {
  data: Record<string, OrderGroupCustomsDuty>;
  loaded: Record<string, boolean>;
  loading: Record<string, boolean>;
  error: Record<string, Error>;
};

const initialState: State = {
  data: {},
  loaded: {},
  loading: {},
  error: {},
};

export const reducer = createReducer<State>(initialState)
  .handleAction(loadAction.request, (state, {payload}) => {
    return {
      ...state,
      error: assignRemove(state.error, payload.id),
      loaded: assignRemove(state.loaded, payload.id),
      loading: assignSet(state.loading, payload.id, true),
    };
  })
  .handleAction(loadAction.success, (state, {payload}) => {
    return {
      ...state,
      data: assignSet(state.data, payload.id, payload.result),
      loaded: assignSet(state.loaded, payload.id, true),
      loading: assignRemove(state.loading, payload.id),
    };
  })
  .handleAction(loadAction.failure, (state, {meta, payload}) => {
    return {
      ...state,
      error: assignSet(state.error, meta.id, payload),
      loading: assignRemove(state.loading, meta.id),
    };
  });

export function isOrderGroupCustomsDutyLoaded(globalState: RootState, id: string): boolean {
  const {customsDuty} = globalState.orderGroups;
  return !!customsDuty.loaded[id];
}

export function getOrderGroupCustomsDuty(
  globalState: RootState,
  id: string,
): unknown | null | undefined {
  const {customsDuty} = globalState.orderGroups;
  return isOrderGroupCustomsDutyLoaded(globalState, id) ? customsDuty.data[id] : null;
}

export function getOrderGroupCustomsDutyError(
  globalState: RootState,
  id: string,
): Error | null | undefined {
  return globalState.orderGroups.customsDuty.error[id];
}

export function isOrderGroupCustomsDutyLoading(globalState: RootState, id: string): boolean {
  return !!globalState.orderGroups.customsDuty.loading[id];
}

export function loadOrderGroupCustomsDuty(
  orderGroupId: string,
): FuncAction<Promise<CustomsDutyResponse>> {
  return requestActionCreator(loadAction, {id: orderGroupId}, (store, client) => {
    const state = store.getState();
    const prefix = `${getOrigin(state)}/${getLanguage(state)}/duty/${orderGroupId}`;
    const body = {
      successUrl: `${prefix}/success`,
      cancelUrl: `${prefix}/cancel`,
      failureUrl: `${prefix}/error`,
    };

    return client.api
      .post<ClientBackendResponse<CustomsDutyResponse>>(
        `/orderGroups/${orderGroupId}/customsDuty`,
        {body},
      )
      .then(extractResponsePayloadEnhanced);
  });
}
