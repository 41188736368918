import {NamedColor} from 'types/Color';
import {EventParams} from 'types/EventParams';
import {GalleryItems} from 'types/GalleryItem';
import {Text} from 'types/Layout';
import {ProductLiteRaw} from 'types/ProductLite';
import {SocialUser} from 'types/SocialUser';

export enum SocialPostFilterId {
  ALL = 'all',
  ONE_STAR = 'oneStar',
  TWO_STARS = 'twoStars',
  THREE_STARS = 'threeStars',
  FOUR_STARS = 'fourStars',
  FIVE_STARS = 'fiveStars',
  WITH_PHOTO = 'withPhoto',
  WITH_VIDEO = 'withVideo',
  WITH_MEDIA = 'withMedia',
  WITH_TEXT = 'withText',
  WITH_AT_LEAST_ONE_STAR = 'withAtLeastOneStar',
  WITH_AT_LEAST_TWO_STARS = 'withAtLeastTwoStars',
  WITH_AT_LEAST_THREE_STARS = 'withAtLeastThreeStars',
  WITH_AT_LEAST_FOUR_STARS = 'withAtLeastFourStars',
  WITH_AT_LEAST_FIVE_STARS = 'withAtLeastFiveStars',
}

type TextObj = {
  body?: string;
  subtitle?: string;
  title?: string;
};

type SocialPostLocalizedText = Partial<Record<string, TextObj>>;

type SocialPostText =
  | {
      // Simple post text. This format is used by majority of Joom users when they create posts/reviews.
      simpleText?: {text: string};
    }
  | {
      // Structed text with title, subtitle and body. Used for exported promotions and JO posts.
      promoPostText?: TextObj;
    }
  | {
      // Map (lang -> text) with of author provided localizations. Used for JO and live video posts
      localizedText?: SocialPostLocalizedText;
    };

type SocialPostTextMetadata = Record<
  string,
  {
    promocode: {
      expired: boolean;
      value: string;
    };
  }
>;

type SocialPostTextTranslationPolicy =
  | 'originalFirst'
  | 'originalOnly'
  | 'translatedFirst'
  | 'translatedOnly';

export type SocialPostReviewAnswer = {
  text: Text;
};

export type SocialPostTextDetails = {
  // Raw text provided by author. It doesn't contain any applied server-side formatting, escaping, etc. It should be used for editing text by the author.
  rawText?: SocialPostText;
  // The text in the original author language. It contains applied server-side formatting, escaping, etc. It should be used to display text.
  originalText?: SocialPostText;
  // The text translated to the viewer's device language. It contains applied server-side formatting, escaping, etc. It should be used to display text.
  translatedText?: SocialPostText;
  textMetadata?: SocialPostTextMetadata;
  translationPolicy: SocialPostTextTranslationPolicy;
};

export type SocialPost = {
  id: string;
  media?: GalleryItems;
  mediaFallback?: GalleryItems;
  textV2?: SocialPostTextDetails;
  // @deprecated
  text?: string;
  translateButtonTexts?: unknown;
  createdTimeMs: number;
  author: SocialUser;
  countryMetadata?: unknown;
  likesCount?: number;
  commentsCount?: number;
  isHidden?: boolean;
  isHideable?: boolean;
  isLiked?: boolean;
  isOwn?: boolean;
  isFake?: boolean;
  isEditable?: boolean;
  isDeletable?: boolean;
  isReportable?: boolean;
  orderId?: string;
  callToAction?: unknown;
  starRating?: number;
  hasProducts?: boolean;
  payload?: {
    review?: {
      size?: string;
      color?: NamedColor;
      colors?: NamedColor[];
      product?: ProductLiteRaw;
      productContext?: unknown;
      reviewId?: string;
      answers?: SocialPostReviewAnswer[];
    };
  };
  likesDisabled?: boolean;
  commentsDisabled?: boolean;
  sharingDisabled?: boolean;
  reportingDisabled?: boolean;
  isUnexportedReview?: boolean;
  likers?: SocialUser[];
  productsCount?: number;
  eventParams?: EventParams;
  isSubscribed?: boolean;
  canChangeSubscribeStatus?: boolean;
  forAdults?: boolean;
  feedback?: {
    deeplink: string;
  };
  caption?: string;
  isPinned?: boolean;
  // @deprecated
  originalText?: string;
  // @deprecated
  translationPolicy?: string;
};

export function getSocialPostText(post?: Pick<SocialPost, 'textV2'> | null): string {
  if (!post?.textV2?.translatedText) return '';
  const {translatedText: text} = post.textV2;

  if ('simpleText' in text) return text.simpleText?.text || '';
  if ('promoPostText' in text) return text.promoPostText?.body || '';
  if ('localizedText' in text && text.localizedText) {
    return Object.values(text.localizedText)[0]?.body || '';
  }
  return '';
}

export function getSocialPostLocalizedText({
  textV2,
}: Pick<SocialPost, 'textV2'>): SocialPostLocalizedText | undefined {
  if (textV2?.translatedText && 'localizedText' in textV2.translatedText) {
    return textV2?.translatedText.localizedText;
  }
  return undefined;
}
