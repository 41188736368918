import React from 'react';
import {clamp} from 'utils/math';

type Props = {
  progress: number;
  radius?: number;
  strokeWidth?: number;
};

export function CircleProgress({progress, radius = 40, strokeWidth = 6}: Props): JSX.Element {
  const size = radius * 2 + strokeWidth;
  const center = radius + strokeWidth / 2;
  const circumference = radius * 2 * Math.PI;
  const dashoffset = (1 - clamp(progress, 0, 1)) * circumference;

  return (
    <svg viewBox={`0 0 ${size} ${size}`} xmlns="http://www.w3.org/2000/svg">
      <circle
        cx={center}
        cy={center}
        fill="transparent"
        r={radius}
        stroke="currentColor"
        strokeDasharray={circumference}
        strokeDashoffset={dashoffset}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
}
