import {Locator} from 'components/Locator';
import React, {useCallback} from 'react';
import {OrderReviewQuestion} from 'types/OrderReviewInfo';

import styles from './index.scss';

type SelectProps = {
  name: string;
  onSelect: (value: string[]) => void;
  question: OrderReviewQuestion;
  type?: 'radio' | 'checkbox';
  value?: string[];
};

export function Select({
  name,
  onSelect,
  question,
  type = 'radio',
  value,
}: SelectProps): JSX.Element | null {
  const {answers} = question;

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = event.target.value;
      let nextValue = value?.filter((val) => val !== inputValue) ?? [];

      if (!value || value.length === nextValue.length) {
        if (type === 'checkbox') {
          nextValue.push(inputValue);
        } else {
          nextValue = [inputValue];
        }
      }

      onSelect(nextValue);
    },
    [onSelect, type, value],
  );

  if (!answers?.length) {
    return null;
  }

  return (
    <div className={styles.root}>
      {answers.map((answer) => (
        <Locator id="reviewForm-reason" key={answer.id}>
          <label>
            <input
              checked={value?.includes(answer.id)}
              className={styles.input}
              name={name}
              onChange={handleChange}
              type="checkbox"
              value={answer.id}
            />
            <span className={styles.item}>{answer.title}</span>
          </label>
        </Locator>
      ))}
    </div>
  );
}
