import cn from 'classnames';
import {ConditionalWrapper} from 'components/ConditionalWrapper';
import {DeepLink} from 'components/DeepLink';
import {Image} from 'components/Image';
import {Locator} from 'components/Locator';
import {OnboardingTooltip} from 'components/OnboardingTooltip/loadable';
import {eventParamsList} from 'helpers/eventParams';
import {usePreviewEvent} from 'hooks/usePreviewEvent';
import React, {useCallback, useMemo, useRef} from 'react';
import {CategoryPromoLink as CategoryPromoLinkType} from 'types/CategoryPromoLink';
import {gradientToRgbColor, isColorLight} from 'utils/colors';
import {
  convertAnyBackendGradientToCSSValue,
  convertBackendColorToCSSValue,
} from 'utils/styles/color';

import styles from './index.scss';

type Props = {
  link: CategoryPromoLinkType;
  onClick?: () => void;
  shouldHighlight?: boolean;
  linkId: string;
  setHighlightStatus: (index: number | string, value: boolean) => void;
};

const iconVwFit = {sm: '1.5em'};

export const CategoryPromoLink = React.memo(function CategoryPromoLink({
  link,
  onClick,
  setHighlightStatus,
  shouldHighlight = false,
  linkId,
}: Props): React.ReactElement {
  const ref = useRef<HTMLAnchorElement>(null);

  usePreviewEvent(
    {
      rootRef: ref,
      previewEvent: {
        type: 'categoryPromoLinkPreview',
        payload: {
          label: link.title.text,
          from: 'header',
        },
        params: eventParamsList(link.eventParams),
      },
    },
    {
      clickEvent: {
        type: 'categoryPromoLinkClick',
        payload: {
          label: link.title.text,
          url: link.url,
          from: 'header',
        },
      },
    },
  );

  const handleClick = useCallback(() => {
    if (onClick) {
      onClick();
    }
    if (shouldHighlight && setHighlightStatus) {
      setHighlightStatus(linkId, false);
    }
  }, [onClick, shouldHighlight, setHighlightStatus, linkId]);

  const handleHighlightTooltipButtonClick = useCallback(() => {
    setHighlightStatus(linkId, false);
  }, [setHighlightStatus, linkId]);

  const style = useMemo(
    () =>
      // for links with background used predefined text color (colorClass)
      link.background
        ? {background: convertAnyBackendGradientToCSSValue(link.background)}
        : {color: convertBackendColorToCSSValue(link.title.color)},
    [link.background, link.title.color],
  );

  const colorClass: string | undefined = useMemo(() => {
    if (link.background) {
      const backgroundRgbColor = gradientToRgbColor(link.background);
      const isLight = isColorLight(backgroundRgbColor);
      return isLight ? styles.lightBackground : styles.darkBackground;
    }

    return undefined;
  }, [link.background]);

  return (
    <Locator id="CategoryPromoLink">
      <div className={styles.promoLinkWrapper}>
        <ConditionalWrapper
          condition={shouldHighlight}
          // doesn't affect performance
          // eslint-disable-next-line react/jsx-no-bind
          wrapper={(children) => (
            <div className={styles.promoLinkOnboardingWrapper}>
              <OnboardingTooltip
                message={link.highlightTooltip?.caption.text}
                buttonText={link.highlightTooltip?.button.text}
                onButtonClick={handleHighlightTooltipButtonClick}
                useBubble
                useCloseButton
                useForceReposition
                eventParams={link.eventParams}
              >
                {children}
              </OnboardingTooltip>
            </div>
          )}
        >
          <DeepLink
            className={cn(styles.promoLink, link.background && styles.withBackground, colorClass)}
            onClick={handleClick}
            ref={ref}
            to={link.url}
            style={style}
          >
            {link.icon && !link.background && (
              <span className={styles.promoLinkIcon}>
                <Image
                  vwFit={iconVwFit}
                  className={styles.image}
                  image={link.icon}
                  contain
                  pxFit={40}
                />
              </span>
            )}
            {link.title.text}
          </DeepLink>
        </ConditionalWrapper>
      </div>
    </Locator>
  );
});
