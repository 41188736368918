import {PAGE_PARAM, SORT_PARAM, TYPE_PARAM} from 'components/ClientProduct/Reviews/constants';
import {Location} from 'history';
import {DeviceVars, WebProductStoreReviewsConfig8938} from 'types/deviceVars';
import {isRecord, isString} from 'utils/guards';
import {createUrl, getQueryData, getQueryStringParam, QueryMap} from 'utils/url';

type Query = {
  filterId?: string;
  sort?: string;
  page?: string;
};

export function createFilterUrl(baseUrl: string, query: Query): string {
  const params: QueryMap = {};

  if (typeof query.filterId !== 'undefined') {
    params[TYPE_PARAM] = query.filterId;
  }

  if (typeof query.page !== 'undefined') {
    params[PAGE_PARAM] = query.page;
  }

  if (typeof query.sort !== 'undefined') {
    params[SORT_PARAM] = query.sort;
  }

  return createUrl(baseUrl, params, {removeEmptyQueryParams: true});
}

type ReviewsQueryParams = {
  filter?: string;
  pageToken?: string;
  sort?: string;
};

export const getReviewsQueryParams = (query: QueryMap<string>): ReviewsQueryParams => {
  return {
    filter: getQueryStringParam(query, TYPE_PARAM),
    pageToken: getQueryStringParam(query, PAGE_PARAM),
    sort: getQueryStringParam(query, SORT_PARAM),
  };
};

type GetProductStoreReviewsConfigOptions = {
  deviceVars: DeviceVars;
  location: Location;
};

export const getProductStoreReviewsConfig = ({
  deviceVars,
  location,
}: GetProductStoreReviewsConfigOptions): WebProductStoreReviewsConfig8938 | undefined => {
  const query = getQueryData(location.search);
  const {webProductStoreReviewsConfig8938: config} = deviceVars;

  if (!isRecord(config?.utms)) {
    return undefined;
  }

  const isEnabled = Object.entries(config.utms).some(([utmKey, utmValues]) => {
    const queryValue = query[utmKey];

    return isString(queryValue) && Array.isArray(utmValues) && utmValues.includes(queryValue);
  });

  if (!isEnabled) {
    return undefined;
  }

  return config;
};
