import {ContextMenu} from 'components/ContextMenu';
import {Overlay} from 'components/Overlay';
import {Button} from 'components/UIKit/Button';
import {usePopupsShown} from 'components/UIKit/Popup/PopupProvider';
import {useSendAnalytics, useSendAnalyticsWithSinceOpenMs} from 'hooks/useAnalytics';
import {useApiClient} from 'hooks/useApiClient';
import {useEffectOnce} from 'hooks/useEffectOnce';
import {useRoute} from 'hooks/useRoute';
import React, {ReactElement, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';
import {useLocation} from 'react-router-dom';
import {countryList} from 'utils/countries';

import styles from './index.scss';

const messages = defineMessages({
  title: {
    description:
      '[title] Заголовок попапа с предолжением перейти на другой сайт, например: "Хотите заказать в Россию?"',
    defaultMessage: 'Want to order {toCountryName}?',
  },
  text: {
    description:
      'Текст после заголовка "Хотите заказать в Россию?" с предложением перейти на другой сайт',
    defaultMessage: 'If yes, then you will be redirected to {siteUrl}',
  },
  leaveButton: {
    description:
      '[button] Текст кнопки, нажимая на которую пользователь соглашается перейти на другой сайт',
    defaultMessage: 'Yes',
  },
  stayButton: {
    description:
      '[button] Текст кнопки, нажимая на которую пользователь отказывается перейти на другой сайт',
    defaultMessage: 'No',
  },
});

const GEO_IP_STORAGE_KEY = 'lastSelectedGeoIpRegion';
const SHOWN_STORAGE_KEY = 'entityRedirectShown';

export function JmtEntityRedirectTooltip(): ReactElement | null {
  const client = useApiClient();
  const sendEvent = useSendAnalytics();
  const sendEventSinceOpen = useSendAnalyticsWithSinceOpenMs();
  const route = useRoute();
  const popupsShown = usePopupsShown();
  const location = useLocation();
  const initialPath = useRef(location.pathname).current;

  const geoIpRegion = client.device.getDetectedCountry();

  const [loading, setLoading] = useState(false);
  const [shown, setShown] = useState(false);

  useEffectOnce(() => {
    const jmtMigrationInfoEnabled = client.device.getDeviceVar('jmtAutoRedirect');
    const jmtCountries = client.device.getDeviceVar('jmtMigrationCountries')?.countries;

    const isJmtGeoIpRegion = jmtCountries?.includes(geoIpRegion);
    const lastGeoIpRegion = client.storages.local.getItem(GEO_IP_STORAGE_KEY);
    const isAlreadyShown = client.storages.session.getItem(SHOWN_STORAGE_KEY);

    if (
      // must be shown only once per browser tab
      !isAlreadyShown &&
      // show only for jmt or everywhere when devicevar enabled
      (client.scope.isJmt || jmtMigrationInfoEnabled) &&
      // geo ip region exists
      geoIpRegion &&
      // delivery to geo ip region not available for this entity
      isJmtGeoIpRegion !== client.scope.isJmt &&
      // geo ip region changes since last user cancellation or never saved
      lastGeoIpRegion !== geoIpRegion
    ) {
      setShown(true);

      sendEvent('appMigrationInfoShow', {source: route.name});
    }
  });

  useEffect(() => {
    // close on any navigation
    if (location.pathname !== initialPath) {
      setShown(false);

      client.storages.session.setItem(SHOWN_STORAGE_KEY, true);
    }
  }, [client.storages.session, initialPath, location.pathname]);

  const handleLeave = useCallback(() => {
    sendEventSinceOpen(({sinceOpenMs: sinceShowMs}) => ({
      type: 'appMigrationInfoButtonClick',
      payload: {source: route.name, sinceShowMs, buttonId: 'leave'},
    }));

    setLoading(true);

    client.jmtMigration.manualRedirectToOppositeEntity();
  }, [client.jmtMigration, route.name, sendEventSinceOpen]);

  const handleStay = useCallback(() => {
    sendEventSinceOpen(({sinceOpenMs: sinceShowMs}) => ({
      type: 'appMigrationInfoButtonClick',
      payload: {source: route.name, sinceShowMs, buttonId: 'stay'},
    }));

    setShown(false);

    client.storages.local.setItem(GEO_IP_STORAGE_KEY, geoIpRegion);
  }, [client.storages.local, geoIpRegion, route.name, sendEventSinceOpen]);

  const toCountryName = useMemo(() => {
    const countryData = countryList.find(({id}) => id === geoIpRegion);

    return countryData ? <FormattedMessage {...countryData.nameToCountry} /> : geoIpRegion;
  }, [geoIpRegion]);

  const siteUrl = client.scope.isJmt ? 'joom.com' : 'joom.ru';

  if (!shown || popupsShown) {
    return null;
  }

  return (
    <ContextMenu widthByContent noPaddings usePortal>
      <div className={styles.root}>
        <div className={styles.title}>
          <FormattedMessage {...messages.title} values={{toCountryName}} />
        </div>
        <div className={styles.text}>
          <FormattedMessage {...messages.text} values={{siteUrl}} />
        </div>
        <Overlay loading={loading}>
          <div className={styles.button}>
            <Button tag="button" color="accent" onClick={handleLeave} disabled={loading}>
              <FormattedMessage {...messages.leaveButton} />
            </Button>
          </div>
        </Overlay>
        <div className={styles.button}>
          <Button tag="button" color="gray" onClick={handleStay} disabled={loading}>
            <FormattedMessage {...messages.stayButton} />
          </Button>
        </div>
      </div>
    </ContextMenu>
  );
}
