import ErrorMessage from 'components/ErrorMessage';
import ErrorShape from 'shapes/Error';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import React, {createRef, Component} from 'react';
import DotLoader from 'components/DotLoader';
import WhiteList from 'components/WhiteList';
import {defineMessages, FormattedMessage, useIntl} from 'react-intl';
import {required, email} from 'utils/validation';
import {Locator} from 'components/Locator';
import {OkButton} from './OkButton';
import styles from './NotDeliveredForm.scss';

const cn = classnames.bind(styles);

const messages = defineMessages({
  placeholder: {
    defaultMessage: 'Enter your email',
    description: 'Placeholder for email input',
  },
});

export function NotDeliveredForm(props) {
  const intl = useIntl();
  return <NotDeliveredFormBase {...props} intl={intl} />;
}

class NotDeliveredFormBase extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    error: ErrorShape,
    intl: PropTypes.objectOf(PropTypes.any).isRequired,
  };

  static defaultProps = {
    loading: false,
    error: null,
  };

  constructor(props) {
    super(props);
    this.emailRef = createRef(null);

    this.state = {
      error: props.error,
      pristine: !props.error,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const error = props.error || state.error;

    return {
      error,
      pristine: !error && state.pristine,
    };
  }

  getValue() {
    return this.emailRef.current.value || '';
  }

  handleBlur = () => {
    if (this.state.pristine) {
      this.setState({pristine: false});
    }
    this.validate();
  };

  handleFocus = (evt) => {
    this.setState({error: null});
  };

  handleSubmit = (evt) => {
    evt.preventDefault();
    if (this.state.pristine) {
      this.setState({pristine: false});
    }
    if (this.validate()) {
      this.props.onSubmit(this.getValue());
    }
  };

  validate() {
    const value = this.getValue();
    const error = required(value) || email(value);
    const formattedError = error ? this.props.intl.formatMessage(error) : null;
    if (formattedError !== this.state.error) {
      this.setState({error: formattedError});
    }

    return !error;
  }

  renderError() {
    const {error, pristine} = this.state;
    if (error && !pristine) {
      return (
        <p className={styles.error}>
          {typeof error === 'string' ? error : <ErrorMessage error={error} internal noRequestId />}
        </p>
      );
    }

    return null;
  }

  render() {
    const {error, pristine} = this.state;
    const {onCancel, loading} = this.props;
    return (
      <WhiteList>
        <WhiteList.Header>
          <h2 className={styles.header}>
            <FormattedMessage
              defaultMessage="Enter your email"
              description="Not delivered form, header"
            />
          </h2>
        </WhiteList.Header>
        <WhiteList.Item>
          <div className={styles.content}>
            <p className={styles.text}>
              <FormattedMessage
                defaultMessage="Please enter your email address and our customer support team will get in touch with you soon."
                description="Not delivered form, enter your email text"
              />
            </p>
            <form className={styles.form} onSubmit={this.handleSubmit}>
              <Locator id="DeliveryConfirmationEmailSubmitButton">
                <button type="submit" className={styles.submit}>
                  <span className={styles.submitText}>
                    <FormattedMessage
                      defaultMessage="Submit"
                      description="Not delivered form, submit"
                    />
                  </span>
                  {loading ? <span className={styles.submitLoading} /> : null}
                </button>
              </Locator>
              <div className={styles.emailWrapper}>
                <Locator id="DeliveryConfirmationEmailInput">
                  <input
                    className={cn('email', {
                      emailError: !pristine && error,
                    })}
                    name="email"
                    onBlur={this.handleBlur}
                    onFocus={this.handleFocus}
                    placeholder={this.props.intl.formatMessage(messages.placeholder)}
                    ref={this.emailRef}
                    type="text"
                  />
                </Locator>
              </div>
              {this.renderError()}
            </form>
            <Locator id="DeliveryConfirmationEmailCancelButton">
              <OkButton onClick={onCancel}>
                <FormattedMessage
                  defaultMessage="Cancel"
                  description="Not delivered form, cancel"
                />
              </OkButton>
            </Locator>
            {!loading || (
              <div className={styles.loading}>
                <span className={styles.loader}>
                  <DotLoader style="dark" />
                </span>
              </div>
            )}
          </div>
        </WhiteList.Item>
      </WhiteList>
    );
  }
}
