import {ClientPopup as ClientPopupBase} from 'components/ClientPopup';
import {PopupView, PopupViewProps} from 'components/UIKit/Popup/PopupProvider';
import {useSelector} from 'hooks/redux';
import {useDispatchActionMap} from 'hooks/useDispatch';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
  getPopupById,
  getPopupForEvent,
  loadShareData,
  PopupEvent,
  popupMarkAsSeen,
} from 'store/modules/popup';
import {
  getCurrentPopup,
  getShareData,
  getShareError,
  isPopupLoaded,
  isShareLoading,
} from 'store/modules/popup/selectors';
import {PopupResponse} from 'types/Popup';

export type PopupParams = Record<string, string>;

type EssentialProps = {
  popup: PopupResponse;
};

type Props = {
  popupId?: string;
  popupParams?: PopupParams;
  priority?: number;
  eventPopupType?: PopupEvent;
};

const actionsMap = {
  getPopupById,
  getPopupForEvent,
  popupMarkAsSeen,
  loadShareData,
};

const ClientPopupEssential: React.FC<EssentialProps & PopupViewProps> = ({
  popup,
  onBack,
  onClose,
}) => {
  const shareData = useSelector(getShareData);
  const shareLoading = useSelector(isShareLoading);
  const shareError = useSelector(getShareError);
  const actions = useDispatchActionMap(actionsMap);

  const handleClose = useCallback(() => {
    if (popup) {
      try {
        actions.popupMarkAsSeen(popup.id, popup.params);
      } catch (e) {
        // do nothing
      }
    }
    onClose();
  }, [popup, onClose, actions]);

  if (!popup) {
    return null;
  }

  return (
    <ClientPopupBase
      popup={popup}
      shareData={shareData}
      shareLoading={shareLoading}
      shareError={shareError}
      loadShareData={actions.loadShareData}
      onClose={handleClose}
      onBack={onBack}
    />
  );
};

export const ClientPopup: React.FC<Props & PopupViewProps> = ({
  action,
  onBack,
  onClose,
  popupId,
  eventPopupType,
  popupParams,
}) => {
  const currentPopup = useSelector(getCurrentPopup);
  const isLoadedStore = useSelector(isPopupLoaded);
  const [isLoadedState, setIsLoaded] = useState(false);

  const isLoaded = isLoadedStore && isLoadedState;

  const actions = useDispatchActionMap(actionsMap);

  useEffect(() => {
    let outdated = false;

    async function load() {
      setIsLoaded(false);

      try {
        if (popupId) {
          await actions.getPopupById(popupId, popupParams);
        } else if (eventPopupType) {
          await actions.getPopupForEvent(eventPopupType);
        }
      } finally {
        if (!outdated) {
          setIsLoaded(true);
        }
      }
    }

    load();

    return () => {
      outdated = true;
    };
  }, [actions, popupId, popupParams, eventPopupType]);

  useEffect(() => {
    if (!currentPopup && isLoaded) {
      onClose();
    }
  }, [currentPopup, isLoaded, onClose]);

  return isLoaded && currentPopup ? (
    <ClientPopupEssential action={action} popup={currentPopup} onClose={onClose} onBack={onBack} />
  ) : null;
};

export function createClientPopupView({
  popupId,
  eventPopupType,
  popupParams,
  priority,
}: Props): PopupView {
  return {
    priority,
    render: (popupViewProps) => (
      <ClientPopup
        {...popupViewProps}
        popupId={popupId}
        popupParams={popupParams}
        eventPopupType={eventPopupType}
      />
    ),
    withoutOverlay: true,
  };
}

export function createClientPopupViewBasic({
  popup,
  priority,
}: {
  popup: PopupResponse;
  priority?: number;
}): PopupView {
  return {
    priority,
    render: (popupViewProps) => <ClientPopupEssential {...popupViewProps} popup={popup} />,
  };
}

export function useClientPopupView({
  popupId,
  eventPopupType,
  popupParams,
  priority,
}: Props): PopupView {
  return useMemo(
    () => createClientPopupView({popupId, popupParams, priority, eventPopupType}),
    [popupId, popupParams, priority, eventPopupType],
  );
}
