import classnames from 'classnames/bind';
import React, {HTMLAttributes, ReactNode} from 'react';

import styles from './HyperText.scss';

const cn = classnames.bind(styles);

type Props = {
  children: ReactNode;
  asPage?: boolean;
  inverseColor?: boolean;
  'data-testid'?: string;
  'data-test-consent-id'?: string;
} & HTMLAttributes<HTMLDivElement>;

export const HyperText = ({
  children,
  asPage,
  inverseColor,
  'data-testid': testId,
  'data-test-consent-id': consentId,
  ...rest
}: Props): JSX.Element => {
  return (
    <div
      className={cn('hyperText', {asPage, inverseColor})}
      {...rest}
      data-testid={testId}
      data-test-consent-id={consentId}
    >
      {children}
    </div>
  );
};
