import PropTypes from 'prop-types';
import React, {memo, forwardRef} from 'react';
import styles from './index.scss';

const ParcelPageCardComponent = forwardRef(function ParcelPageCardComponent(
  {children, ...restProps},
  ref,
) {
  if (!children) {
    return null;
  }

  return (
    <div ref={ref} className={styles.parcelPageCard} {...restProps}>
      {children}
    </div>
  );
});

ParcelPageCardComponent.propTypes = {
  children: PropTypes.node,
};

ParcelPageCardComponent.defaultProps = {
  children: null,
};

export const ParcelPageCard = memo(ParcelPageCardComponent);

const ParcelPageCardGroupComponent = forwardRef(({children}, ref) => {
  return (
    <div className={styles.parcelPageCardGroup} ref={ref}>
      {children}
    </div>
  );
});

ParcelPageCardGroupComponent.propTypes = {
  children: PropTypes.node.isRequired,
};

export const ParcelPageCardGroup = memo(ParcelPageCardGroupComponent);

export const ParcelPageCardInner = memo(
  forwardRef(({children, ...restProps}, ref) => {
    return (
      <div className={styles.parcelPageCardInner} ref={ref} {...restProps}>
        {children}
      </div>
    );
  }),
);

ParcelPageCardInner.propTypes = {
  children: PropTypes.node.isRequired,
};

export const ParcelPageCardButton = memo(({children, onClick, ...rest}) => {
  return (
    <div
      className={`${styles.parcelPageCard} ${styles.parcelPageCardButton}`}
      onClick={onClick}
      role="button"
      tabIndex="0"
      {...rest}
    >
      {children}
    </div>
  );
});

ParcelPageCardButton.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
};
