import {BannerProps} from 'components/ContentList/BannersList/BannerProps';
import {Locator} from 'components/Locator';
import React from 'react';

import {Slider, SliderItem} from '../../Slider';
import styles from './index.scss';
import {Item} from './Item';

export const ThreeInRow = React.memo(function ThreeInRow({
  bannerAppearance,
  items,
  onScroll,
  source,
}: BannerProps): JSX.Element {
  return (
    <Locator id="BannersListThreeInRow">
      <div className={styles.threeInRow}>
        <Slider loop onScroll={onScroll}>
          <div className={styles.inner}>
            {items.map((item) => (
              <SliderItem key={item.id}>
                {({a11yProps, ref, selected}): React.ReactElement => (
                  <div ref={ref} className={styles.cell} {...a11yProps}>
                    <Item
                      bannerAppearance={bannerAppearance}
                      item={item}
                      selected={selected}
                      source={source}
                    />
                  </div>
                )}
              </SliderItem>
            ))}
          </div>
        </Slider>
      </div>
    </Locator>
  );
});
