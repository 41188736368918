import {
  useProductLiteAdultPopup,
  useProductLiteShouldShowSensitive,
} from 'components/ProductLite/Revision/hooks';
import {type Mark, createLocator} from 'create-locator';
import {useDeviceVar} from 'hooks/useDeviceVars';
import {usePatchedProductLite} from 'hooks/usePatchedProductLite';
import React from 'react';
import {isFallbackFeatureEnabled} from 'store/modules/preferences';
import {ProductLite as ProductLiteType} from 'types/ProductLite';
import {VwFit} from 'types/VwFit';

import {type ProductLiteLocator, ProductLite as ProductLiteRevision} from './Revision';

export type {ProductLiteLocator};

export type Props = {
  bot?: boolean;
  className?: string;
  compact?: boolean;
  focusable?: boolean;
  hideRating?: boolean;
  onFavorite?: (product: ProductLiteType) => void;
  onUnfavorite?: (product: ProductLiteType) => void;
  product: ProductLiteType;
  pxFit?: number;
  sizes?: string;
  source?: string;
  vwFit?: VwFit;
  width?: string;
} & Partial<Mark<ProductLiteLocator>>;

export const ProductLite = React.memo(function ProductLite(props: Props): React.ReactElement {
  const {
    bot = false,
    className,
    compact = false,
    focusable = true,
    hideRating = false,
    onFavorite,
    onUnfavorite,
    pxFit = 220,
    sizes,
    source,
    vwFit = 25,
    width,
  } = props;
  const locator = createLocator(props);
  const product = usePatchedProductLite(props.product);
  const productInNewTab = useDeviceVar('productInNewTab');
  const shouldShowSensitiveOverlay = useProductLiteShouldShowSensitive(product);
  const adultPopup = useProductLiteAdultPopup(product);

  return (
    <ProductLiteRevision
      className={className}
      compact={compact}
      focusable={focusable}
      hideRating={hideRating}
      isSensitive={shouldShowSensitiveOverlay}
      onAdultPopupOpen={adultPopup?.open}
      onFavorite={onFavorite}
      onUnfavorite={onUnfavorite}
      product={product}
      productInNewTab={isFallbackFeatureEnabled(productInNewTab)}
      pxFit={bot ? 100 : pxFit}
      showAdult={Boolean(adultPopup)}
      sizes={sizes}
      source={source}
      vwFit={vwFit}
      width={width}
      {...locator({productId: product.id})}
    />
  );
});

// eslint-disable-next-line import/no-default-export
export default ProductLite;
