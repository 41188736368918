import Button from 'components/Button';
import React from 'react';
import {StyledButton} from 'types/StyledButton';
import {rootLocator} from 'utils/rootLocator';

import styles from './index.scss';

const locator = rootLocator.clientPopup.shareButton;

type Props = {
  button: StyledButton;
  onClick: () => void;
};

export const ShareButton = React.memo(function ShareButton({
  button,
  onClick,
}: Props): React.ReactElement {
  return (
    <Button
      className={styles.button}
      customColors={{
        text: button.textColor,
        background: button.backgroundColor,
      }}
      onClick={onClick}
      block
      {...locator()}
    >
      {button.text}
    </Button>
  );
});
