import classnames from 'classnames';
import React, {useEffect, useMemo, useState} from 'react';

import styles from './index.scss';
import splashConfig from './splashes.export.scss';

export enum State {
  EMPTY = 'empty',
  FILLED = 'filled',
}

type Props = {
  width?: number | string;
  height?: number | string;
  state?: State;
  noInitialAnimation?: boolean;
};

const SPLASHES_AMOUNT = parseInt(splashConfig.splashesAmount || '', 10);

export const AnimatedHeart = ({
  width = '100%',
  height = '100%',
  state,
  noInitialAnimation = true,
}: Props): JSX.Element => {
  const splashesRange = Array(SPLASHES_AMOUNT).fill(null);

  const [animated, setAnimated] = useState(!noInitialAnimation);
  const [initialState] = useState(state);

  useEffect(() => {
    if (state !== initialState) {
      // it will always be animated since the first state change
      setAnimated(true);
    }
  }, [state, initialState]);

  const stateClassNames = useMemo(() => {
    const classNames = [animated && styles.animated];
    switch (state) {
      case State.EMPTY: {
        classNames.push(styles.empty);
        break;
      }
      case State.FILLED: {
        classNames.push(styles.filled);
        break;
      }
      default: {
        classNames.push(styles.infinite);
        break;
      }
    }

    return classNames;
  }, [state, animated]);

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classnames(styles.root, stateClassNames)}
    >
      <mask
        id="animatedHeartCircleMask"
        style={{maskType: 'alpha'}}
        maskUnits="userSpaceOnUse"
        x="-5"
        y="-5"
        width="34"
        height="34"
      >
        <circle cx="12" cy="12" r="12" fill="white" className={styles.maskCircle} />
      </mask>
      <g mask="url(#animatedHeartCircleMask)">
        <g className={styles.heartWrapper} x="12" y="12">
          <path
            className={styles.heart}
            d="M12.5383 19.7993C12.2329 20.0676 11.7679 20.0668 11.4635 19.7974L10.84 19.2455C6.72 15.6133 4 13.2178 4 10.2778C4 7.88222 5.936 6 8.4 6C9.792 6 11.128 6.63 12 7.62555C12.872 6.63 14.208 6 15.6 6C18.064 6 20 7.88222 20 10.2778C20 13.2178 17.28 15.6133 13.16 19.2533L12.5383 19.7993Z"
            fill="currentColor"
          />
        </g>
        <circle cx="12" cy="12" r="6.6" className={styles.circle} />
        <g className={styles.splashesWrapper}>
          {splashesRange.map((_, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <g className={styles.splash} key={`splash-${index}`}>
              <rect
                x="11.5"
                y="10"
                height="3"
                width="1"
                fill="currentColor"
                rx="1"
                ry="0.5"
                className={styles.splashRect}
              />
            </g>
          ))}
        </g>
      </g>
    </svg>
  );
};
