import {MediaBundle, MediaType} from '../types';
import {isMediaImageBundle} from './isMediaImageBundle';
import {isMediaVideoBundle} from './isMediaVideoBundle';

export function getMediaTypeByMediaBundle(mediaBundle: MediaBundle): MediaType {
  switch (true) {
    case isMediaImageBundle(mediaBundle):
      return MediaType.IMAGE;

    case isMediaVideoBundle(mediaBundle):
      return MediaType.VIDEO;

    default:
      throw new Error('Unknown media bundle type');
  }
}
