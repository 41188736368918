import classnames from 'classnames';
import React from 'react';

import styles from './index.scss';

export function SplashMessageActions({
  children,
  marginBottom,
  marginTop,
}: {
  children: React.ReactNode;
  marginBottom?: boolean;
  marginTop?: boolean;
}): JSX.Element {
  return (
    <div
      className={classnames(styles.actions, {
        [styles.marginBottom!]: marginBottom,
        [styles.marginTop!]: marginTop,
      })}
    >
      {children}
    </div>
  );
}
