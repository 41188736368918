import classnames from 'classnames/bind';
import CloseIcon from 'components/BottomSheet/closeIcon.jsx.svg';
import Arrow from 'components/icons/backArrow.jsx.svg';
import {Preloader} from 'components/Preloader';
import {useAnalytics} from 'hooks/useAnalytics';
import React, {useEffect, useMemo, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {CategoryFrom, getCategorySourceByPageSource} from 'types/analytics/Category';
import {PageSource} from 'types/PageSource';
import {getParamsData} from 'utils/search/pattern';

import {CatalogLink} from './CatalogLink';
import {getCategoriesForAnalytics} from './getCategoriesForAnalytics';
import styles from './index.scss';
import {TopLevelCategory} from './TopLevelCategory';
import {BuildUrlFunc, Params, SubCategory} from './types';

type MultilevelCatalogProps = {
  buildUrl: BuildUrlFunc;
  loading: boolean;
  onClose?: () => void;
  onCloseBeforeNavigate?: () => void;
  params: Params;
  subCategory?: SubCategory;
  mobileSheetView: boolean;
  source?: PageSource;
  from: CategoryFrom;
};

const cn = classnames.bind(styles);

export const MultilevelCatalog = React.memo(function MultilevelCatalog({
  buildUrl,
  loading,
  onClose,
  onCloseBeforeNavigate,
  params,
  subCategory,
  mobileSheetView = false,
  source,
  from,
}: MultilevelCatalogProps) {
  const paramsData = useMemo(() => getParamsData(params), [params]);
  const [showLoader, setShowLoader] = useState(loading);
  const analytics = useAnalytics();
  const isTopLevelView = Boolean(subCategory && subCategory.length > 1);

  useEffect(() => {
    if (!loading) {
      setShowLoader(false);

      if (!subCategory) {
        return;
      }

      const categories = getCategoriesForAnalytics(subCategory);

      if (isTopLevelView) {
        analytics.sendEvent({
          type: 'categoriesPreview',
          payload: {
            from,
            categories,
            source: getCategorySourceByPageSource(source),
          },
        });
      }
    }
  }, [loading, isTopLevelView, subCategory, analytics, source, from]);

  if (showLoader) {
    return (
      <div className={styles.preloader}>
        <Preloader />
      </div>
    );
  }

  if (!subCategory) {
    return null;
  }

  return (
    <div className={cn({mobileSheetView})}>
      {mobileSheetView ? (
        <div className={styles.closeContainer}>
          <CloseIcon className={styles.close} onClick={onClose} />
        </div>
      ) : null}
      {isTopLevelView && paramsData.categoryId ? (
        <CatalogLink from={from} buildUrl={buildUrl} isParent loading={loading} params={paramsData}>
          <Arrow className={styles.back} />
          <FormattedMessage
            description="Ссылка «Ко всем категориям» в многоуровневом каталоге"
            defaultMessage="All categories"
          />
        </CatalogLink>
      ) : null}
      {subCategory.map((topLevelCategory) => (
        <TopLevelCategory
          buildUrl={buildUrl}
          isTopLevelView={isTopLevelView}
          key={`top-level-category-${topLevelCategory.id}`}
          loading={loading}
          onClose={onClose}
          onCloseBeforeNavigate={onCloseBeforeNavigate}
          params={paramsData}
          topLevelCategory={topLevelCategory}
          mobileSheetView={mobileSheetView}
          source={source}
          from={from}
        />
      ))}
    </div>
  );
});
