import classnames from 'classnames/bind';
import {AuthViewType} from 'components/Auth/Views/Type';
import {Helmet} from 'components/Helmet/Helmet';
import {ContentTheme, ContentThemeContext} from 'providers/ContentThemeContext';
import {UserAgentContext} from 'providers/UserAgentContext';
import AnalyticsShape from 'shapes/Analytics';
import {CategoryPromoLinksShape} from 'shapes/CategoryPromoLink';
import CategoryViewShape from 'shapes/CategoryView';
import {buildHelmetProps} from 'helpers/pageMetadata';
import {DeviceVarsShape} from 'shapes/Devicevars';
import ErrorShape from 'shapes/Error';
import {LinksBlock} from 'components/PageMetadata/LinksBlock';
import {TextBlock} from 'components/PageMetadata/TextBlock';
import {Footer} from 'components/Footer';
import {Footer as NewFooter} from 'components/NewFooter';
import {Preloader} from 'components/Preloader';
import {NavBar} from 'components/NavBar';
import Legality from 'components/Legality';
import {Language} from 'components/Language';
import LegalityShape from 'shapes/Legality';
import LocationShape from 'shapes/Location';
import {NotFound} from 'components/NotFound';
import {PageError} from 'components/PageError';
import {PageLoading} from 'components/PageLoading';
import {MultilevelCatalog} from 'components/MultilevelCatalog';
import {Skipper} from 'components/Skipper';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Currency} from 'components/Currency';
import {Locator} from 'components/Locator';
import TrackingShape from 'shapes/Tracking';
import {UserShape} from 'shapes/User';
import ForcedRegion from 'components/ForcedRegion';
import {Reducer} from 'components/Layout/Reducer';
import {bindActionCreators} from 'redux';
import {loadPageMetadata} from 'store/modules/pageMetadata';
import {getPageMetadata, isPageMetadataLoaded} from 'store/modules/pageMetadata/selectors';
import {createNetworkError} from 'utils/error';
import {createUrl, getQueryData} from 'utils/url';
import {shallowDiffers} from 'utils/shallowDiffers';
import Search from 'components/Header/Search';
import {eventParamsList} from 'helpers/eventParams';
import {isLoginRequired, setStatusCode} from 'helpers/router';
import {loadCartCount} from 'store/modules/cartCount';
import {getCartCount, isCartCountLoaded} from 'store/modules/cartCount/selectors';
import {
  getCategoryView,
  isCategoryLoading,
  isCategoryViewLoaded,
  loadCategoriesHierarchy,
  ROOT_ID,
} from 'store/modules/categoryViews';
import {
  getCloseMemo,
  loadCloseMemo,
  setMemoClosed,
  isCloseMemoLoaded,
} from 'store/modules/closeMemo';
import {getCurrencies, getCurrenciesError, isCurrenciesLoading} from 'store/modules/currencies';
import {
  loadLegalityConsent,
  getLegalityConsentRequires,
  isLegalityConsentLoaded,
  isLegalityConsentLoading,
} from 'store/modules/legality';
import {loadNotificationsFilters} from 'store/modules/notifications/filters';
import {saveNotificationsPreferences} from 'store/modules/notifications/preferences';
import {needUpdateNotificationsUnreadCount} from 'store/modules/notifications/selectors';
import {searchUrl, searchUrlByParams} from 'containers/pages/SearchPage/url';
import {
  getUser,
  loadUser,
  signout,
  signup,
  socialSignin,
  isNonEphemeral,
  isSignining,
  isUnauthorized,
  isUserLoaded,
  smartLockSignin,
} from 'store/modules/auth';
import {getPointsAccount, loadPointsAccount} from 'store/modules/points';
import {loadCouponCards, syncViewedCouponCards} from 'store/modules/couponCards';
import {getCouponCards, isCouponCardsLoaded} from 'store/modules/couponCards/selectors';
import {
  getDeviceId,
  getDeviceVar,
  getDeviceVars,
  hasLoadingLanguages,
  isBot,
  saveCurrency,
  saveLanguage,
  saveForcedRegion,
} from 'store/modules/preferences';
import {
  setCouponViewTimeForUser,
  getLastCouponViewTimeForCurrentUser,
} from 'store/modules/couponViews';
import {getSubCategory} from 'store/modules/search';
import {FormattedMessage} from 'react-intl';
import {LANGUAGE_REGEXP} from 'utils/language';
import config, {Scope} from 'config';
import {nullFunction} from 'utils/function';
import {
  getEmptySearchData,
  getSearchData,
  assignSearchDataQuery,
  assignSearchDataFiltersByApplied,
} from 'utils/search';
import PointsAccountShape from 'shapes/PointsAccount';
import {SubCategoryShape} from 'shapes/SubCategory';
import {loadCookiesSettings} from 'store/modules/cookiesSettings';
import Catalog from 'components/ProductList/Catalog';
import {MobileBottomSheet} from 'components/MobileBottomSheet';
import {BottomSheetHeader} from 'components/BottomSheet';
import {CATEGORY_PARAM} from 'components/ProductList/consts';
import {getPageHash, getPageHashUrl} from 'store/modules/pageHash/selectors';
import {loadPageHash} from 'store/modules/pageHash';
import {loadCategoryPromoLinks} from 'store/modules/categoryPromoLinks';
import {
  getCategoryPromoLinks,
  needLoadCategoryPromoLinks,
} from 'store/modules/categoryPromoLinks/selectors';
import {OldBrowser} from 'components/OldBrowser';
import {CategoryFrom} from 'types/analytics/Category';
import {SigninContext} from 'providers/SigninContext';
import {closeCookiesNames} from 'helpers/ApiClient/Device/cookieValues';
import {loadable} from 'utils/nextTickLoadable';

import {getCategoryPromoLinkId} from 'utils/categoryPromoLink';
import {Indicator} from 'components/NotificationsCenter/Indicator';
import {BurgerViewType} from 'components/Burger/BurgerViewType';
import {
  memoizeByResult,
  memoizeLastShallowEqual,
  memoizeLastShallowEqualEachArg,
} from 'utils/memoize';
import isEqual from 'lodash/isEqual';
import {SignupPromo} from 'components/SignupPromo/SignupPromo';
import {isObject} from 'utils/guards';
import {PagePreferencesContext} from 'providers/PagePreferencesContext';
import {LocationPopup} from 'containers/LocationPopup';
import {LazyHydrate} from 'components/LazyHydrate/LazyHydrate';
import {nextTick} from 'utils/nextTick';
import {DesktopRenderer} from 'components/MediaRenderer';
import {ForcedEndpointsConfig} from 'components/ForcedEndpointsConfig';
import {VersionMismatchController} from 'components/VersionMismatchController';
import {ForcedRtl} from 'components/ForcedRtl';
import {initSearchSession} from 'utils/searchSession';
import {UserAndDeviceInfo} from 'components/UserAndDeviceInfo';
import {openProductCollectionToast} from 'hooks/useOpenProductCollectionToast';
import {
  addProductToCollection,
  removeProductFromCollection,
} from 'store/modules/productCollections';
import {createDetachedPromise} from 'utils/promise';
import {Snow} from 'components/Snow';
import {PageSource} from 'types/PageSource';
import {ConfirmUnfavoritePopup} from 'components/ProductCollections/ConfirmUnfavoritePopup';
import {getCommonProductAddOrRemoveFlow, getCommonProductId} from 'types/CommonProduct';
import {JmtMigrationPopupController} from 'containers/WelcomePopups/JmtMigrationPopupController';
import {getJoomOrigin, getJmtOrigin} from 'helpers/ApiClient/JmtMigration/utils';
import {ForcedRenderingType} from 'components/ForcedRenderingType';
import {COUPON_BANNER_TYPE} from 'components/Header/CouponOld/consts';
import {getUrl} from 'routes';
import {isOfferShown} from 'helpers/productLite';
import {HeaderCoupon} from 'components/Header/CouponRedesign';
import {getRewardWheelData} from 'store/modules/rewardWheel/wheel/selectors';
import Header from 'components/Header/Header';
import {isPersonalDataExistsLoaded} from 'store/modules/personalData/exists/selectors';
import {loadPersonalDataExists} from 'store/modules/personalData/exists';
import {PASSPORT_FORM_ORIGIN} from 'components/PassportInfoPopup/constants';
import {ScopeConfig} from 'helpers/ApiClient/Scope/ScopeConfig';
import {ForcedDomainScope} from 'components/ForcedDomainScope';
import styles from './PageBase.scss';

const cn = classnames.bind(styles);

const CATEGORIES_BOTTOM_SHEET = 'categories';
const SMARTLOCK_STORAGE_ID = 'smartLockShown';

const Auth = loadable(() => import('containers/Auth/Auth'), {
  resolveComponent: (module) => module.default,
  ssr: false,
});
const RewardWheelConnector = loadable(() => import('connectors/RewardWheel'), {
  resolveComponent: (module) => module.RewardWheel,
  ssr: false,
});
const MiniCart = loadable(() => import('connectors/MiniCart'), {
  resolveComponent: (module) => module.MiniCart,
  ssr: false,
});
const IdlerConnector = loadable(() => import('connectors/Idler'), {
  resolveComponent: (module) => module.Idler,
  ssr: false,
});
const NotificationsCenter = loadable(() => import('connectors/NotificationsCenter'), {
  resolveComponent: (module) => module.NotificationsCenter,
  ssr: false,
  fallback: <Indicator />,
});
const Burger = loadable(() => import('components/Burger'), {
  resolveComponent: (module) => module.Burger,
  ssr: false,
});
const CookiesSettingsBanner = loadable(() => import('containers/CookiesSettings/Banner'), {
  resolveComponent: (module) => module.CookiesSettingsBanner,
  ssr: false,
});
const CoolbeRegion = loadable(() => import('components/CoolbeRegion'), {
  resolveComponent: (module) => module.Region,
});

// lazy compile big components in next tick
nextTick(() => require('components/Header/Profile'));

export class PageBase extends Component {
  static propTypes = {
    cartCount: PropTypes.number,
    catalogLoading: PropTypes.bool.isRequired,
    categoryError: ErrorShape,
    categoryLoading: PropTypes.bool,
    categoryView: CategoryViewShape,
    categoryPromoLinks: CategoryPromoLinksShape,
    client: PropTypes.objectOf(PropTypes.any).isRequired,
    closeEmailPromo: PropTypes.func.isRequired,
    couponCards: PropTypes.array.isRequired,
    currency: PropTypes.string.isRequired,
    detectedLanguage: PropTypes.string.isRequired,
    deviceId: PropTypes.string.isRequired,
    deviceVars: DeviceVarsShape.isRequired,
    disableCookiesUntilUserSetsPermission: PropTypes.bool.isRequired,
    bot: PropTypes.bool,
    itemsLoading: PropTypes.bool,
    lastCouponViewTime: PropTypes.number,
    legalityConsentDocument: LegalityShape,
    loadCouponCards: PropTypes.func.isRequired,
    loadingLanguages: PropTypes.bool.isRequired,
    loadLegalityConsent: PropTypes.func.isRequired,
    loadCategoryPromoLinks: PropTypes.func.isRequired,
    loadCookiesSettings: PropTypes.func.isRequired,
    locale: PropTypes.string.isRequired,
    location: LocationShape.isRequired,
    metadata: PropTypes.shape({
      alternateName: PropTypes.object,
      attrs: PropTypes.object,
      bottomTextBlock: PropTypes.object,
      bottomLinksBlock: PropTypes.object,
      canonical: PropTypes.string,
      description: PropTypes.string,
      h1: PropTypes.string,
      relAlternate: PropTypes.string,
      title: PropTypes.string,
    }),
    multilevelCatalog: PropTypes.bool,
    origin: PropTypes.string.isRequired,
    params: PropTypes.objectOf(PropTypes.string).isRequired,
    pageHash: PropTypes.string.isRequired,
    region: PropTypes.string.isRequired,
    reload: PropTypes.func.isRequired,
    richMeta: PropTypes.bool.isRequired,
    route: PropTypes.objectOf(PropTypes.any).isRequired,
    scope: PropTypes.instanceOf(ScopeConfig).isRequired,
    staticContext: PropTypes.objectOf(PropTypes.any),
    rootCategoryView: CategoryViewShape,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
      replace: PropTypes.func.isRequired,
    }).isRequired,
    saveNotificationsPreferences: PropTypes.func.isRequired,
    selectedCategoryId: PropTypes.string,
    setCouponViewTimeForUser: PropTypes.func.isRequired,
    signin: PropTypes.func,
    signup: PropTypes.func.isRequired,
    signining: PropTypes.bool.isRequired,
    signout: PropTypes.func.isRequired,
    smartLockSignin: PropTypes.func.isRequired,
    socialSignin: PropTypes.func.isRequired,
    subCategory: SubCategoryShape,
    tracking: TrackingShape.isRequired,
    user: UserShape,
    userAdulthood: PropTypes.bool,
    pointsAccount: PointsAccountShape,
    // App provides contexts as props for pages
    analytics: AnalyticsShape.isRequired,
    intl: PropTypes.objectOf(PropTypes.any).isRequired,
    store: PropTypes.objectOf(PropTypes.any).isRequired,
    wheelShown: PropTypes.bool.isRequired,
    maidenCouponAutoShow: PropTypes.bool,
    addProductToCollection: PropTypes.func.isRequired,
    removeProductFromCollection: PropTypes.func.isRequired,
    popupManager: PropTypes.object.isRequired,
    openToast: PropTypes.func.isRequired,
  };

  static defaultProps = {
    cartCount: null,
    categoryError: null,
    categoryLoading: false,
    categoryView: null,
    categoryPromoLinks: undefined,
    bot: false,
    itemsLoading: false,
    lastCouponViewTime: 0,
    legalityConsentDocument: null,
    maidenCouponAutoShow: false,
    metadata: null,
    multilevelCatalog: false,
    pointsAccount: null,
    rootCategoryView: null,
    selectedCategoryId: '',
    signin: null,
    staticContext: undefined,
    subCategory: null,
    user: null,
    userAdulthood: false,
  };

  constructor(props) {
    super(props);

    // eslint-disable-next-line no-param-reassign
    props.route.pageStatus = props.route.status || 200;

    this.handleAuthSuccess = this.handleAuthSuccess.bind(this);
    this.handleCategorySelect = this.handleCategorySelect.bind(this);
    this.handleAuthClose = this.handleAuthClose.bind(this);
    this.handleAuthRetry = this.handleAuthRetry.bind(this);
    this.handleRewardWheelActivate = this.handleRewardWheelActivate.bind(this);
    this.onHeaderSignin = this.onHeaderSignin.bind(this);
    this.onHeaderSignout = this.onHeaderSignout.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.onSelectCategory = this.onSelectCategory.bind(this);
    this.setShowPreloaderOverlay = this.setShowPreloaderOverlay.bind(this);

    this.mounted = false;
    this.mountTime = 0;
    this.lastErrorHref = null;
    // Отключить отображение миникорзины на странице
    this.disableMiniCart = false;
    // Включать визуализацию, которая активируется при PageBase.refresh
    this.enableRefreshVisualisation = false;
    // Поддерживается ли на странице белый бэк
    this.whiteBackgroundSupported = false;
    // Enables rounded footer style (only for mobile product page for now)
    this.mobileRoundedFooter = false;

    this.pageMetadataParams = [];
    this.pageMetadata = null;

    this.mainContentTargetRef = React.createRef();

    this.state = {
      /** Use only for false by default, because can be overwritten by children */
      // isBurgerVisible: false,
      // isSignin: false,
      // signinView: null,
      // showPreloaderOverlay: false,
      // promoLinksHighlight: {},
      // forceAnnounceCoupon: false,
    };
  }

  componentDidMount() {
    this.mounted = true;
    this.mountTime = Date.now();

    Auth.preload();
    Burger.preload();

    this.showInitSmartLockSignin();

    // multilevelCatalog not used at now, but saved for future
    if (!this.props.multilevelCatalog) {
      window.addEventListener('popstate', this.handlePopState);
    }

    initSearchSession(this.props.client, this.props.location);
  }

  componentDidUpdate(prevProps) {
    const {user: prevUser} = prevProps;
    const {user} = this.props;
    if (prevUser && user && user.id !== prevUser.id) {
      // user id was changed
      this.processUserChange();
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (!props.categoryPromoLinks?.length) {
      return null;
    }

    if (state.promoLinksHighlight && Object.keys(state.promoLinksHighlight).length) {
      return null;
    }

    let promoLinksParamsFromCookie = null;

    try {
      promoLinksParamsFromCookie = JSON.parse(
        props.client.cookiesRegistry.categoryPromoLinkOnboarding.restore() || null,
      );
    } catch (e) {
      // Do nothing
    }

    if (promoLinksParamsFromCookie) {
      return {...state, promoLinksHighlight: promoLinksParamsFromCookie};
    }

    return {
      ...state,
      promoLinksHighlight: props.categoryPromoLinks.reduce((acc, link) => {
        acc[getCategoryPromoLinkId(link)] = link.shouldHighlight;
        return acc;
      }, {}),
    };
  }

  componentWillUnmount() {
    this.mounted = false;

    // multilevelCatalog not used at now, but saved for future
    if (!this.props.multilevelCatalog) {
      window.removeEventListener('popstate', this.handlePopState);
    }
  }

  getHiddenHeaderMobileButtons() {
    return [];
  }

  showInitSmartLockSignin() {
    if (
      !this.props.bot &&
      this.props.deviceVars.webSmartLockSigninOnInit &&
      this.props.user.anonymous &&
      this.props.client.storages.local.getItem(SMARTLOCK_STORAGE_ID) !==
        this.props.client.session.getId()
    ) {
      this.signinBySmartLock('appinit');
    }
  }

  onBurgerClick = () => {
    // this.loadCategory(this.props.categoryId);
    this.setState({
      isBurgerVisible: true,
      burgerView: BurgerViewType.DEFAULT,
    });
  };

  onCategoriesOpen = () => {
    const {multilevelCatalog} = this.props;

    this.setState(
      {
        isCategoriesSheetVisible: true,
      },
      () => {
        if (multilevelCatalog) {
          return;
        }

        const {
          location: {pathname, search},
        } = this.props;
        window.history.pushState(
          {
            ...window.history.state,
            bottomSheet: CATEGORIES_BOTTOM_SHEET,
          },
          '',
          `${pathname}${search}`,
        );
      },
    );
  };

  onHeaderSignin() {
    const {webSmartLockSigninOnProfileClick} = this.props.deviceVars;

    if (webSmartLockSigninOnProfileClick) {
      this.signinBySmartLock('profile').then((result) => {
        if (result === false && this.mounted) {
          this.signin('Header');
        }
      });
    } else {
      this.signin('Header');
    }
  }

  onHeaderSignout() {
    const {analytics} = this.props;
    const {signinSource} = this.state;
    analytics.dataLayer({event: 'Header. Sign Out Click'});
    analytics.sendEvent({
      type: 'signoutClick',
      payload: {from: 'profileMenu', source: signinSource},
    });
    this.signout().catch(nullFunction);
  }

  onBurgerSignout = () => {
    const {analytics} = this.props;
    const {signinSource} = this.state;
    analytics.dataLayer({event: 'Burger. Sign Out Click'});
    analytics.sendEvent({
      type: 'signoutClick',
      payload: {from: 'sidebar', source: signinSource},
    });
    this.setState({isBurgerVisible: false});
    this.signout().catch(nullFunction);
  };

  onCurrencyClick = (currency) => {
    this._dispatchAction(saveCurrency(currency));
  };

  onLanguageClick = (lang) => this._dispatchAction(saveLanguage(lang));

  onForcedRegionChange = (forcedRegion) => {
    this.setShowPreloaderOverlay(true);
    this._dispatchAction(saveForcedRegion(forcedRegion)).finally(() =>
      this.setShowPreloaderOverlay(false),
    );
  };

  onBurgerClose = () => {
    this.setState({isBurgerVisible: false});
  };

  onSearch(query, filters) {
    const searchData = assignSearchDataFiltersByApplied(
      assignSearchDataQuery(getEmptySearchData(), query),
      filters,
    );
    this.props.history.push(searchUrl(this.props.scope, this.getLang(), searchData));
  }

  onSelectCategory(categoryId) {
    if (this.state.isBurgerVisible) {
      this.setState({
        isBurgerVisible: false,
      });
    }
  }

  isPageError(value) {
    return isObject(value) && value.error && value.type === 'pageBaseError';
  }

  createPageError(error, options) {
    return {
      type: 'pageBaseError',
      error: error || new Error(),
      defaultStatus: options?.defaultStatus,
      noButton: options?.noButton,
      element: options?.element,
    };
  }

  getAuthPopupText() {
    if (this.props.wheelShown) {
      return (
        <FormattedMessage
          description={`Подзаголовок формы авторизации на странице авторизации в случае
            перехода из колеса кэшбека`}
          defaultMessage="Log in to see your balance"
        />
      );
    }

    return undefined;
  }

  getBuildUrlForBurger() {
    return searchUrlByParams;
  }

  getCategoryViewForBurger() {
    return null;
  }

  getCanonicalLink(lang) {
    return '';
  }

  getContentClassName() {
    return '';
  }

  getReducerClassName() {
    return '';
  }

  getParentClassName() {
    return '';
  }

  getCouponClassName() {
    return '';
  }

  getDeepUrl() {
    return '';
  }

  getLang() {
    if (LANGUAGE_REGEXP.test(this.props.params.lang || '')) {
      return this.props.params.lang;
    }
    return this.props.detectedLanguage || '';
  }

  getPageTitle() {
    return '';
  }

  getPageDescription() {
    return '';
  }

  getPageOGTitle() {
    return this.getPageTitle();
  }

  getPageOGDescription() {
    return this.getPageDescription();
  }

  getLangPath(lang) {
    const {params, location, route} = this.props;
    const {pathname} = location;
    if (params.lang !== this.getLang() || !route) {
      return pathname;
    }
    return getUrl(route.name, {
      ...params,
      lang,
    });
  }

  getLangList() {
    return this.props.client.device.getAvailableLanguages(
      this.props.scope.hasOppositeEntity ? {legalEntity: undefined} : undefined,
    );
  }

  getLangPaths = (location) =>
    this.getLangList().reduce((next, lang) => {
      next[lang] = this.getLangPath(lang);
      return next;
    }, {});

  getLangLinks = (location) => {
    const paths = this.getLangPaths(location);
    return this.getLangList().reduce((next, lang) => {
      const langConfig = config.languages.find(({code}) => code === lang);

      if (langConfig) {
        const {legalEntity} = langConfig;
        let {origin} = this.props;
        if (this.props.scope.hasOppositeEntity) {
          origin =
            // eslint-disable-next-line no-nested-ternary
            legalEntity === 'joom'
              ? getJoomOrigin(this.props.scope, this.props.client.req)
              : legalEntity === 'jmt'
                ? getJmtOrigin(this.props.scope, this.props.client.req)
                : this.props.origin;
        }

        next[lang] = `${origin}${paths[lang]}`;
      }

      return next;
    }, {});
  };

  getPageOGOverride() {
    return undefined;
  }

  getProductId() {
    return null;
  }

  getDefaultOGImage() {
    const {scope, origin} = this.props;

    let imagePath;

    // eslint-disable-next-line default-case
    switch (scope.topScope) {
      case Scope.GLOBAL:
        imagePath = '/ico/og-logo.png';
        break;
      case Scope.GEEK:
        imagePath = '/ico-geek/og-logo.png';
        break;
      case Scope.COOLBE:
      case Scope.CBTREND:
        imagePath = '/ico-coolbe/og-logo.png';
        break;
    }

    if (imagePath) {
      return {
        'og:image': `${origin}${imagePath}`,
        'og:image:width': '200',
        'og:image:height': '200',
      };
    }

    return undefined;
  }

  buildMetadata(location, metadata, lang, locale) {
    const {
      deviceVars: {webAddOpensearch},
    } = this.props;

    const meta = metadata || {};
    const links = this.getLangLinks(location);
    const title = meta.title || this.getPageTitle();
    const canonical = meta.canonical || this.getCanonicalLink(lang) || links[lang];
    const description = meta.description || this.getPageDescription();
    const og = meta.og || {
      'og:description': this.getPageOGDescription(),
      'og:title': this.getPageOGTitle(),
      'og:url': links[lang],
      ...this.getDefaultOGImage(),
      ...this.getPageOGOverride(),
    };
    const dir = this.props.client.device.getDir(lang);

    return {
      ...meta,
      canonical,
      description,
      relAlternate: meta.relAlternate || links,
      title,
      og,
      locale: locale || '',
      lang: lang || '',
      dir,
      appTargetUrl: this.getDeepUrl(),
      ...(webAddOpensearch ? {search: '/opensearch/opensearch.xml'} : {}),
    };
  }

  getMetadata() {
    const {location, metadata, locale} = this.props;
    const params = [location, metadata, this.getLang(), locale];

    if (shallowDiffers(this.pageMetadataParams, params)) {
      this.pageMetadata = this.buildMetadata(...params);
    }
    this.pageMetadataParams = params;

    return this.pageMetadata;
  }

  getSeoCutOption() {
    return undefined;
  }

  getDefaultSearchValue() {
    return '';
  }

  getPageSource() {
    return PageSource.DEFAULT;
  }

  getPageHiddenSearchDisabled() {
    return false;
  }

  setPageStatus(status) {
    setStatusCode(this.props.staticContext, status);
    this.sendAnalyticsPageErrorIfNeeded(status);
  }

  setSigninProcess(isSigninProcess) {
    // for holding some props before signin
  }

  setShowPreloaderOverlay(showPreloaderOverlay) {
    this.setState({
      showPreloaderOverlay,
    });
  }

  signinPromise = (where, signinView = null, signinContext = null) =>
    new Promise((resolve, reject) =>
      this.signin(where, signinView, signinContext, resolve, reject),
    );

  signin = (
    whereOrOptions,
    signinView = null,
    signinContext = null,
    signinCallbackSuccess,
    signinCallbackClose,
  ) => {
    let options = whereOrOptions;

    if (!isObject(options)) {
      options = {
        context: signinContext,
        onClose: signinCallbackClose,
        onSuccess: signinCallbackSuccess,
        view: signinView,
        where: whereOrOptions,
      };
    }

    if (!options.context?.source) {
      options.context = {
        ...options.context,
        source: this.getPageSource(),
      };
    }

    this.props.analytics.dataLayer({
      event: 'Common. Sign In',
      where: options.where,
    });
    this.state.signinCallbackClose?.();
    this.setState({
      isSignin: true,
      signinView: options.view,
      signinType: options.type,
      signinContext: options.context,
      signinSource: options.where,
      signinCallbackSuccess: options.onSuccess,
      signinCallbackClose: options.onClose,
    });
    this.setSigninProcess(true);
  };

  signinBySmartLock = (source) => {
    const {analytics} = this.props;

    this.props.client.storages.local.setItem(
      SMARTLOCK_STORAGE_ID,
      this.props.client.session.getId(),
    );
    return this.props
      .smartLockSignin(source)
      .then((result) => {
        if (this.mounted && result === true) {
          analytics.sendEvent({
            type: 'smartlockLogin',
            payload: {
              result: 'success',
              source,
            },
          });

          this.handleAuthSuccess();
        }

        return result;
      })
      .catch(() => {
        analytics.sendEvent({
          type: 'smartlockLogin',
          payload: {
            result: 'error',
            source,
          },
        });

        if (this.mounted) {
          this.signin('SmartLock', null, {source});
        }
      });
  };

  handleNotificationsSignin = () => this.signin('NotificationsCenter');

  signout = () => {
    const {route} = this.props;

    this.setState({isSignout: true});
    return this.props
      .signout()
      .then(() => this.props.closeEmailPromo())
      .then(() => this.props.loadCouponCards())
      .then(() => this.props.loadLegalityConsent())
      .then(() =>
        isLoginRequired(route)
          ? window.location.assign(`/${this.getLang()}`)
          : window.location.reload(),
      )
      .finally(() => (this.mounted ? this.setState({isSignout: false}) : null));
  };

  isBackgroundWhite() {
    return Boolean(this.whiteBackgroundSupported);
  }

  processUserChange() {
    const isAnotherTabLoggedOut = this.props.user.anonymous && !this.state.isSignout;
    const isAnotherTabLoggedIn = !this.props.user.anonymous;

    if (isLoginRequired(this.props.route)) {
      if (isAnotherTabLoggedOut) {
        this.setState({pageBaseError: createNetworkError('auth.unauthorized')});
      }
      if (isAnotherTabLoggedIn) {
        this.setState({pageBaseError: createNetworkError('auth.user_changed')});
      }
    }

    if (isAnotherTabLoggedOut || isAnotherTabLoggedIn) {
      this.refresh();
    }
  }

  sendAnalyticsPageErrorIfNeeded(status) {
    if (__CLIENT__) {
      const {location} = this.props;
      if (status >= 400 && location.pathname !== this.lastErrorHref) {
        this.lastErrorHref = location.pathname;
        const {analytics} = this.props;

        analytics.dataLayer({
          event: 'Common. Page Error',
          errorCode: status,
        });
      }
    }
  }

  refresh(waitForAllPromises) {
    const {reload} = this.props;

    if (!this.mounted) {
      return Promise.reject(new Error('Page already unmounted'));
    }

    this.setState({refreshing: true});
    if (reload) {
      return reload(waitForAllPromises).then(() =>
        this.mounted ? this.setState({refreshing: false}) : null,
      );
    }

    return Promise.reject(new Error('reload is not defined'));
  }

  handleAuthSuccess({skipSuccessCallback = false} = {}) {
    const {analytics, wheelShown} = this.props;
    const {rewardWheelAuthCallback} = this.state;

    if (!this.mounted) {
      return Promise.reject(new Error('Page already unmounted'));
    }
    analytics.dataLayer({
      event: 'Common. Auth Complete',
    });

    const {signinCallbackSuccess} = this.state;

    this.setState({
      isSignin: false,
      signinCallbackSuccess: undefined,
      signinCallbackClose: undefined,
    });
    this.setSigninProcess(false);

    this.props.loadLegalityConsent();

    setTimeout(() => {
      if (this.props.maidenCouponAutoShow) {
        this.setState({forceAnnounceCoupon: true}, () => {
          this.props.loadCouponCards();
        });
      } else {
        this.props.loadCouponCards();
      }
    }, 4 * 1000); // 4 seconds

    this.props.loadCategoryPromoLinks();
    this.props.closeEmailPromo();
    this.props.loadCookiesSettings();
    return this._dispatchAction(loadCartCount())
      .then(() => {
        if (wheelShown && rewardWheelAuthCallback) {
          rewardWheelAuthCallback();
        }
      })
      .finally(async () => {
        if (!skipSuccessCallback) {
          await signinCallbackSuccess?.();
        }
      });
  }

  handleAuthRetry() {
    this.handleAuthClose();
    this.signinBySmartLock();
  }

  handleAuthClose() {
    this.state.signinCallbackClose?.();
    this.setState({
      isSignin: false,
      signinCallbackSuccess: undefined,
      signinCallbackClose: undefined,
    });
    this.setSigninProcess(false);
  }

  handleCategorySelect(evt, category) {
    this.setState({isBurgerVisible: false});
  }

  handlePopState = () => {
    const {state} = window.history;
    this.setState({
      isCategoriesSheetVisible: state && state.bottomSheet === CATEGORIES_BOTTOM_SHEET,
    });
  };

  handleRewardWheelSignin = (cb) => {
    this.signin('RewardWheel', AuthViewType.SIGNIN);
    this.setState({rewardWheelAuthCallback: cb});
  };

  async handleRewardWheelActivate() {
    await this.props.loadCouponCards();
  }

  handleProductFavoriteSignin = (cb) => {
    const {promise, resolve} = createDetachedPromise();
    const handleSugninClose = resolve;
    const handleSigninSuccess = async () => {
      await cb();
      resolve();
    };

    this.setState(
      {
        authPopupText: (
          <FormattedMessage
            defaultMessage="Please log in to add to Favourites"
            description="Сообщение в форме авторизации при добавлении продукта в избранное"
          />
        ),
      },
      () => this.signin('Favorite', null, null, handleSigninSuccess, handleSugninClose),
    );

    return promise;
  };

  handleSearchSideButtonClick = () => {
    this.setState({showHiddenSearch: true});
  };

  handleSearchSuggestClose = () => {
    if (this.isHiddenSearchEnabled()) {
      this.setState({showHiddenSearch: false});
    }
  };

  isHiddenSearchEnabled() {
    return Boolean(this.props.deviceVars.webHideSearchbar && !this.getPageHiddenSearchDisabled());
  }

  isSearchHidden() {
    return Boolean(this.isHiddenSearchEnabled() && !this.state.showHiddenSearch);
  }

  sendChangeProductInCollectionEvent(product, favorite) {
    const {analytics} = this.props;
    const {eventParams, id} = product;

    analytics.sendEvent({
      type: 'productLike',
      payload: {
        favorite,
        offerShown: isOfferShown(product),
        productId: id,
        shownPreOffer: 'none',
        sinceOpenMs: Date.now() - this.mountTime,
        source: this.getPageSource(),
      },
      params: eventParamsList(eventParams),
    });
  }

  changeProductInCollection = async (product, favorite) => {
    const handleConfirm = async () => {
      const {productCollectionsConfig} = this.props.deviceVars ?? {};
      const showAddToCollectionOnRevision = Boolean(
        productCollectionsConfig?.showAddToCollectionOnRevision,
      );
      const pageSource = this.getPageSource();
      const action = favorite
        ? this.props.addProductToCollection
        : this.props.removeProductFromCollection;
      const {productCollections} = await action({
        itemKey: {productId: getCommonProductId(product)},
      });

      this.sendChangeProductInCollectionEvent(product, favorite);

      if (
        !favorite ||
        (showAddToCollectionOnRevision &&
          [PageSource.FAVORITES, PageSource.SELF_PRODUCT_COLLECTIONS].includes(pageSource))
      ) {
        openProductCollectionToast({
          popupManager: this.props.popupManager,
          analytics: this.props.analytics,
          openToast: this.props.openToast,
          intl: this.props.intl,
          changes: {
            type: 'productInCollection',
            product,
            productCollections,
            isUserAdult: this.props.userAdulthood,
            isRemoving: !favorite,
          },
        });
      }
    };

    if (!favorite && getCommonProductAddOrRemoveFlow(product) === 'removeFromMultiple') {
      this.props.popupManager.open({
        render: ({onBack, onClose}) => (
          <ConfirmUnfavoritePopup
            onBack={onBack}
            onClose={onClose}
            onConfirm={handleConfirm}
            product={product}
          />
        ),
      });
    } else {
      await handleConfirm();
    }
  };

  getProductChangeInCollectionHandler = (favorite) => {
    const {showFavoriteProducts} = this.props.deviceVars ?? {};
    const showFavoriteProductsEnabled = Boolean(showFavoriteProducts);

    if (!showFavoriteProductsEnabled) {
      return undefined;
    }

    return async (product) => {
      const {user, analytics} = this.props;

      if (favorite) {
        analytics.dataLayer({
          event: 'Product. Add To Favorite',
          productId: getCommonProductId(product),
        });
      }

      if (!user || user.anonymous) {
        await this.handleProductFavoriteSignin(() =>
          this.changeProductInCollection(product, favorite),
        );
      } else {
        await this.changeProductInCollection(product, favorite);
      }
    };
  };

  handleProductAddToCollection = this.getProductChangeInCollectionHandler(true);

  handleProductRemoveFromCollection = this.getProductChangeInCollectionHandler(false);

  _dispatchAction(action) {
    return this.props.store.dispatch(action);
  }

  hasOwnCategories() {
    return false;
  }

  hasLegalityPopup() {
    return true;
  }

  hasMobilePermanentlyStickyHeader() {
    return false;
  }

  hasMobileStickyHeader() {
    return false;
  }

  hasDesktopStickyHeader() {
    return false;
  }

  hasHeaderCoupon() {
    return false;
  }

  hasSnow() {
    return false;
  }

  hasMobileHeaderBackButton() {
    return false;
  }

  onMobileHeaderBackButtonClick = () => {
    return undefined;
  };

  renderBreadcrumbs() {
    return null;
  }

  renderContent() {
    return null;
  }

  getSearchResultHelp() {
    return null;
  }

  renderPageError(pageError) {
    return pageError.element !== undefined ? (
      pageError.element
    ) : (
      <PageError noButton={pageError.noButton} error={pageError.error} />
    );
  }

  renderNotFound() {
    return <NotFound />;
  }

  getMemoMiniCartRenderer = memoizeLastShallowEqualEachArg(({cartCount}) => {
    return (children) => {
      return (
        // eslint-disable-next-line react/no-this-in-sfc
        <MiniCart fallback={children} disabled={this.disableMiniCart || !cartCount}>
          {children}
        </MiniCart>
      );
    };
  });

  getMiniCartRenderer = () => {
    const {cartCount} = this.props;

    return this.getMemoMiniCartRenderer({cartCount});
  };

  NotificationsCenter = (<NotificationsCenter signin={this.handleNotificationsSignin} />);

  getMemoProfileRenderer = memoizeLastShallowEqualEachArg(
    ({user, pointsAccount, signining, lang}) => {
      // eslint-disable-next-line react/prop-types
      return ({shouldShowOrders}) => {
        const Profile = require('components/Header/Profile').default;

        return (
          <Profile
            user={user}
            lang={lang}
            shouldShowOrders={shouldShowOrders}
            signingIn={signining}
            // eslint-disable-next-line react/no-this-in-sfc
            onSignin={this.onHeaderSignin}
            // eslint-disable-next-line react/no-this-in-sfc
            onSignout={this.onHeaderSignout}
            pointsAccount={pointsAccount}
          />
        );
      };
    },
  );

  getProfileRenderer() {
    const {user, pointsAccount, signining} = this.props;
    const lang = this.getLang();
    return this.getMemoProfileRenderer({
      user,
      pointsAccount,
      signining,
      lang,
    });
  }

  getMemoLanguageRenderer = memoizeLastShallowEqualEachArg(({location, lang}) => {
    // eslint-disable-next-line react/prop-types
    return ({from, type}) => {
      // eslint-disable-next-line react/no-this-in-sfc
      const availableLanguages = this.props.client.device.getAvailableLanguages();

      if (availableLanguages.length <= 1) {
        return null;
      }

      return (
        <Language
          // eslint-disable-next-line react/no-this-in-sfc
          onSelect={this.onLanguageClick}
          // eslint-disable-next-line react/no-this-in-sfc
          langPaths={this.getLangPaths(location)}
          selected={lang}
          type={type}
          from={from}
        />
      );
    };
  });

  getLanguageRenderer() {
    const {location} = this.props;
    const lang = this.getLang();
    return this.getMemoLanguageRenderer({location, lang});
  }

  getMemoCurrencyRenderer = memoizeLastShallowEqualEachArg(({currency}) => {
    // eslint-disable-next-line react/prop-types
    return ({from, type}) => {
      return (
        // eslint-disable-next-line react/no-this-in-sfc
        <Currency onChange={this.onCurrencyClick} currency={currency} type={type} from={from} />
      );
    };
  });

  getCurrencyRenderer() {
    const {currency} = this.props;
    return this.getMemoCurrencyRenderer({currency});
  }

  getMemoCoolbeRegionRenderer = memoizeLastShallowEqualEachArg(() => {
    // eslint-disable-next-line react/prop-types
    return ({from, type}) => {
      // eslint-disable-next-line react/no-this-in-sfc
      if (this.props.scope.not(Scope.COOLBE) || this.props.scope.deviceCustomDomain) {
        return null;
      }

      return <CoolbeRegion type={type} from={from} />;
    };
  });

  getCoolbeRegionRenderer() {
    return this.getMemoCoolbeRegionRenderer();
  }

  getMemoCouponRenderer = memoizeLastShallowEqualEachArg(
    ({user, couponCards, lastCouponViewTime, onCouponView}) => {
      return (type) => {
        // eslint-disable-next-line react/no-this-in-sfc
        if (this.props.scope.not(Scope.GLOBAL)) {
          return null;
        }

        const HeaderCouponOld = require('components/Header/CouponOld').default;
        return (
          <HeaderCouponOld
            user={user}
            // eslint-disable-next-line react/no-this-in-sfc
            className={this.getCouponClassName()}
            couponCards={couponCards}
            lastCouponViewTime={lastCouponViewTime}
            onCouponView={onCouponView}
            type={type}
            // eslint-disable-next-line react/no-this-in-sfc
            shouldForceAnnounceCoupon={() => this.state?.forceAnnounceCoupon}
            source="header"
          />
        );
      };
    },
  );

  couponRedesignRenderer = () => <HeaderCoupon source="header" />;

  getCouponRenderer() {
    const {
      user,
      couponCards,
      lastCouponViewTime,
      setCouponViewTimeForUser: onCouponView,
      deviceVars: {webCouponRedesign2023},
    } = this.props;

    return webCouponRedesign2023
      ? this.couponRedesignRenderer
      : this.getMemoCouponRenderer({
          user,
          couponCards,
          lastCouponViewTime,
          onCouponView,
        });
  }

  renderHeader() {
    const {cartCount, tracking, user, location, params} = this.props;
    const lang = this.getLang();

    return (
      <Header
        cartCount={cartCount}
        lang={lang}
        location={location}
        notificationsCenter={this.NotificationsCenter}
        onBurgerClick={this.onBurgerClick}
        shouldHighlightBurger={this.shouldHighlightBurger()}
        onBurgerHighlightClose={this.removeAnyPromoLinkHighlight}
        onSearch={this.onSearch}
        params={params}
        tracking={tracking}
        user={user}
        renderProfile={this.getProfileRenderer()}
        wrapWithMiniCart={this.getMiniCartRenderer()}
        defaultSearchValue={this.getDefaultSearchValue()}
        hasMobileStickyHeader={this.hasMobileStickyHeader()}
        hasMobilePermanentlyStickyHeader={this.hasMobilePermanentlyStickyHeader()}
        hasDesktopStickyHeader={this.hasDesktopStickyHeader()}
        hasCoupon={this.hasHeaderCoupon()}
        searchResultHelp={this.getSearchResultHelp()}
        hasMobileBackButton={this.hasMobileHeaderBackButton()}
        onMobileBackButtonClick={this.onMobileHeaderBackButtonClick}
        showSearchSideButton={this.isSearchHidden()}
        handleSearchSideButtonClick={this.handleSearchSideButtonClick}
        hiddenHeaderMobileButtons={this.getHiddenHeaderMobileButtons()}
      />
    );
  }

  setPromoLinkHighlightStatus = (linkId, value) => {
    this.setState(
      (prevState) => ({
        promoLinksHighlight: {
          ...prevState.promoLinksHighlight,
          [linkId]: value,
        },
      }),
      () => {
        const {cookiesRegistry} = this.props.client;
        cookiesRegistry.categoryPromoLinkOnboarding.store(
          JSON.stringify(this.state.promoLinksHighlight),
        );
      },
    );
  };

  removeAnyPromoLinkHighlight = () => {
    Object.keys(this.state.promoLinksHighlight).forEach((linkId) => {
      this.setPromoLinkHighlightStatus(linkId, false);
    });
  };

  renderHeaderCategories() {
    if (this.props.scope.is(Scope.CBTREND)) {
      return null;
    }

    const {categoryPromoLinks, rootCategoryView} = this.props;
    const {promoLinksHighlight} = this.state;

    return (
      <DesktopRenderer
        render={() => {
          const {CategoriesWide} = require('components/CategoriesWide');
          return (
            <CategoriesWide
              categoryPromoLinks={categoryPromoLinks}
              setHighlightStatus={this.setPromoLinkHighlightStatus}
              promoLinksHighlight={
                this.getShouldHighlightPromoLinksAtAll() ? promoLinksHighlight : {}
              }
              rootCategoryView={rootCategoryView}
              source={this.getPageSource()}
            />
          );
        }}
      />
    );
  }

  renderHelmet() {
    return (
      <Helmet {...buildHelmetProps(this.getMetadata(), this.props.scope, this.props.client)} />
    );
  }

  renderFooterSeoText() {
    const {deviceVars, metadata} = this.props;

    if (!metadata) {
      return null;
    }

    const linksHeader = (
      <FormattedMessage
        description="Popular categories block title"
        defaultMessage="Popular categories"
      />
    );

    const {bottomLinksBlock, bottomTextBlock} = metadata;
    const hasLinks = Boolean(bottomLinksBlock?.links?.length);
    const hasText = Boolean(bottomTextBlock?.items?.length);
    const links = <LinksBlock data={bottomLinksBlock} header={linksHeader} />;
    const text = (
      <TextBlock
        cut={this.getSeoCutOption() ?? deviceVars.webSeoCutMetadataText}
        data={bottomTextBlock}
      />
    );

    const shouldShowLinksBlock = this.getPageSource() !== PageSource.PRODUCT && hasLinks;

    if (!hasLinks && !hasText) {
      return null;
    }

    return (
      <Locator id="SeoBottomBlock">
        <div className={cn('seoBottom')}>
          <div className={cn('light', 'seoBottomBlock')}>
            <Reducer noMarginBottom>{text}</Reducer>
          </div>
          {shouldShowLinksBlock && (
            <div className={cn('light', 'seoBottomBlock')}>
              <Reducer noMarginBottom>{links}</Reducer>
            </div>
          )}
        </div>
      </Locator>
    );
  }

  renderFooter(pageError) {
    const {metadata, pageHash, region, richMeta, user, deviceVars} = this.props;
    const showForcedRegion = global.__SHOW_DEBUG_PREFERENCES__ || (user && user.admin);
    const showForcedEndpointsConfig = config.releaseStage !== 'prod';
    const showForcedDomainScopeConfig = config.releaseStage !== 'prod';
    const showForcedRtl = config.releaseStage !== 'prod';
    const showForcedRenderingType = global.__SHOW_DEBUG_PREFERENCES__ || (user && user.admin);
    const showUserAndDeviceIds = global.__SHOW_DEBUG_PREFERENCES__ || (user && user.admin);

    const forcedRegionComponent = showForcedRegion ? (
      <ForcedRegion currentRegion={region} onChange={this.onForcedRegionChange} />
    ) : null;

    const forcedEndpointsConfigComponent = showForcedEndpointsConfig ? (
      <ForcedEndpointsConfig />
    ) : null;

    const forcedDomainScopeComponent = showForcedDomainScopeConfig ? <ForcedDomainScope /> : null;

    const forcedRenderingTypeComponent = showForcedRenderingType ? <ForcedRenderingType /> : null;

    const forcedRtlComponent = showForcedRtl ? <ForcedRtl /> : null;

    const userAndDeviceIdsComponent = showUserAndDeviceIds ? <UserAndDeviceInfo /> : null;

    const showNewFooter = Boolean(deviceVars.webFooterRedesign8709);

    return (
      <LazyHydrate whenVisible>
        {!pageError && this.renderFooterSeoText()}

        {showNewFooter ? (
          <NewFooter
            deepUrl={this.getDeepUrl()}
            linksBlock={metadata?.bottomLinksBlock}
            pageId={richMeta ? pageHash : undefined}
            productId={this.getProductId()}
            source={this.getPageSource()}
          >
            {forcedRegionComponent}
            {forcedRenderingTypeComponent}
            {forcedEndpointsConfigComponent}
            {forcedDomainScopeComponent}
            {forcedRtlComponent}
            {userAndDeviceIdsComponent}
          </NewFooter>
        ) : (
          <Footer
            pageId={pageHash}
            deepUrl={this.getDeepUrl()}
            richMeta={richMeta}
            productId={this.getProductId()}
            source={this.getPageSource()}
            linksBlock={metadata?.bottomLinksBlock}
            mobileRounded={this.mobileRoundedFooter}
          >
            {forcedRegionComponent}
            {forcedRenderingTypeComponent}
            {forcedEndpointsConfigComponent}
            {forcedDomainScopeComponent}
            {forcedRtlComponent}
            {userAndDeviceIdsComponent}
          </Footer>
        )}
      </LazyHydrate>
    );
  }

  renderSignupPromo() {
    return <SignupPromo />;
  }

  renderNavBar() {
    return (
      <NavBar
        renderCurrency={this.getCurrencyRenderer()}
        renderLanguage={this.getLanguageRenderer()}
        renderRegion={this.getCoolbeRegionRenderer()}
        renderCoupon={this.getCouponRenderer()}
      />
    );
  }

  renderMobileSearch() {
    if (this.isSearchHidden()) {
      return null;
    }

    return (
      <UserAgentContext.Consumer>
        {(userAgent) => (
          <Search
            key={this.props.location.pathname}
            onSubmit={this.onSearch}
            focus={this.isHiddenSearchEnabled()}
            value={getSearchData(this.props.params).query}
            isMobile
            defaultValue={this.getDefaultSearchValue()}
            searchResultHelp={this.getSearchResultHelp()}
            showSuggest={this.isHiddenSearchEnabled()}
            onSuggestClose={this.handleSearchSuggestClose}
          />
        )}
      </UserAgentContext.Consumer>
    );
  }

  renderCouponBanner() {
    if (this.props.deviceVars.webCouponRedesign2023) {
      return null;
    }
    return this.getCouponRenderer()(COUPON_BANNER_TYPE);
  }

  renderBottomHeaderAppBanner() {
    return null;
  }

  renderTopHeaderAppBanner() {
    return null;
  }

  renderUnderHeaderSlot() {
    return null;
  }

  renderPageTop() {
    const {legalityConsentDocument, disableCookiesUntilUserSetsPermission} = this.props;
    const showLegality =
      !this.props.bot && legalityConsentDocument && !disableCookiesUntilUserSetsPermission;

    return (
      <React.Fragment>
        <Skipper onSkipClick={this.skipToContent} />
        <OldBrowser />
        {showLegality ? (
          <Legality
            onSignout={this.signout}
            document={legalityConsentDocument}
            doNotShowModal={!this.hasLegalityPopup()}
            hcaptchaTransport={this.props.client.device.transports.hcaptcha}
          />
        ) : null}
        {this.renderSignupPromo()}
        {this.renderTopHeaderAppBanner()}
        {this.renderNavBar()}
        {this.renderHeader()}
        {this.renderHeaderCategories()}
        {this.renderMobileSearch()}
        {this.renderUnderHeaderSlot()}
        {this.renderBottomHeaderAppBanner()}
        {this.renderCouponBanner()}
      </React.Fragment>
    );
  }

  renderPageInner(contentOrPageError = this.renderContent()) {
    if (this.props.loadingLanguages || (this.state.refreshing && this.enableRefreshVisualisation)) {
      return <PageLoading />;
    }

    if (this.state.pageBaseError) {
      return <PageError error={this.state.pageBaseError} />;
    }

    return (
      <React.Fragment>
        {this.renderBreadcrumbs()}
        <div ref={this.mainContentTargetRef} tabIndex={-1} />
        <main>
          {this.isPageError(contentOrPageError)
            ? this.renderPageError(contentOrPageError)
            : contentOrPageError}
        </main>
      </React.Fragment>
    );
  }

  handleCategoryOpen = (evt, category) => {
    evt.preventDefault();
    const {
      history,
      location: {pathname, search},
    } = this.props;
    const query = getQueryData(search);
    history.replace(
      createUrl(pathname, {
        ...query,
        [CATEGORY_PARAM]: (category && category.id) || ROOT_ID,
      }),
    );
  };

  skipToContent = () => {
    this.mainContentTargetRef.current?.focus();
  };

  renderPreloaderOverlay() {
    const {showPreloaderOverlay} = this.state;

    return showPreloaderOverlay ? (
      <div className={styles.preloaderOverlay}>
        <Preloader />
      </div>
    ) : null;
  }

  handleMobileSheetCloseBeforeNavigate = () => {
    if (!this.props.deviceVars.searchAddSiblingsToLeafCategoryFilters) {
      this.setState({
        isCategoriesSheetVisible: false,
      });
    }
  };

  handleMobileSheetClose = () => {
    this.setState(
      {
        isCategoriesSheetVisible: false,
      },
      () => {
        // multilevelCatalog not used at now, but saved for future
        if (
          !this.props.multilevelCatalog &&
          !this.props.deviceVars.searchAddSiblingsToLeafCategoryFilters
        ) {
          window.history.back();
        }
      },
    );
  };

  getShouldHighlightPromoLinksAtAll = () => {
    return true;
  };

  hasPromoLinksHighlight = () => {
    if (this.state.promoLinksHighlight && this.getShouldHighlightPromoLinksAtAll()) {
      return Object.values(this.state.promoLinksHighlight).some(
        (shouldHighlightLink) => shouldHighlightLink,
      );
    }
    return false;
  };

  shouldHighlightBurger = () => {
    return this.hasPromoLinksHighlight();
  };

  getSigninContext = memoizeLastShallowEqual(() => {
    return {
      socialStart: this.props.socialSignin,
      start: this.signin,
      success: this.handleAuthSuccess,
      retry: this.handleAuthRetry,
      close: this.handleAuthClose,
    };
  });

  getPreferencesContext = memoizeLastShallowEqual(() => {
    return {
      deepUrl: this.getDeepUrl(),
    };
  });

  render() {
    const {isBurgerVisible, burgerView, isSignout, isCategoriesSheetVisible, promoLinksHighlight} =
      this.state;
    const {
      user,
      pointsAccount,
      rootCategoryView,
      catalogLoading,
      location,
      subCategory,
      multilevelCatalog,
    } = this.props;
    if (!user) {
      return <PageError />;
    }

    const light = this.isBackgroundWhite();
    const theme = light ? ContentTheme.LIGHT : ContentTheme.DEFAULT;

    const contentOrPageError = this.renderContent();
    const pageError = this.isPageError(contentOrPageError) ? contentOrPageError : undefined;

    if (pageError) {
      if (pageError.error.status || pageError.defaultStatus) {
        this.setPageStatus(pageError.error.status || pageError.defaultStatus);
      }
    }

    return (
      <ContentThemeContext.Provider value={theme}>
        <SigninContext.Provider value={this.getSigninContext()}>
          <PagePreferencesContext.Provider value={this.getPreferencesContext()}>
            <div className={cn('parent', {light}, this.getParentClassName())}>
              <div className={cn('reducer', this.getReducerClassName())}>
                {this.renderHelmet()}
                {this.renderPageTop()}
                <div className={`${styles.content} ${this.getContentClassName()}`}>
                  {this.renderPageInner(contentOrPageError)}
                </div>
              </div>
              {this.renderFooter(pageError)}
              {this.hasSnow() && <Snow />}
              <LocationPopup />
              <CookiesSettingsBanner />
              <RewardWheelConnector
                onRewardWheelAuthClick={this.handleRewardWheelSignin}
                onRewardWheelActivate={this.handleRewardWheelActivate}
                isUserAnonymous={user.anonymous}
                {...(this.rewardWheelProps || {})}
              />
              <IdlerConnector />
              <JmtMigrationPopupController />
              {!isBurgerVisible || (
                <UserAgentContext.Consumer>
                  {(userAgent) => (
                    <ContentThemeContext.Provider value={ContentTheme.DEFAULT}>
                      <Burger
                        buildUrl={this.getBuildUrlForBurger()}
                        categoryViewForBurger={this.getCategoryViewForBurger()}
                        categoryError={this.props.categoryError}
                        categoryLoading={this.props.categoryLoading}
                        categoryView={this.props.categoryView}
                        categoryPromoLinks={this.props.categoryPromoLinks}
                        setHighlightStatus={this.setPromoLinkHighlightStatus}
                        promoLinksHighlight={promoLinksHighlight}
                        itemsLoading={this.props.itemsLoading}
                        isSigningOut={isSignout}
                        lang={this.getLang()}
                        location={location}
                        onBurgerSignout={this.onBurgerSignout}
                        onClose={this.onBurgerClose}
                        params={this.props.params}
                        pointsAccount={pointsAccount}
                        renderCurrency={this.getCurrencyRenderer()}
                        renderLanguage={this.getLanguageRenderer()}
                        renderRegion={this.getCoolbeRegionRenderer()}
                        rootCategoryView={rootCategoryView}
                        catalogLoading={catalogLoading}
                        selectedCategoryId={this.props.selectedCategoryId}
                        useProps={this.hasOwnCategories(userAgent)}
                        user={user}
                        view={burgerView}
                        subCategory={this.props.subCategory}
                        multilevelCatalog={this.props.multilevelCatalog}
                        source={this.getPageSource()}
                      />
                    </ContentThemeContext.Provider>
                  )}
                </UserAgentContext.Consumer>
              )}
              {this.state.isSignin ? (
                <Auth
                  popupType={this.state.signinType}
                  defaultView={this.state.signinView}
                  onSuccess={this.handleAuthSuccess}
                  onRetry={this.handleAuthRetry}
                  onClose={this.handleAuthClose}
                  lang={this.getLang()}
                  region={this.props.region}
                  source={this.state.signinContext?.source}
                >
                  {this.getAuthPopupText()}
                </Auth>
              ) : null}
              <VersionMismatchController />
              {this.renderPreloaderOverlay()}
            </div>
            {isCategoriesSheetVisible ? (
              <MobileBottomSheet fullScreen onClose={this.handleMobileSheetClose}>
                {/* multilevelCatalog not used at now, but saved for future */}
                {multilevelCatalog ? (
                  <div className={styles.multilevelCatalog}>
                    <MultilevelCatalog
                      buildUrl={this.getBuildUrlForBurger()}
                      loading={this.props.categoryLoading}
                      onClose={this.handleMobileSheetClose}
                      onCloseBeforeNavigate={this.handleMobileSheetCloseBeforeNavigate}
                      params={this.props.params}
                      subCategory={subCategory}
                      mobileSheetView
                      from={CategoryFrom.BOTTOMSHEET}
                      source={this.getPageSource()}
                    />
                  </div>
                ) : (
                  <Catalog
                    buildUrl={this.getBuildUrlForBurger()}
                    lang={this.getLang()}
                    onClose={this.handleMobileSheetClose}
                    onOpen={this.handleCategoryOpen}
                    onSelect={this.handleMobileSheetCloseBeforeNavigate}
                    error={this.props.categoryError}
                    loading={this.props.categoryLoading}
                    categoryView={this.props.categoryView}
                    params={this.props.params}
                    rowSize="large"
                    stickyHeader
                    noRootIcons
                    boldAllCategories
                    rootHeader={
                      <BottomSheetHeader onClose={this.handleMobileSheetClose}>
                        <FormattedMessage
                          defaultMessage="Categories"
                          description="[title] Header of root view in catalog"
                        />
                      </BottomSheetHeader>
                    }
                    headerWithoutLink
                    from={CategoryFrom.BOTTOMSHEET}
                    source={this.getPageSource()}
                    instantSelect={
                      this.catalogInstantSelect ||
                      this.props.deviceVars.searchAddSiblingsToLeafCategoryFilters
                    }
                  />
                )}
              </MobileBottomSheet>
            ) : null}
          </PagePreferencesContext.Provider>
        </SigninContext.Provider>
      </ContentThemeContext.Provider>
    );
  }
}

PageBase.async = [
  {
    deferred: false,
    promise: ({store: {dispatch, getState}}) => isUserLoaded(getState()) || dispatch(loadUser()),
  },
  {
    deferred: true,
    promise: ({store: {dispatch, getState}}) =>
      isNonEphemeral(getState()) && !isCartCountLoaded(getState()) && dispatch(loadCartCount()),
  },
  {
    deferred: true,
    promise: ({store: {dispatch, getState}}) => {
      return isCouponCardsLoaded(getState())
        ? dispatch(syncViewedCouponCards())
        : dispatch(loadCouponCards());
    },
  },
  {
    deferred: true,
    promise: ({store: {dispatch, getState}}) =>
      isCategoryViewLoaded(getState(), ROOT_ID) || dispatch(loadCategoriesHierarchy(ROOT_ID)),
  },
  {
    deferred: false,
    promise: ({store: {dispatch, getState}}) => {
      const state = getState();
      return (
        isLegalityConsentLoaded(state) ||
        isLegalityConsentLoading(state) ||
        dispatch(loadLegalityConsent())
      );
    },
  },
  {
    deferred: false,
    promise: ({store: {dispatch, getState}}) =>
      isCloseMemoLoaded(getState(), closeCookiesNames.emailPromo) ||
      dispatch(loadCloseMemo(closeCookiesNames.emailPromo)),
  },
  {
    deferred: false,
    promise: ({store: {dispatch, getState}}) =>
      isCloseMemoLoaded(getState(), closeCookiesNames.joomMobileAppBanner) ||
      dispatch(loadCloseMemo(closeCookiesNames.joomMobileAppBanner)),
  },
  {
    deferred: true,
    promise: ({store: {dispatch, getState}}) => {
      const state = getState();

      if (!isUnauthorized(state) && needUpdateNotificationsUnreadCount(state)) {
        // В фильтрах хранится корректное количество непрочитанных уведомлений,
        // которое соответствует только доступным фильтрам
        return dispatch(loadNotificationsFilters());
      }

      return undefined;
    },
  },
  {
    deferred: true,
    promise: ({store: {dispatch, getState}}) =>
      getPointsAccount(getState()) || dispatch(loadPointsAccount()),
  },
  {
    deferred: true,
    promise: ({store: {dispatch, getState}, location: {pathname, search}, client}) => {
      const url = `${pathname}${search}`;
      // in development SEO service don't work
      return __DEVELOPMENT__ ||
        client.scope.is(Scope.COOLBE, Scope.CBTREND) ||
        isPageMetadataLoaded(getState(), url)
        ? undefined
        : dispatch(loadPageMetadata(url));
    },
  },
  {
    deferred: false,
    promise: ({store: {dispatch, getState}, location: {pathname, search}}) => {
      const state = getState();
      const url = `${pathname}${search}`;
      return getPageHashUrl(state) === url || dispatch(loadPageHash(url));
    },
  },
  {
    deferred: true,
    promise: ({store: {dispatch, getState}}) =>
      needLoadCategoryPromoLinks(getState()) && dispatch(loadCategoryPromoLinks()),
  },
  {
    deferred: true,
    promise: ({store: {dispatch, getState}}) =>
      isUnauthorized(getState()) ||
      isPersonalDataExistsLoaded(getState(), PASSPORT_FORM_ORIGIN) ||
      dispatch(loadPersonalDataExists(PASSPORT_FORM_ORIGIN)),
  },
];

function closeEmailPromo() {
  return setMemoClosed(closeCookiesNames.emailPromo);
}

function closeJoomMobileAppBanner() {
  return setMemoClosed(closeCookiesNames.joomMobileAppBanner);
}

PageBase.mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      closeEmailPromo,
      closeJoomMobileAppBanner,
      loadCartCount,
      loadCouponCards,
      loadLegalityConsent,
      saveNotificationsPreferences,
      setCouponViewTimeForUser,
      signup,
      signout,
      smartLockSignin,
      socialSignin,
      loadCategoryPromoLinks,
      loadCookiesSettings,
      addProductToCollection,
      removeProductFromCollection,
    },
    dispatch,
  );

const getCategoryViewMemo = memoizeByResult(getCategoryView, isEqual);

PageBase.mapStateToProps = (state, {location: {pathname, search}}) => ({
  cartCount: getCartCount(state),
  categoryPromoLinks: getCategoryPromoLinks(state),
  couponCards: getCouponCards(state),
  currencies: getCurrencies(state),
  currenciesError: getCurrenciesError(state),
  currenciesLoading: isCurrenciesLoading(state),
  currency: state.preferences.currency,
  detectedLanguage: state.preferences.detectedLanguage,
  deviceId: getDeviceId(state),
  deviceVars: getDeviceVars(state),
  bot: isBot(state),
  lastCouponViewTime: getLastCouponViewTimeForCurrentUser(state),
  legalityConsentDocument: getLegalityConsentRequires(state),
  loadingLanguages: hasLoadingLanguages(state),
  locale: state.preferences.locale,
  metadata: getPageMetadata(state, `${pathname}${search}`),
  // not used at now, but saved for future
  multilevelCatalog: false,
  nonEphemeral: isNonEphemeral(state),
  origin: state.preferences.origin,
  pageHash: getPageHash(state, `${pathname}${search}`),
  pointsAccount: getPointsAccount(state),
  region: state.preferences.region || '',
  richMeta: state.preferences.richMeta,
  rootCategoryView: getCategoryViewMemo(state, ROOT_ID),
  catalogLoading: isCategoryLoading(state, ROOT_ID),
  signining: isSignining(state),
  subCategory: getSubCategory(state),
  tracking: state.tracking.data,
  user: getUser(state),
  wheelShown: Boolean(getRewardWheelData(state)),
  webCouponRedesign: getDeviceVar(state, 'webCouponRedesign'),
  maidenCouponAutoShow: getDeviceVar(state, 'maidenCouponAutoShow'),
  disableCookiesUntilUserSetsPermission: getDeviceVar(
    state,
    'disableCookiesUntilUserSetsPermission',
  ),
  joomMobileAppBannerClosed: getCloseMemo(state, closeCookiesNames.joomMobileAppBanner),
});

// for typescript bindings
export const PageBaseBinding = PageBase;

// for backwards compatibility
export default PageBase;
