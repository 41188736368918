import {PopupParams} from 'containers/ClientPopup';
import {useRoute} from 'hooks/useRoute';
import {useRouter} from 'hooks/useRouter';
import React, {ReactElement, useMemo} from 'react';
import {isString} from 'utils/guards';
import {getQueryData} from 'utils/url';

type PopupConfig = {
  popupId: string;
  popupParams: PopupParams;
};

function usePopupConfig(): PopupConfig | undefined {
  const route = useRoute();
  const {location, params} = useRouter();
  const query = useMemo(() => getQueryData(location.search), [location.search]);
  const popupParams = useMemo(() => {
    const rawParams = query.params || query.popupParams;
    const params = Array.isArray(rawParams) ? rawParams[0] : rawParams;

    if (!params) {
      return {};
    }

    try {
      return JSON.parse(params) || {};
    } catch {
      return {};
    }
  }, [query.params, query.popupParams]);

  if (route.name === 'sharePopup' && isString(params.popupId)) {
    return {
      popupId: params.popupId,
      popupParams,
    };
  }

  if (isString(query.popupId)) {
    return {
      popupId: query.popupId,
      popupParams,
    };
  }

  return undefined;
}

export function LocationPopup(): ReactElement | null {
  const popupConfig = usePopupConfig();

  if (popupConfig) {
    const {InnerLocationPopup} =
      // eslint-disable-next-line @typescript-eslint/no-var-requires,global-require
      require('./InnerLocationPopup') as typeof import('./InnerLocationPopup');

    return <InnerLocationPopup {...popupConfig} />;
  }

  return null;
}
