import {Dispatch, SetStateAction, useState} from 'react';

import {FileType} from './File';

export function useFiles(files: FileType[]): [FileType[], Dispatch<SetStateAction<FileType[]>>] {
  return useState(files);
}

function updateFile(
  setFiles: React.Dispatch<React.SetStateAction<FileType[]>>,
  id: string,
  handler: (file: FileType) => FileType,
): void {
  setFiles((files: FileType[]): FileType[] =>
    files.map((file) => (file.id === id ? handler(file) : file)),
  );
}

export function setFileFields(
  setFiles: React.Dispatch<React.SetStateAction<FileType[]>>,
  id: string,
  fields: Partial<FileType>,
): void {
  updateFile(setFiles, id, (file) => ({
    ...file,
    ...fields,
  }));
}

export function setFileProgress(
  setFiles: React.Dispatch<React.SetStateAction<FileType[]>>,
  id: string,
  uploadingProgress: number,
): void {
  updateFile(setFiles, id, (file) => ({
    ...file,
    uploadingProgress,
  }));
}

export function setFileError(
  setFiles: React.Dispatch<React.SetStateAction<FileType[]>>,
  id: string,
  error: Error,
): void {
  updateFile(setFiles, id, (file) => ({
    ...file,
    error,
  }));
}

export function addFiles(
  setFiles: React.Dispatch<React.SetStateAction<FileType[]>>,
  filesToAdd: FileType[],
): void {
  setFiles((files: FileType[]): FileType[] => [...files, ...filesToAdd]);
}

export function removeFile(
  setFiles: React.Dispatch<React.SetStateAction<FileType[]>>,
  id: string,
): void {
  setFiles((files: FileType[]): FileType[] => files.filter((file) => file.id !== id));
}
