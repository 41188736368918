import PropTypes from 'prop-types';
import React, {useCallback} from 'react';

import {defineMessages, useIntl} from 'react-intl';

import {HeaderButton, HeaderButtonContainer} from 'components/Header/HeaderButton';
import {rootLocator} from 'utils/rootLocator';
import {useMobWebAdInterlayerHardcoreModeLink} from 'hooks/useMobWebAdInterlayer';
import {Icon} from 'components/UIKit/Icon';

const messages = defineMessages({
  indicator: {
    description: 'Notification center label in header',
    defaultMessage: 'Notifications',
  },
  indicatorNumeral: {
    description: 'Notifications count label',
    defaultMessage: `{unreadCount, plural, one {notification} other {notifications}}`,
  },
});

const locator = rootLocator.commonPage.notifications.indicator;

export const Indicator = (props) => {
  const {onClick, unreadCount, children, buttonAttributes} = props;
  const intl = useIntl();

  const mobileInterlayerActionOverrideLink = useMobWebAdInterlayerHardcoreModeLink();

  const handleClick = useCallback(
    (event) => {
      if (mobileInterlayerActionOverrideLink) {
        event?.preventDefault();
        event?.stopPropagation();
        mobileInterlayerActionOverrideLink();
        return;
      }

      onClick(event);
    },
    [mobileInterlayerActionOverrideLink, onClick],
  );

  return (
    <HeaderButtonContainer>
      <HeaderButton
        icon={
          <Icon type="mono" name="notification-linear-24" width="24px" height="24px" aria-hidden />
        }
        onClick={handleClick}
        indicatorCount={unreadCount}
        indicatorNumeral={intl.formatMessage(messages.indicatorNumeral, {unreadCount})}
        text={intl.formatMessage(messages.indicator)}
        buttonAttributes={buttonAttributes}
        {...locator.button()}
      >
        {children}
      </HeaderButton>
    </HeaderButtonContainer>
  );
};

Indicator.propTypes = {
  onClick: PropTypes.func,
  unreadCount: PropTypes.number,
  children: PropTypes.node,
  buttonAttributes: PropTypes.object,
};

Indicator.defaultProps = {
  onClick: null,
  unreadCount: null,
  children: null,
  buttonAttributes: {},
};
