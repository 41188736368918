import classnames from 'classnames/bind';
import parse, {domToReact} from 'html-react-parser';
import React, {memo} from 'react';
import {objectFilter} from 'utils/object';
import {useLanguage} from 'hooks/useLanguage';
import {useScope} from 'hooks/useScope';
import {AnyLink} from 'components/AnyLink';
import {getCssPropertiesFromString} from 'utils/styles/getCssPropertiesFromString';
import {AllowedTagName, AllowedAttributes} from './TagName';
import styles from './HyperText.scss';

const cn = classnames.bind(styles);

function replace({options, data, getClassName = cn, lang, scope}) {
  const {type, name, attribs, children} = data;
  if (type !== 'tag') {
    return undefined;
  }

  if (!AllowedTagName[name]) {
    return <></>;
  }

  const attrs = objectFilter(attribs || {}, (value, key) => AllowedAttributes[key]);
  attrs.className = getClassName(name);

  if (name === 'a') {
    if (attrs.style) {
      attrs.style = getCssPropertiesFromString(attrs.style);
    }

    attrs.target = attrs.target || '_blank';
    attrs.rel = attrs.rel || 'noopener noreferrer';

    return (
      <AnyLink {...attrs} to={attrs.href}>
        {domToReact(children, options)}
      </AnyLink>
    );
  }

  return React.createElement(name, attrs, children.length ? domToReact(children, options) : null);
}

// eslint-disable-next-line react/prop-types
export const Html = memo(function Html({html, getClassName}) {
  const lang = useLanguage();
  const scope = useScope();

  const options = {};
  options.replace = (data) => replace({options, data, getClassName, lang, scope});
  return parse(html, options);
});
