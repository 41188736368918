import PropTypes from 'prop-types';
import TrackingCheckpoint from 'shapes/TrackingCheckpoint';
import {ParcelOrderShape} from 'shapes/ParcelOrder';
import {ConsolidationBadgeShape} from 'shapes/ConsolidationBadge';
import {OrderSupportShape} from './OrderSupport';

export const ParcelLiteShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  deliveredTimeMs: PropTypes.number,
  statusAppearance: PropTypes.shape({
    title: PropTypes.string,
    color: PropTypes.string,
  }),
  shipingEstimate: PropTypes.shape({
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
  }),
  orders: PropTypes.arrayOf(ParcelOrderShape),
  tracking: PropTypes.oneOfType([PropTypes.object, TrackingCheckpoint]),
  action: PropTypes.shape({
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }),
  support: OrderSupportShape,
  appearance: PropTypes.shape({
    showAddress: PropTypes.bool.isRequired,
    consolidationBadge: ConsolidationBadgeShape,
  }),
});
