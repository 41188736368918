import {ScopeConfig} from 'helpers/ApiClient/Scope/ScopeConfig';
import {getUrl} from 'routes';

export const SHIPPING_ID = '5e7243b68159e45294929616';
export const WARRANTY_ID = '5e7207f48159e45294928d8d';
export const COOLBE_WARRANTY_ID = '660439fa2898706993289aeb';

type RouteUrl = ReturnType<typeof getUrl>;

export const getShippingUrl = (scope: ScopeConfig, lang: string): RouteUrl =>
  getUrl('faq', {scope, lang, categoryId: SHIPPING_ID});

export const getWarrantyUrl = (scope: ScopeConfig, lang: string): RouteUrl =>
  getUrl('faq', {scope, lang, categoryId: WARRANTY_ID});

export const getCoolbeWarrantyUrl = (scope: ScopeConfig, lang: string): RouteUrl =>
  getUrl('faqArticle', {scope, lang, articleId: COOLBE_WARRANTY_ID});
