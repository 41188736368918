import {ParcelShape} from 'shapes/Parcel';
import PropTypes from 'prop-types';
import React, {useMemo, useCallback, useEffect, useState, memo} from 'react';
import {ParcelPageCard, ParcelPageCardInner} from 'components/ParcelPageCard';
import {isArrayNotEmpty} from 'utils/array';
import {isParcelDeliveryConfirmed} from 'store/modules/parcel';
import {useMounted} from 'hooks/useMounted';
import {ParcelOrderShape} from 'shapes/ParcelOrder';
import ErrorShape from 'shapes/Error';
import {Banners} from 'components/OrderBanner/Banners';
import {getValueByPath} from 'utils/object';
import {useParams} from 'react-router-dom';
import {useLocationQuery} from 'hooks/useLocationQuery';
import {verticalScrollToElement} from 'utils/pageScroll';
import {Order} from './Order';
import {ReviewForm} from '../Review/Form';
import {OrderReview} from '../Review/Review';

const REVIEW_PATH = 'review';
const SCROLL_MARGIN = 16;
const PICKUP_INFO_BANNER_TYPE = 'pickupInfo';

const OrderCardComponent = React.forwardRef(
  (
    {
      activeFilter,
      isYesClicked,
      isNoClicked,
      item,
      markParcelDelivered,
      orderReviewRemoving,
      orderReviewSaving,
      parcel,
      removeOrderReview,
      reviewRemoveError,
      reviewSaveError,
      saveOrderReview,
      triggerReviewOpen,
    },
    ref,
  ) => {
    const {id: queryId} = useLocationQuery();
    const {action} = useParams();
    const deliveryConfirmed = isParcelDeliveryConfirmed(parcel);
    const itemReturnInfo = getValueByPath(item, 'returnInfo');
    const itemReviewInfo = getValueByPath(item, 'reviewInfo');
    const itemVatInfo = parcel.orders.find(
      (orderItem) => orderItem.order.id === item.order.id,
    )?.vatInfo;
    const [reviewMode, setReviewMode] = useState(
      action === REVIEW_PATH && queryId === item.order.id,
    );
    const confirmMode = queryId === item.order.id && !deliveryConfirmed;
    const confirmFirst =
      !queryId &&
      item.order.id === parcel.orders[0].order.id &&
      itemReviewInfo &&
      itemReviewInfo.canReview &&
      !deliveryConfirmed;
    const [isReviewFormOpened, setReviewFormOpened] = useState(triggerReviewOpen || reviewMode);
    const [reviewInfo, setReviewInfo] = useState(itemReviewInfo);
    const mounted = useMounted();
    const banners = getValueByPath(item, 'banners');

    useEffect(() => {
      const mode = action === REVIEW_PATH && queryId === item.order.id;

      setReviewMode(mode);

      if (mode) {
        setReviewFormOpened(true);
        if (ref?.current) {
          verticalScrollToElement(ref.current, {margin: SCROLL_MARGIN, forced: true});
        }
      }
      // disabled after enabling exhaustive deps rule
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [action, queryId]);

    useEffect(() => {
      if (isYesClicked && (confirmMode || confirmFirst)) {
        markParcelDelivered(parcel.id).then(() => {
          setReviewFormOpened(true);
          if (ref?.current) {
            verticalScrollToElement(ref.current, {margin: SCROLL_MARGIN, forced: true});
          }
        });
      }
      // disabled after enabling exhaustive deps rule
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isYesClicked, parcel.id]);

    useEffect(() => {
      if (triggerReviewOpen) {
        setReviewFormOpened(true);
      }
    }, [triggerReviewOpen]);

    const handleEditClick = useCallback(() => {
      setReviewFormOpened(true);
    }, []);

    const handleCancel = useCallback(() => {
      setReviewFormOpened(false);
    }, []);

    const handleSubmitReview = useCallback(
      (data) => {
        const {id} = item.order;

        if (data) {
          saveOrderReview(parcel.id, id, data).then((resultOrder) => {
            if (!mounted) {
              return;
            }
            setReviewInfo(resultOrder.order.reviewInfo);
            setReviewFormOpened(false);
          });
        } else {
          if (!mounted) {
            return;
          }
          setReviewFormOpened(false);
        }
      },
      [saveOrderReview, item, parcel, mounted],
    );

    const handleRemoveReview = useCallback(() => {
      const {id} = item.order;
      removeOrderReview(parcel.id, id).then(
        ({
          body: {
            payload: {reviewInfo: info},
          },
        }) => {
          if (!mounted) {
            return;
          }
          setReviewInfo(info);
          setReviewFormOpened(false);
        },
      );
    }, [removeOrderReview, item, parcel, mounted]);

    const handleReviewClick = useCallback(() => {
      setReviewFormOpened(true);
    }, []);

    const canShowReviewForm = reviewInfo && reviewInfo.canReview;
    const hasReview = reviewInfo && reviewInfo.review;

    const showReviewForm = canShowReviewForm && isReviewFormOpened;
    const showReview = hasReview && !isReviewFormOpened;
    const showReviewButton =
      canShowReviewForm && !hasReview && deliveryConfirmed && !showReviewForm && !isNoClicked;

    const isOrderReviewSaving = orderReviewSaving && orderReviewSaving[item.order.id];
    const isOrderReviewRemoving = orderReviewRemoving && orderReviewRemoving[item.order.id];
    const removeError = reviewRemoveError && reviewRemoveError[item.order.id];
    const saveError = reviewSaveError && reviewSaveError[item.order.id];
    const validatedBanners = useMemo(
      () => (banners || []).filter(({type}) => type !== PICKUP_INFO_BANNER_TYPE),
      [banners],
    );
    const hasBanners = isArrayNotEmpty(validatedBanners);

    return (
      <ParcelPageCard>
        <div ref={ref}>
          {hasBanners && (
            <ParcelPageCardInner>
              <Banners banners={validatedBanners} returnInfo={itemReturnInfo} />
            </ParcelPageCardInner>
          )}
          <Order
            activeFilter={activeFilter}
            onReviewClick={handleReviewClick}
            order={item.order}
            showSpecialPriceIcon={item.showSpecialPriceIcon}
            bottomBanners={item.bottomBanners}
            vatInfo={itemVatInfo}
            parcel={parcel}
            quantity={item.quantity}
            showReviewButton={showReviewButton}
          />
          {showReviewForm && (
            <ReviewForm
              orderId={item.order.id}
              orderCreatedTimeMs={item.order.createdTimeMs}
              orderReview={reviewInfo}
              onCancel={handleCancel}
              onRemove={handleRemoveReview}
              onSubmit={handleSubmitReview}
              parcelId={parcel.id}
              productId={item.order.productId}
              removing={isOrderReviewRemoving}
              loading={isOrderReviewSaving}
              error={removeError || saveError}
            />
          )}
          {showReview && <OrderReview reviewInfo={reviewInfo} onEdit={handleEditClick} />}
        </div>
      </ParcelPageCard>
    );
  },
);

export const OrderCard = memo(OrderCardComponent);

OrderCardComponent.propTypes = {
  isYesClicked: PropTypes.bool,
  isNoClicked: PropTypes.bool,
  item: ParcelOrderShape.isRequired,
  markParcelDelivered: PropTypes.func.isRequired,
  orderReviewRemoving: PropTypes.shape({
    [PropTypes.string]: PropTypes.bool,
  }).isRequired,
  orderReviewSaving: PropTypes.shape({
    [PropTypes.string]: PropTypes.bool,
  }).isRequired,
  parcel: ParcelShape.isRequired,
  removeOrderReview: PropTypes.func.isRequired,
  reviewRemoveError: PropTypes.objectOf(ErrorShape),
  reviewSaveError: PropTypes.objectOf(ErrorShape),
  saveOrderReview: PropTypes.func.isRequired,
  activeFilter: PropTypes.string.isRequired,
  triggerReviewOpen: PropTypes.bool,
};

OrderCardComponent.defaultProps = {
  isYesClicked: false,
  isNoClicked: false,
  reviewRemoveError: {},
  reviewSaveError: {},
  triggerReviewOpen: false,
};
