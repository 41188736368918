import {keyKey} from 'utils/array';

const isTextNode = (node) => node.nodeType === 3;

const AllowedTagName = keyKey([
  'A',
  'BR',
  'CODE',
  'EM',
  'H1',
  'H2',
  'H3',
  'H4',
  'H5',
  'LI',
  'NOBR',
  'OL',
  'P',
  'PRE',
  'STRONG',
  'UL',
]);

const BannedAttributes = [
  /^on/,
  {
    name: 'href',
    value: /^javascript:/,
  },
];

function walkNodes(nodes, fn) {
  for (let i = nodes.length - 1; i >= 0; i--) {
    const node = nodes[i];
    if (!isTextNode(node)) {
      fn(node);
      if (node.children) {
        walkNodes(node.children, fn);
      }
    }
  }
}

function walkAttributes(node, fn) {
  for (let i = 0; i < node.attributes.length; i++) {
    const {name} = node.attributes[i];
    fn(node, name, node.getAttribute(name));
  }
}

function sanitizeNodeAttributes(node, key, value) {
  BannedAttributes.forEach((rule) => {
    if (rule instanceof RegExp) {
      if (rule.test(key)) {
        node.removeAttribute(key);
      }
    } else if (key === rule.name && rule.value.test(value)) {
      node.removeAttribute(key);
    }
  });
}

function sanitizeNode(node) {
  if (node.tagName in AllowedTagName) {
    walkAttributes(node, sanitizeNodeAttributes);
  } else {
    node.remove();
  }
}

function addClassNameByTagName(node, mapper) {
  node.classList.add(mapper(node.tagName.toLowerCase()));
}

function addAttributesByNode(node, mapper) {
  Object.entries(mapper(node) || {}).forEach(([attrName, attrValue]) => {
    if (attrName === 'style') {
      if (attrValue !== null && typeof attrValue === 'object') {
        Object.entries(attrValue).forEach(([prop, value]) => {
          // eslint-disable-next-line no-param-reassign
          node.style[prop] = value;
        });
      }
    } else {
      node.setAttribute(attrName, attrValue);
    }
  });
}

function fragmentFromString(html) {
  return document.createRange().createContextualFragment(html);
}

export function sanitizedFragmentFromString(string, {classNameMapper, attributeMapper}) {
  const fragment = fragmentFromString(string);

  walkNodes(fragment.childNodes, (node) => {
    sanitizeNode(node);

    if (classNameMapper) {
      addClassNameByTagName(node, classNameMapper);
    }

    if (attributeMapper) {
      addAttributesByNode(node, attributeMapper);
    }
  });

  return fragment;
}
