import {Locator} from 'components/Locator';
import React, {ReactElement, useCallback} from 'react';
import {defineMessages, useIntl} from 'react-intl';

import styles from './Back.scss';

const messages = defineMessages({
  back: {
    description: '[label] Назад',
    defaultMessage: 'Back',
  },
});

export const Back = ({onBack}: {onBack: () => void}): ReactElement => {
  const intl = useIntl();
  const handleBack = useCallback(() => onBack(), [onBack]);

  return (
    <Locator id="SidebarBackButton">
      <button
        className={styles.back}
        onClick={handleBack}
        type="button"
        tabIndex={0}
        aria-label={intl.formatMessage(messages.back)}
      />
    </Locator>
  );
};
