import cn from 'classnames';
import {ConditionalWrapper} from 'components/ConditionalWrapper';
import Forward from 'components/icons/forward.jsx.svg';
import {Image} from 'components/Image';
import {useDeepLinkNavigate} from 'hooks/useDeepLink';
import React, {useCallback} from 'react';
import {FormattedNumber} from 'react-intl';
import {ContentItem} from 'types/Popup';
import {convertBackendColorToCSSValue, convertBackendGradientToCSSValue} from 'utils/styles/color';

import styles from './index.scss';

type Props = {
  row: NonNullable<ContentItem['row']>;
  onNavigate?(): void;
};

export const Row: React.FC<Props> = ({
  row: {title, subtitle, textOrder, image, url, content},
  onNavigate,
}: Props) => {
  const navigate = useDeepLinkNavigate();

  const handleRowClick = useCallback(() => {
    if (url) {
      navigate(url).then(() => {
        if (typeof onNavigate === 'function') {
          onNavigate();
        }
      });
    }
  }, [url, onNavigate, navigate]);

  const urlWrapper = useCallback(
    (children: React.ReactNode) => (
      <button type="button" className={styles.wrapperButton} onClick={handleRowClick}>
        {children}
      </button>
    ),
    [handleRowClick],
  );

  const textBlock = [
    // eslint-disable-next-line react/jsx-key
    <div className={styles.title}>{title}</div>,
    subtitle && <div className={styles.subtitle}>{subtitle}</div>,
  ].filter(Boolean);

  if (textOrder === 'reversed') {
    Array.prototype.reverse.call(textBlock);
  }

  return (
    <div className={styles.row}>
      <ConditionalWrapper condition={Boolean(url)} wrapper={urlWrapper}>
        <>
          <div className={styles.image}>
            <Image block image={image} vwFit={{xs: '24px'}} />
          </div>
          <div className={cn(styles.textBlock)}>
            {textBlock.map((line, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <React.Fragment key={index}>{line}</React.Fragment>
            ))}
          </div>
          {content?.badge?.text && (
            <div
              className={styles.badge}
              style={{
                background: convertBackendGradientToCSSValue(content.badge.backgroundGradient),
                color: convertBackendColorToCSSValue(content.badge.textColor),
              }}
            >
              {content.badge.text}
            </div>
          )}
          {content?.money && (
            <div className={styles.money}>
              <FormattedNumber
                maximumFractionDigits={2}
                style="currency"
                currency={content?.money.currency}
                value={content.money.amount}
              />
            </div>
          )}
          {url && (
            <span className={styles.forward}>
              <Forward style={{display: 'block'}} />
            </span>
          )}
        </>
      </ConditionalWrapper>
    </div>
  );
};
