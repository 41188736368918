import {useColorStyles} from 'hooks/useColorStyles';
import React from 'react';
import {GenericBadge as GenericBadgeType} from 'types/ProductBadge';

import styles from './index.scss';

type Props = Omit<GenericBadgeType['generic'], 'title'> & {
  children: GenericBadgeType['generic']['title'];
};

export function GenericBadge({color, children, background}: Props): JSX.Element {
  const style = useColorStyles({color, background});

  return (
    <div style={style} className={styles.genericBadge}>
      <span className={styles.genericTitle}>{children}</span>
    </div>
  );
}
