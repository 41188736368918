import {createRequestAction} from 'store/utils/requestActions';
import {EmptyObject} from 'types/EmptyObject';
import {FaqArticle} from 'types/FaqArticle';
import {SupportParamsMetadata} from 'types/FaqFormData';
import {FaqStructure} from 'types/FaqStructure';

export type LoadStructureRequest = {
  countryCode: string;
};

export type LoadStructureResponse = FaqStructure;

export const loadStructure = createRequestAction(
  '@faq/LOAD_STRUCTURE_REQUEST',
  '@faq/LOAD_STRUCTURE_SUCCESS',
  '@faq/LOAD_STRUCTURE_FAILURE',
)<LoadStructureRequest, FaqStructure>();

export type LoadArticleRequest = {
  id: string;
};

export type LoadArticleResponse = FaqArticle;

export const loadArticle = createRequestAction(
  '@faq/LOAD_ARTICLE_REQUEST',
  '@faq/LOAD_ARTICLE_SUCCESS',
  '@faq/LOAD_ARTICLE_FAILURE',
)<LoadArticleRequest, LoadArticleResponse>();

export type UploadFileRequest = {
  id: string;
};

export type UploadFileResponse = {
  uploadId: string;
};

export const uploadFile = createRequestAction(
  '@faq/UPLOAD_FILE_REQUEST',
  '@faq/UPLOAD_FILE_SUCCESS',
  '@faq/UPLOAD_FILE_FAILURE',
)<UploadFileRequest, UploadFileResponse>();

export type SendFormRequest = {
  name: string;
  email: string;
  message: string;
  friendlyOrderIds: string[];
  fileIds: string[];
  metadata?: SupportParamsMetadata;
};

export type SendFormResponse = EmptyObject;

export const sendForm = createRequestAction(
  '@faq/SEND_FORM_REQUEST',
  '@faq/SEND_FORM_SUCCESS',
  '@faq/SEND_FORM_FAILURE',
)<SendFormRequest, SendFormResponse>();
