import {discardableByUser} from 'helpers/discardable';
import {LOAD_SUCCESS as PRODUCTS_LOAD_SUCCESS} from './products';

const LOAD = 'productsRecent/LOAD';
const LOAD_SUCCESS = 'productsRecent/LOAD_SUCCESS';
const LOAD_FAIL = 'productsRecent/LOAD_FAIL';

const DELETE = 'productsRecent/DELETE';
const DELETE_SUCCESS = 'productsRecent/DELETE_SUCCESS';
const DELETE_FAIL = 'productsRecent/DELETE_FAIL';

const initialState = {
  items: null,
  loading: false,
  error: null,
  loaded: false,
};

export default discardableByUser(function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        error: null,
        loaded: false,
        loading: true,
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        error: null,
        items: action.result.items,
        loaded: true,
        loading: false,
      };
    case LOAD_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case DELETE:
      return {
        ...state,
        ...initialState,
        items: [],
        loaded: true,
      };
    case DELETE_FAIL:
      return {
        ...state,
        loaded: false,
      };
    case PRODUCTS_LOAD_SUCCESS:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
});

export function isProductsRecentLoaded(globalState) {
  return !!globalState.search.productsRecent.loaded;
}

export function isProductsRecentLoading(globalState) {
  return !!globalState.search.productsRecent.loading;
}

export function getProductsRecentError(globalState) {
  return globalState.search.productsRecent.error;
}

export function getProductsRecent(globalState) {
  return globalState.search.productsRecent.items;
}

export function loadProductsRecent() {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client) => {
      if (client.device.isEphemeral()) {
        return Promise.resolve({items: []});
      }
      return client.api
        .get('/search/products/recent')
        .then(({language, currency, body: {payload}}) => payload);
    },
  };
}

export function deleteProductsRecent() {
  return {
    types: [DELETE, DELETE_SUCCESS, DELETE_FAIL],
    promise: (client) =>
      client.api
        .delete('/search/products/recent')
        .then(({language, currency, body: {payload}}) => payload),
  };
}
