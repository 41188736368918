import type CryptoJS from 'crypto-js';
import CryptoWordArray from 'crypto-js/lib-typedarrays';
import CryptoMD5 from 'crypto-js/md5';
import uuid from 'uuid/v4';

import {UploadPart} from './actions';

/**
 * @see https://medium.com/front-end-weekly/refactoring-cryptojs-in-modern-ecmascript-1d4e1837c272
 */
const arrayBufferToWordArray = (arrayBuffer: ArrayBuffer): CryptoJS.lib.WordArray => {
  const uint8Array = new Uint8Array(arrayBuffer);
  const words: number[] = [];

  for (let i = 0; i < uint8Array.byteLength; i += 1) {
    // eslint-disable-next-line no-bitwise
    words[i >>> 2]! |= uint8Array[i]! << (24 - (i % 4) * 8);
  }

  return CryptoWordArray.create(words, uint8Array.byteLength);
};

export const arrayBufferToMd5 = (arrayBuffer: ArrayBuffer): string => {
  return CryptoMD5(arrayBufferToWordArray(arrayBuffer)).toString();
};

const textEncoder = new TextEncoder();
export const getUploadPartUid = (uploadPart: UploadPart): string => {
  return uuid({
    random: textEncoder.encode(`${uploadPart.url}${uploadPart.id}`),
  });
};
