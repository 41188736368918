import classnames from 'classnames/bind';
import React from 'react';
import {FormattedNumber} from 'react-intl';

import styles from './index.scss';

const cn = classnames.bind(styles);

const STAR_WIDTH = 14;
const STAR_SPACE = 4;
const STAR_COUNT = 5;

const MAX_WIDTH = STAR_WIDTH * STAR_COUNT + STAR_SPACE * (STAR_COUNT - 1);

type Props = {
  compact?: boolean;
  rating: number;
};

export const Rating = React.memo(function Rating({compact, rating}: Props) {
  const width = compact
    ? 0
    : Math.min(Math.floor(rating) * STAR_SPACE + rating * STAR_WIDTH, MAX_WIDTH);

  return (
    <div className={styles.rating}>
      <div className={cn('stars', {compact})}>
        {compact ? null : <div className={styles.starFill} style={{width: `${width}px`}} />}
      </div>
      {compact && (
        <span className={styles.text}>
          <FormattedNumber minimumFractionDigits={0} maximumFractionDigits={1} value={rating} />
        </span>
      )}
    </div>
  );
});
