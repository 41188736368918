import {ContextMenu} from 'components/ContextMenu';
import {DotsMenuButton} from 'components/DotsMenuButton';
import React from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';

const messages = defineMessages({
  menuFeedbackButton: {
    defaultMessage: 'Report review',
    description: '[label] Button for product review report',
  },
});

type MoreButtonProps = {
  onReport: () => void;
};

export function MoreButton({onReport}: MoreButtonProps): JSX.Element | null {
  const showFeedbackButton = Boolean(onReport);

  return (
    <DotsMenuButton color="gray">
      {showFeedbackButton && (
        <ContextMenu.Item onClick={onReport}>
          <FormattedMessage {...messages.menuFeedbackButton} />
        </ContextMenu.Item>
      )}
    </DotsMenuButton>
  );
}
