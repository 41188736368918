import {UploaderError, UploaderErrorType} from '../types';

export const createUploaderError = <T extends UploaderErrorType, E extends UploaderError>(
  type: T,
  params: E extends {type: T; params: infer P} ? P : never,
): E => {
  return {
    type,
    params,
  } as E;
};
