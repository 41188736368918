import {requestActionCreator} from 'store/utils/requestActions';
import {FuncAction} from 'typesafe-actions';

import {
  addCollectionToFavorites as addCollectionToFavoritesAction,
  addProductToCollection as addProductToCollectionAction,
  ChangeCollectionInFavoritesRequest,
  ChangeCollectionInFavoritesResponse,
  ChangeProductInCollectionRequest,
  ChangeProductInCollectionResponse,
  createProductCollection as createProductCollectionAction,
  CreateProductCollectionRequest,
  CreateProductCollectionResponse,
  deleteProductCollection as deleteProductCollectionAction,
  DeleteProductCollectionRequest,
  DeleteProductCollectionResponse,
  editProductsInCollections as editProductsInCollectionsAction,
  EditProductsInCollectionsRequest,
  EditProductsInCollectionsResponse,
  loadProductCollectionSelectionPopup as loadProductCollectionSelectionPopupAction,
  LoadProductCollectionSelectionPopupRequest,
  LoadProductCollectionSelectionPopupResponse,
  removeCollectionFromFavorites as removeCollectionFromFavoritesAction,
  removeProductFromCollection as removeProductFromCollectionAction,
  updateProductCollection as updateProductCollectionAction,
  UpdateProductCollectionRequest,
  UpdateProductCollectionResponse,
} from './actions';

type ChangeProductInCollectionResponseBody = {
  payload: {
    updatedPayload: ChangeProductInCollectionResponse;
  };
};

export const addProductToCollection = ({
  itemKey,
}: ChangeProductInCollectionRequest): FuncAction<Promise<ChangeProductInCollectionResponse>> =>
  requestActionCreator(addProductToCollectionAction, {itemKey}, (store, client) =>
    client.api
      .post<ChangeProductInCollectionResponseBody>('/productCollections/products/add', {
        body: {itemKey},
      })
      .then(({body}) => body.payload.updatedPayload),
  );

export const removeProductFromCollection = ({
  itemKey,
}: ChangeProductInCollectionRequest): FuncAction<Promise<ChangeProductInCollectionResponse>> =>
  requestActionCreator(removeProductFromCollectionAction, {itemKey}, (store, client) =>
    client.api
      .post<ChangeProductInCollectionResponseBody>('/productCollections/products/remove', {
        body: {itemKey},
      })
      .then(({body}) => body.payload.updatedPayload),
  );

type EditProductsInCollectionsResponseBody = {
  payload: {
    updatedPayload: EditProductsInCollectionsResponse;
  };
};

export const editProductsInCollections = ({
  updates,
}: EditProductsInCollectionsRequest): FuncAction<Promise<EditProductsInCollectionsResponse>> =>
  requestActionCreator(editProductsInCollectionsAction, {updates}, (store, client) =>
    client.api
      .post<EditProductsInCollectionsResponseBody>('/productCollections/editProductsBatch', {
        body: {updates},
      })
      .then(({body}) => body.payload.updatedPayload),
  );

type LoadProductCollectionSelectionPopupResponseBody = {
  payload: {
    collectionSelectionPopup: LoadProductCollectionSelectionPopupResponse;
  };
};

export const loadProductCollectionSelectionPopup = (
  request: LoadProductCollectionSelectionPopupRequest,
): FuncAction<Promise<LoadProductCollectionSelectionPopupResponse>> =>
  requestActionCreator(loadProductCollectionSelectionPopupAction, request, (store, client) =>
    client.api
      .post<LoadProductCollectionSelectionPopupResponseBody>(
        '/productCollections/collectionSelectionPopup/get',
        {body: request},
      )
      .then(({body}) => body.payload.collectionSelectionPopup),
  );

type CreateProductCollectionRequestBody = {
  payload: CreateProductCollectionResponse;
};

export const createProductCollection = ({
  settings,
}: CreateProductCollectionRequest): FuncAction<Promise<CreateProductCollectionResponse>> =>
  requestActionCreator(createProductCollectionAction, {settings}, (store, client) =>
    client.api
      .post<CreateProductCollectionRequestBody>('/productCollections/create', {
        body: settings,
      })
      .then(({body}) => body.payload),
  );

type UpdateProductCollectionRequestBody = {
  payload: UpdateProductCollectionResponse;
};

export const updateProductCollection = ({
  productCollectionId,
  settings,
}: UpdateProductCollectionRequest): FuncAction<Promise<UpdateProductCollectionResponse>> =>
  requestActionCreator(
    updateProductCollectionAction,
    {productCollectionId, settings},
    (store, client) =>
      client.api
        .post<UpdateProductCollectionRequestBody>(
          `/productCollections/${productCollectionId}/update`,
          {
            body: settings,
          },
        )
        .then(({body}) => body.payload),
  );

type DeleteProductCollectionRequestBody = {
  payload: DeleteProductCollectionResponse;
};

export const deleteProductCollection = ({
  productCollectionId,
}: DeleteProductCollectionRequest): FuncAction<Promise<DeleteProductCollectionResponse>> =>
  requestActionCreator(deleteProductCollectionAction, {productCollectionId}, (store, client) =>
    client.api
      .post<DeleteProductCollectionRequestBody>(`/productCollections/${productCollectionId}/delete`)
      .then(({body}) => body.payload),
  );

type ChangeCollectionInFavoritesResponseBody = {
  payload: ChangeCollectionInFavoritesResponse;
};

export const addCollectionToFavorites = ({
  ownerId,
  collectionId,
}: ChangeCollectionInFavoritesRequest): FuncAction<Promise<ChangeCollectionInFavoritesResponse>> =>
  requestActionCreator(addCollectionToFavoritesAction, {ownerId, collectionId}, (_, client) =>
    client.api
      .post<ChangeCollectionInFavoritesResponseBody>(
        `/productCollections/favorites/${ownerId}/${collectionId}/add`,
        {
          body: {ownerId},
        },
      )
      .then(({body}) => body.payload),
  );

export const removeCollectionFromFavorites = ({
  ownerId,
  collectionId,
}: ChangeCollectionInFavoritesRequest): FuncAction<Promise<ChangeCollectionInFavoritesResponse>> =>
  requestActionCreator(removeCollectionFromFavoritesAction, {ownerId, collectionId}, (_, client) =>
    client.api
      .post<ChangeCollectionInFavoritesResponseBody>(
        `/productCollections/favorites/${ownerId}/${collectionId}/remove`,
        {
          body: {ownerId},
        },
      )
      .then(({body}) => body.payload),
  );
