import PropTypes from 'prop-types';
import {ColorShape} from 'shapes/Color';
import ImageBundle from 'shapes/ImageBundle';

export const CategoryPromoLinkShape = PropTypes.shape({
  icon: ImageBundle,
  title: PropTypes.shape({
    text: PropTypes.string.isRequired,
    color: ColorShape.isRequired,
  }).isRequired,
  url: PropTypes.string.isRequired,
});

export const CategoryPromoLinksShape = PropTypes.arrayOf(CategoryPromoLinkShape);
