import {AlignmentSide, ScrollApiType} from 'components/Slider/useApi';
import {useMutableCallback} from 'hooks/useMutableCallback';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';

import {useObserverCallback} from './useObserverCallback';
import {ObserverCallback, useSliderContext} from './useSliderContext';

type ScrollRelativeIndex = (diff: number, behavior?: ScrollBehavior) => void;

export type Counter = {
  total: number;
  index: number;
  // asc sorted array
  inView: [number, ...number[]];
};

export type CounterApi = ScrollApiType & {
  scrollRelativeIndex: ScrollRelativeIndex;
  scrollNext: () => void;
  scrollPrev: () => void;
};

export type CounterOptions = {
  loop?: boolean;
};

export function useCounter({loop}: CounterOptions = {}): {
  counter: Counter | undefined;
  api: CounterApi;
} {
  const {itemsRef, api} = useSliderContext();
  const mapRef = useRef(new WeakMap<Element, IntersectionObserverEntry>());
  const [counter, setCounter] = useState<Counter | undefined>();

  const observerCallback = useCallback<ObserverCallback>(
    (entries) => {
      if (itemsRef.current?.length) {
        const total = itemsRef.current.length;
        const map = mapRef.current;
        entries.forEach((entry) => {
          map.set(entry.target, entry);
        });

        const inView: number[] = [];

        for (const item of itemsRef.current) {
          const entry = map.get(item.element);

          if (entry?.isIntersecting) {
            inView.push(item.index);
          }
        }

        inView.sort((a, b) => a - b);

        if (inView[0] != null) {
          const index = inView[0];

          setCounter({total, index, inView: inView as [number, ...number[]]});
        }
      } else {
        setCounter(undefined);
      }
    },
    [itemsRef],
  );

  useObserverCallback(observerCallback);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (itemsRef.current && counter && itemsRef.current.length !== counter.total) {
      setCounter({...counter, total: itemsRef.current.length});
    }
  });

  const scrollRelativeIndex: ScrollRelativeIndex = useMutableCallback((diff, behavior) => {
    if (counter) {
      const fromIndex = diff > 0 ? counter.inView[counter.inView.length - 1]! : counter.inView[0];

      const index = loop
        ? (fromIndex + counter.total + diff) % counter.total
        : Math.max(Math.min(fromIndex + diff, counter.total - 1), 0);

      const align = diff < 0 ? AlignmentSide.START : AlignmentSide.END;

      api.scrollToIndex(index, align, behavior);
    }
  });

  const counterApi: CounterApi = useMemo(
    () => ({
      ...api,
      scrollRelativeIndex,
      scrollPrev: () => scrollRelativeIndex(-1),
      scrollNext: () => scrollRelativeIndex(1),
    }),
    [api, scrollRelativeIndex],
  );

  return {counter, api: counterApi};
}
