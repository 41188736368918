import {ScopeConfig} from 'helpers/ApiClient/Scope/ScopeConfig';
import {getSearchData, searchPostfix} from 'utils/search';
import {ParamsData, RouteParams} from 'utils/search/pattern';
import {SearchData} from 'utils/search/types';

type SearchSlug = 'search' | 'bests';

export function searchUrl(
  scope: ScopeConfig,
  lang: string,
  searchData: SearchData,
  slug: SearchSlug = 'search',
): string {
  const url = [scope.pathPrefix, lang, slug, searchPostfix(searchData)].filter(Boolean).join('/');

  return `/${url}`;
}

export function searchUrlByParams(
  scope: ScopeConfig,
  lang: string,
  params: RouteParams | ParamsData,
  slug: SearchSlug = 'search',
): string {
  return searchUrl(scope, lang, getSearchData(params), slug);
}

export function searchCategoryUrl(
  scope: ScopeConfig,
  lang: string,
  categoryId: string,
  slug: SearchSlug = 'search',
): string {
  return searchUrlByParams(scope, lang, {parsed: true, categoryId}, slug);
}
