import classnames from 'classnames/bind';
import {Locator as OldLocator} from 'components/Locator';
import {VisuallyHidden} from 'components/VisuallyHidden';
import {createLocator, Locator, Mark} from 'create-locator';
import React, {ChangeEvent} from 'react';

import styles from './index.scss';

const cn = classnames.bind(styles);

export type SwitchLocator = Locator<void>;

type Props = {
  id: string;
  onToggle: (e: ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
  disabled?: boolean;
  name?: string;
  value?: string;
  size?: 'normal' | 'small';
  'data-testid'?: string;
  'data-test-type'?: string;
} & Partial<Mark<SwitchLocator>>;

export function Switch(props: Props): JSX.Element {
  const {
    id,
    onToggle,
    checked = false,
    disabled = false,
    name,
    value,
    size = 'normal',
    'data-testid': testId,
    'data-test-type': testType,
  } = props;

  const locator = createLocator(props);

  return (
    <OldLocator id={testId} type={testType}>
      <div className={cn('switch', `size-${size}`, {disabled, checked})}>
        <label className={styles.label} htmlFor={id} {...locator()}>
          <VisuallyHidden>
            <OldLocator id="PopupSwitcherInput" type={testType}>
              <input
                type="checkbox"
                id={id}
                checked={checked}
                onChange={onToggle}
                disabled={disabled}
                name={name}
                value={value}
              />
            </OldLocator>
          </VisuallyHidden>
          <span className={styles.thumb} />
        </label>
      </div>
    </OldLocator>
  );
}
