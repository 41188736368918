import {useAnalytics} from 'hooks/useAnalytics';
import {useDeepLinkNavigate} from 'hooks/useDeepLink';
import {useDeviceVar} from 'hooks/useDeviceVars';
import {useCallback, useState} from 'react';
import {CouponSelection} from 'types/Cart';
import {CouponCard as CouponCardType} from 'types/CouponCard';

export type CouponApplyOptions = {
  coupon: CouponCardType;
  isCartLoaded: boolean;
  loadCart(couponSelection: CouponSelection): Promise<unknown>;
  updateCart(couponSelection: CouponSelection, noCartItemSelection?: boolean): Promise<unknown>;
  loadCouponsFull(): Promise<unknown>;
  loadCouponCards(): Promise<unknown>;
  onAfterApplyDeeplinkFollowed?: () => void;
  followDeepLink?: boolean;
  source?: string;
};

export function useCouponApply({
  coupon: {applied, id, afterApplyDeeplink},
  isCartLoaded,
  loadCart,
  updateCart,
  loadCouponCards,
  loadCouponsFull,
  followDeepLink,
  onAfterApplyDeeplinkFollowed,
  source,
}: CouponApplyOptions): [handleApply: () => Promise<void>, loading: boolean] {
  const analytics = useAnalytics();
  const navigate = useDeepLinkNavigate();

  const [loading, setLoading] = useState<boolean>(false);

  const noCartItemSelection = useDeviceVar('backendCartItemsSelection')?.enabled || false;

  const handleApply = useCallback(() => {
    setLoading(true);
    if (source) {
      analytics.sendEvent({
        type: applied ? 'couponDeselect' : 'couponSelect',
        payload: {
          couponId: id,
          source,
          ...(!applied ? {deeplink: afterApplyDeeplink} : {}),
        },
      });
    }
    return (isCartLoaded ? updateCart : loadCart)(
      applied
        ? {none: {}}
        : {
            byId: {
              couponId: id,
            },
          },
      noCartItemSelection,
    )
      .then(() => Promise.all([loadCouponCards(), loadCouponsFull()]))
      .finally(() => setLoading(false))
      .then(() => {
        if (!applied && followDeepLink && afterApplyDeeplink) {
          navigate(afterApplyDeeplink);
          onAfterApplyDeeplinkFollowed?.();
        }
      });
  }, [
    source,
    isCartLoaded,
    updateCart,
    loadCart,
    applied,
    id,
    noCartItemSelection,
    analytics,
    afterApplyDeeplink,
    loadCouponCards,
    loadCouponsFull,
    followDeepLink,
    navigate,
    onAfterApplyDeeplinkFollowed,
  ]);

  return [handleApply, loading];
}
