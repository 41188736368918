import {ApiClient} from 'helpers/ApiClient';
import {useLayoutEffect, useState} from 'react';
import {createPayCancellationError} from 'store/modules/groupedCart/utils';
import {CheckoutSessionOrderGroup} from 'types/CheckoutSession';
import {ApplePayPaymentMethodPayload} from 'types/PaymentMethod';
import {Price} from 'types/Price';
import {memoizeLastShallowEqual} from 'utils/memoize';

export type PaymentRequestData = {
  session: ApplePaySession;
  price: Price;
  merchantId: string;
};

export const APPLE_PAY_API_VERSION = 3;

export function createApplePayPaymentRequest(
  paymentPayload: ApplePayPaymentMethodPayload,
  price: Price,
): ApplePayJS.ApplePayPaymentRequest {
  return {
    total: {
      label: 'Joom',
      amount: price.amount.toString(),
    },
    countryCode: paymentPayload.countryCode,
    currencyCode: price.currency,
    supportedNetworks: paymentPayload.supportedNetworks,
    merchantCapabilities: ['supports3DS'],
  };
}

export function makeApplePayPayment({
  client,
  session,
  orderGroup,
  merchantId,
}: {
  client: ApiClient;
  session: ApplePaySession;
  orderGroup: CheckoutSessionOrderGroup;
  merchantId: string;
}): Promise<ApplePayJS.ApplePayPayment> {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise<ApplePayJS.ApplePayPayment>(async (resolve, reject) => {
    try {
      const validateMerchantPromise = new Promise<ApplePayJS.ApplePayValidateMerchantEvent>(
        (resolve) => {
          session.onvalidatemerchant = resolve;
        },
      );

      const paymentAuthorizedPromise = new Promise<ApplePayJS.ApplePayPaymentAuthorizedEvent>(
        (resolve) => {
          session.onpaymentauthorized = resolve;
        },
      );

      session.oncancel = () => reject(createPayCancellationError());

      session.begin();

      const validateMerchantEvent = await validateMerchantPromise;

      const {
        body: {payload: merchantSession},
      } = orderGroup.payhubParams
        ? await client.payhubApi.post<{payload: unknown}>('/applePay/createPaymentSession', {
            body: {
              verificationUrl: validateMerchantEvent.validationURL,
              merchantId,
              clientToken: orderGroup.payhubParams?.clientToken,
              domain: client.scope.hostname,
            },
          })
        : await client.paymentApi.post<{payload: unknown}>('/applePay/createPaymentSession', {
            body: {
              verificationUrl: validateMerchantEvent.validationURL,
              merchantId,
              orderGroupId: orderGroup.id,
              domain: client.scope.hostname,
            },
          });

      session.completeMerchantValidation(merchantSession);

      const paymentAuthorizedEvent = await paymentAuthorizedPromise;

      resolve(paymentAuthorizedEvent.payment);
    } catch (error) {
      reject(error);
      session.abort();
    }
  });
}

export const getIsApplePaySupported = memoizeLastShallowEqual(() =>
  Boolean(
    // https check is to prevent crashing on localhost
    window.location.protocol === 'https:' &&
      typeof ApplePaySession !== 'undefined' &&
      ApplePaySession.canMakePayments(),
  ),
);

export function useIsApplePaySupported(): boolean | undefined {
  const [isSupported, setIsSupported] = useState<boolean | undefined>();

  useLayoutEffect(() => {
    setIsSupported(getIsApplePaySupported());
  }, []);

  return isSupported;
}
