import ClientApi from '@joomcode/joom-event-types';
import {DotLoader, DotLoaderStyle} from 'components/DotLoader';
import ErrorMessage from 'components/ErrorMessage';
import {Image} from 'components/Image';
import {useSelectionPopupData} from 'components/ProductCollections/AddProductToCollectionsPopup/useSelectionPopupData';
import {
  EditProductCollectionSaveHandler,
  useEditProductCollectionPopup,
} from 'components/ProductCollections/EditProductCollectionPopup';
import {ScrollLoader} from 'components/ScrollLoader';
import {Button} from 'components/UIKit/Button';
import {Icon} from 'components/UIKit/Icon';
import {Popup} from 'components/UIKit/Popup';
import {Content} from 'components/UIKit/Popup/Content';
import {Footer} from 'components/UIKit/Popup/Footer';
import {Header} from 'components/UIKit/Popup/Header';
import {PopupType, PopupViewProps, usePopup} from 'components/UIKit/Popup/PopupProvider';
import {useDispatch} from 'hooks/redux';
import {useSendAnalytics, useSendAnalyticsOpenClose} from 'hooks/useAnalytics';
import {useForceUpdate} from 'hooks/useForceUpdate';
import {useOpenProductCollectionToast} from 'hooks/useOpenProductCollectionToast';
import {useUserAdulthood} from 'hooks/useUserAdulthood';
import React, {ReactElement, useCallback, useMemo, useState} from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';
import {editProductsInCollections} from 'store/modules/productCollections';
import {CommonProduct, getCommonProductId} from 'types/CommonProduct';
import {ProductCollection} from 'types/ProductCollection';
import {
  getProductCollectionLiteImageRestricted,
  ProductCollectionLite,
} from 'types/ProductCollection/ProductCollectionLite';
import {ProductCollectionUpdateList} from 'types/ProductCollection/ProductCollectionUpdateList';

import styles from './index.scss';

const messages = defineMessages({
  title: {
    defaultMessage: 'Choose a collection',
    description: '[title] Заголовок попапа выбора подборок',
  },
  moveTitle: {
    defaultMessage: 'Move to',
    description: '[title] Заголовок попапа выбора подборок',
  },
  createCollection: {
    defaultMessage: 'Create a new collection',
    description: 'Текст кнопки создания новой подборки',
  },
  productsCount: {
    defaultMessage: `{productsCount, plural,
                      one {# product}
                      other {# products}}`,
    description: 'Количество товаров в подборке',
  },
  saveButton: {
    defaultMessage: 'Save',
    description: '[button] Текст в кнопке сохранения товара в подборках',
  },
});

const Collection = React.memo(
  ({
    collection: {id, productsImages, title, productsCount},
    selected,
    onChange,
    disabled,
  }: {
    collection: ProductCollectionLite;
    selected: boolean;
    onChange?(collectionId: string): void;
    disabled?: boolean;
  }) => {
    const isUserAdult = useUserAdulthood();
    const handleClick = useCallback(() => onChange?.(id), [id, onChange]);
    const image = productsImages?.[0];
    const isRestricted = Boolean(
      image && getProductCollectionLiteImageRestricted(image, isUserAdult),
    );

    return (
      <button type="button" className={styles.collection} onClick={handleClick} disabled={disabled}>
        <div className={styles.collectionImage}>
          {image ? (
            <>
              <Image
                blurred={isRestricted}
                multiply
                image={image}
                width="100%"
                height="100%"
                sizes="64px"
                cover
              />
              {isRestricted && (
                <div className={styles.collectionRestrictedIcon}>
                  <Icon type="mono" name="eye-off-filled-24" />
                </div>
              )}
            </>
          ) : (
            <div className={styles.collectionEmptyIcon}>
              <Icon name="empty-filled-24" type="mono" />
            </div>
          )}
          {selected && (
            <div className={styles.collectionSelectedIcon}>
              <Icon name="check-circle-filled-24" type="mono" />
            </div>
          )}
        </div>
        <div className={styles.collectionTitle}>{title}</div>
        {productsCount ? (
          <div className={styles.collectionInfo}>
            <FormattedMessage {...messages.productsCount} values={{productsCount}} />
          </div>
        ) : null}
      </button>
    );
  },
);

type Props = {
  areProductsMoving?: boolean;
  currentCollection?: ProductCollection;
  products: CommonProduct[];
  source: ClientApi.AnalyticsEventProductCollectionSelectorOpen['payload']['selectorOpenSource'];
  onSave?(): void;
};

export function AddProductToCollectionsPopup({
  areProductsMoving = false,
  currentCollection,
  products,
  source,
  onSave,
  onBack,
  onClose,
}: Props & Pick<PopupViewProps, 'onClose' | 'onBack'>): ReactElement {
  const dispatch = useDispatch();
  const isUserAdult = useUserAdulthood();
  const openProductCollectionToast = useOpenProductCollectionToast();
  const sendEvent = useSendAnalytics();
  const {data, loading, error, loadMore} = useSelectionPopupData(
    areProductsMoving || products.length !== 1 ? undefined : getCommonProductId(products[0]!),
  );
  const currentCollectionId = currentCollection?.header?.productCollectionLite?.id;
  const sortedProductCollections = useMemo(() => {
    const list = data?.productCollections ?? [];

    if (currentCollection) {
      const currentCollectionFromList = list.find(
        ({productCollectionLite}) => productCollectionLite.id === currentCollectionId,
      );

      if (currentCollectionFromList) {
        const nextList = list.filter((collection) => collection !== currentCollectionFromList);

        nextList.unshift(currentCollectionFromList);

        return nextList;
      }
    }

    return list;
  }, [currentCollection, currentCollectionId, data?.productCollections]);

  const [changesSet] = useState(() => new Set<string>());

  const handleCreateCollection: EditProductCollectionSaveHandler = useCallback(
    async ({productCollection}) => {
      const collectionLite = productCollection.header.productCollectionLite;
      const updates = products.map((product) => ({
        itemKey: {
          productId: getCommonProductId(product),
        },
        productCollectionId: collectionLite.id,
        isRemoving: false,
      }));
      await dispatch(editProductsInCollections({updates}));

      if (areProductsMoving) {
        openProductCollectionToast({
          type: 'moveProductsToCollection',
          currentCollection,
          productCollections: [collectionLite],
          products,
          isRemoving: false,
          isRemovingFromCurrentCollection: false,
        });
      } else if (products[0]) {
        openProductCollectionToast({
          type: 'productInCollection',
          productCollections: [collectionLite],
          product: products[0],
          isUserAdult,
          isRemoving: false,
        });
      }

      onSave?.();

      if (onBack) onBack();
      else onClose();
    },
    [
      areProductsMoving,
      currentCollection,
      dispatch,
      isUserAdult,
      onBack,
      onClose,
      onSave,
      openProductCollectionToast,
      products,
    ],
  );

  const createCollectionPopup = useEditProductCollectionPopup({
    onSave: handleCreateCollection,
  });

  const handleCreateCollectionClick = useCallback(() => {
    createCollectionPopup.open();

    sendEvent('productCollectionCreateClick', {createClickSource: 'productCollectionsSelector'});
  }, [createCollectionPopup, sendEvent]);

  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitError, setSubmitError] = useState<unknown>();

  const handleSave = useCallback(async () => {
    if (!data) {
      return;
    }

    sendEvent('productCollectionSelectorSaveClick', {selectorOpenSource: source});

    setSubmitLoading(true);
    setSubmitError(undefined);

    try {
      const updates: ProductCollectionUpdateList = [];
      const updatedCollections: Array<{
        productCollectionLite: ProductCollectionLite;
        isRemoving: boolean;
      }> = [];

      data.productCollections.forEach(
        ({productCollectionLite, productCollectionLite: {id}, selection: {selected}}) => {
          if (changesSet.has(id)) {
            const isRemoving = selected || id === currentCollectionId;

            products.forEach((product) => {
              updates.push({
                productCollectionId: id,
                itemKey: {
                  productId: getCommonProductId(product),
                },
                isRemoving,
              });
            });
            updatedCollections.push({
              productCollectionLite,
              isRemoving,
            });
          }
        },
      );

      await dispatch(editProductsInCollections({updates}));

      const isRemoving = updates.every(({isRemoving}) => isRemoving);
      const productCollections = updatedCollections
        .filter((collection) => collection.isRemoving === isRemoving)
        .map(({productCollectionLite}) => productCollectionLite);

      if (areProductsMoving) {
        const isRemovingFromCurrentCollection = updatedCollections.some(
          ({productCollectionLite, isRemoving}) =>
            productCollectionLite.id === currentCollectionId && isRemoving,
        );

        openProductCollectionToast({
          type: 'moveProductsToCollection',
          currentCollection,
          productCollections,
          products,
          isRemoving,
          isRemovingFromCurrentCollection,
        });
      } else if (products[0]) {
        openProductCollectionToast({
          type: 'productInCollection',
          productCollections,
          product: products[0],
          isUserAdult,
          isRemoving,
        });
      }

      onSave?.();

      if (onBack) onBack();
      else onClose();
    } catch (error) {
      setSubmitError(error);
    } finally {
      setSubmitLoading(false);
    }
  }, [
    areProductsMoving,
    changesSet,
    currentCollection,
    currentCollectionId,
    data,
    dispatch,
    isUserAdult,
    onBack,
    onClose,
    onSave,
    openProductCollectionToast,
    products,
    sendEvent,
    source,
  ]);

  const forceUpdate = useForceUpdate();
  const toggleChange = useCallback(
    (collectionId: string) => {
      if (!submitLoading) {
        if (changesSet.has(collectionId)) {
          changesSet.delete(collectionId);
        } else {
          changesSet.add(collectionId);
        }
        forceUpdate();
      }
    },
    [changesSet, forceUpdate, submitLoading],
  );

  useSendAnalyticsOpenClose(
    () => ({
      type: 'productCollectionSelectorOpen',
      payload: {
        selectorOpenSource: source,
      },
    }),
    ({sinceOpenMs}) => ({
      type: 'productCollectionSelectorClose',
      payload: {
        selectorOpenSource: source,
        sinceOpenMs,
      },
    }),
  );

  return (
    <Popup width="550px">
      <Header onClose={onClose} onBack={onBack}>
        {areProductsMoving ? (
          <FormattedMessage {...messages.moveTitle} />
        ) : (
          <FormattedMessage {...messages.title} />
        )}
      </Header>
      <Content>
        {Boolean(error) && (
          <div className={styles.error}>
            <ErrorMessage error={error} noRequestId />
          </div>
        )}
        {loading && (
          <div className={styles.loader}>
            <DotLoader style={DotLoaderStyle.DARK} />
          </div>
        )}
        {data && (
          <ScrollLoader onLoad={loadMore} autoLoadingImmediately>
            {data.userSettings.canCreateNew && (
              <button
                type="button"
                className={styles.newCollectionButton}
                onClick={handleCreateCollectionClick}
                disabled={submitLoading}
              >
                <div className={styles.newCollectionIcon}>
                  <Icon name="plus-linear-24" type="mono" />
                </div>
                <FormattedMessage {...messages.createCollection} />
              </button>
            )}
            {sortedProductCollections.map(
              ({productCollectionLite: collection, selection: {selected}}) => (
                <Collection
                  key={collection.id}
                  collection={collection}
                  selected={
                    // eslint-disable-next-line no-nested-ternary
                    collection.id === currentCollectionId
                      ? !changesSet.has(collection.id)
                      : changesSet.has(collection.id)
                        ? !selected
                        : selected
                  }
                  onChange={toggleChange}
                  disabled={submitLoading}
                />
              ),
            )}
          </ScrollLoader>
        )}
      </Content>
      {data && (
        <Footer marginTop="24px">
          <div className={styles.submitButton}>
            <Button
              tag="button"
              type="button"
              color="primary"
              onClick={handleSave}
              disabled={changesSet.size === 0 || submitLoading}
            >
              <FormattedMessage {...messages.saveButton} />
            </Button>
            {Boolean(submitError) && (
              <div className={styles.submitError}>
                <ErrorMessage error={error} />
              </div>
            )}
          </div>
        </Footer>
      )}
    </Popup>
  );
}

export function useAddProductToCollectionsPopup({
  areProductsMoving,
  currentCollection,
  products,
  source,
  onSave,
  onBack,
  onClose,
}: Props & {
  onBack?(): void;
  onClose?(): void;
}): PopupType {
  const render = useCallback(
    ({onClose, onBack}: PopupViewProps) => (
      <AddProductToCollectionsPopup
        {...{
          areProductsMoving,
          currentCollection,
          products,
          source,
          onSave,
          onBack,
          onClose,
        }}
      />
    ),
    [areProductsMoving, currentCollection, onSave, products, source],
  );

  return usePopup({render, onBack, onClose});
}
