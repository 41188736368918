import {CouponButton as CouponComponent} from 'components/ClientPopup/CouponButton';
import {connect} from 'react-redux';
import {loadCouponCards} from 'store/modules/couponCards';
import {isCartLoaded, loadCart, updateCart} from 'store/modules/groupedCart';
import {getCurrentPopup} from 'store/modules/popup/selectors';
import {RootState} from 'store/rootReducer';

const mapStateToProps = (state: RootState) => ({
  eventParams: getCurrentPopup(state)?.eventParams,
  isCartLoaded: isCartLoaded(state),
});

const mapDispatchToProps = {
  updateCart,
  loadCart,
  loadCouponCards,
};

export const CouponButton = connect(mapStateToProps, mapDispatchToProps)(CouponComponent);
