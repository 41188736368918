import PropTypes from 'prop-types';
import Price from './Price';

export const TextValue = PropTypes.shape({
  text: PropTypes.string.isRequired,
});

export const PriceValue = PropTypes.shape({
  price: Price.isRequired,
});

export const CartPricingValueShape = PropTypes.oneOfType([TextValue, PriceValue]);
