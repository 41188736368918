import {PopupView} from 'components/UIKit/Popup/PopupProvider';
import {useSelector} from 'hooks/redux';
import {useDeviceVar, useDeviceVars} from 'hooks/useDeviceVars';
import {usePopupInQueue} from 'hooks/usePopupInQueue';
import {useRoute} from 'hooks/useRoute';
import {getTrackingMarquesData} from 'store/modules/tracking';
import {Falsy} from 'types/utility';
import {hasTrackingSourceInList} from 'utils/hasTrackingSourceInList';

function useUtmSourceHiddenList(): string[] | undefined {
  const route = useRoute();
  const {hideWelcomePopupsByTrackingSource} = useDeviceVars();

  return (
    hideWelcomePopupsByTrackingSource?.[route.name] || hideWelcomePopupsByTrackingSource?.['*']
  );
}

function useIsHiddenByUtm(): boolean {
  const tracking = useSelector(getTrackingMarquesData);
  const utmSourceHiddenList = useUtmSourceHiddenList();

  return hasTrackingSourceInList(tracking, utmSourceHiddenList);
}

export function useWelcomePopup(popup: PopupView | Falsy): ReturnType<typeof usePopupInQueue> {
  const isHiddenByUtm = useIsHiddenByUtm();
  const isDisabled = useDeviceVar('welcomePopupsDisabled');

  return usePopupInQueue(!isDisabled && !isHiddenByUtm && popup);
}
