import cn from 'classnames';
import {AnyLink} from 'components/AnyLink';
import {Locator} from 'components/Locator';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import {Header as HeaderType} from 'types/ContentList/common';

import styles from './index.scss';

type Props = {
  center?: boolean;
  header: HeaderType;
  titleRef?: React.Ref<HTMLAnchorElement>;
  linkRef?: React.Ref<HTMLAnchorElement>;
  onActionClick?: () => void;
};

export const Header = React.memo(function Header({
  center = false,
  header: {action, title, url},
  titleRef,
  linkRef,
  onActionClick,
}: Props): React.ReactElement {
  const hasAction = Boolean(url);

  return (
    <Locator id="ContentListHeader">
      <div className={cn(styles.header, {[styles.center!]: center && !hasAction})}>
        {hasAction ? (
          <>
            <AnyLink
              className={styles.title}
              innerRef={titleRef}
              onClick={onActionClick}
              to={url}
              testId="ContentListHeader.Title"
            >
              {title}
            </AnyLink>
            <AnyLink
              className={styles.action}
              innerRef={linkRef}
              onClick={onActionClick}
              to={url}
              testId="ContentListAllProductsLink"
            >
              {action || (
                <FormattedMessage
                  defaultMessage="All products"
                  description="Default action text for ContentList header"
                />
              )}
            </AnyLink>
          </>
        ) : (
          <Locator id="ContentListHeader.Title">
            <div className={styles.title}>{title}</div>
          </Locator>
        )}
      </div>
    </Locator>
  );
});
