import {useDeviceVars} from 'hooks/useDeviceVars';
import {useMatchMedia, useMatchMediaBreakpointDown} from 'hooks/useMatchMedia';
import {useIsMobileOrTablet} from 'hooks/useUserAgent';

export function useEnlargedMediaMWeb(): boolean {
  const {enlargeMediaInReviewsMweb = false} = useDeviceVars();
  const mobileOrTablet = useIsMobileOrTablet();
  const smMediaQuery = useMatchMediaBreakpointDown('sm');
  const orientationLandscape = useMatchMedia('(orientation: landscape)');

  return (
    enlargeMediaInReviewsMweb &&
    (smMediaQuery?.matches ?? mobileOrTablet) &&
    !orientationLandscape?.matches
  );
}
