import React, {Component, ReactElement} from 'react';
import swipeJS, {SwipeInstance, SwipeOptions} from 'swipe-js-iso';

import styles from './Swipe.scss';

type ItemMeta = {
  index: number;
  active: boolean;
};

type Props<T> = {
  items: T[];
  render: (item: T, meta: ItemMeta) => ReactElement;
  renderLast?: (meta: ItemMeta) => ReactElement;
  childClassName?: string;
  className?: string;
  fullscreen?: boolean;
  swipeOptions?: SwipeOptions;
  children?: never;
};

type State = {
  currentSlide: number;
};

export class Swipe<T> extends Component<Props<T>, State> {
  static defaultProps = {
    childClassName: '',
    className: '',
    fullscreen: false,
    swipeOptions: null,
  };

  // eslint-disable-next-line react/state-in-constructor
  override state = {
    // eslint-disable-next-line react/destructuring-assignment
    currentSlide: this.props.swipeOptions?.startSlide ?? 0,
  };

  override componentDidMount(): void {
    const {swipeOptions} = this.props;

    this.swipe =
      this.element &&
      swipeJS(this.element, {
        ...swipeOptions,
        callback: (index, elem) => {
          swipeOptions?.callback?.(this.is2SlidesContinuous ? index % 2 : index, elem);

          this.handleChange();
        },
        transitionEnd: (index, elem) =>
          swipeOptions?.transitionEnd?.(this.is2SlidesContinuous ? index % 2 : index, elem),
      });
  }

  override componentDidUpdate(): void {
    this.swipe?.setup();
  }

  override componentWillUnmount(): void {
    if (this.swipe) {
      this.swipe.kill();
      this.swipe = null;
    }
  }

  private handleChange(): void {
    const {swipeOptions} = this.props;

    this.setState({
      currentSlide: this.swipe?.getPos() ?? swipeOptions?.startSlide ?? 0,
    });
  }

  getPos(): number {
    const {swipeOptions} = this.props;
    return this.swipe ? this.swipe.getPos() % this.getNumSlides() : swipeOptions?.startSlide ?? 0;
  }

  getNumItems(): number {
    const {items, renderLast} = this.props;

    if (!renderLast) {
      return items.length;
    }

    return items.length + 1;
  }

  getNumSlides(): number {
    return this.is2SlidesContinuous ? 2 : this.swipe?.getNumSlides() ?? this.getNumItems();
  }

  /**
   original `swipe` has special case for 2 slides with continuous option, but `swipe-js-iso` hasn't;
   without this case swiper can't realize smooth switching between 2 continuous slides;
   therefore need to write this logic yourself - duplicate slides, but change indexes, as if didn't it
   */
  get is2SlidesContinuous(): boolean {
    const {items, swipeOptions} = this.props;
    return Boolean(items.length === 2 && swipeOptions?.continuous);
  }

  private setRef = (element: HTMLDivElement | null): void => {
    this.element = element;
  };

  // eslint-disable-next-line react/sort-comp
  private swipe: SwipeInstance | null = null;

  private element: HTMLDivElement | null = null;

  next(): void {
    if (this.swipe) {
      this.swipe.next();
    }
  }

  prev(): void {
    if (this.swipe) {
      this.swipe.prev();
    }
  }

  slide(to: number, speed?: number): void {
    if (this.swipe) {
      this.swipe.slide(to, speed);
    }
  }

  createSlide(
    isDuplicate = false,
    index: number,
    render: (meta: ItemMeta) => ReactElement,
  ): ReactElement {
    const {childClassName} = this.props;
    const {currentSlide} = this.state;
    const numItems = this.getNumItems();
    const realIndex = isDuplicate ? numItems + index : index;
    const active = realIndex === currentSlide;
    const child = render({index, active});

    return (
      <child.type
        {...child.props}
        key={child.props.key || `slide-${realIndex}`}
        className={`${styles.child} ${childClassName || child.props.className || ''}`}
      />
    );
  }

  createSlides(isDuplicate = false): ReactElement[] {
    const {items, render, renderLast} = this.props;
    const result = items.map((item, index) =>
      this.createSlide(isDuplicate, index, (meta) => render(item, meta)),
    );

    if (renderLast) {
      result.push(this.createSlide(isDuplicate, items.length, renderLast));
    }

    return result;
  }

  override render(): ReactElement {
    const {className, fullscreen} = this.props;

    return (
      <div
        ref={this.setRef}
        className={`${styles.swipe} ${fullscreen ? styles.fullscreen : ''} ${className}`}
        dir="ltr"
      >
        <div className={styles.inner}>
          {this.createSlides()}
          {this.is2SlidesContinuous && this.createSlides(true)}
        </div>
      </div>
    );
  }
}
