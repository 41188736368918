import {ContentListPopupBase, Props} from 'components/ContentListPopup';
import {PopupViewProps} from 'components/UIKit/Popup/PopupProvider';
import {useSelector, useStore} from 'hooks/redux';
import {useAnalytics} from 'hooks/useAnalytics';
import {useDispatchAction} from 'hooks/useDispatch';
import {useEffectOnce} from 'hooks/useEffectOnce';
import React, {useEffect} from 'react';
import {batch} from 'react-redux';
import {setViewedCouponCard} from 'store/modules/couponCards';
import {getCouponCards} from 'store/modules/couponCards/selectors';
import {loadCouponsFull} from 'store/modules/couponsFull';
import {
  getCouponsFull,
  getCouponsFullError,
  isCouponsFullLoading,
} from 'store/modules/couponsFull/selectors';

export const CouponsPopupBase = (props: Props & PopupViewProps): JSX.Element => {
  const store = useStore();

  const analytics = useAnalytics();
  const load = useDispatchAction(loadCouponsFull);

  const contentList = useSelector(getCouponsFull);
  const loading = useSelector(isCouponsFullLoading);
  const error = useSelector(getCouponsFullError);

  useEffectOnce(() => {
    analytics.sendEvent({type: 'couponSelectionOpen'});

    return () => {
      analytics.sendEvent({type: 'couponSelectionClose'});
    };
  });

  useEffectOnce(() => {
    const couponCards = getCouponCards(store.getState());

    batch(() => {
      couponCards?.forEach((coupon) => {
        store.dispatch(setViewedCouponCard(coupon.id));
      });
    });
  });

  useEffect(() => {
    load();
  }, [load]);

  return (
    <ContentListPopupBase contentList={contentList} loading={loading} error={error} {...props} />
  );
};
