import cn from 'classnames';
import {useCouponApply} from 'components/CouponCard/useCouponApply';
import Info from 'components/icons/info.jsx.svg';
import {Image} from 'components/Image';
import {Locator} from 'components/Locator';
import {Button} from 'components/UIKit/Button';
import {useDeepLinkNavigate} from 'hooks/useDeepLink';
import {useDeviceVar} from 'hooks/useDeviceVars';
import {useMatchMediaBreakpointDown} from 'hooks/useMatchMedia';
import React, {useCallback} from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';
import {CouponSelection} from 'types/Cart';
import {CouponCard as CouponCardType} from 'types/CouponCard';

import {Background} from '../ContentList/Background';
import CheckCircle from './checkCircle.jsx.svg';
import styles from './index.scss';
import Time from './time.jsx.svg';

export type Props = {
  content: CouponCardType;
  isCartLoaded: boolean;
  loadCart(couponSelection: CouponSelection): Promise<unknown>;
  updateCart(couponSelection: CouponSelection): Promise<unknown>;
  loadCouponsFull(): Promise<unknown>;
  loadCouponCards(): Promise<unknown>;
  onAfterApplyDeeplinkFollowed?: () => void;
  big?: boolean;
  showInfoButton?: boolean;
  source?: string;
};

const messages = defineMessages({
  apply: {
    description: 'Action button label',
    defaultMessage: 'Apply',
  },
  applied: {
    description: 'Action button label',
    defaultMessage: 'Applied',
  },
  appliedPlace: {
    description: 'Applied coupon caption, after label "Applied"',
    defaultMessage: 'in the cart',
  },
});

const calculateImageSize = (isBig: boolean, isMobile: boolean): string => {
  if (isBig) {
    if (isMobile) {
      return '32px';
    }

    return '50px';
  }

  return '24px';
};

export function CouponCard({
  isCartLoaded,
  loadCart,
  updateCart,
  loadCouponCards,
  loadCouponsFull,
  onAfterApplyDeeplinkFollowed,
  content,
  content: {id, applied, background, texts, primaryIcon, deeplink, appearance},
  big = false,
  showInfoButton = true,
  source,
}: Props): React.ReactElement {
  const mobile = useMatchMediaBreakpointDown('xs');
  const redesign = useDeviceVar('webCouponRedesign2023');

  const navigate = useDeepLinkNavigate();

  const handleInfoClick = useCallback(() => {
    if (showInfoButton) {
      navigate(deeplink);
    }
  }, [deeplink, navigate, showInfoButton]);

  const [handleApply, isUpdating] = useCouponApply({
    coupon: content,
    isCartLoaded,
    loadCart,
    updateCart,
    loadCouponCards,
    loadCouponsFull,
    followDeepLink: true,
    onAfterApplyDeeplinkFollowed,
    source,
  });

  return (
    <Locator id="CouponCard" couponId={id}>
      <div
        className={cn(styles.coupon, {
          [styles.updating!]: isUpdating,
          [styles.hasApplyButton!]: appearance.showApplyButton,
          [styles.isBig!]: big,
        })}
      >
        <Background background={background} />

        <div className={styles.inner}>
          <button
            className={cn(styles.details, {[styles.clickable!]: showInfoButton})}
            type="button"
            onClick={handleInfoClick}
          >
            <div className={styles.imageContainer}>
              <Image
                image={primaryIcon}
                vwFit={{xs: calculateImageSize(big, !!mobile?.matches)}}
                contain
              />
            </div>

            <div className={cn(styles.title)}>
              <span className={cn(styles.text, {[styles.isSmall!]: texts.title.length >= 9})}>
                {texts.title}
              </span>
              {showInfoButton && (
                <span className={styles.infoIcon}>
                  <Info width={16} height={16} />
                </span>
              )}
            </div>
            <div className={styles.subtitle}>{texts.subtitle}</div>
            <div className={styles.timeText}>
              <Time /> {texts.timeText}
            </div>
          </button>

          <div className={styles.buttonContainer}>
            <div className={styles.divider} />
            {applied ? (
              <>
                {redesign ? (
                  <Locator id="CouponCardSelectionStatus" enabled={applied}>
                    <button
                      type="button"
                      className={styles.appliedButtonRedesign}
                      onClick={handleApply}
                    >
                      <span className={styles.appliedMessageRedesign}>
                        <CheckCircle className={styles.appliedIconRedesign} />
                        <FormattedMessage {...messages.applied} />
                      </span>
                      <span className={styles.appliedPlaceMessageRedesign}>
                        <FormattedMessage {...messages.appliedPlace} />
                      </span>
                    </button>
                  </Locator>
                ) : (
                  <Locator id="CouponCardSelectionStatus" enabled={applied}>
                    <button type="button" className={styles.appliedButton} onClick={handleApply}>
                      <CheckCircle />
                      <span className={styles.appliedMessage}>
                        <FormattedMessage {...messages.applied} />
                      </span>
                    </button>
                  </Locator>
                )}
              </>
            ) : (
              <Locator id="CouponCardSelectionStatus" enabled={applied}>
                <Button
                  tag="button"
                  color="ghost"
                  size={big && !mobile?.matches ? 'x-large' : 'large'}
                  onClick={handleApply}
                  fullWidth
                >
                  <FormattedMessage {...messages.apply} />
                </Button>
              </Locator>
            )}
          </div>
        </div>
      </div>
    </Locator>
  );
}
