import PropTypes from 'prop-types';

import ImageBundle from './ImageBundle';

export const Video = PropTypes.shape({
  height: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  bitrate: PropTypes.number,
});

// eslint-disable-next-line import/no-default-export
export default PropTypes.shape({
  autoplay: PropTypes.bool,
  duration: PropTypes.number,
  hlsUrl: PropTypes.string,
  thumbnail: ImageBundle,
  streamUrl: PropTypes.string,
  videos: PropTypes.arrayOf(Video),
  id: PropTypes.string.isRequired,
});
