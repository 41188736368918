import {ParcelPageCard} from 'components/ParcelPageCard';
import {Parcel as ParcelBase} from 'components/Parcels/Parcel';
import React from 'react';
import {Parcel as ParcelType} from 'types/Parcel';

type ParcelProps = {
  activeFilter?: string;
  parcel: ParcelType;
};

export function Parcel({activeFilter, parcel}: ParcelProps): JSX.Element {
  return (
    <ParcelPageCard>
      <ParcelBase parcel={parcel} activeFilter={activeFilter} />
    </ParcelPageCard>
  );
}
