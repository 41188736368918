import {RouteComponentProps} from 'react-router-dom';
import {loggedIn} from 'routes/decorators';
import {PoorRoute} from 'routes/types';

type StaticContext = RouteComponentProps['staticContext'];

export function setStatusCode<T extends StaticContext | undefined>(
  context: T,
  statusCode: number,
): T {
  if (context) {
    context.statusCode = statusCode;
  }
  return context;
}

export function isLoginRequired(route: PoorRoute): boolean {
  return route.decorator === loggedIn;
}
