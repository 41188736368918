import Button from 'components/Button';
import {Locator} from 'components/Locator';
import {useDeepLink} from 'hooks/useDeepLink';
import React, {useCallback} from 'react';
import {StyledButton} from 'types/StyledButton';
import {isExternalUrl} from 'utils/url';

import styles from './index.scss';

type Props = {
  button: StyledButton;
  onClick: () => void;
};

export const ItemButton = React.memo(function ItemButton({
  button,
  onClick,
}: Props): React.ReactElement {
  const [url, deepLinkAction] = useDeepLink(button.deeplink);

  const handleClick = useCallback(() => {
    onClick();
    if (!url && deepLinkAction) {
      deepLinkAction();
    }
  }, [deepLinkAction, onClick, url]);

  const urlProps = url && isExternalUrl(url) ? {href: url} : {to: url};

  return (
    <Locator id="ClientPopupItemButton" text={button.text}>
      <Button
        className={styles.button}
        key={button.text}
        customColors={{
          text: button.textColor,
          background: button.backgroundColor,
        }}
        onClick={handleClick}
        {...urlProps}
        block
      >
        {button.text}
      </Button>
    </Locator>
  );
});
