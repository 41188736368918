import {Locator} from 'components/Locator';
import React from 'react';
import {FormattedMessage} from 'react-intl';

import styles from './index.scss';

type Props = {
  count?: number;
};

export const ReviewsCount = React.memo(function ReviewsCount({count = 0}: Props) {
  return (
    <Locator id="ProductLiteReviewsCount">
      <div className={styles.reviewsCount}>
        <FormattedMessage
          defaultMessage="{count, plural, =101 {100+ reviews} one {# review} other {# reviews}}"
          description="Reviews count on product preview cart"
          values={{count: count >= 100 ? 101 : count}}
        />
      </div>
    </Locator>
  );
});
