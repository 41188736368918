import React from 'react';
import {isExternalUrl} from 'utils/url';
import styles from './HyperText.scss';
import {AllowedTagName, AnchoredTagName} from './TagName';

function anchor(item) {
  let result = '';

  function next(child) {
    if (child.type === 'text') {
      result += child.value;
    }
    if (child.type === 'element' && child.children) {
      child.children.forEach(next);
    }
    return result;
  }

  return encodeURIComponent(next(item).substr(0, 100));
}

export function jsonToHyperText(prefix, jsonList, internalLinks, headerAnchors) {
  let index = 0;
  const key = () => `${prefix}-${++index}`;

  function renderChildren(item) {
    // eslint-disable-next-line no-use-before-define
    return item.children ? item.children.map(renderItem) : null;
  }

  function renderItem(item) {
    if (item.type === 'text') {
      return <React.Fragment key={key()}>{item.value}</React.Fragment>;
    }

    if (item.type === 'element') {
      if (AllowedTagName[item.tagName]) {
        const tagProps = item.properties ? {...item.properties} : {};
        tagProps.className = styles[item.tagName] || null;
        delete tagProps.style;
        if (item.tagName === 'a') {
          if (isExternalUrl(tagProps.href)) {
            tagProps.target = '_blank';
          } else if (internalLinks) {
            if (internalLinks.target) {
              tagProps.target = internalLinks.target;
            }
            if (internalLinks.replacers) {
              internalLinks.replacers.forEach((replacer) => {
                if (tagProps.href) {
                  tagProps.href = replacer(tagProps.href);
                }
              });
            }
          }
        }
        if (item.children && item.children.length > 0) {
          tagProps.children = item.children.map(renderItem);
        }

        if (headerAnchors && AnchoredTagName[item.tagName]) {
          const anchorProp = anchor(item);
          if (anchorProp) {
            return (
              <item.tagName key={key()} {...tagProps}>
                <a className={styles.anchor} name={anchorProp} href={`#${anchorProp}`}>
                  #
                </a>{' '}
                {tagProps.children}
              </item.tagName>
            );
          }
        }

        return <item.tagName key={key()} {...tagProps} />;
      }
      if (item.children) {
        return <React.Fragment key={key()}>{renderChildren(item)}</React.Fragment>;
      }
    }

    return null;
  }

  return <React.Fragment>{renderChildren({children: jsonList || []})}</React.Fragment>;
}
