import {useMergedRef} from 'hooks/useMergedRef';
import React, {
  FC,
  forwardRef,
  HTMLProps,
  ReactElement,
  ReactNode,
  Ref,
  useEffect,
  useLayoutEffect,
  useReducer,
  useRef,
} from 'react';
import {getPassiveEventOptions} from 'utils/dom';
import {nextTick} from 'utils/nextTick';
import {cancelIdleCallback, requestIdleCallback} from 'utils/requestIdleCallback';

export type LazyHydrateProps = {
  ssrOnly?: boolean;
  whenIdle?: boolean;
  whenVisible?: boolean | IntersectionObserverInit;
  noWrapper?: boolean | keyof JSX.IntrinsicElements;
  didHydrate?: () => void;
  promise?: Promise<void>;
  disabled?: boolean;
  on?: (keyof HTMLElementEventMap)[] | keyof HTMLElementEventMap;
  children: ReactNode | (() => ReactNode);
};

type Props = Omit<HTMLProps<HTMLElement>, 'dangerouslySetInnerHTML'> & LazyHydrateProps;

function reducer() {
  return true;
}

// from https://github.com/hadeeb/react-lazy-hydration/blob/master/src/index.tsx
export const LazyHydrate = forwardRef(function LazyHydrate(
  props: Props,
  outerRef: Ref<HTMLElement>,
): ReactElement | null {
  const childRef = useRef<HTMLElement>(null);

  const {
    noWrapper,
    ssrOnly,
    whenIdle,
    whenVisible,
    // pass a promise which hydrates
    promise,
    on = [],
    disabled,
    children,
    // callback for hydration
    didHydrate,
    ...rest
  } = props;

  // Always render on server
  const [hydrated, hydrate] = useReducer(reducer, __SERVER__ || Boolean(disabled));

  if (
    __DEVELOPMENT__ &&
    !ssrOnly &&
    !whenIdle &&
    !whenVisible &&
    !on.length &&
    !promise &&
    typeof disabled !== 'boolean'
  ) {
    throw new Error(
      `LazyHydration: Enable at least one trigger for hydration.\n` +
        `If you don't want to hydrate, use ssrOnly`,
    );
  }

  useLayoutEffect(() => {
    // No SSR Content or disabled
    if (!childRef.current?.hasChildNodes() || disabled) {
      hydrate();
    }
  }, [disabled]);

  useEffect(() => {
    if (hydrated && didHydrate) {
      didHydrate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hydrated]);

  useEffect(() => {
    if (ssrOnly || hydrated) return undefined;
    const rootElement = childRef.current;

    const cleanupFns: VoidFunction[] = [];
    function cleanup() {
      cleanupFns.forEach((fn) => {
        fn();
      });
    }

    if (promise) {
      promise.then(hydrate, hydrate);
    }

    if (whenVisible) {
      if (rootElement && typeof IntersectionObserver !== 'undefined') {
        const observerOptions =
          typeof whenVisible === 'object'
            ? whenVisible
            : {
                rootMargin: '250px',
              };

        const io = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting || entry.intersectionRatio > 0) {
              nextTick(hydrate);
            }
          });
        }, observerOptions);

        io.observe(rootElement);

        cleanupFns.push(() => {
          io.disconnect();
        });
      } else {
        return hydrate();
      }
    }
    if (whenIdle) {
      const idleCallbackId = requestIdleCallback(hydrate, {timeout: 500});
      cleanupFns.push(() => {
        cancelIdleCallback(idleCallbackId);
      });
    }

    const events = ([] as Array<keyof HTMLElementEventMap>).concat(on);

    events.forEach((event) => {
      rootElement?.addEventListener(event, hydrate, getPassiveEventOptions());
      cleanupFns.push(() => {
        rootElement?.removeEventListener(event, hydrate, {});
      });
    });

    return cleanup;
  }, [hydrated, on, ssrOnly, whenIdle, whenVisible, didHydrate, promise, noWrapper]);

  const WrapperElement = (typeof noWrapper === 'string' ? noWrapper : 'div') as unknown as FC<
    HTMLProps<HTMLElement>
  >;

  const mergedRef = useMergedRef(outerRef, childRef);

  const childrenNode = typeof children === 'function' ? children() : children;

  if (hydrated) {
    if (noWrapper) {
      return <>{childrenNode}</>;
    }
    return (
      <WrapperElement ref={mergedRef} {...rest}>
        {childrenNode}
      </WrapperElement>
    );
  }
  return (
    <WrapperElement
      {...rest}
      ref={mergedRef}
      suppressHydrationWarning
      dangerouslySetInnerHTML={{__html: ''}}
    />
  );
});
