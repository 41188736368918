import classnames from 'classnames/bind';
import {Image} from 'components/Image';
import React from 'react';
import {ProductStore} from 'types/ProductStore';
import {Expandable} from 'types/utility';
import {VwFit} from 'types/VwFit';
import {getLettersForTextIcon} from 'utils/text';

import styles from './index.scss';

const cn = classnames.bind(styles);

const ImagePxFit = {
  normal: 72,
  small: 46,
};

type Props = {
  circle?: boolean;
  pxFit?: number;
  size?: 'normal' | 'small' | 'fontSize' | 'inherit';
  name: ProductStore['name'];
  image: ProductStore['image'];
  vwFit?: VwFit;
};

export const StoreLogo = React.memo(function StoreLogo({
  circle = false,
  pxFit,
  size = 'normal',
  name,
  image,
  vwFit,
}: Props): React.ReactElement {
  const imagePxFit = pxFit || (ImagePxFit as Expandable<typeof ImagePxFit>)[size];
  const imageVwFit = vwFit || {xs: `${imagePxFit}px`};

  return (
    <div className={cn('storeLogo', `size-${size}`, {circle})}>
      {image ? (
        <Image className={styles.image} cover image={image} pxFit={imagePxFit} vwFit={imageVwFit} />
      ) : (
        <span className={styles.letters}>{getLettersForTextIcon(name)}</span>
      )}
    </div>
  );
});
