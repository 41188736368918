import {Category} from 'types/Category';
import {isUserCategoryQuery, UserCategoryQuery, UserQuery} from 'types/SearchQuery';

export type SuggestItem = UserQuery & {
  extraInfo?: {
    category?: Category;
  };
};

export type SuggestCategoryItem = SuggestItem & UserCategoryQuery;

export function isSuggestCategoryItem(item: SuggestItem): item is SuggestCategoryItem {
  return isUserCategoryQuery(item);
}

export type Suggest = {
  items: SuggestItem[];
};
