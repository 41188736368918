import cn from 'classnames';
import {Locator} from 'components/Locator';
import {Icon} from 'components/UIKit/Icon';
import {CouponCard} from 'connectors/CouponCard';
import {useSelector} from 'hooks/redux';
import {useAnalytics} from 'hooks/useAnalytics';
import {useLayoutEffectOnce} from 'hooks/useEffectOnce';
import React, {
  CSSProperties,
  ReactElement,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import {getCouponCard} from 'store/modules/couponCards/selectors';
import {RootState} from 'store/rootReducer';
import {CouponCard as CouponCardType} from 'types/CouponCard';

import styles from './CouponPopup.scss';

export type AnimationConfig = {
  topStartPosition: number;
  startAnimation: (couponRect: DOMRect) => void;
};

type Props = {
  coupon: CouponCardType;
  source: string;
  animationConfig?: AnimationConfig;
  onClose: () => void;
};

export function CouponPopup({coupon, source, animationConfig, onClose}: Props): ReactElement {
  const analytics = useAnalytics();

  const [animationStyle, setAnimationStyle] = useState<CSSProperties | undefined>();

  const couponRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    analytics.sendEvent({
      type: 'couponOpen',
      payload: {couponId: coupon.id, source},
    });

    return () => {
      analytics.sendEvent({
        type: 'couponClose',
        payload: {couponId: coupon.id, source},
      });
    };
  }, [analytics, coupon.id, source]);

  useLayoutEffectOnce(() => {
    if (animationConfig && couponRef.current) {
      const couponRect = couponRef.current.getBoundingClientRect();
      const distance = animationConfig.topStartPosition - couponRect.top;

      setAnimationStyle({'--distance': `${distance}px`} as CSSProperties);

      animationConfig.startAnimation(couponRect);
    }
  });

  return (
    <div className={styles.root}>
      <div className={styles.mobileCloseButton}>
        <Icon name="close-linear-24" type="mono" />
      </div>
      <div className={cn(styles.content, animationStyle && styles.animate)} style={animationStyle}>
        <div className={styles.text}>
          <Locator id="CouponPopupTitle">
            <div className={styles.title}>{coupon.texts.receiveTitle}</div>
          </Locator>
          <Locator id="CouponPopupSubtitle">
            <div className={styles.subtitle}>{coupon.texts.previewDescription}</div>
          </Locator>
        </div>
        <div ref={couponRef} className={styles.coupon}>
          <CouponCard
            big
            content={coupon}
            source="newCouponPopup"
            onAfterApplyDeeplinkFollowed={onClose}
          />
        </div>
      </div>
    </div>
  );
}

export function CouponPopupById({
  couponId,
  onClose,
  ...props
}: Omit<Props, 'coupon'> & {couponId: string}): ReactElement | null {
  const coupon = useSelector((state: RootState) => getCouponCard(state, couponId));

  useLayoutEffect(() => {
    if (!coupon) {
      onClose();
    }
  }, [coupon, onClose]);

  if (!coupon) {
    return null;
  }

  return <CouponPopup coupon={coupon} onClose={onClose} {...props} />;
}
