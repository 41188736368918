import {ScopeConfig} from 'helpers/ApiClient/Scope/ScopeConfig';
import {globalLog} from 'helpers/log';
import {OS} from 'helpers/userAgent';
import {UserAgent} from 'types/UserAgent';
import {IResult} from 'ua-parser-js';
import {TypedObject} from 'utils/object/typed';
import {createUrl, QueryMap, serializeQueryData} from 'utils/url';

const logger = globalLog.getLogger('UtilsAppUrl');

export function deeplink(scope: ScopeConfig, target = '', params?: QueryMap): string {
  if (!scope.mobileApps?.android) {
    logger.error('try to get deeplink url when android app not defined');
    return '';
  }
  const url = `${scope.mobileApps.android.scheme}://${target}`;
  return params && Object.keys(params).length ? createUrl(url, params) : url;
}

export function intent(
  scope: ScopeConfig,
  target = '',
  params?: QueryMap,
  fallbackUrl?: string,
): string {
  if (!scope.mobileApps?.android) {
    logger.error('try to get intent url when android app not defined');
    return '';
  }
  const serializedIntentLink = createUrl(target, params);
  return [
    `intent://${serializedIntentLink}#Intent;`,
    `package=${scope.mobileApps.android.id};`,
    `scheme=${scope.mobileApps.android.scheme};`,
    fallbackUrl ? `S.browser_fallback_url=${encodeURIComponent(fallbackUrl)};` : '',
    'end;',
  ]
    .filter(Boolean)
    .join('');
}

const FIREBASE_PARAM_MAP = {
  otherFallbackUrl: 'ofl',
  iOsFallbackUrl: 'ifl',
  androidFallbackUrl: 'afl',
} as const;

export function firebaseLink(
  scope: ScopeConfig,
  target = '',
  params?: QueryMap,
  options?: {
    otherFallbackUrl?: string;
    iOsFallbackUrl?: string;
    androidFallbackUrl?: string;
  },
): string {
  if (!scope.mobileApps?.android && !scope.mobileApps?.ios) {
    logger.error('try to get firebase link when android or ios app not defined');
    return '';
  }

  const additionalParams = TypedObject.fromEntries(
    TypedObject.entries(options || {}).map(([key, value]) => [FIREBASE_PARAM_MAP[key], value]),
  );

  return createUrl(`https://${scope.integrations.firebase.hostname}`, {
    link: createUrl(`https://${scope.hostname}${target}`, params),
    ...(scope.mobileApps?.android && {apn: scope.mobileApps.android.id}),
    ...(scope.mobileApps?.ios && {
      ibi: scope.mobileApps.ios.bundleId,
      isi: scope.mobileApps.ios.id,
    }),
    efr: 1,
    ...additionalParams,
  });
}

let lastUa = '';
let lastIntentSupport = false;
export function isIntentSupported(scope: ScopeConfig, ua: UserAgent): boolean {
  if (!scope.mobileApps?.android) {
    return false;
  }

  if (ua.ua !== lastUa) {
    lastUa = ua.ua;
    lastIntentSupport =
      ua.os.name === OS.ANDROID &&
      (ua.engine.name === 'Blink' || ua.engine.name === 'WebKit') &&
      parseInt(ua.browser.version || '', 10) >= 25;
  }
  return lastIntentSupport;
}

export async function getRelatedMobileApp(
  scope: ScopeConfig,
): Promise<RelatedApplication | undefined> {
  if (navigator.getInstalledRelatedApps) {
    const relatedApps = await navigator.getInstalledRelatedApps();
    return relatedApps.find(
      ({id, platform}) => platform === 'play' && id === scope.mobileApps?.android?.id,
    );
  }

  return undefined;
}

export function googlePlayUrl(scope: ScopeConfig, target?: string, params?: QueryMap): string {
  if (!scope.mobileApps?.android) {
    return '';
  }

  const queryData = target
    ? {
        ...params,
        target_url: deeplink(scope, target),
      }
    : params;

  if (!queryData || Object.keys(queryData).length === 0) {
    return scope.mobileApps.android.url;
  }

  return createUrl(scope.mobileApps.android.url, {
    referrer: serializeQueryData(queryData),
  });
}

export function appStoreUrl(scope: ScopeConfig): string {
  if (!scope.mobileApps?.ios) {
    return '';
  }

  return scope.mobileApps.ios.url;
}

export function appGalleryUrl(scope: ScopeConfig): string {
  if (!scope.mobileApps?.huawei) {
    return '';
  }

  return scope.mobileApps.huawei.url;
}

export function ruStoreUrl(scope: ScopeConfig): string {
  if (!scope.mobileApps?.rustore) {
    return '';
  }

  return scope.mobileApps.rustore.url;
}

export function getPlatformUrl(scope: ScopeConfig, userAgent: IResult): string | null {
  if (userAgent.os.name === 'Android' && scope.mobileApps?.android) {
    return googlePlayUrl(scope);
  }
  if (userAgent.os.name === 'iOS' && scope.mobileApps?.ios) {
    return appStoreUrl(scope);
  }
  if (userAgent.device.vendor === 'Huawei' && scope.mobileApps?.huawei) {
    return appGalleryUrl(scope);
  }
  return null;
}
