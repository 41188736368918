import {ContextMenu} from 'components/ContextMenu';
import CouponShape from 'shapes/Coupon';
import memoize from 'utils/memoize';
import PropTypes from 'prop-types';
import React, {PureComponent} from 'react';
import {FormattedMessage, defineMessages, useIntl} from 'react-intl';
import {getDisabledCouponColors, getCouponColors} from 'helpers/coupons';
import {svgToDataUri} from 'utils/string';
import styles from './index.scss';

export {getCouponColors} from 'helpers/coupons';

const messages = defineMessages({
  close: {
    description: '[label] Закрыть информацию о купоне',
    defaultMessage: 'Close coupon information',
  },
  info: {
    description: '[label] Открыть информацию о купоне',
    defaultMessage: 'Open coupon information',
  },
});

const RIGHT_EAR_PATH = 'M20 190a10 10 0 0 0 0 20.01V400H0V0h20z';
const LEFT_EAR_PATH = 'M0 210A10 10 0 0 0 0 190V0H20V400H0z';
const BACK_BACKGROUND = '#fff';

function getEarImage(fill, path) {
  return `<svg width="20" height="400" xmlns='http://www.w3.org/2000/svg' viewbox="0 0 20 400"><path fill="${fill}" d="${path}"/></svg>`;
}

const getEarsStyle = memoize((start, end) => ({
  background: [
    `url("${svgToDataUri(getEarImage(start, LEFT_EAR_PATH))}") 0 50% / 1.1rem 22rem no-repeat`,
    `url("${svgToDataUri(getEarImage(end, RIGHT_EAR_PATH))}") 100% 50% / 1.1rem 22rem no-repeat`,
  ].join(','),
}));

export function getCouponStyle(colors) {
  if (!colors) {
    return null;
  }

  const {color, start, end} = colors;
  return {
    color,
    backgroundColor: end,
    backgroundImage: `linear-gradient(90deg, ${start}, ${end})`,
  };
}

class CouponComponent extends PureComponent {
  static propTypes = {
    intl: PropTypes.objectOf(PropTypes.any).isRequired,
    big: PropTypes.bool,
    coupon: CouponShape,
    onApply: PropTypes.func,
    onCancel: PropTypes.func,
    isPlaceholder: PropTypes.bool,
  };

  static defaultProps = {
    big: false,
    coupon: null,
    onApply: null,
    onCancel: null,
    isPlaceholder: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      back: false,
    };
  }

  handleInfoClick = () =>
    this.setState({
      back: true,
    });

  handleCloseClick = () =>
    this.setState({
      back: false,
    });

  renderBackContent() {
    const {coupon} = this.props;
    return (
      <div className={styles.back}>
        <div className={styles.backTitle}>{coupon.cardBackTitle}</div>
        <div className={styles.backText}>{coupon.cardBackDescription}</div>
      </div>
    );
  }

  renderBack() {
    const {intl} = this.props;

    return (
      <div className={styles.reducer} style={getEarsStyle(BACK_BACKGROUND, BACK_BACKGROUND)}>
        <div className={styles.content}>
          <div
            role="button"
            tabIndex={0}
            className={styles.close}
            onClick={this.handleCloseClick}
            aria-label={intl.formatMessage(messages.close)}
          />
          {this.renderBackContent()}
        </div>
      </div>
    );
  }

  renderFront(colors) {
    const {big, coupon, isPlaceholder, intl} = this.props;
    const {back} = this.state;
    return (
      <div className={styles.reducer} style={getEarsStyle(colors.start, colors.end)}>
        <div className={styles.content} style={getCouponStyle(colors)}>
          <div className={styles.inner}>
            <div className={styles.top}>
              <div className={styles.cell}>
                <div className={styles.title}>{coupon.cardTitle}</div>
                {coupon.cardBackTitle ? (
                  <span className={styles.info}>
                    <span
                      className={styles.infoIcon}
                      onClick={this.handleInfoClick}
                      aria-label={intl.formatMessage(messages.info)}
                      role="button"
                      tabIndex={-1}
                    />
                    {!big && !isPlaceholder && back ? (
                      <div className={styles.context}>
                        <ContextMenu onClose={this.handleCloseClick}>
                          {this.renderBackContent()}
                        </ContextMenu>
                      </div>
                    ) : null}
                  </span>
                ) : null}
              </div>
            </div>
            <div className={styles.bottom}>
              <div className={styles.cell}>
                <div className={styles.description}>{coupon.cardDescription}</div>
                <div className={styles.expiration}>{coupon.cardExpiration}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderControls(colors, onClick) {
    if (!onClick) {
      return null;
    }

    return (
      <div className={styles.buttonWrapper} style={getCouponStyle(colors)}>
        <div role="button" tabIndex={0} className={styles.button} onClick={onClick}>
          {this.props.onApply ? (
            <FormattedMessage description="Coupon apply button" defaultMessage="Apply coupon" />
          ) : (
            <FormattedMessage description="Coupon cancel button" defaultMessage="Cancel coupon" />
          )}
        </div>
      </div>
    );
  }

  render() {
    const {big, coupon, onApply, onCancel, isPlaceholder} = this.props;
    const {back} = this.state;

    const onButtonClick = onApply || onCancel;

    if (!coupon) {
      return null;
    }

    const colors = onApply ? getDisabledCouponColors() : getCouponColors(coupon);
    const classNames = [
      styles.coupon,
      big ? styles.big : '',
      onApply ? styles.gray : '',
      onButtonClick ? styles.hasButton : '',
    ];

    return (
      <div className={classNames.join(' ')}>
        {big && !isPlaceholder && back ? this.renderBack() : this.renderFront(colors)}
        {this.renderControls(colors, onButtonClick)}
      </div>
    );
  }
}

const Coupon = (props) => {
  return <CouponComponent {...props} intl={useIntl()} />;
};

export default Coupon;
