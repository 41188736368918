import type {ContentListLocator, Props as ContentListProps} from 'components/ContentList';
import {type Mark, createLocator} from 'create-locator';
import React from 'react';
import {
  type ProductsList as ProductsListType,
  ListApperanceDesign,
} from 'types/ContentList/ContentListProductsList';

import {GridProductsList} from './grid';
import {RowProductsList} from './row';

type RowProductsListProps = React.ComponentProps<typeof RowProductsList>;
type Props = {
  center?: boolean;
  content: ProductsListType;
  contentListProps?: Omit<ContentListProps, 'items' | 'onMore'>;
  fullWidth?: boolean;
  id?: string;
  source?: RowProductsListProps['source'];
} & Mark<ContentListLocator>;

export type ProductsListLocator = ContentListLocator;

export const ProductsList = (props: Props): JSX.Element => {
  const {center = false, content, contentListProps, fullWidth = false, id, source} = props;
  const locator = createLocator(props);

  if (content.listAppearance?.design === ListApperanceDesign.Row) {
    return (
      <RowProductsList
        center={center}
        content={content}
        fullWidth={fullWidth}
        id={id}
        source={source}
        onFavorite={contentListProps?.onFavorite}
        onUnfavorite={contentListProps?.onUnfavorite}
        {...locator()}
      />
    );
  }

  return (
    <GridProductsList
      content={content}
      contentListProps={contentListProps}
      source={source}
      id={id}
      {...locator()}
    />
  );
};
