import config from 'config';
import {DEFAULT_APPEARANCE} from 'helpers/contentList';
import {START_PAGE_TOKEN} from 'store/consts';
import {enhanceContentListItems} from 'store/enhancer/contentList';
import {requestActionCreator} from 'store/utils/requestActions';
import {ContextList} from 'types/Context';
import {FuncAction} from 'typesafe-actions';

import {load, LoadRequest, LoadResponse} from './actions';

type LoadResponseBody = {
  payload: LoadResponse;
  contexts: ContextList[];
};

export const loadCouponsFull = (
  pageToken?: string,
  count = config.productsPageSize,
): FuncAction<Promise<LoadResponse>> =>
  requestActionCreator(load, {pageToken, count}, (store, client) => {
    const body: LoadRequest = {
      appearance: DEFAULT_APPEARANCE,
    };
    if (pageToken && pageToken !== START_PAGE_TOKEN) {
      body.pageToken = pageToken;
    }
    if (count) {
      body.count = count;
    }

    return client.api
      .post<LoadResponseBody>('/contentList/coupons/get', {body})
      .then(({currency, language, body: {contexts, payload}}) => ({
        ...payload,
        currency,
        language,
        items: enhanceContentListItems(payload.items, language, currency, pageToken, contexts),
      }));
  });
