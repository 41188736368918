import classnames from 'classnames/bind';
import React, {ReactElement, ReactNode} from 'react';

import styles from './index.scss';

const cn = classnames.bind(styles);

type Props = {
  children: ReactNode;
  transparent?: boolean;
  marginTop?: string;
};

export function Footer({children, transparent, marginTop}: Props): ReactElement {
  const style = marginTop ? {marginTop} : undefined;
  return (
    <div className={cn(styles.footer, {transparent})} style={style}>
      {children}
    </div>
  );
}
