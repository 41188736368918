import {createRequestAction} from 'store/utils/requestActions';

type LoadRequest = {
  url: string;
};

type LoadResponse = {
  hash: string;
};

const load = createRequestAction(
  '@pageHash/LOAD_REQUEST',
  '@pageHash/LOAD_SUCCESS',
  '@pageHash/LOAD_FAILURE',
)<LoadRequest, LoadResponse>();

export {load, LoadRequest, LoadResponse};
