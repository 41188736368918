import React from 'react';
import {Link} from 'react-router-dom';
import {Link as LinkType} from 'types/PageMetadata/Link';

import styles from './index.scss';

type Props = {
  links: LinkType[];
};

export const Links = React.memo(function Links({links}: Props) {
  return (
    <ul className={styles.links}>
      {links.map((link) => (
        <li key={link.anchor} className={styles.linksItem}>
          <Link className={styles.link} to={link.href}>
            {link.anchor}
          </Link>
        </li>
      ))}
    </ul>
  );
});
