import {PageLoading} from 'components/PageLoading';
import React, {memo} from 'react';
import {Error} from 'types/Error';
import {WarrantyTexts} from 'types/WarrantyTexts';

import styles from './index.scss';
import {WarrantyTextsItem} from './WarrantyTextsItem';

export type WarrantyProps = {
  warrantyTexts?: WarrantyTexts;
  warrantyTextsLoading?: boolean;
  warrantyTextsError?: Error;
};

export const Warranty = memo(function Warranty({
  warrantyTexts,
  warrantyTextsLoading = false,
  warrantyTextsError,
}: WarrantyProps): React.ReactElement | null {
  if (warrantyTextsLoading) {
    return <PageLoading />;
  }

  if (warrantyTextsError || !warrantyTexts?.items?.length) {
    return null;
  }

  return (
    <div className={styles.list}>
      {warrantyTexts.items.map((item, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <WarrantyTextsItem key={`item-${i}`} item={item} />
      ))}
    </div>
  );
});
