import PropTypes from 'prop-types';
import {useSelector} from 'hooks/redux';
import React, {useCallback, useMemo} from 'react';
import AnalyticsShape from 'shapes/Analytics';
import {
  isPopupLoading,
  getPopupData,
  getPopupError,
  isPopupLoaded,
  loadPopupData,
  isAdulthoodSaving,
  getAdulthoodSavingError,
  saveUserAdulthood,
} from 'store/modules/adult';
import {
  useAdultPopupView,
  AdultPopup as AdultPopupUI,
  AdultPopupInline as AdultPopupInlineUI,
} from 'components/AdultPopup';
import AdultPopupShape from 'shapes/AdultPopup';
import ErrorShape from 'shapes/Error';

import {useDispatchActionMap} from 'hooks/useDispatch';
import {useAnalytics} from 'hooks/useAnalytics';
import {PopupStack} from 'components/UIKit/Popup/PopupStack';
import {usePopup} from 'components/UIKit/Popup/PopupProvider';

function AdultPopupComponent(props) {
  const view = useAdultPopupView(props);
  return <PopupStack {...view} />;
}

export default function AdultPopupContainer(props) {
  const analytics = useAnalytics();
  const isLoading = useSelector(isPopupLoading);
  const isLoaded = useSelector(isPopupLoaded);
  const data = useSelector(getPopupData);
  const loadingError = useSelector(getPopupError);
  const isSaving = useSelector(isAdulthoodSaving);
  const adulthoodSavingError = useSelector(getAdulthoodSavingError);

  const actionMap = useMemo(
    () => ({
      loadPopupData,
      saveUserAdulthood,
    }),
    [],
  );
  const actions = useDispatchActionMap(actionMap);

  return (
    <AdultPopupContainerBase
      {...props}
      analytics={analytics}
      isLoading={isLoading}
      isLoaded={isLoaded}
      data={data}
      loadingError={loadingError}
      isSaving={isSaving}
      adulthoodSavingError={adulthoodSavingError}
      load={actions.loadPopupData}
      saveUserAdulthood={actions.saveUserAdulthood}
    />
  );
}

class AdultPopupContainerBase extends React.Component {
  static propTypes = {
    data: AdultPopupShape,
    load: PropTypes.func.isRequired,
    onDecline: PropTypes.func,
    onClose: PropTypes.func,
    isSaving: PropTypes.bool,
    loadingError: ErrorShape,
    saveUserAdulthood: PropTypes.func.isRequired,
    adulthoodSavingError: ErrorShape,
    isLoading: PropTypes.bool,
    isLoaded: PropTypes.bool,
    analytics: AnalyticsShape.isRequired,
    Component: PropTypes.func.isRequired,
  };

  static defaultProps = {
    data: null,
    isSaving: false,
    loadingError: null,
    adulthoodSavingError: null,
    isLoading: true,
    isLoaded: false,
    onDecline: undefined,
    onClose: undefined,
  };

  isSelected = false;

  componentDidMount() {
    const {isLoaded, load} = this.props;
    if (!isLoaded) {
      load();
    }
    this.props.analytics.sendEvent({type: 'adultPopupOpen'});
  }

  componentWillUnmount() {
    this.props.analytics.sendEvent({type: 'adultPopupClose'});
  }

  handleConfirm = async () => {
    this.isSelected = true;
    this.props.analytics.sendEvent({type: 'adultPopupConfirmClick'});
    try {
      return await this.props.saveUserAdulthood();
    } catch (error) {
      this.isSelected = false;
      throw error;
    }
  };

  handleDecline = () => {
    this.isSelected = true;
    this.props.analytics.sendEvent({type: 'adultPopupDeclineClick'});
    return this.props.onDecline?.();
  };

  handleClose = () => {
    if (!this.isSelected) {
      this.props.onDecline?.();
    }
    return this.props.onClose?.();
  };

  render() {
    const {isLoading, data, isSaving, loadingError, adulthoodSavingError, Component} = this.props;

    return (
      <Component
        isLoading={isLoading}
        data={data}
        isSaving={isSaving}
        onClose={this.handleClose}
        error={loadingError || adulthoodSavingError}
        onConfirm={this.handleConfirm}
        onDecline={this.handleDecline}
      />
    );
  }
}

export function AdultPopup(props) {
  return <AdultPopupContainer {...props} Component={AdultPopupComponent} />;
}

export function useAdultPopup() {
  const render = useCallback(
    ({onClose}) => <AdultPopupContainer Component={AdultPopupUI} onClose={onClose} />,
    [],
  );

  return usePopup(render);
}

export function AdultPopupInline(props) {
  return <AdultPopupContainer {...props} Component={AdultPopupInlineUI} />;
}
