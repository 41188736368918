import PropTypes from 'prop-types';
import React from 'react';
import Svg from '../Svg';
import styles from './index.scss';

export default function DropdownArrow(props) {
  return (
    <Svg viewBox="0 0 11 7" height="7px" width="11px" {...props}>
      <path className={styles.path} fill={props.color} d="M5.4 7L0 1.6 1.4.2l4 4 4-4 1.4 1.4z" />
    </Svg>
  );
}

DropdownArrow.propTypes = {
  color: PropTypes.string,
};

DropdownArrow.defaultProps = {
  color: 'currentColor',
};
