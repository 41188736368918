import {RootState} from 'store/rootReducer';
import {getSelectors} from 'store/utils/reducerCreators/simple/selectors';
import {createSelectorFactory, createStateGetter} from 'store/utils/selectors';
import {CouponCard} from 'types/CouponCard';

const selector = createSelectorFactory(createStateGetter('couponCards'));

const couponCardsSelectors = getSelectors(selector, () => true);

export const isCouponCardsLoading = couponCardsSelectors.isLoading;

export const isCouponCardsLoaded = couponCardsSelectors.isLoaded;

export const getCouponCardsError = couponCardsSelectors.getError;

export const getCouponCards = selector(({state}) => state.data?.items || undefined);

export const getCouponCardsCount = (state: RootState): number | undefined =>
  getCouponCards(state)?.length;

export const getCouponCard = (state: RootState, id: string): CouponCard | undefined =>
  (getCouponCards(state) || []).find((item) => item.id === id) || undefined;
