import PropTypes from 'prop-types';
import React, {memo} from 'react';
import {FormattedMessage} from 'react-intl';
import {FormattedDate} from 'components/i18n/FormattedDate';
import {Locator} from 'components/Locator';
import styles from './orderDates.scss';

export const OrderDates = memo(function OrderDates({createdTimeMs, warrantyDescription}) {
  return (
    <>
      <div className={styles.dateRow}>
        <span className={styles.dateLabel}>
          <FormattedMessage
            defaultMessage="Order date"
            description="Parcel order, buy date label"
          />
        </span>{' '}
        <Locator id="order-created">
          <span className={styles.dateValue}>
            <FormattedDate value={createdTimeMs} day="numeric" month="long" year="numeric" />
          </span>
        </Locator>
      </div>
      {Boolean(warrantyDescription) && (
        <div className={styles.dateRow}>
          <span className={styles.dateLabel}>
            <FormattedMessage
              defaultMessage="Warranty"
              description="Parcel order, warranty label"
            />
          </span>{' '}
          <Locator id="order-warranty">
            <span className={styles.dateValue}>{warrantyDescription}</span>
          </Locator>
        </div>
      )}
    </>
  );
});

OrderDates.propTypes = {
  warrantyDescription: PropTypes.string,
  createdTimeMs: PropTypes.number.isRequired,
};

OrderDates.defaultProps = {
  warrantyDescription: '',
};
