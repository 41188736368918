import {isMobileOrTabletDevice, OS} from 'helpers/userAgent';
import {useUserAgent} from 'hooks/useUserAgent';
import {AppWebViewContext} from 'providers/AppWebViewContext';
import {useContext, useMemo} from 'react';

type PlatformShare = (data?: ShareData) => Promise<void>;

/* PlatformShare must unify:
 * - native sharing - navigator.share
 * - our custom mobile share through joomShare:// - need to implement in the future
 * - other ways
 *
 * If this hook returns undefined, sharing is not supported
 */
export function usePlatformShare(): PlatformShare | undefined {
  const ua = useUserAgent();
  const webview = useContext(AppWebViewContext);

  return useMemo<PlatformShare | undefined>(() => {
    const nativeShareSupported =
      ((webview && ua.os.name === OS.IOS) || (!webview && isMobileOrTabletDevice(ua))) &&
      typeof navigator !== 'undefined' &&
      !!navigator.share;

    if (!nativeShareSupported) {
      return undefined;
    }

    return (data) => {
      return new Promise((resolve, reject) => {
        try {
          navigator.share(data).then(resolve, reject);
        } catch (e) {
          reject(e);
        }
      });
    };
  }, [ua, webview]);
}
