import React from 'react';
import {CategoryFrom} from 'types/analytics/Category';
import {PageSource} from 'types/PageSource';

import {CatalogLink} from './CatalogLink';
import {BuildUrlFunc, Category, Params} from './types';
import {useCloseIfNoChildren} from './useCloseIfNoChildren';

type InnerChild = {
  buildUrl: BuildUrlFunc;
  child: Category;
  loading: boolean;
  onCloseBeforeNavigate?: () => void;
  params: Params;
  from: CategoryFrom;
  source?: PageSource;
};

export const InnerChild = React.memo(function InnerChildFunc({
  child,
  buildUrl,
  loading,
  onCloseBeforeNavigate,
  params,
  from,
  source,
}: InnerChild) {
  const handleClick = useCloseIfNoChildren({
    onClose: onCloseBeforeNavigate,
    loading,
    children: child.children,
  });

  return (
    <CatalogLink
      buildUrl={buildUrl}
      categoryId={child.id}
      loading={loading}
      onClick={handleClick}
      params={params}
      withMargin
      from={from}
      source={source}
    >
      {child.name}
    </CatalogLink>
  );
});
