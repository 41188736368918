import styles from 'components/ClientProduct/SharePopup/index.scss';
import {CopyContainer} from 'components/CopyContainer';
import ErrorMessage from 'components/ErrorMessage';
import {PageLoading} from 'components/PageLoading';
import {Button} from 'components/UIKit/Button';
import {useModalOverlayLabelledById} from 'components/UIKit/ModalOverlay/context';
import {Popup} from 'components/UIKit/Popup';
import {Header} from 'components/UIKit/Popup/Header';
import {PopupViewProps, PopupViewRender} from 'components/UIKit/Popup/PopupProvider';
import {PopupStack} from 'components/UIKit/Popup/PopupStack';
import {usePlatformShare} from 'hooks/usePlatformShare';
import React, {ReactNode, useCallback, useState} from 'react';
import {defineMessages, useIntl} from 'react-intl';
import {Error} from 'types/Error';
import {UrlResponse} from 'types/UrlResponse';

const messages = defineMessages({
  title: {
    description: 'Заголовок модального окна для шаринга ссылки на продукт',
    defaultMessage: 'Share',
  },
  pushToShare: {
    description:
      'Сообщение для шаринга, когда есть возможность воспользоваться нативными средствами',
    defaultMessage: 'Click on the link below to share the product:',
  },
  pushToShareFailed: {
    description:
      'Сообщение для шаринга, когда нативный шаринг не сработал и нужно попробовать скопировать',
    defaultMessage: 'There was an issue when trying to share. You can copy the link below:',
  },
  copyToShare: {
    description: 'Сообщение для шаринга, когда можно только скопировать ссылку',
    defaultMessage: 'Copy the link below for sharing:',
  },
  copiedToClipboard: {
    description: 'Сообщение для шаринга, когда ссылка скопирована в буфер обмена (в тултипе)',
    defaultMessage: 'Link copied to clipboard',
  },
});

type Props = {
  data?: UrlResponse | null;
  error?: Error | null;
  loading?: boolean;
  title?: string;
  pushToShareLabel?: ReactNode;
  copyToShareLabel?: ReactNode;
};

export function SharePopupBase({
  data,
  error,
  loading,
  onBack,
  onClose,
  title,
  pushToShareLabel,
  copyToShareLabel,
}: Pick<PopupViewProps, 'onClose' | 'onBack'> & Props): React.ReactElement {
  const intl = useIntl();
  const headerUid = useModalOverlayLabelledById();
  const platformShare = usePlatformShare();

  const [platformShareFailed, setPlatformShareFailed] = useState(false);

  const handleShare = useCallback(() => {
    if (platformShare) {
      platformShare({url: data?.url})
        .then(onClose)
        .catch(() => setPlatformShareFailed(true));
      return;
    }
    setPlatformShareFailed(true);
  }, [data, onClose, platformShare]);

  const errorVisible = Boolean(error && !loading);
  const dataVisible = Boolean(data && !error && !loading);

  let textToShare = copyToShareLabel || intl.formatMessage(messages.copyToShare);

  if (platformShare) {
    textToShare = pushToShareLabel || intl.formatMessage(messages.pushToShare);
  }

  if (platformShare && platformShareFailed) {
    textToShare = intl.formatMessage(messages.pushToShareFailed);
  }

  return (
    <Popup width="640px">
      <Header onBack={onBack} onClose={onClose}>
        <div id={headerUid}>{title || intl.formatMessage(messages.title)}</div>
      </Header>
      <div className={styles.content}>
        {loading && <PageLoading />}
        {errorVisible && (
          <div className={styles.error}>
            <ErrorMessage internal error={error} />
          </div>
        )}
        {dataVisible && (
          <>
            <div className={styles.message}>{textToShare}</div>
            {platformShare && !platformShareFailed ? (
              <div className={styles.platformButton}>
                <Button color="gray" onClick={handleShare} size="large" tag="button">
                  {data?.url}
                </Button>
              </div>
            ) : (
              <CopyContainer value={data?.url || ''}>
                <div className={styles.copyContainer}>{data?.url}</div>
              </CopyContainer>
            )}
          </>
        )}
      </div>
    </Popup>
  );
}

export function useSharePopupRender({
  data,
  error,
  loading,
  title,
  pushToShareLabel,
  copyToShareLabel,
}: Props): PopupViewRender {
  return useCallback(
    ({onClose, onBack}: PopupViewProps) => (
      <SharePopupBase
        onClose={onClose}
        onBack={onBack}
        data={data}
        error={error}
        loading={loading}
        title={title}
        pushToShareLabel={pushToShareLabel}
        copyToShareLabel={copyToShareLabel}
      />
    ),
    [data, error, loading, title, pushToShareLabel, copyToShareLabel],
  );
}

export function SharePopup(props: Props): React.ReactElement {
  const render = useSharePopupRender(props);
  return <PopupStack render={render} />;
}
