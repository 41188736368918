import {
  getVisibilityChangeEventName,
  isPageVisibilitySupported,
  isPageVisible,
} from 'helpers/pageVisibility';
import {useAnalytics} from 'hooks/useAnalytics';
import {useDeviceVars} from 'hooks/useDeviceVars';
import {useIntersectionObserverRef} from 'hooks/useIntersectionObserverRef';
import React, {RefObject, useCallback, useEffect, useMemo, useRef} from 'react';
import type {AnalyticsEvent, AnalyticsSendOptions} from 'types/AnalyticsEvent';

const DEFAULT_TIME_THRESHOLD = 1000;
const OBSERVER_OPTIONS = {
  threshold: 0.9,
};

type PreviewOptions = {
  sendPreviewDimensions?: boolean;
  useCapture?: boolean;
};

type PreviewData = {
  rootRef: RefObject<HTMLElement>;
  previewEvent?: AnalyticsEvent | (() => AnalyticsEvent);
  previewEventOptions?: AnalyticsSendOptions;
  visibilityThreshold?: number;
};

type DimensionsPayload = {
  previewLeft: number;
  previewTop: number;
  previewRight: number;
  previewBottom: number;
};
type WindowDimensionsPayload = {
  windowWidth: number;
  windowHeight: number;
};

type ClickEvent = AnalyticsEvent;

export type AnalyticEventWithoutPayload<P, E extends AnalyticsEvent> = E extends {payload: unknown}
  ? {
      type: E['type'];
      payload: Omit<E['payload'], keyof P>;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      params?: any[];
    }
  : {
      type: E['type'];
      payload?: {};
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      params?: any[];
    };

export type PartialClickEvent<E extends AnalyticsEvent> = AnalyticEventWithoutPayload<
  DimensionsPayload & WindowDimensionsPayload,
  E
>;

type ClickHandler = (event: MouseEvent) => void;

type InitialClickDataItem = {
  clickRef?: RefObject<HTMLElement>;
  clickEvent: PartialClickEvent<AnalyticsEvent> | (() => PartialClickEvent<AnalyticsEvent>);
  clickEventOptions?: AnalyticsSendOptions;
};

export type InitialClickData = InitialClickDataItem | InitialClickDataItem[];

// This hook provides logic for preview events (and optionally corresponding
// click events) described in WEB-2211
export function usePreviewEvent(
  previewData: PreviewData,
  clickDataInitial?: InitialClickData,
  {sendPreviewDimensions = false, useCapture = false}: PreviewOptions = {},
): void {
  const {rootRef, previewEvent, previewEventOptions} = previewData;
  const previewMs = useRef(0);
  const analytics = useAnalytics();
  const timeoutCallback = useRef<number | null>(null);
  const isIntersecting = useRef(false);
  const clickHandlers = useRef<ClickHandler[]>([]);
  const {newProductPreviewBehavior} = useDeviceVars();

  const {
    on: newProductPreviewBehaviorOn = false,
    visibilityThreshold = OBSERVER_OPTIONS.threshold,
    timeThreshold = DEFAULT_TIME_THRESHOLD,
  } = newProductPreviewBehavior || {};

  const productPreviewSent = useRef(false);
  const productPreviewSentWithinCurrentEntry = useRef(false);

  const clickData = useMemo(() => {
    if (!clickDataInitial) {
      return undefined;
    }

    const clickDataArr = Array.isArray(clickDataInitial) ? clickDataInitial : [clickDataInitial];

    return clickDataArr.map((item) => {
      if (!item.clickRef) {
        item.clickRef = rootRef;
      }

      return item;
    });
  }, [clickDataInitial, rootRef]);

  const clearPreviewTimeout = useCallback(() => {
    if (!timeoutCallback.current) {
      return;
    }
    window.clearTimeout(timeoutCallback.current);
    timeoutCallback.current = null;
  }, []);

  const sendPreviewEvent = useCallback(() => {
    if (!previewEvent) {
      return;
    }

    previewMs.current = Date.now();

    const event = typeof previewEvent === 'function' ? previewEvent() : previewEvent;

    analytics.sendEvent(event, previewEventOptions);

    clearPreviewTimeout();
    productPreviewSentWithinCurrentEntry.current = true;

    if (!newProductPreviewBehaviorOn) {
      productPreviewSent.current = true;
    }
  }, [
    analytics,
    clearPreviewTimeout,
    newProductPreviewBehaviorOn,
    previewEvent,
    previewEventOptions,
  ]);

  const setPreviewTimeout = useCallback(() => {
    clearPreviewTimeout();
    timeoutCallback.current = window.setTimeout(sendPreviewEvent, timeThreshold);
  }, [sendPreviewEvent, timeThreshold, clearPreviewTimeout]);

  useEffect(() => {
    if (!clickData) {
      return;
    }

    clickHandlers.current = clickData.map(({clickEvent, clickEventOptions = {}}) => {
      return () => {
        if (timeoutCallback.current) {
          clearPreviewTimeout();
          previewMs.current = Date.now();
        }

        if (!productPreviewSentWithinCurrentEntry.current && newProductPreviewBehaviorOn) {
          sendPreviewEvent();
        }

        const rootRefCurrent = rootRef.current;
        const {
          top = 0,
          left = 0,
          bottom = 0,
          right = 0,
        } = rootRefCurrent ? rootRefCurrent.getBoundingClientRect() : {};

        const previewDimensions = sendPreviewDimensions
          ? {
              previewLeft: left,
              previewTop: top,
              previewRight: right,
              previewBottom: bottom,
            }
          : {};

        const event = typeof clickEvent === 'function' ? clickEvent() : clickEvent;
        analytics.sendEvent(
          {
            ...event,
            payload: {
              ...event.payload,
              ...previewDimensions,
            },
          } as ClickEvent,
          clickEventOptions,
        );
      };
    });
  }, [
    newProductPreviewBehaviorOn,
    clearPreviewTimeout,
    sendPreviewEvent,
    analytics,
    clickData,
    rootRef,
    sendPreviewDimensions,
  ]);

  useEffect(
    () => () => {
      clearPreviewTimeout();
    },
    [clearPreviewTimeout],
  );

  useEffect(() => {
    const handlers = clickHandlers.current || [];
    const datas = clickData || [];

    function handleClick(event: MouseEvent) {
      datas.forEach((data, i) => {
        const clickTarget = event.target as Node;
        const target = data.clickRef?.current;
        const handler = handlers[i];

        if (handler && target && clickTarget && target.contains(clickTarget)) {
          handler(event);
        }
      });
    }

    /**
     * React doesn't prevent dom-events and starts its own event processing at the end of
     * the bubble phase. It can stop other processing of events with stopImmediatePropagation,
     * since it subscribes one of the first.
     */
    document.addEventListener('click', handleClick, useCapture);

    return () => {
      document.removeEventListener('click', handleClick, useCapture);
    };
  }, [clickData, useCapture]);

  const handleObserve = useCallback(
    (entry: IntersectionObserverEntry) => {
      const isTargetIntersecting = entry.isIntersecting;
      isIntersecting.current = isTargetIntersecting;

      if (isTargetIntersecting && (newProductPreviewBehaviorOn || !productPreviewSent.current)) {
        setPreviewTimeout();
      } else {
        clearPreviewTimeout();
        productPreviewSentWithinCurrentEntry.current = false;
      }
    },
    [newProductPreviewBehaviorOn, setPreviewTimeout, clearPreviewTimeout],
  );

  const finalVisibilityThreshold = useMemo(() => {
    if (previewData.visibilityThreshold) {
      return previewData.visibilityThreshold;
    }
    return typeof visibilityThreshold === 'number'
      ? visibilityThreshold
      : OBSERVER_OPTIONS.threshold;
  }, [previewData.visibilityThreshold, visibilityThreshold]);

  useIntersectionObserverRef(handleObserve, {
    targetRef: rootRef,
    ...OBSERVER_OPTIONS,
    threshold: finalVisibilityThreshold,
  });

  useEffect(() => {
    if (!isPageVisibilitySupported() || !newProductPreviewBehaviorOn) {
      return undefined;
    }
    const eventName = getVisibilityChangeEventName();

    const handleVisibilityChange = () => {
      if (!isPageVisible()) {
        clearPreviewTimeout();
      } else if (!productPreviewSentWithinCurrentEntry.current && isIntersecting.current) {
        setPreviewTimeout();
      }
    };

    document.addEventListener(eventName, handleVisibilityChange);

    return () => {
      document.removeEventListener(eventName, handleVisibilityChange);
    };
  }, [setPreviewTimeout, clearPreviewTimeout, newProductPreviewBehaviorOn]);
}

type PreviewEventWrapperProps = React.PropsWithChildren<Omit<PreviewData, 'rootRef'>>;

// Fallback for class components
export function PreviewEventWrapper({
  children,
  previewEvent,
  previewEventOptions,
  visibilityThreshold,
}: PreviewEventWrapperProps): JSX.Element {
  const rootRef = useRef<HTMLDivElement>(null);

  usePreviewEvent({
    rootRef,
    previewEvent,
    previewEventOptions,
    visibilityThreshold,
  });

  return <div ref={rootRef}>{children}</div>;
}
