export enum Algorythm {
  SHA256 = 'sha256',
}

const AlgorythmToString = {
  [Algorythm.SHA256]: __SERVER__ ? 'sha256' : 'SHA-256',
};

export async function digestMessage(
  message: string,
  algorythm: Algorythm = Algorythm.SHA256,
): Promise<string> {
  const algo = AlgorythmToString[algorythm];

  if (__SERVER__) {
    // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
    return require('crypto').createHash(algo).update(message).digest('hex');
  }

  if (!('TextEncoder' in global && 'crypto' in global && 'Uint8Array' in global)) {
    throw new Error('Browser does not support crypto digest.');
  }

  const msgUint8 = new TextEncoder().encode(message);
  const hashBuffer = await crypto.subtle.digest(algo, msgUint8);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');

  return hashHex;
}
