import PropTypes from 'prop-types';
import JsonText from './JsonText';

const Button = PropTypes.shape({
  id: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
});

export const Content = PropTypes.shape({
  negativeButton: Button,
  positiveButton: Button,
  texts: JsonText,
  text: JsonText,
  title: PropTypes.string,
});

export default PropTypes.shape({
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  needAccept: PropTypes.bool,
  payload: PropTypes.objectOf(Content).isRequired,
});
