import cn from 'classnames';
import {Locator} from 'components/Locator';
import {useDeepLink} from 'hooks/useDeepLink';
import {useScope} from 'hooks/useScope';
import React, {useCallback} from 'react';
import {Link} from 'react-router-dom';
import {isExternalUrl, isFirebaseUrl, trimJoomUrl} from 'utils/url';

import styles from './index.scss';

type AnyLinkProps = React.PropsWithChildren<{
  className?: string;
  focusable?: boolean;
  innerRef?: React.Ref<HTMLElement>;
  onClick?: () => void;
  rel?: string;
  target?: string;
  to: string | undefined;
  style?: React.CSSProperties;
  testId?: string;
}>;

export function AnyLink({
  children,
  className,
  focusable = false,
  innerRef,
  onClick,
  rel = 'noopener noreferrer',
  style,
  target = '_blank',
  to,
  testId,
}: AnyLinkProps): JSX.Element {
  const scope = useScope();
  const [url, deepLinkAction] = useDeepLink(to);

  const handleClick = useCallback(() => {
    if (onClick) {
      onClick();
    }

    if (!url && deepLinkAction) {
      deepLinkAction();
    }
  }, [onClick, url, deepLinkAction]);

  let content: React.ReactNode;

  if (url) {
    if (isFirebaseUrl(scope, url)) {
      content = (
        <a
          className={className}
          href={url}
          onClick={handleClick}
          ref={innerRef as React.Ref<HTMLAnchorElement>}
          rel={rel || undefined}
          style={style}
          tabIndex={focusable ? 0 : -1}
        >
          {children}
        </a>
      );
    } else if (isExternalUrl(url)) {
      content = (
        <a
          className={className}
          href={url}
          onClick={handleClick}
          ref={innerRef as React.Ref<HTMLAnchorElement>}
          rel={rel || undefined}
          style={style}
          tabIndex={focusable ? 0 : -1}
          target={target}
        >
          {children}
        </a>
      );
    } else {
      content = (
        <Link
          className={className}
          onClick={handleClick}
          ref={innerRef as React.Ref<HTMLAnchorElement>}
          style={style}
          tabIndex={focusable ? 0 : -1}
          to={trimJoomUrl(scope, url)}
        >
          {children}
        </Link>
      );
    }
  } else if (deepLinkAction || onClick) {
    content = (
      <button
        className={cn(styles.button, className)}
        onClick={handleClick}
        ref={innerRef as React.Ref<HTMLButtonElement>}
        style={style}
        tabIndex={focusable ? 0 : -1}
        type="button"
      >
        {children}
      </button>
    );
  } else {
    content = (
      <span className={className} ref={innerRef as React.Ref<HTMLSpanElement>} style={style}>
        {children}
      </span>
    );
  }

  return <Locator id={testId}>{content}</Locator>;
}
