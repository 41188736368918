import {UAParser} from 'ua-parser-js';

const ua = new UAParser();

if (__CLIENT__) {
  ua.setUA(window.navigator.userAgent);
}

export const SUPPORTS_SCROLL_BEHAVIOR = __SERVER__
  ? false
  : 'scrollBehavior' in document.documentElement?.style;

export async function polyfillScrollBehavior(): Promise<void> {
  const browserName = ua.getBrowser().name as string;

  if (
    __CLIENT__ &&
    !SUPPORTS_SCROLL_BEHAVIOR &&
    // Polyfill works incorrectly in Safari. Disable it.
    !['Safari', 'Mobile Safari'].includes(browserName)
  ) {
    await import('scroll-behavior-polyfill');
  }
}
