import React, {ReactNode} from 'react';

import styles from './index.scss';

export function TextList({items}: {items: ReactNode[]}): JSX.Element | null {
  if (items.length === 0) {
    return null;
  }

  return (
    <ul className={styles.textList}>
      {items.map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <li key={index} className={styles.textListItem}>
          {item}
        </li>
      ))}
    </ul>
  );
}
