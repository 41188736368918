import loadable from '@loadable/component';
import {Preloader} from 'components/Preloader';
import React from 'react';

export const Video = loadable(() => import('components/Video'), {
  resolveComponent: (module) => module.Video,
  fallback: <Preloader />,
});
export const VideoCSR = loadable(() => import('components/Video'), {
  resolveComponent: (module) => module.Video,
  fallback: <Preloader />,
  ssr: false,
});
