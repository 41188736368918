import HtmlContent from 'components/HtmlContent';
import {Image} from 'components/Image';
import React from 'react';
import {ScreenHeader as ScreenHeaderType} from 'types/ContentList/ScreenHeader';
import {convertBackendColorToCSSValue} from 'utils/styles/color';

import styles from './index.scss';

type Props = {
  screenHeader: ScreenHeaderType;
};

export const ScreenHeader: React.FC<Props> = ({screenHeader}: Props) => {
  const {title, subtitle, iconImage} = screenHeader;
  return (
    <div className={styles.screenHeader}>
      <Image block className={styles.image} contain image={iconImage} sizes="64px" loadingHidden />
      {title?.text ? (
        <div className={styles.title} style={{color: convertBackendColorToCSSValue(title?.color)}}>
          <HtmlContent html={title.text} />
        </div>
      ) : null}
      {subtitle?.text ? (
        <div
          className={styles.subtitle}
          style={{color: convertBackendColorToCSSValue(subtitle?.color)}}
        >
          <HtmlContent html={subtitle.text} />
        </div>
      ) : null}
    </div>
  );
};
