import classnames from 'classnames';
import Checkbox, {CheckboxShape} from 'components/Checkbox';
import {Image} from 'components/Image';
import {Locator} from 'components/Locator';
import {Price} from 'components/Price';
import {useAddProductToCollectionsPopup} from 'components/ProductCollections/AddProductToCollectionsPopup';
import {useProductLiteAnalytics, useProductLiteUrl} from 'components/ProductLite/Revision/hooks';
import {
  PointsCashbackBadge,
  PointsCashbackBadgeLocator,
} from 'components/ProductLite/Revision/PointsCashbackBadge';
import {Icon} from 'components/UIKit/Icon';
import {Scope} from 'config';
import {createLocator, Locator as LocatorType, Mark} from 'create-locator';
import {useDispatch, useSelector} from 'hooks/redux';
import {useAnalytics} from 'hooks/useAnalytics';
import {useBot} from 'hooks/useBot';
import {useDeviceVars} from 'hooks/useDeviceVars';
import {useIsBreakpointMobile} from 'hooks/useIsBreakpointMobile';
import {useLinkTarget} from 'hooks/useLinkTarget';
import {useMobWebAdInterlayer} from 'hooks/useMobWebAdInterlayer';
import {useScope} from 'hooks/useScope';
import PropTypes from 'prop-types';
import React, {
  ReactElement,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {FormattedMessage} from 'react-intl';
import {Link} from 'react-router-dom';
import {CSSTransition} from 'react-transition-group';
import ProductLiteShape from 'shapes/ProductLite';
import {VwFitShape} from 'shapes/VwFit';
import {updateProductCollectionSelection} from 'store/modules/productCollections/productCollectionSelection/actions';
import {
  getProductCollectionInSelection,
  getProductCollectionSelectionData,
} from 'store/modules/productCollections/productCollectionSelection/selectors';
import {PageSource} from 'types/PageSource';
import {isDiagonalGalleryOverlay, isPointsCashbackBadge} from 'types/ProductBadge';
import {ProductLite as ProductLiteType} from 'types/ProductLite';
import {VwFit} from 'types/VwFit';
import {convertBackendColorToCSSValue, getInlineColor} from 'utils/styles/color';

import {AddToCollectionButton} from './AddToCollectionButton';
import {
  DiscountBadge,
  IconBadges,
  IconBadgesLocator,
  SimpleBadges,
  SimpleBadgesLocator,
  TextBadges,
  TextBadgesLocator,
} from './Badges';
import {BrandBadge, BrandBadgeLocator} from './BrandBadge';
import {Button} from './Button';
import {DiscountBadge as OldDiscountBadge, DiscountBadgeLocator} from './DiscountBadge';
import {FavoriteButton} from './FavoriteButton';
import {ImageOverlays, ImageOverlaysLocator} from './ImageOverlays';
import {DiagonalGalleryOverlay} from './ImageOverlays/DiagonalGalleryOverlay';
import styles from './index.scss';
import {PromoProgressBar} from './PromoProgressBar';
import {Rating} from './Rating';
import {Restricted, RestrictedLocator} from './Restricted';
import {ReviewsCount} from './ReviewsCount';
import {ShippingBadge, ShippingBadgeLocator} from './ShippingBadge';
import {getImage} from './utils';

const COLLECTION_BUTTON_DURATION_MS = 4_500;

type Props = {
  className?: string;
  compact?: boolean;
  focusable?: boolean;
  hideRating?: boolean;
  isSensitive?: boolean;
  onAdultPopupOpen?: () => void;
  onFavorite?: (product: ProductLiteType) => void;
  onUnfavorite?: (product: ProductLiteType) => void;
  product: ProductLiteType;
  productInNewTab: boolean;
  pxFit?: number;
  showAdult?: boolean;
  sizes?: string;
  source?: string;
  vwFit?: VwFit;
  width?: string;
} & Mark<ProductLiteLocator>;

export type ProductLiteLocator = LocatorType<
  {
    brandBadge: BrandBadgeLocator;
    discountBadge: DiscountBadgeLocator;
    pointsCashbackBadge: PointsCashbackBadgeLocator;
    shippingBadge: ShippingBadgeLocator;
    forAdults: RestrictedLocator;
    sensitive: RestrictedLocator;
    price: LocatorType<void>;
    msrPrice: LocatorType<void>;
    imageOverlays: ImageOverlaysLocator;
    iconBadges: IconBadgesLocator;
    simpleBadges: SimpleBadgesLocator;
    textBadges: TextBadgesLocator;
  },
  {productId: string}
>;

export function ProductLite(props: Props): ReactElement {
  const {
    className,
    compact,
    focusable,
    hideRating,
    isSensitive,
    onAdultPopupOpen,
    onFavorite,
    onUnfavorite,
    product,
    productInNewTab,
    pxFit,
    showAdult,
    sizes,
    source,
    vwFit,
    width,
  } = props;
  const locator = createLocator(props);
  const {
    productPreviewRevisionHideName = false,
    productPreviewRevisionHideButton = false,
    productPreviewRevisionHideReviewsCount = false,
    productPreviewRevisionBrandNameInBadge = false,
    productCollectionsConfig,
    showFavoriteProducts = false,
    webProductPreviewRedesign8021,
  } = useDeviceVars();
  const dispatch = useDispatch();
  const bot = useBot();
  const analytics = useAnalytics();
  const scope = useScope();
  const mobile = useIsBreakpointMobile('sm');
  const rootRef = useRef(null);
  const rootStyle = useMemo(() => (width ? {width} : undefined), [width]);
  const mobWebAdInterlayerEnabled = useMobWebAdInterlayer([
    'hardcore',
    'superhardcore',
    'ios_nightmare',
  ]);
  const linkTarget = useLinkTarget(
    productInNewTab && !mobWebAdInterlayerEnabled ? '_blank' : undefined,
  );

  const isBlocked = Boolean(product.blockInfo?.isBlocked);
  const reviewsCount = product.reviewsCount?.value;
  const showFavorite =
    scope.is(Scope.GLOBAL) && Boolean((onFavorite && onUnfavorite) || product.favorite);

  const showRating = !hideRating && typeof product.rating === 'number';
  const showReviewsCount = Boolean(!productPreviewRevisionHideReviewsCount && reviewsCount);
  const showButton =
    !mobile && !compact && !productPreviewRevisionHideButton && (bot || product.inStock);
  const showPriceRow = !isBlocked;
  const showPrice = product.appearance?.showPrice !== false;
  const showMsrPrice = product.appearance?.showMsrPrice !== false;

  const {captions, promoProgressBar, appearance} = product;

  let isOutOfStockForPromo = false;

  if (appearance?.showAsSoldOut) {
    isOutOfStockForPromo = true;
  }

  if (promoProgressBar?.progress === 0) {
    isOutOfStockForPromo = true;
  }

  // Analytics: Product Preview
  const bodyRef = useRef<HTMLAnchorElement>(null);
  const buttonRef = useRef<HTMLAnchorElement>(null);

  useProductLiteAnalytics({
    product,
    rootRef,
    buttonRef,
    bodyRef,
  });

  // Operations with url
  const {url, handleInteraction} = useProductLiteUrl({product});
  const selectionData = useSelector(getProductCollectionSelectionData);
  const inSelection = useSelector(getProductCollectionInSelection);
  const isSelected = Boolean(selectionData?.[product.id]);

  // Product click
  const handleClick = useCallback(
    (event: SyntheticEvent) => {
      if (showAdult && onAdultPopupOpen) {
        event.preventDefault();
        onAdultPopupOpen();
      }
    },
    [onAdultPopupOpen, showAdult],
  );

  // Product select
  const handleSelect = useCallback(
    (event: SyntheticEvent) => {
      event.preventDefault();
      event.stopPropagation();
      dispatch(
        updateProductCollectionSelection({
          product,
          selected: !isSelected,
        }),
      );
    },
    [dispatch, isSelected, product],
  );

  const actionsRef = useRef<HTMLDivElement>(null);
  const isPendingFavorite = useRef(false);
  const [addToCollectionButtonActive, setAddToCollectionButtonActive] = useState(false);
  // Show add to collection button if:
  const addToCollectionButtonEnabled = Boolean(
    // and add to collection button is enabled
    productCollectionsConfig?.showAddToCollectionOnRevision &&
      // and this isn't favorites page
      source !== PageSource.FAVORITES &&
      // and this isn't self product collection page
      source !== PageSource.SELF_PRODUCT_COLLECTION,
  );
  const addToCollectionOnFavoriteEnabled = Boolean(
    // and this is favorites page
    source === PageSource.FAVORITES,
  );
  // Permanently show favorite button if:
  const favoriteButtonActive = Boolean(
    // product in favorites
    product.favorite ||
      // or aproduct collections is enabled
      showFavoriteProducts ||
      // or this is favorites page
      source === PageSource.FAVORITES ||
      // or this is self product collection page
      source === PageSource.SELF_PRODUCT_COLLECTION,
  );

  // call onFavorite has no guarantee that product be favorite
  // use async check after favorite button clicked
  useEffect(() => {
    if (addToCollectionButtonEnabled && isPendingFavorite.current && product.favorite) {
      isPendingFavorite.current = false;

      setAddToCollectionButtonActive(true);

      const timeoutId = setTimeout(
        () => setAddToCollectionButtonActive(false),
        COLLECTION_BUTTON_DURATION_MS,
      );

      return () => clearTimeout(timeoutId);
    }

    setAddToCollectionButtonActive(false);

    return undefined;
  }, [addToCollectionButtonEnabled, product.favorite]);

  const addProductToCollectionPopup = useAddProductToCollectionsPopup({
    products: [product],
    source: 'likeButton',
  });

  const handleAddProductToCollectionClick = useCallback(
    (event: SyntheticEvent) => {
      event.preventDefault();
      event.stopPropagation();
      addProductToCollectionPopup.open();
      analytics.sendEvent({
        type: 'productAddToProductCollectionClick',
        payload: {
          productId: product.id,
          source: 'product',
        },
      });
    },
    [addProductToCollectionPopup, analytics, product.id],
  );

  const handleFavorite = useCallback(async () => {
    if (addToCollectionOnFavoriteEnabled) {
      addProductToCollectionPopup.open();
      analytics.sendEvent({
        type: 'productAddToProductCollectionClick',
        payload: {
          productId: product.id,
          source: 'product',
        },
      });
    } else {
      isPendingFavorite.current = true;

      await onFavorite?.(product);
    }
  }, [
    addProductToCollectionPopup,
    addToCollectionOnFavoriteEnabled,
    analytics,
    onFavorite,
    product,
  ]);

  const handleUnfavorite = useCallback(async () => {
    isPendingFavorite.current = false;

    await onUnfavorite?.(product);
  }, [onUnfavorite, product]);

  const {showSpecialPriceIcon} = appearance || {};

  const showDiscountBadge = product.discount && !product.msrPricePrefix;

  return (
    <div
      className={classnames(
        styles.product,
        {[styles.compact!]: compact, [styles.withFavorite!]: showFavorite},
        className,
      )}
      ref={rootRef}
      style={rootStyle}
      {...locator({productId: product.id})}
    >
      <Locator id="ProductLiteContent" productid={product.id}>
        <Link
          className={styles.content}
          onClick={handleClick}
          onClickCapture={inSelection ? handleSelect : undefined}
          onMouseDown={handleInteraction}
          onTouchStart={handleInteraction}
          tabIndex={focusable ? undefined : -1}
          target={linkTarget}
          to={url}
          ref={bodyRef}
        >
          <div className={styles.square}>
            <div className={styles.squareContent}>
              <div className={styles.imagePlace}>
                <div
                  className={classnames(
                    styles.imageWrap,
                    (isSensitive || isBlocked) && styles.blur,
                    showAdult && styles.adult,
                    isOutOfStockForPromo ? styles.semiTransparent : null,
                  )}
                >
                  {!showAdult ? (
                    <Locator id="ProductLiteImage">
                      <Image
                        alt={product.accessibilityLabel || product.name}
                        className={styles.image}
                        contain
                        image={getImage(product)}
                        maxSourceSize={600}
                        multiply
                        pxFit={pxFit}
                        sizes={sizes}
                        vwFit={vwFit}
                        lcpImageCandidate
                      />
                    </Locator>
                  ) : null}
                </div>
              </div>
              {isSensitive && !showAdult ? (
                <Restricted compact={compact} {...locator.sensitive()}>
                  <FormattedMessage
                    description="Sensitive content warning"
                    defaultMessage="Sensitive content"
                  />
                </Restricted>
              ) : null}
              {showAdult && <Restricted compact={compact} {...locator.forAdults()} />}

              <div className={styles.actionButtons}>
                {showFavorite && (
                  <div
                    className={classnames(styles.actionButton, {
                      [styles.active!]: favoriteButtonActive,
                    })}
                  >
                    <FavoriteButton
                      compact={compact}
                      favorite={product.favorite}
                      onFavorite={handleFavorite}
                      onUnfavorite={handleUnfavorite}
                    />
                  </div>
                )}
              </div>

              <CSSTransition
                nodeRef={actionsRef}
                in={addToCollectionButtonActive}
                timeout={300}
                classNames={styles}
              >
                <div className={styles.actions} ref={actionsRef}>
                  {addToCollectionButtonEnabled && (
                    <div className={styles.addToCollection}>
                      <AddToCollectionButton onClick={handleAddProductToCollectionClick} />
                    </div>
                  )}
                </div>
              </CSSTransition>
              <ImageOverlays product={product} {...locator.imageOverlays()} />
              {inSelection ? (
                <div className={styles.select}>
                  <Checkbox checked={isSelected} shape={CheckboxShape.CIRCLE} size="l" />
                </div>
              ) : null}
            </div>
          </div>
          {isDiagonalGalleryOverlay(product?.galleryOverlay) ? (
            <div className={styles.diagonalGalleryOverlay}>
              <DiagonalGalleryOverlay overlay={product.galleryOverlay} />
            </div>
          ) : null}
          {showDiscountBadge || product.shippingBadge || product.brand || product.badge ? (
            <div className={styles.badges}>
              {showDiscountBadge && !webProductPreviewRedesign8021?.enabled ? (
                <OldDiscountBadge
                  discount={product.discount}
                  greyscale={isOutOfStockForPromo}
                  {...locator.discountBadge()}
                />
              ) : null}
              {product.badge && isPointsCashbackBadge(product.badge) ? (
                <PointsCashbackBadge badge={product.badge} {...locator.pointsCashbackBadge()} />
              ) : null}
              {product.shippingBadge ? (
                <ShippingBadge shippingBadge={product.shippingBadge} {...locator.shippingBadge()} />
              ) : null}
              {product.brand ? (
                <BrandBadge
                  brand={productPreviewRevisionBrandNameInBadge ? product.brand : undefined}
                  {...locator.brandBadge()}
                />
              ) : null}
            </div>
          ) : null}
          {showPriceRow && (
            <div className={styles.pricesBlock}>
              {showDiscountBadge && webProductPreviewRedesign8021?.enabled ? (
                <div className={styles.discountBadgeBlock}>
                  <DiscountBadge
                    discount={product.discount}
                    greyscale={isOutOfStockForPromo}
                    {...locator.discountBadge()}
                  />
                </div>
              ) : null}
              <div className={styles.prices}>
                {showPrice ? (
                  <>
                    {showSpecialPriceIcon ? (
                      <Locator id="SpecialPriceIcon">
                        <span
                          className={classnames(styles.specialPriceIcon, {
                            [styles.greyscale!]: isOutOfStockForPromo,
                          })}
                        >
                          <Icon type="mono" name="lightning-filled-16" width={16} height={16} />
                        </span>
                      </Locator>
                    ) : null}
                    <div
                      className={classnames(styles.price, {
                        [styles.greyscale!]: isOutOfStockForPromo,
                      })}
                      style={
                        !isOutOfStockForPromo
                          ? getInlineColor(product.price?.priceColor)
                          : undefined
                      }
                    >
                      <Price noSchema money={product.price} {...locator.price()} />
                    </div>
                  </>
                ) : null}
                {!showSpecialPriceIcon && showMsrPrice && product.msrPrice ? (
                  <div
                    className={classnames(styles.msrPrice, {
                      [styles.greyscale!]: isOutOfStockForPromo,
                    })}
                  >
                    {product.msrPricePrefix ? (
                      <span className={styles.msrPricePrefix}>{product.msrPricePrefix}</span>
                    ) : null}
                    <span
                      className={styles.msrPriceValue}
                      style={
                        !isOutOfStockForPromo
                          ? getInlineColor(product.msrPrice?.priceColor)
                          : undefined
                      }
                    >
                      <Price noSchema money={product.msrPrice} {...locator.msrPrice()} />
                    </span>
                  </div>
                ) : null}
              </div>
            </div>
          )}
          {captions?.length ? (
            <div className={styles.captions}>
              {captions?.map(({text, icon, color, iconPosition, weight}) => (
                <div
                  key={text}
                  className={classnames(styles.caption, {
                    [styles.greyscale!]: isOutOfStockForPromo,
                    [styles.bold!]: weight === 'bold',
                    [styles.iconEnd!]: iconPosition === 'end',
                  })}
                  style={
                    !isOutOfStockForPromo
                      ? {color: convertBackendColorToCSSValue(color)}
                      : undefined
                  }
                >
                  {icon ? (
                    <span className={styles.captionIcon}>
                      <Image height="16px" image={icon} />
                    </span>
                  ) : null}
                  <span className={styles.captionText}>{text}</span>
                </div>
              ))}
            </div>
          ) : null}
          {promoProgressBar ? (
            <PromoProgressBar
              promoProgressBar={promoProgressBar}
              greyscale={isOutOfStockForPromo}
            />
          ) : null}
          {showRating && product.rating !== undefined && (
            <Locator id="ProductLiteRating" rating-value={product.rating}>
              <div className={styles.rating}>
                <Rating compact={compact} rating={product.rating} />
                {showReviewsCount && <ReviewsCount count={product.reviewsCount?.value} />}
              </div>
            </Locator>
          )}
          {!productPreviewRevisionHideName && (
            <Locator id="ProductLiteName">
              <div
                className={classnames(styles.name, {
                  [styles.isBlocked!]: isBlocked,
                  [styles.greyscale!]: isOutOfStockForPromo,
                })}
              >
                {product.brand && !productPreviewRevisionBrandNameInBadge ? (
                  <>
                    <strong>{product.brand.name}</strong>{' '}
                  </>
                ) : null}
                {product.name}
              </div>
            </Locator>
          )}
          {product.badges && (
            <div className={styles.badgesBlock}>
              <SimpleBadges
                badges={product.badges}
                loadedTimeMs={product.loadedTimeMs}
                {...locator.simpleBadges()}
              />
            </div>
          )}
          {product.iconBadges && (
            <div className={styles.badgesBlock}>
              <IconBadges badges={product.iconBadges} {...locator.iconBadges()} />
            </div>
          )}
          {product.texts && (
            <div className={styles.badgesBlock}>
              <TextBadges badges={product.texts} {...locator.textBadges()} />
            </div>
          )}
        </Link>
      </Locator>
      {showButton && (
        <Locator id="ProductLiteBuyButton">
          <div className={styles.buttons}>
            <Button
              onClick={handleClick}
              onPreClick={handleInteraction}
              target={linkTarget}
              to={url}
              ref={buttonRef}
            >
              <FormattedMessage description="Button on cart page" defaultMessage="Go to checkout" />
            </Button>
          </div>
        </Locator>
      )}
    </div>
  );
}

ProductLite.propTypes = {
  className: PropTypes.string,
  compact: PropTypes.bool,
  focusable: PropTypes.bool,
  hideRating: PropTypes.bool,
  isSensitive: PropTypes.bool,
  onAdultPopupOpen: PropTypes.func,
  onFavorite: PropTypes.func,
  onUnfavorite: PropTypes.func,
  product: ProductLiteShape.isRequired,
  productInNewTab: PropTypes.bool,
  pxFit: PropTypes.number,
  showAdult: PropTypes.bool,
  sizes: PropTypes.string,
  source: PropTypes.string,
  vwFit: VwFitShape,
  width: PropTypes.string,
};

ProductLite.defaultProps = {
  className: null,
  compact: false,
  focusable: true,
  hideRating: false,
  isSensitive: false,
  onAdultPopupOpen: null,
  onFavorite: null,
  onUnfavorite: null,
  productInNewTab: false,
  pxFit: 220,
  showAdult: false,
  sizes: null,
  source: null,
  vwFit: 25,
  width: null,
};
