import {
  BannersListBannerAppearance,
  BannersListImageType,
  BannersListItem,
} from 'types/ContentList/BannersList';
import {colorToHexColor, gradientToRgbColor, isColorLight} from 'utils/colors';

export function isTextColorLight({appearance, gradient}: BannersListItem): boolean {
  try {
    if (appearance && appearance.textBackgroundColor) {
      return !isColorLight(colorToHexColor(appearance.textBackgroundColor));
    }

    if (gradient) {
      return !isColorLight(gradientToRgbColor(gradient));
    }
  } catch (e) {
    // do nothing
  }

  return false;
}

export function normalizeBannerAppearance(
  bannerAppearance?: BannersListBannerAppearance,
): BannersListBannerAppearance {
  // prettier-ignore
  const {
    imageType = BannersListImageType.PICTURE,
    showSubtitle = true,
    showTitle = true
  } = bannerAppearance || {};

  return {imageType, showSubtitle, showTitle};
}

export function isOldStyle(banner: BannersListItem): boolean {
  return !!(banner.background && !banner.gradient);
}
