import {useA11ySettingsPopupRender} from 'components/A11ySettingsPopup';
import {Button} from 'components/UIKit/Button';
import {usePopup} from 'hooks/usePopup';
import React, {useCallback} from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';

import styles from './styles.scss';

type Props = {
  onSkipClick?(): void;
};

const messages = defineMessages({
  skip: {
    description: '[a11y] Надпись на кнопке, переносящей фокус к содержимому страницы',
    defaultMessage: 'Skip to page contents',
  },
  settings: {
    description: '[a11y] Надпись на кнопке, открывающей попап с настройками a11y',
    defaultMessage: 'Open accessibility settings',
  },
});

export function Skipper({onSkipClick}: Props): JSX.Element {
  const a11yOptionsPopup = usePopup(useA11ySettingsPopupRender({}));

  const handleSettingsClick = useCallback(() => {
    a11yOptionsPopup.open();
  }, [a11yOptionsPopup]);

  return (
    <div className={styles.skipper}>
      {onSkipClick && (
        <Button tag="button" color="gray" onClick={onSkipClick}>
          <FormattedMessage {...messages.skip} />
        </Button>
      )}

      <Button tag="button" color="gray" onClick={handleSettingsClick}>
        <FormattedMessage {...messages.settings} />
      </Button>
    </div>
  );
}
