import {RootState} from 'store/rootReducer';
import {getSelectors} from 'store/utils/reducerCreators/simpleMapped/selectors';
import {createSelectorFactory} from 'store/utils/selectors';

const {
  isLoading: isContentListArbitraryLoading,
  isLoaded: isContentListArbitraryLoaded,
  getData: getContentListArbitrary,
  getError: getContentListArbitraryError,
  getRequest: getContentListArbitraryRequest,
} = getSelectors(createSelectorFactory((state: RootState) => state.contentListArbitrary));

export {
  isContentListArbitraryLoading,
  isContentListArbitraryLoaded,
  getContentListArbitrary,
  getContentListArbitraryError,
  getContentListArbitraryRequest,
};
