import {Scope} from 'config';
import {type Mark, createLocator, Locator} from 'create-locator';
import {useScope} from 'hooks/useScope';
import React, {FunctionComponent} from 'react';

import AlertImage from './img/alert-image.svg';
import styles from './index.scss';

export type AlertNothingFoundLocator = Locator<{
  illustration: void;
  title: void;
  description: void;
}>;

type Props = {
  title: string;
  description?: string;
} & Mark<AlertNothingFoundLocator>;

export const AlertNothingFound: FunctionComponent<Props> = React.memo(
  function AlertNothingFound(props) {
    const {title, description} = props;
    const scope = useScope();
    const locator = createLocator(props);

    return (
      <article className={styles.root} {...locator()}>
        {scope.is(Scope.GLOBAL) && (
          <img src={AlertImage} alt={title} className={styles.image} {...locator.illustration()} />
        )}
        <h1 className={styles.title} {...locator.title()}>
          {title}
        </h1>
        <p className={styles.description} {...locator.description()}>
          {description}
        </p>
      </article>
    );
  },
);
