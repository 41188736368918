import classnames from 'classnames/bind';
import {Avatar} from 'components/Avatar';
import {FormattedDate} from 'components/i18n/FormattedDate';
import {Locator} from 'components/Locator';
import {Stars} from 'components/Stars';
import {useBot} from 'hooks/useBot';
import React, {useMemo} from 'react';
import {defineMessages, useIntl} from 'react-intl';
import {Link} from 'react-router-dom';
import {GalleryItem} from 'types/GalleryItem';
import {ImageBundle} from 'types/Image';
import {isArrayNotEmpty} from 'utils/array';

import styles from './index.scss';
import {MediaItem} from './MediaItem';
import {MoreButton} from './MoreButton';
import {Text} from './Text';
import {useEnlargedMediaMWeb} from './useEnlargedMedia';
import {UserName} from './UserName';

const cn = classnames.bind(styles);

const messages = defineMessages({
  profileLink: {
    description: '[a11y] Описание ссылки на профиль',
    defaultMessage: '{fullName} user profile',
  },
});

type AuthorLinkProps = React.PropsWithChildren<{
  to?: string;
}> &
  React.HTMLAttributes<HTMLElement>;

function AuthorLink({children, to, ...props}: AuthorLinkProps): JSX.Element {
  if (to) {
    return (
      <Link {...props} to={to}>
        {children}
      </Link>
    );
  }

  return <div {...props}>{children}</div>;
}

type ReviewUserAvatarProps = {
  image?: ImageBundle;
  name?: string;
};

function AuthorAvatar({image, name}: ReviewUserAvatarProps): JSX.Element {
  const bot = useBot();
  const intl = useIntl();

  return (
    <Avatar
      image={bot ? undefined : image}
      pxFit={50}
      vwFit={{xs: 10, md: 5}}
      alt={name && intl.formatMessage(messages.profileLink, {fullName: name})}
    />
  );
}

type Props = {
  authorAvatar?: ImageBundle;
  authorName?: string;
  authorUrl?: string;
  createdTime?: number;
  defaultSortingId?: string;
  innerRef?: React.Ref<HTMLDivElement>;
  media?: GalleryItem[];
  onEdit?: () => void;
  onMediaClick?: (item: GalleryItem, index: number) => void;
  onReport?: () => void;
  originalLanguage?: string;
  originalText?: string;
  rating?: number;
  rows?: number;
  text?: string;
  translationPolicy?: string;
  variantLabel?: React.ReactNode;
  variantReviewLabel?: React.ReactNode;
  withMedia?: boolean;
  withVariant?: boolean;
};

export function Review({
  authorAvatar,
  authorName,
  authorUrl,
  createdTime,
  innerRef,
  media,
  onEdit,
  onMediaClick,
  onReport,
  originalLanguage,
  originalText,
  rating,
  rows,
  text,
  translationPolicy,
  variantLabel,
  variantReviewLabel,
  withMedia = true,
  withVariant = true,
}: Props): React.ReactElement {
  const bot = useBot();
  const enlargedMedia = useEnlargedMediaMWeb();

  const avatarEl = (
    <Locator id="review-user-avatar">
      <AuthorLink className={styles.avatar} to={authorUrl}>
        <AuthorAvatar image={authorAvatar} name={authorName} />
      </AuthorLink>
    </Locator>
  );

  const slicedMedia = useMemo(() => {
    return enlargedMedia ? media?.slice(0, 5) : media;
  }, [enlargedMedia, media]);

  const showRating = !!rating;
  const showVariant = withVariant && Boolean(variantLabel || variantReviewLabel);
  const showHeaderBottom = showRating || showVariant;
  const showMedia = withMedia && isArrayNotEmpty(slicedMedia);

  return (
    <div className={cn(styles.review, {enlargedMedia})} ref={innerRef}>
      {!enlargedMedia && avatarEl}
      <div className={styles.content}>
        <div className={styles.header}>
          {enlargedMedia && avatarEl}
          <div className={styles.headerMainContent}>
            <div className={styles.headerTopWrap}>
              <div className={styles.nameAndDate}>
                <AuthorLink className={styles.nameLink} to={authorUrl}>
                  <UserName name={authorName} />
                </AuthorLink>
                {createdTime && (
                  <Locator id="review-date">
                    <div className={styles.date}>
                      <FormattedDate
                        day="numeric"
                        month="long"
                        year="numeric"
                        value={createdTime}
                      />
                    </div>
                  </Locator>
                )}
              </div>
              {onReport && <MoreButton onReport={onReport} />}
            </div>
            {showHeaderBottom && (
              <div className={styles.headerBottomWrap}>
                {showRating && (
                  <Locator id="orderReview-rating" value={rating}>
                    <div className={styles.rating}>
                      <Stars rating={rating} />
                    </div>
                  </Locator>
                )}
                {showVariant && (
                  <Locator id="review-variant-name">
                    <div className={styles.variant}>
                      {variantReviewLabel && (
                        <div className={styles.variantReview}>{variantReviewLabel}</div>
                      )}
                      {variantLabel && <div className={styles.variantText}>{variantLabel}</div>}
                    </div>
                  </Locator>
                )}
              </div>
            )}
          </div>
        </div>
        <Text
          onEdit={onEdit}
          originalLanguage={originalLanguage}
          originalText={originalText}
          text={text}
          translationPolicy={translationPolicy}
          rows={rows}
        />
        {showMedia && (
          <div className={styles.medias}>
            {slicedMedia.map((item, index) => (
              <MediaItem
                item={item}
                index={index}
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                onClick={onMediaClick}
                pxFit={bot ? 500 : undefined}
                mediaLength={media!.length}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
