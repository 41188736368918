import {discardableByUser} from 'helpers/discardable';
import {cleanEmailPromoSubscription} from '../email/promoSubscription';

const LOAD = 'notifications/preferences/LOAD';
const LOAD_SUCCESS = 'notifications/preferences/LOAD_SUCCESS';
const LOAD_FAIL = 'notifications/preferences/LOAD_FAIL';

const SAVE = 'notifications/preferences/SAVE';
const SAVE_SUCCESS = 'notifications/preferences/SAVE_SUCCESS';
const SAVE_FAIL = 'notifications/preferences/SAVE_FAIL';

const initialState = {
  data: null,
  saving: false,
  loading: false,
  error: null,
  saveError: null,
  loaded: false,
};

export default discardableByUser(function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        error: null,
        loaded: false,
        loading: true,
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        error: null,
        data: action.result,
        loaded: true,
        loading: false,
      };
    case LOAD_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case SAVE:
      return {
        ...state,
        saveError: null,
        saving: true,
      };
    case SAVE_SUCCESS:
      return {
        ...state,
        error: null,
        data: action.result,
        loaded: true,
        saving: false,
      };
    case SAVE_FAIL:
      return {
        ...state,
        saveError: action.error,
        saving: false,
      };
    default:
      return state;
  }
});

export function isNotificationsPreferencesLoaded(globalState) {
  return !!globalState.notifications.preferences.loaded;
}

export function isNotificationsPreferencesLoading(globalState) {
  return !!globalState.notifications.preferences.loading;
}

export function isNotificationsPreferencesSaving(globalState) {
  return !!globalState.notifications.preferences.saving;
}

export function getNotificationsPreferencesError(globalState) {
  return globalState.notifications.preferences.error;
}

export function getNotificationsPreferencesSavingError(globalState) {
  return globalState.notifications.preferences.savingError;
}

export function getNotificationsPreferences(globalState) {
  return isNotificationsPreferencesLoaded(globalState)
    ? globalState.notifications.preferences.data
    : null;
}

export function loadNotificationsPreferences() {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client) =>
      client.api
        .get('/notifications/preferences')
        .then(({language, currency, body: {payload}}) => payload),
  };
}

export function saveNotificationsPreferences(body) {
  return {
    types: [SAVE, SAVE_SUCCESS, SAVE_FAIL],
    promise: (client, dispatch) =>
      client.api
        .post('/notifications/preferences', {body})
        .then(({language, currency, body: {payload}}) => {
          dispatch(cleanEmailPromoSubscription());
          return payload;
        }),
  };
}
