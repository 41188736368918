import {useStore} from 'hooks/redux';
import {useMemo} from 'react';
import {ActionCreator} from 'redux';
import {ArgumentTypes} from 'types/utility';
import {Action, Dispatch, FuncAction} from 'typesafe-actions';
import {TypedObject} from 'utils/object/typed';

type ExtractActionFromCreator<T extends ActionCreator<Action | FuncAction>> =
  T extends ActionCreator<infer K>
    ? (...args: ArgumentTypes<T>) => K extends FuncAction<infer U> ? U : K
    : never;

export function createAction<TActionCreator extends ActionCreator<Action | FuncAction>>(
  dispatch: Dispatch,
  actionCreator: TActionCreator,
): ExtractActionFromCreator<TActionCreator> {
  return ((...args) =>
    dispatch(actionCreator(...args))) as ExtractActionFromCreator<TActionCreator>;
}

export function useDispatchAction<T extends ActionCreator<Action | FuncAction>>(
  actionCreator: T,
): ExtractActionFromCreator<T> {
  const {dispatch} = useStore();
  return useMemo(() => createAction(dispatch, actionCreator), [dispatch, actionCreator]);
}

export function useDispatchActionMap<T extends Record<string, ActionCreator<Action | FuncAction>>>(
  dict: T,
): {
  [key in keyof T]: ExtractActionFromCreator<T[key]>;
} {
  const {dispatch} = useStore();

  return useMemo(
    () =>
      TypedObject.keys(dict).reduce(
        (result, key) => {
          result[key] = createAction(dispatch, dict[key]);
          return result;
        },
        {} as {
          [key in keyof T]: ExtractActionFromCreator<T[key]>;
        },
      ),
    [dict, dispatch],
  );
}
