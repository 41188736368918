import {useEffectOnce} from 'hooks/useEffectOnce';
import {EffectCallback, RefObject, useEffect, useRef} from 'react';
import {shallowDiffers} from 'utils/shallowDiffers';

export function useRefEffect(
  fn: EffectCallback,
  refs: RefObject<unknown>[],
  deps?: unknown[],
): void {
  const prevDeps = useRef<unknown[]>();
  const prevRefValues = useRef<unknown[]>();
  const cleanupRef = useRef<ReturnType<EffectCallback>>();

  useEffect(() => {
    const refValues = refs.map(({current}) => current);
    if (
      shallowDiffers(prevRefValues.current, refValues) ||
      shallowDiffers(prevDeps.current, deps)
    ) {
      cleanupRef.current?.();
      cleanupRef.current = fn();
      prevRefValues.current = refValues;
      prevDeps.current = deps;
    }
  });

  useEffectOnce(() => () => {
    cleanupRef.current?.();
    // need to clean refs for hot reloading
    cleanupRef.current = undefined;
    prevRefValues.current = undefined;
    prevDeps.current = undefined;
  });
}
