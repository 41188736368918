import {useMutableCallback} from 'hooks/useMutableCallback';
import React, {ReactElement, ReactNode, useCallback, useEffect, useRef} from 'react';

import styles from './index.scss';

const isResizeObserverSupported = typeof ResizeObserver !== 'undefined';

export function ResizeListener({
  children,
  onResize,
}: {
  children?: ReactNode;
  onResize: () => void;
}): ReactElement {
  const ref = useRef<HTMLDivElement>(null);
  const objRef = useRef<HTMLObjectElement>(null);

  const mutableResizeHandler = useMutableCallback(onResize);

  useEffect(() => {
    if (!isResizeObserverSupported || !ref.current) return undefined;

    const observer = new ResizeObserver(() => mutableResizeHandler());
    observer.observe(ref.current);

    return () => observer.disconnect();
  }, [mutableResizeHandler]);

  const handleLoad = useCallback(() => {
    objRef.current?.contentDocument?.defaultView?.addEventListener('resize', mutableResizeHandler);
  }, [mutableResizeHandler]);

  return (
    <div ref={ref} className={styles.root}>
      {children}
      {__CLIENT__ && !isResizeObserverSupported && (
        // eslint-disable-next-line jsx-a11y/alt-text
        <object
          suppressHydrationWarning
          aria-hidden
          onLoad={handleLoad}
          ref={objRef}
          type="text/html"
          data="about:blank"
          tabIndex={-1}
          className={styles.resizeListener}
        />
      )}
    </div>
  );
}
