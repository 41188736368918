import {noop} from 'lodash';
import {createContext, RefObject, useContext} from 'react';

import type {ScrollApiType} from './useApi';

export type ObserverCallback = (
  entries: IntersectionObserverEntry[],
  observer: IntersectionObserver,
) => void;

export type SliderItemType<ItemElement> = {
  element: ItemElement;
  index: number;
};

export type SliderContextType<
  ViewElement extends HTMLElement = HTMLElement,
  ItemElement extends HTMLElement = HTMLElement,
> = {
  api: ScrollApiType;
  itemsRef: RefObject<SliderItemType<ItemElement>[]>;
  observer: IntersectionObserver | undefined;
  observerCallbacksRef: RefObject<Set<ObserverCallback>>;
  viewRef: RefObject<ViewElement>;
  direction: 'horizontal' | 'vertical';
};

export const SliderContext = createContext<SliderContextType>({
  api: {
    scrollIntoView: noop,
    scrollTo: noop,
    scrollToIndex: noop,
  },
  viewRef: {current: null},
  observer: undefined,
  observerCallbacksRef: {current: null},
  itemsRef: {current: null},
  direction: 'horizontal',
});

export function useSliderContext<
  ViewElement extends HTMLElement,
  ItemElement extends HTMLElement,
>(): SliderContextType<ViewElement, ItemElement> {
  return useContext(SliderContext) as unknown as SliderContextType<ViewElement, ItemElement>;
}
