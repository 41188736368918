import React from 'react';
import {
  isAlignedDiscountBadge,
  isDiscountBadge,
  isGenericBadge,
  isOutOfStockBadge,
  ProductBadge as ProductBadgeType,
} from 'types/ProductBadge';

import {DiscountDiagonalBadge} from './DiscountDiagonalBadge';
import {DiscountHorizontalBadge} from './DiscountHorizontalBadge';
import {GenericBadge} from './GenericBadge';

type Props = {
  badge: ProductBadgeType;
  loadedTimeMs?: number;
};

export function ProductBadge({badge, loadedTimeMs}: Props): JSX.Element | null {
  if (isOutOfStockBadge(badge)) {
    return <GenericBadge>{badge.outOfStock.title}</GenericBadge>;
  }

  if (isDiscountBadge(badge)) {
    if (isAlignedDiscountBadge('diagonal', badge)) {
      return <DiscountDiagonalBadge badge={badge} />;
    }
    if (loadedTimeMs) {
      return <DiscountHorizontalBadge loadedTimeMs={loadedTimeMs} badge={badge} />;
    }
  }

  if (isGenericBadge(badge)) {
    return (
      <GenericBadge color={badge.generic.color} background={badge.generic.background}>
        {badge.generic.title}
      </GenericBadge>
    );
  }

  return null;
}
