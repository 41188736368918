import classnames from 'classnames/bind';
import HtmlContent from 'components/HtmlContent';
import {Locator} from 'components/Locator';
import {useReturnInfoPopup} from 'components/OrderCard/ReturnInfoPopup';
import {Image} from 'components/Image';
import {deepLinks, isDeepLink} from 'helpers/deepLink';
import {useTimer} from 'hooks/useTimer';
import PropTypes from 'prop-types';
import React, {memo, useCallback, useMemo, useRef} from 'react';
import {OrderBannerShape} from 'shapes/OrderBanner';
import {ReturnInfoShape} from 'shapes/ReturnInfo';
import {getValueByPath} from 'utils/object';
import {useDeepLinkNavigate} from 'hooks/useDeepLink';
import {CopyContainer} from 'components/CopyContainer';
import {Icon} from 'components/UIKit/Icon';
import {Button} from 'components/UIKit/Button';
import {isColorLight} from 'utils/colors';
import {useBannerPreview} from 'components/ContentList/hooks';
import {Size as TimerSize, Timer} from 'components/UIKit/Timer';
import {useRtl} from 'hooks/useRtl';
import {rootLocator} from 'utils/rootLocator';
import {isExternalUrl} from 'utils/url';
import {Text} from 'components/Text';
import htmlStyles from './htmlStyles.scss';
import styles from './index.scss';
import {useOrderBannerInlineStyles} from './useInlineStyles';

const cn = classnames.bind(styles);
const htmlClassNameMapper = classnames.bind(htmlStyles);

const locator = rootLocator.orderPage.banner;

export const OrderBanner = memo(function OrderBanner({
  banner,
  banner: {id, message, timer, title, eventParams},
  loadedTimeMs,
  returnInfo,
  showDeliveryInfo,
}) {
  const contentRef = useRef(null);
  useBannerPreview({
    bannerId: id,
    eventParams,
    rootRef: contentRef,
  });

  const navigate = useDeepLinkNavigate();
  const rtl = useRtl();
  const icon = getValueByPath(banner, 'icon');

  const pickupInfoItems = getValueByPath(banner, 'payload', 'pickupInfo', 'items') || [];
  const hasPickupInfo = pickupInfoItems.length > 0;

  const pickupInfoButton = getValueByPath(banner, 'payload', 'pickupInfo', 'button');

  const url = pickupInfoButton?.url || banner.url;

  const deepLink = deepLinks.match(url);
  const deepLinkParams = deepLink?.params;

  const hasReturnInfo = Boolean(returnInfo && returnInfo.message);
  const hasReturnAction = deepLink?.view === 'orders' && deepLinkParams.action === 'return';
  const hasReturnInfoPopup = hasReturnInfo && hasReturnAction;

  const returnInfoPopup = useReturnInfoPopup({returnInfo});

  const inlineStyles = useOrderBannerInlineStyles(banner);

  const handlePickupInfoButtonClick = useCallback(async () => {
    if (isDeepLink(url)) {
      navigate(url);
    } else {
      showDeliveryInfo();
    }
  }, [navigate, showDeliveryInfo, url]);

  const handleReturnInfoClick = useCallback(async () => {
    try {
      if (isDeepLink(url)) {
        await navigate(url);
      } else if (isExternalUrl(url)) {
        window.open(url, '_blank', 'noopener,noreferrer')?.focus();
      } else {
        returnInfoPopup.open();
      }
    } catch (e) {
      returnInfoPopup.open();
    }
  }, [navigate, url, returnInfoPopup]);

  const isClickable = Boolean(hasReturnInfoPopup || url) && !pickupInfoButton;

  const clickProps = !isClickable
    ? {}
    : {
        role: 'button',
        tabIndex: 0,
        onClick: handleReturnInfoClick,
      };

  const iconEl = icon ? (
    <div className={styles.image}>
      <Image contain loadingHidden height="100%" width="100%" image={icon} sizes="24px" />
    </div>
  ) : null;

  const buttonColor = useMemo(
    () => (banner.textColor && isColorLight(banner.textColor) ? 'white' : 'primary'),
    [banner.textColor],
  );

  // Timer
  const [timerShown, timestamp] = useTimer(loadedTimeMs, timer?.timeRemainingMs);

  const handleTimerComplete = useCallback(() => {
    window.location.reload();
  }, []);

  return (
    <>
      <Locator id="OrderBanner" bannerId={id}>
        <div
          className={cn('bannerWrapper', {
            clickable: isClickable,
          })}
          style={inlineStyles}
          ref={contentRef}
        >
          <div className={styles.contentWrapper} {...clickProps}>
            <div className={styles.text}>
              {Boolean(title || timerShown) && (
                <div className={styles.title}>
                  {iconEl}
                  <div className={styles.titleContent}>
                    <div className={styles.titleText}>{title}</div>
                    {timerShown && (
                      <div className={styles.titleTimer}>
                        <Timer
                          completeMessage={timer?.text && <Text text={timer.text} />}
                          onComplete={handleTimerComplete}
                          size={TimerSize.INLINE}
                          timestamp={timestamp}
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}
              {Boolean(message) && (
                <div className={styles.messageLine}>
                  {!title && iconEl}
                  <div
                    className={classnames(styles.message, {
                      [htmlStyles.wholeClickable]: isClickable,
                    })}
                  >
                    <HtmlContent html={message} classNameMapper={htmlClassNameMapper} />
                  </div>
                </div>
              )}
            </div>
            {isClickable && (
              <span className={styles.chevronWrapper} {...locator.button()}>
                {rtl ? (
                  <Icon type="mono" name="chevron-left-linear-24" />
                ) : (
                  <Icon type="mono" name="chevron-right-linear-24" />
                )}
              </span>
            )}
            {pickupInfoButton ? (
              <div className={styles.buttonWrapperDesktop}>
                <Locator id="OrderBannerPickupInfoButton">
                  <Button
                    shape="round"
                    tag="button"
                    size="medium"
                    color={buttonColor}
                    onClick={handlePickupInfoButtonClick}
                  >
                    {pickupInfoButton.text}
                  </Button>
                </Locator>
              </div>
            ) : null}
          </div>
          {pickupInfoButton ? (
            <div
              className={cn('buttonWrapperMobile', {
                iconMargin: icon && (!title || !message),
                center: hasPickupInfo,
              })}
            >
              <Locator id="OrderBannerPickupInfoButtonMobile">
                <Button
                  shape="round"
                  tag="button"
                  size="small"
                  color={buttonColor}
                  onClick={handlePickupInfoButtonClick}
                >
                  {pickupInfoButton.text}
                </Button>
              </Locator>
            </div>
          ) : null}
          {hasPickupInfo && (
            <div className={styles.pickupInfo}>
              {pickupInfoItems.map((item) => (
                <div key={item.value} className={styles.pickupInfoItem}>
                  <div className={styles.pickupInfoItemName}>{item.name}</div>
                  <div className={styles.pickupInfoItemValueWrap}>
                    <CopyContainer value={item.value}>
                      <div className={styles.pickupInfoItemValue}>
                        {item.value}
                        <span className={styles.copyIcon}>
                          <Icon type="mono" name="copy-linear-24" />
                        </span>
                      </div>
                    </CopyContainer>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </Locator>
    </>
  );
});

OrderBanner.propTypes = {
  banner: OrderBannerShape.isRequired,
  loadedTimeMs: PropTypes.number.isRequired,
  returnInfo: ReturnInfoShape,
  showDeliveryInfo: PropTypes.func,
};

OrderBanner.defaultProps = {
  returnInfo: null,
  showDeliveryInfo: null,
};
