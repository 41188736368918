import {FormattedDate} from 'components/i18n/FormattedDate';
import {Locator} from 'components/Locator';
import React, {memo} from 'react';
import {FormattedMessage} from 'react-intl';

import styles from './State.scss';

const getFormattedDate = (time?: number) => (
  <FormattedDate day="2-digit" month="2-digit" year="2-digit" value={time} />
);

type Props = {
  shippingMaxTimeMs?: number;
  shippingMinTimeMs?: number;
  deliveredTimeMs?: number;
};

export const StateDate = memo(function StateDate({
  shippingMaxTimeMs,
  shippingMinTimeMs,
  deliveredTimeMs,
}: Props): JSX.Element | null {
  let shippingContent;

  if (deliveredTimeMs) {
    shippingContent = (
      <FormattedDate key="date" value={deliveredTimeMs} day="numeric" month="long" year="numeric" />
    );
  } else {
    const values = {
      shippingMinTimeMs: getFormattedDate(shippingMinTimeMs),
      shippingMaxTimeMs: getFormattedDate(shippingMaxTimeMs),
    };

    if (shippingMinTimeMs && shippingMaxTimeMs) {
      shippingContent = (
        <FormattedMessage
          description="Срок доставки в виде интервала, например: «03.07 - 26.07»"
          defaultMessage="{shippingMinTimeMs} – {shippingMaxTimeMs}"
          values={values}
        />
      );
    } else if (!shippingMinTimeMs && shippingMaxTimeMs) {
      shippingContent = (
        <FormattedMessage
          description="Максимальная дата доставки"
          defaultMessage="until {shippingMaxTimeMs}"
          values={values}
        />
      );
    } else if (shippingMinTimeMs && !shippingMaxTimeMs) {
      shippingContent = (
        <FormattedMessage
          description="Минимальная дата доставки"
          defaultMessage="from {shippingMinTimeMs}"
          values={values}
        />
      );
    }
  }

  return shippingContent ? (
    <div className={styles.date}>
      <span className={styles.label}>
        {deliveredTimeMs ? (
          <FormattedMessage
            defaultMessage="Delivery date:"
            description="Parcel card shipped label for delivered parcel"
          />
        ) : (
          <FormattedMessage
            defaultMessage="Delivery date:"
            description="Parcel card shipped label"
          />
        )}
      </span>
      &nbsp;
      <Locator
        id={
          deliveredTimeMs ? 'parcel-tracking-delivered-date' : 'parcel-tracking-delivery-estimation'
        }
      >
        <span className={styles.value}>{shippingContent}</span>
      </Locator>
    </div>
  ) : null;
});
