import {enhanceCartItem} from 'store/enhancer/cartItem';
import {enhanceProductsList} from 'store/enhancer/productsList';
import {enhanceLoadedTime} from 'store/utils/enhancers';
import {Cart, CartRaw} from 'types/Cart';
import {CartOptions} from 'types/CartGroup/Cart';
import {CartItem} from 'types/CartGroup/CartItem';
import {contentListProductsList} from 'types/ContentList/ContentListProductsList';

function enhanceCartItems(
  items: CartItem[],
  selectedList: string[],
  options: CartOptions,
): CartItem[] {
  return items.map((item) => enhanceCartItem(item, selectedList, options));
}

export function enhanceCart<T extends CartRaw>(cart: T, options: CartOptions): typeof result {
  const result = cart as unknown as Omit<T, 'relatedProducts' | 'relatedProductsList'> & Cart;
  const selectedList = cart.selectionInfo?.selectedItemIds || [];

  result.currency = options.currency;
  result.cartItems = cart.cartItems ? enhanceCartItems(cart.cartItems, selectedList, options) : [];

  if (cart.relatedProducts) {
    result.relatedProducts = contentListProductsList(
      cart.relatedProducts.id,
      enhanceProductsList(cart.relatedProducts, options),
    );
  }

  if (cart.relatedProductsList) {
    result.relatedProductsList = cart.relatedProductsList.map((list) =>
      contentListProductsList(list.id, enhanceProductsList(list, options)),
    );
  }

  enhanceLoadedTime(result);

  return result;
}
