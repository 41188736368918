import {Image} from 'components/Image';
import React, {CSSProperties} from 'react';
import {Background as BackgroundType} from 'types/Background';
import {Gradient, LinearMulticolorGradient} from 'types/Gradient';
import {ImageBundle} from 'types/Image';
import {VwFit} from 'types/VwFit';
import {
  convertAnyBackendGradientToCSSValue,
  convertBackendColorToCSSValue,
} from 'utils/styles/color';

import styles from './index.scss';

type Props = {
  background?: BackgroundType;
  gradient?: Gradient | LinearMulticolorGradient;
  image?: ImageBundle;
  vwFit?: VwFit;
  objectPosition?: string;
};

export const Background = React.memo(function Background({
  background,
  gradient,
  image,
  vwFit,
  objectPosition,
}: Props): JSX.Element | null {
  let currentGradient: Gradient | LinearMulticolorGradient | undefined = gradient;
  let currentImage: ImageBundle | undefined = image;

  if (background) {
    if ('image' in background) {
      currentImage = background.image;
    } else if ('gradient' in background) {
      currentGradient = background.gradient;
    }
  }

  if (currentGradient) {
    const style: CSSProperties = {};

    if (
      'colorStart' in currentGradient &&
      currentGradient.colorStart === currentGradient.colorEnd
    ) {
      style.backgroundColor = convertBackendColorToCSSValue(currentGradient.colorStart);
    } else {
      style.background = convertAnyBackendGradientToCSSValue(currentGradient);
    }

    return <div className={styles.background} style={style} />;
  }

  if (currentImage) {
    return (
      <Image
        className={styles.background}
        cover
        image={currentImage}
        loadingHidden
        objectPosition={objectPosition || 'center center'}
        vwFit={vwFit}
        lcpImageCandidate
      />
    );
  }

  return null;
});
