import {Locator} from 'components/Locator';
import {Tooltip} from 'components/Tooltip';
import {useOnClickOutside} from 'hooks/useOnClickOutside';
import {useTimeout} from 'hooks/useTimeout';
import React, {useCallback, useRef, useState} from 'react';
import {FormattedMessage} from 'react-intl';

import styles from './index.scss';

const TOOLTIP_TTL = 5000;

interface CopyContainerProps {
  children: React.ReactNode;
  value: string | (() => string);
  onSuccess?(): void;
  'data-testid'?: string;
}

export const CopyContainer: React.FC<CopyContainerProps> = ({
  children,
  value,
  onSuccess,
  'data-testid': testId,
}: CopyContainerProps) => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  const [tooltipShown, setShowTooltip] = useState(false);

  const [, cancel, reset] = useTimeout(() => {
    setShowTooltip(false);
  }, TOOLTIP_TTL);

  const showTooltip = useCallback(() => {
    reset();
    setShowTooltip(true);
  }, [reset]);

  const hideTooltip = useCallback(() => {
    cancel();
    setShowTooltip(false);
  }, [cancel]);

  useOnClickOutside(buttonRef, hideTooltip);

  const handleClick = useCallback(() => {
    const text = typeof value === 'string' ? value : value();
    if (!navigator.clipboard) {
      return;
    }

    navigator.clipboard.writeText(text).then(() => {
      showTooltip();
      if (typeof onSuccess === 'function') {
        onSuccess();
      }
    });
  }, [onSuccess, showTooltip, value]);

  return (
    <button type="button" ref={buttonRef} className={styles.container} onClick={handleClick}>
      {testId ? (
        <Locator id={testId}>
          <span>{children}</span>
        </Locator>
      ) : (
        children
      )}
      {tooltipShown && (
        <Tooltip>
          <span className={styles.copiedTooltipText}>
            <FormattedMessage
              description="Generic text in tooltip shown on successful copying to clipboard"
              defaultMessage="Copied"
            />
          </span>
        </Tooltip>
      )}
    </button>
  );
};
