import {gtag} from 'helpers/ApiClient/Analytics/counters/dataLayer';
import {
  ConsentArg,
  convertCookiesSettingsToGtmConsentParams,
  convertCookiesSettingsToGtmCookiesSettings,
} from 'helpers/ApiClient/Analytics/gtm';
import {requestActionCreator} from 'store/utils/requestActions';
import {ClientBackendResponse} from 'types/ClientBackendResponse';
import {CookiesSettings, CookiesSettingsResponse} from 'types/CookiesSettings';
import {FuncAction} from 'typesafe-actions';

import {set, update} from './actions';
import {
  getCookiesSettings,
  getCookiesSettingsUpdateError,
  isCookiesSettingsUpdating,
} from './selectors';

type UpdateCookiesSettingsResult = FuncAction<Promise<CookiesSettings>>;

function loadCookiesSettings(): UpdateCookiesSettingsResult {
  return requestActionCreator(update, {}, (store, client) =>
    client.api
      .post<ClientBackendResponse<CookiesSettingsResponse>>('/users/cookies/get')
      .then((response) => response.body.payload.cookies),
  );
}

function updateCookiesSettings(settings: CookiesSettings): UpdateCookiesSettingsResult {
  return requestActionCreator(update, {}, (store, client) =>
    client.api
      .post<ClientBackendResponse<CookiesSettingsResponse>>('/users/cookies', {
        body: {cookies: settings},
      })
      .then((response) => {
        const {dataLayer} = client.analytics;
        dataLayer({
          event: 'Common. Cookie settings change',
          cookiesSettings: convertCookiesSettingsToGtmCookiesSettings(settings),
        });
        gtag('consent', ConsentArg.UPDATE, convertCookiesSettingsToGtmConsentParams(settings));
        return response.body.payload.cookies;
      }),
  );
}

const setCookiesSettings = set;

export {
  updateCookiesSettings,
  loadCookiesSettings,
  setCookiesSettings,
  getCookiesSettings,
  UpdateCookiesSettingsResult,
  getCookiesSettingsUpdateError,
  isCookiesSettingsUpdating,
};
