import classnames from 'classnames/bind';
import React from 'react';

import styles from './index.scss';
import TrashIcon from './trash.jsx.svg';

type Props = React.HTMLAttributes<HTMLSpanElement> & {
  block?: boolean;
  color?: string;
  disabled?: boolean;
  hoverable?: boolean;
};

const cn = classnames.bind(styles);

const Trash = React.memo(function Trash(props: Props) {
  const {
    block = false,
    color = '#ef5350',
    disabled = false,
    hoverable = false,
    ...restProps
  } = props;

  return (
    <span style={{color}} {...restProps}>
      <TrashIcon
        className={cn('icon', {block, disabled, hoverable: !disabled && hoverable})}
        height="1.2em"
        width="1em"
        viewBox="0 0 18 18"
      />
    </span>
  );
});

// eslint-disable-next-line import/no-default-export
export default Trash;
