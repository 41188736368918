import {CSSProperties, useMemo} from 'react';
import {OrderBanner} from 'types/OrderBanner';
import {
  convertAnyBackendGradientToCSSValue,
  convertBackendColorToCSSValue,
} from 'utils/styles/color';

function getBackgroundColor(banner: OrderBanner): string | undefined {
  if (banner.backgroundGradient) {
    return convertAnyBackendGradientToCSSValue(banner.backgroundGradient);
  }
  if (banner.backgroundStartColor && banner.backgroundEndColor) {
    const startColor = convertBackendColorToCSSValue(banner.backgroundStartColor);
    const endColor = convertBackendColorToCSSValue(banner.backgroundEndColor);
    return `linear-gradient(90deg, ${startColor}, ${endColor})`;
  }
  return undefined;
}

export function useOrderBannerInlineStyles(banner: OrderBanner): CSSProperties {
  return useMemo(
    () => ({
      background: getBackgroundColor(banner),
      color: convertBackendColorToCSSValue(banner.textColor),
    }),
    [banner],
  );
}
