import type {Props as ContentListProps} from 'components/ContentList';
import {type ContentListLocator, ContentList} from 'components/ContentList';
import {type Mark, createLocator} from 'create-locator';
import {useDispatchAction} from 'hooks/useDispatch';
import React, {useCallback, useEffect, useState} from 'react';
import {loadArbitraryContentList} from 'store/modules/contentListArbitrary';
import {ProductsList as ProductsListType} from 'types/ContentList/ContentListProductsList';

import styles from './styles.scss';

type Props = {
  content: ProductsListType;
  contentListProps?: Omit<ContentListProps, 'items' | 'onMore'>;
  source?: string;
  id?: string;
} & Mark<ContentListLocator>;

export const GridProductsList = (props: Props): JSX.Element => {
  const {id, content, contentListProps, source} = props;
  const locator = createLocator(props);
  const {endpointPath, endpointParams, title} = content;

  const [items, setItems] = useState(content.items);
  const [nextPageToken, setNextPageToken] = useState(content.nextPageToken);

  const load = useDispatchAction(loadArbitraryContentList);
  const handleMore = useCallback(() => {
    if (!endpointPath) {
      return Promise.reject();
    }

    return load({endpointPath, endpointParams, nextPageToken, itemId: id}).then((payload) => {
      setItems([...items, ...payload.items]);
      setNextPageToken(payload.nextPageToken);
    });
  }, [endpointParams, endpointPath, id, items, load, nextPageToken]);

  useEffect(() => {
    setItems(content.items);
  }, [content.items]);

  return (
    <div>
      {title && <h3 className={styles.header}>{title}</h3>}
      <div className={styles.content}>
        <ContentList
          source={source}
          {...contentListProps}
          onMore={nextPageToken ? handleMore : undefined}
          items={items}
          {...locator()}
        />
      </div>
    </div>
  );
};
