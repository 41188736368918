import {getDevDomainByScopeMap} from 'helpers/ApiClient/Scope/utils';
import {useApiClient} from 'hooks/useApiClient';
import {useScope} from 'hooks/useScope';
import React, {ReactElement, useCallback, useState} from 'react';
import {useLocation} from 'react-router';

import config, {DomainConfigId} from '../../config';

function ForcedDomainScopeByMap({
  domainByScopeMap,
}: {
  domainByScopeMap: Record<string, string>;
}): ReactElement {
  const scope = useScope();
  const {pathname, search} = useLocation();
  const [configId, setConfigId] = useState<DomainConfigId>(scope.domainConfigId);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const nextConfigId = event.target.value as DomainConfigId;
      setConfigId(nextConfigId);

      window.location.assign(`https://${domainByScopeMap[nextConfigId]}${pathname}${search}`);
    },
    [domainByScopeMap, pathname, search],
  );

  return (
    <select name="forcedEndpointsConfig" value={configId} onChange={handleChange}>
      {Object.keys(domainByScopeMap).map((id) => {
        return (
          <option value={id} key={id}>
            {id} domain
          </option>
        );
      })}
    </select>
  );
}

function ForcedDomainScopeByCookie(): ReactElement {
  const client = useApiClient();
  const [configId, setConfigId] = useState<DomainConfigId | ''>(
    () => client.cookiesRegistry.devDomainScope.restore() || '',
  );

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const nextConfigId = event.target.value as DomainConfigId | '';
      setConfigId(nextConfigId);
      client.cookiesRegistry.devDomainScope.store(nextConfigId || undefined);

      const url = new URL(window.location.href);
      url.searchParams.set('domainConfigId', nextConfigId);
      window.location.replace(url);
    },
    [client.cookiesRegistry.devDomainScope],
  );

  return (
    <select name="forcedEndpointsConfig" value={configId} onChange={handleChange}>
      <option value="">Auto domain scope</option>
      {Object.values(config.scopes).map(({id}) => (
        <option value={id} key={id}>
          {id} domain scope
        </option>
      ))}
    </select>
  );
}

export function ForcedDomainScope(): ReactElement {
  const domainByScopeMap = getDevDomainByScopeMap();

  return domainByScopeMap ? (
    <ForcedDomainScopeByMap domainByScopeMap={domainByScopeMap} />
  ) : (
    <ForcedDomainScopeByCookie />
  );
}
