import {ErrorIllustration} from 'components/ErrorIllustration';
import {Scope} from 'config';
import {useScope} from 'hooks/useScope';
import {useSupportEmail} from 'hooks/useSupportEmail';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import {Error, isApiError} from 'types/Error';

import styles from './Error.scss';

type Props = {
  error?: Error;
};

export const ContentListPopupError = ({error}: Props): JSX.Element => {
  const scope = useScope();
  const supportEmail = useSupportEmail();

  return (
    <div className={styles.errorContainer}>
      {scope.is(Scope.GLOBAL) && (
        <div className={styles.image}>
          <ErrorIllustration source="contentListPopupError" error={error} />
        </div>
      )}
      <div className={styles.title}>
        {(isApiError(error) && error.userTitle) || (
          <FormattedMessage
            description="Header of ContentListPopup Error"
            defaultMessage="Oops! Something went wrong."
          />
        )}
      </div>
      <div className={styles.text}>
        {(isApiError(error) && error.userMessage) || (
          <FormattedMessage
            description="Text of ContentListPopup Error"
            defaultMessage="Try again or contact our customer support: {email}"
            values={{
              email: (
                <a key="email" className={styles.link} href={`mailto:${supportEmail}`}>
                  {supportEmail}
                </a>
              ),
            }}
          />
        )}
      </div>
    </div>
  );
};
