import {CSSProperties} from 'react';
import {Coupon} from 'types/Coupon';
import {CouponCard} from 'types/CouponCard';
import {convertBackendColorToCSSValue, convertBackendGradientToCSSValue} from 'utils/styles/color';

const DEFAULT_TEXT_COLOR = 'ffffff';
const DEFAULT_LEFT_COLOR = 'f06292';
const DEFAULT_RIGHT_COLOR = '8e62dc';

type Colors = {
  color: string;
  end: string;
  start: string;
  id: string;
};

const DISABLED_COLORS = {
  color: '#fff',
  end: '#c7c7cc',
  start: '#c7c7cc',
  id: 'disabled',
};

export function getDisabledCouponColors(): Colors {
  return DISABLED_COLORS;
}

export function getCouponColors(coupon?: Coupon | null): Colors | null {
  if (!coupon) {
    return null;
  }

  const {leftColor, rightColor, textColor} = coupon;
  return {
    start: convertBackendColorToCSSValue(leftColor || DEFAULT_LEFT_COLOR),
    end: convertBackendColorToCSSValue(rightColor || DEFAULT_RIGHT_COLOR),
    color: convertBackendColorToCSSValue(textColor || DEFAULT_TEXT_COLOR),
    id: `${leftColor}-${rightColor}-${textColor}`,
  };
}

export function getCouponStyle(colors?: Colors | null): CSSProperties | null {
  if (!colors) {
    return null;
  }

  const {color, start, end} = colors;
  return {
    color,
    backgroundColor: end,
    backgroundImage: `linear-gradient(90deg, ${start}, ${end})`,
  };
}

export function getCouponCardStyle(coupon: CouponCard | undefined): CSSProperties | undefined {
  if (!coupon) {
    return undefined;
  }

  const backgroundImage = convertBackendGradientToCSSValue(coupon.background?.gradient, '90deg');

  return backgroundImage ? {backgroundImage} : undefined;
}
