import canvasConfetti, {Options} from 'canvas-confetti';

import {isScreenshot} from './isScreenshot';

/**
 * Shoot a blast of confetti via canvas
 */
export function confetti(options: Options): Promise<null | undefined> | null {
  if (isScreenshot()) {
    return Promise.resolve(null);
  }
  return canvasConfetti(options);
}
