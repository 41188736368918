import {FormattedPhone} from 'components/FormattedPhone/loadable';
import {Locator} from 'components/Locator';
import React, {useMemo} from 'react';
import {FormattedList} from 'react-intl';

import styles from './index.scss';

type Props = {
  additionalPhoneNumbers?: string[];
  phoneNumber: string;
};

export const PhoneLinks = React.memo(function PhoneLinks({
  additionalPhoneNumbers,
  phoneNumber,
}: Props) {
  const phones = useMemo(
    () =>
      [phoneNumber, ...(additionalPhoneNumbers || [])].map((phone) => (
        <Locator id="DeliveryPointPhoneNumber" key={phone} phonenumber={phone}>
          <a className={styles.telLink} href={`tel:${phone}`}>
            <FormattedPhone phone={phone} fallback={<>{phone}</>} />
          </a>
        </Locator>
      )),
    [phoneNumber, additionalPhoneNumbers],
  );

  return <FormattedList style="narrow" value={phones} />;
});
