import {clearAllBodyScrollLocks, disableBodyScroll} from 'body-scroll-lock';
import classnames from 'classnames/bind';
import Button from 'components/Button';
import {Locator} from 'components/Locator';
import {Portal} from 'components/Portal';
import {useMergedRef} from 'hooks/useMergedRef';
import throttle from 'lodash/throttle';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {Hammer} from 'utils/hammer';
import {rootLocator} from 'utils/rootLocator';

import CloseIcon from './closeIcon.jsx.svg';
import styles from './index.scss';

const locator = rootLocator.searchPage;

const cn = classnames.bind(styles);

type Props = {
  children: React.ReactNode;
  fullScreen?: boolean;
  onApply?: () => void;
  onClose: (event?: React.MouseEvent | KeyboardEvent) => void;
  onDiscard?: () => void;
};

type BottomSheetListItemProps = {
  children: React.ReactNode;
};

export const BottomSheetListItem = React.memo(function BottomSheetListItem({
  children,
}: BottomSheetListItemProps) {
  return <div className={styles.bottomSheetListItem}>{children}</div>;
});

type BottomSheetHeaderProps = {
  children: React.ReactNode;
  onClose?: (event: React.MouseEvent) => void;
  alignment?: 'center' | 'start';
};

export const BottomSheetHeader = React.memo(function BottomSheetHeader({
  children,
  onClose,
  alignment = 'center',
}: BottomSheetHeaderProps) {
  return (
    <div className={cn('bottomSheetHeader', alignment)}>
      {children}
      {onClose ? (
        <button type="button" onClick={onClose} className={styles.closeContainer}>
          <CloseIcon />
        </button>
      ) : null}
    </div>
  );
});

export const BottomSheet = React.memo(
  React.forwardRef(function BottomSheet(
    {children, fullScreen = false, onApply, onClose, onDiscard}: Props,
    outerRef: React.ForwardedRef<HTMLDivElement>,
  ) {
    const innerRef = useRef<HTMLDivElement>(null);
    const ref = useMergedRef(outerRef, innerRef);
    const hammer = useRef<HammerManager | null>(null);
    const [swipeDownEnabled, setSwipeDownEnabled] = useState(true);
    const showApply = typeof onApply === 'function';
    const showDiscard = typeof onDiscard === 'function';

    const handleKeyDown = useCallback(
      (event: KeyboardEvent): void => {
        if (event.key !== 'Escape') {
          return;
        }

        onClose(event);
      },
      [onClose],
    );

    useEffect(() => {
      if (!innerRef.current) {
        return undefined;
      }

      hammer.current = new Hammer(innerRef.current, {touchAction: 'auto'});

      hammer.current.on('swiperight', () => {
        onClose();
      });

      disableBodyScroll(innerRef.current);
      window.addEventListener('keydown', handleKeyDown);

      return (): void => {
        clearAllBodyScrollLocks();
        window.removeEventListener('keydown', handleKeyDown);

        if (hammer.current) {
          hammer.current.destroy();
        }
      };
    }, [handleKeyDown, onClose]);

    useEffect(() => {
      if (!hammer.current) {
        return;
      }

      if (swipeDownEnabled) {
        const cb = (): void => {
          onClose();
        };
        hammer.current.get('swipe').set({direction: Hammer.DIRECTION_ALL});
        hammer.current.on('swipedown', cb);
      } else {
        hammer.current.off('swipedown');
      }
    }, [swipeDownEnabled, onClose]);

    const handleOverlayClick = useCallback(
      (event: React.MouseEvent) => {
        if (event.target === event.currentTarget) {
          onClose(event);
        }
      },
      [onClose],
    );

    const handleOverlayScroll = useMemo(
      () =>
        throttle(() => {
          if (!innerRef.current) {
            return;
          }
          setSwipeDownEnabled(innerRef.current.scrollTop === 0);
        }, 1000),
      [],
    );

    return (
      <Portal>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
        <div
          onClick={handleOverlayClick}
          role="button"
          tabIndex={0}
          className={cn('parent', {
            fullScreen,
            withApply: showApply,
            withDiscard: showDiscard,
          })}
        >
          <div ref={ref} className={styles.overlay} onScroll={handleOverlayScroll}>
            <div className={styles.children}>{children}</div>
            {(showApply || showDiscard) && (
              <div className={styles.stickyFooter}>
                {showDiscard && (
                  <button
                    type="button"
                    tabIndex={0}
                    onClick={onDiscard}
                    className={cn('stickyFooterButton', 'discardFiltersButton')}
                    {...locator.resetAllButtonMobile()}
                  >
                    <FormattedMessage
                      description="[button] Bottom sheet discard filters text"
                      defaultMessage="Reset filters"
                    />
                  </button>
                )}
                {showApply && (
                  <Locator id="FilterSubmitButton">
                    <div className={cn('stickyFooterButton', 'applyButton')}>
                      <Button block color="darkblue" onClick={onApply}>
                        <FormattedMessage
                          description="[button] Bottom sheet apply button text"
                          defaultMessage="Apply"
                        />
                      </Button>
                    </div>
                  </Locator>
                )}
              </div>
            )}
          </div>
        </div>
      </Portal>
    );
  }),
);
