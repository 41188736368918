import {useEffectOnce} from 'hooks/useEffectOnce';
import {useMergedRef} from 'hooks/useMergedRef';
import React, {createElement, useCallback, useRef} from 'react';
import {requestAnimationFrame} from 'utils/raf';

type IframeClickHandlerProps = React.PropsWithChildren<
  {
    innerRef?: React.Ref<HTMLElement>;
    onClick: () => void;
    tag?: keyof JSX.IntrinsicElements;
  } & React.HTMLAttributes<HTMLElement>
>;

export function IframeClickHandler({
  children,
  innerRef,
  onClick,
  tag = 'div',
  ...attrs
}: IframeClickHandlerProps): JSX.Element {
  const rootRef = useRef<HTMLElement>(null);
  const mergedRef = useMergedRef(rootRef, innerRef);
  const mouseOverRef = useRef(false);
  const focusTargetRef = useRef<HTMLDivElement>(null);

  const handleMouseOver = useCallback(() => {
    mouseOverRef.current = true;
  }, []);
  const handleMouseOut = useCallback(() => {
    mouseOverRef.current = false;
  }, []);

  useEffectOnce(() => {
    function handleWindowBlur() {
      const iframeElement = rootRef.current?.querySelector('iframe');

      if (mouseOverRef.current && document.activeElement === iframeElement) {
        onClick();
        requestAnimationFrame(() => focusTargetRef.current?.focus());
      }
    }

    window.addEventListener('blur', handleWindowBlur);

    return () => {
      window.removeEventListener('blur', handleWindowBlur);
    };
  });

  const iframeWrapper = createElement(
    tag,
    {
      ...attrs,
      onMouseOut: handleMouseOut,
      onMouseOver: handleMouseOver,
      ref: mergedRef,
    },
    children,
  );

  return (
    <>
      <div ref={focusTargetRef} tabIndex={-1} />
      {iframeWrapper}
    </>
  );
}
