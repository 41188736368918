import {DotLoader, DotLoaderStyle} from 'components/DotLoader';
import {ModalOverlay} from 'components/UIKit/ModalOverlay';
import {Popup} from 'components/UIKit/Popup';
import {Content} from 'components/UIKit/Popup/Content';
import {Header} from 'components/UIKit/Popup/Header';
import styles from 'containers/WelcomePopups/JmtMigrationPopupController/styles.scss';
import React, {ReactElement} from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';

const messages = defineMessages({
  popupTitle: {
    description: '!DNT! [title] заголовок попапа для миграции пользователя с другого сайта',
    defaultMessage: 'Data migration',
  },
  title: {
    description: '!DNT! [title] заголовок попапа для миграции пользователя с другого сайта',
    defaultMessage: "You'll need to wait a bit",
  },
  description: {
    description: '!DNT! [button] текст попапа для миграции пользователя с другого сайта',
    defaultMessage: "We'll start transferring your data to a new app for your country now.",
  },
});

export function JmtMigrationLoadingPopup(): ReactElement {
  return (
    <ModalOverlay closeDisabled>
      <Popup width="450px">
        <Header>
          <FormattedMessage {...messages.popupTitle} />
        </Header>
        <Content>
          <div className={styles.loader}>
            <DotLoader style={DotLoaderStyle.DARK} />
          </div>
          <div className={styles.title}>
            <FormattedMessage {...messages.title} />
          </div>
          <div className={styles.description}>
            <FormattedMessage {...messages.description} />
          </div>
        </Content>
      </Popup>
    </ModalOverlay>
  );
}
