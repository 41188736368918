import {
  abortAllUploadRequests,
  finishVideoUploading,
  getVideoUploadingStatus,
  startVideoUploading as startVideoUploadingAction,
  uploadVideoPart,
} from 'store/modules/media';
import {FinishPart} from 'store/modules/media/actions';
import {arrayToObject} from 'utils/array';
import {isString} from 'utils/guards';
import {promiseAllWithParallelLimit} from 'utils/promise';

import {
  MediaStatus,
  MediaType,
  UploaderErrorType,
  UploaderOptions,
  VideoMediaState,
} from '../types';
import {createUploaderError} from './createUploaderError';
import {getMediaStatusByUploadingStatus} from './getMediaStatusByUploadingStatus';

const MAX_PARALLEL_UPLOAD_REQUESTS = 5;
const MAX_REQUEST_ATTEMPTS = 5;

export const startVideoUploading = async (
  mediaUuid: string,
  prevMediaState: VideoMediaState,
  {commit, dispatch, getState}: UploaderOptions,
): Promise<void> => {
  let mediaState = prevMediaState;
  const mediaFile = mediaState.file;

  if (!mediaFile) {
    throw createUploaderError(UploaderErrorType.INTERNAL_ERROR, {
      message: `Attempt to upload a media without file (mediaUuid: ${mediaUuid})`,
    });
  }

  // The video process includes the nest steps:
  // 1. Sending the video metadata and receiving metadata of video parts;
  // 2. Uploading the video parts and save etags;
  // 3. Tring to finish the uploading by sending the etags of uploaded parts and receiving metadata of video parts;
  // 4. If the video upload status is not final, then goto the step 2.
  // 5. Commiting the video bundle.

  const mediaFileArrayBuffer = await mediaFile.arrayBuffer();

  // Start the video upload process by sending the video metadata. The video will be uploaded in parts, the metadata
  // about the parts of the video will be returned with the response.
  const startResponse = await dispatch(startVideoUploadingAction(mediaFile, mediaFileArrayBuffer));

  mediaState = commit(mediaUuid, {
    ...mediaState,
    meta: {
      ...mediaState.meta,
      uploadId: startResponse.id,
      uploadParts: startResponse.uploadParts,
    },
  });

  // The upload parts can be updated every retry. At the same time, it is necessary to save data on previous successful
  // attempts. Data about successfully uploaded parts is saved in the map structure to avoid duplication.
  const finishPartsMap: Record<string, FinishPart> = {};
  const uploadPartsMap = arrayToObject(
    mediaState.meta?.uploadParts ?? [],
    (uploadPart) => uploadPart.id,
  );

  // Try uploading video parts several times. An infinite loop may never end, use finite number of attempts.
  let attempt = 0;
  // eslint-disable-next-line no-constant-condition
  while (true) {
    // If the attempts over, then throw an error.
    if (attempt === MAX_REQUEST_ATTEMPTS) {
      throw createUploaderError(UploaderErrorType.INTERNAL_ERROR, {
        message: `Exceeded the maximum number of attempts (mediaUuid: ${mediaUuid})`,
      });
    }

    const {uploadId, uploadParts = []} = mediaState.meta ?? {};

    // Impossible to get an empty array of uploaded parts or not get the upload ID. If this has happened, then it is an
    // error.
    if (!isString(uploadId) || uploadParts.length === 0) {
      throw createUploaderError(UploaderErrorType.EXTERNAL_ERROR, {
        message: `No upload parts in response from external api (mediaUuid: ${mediaUuid})`,
      });
    }

    try {
      // Parallel uploading of video parts.
      // eslint-disable-next-line no-await-in-loop, no-loop-func
      await promiseAllWithParallelLimit(MAX_PARALLEL_UPLOAD_REQUESTS, (index) => {
        const uploadPart = uploadParts[index];

        if (uploadPart) {
          // Upload the next part of the video.
          return dispatch(uploadVideoPart(uploadPart, mediaFileArrayBuffer)).then(
            (uploadResponse) => {
              // Calculation and committing of the uploading progress.
              mediaState = commit(mediaUuid, {
                ...mediaState,
                bytesUploaded:
                  mediaState.bytesUploaded + (uploadPart.bytesTo - uploadPart.bytesFrom),
              });

              // Save the upload result to send a finish request or use it on the next attempt.
              finishPartsMap[uploadPart.id] = {
                id: uploadPart.id,
                etag: uploadResponse.etag,
              };
            },
          );
        }

        return undefined;
      });
    } catch {
      // Abort all upload requests if one of them fails.
      // eslint-disable-next-line no-await-in-loop
      await dispatch(abortAllUploadRequests(uploadParts));

      const {mediaStatesMap} = getState();
      const nextMediaState = mediaStatesMap[mediaUuid];

      if (nextMediaState?.type === MediaType.VIDEO) {
        mediaState = nextMediaState;
      }

      if (mediaState.status === MediaStatus.IN_PROGRESS_OF_DELETION) {
        break;
      }
    }

    // Finished parts can only be sent sorted in ascending order. Sorting by the bytesFrom property of the correlated
    // uploaded part is the most reliable.
    const finishParts = Object.values(finishPartsMap).sort((finishPartA, finishPartB) => {
      const uploadPartA = uploadPartsMap[finishPartA.id];
      const uploadPartB = uploadPartsMap[finishPartB.id];
      const bytesFromA = uploadPartA?.bytesFrom ?? 0;
      const bytesFromB = uploadPartB?.bytesFrom ?? 0;

      return bytesFromA - bytesFromB;
    });

    // If there are successfully uploaded parts, try to commit them using an external request. Or try updating metadata
    // about the parts of the video.
    // eslint-disable-next-line no-await-in-loop
    const statusResponse = await dispatch(
      finishParts.length > 0
        ? finishVideoUploading(uploadId, finishParts)
        : getVideoUploadingStatus(uploadId),
    );

    // Committing of the uploading status, progress, upload parts and uploaded parts. The upload and uploaded parts will
    // be needed to stop the uploading or the next attempt.
    mediaState = commit(mediaUuid, {
      ...mediaState,
      bytesUploaded: statusResponse.bytesUploaded,
      status: getMediaStatusByUploadingStatus(statusResponse.status),
      meta: {
        ...mediaState.meta,
        finishParts,
        uploadParts: statusResponse.uploadParts,
      },
    });
    Object.assign(
      uploadPartsMap,
      arrayToObject(mediaState.meta?.uploadParts ?? [], (uploadPart) => uploadPart.id),
    );

    // If the status is unsuccessful, then trow an error.
    if (mediaState.status === MediaStatus.ERROR) {
      throw createUploaderError(UploaderErrorType.EXTERNAL_ERROR, {
        message: `Unknown error uploading from external api (mediaUuid: ${mediaUuid})`,
      });
    }

    // If the status is successful, then commit the video bundle and break the retry cycle.
    if (mediaState.status === MediaStatus.SUCCESS) {
      mediaState = commit(mediaUuid, {
        ...mediaState,
        bundle: {
          video: {
            id: startResponse.videoId,
          },
        },
        meta: undefined,
      });

      break;
    }

    attempt += 1;
  }
};
