import React from 'react';

type Props = {
  children: React.ReactNode;
  contextSeed: string;
};

export const ContextSeedContext = React.createContext<string>('');

export function ContextSeedProvider({children, contextSeed}: Props): JSX.Element {
  return <ContextSeedContext.Provider value={contextSeed}>{children}</ContextSeedContext.Provider>;
}
