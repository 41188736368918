import cn from 'classnames';
import React from 'react';

import styles from './index.scss';

type FieldProps = React.PropsWithChildren<{
  hasError?: boolean;
  hint?: React.ReactNode;
  label: React.ReactNode;
  notice?: React.ReactNode;
}>;

export function Field({children, hasError, hint, label, notice}: FieldProps): JSX.Element {
  return (
    <div className={cn(styles.root, hasError && styles.hasError)}>
      <h3 className={styles.label}>{label}</h3>
      {notice ? <p className={styles.notice}>{notice}</p> : null}
      <div className={styles.content}>{children}</div>
      {hint ? <p className={styles.hint}>{hint}</p> : null}
    </div>
  );
}
