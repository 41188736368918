import {isRecord} from 'utils/guards';

export type ProductCollectionSelfOwner = {
  self: Record<string, never>;
};

export type ProductCollectionSocialUserOwner = {
  socialUser: {
    id: string;
  };
};

export type ProductCollectionOwner = ProductCollectionSelfOwner | ProductCollectionSocialUserOwner;

export function isProductCollectionSelfOwner(value: unknown): value is ProductCollectionSelfOwner {
  return isRecord(value) && 'self' in value;
}

export function isProductCollectionSocialUserOwner(
  value: unknown,
): value is ProductCollectionSocialUserOwner {
  return isRecord(value) && isRecord(value.socialUser);
}

export function isProductCollectionOwner(value: unknown): value is ProductCollectionOwner {
  return isProductCollectionSelfOwner(value) || isProductCollectionSocialUserOwner(value);
}

export function getProductCollectionOwnerId(
  owner: ProductCollectionOwner,
  userId: string | undefined,
): string | undefined {
  if (isProductCollectionSelfOwner(owner)) {
    return userId;
  }

  if (isProductCollectionSocialUserOwner(owner)) {
    return owner.socialUser.id;
  }

  return undefined;
}
