import classnames from 'classnames/bind';
import {Image} from 'components/Image';
import {useAnalytics} from 'hooks/useAnalytics';
import {useLanguage} from 'hooks/useLanguage';
import {useScope} from 'hooks/useScope';
import PropTypes from 'prop-types';
import React, {memo, useCallback, useEffect, useId, useMemo, useRef, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {CategoryPromoLinksShape} from 'shapes/CategoryPromoLink';
import CategoryViewShape from 'shapes/CategoryView';
import {CategoryFrom} from 'types/analytics/Category';
import {searchCategoryUrl} from 'containers/pages/SearchPage/url';
import {getValueByPath} from 'utils/object';
import {Link} from 'react-router-dom';
import {Locator} from 'components/Locator';

import {getCategoryPromoLinkId} from 'utils/categoryPromoLink';
import {useOnClickOutside} from 'hooks/useOnClickOutside';
import {Scope} from 'config';
import {CategoryPromoLink} from './CategoryPromoLink';
import styles from './index.scss';
import Menu from './menu.jsx.svg';

const cn = classnames.bind(styles);

const CategoryLink = memo(function CategoryLink({
  id,
  isTopCategory,
  children,
  onClick,
  onMount,
  source,
  visible,
  testId,
  testNameId,
  testCategoryId,
}) {
  const lang = useLanguage();
  const scope = useScope();
  const analytics = useAnalytics();
  const linkChildRef = useRef();

  useEffect(() => {
    const linkChild = linkChildRef.current;
    if (!linkChild || !onMount) {
      return;
    }

    onMount(id, linkChild.getBoundingClientRect().bottom);
  }, [id, onMount]);

  const categoryClickHandler = useCallback(() => {
    onClick();
    analytics.sendEvent({
      type: 'categoriesClick',
      payload: {
        categoryId: id,
        from: isTopCategory ? CategoryFrom.HEADER : CategoryFrom.POPUP,
        source,
      },
    });
  }, [analytics, id, isTopCategory, onClick, source]);

  let className = null;
  if (isTopCategory) {
    className = styles.category;
  } else {
    className = styles.child;
  }

  return (
    <Locator id={testId} name={testNameId} category-id={testCategoryId}>
      <Link
        aria-hidden={!visible}
        className={className}
        onClick={categoryClickHandler}
        tabIndex={visible ? undefined : -1}
        to={searchCategoryUrl(scope, lang, id)}
      >
        <span ref={linkChildRef}>{children}</span>
      </Link>
    </Locator>
  );
});

CategoryLink.propTypes = {
  id: PropTypes.string.isRequired,
  isTopCategory: PropTypes.bool,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  onMount: PropTypes.func.isRequired,
  source: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  testId: PropTypes.string,
  testNameId: PropTypes.string,
  testCategoryId: PropTypes.string,
};

CategoryLink.defaultProps = {
  isTopCategory: false,
  testId: '',
  testNameId: '',
  testCategoryId: '',
};

export const CategoriesWide = React.memo(function CategoriesWide({
  categoryPromoLinks,
  promoLinksHighlight,
  setHighlightStatus,
  rootCategoryView,
  source,
}) {
  const analytics = useAnalytics();
  const scope = useScope();
  const listRef = useRef();
  const listUid = useId();
  const [mountedChildrenAmount, setMountedChildrenAmount] = useState(0);
  const visibleOnMountIds = useRef([]);
  const buttonRef = useRef();
  const popupRef = useRef();
  const [expanded, setExpanded] = useState(false);
  const handleButtonClick = useCallback(() => {
    if (!expanded) {
      analytics.sendEvent({
        type: 'allCategoriesHeaderClick',
      });
      setExpanded(true);
    } else {
      setExpanded(false);
    }
  }, [expanded, analytics]);

  const handleCloseCategories = useCallback(() => setExpanded(false), []);
  const promoLinksVisible = Array.isArray(categoryPromoLinks) && categoryPromoLinks.length > 0;

  const children = useMemo(
    () => getValueByPath(rootCategoryView, 'children') || [],
    [rootCategoryView],
  );

  const onCategoryMount = useCallback((id, bottom) => {
    if (bottom < listRef.current.getBoundingClientRect().bottom) {
      visibleOnMountIds.current.push(id);
    }
    setMountedChildrenAmount((value) => value + 1);
  }, []);

  useEffect(() => {
    const categories = visibleOnMountIds.current;
    if (!categories || !categories.length || mountedChildrenAmount !== children.length) {
      return;
    }

    analytics.sendEvent({
      type: 'categoriesPreview',
      payload: {
        categories,
        from: CategoryFrom.HEADER,
        source,
      },
    });
  }, [analytics, children.length, mountedChildrenAmount, source]);

  useEffect(() => {
    if (expanded && children.length) {
      const categories = children.map(({id}) => id);

      analytics.sendEvent({
        type: 'categoriesPreview',
        payload: {
          categories,
          from: CategoryFrom.POPUP,
          source,
        },
      });
    }
  }, [expanded, children, analytics, source]);

  useOnClickOutside(
    useMemo(() => [buttonRef, popupRef], []),
    handleCloseCategories,
    {disabled: !expanded},
  );

  return (
    <Locator id="Categories">
      <div className={styles.parent}>
        <div className={styles.header}>
          <div className={styles.inner}>
            <div className={styles.categories}>
              <nav className={styles.categoriesList} ref={listRef}>
                {scope.is(Scope.GLOBAL) ? (
                  <Locator id="AllCategoriesButton">
                    <button
                      ref={buttonRef}
                      type="button"
                      className={cn(styles.allCategoriesButton, styles.category, {
                        [styles.expanded]: expanded,
                      })}
                      onClick={handleButtonClick}
                      aria-controls={listUid}
                      aria-expanded={expanded}
                    >
                      <Menu className={styles.menu} />
                      <span className={styles.allCategories}>
                        <FormattedMessage
                          description="Header categories header"
                          defaultMessage="All categories"
                        />
                      </span>
                    </button>
                  </Locator>
                ) : null}
                {expanded && children.length ? (
                  <Locator id="AllCategoriesExpandedPopup">
                    <div className={styles.popup} ref={popupRef}>
                      <div className={styles.popupContent}>
                        <div className={styles.inner} id={listUid}>
                          <nav className={styles.children}>
                            {children.map(({id, name, mainImage}) => (
                              <Locator id="Category" name={name} key={id}>
                                <div className={styles.childWrapper}>
                                  <CategoryLink
                                    onClick={handleCloseCategories}
                                    id={id}
                                    source={source}
                                    visible={expanded}
                                    testId="CategoryLink"
                                    testNameId={name}
                                    testCategoryId={id}
                                  >
                                    <Locator id="CategoryImage" name={name}>
                                      <span className={styles.icon}>
                                        <Image
                                          vwFit={{sm: '1.5em'}}
                                          className={styles.image}
                                          image={mainImage}
                                          contain
                                          pxFit={40}
                                        />
                                      </span>
                                    </Locator>
                                    {name}
                                  </CategoryLink>
                                </div>
                              </Locator>
                            ))}
                          </nav>
                        </div>
                      </div>
                    </div>
                  </Locator>
                ) : null}
                {promoLinksVisible &&
                  categoryPromoLinks.map((link, idx) => {
                    const linkId = getCategoryPromoLinkId(link);
                    return (
                      <CategoryPromoLink
                        link={link}
                        key={linkId}
                        onClick={handleCloseCategories}
                        linkId={linkId}
                        shouldHighlight={promoLinksHighlight[linkId]}
                        setHighlightStatus={setHighlightStatus}
                      />
                    );
                  })}
                {children.map(({id, name}) => (
                  <React.Fragment key={id}>
                    <CategoryLink
                      onMount={onCategoryMount}
                      onClick={handleCloseCategories}
                      isTopCategory
                      id={id}
                      source={source}
                      visible={visibleOnMountIds.current.includes(id)}
                      testId="MainCategoryLink"
                      testNameId={name}
                      testCategoryId={id}
                    >
                      {name}
                    </CategoryLink>{' '}
                  </React.Fragment>
                ))}
              </nav>
            </div>
          </div>
        </div>
      </div>
    </Locator>
  );
});

CategoriesWide.propTypes = {
  categoryPromoLinks: CategoryPromoLinksShape,
  rootCategoryView: CategoryViewShape,
  setHighlightStatus: PropTypes.func,
  promoLinksHighlight: PropTypes.objectOf(PropTypes.bool),
  source: PropTypes.string.isRequired,
};

CategoriesWide.defaultProps = {
  categoryPromoLinks: undefined,
  rootCategoryView: null,
  promoLinksHighlight: {},
  setHighlightStatus: null,
};
