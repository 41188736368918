import {OrderLayout} from 'components/ParcelElements/OrderLayout';
import {Locator} from 'components/Locator';
import {useAnalytics} from 'hooks/useAnalytics';
import {useLanguage} from 'hooks/useLanguage';
import {usePreviewEvent} from 'hooks/usePreviewEvent';
import PropTypes from 'prop-types';
import React, {memo, useEffect, useRef} from 'react';
import {FormattedMessage} from 'react-intl';
import {useLocation} from 'react-router-dom';
import OrderLite from 'shapes/OrderLite';
import {ParcelShape} from 'shapes/Parcel';
import {getValidatedClickArea} from 'types/analytics/Parcel';
import {getValueByPath} from 'utils/object';
import {getQueryData, createUrl} from 'utils/url';
import {eventParamsList} from 'helpers/eventParams';
import {getUrl} from 'routes';
import {useProductUrl} from 'hooks/useProductUrl';
import {useScope} from 'hooks/useScope';

import styles from './Order.scss';
import {ReviewButton} from './ReviewButton';

export const Order = memo(
  ({
    activeFilter,
    parcel,
    order,
    showSpecialPriceIcon = false,
    bottomBanners,
    vatInfo,
    quantity,
    showReviewButton,
    onReviewClick,
  }) => {
    const {productId, productVariantId, productOpenPayload, eventParams: orderEventParams} = order;
    const {id: parcelId, eventParams} = parcel;
    const lang = useLanguage();
    const scope = useScope();
    const location = useLocation();
    const analytics = useAnalytics();
    const imgRef = useRef();

    useEffect(() => {
      const {area} = getQueryData(location.search);

      analytics.sendEvent({
        type: 'orderParcelOpen',
        payload: {
          tabId: activeFilter,
          parcelId,
          location: getValidatedClickArea(area),
        },
        params: eventParamsList(eventParams),
      });
    }, [activeFilter, analytics, eventParams, location.search, parcelId]);
    const context = getValueByPath(order, 'productContext', 'value', 0) || null;

    usePreviewEvent(
      {
        rootRef: imgRef,
        previewEvent: {
          type: 'productPreview',
          payload: {
            productId: order.productId,
            context,
          },
          params: orderEventParams ? [orderEventParams] : [],
        },
      },
      {
        clickEvent: {
          type: 'productClick',
          payload: {productId: order.productId},
          params: orderEventParams ? [orderEventParams] : [],
        },
      },
    );

    const productUrl = useProductUrl(getUrl('product', {lang, scope, productId}), {
      variantId: productVariantId,
      openPayload: productOpenPayload,
    });

    const url = createUrl(productUrl, {from: 'order'});

    return (
      <>
        <OrderLayout
          order={order}
          showSpecialPriceIcon={showSpecialPriceIcon}
          vatInfo={vatInfo}
          quantity={quantity}
          bottomBanners={bottomBanners}
          imgTo={url}
          imgRef={imgRef}
        />
        {showReviewButton && (
          <div className={styles.buttons}>
            {showReviewButton && (
              <Locator id="order-leaveReviewButton">
                {/* TODO(uid11): Remove span after locator fix in WEB-4032 */}
                <span>
                  <ReviewButton onClick={onReviewClick}>
                    <FormattedMessage
                      defaultMessage="Leave a review"
                      description="Parcel order, leave review buton"
                    />
                  </ReviewButton>
                </span>
              </Locator>
            )}
          </div>
        )}
      </>
    );
  },
);

Order.propTypes = {
  activeFilter: PropTypes.string.isRequired,
  parcel: ParcelShape.isRequired,
  order: OrderLite.isRequired,
  bottomBanners: PropTypes.array,
  quantity: PropTypes.number.isRequired,
  showReviewButton: PropTypes.bool,
  onReviewClick: PropTypes.func.isRequired,
  showSpecialPriceIcon: PropTypes.bool,
  vatInfo: PropTypes.shape({
    title: {
      text: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    },
  }),
};

Order.defaultProps = {
  showReviewButton: false,
  showSpecialPriceIcon: false,
  vatInfo: null,
  bottomBanners: null,
};
