import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import CloseIcon from 'components/icons/close';
import React from 'react';
import {defineMessages, useIntl} from 'react-intl';
import {rootLocator} from 'utils/rootLocator';
import styles from './HeaderClose.scss';

const cn = classnames.bind(styles);

const locator = rootLocator.static.headerClose;

const messages = defineMessages({
  close: {
    description: '[label] Описание кнопки «закрыть» для экранных читалок',
    defaultMessage: 'Close',
  },
});

export const HeaderClose = React.memo(function HeaderClose({onClick, color}) {
  const intl = useIntl();

  return (
    <button
      type="button"
      tabIndex={0}
      className={cn('headerClose', color)}
      onClick={onClick}
      aria-label={intl.formatMessage(messages.close)}
      {...locator()}
    >
      <CloseIcon className={styles.icon} />
    </button>
  );
});

HeaderClose.propTypes = {
  onClick: PropTypes.func.isRequired,
  color: PropTypes.string,
};

HeaderClose.defaultProps = {
  color: null,
};
