import classnames from 'classnames/bind';
import type {Locator, Mark} from 'create-locator';
import {createLocator} from 'create-locator';
import React from 'react';
import {ShippingBadge as ShippingBadgeType} from 'types/ShippingBadge';

import styles from './index.scss';

const cn = classnames.bind(styles);

export type ShippingBadgeLocator = Locator<void>;

type Props = {
  shippingBadge: ShippingBadgeType;
} & Mark<ShippingBadgeLocator>;

export const ShippingBadge = React.memo(function ShippingBadge(props: Props) {
  const {shippingBadge} = props;
  const locator = createLocator(props);

  return (
    <div className={cn('badge', 'shippingBadge')} title={shippingBadge.text} {...locator()}>
      {shippingBadge.text}
    </div>
  );
});
