import {PopupViewProps, PopupViewRender} from 'components/UIKit/Popup/PopupProvider';
import {PopupStack} from 'components/UIKit/Popup/PopupStack';
import React, {useCallback} from 'react';

import {CouponsPopupBase} from './connector';

type PopupProps = {
  source?: string;
};

export function useCouponsPopupRender({source}: PopupProps): PopupViewRender {
  return useCallback(
    ({action, onClose}: PopupViewProps) => (
      <CouponsPopupBase
        action={action}
        onClose={onClose}
        source={source}
        url="/contentList/coupons/get"
      />
    ),
    [source],
  );
}

export function CouponsPopup(): React.ReactElement {
  const render = useCouponsPopupRender({});
  return <PopupStack render={render} />;
}
