import {Image} from 'components/Image';
import {Locator} from 'components/Locator';
import {Price} from 'components/Price';
import React, {FC, useMemo} from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';
import {ImageBundle} from 'types/Image';
import {OrderLite} from 'types/OrderLite';

import styles from './Orders.scss';

const IMAGES_MAX = 4;

const messages = defineMessages({
  orders: {
    description: 'Текст для заказов в графе "Заказ" в форме саппорта. Пример "4 заказа"',
    defaultMessage: `{count, plural,
  one {# order}
  other {# orders}
}`,
  },
  ordersWithPrice: {
    description:
      'Текст для заказов с одинаковой валютой в графе "Заказ" в форме саппорта. Пример "4 заказа на сумму 4 $"',
    defaultMessage: `{count, plural,
  one {# order}
  other {# orders}
}, {price}`,
  },
});

type Props = {
  orders: OrderLite[];
};

type TotalPrice = {
  currency: string;
  price: number;
};

function getTotalPrice(orders: OrderLite[]): TotalPrice | null {
  let currency = '';
  let price = 0;
  const sameCurrency = orders.every((order) => {
    price += order.total.price.amount + (order.total.shippingPrice?.amount || 0);

    if (!currency) {
      currency = order.currency;
      return true;
    }
    return currency === order.currency;
  });

  return sameCurrency
    ? {
        currency,
        price,
      }
    : null;
}

function getImages(orders: OrderLite[]): ImageBundle[] {
  const images: ImageBundle[] = [];
  orders.forEach(({mainImage}) => {
    if (mainImage && images.length < IMAGES_MAX) {
      images.push(mainImage);
    }
  });
  return images;
}

export const Orders: FC<Props> = ({orders}: Props): JSX.Element => {
  const price = useMemo(() => getTotalPrice(orders), [orders]);
  const images = useMemo(() => getImages(orders), [orders]);
  const values = {
    count: orders.length,
    price: price ? (
      <Price format={price.currency} value={price.price} noText noSeoLabel noSeoCurrency noSchema />
    ) : null,
  };

  return (
    <div className={styles.orders}>
      <Locator id="Support-popup-order-info">
        <div className={styles.text}>
          {price ? (
            <FormattedMessage {...messages.ordersWithPrice} values={values} />
          ) : (
            <FormattedMessage {...messages.orders} values={values} />
          )}
        </div>
      </Locator>
      <div className={styles.images}>
        {images.map((image) => (
          <div key={image.images[0]?.url || ''} className={styles.image}>
            <Image
              width="100%"
              height="100%"
              pxFit={32}
              block
              cover
              multiply
              vwFit={{xs: '32px'}}
              image={image}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
