import {createRequestAction} from 'store/utils/requestActions';
import {ProductCollection} from 'types/ProductCollection';
import {ProductCollectionLite} from 'types/ProductCollection/ProductCollectionLite';
import {ProductCollectionSelectionPopup} from 'types/ProductCollection/ProductCollectionSelectionPopup';
import {ProductCollectionsUpdatedProductInfo} from 'types/ProductCollection/ProductCollectionUpdatedInfo';
import {ProductCollectionUpdateList} from 'types/ProductCollection/ProductCollectionUpdateList';
import {
  ProductCollectionSettings,
  ProductCollectionUserSettings,
} from 'types/ProductCollection/ProductCollectionUserSettings';

export type ChangeProductInCollectionRequest = {
  itemKey: {
    productId: string;
  };
};

export type ChangeProductInCollectionResponse = {
  collectionsUpdatedProductInfo: ProductCollectionsUpdatedProductInfo;
  productCollections: ProductCollectionLite[];
};

export const addProductToCollection = createRequestAction(
  '@productCollection/addProductRequest',
  '@productCollection/addProductResponse',
  '@productCollection/addProductFailure',
)<ChangeProductInCollectionRequest, ChangeProductInCollectionResponse>();

export const removeProductFromCollection = createRequestAction(
  '@productCollection/removeProductRequest',
  '@productCollection/removeProductResponse',
  '@productCollection/removeProductFailure',
)<ChangeProductInCollectionRequest, ChangeProductInCollectionResponse>();

export type EditProductsInCollectionsRequest = {
  updates: ProductCollectionUpdateList;
};

export type EditProductsInCollectionsResponse = {
  collectionsUpdatedProductInfoList: ProductCollectionsUpdatedProductInfo[];
  productCollections: ProductCollectionLite[];
};

export const editProductsInCollections = createRequestAction(
  '@productCollection/editProductsInCollectionsRequest',
  '@productCollection/editProductsInCollectionsResponse',
  '@productCollection/editProductsInCollectionsFailure',
)<EditProductsInCollectionsRequest, EditProductsInCollectionsResponse>();

export type LoadProductCollectionSelectionPopupRequest = {
  itemKey?: {productId: string};
  pageToken?: string;
};

export type LoadProductCollectionSelectionPopupResponse = ProductCollectionSelectionPopup;

export const loadProductCollectionSelectionPopup = createRequestAction(
  '@productCollection/loadProductCollectionSelectionPopupRequest',
  '@productCollection/loadProductCollectionSelectionPopupResponse',
  '@productCollection/loadProductCollectionSelectionPopupFailure',
)<LoadProductCollectionSelectionPopupRequest, LoadProductCollectionSelectionPopupResponse>();

export type CreateProductCollectionRequest = {
  settings: ProductCollectionSettings;
};

export type CreateProductCollectionResponse = {
  userSettings: ProductCollectionUserSettings;
  productCollection: ProductCollection;
};

export const createProductCollection = createRequestAction(
  '@productCollection/createProductCollectionRequest',
  '@productCollection/createProductCollectionResponse',
  '@productCollection/createProductCollectionFailure',
)<CreateProductCollectionRequest, CreateProductCollectionResponse>();

export type UpdateProductCollectionRequest = {
  settings: ProductCollectionSettings;
  productCollectionId: string;
};

export type UpdateProductCollectionResponse = {
  productCollection: ProductCollection;
};

export const updateProductCollection = createRequestAction(
  '@productCollection/updateProductCollectionRequest',
  '@productCollection/updateProductCollectionResponse',
  '@productCollection/updateProductCollectionFailure',
)<UpdateProductCollectionRequest, UpdateProductCollectionResponse>();

export type DeleteProductCollectionRequest = {
  productCollectionId: string;
};

export type DeleteProductCollectionResponse = {
  userSettings: ProductCollectionUserSettings;
};

export const deleteProductCollection = createRequestAction(
  '@productCollection/deleteProductCollectionRequest',
  '@productCollection/deleteProductCollectionResponse',
  '@productCollection/deleteProductCollectionFailure',
)<DeleteProductCollectionRequest, DeleteProductCollectionResponse>();

export type ChangeCollectionInFavoritesRequest = {
  ownerId: string;
  collectionId: string;
};

export type ChangeCollectionInFavoritesResponse = ProductCollectionLite;

export const addCollectionToFavorites = createRequestAction(
  '@productCollection/addCollectionToFavoritesRequest',
  '@productCollection/addCollectionToFavoritesResponse',
  '@productCollection/addCollectionToFavoritesFailure',
)<ChangeCollectionInFavoritesRequest, ChangeCollectionInFavoritesResponse>();

export const removeCollectionFromFavorites = createRequestAction(
  '@productCollection/removeCollectionFromFavoritesRequest',
  '@productCollection/removeCollectionFromFavoritesResponse',
  '@productCollection/removeCollectionFromFavoritesFailure',
)<ChangeCollectionInFavoritesRequest, ChangeCollectionInFavoritesResponse>();
