import cn from 'classnames';
import {Background} from 'components/ContentList/Background';
import {BannerProps} from 'components/ContentList/BannersList/BannerProps';
import {type BannerWrapperLocator, BannerWrapper} from 'components/ContentList/BannerWrapper';
import {useBannerPreview} from 'components/ContentList/hooks';
import {Image} from 'components/Image';
import {type Locator, type Mark, createLocator} from 'create-locator';
import React, {useRef} from 'react';
import {type BannersListItem, BannersListImageType} from 'types/ContentList/BannersList';

import styles from './index.scss';

const IMAGE_PX_FIT = 138;
const IMAGE_VW_FIT = {
  md: '138px',
  sm: '88px',
};

export type SmallImageGridItemLocator = Locator<
  {bannerWrapper: BannerWrapperLocator},
  {bannerId: string}
>;

type SmallImageGridItemProps = {
  imageType?: BannersListImageType;
  item: BannersListItem;
  source?: string;
  mini?: boolean;
} & Partial<Mark<SmallImageGridItemLocator>>;

export function SmallImageGridItem(props: SmallImageGridItemProps): JSX.Element {
  const {imageType, item, source, mini} = props;
  const locator = createLocator(props);
  const rootRef = useRef<HTMLLIElement>(null);
  const asIcon = imageType === BannersListImageType.ICON;

  useBannerPreview({
    bannerId: item.id,
    eventParams: item.eventParams,
    rootRef,
    clickRefs: [rootRef],
    source,
  });

  return (
    <li
      className={cn(styles.item, asIcon && styles.asIcon, mini && styles.mini)}
      ref={rootRef}
      {...locator({bannerId: item.id})}
    >
      <BannerWrapper noflex source={source} to={item.payload.url} {...locator.bannerWrapper()}>
        <div className={styles.imageWrap}>
          <Background gradient={item.gradient} />
          <Image
            className={styles.image}
            contain
            httpsOnly={false}
            image={item.background}
            lcpImageCandidate
            loadingHidden
            pxFit={IMAGE_PX_FIT}
            vwFit={IMAGE_VW_FIT}
          />
        </div>
        <div className={styles.title}>{item.title}</div>
      </BannerWrapper>
    </li>
  );
}

type SmallImageGridListLocator = Locator<void>;

type SmallImageGridListProps = React.PropsWithChildren<{
  onScroll: BannerProps['onScroll'];
}> &
  Partial<Mark<SmallImageGridListLocator>>;

export function SmallImageGridList(props: SmallImageGridListProps): JSX.Element {
  const locator = createLocator(props);
  const {children, onScroll} = props;

  return (
    <div className={styles.root} onScroll={onScroll} {...locator()}>
      <ul className={styles.list}>{children}</ul>
    </div>
  );
}

export type SmallImageGridLocator = Locator<{
  item: SmallImageGridItemLocator;
  list: SmallImageGridListLocator;
}>;

type SmallImageGridProps = BannerProps & Mark<SmallImageGridLocator>;

export function SmallImageGrid(props: SmallImageGridProps): JSX.Element | null {
  const {bannerAppearance, items, onScroll, source} = props;
  const locator = createLocator(props);

  return (
    <div {...locator()}>
      <SmallImageGridList onScroll={onScroll} {...locator.list()}>
        {items.map((item) => (
          <SmallImageGridItem
            imageType={bannerAppearance.imageType}
            item={item}
            key={item.id}
            source={source}
            {...locator.item()}
          />
        ))}
      </SmallImageGridList>
    </div>
  );
}
