import ErrorMessage from 'components/ErrorMessage';
import Close from 'components/icons/close';
import Info from 'components/icons/info.jsx.svg';
import {Locator} from 'components/Locator';
import {Button, Color} from 'components/UIKit/Button';
import {Icon, Text} from 'components/UIKit/Button/Content';
import React, {FC, ReactNode, useCallback} from 'react';
import {defineMessages, FormattedMessage, FormattedNumber, useIntl} from 'react-intl';
import {Error} from 'types/Error';

import styles from './File.scss';

const messages = defineMessages({
  loading: {
    description: 'Элемент в хлебных крошках',
    defaultMessage: 'Loading…',
  },
  loadingProgress: {
    description: 'Элемент в хлебных крошках',
    defaultMessage: 'Loading {progress}',
  },
  delete: {
    description: '[label] Название иконки «удалить»',
    defaultMessage: 'Remove file',
  },
});

export type FileType = {
  /**
   * Client generated id
   */
  id: string;
  /**
   * Exists only after uploading
   */
  uploadId?: string;
  name: string;
  /**
   * Progress from 0 to 1
   */
  uploadingProgress: number;
  error?: Error;
};

type Props = {
  file: FileType;
  onClick(file: FileType): void;
};

function renderText(file: FileType): ReactNode {
  const {uploadingProgress, name, error} = file;
  if (error) {
    return <ErrorMessage error={error} internal noRequestId />;
  }
  if (uploadingProgress === 0) {
    return <FormattedMessage {...messages.loading} />;
  }
  if (uploadingProgress === 1) {
    return name;
  }
  return (
    <FormattedMessage
      {...messages.loadingProgress}
      values={{
        progress: <FormattedNumber style="percent" value={uploadingProgress} />,
      }}
    />
  );
}

export const File: FC<Props> = ({file, onClick}: Props) => {
  const handleClick = useCallback(() => onClick(file), [file, onClick]);
  const color: Color = {
    base: 'gray',
    color: file.error ? '#fa9c00' : undefined,
  };
  const intl = useIntl();

  return (
    <Locator
      id="FormFile"
      filename={file.name}
      uploaded={file.uploadingProgress === 1 && !file.error}
    >
      <Button tag="button" color={color} size="medium" onClick={handleClick}>
        {file.error ? (
          <Icon>
            <span className={styles.info}>
              <Info className={styles.svg} height="20" width="20" />
            </span>
          </Icon>
        ) : null}
        <Text justify="start" nowrap>
          {renderText(file)}
        </Text>
        <Icon>
          <span className={styles.icon}>
            <Close
              className={styles.svg}
              height="14"
              width="14"
              title={intl.formatMessage(messages.delete)}
            />
          </span>
        </Icon>
      </Button>
    </Locator>
  );
};
