import cn from 'classnames';
import {Reducer} from 'components/Layout/Reducer';
import {Locator} from 'components/Locator';
import {LinksSlider} from 'components/PageMetadata/LinksSlider';
import {Scope} from 'config';
import {useBot} from 'hooks/useBot';
import {useDeviceVars} from 'hooks/useDeviceVars';
import {useScope} from 'hooks/useScope';
import React from 'react';
import {LinksBlock} from 'types/PageMetadata/LinksBlock';
import {PageSource} from 'types/PageSource';
import {clamp} from 'utils/math';

import {FooterApps} from './FooterApps';
import {FooterBadges} from './FooterBadges';
import {FooterBottom} from './FooterBottom';
import {FooterColumnBuyers} from './FooterColumnBuyers';
import {FooterColumnCompany} from './FooterColumnCompany';
import {FooterColumnJoomGroup} from './FooterColumnJoomGroup';
import {FooterColumnPartners} from './FooterColumnPartners';
import {FooterDebug} from './FooterDebug';
import {useFooterPreviewAnalytics} from './hooks';
import styles from './index.scss';

export type FooterProps = React.PropsWithChildren<{
  deepUrl?: string;
  linksBlock?: LinksBlock;
  pageId?: string;
  productId?: string;
  source?: PageSource;
}>;

const COLUMNS_COUNT_TO_STYLE_MAP: Record<string, string | undefined> = {
  '1': styles.withOneColumn,
  '2': styles.withTwoColumns,
  '3': styles.withThreeColumns,
  '4': styles.withMoreColumns,
};

export function Footer({
  children,
  deepUrl = '',
  linksBlock,
  pageId,
  productId,
  source,
}: FooterProps): JSX.Element {
  const bot = useBot();
  const scope = useScope();
  const {trustpilot, visaSecurity} = useDeviceVars();
  const previewRef = useFooterPreviewAnalytics({productId});

  // Apps
  const footerApps = <FooterApps deepUrl={deepUrl!} />;

  // Badges
  const showTrustpilotBadge = Boolean(
    scope.is(Scope.GLOBAL) && scope.notJmt && source !== PageSource.CART && trustpilot?.enabled,
  );
  const showVisaSecurityBadge = Boolean(
    scope.is(Scope.GLOBAL) && scope.notJmt && source !== PageSource.CART && visaSecurity,
  );
  const showFooterBadges = showTrustpilotBadge || showVisaSecurityBadge;
  const footerBadges = showFooterBadges && (
    <FooterBadges
      withDarkTheme={scope.not(Scope.GEEK)}
      withTrustpilot={showTrustpilotBadge}
      withVisaSecurity={showVisaSecurityBadge}
    />
  );

  // Links
  const showFooterLinks = Boolean(source === PageSource.PRODUCT && linksBlock?.links.length);
  const footerLinks = showFooterLinks && (
    <LinksSlider
      linksBlock={linksBlock!}
      productId={productId}
      withDarkTheme={scope.not(Scope.GEEK)}
    />
  );

  // Debug
  const showPageId = Boolean(bot && pageId);
  const showDebugItems = React.Children.count(children) > 0;
  const showFooterDebug = showPageId || showDebugItems;
  const footerDebug = showFooterDebug && <FooterDebug pageId={pageId}>{children}</FooterDebug>;

  // Columns
  const footerColumnBuyers = <FooterColumnBuyers />;
  const footerColumnPartners =
    scope.notJmt &&
    scope.is(Scope.GLOBAL, Scope.GEEK, Scope.COOLBE) &&
    // for coolbe domains but not coolbe prefix
    scope.prefixScope !== Scope.COOLBE ? (
      <FooterColumnPartners />
    ) : null;
  const footerColumnCompany = <FooterColumnCompany />;
  const footerColumnJoomGroup = scope.notJmt && scope.is(Scope.GLOBAL, Scope.GEEK) && (
    <FooterColumnJoomGroup />
  );
  const footerColumns = [
    footerColumnBuyers,
    footerColumnPartners,
    footerColumnCompany,
    footerColumnJoomGroup,
  ].filter(Boolean);
  const columnsClassName = COLUMNS_COUNT_TO_STYLE_MAP[String(clamp(footerColumns.length, 1, 4))];

  return (
    <Locator id="Footer">
      <footer
        className={cn(
          styles.footer,
          columnsClassName,
          scope.not(Scope.GEEK) && styles.withDarkTheme,
        )}
        ref={previewRef}
      >
        <Reducer noMarginBottom>
          <div className={styles.footerInner}>
            {footerApps && <div className={styles.footerApps}>{footerApps}</div>}
            <ul className={styles.footerColumns}>
              {footerColumns.map((footerColumn, footerColumnIndex) => (
                // eslint-disable-next-line react/no-array-index-key
                <li className={styles.footerColumn} key={footerColumnIndex}>
                  {footerColumn}
                  {footerColumnIndex === footerColumns.length - 1 && footerBadges && (
                    <div className={styles.footerColumnBadges}>{footerBadges}</div>
                  )}
                </li>
              ))}
            </ul>
            {footerBadges && <div className={styles.footerBadges}>{footerBadges}</div>}
          </div>
          {footerLinks && <div className={styles.footerLinks}>{footerLinks}</div>}
          <div className={styles.footerBottom}>
            <FooterBottom />
          </div>
          {footerDebug && <div className={styles.footerDebug}>{footerDebug}</div>}
        </Reducer>
      </footer>
    </Locator>
  );
}
