import memoize from 'utils/memoize';
import {VendorList} from './vendor';

let fakeElement = null;

function getFakeElement() {
  if (!fakeElement) {
    fakeElement = document.createElement('fakeelement');
  }
  return fakeElement;
}

function capitalizeProp(prop) {
  return prop.charAt(0).toUpperCase() + prop.slice(1);
}

const getCSSPrefix = memoize((vendor) => (vendor ? `-${vendor.toLowerCase()}-` : ''));

function isStylePropExists(property) {
  return getFakeElement().style[property] !== undefined;
}

export const findVendorByProp = memoize((prop) => {
  if (!isStylePropExists(prop)) {
    const capitalizedProp = capitalizeProp(prop);
    return VendorList.find((prefix) => isStylePropExists(`${prefix}${capitalizedProp}`));
  }
  return null;
});

export function findCSSPropByProp(prop) {
  const vendor = findVendorByProp(prop);
  if (vendor) {
    return `${getCSSPrefix(vendor)}${prop}`;
  }
  return prop;
}

export function findStylePropByProp(prop) {
  const vendor = findVendorByProp(prop);
  if (vendor) {
    return `${vendor}${capitalizeProp(prop)}`;
  }
  return prop;
}
