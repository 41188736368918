import classnames from 'classnames/bind';
import {Image} from 'components/Image';
import {Overlay} from 'components/Overlay';
import {QrCode} from 'components/QrCode';
import {type Locator, type Mark, createLocator} from 'create-locator';
import {useIsBreakpointMobile} from 'hooks/useIsBreakpointMobile';
import React, {ReactElement, useMemo} from 'react';
import {Color} from 'types/Color';
import {convertBackendColorToCSSValue} from 'utils/styles/color';

import {useImageLoading} from '../Content/useImageLoading';
import {BackgroundImageProps, ImageProps} from '../types';
import styles from './index.scss';

const cn = classnames.bind(styles);

export type IllustrationLocator = Locator<{image: void; background: void}>;

export type Props = {
  backgroundColor?: Color;
  backgroundImage?: BackgroundImageProps;
  image?: ImageProps;
  qrLink?: string;
  largeOnMobile?: boolean;
} & Partial<Mark<IllustrationLocator>>;

const DEFAULT_IMAGE_VWFIT = {md: 100, xl: '400px'};
const DEFAULT_IMAGE_PXFIT = 400;
const MINIMUM_LOADING_TIME = 250;
const QR_DESKTOP_SIZE = 200;
const QR_MOBILE_LARGE_SIZE = 160;
const QR_MOBILE_SMALL_SIZE = 120;

export function Illustration(props: Props): ReactElement {
  const {backgroundColor, backgroundImage, image, largeOnMobile, qrLink} = props;
  const locator = createLocator(props);
  const isMobileLarge = useIsBreakpointMobile('md');
  const isMobileSmall = useIsBreakpointMobile('sm');
  const [backgroundImageLoading, handleBackgroundImage] = useImageLoading(
    backgroundImage?.image,
    MINIMUM_LOADING_TIME,
  );
  const [imageLoading, handleImage] = useImageLoading(image?.image, MINIMUM_LOADING_TIME);

  const style = useMemo(() => {
    const color = convertBackendColorToCSSValue(backgroundColor);
    return color ? {backgroundColor: color} : undefined;
  }, [backgroundColor]);

  let qrSize = QR_DESKTOP_SIZE;

  if (isMobileLarge) {
    qrSize = QR_MOBILE_LARGE_SIZE;
  }
  if (isMobileSmall) {
    qrSize = QR_MOBILE_SMALL_SIZE;
  }

  const loading = backgroundImageLoading || (imageLoading && !qrLink);

  return (
    <div className={cn('illustration', {largeOnMobile})} style={style}>
      <Overlay cover loading={loading}>
        <div className={cn('illustrationWrapper', {loading})} {...locator()}>
          {backgroundImage ? (
            <Image
              {...backgroundImage}
              onComplete={handleBackgroundImage}
              width="100%"
              height="100%"
              className={styles.backgroundImage}
              pxFit={backgroundImage.pxFit || DEFAULT_IMAGE_PXFIT}
              vwFit={backgroundImage.vwFit || DEFAULT_IMAGE_VWFIT}
              {...locator.background()}
            />
          ) : null}
          {image && !qrLink ? (
            <Image
              {...image}
              onComplete={handleImage}
              className={styles.image}
              pxFit={image.pxFit || DEFAULT_IMAGE_PXFIT}
              vwFit={image.vwFit || DEFAULT_IMAGE_VWFIT}
              {...locator.image()}
            />
          ) : null}
          {qrLink ? (
            <div className={styles.qrCode} style={{width: qrSize, height: qrSize}}>
              <QrCode value={qrLink} width={qrSize} height={qrSize} />
            </div>
          ) : null}
        </div>
      </Overlay>
    </div>
  );
}
