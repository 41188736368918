import PropTypes from 'prop-types';
import React from 'react';
import {Link} from 'react-router-dom';
import styles from './Button.scss';

function Button(props) {
  const {children, className, to, icon} = props;

  const elementProps = {
    ...props,
    className: styles.button,
  };
  delete elementProps.icon;

  const iconElement = icon ? <img alt="" src={icon} className={styles.icon} /> : null;
  const childrenElement = <span className={styles.inner}>{children}</span>;

  if (to) {
    return (
      <Link {...elementProps}>
        <span className={className}>
          {iconElement}
          {childrenElement}
        </span>
      </Link>
    );
  }

  return (
    <span {...elementProps}>
      <span className={className}>
        {iconElement}
        {childrenElement}
      </span>
    </span>
  );
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  icon: PropTypes.string,
};

Button.defaultProps = {
  className: '',
  to: '',
  icon: null,
};

export default Button;
