import PropTypes from 'prop-types';
import React, {Component} from 'react';
import classnames from 'classnames/bind';
import {DialogModal} from 'components/DialogModal';
import styles from './index.scss';

const cn = classnames.bind(styles);

export default class Popup extends Component {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    onClose: PropTypes.func,
    noPortal: PropTypes.bool,
  };

  static defaultProps = {
    children: null,
    className: '',
    onClose: null,
    noPortal: false,
  };

  constructor(props) {
    super(props);
    this.handleOverlayClick = this.handleOverlayClick.bind(this);
  }

  handleOverlayClick(evt) {
    if (this.props.onClose && evt.target === evt.currentTarget) {
      this.props.onClose();
    }
  }

  render() {
    const {className, children, onClose, ...props} = this.props;
    delete props.onClose;

    return (
      <DialogModal
        {...props}
        className={cn('popup', className)}
        onClose={onClose}
        onClick={this.handleOverlayClick}
      >
        {children}
      </DialogModal>
    );
  }
}
