import {Color} from 'types/Color';
import {isRecord, isString} from 'utils/guards';

export type GradientDirection =
  // draw the gradient from the top to the bottom  direction:
  | 'TOP_BOTTOM'
  // draw the gradient from the top-start to the bottom-end
  | 'TS_BE'
  // draw the gradient from end to start
  | 'END_START'
  // draw the gradient from the end to the start
  | 'BE_TS'
  // draw the gradient from the bottom to the top
  | 'BOTTOM_TOP'
  // draw the gradient from the bottom-start to the top-end
  | 'BS_TE'
  // draw the gradient from the start to the end
  | 'START_END'
  // draw the gradient from the top-start to the bottom-end
  | 'TS_BE';

export type Gradient = {
  themed?: string;
  colorStart: Color;
  colorEnd: Color;
  direction?: GradientDirection;
};

export type LinearGradientColor = {
  color: Color;
  // [0, 1] относительная позиция цвета
  position: number;
};

export type LinearGradientOrientation = 'vertical' | 'horizontal';

export type LinearMulticolorGradient = {
  themed?: string;
  colors: LinearGradientColor[];
  orientation: LinearGradientOrientation;
};

export type LinearGradient = Gradient | LinearMulticolorGradient;

export function isLinearMulticolorGradient(value: unknown): value is LinearMulticolorGradient {
  return isRecord(value) && Array.isArray(value.colors) && isString(value.orientation);
}

export function isLinearGradient(value: unknown): value is LinearGradient {
  return (
    isRecord(value) &&
    ((isString(value.colorStart) && isString(value.colorEnd)) || isLinearMulticolorGradient(value))
  );
}
