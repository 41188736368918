import {createSelectorFactory, createStateGetter} from 'store/utils/selectors';

const moduleSelector = createSelectorFactory(createStateGetter('categoryPromoLinks'));

export const getCategoryPromoLinks = moduleSelector(({state}) => state.data);

export const needLoadCategoryPromoLinks = moduleSelector(({state, language}) => {
  return Boolean(
    !state.loading && (!state.loaded || (state.loaded && state.language !== language)),
  );
});
