import {
  Action,
  AnyAction,
  combineReducers as combineReducersDev,
  Reducer,
  ReducersMapObject,
} from 'redux';
import {TypedObject} from 'utils/object/typed';

// default combineReducers has check at init in production, which take near 10ms for each call
// https://github.com/reduxjs/redux/issues/3440
function combineReducersProd<S, A extends Action = AnyAction>(
  reducers: ReducersMapObject<S, A>,
): Reducer<S, A> {
  const reducerKeys = TypedObject.keys(reducers);
  const finalReducers = {} as ReducersMapObject<S, A>;
  for (const key of reducerKeys) {
    if (typeof reducers[key] === 'function') {
      finalReducers[key] = reducers[key];
    }
  }
  const finalReducerKeys = TypedObject.keys(finalReducers);

  return function combination(state: S = {} as S, action: A) {
    let hasChanged = false;
    const nextState = {} as S;
    for (const key of finalReducerKeys) {
      const reducer = finalReducers[key];
      const previousStateForKey = state[key];
      const nextStateForKey = reducer(previousStateForKey, action);
      if (typeof nextStateForKey === 'undefined') {
        const actionType = action && action.type;
        throw new Error(
          `When called with an action of type ${
            actionType ? `"${String(actionType)}"` : '(unknown type)'
          }, the slice reducer for key "${key}" returned undefined. ` +
            `To ignore an action, you must explicitly return the previous state. ` +
            `If you want this reducer to hold no value, you can return null instead of undefined.`,
        );
      }
      nextState[key] = nextStateForKey;
      hasChanged = hasChanged || nextStateForKey !== previousStateForKey;
    }
    hasChanged =
      hasChanged || finalReducerKeys.length !== Object.keys(state as unknown as object).length;
    return hasChanged ? nextState : state;
  };
}

export const combineReducers =
  process.env.NODE_ENV === 'production' ? combineReducersProd : combineReducersDev;
