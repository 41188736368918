import {defineMessages, IntlShape, MessageDescriptor} from 'react-intl';

import {countryPhoneCodes} from './countryPhoneCodes';

const names = defineMessages({
  ad: {description: 'Country name', defaultMessage: 'Andorra'},
  ae: {
    description: 'Country name',
    defaultMessage: 'United Arab Emirates',
  },
  af: {description: 'Country name', defaultMessage: 'Afghanistan'},
  ag: {description: 'Country name', defaultMessage: 'Antigua & Barbuda'},
  ai: {description: 'Country name', defaultMessage: 'Anguilla'},
  al: {description: 'Country name', defaultMessage: 'Albania'},
  am: {description: 'Country name', defaultMessage: 'Armenia'},
  ao: {description: 'Country name', defaultMessage: 'Angola'},
  ar: {description: 'Country name', defaultMessage: 'Argentina'},
  as: {description: 'Country name', defaultMessage: 'American Samoa'},
  at: {description: 'Country name', defaultMessage: 'Austria'},
  au: {description: 'Country name', defaultMessage: 'Australia'},
  aw: {description: 'Country name', defaultMessage: 'Aruba'},
  ax: {description: 'Country name', defaultMessage: 'Åland Islands'},
  az: {description: 'Country name', defaultMessage: 'Azerbaijan'},
  ba: {description: 'Country name', defaultMessage: 'Bosnia & Herzegovina'},
  bb: {description: 'Country name', defaultMessage: 'Barbados'},
  bd: {description: 'Country name', defaultMessage: 'Bangladesh'},
  be: {description: 'Country name', defaultMessage: 'Belgium'},
  bf: {description: 'Country name', defaultMessage: 'Burkina Faso'},
  bg: {description: 'Country name', defaultMessage: 'Bulgaria'},
  bh: {description: 'Country name', defaultMessage: 'Bahrain'},
  bi: {description: 'Country name', defaultMessage: 'Burundi'},
  bj: {description: 'Country name', defaultMessage: 'Benin'},
  bl: {description: 'Country name', defaultMessage: 'St. Barthélemy'},
  bm: {description: 'Country name', defaultMessage: 'Bermuda'},
  bn: {description: 'Country name', defaultMessage: 'Brunei'},
  bo: {description: 'Country name', defaultMessage: 'Bolivia'},
  bq: {description: 'Country name', defaultMessage: 'Caribbean Netherlands'},
  br: {description: 'Country name', defaultMessage: 'Brazil'},
  bs: {description: 'Country name', defaultMessage: 'Bahamas'},
  bt: {description: 'Country name', defaultMessage: 'Bhutan'},
  bw: {description: 'Country name', defaultMessage: 'Botswana'},
  by: {description: 'Country name', defaultMessage: 'Belarus'},
  bz: {description: 'Country name', defaultMessage: 'Belize'},
  ca: {description: 'Country name', defaultMessage: 'Canada'},
  cc: {
    description: 'Country name',
    defaultMessage: 'Cocos (Keeling) Islands',
  },
  cd: {description: 'Country name', defaultMessage: 'Congo - Kinshasa'},
  cf: {
    description: 'Country name',
    defaultMessage: 'Central African Republic',
  },
  cg: {description: 'Country name', defaultMessage: 'Congo - Brazzaville'},
  ch: {description: 'Country name', defaultMessage: 'Switzerland'},
  ci: {description: 'Country name', defaultMessage: 'Côte d’Ivoire'},
  ck: {description: 'Country name', defaultMessage: 'Cook Islands'},
  cl: {description: 'Country name', defaultMessage: 'Chile'},
  cm: {description: 'Country name', defaultMessage: 'Cameroon'},
  cn: {description: 'Country name', defaultMessage: 'China'},
  co: {description: 'Country name', defaultMessage: 'Colombia'},
  cr: {description: 'Country name', defaultMessage: 'Costa Rica'},
  cu: {description: 'Country name', defaultMessage: 'Cuba'},
  cv: {description: 'Country name', defaultMessage: 'Cape Verde'},
  cw: {description: 'Country name', defaultMessage: 'Curaçao'},
  cx: {description: 'Country name', defaultMessage: 'Christmas Island'},
  cy: {description: 'Country name', defaultMessage: 'Cyprus'},
  cz: {description: 'Country name', defaultMessage: 'Czech Republic'},
  de: {description: 'Country name', defaultMessage: 'Germany'},
  dj: {description: 'Country name', defaultMessage: 'Djibouti'},
  dk: {description: 'Country name', defaultMessage: 'Denmark'},
  dm: {description: 'Country name', defaultMessage: 'Dominica'},
  do: {description: 'Country name', defaultMessage: 'Dominican Republic'},
  dz: {description: 'Country name', defaultMessage: 'Algeria'},
  ec: {description: 'Country name', defaultMessage: 'Ecuador'},
  ee: {description: 'Country name', defaultMessage: 'Estonia'},
  eg: {description: 'Country name', defaultMessage: 'Egypt'},
  eh: {description: 'Country name', defaultMessage: 'Western Sahara'},
  er: {description: 'Country name', defaultMessage: 'Eritrea'},
  es: {description: 'Country name', defaultMessage: 'Spain'},
  et: {description: 'Country name', defaultMessage: 'Ethiopia'},
  fi: {description: 'Country name', defaultMessage: 'Finland'},
  fj: {description: 'Country name', defaultMessage: 'Fiji'},
  fk: {description: 'Country name', defaultMessage: 'Falkland Islands (Islas Malvinas)'},
  fm: {description: 'Country name', defaultMessage: 'Micronesia'},
  fo: {description: 'Country name', defaultMessage: 'Faroe Islands'},
  fr: {description: 'Country name', defaultMessage: 'France'},
  ga: {description: 'Country name', defaultMessage: 'Gabon'},
  gb: {description: 'Country name', defaultMessage: 'United Kingdom'},
  gd: {description: 'Country name', defaultMessage: 'Grenada'},
  ge: {description: 'Country name', defaultMessage: 'Georgia'},
  gf: {description: 'Country name', defaultMessage: 'French Guiana'},
  gg: {description: 'Country name', defaultMessage: 'Guernsey'},
  gh: {description: 'Country name', defaultMessage: 'Ghana'},
  gi: {description: 'Country name', defaultMessage: 'Gibraltar'},
  gl: {description: 'Country name', defaultMessage: 'Greenland'},
  gm: {description: 'Country name', defaultMessage: 'Gambia'},
  gn: {description: 'Country name', defaultMessage: 'Guinea'},
  gp: {description: 'Country name', defaultMessage: 'Guadeloupe'},
  gq: {description: 'Country name', defaultMessage: 'Equatorial Guinea'},
  gr: {description: 'Country name', defaultMessage: 'Greece'},
  gt: {description: 'Country name', defaultMessage: 'Guatemala'},
  gu: {description: 'Country name', defaultMessage: 'Guam'},
  gw: {description: 'Country name', defaultMessage: 'Guinea-Bissau'},
  gy: {description: 'Country name', defaultMessage: 'Guyana'},
  hk: {description: 'Country name', defaultMessage: 'Hong Kong SAR China'},
  hn: {description: 'Country name', defaultMessage: 'Honduras'},
  hr: {description: 'Country name', defaultMessage: 'Croatia'},
  ht: {description: 'Country name', defaultMessage: 'Haiti'},
  hu: {description: 'Country name', defaultMessage: 'Hungary'},
  id: {description: 'Country name', defaultMessage: 'Indonesia'},
  ie: {description: 'Country name', defaultMessage: 'Ireland'},
  il: {description: 'Country name', defaultMessage: 'Israel'},
  im: {description: 'Country name', defaultMessage: 'Isle of Man'},
  in: {description: 'Country name', defaultMessage: 'India'},
  io: {
    description: 'Country name',
    defaultMessage: 'British Indian Ocean Territory',
  },
  iq: {description: 'Country name', defaultMessage: 'Iraq'},
  ir: {description: 'Country name', defaultMessage: 'Iran'},
  is: {description: 'Country name', defaultMessage: 'Iceland'},
  it: {description: 'Country name', defaultMessage: 'Italy'},
  je: {description: 'Country name', defaultMessage: 'Jersey'},
  jm: {description: 'Country name', defaultMessage: 'Jamaica'},
  jo: {description: 'Country name', defaultMessage: 'Jordan'},
  jp: {description: 'Country name', defaultMessage: 'Japan'},
  ke: {description: 'Country name', defaultMessage: 'Kenya'},
  kg: {description: 'Country name', defaultMessage: 'Kyrgyzstan'},
  kh: {description: 'Country name', defaultMessage: 'Cambodia'},
  ki: {description: 'Country name', defaultMessage: 'Kiribati'},
  km: {description: 'Country name', defaultMessage: 'Comoros'},
  kn: {description: 'Country name', defaultMessage: 'St. Kitts & Nevis'},
  kp: {description: 'Country name', defaultMessage: 'North Korea'},
  kr: {description: 'Country name', defaultMessage: 'South Korea'},
  kw: {description: 'Country name', defaultMessage: 'Kuwait'},
  ky: {description: 'Country name', defaultMessage: 'Cayman Islands'},
  kz: {description: 'Country name', defaultMessage: 'Kazakhstan'},
  la: {description: 'Country name', defaultMessage: 'Laos'},
  lb: {description: 'Country name', defaultMessage: 'Lebanon'},
  lc: {description: 'Country name', defaultMessage: 'St. Lucia'},
  li: {description: 'Country name', defaultMessage: 'Liechtenstein'},
  lk: {description: 'Country name', defaultMessage: 'Sri Lanka'},
  lr: {description: 'Country name', defaultMessage: 'Liberia'},
  ls: {description: 'Country name', defaultMessage: 'Lesotho'},
  lt: {description: 'Country name', defaultMessage: 'Lithuania'},
  lu: {description: 'Country name', defaultMessage: 'Luxembourg'},
  lv: {description: 'Country name', defaultMessage: 'Latvia'},
  ly: {description: 'Country name', defaultMessage: 'Libya'},
  ma: {description: 'Country name', defaultMessage: 'Morocco'},
  mc: {description: 'Country name', defaultMessage: 'Monaco'},
  md: {description: 'Country name', defaultMessage: 'Moldova'},
  me: {description: 'Country name', defaultMessage: 'Montenegro'},
  mf: {description: 'Country name', defaultMessage: 'St. Martin'},
  mg: {description: 'Country name', defaultMessage: 'Madagascar'},
  mh: {description: 'Country name', defaultMessage: 'Marshall Islands'},
  mk: {description: 'Country name', defaultMessage: 'North Macedonia'},
  ml: {description: 'Country name', defaultMessage: 'Mali'},
  mm: {description: 'Country name', defaultMessage: 'Myanmar (Burma)'},
  mn: {description: 'Country name', defaultMessage: 'Mongolia'},
  mo: {description: 'Country name', defaultMessage: 'Macau SAR China'},
  mp: {
    description: 'Country name',
    defaultMessage: 'Northern Mariana Islands',
  },
  mq: {description: 'Country name', defaultMessage: 'Martinique'},
  mr: {description: 'Country name', defaultMessage: 'Mauritania'},
  ms: {description: 'Country name', defaultMessage: 'Montserrat'},
  mt: {description: 'Country name', defaultMessage: 'Malta'},
  mu: {description: 'Country name', defaultMessage: 'Mauritius'},
  mv: {description: 'Country name', defaultMessage: 'Maldives'},
  mw: {description: 'Country name', defaultMessage: 'Malawi'},
  mx: {description: 'Country name', defaultMessage: 'Mexico'},
  my: {description: 'Country name', defaultMessage: 'Malaysia'},
  mz: {description: 'Country name', defaultMessage: 'Mozambique'},
  na: {description: 'Country name', defaultMessage: 'Namibia'},
  nc: {description: 'Country name', defaultMessage: 'New Caledonia'},
  ne: {description: 'Country name', defaultMessage: 'Niger'},
  nf: {description: 'Country name', defaultMessage: 'Norfolk Island'},
  ng: {description: 'Country name', defaultMessage: 'Nigeria'},
  ni: {description: 'Country name', defaultMessage: 'Nicaragua'},
  nl: {description: 'Country name', defaultMessage: 'Netherlands'},
  no: {description: 'Country name', defaultMessage: 'Norway'},
  np: {description: 'Country name', defaultMessage: 'Nepal'},
  nr: {description: 'Country name', defaultMessage: 'Nauru'},
  nu: {description: 'Country name', defaultMessage: 'Niue'},
  nz: {description: 'Country name', defaultMessage: 'New Zealand'},
  om: {description: 'Country name', defaultMessage: 'Oman'},
  pa: {description: 'Country name', defaultMessage: 'Panama'},
  pe: {description: 'Country name', defaultMessage: 'Peru'},
  pf: {description: 'Country name', defaultMessage: 'French Polynesia'},
  pg: {description: 'Country name', defaultMessage: 'Papua New Guinea'},
  ph: {description: 'Country name', defaultMessage: 'Philippines'},
  pk: {description: 'Country name', defaultMessage: 'Pakistan'},
  pl: {description: 'Country name', defaultMessage: 'Poland'},
  pm: {description: 'Country name', defaultMessage: 'St. Pierre & Miquelon'},
  pn: {description: 'Country name', defaultMessage: 'Pitcairn Islands'},
  pr: {description: 'Country name', defaultMessage: 'Puerto Rico'},
  ps: {description: 'Country name', defaultMessage: 'Palestinian Territories'},
  pt: {description: 'Country name', defaultMessage: 'Portugal'},
  pw: {description: 'Country name', defaultMessage: 'Palau'},
  py: {description: 'Country name', defaultMessage: 'Paraguay'},
  qa: {description: 'Country name', defaultMessage: 'Qatar'},
  re: {description: 'Country name', defaultMessage: 'Réunion'},
  ro: {description: 'Country name', defaultMessage: 'Romania'},
  rs: {description: 'Country name', defaultMessage: 'Serbia'},
  ru: {description: 'Country name', defaultMessage: 'Russia'},
  rw: {description: 'Country name', defaultMessage: 'Rwanda'},
  sa: {description: 'Country name', defaultMessage: 'Saudi Arabia'},
  sb: {description: 'Country name', defaultMessage: 'Solomon Islands'},
  sc: {description: 'Country name', defaultMessage: 'Seychelles'},
  sd: {description: 'Country name', defaultMessage: 'Sudan'},
  se: {description: 'Country name', defaultMessage: 'Sweden'},
  sg: {description: 'Country name', defaultMessage: 'Singapore'},
  sh: {description: 'Country name', defaultMessage: 'St. Helena'},
  si: {description: 'Country name', defaultMessage: 'Slovenia'},
  sj: {description: 'Country name', defaultMessage: 'Svalbard & Jan Mayen'},
  sk: {description: 'Country name', defaultMessage: 'Slovakia'},
  sl: {description: 'Country name', defaultMessage: 'Sierra Leone'},
  sm: {description: 'Country name', defaultMessage: 'San Marino'},
  sn: {description: 'Country name', defaultMessage: 'Senegal'},
  so: {description: 'Country name', defaultMessage: 'Somalia'},
  sr: {description: 'Country name', defaultMessage: 'Suriname'},
  ss: {description: 'Country name', defaultMessage: 'South Sudan'},
  st: {description: 'Country name', defaultMessage: 'São Tomé & Príncipe'},
  sv: {description: 'Country name', defaultMessage: 'El Salvador'},
  sx: {description: 'Country name', defaultMessage: 'Sint Maarten'},
  sy: {description: 'Country name', defaultMessage: 'Syria'},
  sz: {description: 'Country name', defaultMessage: 'Swaziland'},
  tc: {description: 'Country name', defaultMessage: 'Turks & Caicos Islands'},
  td: {description: 'Country name', defaultMessage: 'Chad'},
  tg: {description: 'Country name', defaultMessage: 'Togo'},
  th: {description: 'Country name', defaultMessage: 'Thailand'},
  tj: {description: 'Country name', defaultMessage: 'Tajikistan'},
  tk: {description: 'Country name', defaultMessage: 'Tokelau'},
  tl: {description: 'Country name', defaultMessage: 'Timor-Leste'},
  tm: {description: 'Country name', defaultMessage: 'Turkmenistan'},
  tn: {description: 'Country name', defaultMessage: 'Tunisia'},
  to: {description: 'Country name', defaultMessage: 'Tonga'},
  tr: {description: 'Country name', defaultMessage: 'Turkey'},
  tt: {description: 'Country name', defaultMessage: 'Trinidad & Tobago'},
  tv: {description: 'Country name', defaultMessage: 'Tuvalu'},
  tw: {description: 'Country name', defaultMessage: 'Taiwan'},
  tz: {description: 'Country name', defaultMessage: 'Tanzania'},
  ua: {description: 'Country name', defaultMessage: 'Ukraine'},
  ug: {description: 'Country name', defaultMessage: 'Uganda'},
  um: {
    description: 'Country name',
    defaultMessage: 'United States Minor Outlying Islands',
  },
  us: {description: 'Country name', defaultMessage: 'United States'},
  uy: {description: 'Country name', defaultMessage: 'Uruguay'},
  uz: {description: 'Country name', defaultMessage: 'Uzbekistan'},
  va: {description: 'Country name', defaultMessage: 'Vatican City'},
  vc: {description: 'Country name', defaultMessage: 'St. Vincent & Grenadines'},
  ve: {description: 'Country name', defaultMessage: 'Venezuela'},
  vg: {description: 'Country name', defaultMessage: 'British Virgin Islands'},
  vi: {description: 'Country name', defaultMessage: 'U.S. Virgin Islands'},
  vn: {description: 'Country name', defaultMessage: 'Vietnam'},
  vu: {description: 'Country name', defaultMessage: 'Vanuatu'},
  wf: {description: 'Country name', defaultMessage: 'Wallis & Futuna'},
  ws: {description: 'Country name', defaultMessage: 'Samoa'},
  xk: {description: 'Country name', defaultMessage: 'Kosovo'},
  ye: {description: 'Country name', defaultMessage: 'Yemen'},
  yt: {description: 'Country name', defaultMessage: 'Mayotte'},
  za: {description: 'Country name', defaultMessage: 'South Africa'},
  zm: {description: 'Country name', defaultMessage: 'Zambia'},
  zw: {description: 'Country name', defaultMessage: 'Zimbabwe'},
});

const namesToCountry = defineMessages({
  cn: {defaultMessage: 'to China', description: `Например: "Доставка в Китай"`},
  pl: {defaultMessage: 'to Poland', description: `Например: "Доставка в Польшу"`},
  es: {defaultMessage: 'to Spain', description: `Например: "Доставка в Испанию"`},
  ch: {defaultMessage: 'to Switzerland', description: `Например: "Доставка в Швейцарию"`},
  au: {defaultMessage: 'to Australia', description: `Например: "Доставка в Австралию"`},
  cz: {defaultMessage: 'to the Czech Republic', description: `Например: "Доставка в Чехию"`},
  tr: {defaultMessage: 'to Turkey', description: `Например: "Доставка в Турцию"`},
  kr: {defaultMessage: 'to Korea', description: `Например: "Доставка в Корею"`},
  jp: {defaultMessage: 'to Japan', description: `Например: "Доставка в Японию"`},
  de: {defaultMessage: 'to Germany', description: `Например: "Доставка в Германию"`},
  ua: {defaultMessage: 'to Ukraine', description: `Например: "Доставка в Украину"`},
  lu: {defaultMessage: 'to Luxembourg', description: `Например: "Доставка в Люксембург"`},
  ru: {defaultMessage: 'to Russia', description: `Например: "Доставка в Россию"`},
  us: {defaultMessage: 'to the United States', description: `Например: "Доставка в США"`},
  fr: {defaultMessage: 'to France', description: `Например: "Доставка во Францию"`},
  it: {defaultMessage: 'to Italy', description: `Например: "Доставка в Италию"`},
  gb: {
    defaultMessage: 'to the United Kingdom',
    description: `Например: "Доставка в Великобританию"`,
  },
  pt: {defaultMessage: 'to Portugal', description: `Например: "Доставка в Португалию"`},
  nl: {defaultMessage: 'to the Netherlands', description: `Например: "Доставка в Нидерланды"`},
  se: {defaultMessage: 'to Sweden', description: `Например: "Доставка в Швецию"`},
  th: {defaultMessage: 'to Thailand', description: `Например: "Доставка в Таиланд"`},
  il: {defaultMessage: 'to Israel', description: `Например: "Доставка в Израиль"`},
  md: {defaultMessage: 'to Moldova', description: `Например: "Доставка в Молдову"`},
  be: {defaultMessage: 'to Belgium', description: `Например: "Доставка в Бельгию"`},
  by: {defaultMessage: 'to Belarus', description: `Например: "Доставка в Беларусь"`},
  ca: {defaultMessage: 'to Canada', description: `Например: "Доставка в Канаду"`},
  cy: {defaultMessage: 'to Cyprus', description: `Например: "Доставка в Кипр"`},
  ro: {defaultMessage: 'to Romania', description: `Например: "Доставка в Румынию"`},
  at: {defaultMessage: 'to Austria', description: `Например: "Доставка в Австрию"`},
  bg: {defaultMessage: 'to Bulgaria', description: `Например: "Доставка в Болгарию"`},
  hu: {defaultMessage: 'to Hungary', description: `Например: "Доставка в Венгрию"`},
  gr: {defaultMessage: 'to Greece', description: `Например: "Доставка в Грецию"`},
  dk: {defaultMessage: 'to Denmark', description: `Например: "Доставка в Данию"`},
  ie: {defaultMessage: 'to Ireland', description: `Например: "Доставка в Ирландию"`},
  lv: {defaultMessage: 'to Latvia', description: `Например: "Доставка в Латвию"`},
  lt: {defaultMessage: 'to Lithuania', description: `Например: "Доставка в Литву"`},
  mt: {defaultMessage: 'to Malta', description: `Например: "Доставка в Мальту"`},
  sk: {defaultMessage: 'to Slovakia', description: `Например: "Доставка в Словакию"`},
  si: {defaultMessage: 'to Slovenia', description: `Например: "Доставка в Словению"`},
  fi: {defaultMessage: 'to Finland', description: `Например: "Доставка в Финляндию"`},
  hr: {defaultMessage: 'to Croatia', description: `Например: "Доставка в Хорватию"`},
  ee: {defaultMessage: 'to Estonia', description: `Например: "Доставка в Эстонию"`},
  in: {defaultMessage: 'to India', description: `Например: "Доставка в Индию"`},
  sa: {
    defaultMessage: 'to Saudi Arabia',
    description: `Например: "Доставка в Саудовскую Аравию"`,
  },
  nz: {defaultMessage: 'to New Zealand', description: `Например: "Доставка в Новую Зеландию"`},
  bd: {defaultMessage: 'to Bangladesh', description: `Например: "Доставка в Бангладеш"`},
  bb: {defaultMessage: 'to Barbados', description: `Например: "Доставка в Барбадос"`},
  cw: {defaultMessage: 'to Curaçao', description: `Например: "Доставка в Кюрасао"`},
  dj: {defaultMessage: 'to Djibouti', description: `Например: "Доставка в Джибути"`},
  gl: {defaultMessage: 'to Greenland', description: `Например: "Доставка в Гренландию"`},
  gw: {defaultMessage: 'to Guinea-Bissau', description: `Например: "Доставка в Гвинею-Бисау"`},
  jo: {defaultMessage: 'to Jordan', description: `Например: "Доставка в Иорданияю"`},
  aq: {defaultMessage: 'to Antarctica', description: `Например: "Доставка в Антарктику"`},
  mk: {
    defaultMessage: 'to North Macedonia',
    description: `Например: "Доставка в Северную Македонию"`,
  },
  ng: {defaultMessage: 'to Nigeria', description: `Например: "Доставка в Нигерию"`},
  pn: {defaultMessage: 'to the Pitcairn Islands', description: `Например: "Доставка в Питкэрн"`},
  rw: {defaultMessage: 'to Rwanda', description: `Например: "Доставка в Руанду"`},
  to: {defaultMessage: 'to Tonga', description: `Например: "Доставка в Тонга"`},
  kp: {
    defaultMessage: "to the Democratic People's Republic of Korea",
    description: `Например: "Доставка в Корейскую Народно-Демократическую Республику"`,
  },
  ph: {defaultMessage: 'to the Philippines', description: `Например: "Доставка на Филиппины"`},
  gu: {defaultMessage: 'to Guam', description: `Например: "Доставка в Гуам"`},
  li: {defaultMessage: 'to Liechtenstein', description: `Например: "Доставка в Лихтенштейн"`},
  nc: {defaultMessage: 'to New Caledonia', description: `Например: "Доставка в Новую Каледонию"`},
  sh: {defaultMessage: 'to St. Helena', description: `Например: "Доставка на Святую Елену"`},
  kn: {
    defaultMessage: 'to Saint Kitts and Nevis',
    description: `Например: "Доставка в Сент-Китс и Невис"`,
  },
  sm: {defaultMessage: 'to San Marino', description: `Например: "Доставка в Сан-Марино"`},
  tv: {defaultMessage: 'to Tuvalu', description: `Например: "Доставка в Тувалу"`},
  um: {
    defaultMessage: 'to the United States Minor Outlying Islands',
    description: `Например: "Доставка в на Малые Тихоокеанские отдаленныеострова Соединенных штатов"`,
  },
  gg: {defaultMessage: 'to Guernsey', description: `Например: "Доставка в Гернси"`},
  kz: {defaultMessage: 'to Kazakhstan', description: `Например: "Доставка в Казахстан"`},
  kw: {defaultMessage: 'to Kuwait', description: `Например: "Доставка в Кувейт"`},
  sn: {defaultMessage: 'to Senegal', description: `Например: "Доставка в Сенегал"`},
  sl: {defaultMessage: 'to Sierra Leone', description: `Например: "Доставка в Сьерра-Леоне"`},
  ao: {defaultMessage: 'to Angola', description: `Например: "Доставка в Анголу"`},
  gf: {
    defaultMessage: 'to French Guiana',
    description: `Например: "Доставка в Французскую Гвиану"`,
  },
  jm: {defaultMessage: 'to Jamaica', description: `Например: "Доставка на Ямайку"`},
  np: {defaultMessage: 'to Nepal', description: `Например: "Доставка в Непал"`},
  cv: {
    defaultMessage: 'to the Republic of Cabo Verde',
    description: `Например: "Доставка в Кабо-Верде"`,
  },
  ki: {defaultMessage: 'to Kiribati', description: `Например: "Доставка в Кирибати"`},
  ve: {defaultMessage: 'to Venezuela', description: `Например: "Доставка в Венесуэлу"`},
  do: {
    defaultMessage: 'to the Dominican Republic',
    description: `Например: "Доставка в Доминиканскую Республику"`,
  },
  bi: {defaultMessage: 'to Burundi', description: `Например: "Доставка в Бурунди"`},
  ky: {
    defaultMessage: 'to the Cayman Islands',
    description: `Например: "Доставка на Кайманские острова"`,
  },
  ge: {defaultMessage: 'to Georgia', description: `Например: "Доставка в Грузию"`},
  om: {defaultMessage: 'to Oman', description: `Например: "Доставка в Оман"`},
  rs: {defaultMessage: 'to Serbia', description: `Например: "Доставка в Сербию"`},
  sr: {defaultMessage: 'to Suriname', description: `Например: "Доставка в Суринам"`},
  dz: {defaultMessage: 'to Algeria', description: `Например: "Доставка в Алжир"`},
  aw: {defaultMessage: 'to Aruba', description: `Например: "Доставка в Аруба"`},
  bn: {
    defaultMessage: 'to Brunei Darussalam',
    description: `Например: "Доставка в Бруней-Даруссалам"`,
  },
  cm: {defaultMessage: 'to Cameroon', description: `Например: "Доставка в Камерун"`},
  gy: {defaultMessage: 'to Guyana', description: `Например: "Доставка в Гайану"`},
  is: {defaultMessage: 'to Iceland', description: `Например: "Доставка в Исландию"`},
  mv: {defaultMessage: 'to the Maldives', description: `Например: "Доставка на Мальдивы"`},
  sd: {defaultMessage: 'to Sudan', description: `Например: "Доставка в Судан"`},
  am: {defaultMessage: 'to Armenia', description: `Например: "Доставка в Армению"`},
  wf: {
    defaultMessage: 'to Wallis and Futuna',
    description: `Например: "Доставка в Уоллис и Футуна"`,
  },
  mu: {defaultMessage: 'to Mauritius', description: `Например: "Доставка на Маврикий"`},
  fm: {defaultMessage: 'to Micronesia', description: `Например: "Доставка в Микронезию"`},
  sj: {
    defaultMessage: 'to Svalbard and Jan Mayen',
    description: `Например: "Доставка в Свальбард (Шпицберген) и Ян-Майен"`,
  },
  bf: {defaultMessage: 'to Burkina Faso', description: `Например: "Доставка в Буркина-Фасо"`},
  gp: {defaultMessage: 'to Guadeloupe', description: `Например: "Доставка в Гваделупу"`},
  hm: {
    defaultMessage: 'to Heard Island and MacDonald Islands',
    description: `Например: "Доставка в Херд и Макдональд, острова"`,
  },
  ma: {defaultMessage: 'to Morocco', description: `Например: "Доставка в Марокко"`},
  za: {
    defaultMessage: 'to the Republic of South Africa',
    description: `Например: "Доставка в Южно-Африканскую Республику"`,
  },
  tt: {
    defaultMessage: 'to Trinidad and Tobago',
    description: `Например: "Доставка в Тринидад и Тобаго"`,
  },
  tn: {defaultMessage: 'to Tunisia', description: `Например: "Доставка в Тунис"`},
  tm: {defaultMessage: 'to Turkmenistan', description: `Например: "Доставка в Туркменистан"`},
  cu: {defaultMessage: 'to Cuba', description: `Например: "Доставка на Кубу"`},
  eh: {defaultMessage: 'to Western Sahara', description: `Например: "Доставка в Западную Сахару"`},
  ae: {
    defaultMessage: 'to the United Arab Emirates',
    description: `Например: "Доставка в Объединённые Арабские Эмираты"`,
  },
  km: {defaultMessage: 'to Comoros', description: `Например: "Доставка в Коморы"`},
  gh: {defaultMessage: 'to Ghana', description: `Например: "Доставка в Ганц"`},
  mr: {defaultMessage: 'to Mauritania', description: `Например: "Доставка в Мавританию"`},
  bh: {defaultMessage: 'to Bahrain', description: `Например: "Доставка в Бахрейн"`},
  cc: {
    defaultMessage: 'to the Cocos (Keeling) Islands',
    description: `Например: "Доставка на Кокосовые (Килинг) острова"`,
  },
  dm: {defaultMessage: 'to Dominica', description: `Например: "Доставка в Доминику"`},
  gm: {defaultMessage: 'to the Gambia', description: `Например: "Доставка в Гамбию"`},
  gn: {defaultMessage: 'to Guinea', description: `Например: "Доставка в Гвинею"`},
  mx: {defaultMessage: 'to Mexico', description: `Например: "Доставка в Мексику"`},
  pa: {defaultMessage: 'to Panama', description: `Например: "Доставка в Панаму"`},
  ba: {
    defaultMessage: 'to Bosnia and Herzegovina',
    description: `Например: "Доставка в Боснию и Герцеговину"`,
  },
  bv: {defaultMessage: 'to Bouvet Island', description: `Например: "Доставка на Буве, остров"`},
  td: {defaultMessage: 'to Chad', description: `Например: "Доставка в Чад"`},
  fo: {
    defaultMessage: 'to the Faroe Islands',
    description: `Например: "Доставка на Фарерские острова"`,
  },
  ht: {defaultMessage: 'to Haiti', description: `Например: "Доставка на Гаити"`},
  mo: {defaultMessage: 'to Macau', description: `Например: "Доставка в Аомынь"`},
  vg: {
    defaultMessage: 'to the British Virgin Islands',
    description: `Например: "Доставка на Виргинские острова (Британские)"`,
  },
  bj: {defaultMessage: 'to Benin', description: `Например: "Доставка в Бенин"`},
  va: {defaultMessage: 'to Vatican City', description: `Например: "Доставка в Ватикан"`},
  mw: {defaultMessage: 'to Malawi', description: `Например: "Доставка в Малави"`},
  ps: {
    defaultMessage: 'to the Palestinian Occupied Territories',
    description: `Например: "Доставка в в Палестинскую оккупированную территорию"`,
  },
  py: {defaultMessage: 'to Paraguay', description: `Например: "Доставка в Парагвай"`},
  mf: {defaultMessage: 'to St. Martin', description: `Например: "Доставка в в Сен-Мартен"`},
  ec: {defaultMessage: 'to Ecuador', description: `Например: "Доставка в Эквадор"`},
  me: {defaultMessage: 'to Montenegro', description: `Например: "Доставка в Черногорию"`},
  nf: {
    defaultMessage: 'to Norfolk Island',
    description: `Например: "Доставка на Норфолк, остров"`,
  },
  ws: {defaultMessage: 'to Samoa', description: `Например: "Доставка в Самоа"`},
  tk: {defaultMessage: 'to Tokelau', description: `Например: "Доставка в Токелау"`},
  ck: {defaultMessage: 'to the Cook Islands', description: `Например: "Доставка на острова Кука"`},
  iq: {defaultMessage: 'to Iraq', description: `Например: "Доставка в Ирак"`},
  nu: {defaultMessage: 'to Niue', description: `Например: "Доставка в Ниуэ"`},
  pr: {defaultMessage: 'to Puerto Rico', description: `Например: "Доставка в Пуэрто-Рико"`},
  so: {defaultMessage: 'to Somalia', description: `Например: "Доставка в Сомали"`},
  ss: {defaultMessage: 'to South Sudan', description: `Например: "Доставка в Южный Судан"`},
  sy: {defaultMessage: 'to Syria', description: `Например: "Доставка в Сирию"`},
  ir: {defaultMessage: 'to Iran', description: `Например: "Доставка в Иран"`},
  ag: {
    defaultMessage: 'to Antigua and Barbuda',
    description: `Например: "Доставка в Антигуа и Барбуда"`,
  },
  az: {defaultMessage: 'to Azerbaijan', description: `Например: "Доставка в Азербайджан"`},
  mq: {defaultMessage: 'to Martinique', description: `Например: "Доставка в Мартинику"`},
  yt: {defaultMessage: 'to Maoré (Mayotte)', description: `Например: "Доставка в Маоре (Майотта)"`},
  ne: {defaultMessage: 'to Niger', description: `Например: "Доставка в Нигер"`},
  sz: {defaultMessage: 'to Eswatini', description: `Например: "Доставка в Эсватини"`},
  tc: {
    defaultMessage: 'to the Turks and Caicos Islands',
    description: `Например: "Доставка в Теркс и Кайкос"`,
  },
  as: {
    defaultMessage: 'to American Samoa',
    description: `Например: "Доставка в Американское Самоа"`,
  },
  al: {defaultMessage: 'to Albania', description: `Например: "Доставка в Албанию"`},
  br: {defaultMessage: 'to Brazil', description: `Например: "Доставка в Бразилию"`},
  cr: {defaultMessage: 'to Costa Rica', description: `Например: "Доставка в Коста-Рику"`},
  ci: {defaultMessage: "to Côte d'Ivoire", description: `Например: "Доставка в Кот-д’Ивуар"`},
  ke: {defaultMessage: 'to Kenya', description: `Например: "Доставка в Кению"`},
  na: {defaultMessage: 'to Namibia', description: `Например: "Доставка в Намибию"`},
  qa: {defaultMessage: 'to Qatar', description: `Например: "Доставка в Катар"`},
  af: {defaultMessage: 'to Afghanistan', description: `Например: "Доставка в Афганистан"`},
  uz: {defaultMessage: 'to Uzbekistan', description: `Например: "Доставка в Узбекистан"`},
  zw: {defaultMessage: 'to Zimbabwe', description: `Например: "Доставка в Зимбабве"`},
  tg: {defaultMessage: 'to Togo', description: `Например: "Доставка в Того"`},
  ug: {defaultMessage: 'to Uganda', description: `Например: "Доставка в Уганду"`},
  lb: {defaultMessage: 'to Lebanon', description: `Например: "Доставка в Ливан"`},
  tl: {defaultMessage: 'to Timor-Leste', description: `Например: "Доставка в в Тимор-Лесте"`},
  pe: {defaultMessage: 'to Peru', description: `Например: "Доставка в Перу"`},
  ar: {defaultMessage: 'to Argentina', description: `Например: "Доставка в Аргентину"`},
  cg: {
    defaultMessage: 'to Congo (Brazzaville)',
    description: `Например: "Доставка в Конго (Браззавиль)"`,
  },
  xk: {
    defaultMessage: 'to the Republic of Kosovo',
    description: `Например: "Доставка в Республику Косово"`,
  },
  fk: {
    defaultMessage: 'to the Falkland Islands',
    description: `Например: "Доставка на Фолклендские (Мальвинские) острова"`,
  },
  pf: {
    defaultMessage: 'to French Polynesia',
    description: `Например: "Доставка в Французскую Полинезию"`,
  },
  ly: {defaultMessage: 'to Libya', description: `Например: "Доставка в Ливию"`},
  mz: {defaultMessage: 'to Mozambique', description: `Например: "Доставка в Мозамбик"`},
  bl: {defaultMessage: 'to Saint Barthélemy', description: `Например: "Доставка в Сен-Бартельми"`},
  ai: {defaultMessage: 'to Anguilla', description: `Например: "Доставка в Ангилью"`},
  st: {
    defaultMessage: 'to São Tomé and Príncipe',
    description: `Например: "Доставка в Сан-Томе и Принсипи"`,
  },
  tf: {
    defaultMessage: 'to the French Southern Territories',
    description: `Например: "Доставка в Французские Южные территории"`,
  },
  mm: {defaultMessage: 'to Myanmar', description: `Например: "Доставка в Мьянму"`},
  ni: {defaultMessage: 'to Nicaragua', description: `Например: "Доставка в Никарагуа"`},
  vc: {
    defaultMessage: 'to St. Vincent and the Grenadines',
    description: `Например: "Доставка в Сент-Винсент и Гренадины"`,
  },
  sx: {defaultMessage: 'to Sint Maarten', description: `Например: "Доставка в Синт-Мартен"`},
  lk: {defaultMessage: 'to Sri Lanka', description: `Например: "Доставка на Шри-Ланку"`},
  eg: {defaultMessage: 'to Egypt', description: `Например: "Доставка в Египет"`},
  bt: {defaultMessage: 'to Bhutan', description: `Например: "Доставка в Бутан"`},
  co: {defaultMessage: 'to Colombia', description: `Например: "Доставка в Колумбию"`},
  fj: {defaultMessage: 'to Fiji', description: `Например: "Доставка на Фиджи"`},
  ga: {defaultMessage: 'to Gabon', description: `Например: "Доставка в Габон"`},
  hn: {defaultMessage: 'to Honduras', description: `Например: "Доставка в Гондурас"`},
  uy: {defaultMessage: 'to Uruguay', description: `Например: "Доставка в Уругвай"`},
  ad: {defaultMessage: 'to Andorra', description: `Например: "Доставка в Андорру"`},
  hk: {
    defaultMessage: 'to Hong Kong',
    description: `Например: "Доставка в Сянган (Гонконг)"`,
  },
  mh: {
    defaultMessage: 'to the Marshall Islands',
    description: `Например: "Доставка на Маршалловы острова"`,
  },
  mc: {defaultMessage: 'to Monaco', description: `Например: "Доставка в Монако"`},
  tw: {defaultMessage: 'to Taiwan', description: `Например: "Доставка в Тайвань"`},
  tj: {defaultMessage: 'to Tajikistan', description: `Например: "Доставка в Таджикистан"`},
  gq: {
    defaultMessage: 'to Equatorial Guinea',
    description: `Например: "Доставка в Экваториальную Гвинею"`,
  },
  vn: {defaultMessage: 'to Vietnam', description: `Например: "Доставка во Вьетнам"`},
  ye: {defaultMessage: 'to Yemen', description: `Например: "Доставка в Йемен"`},
  zm: {defaultMessage: 'to Zambia', description: `Например: "Доставка в Замбию"`},
  kh: {defaultMessage: 'to Cambodia', description: `Например: "Доставка в Камбоджу"`},
  er: {defaultMessage: 'to Eritrea', description: `Например: "Доставка в Эритрею"`},
  la: {defaultMessage: 'to Laos', description: `Например: "Доставка в Лаос"`},
  lr: {defaultMessage: 'to Liberia', description: `Например: "Доставка в Либерию"`},
  pk: {defaultMessage: 'to Pakistan', description: `Например: "Доставка в Пакистан"`},
  pm: {
    defaultMessage: 'to St. Pierre and Miquelon',
    description: `Например: "Доставка в Сен-Пьер и Микелон"`,
  },
  io: {
    defaultMessage: 'to the British Indian Ocean Territory',
    description: `Например: "Доставка в Британскую территорию в Индийском океане"`,
  },
  mg: {defaultMessage: 'to Madagascar', description: `Например: "Доставка на Мадагаскар"`},
  sc: {defaultMessage: 'to the Seychelles', description: `Например: "Доставка на Сейшелы"`},
  sg: {defaultMessage: 'to Singapore', description: `Например: "Доставка в Сингапур"`},
  gs: {
    defaultMessage: 'to South Georgia and South Sandwich Islands',
    description: `Например: "Доставка в Южную Георгию и Южные Сандвичевы острова"`,
  },
  vi: {
    defaultMessage: 'to the US Virgin Islands',
    description: `Например: "Доставка на Виргинские острова (США)"`,
  },
  im: {defaultMessage: 'to the Isle of Man', description: `Например: "Доставка на Остров Мэн"`},
  cd: {
    defaultMessage: 'to Congo (Kinshasa)',
    description: `Например: "Доставка в Конго (Киншаса)"`,
  },
  ms: {defaultMessage: 'to Montserrat', description: `Например: "Доставка в Монтсеррат"`},
  nr: {defaultMessage: 'to Nauru', description: `Например: "Доставка в Науру"`},
  re: {defaultMessage: 'to Réunion', description: `Например: "Доставка в Реюньон"`},
  bs: {defaultMessage: 'to the Bahamas', description: `Например: "Доставка в Багамы"`},
  gi: {defaultMessage: 'to Gibraltar', description: `Например: "Доставка в Гибралтар"`},
  id: {defaultMessage: 'to Indonesia', description: `Например: "Доставка в Индонезию"`},
  kg: {defaultMessage: 'to Kyrgyzstan', description: `Например: "Доставка в Кыргызстан"`},
  ml: {defaultMessage: 'to Mali', description: `Например: "Доставка в Мали"`},
  no: {defaultMessage: 'to Norway', description: `Например: "Доставка в Норвегию"`},
  lc: {defaultMessage: 'to Saint Lucia', description: `Например: "Доставка в Сент-Люсию"`},
  tz: {defaultMessage: 'to Tanzania', description: `Например: "Доставка в Танзанию"`},
  cx: {
    defaultMessage: 'to Christmas Island',
    description: `Например: "Доставка на Остров Рождества"`,
  },
  bz: {defaultMessage: 'to Belize', description: `Например: "Доставка в Белиз"`},
  cf: {
    defaultMessage: 'to the Central African Republic',
    description: `Например: "Доставка в Центрально-Африканскую Республику"`,
  },
  je: {defaultMessage: 'to Jersey', description: `Например: "Доставка в Джерси"`},
  mn: {defaultMessage: 'to Mongolia', description: `Например: "Доставка в Монголия"`},
  pg: {
    defaultMessage: 'to Papua New Guinea',
    description: `Например: "Доставка в Папуа-Новая Гвинея"`,
  },
  vu: {defaultMessage: 'to Vanuatu', description: `Например: "Доставка в Вануату"`},
  ax: {defaultMessage: 'to Jersey', description: `Например: "Доставка в Джерси"`},
  bw: {defaultMessage: 'to Botswana', description: `Например: "Доставка в Ботсвану"`},
  pw: {defaultMessage: 'to Palau', description: `Например: "Доставка в Палау"`},
  bq: {
    defaultMessage: 'to Bonaire, Saba and Sint Eustatius',
    description: `Например: "Доставка в Бонайре, Саба и Синт-Эстатиус"`,
  },
  bo: {defaultMessage: 'to Bolivia', description: `Например: "Доставка в Боливию"`},
  cl: {defaultMessage: 'to Chile', description: `Например: "Доставка в Чили"`},
  sv: {defaultMessage: 'to El Salvador', description: `Например: "Доставка в Сальвадор"`},
  et: {defaultMessage: 'to Ethiopia', description: `Например: "Доставка в Эфиопию"`},
  gd: {defaultMessage: 'to Grenada', description: `Например: "Доставка в Гренаду"`},
  gt: {defaultMessage: 'to Guatemala', description: `Например: "Доставка в Гватемалу"`},
  ls: {defaultMessage: 'to Lesotho', description: `Например: "Доставка в Лесото"`},
  bm: {defaultMessage: 'to Bermuda', description: `Например: "Доставка в Бермуды"`},
  mp: {
    defaultMessage: 'to the Northern Mariana Islands',
    description: `Например: "Доставка на Северные Марианские острова"`,
  },
  sb: {
    defaultMessage: 'to the Solomon Islands',
    description: `Например: "Доставка на Соломоновы Острова"`,
  },
  my: {defaultMessage: 'to Malaysia', description: `Например: "Доставка в Малайзию"`},
});

type CountryData = {
  id: string;
  name: MessageDescriptor;
  nameToCountry: MessageDescriptor;
  phoneCode: number;
};

export const countryList: CountryData[] = [
  {id: 'AD', name: names.ad, nameToCountry: namesToCountry.ad, phoneCode: countryPhoneCodes.ad},
  {id: 'AE', name: names.ae, nameToCountry: namesToCountry.ae, phoneCode: countryPhoneCodes.ae},
  {id: 'AF', name: names.af, nameToCountry: namesToCountry.af, phoneCode: countryPhoneCodes.af},
  {id: 'AG', name: names.ag, nameToCountry: namesToCountry.ag, phoneCode: countryPhoneCodes.ag},
  {id: 'AI', name: names.ai, nameToCountry: namesToCountry.ai, phoneCode: countryPhoneCodes.ai},
  {id: 'AL', name: names.al, nameToCountry: namesToCountry.al, phoneCode: countryPhoneCodes.al},
  {id: 'AM', name: names.am, nameToCountry: namesToCountry.am, phoneCode: countryPhoneCodes.am},
  {id: 'AO', name: names.ao, nameToCountry: namesToCountry.ao, phoneCode: countryPhoneCodes.ao},
  {id: 'AR', name: names.ar, nameToCountry: namesToCountry.ar, phoneCode: countryPhoneCodes.ar},
  {id: 'AS', name: names.as, nameToCountry: namesToCountry.as, phoneCode: countryPhoneCodes.as},
  {id: 'AT', name: names.at, nameToCountry: namesToCountry.at, phoneCode: countryPhoneCodes.at},
  {id: 'AU', name: names.au, nameToCountry: namesToCountry.au, phoneCode: countryPhoneCodes.au},
  {id: 'AW', name: names.aw, nameToCountry: namesToCountry.aw, phoneCode: countryPhoneCodes.aw},
  {id: 'AX', name: names.ax, nameToCountry: namesToCountry.ax, phoneCode: countryPhoneCodes.ax},
  {id: 'AZ', name: names.az, nameToCountry: namesToCountry.az, phoneCode: countryPhoneCodes.az},
  {id: 'BA', name: names.ba, nameToCountry: namesToCountry.ba, phoneCode: countryPhoneCodes.ba},
  {id: 'BB', name: names.bb, nameToCountry: namesToCountry.bb, phoneCode: countryPhoneCodes.bb},
  {id: 'BD', name: names.bd, nameToCountry: namesToCountry.bd, phoneCode: countryPhoneCodes.bd},
  {id: 'BE', name: names.be, nameToCountry: namesToCountry.be, phoneCode: countryPhoneCodes.be},
  {id: 'BF', name: names.bf, nameToCountry: namesToCountry.bf, phoneCode: countryPhoneCodes.bf},
  {id: 'BG', name: names.bg, nameToCountry: namesToCountry.bg, phoneCode: countryPhoneCodes.bg},
  {id: 'BH', name: names.bh, nameToCountry: namesToCountry.bh, phoneCode: countryPhoneCodes.bh},
  {id: 'BI', name: names.bi, nameToCountry: namesToCountry.bi, phoneCode: countryPhoneCodes.bi},
  {id: 'BJ', name: names.bj, nameToCountry: namesToCountry.bj, phoneCode: countryPhoneCodes.bj},
  {id: 'BL', name: names.bl, nameToCountry: namesToCountry.bl, phoneCode: countryPhoneCodes.bl},
  {id: 'BM', name: names.bm, nameToCountry: namesToCountry.bm, phoneCode: countryPhoneCodes.bm},
  {id: 'BN', name: names.bn, nameToCountry: namesToCountry.bn, phoneCode: countryPhoneCodes.bn},
  {id: 'BO', name: names.bo, nameToCountry: namesToCountry.bo, phoneCode: countryPhoneCodes.bo},
  {id: 'BQ', name: names.bq, nameToCountry: namesToCountry.bq, phoneCode: countryPhoneCodes.bq},
  {id: 'BR', name: names.br, nameToCountry: namesToCountry.br, phoneCode: countryPhoneCodes.br},
  {id: 'BS', name: names.bs, nameToCountry: namesToCountry.bs, phoneCode: countryPhoneCodes.bs},
  {id: 'BT', name: names.bt, nameToCountry: namesToCountry.bt, phoneCode: countryPhoneCodes.bt},
  {id: 'BW', name: names.bw, nameToCountry: namesToCountry.bw, phoneCode: countryPhoneCodes.bw},
  {id: 'BY', name: names.by, nameToCountry: namesToCountry.by, phoneCode: countryPhoneCodes.by},
  {id: 'BZ', name: names.bz, nameToCountry: namesToCountry.bz, phoneCode: countryPhoneCodes.bz},
  {id: 'CA', name: names.ca, nameToCountry: namesToCountry.ca, phoneCode: countryPhoneCodes.ca},
  {id: 'CC', name: names.cc, nameToCountry: namesToCountry.cc, phoneCode: countryPhoneCodes.cc},
  {id: 'CD', name: names.cd, nameToCountry: namesToCountry.cd, phoneCode: countryPhoneCodes.cd},
  {id: 'CF', name: names.cf, nameToCountry: namesToCountry.cf, phoneCode: countryPhoneCodes.cf},
  {id: 'CG', name: names.cg, nameToCountry: namesToCountry.cg, phoneCode: countryPhoneCodes.cg},
  {id: 'CH', name: names.ch, nameToCountry: namesToCountry.ch, phoneCode: countryPhoneCodes.ch},
  {id: 'CI', name: names.ci, nameToCountry: namesToCountry.ci, phoneCode: countryPhoneCodes.ci},
  {id: 'CK', name: names.ck, nameToCountry: namesToCountry.ck, phoneCode: countryPhoneCodes.ck},
  {id: 'CL', name: names.cl, nameToCountry: namesToCountry.cl, phoneCode: countryPhoneCodes.cl},
  {id: 'CM', name: names.cm, nameToCountry: namesToCountry.cm, phoneCode: countryPhoneCodes.cm},
  {id: 'CN', name: names.cn, nameToCountry: namesToCountry.cn, phoneCode: countryPhoneCodes.cn},
  {id: 'CO', name: names.co, nameToCountry: namesToCountry.co, phoneCode: countryPhoneCodes.co},
  {id: 'CR', name: names.cr, nameToCountry: namesToCountry.cr, phoneCode: countryPhoneCodes.cr},
  // {id: 'CU', name: names.cu, nameToCountry:namesToCountry.cu, phoneCode: countryPhoneCodes.cu},
  {id: 'CV', name: names.cv, nameToCountry: namesToCountry.cv, phoneCode: countryPhoneCodes.cv},
  {id: 'CW', name: names.cw, nameToCountry: namesToCountry.cw, phoneCode: countryPhoneCodes.cw},
  {id: 'CX', name: names.cx, nameToCountry: namesToCountry.cx, phoneCode: countryPhoneCodes.cx},
  {id: 'CY', name: names.cy, nameToCountry: namesToCountry.cy, phoneCode: countryPhoneCodes.cy},
  {id: 'CZ', name: names.cz, nameToCountry: namesToCountry.cz, phoneCode: countryPhoneCodes.cz},
  {id: 'DE', name: names.de, nameToCountry: namesToCountry.de, phoneCode: countryPhoneCodes.de},
  {id: 'DJ', name: names.dj, nameToCountry: namesToCountry.dj, phoneCode: countryPhoneCodes.dj},
  {id: 'DK', name: names.dk, nameToCountry: namesToCountry.dk, phoneCode: countryPhoneCodes.dk},
  {id: 'DM', name: names.dm, nameToCountry: namesToCountry.dm, phoneCode: countryPhoneCodes.dm},
  {id: 'DO', name: names.do, nameToCountry: namesToCountry.do, phoneCode: countryPhoneCodes.do},
  {id: 'DZ', name: names.dz, nameToCountry: namesToCountry.dz, phoneCode: countryPhoneCodes.dz},
  {id: 'EC', name: names.ec, nameToCountry: namesToCountry.ec, phoneCode: countryPhoneCodes.ec},
  {id: 'EE', name: names.ee, nameToCountry: namesToCountry.ee, phoneCode: countryPhoneCodes.ee},
  {id: 'EG', name: names.eg, nameToCountry: namesToCountry.eg, phoneCode: countryPhoneCodes.eg},
  {id: 'EH', name: names.eh, nameToCountry: namesToCountry.eh, phoneCode: countryPhoneCodes.eh},
  {id: 'ER', name: names.er, nameToCountry: namesToCountry.er, phoneCode: countryPhoneCodes.er},
  {id: 'ES', name: names.es, nameToCountry: namesToCountry.es, phoneCode: countryPhoneCodes.es},
  {id: 'ET', name: names.et, nameToCountry: namesToCountry.et, phoneCode: countryPhoneCodes.et},
  {id: 'FI', name: names.fi, nameToCountry: namesToCountry.fi, phoneCode: countryPhoneCodes.fi},
  {id: 'FJ', name: names.fj, nameToCountry: namesToCountry.fj, phoneCode: countryPhoneCodes.fj},
  {id: 'FK', name: names.fk, nameToCountry: namesToCountry.fk, phoneCode: countryPhoneCodes.fk},
  {id: 'FM', name: names.fm, nameToCountry: namesToCountry.fm, phoneCode: countryPhoneCodes.fm},
  {id: 'FO', name: names.fo, nameToCountry: namesToCountry.fo, phoneCode: countryPhoneCodes.fo},
  {id: 'FR', name: names.fr, nameToCountry: namesToCountry.fr, phoneCode: countryPhoneCodes.fr},
  {id: 'GA', name: names.ga, nameToCountry: namesToCountry.ga, phoneCode: countryPhoneCodes.ga},
  {id: 'GB', name: names.gb, nameToCountry: namesToCountry.gb, phoneCode: countryPhoneCodes.gb},
  {id: 'GD', name: names.gd, nameToCountry: namesToCountry.gd, phoneCode: countryPhoneCodes.gd},
  {id: 'GE', name: names.ge, nameToCountry: namesToCountry.ge, phoneCode: countryPhoneCodes.ge},
  {id: 'GF', name: names.gf, nameToCountry: namesToCountry.gf, phoneCode: countryPhoneCodes.gf},
  {id: 'GG', name: names.gg, nameToCountry: namesToCountry.gg, phoneCode: countryPhoneCodes.gg},
  {id: 'GH', name: names.gh, nameToCountry: namesToCountry.gh, phoneCode: countryPhoneCodes.gh},
  {id: 'GI', name: names.gi, nameToCountry: namesToCountry.gi, phoneCode: countryPhoneCodes.gi},
  {id: 'GL', name: names.gl, nameToCountry: namesToCountry.gl, phoneCode: countryPhoneCodes.gl},
  {id: 'GM', name: names.gm, nameToCountry: namesToCountry.gm, phoneCode: countryPhoneCodes.gm},
  {id: 'GN', name: names.gn, nameToCountry: namesToCountry.gn, phoneCode: countryPhoneCodes.gn},
  {id: 'GP', name: names.gp, nameToCountry: namesToCountry.gp, phoneCode: countryPhoneCodes.gp},
  {id: 'GQ', name: names.gq, nameToCountry: namesToCountry.gq, phoneCode: countryPhoneCodes.gq},
  {id: 'GR', name: names.gr, nameToCountry: namesToCountry.gr, phoneCode: countryPhoneCodes.gr},
  {id: 'GT', name: names.gt, nameToCountry: namesToCountry.gt, phoneCode: countryPhoneCodes.gt},
  {id: 'GU', name: names.gu, nameToCountry: namesToCountry.gu, phoneCode: countryPhoneCodes.gu},
  {id: 'GW', name: names.gw, nameToCountry: namesToCountry.gw, phoneCode: countryPhoneCodes.gw},
  {id: 'GY', name: names.gy, nameToCountry: namesToCountry.gy, phoneCode: countryPhoneCodes.gy},
  {id: 'HK', name: names.hk, nameToCountry: namesToCountry.hk, phoneCode: countryPhoneCodes.hk},
  {id: 'HN', name: names.hn, nameToCountry: namesToCountry.hn, phoneCode: countryPhoneCodes.hn},
  {id: 'HR', name: names.hr, nameToCountry: namesToCountry.hr, phoneCode: countryPhoneCodes.hr},
  {id: 'HT', name: names.ht, nameToCountry: namesToCountry.ht, phoneCode: countryPhoneCodes.ht},
  {id: 'HU', name: names.hu, nameToCountry: namesToCountry.hu, phoneCode: countryPhoneCodes.hu},
  {id: 'ID', name: names.id, nameToCountry: namesToCountry.id, phoneCode: countryPhoneCodes.id},
  {id: 'IE', name: names.ie, nameToCountry: namesToCountry.ie, phoneCode: countryPhoneCodes.ie},
  {id: 'IL', name: names.il, nameToCountry: namesToCountry.il, phoneCode: countryPhoneCodes.il},
  {id: 'IM', name: names.im, nameToCountry: namesToCountry.im, phoneCode: countryPhoneCodes.im},
  {id: 'IN', name: names.in, nameToCountry: namesToCountry.in, phoneCode: countryPhoneCodes.in},
  {id: 'IO', name: names.io, nameToCountry: namesToCountry.io, phoneCode: countryPhoneCodes.io},
  {id: 'IQ', name: names.iq, nameToCountry: namesToCountry.iq, phoneCode: countryPhoneCodes.iq},
  // {id: 'IR', name: names.ir, nameToCountry:namesToCountry.ir, phoneCode: countryPhoneCodes.ir},
  {id: 'IS', name: names.is, nameToCountry: namesToCountry.is, phoneCode: countryPhoneCodes.is},
  {id: 'IT', name: names.it, nameToCountry: namesToCountry.it, phoneCode: countryPhoneCodes.it},
  {id: 'JE', name: names.je, nameToCountry: namesToCountry.je, phoneCode: countryPhoneCodes.je},
  {id: 'JM', name: names.jm, nameToCountry: namesToCountry.jm, phoneCode: countryPhoneCodes.jm},
  {id: 'JO', name: names.jo, nameToCountry: namesToCountry.jo, phoneCode: countryPhoneCodes.jo},
  {id: 'JP', name: names.jp, nameToCountry: namesToCountry.jp, phoneCode: countryPhoneCodes.jp},
  {id: 'KE', name: names.ke, nameToCountry: namesToCountry.ke, phoneCode: countryPhoneCodes.ke},
  {id: 'KG', name: names.kg, nameToCountry: namesToCountry.kg, phoneCode: countryPhoneCodes.kg},
  {id: 'KH', name: names.kh, nameToCountry: namesToCountry.kh, phoneCode: countryPhoneCodes.kh},
  {id: 'KI', name: names.ki, nameToCountry: namesToCountry.ki, phoneCode: countryPhoneCodes.ki},
  {id: 'KM', name: names.km, nameToCountry: namesToCountry.km, phoneCode: countryPhoneCodes.km},
  {id: 'KN', name: names.kn, nameToCountry: namesToCountry.kn, phoneCode: countryPhoneCodes.kn},
  // {id: 'KP', name: names.kp, nameToCountry:namesToCountry.kp, phoneCode: countryPhoneCodes.kp},
  {id: 'KR', name: names.kr, nameToCountry: namesToCountry.kr, phoneCode: countryPhoneCodes.kr},
  {id: 'KW', name: names.kw, nameToCountry: namesToCountry.kw, phoneCode: countryPhoneCodes.kw},
  {id: 'KY', name: names.ky, nameToCountry: namesToCountry.ky, phoneCode: countryPhoneCodes.ky},
  {id: 'KZ', name: names.kz, nameToCountry: namesToCountry.kz, phoneCode: countryPhoneCodes.kz},
  {id: 'LA', name: names.la, nameToCountry: namesToCountry.la, phoneCode: countryPhoneCodes.la},
  {id: 'LB', name: names.lb, nameToCountry: namesToCountry.lb, phoneCode: countryPhoneCodes.lb},
  {id: 'LC', name: names.lc, nameToCountry: namesToCountry.lc, phoneCode: countryPhoneCodes.lc},
  {id: 'LI', name: names.li, nameToCountry: namesToCountry.li, phoneCode: countryPhoneCodes.li},
  {id: 'LK', name: names.lk, nameToCountry: namesToCountry.lk, phoneCode: countryPhoneCodes.lk},
  {id: 'LR', name: names.lr, nameToCountry: namesToCountry.lr, phoneCode: countryPhoneCodes.lr},
  {id: 'LS', name: names.ls, nameToCountry: namesToCountry.ls, phoneCode: countryPhoneCodes.ls},
  {id: 'LT', name: names.lt, nameToCountry: namesToCountry.lt, phoneCode: countryPhoneCodes.lt},
  {id: 'LU', name: names.lu, nameToCountry: namesToCountry.lu, phoneCode: countryPhoneCodes.lu},
  {id: 'LV', name: names.lv, nameToCountry: namesToCountry.lv, phoneCode: countryPhoneCodes.lv},
  {id: 'LY', name: names.ly, nameToCountry: namesToCountry.ly, phoneCode: countryPhoneCodes.ly},
  {id: 'MA', name: names.ma, nameToCountry: namesToCountry.ma, phoneCode: countryPhoneCodes.ma},
  {id: 'MC', name: names.mc, nameToCountry: namesToCountry.mc, phoneCode: countryPhoneCodes.mc},
  {id: 'MD', name: names.md, nameToCountry: namesToCountry.md, phoneCode: countryPhoneCodes.md},
  {id: 'ME', name: names.me, nameToCountry: namesToCountry.me, phoneCode: countryPhoneCodes.me},
  {id: 'MF', name: names.mf, nameToCountry: namesToCountry.mf, phoneCode: countryPhoneCodes.mf},
  {id: 'MG', name: names.mg, nameToCountry: namesToCountry.mg, phoneCode: countryPhoneCodes.mg},
  {id: 'MH', name: names.mh, nameToCountry: namesToCountry.mh, phoneCode: countryPhoneCodes.mh},
  {id: 'MK', name: names.mk, nameToCountry: namesToCountry.mk, phoneCode: countryPhoneCodes.mk},
  {id: 'ML', name: names.ml, nameToCountry: namesToCountry.ml, phoneCode: countryPhoneCodes.ml},
  {id: 'MM', name: names.mm, nameToCountry: namesToCountry.mm, phoneCode: countryPhoneCodes.mm},
  {id: 'MN', name: names.mn, nameToCountry: namesToCountry.mn, phoneCode: countryPhoneCodes.mn},
  {id: 'MO', name: names.mo, nameToCountry: namesToCountry.mo, phoneCode: countryPhoneCodes.mo},
  {id: 'MP', name: names.mp, nameToCountry: namesToCountry.mp, phoneCode: countryPhoneCodes.mp},
  {id: 'MQ', name: names.mq, nameToCountry: namesToCountry.mq, phoneCode: countryPhoneCodes.mq},
  {id: 'MR', name: names.mr, nameToCountry: namesToCountry.mr, phoneCode: countryPhoneCodes.mr},
  {id: 'MS', name: names.ms, nameToCountry: namesToCountry.ms, phoneCode: countryPhoneCodes.ms},
  {id: 'MT', name: names.mt, nameToCountry: namesToCountry.mt, phoneCode: countryPhoneCodes.mt},
  {id: 'MU', name: names.mu, nameToCountry: namesToCountry.mu, phoneCode: countryPhoneCodes.mu},
  {id: 'MV', name: names.mv, nameToCountry: namesToCountry.mv, phoneCode: countryPhoneCodes.mv},
  {id: 'MW', name: names.mw, nameToCountry: namesToCountry.mw, phoneCode: countryPhoneCodes.mw},
  {id: 'MX', name: names.mx, nameToCountry: namesToCountry.mx, phoneCode: countryPhoneCodes.mx},
  {id: 'MY', name: names.my, nameToCountry: namesToCountry.my, phoneCode: countryPhoneCodes.my},
  {id: 'MZ', name: names.mz, nameToCountry: namesToCountry.mz, phoneCode: countryPhoneCodes.mz},
  {id: 'NA', name: names.na, nameToCountry: namesToCountry.na, phoneCode: countryPhoneCodes.na},
  {id: 'NC', name: names.nc, nameToCountry: namesToCountry.nc, phoneCode: countryPhoneCodes.nc},
  {id: 'NE', name: names.ne, nameToCountry: namesToCountry.ne, phoneCode: countryPhoneCodes.ne},
  {id: 'NF', name: names.nf, nameToCountry: namesToCountry.nf, phoneCode: countryPhoneCodes.nf},
  {id: 'NG', name: names.ng, nameToCountry: namesToCountry.ng, phoneCode: countryPhoneCodes.ng},
  {id: 'NI', name: names.ni, nameToCountry: namesToCountry.ni, phoneCode: countryPhoneCodes.ni},
  {id: 'NL', name: names.nl, nameToCountry: namesToCountry.nl, phoneCode: countryPhoneCodes.nl},
  {id: 'NO', name: names.no, nameToCountry: namesToCountry.no, phoneCode: countryPhoneCodes.no},
  {id: 'NP', name: names.np, nameToCountry: namesToCountry.np, phoneCode: countryPhoneCodes.np},
  {id: 'NR', name: names.nr, nameToCountry: namesToCountry.nr, phoneCode: countryPhoneCodes.nr},
  {id: 'NU', name: names.nu, nameToCountry: namesToCountry.nu, phoneCode: countryPhoneCodes.nu},
  {id: 'NZ', name: names.nz, nameToCountry: namesToCountry.nz, phoneCode: countryPhoneCodes.nz},
  {id: 'OM', name: names.om, nameToCountry: namesToCountry.om, phoneCode: countryPhoneCodes.om},
  {id: 'PA', name: names.pa, nameToCountry: namesToCountry.pa, phoneCode: countryPhoneCodes.pa},
  {id: 'PE', name: names.pe, nameToCountry: namesToCountry.pe, phoneCode: countryPhoneCodes.pe},
  {id: 'PF', name: names.pf, nameToCountry: namesToCountry.pf, phoneCode: countryPhoneCodes.pf},
  {id: 'PG', name: names.pg, nameToCountry: namesToCountry.pg, phoneCode: countryPhoneCodes.pg},
  {id: 'PH', name: names.ph, nameToCountry: namesToCountry.ph, phoneCode: countryPhoneCodes.ph},
  {id: 'PK', name: names.pk, nameToCountry: namesToCountry.pk, phoneCode: countryPhoneCodes.pk},
  {id: 'PL', name: names.pl, nameToCountry: namesToCountry.pl, phoneCode: countryPhoneCodes.pl},
  {id: 'PM', name: names.pm, nameToCountry: namesToCountry.pm, phoneCode: countryPhoneCodes.pm},
  {id: 'PN', name: names.pn, nameToCountry: namesToCountry.pn, phoneCode: countryPhoneCodes.pn},
  {id: 'PR', name: names.pr, nameToCountry: namesToCountry.pr, phoneCode: countryPhoneCodes.pr},
  {id: 'PS', name: names.ps, nameToCountry: namesToCountry.ps, phoneCode: countryPhoneCodes.ps},
  {id: 'PT', name: names.pt, nameToCountry: namesToCountry.pt, phoneCode: countryPhoneCodes.pt},
  {id: 'PW', name: names.pw, nameToCountry: namesToCountry.pw, phoneCode: countryPhoneCodes.pw},
  {id: 'PY', name: names.py, nameToCountry: namesToCountry.py, phoneCode: countryPhoneCodes.py},
  {id: 'QA', name: names.qa, nameToCountry: namesToCountry.qa, phoneCode: countryPhoneCodes.qa},
  {id: 'RE', name: names.re, nameToCountry: namesToCountry.re, phoneCode: countryPhoneCodes.re},
  {id: 'RO', name: names.ro, nameToCountry: namesToCountry.ro, phoneCode: countryPhoneCodes.ro},
  {id: 'RS', name: names.rs, nameToCountry: namesToCountry.rs, phoneCode: countryPhoneCodes.rs},
  {id: 'RU', name: names.ru, nameToCountry: namesToCountry.ru, phoneCode: countryPhoneCodes.ru},
  {id: 'RW', name: names.rw, nameToCountry: namesToCountry.rw, phoneCode: countryPhoneCodes.rw},
  {id: 'SA', name: names.sa, nameToCountry: namesToCountry.sa, phoneCode: countryPhoneCodes.sa},
  {id: 'SB', name: names.sb, nameToCountry: namesToCountry.sb, phoneCode: countryPhoneCodes.sb},
  {id: 'SC', name: names.sc, nameToCountry: namesToCountry.sc, phoneCode: countryPhoneCodes.sc},
  // {id: 'SD', name: names.sd, nameToCountry:namesToCountry.sd, phoneCode: countryPhoneCodes.sd},
  {id: 'SE', name: names.se, nameToCountry: namesToCountry.se, phoneCode: countryPhoneCodes.se},
  {id: 'SG', name: names.sg, nameToCountry: namesToCountry.sg, phoneCode: countryPhoneCodes.sg},
  {id: 'SH', name: names.sh, nameToCountry: namesToCountry.sh, phoneCode: countryPhoneCodes.sh},
  {id: 'SI', name: names.si, nameToCountry: namesToCountry.si, phoneCode: countryPhoneCodes.si},
  {id: 'SJ', name: names.sj, nameToCountry: namesToCountry.sj, phoneCode: countryPhoneCodes.sj},
  {id: 'SK', name: names.sk, nameToCountry: namesToCountry.sk, phoneCode: countryPhoneCodes.sk},
  {id: 'SL', name: names.sl, nameToCountry: namesToCountry.sl, phoneCode: countryPhoneCodes.sl},
  {id: 'SM', name: names.sm, nameToCountry: namesToCountry.sm, phoneCode: countryPhoneCodes.sm},
  {id: 'SN', name: names.sn, nameToCountry: namesToCountry.sn, phoneCode: countryPhoneCodes.sn},
  {id: 'SO', name: names.so, nameToCountry: namesToCountry.so, phoneCode: countryPhoneCodes.so},
  {id: 'SR', name: names.sr, nameToCountry: namesToCountry.sr, phoneCode: countryPhoneCodes.sr},
  {id: 'SS', name: names.ss, nameToCountry: namesToCountry.ss, phoneCode: countryPhoneCodes.ss},
  {id: 'ST', name: names.st, nameToCountry: namesToCountry.st, phoneCode: countryPhoneCodes.st},
  {id: 'SV', name: names.sv, nameToCountry: namesToCountry.sv, phoneCode: countryPhoneCodes.sv},
  {id: 'SX', name: names.sx, nameToCountry: namesToCountry.sx, phoneCode: countryPhoneCodes.sx},
  // {id: 'SY', name: names.sy, nameToCountry:namesToCountry.sy, phoneCode: countryPhoneCodes.sy},
  {id: 'SZ', name: names.sz, nameToCountry: namesToCountry.sz, phoneCode: countryPhoneCodes.sz},
  {id: 'TC', name: names.tc, nameToCountry: namesToCountry.tc, phoneCode: countryPhoneCodes.tc},
  {id: 'TD', name: names.td, nameToCountry: namesToCountry.td, phoneCode: countryPhoneCodes.td},
  {id: 'TG', name: names.tg, nameToCountry: namesToCountry.tg, phoneCode: countryPhoneCodes.tg},
  {id: 'TH', name: names.th, nameToCountry: namesToCountry.th, phoneCode: countryPhoneCodes.th},
  {id: 'TJ', name: names.tj, nameToCountry: namesToCountry.tj, phoneCode: countryPhoneCodes.tj},
  {id: 'TK', name: names.tk, nameToCountry: namesToCountry.tk, phoneCode: countryPhoneCodes.tk},
  {id: 'TL', name: names.tl, nameToCountry: namesToCountry.tl, phoneCode: countryPhoneCodes.tl},
  {id: 'TM', name: names.tm, nameToCountry: namesToCountry.tm, phoneCode: countryPhoneCodes.tm},
  {id: 'TN', name: names.tn, nameToCountry: namesToCountry.tn, phoneCode: countryPhoneCodes.tn},
  {id: 'TO', name: names.to, nameToCountry: namesToCountry.to, phoneCode: countryPhoneCodes.to},
  {id: 'TR', name: names.tr, nameToCountry: namesToCountry.tr, phoneCode: countryPhoneCodes.tr},
  {id: 'TT', name: names.tt, nameToCountry: namesToCountry.tt, phoneCode: countryPhoneCodes.tt},
  {id: 'TV', name: names.tv, nameToCountry: namesToCountry.tv, phoneCode: countryPhoneCodes.tv},
  {id: 'TW', name: names.tw, nameToCountry: namesToCountry.tw, phoneCode: countryPhoneCodes.tw},
  {id: 'TZ', name: names.tz, nameToCountry: namesToCountry.tz, phoneCode: countryPhoneCodes.tz},
  {id: 'UA', name: names.ua, nameToCountry: namesToCountry.ua, phoneCode: countryPhoneCodes.ua},
  {id: 'UG', name: names.ug, nameToCountry: namesToCountry.ug, phoneCode: countryPhoneCodes.ug},
  {id: 'UM', name: names.um, nameToCountry: namesToCountry.um, phoneCode: countryPhoneCodes.um},
  {id: 'US', name: names.us, nameToCountry: namesToCountry.us, phoneCode: countryPhoneCodes.us},
  {id: 'UY', name: names.uy, nameToCountry: namesToCountry.uy, phoneCode: countryPhoneCodes.uy},
  {id: 'UZ', name: names.uz, nameToCountry: namesToCountry.uz, phoneCode: countryPhoneCodes.uz},
  {id: 'VA', name: names.va, nameToCountry: namesToCountry.va, phoneCode: countryPhoneCodes.va},
  {id: 'VC', name: names.vc, nameToCountry: namesToCountry.vc, phoneCode: countryPhoneCodes.vc},
  {id: 'VE', name: names.ve, nameToCountry: namesToCountry.ve, phoneCode: countryPhoneCodes.ve},
  {id: 'VG', name: names.vg, nameToCountry: namesToCountry.vg, phoneCode: countryPhoneCodes.vg},
  {id: 'VI', name: names.vi, nameToCountry: namesToCountry.vi, phoneCode: countryPhoneCodes.vi},
  {id: 'VN', name: names.vn, nameToCountry: namesToCountry.vn, phoneCode: countryPhoneCodes.vn},
  {id: 'VU', name: names.vu, nameToCountry: namesToCountry.vu, phoneCode: countryPhoneCodes.vu},
  {id: 'WF', name: names.wf, nameToCountry: namesToCountry.wf, phoneCode: countryPhoneCodes.wf},
  {id: 'WS', name: names.ws, nameToCountry: namesToCountry.ws, phoneCode: countryPhoneCodes.ws},
  {id: 'XK', name: names.xk, nameToCountry: namesToCountry.xk, phoneCode: countryPhoneCodes.xk},
  {id: 'YE', name: names.ye, nameToCountry: namesToCountry.ye, phoneCode: countryPhoneCodes.ye},
  {id: 'YT', name: names.yt, nameToCountry: namesToCountry.yt, phoneCode: countryPhoneCodes.yt},
  {id: 'ZA', name: names.za, nameToCountry: namesToCountry.za, phoneCode: countryPhoneCodes.za},
  {id: 'ZM', name: names.zm, nameToCountry: namesToCountry.zm, phoneCode: countryPhoneCodes.zm},
  {id: 'ZW', name: names.zw, nameToCountry: namesToCountry.zw, phoneCode: countryPhoneCodes.zw},
];

export type CountryLocalizedData = Omit<CountryData, 'name'> & {name: string};

const cache: Partial<Record<string, CountryLocalizedData[]>> = {};

export function getLocalizedCountries(intl: IntlShape): CountryLocalizedData[] {
  let localeData = cache[intl.locale];
  if (!localeData) {
    localeData = countryList.map((item) => ({
      ...item,
      name: intl.formatMessage(item.name),
    }));

    const collator =
      global.Intl && global.Intl.Collator ? new global.Intl.Collator(intl.locale) : null;
    const hasLocaleCompare = !!String.prototype.localeCompare;
    const localeCompareSensivity = {sensitivity: 'base'} as const;

    localeData.sort((left, right) => {
      if (collator) {
        return collator.compare(left.name, right.name);
      }
      if (hasLocaleCompare) {
        return (left.name || '').localeCompare(right.name, intl.locale, localeCompareSensivity);
      }
      if (left.name < right.name) {
        return -1;
      }

      if (left.name > right.name) {
        return 1;
      }

      return 0;
    });

    cache[intl.locale] = localeData;
  }

  return localeData;
}
