import {isFileImage, isFileVideo} from 'utils/file';

import {MediaType} from '../types';

export const getMediaTypeByFile = (file: File): MediaType => {
  switch (true) {
    case isFileImage(file):
      return MediaType.IMAGE;

    case isFileVideo(file):
      return MediaType.VIDEO;

    default:
      throw new Error('Unknown file type');
  }
};
