import classnames from 'classnames/bind';
import {PopupAppearance} from 'components/UIKit/Appearance/PopupAppearance';

import styles from './default.scss';

const cn = classnames.bind(styles);

// Its a little bit hacky way to remove scrollbar while animation is appliying.
function setOverflow(element: HTMLElement, overflow: string): void {
  const parent = element.parentElement;
  if (parent) {
    parent.style.overflow = overflow;
  }
}

export const appearance: PopupAppearance = {
  className: cn('design', 'designPopup'),
  contentClassName: styles.content!,
  transitionProps: {
    classNames: {
      appear: styles.appear,
      appearActive: styles.appearActive,
    },
    in: true,
    appear: true,
    timeout: {appear: 500},
    enter: false,
    exit: false,
    onEnter: (node: HTMLElement): void => setOverflow(node, 'hidden'),
    onEntered: (node: HTMLElement): void => setOverflow(node, ''),
  },
};

export const sidebarRight: PopupAppearance = {
  ...appearance,
  className: cn('design', 'designSidebarRight'),
};

export const sidebarLeft: PopupAppearance = {
  ...appearance,
  className: cn('design', 'designSidebarLeft'),
};
