import React from 'react';
import {Preloader} from 'components/Preloader';
import styles from './index.scss';

export function PageLoading() {
  return (
    <div className={styles.loading}>
      <Preloader />
    </div>
  );
}

// for backwards compatibility
export default PageLoading;
