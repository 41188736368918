import React from 'react';
import {CategoryFrom} from 'types/analytics/Category';
import {PageSource} from 'types/PageSource';

import {AllInThisCategory} from './AllInThisCategory';
import {CatalogLink} from './CatalogLink';
import styles from './index.scss';
import {InnerChild} from './InnerChild';
import {BuildUrlFunc, Category, Params} from './types';
import {useCloseIfNoChildren} from './useCloseIfNoChildren';

type ChildProps = {
  buildUrl: BuildUrlFunc;
  isTopLevelView: boolean;
  item: Category;
  loading: boolean;
  onCloseBeforeNavigate?: () => void;
  params: Params;
  from: CategoryFrom;
  source?: PageSource;
};

export const Child = React.memo(function Child({
  buildUrl,
  isTopLevelView,
  item,
  loading,
  onCloseBeforeNavigate,
  params,
  from,
  source,
}: ChildProps) {
  const {children: itemChildren} = item;
  const handleClick = useCloseIfNoChildren({
    onClose: onCloseBeforeNavigate,
    loading,
    children: itemChildren,
  });

  return (
    <>
      <CatalogLink
        buildUrl={buildUrl}
        categoryId={item.id}
        loading={loading}
        params={params}
        withMargin={isTopLevelView}
        onClick={handleClick}
        from={from}
        source={source}
      >
        {item.name}
      </CatalogLink>
      {params.categoryId === item.id && !isTopLevelView ? (
        <div className={styles.allInCategoryLink}>
          <CatalogLink
            buildUrl={buildUrl}
            categoryId={item.id}
            noHighlight
            onClick={onCloseBeforeNavigate}
            params={params}
            withMargin
            loading={false}
            from={from}
            source={source}
          >
            <AllInThisCategory />
          </CatalogLink>
        </div>
      ) : null}
      {itemChildren &&
        itemChildren.map((child) => (
          <InnerChild
            key={`inner-child-${child.id}`}
            buildUrl={buildUrl}
            child={child}
            onCloseBeforeNavigate={onCloseBeforeNavigate}
            params={params}
            loading={loading}
            from={from}
            source={source}
          />
        ))}
    </>
  );
});
