import {SDLDimension, SDLText} from 'types/ServerDrivenLayout';
import {checkNever} from 'utils/guards';

export function toSize(value: number | SDLDimension | undefined): string | undefined {
  if (value == null) {
    return undefined;
  }

  if (typeof value === 'number') {
    return `${value}px`;
  }

  if (value.endsWith('px') || value.endsWith('%')) {
    return value;
  }

  return `${parseFloat(value)}px`;
}

export function getFontSize(text: SDLText): number {
  if (text.sizeOverride) {
    return text.sizeOverride;
  }

  switch (text.size) {
    case 'note':
      return 10;
    case 'caption':
      return 12;
    case 'text':
      return 14;
    case 'standard':
      return 16;
    case 'subtitle':
      return 18;
    case 'h2':
      return 20;
    case 'h1':
      return 24;
    case undefined:
      return 12;
    default:
      checkNever(text.size);
      return 12;
  }
}

export function getFontWeight(text: SDLText): number {
  switch (text.font) {
    case 'regular':
      return 400;
    case 'medium':
      return 500;
    case 'bold':
      return 700;
    case undefined:
      return 400;
    default:
      checkNever(text.font);
      return 400;
  }
}
