import React from 'react';
import {DeepLinkActionDispatch} from 'types/DeepLinks';

export const DeepLinkMiddlewareContext = React.createContext((targetUrl: string) =>
  Promise.resolve(targetUrl),
);

export const KeepDeepLinkContext = React.createContext<boolean>(false);

export const DeepLinkActionDispatchContext = React.createContext<DeepLinkActionDispatch>(
  () => undefined,
);
