import {Controls} from 'components/ClientProduct/Reviews/Controls';
import {DescriptionButton} from 'components/ClientProduct/Reviews/DescriptionButton';
import {Filters} from 'components/ClientProduct/Reviews/Filters';
import {Header} from 'components/ClientProduct/Reviews/Header';
import styles from 'components/ClientProduct/Reviews/index.scss';
import {Store} from 'components/ClientProduct/Store';
import ErrorMessage from 'components/ErrorMessage';
import {GalleryPopup} from 'components/GalleryPopup';
import {Locator} from 'components/Locator';
import {Preloader, Size as PreloaderSize} from 'components/Preloader';
import {Stars} from 'components/Stars';
import {Button} from 'components/UIKit/Button';
import {Icon as ButtonIcon, Text as ButtonText} from 'components/UIKit/Button/Content';
import {Icon} from 'components/UIKit/Icon';
import {useBot} from 'hooks/useBot';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {defineMessages, FormattedMessage, useIntl} from 'react-intl';
import {useLocation} from 'react-router-dom';
import {Error} from 'types/Error';
import {GalleryItem} from 'types/GalleryItem';
import {ClientProductIncomplete} from 'types/ProductNext';
import {
  ProductReviewFilters,
  ProductReviewSorting,
  ReviewsDescriptionButton,
} from 'types/ProductReviews';
import {SocialPost, SocialPostFilterId} from 'types/SocialPost';
import {isNumber} from 'utils/guards';
import {getObfuscatedCountValue} from 'utils/obfuscatedCount';
import {rootLocator} from 'utils/rootLocator';

import {useProductStoreReviewsConfig} from './hooks';
import {ReviewItem} from './Item';

const messages = defineMessages({
  empty: {
    description: 'Сообщение об отсутствии отзывов о продукте',
    defaultMessage: 'There are no user reviews yet.',
  },
  region: {
    description: '[a11y] Обозначение региона с фильтрами по отзывам',
    defaultMessage: 'Review filters',
  },
  reload: {
    description: '[button] text on button to reload page',
    defaultMessage: 'Reload page',
  },
  galleryPopupLabel: {
    description: '[a11y] Описание модального окна с галереей',
    defaultMessage: 'User image gallery',
  },
});

const locator = rootLocator.productPage.product.reviews;

type Props = {
  nextPage?: string;
  onControlClick?: () => void;
  page?: string;
  prevPage?: string;
  product?: ClientProductIncomplete;
  reviewFilterId?: string;
  reviewFilters?: ProductReviewFilters;
  reviews?: SocialPost[];
  reviewsDescriptionButton?: ReviewsDescriptionButton;
  reviewsError?: Error;
  reviewsLoading?: boolean;
  reviewSorting?: ProductReviewSorting;
  withStoreReviews?: boolean;
};

export const Reviews = React.memo(function Reviews({
  nextPage,
  onControlClick,
  page,
  prevPage,
  product,
  reviewFilterId,
  reviewFilters,
  reviews,
  reviewsDescriptionButton,
  reviewsError,
  reviewsLoading = false,
  reviewSorting,
  withStoreReviews = false,
}: Props): React.ReactElement {
  const intl = useIntl();
  const bot = useBot();
  const controlsVisible = !bot;
  const [currentReviews, setCurrentReviews] = useState(reviews);

  const reviewCount = useMemo(() => {
    const allFilter = (reviewFilters || []).find((filter) => filter.id === SocialPostFilterId.ALL);
    return allFilter ? getObfuscatedCountValue(allFilter.count) : undefined;
  }, [reviewFilters]);

  const location = useLocation();

  const href = `${location.pathname}${location.search}`;

  useEffect(() => {
    if (!reviewsLoading) {
      setCurrentReviews(reviews);
    }
  }, [reviews, reviewsLoading]);

  const reloadPage = useCallback(() => {
    window.location.reload();
  }, []);

  // Gallery Popup
  const [galleryIndex, setGalleryIndex] = useState<number | undefined>(undefined);
  const galleryMediaList = useMemo(
    () => currentReviews?.flatMap((review) => review.media ?? []) ?? [],
    [currentReviews],
  );
  const shouldShowGalleryPopup = isNumber(galleryIndex);

  const handleGalleryPopupOpen = useCallback(
    (media: GalleryItem) => {
      const index = galleryMediaList.indexOf(media);

      setGalleryIndex(index);
    },
    [galleryMediaList],
  );
  const handleGalleryPopupClose = useCallback(() => {
    setGalleryIndex(undefined);
  }, []);

  // Store reviews
  const storeReviewsConfig = useProductStoreReviewsConfig();

  // Render props
  const {id: productId, rating: productRating, store} = product?.header ?? {};
  const rating = withStoreReviews ? store?.rating : productRating;

  const showStore = !!(withStoreReviews && store && productId);
  const showFilters = !withStoreReviews;
  const showReviewsDescriptionButton = !!(reviewsDescriptionButton && productId);

  return (
    <div role="region" aria-label={intl.formatMessage(messages.region)}>
      <Header reviewCount={reviewCount} withStore={showStore} />
      {rating && (
        <Locator id="StarsReviewsBlockProductPage">
          <div className={styles.rating}>
            <Stars hasNumber rating={rating} />
          </div>
        </Locator>
      )}
      {showStore && (
        <div className={styles.store}>
          <Store store={store} productId={productId} />
        </div>
      )}
      {showFilters && (
        <Filters
          filters={reviewFilters}
          href={href}
          productId={productId}
          selectedFilterId={reviewFilterId}
          sorting={reviewSorting}
        />
      )}
      <Locator id="ReviewsBlockProductPage">
        <div className={styles.content}>
          {currentReviews?.length
            ? currentReviews.map((item) => (
                <Locator id="ReviewOnProductPage" key={item.id}>
                  <div className={styles.review}>
                    <ReviewItem
                      defaultSortingId={reviewSorting?.selectedItemId}
                      onMediaClick={handleGalleryPopupOpen}
                      review={item}
                      rows={withStoreReviews ? storeReviewsConfig?.maxTextRows : undefined}
                      withMedia={!withStoreReviews || !storeReviewsConfig?.hideMedia}
                      withReport={!withStoreReviews}
                      withVariant={!withStoreReviews || !storeReviewsConfig?.hideVariant}
                    />
                  </div>
                </Locator>
              ))
            : null}
          {!reviewsLoading && !reviewsError && !currentReviews?.length ? (
            <div className={styles.empty}>
              <FormattedMessage {...messages.empty} />
            </div>
          ) : null}
          {reviewsError && (
            <div className={styles.error}>
              <ErrorMessage error={reviewsError} internal noRequestId />
              <div className={styles.errorButton}>
                <Button
                  tag="button"
                  color="gray"
                  size="small"
                  onClick={reloadPage}
                  {...locator.errorButton()}
                >
                  <ButtonIcon>
                    <Icon name="repeat-linear-16" type="mono" width={16} />
                  </ButtonIcon>
                  <ButtonText>
                    <FormattedMessage {...messages.reload} />
                  </ButtonText>
                </Button>
              </div>
            </div>
          )}
          {reviewsLoading && (
            <div className={styles.overlay}>
              <Preloader size={PreloaderSize.SMALL} />
            </div>
          )}
        </div>
      </Locator>
      {showReviewsDescriptionButton && (
        <DescriptionButton
          reviewsDescriptionButton={reviewsDescriptionButton}
          productId={productId}
        />
      )}
      {controlsVisible && (
        <Controls
          href={href}
          loading={reviewsLoading}
          nextPage={nextPage}
          onClick={onControlClick}
          page={page}
          prevPage={prevPage}
          reviews={reviews}
        />
      )}
      {shouldShowGalleryPopup && (
        <GalleryPopup
          index={galleryIndex}
          items={galleryMediaList}
          onClose={handleGalleryPopupClose}
          popupLabel={intl.formatMessage(messages.galleryPopupLabel)}
        />
      )}
    </div>
  );
});
