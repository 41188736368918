import cn from 'classnames';
import {Locator} from 'components/Locator';
import React, {ReactElement} from 'react';

import badgeColorImg from './badgeColor.svg';
import badgeGrayImg from './badgeGray.svg';
import styles from './index.scss';

type Props = {
  asMark?: boolean;
  withBackground?: boolean;
  withDarkTheme?: boolean;
};

export function VisaSecurityBadge({
  asMark,
  withBackground = true,
  withDarkTheme = false,
}: Props): ReactElement {
  return (
    <Locator id="VisaSecureOnFooter">
      <div
        className={cn(
          styles.root,
          asMark && styles.asMark,
          withBackground && styles.withBackground,
          withDarkTheme && styles.withDarkTheme,
        )}
      >
        <img alt="" src={asMark ? badgeGrayImg : badgeColorImg} className={styles.icon} />
        Payment Card Security Standard
      </div>
    </Locator>
  );
}
