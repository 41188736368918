import {FuncAction} from 'typesafe-actions';
import uuid from 'uuid/v4';

import {
  FinishPart,
  FinishVideoUploadingResponse,
  GetVideoUploadingStatusResponse,
  StartVideoUploadingRequest,
  StartVideoUploadingResponse,
  StopVideoUploadingResponse,
  UploadImageResponse,
  UploadPart,
  UploadVideoPartResponse,
} from './actions';
import {arrayBufferToMd5, getUploadPartUid} from './utils';

type UploadImageResponseBody = {
  payload: UploadImageResponse;
};

export const uploadImage =
  (file: File): FuncAction<Promise<UploadImageResponse>> =>
  async (store, client) =>
    client.api
      .post<UploadImageResponseBody>('/upload/images', {
        attach: [[uuid(), file, {contentType: file.type}]],
      })
      .then(({body: {payload}}) => payload);

type GetVideoUploadingStatusResponseBody = {
  payload: GetVideoUploadingStatusResponse;
};

export const getVideoUploadingStatus =
  (uploadId: string): FuncAction<Promise<GetVideoUploadingStatusResponse>> =>
  async (store, client) =>
    client.api
      .get<GetVideoUploadingStatusResponseBody>('/video/upload/status', {
        query: {
          id: uploadId,
        },
      })
      .then(({body}) => body.payload);

type StartVideoUploadingResponseBody = {
  payload: StartVideoUploadingResponse;
};

export const startVideoUploading =
  (file: File, fileArrayBuffer: ArrayBuffer): FuncAction<Promise<StartVideoUploadingResponse>> =>
  async (store, client) => {
    const fileMd5 = arrayBufferToMd5(fileArrayBuffer);
    const requestBody: StartVideoUploadingRequest = {
      contentType: file.type,
      md5: fileMd5,
      size: file.size,
    };

    return client.api
      .post<StartVideoUploadingResponseBody>('/video/upload/start', {
        body: requestBody,
      })
      .then(({body}) => body.payload);
  };

export const uploadVideoPart =
  (part: UploadPart, fileArrayBuffer: ArrayBuffer): FuncAction<Promise<UploadVideoPartResponse>> =>
  async (store, client) =>
    client.pureApi
      .put<void>(part.url, {
        body: fileArrayBuffer.slice(part.bytesFrom, part.bytesTo),
        retry: true,
        id: getUploadPartUid(part),
      })
      .then(({headers}) => ({
        etag: headers.etag ?? '',
      }));

type FinishVideoUploadingResponseBody = {
  payload: FinishVideoUploadingResponse;
};

export const finishVideoUploading =
  (
    uploadId: string,
    finishParts: FinishPart[],
  ): FuncAction<Promise<FinishVideoUploadingResponse>> =>
  async (store, client) =>
    client.api
      .post<FinishVideoUploadingResponseBody>('/video/upload/finish', {
        body: {
          id: uploadId,
          parts: finishParts,
        },
      })
      .then(({body}) => body.payload);

export const stopVideoUploading =
  (uploadId: string, reason?: string): FuncAction<Promise<StopVideoUploadingResponse>> =>
  async (store, client) =>
    client.api
      .delete<void>('/video/upload', {
        body: {
          id: uploadId,
          reason,
        },
      })
      .then(() => undefined);

export const abortAllUploadRequests =
  (uploadParts: UploadPart[]): FuncAction<Promise<void>> =>
  async (store, client) =>
    uploadParts.forEach((part) => {
      client.pureApi.abort(getUploadPartUid(part));
    });
