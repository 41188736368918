import classnames from 'classnames/bind';
import React from 'react';

import styles from './Reducer.scss';

const cn = classnames.bind(styles);

type Props = {
  center?: boolean;
  children?: React.ReactNode;
  noMarginBottom?: boolean;
  noPadding?: boolean;
  fullWidth?: boolean;
  smallFullWidthOnly?: boolean;
  mobileFullWidthOnly?: boolean;
};

export function Reducer({
  children = null,
  center = false,
  noMarginBottom = false,
  noPadding = false,
  fullWidth = false,
  smallFullWidthOnly = false,
  mobileFullWidthOnly = false,
}: Props): React.ReactElement | null {
  if (!children) {
    return null;
  }

  return (
    <div
      className={cn('reducer', {
        center,
        noMarginBottom,
        noPadding,
        fullWidth,
        smallFullWidthOnly,
        mobileFullWidthOnly,
      })}
    >
      <div className={styles.inner}>{children}</div>
    </div>
  );
}
