import {Button} from 'components/UIKit/Button';
import {Popup} from 'components/UIKit/Popup';
import {Content} from 'components/UIKit/Popup/Content';
import {Footer} from 'components/UIKit/Popup/Footer';
import {Header} from 'components/UIKit/Popup/Header';
import React, {useCallback, useState} from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';
import {CommonProduct} from 'types/CommonProduct';

import {useAddProductToCollectionsPopup} from '../AddProductToCollectionsPopup';
import styles from './index.scss';

type Props = {
  onBack?: () => void;
  onClose: () => void;
  onConfirm: () => void;
  product: CommonProduct;
};

const messages = defineMessages({
  unfavoritePopupTitle: {
    defaultMessage: 'Remove product from collections?',
    description: '[title] Заголовок попапа удаления товара из подборок',
  },
  unfavoritePopupText: {
    defaultMessage: 'Do you want to remove this product from all your favourite collections?',
    description: 'Текст попапа удаления товара из подборок',
  },
  unfavoriteConfirmText: {
    defaultMessage: 'Yes',
    description: '[button] Текст кнопки подтверждения удаления товара из подборок',
  },
  unfavoriteCancelText: {
    defaultMessage: 'No',
    description: '[button] Текст кнопки выбора подборок из которых будет удалён товар',
  },
});

export function ConfirmUnfavoritePopup({onBack, onClose, onConfirm, product}: Props): JSX.Element {
  const [isLoading, setIsLoading] = useState(false);
  const handleConfirmClick = useCallback(async () => {
    setIsLoading(true);
    try {
      await onConfirm();
      onClose();
    } finally {
      setIsLoading(false);
    }
  }, [onClose, onConfirm]);

  const addProductToCollectionPopup = useAddProductToCollectionsPopup({
    products: [product],
    source: 'deleteConfirmationDialog',
    onBack,
    onClose,
    onSave: onClose,
  });

  return (
    <Popup width="550px">
      <Header onClose={onClose}>
        <span className={styles.title}>
          <FormattedMessage {...messages.unfavoritePopupTitle} />
        </span>
      </Header>
      <Content>
        <span className={styles.subtitle}>
          <FormattedMessage {...messages.unfavoritePopupText} />
        </span>
      </Content>
      <Footer>
        <div className={styles.popupButtonList}>
          <div className={styles.popupButton}>
            <Button
              tag="button"
              color="gray"
              onClick={addProductToCollectionPopup.open}
              disabled={isLoading}
            >
              <FormattedMessage {...messages.unfavoriteCancelText} />
            </Button>
          </div>
          <div className={styles.popupButton}>
            <Button tag="button" color="accent" onClick={handleConfirmClick} disabled={isLoading}>
              <FormattedMessage {...messages.unfavoriteConfirmText} />
            </Button>
          </div>
        </div>
      </Footer>
    </Popup>
  );
}
