import React, {memo, useMemo} from 'react';
import {FormattedList, FormattedMessage} from 'react-intl';
import {Locator} from 'components/Locator';
import PropTypes from 'prop-types';
import {ColorShape} from 'shapes/Color';
import {identity} from 'utils/function';

export const OrderAttributes = memo((props) => {
  const {colors, size, quantity} = props;
  const testId = props['data-testid'];
  const list = useMemo(() => {
    const result = [];
    const colorsText = colors
      ?.map((item) => item.name)
      .filter(identity)
      .join(' / ');

    if (colorsText) {
      result.push(colorsText);
    }

    if (size) {
      result.push(size);
    }

    result.push(
      <FormattedMessage
        defaultMessage="{quantity, plural, one {# pc.} other {# pcs.}}"
        description="Parcel order quantity with unit"
        values={{quantity}}
      />,
    );

    return result;
  }, [colors, size, quantity]);

  return (
    <Locator id={testId}>
      <div>
        <FormattedList style="narrow" value={list} />
      </div>
    </Locator>
  );
});

OrderAttributes.propTypes = {
  colors: PropTypes.arrayOf(ColorShape),
  'data-testid': PropTypes.string,
  size: PropTypes.string,
  quantity: PropTypes.number.isRequired,
};

OrderAttributes.defaultProps = {
  colors: [],
  'data-testid': undefined,
  size: null,
};
