import {deepLinks, isDeepLink} from 'helpers/deepLink';
import {useLanguage} from 'hooks/useLanguage';
import {useLogger} from 'hooks/useLogger';
import {useScope} from 'hooks/useScope';
import {
  DeepLinkActionDispatchContext,
  DeepLinkMiddlewareContext,
  KeepDeepLinkContext,
} from 'providers/DeepLinks';
import {useCallback, useContext, useMemo} from 'react';
import {useHistory} from 'react-router-dom';
import {DeepLinkActionDispatch, isDeepLinkAction} from 'types/DeepLinks';
import {isExternalUrl} from 'utils/url';

type ActionDispatcher = () => void;

type UseDeepLink = [string?, ActionDispatcher?];

export function useKeepDeepLink(): boolean {
  return useContext(KeepDeepLinkContext);
}

export function useDeepLinkActionDispatch(): DeepLinkActionDispatch {
  return useContext(DeepLinkActionDispatchContext);
}

export function useDeepLinkMiddleware(): (target: string) => Promise<string> {
  return useContext(DeepLinkMiddlewareContext);
}

export function useDeepLink(sourceUrl?: string): UseDeepLink {
  const keepDeepLink = useKeepDeepLink();
  const dispatch = useDeepLinkActionDispatch();
  const lang = useLanguage();
  const scope = useScope();

  return useMemo(() => {
    if (!keepDeepLink && typeof sourceUrl === 'string' && isDeepLink(sourceUrl)) {
      const match = deepLinks.match(sourceUrl);

      if (match) {
        const result = match.reverse(scope, lang);

        if (isDeepLinkAction(result)) {
          return [undefined, () => dispatch(result)];
        }

        return [result, undefined];
      }
    }

    return [sourceUrl, undefined];
  }, [keepDeepLink, sourceUrl, scope, lang, dispatch]);
}

export function useDeepLinkNavigate(): (targetUrl: string, replace?: boolean) => Promise<void> {
  const logger = useLogger('useDeepLinkNavigate');

  const dispatch = useDeepLinkActionDispatch();
  const lang = useLanguage();
  const scope = useScope();
  const history = useHistory();
  const middleware = useDeepLinkMiddleware();

  return useCallback(
    (targetUrl: string, replace = false) => {
      return middleware(targetUrl)
        .then(
          (updatedUrl) =>
            new Promise<void>((resolve, reject) => {
              const match = deepLinks.match(updatedUrl || targetUrl);
              if (match) {
                const result = match.reverse(scope, lang);

                if (isDeepLinkAction(result)) {
                  dispatch(result);
                } else if (isExternalUrl(result)) {
                  window.open(result, '_blank')?.focus();
                } else if (replace) {
                  history.replace(result);
                } else {
                  history.push(result);
                }

                resolve();
              } else {
                reject();
              }
            }),
        )
        .catch(() => {
          logger.info(`Deeplink navigation rejected: ${targetUrl}`);
          return Promise.reject();
        });
    },
    [dispatch, history, scope, lang, middleware, logger],
  );
}
