import {Image} from 'components/Image';
import {useColorStyles} from 'hooks/useColorStyles';
import {useDeepLink} from 'hooks/useDeepLink';
import React, {useCallback} from 'react';
import {Row as RowType} from 'types/Layout';

import styles from './index.scss';

type Props = {
  row: RowType;
};

export const LayoutRow: React.FC<Props> = ({row}: Props) => {
  const [, deepLinkAction] = useDeepLink(row?.url);

  const handleClick = useCallback(() => {
    if (deepLinkAction) {
      deepLinkAction();
    }
  }, [deepLinkAction]);

  const style = useColorStyles({
    color: row.titleColor,
    background: row.background,
  });

  return (
    <button type="button" className={styles.row} onClick={handleClick} style={style}>
      <Image block className={styles.image} contain image={row.image} loadingHidden />
      {row.title}
    </button>
  );
};
