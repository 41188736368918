import PropTypes from 'prop-types';
import React from 'react';

import {DaysMessage} from 'components/DaysMessage';

import {NumberGroup} from './NumberGroup';
import {TimerBox} from './TimerBox';
import styles from './index.scss';

const MINUTE = 60;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;

export class Countdown extends React.PureComponent {
  static propTypes = {
    onComplete: PropTypes.func,
    timestamp: PropTypes.number.isRequired,
    changeFormatDurationBeforeMs: PropTypes.number,
    completeMessage: PropTypes.node,
    mode: PropTypes.oneOf(['simple', 'default']),
  };

  static defaultProps = {
    onComplete: null,
    completeMessage: null,
    mode: 'default',
    changeFormatDurationBeforeMs: 2 * DAY * 1000,
  };

  constructor(props) {
    super(props);

    this.updateRemaining = this.updateRemaining.bind(this);
    this.state = {
      remaining: this.getRemaining(),
      isCompleteMessageShown: false,
    };
    this.timerId = 0;
  }

  componentDidMount() {
    this.timerId = setTimeout(this.updateRemaining, 0);
  }

  componentWillUnmount() {
    clearTimeout(this.timerId);
  }

  handleComplete() {
    const {onComplete, completeMessage} = this.props;

    onComplete?.();

    if (completeMessage) {
      this.setState({isCompleteMessageShown: true});
    }
  }

  getRemaining() {
    return Math.max(this.props.timestamp - Date.now(), 0);
  }

  updateRemaining() {
    const remaining = this.getRemaining();
    const nextDelay = Math.min(remaining, 1000);

    if (remaining !== this.state.remaining) {
      this.setState({remaining});
    }

    if (nextDelay > 0) {
      this.timerId = setTimeout(this.updateRemaining, nextDelay);
    } else {
      this.handleComplete();
    }
  }

  render() {
    const {remaining, isCompleteMessageShown} = this.state;
    const {completeMessage, changeFormatDurationBeforeMs} = this.props;

    let seconds = Math.round(remaining / 1000);
    const days = Math.floor(seconds / DAY);
    seconds %= DAY;
    const hours = Math.floor(seconds / HOUR);
    seconds %= HOUR;
    const minutes = Math.floor(seconds / MINUTE);
    seconds %= MINUTE;

    if (remaining >= changeFormatDurationBeforeMs) {
      return (
        <TimerBox>
          <DaysMessage value={days} />
        </TimerBox>
      );
    }

    if (isCompleteMessageShown) {
      return <TimerBox>{completeMessage}</TimerBox>;
    }

    const displayedHours = days * 24 + hours;

    const isPlain = this.props.mode === 'simple';
    return (
      <div className={styles.countdown} dir="ltr" suppressHydrationWarning>
        {(!days && !hours) || <NumberGroup value={displayedHours} plain={isPlain} />}
        <NumberGroup value={minutes} plain={isPlain} />
        <NumberGroup value={seconds} last plain={isPlain} />
      </div>
    );
  }
}
