import styles from 'components/ClientProduct/Reviews/Header/index.scss';
import {Locator} from 'components/Locator';
import React from 'react';
import {defineMessages, FormattedMessage, FormattedNumber} from 'react-intl';

const messages = defineMessages({
  header: {
    description: 'Заголовок отзывов на странице продукта',
    defaultMessage: 'Reviews',
  },
  headerWithCount: {
    description: 'Заголовок отзывов со счётчиком на странице продукта',
    defaultMessage: 'Reviews {count}',
  },
  headerStore: {
    description: 'Заголовок отзывов на магазин на странице продукта',
    defaultMessage: "Reviews of store's products",
  },
  headerStoreWithCount: {
    description: 'Заголовок отзывов со счётчиком на магазин на странице продукта',
    defaultMessage: "Reviews of store's products {count}",
  },
});

type Props = {
  reviewCount?: number;
  withStore?: boolean;
};

export function Header({reviewCount = 0, withStore = false}: Props): React.ReactElement {
  const countEl = (
    <Locator id="RatingCountInReviewBlock">
      <span className={styles.count}>
        <FormattedNumber value={reviewCount} />
      </span>
    </Locator>
  );
  let messageEl = <FormattedMessage {...messages.header} />;

  if (withStore) {
    if (reviewCount) {
      messageEl = <FormattedMessage {...messages.headerStoreWithCount} values={{count: countEl}} />;
    }

    messageEl = <FormattedMessage {...messages.headerStore} />;
  }

  if (reviewCount) {
    messageEl = <FormattedMessage {...messages.headerWithCount} values={{count: countEl}} />;
  }

  return (
    <Locator id="HeaderReviewsBlockProductPage">
      <h3 className={styles.header}>{messageEl}</h3>
    </Locator>
  );
}
