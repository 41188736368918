import {OldBrowserBanner} from 'components/OldBrowser/OldBrowserBanner';
import {useUserAgent} from 'hooks/useUserAgent';
import React, {ReactElement, useMemo} from 'react';
import {productionBrowsersRegExp} from 'utils/browserslist/productionBrowsersRegExp.val';

export function OldBrowser(): ReactElement | null {
  const {ua: userAgent} = useUserAgent();

  const isSupportedBrowser = useMemo(() => productionBrowsersRegExp.test(userAgent), [userAgent]);

  return isSupportedBrowser ? null : <OldBrowserBanner />;
}
