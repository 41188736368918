import {Button} from 'components/UIKit/Button';
import {eventParamsList} from 'helpers/eventParams';
import {usePreviewEvent} from 'hooks/usePreviewEvent';
import {useUniversalNavigate} from 'hooks/useUniversalNavigate';
import React, {useCallback, useMemo, useRef} from 'react';
import {
  isProductCardGroupProductItem,
  ProductCardGroup as ProductCardGroupType,
  ProductCardGroupDesign,
} from 'types/ContentList/ProductCartGroup';
import {ProductLite} from 'types/ProductLite';
import {
  convertAnyBackendGradientToCSSValue,
  convertBackendColorToCSSValue,
} from 'utils/styles/color';

import {Header} from '../Header';
import styles from './index.scss';
import {LargeCards} from './LargeCards';
import {SmallCards} from './SmallCards';

type Props = {
  content: ProductCardGroupType;
  id: string;
  onFavorite?: (product: ProductLite) => Promise<void>;
  onUnfavorite?: (product: ProductLite) => Promise<void>;
};

const ThresholdByDesign = {
  [ProductCardGroupDesign.LARGE_CARD]: 0.3,
  [ProductCardGroupDesign.SMALL_CARD]: 0.5,
};

export function canRenderProductCardGroup({
  appearance: {design},
  items,
}: ProductCardGroupType): boolean {
  return (
    design === ProductCardGroupDesign.LARGE_CARD ||
    design === ProductCardGroupDesign.SMALL_CARD ||
    items.some(isProductCardGroupProductItem)
  );
}

export const ProductCardGroup = React.memo(function ProductCardGroup({
  content: {
    appearance: {design},
    header,
    items,
    button,
    eventParams,
  },
  id,
  onFavorite,
  onUnfavorite,
}: Props): React.ReactElement | null {
  const rootRef = useRef<HTMLDivElement>(null);
  const linkRef = useRef(null);
  const titleRef = useRef(null);

  const products = useMemo(() => {
    return items.reduce<Array<ProductLite>>((result, item) => {
      if (item.product) {
        result.push(item.product);
      }

      return result;
    }, []);
  }, [items]);

  usePreviewEvent(
    {
      rootRef,
      previewEvent: {
        type: 'productCardGroupPreview',
        payload: {id},
        params: eventParamsList(eventParams),
      },
      visibilityThreshold: ThresholdByDesign[design],
    },
    [
      {
        clickRef: linkRef,
        clickEvent: {
          type: 'productCardGroupActionClick',
          payload: {id},
          params: eventParamsList(eventParams),
        },
      },
      {
        clickRef: titleRef,
        clickEvent: {
          type: 'productCardGroupActionClick',
          payload: {id},
          params: eventParamsList(eventParams),
        },
      },
    ],
  );

  const navigate = useUniversalNavigate();
  const handleButtonClick = useCallback(() => {
    if (button?.url) {
      navigate(button.url);
    }
  }, [button?.url, navigate]);

  return (
    <div ref={rootRef}>
      {header && <Header header={header} titleRef={titleRef} linkRef={linkRef} />}
      {design === ProductCardGroupDesign.SMALL_CARD && (
        <SmallCards id={id} items={products} onFavorite={onFavorite} onUnfavorite={onUnfavorite} />
      )}
      {design === ProductCardGroupDesign.LARGE_CARD && (
        <LargeCards items={products} onFavorite={onFavorite} onUnfavorite={onUnfavorite} />
      )}
      {button && (
        <div className={styles.button}>
          <Button
            tag="button"
            size="medium"
            color={{
              base: 'gray',
              background: convertAnyBackendGradientToCSSValue(button?.backgroundGradient, '90deg'),
              color: convertBackendColorToCSSValue(button?.textColor),
            }}
            onClick={handleButtonClick}
          >
            {button.text}
          </Button>
        </div>
      )}
    </div>
  );
});
