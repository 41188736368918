import {Html} from 'components/HyperText/Html';
import {Image} from 'components/Image';
import React from 'react';
import {TermsItem as TermsItemType} from 'types/ContentList/ContentListTermsItem';

import styles from './index.scss';

type Props = {content: TermsItemType};

export function TermsItem({content}: Props): React.ReactElement {
  const {icon} = content;

  return (
    <div className={styles.termsItem}>
      {icon ? (
        <Image
          image={icon}
          alt={icon.accessibilityLabel}
          className={styles.icon}
          contain
          pxFit={24}
        />
      ) : null}
      <div>
        <div className={styles.title}>{content.title?.text}</div>
        <div className={styles.text}>
          <Html html={content.text.text} />
        </div>
      </div>
    </div>
  );
}
