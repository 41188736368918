import React from 'react';
import {defineMessages, FormattedMessage, FormattedNumber, MessageDescriptor} from 'react-intl';
import {ObfuscatedCount} from 'types/ObfuscatedCount';
import {formatWithKs} from 'utils/number';

type Props = {
  count: ObfuscatedCount;
  message?: MessageDescriptor;
  isCompact?: boolean;
};

const emptyMessage = defineMessages({
  empty: {
    defaultMessage: '{value}',
    description: 'Empty string for counter formatting',
  },
}).empty;

export function FormattedCount({
  count,
  message = emptyMessage,
  isCompact = false,
}: Props): React.ReactElement {
  let value;
  let floor;

  if (typeof count === 'number') {
    value = count;
    floor = false;
  } else {
    value = count.value;
    floor = count.modifier === 'floor';
  }

  if (isCompact) {
    value = formatWithKs(value, true, 0);
  } else if (floor) {
    value = (
      <>
        <FormattedNumber minimumFractionDigits={0} value={value} />+
      </>
    );
  }

  return <FormattedMessage {...message} values={{value}} />;
}
