import {isNumber, isRecord} from 'utils/guards';

export type Count = {
  modifier?: 'floor';
  text?: string;
  value: number;
};

export type ObfuscatedCount = number | Count;

export function isObfuscatedCount(count: unknown): count is ObfuscatedCount {
  return isNumber(count) || (isRecord(count) && 'value' in count);
}
