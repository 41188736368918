import {checkNever} from 'utils/guards';

import {MediaStatus, MediaType, UploaderErrorType, UploaderOptions} from '../types';
import {createUploaderError} from './createUploaderError';
import {startImageUploading} from './startImageUploading';
import {startVideoUploading} from './startVideoUploading';
import {validateMediaState} from './validateMediaState';

export const startMediaUploading = async (
  mediaUuid: string,
  uploaderOptions: UploaderOptions,
): Promise<void> => {
  const {mediaStatesMap} = uploaderOptions.getState();
  let mediaState = mediaStatesMap[mediaUuid];

  if (!mediaState) {
    throw createUploaderError(UploaderErrorType.INTERNAL_ERROR, {
      message: `Attempt to validate a non-existent media state (mediaUuid: ${mediaUuid})`,
    });
  }

  if (mediaState.status !== MediaStatus.WILL_BE_UPLOADED) {
    return;
  }

  mediaState = uploaderOptions.commit(mediaUuid, {
    ...mediaState,
    status: MediaStatus.IN_PROGRESS,
  });

  validateMediaState(mediaUuid, mediaState, uploaderOptions);

  switch (mediaState.type) {
    case MediaType.IMAGE:
      await startImageUploading(mediaUuid, mediaState, uploaderOptions);
      break;

    case MediaType.VIDEO:
      await startVideoUploading(mediaUuid, mediaState, uploaderOptions);
      break;

    default:
      checkNever(mediaState);
  }
};
