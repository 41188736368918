import {TRUSTPILOT_CONFIG_DEFAULT} from 'components/Trustpilot/consts';
import {TrustpilotBadge} from 'components/Trustpilot/TrustpilotBadge';
import {TrustpilotConfig} from 'components/Trustpilot/types';
import {VisaSecurityBadge} from 'components/VisaSecurityBadge';
import React, {useMemo} from 'react';

import styles from './index.scss';

type FooterBadgesProps = {
  withDarkTheme?: boolean;
  withTrustpilot?: boolean;
  withVisaSecurity?: boolean;
};

export function FooterBadges({
  withDarkTheme = false,
  withTrustpilot = true,
  withVisaSecurity = true,
}: FooterBadgesProps): JSX.Element | null {
  const trustpilotConfig = useMemo(
    () =>
      ({
        ...TRUSTPILOT_CONFIG_DEFAULT,
        theme: withDarkTheme ? 'dark' : 'light',
      }) as TrustpilotConfig,
    [withDarkTheme],
  );

  if (!withTrustpilot && !withVisaSecurity) {
    return null;
  }

  return (
    <div className={styles.badges}>
      {withTrustpilot && (
        <div className={styles.badgesItem}>
          <TrustpilotBadge config={trustpilotConfig} withBackground={false} />
        </div>
      )}
      {withVisaSecurity && (
        <div className={styles.badgesItem}>
          <VisaSecurityBadge withBackground={false} withDarkTheme={withDarkTheme} />
        </div>
      )}
    </div>
  );
}
