import {useCallback, useEffect, useRef} from 'react';

export type UseTimeoutFnReturn = [
  isReady: () => boolean | null,
  clear: () => void,
  set: (updatedMs?: number) => void,
];

export function useTimeout(
  fn: (...args: unknown[]) => unknown,
  ms = 0,
  startNow = false,
): UseTimeoutFnReturn {
  const ready = useRef<boolean | null>(false);
  const timeout = useRef<ReturnType<typeof setTimeout>>();
  const callback = useRef(fn);

  const isReady = useCallback(() => ready.current, []);

  const set = useCallback(
    (updatedMs?: number) => {
      ready.current = false;
      if (timeout.current) {
        clearTimeout(timeout.current);
      }

      timeout.current = setTimeout(() => {
        ready.current = true;
        callback.current();
      }, updatedMs || ms);
    },
    [ms],
  );

  const clear = useCallback(() => {
    ready.current = null;
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
  }, []);

  callback.current = fn;

  useEffect(() => {
    if (startNow) {
      set();
    }

    return clear;
  }, [clear, set, startNow]);

  return [isReady, clear, set];
}
