import {createLocator, Locator, Mark} from 'create-locator';
import {useUniversalNavigate} from 'hooks/useUniversalNavigate';
import React, {useCallback} from 'react';
import {ProductLite} from 'types/ProductLite';

import styles from '../index.scss';
import {ProductBadge} from './ProductBadge';

type Props = {
  product: ProductLite;
};

export type ImageOverlaysLocator = Locator<{
  adBadge: void;
}>;

// Should not be translated
const AD_CAPTION = 'AD';

export function ImageOverlays({
  product,
  ...rest
}: Props & Mark<ImageOverlaysLocator>): JSX.Element | null {
  const locator = createLocator(rest);

  const productBadge = product.badge ? (
    <ProductBadge loadedTimeMs={product.loadedTimeMs} badge={product.badge} />
  ) : null;

  const hasAdBadge = Boolean(product.advertisement?.isAd);

  const navigate = useUniversalNavigate();

  const handleAdBadgeClick = useCallback(
    (event: React.SyntheticEvent) => {
      if (product.advertisement?.deeplink) {
        event.preventDefault();
        event.stopPropagation();
        navigate(product.advertisement.deeplink);
      }
    },
    [navigate, product.advertisement?.deeplink],
  );

  return (
    <>
      {productBadge ? <div className={styles.notice}>{productBadge}</div> : null}
      {hasAdBadge ? (
        <div className={styles.icons}>
          <button
            onClick={handleAdBadgeClick}
            type="button"
            className={styles.adBadge}
            {...locator.adBadge()}
          >
            {AD_CAPTION}
          </button>
        </div>
      ) : null}
    </>
  );
}
