import React, {useRef} from 'react';
import {
  SideBannerGroupBanner,
  SideBannerGroupBannerDesign,
} from 'types/ContentList/SideBannerGroup';

import {Background} from '../../Background';
import {useBannerPreview} from '../../hooks';
import styles from './index.scss';
import {OneLineContent} from './OneLineContent';
import {TwoLineContent} from './TwoLineContent';

type Props = {
  banner: SideBannerGroupBanner;
  design: SideBannerGroupBannerDesign;
  id: string;
  source?: string;
};

const BANNER_THRESHOLD = 0.7;

export const Banner = React.memo(function Banner({banner, design, id, source}: Props) {
  const contentRef = useRef<HTMLDivElement>(null);
  const bannerRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useBannerPreview({
    bannerId: id,
    rootRef: contentRef,
    clickRefs: [bannerRef, buttonRef],
    visibilityThreshold: BANNER_THRESHOLD,
    source,
  });

  return (
    <div className={styles.banner} ref={contentRef}>
      <Background background={banner.background} />
      <div className={styles.content}>
        {design === SideBannerGroupBannerDesign.ONE_LINE ? (
          <OneLineContent banner={banner} ref={bannerRef} source={source} />
        ) : (
          <TwoLineContent
            banner={banner}
            buttonRef={buttonRef}
            bannerRef={bannerRef}
            source={source}
          />
        )}
      </div>
    </div>
  );
});
