import {cancelAnimationFrame, requestAnimationFrame} from './raf';

const DEFAULT_ACC = (value: number): number => value;
export const DEFAULT_DURATION = 300;

const animations: Record<string, number> = {};
let counter = 1;

type Handler = (progress: number) => void;

export const ANIMATION_MAX_PROGRESS = 1;

export function animate(handler: Handler, duration = DEFAULT_DURATION, acc = DEFAULT_ACC): number {
  const start = Date.now();
  const end = start + duration;
  const id = counter;

  counter += 1;

  function tick(): void {
    const now = Date.now();

    handler(acc(Math.min((now - start) / (end - start), ANIMATION_MAX_PROGRESS)));

    if (animations[id] && now < end) {
      animations[id] = requestAnimationFrame(tick);
    } else {
      delete animations[id];
    }
  }

  animations[id] = -1;
  tick();

  return id;
}

export function cancelAnimation(id: number): void {
  cancelAnimationFrame(animations[id]!);
}
