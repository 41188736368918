import PropTypes from 'prop-types';
import {ColorShape} from './Color';
import ImageBundle from './ImageBundle';
import OrderTotalPrice from './OrderTotalPrice';
import {ShippingEstimateShape} from './ShippingEstimate';
import TrackingCheckpoint from './TrackingCheckpoint';
import Warranty from './Warranty';

export default PropTypes.shape({
  checkpointsCount: PropTypes.number,
  colors: PropTypes.arrayOf(ColorShape),
  createdTimeMs: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  friendlyId: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  latestCheckpoint: TrackingCheckpoint,
  mainImage: ImageBundle,
  quantity: PropTypes.number.isRequired,
  size: PropTypes.string,
  total: OrderTotalPrice.isRequired,
  warranty: Warranty.isRequired,
  shippingEstimate: ShippingEstimateShape.isRequired,
});
