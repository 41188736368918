import {objectValues} from 'utils/object';
import PropTypes from 'prop-types';
import {ColorShape} from 'shapes/Color';

export const CouponState = {
  ACTIVE: 'active',
  USED: 'used',
  EXPIRED: 'expired',
};

export default PropTypes.shape({
  activationDeadlineMs: PropTypes.number,
  activationDelayMs: PropTypes.number, // Delay between opening screen and showing coupon
  activationTimeMs: PropTypes.number,
  cardBackDescription: PropTypes.string, // Back description
  cardBackTitle: PropTypes.string, // Back title
  cardDescription: PropTypes.string.isRequired, // Fullscreen front description
  cardExpiration: PropTypes.string.isRequired, // Fullscreen front expiration
  cardTitle: PropTypes.string.isRequired, // Fullscreen front title
  cartTitle: PropTypes.string, // Cart and checkout title
  createdTimeMs: PropTypes.number,
  durationMs: PropTypes.number,
  expirationTimeMs: PropTypes.number,
  expirationType: PropTypes.string,
  homeTitle: PropTypes.string,
  id: PropTypes.string.isRequired,
  leftColor: ColorShape.isRequired, // Left gradient color (rrggbb)
  loadedTimeMs: PropTypes.number,
  receiveStubTitle: PropTypes.string, // Coupon stub title upon first opening
  receiveSubtitle: PropTypes.string, // Fullscreen top subtitle upon first opening, optional
  receiveTitle: PropTypes.string, // Fullscreen top title upon first opening, optional
  rightColor: ColorShape.isRequired, // Right gradient color (rrggbb)
  state: PropTypes.oneOf(objectValues(CouponState)),
  textColor: ColorShape.isRequired, // Text color (rrggbb)
  timeRemainingMs: PropTypes.number,
  type: PropTypes.string,
  updatedTimeMs: PropTypes.number,
  isSelected: PropTypes.bool,
});
