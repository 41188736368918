import PropTypes from 'prop-types';
import React, {memo} from 'react';
import Button from 'components/Button';
import ErrorMessage from 'components/ErrorMessage';
import {Locator} from 'components/Locator';
import ErrorShape from 'shapes/Error';
import {FormattedMessage} from 'react-intl';
import {
  DeliveryConfirmationMode,
  DeliveryConfirmationModeType,
} from 'shapes/ParcelDeliveryConfirmationMode';
import {ParcelPageCardInner} from 'components/ParcelPageCard';
import styles from './DoYouReceive.scss';

export const DoYouReceive = memo(
  ({deliveryConfirmationMode, onYes, onNo, yesLoading, yesError}) => {
    return (
      <Locator id="DeliveryConfirmationDialog">
        <ParcelPageCardInner>
          <h2 className={styles.header}>
            {deliveryConfirmationMode === DeliveryConfirmationModeType.YES_NO ? (
              <FormattedMessage
                defaultMessage="Have you received this order?"
                description="Do you receive header, YES and NO buttons are shown"
              />
            ) : (
              <FormattedMessage
                defaultMessage="Confirm delivery"
                description="Do you receive header, only YES button is shown"
              />
            )}
          </h2>
          {yesError ? (
            <div className={styles.error}>
              {typeof error === 'string' ? (
                yesError
              ) : (
                <ErrorMessage error={yesError} internal noRequestId />
              )}
            </div>
          ) : null}
          <div className={styles.buttons}>
            {deliveryConfirmationMode === DeliveryConfirmationModeType.YES_NO && (
              <div className={styles.button}>
                <Locator id="HaveYouNotReceivedButton">
                  <Button color="darkblue" padding="none" variant="outline" onClick={onNo} block>
                    <div className={styles.buttonText}>
                      <FormattedMessage
                        defaultMessage="No"
                        description="Do you receive, NO button text"
                      />
                    </div>
                  </Button>
                </Locator>
              </div>
            )}
            <div className={styles.button}>
              <Locator id="DoYouReceiveButton">
                <Button loading={yesLoading} color="darkblue" padding="none" onClick={onYes} block>
                  <div className={styles.buttonText}>
                    <FormattedMessage
                      defaultMessage="Yes"
                      description="Do you receive, YES button text"
                    />
                  </div>
                </Button>
              </Locator>
            </div>
          </div>
        </ParcelPageCardInner>
      </Locator>
    );
  },
);

DoYouReceive.propTypes = {
  deliveryConfirmationMode: DeliveryConfirmationMode,
  onNo: PropTypes.func.isRequired,
  onYes: PropTypes.func.isRequired,
  yesLoading: PropTypes.bool.isRequired,
  yesError: ErrorShape,
};

DoYouReceive.defaultProps = {
  deliveryConfirmationMode: null,
  yesError: null,
};
