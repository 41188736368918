import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {animate, cancelAnimation} from 'utils/animation';
import throttle from 'utils/throttle';
import styles from './index.scss';

export default class HorizontalPane extends Component {
  static propTypes = {
    className: PropTypes.string,
    fade: PropTypes.bool,
    children: PropTypes.node.isRequired,
    prev: PropTypes.node.isRequired,
    next: PropTypes.node.isRequired,
    delayedOverflowUpdate: PropTypes.bool,
    shiftPercent: PropTypes.number,
    onSlide: PropTypes.func,
    itemsPerPage: PropTypes.number,
    snapToItems: PropTypes.bool,
  };

  static defaultProps = {
    className: '',
    fade: false,
    delayedOverflowUpdate: false,
    shiftPercent: 0.8,
    onSlide: null,
    itemsPerPage: 0,
    snapToItems: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      overflowLeft: false,
      overflowRight: false,
    };

    this.animationId = null;
    this.scrollable = null;
    this.scrollFrom = null;
    this.scrollStopTimer = null;

    this.handlePrev = this.handlePrev.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handleResize = throttle(this.handleResize.bind(this));
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    if (__CLIENT__) {
      global.addEventListener('resize', this.handleResize);
      global.addEventListener('mouseup', this.handleMouseUp);

      if (this.props.delayedOverflowUpdate) {
        setTimeout(this.updateOverflows.bind(this), 0);
      } else {
        this.updateOverflows();
      }
    }
  }

  componentWillUnmount() {
    if (__CLIENT__) {
      global.removeEventListener('resize', this.handleResize);
      global.removeEventListener('mouseup', this.handleMouseUp);
      cancelAnimation(this.animationId);
      clearTimeout(this.scrollStopTimer);
    }
  }

  handleScrollStop = () => {
    if (this.props.onSlide) {
      this.props.onSlide();
    }
    this.snap();
  };

  updateOverflows() {
    const {scrollable} = this;
    if (!scrollable) {
      return;
    }

    const overflowLeft = scrollable.scrollLeft > 0;
    const overflowRight =
      scrollable.scrollWidth > scrollable.offsetWidth + scrollable.scrollLeft + 1;

    if (this.state.overflowLeft !== overflowLeft || this.state.overflowRight !== overflowRight) {
      this.setState({overflowLeft, overflowRight});
    }
  }

  slide(percent) {
    cancelAnimation(this.animationId);
    this.scrollFrom = this.scrollable.scrollLeft;
    this.animationId = animate((progress) => {
      const {scrollable} = this;
      scrollable.scrollLeft = this.scrollFrom + scrollable.offsetWidth * percent * progress;
      this.updateOverflows();
    });
  }

  snap() {
    const {snapToItems, itemsPerPage} = this.props;

    if (!snapToItems || !itemsPerPage) {
      return;
    }

    cancelAnimation(this.animationId);
    this.scrollFrom = this.scrollable.scrollLeft;
    const {scrollable} = this;
    const stepWidth = scrollable.getBoundingClientRect().width / itemsPerPage;
    const hiddenPart = this.scrollFrom % stepWidth;
    const shift = hiddenPart < stepWidth / 2 ? -hiddenPart : stepWidth - hiddenPart;
    this.animationId = animate((progress) => {
      scrollable.scrollLeft = Math.round(this.scrollFrom + shift * progress);
      this.updateOverflows();
    }, 200);
  }

  handlePrev() {
    this.slide(-this.props.shiftPercent);
  }

  handleNext() {
    this.slide(this.props.shiftPercent);
  }

  handleResize() {
    cancelAnimation(this.animationId);
    this.updateOverflows();
    this.snap();
  }

  handleScroll() {
    this.updateOverflows();

    clearTimeout(this.scrollStopTimer);
    this.scrollStopTimer = setTimeout(this.handleScrollStop, 100);
  }

  render() {
    const {children, className, prev, next, fade} = this.props;
    const {overflowLeft, overflowRight} = this.state;

    const classNames = [
      styles.pane,
      className,
      fade && overflowLeft ? styles.fadeLeft : '',
      fade && overflowRight ? styles.fadeRight : '',
    ];

    return (
      <div className={classNames.join(' ')}>
        <div className={styles.reducer}>
          <div
            ref={(scrollable) => {
              this.scrollable = scrollable;
            }}
            className={styles.inner}
            onScroll={this.handleScroll}
          >
            <div className={styles.content}>{children}</div>
          </div>
        </div>
        {overflowLeft ? (
          <span role="button" tabIndex={-1} onClick={this.handlePrev}>
            {prev}
          </span>
        ) : null}
        {overflowRight ? (
          <span role="button" tabIndex={-1} onClick={this.handleNext}>
            {next}
          </span>
        ) : null}
      </div>
    );
  }
}
