import classnames from 'classnames/bind';
import {deprecated} from 'utils/propTypes/deprecated';
import PropTypes from 'prop-types';
import React from 'react';
import {Icon} from 'components/UIKit/Icon';
import styles from './Item.scss';

const cn = classnames.bind(styles);

function WhiteListItem(props) {
  const {withArrow, children, className, noSeparator, ...elementProps} = props;
  const TagName = elementProps.href ? 'a' : 'div';
  const isClickable = Boolean(elementProps.onClick || elementProps.href || elementProps.to);

  if (!elementProps.href) {
    delete elementProps.href;
  }

  return (
    <TagName {...elementProps} className={`${cn('item', {isClickable, noSeparator})} ${className}`}>
      <div className={cn('content')}>{children}</div>
      {withArrow ? (
        <span className={cn('arrow')}>
          <Icon
            type="mono"
            name="chevron-right-no-insets"
            width="16px"
            height="24px"
            className={cn('arrow')}
          />
        </span>
      ) : null}
    </TagName>
  );
}

WhiteListItem.propTypes = {
  withArrow: PropTypes.bool,
  noSeparator: PropTypes.bool,
  hidden: PropTypes.bool,
  href: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
  className: deprecated(PropTypes.string, 'Use wrapper or add new prop.'),
};

WhiteListItem.defaultProps = {
  children: null,
  className: '',
  hidden: false,
  href: '',
  onClick: null,
  noSeparator: false,
  withArrow: false,
};

export default WhiteListItem;
