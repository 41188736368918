import React, {ReactElement} from 'react';
import {Link} from 'react-router-dom';
import {ImageLink} from 'types/PageMetadata/ImageLink';

import styles from './index.scss';

type Item = string | ImageLink;

type Props = {
  images: Item[];
};

function Image({src}: {src: string}): ReactElement {
  return <img alt="" className={styles.imagesItem} src={src} />;
}

function renderItem(image: Item): ReactElement {
  if (typeof image === 'string') {
    return <Image key={image} src={image} />;
  }

  if (image.href && image.href[0] === '/') {
    return (
      <Link key={image.image} to={image.href}>
        <Image src={image.image} />
      </Link>
    );
  }

  return (
    <a key={image.image} href={image.href}>
      <Image src={image.image} />
    </a>
  );
}

export const Images = React.memo(function Images({images}: Props) {
  return <p className={styles.images}>{images.map((image) => renderItem(image))}</p>;
});
