import cn from 'classnames';
import {Reducer} from 'components/Layout/Reducer';
import React from 'react';

import {Column} from './Column';
import {useStickyEnabled} from './hooks';
import styles from './index.scss';
import {StickyEnabledContext} from './StickyEnabledContext';

type Props = {
  children: React.ReactNode;
  enabledFrom?: string;
  noMarginBottom?: boolean;
  noPadding?: boolean;
  fullWidth?: boolean;
  mobileFullWidthOnly?: boolean;
};

export function StickyColumns({
  enabledFrom,
  children,
  noMarginBottom,
  noPadding,
  fullWidth,
  mobileFullWidthOnly,
}: Props): React.ReactElement {
  const enabled = useStickyEnabled(enabledFrom);

  return (
    <Reducer
      center
      noMarginBottom={noMarginBottom}
      noPadding={noPadding}
      fullWidth={fullWidth}
      mobileFullWidthOnly={mobileFullWidthOnly}
    >
      <div
        className={cn(
          styles.row,
          fullWidth && styles.fullWidth,
          mobileFullWidthOnly && styles.mobileFullWidthOnly,
        )}
      >
        <StickyEnabledContext.Provider value={enabled}>{children}</StickyEnabledContext.Provider>
      </div>
    </Reducer>
  );
}

StickyColumns.Column = Column;
