import {CommonProduct} from 'types/CommonProduct';
import {createAction} from 'typesafe-actions';

export type UpdateProductCollectionSelectionPayload = {
  product: CommonProduct;
  selected: boolean;
};

export const updateProductCollectionSelection = createAction(
  '@productCollectionSelection/UPDATE',
)<UpdateProductCollectionSelectionPayload>();

export const startProductCollectionSelection = createAction(
  '@productCollectionSelection/START',
)<never>();

export const clearProductCollectionSelection = createAction(
  '@productCollectionSelection/CLEAR',
)<never>();
