import {Locator} from 'components/Locator';
import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {ConsolidationBadgeShape} from 'shapes/ConsolidationBadge';
import {ParcelStatusAppearanceShape} from 'shapes/ParcelStatusAppearance';
import classnames from 'classnames/bind';
import memoize from 'utils/memoize';
import {convertBackendColorToCSSValue} from 'utils/styles/color';
import {ShippingEstimateShape} from 'shapes/ShippingEstimate';
import {ConsolidationHeaderText} from './ConsolidationHeaderText';
import styles from './consolidationHeader.scss';

const cn = classnames.bind(styles);

const getBadgeInlineStyles = memoize((backgroundColor, color) => ({
  ...(color && {
    color: convertBackendColorToCSSValue(color),
  }),
  ...(backgroundColor && {
    backgroundColor: convertBackendColorToCSSValue(backgroundColor),
  }),
}));

export const ConsolidationHeader = memo(
  ({consolidationBadge, deliveredTimeMs, shippingEstimate, statusAppearance}) => {
    const {badgeBackgroundColor, badgeTextColor} = statusAppearance;
    const consolidationBadgeTitle = consolidationBadge && consolidationBadge.title;

    return (
      <Locator id="consolidationHeader">
        <div className={styles.header}>
          <div className={styles.headerText}>
            <ConsolidationHeaderText
              statusAppearanceText={statusAppearance.text}
              deliveredTimeMs={deliveredTimeMs}
              shippingEstimate={shippingEstimate}
            />
          </div>
          {Boolean(consolidationBadgeTitle) && (
            <Locator id="consolidationHeader-badge">
              <div className={cn('badge', 'consolidationBadge')}>{consolidationBadgeTitle}</div>
            </Locator>
          )}
          {statusAppearance.title && (
            <Locator id="consolidationHeader-statusBadge">
              <div
                className={cn('badge', 'statusBadge')}
                style={getBadgeInlineStyles(badgeBackgroundColor, badgeTextColor)}
              >
                {statusAppearance.title}
              </div>
            </Locator>
          )}
        </div>
      </Locator>
    );
  },
);

ConsolidationHeader.propTypes = {
  consolidationBadge: ConsolidationBadgeShape,
  deliveredTimeMs: PropTypes.number,
  shippingEstimate: ShippingEstimateShape,
  statusAppearance: ParcelStatusAppearanceShape.isRequired,
};

ConsolidationHeader.defaultProps = {
  consolidationBadge: null,
  deliveredTimeMs: null,
  shippingEstimate: null,
};
