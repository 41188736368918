import classnames from 'classnames/bind';
import {Locator} from 'components/Locator';
import mousetrap from 'mousetrap';
import React, {Component} from 'react';
import {clamp} from 'utils/math';

import styles from './index.scss';
import {Item as ItemComponent} from './Item';

const cn = classnames.bind(styles);

const BODY_CLICK_DELAY = 400;

type Mousetrap = ReturnType<typeof mousetrap>;
type Position = [number, number];
type Props<T> = {
  children: (activeItem: T | undefined, items: T[]) => JSX.Element;
  className?: string;
  input?: HTMLInputElement;
  onClose?: () => void;
  onComplete?: (item: T) => void;
  onSelect: (item: T) => void;
  items: T[];
  uid: string;
  'data-testid'?: string;
};

type State = {
  position: Position;
};

export class Suggest<T> extends Component<Props<T>, State> {
  static Item = ItemComponent;

  static defaultProps = {
    input: null,
    onClose: null,
    onComplete: null,
  };

  private mousetrap: Mousetrap | null = null;

  private bodyHandlerTimer?: NodeJS.Timeout;

  private parent: HTMLDivElement | null = null;

  private parentClick = false;

  constructor(props: Props<T>) {
    super(props);

    this.handleBodyClick = this.handleBodyClick.bind(this);
    this.handleDown = this.handleDown.bind(this);
    this.handleEnter = this.handleEnter.bind(this);
    this.handleEsc = this.handleEsc.bind(this);
    this.handleLeft = this.handleLeft.bind(this);
    this.handleParentClick = this.handleParentClick.bind(this);
    this.handleRight = this.handleRight.bind(this);
    this.handleTab = this.handleTab.bind(this);
    this.handleUp = this.handleUp.bind(this);
    this.setParentElement = this.setParentElement.bind(this);

    this.state = {
      position: [-1, 0],
    };
  }

  override componentDidMount(): void {
    if (__CLIENT__) {
      const {input} = this.props;

      if (input) {
        this.listenInput(input);
      }

      this.bodyHandlerTimer = setTimeout(
        () => global.addEventListener('click', this.handleBodyClick),
        BODY_CLICK_DELAY,
      );
      if (this.parent) {
        this.parent.addEventListener('click', this.handleParentClick);
      }
    }
  }

  override componentDidUpdate(prevProps: Props<T>): void {
    const {input, items} = this.props;

    if (prevProps.items !== items) {
      this.dropProsition();
    }
    if (prevProps.input !== input) {
      this.listenInput(prevProps.input);
    }
  }

  override componentWillUnmount(): void {
    if (this.mousetrap) {
      this.unlistenInput();
    }
    if (__CLIENT__) {
      clearTimeout(this.bodyHandlerTimer);
      global.removeEventListener('click', this.handleBodyClick);
      if (this.parent) {
        this.parent.removeEventListener('click', this.handleParentClick);
      }
    }
  }

  private handleBodyClick(evt: MouseEvent) {
    const {input, onClose} = this.props;

    if (this.parentClick || evt.target === input) {
      this.parentClick = false;
      return;
    }

    onClose?.();
  }

  private handleParentClick() {
    this.parentClick = true;
  }

  private handleLeft(evt: KeyboardEvent) {
    this.movePosition(evt, [0, -1]);
  }

  private handleRight(evt: KeyboardEvent) {
    this.movePosition(evt, [0, 1]);
  }

  private handleUp(evt: KeyboardEvent) {
    this.movePosition(evt, [-1, 0]);
  }

  private handleDown(evt: KeyboardEvent) {
    this.movePosition(evt, [1, 0]);
  }

  private handleTab(evt: KeyboardEvent) {
    const {onComplete} = this.props;
    const item = this.getActiveItem();

    if (item) {
      evt.preventDefault();

      if (onComplete) {
        onComplete(item);
      }
    }
  }

  private handleEnter(evt: KeyboardEvent) {
    const {onSelect} = this.props;
    const item = this.getActiveItem();

    if (item) {
      evt.preventDefault();
      onSelect(item);
    }
  }

  private handleEsc() {
    const {onClose} = this.props;

    if (onClose) {
      onClose();
    }
  }

  private getActiveItem(): T {
    const {items} = this.props;
    const {position} = this.state;
    const [top, left] = position;
    const row = items[top];

    return Array.isArray(row) ? row[left] : row;
  }

  private setParentElement(element: HTMLDivElement): void {
    this.parent = element;
  }

  private dropProsition(): void {
    this.setState({
      position: [-1, 0],
    });
  }

  private unlistenInput(): void {
    if (this.mousetrap) {
      this.mousetrap.reset();
      this.mousetrap = null;
    }
  }

  private listenInput(input?: HTMLInputElement) {
    this.unlistenInput();
    if (input) {
      this.mousetrap = mousetrap(input);
      this.mousetrap.bind('down', this.handleDown);
      this.mousetrap.bind('enter', this.handleEnter);
      this.mousetrap.bind('esc', this.handleEsc);
      this.mousetrap.bind('left', this.handleLeft);
      this.mousetrap.bind('right', this.handleRight);
      this.mousetrap.bind('tab', this.handleTab);
      this.mousetrap.bind('up', this.handleUp);
    }
  }

  private movePosition(evt: KeyboardEvent, [diffTop, diffLeft]: Position) {
    const {items} = this.props;
    const {position} = this.state;
    const [top, left] = position;
    const newTop = clamp(top + diffTop, -1, items.length - 1);
    const maybeRow = items[newTop];
    const newLeft = Array.isArray(maybeRow) ? clamp(left + diffLeft, 0, maybeRow.length - 1) : 0;

    if (top !== newTop || left !== newLeft) {
      evt.preventDefault();
      this.setState({position: [newTop, newLeft]});
    }
  }

  override render(): JSX.Element {
    const {children, className, items, uid, 'data-testid': testId} = this.props;

    return (
      <Locator id={testId ?? 'Suggest'}>
        <div className={cn(styles.suggest, className)} id={uid} ref={this.setParentElement}>
          {children(this.getActiveItem(), items)}
        </div>
      </Locator>
    );
  }
}
