import {Locator} from 'components/Locator';
import React, {ReactElement, useCallback} from 'react';
import {defineMessages, useIntl} from 'react-intl';

import styles from './Close.scss';

const messages = defineMessages({
  label: {
    description: '[label] Кнопка закрытия попапа',
    defaultMessage: 'Close',
  },
});

export const Close = ({
  standalone,
  onClose,
}: {
  standalone?: boolean;
  onClose: () => void;
}): ReactElement => {
  const intl = useIntl();
  const handleClose = useCallback(() => onClose(), [onClose]);

  return (
    <Locator id="SidebarCloseButton">
      <button
        className={`${styles.close} ${standalone ? styles.standalone : ''}`}
        onClick={handleClose}
        type="button"
        aria-label={intl.formatMessage(messages.label)}
        tabIndex={0}
      />
    </Locator>
  );
};
