import {Copyright} from 'components/Copyright';
import React from 'react';

import styles from './index.scss';

export function FooterBottom(): JSX.Element {
  return (
    <div className={styles.bottom}>
      <Copyright />
    </div>
  );
}
