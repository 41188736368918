import {NoIndex} from 'components/NoIndex';
import {ApiClient} from 'helpers/ApiClient';
import {useApiClient} from 'hooks/useApiClient';
import {useForceUpdate} from 'hooks/useForceUpdate';
import React, {useEffect} from 'react';

function getCopyrightText(client: ApiClient): string {
  // WEB-8692 hardcode IP copyright entity for JMT
  const copyrightEntity = client.device.scope.isJmt ? 'ip' : client.device.getCopyrightEntity();

  let copyrightText;
  switch (copyrightEntity) {
    case 'joom':
      copyrightText = 'SIA Joom (Latvia)';
      break;
    case 'ip':
      copyrightText =
        client.device.getLanguage() === 'ru' ? 'ООО “Интернет Проекты”' : 'Internet Projects LLC';
      break;
    case 'jmt':
      copyrightText = 'JM Technologies DMCC';
      break;
    default:
      copyrightText = '';
  }

  return copyrightText;
}

export function Copyright(): React.ReactElement {
  const client = useApiClient();
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    client.device.on('configChange', forceUpdate);

    return () => {
      client.device.off('configChange', forceUpdate);
    };
  }, [client.device, forceUpdate]);

  const year = new Date().getFullYear();
  return (
    <NoIndex>
      © {year} {getCopyrightText(client)}
    </NoIndex>
  );
}
