import React, {CSSProperties, forwardRef, Ref} from 'react';
import {Link} from 'react-router-dom';
import {Button as ButtonType} from 'types/ContentList/common';
import {convertBackendColorToCSSValue, convertBackendGradientToCSSValue} from 'utils/styles/color';

import {usePrepareUrl} from '../../hooks';
import styles from './index.scss';

type Props = {
  button: ButtonType;
};

export const Button = forwardRef(function Button(
  {button}: Props,
  ref?: Ref<HTMLElement>,
): React.ReactElement {
  const labelStyle: CSSProperties | undefined = button.textColor
    ? {color: convertBackendColorToCSSValue(button.textColor)}
    : undefined;
  const buttonStyle: CSSProperties | undefined = button.backgroundGradient
    ? {background: convertBackendGradientToCSSValue(button.backgroundGradient)}
    : undefined;
  const label = (
    <span className={styles.label} style={labelStyle}>
      {button.text}
    </span>
  );
  const url = usePrepareUrl(button.url || '');

  if (url) {
    return (
      <Link
        className={styles.button}
        ref={ref as Ref<HTMLAnchorElement>}
        style={buttonStyle}
        to={url}
      >
        {label}
      </Link>
    );
  }

  return (
    <button
      className={styles.button}
      ref={ref as Ref<HTMLButtonElement>}
      style={buttonStyle}
      type="button"
    >
      {label}
    </button>
  );
});
