export function formatUserInput(rawText = ''): Array<string> {
  const trimmedText = rawText.trim();

  if (!trimmedText) {
    return [];
  }

  return trimmedText.split(/[\s\uFEFF\xA0]*\n[\n\s\uFEFF\xA0]+/);
}

export function getLettersForTextIcon(text = ''): string {
  const words = text.split(/[ _\-/.,]/);
  words.length = Math.min(words.length, 2);

  return words.map((word) => word.slice(0, 1).toUpperCase()).join('');
}
