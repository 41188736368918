import PropTypes from 'prop-types';
import Coordinates from 'shapes/Coordinates';

export default PropTypes.shape({
  id: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  coordinates: Coordinates.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
});
