import {ImageBundle} from 'types/Image';

// Context Types

export enum FeedbackContextType {
  STORE = 'store',
  PRODUCT = 'product',
  PRODUCT_REVIEW = 'productReview',
  SOCIAL_POST = 'socialPost',
  SOCIAL_POST_COMMENT = 'socialPostComment',
}

export type FeedbackProductContext = {
  [FeedbackContextType.PRODUCT]: {
    id: string;
  };
};

export type FeedbackProductReviewContext = {
  [FeedbackContextType.PRODUCT_REVIEW]: {
    productId: string;
    reviewId: string;
  };
};

export type FeedbackSocialPostContext = {
  [FeedbackContextType.SOCIAL_POST]: {
    id: string;
  };
};

export type FeedbackSocialPostCommentContext = {
  [FeedbackContextType.SOCIAL_POST_COMMENT]: {
    postId: string;
    commentId: string;
  };
};

export type FeedbackStoreContext = {
  [FeedbackContextType.STORE]: {
    id: string;
  };
};

export type FeedbackContext =
  | FeedbackProductContext
  | FeedbackProductReviewContext
  | FeedbackSocialPostContext
  | FeedbackSocialPostCommentContext
  | FeedbackStoreContext;

export const isFeedbackProductReviewContext = (
  context: FeedbackContext,
): context is FeedbackProductReviewContext => {
  return FeedbackContextType.PRODUCT_REVIEW in context;
};

export const isFeedbackSocialPostContext = (
  context: FeedbackContext,
): context is FeedbackSocialPostContext => {
  return FeedbackContextType.SOCIAL_POST in context;
};

export const isFeedbackSocialPostCommentContext = (
  context: FeedbackContext,
): context is FeedbackSocialPostCommentContext => {
  return FeedbackContextType.SOCIAL_POST_COMMENT in context;
};

// Other Common Types

export type FeedbackAppearance = {
  isLast?: boolean;
  canComment?: boolean;
  commentRequired?: boolean;
  canAskResponse?: boolean;
  needContacts?: boolean;
  contactsNotice?: string;
  socialReportReason?: string;
};

export type FeedbackContacts = {
  fullName: string;
  email: string;
};

export type FeedbackStep = {
  id: string;
  parentId?: string;
  title: string;
  description?: string;
  children?: FeedbackStep[];
  appearance?: FeedbackAppearance;
};

// Request/Reponse Types

export type FeedbackLoadRequest = {
  id?: string;
  context: FeedbackContext;
};

export type FeedbackLoadResult = FeedbackStep;

export type FeedbackSendRequest = {
  stepId: string;
  clientUniqueId: string;
  context: FeedbackContext;
  text?: string;
  needResponse?: boolean;
  contacts?: FeedbackContacts;
};

export type FeedbackSendResult = {
  text: string;
  description?: string;
  image?: ImageBundle;
};
