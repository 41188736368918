import PropTypes from 'prop-types';
import React, {memo} from 'react';
import {ParcelPageCardInner} from 'components/ParcelPageCard';
import {FormattedMessage} from 'react-intl';
import Warranty from 'shapes/Warranty';
import {FormattedDate} from 'components/i18n/FormattedDate';
import {Locator} from 'components/Locator';
import {Icon} from 'components/UIKit/Icon';
import {useScope} from 'hooks/useScope';
import {Scope} from 'config';
import {OkButton} from '../OkButton';
import styles from './index.scss';

export const ParcelDuration = memo(({warranty, onConfirm}) => {
  const scope = useScope();

  return (
    <ParcelPageCardInner>
      <Locator id="OrderWarrantyDisclaimerCard">
        <div className={styles.content}>
          {scope.is(Scope.GLOBAL) && (
            <div className={styles.iconWrapper}>
              <Icon type="illustration" name="3d-calendar" />
            </div>
          )}
          <p className={styles.header}>
            <span className={styles.sentence}>
              <FormattedMessage
                defaultMessage={`The warranty period starts in {days, plural,
  one {# day}
  other {# days}
} after placing the order.`}
                description="Parcel duration text"
                values={{days: warranty.duration}}
              />
            </span>
            <FormattedMessage
              defaultMessage="Please let us know if your order does not arrive by {date} and we will issue you a refund!"
              description={`Сообщение о начале гарантийного срока на странице заказа.
            Показывается, когда пользователь нажал кнопку, что еще не получил заказ.`}
              values={{
                date: (
                  <FormattedDate
                    key="date"
                    value={warranty.startTimeMs}
                    day="numeric"
                    year="numeric"
                    month="long"
                  />
                ),
              }}
            />
          </p>
          <Locator id="OkayIllWaitForWarrantyPeriodToCome">
            <OkButton onClick={onConfirm}>
              <FormattedMessage defaultMessage="OK" description="Button on parcel page" />
            </OkButton>
          </Locator>
        </div>
      </Locator>
    </ParcelPageCardInner>
  );
});

ParcelDuration.propTypes = {
  warranty: Warranty.isRequired,
  onConfirm: PropTypes.func.isRequired,
};
