import {DEFAULT_APPEARANCE} from 'helpers/contentList';
import {Filter} from 'types/Search';
import {arrayToObject, compareByItemKey, pushOrUpdate} from 'utils/array';
import {isDefAndNotNull} from 'utils/function';
import {memoizeLastShallowEqual} from 'utils/memoize';
import {assignSet, getValueByPath} from 'utils/object';
import {
  SearchData,
  SearchDataFilter,
  SearchDataSorting,
  SearchRequest,
  SearchRequestFilterItem,
} from 'utils/search/types';
import {shallowDiffers} from 'utils/shallowDiffers';

import {
  buildFilterRequest,
  buildFiltersParams,
  extractFilterValue,
  parseFilterByParams,
  parseFilterValue,
} from './filters';
import {
  getParamsData,
  ParamsData,
  SEARCH_FALLBACK_POSTFIX,
  SEARCH_POSTFIX,
  SEARCH_TAGID_POSTFIX,
} from './pattern';

// FYI: if you want to add new param please check hasTrendingLinks function
export const FULL_ROUTE_POSTFIX = SEARCH_FALLBACK_POSTFIX;

export const SEARCH_ROUTE_WITH_TAGID_POSTFIX = SEARCH_TAGID_POSTFIX;

export const SEARCH_ROUTE_POSTFIX = SEARCH_POSTFIX;

export function buildSearchRequest(
  searchData: SearchData | undefined,
  appearance = DEFAULT_APPEARANCE,
): SearchRequest | null {
  if (!searchData) {
    return null;
  }

  const {
    query,
    filters,
    sorting,
    searchEngine,
    storeId,
    promotionId,
    trendingLinkSlug,
    brandId,
    promotedProduct,
  } = searchData;
  const request: SearchRequest = {};

  if (query) {
    request.query = query;
  }

  if (searchEngine) {
    request.searchEngine = searchEngine;
  }

  if (sorting) {
    request.sorting = [
      {
        fieldName: sorting.fieldName,
        order: sorting.order,
      },
    ];
  }

  if (promotedProduct) {
    request.promotedProductIds = [promotedProduct];
  }

  if (filters && filters.length > 0) {
    const requestFilters: SearchRequestFilterItem[] = [];
    filters.forEach(({id, valueType, value}) => {
      const filterRequest = buildFilterRequest(id, valueType, value);
      if (filterRequest) {
        requestFilters.push(filterRequest);
      }
    });

    if (requestFilters.length > 0) {
      request.filters = requestFilters;
    }
  }

  if (storeId) {
    request.origin = {
      source: 'store',
      storeId,
    };
  }

  if (promotionId) {
    request.origin = {
      source: 'promotion',
      promotionId,
    };
  }

  if (trendingLinkSlug) {
    request.origin = {
      source: 'webLandingPage',
      trendingLinkSlug,
    };
  }

  if (brandId) {
    request.origin = {
      source: 'brand',
      brandId,
    };
  }

  if (appearance) {
    request.appearance = appearance;
  }

  return request;
}

export function getEmptySearchData(): SearchData {
  return {
    filters: [],
    filtersValueDict: {},
    searchEngine: '',
  };
}

export function convertAppliedFilter(
  filter: Filter | SearchRequestFilterItem,
): SearchDataFilter | null {
  const value = extractFilterValue(filter);
  if (value) {
    return {
      id: filter.id,
      valueType: filter.value.type,
      value,
    } as SearchDataFilter;
  }
  return null;
}

export function assignSearchDataFilter(
  searchData: SearchData,
  filter: SearchDataFilter | undefined,
): SearchData {
  if (!searchData || !filter) {
    return searchData;
  }

  const newFilters = pushOrUpdate(searchData.filters, filter, (a, b) => a.id === b.id).filter(
    Boolean,
  );

  if (!newFilters || !newFilters.length || newFilters === searchData.filters) {
    return searchData;
  }

  return {
    ...searchData,
    filters: newFilters,
    filtersValueDict: arrayToObject(
      newFilters,
      ({id}) => id,
      ({value}) => value,
    ),
  };
}

export function assignSearchDataSorting(
  searchData: SearchData,
  sorting: SearchDataSorting | undefined,
): SearchData {
  if (searchData && shallowDiffers(searchData.sorting, sorting)) {
    return assignSet(searchData, 'sorting', sorting);
  }
  return searchData;
}

export function assignSearchDataStoreId(
  searchData: SearchData,
  storeId: string | undefined,
): SearchData {
  if (searchData && storeId) {
    return assignSet(searchData, 'storeId', storeId);
  }
  return searchData;
}

export function assignSearchDataBrandId(
  searchData: SearchData,
  brandId: string | undefined,
): SearchData {
  if (searchData && brandId) {
    return assignSet(searchData, 'brandId', brandId);
  }
  return searchData;
}

export function assignSearchEngine(
  searchData: SearchData,
  searchEngine: string | undefined,
): SearchData {
  if (searchData && searchData.searchEngine !== searchEngine) {
    return assignSet(searchData, 'searchEngine', searchEngine);
  }
  return searchData;
}

export function assignSearchDataQuery(
  searchData: SearchData,
  query: string | undefined,
): SearchData {
  return assignSet(searchData, 'query', query);
}

export function assignSearchDataFiltersByApplied(
  searchData: SearchData,
  appliedFilters: Filter[] | SearchRequestFilterItem[] | undefined,
): SearchData {
  const filters = appliedFilters
    ? appliedFilters.map(convertAppliedFilter).filter(isDefAndNotNull)
    : [];
  return {
    ...searchData,
    filters,
    filtersValueDict: arrayToObject(
      filters,
      ({id}) => id,
      ({value}) => value,
    ),
  };
}

export function assignCategoryId(
  searchData: SearchData,
  categoryId: string | undefined,
): SearchData {
  const filters: SearchDataFilter[] = [];
  const filtersValueDict: Record<string, unknown> = {};

  if (categoryId) {
    filters.push({
      id: 'categoryId',
      valueType: 'categories',
      value: parseFilterValue('categories', categoryId),
    });
  }

  filters.forEach((filter) => {
    filtersValueDict[filter.id] = filter.value;
  });

  return {
    ...searchData,
    filters,
    filtersValueDict,
  };
}

function assignSearchDataFiltersByParams(searchData: SearchData, params: ParamsData) {
  const filters: SearchDataFilter[] = [];
  const filtersValueDict: Record<string, SearchDataFilter['value']> = {};

  if (params) {
    const {categoryId, filterParams, tagId, brandIdFilter} = params;

    if (categoryId) {
      filters.push({
        id: 'categoryId',
        valueType: 'categories',
        value: parseFilterValue('categories', categoryId),
      });
    }

    if (tagId) {
      filters.push({
        id: 'productTags',
        valueType: 'productTags',
        value: parseFilterValue('productTags', tagId),
      });
    }

    if (brandIdFilter) {
      filters.push({
        id: 'brand',
        valueType: 'tree',
        value: parseFilterValue('tree', brandIdFilter),
      });
    }

    if (filterParams) {
      const otherFilters: SearchDataFilter[] = [];
      filterParams.forEach((item) => {
        const filter = parseFilterByParams(item);
        if (filter) {
          otherFilters.push(filter);
        }
      });
      otherFilters.sort((a, b) => compareByItemKey(a, b, 'id'));
      filters.push(...otherFilters);
    }

    filters.forEach((filter) => {
      filtersValueDict[filter.id] = filter.value;
    });
  }

  return {
    ...searchData,
    filters,
    filtersValueDict,
  };
}

export const getSearchData = memoizeLastShallowEqual(
  (
    rawParams: Record<string, string | string[] | boolean | undefined> | undefined,
    searchEngine: string = '',
  ): SearchData => {
    const data = getEmptySearchData();
    const params = getParamsData(rawParams);

    if (searchEngine) {
      data.searchEngine = searchEngine;
    }

    if (!params) {
      return data;
    }

    if (params.sortingField && params.sortingOrder) {
      data.sorting = {
        fieldName: params.sortingField,
        order: params.sortingOrder,
      };
    }

    if (params.storeId) {
      data.storeId = params.storeId;
    }

    if (params.promotionId) {
      data.promotionId = params.promotionId;
    }

    if (params.trendingLinkSlug) {
      data.trendingLinkSlug = params.trendingLinkSlug;
    }

    if (params.brandId) {
      data.brandId = params.brandId;
    }

    if (params.promotedProduct) {
      data.promotedProduct = params.promotedProduct;
    }

    return assignSearchDataQuery(assignSearchDataFiltersByParams(data, params), params.query);
  },
);

export function searchPostfix(searchData: SearchData | undefined): string {
  const {filters, sorting, query} = searchData || getEmptySearchData();
  const categoryFilter = filters.find((item) => item.id === 'categoryId');
  const categoryId = categoryFilter
    ? getValueByPath(categoryFilter, 'value', 'items', 0, 'id')
    : null;

  return [
    buildFiltersParams(filters),
    categoryId ? `c.${encodeURIComponent(categoryId)}` : '',
    sorting
      ? `s.${encodeURIComponent(sorting.fieldName)}.${encodeURIComponent(sorting.order)}`
      : '',
    query ? `q.${encodeURIComponent(query)}` : '',
  ]
    .filter((part) => !!part)
    .join('/');
}

export function buildSearchDataFromRequest(request: SearchRequest | undefined): SearchData | null {
  if (!request) {
    return null;
  }

  const {query, filters, sorting, searchEngine, origin} = request;
  let searchData = getEmptySearchData();

  if (filters && filters.length > 0) {
    searchData = assignSearchDataFiltersByApplied(searchData, filters);
  }

  if (query) {
    searchData.query = query;
  }

  if (searchEngine) {
    searchData.searchEngine = searchEngine;
  }

  if (sorting && sorting[0]) {
    searchData.sorting = {
      fieldName: sorting[0].fieldName,
      order: sorting[0].order,
    };
  }

  if (origin && 'storeId' in origin && origin.storeId) {
    searchData.storeId = origin.storeId;
  }

  if (origin && 'promotionId' in origin && origin.promotionId) {
    searchData.promotionId = origin.promotionId;
  }

  if (origin && 'brandId' in origin && origin.brandId) {
    searchData.brandId = origin.brandId;
  }

  return searchData;
}
