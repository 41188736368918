import {RefObject, useCallback, useEffect, useRef} from 'react';

const DEFAULT_ROOT_MARGIN = '0px';
const DEFAULT_THRESHOLD = 0;

export type Options = {
  targetRef: RefObject<HTMLElement>;
  rootRef?: RefObject<HTMLElement>;
  rootMargin?: IntersectionObserverInit['rootMargin'];
  threshold?: IntersectionObserverInit['threshold'];
  disabled?: boolean;
};

export function useIntersectionObserverRef(
  callback: (entry: IntersectionObserverEntry) => void,
  options: Options,
): void {
  const observerRef = useRef<IntersectionObserver | undefined>();
  const lastTargetRef = useRef<HTMLElement | null>(null);

  const {disabled, rootRef} = options;
  const rootMargin = options.rootMargin ?? DEFAULT_ROOT_MARGIN;
  const threshold = options.threshold ?? DEFAULT_THRESHOLD;

  const root = rootRef?.current || null;
  const {targetRef} = options;

  const handleCallback = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      callback(entries[0]!);
    },
    [callback],
  );

  useEffect(() => {
    if (__SERVER__ || !window.IntersectionObserver || disabled) {
      return undefined;
    }

    const instance = new IntersectionObserver(handleCallback, {
      // at first render root may be undefined
      root: root || rootRef?.current,
      rootMargin,
      threshold,
    });

    observerRef.current = instance;

    return (): void => {
      instance.disconnect();
      observerRef.current = undefined;
      lastTargetRef.current = null;
    };
  }, [root, rootMargin, threshold, handleCallback, disabled, rootRef]);

  useEffect(() => {
    const target = targetRef.current;
    const observer = observerRef.current;
    const lastTarget = lastTargetRef.current;

    if (target !== lastTarget && observer) {
      if (lastTarget) observer.unobserve(lastTarget);

      if (target) observer.observe(target);

      lastTargetRef.current = target;
    }
  });
}
