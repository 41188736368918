import React from 'react';
import {defineMessages, FormattedMessage, FormattedNumber} from 'react-intl';

import {UploaderError, UploaderErrorType} from '../types';

type Props = {
  error: UploaderError;
};

const messages = defineMessages({
  fileTypeNotSupportedError: {
    defaultMessage: 'The selected file format is not supported.',
    description: 'Сообщение об ошибке во время загрузки файлов',
  },
  tooBigFileError: {
    defaultMessage: 'The size of this file exceeds {maxMBytes} MB.',
    description: 'Сообщение об ошибке во время загрузки файлов',
  },
  tooManyFilesError: {
    defaultMessage: `Unable load more than {maxCount, plural,
  one {# file}
  other {# files}
}.`,
    description: 'Сообщение об ошибке во время загрузки файлов',
  },
  tooSmallFileError: {
    defaultMessage: 'The size of this file is less than {minMBytes} MB.',
    description: 'Сообщение об ошибке во время загрузки файлов',
  },
  unknownError: {
    defaultMessage: 'Failed to load file.',
    description: 'Сообщение об ошибке во время загрузки файлов',
  },
});

export function ErrorMessage({error}: Props): JSX.Element | null {
  switch (error.type) {
    case UploaderErrorType.FILE_TYPE_NOT_SUPPORTED: {
      return <FormattedMessage {...messages.fileTypeNotSupportedError} />;
    }

    case UploaderErrorType.TOO_BIG_FILE: {
      const maxMBytes = error.params.maxBytes / 1024 / 1024;

      return (
        <FormattedMessage
          {...messages.tooBigFileError}
          values={{
            maxMBytes: (
              <FormattedNumber
                maximumFractionDigits={2}
                minimumFractionDigits={0}
                value={maxMBytes}
              />
            ),
          }}
        />
      );
    }

    case UploaderErrorType.TOO_MANY_FILES: {
      const {maxCount} = error.params;

      return <FormattedMessage {...messages.tooManyFilesError} values={{maxCount}} />;
    }

    case UploaderErrorType.TOO_SMALL_FILE: {
      const minMBytes = error.params.minBytes / 1024 / 1024;

      return (
        <FormattedMessage
          {...messages.tooSmallFileError}
          values={{
            minMBytes: (
              <FormattedNumber
                maximumFractionDigits={2}
                minimumFractionDigits={0}
                value={minMBytes}
              />
            ),
          }}
        />
      );
    }

    default:
      return <FormattedMessage {...messages.unknownError} />;
  }
}
