import {createRequestAction} from 'store/utils/requestActions';
import {CookiesSettings} from 'types/CookiesSettings';
import {createAction} from 'typesafe-actions';

export const set = createAction('@cookiesSettings/SET')<CookiesSettings>();

export const update = createRequestAction(
  '@cookiesSettings/UPDATE_REQUEST',
  '@cookiesSettings/UPDATE_SUCCESS',
  '@cookiesSettings/UPDATE_FAILURE',
)<Record<string, never>, CookiesSettings>();
