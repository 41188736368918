import {createRequestAction} from 'store/utils/requestActions';
import {Cart, CouponSelection} from 'types/Cart';
import {CartItem} from 'types/CartGroup/CartItem';
import {
  CheckoutSession,
  CheckoutSessionGroup,
  CheckoutSessionOrderGroup,
} from 'types/CheckoutSession';
import {ContentListItem} from 'types/ContentList';
import {Error} from 'types/Error';
import {OpenPayload} from 'types/OpenPayload';
import {ProductPreOffer} from 'types/ProductPreOffer';
import {createAction} from 'typesafe-actions';

// cart

export type CartItemInfo = {
  productId: string;
  productVariantId: string;
  quantity?: number;
  productOpenPayload?: OpenPayload;
  preOfferType?: ProductPreOffer['type'];
};

export const addCartItem = createRequestAction(
  '@groupedCart/ADD_CART_ITEM_REQUEST',
  '@groupedCart/ADD_CART_ITEM_SUCCESS',
  '@groupedCart/ADD_CART_ITEM_FAILURE',
)<CartItemInfo, Cart | null>();

export const removeCartItems = createRequestAction(
  '@groupedCart/REMOVE_CART_ITEMS_REQUEST',
  '@groupedCart/REMOVE_CART_ITEMS_SUCCESS',
  '@groupedCart/REMOVE_CART_ITEMS_FAILURE',
)<CartItem[], Cart | null>();

export const selectCartItems = createRequestAction(
  '@groupedCart/SELECT_CART_ITEMS_REQUEST',
  '@groupedCart/SELECT_CART_ITEMS_SUCCESS',
  '@groupedCart/SELECT_CART_ITEMS_FAILURE',
)<{ids: string[]; select: boolean}, unknown>();

export const changeItemQuantity = createRequestAction(
  '@groupedCart/CHANGE_ITEM_QUANTITY_REQUEST',
  '@groupedCart/CHANGE_ITEM_QUANTITY_SUCCESS',
  '@groupedCart/CHANGE_ITEM_QUANTITY_FAILURE',
)<{item: CartItem; quantity: number}, unknown>();

export const loadCart = createRequestAction(
  '@groupedCart/LOAD_CART_REQUEST',
  '@groupedCart/LOAD_CART_SUCCESS',
  '@groupedCart/LOAD_CART_FAILURE',
)<CouponSelection | null, unknown>();

export const updateCart = createRequestAction(
  '@groupedCart/UPDATE_CART_REQUEST',
  '@groupedCart/UPDATE_CART_SUCCESS',
  '@groupedCart/UPDATE_CART_FAILURE',
)<CouponSelection | null, unknown>();

export const setCart = createAction('@groupedCart/SET_CART')<Cart>();

export const patchCartItems = createAction('@groupedCart/PATCH_CART_ITEMS')<
  Record<string, Partial<CartItem>>
>();

export const setCartError = createAction('@groupedCart/CART_ERROR')<Error>();

// item errors

export type CartItemError = {
  id: string;
  message: string;
  type: string;
};

export const setCartItemError = createAction('@groupedCart/SET_CART_ITEM_ERROR')<CartItemError>();
export const clearCartItemsError = createAction('@groupedCart/CLEAR_CART_ITEMS_ERROR')<
  string[] | undefined
>();
export const clearCartItemError = createAction('@groupedCart/CLEAR_CART_ITEM_ERROR')<string>();

// session

export const createSession = createRequestAction(
  '@groupedCart/CREATE_SESSION_REQUEST',
  '@groupedCart/CREATE_SESSION_SUCCESS',
  '@groupedCart/CREATE_SESSION_FAILURE',
)<undefined, CheckoutSession>();
export const loadSession = createRequestAction(
  '@groupedCart/LOAD_SESSION_REQUEST',
  '@groupedCart/LOAD_SESSION_SUCCESS',
  '@groupedCart/LOAD_SESSION_FAILURE',
)<{sessionId: string}, unknown>();

export const updateSession = createRequestAction(
  '@groupedCart/UPDATE_SESSION_REQUEST',
  '@groupedCart/UPDATE_SESSION_SUCCESS',
  '@groupedCart/UPDATE_SESSION_FAILURE',
)<undefined, unknown>();

export const updateSessionPricing = createRequestAction(
  '@groupedCart/UPDATE_SESSION_PRICING_REQUEST',
  '@groupedCart/UPDATE_SESSION_PRICING_SUCCESS',
  '@groupedCart/UPDATE_SESSION_PRICING_FAILURE',
)<undefined, unknown>();

export const resetSessionPricing = createRequestAction(
  '@groupedCart/RESET_SESSION_PRICING_REQUEST',
  '@groupedCart/RESET_SESSION_PRICING_SUCCESS',
  '@groupedCart/RESET_SESSION_PRICING_FAILURE',
)<undefined, unknown>();

export const loadSessionStep = createRequestAction(
  '@groupedCart/LOAD_SESSION_STEP_REQUEST',
  '@groupedCart/LOAD_SESSION_STEP_SUCCESS',
  '@groupedCart/LOAD_SESSION_STEP_FAILURE',
)<{groupId: string}, unknown>();

export const loadSessionOrderGroup = createRequestAction(
  '@groupedCart/LOAD_SESSION_ORDER_GROUP_REQUEST',
  '@groupedCart/LOAD_SESSION_ORDER_GROUP_SUCCESS',
  '@groupedCart/LOAD_SESSION_ORDER_GROUP_FAILURE',
)<{groupId: string}, unknown>();

export const sessionPay = createRequestAction(
  '@groupedCart/SESSION_PAY_REQUEST',
  '@groupedCart/SESSION_PAY_SUCCESS',
  '@groupedCart/SESSION_PAY_FAILURE',
)<{groupId: string}, unknown>();

export const markDisclaimerAsSeen = createAction('@groupedCart/MARK_DISCLAIMER_AS_SEEN')<string>();

export const setSession = createAction('@groupedCart/SET_SESSION')<CheckoutSession>();

export const setSessionPricing = createAction(
  '@groupedCart/SET_SESSION_PRICING',
)<CheckoutSession>();

export const setSessionOrderGroup = createAction('@groupedCart/SET_SESSION_ORDER_GROUP')<{
  group: CheckoutSessionGroup;
  orderGroup: CheckoutSessionOrderGroup;
}>();

export const setSessionError = createAction('@groupedCart/SET_SESSION_ERROR')<Error>();

export const loadSessionCompletionItems = createRequestAction(
  '@groupedCart/LOAD_SESSION_COMPLETION_ITEMS_REQUEST',
  '@groupedCart/LOAD_SESSION_COMPLETION_ITEMS_SUCCESS',
  '@groupedCart/LOAD_SESSION_COMPLETION_ITEMS_FAILURE',
)<{sessionId: string}, unknown>();

export const setSessionCompletionItems = createAction('@groupedCart/SET_SESSION_COMPLETION_ITEMS')<
  ContentListItem[]
>();

// payment methods

export const removePaymentMethod = createRequestAction(
  '@groupedCart/REMOVE_PAYMENT_METHOD_REQUEST',
  '@groupedCart/REMOVE_PAYMENT_METHOD_SUCCESS',
  '@groupedCart/REMOVE_PAYMENT_METHOD_FAILURE',
)<{paymentMethodId: string}, unknown>();
