import {defineMessages, MessageDescriptor} from 'react-intl';
import {Expandable} from 'types/utility';

import {isDefAndNotNull} from './function';
import {getPhoneCountryCode, removeInputPhoneCode} from './phone';

type ExtendedMessageDescriptor = MessageDescriptor & {values?: Record<string, string | number>};

export type ValidatorError = ExtendedMessageDescriptor;
export type Validator<T extends string> = (value: T, data?: unknown) => ValidatorError | undefined;

const EMAIL_RE =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const ZIP_RE = /^\d+((\s|-)?\d+)+$/;

export const validationErrors = defineMessages({
  email: {
    description: 'Input validator',
    defaultMessage: 'Invalid email address',
  },
  phone: {
    description: 'Input validator',
    defaultMessage: 'Invalid phone format',
  },
  phoneCountry: {
    description: 'Input validator',
    defaultMessage: 'Cannot identify country by phone number',
  },
  phoneRussia: {
    description: 'Input validator',
    defaultMessage: 'Use the format +7-XXX-XXX-XX-XX for Russian numbers',
  },
  required: {
    description: 'Input validator',
    defaultMessage: 'Required field',
  },
  doNotMatch: {
    description: 'Input validator',
    defaultMessage: 'Does not match',
  },
  minLength: {
    description: 'Input validator',
    defaultMessage: `Must be at least {min, plural,
      one {# character}
      other {# characters}
    }`,
  },
  maxLength: {
    description: 'Input validator',
    defaultMessage: `Must be no more than {max, plural,
      one {# character}
      other {# characters}
    }`,
  },
  exactLength: {
    description: 'Input validator',
    defaultMessage: `{length, plural,
      one {Must be exactly # character long}
      other {Must be exactly # characters long}
    }`,
  },
  integer: {
    description: 'Input validator',
    defaultMessage: 'Must be a number',
  },
  zip: {
    description: 'Input validator',
    defaultMessage: 'Invalid ZIP code/postcode',
  },
  iban: {
    description: 'Input validator',
    defaultMessage: 'Invalid IBAN',
  },
});

export const isEmpty = (value: unknown): boolean =>
  value === undefined || value === null || value === '';

export const join =
  <T extends string>(rules: Validator<T>[]): Validator<T> =>
  (value, data) =>
    rules.map((rule) => rule(value, data)).filter((error) => !!error)[0 /* first error */];

export function email(value: string): ReturnType<Validator<string>> {
  if (isEmpty(value)) {
    return undefined;
  }

  if (!EMAIL_RE.test(value)) {
    return validationErrors.email;
  }

  return undefined;
}

export function zip(value: string): ReturnType<Validator<string>> {
  if (!isEmpty(value) && !ZIP_RE.test(value)) {
    return validationErrors.zip;
  }
  return undefined;
}

export function required<T>(value: T): ReturnType<Validator<string>> {
  if (isEmpty(value)) {
    return validationErrors.required;
  }
  return undefined;
}

export function customRequired<T extends ValidatorError>(
  message: T,
): (value: unknown) => T | undefined {
  return (value) => (isEmpty(value) ? message : undefined);
}

export function minLength(min: number): Validator<string> {
  return (value) => {
    if (!isEmpty(value) && value.length <= min) {
      return {
        ...validationErrors.minLength,
        values: {min},
      };
    }
    return undefined;
  };
}

export function maxLength(max: number): Validator<string> {
  return (value) => {
    if (!isEmpty(value) && value.length >= max) {
      return {
        ...validationErrors.maxLength,
        values: {max},
      };
    }
    return undefined;
  };
}

export function exactLength(length: number, error?: ValidatorError): Validator<string> {
  return (value) => {
    if (!isEmpty(value) && value.length !== length) {
      return (
        error ?? {
          ...validationErrors.exactLength,
          values: {length},
        }
      );
    }
    return undefined;
  };
}

export function integer(value: string): ReturnType<Validator<string>> {
  if (!Number.isInteger(Number(value))) {
    return validationErrors.integer;
  }
  return undefined;
}

export function oneOf<T>(enumeration: T[]): (value: T) => string | undefined {
  return (value: T): string | undefined => {
    if (!enumeration.indexOf(value)) {
      return `Must be one of: ${enumeration.join(', ')}`;
    }
    return undefined;
  };
}

export function match(field: string) {
  return <T extends string>(value: T, data: {[x: string]: T}): ReturnType<Validator<T>> => {
    if (data) {
      if (value !== data[field]) {
        return validationErrors.doNotMatch;
      }
    }
    return undefined;
  };
}

export function requiredCheckbox(value: boolean): ValidatorError | undefined {
  if (value !== true) {
    return validationErrors.required;
  }

  return undefined;
}

export function requiredPhone(value: string): ValidatorError | undefined {
  const code = getPhoneCountryCode(value);
  const number = removeInputPhoneCode(value, code);

  if (isEmpty(number)) {
    return validationErrors.required;
  }

  return undefined;
}

function mod97(input: string) {
  let checksum: string | number = input.slice(0, 2);
  let fragment = '';

  for (let offset = 2; offset < input.length; offset += 7) {
    fragment = checksum + input.substring(offset, offset + 7);
    checksum = parseInt(fragment, 10) % 97;
  }
  return checksum;
}

export function iban({skipCountryCheck}: {skipCountryCheck?: boolean}): Validator<string> {
  return (input: string) => {
    const codeLengths = {
      AD: 24,
      AE: 23,
      AL: 28,
      AT: 20,
      AZ: 28,
      BA: 20,
      BE: 16,
      BG: 22,
      BH: 22,
      BI: 28,
      BR: 29,
      BY: 28,
      CH: 21,
      CR: 22,
      CY: 28,
      CZ: 24,
      DE: 22,
      DK: 18,
      DO: 28,
      EE: 20,
      EG: 29,
      ES: 24,
      LC: 32,
      FI: 18,
      FO: 18,
      FR: 27,
      GB: 22,
      GE: 22,
      GI: 23,
      GL: 18,
      GR: 27,
      GT: 28,
      HR: 21,
      HU: 28,
      IE: 22,
      IL: 23,
      IQ: 23,
      IS: 26,
      IT: 27,
      JO: 30,
      KW: 30,
      KZ: 20,
      LB: 28,
      LI: 21,
      LT: 20,
      LU: 20,
      LV: 21,
      LY: 25,
      MC: 27,
      MD: 24,
      ME: 22,
      MK: 19,
      MR: 27,
      MT: 31,
      MU: 30,
      NL: 18,
      NO: 15,
      PK: 24,
      PL: 28,
      PS: 29,
      PT: 25,
      QA: 29,
      RO: 24,
      RS: 22,
      SA: 24,
      SC: 31,
      SD: 18,
      SE: 24,
      SI: 19,
      SK: 24,
      SM: 27,
      ST: 25,
      SV: 28,
      TL: 23,
      TN: 24,
      TR: 26,
      UA: 29,
      VA: 22,
      VG: 24,
      XK: 20,
    };

    const iban = input.toUpperCase().replace(/[^A-Z0-9]/g, '');

    const code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/) as
      | [string, string, string, string]
      | undefined;

    if (
      !code ||
      (!skipCountryCheck &&
        iban.length !== (codeLengths as Expandable<typeof codeLengths>)[code[1]])
    ) {
      return validationErrors.iban;
    }

    const digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, (letter: string) => {
      return (letter.charCodeAt(0) - 55).toString();
    });

    return mod97(digits) === 1 ? undefined : validationErrors.iban;
  };
}

export function createCombinedValidator<T extends string>(
  validators: Validator<T>[],
): (value: T) => ExtendedMessageDescriptor[] {
  return (value: T) => validators.map((validator) => validator(value)).filter(isDefAndNotNull);
}
