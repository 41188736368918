import {Category, SubCategory} from './types';

function getCategoriesForAnalytics(
  category: SubCategory | Category[],
  initValue: string[] = [],
): string[] {
  return category.reduce<string[]>((result, {id, children}) => {
    if (children && children.length) {
      // eslint-disable-next-line no-param-reassign
      result = [...result, id, ...children.map(({id: childId}) => childId)];
    } else {
      result.push(id);
    }

    return result;
  }, initValue);
}

export {getCategoriesForAnalytics};
