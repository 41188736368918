import {patchProductLite} from 'helpers/productPatch/patchProductLite';
import {useMemo} from 'react';
import {ProductLite} from 'types/ProductLite';

import {useProductPatch} from './useProductPatch';

export const usePatchedProductLite = (productLite: ProductLite): ProductLite => {
  const patch = useProductPatch(productLite.loadedTimeMs || 0, productLite.patch);
  return useMemo(() => {
    return patch ? patchProductLite(productLite, patch) : productLite;
  }, [patch, productLite]);
};
