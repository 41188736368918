import classnames from 'classnames/bind';
import HtmlContent from 'components/HtmlContent';
import {useScope} from 'hooks/useScope';
import React from 'react';
import {ContentItem} from 'types/Popup';
import {getColorStyles} from 'utils/styles/color';

import styles from '../index.scss';
import {attributeMapperWithAdditionalParameters} from '../utils';

const cn = classnames.bind(styles);

type HtmlContentProps = React.ComponentProps<typeof HtmlContent>;
type Props = {
  onLinkClick: NonNullable<HtmlContentProps['onClick']>;
  subtitle: NonNullable<ContentItem['subtitle']>;
};

export const Subtitle = ({onLinkClick, subtitle}: Props): JSX.Element => {
  const scope = useScope();
  const horizontalAlignment = subtitle.horizontalAlignment?.toString() || '';

  return (
    <div className={cn('titleContainer', {[horizontalAlignment]: Boolean(horizontalAlignment)})}>
      <HtmlContent
        className={styles.subtitle}
        style={getColorStyles(subtitle.textColor)}
        html={subtitle.text}
        attributeMapper={attributeMapperWithAdditionalParameters(scope, {
          a: {style: getColorStyles(subtitle.linkColor)},
        })}
        onClick={onLinkClick}
      />
    </div>
  );
};
