import {ECSLog, ECSLogger} from 'helpers/log/ECS/types';
import {useApiClient} from 'hooks/useApiClient';

export function useLog(): ECSLog {
  const apiClient = useApiClient();
  return apiClient.device.log;
}

export function useLogger(name: string): ECSLogger {
  const log = useLog();
  return log.getLogger(name);
}
