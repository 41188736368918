import {castDraft, produce} from 'immer';
import {Error} from 'types/Error';
import {isShallowDifferableObject, shallowDiffers} from 'utils/shallowDiffers';

export type SimpleState<TRequest, TData> = {
  readonly data: TData | null;
  readonly error: Error | null;
  readonly loading: boolean;
  readonly request: TRequest | null;
  readonly loaded: boolean;
};

export type SimpleStateRequest<T> = T extends SimpleState<infer U, unknown> ? U : never;

export type SimpleStateData<T> = T extends SimpleState<unknown, infer U> ? U : never;

export function getInitialState<TRequest, TData>(): SimpleState<TRequest, TData> {
  return {
    data: null,
    error: null,
    loaded: false,
    loading: false,
    request: null,
  };
}

export function handleRequest<TRequest, TData>(
  state: SimpleState<TRequest, TData>,
  request: TRequest,
  keepData?: boolean,
): SimpleState<TRequest, TData> {
  return produce(state, (draft) => {
    draft.request = castDraft(request);
    if (!keepData) {
      draft.data = null;
      draft.loaded = false;
    }
    draft.error = null;
    draft.loading = true;
  });
}

function isSameRequests(left: unknown, right: unknown): boolean {
  if (isShallowDifferableObject(left) && isShallowDifferableObject(right)) {
    return !shallowDiffers(left, right);
  }
  return left === right;
}

export function handleSuccess<TRequest, TData>(
  state: SimpleState<TRequest, TData>,
  request: TRequest,
  data: TData,
): SimpleState<TRequest, TData> {
  if (isSameRequests(state.request, request)) {
    return produce(state, (draft) => {
      draft.data = castDraft(data);
      draft.loading = false;
      draft.loaded = true;
    });
  }
  return state;
}

export function handleFailure<TRequest, TData>(
  state: SimpleState<TRequest, TData>,
  request: TRequest,
  error: Error | null,
): SimpleState<TRequest, TData> {
  if (isSameRequests(state.request, request)) {
    return produce(state, (draft) => {
      draft.error = error;
      draft.loading = false;
    });
  }
  return state;
}
