import classnames from 'classnames';
import {BannerWrapper} from 'components/ContentList/BannerWrapper';
import {Image} from 'components/Image';
import {Locator} from 'components/Locator';
import {Icon} from 'components/UIKit/Icon';
import {Timer} from 'components/UIKit/Timer';
import {useTimer} from 'hooks/useTimer';
import React, {useRef} from 'react';
import {CountdownRowV2 as CountdownRowV2Type} from 'types/ContentList/CountdownRowV2';
import {getInlineColor} from 'utils/styles/color';

import {Background} from '../Background';
import {useBannerPreview} from '../hooks';
import {CompleteMessage} from '../TimerCompleteMessage';
import styles from './index.scss';

const BANNER_THRESHOLD = 0.9;

type Props = {
  content: CountdownRowV2Type;
  id: string;
  loadedTimeMs?: number;
  source?: string;
  withExtraTopMargin?: boolean;
};

export function CountdownRowV2({
  content: {
    background,
    image,
    subtitle,
    timerBackgroundColor,
    timerColor,
    timeRemainingMs,
    title,
    deeplink,
    timerMaxDuration,
    eventParams,
  },
  id,
  loadedTimeMs,
  withExtraTopMargin,
  source,
}: Props): React.ReactElement {
  const contentRef = useRef(null);
  const clickRef = useRef(null);

  useBannerPreview({
    rootRef: contentRef,
    bannerId: id,
    clickRefs: [clickRef],
    visibilityThreshold: BANNER_THRESHOLD,
    eventParams,
    source,
  });

  const arrowColor = getInlineColor(title?.color);

  const [timerShown, timestamp] = useTimer(loadedTimeMs, timeRemainingMs);

  return (
    <BannerWrapper ref={clickRef} to={deeplink} source={source} mobileFullWidth>
      <Locator id="ContentListCountdownRowV2">
        <div
          className={classnames(styles.banner, {
            [styles.withBackground!]: Boolean(background),
            [styles.withExtraTopMargin!]: withExtraTopMargin,
          })}
        >
          <Background background={background} />
          <div className={styles.inner} ref={contentRef}>
            {image && (
              <div className={styles.imageWrap}>
                <Image
                  className={styles.image}
                  contain
                  image={image}
                  loadingHidden
                  pxFit={24}
                  vwFit={24}
                />
              </div>
            )}
            <div className={styles.textWrap}>
              <div className={styles.mainText}>
                <Locator id="ContentListCountdownRowV2Title">
                  <div className={styles.title} style={getInlineColor(title.color)}>
                    {title.text}
                  </div>
                </Locator>
              </div>
              {subtitle?.text ? (
                <div className={styles.otherText}>
                  {subtitle && (
                    <Locator id="ContentListCountdownRowV2Subtitle">
                      <div className={styles.subtitle} style={getInlineColor(subtitle.color)}>
                        {subtitle.text}
                      </div>
                    </Locator>
                  )}
                </div>
              ) : null}
            </div>
            {timerShown ? (
              <Locator id="ContentListCountdownRowV2Timer">
                <div className={styles.timerWrap}>
                  <Timer
                    timestamp={timestamp}
                    customTheme={{
                      digitBackgroundColor: timerBackgroundColor,
                      digitColor: timerColor,
                    }}
                    changeFormatDurationBeforeMs={timerMaxDuration}
                    completeMessage={<CompleteMessage />}
                  />
                </div>
              </Locator>
            ) : null}
            {deeplink && (
              <Locator id="ContentListCountdownRowV2Arrow">
                <div className={styles.arrowWrap} style={arrowColor}>
                  <span className={styles.arrow}>
                    <Icon type="mono" name="chevron-right-linear-24" width="24px" height="24px" />
                  </span>
                </div>
              </Locator>
            )}
          </div>
        </div>
      </Locator>
    </BannerWrapper>
  );
}
