import {FormattedCount} from 'components/FormattedCount';
import {Locator} from 'components/Locator';
import {Icon} from 'components/UIKit/Icon';
import React from 'react';
import {FormattedNumber} from 'react-intl';
import {isObfuscatedCount, ObfuscatedCount} from 'types/ObfuscatedCount';

import styles from './index.scss';

type Props = {
  rating: number;
  reviewsCount?: ObfuscatedCount;
  'data-testid'?: string;
};

export function StarsTiny({rating, reviewsCount, 'data-testid': testId}: Props): JSX.Element {
  return (
    <Locator id={testId}>
      <span className={styles.root}>
        <span className={styles.icon}>
          <Icon name="star-filled-16" type="mono" />
        </span>
        <span className={styles.rating}>
          <FormattedNumber maximumFractionDigits={1} minimumFractionDigits={0} value={rating} />
        </span>
        {isObfuscatedCount(reviewsCount) && (
          <>
            /
            <span className={styles.reviewsCount}>
              <FormattedCount count={reviewsCount} />
            </span>
          </>
        )}
      </span>
    </Locator>
  );
}
