import classnames from 'classnames/bind';
import CategoryViewShape from 'shapes/CategoryView';
import DotLoader from 'components/DotLoader';
import ErrorShape from 'shapes/Error';
import {Hoverable} from 'components/Hoverable';
import {Image} from 'components/Image';
import {Preloader} from 'components/Preloader';
import PropTypes from 'prop-types';
import {searchUrl, searchUrlByParams} from 'containers/pages/SearchPage/url';
import {getValueByPath} from 'utils/object';
import {createUrl} from 'utils/url';
import {useAnalytics} from 'hooks/useAnalytics';
import {FormattedMessage} from 'react-intl';
import {Link} from 'react-router-dom';
import {isLeafCategory, ROOT_ID} from 'store/modules/categoryViews';
import AnalyticsShape from 'shapes/Analytics';
import React, {PureComponent} from 'react';
import {useLocationQuery} from 'hooks/useLocationQuery';
import {getParamsData} from 'utils/search/pattern';
import {useBot} from 'hooks/useBot';
import {Skeleton, SkeletonContainer} from 'components/Skeleton';
import {parseFilterByParams} from 'utils/search/filters';
import {useDeviceVar} from 'hooks/useDeviceVars';
import {useScope} from 'hooks/useScope';
import {Locator as OldLocator} from 'components/Locator';
import {rootLocator} from 'utils/rootLocator';
import PrevIcon from './prev.jsx.svg';
import CloseIcon from './close.jsx.svg';

import styles from './index.scss';

const cn = classnames.bind(styles);

const locator = rootLocator.searchPage;

export class CatalogBase extends PureComponent {
  static propTypes = {
    analytics: AnalyticsShape.isRequired,
    scope: PropTypes.string.isRequired,
    buildUrl: PropTypes.func,
    boldAllCategories: PropTypes.bool,
    bot: PropTypes.bool.isRequired,
    leafCategoryWithSiblings: PropTypes.bool.isRequired,
    categoryView: CategoryViewShape,
    error: ErrorShape,
    hideError: PropTypes.bool,
    instantSelect: PropTypes.bool,
    lang: PropTypes.string.isRequired,
    light: PropTypes.bool,
    loading: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    onOpen: PropTypes.func.isRequired,
    onSelect: PropTypes.func,
    params: PropTypes.shape({
      categoryId: PropTypes.string,
      query: PropTypes.string,
    }).isRequired,
    stickyHeader: PropTypes.bool,
    noRootIcons: PropTypes.bool,
    rootHeader: PropTypes.node,
    query: PropTypes.objectOf(PropTypes.any).isRequired,
    headerWithoutLink: PropTypes.bool,
    rowSize: PropTypes.oneOf(['large', 'normal']),
    source: PropTypes.string,
    from: PropTypes.string.isRequired,
    targetBlank: PropTypes.bool,
    onMount: PropTypes.func,
  };

  static defaultProps = {
    buildUrl: searchUrl,
    boldAllCategories: false,
    categoryView: null,
    error: null,
    hideError: false,
    instantSelect: false,
    light: false,
    onClose: null,
    onSelect: () => {},
    stickyHeader: false,
    noRootIcons: false,
    rootHeader: null,
    headerWithoutLink: false,
    rowSize: 'normal',
    source: null,
    targetBlank: false,
    onMount: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      categoryView: props.categoryView,
    };
  }

  componentDidMount() {
    const children = getValueByPath(this.props, 'categoryView', 'children');

    this.props.onMount?.();

    if (children && children.length) {
      const {analytics, source, from} = this.props;
      const categories = children.map(({id}) => id);

      analytics.sendEvent({
        type: 'categoriesPreview',
        payload: {
          categories,
          from,
          source,
        },
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.props.loading && !this.props.error && this.props.categoryView) {
      const categoryId = getValueByPath(this.state, 'categoryView', 'category', 'id');
      const nextParentId = getValueByPath(this.props, 'categoryView', 'parent', 'id');
      const nextCategoryId = getValueByPath(this.props, 'categoryView', 'category', 'id');
      const nextChildren = getValueByPath(this.props, 'categoryView', 'children') || [];
      const nextChildrenIds = nextChildren.map(({id}) => id);
      const nextChildrenLength = getValueByPath(nextChildren, 'length');
      const isRoot = getValueByPath(prevProps, 'categoryView', 'root');
      const isNextRoot = getValueByPath(this.props, 'categoryView', 'root');
      const {instantSelect} = prevProps;

      if (instantSelect) {
        if ((!isRoot && isNextRoot) || (prevProps.loading && !this.props.loading && nextChildren)) {
          this.sendPreviewEvent(nextChildrenIds);
        }
      } else if (categoryId !== nextCategoryId) {
        this.sendPreviewEvent(nextChildrenIds);
      }

      if (nextCategoryId && nextParentId && nextParentId === categoryId && !nextChildrenLength) {
        const containsNextCategory = !!this.state.categoryView?.children.find(
          ({id}) => id === nextCategoryId,
        );
        if (containsNextCategory) {
          return;
        }
      }

      if (this.props.categoryView !== this.state.categoryView) {
        this.setState({categoryView: this.props.categoryView});
      }
    }
  }

  sendPreviewEvent(categories) {
    const {analytics, source, from} = this.props;
    analytics.sendEvent({
      type: 'categoriesPreview',
      payload: {
        categories,
        from,
        source,
      },
    });
  }

  getUrl(params, category) {
    const {bot, buildUrl, lang, scope} = this.props;
    if (bot) {
      // WEB-5472 add brand filter for crawlers
      const brandFilter = params.filterParams?.find(
        (filter) => parseFilterByParams(filter)?.id === 'brand',
      );
      // WEB-2153 render only simple catalog urls for crawlers
      return searchUrlByParams(scope, lang, {
        parsed: true,
        categoryId: category?.id,
        filterParams: [brandFilter].filter(Boolean),
      });
    }

    const urlBase = buildUrl(scope, lang, {
      ...params,
      categoryId: (category && category.id) || null,
    });
    return createUrl(urlBase, this.props.query);
  }

  getIsLeafWithSiblings(selectedCategoryId) {
    const {children} = this.state.categoryView;
    const {leafCategoryWithSiblings} = this.props;

    const selectedCategory = children?.find(({id}) => id === selectedCategoryId);
    return leafCategoryWithSiblings && isLeafCategory(selectedCategory);
  }

  getClickHandler(category, leafWithSiblings) {
    return (evt) => {
      if (category) {
        const {analytics, source, from} = this.props;
        analytics.sendEvent({
          type: 'categoriesClick',
          payload: {
            categoryId: category.id,
            from: leafWithSiblings ? 'leafWithSiblings' : from,
            source,
          },
        });
      }
      this.setState({lastClickedCategory: category});
      if (this.props.instantSelect) {
        this.props.onSelect(evt, category);
      } else {
        this.props.onOpen(evt, category);
      }
    };
  }

  getChildClickHandler = (category, leafWithSiblings) => (evt) => {
    const {analytics, source, from} = this.props;
    analytics.sendEvent({
      type: 'categoriesClick',
      payload: {
        categoryId: category.id,
        from: leafWithSiblings ? 'leafWithSiblings' : from,
        source,
      },
    });
    this.props.onSelect(evt, category);
  };

  getCurrentClickHandler = (category, leafWithSiblings) => (evt) => {
    if (leafWithSiblings) {
      const {analytics, source} = this.props;
      analytics.sendEvent({
        type: 'categoriesClick',
        payload: {
          categoryId: category.id,
          from: 'allInThisCategory',
          source,
        },
      });
    }
    this.props.onSelect(evt, category);
  };

  renderLinkName(name) {
    const {noRootIcons} = this.props;
    return <span className={cn(styles.name, {noRootIcons})}>{name}</span>;
  }

  renderParent(params) {
    const {
      categoryView: {root, category, parent},
      lastClickedCategory,
    } = this.state;
    const {
      instantSelect,
      onClose,
      onSelect,
      stickyHeader,
      rootHeader,
      headerWithoutLink,
      targetBlank,
    } = this.props;
    const {categoryId} = params;
    const handler = this.getClickHandler(parent);

    if (root && (!categoryId || categoryId === ROOT_ID)) {
      return rootHeader || null;
    }

    if (!root) {
      const loading = this.props.loading && parent === lastClickedCategory;
      if (headerWithoutLink) {
        return (
          <div className={cn({headerWithoutLink, sticky: stickyHeader})}>
            <Link
              className={cn('link', 'parent')}
              onClick={handler}
              to={this.getUrl(params, parent)}
              target={targetBlank ? '_blank' : undefined}
            >
              <PrevIcon className={styles.prevIcon} />
            </Link>
            <div className={styles.title}>{this.renderLinkName(category.name)}</div>
            {onClose ? (
              <div role="button" tabIndex="0" onClick={onClose} className={styles.closeContainer}>
                <CloseIcon />
              </div>
            ) : null}
          </div>
        );
      }

      return (
        <Hoverable>
          <Link
            className={cn('link', 'parent', {sticky: stickyHeader})}
            onClick={handler}
            to={this.getUrl(params, parent)}
            target={targetBlank ? '_blank' : undefined}
            {...locator.goToParentCategoryButton()}
          >
            <PrevIcon className={styles.prevIcon} />
            {loading ? this.renderInlineLoading() : this.renderLinkName(category.name)}
          </Link>
        </Hoverable>
      );
    }

    if (instantSelect) {
      return null;
    }

    return (
      <>
        <Link
          className={cn('link', 'all')}
          onClick={(evt) => onSelect(evt, category)}
          to={this.getUrl(params)}
          target={targetBlank ? '_blank' : undefined}
        >
          {this.renderLinkName(
            <FormattedMessage
              description="Button to all categories"
              defaultMessage="All categories"
            />,
          )}
        </Link>
        {onClose ? (
          <div role="button" tabIndex="0" onClick={onClose} className={styles.closeContainer}>
            <CloseIcon />
          </div>
        ) : null}
      </>
    );
  }

  renderCurrent(params) {
    const {category, root} = this.state.categoryView;
    const {instantSelect, boldAllCategories, targetBlank} = this.props;
    const {categoryId} = params;
    const leafWithSiblings = this.getIsLeafWithSiblings(categoryId);

    if (instantSelect && !leafWithSiblings) {
      return null;
    }

    if (!root) {
      const className = cn('link', {
        selected: categoryId === category.id,
        boldAllCategories: boldAllCategories || leafWithSiblings,
      });

      return (
        <Link
          onClick={this.getCurrentClickHandler(category, leafWithSiblings)}
          className={className}
          to={this.getUrl(params, category)}
          target={targetBlank ? '_blank' : undefined}
        >
          {this.renderLinkName(
            <FormattedMessage
              description="Button to select current category"
              defaultMessage="Everything in this category"
            />,
          )}
        </Link>
      );
    }

    return null;
  }

  renderInlineLoading() {
    return (
      <span className={styles.inlineLoading}>
        <DotLoader style="dark" />
      </span>
    );
  }

  renderChild(params, category) {
    const {
      categoryView: {root},
      lastClickedCategory,
    } = this.state;
    const {noRootIcons, targetBlank} = this.props;
    const {categoryId} = params;
    const {hasPublicChildren} = category;
    const loading = this.props.loading && category === lastClickedCategory;
    const className = cn('link', {
      hasIcon: root,
      next: hasPublicChildren,
      selected: categoryId === category.id,
    });
    const leafWithSiblings = this.getIsLeafWithSiblings(categoryId);

    return (
      <OldLocator id="ProductListCategoryLink" category-id={category.id}>
        <Link
          key={category.id}
          to={this.getUrl(params, category)}
          className={className}
          onClick={
            hasPublicChildren
              ? this.getClickHandler(category, leafWithSiblings)
              : this.getChildClickHandler(category, leafWithSiblings)
          }
          target={targetBlank ? '_blank' : undefined}
        >
          {!root || noRootIcons || (
            <Image
              vwFit={{sm: '1.5em'}}
              className={styles.icon}
              image={category.mainImage}
              contain
              pxFit={40}
            />
          )}
          {loading ? this.renderInlineLoading() : this.renderLinkName(category.name)}
        </Link>
      </OldLocator>
    );
  }

  renderError() {
    return (
      <div className={styles.error}>
        <FormattedMessage
          description="Categories list error"
          defaultMessage="Failed to load product categories"
        />
      </div>
    );
  }

  renderLoading() {
    if (!this.props.loading) {
      return null;
    }

    return (
      <div className={styles.loading}>
        <Preloader size="small" />
      </div>
    );
  }

  renderChildrenSkeleton() {
    const {
      categoryView: {root},
    } = this.state;
    const {noRootIcons} = this.props;

    return [124, 164, 84, 124, 164, 84].map((width, index) => (
      <div
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        className={cn('skeletonLink', {
          hasIcon: root,
        })}
      >
        {!root || noRootIcons || (
          <span className={styles.icon}>
            <Skeleton contain />
          </span>
        )}
        <Skeleton text style={{width}} mobileStyle={{width: '100%'}} />
      </div>
    ));
  }

  render() {
    const {error, light, rowSize, params, hideError} = this.props;
    const paramsData = getParamsData(params);
    const {categoryView} = this.state;

    const showError = Boolean(error && !hideError);
    const showLoading = Boolean(!error && !categoryView);
    const showCatalog = Boolean(!error && categoryView);

    return (
      <SkeletonContainer className={cn('catalog', rowSize, {light})}>
        {showError && this.renderError()}
        {showLoading && this.renderLoading()}
        {showCatalog && (
          <>
            {this.renderParent(paramsData)}
            {this.renderCurrent(paramsData)}
            {categoryView.children.map((child) => this.renderChild(paramsData, child))}
            {categoryView.childrenLoading && this.renderChildrenSkeleton()}
          </>
        )}
      </SkeletonContainer>
    );
  }
}

export default function Catalog(props) {
  const query = useLocationQuery();
  const scope = useScope();
  const analytics = useAnalytics();
  const bot = useBot();
  const leafCategoryWithSiblings = useDeviceVar('searchAddSiblingsToLeafCategoryFilters');

  return (
    <CatalogBase
      {...props}
      query={query}
      scope={scope}
      analytics={analytics}
      bot={bot}
      leafCategoryWithSiblings={leafCategoryWithSiblings}
    />
  );
}
