import cn from 'classnames';
import {Locator} from 'components/Locator';
import React, {useCallback, useEffect, useId, useRef, useState} from 'react';

import styles from './index.scss';

export type Props = {
  disabled?: boolean;
  error?: boolean;
  label?: string | React.ReactElement;
  name: string;
  maxlength?: number;
  onBlur?: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  value?: string;
  controlled?: boolean;
  placeholder?: string;
  'data-testid'?: string;
};

export const Textarea = React.memo(function Textarea({
  disabled = false,
  error = false,
  label,
  name,
  maxlength,
  onBlur,
  onChange,
  value = '',
  controlled,
  placeholder,
  'data-testid': testId,
}: Props): React.ReactElement {
  const [innerInputValue, setInputValue] = useState(value);
  const controlRef = useRef<HTMLLabelElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const id = useId();

  const inputValue = controlled ? value : innerInputValue;

  const resizeTextarea = useCallback(() => {
    const control = controlRef.current;
    const textarea = textareaRef.current;

    if (!control || !textarea) {
      return;
    }

    textarea.style.height = 'auto';
    textarea.style.overflow = 'hidden';
    control.style.height = `${textarea.scrollHeight + 2}px`;
    textarea.style.overflow = '';
    textarea.style.height = '100%';
  }, []);

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      const {target} = event;

      setInputValue(target.value);
      if (!controlled) resizeTextarea();

      if (onChange) {
        onChange(event);
      }
    },
    [controlled, onChange, resizeTextarea],
  );

  useEffect(() => {
    if (!textareaRef.current) return;
    textareaRef.current.value = inputValue;
  }, [inputValue, textareaRef]);

  useEffect(resizeTextarea);

  const hasValue = Boolean(inputValue);
  const hasError = Boolean(error);

  return (
    <label className={styles.wrap} htmlFor={id}>
      <Locator id={testId}>
        <span
          className={cn(styles.control, hasValue && styles.withValue, hasError && styles.error)}
          ref={controlRef}
        >
          <textarea
            className={styles.textarea}
            disabled={disabled}
            id={id}
            maxLength={maxlength}
            name={name}
            onBlur={onBlur}
            onChange={handleInputChange}
            placeholder={placeholder}
            ref={textareaRef}
            value={inputValue}
          />
        </span>
      </Locator>
      {label && <span className={styles.label}>{label}</span>}
    </label>
  );
});
