import {useMemo} from 'react';
import {useHistory, useLocation, useParams, useRouteMatch} from 'react-router-dom';
import {getQueryData} from 'utils/url';

type Router = {
  readonly push: ReturnType<typeof useHistory>['push'];
  readonly replace: ReturnType<typeof useHistory>['push'];
  readonly pathname: ReturnType<typeof useLocation>['pathname'];
  readonly location: ReturnType<typeof useLocation> & {
    readonly query: ReturnType<typeof getQueryData>;
  };
  readonly history: ReturnType<typeof useHistory>;
  readonly params: ReturnType<typeof useParams>;
  readonly match: ReturnType<typeof useRouteMatch>;
};

/**
 * NB! DO NOT USE THIS HOOK!
 * Better to use react-router-dom instead. This hook exists only for backwards compatibility
 */
export function useRouter(): Router {
  const params = useParams();
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  // Return our custom router object
  // Memoize so that a new object is only returned if something changes
  return useMemo(() => {
    return {
      // For convenience add push(), replace(), pathname at top level
      push: history.push,
      replace: history.replace,
      pathname: location.pathname,
      params,
      // Include match, location, history objects so we have
      // access to extra React Router functionality if needed.
      match,
      location: {
        ...location,
        query: getQueryData(location.search),
      },
      history,
    };
  }, [params, match, location, history]);
}
