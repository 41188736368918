import {QRCodeSVGCsr} from 'components/QRCodeSVGCsr';
import {Square} from 'components/Square';
import React from 'react';

import styles from './index.scss';

type QrCodeProps = {
  height: number;
  value: string;
  width: number;
};

export function QrCode({height, value, width}: QrCodeProps): JSX.Element {
  return (
    <Square className={styles.qr}>
      <QRCodeSVGCsr className={styles.qrSvg} height={height} value={value} width={width} />
    </Square>
  );
}
