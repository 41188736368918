import PropTypes from 'prop-types';

const ItemShape = {
  type: PropTypes.string.isRequired,
  tagName: PropTypes.string,
  properties: PropTypes.objectOf(PropTypes.string.isRequired),
  value: PropTypes.string,
};
ItemShape.children = PropTypes.arrayOf(PropTypes.shape(ItemShape));

const Item = PropTypes.shape(ItemShape);
export default PropTypes.arrayOf(Item.isRequired);
