import {Image} from 'components/Image';
import React from 'react';
import {TermsHeader as TermsHeaderType} from 'types/ContentList/ContentListTermsHeader';

import styles from './index.scss';

type Props = {
  content: TermsHeaderType;
};

export function TermsHeader({content}: Props): React.ReactElement {
  const {title, icon} = content;

  return (
    <div className={styles.header}>
      {icon ? (
        <Image
          image={icon}
          alt={icon.accessibilityLabel}
          className={styles.image}
          contain
          pxFit={53}
        />
      ) : null}
      <div className={styles.text}>{title?.text}</div>
    </div>
  );
}
