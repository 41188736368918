import {useApiClient} from 'hooks/useApiClient';
import {useMemo} from 'react';

export function useDir(): 'ltr' | 'rtl' {
  const client = useApiClient();

  return useMemo(() => client.device.getDir(), [client.device]);
}

export function useRtl(): boolean {
  const dir = useDir();

  return dir === 'rtl';
}
