import PropTypes from 'prop-types';
import Context from './Context';
import GalleryItem from './GalleryItem';
import ImageBundle from './ImageBundle';
import Price from './Price';
import ProductPreOffer from './ProductPreOffer';

export default PropTypes.shape({
  categoryId: PropTypes.string,
  context: Context,
  contextId: PropTypes.string,
  countryFlag: ImageBundle,
  currency: PropTypes.string,
  favorite: PropTypes.bool,
  gallery: PropTypes.arrayOf(GalleryItem.isRequired),
  id: PropTypes.string.isRequired,
  pdid: PropTypes.string, // `enhancePageDependedId` for duplicates in products
  inStock: PropTypes.bool,
  mainImage: ImageBundle,
  msrPrice: Price,
  preOffer: ProductPreOffer,
  price: Price.isRequired,
  storeId: PropTypes.string.isRequired,
  forAdults: PropTypes.bool,
  rating: PropTypes.number,
  reviewsCount: PropTypes.shape({
    value: PropTypes.number.isRequired,
  }),
});
