import {ImageBundle} from 'types/Image';
import {ProductAddOrRemoveFlow} from 'types/ProductCollection/ProductAddOrRemoveFlow';
import {ProductLite} from 'types/ProductLite';
import {ClientProduct, ClientProductIncomplete, isClientProduct} from 'types/ProductNext';
import {getIsSensitiveProductShown} from 'utils/sensitiveContent';

export type CommonProduct = ProductLite | ClientProduct | ClientProductIncomplete;

export function getCommonProductId(product: CommonProduct): string {
  if (isClientProduct(product)) {
    return product.header.id;
  }

  return product.id;
}

export function getCommonProductImageBundle(product: CommonProduct): ImageBundle | undefined {
  if (isClientProduct(product)) {
    return product.header.mainImage;
  }

  return product.mainImage;
}

export function getCommonProductRestricted(product: CommonProduct, isUserAdult = false): boolean {
  if (isClientProduct(product)) {
    return Boolean(
      product.header.blockInfo?.isBlocked ||
        (product.header.isSensitive && !getIsSensitiveProductShown(product.header.id)) ||
        (product.header.forAdults && !isUserAdult),
    );
  }

  return Boolean(
    product.blockInfo?.isBlocked ||
      product.isBlocked ||
      (product.isSensitive && !getIsSensitiveProductShown(product.id)) ||
      (product.forAdults && !isUserAdult),
  );
}

export function getCommonProductAddOrRemoveFlow(
  product: CommonProduct,
): ProductAddOrRemoveFlow | undefined {
  if (isClientProduct(product)) {
    return product.header.productCollectionFlow;
  }

  return undefined;
}
