import {discardableByUser} from 'helpers/discardable';

const CLEAN = 'email/promoSubscription/CLEAN';
const LOAD = 'email/promoSubscription/LOAD';
const LOAD_SUCCESS = 'email/promoSubscription/LOAD_SUCCESS';
const LOAD_FAIL = 'email/promoSubscription/LOAD_FAIL';

const initialState = {
  data: null,
  loading: false,
  error: null,
  loaded: false,
};

export default discardableByUser(function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        error: null,
        loaded: false,
        loading: true,
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        error: null,
        data: action.result,
        loaded: true,
        loading: false,
      };
    case LOAD_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case CLEAN:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
});

export function isEmailPromoSubscriptionLoaded(globalState) {
  return !!globalState.email.promoSubscription.loaded;
}

export function isEmailPromoSubscriptionLoading(globalState) {
  return !!globalState.email.promoSubscription.loading;
}

export function getEmailPromoSubscriptionError(globalState) {
  return globalState.email.promoSubscription.error;
}

export function hasEmailPromoSubscription(globalState) {
  return isEmailPromoSubscriptionLoaded(globalState)
    ? globalState.email.promoSubscription.data
    : null;
}

export function cleanEmailPromoSubscription() {
  return {type: CLEAN};
}

export function loadEmailPromoSubscription() {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client) =>
      client.api
        .get('/email/shouldShowPromoSubscriptionCheckbox')
        .then(({language, currency, body: {payload}}) => payload),
  };
}
