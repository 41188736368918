import classnames from 'classnames/bind';
import {Loader} from 'components/UIKit/Loader';
import React, {ReactNode} from 'react';

import styles from './Content.scss';

const cn = classnames.bind(styles);

type TextProps = {
  justify?: 'center' | 'start' | 'end';
  children: ReactNode;
  grow?: boolean;
  nowrap?: boolean;
};

type IconProps = {
  children: ReactNode;
};

type LoadingProps = {
  children: ReactNode;
  loading: boolean;
};

export function Text({
  justify = 'center',
  grow = false,
  nowrap = false,
  children,
}: TextProps): JSX.Element {
  return (
    <span className={cn('item', justify, {grow})}>
      {nowrap ? <span className={styles.nowrap}>{children}</span> : children}
    </span>
  );
}

export function Icon({children}: IconProps): JSX.Element {
  return <span className={cn('item', 'icon')}>{children}</span>;
}

export function Loading({children, loading}: LoadingProps): JSX.Element {
  return (
    <span className={cn('loadingContent', {loading})}>
      {loading && (
        <div className={styles.loader}>
          <Loader />
        </div>
      )}
      {children}
    </span>
  );
}
