import {Image} from 'components/Image';
import {useRtl} from 'hooks/useRtl';
import React, {CSSProperties, ReactElement, useMemo} from 'react';
import {HorizontalAlignment} from 'types/Layout';
import {SDLImage} from 'types/ServerDrivenLayout';
import {MergeUnion} from 'types/utility';

import styles from './styles.scss';

const HORIZONTAL_ALIGNMENT_MAP: Record<HorizontalAlignment, string> = {
  start: 'left',
  center: 'center',
  end: 'right',
};

export function SDLImageView({image}: {image: SDLImage}): ReactElement {
  const isRtl = useRtl();

  const style = useMemo(() => {
    const style: CSSProperties = {};

    if (image.scaleType) {
      const scaleType = image.scaleType as MergeUnion<typeof image.scaleType>;

      if (scaleType.aspectFill) {
        style.objectFit = 'cover';
      } else if (scaleType.aspectFit) {
        style.objectFit = 'contain';
      }

      const aspect = scaleType.aspectFit || scaleType.aspectFill;
      if (aspect?.horizontalAlignment || aspect?.verticalAlignment) {
        const horizontal = HORIZONTAL_ALIGNMENT_MAP[aspect.horizontalAlignment!] || 'center';
        const vertical = aspect.verticalAlignment || 'center';
        style.objectPosition = `${horizontal} ${vertical}`;
      }
    }

    if (image.autoMirror && isRtl) {
      style.transform = 'scaleX(-1)';
    }

    return style;
  }, [image, isRtl]);

  return (
    <Image image={image.image} className={styles.image} style={style} sizes="auto" loadingHidden />
  );
}
