import cn from 'classnames';
import React from 'react';

import styles from './index.scss';

export enum DotLoaderStyle {
  LIGHT = 'light',
  DARK = 'dark',
  LINK = 'link',
  INHERIT = 'inherit',
}

type Props = {
  className?: string;
  style?: DotLoaderStyle;
};

export const DotLoader = React.memo(function DotLoader({
  className,
  style = DotLoaderStyle.LINK,
}: Props) {
  return (
    <span className={cn(styles.loader, styles[style], className)}>
      <span className={`${styles.dot} ${styles.dot1}`} />
      <span className={`${styles.dot} ${styles.dot2}`} />
      <span className={`${styles.dot} ${styles.dot3}`} />
      <span className={`${styles.dot} ${styles.dot4}`} />
    </span>
  );
});

// eslint-disable-next-line import/no-default-export
export default DotLoader;
