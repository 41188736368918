import {ProductLite} from 'components/ProductLite';
import React from 'react';
import {ProductLite as ProductLiteType} from 'types/ProductLite';

import {WIDE4} from '../../imageSizes';
import styles from './index.scss';

type Props = {
  items: Array<ProductLiteType>;
  onFavorite?: (product: ProductLiteType) => Promise<void>;
  onUnfavorite?: (product: ProductLiteType) => Promise<void>;
};

export const LargeCards = React.memo(function LargeCards({
  items,
  onFavorite,
  onUnfavorite,
}: Props): React.ReactElement {
  return (
    <div className={styles.largeCards}>
      {items.map((item) => (
        <div key={item.id} className={styles.cell}>
          <ProductLite
            product={item}
            sizes={WIDE4.sizes}
            width="100%"
            onFavorite={onFavorite}
            onUnfavorite={onUnfavorite}
          />
        </div>
      ))}
    </div>
  );
});
