import {keyKey} from 'utils/array';

const Type = {
  DISCLAIMER: 'anonymousUsagePopup',
  TOS_ON_ACTION_POPUP: 'anonymousTermsOfServicePopup',
  TOS_POPUP: 'termsOfServicePopup',
  TOS_POPUP_DESAGREE_TO_SETTINGS: 'termsOfServicePopupDisagreeToSettings',
  TOS_POPUP_SETTINGS: 'termsOfServicePopupSettings',
};

export const ContentType = {
  DEFAULT: 'content',
  CONFIRMATION: 'confirmationContent',
};

export const TypeModal = keyKey([Type.TOS_POPUP, Type.TOS_POPUP_DESAGREE_TO_SETTINGS]);

export const TypeWithConfirmation = keyKey([
  Type.TOS_POPUP,
  Type.TOS_POPUP_SETTINGS,
  Type.TOS_POPUP_DESAGREE_TO_SETTINGS,
]);

export const TypeWithCancellation = keyKey([Type.TOS_POPUP_SETTINGS]);

export const RelatedDocumentOnDisagree = {
  [Type.TOS_POPUP_DESAGREE_TO_SETTINGS]: Type.TOS_POPUP_SETTINGS,
};

export default Type;
