import {type Locator, type Mark, createLocator} from 'create-locator';
import {useLogger} from 'hooks/useLogger';
import React, {HTMLAttributes, memo} from 'react';
import {defineMessages, FormattedMessage, FormattedNumber} from 'react-intl';
import {Price as PriceType} from 'types/Price';
import {getDatePlusOneYear} from 'utils/date/getDatePlusOneYear';
import {getIsoDate} from 'utils/date/getIsoDate';
import {isDefAndNotNull} from 'utils/function';
import {isNumber} from 'utils/guards';
import {getInlineColor} from 'utils/styles/color';

import styles from './index.scss';

export type PriceLocator = Locator<void>;

function calcMinimumFractionDigits(value: number): number {
  return value % 1 === 0 ? 0 : 2;
}

const messages = defineMessages({
  rub: {
    description: 'Price hidden roubles',
    defaultMessage: 'rub.',
  },
  free: {
    description: 'Price when its free',
    defaultMessage: 'Free',
  },
  label: {
    description: 'Price hidden label',
    defaultMessage: 'Price',
  },
});

type BaseProps = {
  format?: string;
  minimumFractionDigits?: number;
  noText?: boolean;
  noSchema?: boolean;
  noSeoCurrency?: boolean;
  noSeoLabel?: boolean;
} & Partial<Mark<PriceLocator>>;

type MoneyProps = BaseProps & {
  money: PriceType;
  value?: never;
};

type ValueProps = BaseProps & {
  money?: never;
  value: string | number;
};

export type Props = MoneyProps | ValueProps;

function getMoneyAmountFromProps(props: Props): number | undefined {
  if (isNumber(props.money?.amount)) {
    return props.money?.amount;
  }

  // In some cases, for unknown reasons, the money prop may be a number.
  if (isNumber(props.money)) {
    return props.money;
  }

  if (isNumber(props.value)) {
    return props.value;
  }

  return undefined;
}

function getMoneyCurrencyFromProps(props: Props): string {
  return props.money?.currency || props.format || '';
}

const Price = memo(function Price(props: Props) {
  const {noText = false, noSchema = false, noSeoCurrency = false, noSeoLabel = false} = props;
  const locator = createLocator(props);
  const value = getMoneyAmountFromProps(props);
  const format = getMoneyCurrencyFromProps(props);
  const logger = useLogger('Price');

  if (value === undefined) {
    logger.warn('Price is not defined');
    return null;
  }

  if (!noText && value === 0) {
    return <FormattedMessage {...messages.free} />;
  }

  const minimumFractionDigits = isDefAndNotNull(props.minimumFractionDigits)
    ? props.minimumFractionDigits
    : calcMinimumFractionDigits(parseFloat(value.toString()));

  const priceProps: HTMLAttributes<HTMLSpanElement> = noSchema
    ? {}
    : {
        itemProp: 'price',
        content: value.toString(),
      };

  priceProps.style = getInlineColor(props.money?.priceColor);

  const priceValidDate = getIsoDate(getDatePlusOneYear(new Date()));

  return (
    <>
      {noSeoLabel ? null : (
        <span key="hiddenLabel" className={styles.hidden}>
          <FormattedMessage {...messages.label} />{' '}
        </span>
      )}
      <span key="price" {...priceProps} {...locator()}>
        <FormattedNumber
          minimumFractionDigits={minimumFractionDigits}
          maximumFractionDigits={2}
          style={format ? 'currency' : 'decimal'}
          currency={format}
          value={parseFloat(value.toString())}
        />
      </span>
      {noSchema ? null : (
        <>
          <meta key="currency" itemProp="priceCurrency" content={format} />
          <meta key="validUntil" itemProp="priceValidUntil" content={priceValidDate} />
        </>
      )}
      {!noSeoCurrency && format === 'RUB' ? (
        <span key="hiddenCurrency" className={styles.hidden}>
          {' '}
          <FormattedMessage {...messages.rub} />
        </span>
      ) : null}
    </>
  );
});

export {Price};
