import {memoizeLastShallowEqual} from './memoize';

const UTM_SOURCE = 'utm_source';

function hasTrackingSourceInListBase(
  tracking?: Partial<Record<string, string>>,
  list?: Array<'*' | string>,
): boolean {
  const source = tracking?.[UTM_SOURCE];
  if (!source) {
    return false;
  }

  // `*` means any utm_source
  return list?.some((value) => value === '*' || value === source) || false;
}

export const hasTrackingSourceInList = memoizeLastShallowEqual(hasTrackingSourceInListBase);
