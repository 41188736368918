import {Image} from 'components/Image';
import {useParcelCancellationPopupRender} from 'components/ParcelCancellationPopup';
import {ParcelPageCard, ParcelPageCardInner} from 'components/ParcelPageCard';
import {Text} from 'components/Text';
import {Button} from 'components/UIKit/Button';
import {PopupType} from 'components/UIKit/Popup/PopupProvider';
import {Size as TimerSize, Timer} from 'components/UIKit/Timer';
import {useSelector} from 'hooks/redux';
import {useDispatchAction} from 'hooks/useDispatch';
import {usePopup} from 'hooks/usePopup';
import {useTimer} from 'hooks/useTimer';
import {useUniversalNavigate} from 'hooks/useUniversalNavigate';
import React, {useCallback, useMemo} from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';
import {
  cancelOrderGroup as cancelOrderGroupAction,
  getOrderGroupCancelationError,
  getOrderGroupCancelationInProgress,
} from 'store/modules/orderGroups/orderGroup';
import {AwaitingPaymentOrderGroup as AwaitingPaymentOrderGroupType} from 'types/ContentList/ContentListAwaitingPaymentOrderGroup';
import {voidFunction} from 'utils/function';
import {convertBackendColorToCSSValue, convertBackendGradientToCSSValue} from 'utils/styles/color';

import styles from './index.scss';

const messages = defineMessages({
  actionButtonLabel: {
    defaultMessage: 'Go to details',
    description: '[label] Button to display details about the order',
  },
  cancelButtonLabel: {
    defaultMessage: 'Cancel order',
    description: '[label] Button to cancel the order',
  },
});

type AwaitingPaymentOrderGroupHeaderProps = {
  loadedTimeMs: number;
  awaitingPaymentOrderGroup: AwaitingPaymentOrderGroupType;
};

const HEADER_ICON_PX_FIT = 24;
const HEADER_ICON_VW_FIT = 24;

function AwaitingPaymentOrderGroupHeader({
  loadedTimeMs,
  awaitingPaymentOrderGroup,
}: AwaitingPaymentOrderGroupHeaderProps): JSX.Element {
  const {icon, subtitle, timer, title} = awaitingPaymentOrderGroup;
  const [timerShown, timestamp] = useTimer(loadedTimeMs, timer?.timeRemainingMs);

  const handleTimerComplete = useCallback(() => {
    window.location.reload();
  }, []);

  return (
    <div className={styles.header}>
      {title && (
        <div className={styles.headerTitle}>
          {icon && (
            <Image
              className={styles.headerIcon}
              contain
              image={icon}
              pxFit={HEADER_ICON_PX_FIT}
              vwFit={HEADER_ICON_VW_FIT}
            />
          )}
          <Text text={title} />
        </div>
      )}
      {subtitle && <Text className={styles.headerSubtitle} text={subtitle} />}
      {timerShown && (
        <div className={styles.headerTimer}>
          <Timer
            completeMessage={timer?.text && <Text text={timer.text} />}
            customTheme={{
              digitBackgroundColor: timer?.digitBackgroundColor,
              digitColor: timer?.digitColor,
            }}
            onComplete={handleTimerComplete}
            size={TimerSize.SMALL}
            timestamp={timestamp}
          />
        </div>
      )}
    </div>
  );
}

type AwaitingPaymentOrderGroupProductsProps = {
  awaitingPaymentOrderGroup: AwaitingPaymentOrderGroupType;
};

const PRODUCT_IMAGE_PX_FIT = 64;
const PRODUCT_IMAGE_VW_FIT = {
  sm: 48,
  md: 64,
};

function AwaitingPaymentOrderGroupProducts({
  awaitingPaymentOrderGroup,
}: AwaitingPaymentOrderGroupProductsProps): JSX.Element | null {
  const {products} = awaitingPaymentOrderGroup;

  if (!products?.length) {
    return null;
  }

  return (
    <div className={styles.products}>
      {products.map((item) =>
        item.image ? (
          <div className={styles.productsItem} key={item.productId}>
            <Image
              className={styles.productsImage}
              contain
              height="100%"
              image={item.image}
              multiply
              pxFit={PRODUCT_IMAGE_PX_FIT}
              vwFit={PRODUCT_IMAGE_VW_FIT}
              width="100%"
            />
          </div>
        ) : null,
      )}
    </div>
  );
}

type AwaitingPaymentOrderGroupActionsProps = {
  awaitingPaymentOrderGroup: AwaitingPaymentOrderGroupType;
};

function AwaitingPaymentOrderGroupActions({
  awaitingPaymentOrderGroup,
}: AwaitingPaymentOrderGroupActionsProps): JSX.Element {
  const {actionButton, cancelBehavior, cancelButton, orderGroupId} = awaitingPaymentOrderGroup;
  const navigate = useUniversalNavigate();
  const actionButtonColor = useMemo(
    () => ({
      color: convertBackendColorToCSSValue(actionButton.textColor),
      background: convertBackendGradientToCSSValue(actionButton.backgroundGradient),
    }),
    [actionButton.backgroundGradient, actionButton.textColor],
  );
  const cancelButtonColor = useMemo(
    () => ({
      color: convertBackendColorToCSSValue(cancelButton?.textColor),
      background: convertBackendGradientToCSSValue(cancelButton?.backgroundGradient),
    }),
    [cancelButton?.backgroundGradient, cancelButton?.textColor],
  );
  const cancelOrderGroup = useDispatchAction(cancelOrderGroupAction);
  const cancelationInProgress = useSelector(getOrderGroupCancelationInProgress);
  const cancelationError = useSelector(getOrderGroupCancelationError);

  let cancelationPopup: PopupType | undefined;

  const handleAction = useCallback(() => {
    if (actionButton.url) {
      navigate(actionButton.url);
    }
  }, [actionButton.url, navigate]);
  const handleCancel = useCallback(() => {
    cancelOrderGroup(orderGroupId)
      .then(() => {
        window.location.reload();
        cancelationPopup?.close();
      })
      .catch(() => null);
  }, [cancelOrderGroup, cancelationPopup, orderGroupId]);

  cancelationPopup = usePopup(
    useParcelCancellationPopupRender({
      cancelBehavior,
      error: cancelationError,
      loading: cancelationInProgress,
      onCancel: handleCancel,
      onChangeAddress: voidFunction,
    }),
  );

  return (
    <div className={styles.actions}>
      {cancelButton && (
        <div className={styles.actionsButton}>
          <Button color={cancelButtonColor} onClick={cancelationPopup.open} tag="button">
            {cancelButton.text ?? <FormattedMessage {...messages.cancelButtonLabel} />}
          </Button>
        </div>
      )}
      <div className={styles.actionsButton}>
        <Button color={actionButtonColor} onClick={handleAction} tag="button">
          {actionButton.text ?? <FormattedMessage {...messages.actionButtonLabel} />}
        </Button>
      </div>
    </div>
  );
}

type AwaitingPaymentOrderGroupProps = {
  loadedTimeMs: number;
  awaitingPaymentOrderGroup: AwaitingPaymentOrderGroupType;
};

export function AwaitingPaymentOrderGroup({
  loadedTimeMs,
  awaitingPaymentOrderGroup,
}: AwaitingPaymentOrderGroupProps): JSX.Element {
  return (
    <ParcelPageCard>
      <ParcelPageCardInner>
        <AwaitingPaymentOrderGroupHeader
          loadedTimeMs={loadedTimeMs}
          awaitingPaymentOrderGroup={awaitingPaymentOrderGroup}
        />
        <AwaitingPaymentOrderGroupProducts awaitingPaymentOrderGroup={awaitingPaymentOrderGroup} />
        <AwaitingPaymentOrderGroupActions awaitingPaymentOrderGroup={awaitingPaymentOrderGroup} />
      </ParcelPageCardInner>
    </ParcelPageCard>
  );
}
