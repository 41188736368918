import {isRecord} from '../utils/guards';
import {ImageBundle} from './Image';
import {VideoBundle} from './Video';

export enum GalleryItemType {
  VIDEO = 'video',
  IMAGE = 'image',
}

export const AllowedGalleryItemTypes = {
  [GalleryItemType.IMAGE]: true,
  [GalleryItemType.VIDEO]: true,
};

export type GalleryVideoItem = {
  type: GalleryItemType.VIDEO;
  payload: VideoBundle;
};

export type GalleryImageItem = {
  type: GalleryItemType.IMAGE;
  payload: ImageBundle;
};

export type GalleryItem = GalleryVideoItem | GalleryImageItem;

export type GalleryItems = Array<GalleryItem>;

export function isGalleryVideoItem(item: unknown): item is GalleryVideoItem {
  return Boolean(isRecord(item) && (item as GalleryItem).type === GalleryItemType.VIDEO);
}

export function isGalleryValidVideoItem(
  item: unknown,
): item is GalleryVideoItem & {error?: undefined} {
  return isGalleryVideoItem(item) && !item.payload.error;
}

export function isGalleryBrokenVideoItem(
  item: unknown,
): item is GalleryVideoItem & {error: string} {
  return isGalleryVideoItem(item) && Boolean(item.payload.error);
}

export function isGalleryProcessingVideoItem(item: unknown): item is GalleryVideoItem {
  return isGalleryVideoItem(item) && item.payload.status === 'processing';
}

export function isGalleryImageItem(item: unknown): item is GalleryImageItem {
  return Boolean(isRecord(item) && (item as GalleryItem).type === GalleryItemType.IMAGE);
}
