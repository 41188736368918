import PropTypes from 'prop-types';
import React, {memo} from 'react';
import {Price} from 'components/Price';
import {PointAmount} from 'components/PointAmount';
import {FormattedList} from 'react-intl';
import {isDefAndNotNull} from 'utils/function';
import {rootLocator} from 'utils/rootLocator';
import PriceShape from 'shapes/Price';
import styles from './orderPrice.scss';

const locator = rootLocator.parcelsPage.parcel.prices.price;

export const OrderPrice = memo(function OrderPrice(props) {
  const {value, currency, pointAmountMoney} = props;

  const price = value ? <Price key="price" value={value} format={currency} /> : null;
  const points = pointAmountMoney ? <PointAmount pointAmountMoney={pointAmountMoney} /> : null;

  return (
    <div className={styles.price} {...locator()}>
      <FormattedList value={[price, points].filter(isDefAndNotNull)} />
    </div>
  );
});

OrderPrice.propTypes = {
  currency: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  pointAmountMoney: PriceShape,
};

OrderPrice.defaultProps = {
  pointAmountMoney: undefined,
};
