import {useMatchMediaBreakpointDown} from 'hooks/useMatchMedia';
import React, {ReactElement} from 'react';

type Props = {
  // render must return element or component with only one wrapper element
  render: () => ReactElement;
  breakpointName?: string;
};

export function DesktopRenderer({render, breakpointName = 'sm'}: Props): ReactElement | null {
  const isMobile = useMatchMediaBreakpointDown(breakpointName)?.matches;

  return isMobile ? <div suppressHydrationWarning /> : render?.() || null;
}

export function MobileRenderer({render, breakpointName = 'sm'}: Props): ReactElement | null {
  const isMobile = useMatchMediaBreakpointDown(breakpointName)?.matches;

  return isMobile === false ? <div suppressHydrationWarning /> : render?.() || null;
}
