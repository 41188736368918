import {HelpButton, HelpButtonWidth} from 'components/HelpButton';
import {Html} from 'components/HyperText/Html';
import {Image} from 'components/Image';
import React, {SyntheticEvent} from 'react';
import {StoreDescription} from 'types/StoreDescription';
import {rootLocator} from 'utils/rootLocator';

import styles from './Description.scss';

const locator = rootLocator.productPage.infoAndResponsibility;

export type DescriptionProps = {
  block?: boolean;
  description: StoreDescription;
  responsibilityInfo?: StoreDescription;
};

export function preventDefault(evt: SyntheticEvent): void {
  // Prevent following product page link
  evt.preventDefault();
  // Stop an event propagation to prevent call the click listener of the analytics.
  evt.nativeEvent.stopImmediatePropagation();
}

type DescriptionContentProps = {
  description: StoreDescription;
  responsibilityInfo?: StoreDescription;
};

export const DescriptionContent: React.FC<DescriptionContentProps> = ({
  description,
  responsibilityInfo,
}: DescriptionContentProps) => {
  const descriptionItemsCount = description.length;

  return (
    <div className={styles.description} {...locator.info()}>
      {description.map(({title, text, icon}, index) => (
        <div key={title} className={styles.row} tabIndex={index + 1}>
          {icon ? (
            <div className={styles.icon}>
              <Image
                loadImmediately
                loadingHidden
                contain
                image={icon}
                pxFit={16}
                vwFit={{xs: 16}}
                width="16px"
                height="16px"
              />
            </div>
          ) : null}
          {title ? (
            <div className={styles.title}>
              <Html html={title} />
            </div>
          ) : null}
          {text ? (
            <div className={styles.text}>
              <Html html={text} />
            </div>
          ) : null}
        </div>
      ))}

      {responsibilityInfo?.map(({title, text, icon}, index) => (
        <div
          key={title}
          className={styles.rowBlock}
          tabIndex={descriptionItemsCount + index + 1}
          {...locator.responsibility()}
        >
          {icon ? (
            <div className={styles.icon}>
              <Image
                loadImmediately
                loadingHidden
                contain
                image={icon}
                pxFit={16}
                vwFit={{xs: 16}}
                width="16px"
                height="16px"
              />
            </div>
          ) : null}
          {title ? (
            <div className={styles.title}>
              <Html html={title} />
            </div>
          ) : null}
          {text ? (
            <div className={styles.text}>
              <Html html={text} />
            </div>
          ) : null}
        </div>
      ))}
    </div>
  );
};

export function Description({
  block,
  description,
  responsibilityInfo,
}: DescriptionProps): JSX.Element | null {
  return (
    <HelpButton block={block} tooltipWidth={HelpButtonWidth.L} onOpen={preventDefault}>
      <DescriptionContent description={description} responsibilityInfo={responsibilityInfo} />
    </HelpButton>
  );
}
