import PropTypes from 'prop-types';

import ImageBundle from './ImageBundle';
import VideoBundle from './VideoBundle';

// eslint-disable-next-line import/no-default-export
export default PropTypes.shape({
  type: PropTypes.string.isRequired,
  payload: PropTypes.oneOfType([ImageBundle, VideoBundle]).isRequired,
});
