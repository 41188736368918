import cn from 'classnames';
import {AnyLink} from 'components/AnyLink';
import {Countdown} from 'components/Countdown';
import {Image} from 'components/Image';
import {Text} from 'components/Text';
import {createLocator, Locator, Mark} from 'create-locator';
import {useTimer} from 'hooks/useTimer';
import React, {useCallback, useMemo, useState} from 'react';
import {FormattedNumber} from 'react-intl';
import {isLinearGradient} from 'types/Gradient';
import {
  ProductLiteIconBadge,
  ProductLiteIconBadges,
  ProductLiteSimpleBadge,
  ProductLiteTextBadge,
} from 'types/ProductLite';
import {getImageBundleAspectRatio} from 'utils/image';
import {getStylesByBackgroundColorAndGradient} from 'utils/styles/color';

import styles from './index.scss';

// Simple badge

type SimpleBadgeProps = {
  badge: ProductLiteSimpleBadge;
  loadedTimeMs: number;
};

function SimpleBadge({badge, loadedTimeMs}: SimpleBadgeProps): JSX.Element | null {
  const {text, background} = badge.badge ?? {};
  const {description} = badge;
  const descriptionTimer = description && 'timer' in description ? description.timer : undefined;
  const descriptionText = description && 'text' in description ? description.text : undefined;
  const [timerShown, timestamp] = useTimer(loadedTimeMs, descriptionTimer?.timeRemainingMs);
  const rootStyle = useMemo(
    () => getStylesByBackgroundColorAndGradient(undefined, background),
    [background],
  );

  if (!text && !description) {
    return null;
  }

  return (
    <li className={cn(styles.badge, styles.block)} style={rootStyle}>
      {text && <Text className={styles.badgeTitle} text={text} />}
      {descriptionTimer && timerShown && (
        <span className={styles.badgeTimer}>
          <Countdown timestamp={timestamp} />
        </span>
      )}
      {descriptionText && <Text className={styles.badgeSubtitle} text={descriptionText} />}
    </li>
  );
}

export type SimpleBadgesLocator = Locator<void>;

type SimpleBadgesProps = {
  badges: ProductLiteSimpleBadge[];
  loadedTimeMs: number;
} & Partial<Mark<SimpleBadgesLocator>>;

export function SimpleBadges(props: SimpleBadgesProps): JSX.Element {
  const {badges, loadedTimeMs} = props;
  const locator = createLocator(props);
  return (
    <ul className={styles.badges} {...locator()}>
      {badges.map((badge) => (
        <SimpleBadge badge={badge} key={badge.id} loadedTimeMs={loadedTimeMs} />
      ))}
    </ul>
  );
}

// Icon badge

const ICON_PX_FIT = 14; /* px */
const ICON_VW_FIT = 14; /* px */

const ICON_ONLY_HEIGHT = 20; /* px */

type IconBadgeProps = {
  badge: ProductLiteIconBadge;
};

function IconBadge({badge}: IconBadgeProps): JSX.Element | null {
  const [iconBroken, setIconBroken] = useState(false);
  const rootStyle = useMemo(
    () => getStylesByBackgroundColorAndGradient(undefined, badge.background),
    [badge.background],
  );

  const iconAspectRatio = useMemo(() => getImageBundleAspectRatio(badge.icon), [badge.icon]);

  const iconOnly = !isLinearGradient(badge.background) && !badge.title && !badge.subtitle;

  const handleIconComplete = useCallback((hasError: boolean) => {
    setIconBroken(hasError);
  }, []);

  if (!badge.icon && !badge.title && !badge.subtitle) {
    return null;
  }

  return (
    <li
      className={cn(styles.badge, styles.block, {
        [styles.iconOnly!]: iconOnly,
      })}
      style={rootStyle}
    >
      <AnyLink className={styles.badgeInner} to={badge.deeplink}>
        {badge.icon && !iconBroken && (
          <span className={styles.badgeIcon}>
            <Image
              block
              height={iconOnly ? `${ICON_ONLY_HEIGHT}px` : '100%'}
              image={badge.icon}
              onComplete={handleIconComplete}
              pxFit={ICON_PX_FIT}
              vwFit={ICON_VW_FIT}
              // hack safari a bit
              width={iconOnly ? `${ICON_ONLY_HEIGHT * iconAspectRatio}px` : '100%'}
            />
          </span>
        )}
        {badge.title && <Text className={styles.badgeTitle} text={badge.title} />}
        {badge.subtitle && <Text className={styles.badgeSubtitle} text={badge.subtitle} />}
      </AnyLink>
    </li>
  );
}

export type IconBadgesLocator = Locator<void>;

type IconBadgesProps = {
  badges: ProductLiteIconBadges;
} & Partial<Mark<IconBadgesLocator>>;

export function IconBadges(props: IconBadgesProps): JSX.Element {
  const {badges: badgesConfig} = props;
  const locator = createLocator(props);
  const {badges, maxLines} = badgesConfig;
  const rootStyle = useMemo(
    () =>
      ({
        '--max-lines': maxLines,
      }) as React.CSSProperties,
    [maxLines],
  );

  return (
    <ul className={cn(styles.badges, styles.overflow)} style={rootStyle} {...locator()}>
      {badges.map((badge) => (
        <IconBadge badge={badge} key={badge.id} />
      ))}
    </ul>
  );
}

// Text badge

type TextBadgeProps = {
  badge: ProductLiteTextBadge;
};

function TextBadge({badge}: TextBadgeProps): JSX.Element | null {
  return (
    <li className={styles.badge}>
      {badge.title && <Text className={styles.badgeTitle} text={badge.title} />}
      {badge.subtitle && <Text className={styles.badgeSubtitle} text={badge.subtitle} />}
    </li>
  );
}

export type TextBadgesLocator = Locator<void>;

type TextBadgesProps = {
  badges: ProductLiteTextBadge[];
} & Partial<Mark<TextBadgesLocator>>;

export function TextBadges(props: TextBadgesProps): JSX.Element {
  const {badges} = props;
  const locator = createLocator(props);
  return (
    <ul className={styles.badges} {...locator()}>
      {badges.map((badge, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <TextBadge badge={badge} key={index} />
      ))}
    </ul>
  );
}

// Discount badge

export type DiscountBadgeLocator = Locator<void>;

type DiscountBadgeProps = {
  discount: number;
  greyscale?: boolean;
} & Partial<Mark<DiscountBadgeLocator>>;

export function DiscountBadge(props: DiscountBadgeProps): JSX.Element {
  const {discount, greyscale = false} = props;
  const locator = createLocator(props);

  return (
    <div
      className={cn(styles.badge, styles.discount, greyscale && styles.greyscale)}
      {...locator()}
    >
      <FormattedNumber minimumFractionDigits={0} style="percent" value={discount / -100} />
    </div>
  );
}
