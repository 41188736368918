import {useLinkTarget} from 'hooks/useLinkTarget';
import React, {forwardRef, PropsWithChildren, Ref, SyntheticEvent} from 'react';
import {Link} from 'react-router-dom';

import styles from './index.scss';

type Props = PropsWithChildren<{
  onClick: (event: SyntheticEvent) => void;
  onPreClick: (event: SyntheticEvent) => void;
  target?: string;
  to: string;
}>;

export const Button = forwardRef(function Button(
  {children, onClick, onPreClick, target, to}: Props,
  ref: Ref<HTMLAnchorElement>,
) {
  const linkTarget = useLinkTarget(target);
  return (
    <Link
      className={styles.button}
      onClick={onClick}
      onMouseDown={onPreClick}
      onTouchStart={onPreClick}
      target={linkTarget}
      to={to}
      ref={ref}
    >
      <span className={styles.buttonLabel}>{children}</span>
    </Link>
  );
});
