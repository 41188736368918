import {ContentList} from 'components/ContentList';
import {Locator} from 'components/Locator';
import {Overlay} from 'components/Overlay';
import {PropsHolder} from 'components/PropsHolder';
import {Popup} from 'components/UIKit/Popup';
import {Footer} from 'components/UIKit/Popup/Footer';
import {StandaloneControls} from 'components/UIKit/Popup/Header/StandaloneControls';
import {PopupViewProps, PopupViewRender} from 'components/UIKit/Popup/PopupProvider';
import {PopupStack} from 'components/UIKit/Popup/PopupStack';
import {useAnalytics} from 'hooks/useAnalytics';
import React, {useCallback, useEffect} from 'react';
import {ContentListItem, ContentListItemType} from 'types/ContentList';
import {Error} from 'types/Error';

import {ContentListPopupError} from './Error';
import styles from './styles.scss';

export type Props = {
  contentList?: ContentListItem[] | null;
  loading?: boolean;
  error?: Error;
  source?: string;
  url?: string;
  footer?: React.ReactNode;
  width?: string;
  containerMargins?: string;
  renderItemByType?: (type: ContentListItemType, item: ContentListItem) => React.ReactNode;
};

export const ContentListPopupBase: React.FC<Props & PopupViewProps> = ({
  onBack,
  onClose,
  loading,
  error,
  contentList,
  source,
  url,
  footer,
  width = '735px',
  containerMargins,
  renderItemByType,
}) => {
  const analytics = useAnalytics();

  useEffect(() => {
    if (source && url) {
      analytics.sendEvent({
        type: 'contentListOpen',
        payload: {
          url,
          params: {source},
        },
      });
    }

    return () => {
      if (source && url) {
        analytics.sendEvent({
          type: 'contentListClose',
          payload: {
            url,
            params: {source},
          },
        });
      }
    };
  }, [analytics, source, url]);

  const containerStyle = containerMargins ? {margin: containerMargins} : undefined;

  return (
    <Popup width={width}>
      <StandaloneControls onClose={onClose} onBack={onBack} sticky />
      {error ? (
        <ContentListPopupError error={error} />
      ) : (
        <PropsHolder hold={Boolean(!contentList)} contentList={contentList}>
          {(heldProps) => (
            <Locator id="ContentListPopup">
              <div className={styles.container} style={containerStyle} data-scrolllockignore>
                {heldProps.contentList && (
                  <Overlay loading={loading}>
                    <ContentList
                      isNarrow
                      items={heldProps.contentList}
                      source={source}
                      renderItemByType={renderItemByType}
                    />
                  </Overlay>
                )}
              </div>
            </Locator>
          )}
        </PropsHolder>
      )}
      {Boolean(footer) && <Footer>{footer}</Footer>}
    </Popup>
  );
};

export function useContentListPopupRender({contentList, loading}: Props): PopupViewRender {
  return useCallback(
    ({action, onClose, onBack}: PopupViewProps) => (
      <ContentListPopupBase
        contentList={contentList}
        action={action}
        onClose={onClose}
        onBack={onBack}
        loading={loading}
      />
    ),
    [contentList, loading],
  );
}

export function ContentListPopup(props: Props): React.ReactElement {
  const render = useContentListPopupRender(props);
  return <PopupStack render={render} />;
}
