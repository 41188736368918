import PropTypes from 'prop-types';

export const MapCoordinates = PropTypes.shape({
  latitude: PropTypes.number.isRequired,
  longitude: PropTypes.number.isRequired,
});

export const MapBounds = PropTypes.shape({
  west: PropTypes.number.isRequired,
  north: PropTypes.number.isRequired,
  east: PropTypes.number.isRequired,
  south: PropTypes.number.isRequired,
});

export const MapMarkerShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  latitude: PropTypes.number.isRequired,
  longitude: PropTypes.number.isRequired,
  active: PropTypes.bool,
  clickable: PropTypes.bool,
  type: PropTypes.oneOf(['X5-CASHBOX', 'X5-POSTAMAT']),
});

export const MapCenterShape = PropTypes.shape({
  value: MapCoordinates,
});

export const MapCenterPropType = PropTypes.oneOfType([MapCoordinates, MapCenterShape]);

export const MapZoomShape = PropTypes.shape({
  value: PropTypes.number.isRequired,
  minBounds: MapBounds,
});

export const MapZoomPropType = PropTypes.oneOfType([PropTypes.number, MapZoomShape]);
