import {isNumber} from 'utils/guards';

import {MediaState, UploaderErrorType, UploaderOptions} from '../types';
import {createUploaderError} from './createUploaderError';
import {getSupportedMimeTypes} from './getSupportedMimeTypes';
import {isMimeTypeSupported} from './isMimeTypeSupported';

export const validateMediaState = (
  mediaUuid: string,
  mediaState: MediaState,
  {filters}: UploaderOptions,
): void => {
  const mediaFile = mediaState.file;

  if (mediaFile) {
    const filter = filters.find((filter) => isMimeTypeSupported(filter, mediaFile.type));

    // Expect the file type to be supported.
    if (!filter) {
      const supportedMimeTypes = getSupportedMimeTypes(filters);

      throw createUploaderError(UploaderErrorType.FILE_TYPE_NOT_SUPPORTED, {
        supportedMimeTypes,
      });
    }

    // Expect the file size to be greater than or equal to the minimum posible size.
    if (isNumber(filter.minBytes) && mediaFile.size < filter.minBytes) {
      throw createUploaderError(UploaderErrorType.TOO_SMALL_FILE, {
        minBytes: filter.minBytes,
      });
    }

    // Expect the file size to be less than or equal to the maximum posible size.
    if (isNumber(filter.maxBytes) && mediaFile.size > filter.maxBytes) {
      throw createUploaderError(UploaderErrorType.TOO_BIG_FILE, {
        maxBytes: filter.maxBytes,
      });
    }
  }
};
