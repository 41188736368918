import classnames from 'classnames';
import React from 'react';
import {PromoProgressBar as PromoProgressBarType} from 'types/ProductLite';
import {convertBackendColorToCSSValue} from 'utils/styles/color';

import styles from './index.scss';

type Props = {
  promoProgressBar: PromoProgressBarType;
  greyscale?: boolean;
};

export const PromoProgressBar = ({promoProgressBar, greyscale}: Props): JSX.Element => {
  return (
    <div className={styles.progressBarWrapper}>
      <span
        className={classnames(styles.progressBarText, {[styles.greyscale!]: greyscale})}
        style={{
          color: convertBackendColorToCSSValue(promoProgressBar.title.color),
        }}
      >
        {promoProgressBar.title.text}
      </span>
      <div
        className={styles.progressBarTrack}
        style={{backgroundColor: convertBackendColorToCSSValue(promoProgressBar.trackColor)}}
      >
        <div
          className={styles.progressBarProgress}
          style={{
            width: `${promoProgressBar.progress * 100}%`,
            backgroundColor: convertBackendColorToCSSValue(promoProgressBar.progressColor),
          }}
        />
      </div>
    </div>
  );
};
