import {requestActionCreator} from 'store/utils/requestActions';
import {FuncAction} from 'typesafe-actions';

import {load, LoadResponse} from './actions';

type LoadResponseBody = {
  payload: LoadResponse['links'];
};

export function loadCategoryPromoLinks(): FuncAction {
  return requestActionCreator(load, {}, (store, client) =>
    client.api.get<LoadResponseBody>('/navigation/web').then(({language, body: {payload}}) => ({
      links: payload,
      language,
    })),
  );
}
