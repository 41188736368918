import classnames from 'classnames/bind';
import {Locator} from 'components/Locator';
import {MediaPreview} from 'components/MediaPreview';
import React, {useCallback} from 'react';
import {GalleryItem, isGalleryVideoItem} from 'types/GalleryItem';
import {VwFit} from 'types/VwFit';
import {getGalleryItemKey} from 'utils/gallery';

import styles from './index.scss';
import {useEnlargedMediaMWeb} from './useEnlargedMedia';

const MAX_MEDIA_AMOUNT = 5;

const cn = classnames.bind(styles);

function getMediaVwFit(enlargedMedia: boolean, mediaLength: number, index: number): VwFit {
  if (enlargedMedia) {
    if (mediaLength === 1 || (index === 0 && mediaLength > 2)) {
      return {md: 100};
    }
    if (mediaLength === 2) {
      return {md: 50};
    }
    if (mediaLength > 2 && index > 0) {
      return {md: Math.floor(100 / Math.min(MAX_MEDIA_AMOUNT - 1, mediaLength - 1))};
    }
  }

  return {xs: '72px', md: '64px'};
}

type Props = {
  alt?: string;
  index: number;
  item: GalleryItem;
  onClick?: (item: GalleryItem, index: number) => void;
  pxFit?: number;
  mediaLength: number;
};

function getToppingRatio(length: number): string {
  if (length === 3) {
    return '50%';
  }
  if (length >= 4) {
    return '65%';
  }
  return '100%';
}

export function MediaItem({
  alt = '',
  index,
  item,
  onClick,
  pxFit,
  mediaLength,
}: Props): React.ReactElement {
  const enlargedMedia = useEnlargedMediaMWeb();
  const handleClick = useCallback(() => {
    onClick?.(item, index);
  }, [index, item, onClick]);

  const isVideoItem = isGalleryVideoItem(item);

  const halfWidth = enlargedMedia && mediaLength === 2;
  const isTopping = enlargedMedia && index === 0 && mediaLength > 2;
  const toppingRatio = getToppingRatio(mediaLength);
  const moreAmount =
    enlargedMedia && index === MAX_MEDIA_AMOUNT - 1 && mediaLength > MAX_MEDIA_AMOUNT
      ? mediaLength - 1 - index
      : undefined;

  return (
    <Locator id="orderReview-reviewMediaAttach">
      <button
        className={cn('media', {
          video: isVideoItem,
          toppingItem: isTopping,
          halfWidth,
        })}
        onClick={handleClick}
        type="button"
        style={{
          paddingTop: isTopping ? toppingRatio : undefined,
        }}
      >
        <div className={styles.mediaInner}>
          <MediaPreview
            alt={alt}
            cover
            key={getGalleryItemKey(item)}
            index={index}
            item={item}
            pxFit={pxFit}
            vwFit={getMediaVwFit(enlargedMedia, mediaLength, index)}
            square={!isTopping}
            darkenVideo={enlargedMedia}
            moreAmount={moreAmount}
            layoutBgInherit
            fixedIconSizes={enlargedMedia}
          />
        </div>
      </button>
    </Locator>
  );
}
