import styles from 'components/ClientProduct/Reviews/DescriptionButton/index.scss';
import {LayoutRow} from 'components/LayoutRow';
import {eventParamsList} from 'helpers/eventParams';
import {usePreviewEvent} from 'hooks/usePreviewEvent';
import React, {useRef} from 'react';
import {ClientProduct} from 'types/ProductNext';
import {ReviewsDescriptionButton} from 'types/ProductReviews';

type Props = {
  reviewsDescriptionButton: ReviewsDescriptionButton;
  productId: ClientProduct['header']['id'];
};

export const DescriptionButton: React.FC<Props> = ({
  reviewsDescriptionButton,
  productId,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  const eventPayload = {
    payload: {
      productId,
    },
  };
  const eventParams = eventParamsList(reviewsDescriptionButton.eventParams);

  usePreviewEvent(
    {
      rootRef: ref,
      previewEvent: {
        type: 'productReviewTermsPreview',
        params: eventParams,
        ...eventPayload,
      },
    },
    {
      clickEvent: {
        type: 'productReviewTermsClick',
        ...eventPayload,
        params: eventParams,
      },
    },
  );

  return (
    <div ref={ref} className={styles.descriptionButtonBlock}>
      <LayoutRow row={reviewsDescriptionButton} />
    </div>
  );
};
