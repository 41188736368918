import productCols from './gridExports.scss';

function getSize(name: string, ratio: number, px = false): string {
  const breakpoint = productCols[name];

  if (px) {
    const containerSize = parseInt(productCols[name] || '', 10);
    const gap = parseInt(productCols[`${name}gap`] || '', 10);
    const px = Math.round(containerSize * ratio - gap);

    return `(min-width: ${breakpoint}) ${px}px`;
  }

  const vw = Math.round(100 * ratio);

  return `(min-width: ${breakpoint}) ${vw}vw`;
}

export const NARROW4 = {
  // prettier-ignore
  sizes: [
    getSize('cols6', 1 / 4, true),
    getSize('cols4', 1 / 4),
    getSize('cols3', 1 / 3),
    getSize('cols2', 1 / 2),
  ].join(', '),
};

export const NARROW4HALF = {
  // prettier-ignore
  sizes: [
    getSize('cols6', 1 / 4 / 2, true),
    getSize('cols4', 1 / 4 / 2),
    getSize('cols3', 1 / 3 / 2),
    getSize('cols2', 1 / 2 / 2),
  ].join(', '),
};

export const WIDE4 = {
  // prettier-ignore
  sizes: [
    getSize('cols6', 1 / 4, true),
    getSize('cols4', 1 / 4),
    getSize('cols3', 1 / 3),
    getSize('cols2', 1 / 2),
  ].join(', '),
};

export const WIDE4HALF = {
  // prettier-ignore
  sizes: [
    getSize('cols6', 1 / 4 / 2, true),
    getSize('cols4', 1 / 4 / 2),
    getSize('cols3', 1 / 3 / 2),
    getSize('cols2', 1 / 2 / 2),
  ].join(', '),
};

export const WIDE6 = {
  // prettier-ignore
  sizes: [
    getSize('cols6', 1 / 6, true),
    getSize('cols4', 1 / 4),
    getSize('cols3', 1 / 3),
    getSize('cols2', 1 / 2),
  ].join(', '),
};

export const WIDE6HALF = {
  // prettier-ignore
  sizes: [
    getSize('cols6', 1 / 6 / 2, true),
    getSize('cols4', 1 / 4 / 2),
    getSize('cols3', 1 / 3 / 2),
    getSize('cols2', 1 / 2 / 2),
  ].join(', '),
};
