import {useRef} from 'react';

// use only when u have not other variants
export function useMutableCallback<Args extends unknown[], Result>(
  callback: (...args: Args) => Result,
): typeof callback {
  const callbackRef = useRef(callback);
  callbackRef.current = callback;

  // useRef instead useCallback because useCallback may clear its state in rare cases
  const mutableRef = useRef((...args: Args) => callbackRef.current(...args));
  return mutableRef.current;
}
