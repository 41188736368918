import {PopupResponse} from 'types/Popup';

import {ColoredText} from './ColoredText';
import {Gradient} from './Gradient';
import {ImageBundle} from './Image';
import {Button} from './Layout';
import {SocialUser} from './SocialUser';

export enum CommonProfileBannerPlace {
  PROFILE_MENU = 'profileMenu',
  ORDERS_LIST = 'ordersList',
}

export type CommonProfileBanner = {
  icon: ImageBundle;
  title: ColoredText;
  description: ColoredText;
  button: Button;
  background?: Gradient;
  eventParams?: string;
  places?: CommonProfileBannerPlace[];
};

export type BackendUser = {
  id: string;
  fullName: string;
  avatar?: ImageBundle;
  socialUserId?: string;
  deviceId: string;
  anonymous: boolean;
  admin?: boolean;
  firstName: string;
  lastName: string;
  name?: string;
  email?: string;
  gender: string;
  isPremium?: boolean;
  googleId?: string;
  facebookId?: string;
  okId?: string;
  vkId?: string;
  followersCount?: number;
  followingCount?: number;
  postsCount?: number;
  referralProgramParticipant?: boolean;
  ephemeral: boolean;
  paidPostsAccess?: boolean;
  pointsDisabled?: boolean;
  isAdult?: boolean;
  isVerified: boolean;
  passportRequired?: boolean;
  socialUser?: SocialUser;
  banners?: CommonProfileBanner[];
  jmtMigrationRecovery?: {
    url: string;
  };
};

export type User = BackendUser;

export type UserWithPopup = User & {popup?: PopupResponse};
