import React, {memo, useMemo} from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import {FormattedDate} from 'components/i18n/FormattedDate';
import {Locator} from 'components/Locator';
import {ShippingEstimateShape} from 'shapes/ShippingEstimate';
import styles from './consolidationHeader.scss';

export const ConsolidationHeaderText = memo(
  ({statusAppearanceText, deliveredTimeMs, shippingEstimate}) => {
    const deliveryEstimateMessage = useMemo(() => {
      if (!shippingEstimate) return null;

      return shippingEstimate.min !== 0 ? (
        <span>
          <FormattedMessage
            description="Parcel header, parcel delivery shipping interval"
            defaultMessage="{shippingMinTimeMs} – {shippingMaxTimeMs}"
            values={{
              shippingMinTimeMs: (
                <FormattedDate
                  day="2-digit"
                  month="2-digit"
                  year="2-digit"
                  value={shippingEstimate.min}
                />
              ),
              shippingMaxTimeMs: (
                <FormattedDate
                  day="2-digit"
                  month="2-digit"
                  year="2-digit"
                  value={shippingEstimate.max}
                />
              ),
            }}
          />
        </span>
      ) : (
        <span>
          <FormattedMessage
            description="Parcel header, parcel delivery 'shipping before' text"
            defaultMessage="by {shippingMaxTimeMs}"
            values={{
              shippingMaxTimeMs: (
                <FormattedDate
                  day="2-digit"
                  month="2-digit"
                  year="2-digit"
                  value={shippingEstimate.max}
                />
              ),
            }}
          />
        </span>
      );
    }, [shippingEstimate]);

    if (statusAppearanceText) {
      return statusAppearanceText;
    }

    if (deliveredTimeMs) {
      return (
        <>
          <span className={styles.shippingLabel}>
            <FormattedMessage
              defaultMessage="Delivered"
              description="Parcel header, delivery label"
            />
          </span>
          <FormattedDate
            day="numeric"
            key="date"
            month="long"
            year="numeric"
            value={deliveredTimeMs}
          />
        </>
      );
    }

    if (!shippingEstimate) {
      return (
        <FormattedMessage
          defaultMessage="Order status"
          description="Parcel header, order status label"
        />
      );
    }

    return (
      <>
        <span className={styles.shippingLabel}>
          <FormattedMessage
            defaultMessage="Delivery"
            description="Parcel header, delivery estimate label"
          />
        </span>
        <Locator id="order-delivery-estimates">{deliveryEstimateMessage}</Locator>
      </>
    );
  },
);

ConsolidationHeaderText.propTypes = {
  deliveredTimeMs: PropTypes.number,
  shippingEstimate: ShippingEstimateShape,
  statusAppearanceText: PropTypes.string,
};

ConsolidationHeaderText.defaultProps = {
  deliveredTimeMs: null,
  shippingEstimate: null,
  statusAppearanceText: '',
};
