import PropTypes from 'prop-types';
import {OrderBannerShape} from 'shapes/OrderBanner';
import OrderLite from 'shapes/OrderLite';
import OrderTotalPrice from 'shapes/OrderTotalPrice';
import {ParcelStatusAppearanceShape} from 'shapes/ParcelStatusAppearance';
import {ReturnInfoShape} from 'shapes/ReturnInfo';
import {OrderReviewInfoShape} from './OrderReviewInfo';

export const ParcelOrderShape = PropTypes.shape({
  order: OrderLite.isRequired,
  quantity: PropTypes.number.isRequired,
  total: OrderTotalPrice.isRequired,
  action: PropTypes.shape({
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }),
  statusAppearance: ParcelStatusAppearanceShape,
  banners: PropTypes.arrayOf(OrderBannerShape),
  returnInfo: ReturnInfoShape,
  reviewInfo: OrderReviewInfoShape,
  showSpecialPriceIcon: PropTypes.bool,
});
