/**
 * Returns whether current process runs in Storycap browser.
 * It's useful to change your stories' behavior only in
 * Storycap (e.g. disable JavaScript animation).
 * See: storycap/lib/client/is-screenshot
 */
export function isScreenshot(): boolean {
  // eslint-disable-next-line
  return Boolean((global as {emitCapture?: unknown})['emitCapture']);
}
