import classNames from 'classnames';
import {useScope} from 'hooks/useScope';
import {A11yContext} from 'providers/A11yContext';
import {NoOutlineContext} from 'providers/NoOutlineContext';
import React, {useContext} from 'react';
import {
  Helmet as ReactHelmet,
  HelmetProps,
  HtmlProps,
  LinkProps,
  MetaProps,
} from 'react-helmet-async';
import {objectFilter} from 'utils/object';

const defaultMeta: MetaProps[] = [];
const defaultLink: LinkProps[] = [];
const defaultHtmlAttributes: HtmlProps = {};

function concatNonEmpty<T>(...items: Array<null | undefined | Array<T>>): Array<T> {
  return items.reduce((next: Array<T>, list) => {
    if (list) {
      return next.concat(list.filter((item) => item !== null && item !== undefined));
    }
    return next;
  }, []);
}

type Props = {
  htmlAttributes?: HtmlProps;
  meta?: MetaProps[];
  link?: LinkProps[];
  lang?: string;
} & Record<string, unknown>;

export const Helmet = ({
  htmlAttributes,
  meta,
  link,
  lang,
  ...restProps
}: Props & HelmetProps): JSX.Element => {
  const noOutline = useContext(NoOutlineContext);
  const {
    settings: {highContrast},
  } = useContext(A11yContext);
  const scope = useScope();

  return (
    <ReactHelmet
      meta={concatNonEmpty(defaultMeta, meta)}
      htmlAttributes={{
        ...defaultHtmlAttributes,
        ...htmlAttributes,
      }}
      link={concatNonEmpty(defaultLink, link)}
      bodyAttributes={objectFilter(
        {
          lang: lang || undefined,
          class: classNames({
            noOutline,
            highContrast,
            [`${scope.topScope}Scope`]: true,
          }),
        },
        Boolean,
      )}
      {...restProps}
    />
  );
};
