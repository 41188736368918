import React from 'react';
import {ColoredText} from 'types/ColoredText';
import {getInlineColor} from 'utils/styles/color';

type Props = {
  className?: string;
  text: ColoredText;
};

export function Text({className, text: {text, color}}: Props): JSX.Element {
  return (
    <span className={className} style={getInlineColor(color)}>
      {text}
    </span>
  );
}
