export const SUPPORTED_BARCODE_TYPES = [
  'CODE39',
  'CODE128',
  'CODE128A',
  'CODE128B',
  'CODE128C',
  'EAN13',
  'EAN8',
  'EAN5',
  'EAN2',
  'UPC',
  'ITF14',
  'ITF',
  'MSI',
  'MSI10',
  'MSI11',
  'MSI1010',
  'MSI1110',
  'PHARMACODE',
  'CODABAR',
];
export const DEFAULT_BARCODE_TYPE = 'CODE39';
