import noop from 'lodash/noop';
import React from 'react';

export type SliderElement =
  | React.RefObject<HTMLDivElement>
  | React.MutableRefObject<HTMLDivElement>;

export type SliderElementsSelected = Array<boolean>;

export type SliderElements = Array<SliderElement>;

export type SliderElementsSetter = (elements: SliderElements) => SliderElements;

export type SliderElementsSetterProxy = (cb: SliderElementsSetter) => void;

export type SliderElementsContext = {
  selected: SliderElementsSelected;
  elements: SliderElements;
  updateElements: SliderElementsSetterProxy;
  handleNext: () => void;
};

export const SliderElementsContext = React.createContext<SliderElementsContext>({
  selected: [],
  elements: [],
  handleNext: noop,
  updateElements: (cb) => cb([]),
});
