import classnames from 'classnames/bind';
import Button from 'components/Button';
import {ContextMenu, ContextMenuPlacement} from 'components/ContextMenu';
import {DotLoader, DotLoaderStyle} from 'components/DotLoader';
import ErrorMessage from 'components/ErrorMessage';
import Arrow from 'components/Language/arrow.jsx.svg';
import {Locator} from 'components/Locator';
import {useSelector} from 'hooks/redux';
import {useAnalytics} from 'hooks/useAnalytics';
import {useDispatchAction} from 'hooks/useDispatch';
import omit from 'lodash/omit';
import React, {useCallback, useEffect, useRef} from 'react';
import useDropdownMenu from 'react-accessible-dropdown-menu-hook';
import {
  getCurrencies,
  getCurrenciesError,
  isCurrenciesLoading,
  loadCurrencies,
} from 'store/modules/currencies';
import {isNumber} from 'utils/guards';

import styles from './index.scss';

const cn = classnames.bind(styles);

export enum CurrencyType {
  BUTTON = 'button',
}

type Props = {
  currency: string;
  onChange(currency: string): void;
  type?: CurrencyType;
  from: 'header' | 'sidebar' | 'footer';
};

export function Currency({currency, onChange, type, from}: Props): JSX.Element {
  const analytics = useAnalytics();
  const triggerRef = useRef(null);
  const currencies = useSelector(getCurrencies);
  const error = useSelector(getCurrenciesError);
  const loading = useSelector(isCurrenciesLoading);

  const {buttonProps, itemProps, isOpen, setIsOpen, moveFocus} = useDropdownMenu<HTMLButtonElement>(
    currencies?.length || 0,
    {
      disableFocusFirstItemOnClick: true,
      handleItemKeyboardSelect: (e) => {
        e.preventDefault();
        e.currentTarget.click();
      },
    },
  );

  const load = useDispatchAction(loadCurrencies);

  const handleContextMenuClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const handleClick = useCallback(() => {
    if (!currencies && !loading) {
      load();
    }

    analytics.sendEvent({
      type: 'currencyClick',
      payload: {from},
    });

    setIsOpen(true);
  }, [analytics, currencies, from, load, loading, setIsOpen]);

  // this does two things.
  // first, it moves focus to currently selected item
  // second, it does it after Popper sets the position of the menu so the sidebar does not jump to the top, closing the menu
  // no, useLayoutEffect does nothing.
  // yes, this is madness.
  useEffect(() => {
    if (isOpen) {
      const index = currencies?.findIndex((item) => item === currency);

      if (isNumber(index) && index !== -1) {
        setTimeout(() => {
          moveFocus(index);
        }, 16);
      }
    }
  }, [currencies, currency, isOpen, moveFocus]);

  const handleCurrencyClick = useCallback(
    (selected: string) => {
      if (currency !== selected) {
        analytics.dataLayer({
          event: 'Header. Change Currency',
          currency: selected,
        });

        onChange(selected);
      }

      setIsOpen(false);
    },
    [analytics, currency, onChange, setIsOpen],
  );

  const renderContextMenuContent = useCallback(() => {
    if (loading) {
      return (
        <div className={styles.loading}>
          <DotLoader style={DotLoaderStyle.DARK} />
        </div>
      );
    }

    if (error) {
      return (
        <div className={styles.error}>
          <ErrorMessage error={error} noRequestId internal />
        </div>
      );
    }

    if (currencies) {
      return (
        <div className={styles.list}>
          <Locator id="CurrencyMenu">
            <div className={styles.inner} role="menu">
              {currencies.map((item, index) => (
                <div className={styles.item} key={item}>
                  <Locator id="CurrencyMenuItem">
                    <button
                      {...itemProps[index]}
                      className={cn(styles.currency, {
                        [styles.currencySelected!]: item === currency,
                      })}
                      type="button"
                      // eslint-disable-next-line react/jsx-no-bind
                      onClick={() => handleCurrencyClick(item)}
                    >
                      {item}
                    </button>
                  </Locator>
                </div>
              ))}
            </div>
          </Locator>
        </div>
      );
    }

    return null;
  }, [currencies, currency, error, handleCurrencyClick, itemProps, loading]);

  const renderContextMenu = useCallback(
    (placement?: ContextMenuPlacement) => {
      if (!isOpen) {
        return null;
      }

      return (
        <ContextMenu
          onClose={handleContextMenuClose}
          usePortal={false}
          placement={placement}
          triggerRefs={[triggerRef]}
        >
          <div className={styles.content}>{renderContextMenuContent()}</div>
        </ContextMenu>
      );
    },
    [handleContextMenuClose, isOpen, renderContextMenuContent],
  );

  if (type === CurrencyType.BUTTON) {
    return (
      <Locator id="Currency" isMobile="true">
        <div className={styles.parent} ref={triggerRef}>
          <Button
            type="button"
            {...omit(buttonProps, 'onKeyDown', 'onClick')}
            onClick={handleClick}
            block
            color="lightgrey"
          >
            {currency}
          </Button>
          {renderContextMenu(ContextMenuPlacement.TOP)}
        </div>
      </Locator>
    );
  }

  return (
    <Locator id="Currency">
      <div className={styles.selectWrapper} ref={triggerRef}>
        <button
          // I have no clue on why they explicitly set onKeyDown, todo refactor lib
          {...omit(buttonProps, 'onKeyDown', 'onClick')}
          type="button"
          onClick={handleClick}
          className={cn('button', 'selectButton')}
        >
          {currency}
          <Arrow className={styles.selectArrow} />
        </button>
        {renderContextMenu()}
      </div>
    </Locator>
  );
}
