import cn from 'classnames';
import {Background} from 'components/ContentList/Background';
import {BannerProps} from 'components/ContentList/BannersList/BannerProps';
import {BannerWrapper} from 'components/ContentList/BannerWrapper';
import {useBannerPreview} from 'components/ContentList/hooks';
import {Image} from 'components/Image';
import {Square} from 'components/Square';
import React, {forwardRef, ReactElement, Ref, useMemo, useRef} from 'react';
import {BannersListItem} from 'types/ContentList/BannersList';
import {getInlineColor} from 'utils/styles/color';

import contentListStyles from '../../index.scss';
import styles from './CardGrid.scss';

const CardGridItem = forwardRef(function CardGridItem(
  {item, imageProps, source}: {item: BannersListItem} & Pick<BannerProps, 'imageProps' | 'source'>,
  rootRef?: Ref<HTMLDivElement>,
): ReactElement {
  const {eventParams} = item;
  const contentRef = useRef<HTMLDivElement>(null);
  const showBackgroundBelowText = Boolean(item.appearance?.showBackgroundBelowText);

  useBannerPreview({
    bannerId: item.id,
    eventParams,
    rootRef: contentRef,
    source,
  });

  const textStyle = useMemo(
    () => getInlineColor(item.appearance?.textColor),
    [item.appearance?.textColor],
  );
  const text = (
    <div className={styles.text} style={textStyle}>
      <div className={styles.title}>{item.title}</div>
      {item.subtitle && <div className={styles.subtitle}>{item.subtitle}</div>}
    </div>
  );

  return (
    <div ref={rootRef} className={contentListStyles.cell}>
      <div
        ref={contentRef}
        className={cn(styles.root, {[styles.showBackgroundBelowText!]: showBackgroundBelowText})}
      >
        <BannerWrapper stretch noflex to={item.payload.url} source={source}>
          <Square className={styles.square}>
            <div className={styles.content}>
              <Background gradient={item.gradient} />
              <Image
                alt={item.title}
                className={styles.image}
                contain
                image={item.background}
                maxSourceSize={600}
                transparentLoading
                lcpImageCandidate
                {...imageProps}
              />
              {showBackgroundBelowText && text}
            </div>
          </Square>
          {!showBackgroundBelowText && text}
        </BannerWrapper>
      </div>
    </div>
  );
});

export const CardGrid = forwardRef(function CardGrid(
  {items, imageProps, source}: BannerProps,
  rootRef?: Ref<HTMLDivElement>,
): ReactElement {
  return (
    <>
      {items.map((item, index) => (
        // because this banner hasn't container, pass rootRef to first item
        <CardGridItem
          ref={!index ? rootRef : undefined}
          key={item.id}
          {...{item, imageProps, source}}
        />
      ))}
    </>
  );
});
