import React from 'react';
import {FormattedMessage} from 'react-intl';

type Props = {
  value: number | Array<number>;
};

export function DaysMessage({value}: Props): React.ReactElement {
  const isArray = Array.isArray(value);
  const min = isArray ? value[0] : value;
  const max = isArray ? value[1] : value;

  if (min === max || min === undefined || max === undefined) {
    const count = min || max;
    return (
      <FormattedMessage
        description="Shipping time min === max"
        defaultMessage={`{count, plural,
  one {# day}
  other {# days}
}`}
        values={{count}}
      />
    );
  }

  return (
    <FormattedMessage
      description="Shipping time"
      defaultMessage={`{min} – {max, plural,
  one {# day}
  other {# days}
}`}
      values={{min, max}}
    />
  );
}
