import {MediaState, MediaStatus} from '../types';
import {getMediaTypeByFile} from './getMediaTypeByFile';

export const createMediaStateByFile = (uuid: string, file: File): MediaState => {
  return {
    bytesTotal: file.size,
    bytesUploaded: 0,
    file,
    status: MediaStatus.WILL_BE_UPLOADED,
    type: getMediaTypeByFile(file),
    uuid,
  };
};
