import {DialogButtons, DialogContent} from 'components/UIKit/Dialog';
import {Popup} from 'components/UIKit/Popup';
import {StandaloneControls} from 'components/UIKit/Popup/Header/StandaloneControls';
import {PopupViewRender} from 'components/UIKit/Popup/PopupProvider';
import React from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';

const messages = defineMessages({
  title: {
    description:
      '[title] Заголовок в попапе с предупреждением возможной потери данных при выходе из аккаунта',
    defaultMessage: 'Important! Save your data.',
  },
  text: {
    description: 'Текст в попапе с предупреждением возможной потери данных при выходе из аккаунта',
    defaultMessage:
      "Log in again so you don't lose access to your account. If you don't, you won't be able to log into your account again.",
  },
  continueButton: {
    description:
      '[button] Кнопка продолжения в попапе с предупреждением возможной потери данных при выходе из аккаунта',
    defaultMessage: 'Continue',
  },
  signoutButton: {
    description:
      '[button] Кнопка выхода в попапе с предупреждением возможной потери данных при выходе из аккаунта',
    defaultMessage: 'Log out',
  },
});

type Props = {
  onContinue: () => void;
  onSignout: () => void;
};

export function getJmtMigrationSignoutPopupRender({onContinue, onSignout}: Props): PopupViewRender {
  return ({onClose, onBack}) => (
    <Popup width="500px">
      <StandaloneControls onBack={onBack} onClose={onClose} />
      <DialogContent
        title={<FormattedMessage {...messages.title} />}
        text={<FormattedMessage {...messages.text} />}
      >
        <DialogButtons
          buttons={[
            {
              color: 'accent',
              onClick() {
                onClose();
                onContinue();
              },
              children: <FormattedMessage {...messages.continueButton} />,
            },
            {
              color: 'gray',
              onClick() {
                onClose();
                onSignout();
              },
              children: <FormattedMessage {...messages.signoutButton} />,
            },
          ]}
        />
      </DialogContent>
    </Popup>
  );
}
