import {ParcelLiteShape} from 'shapes/ParcelLite';
import React, {memo} from 'react';
import {ParcelTrackingShape} from 'shapes/ParcelTracking';
import {getShippingEstimate, getDeliveredTimeMs} from 'store/modules/parcel';
import {ParcelPageCard, ParcelPageCardInner} from 'components/ParcelPageCard';
import {ParcelBarcode} from 'components/ParcelBarcode';
import {useIsMobileOrTablet} from 'hooks/useUserAgent';
import {Checkpoints} from './Checkpoints';
import {State} from './State';
import {StateDate} from './StateDate';
import styles from './index.scss';

export const ParcelShipping = memo(({parcel, parcelTracking}) => {
  const deliveredTimeMs = getDeliveredTimeMs(parcel);
  const shippingEstimate = getShippingEstimate(parcel);
  const isMobileOrTablet = useIsMobileOrTablet();

  return (
    <ParcelPageCard>
      <ParcelPageCardInner>
        <div className={styles.info}>
          <div className={styles.infoText}>
            {parcelTracking && <State parcel={parcel} parcelTracking={parcelTracking} />}
            <StateDate
              shippingMinTimeMs={shippingEstimate?.min}
              shippingMaxTimeMs={shippingEstimate?.max}
              deliveredTimeMs={deliveredTimeMs}
            />
          </div>
          {isMobileOrTablet && parcelTracking?.barcode && (
            <div className={styles.infoBarcode}>
              <ParcelBarcode barcode={parcelTracking.barcode} />
            </div>
          )}
        </div>
        {parcelTracking?.checkpoints?.length ? (
          <Checkpoints checkpoints={parcelTracking.checkpoints} />
        ) : null}
      </ParcelPageCardInner>
    </ParcelPageCard>
  );
});

ParcelShipping.defaultProps = {
  parcelTracking: null,
};

ParcelShipping.propTypes = {
  parcel: ParcelLiteShape.isRequired,
  parcelTracking: ParcelTrackingShape,
};
