import {PageSource} from 'types/PageSource';

export enum CategorySource {
  BEST = 'best',
  DEFAULT = 'other',
  FAVORITES = 'favorites',
  PROMOTION = 'promotion',
  SEARCH = 'search',
  STORE = 'store',
}

export enum CategoryFrom {
  POPUP = 'popup',
  HEADER = 'header',
  CATALOG = 'catalog',
  SIDEBAR = 'sidebar',
  BOTTOMSHEET = 'bottomSheet',
}

export function getCategorySourceByPageSource(pageSource: PageSource | undefined): CategorySource {
  switch (pageSource) {
    case PageSource.BEST:
      return CategorySource.BEST;

    case PageSource.FAVORITES:
      return CategorySource.FAVORITES;

    case PageSource.PROMOTION:
      return CategorySource.PROMOTION;

    case PageSource.SEARCH:
      return CategorySource.SEARCH;

    case PageSource.STORE:
      return CategorySource.STORE;

    default:
      return CategorySource.DEFAULT;
  }
}
