import {requestAnimationFrame} from 'utils/raf';

// this function should be pure
export function createSkeletonConfigure(
  containerElement: HTMLElement,
  skeletonElement: HTMLElement,
  speed: number,
): () => void {
  const containerDomRect = containerElement.getBoundingClientRect();
  const skeletonDomRect = skeletonElement.getBoundingClientRect();

  const duration = containerDomRect.width / speed;
  const start = containerDomRect.left - skeletonDomRect.left;
  const end = start + containerDomRect.width;

  return () => {
    skeletonElement.style.setProperty('--animationDuration', `${duration}s`);
    skeletonElement.style.setProperty('--start', `${start}px`);
    skeletonElement.style.setProperty('--end', `${end}px`);
  };
}

export function configureSkeleton(
  containerElement: HTMLElement,
  skeletonElement: HTMLElement,
  speed: number,
): void {
  const configure = createSkeletonConfigure(containerElement, skeletonElement, speed);

  requestAnimationFrame(configure);
}
