import {Item} from 'components/ClientProduct/Attributes/Item';
import React from 'react';
import {ProductAttributesGroup} from 'types/ProductAttributes';

type Props = {
  group: ProductAttributesGroup;
};

export const Group = React.memo(function Group({group: {items}}: Props): React.ReactElement {
  return (
    <>
      {items.map((item) => (
        <Item key={item.id} item={item} />
      ))}
    </>
  );
});
