import {useAnalytics} from 'hooks/useAnalytics';
import {useApiClient} from 'hooks/useApiClient';
import {useBoolean} from 'hooks/useBoolean';
import {useEffectOnce} from 'hooks/useEffectOnce';
import {useRtl} from 'hooks/useRtl';
import {useUniversalNavigate} from 'hooks/useUniversalNavigate';
import React, {CSSProperties, forwardRef, ReactElement, Ref, useCallback, useMemo} from 'react';
import {SDLFlexboxNode, SDLNode} from 'types/ServerDrivenLayout';
import {
  convertAnyBackendGradientToCSSValue,
  convertBackendColorToCSSValue,
} from 'utils/styles/color';

import {SDLImageView} from './SDLImageView';
import {SDLTextView} from './SDLTextView';
import styles from './styles.scss';
import {toSize} from './utils';

export const SDLNodeView = forwardRef(
  ({node}: {node: SDLNode | SDLFlexboxNode}, ref: Ref<HTMLElement>): ReactElement | null => {
    const client = useApiClient();
    const analytics = useAnalytics();
    const navigate = useUniversalNavigate();
    const isRtl = useRtl();

    const logicalToPhysical = useCallback(
      function logicalToPhysical<Value>(start: Value, end: Value): [left: Value, right: Value] {
        return isRtl ? [end, start] : [start, end];
      },
      [isRtl],
    );

    const style = useMemo(() => {
      const style: CSSProperties = {};

      if (node.size) {
        style.width = toSize(node.size.width);
        style.height = toSize(node.size.height);
        style.maxWidth = toSize(node.size.maxWidth);
        style.maxHeight = toSize(node.size.maxHeight);
        style.minWidth = toSize(node.size.minWidth);
        style.minHeight = toSize(node.size.minHeight);

        if (node.size.aspectRatio) {
          style.aspectRatio = node.size.aspectRatio;
          // prevent container with aspect ratio to grow
          style.overflow = 'hidden';
        }
      }

      if (node.padding) {
        style.paddingTop = toSize(node.padding.top);
        style.paddingBottom = toSize(node.padding.bottom);
        [style.paddingLeft, style.paddingRight] = logicalToPhysical(
          toSize(node.padding.start),
          toSize(node.padding.end),
        );
      }

      if (node.margin) {
        style.marginTop = toSize(node.margin.top);
        style.marginBottom = toSize(node.margin.bottom);
        [style.marginLeft, style.marginRight] = logicalToPhysical(
          toSize(node.margin.start),
          toSize(node.margin.end),
        );
      }

      if (node.background?.content) {
        if ('gradient' in node.background.content) {
          style.background = convertAnyBackendGradientToCSSValue(node.background.content.gradient);
        } else if ('color' in node.background.content) {
          style.backgroundColor = convertBackendColorToCSSValue(node.background.content.color);
        }
      }

      if (node.background?.corners) {
        style.overflow = 'hidden';

        if ('radius' in node.background.corners) {
          style.borderRadius = toSize(node.background.corners.radius);
        } else if ('radii' in node.background.corners) {
          [style.borderTopLeftRadius, style.borderTopRightRadius] = logicalToPhysical(
            toSize(node.background.corners.radii.startTop),
            toSize(node.background.corners.radii.endTop),
          );
          [style.borderBottomLeftRadius, style.borderBottomRightRadius] = logicalToPhysical(
            toSize(node.background.corners.radii.startBottom),
            toSize(node.background.corners.radii.endBottom),
          );
        }
      }

      if (node.alpha) {
        style.opacity = node.alpha;
      }

      if (node.content && 'flex' in node.content && node.content.flex?.flex) {
        const {flex} = node.content.flex;

        style.display = 'flex';
        style.position = 'relative';
        style.flexDirection = flex.flexDirection;
        style.flexWrap = flex.flexWrap;
        style.justifyContent = flex.justifyContent;
        style.alignItems = flex.alignItems;
        style.alignContent = flex.alignContent;
        style.rowGap = flex.rowGap || flex.gap;
        style.columnGap = flex.columnGap || flex.gap;
      }

      if ('flex' in node && node.flex) {
        style.alignSelf = node.flex.alignSelf;
        style.flexBasis = node.flex.basis === 'auto' ? undefined : toSize(node.flex.basis);
        style.flexGrow = node.flex.grow;
        style.flexShrink = node.flex.shrink;

        if (node.flex.position) {
          style.position = node.flex.position.type || 'relative';
          style.top = toSize(node.flex.position.top);
          style.bottom = toSize(node.flex.position.bottom);
          [style.left, style.right] = logicalToPhysical(
            toSize(node.flex.position.start),
            toSize(node.flex.position.end),
          );
        }
      }

      if (node.onClick) {
        style.cursor = 'pointer';
      }

      return style as CSSProperties;
    }, [logicalToPhysical, node]);

    const handleClick = useMemo(() => {
      if (node.onClick) {
        return async () => {
          if (node.onClick?.context) {
            await client.api.post('/context/activate', {body: node.onClick.context});
          }

          if (node.onClick?.event) {
            analytics.sendEvent(node.onClick.event, {immediately: true});
          }

          if (node.onClick?.content?.openDeeplink?.deeplink) {
            navigate(node.onClick.content.openDeeplink.deeplink);
          }
        };
      }

      return undefined;
    }, [analytics, client.api, navigate, node.onClick]);

    const hidden = useBoolean(node.visibility?.isHidden);

    useEffectOnce(() => {
      if (node.visibility?.timeRemainingMs) {
        const timeoutId = setTimeout(hidden.toggle, node.visibility.timeRemainingMs);

        return () => clearTimeout(timeoutId);
      }
      return undefined;
    });

    if (hidden.value) {
      return null;
    }

    let content = null;
    if (node.content) {
      if ('flex' in node.content && node.content.flex) {
        content = node.content.flex.children?.map((node, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <SDLNodeView key={index} node={node} />
        ));
      } else if ('text' in node.content && node.content.text) {
        content = <SDLTextView text={node.content.text} />;
      } else if ('image' in node.content && node.content.image) {
        content = <SDLImageView image={node.content.image} />;
      } else if ('timer' in node.content && node.content.timer) {
        // @TODO: implement timer component
        content = null;
      }
    }

    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
      <div className={styles.node} style={style} ref={ref as Ref<never>} onClick={handleClick}>
        {content}
      </div>
    );
  },
);
