import {Icon} from 'components/UIKit/Icon';
import {useDeepLink} from 'hooks/useDeepLink';
import React, {useCallback} from 'react';
import {SearchResultHelp as SearchResultHelpType} from 'types/Search';
import {rootLocator} from 'utils/rootLocator';

import styles from '../index.scss';

const locator = rootLocator.commonPage.search.searchForm;

type Props = {
  searchResultHelp?: SearchResultHelpType;
};

export const SearchResultHelp: React.FC<Props> = ({searchResultHelp}: Props) => {
  const deeplink = searchResultHelp?.deeplink;
  const [, deepLinkAction] = useDeepLink(deeplink);

  const handleClick = useCallback(() => {
    if (deepLinkAction) {
      deepLinkAction();
    }
  }, [deepLinkAction]);

  if (!searchResultHelp) {
    return null;
  }

  return (
    <button
      type="button"
      className={styles.helpButton}
      onClick={handleClick}
      {...locator.infoIcon()}
    >
      <Icon height={24} name="help-filled-24" type="mono" width={24} />
    </button>
  );
};
