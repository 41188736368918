import {Locator} from 'components/Locator';
import {useParcelCancellationPopupRender} from 'components/ParcelCancellationPopup';
import {ParcelPageCardButton} from 'components/ParcelPageCard';
import {useMountedRef} from 'hooks/useMounted';
import {usePopup} from 'hooks/usePopup';
import React, {FC, useCallback, useEffect, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {isParcelCancellable} from 'store/modules/parcel';
import {Error} from 'types/Error';
import {Parcel} from 'types/Parcel';

import styles from './index.scss';

type Props = {
  parcel?: Parcel;
  error?: Error;
  loading?: boolean;
  onCancel: () => Promise<unknown>;
  updateParcelAddress: typeof import('store/modules/parcel').updateParcelAddress;
};

export const Cancellation: FC<Props> = ({parcel, error, onCancel, updateParcelAddress}) => {
  const mountedRef = useMountedRef();
  const [loading, setLoading] = useState(false);
  const [close, setClosed] = useState(false);

  const handleChangeAddress = useCallback(async () => {
    const cancelBehavior = parcel?.cancelBehavior || {};
    const suggestedAddress =
      'changeAddressSuggestion' in cancelBehavior &&
      cancelBehavior.changeAddressSuggestion?.suggestedAddress;
    if (!parcel || !suggestedAddress) {
      return;
    }

    const addressUpdate = {
      metainfoId: suggestedAddress.metainfoId,
      ...suggestedAddress.fields,
    };

    setLoading(true);
    await updateParcelAddress(parcel.id, addressUpdate, false);

    if (mountedRef.current) {
      setLoading(false);
      setClosed(true);
    }
  }, [parcel, updateParcelAddress, mountedRef]);

  const handleCancel = useCallback(async () => {
    if (!loading) {
      setLoading(true);
      await onCancel();
      if (mountedRef.current) {
        setLoading(false);
        setClosed(true);
      }
    }
  }, [onCancel, loading, mountedRef]);

  const popup = usePopup(
    useParcelCancellationPopupRender({
      cancelBehavior: parcel?.cancelBehavior,
      error,
      loading,
      onCancel: handleCancel,
      onChangeAddress: handleChangeAddress,
    }),
  );

  useEffect(() => {
    if (close) {
      setClosed(false);
      popup.close();
    }
  }, [popup, close]);

  if (!parcel || !isParcelCancellable(parcel)) {
    return null;
  }

  return (
    <ParcelPageCardButton onClick={popup.open}>
      <Locator id="CancelOrderButton">
        <span className={styles.text}>
          <FormattedMessage defaultMessage="Cancel order" description="Cancel parcel button" />
        </span>
      </Locator>
    </ParcelPageCardButton>
  );
};
