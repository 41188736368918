import {ConditionalWrapper} from 'components/ConditionalWrapper';
import {CopyContainer} from 'components/CopyContainer';
import {Image} from 'components/Image';
import {Text} from 'components/Text';
import {Button} from 'components/UIKit/Button';
import {Icon as ButtonIcon, Text as ButtonText} from 'components/UIKit/Button/Content';
import {Icon} from 'components/UIKit/Icon';
import {Size as TimerSize, Timer} from 'components/UIKit/Timer';
import {useTimer} from 'hooks/useTimer';
import React, {useCallback, useMemo} from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';
import {
  isPaymentRequisitesRefreshScreenAction,
  PaymentRequisites as PaymentRequisitesType,
  PaymentRequisitesRefreshScreenAction as PaymentRequisitesRefreshScreenActionType,
} from 'types/ContentList/ContentListPaymentRequisites';
import {convertBackendColorToCSSValue, convertBackendGradientToCSSValue} from 'utils/styles/color';

import styles from './index.scss';

const messages = defineMessages({
  refreshScreenButtonLabel: {
    defaultMessage: 'Update information',
    description: '[label] Button to refresh screen',
  },
});

type PaymentRequisitesHeaderProps = {
  loadedTimeMs: number;
  paymentRequisites: PaymentRequisitesType;
};

const HEADER_ICON_PX_FIT = 64;
const HEADER_ICON_VW_FIT = 64;

function PaymentRequisitesHeader({
  loadedTimeMs,
  paymentRequisites,
}: PaymentRequisitesHeaderProps): JSX.Element {
  const {icon, subtitle, timer, title} = paymentRequisites;

  const [timerShown, timestamp] = useTimer(loadedTimeMs, timer?.timeRemainingMs);

  return (
    <div className={styles.header}>
      {icon && (
        <Image
          className={styles.headerIcon}
          contain
          image={icon}
          pxFit={HEADER_ICON_PX_FIT}
          vwFit={HEADER_ICON_VW_FIT}
        />
      )}
      {timerShown && (
        <div className={styles.headerTimer}>
          <Timer
            completeMessage={timer?.text && <Text text={timer.text} />}
            customTheme={{
              digitBackgroundColor: timer?.digitBackgroundColor,
              digitColor: timer?.digitColor,
            }}
            size={TimerSize.SMALL}
            timestamp={timestamp}
          />
        </div>
      )}
      {title && <Text className={styles.headerTitle} text={title} />}
      {subtitle && <Text className={styles.headerSubtitle} text={subtitle} />}
    </div>
  );
}

type PaymentRequisitesFieldsProps = {
  paymentRequisites: PaymentRequisitesType;
};

function PaymentRequisitesFields({
  paymentRequisites,
}: PaymentRequisitesFieldsProps): JSX.Element | null {
  const {fields} = paymentRequisites;

  if (!fields?.length) {
    return null;
  }

  return (
    <table className={styles.fields}>
      <tbody>
        {fields.map((field) => (
          <tr className={styles.fieldsRow} key={field.title.text}>
            <td className={styles.fieldsColTitle}>
              <Text text={field.title} />
            </td>
            <td className={styles.fieldsColValue}>
              <ConditionalWrapper
                condition={Boolean(field.valueToCopy)}
                // eslint-disable-next-line react/jsx-no-bind
                wrapper={(children) => (
                  <div className={styles.fieldsCopyButton}>
                    <CopyContainer value={field.valueToCopy as string}>
                      {children}
                      <span className={styles.fieldsCopyIcon}>
                        <Icon height={16} name="copy-linear-16" type="mono" width={16} />
                      </span>
                    </CopyContainer>
                  </div>
                )}
              >
                <Text text={field.value} />
              </ConditionalWrapper>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

type PaymentRequisitesRefreshScreenActionProps = {
  action: PaymentRequisitesRefreshScreenActionType;
};

const REFRESH_SCREEN_ACTION_ICON_PX_FIT = 24;
const REFRESH_SCREEN_ACTION_ICON_VW_FIT = 24;

function PaymentRequisitesRefreshScreenAction({
  action,
}: PaymentRequisitesRefreshScreenActionProps): JSX.Element {
  const {button, text} = action.refreshScreen;
  const buttonColor = useMemo(
    () => ({
      color: convertBackendColorToCSSValue(button?.textColor),
      background: convertBackendGradientToCSSValue(button?.backgroundGradient),
    }),
    [button?.backgroundGradient, button?.textColor],
  );

  const handleRefreshScreen = useCallback(() => {
    window.location.reload();
  }, []);

  return (
    <div className={styles.refreshScreenAction}>
      {text && <Text className={styles.refreshScreenActionText} text={text} />}
      <Button color={buttonColor} onClick={handleRefreshScreen} shape="round" tag="button">
        {button?.icon && (
          <ButtonIcon>
            <Image
              height="24px"
              image={button.icon}
              pxFit={REFRESH_SCREEN_ACTION_ICON_PX_FIT}
              vwFit={REFRESH_SCREEN_ACTION_ICON_VW_FIT}
              width="24px"
            />
          </ButtonIcon>
        )}
        <ButtonText>
          {button?.text ?? <FormattedMessage {...messages.refreshScreenButtonLabel} />}
        </ButtonText>
      </Button>
    </div>
  );
}

type PaymentRequisitesFooterProps = {
  paymentRequisites: PaymentRequisitesType;
};

function PaymentRequisitesFooter({
  paymentRequisites,
}: PaymentRequisitesFooterProps): JSX.Element | null {
  const {action} = paymentRequisites;
  const content = useMemo(() => {
    if (isPaymentRequisitesRefreshScreenAction(action)) {
      return <PaymentRequisitesRefreshScreenAction action={action} />;
    }

    return undefined;
  }, [action]);

  if (!content) {
    return null;
  }

  return <div className={styles.footer}>{content}</div>;
}

type PaymentRequisitesProps = {
  loadedTimeMs: number;
  paymentRequisites: PaymentRequisitesType;
};

export function PaymentRequisites({
  loadedTimeMs,
  paymentRequisites,
}: PaymentRequisitesProps): JSX.Element {
  return (
    <div className={styles.root}>
      <PaymentRequisitesHeader loadedTimeMs={loadedTimeMs} paymentRequisites={paymentRequisites} />
      <PaymentRequisitesFields paymentRequisites={paymentRequisites} />
      <PaymentRequisitesFooter paymentRequisites={paymentRequisites} />
    </div>
  );
}
