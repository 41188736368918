import styles from 'components/ClientProduct/Reviews/Filters/index.scss';
import {Image} from 'components/Image';
import {Icon} from 'components/UIKit/Icon';
import {getFlagIconNameByEmoji} from 'components/UIKit/Icon/utils/getFlagIconNameByEmoji';
import React from 'react';
import {ProductReviewFilter} from 'types/ProductReviews';

import PhotoIcon from './photo.jsx.svg';
import ReviewIcon from './review.jsx.svg';
import StarIcon from './star.jsx.svg';

type Props = {
  filter: ProductReviewFilter;
};

function getIconByFilter(filter: ProductReviewFilter): React.ReactElement | null {
  const {title, icon, id} = filter;

  if (icon) {
    return <Image image={icon} width="14px" height="14px" />;
  }

  switch (id) {
    case 'withPhoto':
      return <PhotoIcon />;
    case 'withText':
      return <ReviewIcon />;
    case 'oneStar':
      return <StarIcon />;
    case 'twoStars':
      return (
        <>
          <StarIcon />
          <StarIcon />
        </>
      );
    case 'threeStars':
      return (
        <>
          <StarIcon />
          <StarIcon />
          <StarIcon />
        </>
      );
    case 'fourStars':
      return (
        <>
          <StarIcon />
          <StarIcon />
          <StarIcon />
          <StarIcon />
        </>
      );
    case 'fiveStars':
      return (
        <>
          <StarIcon />
          <StarIcon />
          <StarIcon />
          <StarIcon />
          <StarIcon />
        </>
      );

    case 'userCountry':
      if (title) {
        const flagIconName = getFlagIconNameByEmoji(title);
        return flagIconName ? (
          <Icon type="multi" name={flagIconName} width={14} height={14} />
        ) : (
          <span>{title}</span>
        );
      }
      return null;
    default:
      return null;
  }
}

export function FilterIcon({filter}: Props): React.ReactElement | null {
  const icon = getIconByFilter(filter);
  return icon ? <span className={styles.icon}>{icon}</span> : null;
}
