import React from 'react';
import {TableItemContent} from 'types/PageMetadata/TextBlock';

import styles from './index.scss';

type Props = {
  table: TableItemContent;
};

export function Table({table}: Props): JSX.Element | null {
  if (table.values.length === 0) {
    return null;
  }

  return (
    <table className={styles.table}>
      <thead>
        <tr>
          {table.header.map((tableHeader, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <th key={`${tableHeader}${index}`}>{tableHeader}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {table.values.map((tableRow, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <tr key={index}>
            {tableRow.map((tableData, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <td key={`${tableData}${index}`}>{tableData}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}
