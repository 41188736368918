import {SocialSigninCallback} from 'components/Auth/Views/Type';
import type {PageBase, SigninOptions} from 'containers/PageBase';
import {createContext} from 'react';
import {isObject} from 'utils/guards';

const commonFallback = () => {
  if (__DEVELOPMENT__) {
    throw new Error("don't use SigninContext outside of provider");
  }
};

const socialStartFallback: SocialSigninCallback = () => Promise.reject();

const startFallback: PageBase['signin'] = (...args) => {
  commonFallback();

  const [whereOrOptions, , , , signinCallbackClose] = args;
  let options: SigninOptions;

  if (isObject(whereOrOptions)) {
    options = whereOrOptions;
  } else {
    options = {
      where: whereOrOptions,
      onClose: signinCallbackClose,
    } as SigninOptions;
  }

  options.onClose?.();
};

const successFallback = (): Promise<void> => {
  commonFallback();
  return Promise.resolve();
};

type SigninContext = {
  socialStart: SocialSigninCallback;
  start: PageBase['signin'];
  success: typeof successFallback;
  retry(): void;
  close(): void;
};

export const SigninContext = createContext<SigninContext>({
  socialStart: socialStartFallback,
  start: startFallback,
  success: successFallback,
  retry: commonFallback,
  close: commonFallback,
});
