import classnames from 'classnames/bind';
import Checkbox from 'components/Checkbox';
import {Locator} from 'components/Locator';
import {LocationDescriptorObject} from 'history';
import React, {HTMLAttributes} from 'react';
import {Link} from 'react-router-dom';

import styles from './Item.scss';

const cn = classnames.bind(styles);

export type Item = {
  checkable?: boolean;
  children?: React.ReactNode;
  disabled?: boolean;
  hidden?: boolean;
  href?: string;
  largeText?: boolean;
  level?: number;
  selected?: boolean;
  to?: string | LocationDescriptorObject;
  size?: 's' | 'm';
  onClick?: (event: React.SyntheticEvent) => void;
  noHighlightOnHover?: boolean;
  testId?: string;
  targetBlank?: boolean;
} & HTMLAttributes<HTMLElement>;

export const Item = React.forwardRef(
  (
    {
      checkable = false,
      disabled = false,
      hidden = false,
      level,
      testId,
      selected = false,
      largeText = false,
      noHighlightOnHover,
      size = 's',
      targetBlank = false,
      ...props
    }: Item,
    ref,
  ) => {
    const {to, href} = props;
    const itemClassName = cn('item', level ? `level-${level}` : null, {
      checkable,
      clickable: Boolean(to || href),
      disabled,
      hidden,
      selected,
      largeText,
      noHighlightOnHover,
    });

    if (to) {
      return (
        <Locator id={testId}>
          <Link
            {...props}
            to={props.to as string | LocationDescriptorObject}
            className={itemClassName}
            innerRef={ref as React.RefObject<HTMLAnchorElement>}
            target={targetBlank ? '_blank' : undefined}
          />
        </Locator>
      );
    }

    if (href) {
      return (
        <Locator id={testId}>
          {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
          <a ref={ref as React.RefObject<HTMLAnchorElement>} {...props} className={itemClassName} />
        </Locator>
      );
    }

    if (checkable) {
      return (
        <Checkbox
          ref={ref as React.RefObject<HTMLInputElement>}
          className={itemClassName}
          size={size}
          theme="lightgray"
          {...props}
        />
      );
    }

    return (
      <Locator id={testId}>
        <button
          ref={ref as React.RefObject<HTMLButtonElement>}
          type="button"
          {...props}
          className={itemClassName}
        />
      </Locator>
    );
  },
);

// eslint-disable-next-line import/no-default-export
export default Item;
