import {PropsHolder} from 'components/PropsHolder';
import {useLanguage} from 'hooks/useLanguage';
import PropTypes from 'prop-types';
import React, {useCallback} from 'react';
import {defineMessages, useIntl} from 'react-intl';
import {useAnalytics} from 'hooks/useAnalytics';
import {useScope} from 'hooks/useScope';
import {getUrl} from 'routes';
import {rootLocator} from 'utils/rootLocator';
import {useMobWebAdInterlayerHardcoreModeLink} from 'hooks/useMobWebAdInterlayer';
import {Icon} from 'components/UIKit/Icon';
import {HeaderButton} from '../HeaderButton';

const messages = defineMessages({
  cart: {
    description: 'Header cart button label',
    defaultMessage: 'Shopping cart',
  },
  indicatorNumeral: {
    description: 'Mini cart button label',
    defaultMessage: `{count, plural, one {product} other {products}}`,
  },
});

const locator = rootLocator.commonPage.header.cart;

const Cart = React.memo(function Cart(props) {
  const {count} = props;
  const intl = useIntl();
  const lang = useLanguage();
  const scope = useScope();
  const analytics = useAnalytics();
  const mobileInterlayerActionOverrideLink = useMobWebAdInterlayerHardcoreModeLink();

  const handleClick = useCallback(
    (event) => {
      if (mobileInterlayerActionOverrideLink) {
        event?.preventDefault();
        event?.stopPropagation();

        mobileInterlayerActionOverrideLink(event);
      }
      analytics.sendEvent({
        type: 'cartHeaderClick',
      });
    },
    [analytics, mobileInterlayerActionOverrideLink],
  );

  return (
    <PropsHolder hold={typeof count !== 'number'} count={count}>
      {(holdedProps) => (
        <HeaderButton
          icon={
            <Icon aria-hidden type="mono" name="bar-cart-linear-24" width="24px" height="24px" />
          }
          to={getUrl('cart', {lang, scope})}
          onClick={handleClick}
          indicatorCount={holdedProps.count}
          indicatorNumeral={intl.formatMessage(messages.indicatorNumeral, {
            count: holdedProps.count,
          })}
          text={intl.formatMessage(messages.cart)}
          {...locator.button()}
        />
      )}
    </PropsHolder>
  );
});

Cart.propTypes = {
  count: PropTypes.number,
};

Cart.defaultProps = {
  count: null,
};

export default Cart;
