import PropTypes from 'prop-types';

export default PropTypes.shape({
  body: PropTypes.shape({
    message: PropTypes.string,
  }),
  error: PropTypes.shape({
    // error message for testing purposes only
    message: PropTypes.string,
    requestId: PropTypes.string,
    status: PropTypes.string,
    type: PropTypes.string,
    userMessage: PropTypes.string,
    userTitle: PropTypes.string,
  }),
  message: PropTypes.string,
  status: PropTypes.number,
});
