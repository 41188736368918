import {AppLanguageCode} from 'config';
import {checkNever} from 'utils/guards';

export function getLocaleName(language: string, logWarning?: (arg: string) => void): string {
  const appLang = language as AppLanguageCode;
  switch (appLang) {
    // not used at now
    // case 'da':
    //   return 'Dansk';
    case 'cs':
      return 'Čeština';
    case 'de':
      return 'Deutsch';
    case 'en':
      return 'English';
    case 'es':
      return 'Español';
    case 'et':
      return 'Eesti';
    case 'fr':
      return 'Français';
    case 'hu':
      return 'Magyar';
    case 'it':
      return 'Italiano';
    case 'lv':
      return 'Latviešu';
    case 'lt':
      return 'Lietuvių';
    case 'nl':
      return 'Nederlands';
    case 'nb':
      return 'Norsk bokmål';
    case 'pl':
      return 'Polski';
    case 'pt':
      return 'Português';
    case 'pt-br':
      return 'Português (Brasil)';
    case 'ro':
      return 'Română';
    case 'kk':
      return 'Қазақша';
    case 'ru':
    case 'ru-ua':
      return 'Русский';
    case 'sk':
      return 'Slovenčina';
    case 'sv':
      return 'Svenska';
    case 'tr':
      return 'Türkçe';
    case 'el':
      return 'Ελληνικά';
    case 'uk':
      return 'Українська';
    case 'ka':
      return 'ქართული';
    case 'he':
      return 'עברית';
    case 'ar':
      return 'العربية';
    case 'ja':
      return '日本語';
    case 'lol':
      return 'Контекстный перевод (бэкенд)';
    // case 'tlh':
    //   return 'Контекстный перевод (веб-клиент)';
    default:
      checkNever(appLang);
      if (typeof logWarning === 'function') {
        logWarning(`Undefined language ${language}`);
      }
      return language;
  }
}
