const NUMBER_REGEX = /((?:-|\+)?[0-9]+(?:(?:.|,| )[0-9]+)*)/g;
const CLEAN_REGEX = /[^-0-9.,]/g;
const COMMA_REGEX = /,/g;
const DOT_REGEX = /\./g;
const COMMA = ',';
const DOT = '.';

export function parseUserInputNumber(value: number | string): number {
  const match = value.toString().match(NUMBER_REGEX);

  if (!match) {
    return NaN;
  }
  const number = match[0].replace(CLEAN_REGEX, '');

  const commaSeparated = number.split(COMMA);
  let isCommaFloating = commaSeparated.length === 2 && commaSeparated[1]!.length <= 2;
  if (!isCommaFloating) {
    const dotSeparated = number.split(DOT);
    if (dotSeparated.length >= 2) {
      isCommaFloating = dotSeparated[dotSeparated.length - 1]?.length === 3;
    }
  }

  if (isCommaFloating) {
    return parseFloat(number.replace(DOT_REGEX, '').replace(COMMA, DOT));
  }
  return parseFloat(number.replace(COMMA_REGEX, ''));
}

const ABBREVIATIONS = ['K', 'M', 'B', 'T', 'Q'];

export function formatWithKs(count: number, plus?: boolean, fractionDigits = 1): string {
  let result = String(count);

  if (count < 1000) {
    return result;
  }

  const rank = Math.floor(Math.log10(count)) + 1;
  const abbreviationIndex = Math.floor((rank - 4) / 3);
  const abbreviation = ABBREVIATIONS[abbreviationIndex];
  const abbreviationRank = (abbreviationIndex + 1) * 3;

  if (!abbreviation) {
    // Abbreviation failed. You cannot abbreviate numbers higher
    // than `999${ABBREVIATIONS[ABBREVIATIONS.length - 1]}`
    return result;
  }

  const resultNumber = Math.round(count) / 10 ** abbreviationRank;

  if (plus) {
    const integerLength = rank % 3 || 3;
    const flooredNumber = parseFloat(
      // We don't need to round the number by math laws,
      // here we need to floor it, so we slice it to digits
      String(resultNumber).slice(
        0,
        // and take the amount of digits we need (and dot if there should be one)
        integerLength + (fractionDigits ? fractionDigits + 1 : 0),
      ),
    );

    // Here we check if we need to add plus by comparing the original number with floored one
    const shouldReallyAddPlus = flooredNumber * 10 ** abbreviationRank < count;
    result = `${flooredNumber}${abbreviation}${shouldReallyAddPlus ? '+' : ''}`;
  } else {
    // parseFloat is here to trim unnecessary zeroes
    // after our toFixed transformation
    // e.g. 3.20K becomes 3.2K because 0 is
    // considered unnecessary in math.
    // This probably should be parametrized if
    // there's a need to abbreviate monetary values
    result = `${parseFloat(resultNumber.toFixed(fractionDigits))}${abbreviation}`;
  }

  return result;
}

export function maskNumber(str: string, pattern: string, maskSymbol = 'x'): string {
  const digits = str.replace(/\D/g, '');
  const parts = pattern.split(maskSymbol);
  const length = Math.min(Math.max(1, parts.length - 1), digits.length);
  let result = '';
  let index = 0;

  for (; index < length; index += 1) {
    const part = parts[index] ?? '';
    const digit = digits[index];

    result += `${part}${digit}`;
  }

  if (index === 0 || index === parts.length - 1) {
    result += parts[index];
  }

  return result;
}
