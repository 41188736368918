import {isRecord} from 'utils/guards';

export enum PersonalDataFormOriginsFieldSetsIds {
  Passport = 'passport',
  PassportRu = 'passportRU',
}

type PersonalDataFormOriginWithFieldSetOnly = {
  fieldSet: {
    id: PersonalDataFormOriginsFieldSetsIds;
  };
};

type PersonalDataFormOriginWithCheckoutGroupId = {
  fieldSetId: PersonalDataFormOriginsFieldSetsIds;
  checkoutGroupId: string;
};

export type PersonalDataFormOrigin =
  | PersonalDataFormOriginWithFieldSetOnly
  | PersonalDataFormOriginWithCheckoutGroupId;

export const isPersonalDataFormOriginWithFieldSetOnly = (
  value: unknown,
): value is PersonalDataFormOriginWithFieldSetOnly => {
  return isRecord(value) && 'fieldSet' in value;
};
