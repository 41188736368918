import {CSSProperties} from 'react';
import {SideBannerGroupBanner} from 'types/ContentList/SideBannerGroup';
import {convertBackendColorToCSSValue} from 'utils/styles/color';

type TextStyle = {
  subtitle?: CSSProperties;
  title?: CSSProperties;
};

export function getTextInlineStyle({subtitle, title}: SideBannerGroupBanner): TextStyle {
  const textStyle: TextStyle = {};

  if (subtitle && subtitle.color) {
    textStyle.subtitle = {color: convertBackendColorToCSSValue(subtitle.color)};
  }

  if (title && title.color) {
    textStyle.title = {color: convertBackendColorToCSSValue(title.color)};
  }

  return textStyle;
}
