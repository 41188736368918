import classNamesBind from 'classnames/bind';
import {useBannerPreview} from 'components/ContentList/hooks';
import HtmlContent from 'components/HtmlContent';
import {Image} from 'components/Image';
import {Locator} from 'components/Locator';
import {Square} from 'components/Square';
import {useDeviceVar} from 'hooks/useDeviceVars';
import React, {CSSProperties, useRef} from 'react';
import {BannersListBannerAppearance, BannersListItem} from 'types/ContentList/BannersList';
import {convertBackendColorToCSSValue} from 'utils/styles/color';

import {Background} from '../../../Background';
import {BannerWrapper} from '../../../BannerWrapper';
import {isTextColorLight} from '../../utils';
import styles from './index.scss';

type Props = {
  bannerAppearance: BannersListBannerAppearance;
  item: BannersListItem;
  selected: boolean;
  source?: string;
  small?: boolean;
  notClickable?: boolean;
};

const cn = classNamesBind.bind(styles);

const itemImageVwFit = {
  md: '288px',
  sm: '132px',
};

export const Item = React.memo(function Item({
  bannerAppearance: {showSubtitle, showTitle},
  item,
  selected,
  source,
  notClickable,
  small,
}: Props): JSX.Element {
  const {eventParams} = item;
  const itemRef = useRef(null);
  const lightColor = isTextColorLight(item);
  const {appearance} = item;

  const textWrapStyle: CSSProperties = {};

  useBannerPreview({
    bannerId: item.id,
    rootRef: itemRef,
    eventParams,
    source,
  });

  const bigFullSheetLongImage = useDeviceVar('webBigFullSheetLongImage');

  if (appearance?.textBackgroundColor) {
    textWrapStyle.backgroundColor = convertBackendColorToCSSValue(appearance.textBackgroundColor);
  }

  const imageSource = item.icon || item.background;

  return (
    <Locator id="BannersListFullSheetLongBanner">
      <BannerWrapper
        focusable={selected}
        ref={itemRef}
        to={notClickable ? undefined : item.payload.url}
        source={source}
      >
        <div className={cn('item', {small, withBackgroundImage: item.backgroundFull})}>
          <Background gradient={item.gradient} />
          {item.backgroundFull ? (
            <div className={cn('backgroundFull')}>
              <Background
                background={{image: item.backgroundFull}}
                objectPosition="center center"
              />
            </div>
          ) : null}
          <div className={styles.content}>
            <div className={cn('textWrap', {lightColor})} style={textWrapStyle}>
              <div className={styles.text}>
                {showTitle && item.title && (
                  <Locator id="BannersListFullSheetLongBannerTitle">
                    <div className={styles.title}>{item.title}</div>
                  </Locator>
                )}
                {showTitle && item.titleImage ? (
                  <Image image={item.titleImage} height="48px" className={styles.titleImage} />
                ) : null}
                {Boolean(showSubtitle && item.subtitle) && (
                  <Locator id="BannersListFullSheetLongBannerSubtitle">
                    <div
                      className={cn('subtitle', {
                        [styles.longText!]: String(item.subtitle).length >= 50,
                      })}
                    >
                      <HtmlContent html={String(item.subtitle)} />
                    </div>
                  </Locator>
                )}
              </div>
            </div>
            <div className={styles.imageWrap}>
              {imageSource && (
                <Locator id="BannersListFullSheetLongBannerImage">
                  <div
                    className={cn(styles.imagePlaceholder, {
                      [styles.bigImage!]: bigFullSheetLongImage,
                      [styles.hideOnDesktop!]: item.backgroundFull,
                    })}
                  >
                    <Square>
                      <Image
                        block
                        className={styles.image}
                        contain
                        image={imageSource}
                        loadingHidden
                        pxFit={288}
                        vwFit={itemImageVwFit}
                        lcpImageCandidate
                      />
                    </Square>
                  </div>
                </Locator>
              )}
            </div>
          </div>
        </div>
      </BannerWrapper>
    </Locator>
  );
});
