import React from 'react';

/* eslint-disable react/no-danger */

type Props = {
  children: React.ReactNode;
};

export function NoIndex({children}: Props): React.ReactElement {
  return (
    <>
      <span dangerouslySetInnerHTML={{__html: '<!--noindex-->'}} />
      {children}
      <span dangerouslySetInnerHTML={{__html: '<!--/noindex-->'}} />
    </>
  );
}
