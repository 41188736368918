import {Badges} from 'components/Badges';
import {Locator} from 'components/Locator';
import {QRCodeSVGCsr} from 'components/QRCodeSVGCsr';
import {useCompanyName} from 'hooks/useCompanyName';
import {useIsBreakpointMobile} from 'hooks/useIsBreakpointMobile';
import React from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';

import {useQrCodeUrl} from './hooks';
import styles from './index.scss';

const messages = defineMessages({
  title: {
    defaultMessage: 'More deals & promos in the {companyName} app',
    description: '[title] Title for apps banner',
  },
  qrCaption: {
    defaultMessage: 'Scan to download app',
    description: 'Caption for qr code',
  },
});

type FooterAppsProps = {
  deepUrl: string;
};

export function FooterApps({deepUrl}: FooterAppsProps): JSX.Element {
  const companyName = useCompanyName();
  const isMobile = useIsBreakpointMobile('sm');
  const qrCodeUrl = useQrCodeUrl({deepUrl});

  return (
    <div className={styles.apps}>
      <div className={styles.appsHeader}>
        <FormattedMessage {...messages.title} values={{companyName}} />
      </div>
      <div className={styles.appsQrContainer}>
        <div className={styles.qr}>
          <div className={styles.qrContent}>
            <Locator id="FooterQRBlock">
              <QRCodeSVGCsr height={112} value={qrCodeUrl} width={112} />
            </Locator>
          </div>
          <div className={styles.qrCaption}>
            <FormattedMessage {...messages.qrCaption} />
          </div>
        </div>
      </div>
      <Locator id="FooterBadges">
        <div className={styles.appsBadgesContainer}>
          <Badges deepUrl={deepUrl} eventCategory="Footer" small={!isMobile} />
        </div>
      </Locator>
    </div>
  );
}
