import connectDuck from 'store/connectDuck';
import LegalityShape from 'shapes/Legality';
import ErrorShape from 'shapes/Error';
import PropTypes from 'prop-types';
import React from 'react';
import {
  getLegality,
  getLegalityError,
  isLegalityLoading,
  loadLegality,
} from 'store/modules/legality';
import {connect} from 'react-redux';

class Lazy extends React.Component {
  static propTypes = {
    children: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    document: LegalityShape,
    documentError: ErrorShape,
    documentLoading: PropTypes.bool.isRequired,
    loadLegality: PropTypes.func.isRequired,
  };

  static defaultProps = {
    document: null,
    documentError: null,
  };

  componentDidMount() {
    this.props.loadLegality(this.props.id);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id) {
      this.props.loadLegality(this.props.id);
    }
  }

  render() {
    const {children, document, documentError, documentLoading} = this.props;
    return children({
      document,
      documentError,
      documentLoading,
    });
  }
}

export default connect((state, {id}) => ({
  document: getLegality(state, id),
  documentLoading: isLegalityLoading(state, id),
  documentError: getLegalityError(state, id),
}))(connectDuck({loadLegality})(Lazy));
