import classnames from 'classnames/bind';
import React from 'react';

import styles from './index.scss';

const cn = classnames.bind(styles);

type Props = {
  children?: React.ReactNode;
  className?: string;
};

export function Square(props: Props): React.ReactElement {
  const {children = null, className = null} = props;

  return (
    <div {...props} className={cn('square', className)}>
      <div className={styles.content}>{children}</div>
    </div>
  );
}
