import ErrorMessage from 'components/ErrorMessage';
import {NotFound} from 'components/NotFound';
import {Oops} from 'components/Oops';
import {SplashMessage} from 'components/SplashMessage';
import {useLanguage} from 'hooks/useLanguage';
import {useScope} from 'hooks/useScope';
import React, {FC} from 'react';
import {FormattedMessage} from 'react-intl';
import {getUrl} from 'routes';
import type {Error} from 'types/Error';

type Props = Readonly<{
  error?: Error;
  noButton?: boolean;
}>;

export const PageError: FC<Props> = ({error, noButton}) => {
  const lang = useLanguage();
  const scope = useScope();

  if (!error) {
    return null;
  }

  if ('status' in error && error.status === 404) {
    return <NotFound noButton={noButton} />;
  }

  return (
    <Oops asPage message={<ErrorMessage error={error} />} error={error}>
      {noButton ? null : (
        <SplashMessage.Link to={getUrl('main', {lang, scope})}>
          <FormattedMessage
            description="Button caption of PageError block"
            defaultMessage="Go back to home page"
          />
        </SplashMessage.Link>
      )}
    </Oops>
  );
};
