import cn from 'classnames';
import {Image} from 'components/Image';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {ImageBundle} from 'types/Image';
import {
  createVideoThumbnail,
  isFileImage,
  isFileVideo,
  VideoThumbnailGenerationOptions,
} from 'utils/file';
import {createImageThumbnail} from 'utils/file/createImageThumbnail';
import {voidFunction} from 'utils/function';

import {MediaState} from '../../../types';
import styles from './index.scss';

const THUMBNAIL_SIZE = 200;
const VIDEO_THUMBNAIL_GENERATION_OPTIONS: VideoThumbnailGenerationOptions = {
  maxSideSize: THUMBNAIL_SIZE,
  seekTo: 0,
};

const getThumbnail = (mediaState: MediaState): ImageBundle | undefined => {
  if (mediaState.bundle) {
    if ('image' in mediaState.bundle) {
      return mediaState.bundle.image;
    }

    if ('video' in mediaState.bundle) {
      return mediaState.bundle.video.thumbnail;
    }
  }

  return undefined;
};

export const useThumbnail = (mediaState: MediaState): JSX.Element | null => {
  const [imageThumbnail, setImageThumbnail] = useState<string>();
  const [videoThumbnail, setVideoThumbnail] = useState<string>();
  const [showGeneratedThumbnail, setShowGeneratedThumbnail] = useState(false);
  const commonThumbnail = getThumbnail(mediaState);
  const hasCommonThumbnail = Boolean(commonThumbnail);
  const hasGeneratedThumbnail = Boolean(imageThumbnail || videoThumbnail);

  const handleGeneratedThumbnailComplete = useCallback((hasError: boolean) => {
    if (hasError) {
      setImageThumbnail(undefined);
      setVideoThumbnail(undefined);
    } else {
      setShowGeneratedThumbnail(true);
    }
  }, []);

  useEffect(() => {
    if (mediaState.file) {
      if (isFileImage(mediaState.file)) {
        createImageThumbnail(mediaState.file).then(setImageThumbnail).catch(voidFunction);
      } else if (isFileVideo(mediaState.file)) {
        createVideoThumbnail(mediaState.file, VIDEO_THUMBNAIL_GENERATION_OPTIONS)
          .then(setVideoThumbnail)
          .catch(voidFunction);
      }
    }
  }, [mediaState.file]);

  return useMemo(() => {
    if (hasCommonThumbnail) {
      return (
        <Image
          className={styles.image}
          contain
          height="100%"
          image={commonThumbnail}
          loadingHidden
          pxFit={THUMBNAIL_SIZE}
          width="100%"
        />
      );
    }

    if (hasGeneratedThumbnail) {
      return (
        <Image
          className={cn(styles.image, !showGeneratedThumbnail && styles.hidden)}
          contain
          height="100%"
          loadingHidden
          onComplete={handleGeneratedThumbnailComplete}
          pxFit={THUMBNAIL_SIZE}
          src={imageThumbnail ?? videoThumbnail}
          width="100%"
        />
      );
    }

    return null;
  }, [
    commonThumbnail,
    handleGeneratedThumbnailComplete,
    hasCommonThumbnail,
    hasGeneratedThumbnail,
    imageThumbnail,
    showGeneratedThumbnail,
    videoThumbnail,
  ]);
};
