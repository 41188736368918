import classNamesBind from 'classnames/bind';
import React from 'react';

import styles from './index.scss';

const cn = classNamesBind.bind(styles);

type Props = {
  size?: 'inherit' | 'fontSize';
};

export function CircleLoading({size = 'inherit'}: Props): React.ReactElement {
  return <span className={cn('loading', size && `size-${size}`)} />;
}
