import React, {ReactElement, useMemo} from 'react';
import {LinksBlock as LinksBlockType} from 'types/PageMetadata/LinksBlock';

import {TextBlock} from '../TextBlock';

type Props = {
  header?: string;
  data?: LinksBlockType;
};

export function LinksBlock({data, header}: Props): ReactElement | null {
  const items = useMemo(() => {
    const result = [];
    if (data?.links?.length) {
      if (header) {
        result.push({header});
      }
      result.push({linksList: data.links});
      return result;
    }
    return null;
  }, [header, data]);

  return items ? <TextBlock data={{items}} /> : null;
}
