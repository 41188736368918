import {getServerTimestamp} from 'helpers/serverTime';
import {getValueByPath} from 'utils/object';

const UNREAD_COUNT_INTERVAL = 5 * 60 * 1000; // 5min

/* Root */

const getNotificationsRootState = (globalState) => globalState.notifications;

/* Filters */

export const getNotificationsFiltersState = (globalState) =>
  getNotificationsRootState(globalState).filters;

export const getNotificationsFilters = (globalState) =>
  getNotificationsFiltersState(globalState).items;

export const getNotificationsFirstFilterId = (globalState) =>
  getValueByPath(getNotificationsFilters(globalState), 0, 'id');

/* Settings */

export const getNotificationsSettingsState = (globalState) =>
  getNotificationsRootState(globalState).settings;

/* Notifications */

const getNotificationsState = (globalState) => globalState.notifications.notifications;

export const getNotificationsForCurrentFilterState = (globalState) => {
  const {currentFilterId, data} = getNotificationsState(globalState);

  return currentFilterId in data ? data[currentFilterId] : null;
};

export const getNotificationsUnreadCount = (globalState) =>
  getNotificationsState(globalState).unreadCount;

export const needUpdateNotificationsUnreadCount = (globalState) => {
  const timeDelta = getServerTimestamp() - getNotificationsState(globalState).unreadCountLastUpdate;

  return timeDelta > UNREAD_COUNT_INTERVAL;
};
