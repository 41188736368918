import classnames from 'classnames/bind';
import React from 'react';

import styles from './Column.scss';

const cn = classnames.bind(styles);
const sizes = ['xs', 'sm', 'md', 'lg', 'xl'] as const;

type Size = number | string;

export type Props = {
  children?: React.ReactNode;
  inFrontOnDesktop?: boolean;
  marginBottom?: boolean;
  fullWidth?: boolean;
  mobileFullWidthOnly?: boolean;
  xs?: Size;
  sm?: Size;
  md?: Size;
  lg?: Size;
  xl?: Size;
};

export const Column = React.forwardRef(function Column(
  props: Props,
  ref: React.Ref<HTMLDivElement>,
): React.ReactElement {
  const {
    children,
    inFrontOnDesktop = false,
    marginBottom = false,
    fullWidth = false,
    mobileFullWidthOnly,
  } = props;
  const sizeClassNames = sizes
    .filter((size) => typeof props[size] === 'string' || typeof props[size] === 'number')
    .map((size) => `${size}-${props[size]}`);

  return (
    <div
      ref={ref}
      className={cn(
        'column',
        {marginBottom, inFrontOnDesktop, fullWidth, mobileFullWidthOnly},
        ...sizeClassNames,
      )}
    >
      {children}
    </div>
  );
});
