import PropTypes from 'prop-types';
import React from 'react';
import {Locator} from 'components/Locator';
import ErrorShape from 'shapes/Error';
import {getLocalizedErrorMessage} from 'utils/error';
import {lowercaseFirstLetter} from 'utils/string';
import {useIntl} from 'react-intl';
import {useScope} from 'hooks/useScope';
import styles from './index.scss';

const ErrorMessage = ({error, noRequestId, noTitle, internal, lowercase}) => {
  const scope = useScope();
  const intl = useIntl();
  const {requestId, error: innerError, body} = error || {};

  const popup = body?.payload?.popup;

  const {userTitle, userMessage} = innerError || {};

  const {title: popupTitle, text: popupMessage} = popup || {};

  const title = userTitle || popupTitle;
  const message =
    userMessage || popupMessage || getLocalizedErrorMessage(scope, intl, error || {}, internal);

  return (
    <Locator id="ErrorMessage">
      <span role="alert">
        {title && !noTitle && <h3 className={styles.title}>{title}</h3>}
        {lowercase ? lowercaseFirstLetter(message) : message}
        {noRequestId || !message || !requestId || <br />}
        {noRequestId || !requestId || (
          <Locator id="ErrorMessageRequestId">
            <span className={styles.notice}>ID: {requestId}</span>
          </Locator>
        )}
      </span>
    </Locator>
  );
};

ErrorMessage.propTypes = {
  error: ErrorShape,
  internal: PropTypes.bool,
  lowercase: PropTypes.bool,
  noRequestId: PropTypes.bool,
  noTitle: PropTypes.bool,
};

ErrorMessage.defaultProps = {
  error: null,
  internal: false,
  lowercase: false,
  noRequestId: false,
  noTitle: false,
};

export default ErrorMessage;
