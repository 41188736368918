import classnames from 'classnames/bind';
import React, {ReactElement, ReactNode} from 'react';

import {Back} from './Back';
import {Close} from './Close';
import styles from './Header.scss';

const cn = classnames.bind(styles);

export const Header = ({
  children,
  className,
  noShadow,
  onBack,
  onClose,
  transparent,
  collapsed,
  headerUid,
}: {
  children?: ReactNode;
  className?: string;
  noShadow?: boolean;
  transparent?: boolean;
  collapsed?: boolean;
  onBack?: () => void;
  onClose?: () => void;
  headerUid?: string;
}): ReactElement => {
  const classNames = cn(className, styles.header, {
    noShadow,
    transparent,
    collapsed,
    hasControls: Boolean(onBack || onClose),
  });

  return (
    <div className={classNames}>
      <div className={styles.inner}>
        {onBack ? <Back onBack={onBack} /> : null}
        {onClose ? <Close onClose={onClose} /> : null}
        <div className={styles.title} id={headerUid}>
          {children}
        </div>
      </div>
    </div>
  );
};
