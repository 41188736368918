import config from 'config';
import {getValueByPath} from 'utils/object';
import {AuthProvider} from 'types/AuthProvider';

const SET_BOT = 'preferences/SET_BOT';
const SET_DEVICE_ID = 'preferences/SET_DEVICE_ID';
const SET_DEVICE_VARS = 'preferences/SET_DEVICE_VARS';
const SET_ORIGIN = 'preferences/SET_ORIGIN';
const SET_RICH_META = 'preferences/SET_RICH_META';
export const SET_DETECTED_LANGUAGE = 'preferences/SET_DETECTED_LANGUAGE';
export const SET_CURRENCY = 'preferences/SET_CURRENCY';
export const SET_LANGUAGE = 'preferences/SET_LANGUAGE';
export const SET_REGION = 'preferences/SET_REGION';
export const SET_APP_WEBVIEW = 'preferences/SET_APP_WEBVIEW';

export const initialState = {
  bot: false,
  currency: '',
  deviceId: '',
  detectedLanguage: '',
  devicevars: {},
  language: '',
  loadedLanguages: {},
  loadingLanguages: {},
  locale: '',
  origin: '',
  region: '',
  richMeta: false,
  appWebView: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_DEVICE_ID:
      return {
        ...state,
        deviceId: action.deviceId,
      };
    case SET_CURRENCY:
      return {
        ...state,
        currency: action.currency,
      };
    case SET_LANGUAGE:
      return {
        ...state,
        language: action.language,
        locale:
          config.languages.find(({code}) => code === action.language)?.locale || action.language,
      };
    case SET_DETECTED_LANGUAGE:
      return {
        ...state,
        detectedLanguage: action.detectedLanguage,
      };
    case SET_RICH_META:
      return {
        ...state,
        richMeta: action.richMeta,
      };
    case SET_BOT:
      return {
        ...state,
        bot: action.bot,
      };
    case SET_DEVICE_VARS:
      return {
        ...state,
        devicevars: action.devicevars,
      };
    case SET_ORIGIN:
      return {
        ...state,
        origin: action.origin,
      };
    case SET_REGION:
      return {
        ...state,
        region: action.region,
      };
    case SET_APP_WEBVIEW:
      return {
        ...state,
        appWebView: action.appWebView,
      };
    default:
      return state;
  }
}

export function getDeviceId(globalState) {
  return globalState.preferences.deviceId || null;
}

export function getOrigin(globalState) {
  return globalState.preferences.origin;
}

export function getRegion(globalState) {
  return globalState.preferences.region;
}

export function isBot(globalState) {
  return !!globalState.preferences.bot;
}

export function isRichMeta(globalState) {
  return !!globalState.preferences.richMeta;
}

export function isAppWebView(globalState) {
  return !!globalState.preferences.appWebView;
}

export function saveCurrency(currency) {
  return (store, client) => client.device.setCurrency(currency);
}

export function setCurrency(currency) {
  return {
    type: SET_CURRENCY,
    currency,
  };
}

export function setDeviceId(deviceId) {
  return {
    type: SET_DEVICE_ID,
    deviceId,
  };
}

export function saveLanguage(language) {
  return (store, client) => {
    client.device.forceLanguage(null);
    return client.device.setLanguage(language);
  };
}

export function saveForcedRegion(forcedRegion) {
  return (store, client) => client.device.setForcedRegion(forcedRegion);
}

export function setDetectedLanguage(detectedLanguage) {
  return {
    type: SET_DETECTED_LANGUAGE,
    detectedLanguage,
  };
}

export function setDeviceVars(devicevars) {
  return {
    type: SET_DEVICE_VARS,
    devicevars,
  };
}

export function setBot(bot) {
  return {
    type: SET_BOT,
    bot,
  };
}

export function setRichMeta(richMeta) {
  return {
    type: SET_RICH_META,
    richMeta,
  };
}

export function setLanguage(language) {
  return (store, client) => {
    client.device.forceLanguage(language);
    store.dispatch({
      type: SET_LANGUAGE,
      language,
    });
  };
}

export function setOrigin(origin) {
  return {
    type: SET_ORIGIN,
    origin,
  };
}

export function setRegion(region) {
  return {
    type: SET_REGION,
    region,
  };
}

export function setAppWebView(appWebView) {
  return {
    type: SET_APP_WEBVIEW,
    appWebView,
  };
}

export function getDeviceVars(state) {
  return state.preferences.devicevars || {};
}

export function getDeviceVar(state, name) {
  return getDeviceVars(state)[name];
}

export function getLanguage(state) {
  return state.preferences.language;
}

export function getDetectedLanguage(state) {
  return state.preferences.detectedLanguage;
}

export function getCurrency(state) {
  return state.preferences.currency;
}

export function hasLoadingLanguages(state) {
  return Object.keys(state.preferences.loadingLanguages).length > 0;
}

export function isFallbackFeatureEnabled(feature) {
  // Use it only for migrated to devicevars features
  return (
    feature === true ||
    getValueByPath(feature, 'enabled') === 'on' ||
    getValueByPath(feature, 'option') === 'on'
  );
}

export function isFallbackFeatureByNameEnabled(state, name) {
  // Use it only for migrated to devicevars features
  return isFallbackFeatureEnabled(getDeviceVars(state)[name]);
}

export function isFacebookEnabled(globalState) {
  return getDeviceVar(globalState, 'socialAuth')?.[AuthProvider.FACEBOOK] === 'on';
}
