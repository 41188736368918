import cn from 'classnames';
import {Locator} from 'components/Locator';
import {useLanguage} from 'hooks/useLanguage';
import {useRafResize} from 'hooks/useResize';
import React, {useCallback, useRef, useState} from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';
import {TranslationPolicy} from 'shapes/ProductReview';

import styles from './index.scss';

const messages = defineMessages({
  expandButton: {
    description: 'Expand review button',
    defaultMessage: 'Show more',
  },
});

type Props = {
  onEdit?: () => void;
  originalLanguage?: string;
  originalText?: string;
  text?: string;
  translationPolicy?: string;
  rows?: number;
};

export function Text({
  onEdit,
  originalLanguage,
  originalText,
  text,
  translationPolicy,
  rows,
}: Props): React.ReactElement | null {
  const language = useLanguage();
  const textContentRef = useRef<HTMLParagraphElement>(null);
  const textContainerRef = useRef<HTMLDivElement>(null);
  const [expanded, setExpanded] = useState(false);
  const [translated, setTranslated] = useState(false);
  const [showExpandButton, setShowExpandButton] = useState(false);

  const hanldeExpandButtonUpdateVisibility = useCallback(() => {
    const textContainerRect = textContainerRef.current?.getBoundingClientRect();
    const textContentRect = textContentRef.current?.getBoundingClientRect();

    if (textContainerRect && textContentRect) {
      setShowExpandButton(textContainerRect.height < textContentRect.height);
    }
  }, []);
  const handeleExpand = useCallback(() => {
    setExpanded(true);
    requestAnimationFrame(hanldeExpandButtonUpdateVisibility);
  }, [hanldeExpandButtonUpdateVisibility]);
  const handleTranslate = useCallback(() => {
    setTranslated((value) => !value);
    requestAnimationFrame(hanldeExpandButtonUpdateVisibility);
  }, [hanldeExpandButtonUpdateVisibility]);

  useRafResize(() => {
    if (!expanded) {
      hanldeExpandButtonUpdateVisibility();
    }
  });

  if (!text && !onEdit) {
    return null;
  }

  const differentLanguage = language !== originalLanguage;
  const showTranslationButton =
    differentLanguage &&
    (translationPolicy === TranslationPolicy.ORIGINAL_FIRST ||
      translationPolicy === TranslationPolicy.TRANSLATED_FIRST);

  let showOriginalText =
    differentLanguage &&
    (translationPolicy === TranslationPolicy.ORIGINAL_ONLY ||
      translationPolicy === TranslationPolicy.ORIGINAL_FIRST);

  showOriginalText = translated ? !showOriginalText : showOriginalText;

  return (
    <div className={styles.root}>
      {text && (
        <Locator id="OrderReviewText">
          <div
            className={cn(styles.text, expanded && styles.isExpanded)}
            ref={textContainerRef}
            style={{'--rows': rows} as React.CSSProperties}
          >
            <p ref={textContentRef}>{showOriginalText ? originalText : text}</p>
          </div>
        </Locator>
      )}
      {showExpandButton && (
        <div className={styles.controls}>
          <button className={styles.expandButton} onClick={handeleExpand} type="button">
            <FormattedMessage {...messages.expandButton} />
          </button>
        </div>
      )}
      {showTranslationButton && (
        <div className={styles.controls}>
          <button
            onClick={handleTranslate}
            type="button"
            className={showOriginalText ? styles.showTranslated : styles.showOriginal}
          >
            {showOriginalText ? (
              <FormattedMessage
                description="Show translated review button"
                defaultMessage="Show translated review"
              />
            ) : (
              <FormattedMessage
                description="Show review in original language button"
                defaultMessage="Show in original language"
              />
            )}
          </button>
        </div>
      )}
      {onEdit && (
        <div className={styles.controls}>
          <button onClick={onEdit} type="button" className={styles.edit}>
            <FormattedMessage description="Edit review button" defaultMessage="Edit" />
          </button>
        </div>
      )}
    </div>
  );
}
