import PropTypes from 'prop-types';
import ImageBundle from 'shapes/ImageBundle';
import PaymentMethodBadge from 'shapes/PaymentMethodBadge';

export {
  PaymentMethodKnownType,
  PaymentMethodSyntheticType,
  PaymentMethodWithoutUserInteraction,
} from 'shapes/PaymentMethod';

export default PropTypes.shape({
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  icon: ImageBundle,
  badge: PaymentMethodBadge,
  isPrimary: PropTypes.bool.isRequired,
  isEnabled: PropTypes.bool.isRequired,
  isRemovable: PropTypes.bool.isRequired,
  helpInfo: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
  }),
});
