import React, {CSSProperties} from 'react';
import {Text as TextType} from 'types/Popup';
import {getColorStyles} from 'utils/styles/color';

type Props = {
  text: TextType;
  style?: CSSProperties;
};

export const Text: React.FC<Props> = ({text, style}: Props) => (
  <span style={{...getColorStyles(text.color), ...getColorStyles(text.textColor), ...style}}>
    {text.text}
  </span>
);
