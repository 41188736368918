import {enhanceCheckoutSessionItem} from 'store/enhancer/checkoutSessionItem';
import {enhanceLoadedTime} from 'store/utils/enhancers';
import {CheckoutSession, CheckoutSessionGroup, CheckoutSessionItem} from 'types/CheckoutSession';

function enhanceCheckoutSessionItems(items: CheckoutSessionItem[]): CheckoutSessionItem[] {
  return items.map((item) => enhanceCheckoutSessionItem(item));
}

function enhanceCheckoutSessionGroups(groups: CheckoutSessionGroup[]): CheckoutSessionGroup[] {
  return groups.map((group) => ({...group, items: enhanceCheckoutSessionItems(group.items)}));
}

export function enhanceCheckoutSession(session: CheckoutSession): CheckoutSession {
  const result = session;

  result.groups = session.groups ? enhanceCheckoutSessionGroups(session.groups) : [];

  enhanceLoadedTime(result);

  return result;
}
