import classNamesBind from 'classnames/bind';
import {Locator} from 'components/Locator';
import {ProductLite} from 'components/ProductLite';
import React, {useMemo, useRef} from 'react';
import {HorizontalPosition} from 'types/ContentList/common';
import {
  isSideBannerGroupProductItem,
  SideBannerGroup as SideBannerGroupType,
  SideBannerGroupBannerDesign,
  SideBannerGroupItemDesign,
} from 'types/ContentList/SideBannerGroup';
import type {ProductLite as ProductLiteType} from 'types/ProductLite';

import {Header} from '../Header';
import {useBannersPreview} from '../hooks';
import {WIDE6} from '../imageSizes';
import {Banner} from './Banner';
import styles from './index.scss';

const cn = classNamesBind.bind(styles);

type Props = {
  content: SideBannerGroupType;
  id: string;
  source?: string;
  onFavorite?: (product: ProductLiteType) => Promise<void>;
  onUnfavorite?: (product: ProductLiteType) => Promise<void>;
};

export function canRenderSideBannerGroup({
  appearance: {bannerDesign, itemDesign},
}: SideBannerGroupType): boolean {
  // prettier-ignore
  return (
    itemDesign === SideBannerGroupItemDesign.SMALL_CARD ||
    itemDesign === SideBannerGroupItemDesign.LARGE_CARD ||
    bannerDesign === SideBannerGroupBannerDesign.ONE_LINE ||
    bannerDesign === SideBannerGroupBannerDesign.TWO_LINE
  );
}

export const SideBannerGroup = React.memo(function SideBannerGroup({
  content: {
    appearance: {bannerDesign, bannerPosition},
    banner,
    header,
    items,
  },
  id,
  source,
  onFavorite,
  onUnfavorite,
}: Props): JSX.Element {
  const bannerRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef(null);
  const linkRef = useRef(null);
  const headerInContent = bannerDesign === SideBannerGroupBannerDesign.TWO_LINE;

  const products = useMemo(() => {
    return items.filter(isSideBannerGroupProductItem).map((item) => item.product);
  }, [items]);

  useBannersPreview({
    bannersBlockId: id,
    clickRefs: [linkRef, titleRef],
    rootRef: bannerRef,
  });

  return (
    <Locator id="ContentListProductsGroup">
      <div className={cn(`design-${bannerDesign}`)}>
        {header && !headerInContent && (
          <Header header={header} linkRef={linkRef} titleRef={titleRef} />
        )}
        <div className={cn('inner', {reverse: bannerPosition === HorizontalPosition.END})}>
          <div ref={bannerRef} className={styles.bannerWrap}>
            <Banner banner={banner} design={bannerDesign} id={id} source={source} />
          </div>
          <div className={styles.productsWrap}>
            {header && headerInContent && (
              <Header header={header} linkRef={linkRef} titleRef={titleRef} />
            )}
            <div className={styles.products}>
              {products.map((product) => (
                <div key={product.id} className={styles.product}>
                  <ProductLite
                    key={product.id}
                    product={product}
                    sizes={WIDE6.sizes}
                    width="100%"
                    onFavorite={onFavorite}
                    onUnfavorite={onUnfavorite}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Locator>
  );
});
