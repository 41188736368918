import {enhanceLoadedTime} from 'store/utils/enhancers';
import {CartItem, CartItemOptions} from 'types/CartGroup/CartItem';

export function enhanceCartItem(
  item: CartItem,
  selectedList: string[],
  options: CartItemOptions,
): CartItem {
  const result = item;

  // for ecommerce
  result.currency = options.currency;
  result.isSelected = selectedList.includes(item.id);

  enhanceLoadedTime(result);

  return result;
}
