import classNamesBind from 'classnames/bind';
import styles from 'components/ClientProduct/Reviews/Filters/index.scss';
import {createFilterUrl} from 'components/ClientProduct/Reviews/utils';
import {ObfuscatedCount} from 'components/ObfuscatedCount';
import {VisuallyHidden} from 'components/VisuallyHidden';
import {useBot} from 'hooks/useBot';
import React from 'react';
import {defineMessages, useIntl} from 'react-intl';
import {Link} from 'react-router-dom';
import {ProductReviewFilter} from 'types/ProductReviews';

import {FilterIcon} from './FilterIcon';

const cn = classNamesBind.bind(styles);

type Props = {
  filter: ProductReviewFilter;
  href: string;
  selected: boolean;
};

const messages = defineMessages({
  withPhoto: {
    description: 'Текстовые подсказки для экранных читалок',
    defaultMessage: 'Reviews with photos',
  },
  withText: {
    description: 'Текстовые подсказки для экранных читалок',
    defaultMessage: 'Reviews with text',
  },
  oneStar: {
    description: 'Текстовые подсказки для экранных читалок',
    defaultMessage: 'One-star reviews',
  },
  twoStars: {
    description: 'Текстовые подсказки для экранных читалок',
    defaultMessage: 'Two-star reviews',
  },
  threeStars: {
    description: 'Текстовые подсказки для экранных читалок',
    defaultMessage: 'Three-star reviews',
  },
  fourStars: {
    description: 'Текстовые подсказки для экранных читалок',
    defaultMessage: 'Four-star reviews',
  },
  fiveStars: {
    description: 'Текстовые подсказки для экранных читалок',
    defaultMessage: 'Five-star reviews',
  },
  userCountry: {
    description: 'Text hint for screen readers',
    defaultMessage: 'Reviews from your country',
  },
  // some languages require special symbol with alone count
  count: {
    description: 'Review count breakdown by rating or text/image availability',
    defaultMessage: '{count}',
  },
});

export function Filter({filter, href, selected}: Props): React.ReactElement {
  const intl = useIntl();
  const bot = useBot();
  const hoverable = !selected;
  const className = cn('button', {hoverable, selected});
  const message =
    filter.title ||
    (filter.id !== 'all' && messages[filter.id] ? intl.formatMessage(messages[filter.id]) : null);
  const content =
    filter.id === 'all'
      ? filter.title
      : intl.formatMessage(messages.count, {count: <ObfuscatedCount count={filter.count} />});
  const hiddenContent = filter.id !== 'all' ? message : null;

  if (bot) {
    return <span className={className}>{content}</span>;
  }

  const to = createFilterUrl(href, {filterId: filter.id, page: ''});

  return (
    <Link className={className} to={to} aria-current={selected}>
      <VisuallyHidden>{hiddenContent}</VisuallyHidden>
      <FilterIcon filter={filter} />
      {content}
    </Link>
  );
}
