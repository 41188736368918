import {requestActionCreator} from 'store/utils/requestActions';
import {Fields} from 'types/AddressMetainfo';
import {PersonalDataFormOrigin} from 'types/PersonalData';
import {FuncAction} from 'typesafe-actions';

import {
  loadPersonalDataForm as loadPersonalDataFormAction,
  LoadPersonalDataFormResponse,
  updatePersonalDataForm as updatePersonalDataFormAction,
  UpdatePersonalDataFormResponse,
  validatePartialPersonalDataForm as validatePartialPersonalDataFormAction,
  ValidatePartialPersonalDataFormResponse,
  validatePersonalDataForm as validatePersonalDataFormAction,
  ValidatePersonalDataFormResponse,
} from './actions';

type LoadPersonalDataFormResponseBody = {
  payload: LoadPersonalDataFormResponse;
};

export function loadPersonalDataForm(origin: PersonalDataFormOrigin): FuncAction {
  return requestActionCreator(loadPersonalDataFormAction, {origin}, (_, client) =>
    client.secureApi
      .post<LoadPersonalDataFormResponseBody>('/personalData/form/get', {
        body: {origin},
      })
      .then(({body: {payload}}) => payload),
  );
}

type ValidatePartialPersonalDataFormResponseBody = {
  payload: ValidatePartialPersonalDataFormResponse;
};

export function validatePartialPersonalDataForm(
  fields: Fields,
  origin: PersonalDataFormOrigin,
): FuncAction {
  return requestActionCreator(
    validatePartialPersonalDataFormAction,
    {fields, origin},
    (_, client) =>
      client.secureApi
        .post<ValidatePartialPersonalDataFormResponseBody>('/personalData/form/validatePartial', {
          body: {fields, origin},
        })
        .then(({body: {payload}}) => payload),
  );
}

export type ValidatePersonalDataFormResponseBody = {
  payload: ValidatePersonalDataFormResponse;
};

export function validatePersonalDataForm(
  fields: Fields,
  origin: PersonalDataFormOrigin,
): FuncAction {
  return requestActionCreator(validatePersonalDataFormAction, {fields, origin}, (_, client) =>
    client.secureApi
      .post<ValidatePersonalDataFormResponseBody>('/personalData/form/validate', {
        body: {fields, origin},
      })
      .then(({body: {payload}}) => payload),
  );
}

export type UpdatePersonalDataFormResponseBody = {
  payload: UpdatePersonalDataFormResponse;
};

export function updatePersonalDataForm(fields: Fields, origin: PersonalDataFormOrigin): FuncAction {
  return requestActionCreator(updatePersonalDataFormAction, {fields, origin}, (_, client) =>
    client.secureApi
      .post<UpdatePersonalDataFormResponseBody>('/personalData/form/put', {
        body: {fields, origin},
      })
      .then(({body: {payload}}) => payload),
  );
}
