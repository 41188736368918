import {enhanceLanguage} from 'store/utils/enhancers';
import {userPreferencesChanged} from 'helpers/mobileAppApi';
import {isRecord} from 'utils/guards';
import {DeephemerizeReasonType} from 'helpers/ApiClient/Device/deephemerizeReason';

export const LOAD = 'userPreferences/LOAD';
export const LOAD_SUCCESS = 'userPreferences/LOAD_SUCCESS';
export const LOAD_FAIL = 'userPreferences/LOAD_FAIL';
export const SAVE = 'userPreferences/SAVE';
export const SAVE_SUCCESS = 'userPreferences/SAVE_SUCCESS';
export const SAVE_FAIL = 'userPreferences/SAVE_FAIL';

export const initialState = {
  data: null,
  error: null,
  loaded: false,
  loading: false,
  savingError: null,
  saving: false,
};

function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        error: null,
        loaded: false,
        loading: true,
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.result,
      };
    case LOAD_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case SAVE:
      return {
        ...state,
        saving: true,
        savingError: null,
      };
    case SAVE_SUCCESS:
      return {
        ...state,
        data: action.result,
        loaded: true,
        saving: false,
      };
    case SAVE_FAIL:
      return {
        ...state,
        savingError: action.error,
        saving: false,
      };
    default:
      return state;
  }
}

export default reducer;

export function isUserPreferencesLoaded(state) {
  return state.userPreferences.loaded;
}

export function isUserPreferencesLoading(state) {
  return state.userPreferences.loading;
}

export function getUserPreferences(state) {
  return isUserPreferencesLoaded(state) ? state.userPreferences.data : null;
}

export function getUserPreferencesError(state) {
  return state.userPreferences.error;
}

export function isUserPreferencesSaving(state) {
  return state.userPreferences.saving;
}

export function getUserPreferencesSavingError(state) {
  return state.userPreferences.savingError;
}

export function loadUserPreferences() {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client) =>
      client.api.get('/users/self/preferences').then(({language, body: {payload}}) =>
        enhanceLanguage({
          ...payload,
          language,
        }),
      ),
  };
}

export function saveUserPreferences(body, forceUpgradeEphemeral) {
  return {
    types: [SAVE, SAVE_SUCCESS, SAVE_FAIL],
    promise: (client) => {
      const promise =
        forceUpgradeEphemeral && client.device.isEphemeral()
          ? client.device.upgradeEphemeral({
              type: DeephemerizeReasonType.UPDATE_PREFERENCES,
              list: isRecord(body) ? Object.keys(body) : [],
            })
          : Promise.resolve(null);

      return promise.then(() =>
        client.api.put('/users/self/preferences', {body}).then(({language, body: {payload}}) => {
          client.analytics.setThirdPartyPreferences(payload.thirdParties);
          userPreferencesChanged();
          return enhanceLanguage({
            ...payload,
            language,
          });
        }),
      );
    },
  };
}
