import cn from 'classnames';
import {Locator} from 'components/Locator';
import {Preloader, Size as PreloaderSize} from 'components/Preloader';
import {CircleProgress} from 'components/UIKit/CircleProgress';
import {Icon} from 'components/UIKit/Icon';
import React, {useCallback, useMemo} from 'react';
import {isNotNullish, isNumber} from 'utils/guards';

import {MediaState, MediaStatus, MediaType, UploaderErrorType, UploaderSize} from '../types';
import {useThumbnail} from './hooks/useThumbnail';
import styles from './index.scss';

type Props = {
  mediaState: MediaState;
  onRemove?: (mediaUUid: string) => void;
  onRetry?: (mediaUUid: string) => void;
  size?: UploaderSize;
};

const RETRY_UPLOADER_ERROR_TYPE = [
  UploaderErrorType.EXTERNAL_ERROR,
  UploaderErrorType.INTERNAL_ERROR,
  UploaderErrorType.TOO_MANY_FILES,
  UploaderErrorType.UNKNOWN,
];

export function Item({mediaState, onRemove, onRetry, size}: Props): JSX.Element {
  const thumbnailElement = useThumbnail(mediaState);
  const handleRemove = useCallback(() => {
    onRemove?.(mediaState.uuid);
  }, [mediaState.uuid, onRemove]);
  const handleRetry = useCallback(() => {
    onRetry?.(mediaState.uuid);
  }, [mediaState.uuid, onRetry]);

  const canRetry = useMemo(() => {
    const mediaStateError = mediaState.error;

    return (
      Boolean(onRetry) &&
      isNotNullish(mediaStateError) &&
      RETRY_UPLOADER_ERROR_TYPE.includes(mediaStateError.type)
    );
  }, [mediaState.error, onRetry]);
  const showLoading =
    mediaState.status === MediaStatus.IN_PROGRESS ||
    mediaState.status === MediaStatus.IN_PROGRESS_OF_DELETION ||
    mediaState.status === MediaStatus.WILL_BE_DELETED ||
    mediaState.status === MediaStatus.WILL_BE_UPLOADED;
  const showError = mediaState.status === MediaStatus.ERROR;
  const showThumbnail = mediaState.status === MediaStatus.SUCCESS && Boolean(thumbnailElement);
  const showEmpty = mediaState.status === MediaStatus.SUCCESS && !thumbnailElement;
  const isVideo = mediaState.type === MediaType.VIDEO;
  const progress = mediaState.bytesUploaded / mediaState.bytesTotal;

  return (
    <Locator id="mediaUploader-item" status={mediaState.status}>
      <div
        className={cn(styles.item, {
          [styles[`size-${size}`]!]: Boolean(size),
        })}
      >
        {onRemove ? (
          <button className={styles.removeAction} onClick={handleRemove} type="button">
            <Icon type="mono" name="close-circle-filled-16" />
          </button>
        ) : null}
        <div className={styles.content}>
          {showLoading &&
            (isNumber(progress) && progress > 0 ? (
              <CircleProgress progress={progress} />
            ) : (
              <Preloader size={PreloaderSize.FULL_COVER} />
            ))}
          {showError &&
            (canRetry ? (
              <button className={styles.retryAction} onClick={handleRetry} type="button">
                <Icon type="mono" name="repeat-linear-16" />
              </button>
            ) : (
              <Icon type="mono" name="error-filled-24" />
            ))}
          {showEmpty && (
            <div className={styles.empty}>
              {isVideo ? (
                <Icon type="mono" name="video-filled-24" />
              ) : (
                <Icon type="mono" name="image-filled-24" />
              )}
            </div>
          )}
        </div>
        {showThumbnail && <div className={styles.thumbnail}>{thumbnailElement}</div>}
      </div>
    </Locator>
  );
}
