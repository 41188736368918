import React, {memo} from 'react';
import {ParcelTrackingShape} from 'shapes/ParcelTracking';
import {ParcelShape} from 'shapes/Parcel';
import {FormattedMessage} from 'react-intl';
import {FormattedDate} from 'components/i18n/FormattedDate';
import {CopyContainer} from 'components/CopyContainer';
import {Icon} from 'components/UIKit/Icon';
import {Locator} from 'components/Locator';
import styles from './State.scss';

export const State = memo(({parcel, parcelTracking}) => {
  const {trackingNumber} = parcel?.lite?.tracking ?? {};

  return (
    <>
      <div className={styles.tracking}>
        <span className={styles.label}>
          <FormattedMessage
            defaultMessage="Tracking number:"
            description="Parcel card shipping label"
          />
        </span>
        &nbsp;
        <span className={styles.value}>
          {trackingNumber ? (
            <Locator id="parcel-tracking-number">
              <CopyContainer value={trackingNumber}>
                {trackingNumber}
                <span className={styles.copyIcon}>
                  <Icon type="mono" name="copy-linear-16" />
                </span>
              </CopyContainer>
            </Locator>
          ) : (
            '—'
          )}
        </span>
      </div>
      {parcelTracking.shippedTimeMs ? (
        <div className={styles.date}>
          <span className={styles.label}>
            <FormattedMessage
              defaultMessage="Shipped date:"
              description="Parcel card shipping label"
            />
          </span>
          &nbsp;
          <Locator id="parcel-tracking-departure-date">
            <span className={styles.value}>
              <FormattedDate
                value={parcelTracking.shippedTimeMs}
                day="numeric"
                month="long"
                year="numeric"
              />
            </span>
          </Locator>
        </div>
      ) : null}
    </>
  );
});

State.propTypes = {
  parcel: ParcelShape.isRequired,
  parcelTracking: ParcelTrackingShape.isRequired,
};
