import {useCallback, useEffect, useRef, useState} from 'react';
import {ImageBundle} from 'types/Image';
import {getImageKey} from 'utils/image';

type OnLoadHandler = () => void;

export function useImageLoading(
  image?: ImageBundle,
  minLoadingTime?: number,
): [boolean, OnLoadHandler] {
  const loadedBackgroundImagesMap = useRef(new Map());
  const key = image ? getImageKey(image) : '';
  const [loadingTs, setLoadingTs] = useState(0);
  const [loading, setLoading] = useState(!loadedBackgroundImagesMap.current.has(key) && !!key);
  const [debouncedLoading, setDebouncedLoading] = useState(true);
  const handler = useCallback(() => {
    setLoading(false);
    loadedBackgroundImagesMap.current.set(key, undefined);
  }, [key]);

  useEffect(() => {
    setLoading(!loadedBackgroundImagesMap.current.has(key) && !!key);
  }, [key]);

  useEffect(() => {
    if (loading) {
      setLoadingTs(Date.now());
      setDebouncedLoading(true);
    } else {
      const diff = Date.now() - loadingTs;
      if (!minLoadingTime || diff > minLoadingTime) {
        setDebouncedLoading(false);
      } else {
        const delay = minLoadingTime - diff;
        const timer = setTimeout(() => setDebouncedLoading(false), delay);
        return () => clearTimeout(timer);
      }
    }
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return [debouncedLoading, handler];
}
