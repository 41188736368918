import PropTypes from 'prop-types';
import ImageBundleShape from './ImageBundle';

const Category = PropTypes.shape({
  behavior: PropTypes.string,
  count: PropTypes.shape({
    value: PropTypes.number,
  }),
  description: PropTypes.string,
  hasPublicChildren: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  mainImage: ImageBundleShape,
  name: PropTypes.string.isRequired,
});

Category.children = PropTypes.arrayOf(Category);

export const SubCategoryShape = PropTypes.arrayOf(Category);
