import cn from 'classnames';
import {Image} from 'components/Image';
import {Overlay} from 'components/Overlay';
import {Button, ButtonLocator} from 'components/UIKit/Button';
import Next from 'components/WhiteList/next.jsx.svg';
import {type Mark, createLocator, Locator} from 'create-locator';
import {useUniversalNavigate} from 'hooks/useUniversalNavigate';
import {useIsMobileOrTablet} from 'hooks/useUserAgent';
import React, {ForwardedRef, HTMLAttributes, RefObject, useCallback} from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';
import {Color} from 'types/Color';
import {Gradient} from 'types/Gradient';
import {ImageBundle} from 'types/Image';
import {StyledButton} from 'types/StyledButton';
import {isColorLight} from 'utils/colors';
import {convertBackendColorToCSSValue, convertBackendGradientToCSSValue} from 'utils/styles/color';

import styles from './index.scss';

const messages = defineMessages({
  more: {
    description: 'Текст на кнопке баннера, если он не задан в админке',
    defaultMessage: 'More',
  },
});

export type GenericBannerLocator = Locator<
  {actionButton: ButtonLocator; mobileActionButton: ButtonLocator},
  {isMobile?: string}
>;

type Props = {
  title: string;
  text: string;
  icon?: ImageBundle;
  actionButton?: StyledButton;
  buttons?: StyledButton[];
  textColor?: Color;
  background?: Gradient;
  fullWidth?: boolean;
  compact?: boolean;
  borderColor?: Color;
  onClick?(): void;
  processing?: boolean;
  buttonRef?: RefObject<HTMLDivElement>;
} & Partial<Mark<GenericBannerLocator>>;

const Wrapper = React.memo(
  ({
    tagName = 'div',
    className,
    children,
    ...props
  }: React.PropsWithChildren<{
    tagName: 'button' | 'div';
    className: string;
  }> &
    HTMLAttributes<HTMLElement>) => {
    const ConditionalTag = tagName;

    return (
      <ConditionalTag
        className={cn(className, tagName === 'button' ? styles.bannerButton : null)}
        type={tagName === 'button' ? 'button' : undefined}
        {...props}
      >
        {children}
      </ConditionalTag>
    );
  },
);

const GenericBannerButton = ({button}: {button: StyledButton}): JSX.Element => {
  const {text: buttonText, url, textColor, backgroundColor, backgroundGradient, onClick} = button;

  const navigate = useUniversalNavigate();

  const handleActionClick = useCallback(() => {
    if (url) {
      navigate(url);
    }

    if (onClick) {
      onClick();
    }
  }, [navigate, onClick, url]);

  const buttonStyles =
    textColor && (backgroundColor || backgroundGradient)
      ? {
          color: {
            color: convertBackendColorToCSSValue(textColor),
            background: backgroundColor
              ? convertBackendColorToCSSValue(backgroundColor)
              : convertBackendGradientToCSSValue(backgroundGradient),
          },
        }
      : {};

  return (
    <Button
      tag="button"
      size="small"
      shape="round"
      color="primary"
      onClick={handleActionClick}
      {...buttonStyles}
    >
      {buttonText}
    </Button>
  );
};

export const GenericBanner = React.forwardRef(function GenericBanner(
  props: Props,
  ref: ForwardedRef<HTMLDivElement>,
): React.ReactElement | null {
  const {
    title,
    text,
    icon,
    buttons,
    actionButton,
    textColor,
    background,
    fullWidth,
    borderColor,
    compact,
    onClick,
    processing,
    buttonRef,
  } = props;
  const locator = createLocator(props);
  const navigate = useUniversalNavigate();
  const mobileOrTablet = useIsMobileOrTablet();
  const isCompact = mobileOrTablet || compact;

  const {text: buttonText, url} = actionButton || {};

  const handleActionClick = useCallback(() => {
    onClick?.();

    if (url) navigate(url);
  }, [navigate, onClick, url]);

  const bannerStyle = {
    backgroundImage: background && convertBackendGradientToCSSValue(background),
    color: textColor && convertBackendColorToCSSValue(textColor),
    '--border-color': convertBackendColorToCSSValue(borderColor),
  };

  return (
    <Overlay loading={processing}>
      <Wrapper
        tagName={isCompact && url ? 'button' : 'div'}
        className={cn(styles.banner, {[styles.fullWidth!]: fullWidth})}
        style={bannerStyle}
        onClick={isCompact ? handleActionClick : undefined}
      >
        <div
          role="banner"
          ref={ref}
          className={styles.content}
          {...locator({isMobile: String(isCompact)})}
        >
          <div className={styles.title}>
            {icon ? (
              <div className={styles.image}>
                <Image
                  contain
                  image={icon}
                  vwFit={{xs: '24px'}}
                  className={styles.iconImage}
                  height="100%"
                  width="100%"
                />
              </div>
            ) : null}
            <div className={styles.titleContent}>{title}</div>
          </div>
          {text ? <div className={styles.text}>{text}</div> : null}
          {buttons?.length ? (
            <div className={styles.buttons}>
              {buttons?.map((button, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <div className={styles.buttonContainer} key={index}>
                  <GenericBannerButton button={button} />
                </div>
              ))}
            </div>
          ) : null}
        </div>
        {actionButton ? (
          <div className={styles.action} ref={buttonRef}>
            {isCompact ? (
              <span className={styles.forward}>
                <Next className={styles.arrow} {...locator.mobileActionButton()} />
              </span>
            ) : (
              <Button
                tag="button"
                color={textColor && isColorLight(textColor) ? 'ghost' : 'primary'}
                size="medium"
                shape="round"
                onClick={handleActionClick}
                {...locator.actionButton()}
              >
                {buttonText || <FormattedMessage {...messages.more} />}
              </Button>
            )}
          </div>
        ) : null}
      </Wrapper>
    </Overlay>
  );
});
