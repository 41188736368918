import {getSelectors} from 'store/utils/reducerCreators/simple/selectors';
import {createSelectorFactory} from 'store/utils/selectors';

export const {
  isLoading: isPassportDataLoading,
  isLoaded: isPassportDataLoaded,
  getData: getPassportData,
  getError: getPassportDataError,
  getRequest: getPassportDataRequest,
} = getSelectors(createSelectorFactory((globalState) => globalState.personalData.passport));
