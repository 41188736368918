import classnames from 'classnames/bind';
import {useScope} from 'hooks/useScope';
import React, {ComponentType, HTMLProps} from 'react';

import styles from './index.scss';

const cn = classnames.bind(styles);

type Props = {
  color?: string;
  isSmall?: boolean;
  title?: string;
};

export const Geek: React.FC<Props> = ({isSmall = false, color = '#000', title}: Props) => {
  const scope = useScope();
  // eslint-disable-next-line global-require
  const imageModule = scope.isJmt ? require('./geekJmt.jsx.svg') : require('./geekJoom.jsx.svg');

  const Image: ComponentType<HTMLProps<HTMLOrSVGElement>> =
    'default' in imageModule ? imageModule.default : imageModule;

  return (
    <Image
      alt={title}
      style={{color}}
      className={cn('logo', scope.isJmt ? 'jmtGeek' : 'joomGeek', {isSmall})}
    />
  );
};
