import {GenericBanner} from 'components/GenericBanner';
import {eventParamsList} from 'helpers/eventParams';
import {useSelector} from 'hooks/redux';
import {useAnalytics} from 'hooks/useAnalytics';
import {useDispatchAction} from 'hooks/useDispatch';
import {usePreviewEvent} from 'hooks/usePreviewEvent';
import React, {useCallback, useRef} from 'react';
import {
  emailConfirmationSend,
  getEmailConfirmationLoading,
  getEmailConfirmationSent,
} from 'store/modules/auth';
import {EmailConfirmation} from 'types/EmailConfirmation';
import {isDefAndNotNull} from 'utils/function';

type Props = {
  banner: EmailConfirmation;
  compact?: boolean;
};

export function EmailConfirmationBanner({banner, compact}: Props): JSX.Element | null {
  const {
    icon,
    background,
    beforeEmailSentBlock,
    afterEmailSentBlock,
    afterSecondEmailSentBlock,
    eventParams,
  } = banner;

  const previewRef = useRef(null);
  usePreviewEvent({
    rootRef: previewRef,
    previewEvent: {
      type: 'emailVerificationPreview',
      params: eventParamsList(eventParams),
    },
  });

  const analytics = useAnalytics();

  const emailConfirmationSent = useSelector(getEmailConfirmationSent);
  const processing = useSelector(getEmailConfirmationLoading);
  const sendConfirmation = useDispatchAction(emailConfirmationSend);

  const currentBlock =
    [beforeEmailSentBlock, afterEmailSentBlock, afterSecondEmailSentBlock][
      Math.min(emailConfirmationSent, 2)
    ] || afterEmailSentBlock;

  const {title, description, sendEmailButton, supportButton} = currentBlock;

  const handleSendEmailClick = useCallback(() => {
    analytics.sendEvent({
      type: 'emailVerificationClick',
      params: eventParamsList(eventParams),
    });
    sendConfirmation();
  }, [analytics, eventParams, sendConfirmation]);

  const handleSupportClick = useCallback(() => {
    analytics.sendEvent({
      type: 'emailVerificationContactSupportClick',
      params: eventParamsList(eventParams),
    });
  }, [analytics, eventParams]);

  const buttons = [
    sendEmailButton
      ? {
          ...sendEmailButton,
          onClick: handleSendEmailClick,
        }
      : null,
    supportButton
      ? {
          ...supportButton,
          onClick: handleSupportClick,
        }
      : null,
  ].filter(isDefAndNotNull);

  return (
    <GenericBanner
      ref={previewRef}
      processing={processing}
      icon={icon}
      background={background}
      title={title.text}
      text={description.text}
      buttons={buttons}
      compact={compact}
    />
  );
}
