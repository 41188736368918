import cn from 'classnames';
import {AnyLink} from 'components/AnyLink';
import {Locator} from 'components/Locator';
import {Icon} from 'components/UIKit/Icon';
import {useAnalytics} from 'hooks/useAnalytics';
import {useBoolean} from 'hooks/useBoolean';
import React, {useCallback} from 'react';

import styles from './index.scss';

type FooterColumnItemProps = React.PropsWithChildren<{
  'data-testid'?: string;
  hasReferrer?: boolean;
  onClick?: () => void;
  to?: string;
}>;

function FooterColumnItem({
  'data-testid': testId,
  children,
  hasReferrer,
  onClick,
  to,
}: FooterColumnItemProps): JSX.Element {
  const analytics = useAnalytics();

  const handleClick = useCallback(() => {
    analytics.sendEvent({
      type: 'supportLinkClick',
      payload: {from: 'footer'},
    });

    onClick?.();
  }, [analytics, onClick]);

  return (
    <li className={styles.columnItem}>
      <AnyLink
        className={styles.columnItemLink}
        onClick={handleClick}
        rel={hasReferrer ? 'noopener' : 'noopener noreferrer'}
        to={to}
        testId={testId}
      >
        {children}
      </AnyLink>
    </li>
  );
}

type FooterColumnProps = React.PropsWithChildren<{
  title: React.ReactNode;
}>;

export function FooterColumn({children, title}: FooterColumnProps): JSX.Element {
  const state = useBoolean(false);

  return (
    <div className={cn(styles.column, !state.value && styles.isCollapsed)}>
      <div className={styles.columnHeader}>
        <div className={styles.columnTitle}>{title}</div>
        <Locator id="FooterExpandButton">
          <button className={styles.columnToggler} onClick={state.toggle} type="button">
            <Icon
              height={24}
              name={state.value ? 'chevron-up-linear-24' : 'chevron-down-linear-24'}
              type="mono"
              width={24}
            />
          </button>
        </Locator>
      </div>
      <ul className={styles.columnItems}>{children}</ul>
    </div>
  );
}

FooterColumn.Item = FooterColumnItem;
