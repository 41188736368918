import {Locator} from 'components/Locator';
import {Button} from 'components/UIKit/Button';
import {Loading} from 'components/UIKit/Button/Content';
import {useIntersectionObserverRef} from 'hooks/useIntersectionObserverRef';
import {useMountedRef} from 'hooks/useMounted';
import {A11yContext} from 'providers/A11yContext';
import React, {ReactElement, useCallback, useContext, useMemo, useRef, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import breakpoints from 'theme/gridBreakpoints.export.scss';

import styles from './index.scss';

const DEFAULT_GAP = 150;

export enum ScrollLoaderActionType {
  CLICK = 'click',
  SCROLL = 'scroll',
}

function isEnabled(from: string) {
  if (!__CLIENT__) {
    return false;
  }

  if (!from) {
    return false;
  }

  return !!window.matchMedia && window.matchMedia(`(min-width: ${breakpoints[from]})`).matches;
}

type Props = {
  children: React.ReactNode;
  enabledFrom?: string;
  gap?: number;
  removeLoadMoreLink?: boolean;
  to?: string;
  onLoad?: (
    event: React.SyntheticEvent<HTMLElement> | undefined,
    action: 'click' | 'scroll',
  ) => Promise<void>;
  autoLoadingImmediately?: boolean;
  disableAutoload?: boolean;
};

export function ScrollLoader({
  onLoad,
  to,
  children,
  gap = DEFAULT_GAP,
  enabledFrom = 'xs',
  removeLoadMoreLink,
  autoLoadingImmediately,
  disableAutoload = false,
}: Props): ReactElement {
  const mountedRef = useMountedRef();
  const [loading, setLoading] = useState(false);
  const [autoLoading, setAutoLoading] = useState(
    isEnabled(enabledFrom) ? autoLoadingImmediately : false,
  );
  const autoLoadRef = useRef<HTMLDivElement>(null);

  const {settings: a11Settings} = useContext(A11yContext);

  const shouldDisableAutoLoad = a11Settings.disableAutoload || disableAutoload;

  const handleLoad = useCallback(
    (
      event: React.SyntheticEvent<HTMLElement> | undefined,
      action: 'click' | 'scroll' = ScrollLoaderActionType.CLICK,
    ) => {
      if (action === ScrollLoaderActionType.CLICK && event) {
        event.preventDefault();
      }

      if (!loading && onLoad) {
        setLoading(true);
        onLoad(event, action)
          .then(
            () =>
              mountedRef.current &&
              setAutoLoading(!shouldDisableAutoLoad ? isEnabled(enabledFrom) : false),
          )
          .finally(() => mountedRef.current && setLoading(false));
      }
    },
    [shouldDisableAutoLoad, enabledFrom, loading, mountedRef, onLoad],
  );

  const handleObserve = useCallback(
    (entry: IntersectionObserverEntry) => {
      if (entry.isIntersecting) {
        handleLoad(undefined, 'scroll');
      }
    },
    [handleLoad],
  );

  useIntersectionObserverRef(handleObserve, {
    targetRef: autoLoadRef,
  });

  const message = useMemo(
    () => (
      <Locator id="ScrollLoader-showMoreButton">
        <span>
          <FormattedMessage description="More button" defaultMessage="Show more" />
        </span>
      </Locator>
    ),
    [],
  );

  const createButton = useCallback(
    ({id, loading = false}: {id: string; loading?: boolean}) => {
      return (
        <div className={styles.button}>
          <Locator id={id}>
            <Button onClick={handleLoad} color="primary" size="large" to={to || ''}>
              <Loading loading={loading}>{message}</Loading>
            </Button>
          </Locator>
        </div>
      );
    },
    [message, handleLoad, to],
  );

  const controls = useMemo(() => {
    if (!onLoad) {
      return null;
    }

    if (loading) {
      return createButton({id: 'ScrollLoader-loading', loading: true});
    }

    if (autoLoading) {
      return (
        <div className={styles.autoLoading}>
          <div
            data-testid="ScrollLoader-autoloading"
            ref={autoLoadRef}
            className={styles.autoLoadingObserver}
            style={{height: gap}}
          />
          <Button tag="button" color="primary" size="large">
            .
          </Button>
        </div>
      );
    }

    if (removeLoadMoreLink) {
      return (
        <div className={styles.button}>
          <Button tag="button" color="primary" size="large">
            {message}
          </Button>
        </div>
      );
    }

    return createButton({id: 'ShowMoreButton'});
  }, [onLoad, loading, autoLoading, removeLoadMoreLink, createButton, gap, message]);

  return (
    <>
      {children}
      {controls ? <div className={styles.controls}>{controls}</div> : null}
    </>
  );
}
