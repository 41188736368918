import {Scope} from 'config';
import {ScopeConfig} from 'helpers/ApiClient/Scope/ScopeConfig';
import {requestActionCreator} from 'store/utils/requestActions';
import {ProgressEvent} from 'superagent';
import {FaqStructureArticle, FaqStructureCategory, FaqStructureRaw} from 'types/FaqStructure';
import {FuncAction} from 'typesafe-actions';
import {create as createHashSet} from 'utils/hashSet';
import {create as createTree} from 'utils/tree';

import {
  loadArticle,
  LoadArticleResponse,
  loadStructure,
  LoadStructureResponse,
  sendForm,
  SendFormRequest,
  SendFormResponse,
  uploadFile,
  UploadFileResponse,
} from './actions';

type StructureResponseBody = {
  data: FaqStructureRaw;
};

type ArticleResponseBody = string;

type UploadFileResponseBody = {
  data: string;
};

function filterEmptyCategories(
  categories: FaqStructureCategory[],
  faqs: FaqStructureArticle[],
): FaqStructureCategory[] {
  const categoriesWithChildren: Partial<Record<string, boolean>> = {};

  categories.forEach(({parentId}) => {
    if (parentId) {
      categoriesWithChildren[parentId] = true;
    }
  });

  faqs.forEach(({categoryId}) => {
    if (categoryId) {
      categoriesWithChildren[categoryId] = true;
    }
  });

  return categories.filter(({id}) => categoriesWithChildren[id]);
}

function getTagsAllOfByScope(scope: ScopeConfig): string {
  if (scope.isJmt) {
    return 'jmt';
  }
  if (scope.is(Scope.CBTREND)) {
    return 'coolbetrending';
  }
  if (scope.domainScope === Scope.COOLBE) {
    return 'coolbecom';
  }
  if (scope.prefixScope === Scope.COOLBE) {
    return 'coolbe';
  }
  // GEEK currently doesn't have a custom FAQ
  return 'default';
}
export const loadFaqStructure = (countryCode: string): FuncAction =>
  requestActionCreator(loadStructure, {countryCode}, (store, client) =>
    client.faqApi
      .get<StructureResponseBody>(`/structure`, {
        query: {countryCode, tagsAllOf: getTagsAllOfByScope(client.scope)},
      })
      .then(
        ({
          language,
          body: {
            data: {categories, faqs},
          },
        }): LoadStructureResponse => {
          const categoriesWithChildren = filterEmptyCategories(categories, faqs);
          return {
            categories: createTree(categoriesWithChildren, {
              extractId: (item) => item.id,
              extractParentId: (item) => item.parentId,
            }),
            faqs: createHashSet(faqs, (item) => item.id),
            countryCode,
            language,
          };
        },
      ),
  );

// WEB-8833 hack for regional domains
const replaceCoolbeLinks = (text: string, scope: ScopeConfig): string => {
  if (scope.isDomain(Scope.COOLBE) && scope.deviceCustomDomain) {
    return text.replace(/coolbe\.com/g, scope.deviceCustomDomain);
  }

  return text;
};

export const loadFaqArticle = (id: string): FuncAction =>
  requestActionCreator(loadArticle, {id}, (store, client) =>
    client.faqApi.get<ArticleResponseBody>(`/faq/${id}`).then(
      (res): LoadArticleResponse => ({
        html: replaceCoolbeLinks(res.text || '', client.scope),
        id,
        language: res.language,
      }),
    ),
  );

export const uploadFaqFile = (
  id: string,
  file: File,
  onProgress?: (event: ProgressEvent) => unknown,
): FuncAction =>
  requestActionCreator(uploadFile, {id}, (store, client) =>
    client.faqApi
      .post<UploadFileResponseBody>('/upload', {
        attach: [['file', file]],
        headers: {filename: encodeURIComponent(file.name)},
        onProgress,
        id,
      })
      .then(
        (res): UploadFileResponse => ({
          uploadId: res.body.data,
        }),
      ),
  );

export const cancelFaqFileUploading =
  (id: string): FuncAction =>
  (store, client): boolean =>
    client.faqApi.abort(id);

export const sendFaqForm = (body: SendFormRequest): FuncAction =>
  requestActionCreator(sendForm, body, (store, client) =>
    client.api.post('/support/ticket', {body}).then((): SendFormResponse => ({})),
  );
