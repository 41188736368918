import React from 'react';
import {Link, LinkProps} from 'react-router-dom';

import styles from './index.scss';

type Props = Omit<LinkProps, 'className'> & {
  style?: 'button' | 'text';
};

export function SplashMessageLink({style = 'button', ...linkProps}: Props): JSX.Element {
  return <Link {...linkProps} className={styles[`${style}Link`]} />;
}
