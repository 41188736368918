import {matchMedia} from 'hooks/useMatchMedia';
import {useEffect, useState} from 'react';
import breakpoints from 'theme/gridBreakpointsMin.export.scss';
import {supportsPositionSticky} from 'utils/styles';

export function useStickyEnabled(enabledFrom?: string): boolean {
  const [enabled, setEnabled] = useState(false);

  // Don't calculate the enabled until a component is mounted, because this may break hydrate
  useEffect(() => {
    if (!enabledFrom || !supportsPositionSticky()) {
      return undefined;
    }

    const mediaQueryList = matchMedia(`(min-width: ${breakpoints[enabledFrom]})`);

    if (mediaQueryList) {
      const updateEnabled = (): void => setEnabled(Boolean(mediaQueryList.matches));
      mediaQueryList.addListener(updateEnabled);
      updateEnabled();

      return (): void => mediaQueryList.removeListener(updateEnabled);
    }

    return undefined;
  }, [enabledFrom]);

  return enabled;
}
