import {UserAgentContext} from 'providers/UserAgentContext';
import debounce from 'utils/debounce';
import {Image} from 'components/Image';
import PropTypes from 'prop-types';
import React from 'react';
import throttle from 'utils/throttle';
import {isMobileOrTabletDevice} from 'helpers/userAgent';
import {isPassiveSupported} from 'utils/dom';

let scrollTop = null;
let viewportHeight = null;

export default class ViewportListener extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    onChange: PropTypes.func.isRequired,
    listenImages: PropTypes.bool,
    passive: PropTypes.bool,
    scrollableElement: PropTypes.any,
  };

  static contextType = UserAgentContext;

  static defaultProps = {
    listenImages: false,
    passive: false,
    scrollableElement: undefined,
  };

  scrollableElement;

  constructor(props) {
    super(props);

    this.listenerOptions = null;
    this.handleResize = this.handleResize.bind(this);
    this.updateThrottled = throttle(this.update.bind(this), 50);
    this.updateThrottledForced = () => this.updateThrottled(true);
    this.updateDebounced = debounce(this.update.bind(this), 300);
    this.handleScroll = this.handleScroll.bind(this);
  }

  updateScrollListener() {
    const element = this.props.scrollableElement || window;
    if (this.scrollableElement === element) {
      return;
    }
    if (this.scrollableElement) {
      this.scrollableElement.removeEventListener('scroll', this.handleScroll, this.listenerOptions);
    }
    this.scrollableElement = element;
    this.scrollableElement.addEventListener('scroll', this.handleScroll, this.listenerOptions);
  }

  componentDidMount() {
    if (__CLIENT__) {
      this.listenerOptions = this.getEventLisenterOptions();
      this.updateScrollListener();
      window.addEventListener('resize', this.handleResize, this.listenerOptions);
      if (this.props.listenImages) {
        Image.listen(this.updateThrottledForced);
      }
      this.update(true);
    }
  }

  componentDidUpdate() {
    if (__CLIENT__) {
      this.update(true);
      this.updateScrollListener();
    }
  }

  componentWillUnmount() {
    if (__CLIENT__) {
      this.scrollableElement.removeEventListener('scroll', this.handleScroll, this.listenerOptions);
      window.removeEventListener('resize', this.handleResize, this.listenerOptions);
      Image.unlisten(this.updateThrottledForced);
    }
  }

  getEventLisenterOptions() {
    if (this.props.passive && isPassiveSupported()) {
      return {passive: true};
    }
    return false;
  }

  handleResize() {
    viewportHeight = null;
    this.updateThrottled(true);
    if (isMobileOrTabletDevice(this.context)) {
      // ios has browser hideable panel, but mobile safari fires resize
      // BEFORE relayout, so we have to update position after time.
      this.updateDebounced(true);
    }
  }

  handleScroll() {
    this.update();
  }

  update(force) {
    if (__CLIENT__ && isMobileOrTabletDevice(this.context)) {
      // ios has browser hideable panel, but mobile safari fires resize
      // BEFORE relayout, so we have to update position after time.
      viewportHeight = null;
    }
    scrollTop = null;
    this.props.onChange(force);
  }

  render() {
    const {children} = this.props;

    return <React.Fragment>{children}</React.Fragment>;
  }
}

ViewportListener.getScrollTop = function getScrollTop() {
  if (scrollTop === null) {
    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  }
  return scrollTop;
};

ViewportListener.getViewportHeight = function getViewportHeight() {
  if (viewportHeight === null) {
    viewportHeight = document.documentElement.clientHeight || window.innerHeight || 0;
  }
  return viewportHeight;
};
