import classnames from 'classnames/bind';
import {createLocator, Locator, Mark} from 'create-locator';
import React, {FC, ReactNode} from 'react';
import {FormattedMessage} from 'react-intl';

import styles from './index.scss';

const cn = classnames.bind(styles);

export type RestrictedLocator = Locator<void>;

type Props = {
  compact?: boolean;
  children?: ReactNode;
} & Mark<RestrictedLocator>;

const defaultChildren = (
  <FormattedMessage description="Product for adults" defaultMessage="Adult product" />
);

export const Restricted: FC<Props> = (props) => {
  const {compact, children = defaultChildren} = props;
  const locator = createLocator(props);
  return (
    <div className={cn('restricted', {compact})} {...locator()}>
      <div className={styles.description}>{children}</div>
    </div>
  );
};
