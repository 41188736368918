import classnames from 'classnames/bind';
import {CopyContainer} from 'components/CopyContainer';
import HtmlContent from 'components/HtmlContent';
import CopyIcon from 'components/icons/copy.jsx.svg';
import {Price} from 'components/Price';
import {ServerDrivenLayout} from 'components/ServerDrivenLayout';
import {CouponButton} from 'connectors/CouponButton';
import {isDeepLink} from 'helpers/deepLink';
import {useSendAnalytics} from 'hooks/useAnalytics';
import {useEffectOnce} from 'hooks/useEffectOnce';
import {useScope} from 'hooks/useScope';
import {useUniversalNavigate} from 'hooks/useUniversalNavigate';
import React, {useCallback} from 'react';
import {ContentItem, PopupResponse, ShareParams} from 'types/Popup';
import {isString} from 'utils/guards';
import {getColorStyles} from 'utils/styles/color';

import {AppLinkButton} from './AppLinkButton/AppLinkButton';
import {GradientHeader} from './GradientHeader';
import {IconList} from './IconList';
import styles from './index.scss';
import {ItemButton} from './ItemButton';
import {Row} from './Row';
import {SecondaryText} from './SecondaryText';
import {ShareButton} from './ShareButton';
import {Subtitle} from './Subtitle';
import {Title} from './Title';
import {attributeMapperWithAdditionalParameters} from './utils';

const cn = classnames.bind(styles);

const CUSTOM_DEEP_LINK_REGEX = /^(joom:\/\/share\?params)/;

const isCustomDeepLink = (url = ''): boolean => CUSTOM_DEEP_LINK_REGEX.test(url);

type Props = {
  item: ContentItem;
  loadShareData(sourceUrlOrShareParama?: string | ShareParams): void;
  onClose(): void;
  onShareClick(url?: string): void;
  popup: PopupResponse;
};

export const Item: React.FC<Props> = ({
  item,
  loadShareData,
  onClose,
  onShareClick,
  popup,
}: Props) => {
  const scope = useScope();
  const navigate = useUniversalNavigate();
  const sendAnalytics = useSendAnalytics();

  const handleCopySuccess = useCallback(
    () => sendAnalytics('popupPromoCodeCopied', {popupId: popup.id}),
    [popup.id, sendAnalytics],
  );

  const handleButtonClick = useCallback(() => {
    sendAnalytics(
      'popupButtonClick',
      {popupId: popup.id},
      {params: [popup.eventParams, item.button?.eventParams].filter(isString)},
    );
    onClose();
  }, [item, onClose, popup, sendAnalytics]);

  const triggerClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const shareButton =
    item.shareButton || (isCustomDeepLink(item.button?.deeplink) && item.button) || undefined;

  useEffectOnce(() => {
    if (shareButton) {
      loadShareData(shareButton.deeplink);
    }
  });

  const handleShareButtonClick = useCallback(() => {
    sendAnalytics(
      'popupButtonClick',
      {popupId: popup.id},
      {params: [popup.eventParams, shareButton?.eventParams].filter(isString)},
    );
    if (shareButton) {
      onShareClick(shareButton.deeplink);
    }
  }, [onShareClick, popup, sendAnalytics, shareButton]);

  const handleAppLinkButtonClick = useCallback(() => {
    sendAnalytics('popupButtonClick', {popupId: popup.id});
  }, [popup.id, sendAnalytics]);

  const handleHtmlLinkClick = useCallback(
    (event: React.MouseEvent) => {
      const target = event.target as HTMLAnchorElement;

      sendAnalytics(
        'popupLinkClick',
        {popupId: popup.id},
        {params: [popup.eventParams].filter(isString)},
      );

      if (isDeepLink(target?.href)) {
        event.preventDefault();
        navigate(target?.href);
      }
    },
    [navigate, popup.eventParams, popup.id, sendAnalytics],
  );

  let priceContent = null;

  if (item.prices) {
    if (item.prices.price) {
      priceContent = (
        <span className={styles.price}>
          <Price noText money={item.prices.price} />
        </span>
      );
    } else if (item.prices.msrPrice) {
      priceContent = (
        <span className={styles.price}>
          <Price noText money={{amount: 0, currency: item.prices.msrPrice.currency}} />
        </span>
      );
    }
  }

  return (
    <>
      {item.gradientHeader ? <GradientHeader gradientHeader={item.gradientHeader} /> : null}

      {item.title ? <Title title={item.title} /> : null}

      {item.subtitle ? (
        <Subtitle onLinkClick={handleHtmlLinkClick} subtitle={item.subtitle} />
      ) : null}

      {item.layout ? <ServerDrivenLayout layout={item.layout} /> : null}

      {item.prices ? (
        <div className={styles.prices}>
          {priceContent}{' '}
          {item.prices.msrPrice ? (
            <span className={styles.msrPrice}>
              <Price money={item.prices.msrPrice} />
            </span>
          ) : null}
        </div>
      ) : null}

      {item.promocode ? (
        <div className={styles.promocode}>
          <span className={styles.promocodeValue} style={getColorStyles(item.promocode.textColor)}>
            {item.promocode.value}
          </span>
          <span className={styles.promocodeCopyButton}>
            <CopyContainer value={item.promocode.value} onSuccess={handleCopySuccess}>
              <CopyIcon
                style={getColorStyles(item.promocode.copyButtonColor)}
                width="24"
                height="24"
              />
            </CopyContainer>
          </span>
        </div>
      ) : null}

      {item.instructionList ? (
        <div
          className={cn('list', {
            [styles.numbered!]: item.instructionList.numbered,
          })}
        >
          {item.instructionList.items.map((listItem) => (
            <div className={styles.listItem} key={listItem.text}>
              <div className={styles.listItemCounter} />
              <HtmlContent
                html={listItem.text}
                style={getColorStyles(listItem.textColor)}
                attributeMapper={attributeMapperWithAdditionalParameters(scope, {
                  a: {style: getColorStyles(listItem.textColor)},
                })}
                onClick={handleHtmlLinkClick}
              />
            </div>
          ))}
        </div>
      ) : null}

      {item.row ? <Row row={item.row} onNavigate={triggerClose} /> : null}

      {item.secondaryText ? <SecondaryText secondaryText={item.secondaryText} /> : null}

      {item.iconList ? <IconList iconList={item.iconList} /> : null}

      {shareButton && <ShareButton button={shareButton} onClick={handleShareButtonClick} />}

      {!shareButton && item.button && (
        <ItemButton button={item.button} onClick={handleButtonClick} />
      )}

      {item.couponButton ? (
        <CouponButton
          popupId={popup.id}
          couponButton={item.couponButton}
          onChangeState={triggerClose}
        />
      ) : null}
      {item.appLinkButton ? (
        <AppLinkButton appLinkButton={item.appLinkButton} onClick={handleAppLinkButtonClick} />
      ) : null}
    </>
  );
};
