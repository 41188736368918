import cn from 'classnames';
import ErrorMessage from 'components/ErrorMessage';
import {MediaUploader} from 'components/MediaUploader';
import {ErrorMessage as MediaUploaderErrorMessage} from 'components/MediaUploader/ErrorMessage';
import {MediaBundle, UploaderError} from 'components/MediaUploader/types';
import {isMediaImageBundle} from 'components/MediaUploader/utils/isMediaImageBundle';
import {Button} from 'components/UIKit/Button';
import {Input} from 'components/UIKit/Form/Input';
import {Textarea} from 'components/UIKit/Form/Textarea';
import {Icon} from 'components/UIKit/Icon';
import {Popup} from 'components/UIKit/Popup';
import {Content} from 'components/UIKit/Popup/Content';
import {Header} from 'components/UIKit/Popup/Header';
import {PopupType, PopupViewProps, usePopup} from 'components/UIKit/Popup/PopupProvider';
import {useDispatch} from 'hooks/redux';
import {useSendAnalytics, useSendAnalyticsOpenClose} from 'hooks/useAnalytics';
import {useDeviceVar} from 'hooks/useDeviceVars';
import React, {
  ChangeEvent,
  ReactElement,
  SyntheticEvent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';
import {createProductCollection, updateProductCollection} from 'store/modules/productCollections';
import {ImageBundle} from 'types/Image';
import {ProductCollection} from 'types/ProductCollection';
import {ProductCollectionSettings} from 'types/ProductCollection/ProductCollectionUserSettings';

import styles from './index.scss';

const messages = defineMessages({
  createTitle: {
    defaultMessage: 'New collection',
    description: '[title] заголовок попапа создания подборки',
  },
  editTitle: {
    defaultMessage: 'Edit collection',
    description: '[title] заголовок попапа редактирования подборки',
  },
  privateRadioText: {
    defaultMessage: 'Private',
    description: 'текст контрола для выбора личной подборки',
  },
  publicRadioText: {
    defaultMessage: 'Public',
    description: 'текст контрола для выбора общедоступной подборки',
  },
  uploadImageFieldLabel: {
    defaultMessage: 'Upload photo',
    description: 'лейбл для поля загрузки изображения подборки',
  },
  titleFieldLabel: {
    defaultMessage: 'Collection name*',
    description: 'лейбл для обязательного текстового поля названия подборки',
  },
  subtitleFieldLabel: {
    defaultMessage: 'Description',
    description: 'лейбл для текстового поля описания подборки',
  },
  createSaveButton: {
    defaultMessage: 'Create a collection',
    description: '[button] текст кнопки создания подборки',
  },
  editSaveButton: {
    defaultMessage: 'Save',
    description: '[button] текст кнопки сохранения подборки',
  },
  editPublicWarning: {
    defaultMessage:
      'If you switch the collection type to "Private", your public link will become invalid.',
    description: 'текст в попапе редактирования подборки',
  },
});

const MEDIA_UPLOADER_FILTERS = [
  {
    supportedMimeTypes: ['image/png', 'image/jpeg', 'image/heic'],
    maxBytes: 1024 * 1024 * 10 /* 10 Mb */,
  },
];

export type EditProductCollectionSaveHandler = (data: {
  settings: ProductCollectionSettings;
  productCollection: ProductCollection;
}) => void | Promise<void>;

type FormProps = {
  currentSettings?: ProductCollectionSettings;
  productCollectionId?: string;
  onSave?: EditProductCollectionSaveHandler;
};

export function EditProductCollectionPopup({
  productCollectionId,
  currentSettings,
  onSave,
  onClose,
  onBack,
}: FormProps & Pick<PopupViewProps, 'onClose' | 'onBack'>): ReactElement {
  const isEditing = !!(currentSettings && productCollectionId);
  const productCollectionEditorMode = isEditing ? 'edit' : 'create';

  const sendEvent = useSendAnalytics();
  const dispatch = useDispatch();

  const {
    createPublicCollectionByDefault = false,
    titleMaxLength = 200,
    descriptionMaxLength = 2000,
  } = useDeviceVar('productCollectionsConfig') || {};

  const [settings, setSettings] = useState<ProductCollectionSettings>(() =>
    isEditing
      ? currentSettings
      : {title: '', subtitle: '', isPublic: createPublicCollectionByDefault},
  );

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [uploaderError, setUploaderError] = useState<UploaderError>();
  const [submitError, setSubmitError] = useState<unknown>();

  const initialImages = useMemo(
    () => (currentSettings?.cover ? [{image: currentSettings.cover}] : undefined),
    [currentSettings],
  );

  const handlePublicChange = useCallback(() => {
    const isPublic = !settings.isPublic;
    setSettings((settings) => ({...settings, isPublic}));
    sendEvent('productCollectionEditorTypeClick', {
      productCollectionId,
      productCollectionEditorMode,
      productCollectionType: isPublic ? 'public' : 'private',
    });
  }, [productCollectionEditorMode, productCollectionId, sendEvent, settings.isPublic]);

  const handleTitleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSettings((settings) => ({...settings, title: event.target.value}));
  }, []);

  const handleSubtitleChange = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
    setSettings((settings) => ({...settings, subtitle: event.target.value}));
  }, []);

  const handleMediaChange = useCallback((mediaBundles: MediaBundle[]) => {
    const [firstMediaBundle] = mediaBundles;
    const cover =
      firstMediaBundle && isMediaImageBundle(firstMediaBundle)
        ? firstMediaBundle.image
        : ({} as ImageBundle);

    setSettings((settings) => ({...settings, cover}));
    setUploaderError(undefined);
  }, []);

  const handleSubmit = useCallback(
    async (event: SyntheticEvent) => {
      event.preventDefault();

      setSubmitError(undefined);

      if (uploaderError) {
        return;
      }

      if (!settings.title) {
        return;
      }

      setIsSubmitting(true);

      try {
        const resultSettings: ProductCollectionSettings = settings.isPublic
          ? settings
          : {
              isPublic: false,
              title: settings.title,
              subtitle: '',
              cover: {} as ImageBundle,
            };

        let response;

        if (currentSettings && productCollectionId) {
          response = await dispatch(
            updateProductCollection({
              productCollectionId,
              settings: resultSettings,
            }),
          );
        } else {
          response = await dispatch(
            createProductCollection({
              settings: resultSettings,
            }),
          );
        }

        await onSave?.({
          settings: resultSettings,
          productCollection: response.productCollection,
        });

        if (onBack) onBack();
        else onClose();

        sendEvent('productCollectionEditorSave', {
          productCollectionId,
          productCollectionEditorMode,
          productCollectionType: resultSettings.isPublic ? 'public' : 'private',
          hasName: Boolean(resultSettings.title),
          hasDescription: Boolean(resultSettings.subtitle),
          hasImage: Boolean(resultSettings.cover?.images),
        });
      } catch (error) {
        setSubmitError(error);
      } finally {
        setIsSubmitting(false);
      }
    },
    [
      uploaderError,
      settings,
      currentSettings,
      productCollectionId,
      onSave,
      onBack,
      onClose,
      sendEvent,
      productCollectionEditorMode,
      dispatch,
    ],
  );

  useSendAnalyticsOpenClose(
    () => ({
      type: 'productCollectionEditorOpen',
      payload: {
        productCollectionId,
        productCollectionEditorMode,
      },
    }),
    ({sinceOpenMs}) => ({
      type: 'productCollectionEditorClose',
      payload: {
        productCollectionId,
        productCollectionEditorMode,
        sinceOpenMs,
      },
    }),
  );

  return (
    <Popup width="500px">
      <Header onClose={onClose} onBack={onBack}>
        <FormattedMessage {...(isEditing ? messages.editTitle : messages.createTitle)} />
      </Header>
      <Content>
        <form className={styles.root} onSubmit={handleSubmit}>
          <fieldset className={styles.publicFieldset}>
            <label className={cn(styles.publicItem, !settings.isPublic && styles.active)}>
              <input
                className={styles.publicRadio}
                type="radio"
                name="public"
                checked={!settings.isPublic}
                onChange={handlePublicChange}
                disabled={isSubmitting}
              />
              <FormattedMessage {...messages.privateRadioText} />
            </label>
            <label className={cn(styles.publicItem, settings.isPublic && styles.active)}>
              <input
                className={styles.publicRadio}
                type="radio"
                name="public"
                checked={settings.isPublic}
                onChange={handlePublicChange}
                disabled={isSubmitting}
              />
              <FormattedMessage {...messages.publicRadioText} />
            </label>
          </fieldset>
          <div className={cn(styles.field, !settings.isPublic && styles.hidden)}>
            <MediaUploader
              disabled={isSubmitting}
              filters={MEDIA_UPLOADER_FILTERS}
              maxCount={1}
              onChange={handleMediaChange}
              onError={setUploaderError}
              showLabel
              size="small"
              value={initialImages}
            />
          </div>
          <div className={styles.field}>
            <Input
              name="title"
              label={<FormattedMessage {...messages.titleFieldLabel} />}
              maxlength={titleMaxLength}
              value={settings.title || ''}
              onChange={handleTitleChange}
              disabled={isSubmitting}
            />
          </div>
          <div className={cn(styles.field, !settings.isPublic && styles.hidden)}>
            <Textarea
              name="subtitle"
              label={<FormattedMessage {...messages.subtitleFieldLabel} />}
              maxlength={descriptionMaxLength}
              value={settings.subtitle || ''}
              onChange={handleSubtitleChange}
              disabled={isSubmitting}
              controlled
            />
          </div>
          {isEditing && currentSettings?.isPublic && !settings.isPublic && (
            <div className={styles.editPublicWarning}>
              <div className={styles.editPublicWarningIcon}>
                <Icon name="warning-filled-24" type="mono" />
              </div>
              <FormattedMessage {...messages.editPublicWarning} />
            </div>
          )}
          {Boolean(uploaderError || submitError) && (
            <div className={styles.error}>
              {uploaderError && <MediaUploaderErrorMessage error={uploaderError} />}
              {!uploaderError && <ErrorMessage error={submitError} noRequestId noTitle />}
            </div>
          )}
          <div className={styles.submitButton}>
            <Button
              tag="button"
              type="submit"
              color="primary"
              size="large"
              disabled={!settings.title.trim() || isSubmitting}
            >
              <FormattedMessage
                {...(isEditing ? messages.editSaveButton : messages.createSaveButton)}
              />
            </Button>
          </div>
        </form>
      </Content>
    </Popup>
  );
}

export function useEditProductCollectionPopup({
  productCollectionId,
  currentSettings,
  onSave,
  onClose,
}: FormProps & {onClose?(): void} = {}): PopupType {
  const render = useCallback(
    ({onClose, onBack}: PopupViewProps) => (
      <EditProductCollectionPopup
        {...{
          onClose,
          onBack,
          productCollectionId,
          currentSettings,
          onSave,
        }}
      />
    ),
    [onSave, currentSettings, productCollectionId],
  );

  return usePopup({render, onClose});
}
