import {createRequestAction} from 'store/utils/requestActions';
import {Fields, ValidationMessages} from 'types/AddressMetainfo';
import {PersonalDataFormOrigin} from 'types/PersonalData';

export type FetchFieldValueRequest = {
  fieldName: string;
  fields: Fields;
  origin: PersonalDataFormOrigin;
};
export type FetchFieldValueResponse =
  | {messages: ValidationMessages}
  | {value: number}
  | {deeplink: string}
  | {
      performRequest: {
        request: {
          body?: Record<string, unknown>;
          headers?: Record<string, string>;
          method: string;
          url: string;
        };
        state: unknown;
      };
    };

export const fetchFieldValue = createRequestAction(
  '@personalDataForm/FETCH_FIELD_VALUE_REQUEST',
  '@personalDataForm/FETCH_FIELD_VALUE_RESPONSE',
  '@personalDataForm/FETCH_FIELD_VALUE_FAILURE',
)<FetchFieldValueRequest, FetchFieldValueResponse>();

export type DeletePersonalDataRequest = {
  origin: PersonalDataFormOrigin;
};
export type DeletePersonalDataResponse = void;

export const deletePersonalData = createRequestAction(
  '@personalData/DELETE_REQUEST',
  '@personalData/DELETE_RESPONSE',
  '@personalData/DELETE_FAILURE',
)<DeletePersonalDataRequest, DeletePersonalDataResponse>();
