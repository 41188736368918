import cn from 'classnames';
import {ContextMenu} from 'components/ContextMenu';
import {Icon} from 'components/UIKit/Icon';
import React, {ReactElement, ReactNode, useCallback, useState} from 'react';

import styles from './DotsMenuButton.scss';

export function DotsMenuButton({
  children,
  color = 'gray',
  open,
  setOpen,
}: {
  children: ReactNode;
  color?: 'gray';
  open?: boolean;
  setOpen?: (open: boolean) => void;
}): ReactElement | null {
  const [showContextMenu, setShowContextMenu] = useState(false);

  const openContextMenu = useCallback(
    () => (typeof setOpen === 'function' ? setOpen(true) : setShowContextMenu(true)),
    [setOpen],
  );
  const closeContextMenu = useCallback(
    () => (typeof setOpen === 'function' ? setOpen(false) : setShowContextMenu(false)),
    [setOpen],
  );

  const childrenCount = React.Children.toArray(children).filter(Boolean).length;

  if (childrenCount === 0) {
    return null;
  }

  const isOpen = typeof open !== 'undefined' ? Boolean(open) : showContextMenu;

  return (
    <span className={styles.root}>
      <button
        type="button"
        className={cn(styles.button, color && styles[color])}
        onClick={openContextMenu}
      >
        <Icon type="mono" name="more-vertical-filled-24" width="24px" height="24px" />
      </button>
      {isOpen && (
        <ContextMenu onClose={closeContextMenu} widthByContent>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <div onClick={closeContextMenu}>{children}</div>
        </ContextMenu>
      )}
    </span>
  );
}
