import {useSelector} from 'hooks/redux';
import {SigninContext} from 'providers/SigninContext';
import {useCallback, useContext} from 'react';
import {getUser} from 'store/modules/auth/getUser';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useSignin() {
  return useContext(SigninContext);
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useSigninPromise() {
  const signin = useSignin();
  return useCallback(
    (where: string, signinView?: string | null, signinContext?: {source: string} | null) =>
      new Promise<void>((resolve, reject) =>
        signin.start(where, signinView, signinContext, resolve, reject),
      ),
    [signin],
  );
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useSigninVerifyAction(
  where: string,
  signinView?: string | null,
  signinContext?: {source: string} | null,
) {
  const signinPromise = useSigninPromise();
  const user = useSelector(getUser);

  return useCallback(
    async (action: () => Promise<unknown> | unknown): Promise<void> => {
      if (!user || user.anonymous) {
        try {
          await signinPromise(where, signinView, signinContext);
          await action();
        } catch {
          // do nothing
        }
      } else {
        await action();
      }
    },
    [signinContext, signinPromise, signinView, user, where],
  );
}
