import {
  type CountryCode,
  formatIncompletePhoneNumber,
  formatNumber,
  isValidPhoneNumber,
} from 'libphonenumber-js';
import {isEmpty, validationErrors, Validator} from 'utils/validation';

import {countryList} from './countries';

const NON_DIGIT_REGEX = /[^0-9]/g;

const indexPhoneCodeByCountryId: Partial<Record<string, number>> = {};
const indexCountryIdByPhoneCode: Partial<Record<string, string>> = {};

countryList.forEach((country) => {
  indexPhoneCodeByCountryId[country.id] = country.phoneCode;
  indexCountryIdByPhoneCode[country.phoneCode] = country.id;
});

export function normalizePhone(str = ''): string {
  const value = str.replace(NON_DIGIT_REGEX, '');
  return value ? `+${value}` : value;
}

export function removePlusSign(phone = ''): string {
  return phone.startsWith('+') ? phone.slice(1) : phone;
}

export function isValidPhone(str: string): boolean {
  return isValidPhoneNumber(str);
}

export function getPhoneCountryCode(phone: string): string {
  let code = phone.startsWith('+') ? phone.slice(1, 4) : phone.slice(0, 3);
  while (code) {
    if (code in indexCountryIdByPhoneCode) {
      return code;
    }
    code = code.slice(0, code.length - 1);
  }
  return '';
}

export function getPhoneCodeByCountryId(countryId: string): string {
  return String(indexPhoneCodeByCountryId[countryId] || '');
}

export function removeInputPhoneCode(str: string, code: string): string {
  return str.slice(str.startsWith('+') ? code.length + 1 : code.length).trim();
}

export function formatPhoneWithoutCode(code: string, number: string): string {
  return formatIncompletePhoneNumber(number, indexCountryIdByPhoneCode[code] as CountryCode);
}

export function formatPhone(str: string): string {
  const phone = normalizePhone(str);
  return phone ? formatNumber(phone, 'INTERNATIONAL') || formatIncompletePhoneNumber(phone) : '';
}

export function phoneValidator(value: string): ReturnType<Validator<string>> {
  if (!isEmpty(value)) {
    if (!isValidPhone(value)) {
      return validationErrors.phone;
    }
  }
  return undefined;
}

export function phoneCountryCodeValidator(value: string): ReturnType<Validator<string>> {
  if (!isEmpty(value)) {
    const phoneNumber = normalizePhone(value);
    const code = getPhoneCountryCode(phoneNumber);
    if (!code) {
      if (phoneNumber[1] === '8') {
        return validationErrors.phoneRussia;
      }
      return validationErrors.phoneCountry;
    }
  }
  return undefined;
}
