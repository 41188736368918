import classnames from 'classnames/bind';
import {Image} from 'components/Image';
import {Locator} from 'components/Locator';
import {OrderAttributes} from 'components/ParcelElements/OrderAttributes';
import {OrderDates} from 'components/ParcelElements/OrderDates';
import {OrderId} from 'components/ParcelElements/OrderId';
import {OrderPrice} from 'components/ParcelElements/OrderPrice';
import {VatBadge} from 'components/VatBadge';
import {useUniversalNavigate} from 'hooks/useUniversalNavigate';
import PropTypes from 'prop-types';
import React, {memo, useCallback} from 'react';
import {Link} from 'react-router-dom';
import OrderLite from 'shapes/OrderLite';
import {RefShape} from 'shapes/Ref';
import {getValueByPath} from 'utils/object';
import {convertBackendColorToCSSValue} from 'utils/styles/color';

import {rootLocator} from 'utils/rootLocator';
import {createLocator} from 'create-locator';
import {Icon} from 'components/UIKit/Icon';
import styles from './orderLayout.scss';

const cn = classnames.bind(styles);

const pricesLocator = createLocator(rootLocator.parcelsPage.parcel.prices());

export const OrderLayout = memo(function OrderLayout({
  order,
  bottomBanners,
  vatInfo,
  quantity,
  rightButton,
  onImgClick,
  onIdClick,
  imgTo,
  idTo,
  imgRef,
  idRef,
  showSpecialPriceIcon = false,
}) {
  const {mainImage, createdTimeMs, friendlyId, currency, total, size, colors} = order;
  const warrantyDescription = getValueByPath(order, 'warranty', 'description');

  const productImage = (
    <Locator id="order-image">
      <Image contain height="100%" width="100%" image={mainImage} sizes="100px" />
    </Locator>
  );

  const navigate = useUniversalNavigate();
  const getNavigateCallback = useCallback((to) => () => navigate(to), [navigate]);

  return (
    <Locator id="Order" orderid={order.id}>
      <div className={styles.orderLayout}>
        <div className={styles.left}>
          <Locator id="order-link">
            <Link ref={imgRef} to={imgTo} className={styles.image} onClick={onImgClick}>
              {productImage}
            </Link>
          </Locator>
          <div>
            <div ref={idRef}>
              <Locator id="order-friendlyId">
                <OrderId onClick={onIdClick} id={friendlyId} to={idTo} />
              </Locator>
            </div>
            <Locator id="order-attributes">
              <OrderAttributes colors={colors} size={size} quantity={quantity} />
            </Locator>
            <div className={styles.mobilePrice} {...pricesLocator()}>
              <span className={styles.priceWrap}>
                {showSpecialPriceIcon ? (
                  <span className={styles.specialPriceIcon}>
                    <Icon type="mono" name="lightning-filled-16" width="16px" height="16px" />
                  </span>
                ) : null}
                <OrderPrice
                  value={total.price.amount + total.shippingPrice.amount}
                  currency={currency}
                  pointAmountMoney={total.pointAmountMoney}
                  {...pricesLocator.price()}
                />
              </span>
              {vatInfo && (
                <div className={styles.vatBadge}>
                  <VatBadge vatInfo={vatInfo} />
                </div>
              )}
            </div>
            {bottomBanners ? (
              <div className={styles.bottomBanners}>
                {bottomBanners.map((banner, index) => (
                  <button
                    type="button"
                    className={styles.bottomBannerWrapper}
                    onClick={banner.deeplink ? getNavigateCallback(banner.deeplink) : undefined}
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${banner.eventParams}-${index}`}
                  >
                    <div className={styles.bottomBanner}>
                      <Image
                        multiply
                        pxFit={40}
                        image={banner.icon}
                        className={styles.bottomBannerIcon}
                      />
                      <p
                        style={{color: convertBackendColorToCSSValue(banner.text.color)}}
                        className={styles.bottomBannerText}
                      >
                        {banner.text.text}
                      </p>
                    </div>
                  </button>
                ))}
              </div>
            ) : null}
          </div>
        </div>
        <div className={styles.center}>
          <OrderDates createdTimeMs={createdTimeMs} warrantyDescription={warrantyDescription} />
        </div>
        <div className={cn('right', {withButton: Boolean(rightButton)})} {...pricesLocator()}>
          <div className={styles.desktopPrice}>
            <span className={styles.priceWrap}>
              {showSpecialPriceIcon ? (
                <span className={styles.specialPriceIcon}>
                  <Icon type="mono" name="lightning-filled-16" width="16px" height="16px" />
                </span>
              ) : null}
              <OrderPrice
                value={total.price.amount + total.shippingPrice.amount}
                currency={currency}
                pointAmountMoney={total.pointAmountMoney}
                {...pricesLocator.price()}
              />
            </span>

            {vatInfo && (
              <div className={styles.vatBadge}>
                <VatBadge vatInfo={vatInfo} />
              </div>
            )}
          </div>

          {rightButton}
        </div>
      </div>
    </Locator>
  );
});

OrderLayout.propTypes = {
  rightButton: PropTypes.node,
  order: OrderLite.isRequired,
  bottomBanners: PropTypes.array,
  quantity: PropTypes.number.isRequired,
  onImgClick: PropTypes.func,
  onIdClick: PropTypes.func,
  imgTo: PropTypes.string.isRequired,
  idTo: PropTypes.string,
  imgRef: RefShape,
  idRef: RefShape,
  vatInfo: PropTypes.shape({
    title: {
      text: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    },
  }),
  showSpecialPriceIcon: PropTypes.bool,
};

OrderLayout.defaultProps = {
  rightButton: null,
  onImgClick: null,
  onIdClick: null,
  bottomBanners: null,
  idTo: '',
  imgRef: null,
  idRef: null,
  vatInfo: null,
  showSpecialPriceIcon: false,
};
