import classNamesBind from 'classnames/bind';
import styles from 'components/ClientProduct/BrandHeader/index.scss';
import VerifiedIcon from 'components/ClientProduct/BrandHeader/Verified.jsx.svg';
import {Image} from 'components/Image';
import {Locator} from 'components/Locator';
import {searchUrlByParams} from 'containers/pages/SearchPage/url';
import {useAnalytics} from 'hooks/useAnalytics';
import {useLanguage} from 'hooks/useLanguage';
import {useScope} from 'hooks/useScope';
import React, {useCallback} from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';
import {Link} from 'react-router-dom';
import {ProductBrand} from 'types/ProductBrand';
import {isColorLight} from 'utils/colors';
import {convertBackendColorToCSSValue, getColorString} from 'utils/styles/color';
import {createUrl} from 'utils/url';

const cn = classNamesBind.bind(styles);

const messages = defineMessages({
  quality: {
    description: 'Текс "Гарантия качества" в блоке бренда на странице товара',
    defaultMessage: 'Quality guarantee',
  },
});

export type BrandHeaderProps = {
  brand: ProductBrand;
  productId?: string;
};

export const BrandHeader = React.memo(function BrandHeader({
  brand,
  productId,
}: BrandHeaderProps): React.ReactElement {
  const lang = useLanguage();
  const scope = useScope();
  const url = createUrl(searchUrlByParams(scope, lang, {parsed: true, brandIdFilter: brand.id}));

  let backgroundColor;
  let darkTheme;

  if (brand.mainColor) {
    backgroundColor = convertBackendColorToCSSValue(brand.mainColor);
    darkTheme = !isColorLight(getColorString(brand.mainColor) || 'ffffff');
  }

  // Analytics
  const analytics = useAnalytics();
  const sendClickEvent = useCallback(() => {
    analytics.sendEvent({
      type: 'brandClick',
      payload: {
        brandId: brand.id,
        productId,
      },
    });
  }, [analytics, brand.id, productId]);

  return (
    <Locator id="BrandHeaderOnProductPage">
      <Link
        className={cn('brandHeader', {darkTheme})}
        onClick={sendClickEvent}
        style={{backgroundColor}}
        to={url}
      >
        {brand.mainLogo && (
          <Locator id="BrandLogoOnProductPage">
            <Image
              className={styles.logo}
              cover
              image={brand.mainLogo}
              loadImmediately
              pxFit={40}
            />
          </Locator>
        )}
        <div className={styles.text}>
          <div className={styles.name}>{brand.name}</div>
          <div className={styles.subtitle}>{brand.subtitle}</div>
        </div>
        {brand.isVerified && (
          <div className={styles.verified}>
            <VerifiedIcon className={styles.verifiedIcon} />
            <div className={styles.verifiedText}>
              <FormattedMessage {...messages.quality} />
            </div>
          </div>
        )}
      </Link>
    </Locator>
  );
});
