import ClientApi from '@joomcode/joom-event-types';
import {Icon} from 'components/UIKit/Icon';
import {useAnalytics} from 'hooks/useAnalytics';
import {useCurrentUrlForAnalytics} from 'hooks/useCurrentUrlForAnalytics';
import {useEffectOnce} from 'hooks/useEffectOnce';
import React from 'react';
import {Error, isApiError, isPopupError, isRuntimeError} from 'types/Error';

type Props = {
  source: string;
  error?: Error;
};

export const ErrorIllustration = ({source, error}: Props): JSX.Element => {
  const analytics = useAnalytics();
  const url = useCurrentUrlForAnalytics();

  useEffectOnce(() => {
    const errorPayload: Pick<
      ClientApi.AnalyticsEventErrorImageShow['payload'],
      'errorCode' | 'errorType' | 'errorText'
    > = {};

    if (error) {
      if (isApiError(error)) {
        const {status, type, message} = error;
        errorPayload.errorCode = status;
        errorPayload.errorType = type;
        if (message) {
          errorPayload.errorText = message;
        }
      } else if (isPopupError(error)) {
        errorPayload.errorText = error.payload.popup.text || '';
        errorPayload.errorType = error.type;
      } else if (isRuntimeError(error)) {
        errorPayload.errorText = error.message || '';
        errorPayload.errorType = 'runtime';
      }
    }

    analytics.sendEvent({
      type: 'errorImageShow',
      payload: {
        url,
        source,
        ...errorPayload,
      },
    });
  });

  return <Icon type="illustration" name="3d-error" />;
};
