import {AppLink} from 'components/AppLink';
import {useScope} from 'hooks/useScope';
import React from 'react';
import {ruStoreUrl} from 'utils/url/app';

import {Badge} from '../Badge';
import styles from './index.scss';
import {useBadgeHandler} from './useBadgeHandler';

type Props = {
  deepUrl: string;
  eventCategory: string;
  small?: boolean;
};

export function RuStoreBadge({deepUrl, eventCategory, small = false}: Props): JSX.Element | null {
  const handleRuStore = useBadgeHandler(deepUrl, eventCategory, 'Ru Store Badge. Click');
  const scope = useScope();
  const appUrl = ruStoreUrl(scope);

  if (!appUrl) {
    return null;
  }

  return (
    <AppLink className={styles.link} href={appUrl} onClick={handleRuStore} role="button">
      <Badge className={styles.badge} small={small} type="rustore" />
    </AppLink>
  );
}
