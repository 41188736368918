import {Range as PriceRange} from 'components/Price/Range';
import React, {ReactNode} from 'react';
import {
  Filter,
  FilterValueCategories,
  FilterValueCheckbox,
  FilterValueColors,
  FilterValueMoneyRange,
  FilterValueProductTags,
  FilterValueProps,
  FilterValueSimple,
  FilterValueStores,
  FilterValueTree,
} from 'types/Search';
import {Expandable} from 'types/utility';
import {compareByItemKey} from 'utils/array';
import currencies from 'utils/currencies';
import {identity} from 'utils/function';
import {invertObject} from 'utils/object';
import {
  CategoriesSearchDataFilter,
  CheckboxSearchDataFilter,
  ColorsSearchDataFilter,
  MoneyRangeSearchDataFilter,
  ProductTagsSearchDataFilter,
  SearchDataFilter,
  SearchDataFilterType,
  SearchRequestFilterItem,
  SearchRequestFilterItemValue,
  SearchRequestFilterItemValueCategories,
  SearchRequestFilterItemValueCheckbox,
  SearchRequestFilterItemValueColors,
  SearchRequestFilterItemValueMoneyRange,
  SearchRequestFilterItemValueProductTags,
  SearchRequestFilterItemValueSimple,
  SearchRequestFilterItemValueStores,
  SearchRequestFilterItemValueTree,
  SimpleSearchDataFilter,
  StoresSearchDataFilter,
  TreeSearchDataFilter,
} from 'utils/search/types';

const Aliases = {
  clr: ['color', 'colors'],
  m: ['material', 'tree'],
  p: ['origPrice', 'moneyRange'],
  fsh: ['fastShipping', 'checkbox'],
} satisfies Record<string, [id: string, value: string]>;

export const IndependentAliases = {
  categoryId: 'c',
  productTags: 't',
};

const FilterIdToAlias = invertObject(Aliases, ([id]: [string, string]) => id);

type FilterValueConfig<SearchDataValue, AppliedValue, RequestValue> = {
  // filter value to url param value
  format: (data: SearchDataValue) => string;
  // param value to filter value
  parse: (value: string) => SearchDataValue | undefined | null;
  // filter value to api request value
  build: (data: SearchDataValue) => RequestValue | undefined | null;
  // api response value to filter value
  extract: (value: AppliedValue | RequestValue) => SearchDataValue | undefined | null;
  render: (data: AppliedValue) => ReactNode;
};

export class FilterValue<
  SearchFilter extends SearchDataFilter,
  AppliedValue extends FilterValueProps & {type: SearchFilter['valueType']},
  RequestValue extends SearchRequestFilterItemValue & {type: SearchFilter['valueType']},
> {
  // eslint-disable-next-line no-useless-constructor
  constructor(
    readonly type: SearchFilter['valueType'],
    private readonly config: FilterValueConfig<
      NonNullable<SearchFilter['value']>,
      AppliedValue,
      Omit<RequestValue, 'type'>
    >,
    // eslint-disable-next-line no-empty-function
  ) {}

  format(data: SearchFilter['value']): string {
    return (data && this.config.format(data)) || '';
  }

  parse(str: string): SearchFilter['value'] | null {
    return (str && this.config.parse(str)) || null;
  }

  extract(
    appliedFilter:
      | (Filter & {value: AppliedValue})
      | (SearchRequestFilterItem & {value: RequestValue})
      | undefined,
  ): SearchFilter['value'] | null {
    return (
      (appliedFilter && appliedFilter.value && this.config.extract(appliedFilter.value)) || null
    );
  }

  build(value: SearchFilter['value']): RequestValue | null {
    const builtValue = value ? this.config.build(value) : null;
    return builtValue
      ? ({
          type: this.type,
          ...builtValue,
        } as RequestValue)
      : null;
  }

  render(filterValue: AppliedValue): ReactNode {
    return (filterValue && this.config.render(filterValue)) || null;
  }
}

const MONEY_RANGE_REGEX = /^([A-Z]{3})~(\d+(?:\.\d+)?)?-(\d+(?:\.\d+)?)?$/;

function num(value: unknown) {
  return typeof value === 'number' ? value : '';
}

type DefaultAppliedValue = {items?: {id?: string; name?: string}[]};
type DefaultRequestValue = {items: {id: string}[]};

const defaultFilterConfig = {
  format: (data: string) => data,
  parse: (value: string) => value,
  build: (id: string): DefaultRequestValue => ({items: [{id}]}),
  extract: (data: DefaultAppliedValue) => data?.items?.[0]?.id,
  render: (data: DefaultAppliedValue) => data?.items?.[0]?.name,
};

export const FilterValueType = {
  moneyRange: new FilterValue<
    MoneyRangeSearchDataFilter,
    FilterValueMoneyRange,
    SearchRequestFilterItemValueMoneyRange
  >('moneyRange', {
    format: (data) => `${data.currency}~${num(data.min)}-${num(data.max)}`,
    parse: (str) => {
      const match = str?.match(MONEY_RANGE_REGEX);
      if (!match) {
        return null;
      }

      const [, currency, strMin, strMax] = match;
      const min = parseFloat(strMin!);
      const max = parseFloat(strMax!);
      if (!currency || !currencies.includes(currency as (typeof currencies)[number])) {
        return null;
      }

      const hasMin = !Number.isNaN(min);
      const hasMax = !Number.isNaN(max);
      if ((hasMin || hasMax) && (hasMin !== hasMax || min <= max)) {
        return {
          currency,
          ...(hasMin && {min}),
          ...(hasMax && {max}),
        };
      }

      return null;
    },
    build: identity,
    extract: identity,
    render: ({min, max, currency}) =>
      currency && (min || max) ? (
        <PriceRange min={min} max={max} format={currency} capitalize />
      ) : null,
  }),
  categories: new FilterValue<
    CategoriesSearchDataFilter,
    FilterValueCategories,
    SearchRequestFilterItemValueCategories
  >('categories', defaultFilterConfig),
  colors: new FilterValue<
    ColorsSearchDataFilter,
    FilterValueColors,
    SearchRequestFilterItemValueColors
  >('colors', {
    format: (data) => (Array.isArray(data) ? data.join(',') : data),
    parse: (data) => (typeof data === 'string' ? data.split(',') : null),
    build: (data) => {
      const items = [];

      if (Array.isArray(data)) {
        data.forEach((id) => items.push({id}));
      } else {
        items.push({id: data});
      }

      return {items};
    },
    extract: (data) => data?.items?.map(({id}) => id),
    render: ({items: [data] = []}) => data?.colors?.map(({name}) => name).join(' / '),
  }),
  productTags: new FilterValue<
    ProductTagsSearchDataFilter,
    FilterValueProductTags,
    SearchRequestFilterItemValueProductTags
  >('productTags', {
    ...defaultFilterConfig,
    build: (id) => ({tags: [{id}]}),
    extract: (data) => data?.tags?.[0]?.id,
    render: (data) => data?.tags?.[0]?.name,
  }),
  simple: new FilterValue<
    SimpleSearchDataFilter,
    FilterValueSimple,
    SearchRequestFilterItemValueSimple
  >('simple', defaultFilterConfig),
  stores: new FilterValue<
    StoresSearchDataFilter,
    FilterValueStores,
    SearchRequestFilterItemValueStores
  >('stores', defaultFilterConfig),
  tree: new FilterValue<TreeSearchDataFilter, FilterValueTree, SearchRequestFilterItemValueTree>(
    'tree',
    {
      ...defaultFilterConfig,
      format: (data) => (Array.isArray(data) ? data.join(',') : data),
      parse: (data) => (typeof data === 'string' ? data.split(',') : null),
      build: (data) => {
        const items = Array.isArray(data) ? data : [data];
        return {
          items: items.map((id) => ({id})),
        };
      },
      extract: (data) => {
        if (data && data.items) {
          return data.items.map(({id}) => id);
        }
        return [];
      },
    },
  ),
  checkbox: new FilterValue<
    CheckboxSearchDataFilter,
    FilterValueCheckbox,
    SearchRequestFilterItemValueCheckbox
  >('checkbox', {
    format: (data) => (data ? 'on' : 'off'),
    parse: (data) => {
      switch (data) {
        case 'on':
          return true;
        case 'off':
          return false;
        default:
          return null;
      }
    },
    build: (data) => {
      if (typeof data === 'boolean') {
        return {checked: data};
      }

      return null;
    },
    extract: (data) => data?.checked,
    render: () => null,
  }),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} satisfies {[Filter in SearchDataFilter as Filter['valueType']]: FilterValue<Filter, any, any>};

export function parseFilterValue<const Type extends SearchDataFilterType = SearchDataFilterType>(
  filterValueType: Type | string,
  str: string,
): ReturnType<(typeof FilterValueType)[Type]['parse']> {
  const valueType = (FilterValueType as Expandable<typeof FilterValueType>)[filterValueType];

  return (valueType ? valueType.parse(str) : null) as ReturnType<
    (typeof FilterValueType)[Type]['parse']
  >;
}

export function formatFilterValue<const Filter extends SearchDataFilter>(
  filterValueType: Filter['valueType'],
  data: Filter['value'],
): string | undefined | null {
  const valueType = FilterValueType[filterValueType];
  return valueType ? valueType.format(data as never) : '';
}

export function extractFilterValue(
  appliedFilter: Filter | SearchRequestFilterItem,
): ReturnType<(typeof FilterValueType)[SearchDataFilterType]['extract']> {
  if (!appliedFilter || !appliedFilter.value || !appliedFilter.value.type) {
    return null;
  }

  const valueType = (FilterValueType as Expandable<typeof FilterValueType>)[
    appliedFilter.value.type
  ];
  return valueType ? valueType.extract(appliedFilter as never) : null;
}

export function renderAppliedFilter(
  appliedFilter: Filter,
): ReturnType<(typeof FilterValueType)[SearchDataFilterType]['render']> {
  if (!appliedFilter || !appliedFilter.value) {
    return null;
  }

  const {value} = appliedFilter;
  const valueType = (FilterValueType as Expandable<typeof FilterValueType>)[value.type];
  return valueType ? valueType.render(value as never) : null;
}

export function buildFilterRequest<Filter extends SearchDataFilter>(
  id: string | undefined,
  filterValueType: Filter['valueType'],
  data: Filter['value'],
): SearchRequestFilterItem | null {
  if (!id) {
    return null;
  }

  const valueType = FilterValueType[filterValueType];
  const value = valueType && valueType.build(data as never);
  return value ? {id, value} : null;
}

export function parseFilterByParams(params: string | undefined): SearchDataFilter | null {
  if (!params) {
    return null;
  }

  const [paramId, ...paramArgs] = params.split('.') as [string, ...string[]];
  const alias = (Aliases as Expandable<typeof Aliases>)[paramId];
  const id = alias ? alias[0] : paramId;
  const valueType = alias ? alias[1] : paramArgs[0];
  const paramValue = (alias ? paramArgs : paramArgs.slice(1)).join('.');

  if (id && valueType && paramValue) {
    const value = parseFilterValue(valueType, paramValue);
    if (value) {
      return {id, valueType, value} as SearchDataFilter;
    }
  }

  return null;
}

export function buildFilterParams({id, valueType, value}: SearchDataFilter): string {
  const str = formatFilterValue(valueType, value);
  if (!str) {
    return '';
  }

  const independentAlias = (IndependentAliases as Expandable<typeof IndependentAliases>)[id];
  if (independentAlias) {
    return `${encodeURIComponent(independentAlias)}.${encodeURIComponent(str)}`;
  }

  const alias = FilterIdToAlias[id];
  if (alias) {
    return `f.${encodeURIComponent(alias)}.${encodeURIComponent(str)}`;
  }

  return `f.${encodeURIComponent(id)}.${encodeURIComponent(valueType)}.${encodeURIComponent(str)}`;
}

export function sortFilters(filters: SearchDataFilter[]): SearchDataFilter[] {
  if (!filters) {
    return filters;
  }

  return [...filters].sort((a, b) => {
    const indA = a.id in IndependentAliases;
    const indB = b.id in IndependentAliases;
    if (indA !== indB) {
      return indA ? -1 : 1;
    }
    return compareByItemKey(a, b, 'id');
  });
}

export function buildFiltersParams(filters: SearchDataFilter[] | undefined): string {
  if (!filters) {
    return '';
  }

  return sortFilters(filters).map(buildFilterParams).filter(identity).join('/');
}

/**
 * Функция возвращает список выбранных значений фильтра
 */
export function getFilterItems<F extends Filter<Extract<FilterValueProps, {items?: unknown[]}>>>(
  filter: F,
  defaultValue: F['value']['items'],
): F['value']['items'] {
  const items = filter?.value?.items;

  return Array.isArray(items) ? items : defaultValue;
}
