import classnames from 'classnames/bind';
import {Preloader} from 'components/Preloader';
import {
  discardSearchInputActions,
  getTypedValue,
  searchInputActions,
  SearchInputActionSource,
  updateSearchInputActions,
} from 'components/Suggest/analytics';
import {useAnalytics} from 'hooks/useAnalytics';
import PropTypes from 'prop-types';
import React, {Component, useId} from 'react';
import {defineMessages, FormattedMessage, useIntl} from 'react-intl';
import {connect} from 'react-redux';
import AnalyticsShape from 'shapes/Analytics';
import {SearchResultHelpShape} from 'shapes/Search';
import {
  deleteProductsRecent,
  getProductsRecent,
  getSearchSuggest,
  isProductsRecentLoading,
  isSearchSuggestLoading,
  loadProductsRecent,
  loadSearchSuggest,
} from 'store/modules/search';
import debounce from 'utils/debounce';
import {memoizeLastShallowEqual} from 'utils/memoize';
import {getValueByPath} from 'utils/object';
import {Locator} from 'components/Locator';
import {Icon} from 'components/UIKit/Icon';
import {rootLocator} from 'utils/rootLocator';
import {useSsrOrHydration} from 'hooks/useSsrOrHydration';
import {useMatchMediaBreakpointDown} from 'hooks/useMatchMedia';
import {RemoveScroll} from 'react-remove-scroll';
import styles from './index.scss';
import {SearchResultHelp} from './SearchResultHelp';
import {SearchSuggest} from './SearchSuggest';

const cn = classnames.bind(styles);

const locator = rootLocator.commonPage.search;

const MAX_RECENT_LENGTH = 10;
const DELAY = 300;

const messages = defineMessages({
  placeholder: {
    defaultMessage: 'What are you looking for?',
    description: 'Placeholder for search input',
  },
  clear: {
    description: '[label] Clear',
    defaultMessage: 'Clear search bar',
  },
});

const loadIfNeed = (value, isLoading, loader) =>
  value || isLoading ? Promise.resolve(null) : loader();

export default function Search(props) {
  const analytics = useAnalytics();
  const intl = useIntl();
  const suggestUid = useId();
  const isSsrOrHydration = useSsrOrHydration();
  const isMobileMediaQuery = useMatchMediaBreakpointDown('sm')?.matches;

  return (
    <ConnectedSearch
      {...props}
      analytics={analytics}
      intl={intl}
      suggestUid={suggestUid}
      isSsrOrHydration={isSsrOrHydration}
      isMobileMediaQuery={isMobileMediaQuery}
    />
  );
}

class SearchBase extends Component {
  static propTypes = {
    deleteProductsRecent: PropTypes.func.isRequired,
    defaultValue: PropTypes.string,
    focus: PropTypes.bool,
    productsRecent: PropTypes.any,
    suggest: PropTypes.object.isRequired,
    isProductsRecentLoading: PropTypes.bool.isRequired,
    loadProductsRecent: PropTypes.func.isRequired,
    loadSearchSuggest: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    value: PropTypes.string,
    isMobile: PropTypes.bool,
    analytics: AnalyticsShape.isRequired,
    intl: PropTypes.objectOf(PropTypes.any).isRequired,
    suggestUid: PropTypes.string.isRequired,
    searchResultHelp: SearchResultHelpShape,
    showSuggest: PropTypes.bool,
    onSuggestClose: PropTypes.func,
    isSsrOrHydration: PropTypes.bool.isRequired,
    isMobileMediaQuery: PropTypes.bool,
  };

  static defaultProps = {
    defaultValue: '',
    focus: false,
    value: '',
    isMobile: false,
    searchResultHelp: null,
    productsRecent: undefined,
    showSuggest: undefined,
    onSuggestClose: undefined,
    isMobileMediaQuery: undefined,
  };

  constructor(props) {
    super(props);

    this.input = null;
    this.focus = null;

    this.handleChange = this.handleChange.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSuggestClose = this.handleSuggestClose.bind(this);
    this.handleSuggestActive = this.handleSuggestActive.bind(this);
    this.handleSuggestSelect = this.handleSuggestSelect.bind(this);
    this.handleSuggestRecentSelect = this.handleSuggestRecentSelect.bind(this);
    this.loadSuggest = this.loadSuggest.bind(this);
    this.loadSuggestDebounced = debounce(() => (this.input ? this.loadSuggest() : null), DELAY);

    const value = props.value || props.defaultValue;

    this.openMs = undefined;
    this.lastFocusMs = undefined;
    this.initialQuery = value;

    this.state = {
      showSuggest: Boolean(props.showSuggest),
      value,
      waitForLoading: false,
      // WEB-2223: disable input for ssr rendering to prevent
      // unwanted input discarding after script loading
      inputDisabled: props.isSsrOrHydration,
      selectedSuggestItemId: null,
    };
  }

  componentDidMount() {
    this.openMs = Date.now();
    this.setState({inputDisabled: false});
    if (this.props.focus && this.input?.offsetWidth) {
      this.input.focus();
      this.input.select();
    }
  }

  componentDidUpdate(prevProps) {
    const {focus, value} = this.props;
    if (value !== prevProps.value) {
      this.setState({value});
    }
    if (focus && focus !== prevProps.focus && this.input?.offsetWidth) {
      this.input.focus();
      this.input.select();
    }
  }

  componentWillUnmount() {
    this.input = null;
  }

  isSearchSuggestLoading(prefix) {
    return isSearchSuggestLoading(this.props.suggest, prefix);
  }

  getSearchSuggest(prefix) {
    return getSearchSuggest(this.props.suggest, prefix);
  }

  isSuggestLoading() {
    if (!this.state.showSuggest) {
      return false;
    }

    if (this.state.value) {
      return this.isSearchSuggestLoading(this.state.value);
    }

    return this.props.isProductsRecentLoading;
  }

  async loadSuggest() {
    const {value} = this.state;

    await Promise.all([
      loadIfNeed(this.props.productsRecent, this.props.isProductsRecentLoading, () =>
        this.props.loadProductsRecent(),
      ),
      loadIfNeed(this.getSearchSuggest(value), this.isSearchSuggestLoading(value), () =>
        this.props.loadSearchSuggest(value),
      ),
    ]);

    this.setState({
      waitForLoading: false,
    });
  }

  handleSuggestSelect(item, actionSource = SearchInputActionSource.SUGGEST) {
    const {dataLayer} = this.props.analytics;
    const {query: searchQuery} = item;
    const categoryId = getValueByPath(item, 'filters', 0, 'value', 'items', 0, 'id');

    updateSearchInputActions({
      source: actionSource,
      text: searchQuery,
      sinceStartMs: Date.now() - this.openMs,
      sourceId: categoryId || searchQuery,
    });

    this.sendSearchQueryInputEvent(searchQuery);

    dataLayer({
      event: 'Search Form. Select Suggest',
      searchQuery,
    });

    this.handleSuggestClose();
    this.props.onSubmit(searchQuery, item.filters);
  }

  handleSuggestRecentSelect(item) {
    return this.handleSuggestSelect(item, SearchInputActionSource.RECENT);
  }

  handleClear() {
    const {analytics} = this.props;
    analytics.sendEvent({
      type: 'searchQueryInputCancel',
      payload: {
        searchInputActions: [...searchInputActions],
        durationMs: Date.now() - this.openMs,
      },
    });

    if (this.input) {
      this.input.value = '';
      this.input.focus();
      this.handleChange();
    }

    this.openMs = Date.now();
    discardSearchInputActions();
  }

  handleSuggestClose() {
    this.props.onSuggestClose?.();
    this.setState({showSuggest: false});
  }

  handleFocus() {
    this.lastFocusMs = Date.now();
    this.setState({showSuggest: true});
    this.loadSuggest();
  }

  handleBlur(event) {
    if (event.currentTarget.contains(event.relatedTarget)) {
      return;
    }

    this.handleSuggestClose();
  }

  handleChange() {
    if (this.input.value === this.state.value) {
      return;
    }

    const {value} = this.input;

    updateSearchInputActions({
      source: SearchInputActionSource.MANUAL,
      text: getTypedValue(value, this.initialQuery),
      sinceStartMs: Date.now() - this.openMs,
    });

    this.setState(
      {
        value,
        showSuggest: true,
        waitForLoading: true,
      },
      this.loadSuggestDebounced,
    );
  }

  sendSearchQueryInputEvent(submittedQuery) {
    const {
      state: {value: enteredQuery},
      initialQuery,
      lastFocusMs,
    } = this;
    const {analytics} = this.props;
    analytics.sendEvent({
      type: 'searchQueryInput',
      payload: {
        searchInputActions: [...searchInputActions],
        durationMs: Date.now() - lastFocusMs,
        submittedQuery,
        enteredQuery,
        initialQuery,
      },
    });

    discardSearchInputActions();
  }

  handleSubmit(evt) {
    evt.preventDefault();

    const {
      props: {
        analytics: {dataLayer},
      },
      state: {value: searchQuery},
    } = this;

    this.sendSearchQueryInputEvent(searchQuery);

    dataLayer({
      event: 'Search Form. Submit',
      searchQuery,
    });

    this.handleChange();
    this.input.blur();
    this.props.onSubmit(this.state.value, null);
    this.handleSuggestClose();
  }

  handleSuggestActive(item, id) {
    this.setState({
      selectedSuggestItemId: id,
    });
  }

  getEmptySuggestItems = memoizeLastShallowEqual((productsRecent) => {
    const recent = (productsRecent || []).slice(0, MAX_RECENT_LENGTH);

    if (!recent.length) {
      return null;
    }

    return recent;
  });

  getFilteredSuggestItems = memoizeLastShallowEqual((items) => {
    return items.filter((item) => {
      if (Object.keys(item).length === 1 && typeof item.query === 'string' && !item.query.length) {
        return false;
      }

      return true;
    });
  });

  renderEmptySuggest() {
    const {deleteProductsRecent: handleClear, isMobile, productsRecent, suggestUid} = this.props;
    const recentItems = this.getEmptySuggestItems(productsRecent);

    if (!recentItems) {
      return null;
    }

    return (
      <RemoveScroll enabled={isMobile} className={styles.suggest}>
        <Locator id="RecentSearchQueriesBlock">
          <SearchSuggest
            input={this.input}
            mobile={isMobile}
            onClear={handleClear}
            onClose={this.handleSuggestClose}
            onSelect={this.handleSuggestRecentSelect}
            recentItems={recentItems}
            uid={suggestUid}
          />
        </Locator>
      </RemoveScroll>
    );
  }

  renderSuggestWithItems() {
    const {isMobile, suggestUid} = this.props;
    const {value} = this.state;
    const suggest = this.getSearchSuggest(value);
    const showItems = Boolean(value && suggest && suggest.items && suggest.items.length > 0);
    const items = showItems ? this.getFilteredSuggestItems(suggest.items) : undefined;

    if (!items) {
      return null;
    }

    return (
      <RemoveScroll enabled={isMobile} className={styles.suggest}>
        <Locator id="SearchSuggestionsBlock">
          <SearchSuggest
            input={this.input}
            items={items}
            mobile={isMobile}
            onClose={this.handleSuggestClose}
            onSelect={this.handleSuggestSelect}
            uid={suggestUid}
          />
        </Locator>
      </RemoveScroll>
    );
  }

  renderSuggest() {
    const {showSuggest, waitForLoading, value} = this.state;

    if (!showSuggest || waitForLoading || this.isSuggestLoading()) {
      return null;
    }

    return value ? this.renderSuggestWithItems() : this.renderEmptySuggest();
  }

  renderInputControl() {
    const {value} = this.state;
    const {intl} = this.props;
    const loading = this.isSuggestLoading();

    if (!value && !loading) {
      return null;
    }

    return (
      <Locator id="SearchControl" isMobile={this.props.isMobile}>
        <button
          type="button"
          aria-label={intl.formatMessage(messages.clear)}
          onClick={this.handleClear}
          className={styles.sideBlockButton}
        >
          {loading ? (
            <span className={styles.preloader}>
              <Preloader size="inline" block />
            </span>
          ) : (
            <Icon height={24} name="close-linear-24" type="mono" width={24} />
          )}
        </button>
      </Locator>
    );
  }

  renderSearchResultHelp() {
    const {searchResultHelp} = this.props;
    return <SearchResultHelp searchResultHelp={searchResultHelp} />;
  }

  render() {
    const {isMobile, isMobileMediaQuery, isSsrOrHydration, defaultValue, suggestUid} = this.props;
    const {value, showSuggest, inputDisabled, selectedSuggestItemId} = this.state;
    const isActive = showSuggest;

    // hide search after ssr when not displayed to prevent callbacks
    if (
      !isSsrOrHydration &&
      isMobileMediaQuery != null &&
      Boolean(isMobile) !== isMobileMediaQuery
    ) {
      return null;
    }

    return (
      <Locator id="SearchForm">
        <div className={cn({isMobile, isActive})}>
          <form onSubmit={this.handleSubmit} className={styles.search} onBlur={this.handleBlur}>
            <div className={styles.container}>
              <button className={styles.backButton} onClick={this.handleSuggestClose} type="button">
                <Icon name="arrow-left-linear-24" type="mono" />
              </button>
              <div className={styles.bar} {...locator.searchBar({isMobile})}>
                <label className={styles.label}>
                  <div className={styles.magnifier}>
                    <Icon height={24} name="search-linear-24" type="mono" width={24} />
                  </div>
                  <Locator id="SearchInput" isMobile={isMobile}>
                    <input
                      className={styles.input}
                      onFocus={this.handleFocus}
                      onChange={this.handleChange}
                      onInput={this.handleChange}
                      placeholder={this.props.intl.formatMessage(messages.placeholder)}
                      disabled={inputDisabled}
                      ref={(input) => {
                        this.input = input;
                      }}
                      defaultValue={defaultValue || value}
                      aria-autocomplete="list"
                      role="combobox"
                      aria-haspopup="listbox"
                      aria-controls={suggestUid}
                      aria-activedescendant={showSuggest ? selectedSuggestItemId : null}
                      aria-expanded={showSuggest}
                    />
                  </Locator>
                  <div className={styles.sideBlocks}>
                    {this.renderInputControl()}
                    {this.renderSearchResultHelp()}
                  </div>
                </label>
                <Locator id="SearchButton">
                  <button type="submit" className={styles.submitButton}>
                    <FormattedMessage
                      defaultMessage="Search"
                      description="Submit button text in header search bar"
                    />
                  </button>
                </Locator>
              </div>
            </div>
            {this.renderSuggest()}
          </form>
        </div>
      </Locator>
    );
  }
}

const ConnectedSearch = connect(
  (state) => ({
    productsRecent: getProductsRecent(state),
    suggest: state.search.suggest,
    isProductsRecentLoading: isProductsRecentLoading(state),
  }),
  {
    deleteProductsRecent,
    loadProductsRecent,
    loadSearchSuggest,
  },
)(SearchBase);
