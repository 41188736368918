import PropTypes from 'prop-types';
import ImageBundle from 'shapes/ImageBundle';
import VideoBundle from 'shapes/VideoBundle';

// eslint-disable-next-line import/no-default-export
export default PropTypes.oneOf([
  PropTypes.shape({
    image: ImageBundle.isRequired,
  }),
  PropTypes.shape({
    video: VideoBundle.isRequired,
  }),
]);
