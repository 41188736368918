import classnames from 'classnames/bind';
import {CircleLoading} from 'components/CircleLoading';
import {Locator} from 'components/Locator';
import {AnimatedHeart, State as HeartState} from 'components/UIKit/Animations/Heart';
import {Icon} from 'components/UIKit/Icon';
import {useDeviceVar} from 'hooks/useDeviceVars';
import React, {SyntheticEvent, useCallback, useState} from 'react';

import styles from './index.scss';

const cn = classnames.bind(styles);

type Props = {
  compact?: boolean;
  favorite: boolean | undefined;
  onFavorite: () => Promise<void>;
  onUnfavorite: () => Promise<void>;
};

export const FavoriteButton = React.memo(function FavoriteButton(props: Props) {
  const {compact, favorite, onFavorite, onUnfavorite} = props;
  const [loading, setLoading] = useState(false);

  const shouldAnimate = useDeviceVar('likeButton8819')?.enabled || false;

  const handleClick = useCallback(
    async (e: SyntheticEvent) => {
      if (!onFavorite || !onUnfavorite) {
        return;
      }

      e.preventDefault();
      e.stopPropagation();

      setLoading(true);

      try {
        if (favorite) {
          await onUnfavorite();
        } else {
          await onFavorite();
        }
      } finally {
        setLoading(false);
      }
    },
    [favorite, onFavorite, onUnfavorite],
  );

  const shouldAppearFavorited = favorite || (!favorite && loading);

  let icon = loading ? (
    <CircleLoading size="fontSize" />
  ) : (
    <Icon
      width="16px"
      height="16px"
      type="mono"
      name={shouldAppearFavorited ? 'heart-filled-16' : 'heart-linear-16'}
    />
  );

  if (shouldAnimate) {
    icon = (
      <AnimatedHeart
        state={shouldAppearFavorited ? HeartState.FILLED : HeartState.EMPTY}
        width={32}
        height={32}
      />
    );
  }

  return (
    <Locator id="FavoriteButton" favorite={favorite}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <div
        className={cn('favoriteButton', {
          compact,
          [styles.favorite!]: shouldAppearFavorited && shouldAnimate,
        })}
        onClick={handleClick}
        role="button"
        tabIndex={0}
      >
        {icon}
      </div>
    </Locator>
  );
});
