import {ApiClient} from 'helpers/ApiClient';
import type {Location} from 'history';
import {useTabUnfocus} from 'hooks/useTabUnfocus';
import {useCallback, useEffect, useRef} from 'react';
import {useLocation} from 'react-router-dom';

import {AcrossTabsSession} from './AcrossTabSession';
import {
  generateSearchSessionUuid,
  getPreviousSearchSessionId,
  getSearchSession,
  getSessionByLocation,
  isRouteChange,
  isSearchRouteLeave,
  isWithinSession,
  setPreviousSearchSessionId,
  setSearchSession,
  setSessionForLocation,
  shouldDiscardPreviousSession,
} from './helpers';
import type {SearchSession} from './types';
import {useSearchSessionUpdate} from './useSearchSessionUpdate';

export function initSearchSession(client: ApiClient, location: Location): void {
  const acrossTabSession = new AcrossTabsSession(client);
  const acrossTabSessionId = acrossTabSession.get();
  const currentSession = getSearchSession(client);
  const lastSession = getSessionByLocation(client, location.key);
  const previousSearchSessionId = getPreviousSearchSessionId(client);

  if (currentSession) {
    setSessionForLocation(client, location.key, currentSession);
  } else if (acrossTabSessionId) {
    setPreviousSearchSessionId(client, acrossTabSessionId);
  } else if (previousSearchSessionId) {
    if (!lastSession) {
      setSessionForLocation(client, location.key, previousSearchSessionId);
    } else {
      setPreviousSearchSessionId(client, lastSession);
    }
  }
}

export function useSearchSession(): void {
  const currentLocation = useLocation();
  const location = useRef<Location>(currentLocation);
  const searchSessionUpdate = useSearchSessionUpdate();
  const {getCurrentSearchSession, setAcrossTabSession} = searchSessionUpdate;

  useEffect(() => {
    const {
      getCurrentSearchSession,
      discardSearchSession,
      setPreviousSearchSession,
      discardPreviousSearchSession,
      getSessionByLocation,
      setSessionForLocation,
      discardLocationToSession,
    } = searchSessionUpdate;

    if (!isRouteChange(location.current, currentLocation)) {
      return;
    }

    const currentSession = getCurrentSearchSession();

    if (isWithinSession(location.current, currentLocation)) {
      if (currentSession) {
        setSessionForLocation(location.current.key, currentSession);
      }
      return;
    }

    const lastSessionId = getSessionByLocation(location.current.key);

    if (lastSessionId && currentLocation.key) {
      setPreviousSearchSession(lastSessionId);
    }

    if (isSearchRouteLeave(location.current, currentLocation)) {
      discardSearchSession();
    }

    if (shouldDiscardPreviousSession(location.current, currentLocation)) {
      discardPreviousSearchSession();
      discardLocationToSession();
    }

    location.current = currentLocation;
  }, [currentLocation, location, searchSessionUpdate]);

  const handleTabUnfocus = useCallback(() => {
    const searchSessionId = getCurrentSearchSession();

    if (searchSessionId) {
      setAcrossTabSession(searchSessionId);
    }
  }, [getCurrentSearchSession, setAcrossTabSession]);

  useTabUnfocus(handleTabUnfocus);

  const handleBeforeUnload = useCallback(() => {
    const {discardSearchSession, discardLocationToSession, discardPreviousSearchSession} =
      searchSessionUpdate;

    discardSearchSession();
    discardPreviousSearchSession();
    discardLocationToSession();
  }, [searchSessionUpdate]);

  useEffect(() => {
    global.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      global.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [handleBeforeUnload]);
}

function getSearchSessionId(
  client: ApiClient,
  searchSessionFromReduxState: SearchSession,
): SearchSession['searchSessionId'] {
  const fromStorage = getSearchSession(client);

  if (fromStorage) {
    return fromStorage;
  }

  // to proceed with session id generated in node
  if (searchSessionFromReduxState) {
    const {searchSessionId} = searchSessionFromReduxState;
    if (searchSessionId) {
      return searchSessionId;
    }
  }

  return generateSearchSessionUuid();
}

export function generateSearchSession(
  client: ApiClient,
  searchSessionFromReduxState: SearchSession,
): SearchSession {
  const searchRequestId = generateSearchSessionUuid();

  const searchSessionId = getSearchSessionId(client, searchSessionFromReduxState);
  const previousSearchSessionId = getPreviousSearchSessionId(client);
  setSearchSession(client, searchSessionId);

  const result: SearchSession = {searchRequestId, searchSessionId};

  if (previousSearchSessionId) {
    result.previousSearchSessionId = previousSearchSessionId;
  }

  return result;
}
