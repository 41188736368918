import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './Subheader.scss';

const cn = classnames.bind(styles);

const Subheader = React.memo(function Subheader({children}) {
  return <h2 className={cn('subheader')}>{children}</h2>;
});

Subheader.propTypes = {
  children: PropTypes.node,
};

Subheader.defaultProps = {
  children: null,
};

export default Subheader;
