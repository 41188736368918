export const getCssPropertiesFromString = (cssString: string): React.CSSProperties => {
  const css_json = `{"${cssString
    .replace(/; /g, '", "')
    .replace(/: /g, '": "')
    .replace(';', '')}"}`;

  const obj = JSON.parse(css_json);

  const keyValues = Object.keys(obj).map((key) => {
    const camelCased = key.replace(/-[a-z]/g, (g) => g?.[1]?.toUpperCase() || '');
    return {[camelCased]: obj[key]};
  });
  return Object.assign({}, ...keyValues);
};
