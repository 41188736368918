import PropTypes from 'prop-types';
import React, {PureComponent} from 'react';
import classnames from 'classnames/bind';
import {sanitizedFragmentFromString} from 'utils/sanitizer';
import styles from './index.scss';

const cn = classnames.bind(styles);

export default class HtmlContent extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    html: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    classNameMapper: PropTypes.func,
    attributeMapper: PropTypes.func,
    nodeMapper: PropTypes.func,
  };

  static defaultProps = {
    className: null,
    onClick: null,
    classNameMapper: cn,
    attributeMapper: null,
    nodeMapper: null,
  };

  constructor(props) {
    super(props);
    this.container = React.createRef();
  }

  componentDidMount() {
    this.renderContent();
  }

  componentDidUpdate() {
    this.renderContent();
  }

  handleClick = (event) => {
    const {onClick} = this.props;
    if (event.target.tagName === 'A') {
      onClick(event);
    }
  };

  renderContent() {
    const {html, classNameMapper, attributeMapper, nodeMapper} = this.props;
    const fragment = sanitizedFragmentFromString(html, {
      classNameMapper,
      attributeMapper,
      nodeMapper,
    });

    if (this.fragment) {
      this.container.current.innerHTML = '';
    }

    this.container.current.appendChild(fragment);
    this.fragment = fragment;
  }

  render() {
    const {className, onClick, ...props} = this.props;
    delete props.html;
    delete props.classNameMapper;
    delete props.attributeMapper;
    delete props.nodeMapper;

    const clickProps = onClick
      ? {
          onClick: this.handleClick,
          role: 'button',
          tabIndex: '0',
        }
      : {};

    return <div {...props} {...clickProps} className={className} ref={this.container} />;
  }
}
