import {useRtl} from 'hooks/useRtl';
import {RefObject, useMemo} from 'react';
import {polyfillScrollBehavior} from 'utils/scrollBehavior';

import {SliderItemType} from './useSliderContext';

polyfillScrollBehavior();

export enum AlignmentSide {
  START = 'start',
  END = 'end',
}

type ScrollTo = (scrollStart: number, behavior?: ScrollBehavior) => void;

type ScrollToIndex = (index: number, align?: AlignmentSide, behavior?: ScrollBehavior) => void;

type ScrollIntoView = (
  target: HTMLElement,
  align?: AlignmentSide,
  behavior?: ScrollBehavior,
) => void;

// Fix rounding of the scroll position.
const SCROLL_ROUNDING = 0.1;

export type ScrollApiType = {
  scrollIntoView: ScrollIntoView;
  scrollTo: ScrollTo;
  scrollToIndex: ScrollToIndex;
};

export function useApi(
  viewRef: RefObject<HTMLElement>,
  itemsRef: RefObject<SliderItemType<HTMLElement>[]>,
  direction: 'horizontal' | 'vertical',
): ScrollApiType {
  const rtl = useRtl();

  return useMemo((): ScrollApiType => {
    const scrollTo: ScrollTo = (targetScrollStart, behavior = 'smooth') => {
      let scrollSide;

      switch (direction) {
        case 'vertical':
          scrollSide = 'top';
          break;
        case 'horizontal':
        default:
          scrollSide = 'left';
          break;
      }
      viewRef.current?.scroll({[scrollSide]: targetScrollStart, behavior});
    };

    const scrollIntoView: ScrollIntoView = (target, align = AlignmentSide.START, behavior) => {
      if (!viewRef.current) {
        return;
      }

      let sideMap: Record<AlignmentSide, 'right' | 'left' | 'top' | 'bottom'> = rtl
        ? ({[AlignmentSide.START]: 'right', [AlignmentSide.END]: 'left'} as const)
        : ({[AlignmentSide.START]: 'left', [AlignmentSide.END]: 'right'} as const);

      if (direction === 'vertical') {
        sideMap = {[AlignmentSide.START]: 'top', [AlignmentSide.END]: 'bottom'} as const;
      }
      const side = sideMap[align];

      const view = viewRef.current;
      const contentPos = view.getBoundingClientRect()[side];
      const targetPos = target.getBoundingClientRect()[side];

      scrollTo(
        (direction === 'vertical' ? view.scrollTop : view.scrollLeft) +
          targetPos -
          contentPos +
          SCROLL_ROUNDING,
        behavior,
      );
    };

    const scrollToIndex: ScrollToIndex = (index: number, align, behavior) => {
      const target = itemsRef.current?.find((item) => item.index === index);

      if (target) {
        scrollIntoView(target.element, align, behavior);
      }
    };

    return {scrollIntoView, scrollTo, scrollToIndex};
  }, [viewRef, rtl, itemsRef, direction]);
}
