export const AllowedTagName = {
  a: true,
  br: true,
  code: true,
  div: true,
  em: true,
  b: true,
  h1: true,
  h2: true,
  h3: true,
  h4: true,
  h5: true,
  li: true,
  nobr: true,
  ol: true,
  p: true,
  pre: true,
  span: true,
  strong: true,
  ul: true,
};

export const AnchoredTagName = {
  h1: true,
  h2: true,
  h3: true,
  h4: true,
  h5: true,
};

export const AllowedAttributes = {
  alt: true,
  disabled: true,
  height: true,
  href: true,
  src: true,
  style: true,
  title: true,
  width: true,
};
