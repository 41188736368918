import {prepareFiltersPayload} from './utils';

const LOAD = 'notifications/filters/LOAD';
export const LOAD_SUCCESS = 'notifications/filters/LOAD_SUCCESS';
const LOAD_FAIL = 'notifications/filters/LOAD_FAIL';

const initialState = {
  items: null,
  loading: false,
  loadingError: null,
};

function baseReducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true,
        loadingError: null,
      };

    case LOAD_SUCCESS:
      return {
        ...state,
        items: action.result.items,
        loading: false,
      };

    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loadingError: action.error,
      };

    default:
      return state;
  }
}

export const reducer = baseReducer;

export function loadNotificationsFilters() {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client) => client.api.get('/notifications/filters').then(prepareFiltersPayload),
  };
}

export function loadNotificationsFiltersSuccess(result) {
  return {
    type: LOAD_SUCCESS,
    result,
  };
}
