const ANY_MIME_SUPTYPE = '*';

const parseMimeType = (mimeType: string): [string, string] => {
  const [type, subtype] = mimeType.split('/') as [string, ...string[]];

  return [type, subtype ?? ANY_MIME_SUPTYPE];
};

/**
 * @example
 * matchMimeType('image/png', 'image/png')
 * matchMimeType('video/mp4', 'video/*')
 */
export const matchMimeType = (actual: string, expected: string): boolean => {
  const [typeA, subtypeA] = parseMimeType(actual);
  const [typeB, subtypeB] = parseMimeType(expected);

  return typeA === typeB && (subtypeA === subtypeB || subtypeB === ANY_MIME_SUPTYPE);
};
