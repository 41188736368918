import PropTypes from 'prop-types';
import ImageBundle from './ImageBundle';

export const WarrantyTextsItemShape = PropTypes.shape({
  text: PropTypes.string.isRequired,
  icon: ImageBundle,
});

export const WarrantyTextsShape = PropTypes.shape({
  items: PropTypes.arrayOf(WarrantyTextsItemShape),
});
