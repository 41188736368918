import {uploadImage} from 'store/modules/media';

import {ImageMediaState, MediaStatus, UploaderErrorType, UploaderOptions} from '../types';
import {createUploaderError} from './createUploaderError';

export const startImageUploading = async (
  mediaUuid: string,
  prevMediaState: ImageMediaState,
  {commit, dispatch}: UploaderOptions,
): Promise<void> => {
  let mediaState = prevMediaState;
  const mediaFile = mediaState.file;

  if (!mediaFile) {
    throw createUploaderError(UploaderErrorType.INTERNAL_ERROR, {
      message: `Attempt to upload a media without file (mediaUuid: ${mediaUuid})`,
    });
  }

  const response = await dispatch(uploadImage(mediaFile));
  const [firstImage] = response.items;

  if (!firstImage) {
    throw createUploaderError(UploaderErrorType.EXTERNAL_ERROR, {
      message: `No images returned (mediaUuid: ${mediaUuid})`,
    });
  }

  mediaState = commit(mediaUuid, {
    ...mediaState,
    bytesUploaded: mediaState.bytesTotal,
    bundle: {
      image: firstImage,
    },
    status: MediaStatus.SUCCESS,
  });
};
