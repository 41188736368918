import {isString} from 'utils/guards';

export function createImageThumbnail(file: File): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.addEventListener('error', (error) => {
      reject(error);
    });
    reader.addEventListener('loadend', () => {
      if (isString(reader.result)) {
        resolve(reader.result);
      } else {
        reject(new Error('Failed to read file'));
      }
    });
  });
}
