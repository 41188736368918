import {Coolbe} from 'components/Header/Logo/Coolbe';
import {Scope} from 'config';
import {useScope} from 'hooks/useScope';
import React from 'react';

import {Geek} from './Geek';
import {Logo} from './Logo';

type Props = {
  isSmall?: boolean;
  color?: string;
};

export function HeaderLogo({isSmall = false, color}: Props): JSX.Element {
  const scope = useScope();

  return {
    [Scope.GLOBAL]: <Logo isSmall={isSmall} color={color} />,
    [Scope.GEEK]: <Geek isSmall={isSmall} color={color} />,
    [Scope.COOLBE]: <Coolbe isSmall={isSmall} color={color} />,
    [Scope.CBTREND]: <Coolbe isSmall={isSmall} color={color} />,
  }[scope.topScope];
}
