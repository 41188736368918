import {hasMobileAppForGivenOS} from 'helpers/userAgent';
import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {type TrackingData, getTrackingMarquesData} from 'store/modules/tracking';
import {MobWebAdInterlayerConfig, MobWebAdInterlayerMode} from 'types/deviceVars';

import {useAppLinkHandler} from './useAppLink';
import {useDeviceVar} from './useDeviceVars';
import {usePagePreferences} from './usePagePreferences';
import {useScope} from './useScope';
import {useUserAgent} from './useUserAgent';

export const isMobWebAdInterlayerEnabled = (
  tracking: TrackingData | undefined,
  config: MobWebAdInterlayerConfig | undefined,
  modes: MobWebAdInterlayerMode[],
): boolean => {
  if (!config) {
    return false;
  }

  const {mode: configuredMode, utmParamName, utmSources} = config;

  // If superhardcore mode is configured, ignore other settings, just return true
  if (configuredMode === 'superhardcore') {
    return true;
  }

  // If mode is not configured or not in the list of allowed modes, return false
  if (!configuredMode || !modes.includes(configuredMode)) {
    return false;
  }

  // If no utmSources or utmParamName is configured, return false
  if (!(utmSources || utmParamName) || !tracking) {
    return false;
  }

  // If utmSources are configured and the current utm_source is not in the list, return false
  if (
    (configuredMode === 'hardcore' || configuredMode === 'ios_nightmare') &&
    utmSources &&
    tracking.utm_source &&
    utmSources?.includes(tracking.utm_source)
  ) {
    return true;
  }

  // If utmParamName is configured and the current utm param is not in the list, return false
  if (utmParamName && tracking[`utm_${utmParamName}`]) {
    return true;
  }

  return false;
};

export const useMobWebAdInterlayer = (modes: MobWebAdInterlayerMode[]): boolean => {
  const config = useDeviceVar('mobWebAdInterlayerConfig');
  const tracking = useSelector(getTrackingMarquesData);

  return useMemo(
    () => isMobWebAdInterlayerEnabled(tracking, config, modes),
    [config, tracking, modes],
  );
};

export const useMobWebAdInterlayerHardcoreModeLink = (
  customDeepUrl?: string,
): ReturnType<typeof useAppLinkHandler> => {
  const suitableForMobWebInterlayer = useMobWebAdInterlayer([
    'hardcore',
    'superhardcore',
    'ios_nightmare',
  ]);
  const userAgent = useUserAgent();
  const {deepUrl} = usePagePreferences();
  const scope = useScope();
  const appLink = useAppLinkHandler({target: customDeepUrl ?? deepUrl});
  const hasMobileApp = hasMobileAppForGivenOS(userAgent.os.name, scope);
  const shouldEnableMobWebInterlayerLogic =
    hasMobileApp && (customDeepUrl ?? deepUrl) && suitableForMobWebInterlayer && appLink;

  if (shouldEnableMobWebInterlayerLogic) {
    return appLink;
  }

  return undefined;
};
