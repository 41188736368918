import classnames from 'classnames/bind';
import {PhoneLinks} from 'components/DeliveryPointsPopup/PhoneLinks';
import {Locator} from 'components/Locator';
import {DeliveryPointInfoShape} from 'shapes/DeliveryPoints';
import React, {memo} from 'react';
import {FormattedMessage} from 'react-intl';
import {getValueByPath} from 'utils/object';
import {ParcelShape} from 'shapes/Parcel';
import {Map} from './Map';
import styles from './index.scss';
import {getMapCenter} from './getMapCenter';

const cn = classnames.bind(styles);

export const DeliveryPoint = memo(function DeliveryPoint({deliveryPointInfo, parcel}) {
  const {coordinates, workingHours, address, phoneNumber, name, shelfTime} = deliveryPointInfo;

  const description = getValueByPath(parcel, 'deliveryPoint', 'description');

  return (
    <>
      <h2 className={styles.deliveryPointTitle}>
        <FormattedMessage
          description="Delivery point address header on parcel page"
          defaultMessage="Pickup point address"
        />
      </h2>
      <Map
        rounded
        coordinates={coordinates}
        query={getMapCenter(coordinates.latitude, coordinates.longitude)}
      />
      <div className={styles.deliveryPointName}>{name}</div>
      <p className={styles.info}>{address}</p>
      {Boolean(shelfTime) && <p className={styles.info}>{shelfTime}</p>}
      {Boolean(workingHours) && (
        <Locator id="DeliveryPointWorkingHours">
          <p className={cn('info', 'light', 'hours')}>{workingHours}</p>
        </Locator>
      )}
      {Boolean(phoneNumber) && (
        <p className={cn('info', 'light')}>
          <PhoneLinks phoneNumber={phoneNumber} />
        </p>
      )}
      {Boolean(description) && (
        <div className={styles.descriptionBlock}>
          <div className={styles.descriptionTitle}>
            <FormattedMessage
              description="Delivery point map `How to get` text"
              defaultMessage="How to get there"
            />
          </div>
          <p className={styles.description}>{description}</p>
        </div>
      )}
    </>
  );
});

DeliveryPoint.propTypes = {
  deliveryPointInfo: DeliveryPointInfoShape,
  parcel: ParcelShape.isRequired,
};

DeliveryPoint.defaultProps = {
  deliveryPointInfo: null,
};
