import ClientApi from '@joomcode/joom-event-types';
import {convertHref} from 'components/HyperText/utils';
import {eventParamsList} from 'helpers/eventParams';
import {useSendAnalytics} from 'hooks/useAnalytics';
import {useLanguage} from 'hooks/useLanguage';
import {InitialClickData, PartialClickEvent, usePreviewEvent} from 'hooks/usePreviewEvent';
import {useScope} from 'hooks/useScope';
import {KeepDeepLinkContext} from 'providers/DeepLinks';
import {useCallback, useContext, useMemo, useRef} from 'react';
import type {AnalyticsSendOptions} from 'types/AnalyticsEvent';
import type {EventParams} from 'types/EventParams';

type RemainingSecondsResult = {remainingSeconds?: number};

export function getRemainingSeconds(expirationTimeMs?: number): RemainingSecondsResult {
  if (typeof expirationTimeMs === 'number') {
    return {
      remainingSeconds: Math.floor(Math.max(expirationTimeMs - Date.now(), 0) / 1000),
    };
  }

  return {};
}

export function useSendProductCardGroupScroll(id: string): () => void {
  const eventSent = useRef<boolean>(false);
  const sendAnalytics = useSendAnalytics();

  return useCallback(() => {
    if (eventSent.current) {
      return;
    }

    eventSent.current = true;
    sendAnalytics('productCardGroupScroll', {productGroupId: id});
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps
}

export function usePrepareUrl(url?: string): string {
  const lang = useLanguage();
  const scope = useScope();
  const keepDeepLink = useContext(KeepDeepLinkContext);

  return useMemo(() => {
    if (!url) {
      return '';
    }

    // skip deeplink convert
    if (keepDeepLink) {
      return url;
    }

    const convertedUrl = convertHref(scope, lang, url);

    return typeof convertedUrl === 'string' ? convertedUrl : url;
  }, [lang, scope, url, keepDeepLink]);
}

export function useBannerPreview({
  bannerId,
  eventParams,
  rootRef,
  clickRefs = [],
  expirationTimeMs,
  visibilityThreshold,
  source,
  clickEventOptions,
}: {
  bannerId: string;
  eventParams?: EventParams;
  rootRef: React.RefObject<HTMLElement>;
  clickRefs?: React.RefObject<HTMLElement>[];
  expirationTimeMs?: number;
  visibilityThreshold?: number;
  source?: string;
  clickEventOptions?: AnalyticsSendOptions;
}): void {
  const clickEvents: InitialClickData = useMemo(() => {
    const clickEvent: () => PartialClickEvent<ClientApi.AnalyticsEventBannerClick> = () => ({
      type: 'bannerClick',
      payload: {bannerId, place: source},
      params: eventParamsList(eventParams),
      ...getRemainingSeconds(expirationTimeMs),
    });

    if (!clickRefs.length) {
      return {
        clickEvent,
        clickEventOptions,
      };
    }

    return clickRefs.map((ref) => {
      return {
        clickRef: ref,
        clickEvent,
        clickEventOptions,
      };
    });
  }, [bannerId, clickEventOptions, clickRefs, eventParams, expirationTimeMs, source]);

  usePreviewEvent(
    {
      rootRef,
      previewEvent: () => ({
        type: 'bannerPreview',
        payload: {bannerId, place: source},
        params: eventParamsList(eventParams),
        ...getRemainingSeconds(expirationTimeMs),
      }),
      visibilityThreshold,
    },
    clickEvents,
  );
}

type UseBannersPreviewProps = {
  bannersBlockId: string;
  clickRefs?: React.RefObject<HTMLElement>[];
  eventParams?: EventParams;
  rootRef: React.RefObject<HTMLElement>;
};

export function useBannersPreview({
  bannersBlockId,
  clickRefs,
  eventParams,
  rootRef,
}: UseBannersPreviewProps): void {
  const clickEvents = useMemo<InitialClickData | undefined>(() => {
    const clickEvent: () => PartialClickEvent<ClientApi.AnalyticsEventBannersClick> = () => ({
      type: 'bannersClick',
      payload: {bannersBlockId},
      params: eventParamsList(eventParams),
    });

    if (!clickRefs?.length) {
      return undefined;
    }

    return clickRefs.map((clickRef) => ({
      clickRef,
      clickEvent,
    }));
  }, [bannersBlockId, clickRefs, eventParams]);

  usePreviewEvent(
    {
      rootRef,
      previewEvent: () => ({
        type: 'bannersPreview',
        payload: {bannersBlockId},
        params: eventParamsList(eventParams),
      }),
    },
    clickEvents,
  );
}

export function useSendBannersScroll(bannersBlockId: string): () => void {
  const eventSent = useRef<boolean>(false);
  const sendAnalytics = useSendAnalytics();

  return useCallback(() => {
    if (eventSent.current) {
      return;
    }

    eventSent.current = true;
    sendAnalytics('bannersScroll', {bannersBlockId});
  }, [bannersBlockId]); // eslint-disable-line react-hooks/exhaustive-deps
}
