import {RefObject, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {registerStickyHeader} from 'utils/pageScroll';

import {useIntersectionObserverRef} from './useIntersectionObserverRef';

function useStuckElement<T extends HTMLElement>({
  enabled,
}: {
  enabled: boolean;
}): {
  // ref to be set on the sticky element
  stickyRef: RefObject<T>;
  isStuck: boolean;
} {
  const stickyRef = useRef<T>(null);
  const [isStuck, setStuck] = useState(false);

  const handleObserve = useCallback((entry: IntersectionObserverEntry) => {
    setStuck(entry.intersectionRatio < 1);
  }, []);

  const observerOptions = useMemo(
    () => ({
      targetRef: stickyRef,
      rootMargin: '-1px 0px 0px 0px',
      threshold: [1],
    }),
    [],
  );

  useIntersectionObserverRef(handleObserve, observerOptions);

  useEffect(() => {
    const unregister = registerStickyHeader({nodeRef: stickyRef, margin: 8});

    return unregister;
  }, []);

  if (!enabled) {
    return {stickyRef, isStuck: false};
  }

  return {stickyRef, isStuck};
}

export {useStuckElement};
