import classnames from 'classnames/bind';
import {useAnalytics} from 'hooks/useAnalytics';
import {useApiClient} from 'hooks/useApiClient';
import {useDeviceVars} from 'hooks/useDeviceVars';
import {useEffectOnce} from 'hooks/useEffectOnce';
import {useVisibilityChange} from 'hooks/useVisibilityChange';
import {useSnowContext} from 'providers/SnowContext';
import React, {useCallback, useMemo, useRef, useState} from 'react';

import styles from './index.scss';
import {SnowFlakes} from './snow';
import {snowUserData} from './storage';

const cn = classnames.bind(styles);

function minToMs(n: number) {
  return n * 60 * 1000;
}

function useSnowTimer(cb: () => void, timeLimitMin?: number) {
  const client = useApiClient();
  const snowData = useMemo(() => snowUserData(client), [client]);

  const timerId = useRef<number>();

  const lastStartTime = useRef<number>();

  const timerDisabled = !timeLimitMin || snowData.isHiddenByTimeout() || snowData.choiceExists();

  const timerCb = useCallback(() => {
    cb?.();
    snowData.setHiddenByTimeout(true);
    snowData.removeTimer();
  }, [cb, snowData]);

  const setTimer = useCallback(() => {
    if (timerDisabled) {
      return;
    }

    lastStartTime.current = Date.now();
    const totalMs = minToMs(timeLimitMin);
    const elapsedTime = snowData.getViewingTime();
    const timeout = elapsedTime ? totalMs - elapsedTime : totalMs;
    timerId.current = window.setTimeout(timerCb, timeout);
  }, [timerDisabled, timeLimitMin, snowData, timerCb]);

  function clearTimer() {
    window.clearTimeout(timerId.current);
    lastStartTime.current = undefined;
  }

  const updateTimer = useCallback(() => {
    if (timerDisabled) {
      return;
    }

    if (lastStartTime.current && !Number.isNaN(lastStartTime.current)) {
      snowData.increaseViewingTime(Date.now() - lastStartTime.current);
    }

    clearTimer();
  }, [snowData, timerDisabled]);

  useVisibilityChange(updateTimer, setTimer);

  const startTime = useRef<number>();

  useEffectOnce(() => {
    if (timerDisabled) {
      return undefined;
    }

    startTime.current = Date.now();

    setTimer();

    return () => {
      clearTimer();
    };
  });
}

const SnowCanvas: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const snowRef = useRef<SnowFlakes>();
  const analytics = useAnalytics();
  const {toggleSnow} = useSnowContext();
  const {snowFlakes2023: {timeLimitMin} = {}} = useDeviceVars();

  const [canvasTransparency, setCanvasTransparency] = useState(false);

  useEffectOnce(() => {
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext('2d');

    if (!canvas || !ctx) {
      return undefined;
    }

    snowRef.current = new SnowFlakes({
      canvas,
      ctx,
    });

    analytics.sendEvent({type: 'christmasSnowShow'});

    return () => {
      snowRef.current?.destroy();
    };
  });

  useVisibilityChange(
    () => snowRef.current?.stop(),
    () => snowRef.current?.start(),
  );

  const hideAfterTimeout = useCallback(() => {
    setCanvasTransparency(true);
  }, []);

  const handleTransitionEnd = useCallback(() => {
    toggleSnow(false);
  }, [toggleSnow]);

  useSnowTimer(hideAfterTimeout, timeLimitMin);

  return (
    <canvas
      className={cn('canvas', {hidden: canvasTransparency})}
      ref={canvasRef}
      onTransitionEnd={handleTransitionEnd}
    />
  );
};

export const Snow: React.FC = () => {
  const {snowFlakes2023: {on: featureOn = false} = {}} = useDeviceVars();
  const {isSnowShown} = useSnowContext();

  if (featureOn && isSnowShown) {
    return <SnowCanvas />;
  }

  return null;
};
