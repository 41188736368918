import {UserAgentContext} from 'providers/UserAgentContext';
import AnalyticsShape from 'shapes/Analytics';
import LocationShape from 'shapes/Location';
import PropTypes from 'prop-types';
import React, {createRef, PureComponent} from 'react';
import {isMobileOrTabletDevice} from 'helpers/userAgent';
import {defineMessages, FormattedMessage, useIntl} from 'react-intl';
import {Link} from 'react-router-dom';
import {identity} from 'utils/function';
import {useAnalytics} from 'hooks/useAnalytics';
import {useDeviceVars} from 'hooks/useDeviceVars';
import {SearchResultHelpShape} from 'shapes/Search';
import {getSearchData} from 'utils/search';
import {OnboardingTooltip} from 'components/OnboardingTooltip/loadable';
import throttle from 'utils/throttle';
import classnames from 'classnames/bind';
import {JmtEntityRedirectTooltip} from 'components/JmtEntityRedirectTooltip';
import {getUrl} from 'routes';
import {useScope} from 'hooks/useScope';
import {useAppHistoryInfo} from 'providers/AppHistoryInfo';
import {Icon} from 'components/UIKit/Icon';
import {HeaderCoupon} from 'components/Header/CouponRedesign';
import {CouponTooltip} from 'components/Header/CouponRedesign/CouponTooltip';
import {
  getStorageCouponOnboardingShown,
  setStorageCouponOnboardingShown,
} from 'store/modules/couponCards/storage';
import {rootLocator} from 'utils/rootLocator';
import {useMobWebAdInterlayerHardcoreModeLink} from 'hooks/useMobWebAdInterlayer';
import {Scope} from 'config';
import {ClientTutorialId} from 'components/OnboardingTooltip/clientTutorialId';
import {useIsBreakpointMobile} from 'hooks/useIsBreakpointMobile';
import Cart from './Cart';
import {Favorites} from './Favorites';
import {HeaderLogo} from './Logo';
import Search from './Search';
import styles from './Header.scss';
import {HeaderButton, HeaderButtonContainer} from './HeaderButton';

const cn = classnames.bind(styles);

const SCROLL_THRESHOLD_MS = 250;
const SCROLL_DELTA = 10;
const HEADER_HEIGHT = parseInt(styles.smallHeaderHeight, 10);
const COUPON_TOOLTIP_TIMEOUT = 4_000;

export default function Header(props) {
  const analytics = useAnalytics();
  const scope = useScope();
  const intl = useIntl();
  const appHistoryInfo = useAppHistoryInfo();
  const {favoritesInHeader, mobileWebStickyHeader, desktopWebStickyHeader, webCouponRedesign2023} =
    useDeviceVars();

  const mobileInterlayerActionOverrideLink = useMobWebAdInterlayerHardcoreModeLink();
  const isMobileViewport = useIsBreakpointMobile('sm');

  return (
    <HeaderBase
      {...props}
      scope={scope}
      isMobileViewport={isMobileViewport}
      analytics={analytics}
      favoritesInHeader={favoritesInHeader}
      intl={intl}
      appHistoryInfo={appHistoryInfo}
      mobileWebStickyHeader={mobileWebStickyHeader}
      desktopWebStickyHeader={desktopWebStickyHeader}
      mobileInterlayerActionOverrideLink={mobileInterlayerActionOverrideLink}
      // eslint-disable-next-line react/prop-types
      hasCoupon={webCouponRedesign2023 && props.hasCoupon}
    />
  );
}

const onboardingTooltipMessages = defineMessages({
  caption: {
    description: 'Надпись на тултипе, подсвечивающем бургер',
    defaultMessage: "Hey, we've got something new",
  },
});

const messages = defineMessages({
  burgerLabel: {
    description: '[label] Бургер',
    defaultMessage: 'Menu',
  },
  orders: {
    description: 'Header orders button label',
    defaultMessage: 'My orders',
  },
  search: {
    description: 'Header search button label',
    defaultMessage: 'Search',
  },
});

const locator = rootLocator.commonPage.header;

class HeaderBase extends PureComponent {
  static propTypes = {
    cartCount: PropTypes.number,
    defaultSearchValue: PropTypes.string,
    lang: PropTypes.string.isRequired,
    scope: PropTypes.string.isRequired,
    location: LocationShape.isRequired,
    notificationsCenter: PropTypes.node,
    onBurgerClick: PropTypes.func,
    onSearch: PropTypes.func.isRequired,
    params: PropTypes.shape({
      query: PropTypes.string,
    }).isRequired,
    renderProfile: PropTypes.func.isRequired,
    wrapWithMiniCart: PropTypes.func,
    analytics: AnalyticsShape.isRequired,
    favoritesInHeader: PropTypes.bool,
    shouldHighlightBurger: PropTypes.bool,
    onBurgerHighlightClose: PropTypes.func,
    intl: PropTypes.objectOf(PropTypes.any).isRequired,
    mobileWebStickyHeader: PropTypes.bool,
    desktopWebStickyHeader: PropTypes.bool,
    hasMobilePermanentlyStickyHeader: PropTypes.bool,
    hasMobileStickyHeader: PropTypes.bool,
    hasDesktopStickyHeader: PropTypes.bool,
    hasMobileBackButton: PropTypes.bool,
    onMobileBackButtonClick: PropTypes.func,
    searchResultHelp: SearchResultHelpShape,
    appHistoryInfo: PropTypes.object.isRequired,
    hasCoupon: PropTypes.bool,
    showSearchSideButton: PropTypes.bool,
    handleSearchSideButtonClick: PropTypes.func,
    mobileInterlayerActionOverrideLink: PropTypes.func,
    hiddenHeaderMobileButtons: PropTypes.arrayOf(PropTypes.string),
    isMobileViewport: PropTypes.bool,
  };

  static defaultProps = {
    cartCount: null,
    defaultSearchValue: '',
    notificationsCenter: null,
    onBurgerClick: null,
    wrapWithMiniCart: identity,
    favoritesInHeader: false,
    shouldHighlightBurger: false,
    onBurgerHighlightClose: null,
    mobileWebStickyHeader: false,
    desktopWebStickyHeader: false,
    hasMobilePermanentlyStickyHeader: false,
    hasMobileStickyHeader: false,
    hasDesktopStickyHeader: false,
    hasMobileBackButton: false,
    onMobileBackButtonClick: undefined,
    searchResultHelp: null,
    hasCoupon: undefined,
    showSearchSideButton: undefined,
    handleSearchSideButtonClick: undefined,
    mobileInterlayerActionOverrideLink: undefined,
    hiddenHeaderMobileButtons: [],
    isMobileViewport: false,
  };

  constructor(props) {
    super(props);

    this.mountTimer = 0;
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSearchOpen = this.handleSearchOpen.bind(this);
    this.handleSearchClose = this.handleSearchClose.bind(this);
    this.handleScroll = throttle(this.handleScroll, SCROLL_THRESHOLD_MS);
    this.anchorRef = createRef(null);
    this.rootRef = createRef(null);
    this.pageYOffset = 0;

    this.state = {
      mobileSearchMode: false,
      isBurgerHighlightClosed: false,
      isMobileStickyVisible: false,
      isSticky: false,
      hiddenSearchOpenedPathname: '',
    };
  }

  componentDidMount() {
    if (this.shouldBeStickyOnMobile()) {
      window.addEventListener('scroll', this.handleScroll);
    }
    if (
      (this.hasDesktopCoupon() || this.hasMobilePermanentlyStickyHeader()) &&
      this.rootRef.current
    ) {
      this.observer = new IntersectionObserver(this.handleIntersection, {
        rootMargin: '-1px 0px 0px 0px',
        threshold: [1],
      });
      this.observer.observe(this.rootRef.current);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    this.observer?.disconnect();
  }

  shouldBeStickyOnMobile = () => {
    const {mobileWebStickyHeader, hasMobileStickyHeader} = this.props;
    return mobileWebStickyHeader && hasMobileStickyHeader;
  };

  shouldBeStickyOnDesktop = () => {
    const {desktopWebStickyHeader, hasDesktopStickyHeader} = this.props;
    return desktopWebStickyHeader && hasDesktopStickyHeader;
  };

  hasDesktopCoupon() {
    return this.shouldBeStickyOnDesktop() && this.props.hasCoupon;
  }

  hasMobilePermanentlyStickyHeader() {
    return Boolean(this.props.isMobileViewport && this.props.hasMobilePermanentlyStickyHeader);
  }

  hasMobileBackButton() {
    const {appHistoryInfo, hasMobileBackButton} = this.props;
    return hasMobileBackButton && appHistoryInfo.items.length > 0;
  }

  handleSearchOpen() {
    this.setState({mobileSearchMode: true});
  }

  handleSearchClose() {
    this.setState({mobileSearchMode: false});
  }

  handleIntersection = ([event] = []) => {
    this.setState({isSticky: event?.intersectionRatio < 1});
  };

  handleScroll = () => {
    const currentOffset = window.pageYOffset;

    if (
      !this.props.mobileWebStickyHeader ||
      Math.abs(currentOffset - this.pageYOffset) <= SCROLL_DELTA
    ) {
      return;
    }

    const isMobileStickyVisible = currentOffset > HEADER_HEIGHT && currentOffset < this.pageYOffset;
    this.setState({isMobileStickyVisible});
    this.pageYOffset = currentOffset;
  };

  handleSearch(...args) {
    this.props.onSearch(...args);
  }

  handleBackButtonClick = () => {
    window.history.back();
    this.props.onMobileBackButtonClick?.();
  };

  handleLogoClick = () => {
    this.props.analytics.sendEvent({
      type: 'logoHeaderClick',
    });
  };

  handleBurgerClick = () => {
    this.props.onBurgerClick();
    this.setState({isBurgerHighlightClosed: true});
  };

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  handleHeaderClick = () => {
    this.scrollToTop();
  };

  handleLogoButtonClick = () => {
    this.scrollToTop();
  };

  handleMobileCouponClose = () => {
    if (!getStorageCouponOnboardingShown() && this.shouldBeStickyOnMobile()) {
      this.setState({showCouponMenuTooltip: true});
      setStorageCouponOnboardingShown(true);

      setTimeout(() => {
        this.setState({showCouponMenuTooltip: false});
      }, COUPON_TOOLTIP_TIMEOUT);
    }
  };

  handleCouponTooltipClose = () => {
    this.setState({showCouponMenuTooltip: false});
  };

  renderBurger() {
    const {isBurgerHighlightClosed, showCouponMenuTooltip} = this.state;
    const {intl, onBurgerClick, shouldHighlightBurger, onBurgerHighlightClose} = this.props;

    if (!onBurgerClick) {
      return null;
    }

    const button = (
      <button
        className={styles.burger}
        onClick={this.handleBurgerClick}
        type="button"
        aria-label={intl.formatMessage(messages.burgerLabel)}
        {...locator.burgerMenu()}
      />
    );

    if (showCouponMenuTooltip) {
      return (
        <div className={styles.burgerWrapper}>
          <CouponTooltip onClose={this.handleCouponTooltipClose}>{button}</CouponTooltip>
        </div>
      );
    }

    if (shouldHighlightBurger && !isBurgerHighlightClosed) {
      return (
        <div className={styles.burgerWrapper}>
          <OnboardingTooltip
            message={<FormattedMessage {...onboardingTooltipMessages.caption} />}
            round
            useBubble
            useForceReposition
            useCloseButton
            onButtonClick={this.handleBurgerClick}
            onCloseClick={onBurgerHighlightClose}
            tutorialId={ClientTutorialId.NAVIGATION_BURGER}
          >
            {button}
          </OnboardingTooltip>
        </div>
      );
    }

    return <div className={styles.burgerWrapper}>{button}</div>;
  }

  renderCart() {
    const {cartCount, wrapWithMiniCart} = this.props;

    return (
      <HeaderButtonContainer>
        {typeof wrapWithMiniCart === 'function' ? (
          wrapWithMiniCart(<Cart count={cartCount} />)
        ) : (
          <Cart count={cartCount} />
        )}
      </HeaderButtonContainer>
    );
  }

  handleOrdersClick = (event) => {
    if (this.props.mobileInterlayerActionOverrideLink) {
      event?.preventDefault();
      event?.stopPropagation();

      this.props.mobileInterlayerActionOverrideLink(event);
    }

    this.props.analytics.sendEvent({
      type: 'ordersLinkClick',
      payload: {from: 'header'},
    });
  };

  handleSearchSideButtonClick = () => {
    this.props.handleSearchSideButtonClick?.();
    this.setState({hiddenSearchOpenedPathname: this.props.location.pathname});
  };

  renderOrders() {
    const {lang, scope, intl} = this.props;

    return (
      <HeaderButtonContainer>
        <HeaderButton
          to={getUrl('orders', {lang, scope})}
          icon={
            <Icon aria-hidden type="mono" name="archive-linear-24" width="24px" height="24px" />
          }
          text={intl.formatMessage(messages.orders)}
          onClick={this.handleOrdersClick}
          {...locator.ordersButton()}
        />
        <JmtEntityRedirectTooltip />
      </HeaderButtonContainer>
    );
  }

  renderSearchSideButton() {
    const {intl} = this.props;

    return (
      <HeaderButtonContainer>
        <HeaderButton
          icon={<Icon name="search-linear-24" type="mono" />}
          text={intl.formatMessage(messages.search)}
          onClick={this.handleSearchSideButtonClick}
        />
      </HeaderButtonContainer>
    );
  }

  renderNotificationsCenter() {
    const {notificationsCenter, scope} = this.props;

    if (!notificationsCenter || scope.not(Scope.GLOBAL)) {
      return null;
    }

    return notificationsCenter;
  }

  render() {
    const {
      renderProfile,
      lang,
      scope,
      location,
      params,
      favoritesInHeader,
      defaultSearchValue,
      searchResultHelp,
      hasCoupon,
      showSearchSideButton,
      hiddenHeaderMobileButtons,
      isMobileViewport,
    } = this.props;
    const {
      mobileSearchMode,
      isMobileStickyVisible,
      showCouponMenuTooltip,
      isSticky,
      hiddenSearchOpenedPathname,
    } = this.state;

    const hasMobilePermanentlyStickyHeader = this.hasMobilePermanentlyStickyHeader();
    const showLogo = !this.hasMobileBackButton();
    const showSearchButton = Boolean(
      (!isMobileViewport || !hiddenHeaderMobileButtons.includes('search')) &&
        showSearchSideButton &&
        scope.not(Scope.CBTREND),
    );

    return (
      <>
        <div
          ref={this.rootRef}
          className={cn('header', 'mobileSticky', {
            mobileStickyVisible:
              isMobileStickyVisible || showCouponMenuTooltip || hasMobilePermanentlyStickyHeader,
            desktopSticky: this.shouldBeStickyOnDesktop(),
            withPermanentlyStickyHeader: hasMobilePermanentlyStickyHeader,
            isSticky,
          })}
          {...locator()}
        >
          <div className={styles.inner}>
            <div className={styles.reducer}>
              <div className={styles.table}>
                <div className={styles.row}>
                  {this.hasMobileBackButton() && (
                    <div className={cn(styles.side, styles.mobileOnly)}>
                      <button
                        type="button"
                        className={styles.mobileBackButton}
                        onClick={this.handleBackButtonClick}
                      >
                        <Icon name="arrow-left-linear-24" type="mono" loader={null} />
                      </button>
                    </div>
                  )}
                  <div className={styles.side}>{this.renderBurger()}</div>
                  <div className={cn(styles.side, !showLogo && styles.desktopOnly)}>
                    {isMobileStickyVisible ? (
                      <button
                        className={cn('logo', 'logoButton')}
                        type="button"
                        onClick={this.handleLogoButtonClick}
                        {...locator.logoButton()}
                      >
                        <HeaderLogo />
                      </button>
                    ) : (
                      <Link
                        className={styles.logo}
                        to={getUrl('main', {lang, scope})}
                        onClick={this.handleLogoClick}
                        {...locator.logoButton()}
                      >
                        <HeaderLogo />
                      </Link>
                    )}
                  </div>
                  {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                  <div className={styles.content} onClick={this.handleHeaderClick}>
                    {scope.not(Scope.CBTREND) && !showSearchSideButton && (
                      <div className={styles.search}>
                        <UserAgentContext.Consumer>
                          {(userAgent) => (
                            <Search
                              key={location.pathname}
                              onSubmit={this.handleSearch}
                              focus={
                                (mobileSearchMode && isMobileOrTabletDevice(userAgent)) ||
                                hiddenSearchOpenedPathname === location.pathname
                              }
                              value={getSearchData(params).query}
                              defaultValue={defaultSearchValue}
                              searchResultHelp={searchResultHelp}
                            />
                          )}
                        </UserAgentContext.Consumer>
                      </div>
                    )}
                  </div>
                  <div className={styles.side}>
                    <div className={styles.buttons}>
                      {showSearchButton && this.renderSearchSideButton()}
                      {isMobileViewport && hiddenHeaderMobileButtons.includes('notificationsCenter')
                        ? null
                        : scope.not(Scope.CBTREND) && this.renderNotificationsCenter()}
                      {isMobileViewport && hiddenHeaderMobileButtons.includes('profile')
                        ? null
                        : scope.not(Scope.CBTREND) &&
                          renderProfile({
                            shouldShowOrders:
                              isMobileViewport && hiddenHeaderMobileButtons.includes('orders'),
                          })}
                      {favoritesInHeader
                        ? ((!hiddenHeaderMobileButtons.includes('favorites') ||
                            !isMobileViewport) &&
                            scope.not(Scope.CBTREND) && <Favorites />) ||
                          null
                        : ((!hiddenHeaderMobileButtons.includes('orders') || !isMobileViewport) &&
                            scope.not(Scope.CBTREND) &&
                            this.renderOrders()) ||
                          null}
                      {isMobileViewport && hiddenHeaderMobileButtons.includes('cart')
                        ? null
                        : this.renderCart()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {this.hasDesktopCoupon() && (
              <div className={styles.desktopCouponContainer}>
                <div className={cn(styles.desktopCoupon, isSticky && styles.shown)}>
                  <HeaderCoupon source="header" />
                </div>
              </div>
            )}
          </div>
        </div>
        {hasCoupon && (
          <div className={styles.mobileCoupon}>
            <HeaderCoupon onClose={this.handleMobileCouponClose} source="header" mobile />
          </div>
        )}
        {scope.is(Scope.CBTREND) ? <div className={styles.whiteSpacer} /> : null}
      </>
    );
  }
}
