import {MediaBundle, MediaState, MediaStatus} from '../types';
import {getMediaTypeByMediaBundle} from './getMediaTypeByMediaBundle';

export function createMediaStateByMediaBundle(uuid: string, mediaBundle: MediaBundle): MediaState {
  return {
    bundle: mediaBundle,
    bytesTotal: 0,
    bytesUploaded: 0,
    status: MediaStatus.SUCCESS,
    type: getMediaTypeByMediaBundle(mediaBundle),
    uuid,
  } as MediaState;
}
