import {Stars} from 'components/Stars';
import React from 'react';

import styles from './index.scss';

export type SeoReviewProps = {
  userName?: string | null;
  /** Numeric rating from 1 to 5 */
  rating?: number | null;
  text?: string | null;
};

export function SeoReview({userName, rating, text}: SeoReviewProps): JSX.Element | null {
  if (!userName || !text) {
    return null;
  }

  return (
    <div className={styles.review}>
      <div className={styles.header}>
        <div className={styles.author}>{userName}</div>
        {rating ? <Stars rating={rating} /> : null}
      </div>

      <div className={styles.content}>{text}</div>
    </div>
  );
}
