import {Group} from 'components/ClientProduct/Attributes/Group';
import React from 'react';
import {ProductAttributes} from 'types/ProductAttributes';

type AllAttributesProps = {attributes: ProductAttributes};

export const AllAttributes: React.FC<AllAttributesProps> = ({
  attributes,
}: {
  attributes: ProductAttributes;
}) => {
  const {all} = attributes;

  if (!all) {
    return null;
  }

  return (
    <>
      {all.map((group) => (
        <Group key={group.id} group={group} />
      ))}
    </>
  );
};
