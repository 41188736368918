import {BannerProps} from 'components/ContentList/BannersList/BannerProps';
import {Locator} from 'components/Locator';
import React from 'react';

import {Slider, SliderItem} from '../../Slider';
import styles from './index.scss';
import {Item} from './Item';

export const FullSheetLong = React.memo(function FullSheetLong({
  bannerAppearance,
  items,
  onScroll,
  notClickable,
  source,
  small,
}: BannerProps): JSX.Element {
  return (
    <Locator id="BannersListFullSheetLong">
      <div className={styles.fullSheetLong}>
        <Slider loop onScroll={onScroll} withDots>
          <div className={styles.inner}>
            {items.map((item) => (
              <SliderItem key={item.id} timeout={item?.autoscrollTimerMs}>
                {({
                  a11yProps,
                  ref,
                  selected,
                  pauseAutoscroll,
                  unpauseAutoscroll,
                }): React.ReactElement => (
                  <div
                    ref={ref}
                    className={styles.cell}
                    {...a11yProps}
                    onMouseEnter={pauseAutoscroll}
                    onMouseLeave={unpauseAutoscroll}
                    onTouchStart={pauseAutoscroll}
                    onTouchEnd={unpauseAutoscroll}
                  >
                    <Item
                      bannerAppearance={bannerAppearance}
                      item={item}
                      selected={selected}
                      source={source}
                      small={small}
                      notClickable={notClickable}
                    />
                  </div>
                )}
              </SliderItem>
            ))}
          </div>
        </Slider>
      </div>
    </Locator>
  );
});
