import ErrorMessage from 'components/ErrorMessage';
import {Column} from 'components/Layout/Column';
import {Reducer} from 'components/Layout/Reducer';
import {Row} from 'components/Layout/Row';
import {Overlay} from 'components/Overlay';
import {Button} from 'components/UIKit/Button';
import {Popup} from 'components/UIKit/Popup';
import {StandaloneControls} from 'components/UIKit/Popup/Header/StandaloneControls';
import {PopupViewExtended, PopupViewProps} from 'components/UIKit/Popup/PopupProvider';
import {PopupUserActions} from 'components/UIKit/Popup/types';
import {useMountedRef} from 'hooks/useMounted';
import React, {FC, ReactNode, useCallback, useMemo} from 'react';
import {AdultPopup as AdultPopupType} from 'types/AdultPopup';
import {Error} from 'types/Error';
import {wrapToPromise} from 'utils/promise';

import {rootLocator} from '../../utils/rootLocator';
import styles from './index.scss';

const locator = rootLocator.adultPopup;

type ContentProps = {
  data?: AdultPopupType;
  error?: Error;
  children?: ReactNode;
};

type ButtonProps = {
  data: AdultPopupType;
  onConfirm: () => unknown;
  onDecline: () => unknown;
  isSaving?: boolean;
};

type Props = Omit<ButtonProps, 'data'> &
  ContentProps & {
    isLoading: boolean;
  };

const Content: FC<ContentProps> = ({children, data, error}) => (
  <div className={styles.content} {...locator()}>
    {data?.title ? (
      <>
        <div className={styles.title}>{data.title}</div>
      </>
    ) : null}
    {error ? (
      <div className={styles.error}>
        <ErrorMessage error={error} internal />
      </div>
    ) : null}
    {data?.body ? <div className={styles.text}>{data.body}</div> : null}
    {children}
  </div>
);

const Buttons: FC<ButtonProps> = ({onConfirm, onDecline, data, isSaving}) => (
  <>
    <div className={styles.buttonContainer} {...locator.iAmAdult()}>
      <Button tag="button" color="primary" onClick={onConfirm} disabled={isSaving}>
        {data.confirmText}
      </Button>
    </div>
    <div className={styles.buttonContainer} {...locator.iAmNotAdult()}>
      <Button tag="button" color="gray" onClick={onDecline} disabled={isSaving}>
        {data.declineText}
      </Button>
    </div>
  </>
);

export const AdultPopupInline: React.FC<Props> = ({
  onConfirm,
  onDecline,
  isLoading,
  data,
  isSaving,
  error,
}) => (
  <Reducer center noMarginBottom>
    <Row>
      <Column xs={12}>
        <div className={styles.reducer}>
          <Overlay loading={isLoading || isSaving}>
            <Content data={data} error={error}>
              {data ? (
                <Buttons
                  data={data}
                  onConfirm={onConfirm}
                  onDecline={onDecline}
                  isSaving={isSaving}
                />
              ) : null}
            </Content>
          </Overlay>
        </div>
      </Column>
    </Row>
  </Reducer>
);

export const AdultPopup: React.FC<PopupViewProps & Props> = ({
  onConfirm,
  onDecline,
  isLoading,
  data,
  isSaving,
  error,
  onClose,
  onBack,
}) => {
  const mountedRef = useMountedRef();
  const handleConfirm = useCallback(
    () => wrapToPromise(onConfirm()).then(() => (mountedRef.current ? onClose() : null)),
    [onConfirm, onClose, mountedRef],
  );
  const handleDecline = useCallback(
    () => wrapToPromise(onDecline()).then(() => (mountedRef.current ? onClose() : null)),
    [onDecline, onClose, mountedRef],
  );

  return (
    <Popup width="490px">
      <StandaloneControls onBack={onBack} onClose={onClose} />
      <Overlay loading={isLoading || isSaving}>
        <Content data={data} error={error}>
          {data ? (
            <Buttons
              data={data}
              onConfirm={handleConfirm}
              onDecline={handleDecline}
              isSaving={isSaving}
            />
          ) : null}
        </Content>
      </Overlay>
    </Popup>
  );
};

export function useAdultPopupView({
  onBack,
  onClose,
  onConfirm,
  onDecline,
  isLoading,
  data,
  isSaving,
  error,
}: Props & PopupUserActions): PopupViewExtended {
  return useMemo(
    () => ({
      onBack,
      onClose,
      render: (popupViewProps: PopupViewProps) => (
        <AdultPopup
          {...popupViewProps}
          onConfirm={onConfirm}
          onDecline={onDecline}
          isLoading={isLoading}
          data={data}
          isSaving={isSaving}
          error={error}
        />
      ),
    }),
    [onBack, onClose, onConfirm, onDecline, isLoading, data, isSaving, error],
  );
}
