import {getRemainingTime} from 'helpers/serverTime';
import {useCallback, useEffect, useState} from 'react';
import {setTimeoutSafe} from 'utils/setTimeoutSafe';

/**
 * Absolute time in milleseconds (non-negative integer).
 */
type Timestamp = number;

/**
 * Relative time in milliseconds (non-negative integer) since
 * the entity was loaded. Backend API usually sends relative
 * time depending on request time (loading time for clients).
 */
type TimeSinceLoadMs = number;

/**
 * Time in milliseconds (non-negative integer) till something.
 * This time is adjusted to client time (the main difference is
 * `TimeSinceLoadMs` is adjusted to entity load time).
 */
type RemainingTimeMs = number;

/**
 * Represents visibility state for the timer.
 * `true` means need to show timer, `false` otherwise.
 */
type IsTimerShown = boolean;

/**
 * Converts relative time difference to `Timestamp`.
 */
function getTimestamp(loadedTimeMs: Timestamp, sinceLoadMs: TimeSinceLoadMs): Timestamp {
  return loadedTimeMs + sinceLoadMs;
}

/**
 * Returns `true` when `RemainingTimeMs` is `0`.
 */
function useTimeHasCome(getRemainingTimeMs: () => RemainingTimeMs): boolean {
  const [timeHasCome, setTimeHasCome] = useState(() => getRemainingTimeMs() === 0);

  useEffect(() => {
    const remainingTimeMs = getRemainingTimeMs();

    if (!remainingTimeMs) {
      setTimeHasCome(true);
      return undefined;
    }

    setTimeHasCome(false);

    return setTimeoutSafe(() => setTimeHasCome(true), remainingTimeMs);
  }, [getRemainingTimeMs]);

  return timeHasCome;
}

/**
 * Returns visibility state of timer and `Timestamp` of timer expiration.
 */
export function useTimer(loadedTimeMs = 0, remainingMs = 0): [IsTimerShown, Timestamp] {
  const timestamp = getTimestamp(loadedTimeMs, remainingMs);
  const getExpiredRemainingTime = useCallback(() => getRemainingTime(timestamp), [timestamp]);

  const expired = useTimeHasCome(getExpiredRemainingTime);

  return [!expired, timestamp];
}
