import React, {FC} from 'react';

import {File, FileType} from './File';
import styles from './index.scss';

type Props = {
  files: FileType[];
  onRemove(file: FileType): void;
};

export const Files: FC<Props> = ({files, onRemove}: Props): JSX.Element => {
  return (
    <div className={styles.files}>
      {files.map((file) => (
        <div key={file.id} className={styles.file}>
          <File onClick={onRemove} file={file} />
        </div>
      ))}
    </div>
  );
};
