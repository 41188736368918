import {Locator} from 'components/Locator';
import {Button} from 'components/UIKit/Button';
import {Popup} from 'components/UIKit/Popup';
import {Header} from 'components/UIKit/Popup/Header';
import {PopupViewProps, PopupViewRender} from 'components/UIKit/Popup/PopupProvider';
import {PopupStack} from 'components/UIKit/Popup/PopupStack';
import {Switch} from 'components/UIKit/Switch';
import {A11yContext} from 'providers/A11yContext';
import React, {ChangeEvent, useCallback, useContext, useState} from 'react';
import {defineMessages, FormattedMessage, MessageDescriptor} from 'react-intl';

import styles from './styles.scss';

const messages = defineMessages({
  title: {
    description: 'Заголовок попапа',
    defaultMessage: 'Accessibility settings',
  },
  description: {
    description: 'Описание',
    defaultMessage:
      'To ensure that the website is accessible to everyone, including people with visual, hearing, cognitive and motor disabilities, you can optimise the settings to match your needs:',
  },
  apply: {
    description: 'Кнопка «Применить» на форме опций специальных возможностей',
    defaultMessage: 'Apply',
  },
  highContrastTitle: {
    description: 'High contrast setting title',
    defaultMessage: 'High contrast mode',
  },
  highContrastDescription: {
    description: 'High contrast setting description',
    defaultMessage:
      'This function helps people with eyesight issues. It replaces the button colours and fonts to make viewing them comfortable.',
  },
  disableAutoloadTitle: {
    description: 'Disable autoload setting title',
    defaultMessage: 'Disable automatic page loading',
  },
  disableAutoloadDescription: {
    description: 'Disable autoload setting description',
    defaultMessage:
      'Allows you to view the list of products page by page, by clicking the "Show more" button.',
  },
});

export type Props = {
  onToggle?(): void;
  onApply?(): void;
};

type SettingsItem = {
  id: string;
  text: {
    title: MessageDescriptor;
    description: MessageDescriptor;
  };
};

const settingsItems = [
  {
    id: 'highContrast',
    text: {
      title: messages.highContrastTitle,
      description: messages.highContrastDescription,
    },
  },
  {
    id: 'disableAutoload',
    text: {
      title: messages.disableAutoloadTitle,
      description: messages.disableAutoloadDescription,
    },
  },
] as const satisfies SettingsItem[];

export const A11ySettingsPopupBase: React.FC<Props & PopupViewProps> = ({
  onClose,
  onBack,
  onToggle,
  onApply,
}) => {
  const {settings, updateSettings} = useContext(A11yContext);

  const [intermediateState, setIntermediateState] = useState({...settings});

  const handleToggle = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const {name, checked} = event.target;

      setIntermediateState({
        ...intermediateState,
        [name]: checked,
      });

      onToggle?.();
    },
    [intermediateState, onToggle],
  );

  const handleApply = useCallback(() => {
    updateSettings(intermediateState);
    onApply?.();
    onClose?.();
  }, [intermediateState, onApply, onClose, updateSettings]);

  return (
    <Popup width="494px" testId="A11yPopup">
      <Header onClose={onClose} onBack={onBack}>
        <span className={styles.title}>
          <FormattedMessage {...messages.title} />
        </span>
      </Header>

      <div className={styles.popupContainer}>
        <p className={styles.description}>
          <FormattedMessage {...messages.description} />
        </p>

        <ul className={styles.settings}>
          {settingsItems.map((item) => (
            <li key={item.id} className={styles.settingsItem}>
              <div className={styles.settingsItemText}>
                <div className={styles.settingsItemTitle}>
                  <FormattedMessage {...item.text.title} />
                </div>
                <div className={styles.settingsItemDescription}>
                  <FormattedMessage {...item.text.description} />
                </div>
              </div>
              <Locator id="A11yPopupSwitch" type={item.id}>
                <Switch
                  checked={intermediateState[item.id]}
                  onToggle={handleToggle}
                  name={item.id}
                  id={item.id}
                />
              </Locator>
            </li>
          ))}
        </ul>

        <Locator id="A11ySubmitButton">
          <Button tag="button" onClick={handleApply}>
            <FormattedMessage {...messages.apply} />
          </Button>
        </Locator>
      </div>
    </Popup>
  );
};

export function useA11ySettingsPopupRender({onToggle, onApply}: Props): PopupViewRender {
  return useCallback(
    ({action, onClose, onBack}: PopupViewProps) => (
      <A11ySettingsPopupBase
        action={action}
        onClose={onClose}
        onBack={onBack}
        onToggle={onToggle}
        onApply={onApply}
      />
    ),
    [onApply, onToggle],
  );
}

export function A11ySettingsPopup(props: Props): React.ReactElement {
  const render = useA11ySettingsPopupRender(props);
  return <PopupStack render={render} />;
}
