import {Icon} from 'components/UIKit/Icon';
import {Scope} from 'config';
import {useLanguage} from 'hooks/useLanguage';
import {useScope} from 'hooks/useScope';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import {getUrl} from 'routes';

import {SplashMessage} from '../SplashMessage';
import styles from './styles.scss';

export function NotFound({noButton}: {noButton?: boolean}): JSX.Element {
  const lang = useLanguage();
  const scope = useScope();

  return (
    <SplashMessage asPage>
      {scope.is(Scope.GLOBAL) && (
        <SplashMessage.Image>
          <div className={styles.illustration}>
            <Icon type="illustration" name="3d-email" />
          </div>
        </SplashMessage.Image>
      )}
      <SplashMessage.Title>
        <FormattedMessage description="Header of PageNotFound block" defaultMessage="Oops..." />
      </SplashMessage.Title>
      <SplashMessage.Text>
        <FormattedMessage
          description="Text of PageNotFound block"
          defaultMessage="The page you are looking for does not exist"
        />
      </SplashMessage.Text>
      {!noButton && (
        <SplashMessage.Actions>
          <SplashMessage.Link to={getUrl('main', {lang, scope})}>
            <FormattedMessage
              description="Button caption of PageNotFound block"
              defaultMessage="Return to home page"
            />
          </SplashMessage.Link>
        </SplashMessage.Actions>
      )}
    </SplashMessage>
  );
}
