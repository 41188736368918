import {useDeviceVars} from 'hooks/useDeviceVars';
import {useMemo} from 'react';
import {useLocation} from 'react-router';

import {getProductStoreReviewsConfig} from './utils';

type UseProductStoreReviewsConfigRenderProps = ReturnType<typeof getProductStoreReviewsConfig>;

export const useProductStoreReviewsConfig = (): UseProductStoreReviewsConfigRenderProps => {
  const deviceVars = useDeviceVars();
  const location = useLocation();

  return useMemo(
    () => getProductStoreReviewsConfig({deviceVars, location}),
    [deviceVars, location],
  );
};
