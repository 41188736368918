import {useDeepLink} from 'hooks/useDeepLink';
import React, {useCallback} from 'react';
import {Link, LinkProps} from 'react-router-dom';
import {identity} from 'utils/function';

export interface DeepLinkProps extends LinkProps {
  to: string;
}

export const DeepLink = React.forwardRef(function DeepLink(
  {to, onClick, ...restProps}: DeepLinkProps,
  ref: React.Ref<HTMLAnchorElement>,
): React.ReactElement {
  const [url, deepLinkAction] = useDeepLink(to);

  const handlePreventClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      if (deepLinkAction) {
        e.preventDefault();
      }

      if (onClick) {
        onClick(e);
      }

      if (deepLinkAction) {
        deepLinkAction();
      }
    },
    [deepLinkAction, onClick],
  );

  return (
    <Link
      {...restProps}
      to={url || identity}
      innerRef={ref}
      onClick={deepLinkAction ? handlePreventClick : onClick}
    />
  );
});
