import React from 'react';
import {defineMessages, FormattedMessage, useIntl} from 'react-intl';

import styles from './index.scss';

type Props = React.PropsWithChildren<{
  count: number;
  index: number;
  onPrev(): void;
  onNext(): void;
  onClose(): void;
}>;

const messages = defineMessages({
  prev: {
    description: '[label] Подпись на кнопке «предыдущий слайд» для экранных читалок',
    defaultMessage: 'Previous slide',
  },
  next: {
    description: '[label] Подпись на кнопке «следующий слайд» для экранных читалок',
    defaultMessage: 'Next slide',
  },
  close: {
    description: '[label] Подпись на кнопке закрытия галереи для экранных читалок',
    defaultMessage: 'Close gallery',
  },
});

export function GalleryPopupChrome({
  children,
  count,
  index,
  onPrev,
  onNext,
  onClose,
}: Props): JSX.Element {
  const intl = useIntl();
  const showButtons = count > 1;

  return (
    <>
      <button
        type="button"
        className={styles.close}
        onClick={onClose}
        aria-label={intl.formatMessage(messages.close)}
        data-gallery-slider-action
      />
      <div className={styles.counter}>
        <FormattedMessage
          description="Image counter in gallery popup"
          defaultMessage="{index} of {count}"
          values={{index: index + 1, count}}
        />
      </div>
      {showButtons && (
        <button
          type="button"
          className={styles.prev}
          onClick={onPrev}
          aria-label={intl.formatMessage(messages.prev)}
          data-gallery-slider-action
        />
      )}
      {children}
      {showButtons && (
        <button
          type="button"
          className={styles.next}
          onClick={onNext}
          aria-label={intl.formatMessage(messages.next)}
          data-gallery-slider-action
        />
      )}
    </>
  );
}
