import classnames from 'classnames/bind';
import {Square} from 'components/Square';
import {useLanguage} from 'hooks/useLanguage';
import PropTypes from 'prop-types';
import React, {memo} from 'react';
import {MapCoordinates} from 'shapes/MapView';
import {createUrl} from 'utils/url';

import {useScope} from 'hooks/useScope';
import {getMapCenter} from './getMapCenter';
import styles from './index.scss';

const cn = classnames.bind(styles);

export const Map = memo(function Map({query, coordinates, square, rounded}) {
  const lang = useLanguage();
  const scope = useScope();
  const {latitude, longitude} = coordinates;

  const iframe = (
    <iframe
      allowFullScreen
      className={styles.iframe}
      src={createUrl('https://www.google.com/maps/embed/v1/place', {
        center: getMapCenter(latitude, longitude),
        q: query,
        zoom: '16',
        key: scope.integrations.googleMapsKey,
        language: lang,
      })}
      title="Map"
    />
  );

  return (
    <div className={cn(styles.map, {rounded, square})}>
      {square ? <Square>{iframe}</Square> : iframe}
    </div>
  );
});

Map.propTypes = {
  coordinates: MapCoordinates.isRequired,
  query: PropTypes.string,
  rounded: PropTypes.bool,
  square: PropTypes.bool,
};

Map.defaultProps = {
  query: '',
  rounded: false,
  square: false,
};
