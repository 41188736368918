import classnames from 'classnames/bind';
import HtmlContent from 'components/HtmlContent';
import {Image} from 'components/Image';
import {useModalOverlayLabelledById} from 'components/UIKit/ModalOverlay/context';
import {useScope} from 'hooks/useScope';
import React from 'react';
import {ContentItem} from 'types/Popup';
import {getColorStyles} from 'utils/styles/color';

import styles from '../index.scss';
import {attributeMapperWithAdditionalParameters} from '../utils';

const cn = classnames.bind(styles);

type Props = {
  title: NonNullable<ContentItem['title']>;
};

export const Title = ({title}: Props): JSX.Element => {
  const scope = useScope();
  const titleUid = useModalOverlayLabelledById();
  const horizontalAlignment = title.horizontalAlignment?.toString() || '';

  return (
    <div className={cn('titleContainer', {[horizontalAlignment]: Boolean(horizontalAlignment)})}>
      {title.icon ? (
        <Image block className={styles.titleIcon} contain image={title.icon} loadingHidden />
      ) : null}
      <HtmlContent
        className={styles.title}
        style={getColorStyles(title.textColor)}
        html={title.text}
        attributeMapper={attributeMapperWithAdditionalParameters(scope, {
          a: {style: getColorStyles(title.linkColor)},
        })}
        id={titleUid}
      />
    </div>
  );
};
