import React, {ComponentProps, ReactElement} from 'react';
import {CSSTransition} from 'react-transition-group';

import styles from './Sidebar.scss';

type Props = Partial<ComponentProps<typeof CSSTransition>>;

export function Animation(props: Props): ReactElement {
  const {children, ...rest} = props;
  return (
    <CSSTransition
      in
      classNames={styles}
      appear
      timeout={{appear: 300}}
      enter={false}
      exit={false}
      {...rest}
    >
      {children}
    </CSSTransition>
  );
}
