import classnames from 'classnames/bind';
import {Locator} from 'components/Locator';
import {useIntersectionObserverRef} from 'hooks/useIntersectionObserverRef';
import React, {ReactElement, useCallback, useRef, useState} from 'react';
import {defineMessages, useIntl} from 'react-intl';
import {supportsPositionSticky} from 'utils/styles';

import {PopupUserActions} from '../types';
import BackIcon from './back.jsx.svg';
import CloseIcon from './close.jsx.svg';
import styles from './StandaloneControls.scss';

const cn = classnames.bind(styles);

type Props = PopupUserActions & {
  background?: 'none' | 'always' | 'stuck' | 'mobile' | 'tablet' | 'white';
  sticky?: boolean;
};

const messages = defineMessages({
  back: {
    description: '[label] Вернуться назад',
    defaultMessage: 'Go back',
  },
  close: {
    description: '[label] Закрыть',
    defaultMessage: 'Close',
  },
});

export function StandaloneControls({
  onBack,
  onClose,
  background = 'stuck',
  sticky = true,
}: Props): ReactElement {
  const intl = useIntl();
  const ref = useRef<HTMLDivElement>(null);
  const [stuck, setStuck] = useState(false);

  const handleObserve = useCallback(
    (entry: IntersectionObserverEntry) => setStuck(!entry.isIntersecting),
    [],
  );

  useIntersectionObserverRef(handleObserve, {
    targetRef: ref,
    threshold: 1.0,
    rootMargin: '0px',
    disabled: !sticky || background !== 'stuck' || !supportsPositionSticky(),
  });

  const withBackground = background === 'always' || (background === 'stuck' && stuck);

  return (
    <div
      ref={ref}
      className={cn('standaloneControls', {
        sticky,
        withBackground,
        withMobileBackground: background === 'mobile',
        withTabletBackground: background === 'tablet',
        withWhiteBackground: background === 'white',
        hasBackButton: Boolean(onBack),
        hasCloseButton: Boolean(onClose),
      })}
    >
      {onBack ? (
        <button
          type="button"
          className={styles.back}
          onClick={onBack}
          aria-label={intl.formatMessage(messages.back)}
        >
          <BackIcon />
        </button>
      ) : (
        <span />
      )}
      {onClose ? (
        <Locator id="PopupCloseButton">
          <button
            type="button"
            className={styles.close}
            onClick={onClose}
            aria-label={intl.formatMessage(messages.close)}
          >
            <CloseIcon />
          </button>
        </Locator>
      ) : (
        <span />
      )}
    </div>
  );
}
