import classnames from 'classnames';
import {BottomSheet, BottomSheetHeader} from 'components/BottomSheet';
import {ContextMenu} from 'components/ContextMenu';
import {useIsBreakpointMobile} from 'hooks/useIsBreakpointMobile';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import useDropdownMenu from 'react-accessible-dropdown-menu-hook';
import {CSSTransition} from 'react-transition-group';

import type {Option} from '../Form/Select';
import styles from './index.scss';

type Props = {
  options: Option[];
  value: Option['id'];
  renderOption?: (option: Option, isSelected?: boolean) => JSX.Element;
  onChange: (value: Option['id']) => void;
  onOpen?: () => void;
  onClose?: () => void;
  anchorRef: React.RefObject<HTMLElement>;
  bottomSheetTitle?: JSX.Element;
  dropdownProps: Omit<ReturnType<typeof useDropdownMenu<HTMLButtonElement>>, 'buttonProps'>;
};

const DEFAULT_OPTION_RENDERER = ({name}: Option, isSelected?: boolean): JSX.Element => (
  <div className={classnames(styles.defaultOption, {[styles.selected!]: isSelected})}>{name}</div>
);

const BOTTOM_SHEET_APPEAR_TIMEOUT = 400;

// for some reason it's 16ms, as seen in components/CoolbeRegion, components/Currency, components/Language
const DROPDOWN_FOCUS_TIMEOUT = 16;

export const Picker = ({
  onChange,
  options,
  value,
  bottomSheetTitle,
  dropdownProps: {isOpen, setIsOpen, itemProps, moveFocus},
  anchorRef,
  renderOption = DEFAULT_OPTION_RENDERER,
}: Props): JSX.Element | null => {
  const isMobile = useIsBreakpointMobile('sm');
  const [appliedValue, setAppliedValue] = useState(value);
  const getHandleItemClick = useCallback(
    (option: Option) => () => {
      if (!isMobile) {
        onChange(option.id);
        setIsOpen(false);
      } else {
        setAppliedValue(option.id);
      }
    },
    [setIsOpen, onChange, isMobile, setAppliedValue],
  );

  const handleApply = useCallback(() => {
    onChange(appliedValue);
  }, [onChange, appliedValue]);

  const handleClose = useCallback(() => setIsOpen(false), [setIsOpen]);

  // this does two things.
  // first, it moves focus to currently selected item
  // second, it does it after Popper sets the position of the menu so the sidebar does not jump to the top, closing the menu
  // no, useLayoutEffect does nothing.
  // yes, this is madness.
  // See also:
  // - src/components/Currency/index.tsx
  // - src/components/CoolbeRegion/index.tsx
  // - src/components/Language/index.tsx
  useEffect(() => {
    if (isOpen) {
      const index = options?.findIndex((item) => item.id === value);

      if (typeof index === 'number' && index !== -1) {
        setTimeout(() => {
          moveFocus(index);
          // gnarly timeout magic
        }, DROPDOWN_FOCUS_TIMEOUT);
      }
    }
    // trigger only when opened
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    setAppliedValue(value);
  }, [value]);

  const bottomSheetRef = useRef(null);
  const listbox = (
    <div className={styles.listbox} role="menu">
      {options.map((option, idx) => {
        const isSelected = option.id === appliedValue;
        return (
          <button
            type="button"
            key={option.id}
            className={classnames(styles.option, {[styles.isSelected!]: isSelected})}
            {...itemProps[idx]}
            onClick={getHandleItemClick(option)}
          >
            {renderOption(option, isSelected)}
          </button>
        );
      })}
    </div>
  );

  if (isMobile) {
    return (
      <CSSTransition
        classNames={{
          enter: styles.bottomSheetEnter,
          enterActive: styles.bottomSheetEnterActive,
          exit: styles.bottomSheetExit,
          exitActive: styles.bottomSheetExitActive,
        }}
        in={isOpen}
        timeout={BOTTOM_SHEET_APPEAR_TIMEOUT}
        unmountOnExit
        mountOnEnter
        nodeRef={bottomSheetRef}
      >
        <BottomSheet onClose={handleClose} ref={bottomSheetRef} onApply={handleApply}>
          {bottomSheetTitle ? (
            <BottomSheetHeader alignment="start" onClose={handleClose}>
              <span className={styles.bottomSheetTitle}>{bottomSheetTitle}</span>
            </BottomSheetHeader>
          ) : null}
          {listbox}
        </BottomSheet>
      </CSSTransition>
    );
  }

  return (
    <ContextMenu referenceElement={anchorRef.current} hidden={!isOpen}>
      {listbox}
    </ContextMenu>
  );
};

export const usePicker = ({
  options,
  value,
  onChange,
  onOpen,
  onClose,
  renderOption,
  bottomSheetTitle,
}: Pick<
  Props,
  'options' | 'value' | 'onChange' | 'renderOption' | 'bottomSheetTitle' | 'onOpen' | 'onClose'
>): {
  pickerProps: Props;
  pickerElement: JSX.Element;
  buttonProps: ReturnType<typeof useDropdownMenu<HTMLButtonElement>>['buttonProps'];
} => {
  const {buttonProps, ...dropdownProps} = useDropdownMenu<HTMLButtonElement>(options.length, {
    disableFocusFirstItemOnClick: true,
    handleItemKeyboardSelect: (e) => {
      e.preventDefault();
      e.currentTarget.click();
    },
  });

  const {setIsOpen} = dropdownProps;

  const [opened, setOpened] = useState(false);

  useEffect(() => {
    if (dropdownProps.isOpen && !opened) {
      setOpened(true);
      onOpen?.();
    }

    if (!dropdownProps.isOpen && opened) {
      setOpened(false);
      onClose?.();
    }
  }, [dropdownProps.isOpen, setOpened, onOpen, onClose, opened]);

  const handleChange = useCallback(
    (option: Option['id']) => {
      onChange(option);
      setIsOpen(false);
    },
    [onChange, setIsOpen],
  );

  const pickerProps = {
    dropdownProps,
    value,
    options,
    anchorRef: buttonProps.ref,
    onChange: handleChange,
    renderOption: renderOption ?? DEFAULT_OPTION_RENDERER,
    bottomSheetTitle,
  };

  return {
    buttonProps,
    pickerProps,
    pickerElement: <Picker {...pickerProps} />,
  };
};
