import {isRecord, isString} from 'utils/guards';

import {UploaderError, UploaderErrorType} from '../types';
import {createUploaderError} from './createUploaderError';
import {isUploaderError} from './isUploaderError';

function getErrorMessage(error: unknown): string | undefined {
  if (error) {
    if (isRecord(error) && isString(error.messages)) {
      return error.message as string;
    }

    return String(error);
  }

  return undefined;
}

export function createUploaderErrorByError(error: unknown): UploaderError {
  if (isUploaderError(error)) {
    return error;
  }

  return createUploaderError(UploaderErrorType.UNKNOWN, {
    message: getErrorMessage(error),
  });
}
