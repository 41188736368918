import {Color} from './Color';
import {EventParams} from './EventParams';
import {Gradient, LinearGradient} from './Gradient';
import {ImageBundle} from './Image';
import {Text} from './Layout';
import {OfferDescription} from './Offers';

export enum BannerPlace {
  PRICE_DETAILS = 'priceDetails',
}

export type ActionBanner = {
  action: {
    title: string;
    text: string;
    action?: string;
    titleColor?: string;
    textColor?: string;
    actionColor?: string;
    borderColor?: Color;
    backgroundGradient?: Gradient;
    image?: ImageBundle;
    url?: string;
    eventParams?: EventParams;
    place?: BannerPlace;
  };
};

export type Banner = ActionBanner;

export enum OfferBannerState {
  APPLIED = 'applied',
  NOT_APPLIED = 'notApplied',
}

export type OfferBannerButton = {
  texts: {
    applied: Text;
    notApplied: Text;
  };
  backgrounds?: {
    applied: LinearGradient;
    notApplied: LinearGradient;
  };
};

export type OfferBanner = {
  background: LinearGradient;
  button?: OfferBannerButton;
  deeplink?: string;
  icon?: ImageBundle;
  offerDescription: OfferDescription;
  state: OfferBannerState;
  subtitle: Text;
  title: Text;
};
