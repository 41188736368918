import {Block} from 'components/ClientProduct/Block';
import {BrandHeader} from 'components/ClientProduct/BrandHeader';
import React from 'react';
import {ProductBrand as ProductBrandType} from 'types/ProductBrand';

type Props = {
  productBrand: ProductBrandType;
};

export function ProductBrand({productBrand}: Props): JSX.Element {
  return (
    <Block>
      <BrandHeader brand={productBrand} />
    </Block>
  );
}
