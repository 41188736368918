import {ReloadPopup} from 'components/ReloadPopup';
import {useSelector, useStore} from 'hooks/redux';
import {useHistoryBlock} from 'hooks/useHistoryBlock';
import React, {ReactElement, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {isVersionMismatch, isVersionMismatchCritical} from 'store/modules/version';
import {withOriginalStore} from 'store/withOriginalStore';

export const VersionMismatchController = withOriginalStore(
  function VersionMismatchController(): ReactElement | null {
    const store = useStore();
    const history = useHistory();
    const historyBlock = useHistoryBlock();

    const critical = useSelector(isVersionMismatchCritical);

    useEffect(() => {
      let navigationOccurred = false;

      const unblock = historyBlock((location, action) => {
        const mismatch = isVersionMismatch(store.getState());
        if (!mismatch) {
          return undefined;
        }
        // prevent cycle when use browser history
        if (navigationOccurred) {
          // prevent app navigation
          return false;
        }
        navigationOccurred = true;

        // eslint-disable-next-line default-case
        switch (action) {
          case 'PUSH':
          case 'REPLACE': {
            const {key, state} = location;
            const url = history.createHref(location);
            window.history[action === 'PUSH' ? 'pushState' : 'replaceState']({key, state}, '', url);
            window.location.reload();
            break;
          }
          case 'POP':
            window.location.reload();
            break;
        }

        // prevent app navigation
        return false;
      });

      return () => {
        unblock();
      };
    }, [history, historyBlock, store]);

    return critical ? <ReloadPopup /> : null;
  },
);
