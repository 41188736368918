import classnames from 'classnames/bind';
import {Image} from 'components/Image';
import {ImageProps} from 'components/Image/types';
import React, {memo} from 'react';

import styles from './index.scss';

const cn = classnames.bind(styles);

type Props = ImageProps & {
  large?: boolean;
  noBackground?: boolean;
  small?: boolean;
  useOutlineIconAsDefault?: boolean;
};

export const Avatar = memo(function Avatar(props: Props) {
  const {
    large = false,
    useOutlineIconAsDefault = false,
    noBackground = false,
    small = false,
    className,
    ...imageProps
  } = props;

  return (
    <Image
      {...imageProps}
      className={cn(styles.avatar, className, {
        large,
        outline: useOutlineIconAsDefault,
        noBackground,
        small,
      })}
      classNameBroken={styles.broken}
      classNameEmpty={styles.empty}
    />
  );
});
