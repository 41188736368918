import classNamesBind from 'classnames/bind';
import {createLocator, Locator, Mark} from 'create-locator';
import {useBot} from 'hooks/useBot';
import {useDeepLink} from 'hooks/useDeepLink';
import React, {forwardRef, Ref, useCallback, useMemo} from 'react';
import {Link} from 'react-router-dom';
import {createUrl, isExternalUrl} from 'utils/url';

import styles from './index.scss';

const cn = classNamesBind.bind(styles);

export type BannerWrapperLocator = Locator<
  void,
  {
    type: 'externalLink' | 'link' | 'deepLink' | 'wrapper';
  }
>;

type Props = {
  children: React.ReactNode;
  focusable?: boolean;
  onClick?: () => void;
  stretch?: boolean;
  to?: string;
  noflex?: boolean;
  source?: string;
  mobileFullWidth?: boolean;
  'data-testid'?: string;
} & Partial<Mark<BannerWrapperLocator>>;

export const BannerWrapper = forwardRef(function BannerWrapper(
  props: Props,
  ref?: Ref<HTMLElement>,
) {
  const {
    children,
    onClick,
    focusable = true,
    stretch = false,
    noflex = false,
    to,
    source,
    mobileFullWidth,
    'data-testid': testId,
  } = props;
  const locator = createLocator(props);
  const [rawUrl, deepLinkAction] = useDeepLink(to);
  const isBot = useBot();

  const url = useMemo(() => {
    // source param for ******* analytics WEB-5460
    if (!isBot && source && rawUrl?.includes('/promotions/')) {
      return createUrl(rawUrl, {source});
    }
    return rawUrl;
  }, [isBot, rawUrl, source]);

  const handleClick = useCallback(() => {
    if (onClick) {
      onClick();
    }

    if (!url && deepLinkAction) {
      deepLinkAction();
    }
  }, [onClick, url, deepLinkAction]);

  if (url) {
    if (isExternalUrl(url)) {
      return (
        <a
          ref={ref as Ref<HTMLAnchorElement>}
          className={cn('wrapper', 'clickable', {stretch, noflex, mobileFullWidth})}
          onClick={handleClick}
          tabIndex={focusable ? 0 : -1}
          href={url}
          data-testid={testId}
          target="_blank"
          rel="noopener noreferrer"
          {...locator({type: 'externalLink'})}
        >
          {children}
        </a>
      );
    }

    return (
      <Link
        ref={ref as Ref<HTMLAnchorElement>}
        className={cn('wrapper', 'clickable', {stretch, noflex, mobileFullWidth})}
        onClick={handleClick}
        tabIndex={focusable ? 0 : -1}
        to={url}
        data-testid={testId}
        {...locator({type: 'link'})}
      >
        {children}
      </Link>
    );
  }

  if (deepLinkAction) {
    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events
      <div
        className={cn('wrapper', 'clickable', {stretch, noflex, mobileFullWidth})}
        onClick={handleClick}
        role="button"
        tabIndex={0}
        ref={ref as Ref<HTMLDivElement>}
        data-testid={testId}
        {...locator({type: 'deepLink'})}
      >
        {children}
      </div>
    );
  }

  return (
    <div
      ref={ref as Ref<HTMLDivElement>}
      className={cn('wrapper', {stretch, mobileFullWidth})}
      data-testid={testId}
      {...locator({type: 'wrapper'})}
    >
      {children}
    </div>
  );
});
