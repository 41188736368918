import {enhanceProductsList} from 'store/enhancer/productsList';
import {requestActionCreator} from 'store/utils/requestActions';
import {ProductsListRaw} from 'types/ContentList/ContentListProductsList';
import {FuncAction} from 'typesafe-actions';

import {
  loadArbitraryContentList as loadArbitraryContentListAction,
  LoadArbitraryContentListRequest,
  LoadArbitraryContentListResponse,
} from './actions';

type ArbitraryLoadResponseRaw = {
  payload: ProductsListRaw;
};

export const loadArbitraryContentList = ({
  endpointPath,
  endpointParams,
  nextPageToken: pageToken,
  itemId,
}: LoadArbitraryContentListRequest): FuncAction<Promise<LoadArbitraryContentListResponse>> =>
  requestActionCreator(
    loadArbitraryContentListAction,
    {endpointPath, endpointParams, itemId, nextPageToken: pageToken},
    (store, client) =>
      client.api
        .post<ArbitraryLoadResponseRaw>(endpointPath, {
          body: {
            pageToken,
            params: endpointParams,
            productsListParams: endpointParams,
          },
        })
        .then(({language, currency, body: {payload}}) =>
          enhanceProductsList(payload, {
            contexts: payload.contexts,
            currency,
            language,
            pageToken,
          }),
        ),
  );
