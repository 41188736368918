import {ScopeConfig} from 'helpers/ApiClient/Scope/ScopeConfig';
import {deepLinks, isDeepLink} from 'helpers/deepLink';
import {DeepLinkAction} from 'types/DeepLinks';

export function convertHref(
  scope: ScopeConfig,
  lang: string,
  href: string,
): string | DeepLinkAction {
  if (isDeepLink(href)) {
    const link = deepLinks.match(href);
    if (link) {
      return link.reverse(scope, lang);
    }
  }
  return href;
}
