import React from 'react';
import {objectMap} from 'utils/object';
import {useStore} from 'hooks/redux';

export default function connectDuck(creators = {}, getters = {}) {
  return (InnerComponent) => {
    function DuckConnector(props) {
      const store = useStore();
      const {dispatch, getState} = store;
      const wrappedCreators = objectMap(
        creators,
        (creator) =>
          (...args) =>
            dispatch(creator(...args)),
      );
      const wrappedGetters = objectMap(
        getters,
        (getter) =>
          (...args) =>
            getter(getState(), ...args),
      );
      return <InnerComponent {...props} {...wrappedGetters} {...wrappedCreators} />;
    }

    return DuckConnector;
  };
}
