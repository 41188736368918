import cn from 'classnames';
import {AnyLink} from 'components/AnyLink';
import {Locator} from 'components/Locator';
import React from 'react';
import {Row as RowType} from 'types/ContentList/Row';
import {getInlineColor} from 'utils/styles/color';

import styles from './index.scss';

type Props = {
  content: RowType;
  id?: string;
};

export const Row = React.memo(function Row({
  content: {title, isBoldTitle, titleCounter, titleColor, url},
}: Props): React.ReactElement {
  return (
    <AnyLink to={url} className={styles.link}>
      <Locator id="ContentListRow">
        <div
          className={cn(styles.row, {[styles.bold!]: isBoldTitle})}
          style={getInlineColor(titleColor)}
        >
          {title} {titleCounter && <span className={styles.counter}>{titleCounter}</span>}
        </div>
      </Locator>
    </AnyLink>
  );
});
