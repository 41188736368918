import ClientApi from '@joomcode/joom-event-types';
import classnames from 'classnames';
import {FormattedCount} from 'components/FormattedCount';
import MessageFilled from 'components/icons/messageFilled.jsx.svg';
import StarFilled from 'components/icons/starFilled.jsx.svg';
import UserFilled from 'components/icons/userFilled.jsx.svg';
import {Image} from 'components/Image';
import {Price} from 'components/Price';
import {StoreLogo} from 'components/StoreLogo';
import {eventParamsList} from 'helpers/eventParams';
import {useContextSeed} from 'hooks/useContextSeed';
import {useLanguage} from 'hooks/useLanguage';
import {useLinkTarget} from 'hooks/useLinkTarget';
import {usePreviewEvent} from 'hooks/usePreviewEvent';
import {useScope} from 'hooks/useScope';
import React, {FunctionComponent, useCallback, useMemo, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import {getUrl} from 'routes';
import {PowerShop} from 'types/ContentList/PowerShop';
import {createUrl} from 'utils/url';

import styles from './index.scss';

type Props = {
  powerShop: PowerShop;
  source?: 'stores' | 'search';
  size?: 'small' | 'medium';
  href?: string;
};

export const PowerShopCard: FunctionComponent<Props> = ({
  powerShop,
  source = 'stores',
  size = 'medium',
  href,
}) => {
  const {name, rating, reviewsCount, favoritesCount, image, flag, id, context, eventParams} =
    powerShop?.productsList?.headerAppearance?.store?.store || {};

  const products = powerShop?.productsList?.items
    ?.slice(0, 3)
    .map(({content: {product}}) => product);

  const lang = useLanguage();
  const scope = useScope();

  const [interactionEnabled, setInteractionEnabled] = useState(false);
  const handleInteraction = useCallback(() => {
    if (context) {
      setInteractionEnabled(true);
    }
  }, [context]);

  const contextSeed = useContextSeed();

  const link = useMemo(() => {
    const linkWithoutContext = href || getUrl('store', {scope, lang, storeId: id});

    if (context && interactionEnabled) {
      return createUrl(linkWithoutContext, {
        context: JSON.stringify(context),
        contextSeed,
      });
    }

    return linkWithoutContext;
  }, [href, id, context, scope, contextSeed, lang, interactionEnabled]);

  const linkTarget = useLinkTarget('_blank');

  const rootRef = useRef(null);

  usePreviewEvent(
    {
      rootRef,
      previewEvent: {
        type: 'storePreview',
        payload: {
          storeId: id,
          source,
          ...(context ? {context} : null),
        },
        params: eventParamsList(eventParams),
      } as ClientApi.AnalyticsEventStorePreview,
    },
    {
      clickEvent: {
        type: 'storeClick',
        payload: {
          storeId: id,
          source,
        },
        params: eventParamsList(eventParams),
      },
    },
  );

  const isSmall = size === 'small';

  return (
    <Link
      to={link}
      className={styles.link}
      ref={rootRef}
      target={linkTarget}
      onMouseDown={handleInteraction}
      onTouchStart={handleInteraction}
    >
      <article className={classnames(styles.root, {[styles.rootSmall!]: isSmall})} key={id}>
        <div className={styles.infoDrawer}>
          <div className={classnames(styles.avatarWrapper, image && styles.withImage)}>
            <StoreLogo name={name} image={image} size="inherit" circle />
          </div>
          <div className={styles.captions}>
            <h1 className={styles.name}>{name}</h1>
            <div className={styles.counts}>
              {rating ? (
                <span className={styles.count}>
                  <StarFilled className={styles.countIcon} />
                  <span className={styles.countText}>{rating.toFixed(1)}</span>
                </span>
              ) : null}
              {reviewsCount ? (
                <span className={styles.count}>
                  <MessageFilled className={styles.countIcon} />
                  <span className={styles.countText}>
                    <FormattedCount count={{...reviewsCount}} isCompact={isSmall} />
                  </span>
                </span>
              ) : null}
              {favoritesCount ? (
                <span className={styles.count}>
                  <UserFilled className={styles.countIcon} />
                  <FormattedCount count={{...favoritesCount}} isCompact={isSmall} />
                </span>
              ) : null}
            </div>
          </div>
          <Image image={flag} className={styles.flag} />
        </div>
        <div className={styles.productsGrid}>
          {products.map(({mainImage, price, id: productId}, index) => (
            <div className={styles.product} key={productId}>
              {price !== undefined ? (
                <span className={styles.price}>
                  <Price noSeoCurrency noSeoLabel noText money={price} />
                </span>
              ) : null}
              <Image
                image={mainImage}
                className={styles.productImage}
                lcpImageCandidate={index === 0}
              />
            </div>
          ))}
        </div>
      </article>
    </Link>
  );
};
