import cn from 'classnames';
import {BannerWrapper} from 'components/ContentList/BannerWrapper';
import {Image} from 'components/Image';
import {Locator} from 'components/Locator';
import React, {forwardRef, Ref} from 'react';
import {SideBannerGroupBanner} from 'types/ContentList/SideBannerGroup';
import {isColorLight} from 'utils/colors';

import {getTextInlineStyle} from '../utils';
import styles from './index.scss';

type Props = {
  banner: SideBannerGroupBanner;
  source?: string;
};

export const OneLineContent = forwardRef(function OneLineContent(
  {banner, source}: Props,
  ref?: Ref<HTMLElement>,
): React.ReactElement {
  const {button, image, subtitle, title, payload, background} = banner;
  const isLightBackground =
    background &&
    'gradient' in background &&
    isColorLight(background.gradient.colorEnd || background.gradient.colorStart);
  const inlineStyle = getTextInlineStyle(banner);
  const url = (button && button.url) || (payload && payload.url) || '';

  return (
    <Locator id="OneLineContentBanner">
      <div className={styles.oneLineContent}>
        <Locator id="OneLineContentBannerWrapper">
          <BannerWrapper ref={ref} stretch to={url} source={source}>
            <div className={styles.inner}>
              <div className={cn(styles.textWrap, isLightBackground && styles.lightBackground)}>
                {title && (
                  <Locator id="OneLineContentBannerTitle">
                    <div className={styles.title} style={inlineStyle.title}>
                      {title.text}
                    </div>
                  </Locator>
                )}
                {subtitle && (
                  <Locator id="OneLineContentBannerSubtitle">
                    <div className={styles.subtitle} style={inlineStyle.subtitle}>
                      {subtitle.text}
                    </div>
                  </Locator>
                )}
              </div>
              <div className={styles.imageWrap}>
                <Image className={styles.image} contain image={image} loadingHidden />
              </div>
            </div>
          </BannerWrapper>
        </Locator>
      </div>
    </Locator>
  );
});
