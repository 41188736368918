import PropTypes from 'prop-types';
import React, {useCallback, useMemo} from 'react';
import {Link} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import classnames from 'classnames/bind';
import {useLanguage} from 'hooks/useLanguage';
import {useAnalytics} from 'hooks/useAnalytics';
import {useDeviceVars} from 'hooks/useDeviceVars';
import {
  getWarrantyUrl,
  getShippingUrl,
  SHIPPING_ID,
  WARRANTY_ID,
  getCoolbeWarrantyUrl,
  COOLBE_WARRANTY_ID,
} from 'components/Faq/urls';
import {JoomProLinkMessage} from 'messages/JoomProLink';
import {JoomLogisticsLinkMessage} from 'messages/JoomLogisticsLink';
import {Switcher as SnowSwitcher} from 'components/Snow/Switcher/Header';
import {BadgeButton} from 'components/Header/BadgeButton';
import {Locator} from 'components/Locator/Locator';
import {useScope} from 'hooks/useScope';
import {getUrl} from 'routes';
import {Scope} from 'config';
import styles from './index.scss';

const cn = classnames.bind(styles);

export const NavBar = React.memo(function NavBar({
  renderCoupon,
  renderCurrency,
  renderLanguage,
  renderRegion,
}) {
  const scope = useScope();
  const lang = useLanguage();
  const analytics = useAnalytics();
  const {
    joomProLink,
    joomLogisticsLink,
    snowFlakes2023: {on: snowEnabled = false} = {},
    webScopes,
    webShowJoomGeekLinkOnJoom,
  } = useDeviceVars();
  const geekPrefixConfig = scope.availablePrefixScopes?.find(
    (config) => config.scope === Scope.GEEK,
  );
  const showGeekLink = Boolean(
    webShowJoomGeekLinkOnJoom && geekPrefixConfig && webScopes?.includes(Scope.GEEK),
  );

  const handleLinkClickCreator = useCallback(
    (eventType) => () => {
      analytics.sendEvent({
        type: eventType,
        payload: {from: 'header'},
      });
    },
    [analytics],
  );

  const handleSupportLinkClick = useMemo(
    () => handleLinkClickCreator('supportLinkClick'),
    [handleLinkClickCreator],
  );
  const handleDeliveryLinkClick = useMemo(
    () => handleLinkClickCreator('deliveryLinkClick'),
    [handleLinkClickCreator],
  );
  const handleWarrantyLinkClick = useMemo(
    () => handleLinkClickCreator('warrantyLinkClick'),
    [handleLinkClickCreator],
  );

  const languageEl = renderLanguage({from: 'header'});
  const currencyEl = renderCurrency({from: 'header'});
  const regionEl = renderRegion({from: 'header'});
  const coupon = renderCoupon();

  return (
    <div className={styles.navBarContainer}>
      <div className={styles.navBar}>
        <div className={styles.leftSection}>
          {languageEl ? <div className={styles.navBarItem}>{languageEl}</div> : null}
          {currencyEl ? <div className={styles.navBarItem}>{currencyEl}</div> : null}
          {regionEl ? <div className={styles.navBarItem}>{regionEl}</div> : null}
          {snowEnabled ? (
            <div className={styles.navBarItem}>
              <SnowSwitcher />
            </div>
          ) : null}
        </div>
        <div className={styles.rightSection}>
          <nav className={styles.navBarLinks}>
            <Locator id="NavBarSupportLink">
              <Link
                to={getUrl('faq', {scope, lang})}
                onClick={handleSupportLinkClick}
                className={cn('navBarItem', 'navBarLink')}
              >
                <FormattedMessage
                  defaultMessage="Help сentre"
                  description="Navbar support link text"
                />
              </Link>
            </Locator>
            <Locator id="NavBarDeliveryLink" article-id={SHIPPING_ID}>
              <Link
                to={getShippingUrl(scope, lang)}
                onClick={handleDeliveryLinkClick}
                className={cn('navBarItem', 'navBarLink')}
              >
                <FormattedMessage
                  defaultMessage="Delivery"
                  description="Navbar delivery link text"
                />
              </Link>
            </Locator>
            {scope.isDomain(Scope.COOLBE) ? (
              <Locator id="NavBarWarrantyLink" article-id={COOLBE_WARRANTY_ID}>
                <Link
                  to={getCoolbeWarrantyUrl(scope, lang)}
                  onClick={handleWarrantyLinkClick}
                  className={cn('navBarItem', 'navBarLink')}
                >
                  <FormattedMessage
                    defaultMessage="Refund Policy & Warranty"
                    description={
                      'Navbar warranty link text\nOriginal EN message:\nRefund Policy & Warranty'
                    }
                  />
                </Link>
              </Locator>
            ) : (
              <Locator id="NavBarWarrantyLink" article-id={WARRANTY_ID}>
                <Link
                  to={getWarrantyUrl(scope, lang)}
                  onClick={handleWarrantyLinkClick}
                  className={cn('navBarItem', 'navBarLink')}
                >
                  <FormattedMessage
                    defaultMessage="Warranty"
                    description="Navbar warranty link text"
                  />
                </Link>
              </Locator>
            )}
            {scope.notJmt && joomProLink ? (
              <Locator id="NavBarJoomProLink">
                {/* eslint-disable-next-line react/jsx-no-target-blank */}
                <a
                  className={cn('navBarItem', 'navBarLink')}
                  target="_blank"
                  rel="noopener"
                  href={joomProLink}
                >
                  <JoomProLinkMessage />
                </a>
              </Locator>
            ) : null}
            {scope.notJmt && joomLogisticsLink ? (
              <Locator id="NavBarJoomLogisticsLink">
                {/* eslint-disable-next-line react/jsx-no-target-blank */}
                <a
                  className={cn('navBarItem', 'navBarLink')}
                  target="_blank"
                  rel="noopener"
                  href={joomLogisticsLink}
                >
                  <JoomLogisticsLinkMessage />
                </a>
              </Locator>
            ) : null}
          </nav>
        </div>
        {showGeekLink && geekPrefixConfig ? (
          <a
            href={`/${geekPrefixConfig.pathPrefix}/${lang}`}
            target="_blank"
            className={styles.badge}
            rel="noreferrer"
          >
            <BadgeButton>{geekPrefixConfig.companyInfo.companyName}</BadgeButton>
          </a>
        ) : null}
        {coupon ? <div className={styles.badge}>{coupon}</div> : null}
      </div>
    </div>
  );
});

NavBar.propTypes = {
  renderCoupon: PropTypes.func.isRequired,
  renderCurrency: PropTypes.func.isRequired,
  renderLanguage: PropTypes.func.isRequired,
  renderRegion: PropTypes.func.isRequired,
};
