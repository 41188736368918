import {useRefEffect} from 'hooks/useRefEffect';
import {RefObject, useRef} from 'react';

import {useSliderContext} from './useSliderContext';

export function useItem<T extends HTMLElement>(
  index: number,
): [RefObject<T>, {'data-slide': number}] {
  const {itemsRef, observer} = useSliderContext();
  const ref = useRef<T>(null);

  useRefEffect(
    () => {
      const items = itemsRef.current;
      const element = ref.current;
      if (items && element) {
        const nextItem = {
          element,
          index,
        };

        items.push(nextItem);

        return () => {
          const indexToDelete = items.indexOf(nextItem);

          if (indexToDelete >= 0) {
            items.splice(indexToDelete, 1);
          }
        };
      }
      return undefined;
    },
    [ref],
    [index],
  );

  useRefEffect(
    () => {
      const element = ref.current;
      if (observer && element) {
        observer.observe(element);
        return () => observer.unobserve(element);
      }
      return undefined;
    },
    [ref],
    [observer, index],
  );

  return [ref, {'data-slide': index}];
}
