import {checkNever} from 'utils/guards';

import {MediaStatus, MediaType, UploaderErrorType, UploaderOptions} from '../types';
import {createUploaderError} from './createUploaderError';
import {stopVideoUploading} from './stopVideoUploading';

export const stopMediaUploading = async (
  mediaUuid: string,
  uploaderOptions: UploaderOptions,
): Promise<void> => {
  const {mediaStatesMap} = uploaderOptions.getState();
  let mediaState = mediaStatesMap[mediaUuid];

  if (!mediaState) {
    throw createUploaderError(UploaderErrorType.INTERNAL_ERROR, {
      message: `Attempt to validate a non-existent media state (mediaUuid: ${mediaUuid})`,
    });
  }

  if (mediaState.status !== MediaStatus.WILL_BE_DELETED) {
    return;
  }

  mediaState = uploaderOptions.commit(mediaUuid, {
    ...mediaState,
    status: MediaStatus.IN_PROGRESS_OF_DELETION,
  });

  switch (mediaState.type) {
    case MediaType.IMAGE:
      break;

    case MediaType.VIDEO:
      await stopVideoUploading(mediaUuid, mediaState, uploaderOptions);
      break;

    default:
      checkNever(mediaState);
  }
};
