import {Point} from 'types/Point';

export const EPSILON = 0.001;

export function clamp(value: number, min: number, max: number): number {
  return Math.min(Math.max(value, min), max);
}

export function getPercentByValue(value: number, min: number, max: number): number {
  return (value - min) / (max - min);
}

export function getValueByPercent(percent: number, min: number, max: number): number {
  return min + percent * (max - min);
}

export function getLog10PercentByValue(value: number, min: number, max: number): number {
  return (Math.log10(value) - Math.log10(min)) / (Math.log10(max) - Math.log10(min));
}

export function getLog10ValueByPercent(percent: number, min: number, max: number): number {
  return 10 ** (Math.log10(min) + percent * (Math.log10(max) - Math.log10(min)));
}

export function angleToRadians(angle: number): number {
  return (angle * Math.PI) / 180;
}

export function angleDx(degrees: number, radius = 1): number {
  return radius * Math.cos(angleToRadians(degrees));
}

export function angleDy(degrees: number, radius = 1): number {
  return radius * Math.sin(angleToRadians(degrees));
}

export function getAngleBetweenPoints(p1: Point, p2: Point): number {
  return (Math.atan2(p2.y - p1.y, p2.x - p1.x) * 180) / Math.PI;
}

export function getDistanceBetweenPoints(p1: Point, p2: Point): number {
  return Math.sqrt((p1.x - p2.x) ** 2 + (p1.y - p2.y) ** 2);
}

export const areApproximatelyEqual = (a: number, b: number, epsilon: number = EPSILON): boolean => {
  return Math.abs(a - b) <= epsilon;
};
