import {useModalOverlayLabelledById} from 'components/UIKit/ModalOverlay/context';
import {Popup} from 'components/UIKit/Popup';
import {Header} from 'components/UIKit/Popup/Header';
import {PopupUserActions} from 'components/UIKit/Popup/types';
import {usePopupManager} from 'hooks/usePopupManager';
import React, {useCallback} from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';

import {Form, FormRequestData, Props as FormProps} from '../Form';
import styles from './index.scss';
import {SuccessPopup} from './SuccessPopup';

const messages = defineMessages({
  header: {
    description: '[header] Заголовок формы поддержки',
    defaultMessage: 'Send us a request',
  },
});

export type Props = FormProps & PopupUserActions;

export const FormPopup = ({
  onBack,
  onClose,
  onSubmit,
  metadata,
  ...restProps
}: Props): JSX.Element => {
  const popupManager = usePopupManager();

  const handleSubmit = useCallback(
    (data: Omit<FormRequestData, 'metadata'>) => {
      const result = onSubmit({...data, metadata});
      result.then(() =>
        popupManager.open(({action, onBack, onClose}) => (
          <SuccessPopup action={action} email={data.email} onBack={onBack} onClose={onClose} />
        )),
      );
      return result;
    },
    [onSubmit, popupManager, metadata],
  );

  const headerUid = useModalOverlayLabelledById();

  return (
    <Popup width="750px" testId="Support-popup">
      <Header onBack={onBack} onClose={onClose}>
        <span id={headerUid}>
          <FormattedMessage {...messages.header} />
        </span>
      </Header>
      <div className={styles.content}>
        <Form onSubmit={handleSubmit} {...restProps} />
      </div>
    </Popup>
  );
};
