import PropTypes from 'prop-types';
import React, {memo} from 'react';
import classnames from 'classnames/bind';
import Button from 'components/Button';
import styles from './Order.scss';

const cn = classnames.bind(styles);

export const ReviewButton = memo(function ReviewButton({children, onClick}) {
  return (
    <>
      <div className={cn('button', 'desktopButton')}>
        <Button color="darkblue" onClick={onClick} padding="wide">
          {children}
        </Button>
      </div>
      <div className={cn('button', 'mobileButton')}>
        <Button block color="lightgrey" onClick={onClick} padding="wide">
          <span className={styles.highlightedButtonText}>{children}</span>
        </Button>
      </div>
    </>
  );
});

ReviewButton.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
};
