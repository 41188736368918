import PropTypes from 'prop-types';
import ImageBundle from './ImageBundle';

export default PropTypes.shape({
  description: PropTypes.string,
  forAdults: PropTypes.bool,
  hasPublicChildren: PropTypes.bool,
  id: PropTypes.string.isRequired,
  mainImage: ImageBundle,
  name: PropTypes.string.isRequired,
  parentId: PropTypes.string,
  root: PropTypes.bool,
});

export const RootCategory = PropTypes.shape({
  root: PropTypes.bool,
});
