import classnames from 'classnames/bind';
import {Icon} from 'components/UIKit/Icon';
import {type Locator, type Mark, createLocator} from 'create-locator';
import React from 'react';
import {PointsCashbackBadge as BadgeType} from 'types/ProductBadge';

import styles from './index.scss';

const cn = classnames.bind(styles);

export type PointsCashbackBadgeLocator = Locator<void>;

type Props = {badge: BadgeType} & Mark<PointsCashbackBadgeLocator>;

export const PointsCashbackBadge = React.memo(function DiscountBadge(props: Props) {
  const {badge} = props;
  const locator = createLocator(props);

  return (
    <div className={cn('badge', 'pointsCashbackBadge')} {...locator()}>
      <span className={styles.pointsCashbackBadgeIcon}>
        <Icon type="mono" name="joom-24" />
      </span>
      <span className={styles.pointsCashbackBadgeText}>{badge.pointsCashback.title}</span>
    </div>
  );
});
