import {AppLink} from 'components/AppLink';
import {useScope} from 'hooks/useScope';
import React from 'react';
import {appGalleryUrl} from 'utils/url/app';

import {Badge} from '../Badge';
import styles from './index.scss';
import {useBadgeHandler} from './useBadgeHandler';

type Props = {
  deepUrl: string;
  eventCategory: string;
  small?: boolean;
};

export function AppGalleryBadge({
  deepUrl,
  eventCategory,
  small = false,
}: Props): JSX.Element | null {
  const handleAppGallery = useBadgeHandler(
    deepUrl,
    eventCategory,
    'Huawei App Gallery Badge. Click',
  );
  const scope = useScope();
  const appUrl = appGalleryUrl(scope);

  if (!appUrl) {
    return null;
  }

  return (
    <AppLink className={styles.link} href={appUrl} onClick={handleAppGallery} role="button">
      <Badge className={styles.badge} small={small} type="huawei" />
    </AppLink>
  );
}
