import {useEffectOnce} from 'hooks/useEffectOnce';
import {useState} from 'react';
import {memoize} from 'utils/memoize';

export const getIsPWA = memoize(
  () =>
    __CLIENT__ &&
    (Boolean((navigator as {standalone?: unknown}).standalone) ||
      window.matchMedia('(display-mode: standalone)').matches),
);

export const getLinkTarget = (target: '_blank' | string | undefined): string | undefined =>
  getIsPWA() ? undefined : target;

export function useLinkTarget(target: '_blank' | string | undefined): string | undefined {
  const [isPWA, setIsPWA] = useState(false);

  // set isPWA after first render to prevent hydration errors
  useEffectOnce(() => {
    setIsPWA(getIsPWA());
  });

  if (isPWA || !target) {
    return undefined;
  }

  return target;
}
