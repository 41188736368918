export type VideoThumbnailGenerationOptions = {
  maxSideSize?: number;
  seekTo?: number;
};

export const createVideoThumbnail = async (
  file: File,
  {maxSideSize, seekTo = 0}: VideoThumbnailGenerationOptions,
): Promise<string> => {
  const videoUrl = URL.createObjectURL(file);

  return new Promise<string>((resolve, reject) => {
    const video = document.createElement('video');

    video.src = videoUrl;

    video.addEventListener('error', (error) => {
      URL.revokeObjectURL(videoUrl);
      reject(error);
    });

    video.addEventListener('loadedmetadata', () => {
      // delay seeking or else 'seeked' event won't fire on Safari
      setTimeout(() => {
        video.currentTime = video.duration >= seekTo ? seekTo : Math.floor(video.duration / 2);
      }, 200);

      video.addEventListener('seeked', () => {
        const canvas = document.createElement('canvas');
        const actualMaxSizeSize = Math.max(video.videoWidth, video.videoHeight);
        const sideSizeMultiplier = (maxSideSize ?? actualMaxSizeSize) / actualMaxSizeSize;
        const thumbnailWidth = video.videoWidth * sideSizeMultiplier;
        const thumbnailHeight = video.videoHeight * sideSizeMultiplier;

        canvas.width = thumbnailWidth;
        canvas.height = thumbnailHeight;

        canvas.getContext('2d')?.drawImage(video, 0, 0, thumbnailWidth, thumbnailHeight);

        URL.revokeObjectURL(videoUrl);
        resolve(canvas.toDataURL('image/jpeg'));
      });
    });

    video.load();
  });
};
