import classnames from 'classnames/bind';
import React from 'react';

import styles from './index.scss';

const cn = classnames.bind(styles);

export function VisuallyHidden(
  props: Omit<React.HTMLAttributes<HTMLDivElement>, 'className'>,
): JSX.Element {
  return <div {...props} className={cn('visuallyHidden')} />;
}
