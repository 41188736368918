import {requestActionCreator} from 'store/utils/requestActions';
import {Fields} from 'types/AddressMetainfo';
import {PersonalDataFormOrigin} from 'types/PersonalData';
import {TransportMethod} from 'types/Transport';
import {FuncAction} from 'typesafe-actions';

import {
  deletePersonalData as deletePersonalDataAction,
  DeletePersonalDataResponse,
  fetchFieldValue as fetchFieldValueAction,
  FetchFieldValueResponse,
} from './actions';

type FetchFieldValueResponseBody = {
  payload: FetchFieldValueResponse;
};

export function fetchFieldValue(
  fieldName: string,
  fields: Fields,
  origin: PersonalDataFormOrigin,
): FuncAction {
  return requestActionCreator(
    fetchFieldValueAction,
    {fieldName, fields, origin},
    async (_, client) => {
      const fetchValueBody = {field: fieldName, fields, origin};
      const fetchValueResponse = await client.secureApi.post<FetchFieldValueResponseBody>(
        '/personalData/form/fetchValue',
        {
          body: fetchValueBody,
        },
      );
      let fetchValuePayload = fetchValueResponse.body.payload;

      while ('performRequest' in fetchValuePayload) {
        const {request: thirdPartyRequest, state: requestState} = fetchValuePayload.performRequest;
        // eslint-disable-next-line no-await-in-loop
        const thirdPartyResponse = await client.pureApi.request(
          thirdPartyRequest.method.toLowerCase() as TransportMethod,
          thirdPartyRequest.url,
          {
            body: thirdPartyRequest.body,
            headers: thirdPartyRequest.headers,
          },
        );

        // eslint-disable-next-line no-await-in-loop
        const interpretResponse = await client.secureApi.post<FetchFieldValueResponseBody>(
          '/personalData/form/fetchValue/interpretResponse',
          {
            body: {
              fetchValue: fetchValueBody,
              response: {
                body: JSON.stringify(thirdPartyResponse.body),
                headers: thirdPartyResponse.headers,
                statusCode: thirdPartyResponse.status,
              },
              state: requestState,
            },
          },
        );

        fetchValuePayload = interpretResponse.body.payload;
      }

      return fetchValuePayload;
    },
  );
}

export function deletePersonalData(origin: PersonalDataFormOrigin): FuncAction {
  return requestActionCreator(deletePersonalDataAction, {origin}, (_, client) =>
    client.secureApi
      .post<DeletePersonalDataResponse>('/personalData/delete', {
        body: {origin},
      })
      .then(() => undefined),
  );
}
