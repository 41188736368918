import findLastIndex from 'lodash/findLastIndex';
import replace from 'lodash/replace';

export enum SearchInputActionSource {
  RECENT = 'recent',
  SUGGEST = 'suggestion',
  MANUAL = 'manual',
}

type SearchInputAction = {
  sinceStart: number;
  source: SearchInputActionSource;
  text: string;
};

const searchInputActions: SearchInputAction[] = [];

export function updateSearchInputActions(action: SearchInputAction): void {
  if (action.source === SearchInputActionSource.MANUAL) {
    const index = findLastIndex(searchInputActions, {source: SearchInputActionSource.MANUAL});

    if (index >= 0) {
      searchInputActions.splice(index, 1, action);
    } else {
      searchInputActions.push(action);
    }
  } else {
    searchInputActions.push(action);
  }
}

export function discardSearchInputActions(): void {
  searchInputActions.splice(0, searchInputActions.length);
}

export {searchInputActions};

export function getTypedValue(value: string, initialValue: string): string {
  return replace(value, initialValue, '');
}
