import {POPUPS_PRIORITY, PopupViewRender} from 'components/UIKit/Popup/PopupProvider';
import {JmtMigrationPopup} from 'containers/WelcomePopups/JmtMigrationPopupController/JmtMigrationPopup';
import {JmtMigrationSuccessPopup} from 'containers/WelcomePopups/JmtMigrationPopupController/JmtMigrationSuccessPopup';
import {useApiClient} from 'hooks/useApiClient';
import {useLocationQuery} from 'hooks/useLocationQuery';
import {useWelcomePopup} from 'hooks/useWelcomePopup';
import React, {useMemo} from 'react';
import {createUrl} from 'utils/url';

export function JmtMigrationPopupController(): null {
  const client = useApiClient();

  const {jmtMigrationError, jmtMigrationSuccess, ...query} = useLocationQuery();

  const canShowMigrationPopup = useMemo(
    () => client.jmtMigration.canShowMigrationPopup(),
    [client.jmtMigration],
  );

  const render = useMemo((): PopupViewRender | null => {
    if (client.scope.notJmt) {
      return null;
    }

    if (jmtMigrationSuccess) {
      return ({onClose}) => <JmtMigrationSuccessPopup onClose={onClose} />;
    }

    if (jmtMigrationError || canShowMigrationPopup) {
      return ({onClose}) => (
        <JmtMigrationPopup onClose={onClose} errorView={Boolean(jmtMigrationError)} />
      );
    }

    return null;
  }, [canShowMigrationPopup, client.scope.notJmt, jmtMigrationError, jmtMigrationSuccess]);

  useWelcomePopup(
    render
      ? {
          render,
          withoutOverlay: true,
          priority: POPUPS_PRIORITY.JMT_MIGRATION_POPUP,
          onClose: () => {
            window.history.replaceState(
              window.history.state,
              '',
              createUrl(window.location.pathname, query),
            );
          },
        }
      : null,
  );

  return null;
}
