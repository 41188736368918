import classNamesBind from 'classnames/bind';
import {BannerWrapper} from 'components/ContentList/BannerWrapper';
import {useBannerPreview} from 'components/ContentList/hooks';
import {Image} from 'components/Image';
import {Locator} from 'components/Locator';
import React, {CSSProperties, useRef} from 'react';
import {BannersListBannerAppearance, BannersListItem} from 'types/ContentList/BannersList';
import {HorizontalPosition, VerticalPosition} from 'types/ContentList/common';
import {convertBackendColorToCSSValue} from 'utils/styles/color';

import {Background} from '../../../Background';
import {isOldStyle, isTextColorLight} from '../../utils';
import styles from './index.scss';

type Props = {
  bannerAppearance: BannersListBannerAppearance;
  item: BannersListItem;
  selected: boolean;
  source?: string;
};

const cn = classNamesBind.bind(styles);

const imageVwFit = {
  xs: '132px',
};

const backgroundVwFit = {
  xs: '100vw',
  sm: '50vw',
  md: '33vw',
  xl: '420px',
};

export const Item = React.memo(function Item({
  bannerAppearance: {showSubtitle, showTitle},
  item,
  selected,
  source,
}: Props): JSX.Element {
  const {appearance, eventParams} = item;
  const textWrapStyle: CSSProperties = {};
  const imageWrapStyle: CSSProperties = {};
  const itemRef = useRef(null);
  const lightColor = isTextColorLight(item);
  const oldStyle = isOldStyle(item);
  let reverseContent = false;

  useBannerPreview({
    bannerId: item.id,
    eventParams,
    rootRef: itemRef,
    source,
  });

  let hasTextBackgroundColor;

  if (appearance) {
    if (appearance.textBackgroundColor) {
      hasTextBackgroundColor = true;
      const backgroundColor = convertBackendColorToCSSValue(appearance.textBackgroundColor);
      textWrapStyle.backgroundColor = backgroundColor;
      imageWrapStyle.backgroundColor = backgroundColor;
    }

    if (appearance.horTextPosition === HorizontalPosition.START) {
      textWrapStyle.textAlign = 'left';
    } else if (appearance.horTextPosition === HorizontalPosition.END) {
      textWrapStyle.textAlign = 'right';
    }

    if (appearance.vertTextPosition === VerticalPosition.BOTTOM) {
      reverseContent = true;
    }
  }

  const paddableBackgroundBanner = hasTextBackgroundColor && !item.icon && item.background;

  return (
    <Locator id="BannersListThreeInRowBanner">
      <BannerWrapper focusable={selected} ref={itemRef} to={item.payload.url} source={source}>
        <div className={cn('item', {oldStyle})}>
          <Locator id="BannersListThreeInRowBannerBackground">
            {oldStyle && !paddableBackgroundBanner ? (
              <div className={styles.backgroundWrap}>
                <Background image={item.background} />
              </div>
            ) : (
              <Background gradient={item.gradient} />
            )}
          </Locator>
          <div
            className={cn('content', {
              reverseContent,
              noPadding: paddableBackgroundBanner,
            })}
          >
            <div
              className={cn('textWrap', {
                lightColor,
              })}
              style={textWrapStyle}
            >
              <Locator id="BannersListThreeInRowBannerTitle">
                {showTitle && <div className={styles.title}>{item.title}</div>}
              </Locator>
              {Boolean(showSubtitle && item.subtitle) && (
                <Locator id="BannersListThreeInRowBannerSubtitle">
                  <div className={styles.subtitle}>{item.subtitle}</div>
                </Locator>
              )}
            </div>
            {(!oldStyle || item.icon) && !paddableBackgroundBanner && (
              <>
                <div className={styles.spacer} />
                <Locator id="BannersListThreeInRowBannerImage">
                  <div className={styles.imageWrap}>
                    <Image
                      block
                      className={styles.image}
                      contain
                      transparentLoading
                      image={item.icon || item.background}
                      pxFit={132}
                      vwFit={imageVwFit}
                      lcpImageCandidate
                    />
                  </div>
                </Locator>
              </>
            )}
            {paddableBackgroundBanner ? (
              <Locator id="BannersListThreeInRowBannerBackground">
                <div
                  className={cn('backgroundWrap', {underText: hasTextBackgroundColor})}
                  style={imageWrapStyle}
                >
                  <Background
                    image={item.background}
                    objectPosition={!reverseContent ? 'top center' : 'bottom center'}
                    vwFit={backgroundVwFit}
                  />
                </div>
              </Locator>
            ) : null}
          </div>
        </div>
      </BannerWrapper>
    </Locator>
  );
});
