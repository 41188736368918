import ErrorMessage from 'components/ErrorMessage';
import Attach from 'components/icons/attach.jsx.svg';
import {Locator} from 'components/Locator';
import {Overlay} from 'components/Overlay';
import {Button} from 'components/UIKit/Button';
import {Icon, Text} from 'components/UIKit/Button/Content';
import {Row} from 'components/UIKit/Form';
import {Files} from 'components/UIKit/Form/Files';
import {FileType} from 'components/UIKit/Form/Files/File';
import {
  addFiles,
  removeFile,
  setFileError,
  setFileFields,
  useFiles,
} from 'components/UIKit/Form/Files/useFiles';
import {createApiError} from 'helpers/ApiClient/Transport/Response';
import {useIdSeed} from 'hooks/useIdSeed';
import {useMountedRef} from 'hooks/useMounted';
import React, {FC, SyntheticEvent, useCallback, useRef, useState} from 'react';
import {defineMessages, FormattedMessage, IntlShape, useIntl} from 'react-intl';
import {SendFormRequest} from 'store/modules/faq/actions';
import {ProgressEvent} from 'superagent';
import {ApiError, Error} from 'types/Error';
import {SupportParamsMetadata} from 'types/FaqFormData';
import {OrderLite} from 'types/OrderLite';
import {User} from 'types/User';
import {guid} from 'utils/guid';

import styles from './index.scss';
import {Orders} from './Orders';

const FILES_MAX = 10;
const FILE_MAX_SIZE_MB = 25;

const messages = defineMessages({
  name: {
    description: '[label] Форма обратной связи в саппорте',
    defaultMessage: 'Your name',
  },
  email: {
    description: '[label] Форма обратной связи в саппорте',
    defaultMessage: 'Your email address',
  },
  message: {
    description: '[label] Форма обратной связи в саппорте',
    defaultMessage: 'How can we help you?',
  },
  order: {
    description: '[label] Форма обратной связи в саппорте',
    defaultMessage: 'Order',
  },
  attach: {
    description: '[button] Форма обратной связи в саппорте',
    defaultMessage: 'Add files',
  },
  send: {
    description: '[button] Форма обратной связи в саппорте',
    defaultMessage: 'Submit',
  },
  nameRequiredError: {
    description: 'Текст ошибки в форме обратной связи в саппорте',
    defaultMessage: 'Please enter your name',
  },
  fileSizeError: {
    description: 'Текст ошибки в форме обратной связи в саппорте',
    defaultMessage: 'The size exceeds {size} Mb',
  },
  emailRequiredError: {
    description: 'Текст ошибки в форме обратной связи в саппорте',
    defaultMessage: 'Please enter your email',
  },
  maxFilesError: {
    description: 'Текст ошибки в форме обратной связи в саппорте',
    defaultMessage: `You cannot add more than {max, plural,
  one {# file}
  other {# files}
}`,
  },
  messageRequiredError: {
    description: 'Текст ошибки в форме обратной связи в саппорте',
    defaultMessage: 'How can we help you?',
  },
  uploadingError: {
    description: 'Текст ошибки в форме обратной связи в саппорте',
    defaultMessage: 'Some attachments failed to upload',
  },
});

export type FormRequestData = SendFormRequest;

export type Props = {
  onFileUpload: (
    id: string,
    file: File,
    onProgress?: (event: ProgressEvent) => unknown,
  ) => Promise<{uploadId: string}>;
  onFileRemove?: (id: string) => unknown;
  onSubmit: (data: FormRequestData) => Promise<unknown>;
  orders?: OrderLite[];
  user?: User;
  defaultMessage?: string;
  metadata?: SupportParamsMetadata;
};

type FormElements = HTMLFormControlsCollection & {
  name: HTMLInputElement;
  email: HTMLInputElement;
  message: HTMLTextAreaElement;
};

function validationError(
  intl: IntlShape,
  key: keyof typeof messages,
  values?: Record<string, string | number>,
): ApiError {
  return createApiError('form.validation', 400, {
    userMessage: intl.formatMessage(messages[key], values),
  });
}

export const Form: FC<Props> = function Form({
  onSubmit,
  onFileUpload,
  onFileRemove,
  orders,
  user,
  defaultMessage,
}: Props): JSX.Element {
  const intl = useIntl();
  const mountedRef = useMountedRef();
  const formRef = useRef<HTMLFormElement>(null);
  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState<Record<string, Error>>({});
  const [files, setFiles] = useFiles([]);

  const handleAttach = useCallback(
    (evt: SyntheticEvent<HTMLInputElement>): void => {
      const input = evt.target as HTMLInputElement;
      const {files: inputFiles} = input;

      if (submitting) {
        input.value = '';
        return;
      }

      if (!inputFiles || inputFiles.length === 0) {
        return;
      }

      const length = Math.min(inputFiles.length, Math.max(FILES_MAX - files.length, 0));
      if (!length) {
        setErrors((data) => ({
          ...data,
          global: validationError(intl, 'maxFilesError', {max: FILES_MAX}),
        }));
        return;
      }

      const result: FileType[] = [];
      for (let index = 0; index < length; index += 1) {
        const file = inputFiles[index]!;
        const id = guid();
        const isCorrrectFileSize = file.size <= FILE_MAX_SIZE_MB * 1024 * 1024;

        result.push({
          id,
          name: file.name,
          uploadingProgress: 0,
          error: isCorrrectFileSize
            ? undefined
            : createApiError('babylone.client_error', 400, {
                userMessage: intl.formatMessage(messages.fileSizeError, {
                  size: FILE_MAX_SIZE_MB,
                }),
              }),
        });

        if (isCorrrectFileSize) {
          onFileUpload(id, file, (progress: ProgressEvent) => {
            const uploadingProgress = Math.min((progress.percent || 0) / 100, 0.99);
            setFileFields(setFiles, id, {uploadingProgress});
          })
            .then(({uploadId}) =>
              mountedRef.current
                ? setFileFields(setFiles, id, {
                    uploadingProgress: 1,
                    uploadId,
                  })
                : null,
            )
            .catch((error) => setFileError(setFiles, id, error));
        }
      }

      input.value = '';

      addFiles(setFiles, result);
    },
    [submitting, files.length, setFiles, intl, onFileUpload, mountedRef],
  );

  const handleDetach = useCallback(
    (file: FileType): void => {
      if (onFileRemove) {
        onFileRemove(file.id);
      }
      removeFile(setFiles, file.id);
    },
    [onFileRemove, setFiles],
  );

  const handleSubmit = useCallback(
    (evt: SyntheticEvent<HTMLFormElement>): void => {
      evt.preventDefault();
      if (submitting) {
        return;
      }

      const elements = evt.currentTarget.elements as FormElements;
      const name = elements.name.value;
      const email = elements.email.value;
      const message = elements.message.value;
      const newErrors: Record<string, Error> = {};

      if (!name) {
        newErrors.name = validationError(intl, 'nameRequiredError');
      }

      if (!email) {
        newErrors.email = validationError(intl, 'emailRequiredError');
      }

      if (!message) {
        newErrors.message = validationError(intl, 'messageRequiredError');
      }

      if (files.some((file) => !file.uploadId)) {
        newErrors.global = validationError(intl, 'uploadingError');
      }

      if (Object.keys(newErrors).length) {
        setErrors(newErrors);

        (
          Array.from(elements).filter(
            (el) => ['INPUT', 'TEXTAREA'].includes(el.tagName) && !(el as HTMLInputElement).value,
          )[0] as HTMLInputElement
        ).focus();

        return;
      }

      setSubmitting(true);

      const data: FormRequestData = {
        name,
        email,
        message,
        friendlyOrderIds: orders ? orders.map(({friendlyId}) => friendlyId) : [],
        fileIds: files.map(({uploadId}) => uploadId as string),
      };

      onSubmit(data)
        .then(() => {
          setErrors({});
        })
        .catch((error) => {
          setErrors({global: error});
        })
        .finally(() => (mountedRef.current ? setSubmitting(false) : null));
    },
    [submitting, files, onSubmit, orders, intl, mountedRef],
  );

  const uidSeed = useIdSeed();

  return (
    <Locator id="Support-popup-form">
      <form className={styles.form} onSubmit={handleSubmit} ref={formRef}>
        <Overlay loading={submitting}>
          <Row
            htmlFor="name"
            name={<FormattedMessage {...messages.name} />}
            error={errors.name}
            errorUid={uidSeed('name')}
          >
            <Locator id="Support-popup-form-name">
              <input
                className={styles.input}
                name="name"
                id="name"
                defaultValue={user?.fullName}
                aria-describedby={uidSeed('name')}
              />
            </Locator>
          </Row>
          <Row
            htmlFor="email"
            name={<FormattedMessage {...messages.email} />}
            error={errors.email}
            errorUid={uidSeed('email')}
          >
            <Locator id="Support-popup-form-email">
              <input
                className={styles.input}
                name="email"
                id="email"
                defaultValue={user?.email}
                aria-describedby={uidSeed('email')}
              />
            </Locator>
          </Row>
          {orders?.length ? (
            <Row
              name={<FormattedMessage {...messages.order} />}
              error={errors.email}
              errorUid={uidSeed('orders')}
            >
              <Orders orders={orders} />
            </Row>
          ) : null}
          <Row
            htmlFor="message"
            name={<FormattedMessage {...messages.message} />}
            error={errors.message}
            errorUid={uidSeed('message')}
          >
            <Locator id="Support-popup-form-message">
              <textarea
                className={styles.textarea}
                rows={4}
                name="message"
                id="message"
                defaultValue={defaultMessage}
                aria-describedby={uidSeed('message')}
              />
            </Locator>
            <Files files={files} onRemove={handleDetach} />
          </Row>
          {errors.global ? (
            <div className={styles.error}>
              <ErrorMessage error={errors.global} internal noRequestId />
            </div>
          ) : null}
          <div className={styles.controls}>
            <div className={styles.control}>
              <Button
                tag="label"
                htmlFor="attachFile"
                color="gray"
                size={{xs: 'medium', md: 'large'}}
              >
                <Icon>
                  <Attach />
                </Icon>
                <Text nowrap>
                  <FormattedMessage {...messages.attach} />
                </Text>
                <Locator id="Support-popup-form-file">
                  <input
                    onChange={handleAttach}
                    className={styles.file}
                    type="file"
                    name="file"
                    id="attachFile"
                    multiple
                  />
                </Locator>
              </Button>
            </div>
            <div className={styles.control}>
              <Locator id="Support-popup-form-submit-button">
                <Button
                  disabled={submitting}
                  tag="button"
                  type="submit"
                  color="accent"
                  size={{xs: 'medium', md: 'large'}}
                >
                  <Text nowrap>
                    <FormattedMessage {...messages.send} />
                  </Text>
                </Button>
              </Locator>
            </div>
          </div>
        </Overlay>
      </form>
    </Locator>
  );
};
