import {useColorStyles} from 'hooks/useColorStyles';
import React from 'react';
import {DiscountBadge} from 'types/ProductBadge';

import styles from '../index.scss';

type Props = {
  badge: DiscountBadge<'diagonal'>;
};

export function DiscountDiagonalBadge({badge: {discount}}: Props): JSX.Element {
  const diagonalStyles = useColorStyles({
    color: discount.titleColor,
    background: discount.background,
  });
  return (
    <div className={styles.ribbon}>
      <div style={diagonalStyles} className={styles.ribbonInner}>
        <div className={styles.ribbonText}>{discount.title}</div>
      </div>
    </div>
  );
}
