import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, {useRef} from 'react';
import {CSSTransition} from 'react-transition-group';
import styles from './Success.scss';

const cn = classnames.bind(styles);

const Success = React.memo(function Success({success}) {
  const ref = useRef(null);

  return (
    <CSSTransition
      classNames={styles}
      in={success}
      timeout={{enter: 700}}
      mountOnEnter
      unmountOnExit
      nodeRef={ref}
    >
      <div ref={ref} className={cn('success')}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          viewBox="0 0 20 20"
          preserveAspectRatio="xMidYMid meet"
        >
          <path
            className={cn('check')}
            fill="none"
            stroke="currentColor"
            strokeMiterlimit="10"
            strokeWidth="1.5"
            d="M5.21 9.87l3.26 3.26 6.25-6.26"
          />
          <path
            className={cn('lines')}
            fill="none"
            stroke="currentColor"
            strokeMiterlimit="10"
            strokeWidth=".3"
            d="M6.36 16.48l-1.53 2.64M6.05 3.65L4.53 1.01M9.97 16.95V20M16.8 6.44l2.64-1.52M3.69 14.01l-2.64 1.53M3.69 6.32L1.05 4.79M9.97 3.05V0M3.05 9.84H0M13.76 3.65l1.53-2.64M16.86 9.84h3.04M15.98 13.41l2.64 1.53M13.21 16.05l1.52 2.64"
          />
        </svg>
      </div>
    </CSSTransition>
  );
});

Success.propTypes = {
  success: PropTypes.bool,
};

Success.defaultProps = {
  success: false,
};

export default Success;
