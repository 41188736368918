import cn from 'classnames';
import {Locator} from 'components/Locator';
import {useTrustpilotAnalytics} from 'components/Trustpilot/useTrustpilotAnalytics';
import {useDeviceVars} from 'hooks/useDeviceVars';
import React from 'react';

import {TRUSTPILOT_CONFIG_DEFAULT} from '../consts';
import {TrustpilotWidget} from '../TrustpilotWidget';
import {TrustpilotConfig} from '../types';
import styles from './index.scss';

type Props = {
  config?: TrustpilotConfig;
  withBackground?: boolean;
};

export function TrustpilotBadge({
  config = TRUSTPILOT_CONFIG_DEFAULT,
  withBackground = true,
}: Props): JSX.Element {
  const {trustpilot = {}} = useDeviceVars();
  const {onClick, ref} = useTrustpilotAnalytics();

  return (
    <Locator id="TrustPilotOnFooter">
      <div className={cn(styles.root, withBackground && styles.withBackground)}>
        <TrustpilotWidget
          templateId={trustpilot.templateId}
          businessUnitId={trustpilot.businessUnitId}
          config={config}
          onClick={onClick}
          ref={ref}
        />
      </div>
    </Locator>
  );
}
