import PropTypes from 'prop-types';
import ProductLiteShape from './ProductLite';
import PromotionShape from './Promotion';

const ProductItemShape = PropTypes.shape({
  product: ProductLiteShape,
});

const PromotionsCarouselItemShape = PropTypes.shape({
  promotionsCarousel: PropTypes.shape({
    promotions: PropTypes.arrayOf(PromotionShape.isRequired).isRequired,
  }).isRequired,
});

const SearchFiltersItemShape = PropTypes.shape({});

export const ContentListItemShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([
    ProductItemShape,
    PromotionsCarouselItemShape,
    SearchFiltersItemShape,
  ]),
  pdid: PropTypes.string, // `enhancePageDependedId` for duplicates
});
