import {FormPopup as FormPopupUI} from 'components/Faq/FormPopup';
import {PopupView} from 'components/UIKit/Popup/PopupProvider';
import React, {useMemo} from 'react';
import {connect} from 'react-redux';
import {getUser} from 'store/modules/auth/getUser';
import {cancelFaqFileUploading, sendFaqForm, uploadFaqFile} from 'store/modules/faq';
import {RootState} from 'store/rootReducer';
import {SupportParamsMetadata} from 'types/FaqFormData';
import {OrderLite} from 'types/OrderLite';

const mapStateToProps = (globalState: RootState) => ({
  user: getUser(globalState) || undefined,
});

const mapDispatchToProps = {
  onSubmit: sendFaqForm,
  onFileUpload: uploadFaqFile,
  onFileRemove: cancelFaqFileUploading,
};

export const FormPopup = connect(mapStateToProps, mapDispatchToProps)(FormPopupUI);

type Props =
  | {
      orders?: OrderLite[];
      metadata?: SupportParamsMetadata;
    }
  | undefined;

export function createFormPopupView({
  orders,
  priority,
  metadata,
}: Props & {priority?: number} = {}): PopupView {
  return {
    priority,
    render: (popupViewProps) => (
      <FormPopup {...popupViewProps} orders={orders} metadata={metadata} />
    ),
  };
}

export function useFormPopupView({orders}: Props = {}): PopupView {
  return useMemo(() => createFormPopupView({orders}), [orders]);
}
