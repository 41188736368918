import {Locator} from 'components/Locator';
import {useDeviceVars} from 'hooks/useDeviceVars';
import {useScope} from 'hooks/useScope';
import {JoomLogisticsLinkMessage} from 'messages/JoomLogisticsLink';
import {JoomProLinkMessage} from 'messages/JoomProLink';
import React, {useMemo} from 'react';
import {FormattedMessage} from 'react-intl';

import {FooterColumn} from '../FooterColumn';

export function FooterColumnPartners(): JSX.Element {
  const scope = useScope();
  const {joomProLink, joomLogisticsLink} = useDeviceVars();

  const partnersColumnTitle = (
    <FormattedMessage description="'For partners' header in footer" defaultMessage="Partners" />
  );

  const merchantLink = useMemo(() => {
    const domain = scope.hostname.replace('www.', '');
    return `https://merchant.${domain}`;
  }, [scope.hostname]);

  return (
    <FooterColumn title={partnersColumnTitle}>
      <Locator id="FooterLinkMerchant">
        <FooterColumn.Item to={`${merchantLink}/`}>
          <FormattedMessage
            description="Merchant link in footer"
            defaultMessage="Become a seller"
          />
        </FooterColumn.Item>
      </Locator>
      <Locator id="FooterLinkTermsInMerchantBlock">
        <FooterColumn.Item to={`${merchantLink}/terms`}>
          <FormattedMessage
            description="Merchant terms link in footer"
            defaultMessage="Terms of service"
          />
        </FooterColumn.Item>
      </Locator>
      {joomLogisticsLink ? (
        <Locator id="FooterLinkJL">
          <FooterColumn.Item to={joomLogisticsLink} hasReferrer>
            <JoomLogisticsLinkMessage />
          </FooterColumn.Item>
        </Locator>
      ) : null}
      {joomProLink ? (
        <Locator id="FooterLinkJoomPro">
          <FooterColumn.Item to={joomProLink} hasReferrer>
            <JoomProLinkMessage />
          </FooterColumn.Item>
        </Locator>
      ) : null}
    </FooterColumn>
  );
}
