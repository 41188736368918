import cn from 'classnames';
import {Locator} from 'components/Locator';
import React from 'react';
import {SideBannerGroupBanner} from 'types/ContentList/SideBannerGroup';
import {isColorLight} from 'utils/colors';

import {getTextInlineStyle} from '../utils';
import styles from './index.scss';

type Props = {
  banner: SideBannerGroupBanner;
};

export function Text({banner}: Props): React.ReactElement {
  const {subtitle, title, background} = banner;
  const isLightBackground =
    background &&
    'gradient' in background &&
    isColorLight(background.gradient.colorEnd || background.gradient.colorStart);
  const inlineStyle = getTextInlineStyle(banner);

  return (
    <div className={cn(styles.textWrap, isLightBackground && styles.lightBackground)}>
      {title && (
        <Locator id="TwoLinesContentBannerTitle">
          <div className={styles.title} style={inlineStyle.title}>
            {title.text}
          </div>
        </Locator>
      )}
      {subtitle && (
        <Locator id="TwoLinesContentBannerSubtitle">
          <div className={styles.subtitle} style={inlineStyle.subtitle}>
            {subtitle.text}
          </div>
        </Locator>
      )}
    </div>
  );
}
