import ClientApi from '@joomcode/joom-event-types';
import classnames from 'classnames';
import {Description} from 'components/ClientProduct/Store/Description/Description';
import {FullDescriptionButton} from 'components/ClientProduct/Store/Description/FullDescriptionButton';
import styles from 'components/ClientProduct/Store/index.scss';
import {Image} from 'components/Image';
import {Locator} from 'components/Locator';
import {Skeleton} from 'components/Skeleton';
import {Stars} from 'components/Stars';
import {StarsTiny} from 'components/StarsTiny';
import {StoreLogo} from 'components/StoreLogo';
import {Icon} from 'components/UIKit/Icon';
import {Scope} from 'config';
import {eventParamsList} from 'helpers/eventParams';
import {useApiClient} from 'hooks/useApiClient';
import {useContextSeed} from 'hooks/useContextSeed';
import {useLayoutEffectOnce} from 'hooks/useEffectOnce';
import {useIntersectionObserverRef} from 'hooks/useIntersectionObserverRef';
import {useLanguage} from 'hooks/useLanguage';
import {useMatchMediaBreakpointDown} from 'hooks/useMatchMedia';
import {usePreviewEvent} from 'hooks/usePreviewEvent';
import {useScope} from 'hooks/useScope';
import {debounce} from 'lodash';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {defineMessages, useIntl} from 'react-intl';
import {Link} from 'react-router-dom';
import {getUrl} from 'routes';
import {ProductStoreWithReviews} from 'types/ProductNext/Header';
import {getSocialPostText, SocialPost} from 'types/SocialPost';
import {isNumber} from 'utils/guards';
import {convertBackendColorToCSSValue} from 'utils/styles/color';
import {createUrl} from 'utils/url';

const messages = defineMessages({
  products: {
    description: 'Количество товаров в блоке "Магазин" на странице товара',
    defaultMessage: `{ value, plural, one {# product} other {{value} products} }`,
  },
  orders: {
    description: 'Количество заказов в блоке "Магазин" на странице товара',
    defaultMessage: `{ value, plural, one {# product sold} other {{value} products sold} }`,
  },
  storeReviews: {
    description: 'Отзывы в блоке "Магазин" на странице товара',
    defaultMessage: 'Store reviews',
  },
});

type Props = {
  store: ProductStoreWithReviews;
  productId: string;
};

const END_SCROLL_SHADOW_OFFSET = 4;

const StoreReview = ({review}: {review: SocialPost}): React.ReactElement => {
  const [hasEndScrollShadow, setHasEndScrollShadow] = useState(false);

  const reviewBodyRef = useRef<HTMLDivElement>(null);
  const reviewBodyContentRef = useRef<HTMLDivElement>(null);
  const [isScrollable, setIsScrollable] = useState(false);

  useLayoutEffectOnce(() => {
    if (reviewBodyRef.current) {
      setHasEndScrollShadow(
        reviewBodyRef.current.scrollTop + reviewBodyRef.current.offsetHeight <
          reviewBodyRef.current.scrollHeight,
      );
    }
  });

  useIntersectionObserverRef(
    (entry) => {
      setIsScrollable(entry.intersectionRatio !== 1);
    },
    {
      rootRef: reviewBodyRef,
      targetRef: reviewBodyContentRef,
    },
  );

  const handleBodyScroll = debounce(
    useCallback(() => {
      if (reviewBodyRef.current) {
        setHasEndScrollShadow(
          reviewBodyRef.current.scrollTop + reviewBodyRef.current.offsetHeight <
            reviewBodyRef.current.scrollHeight - END_SCROLL_SHADOW_OFFSET,
        );
      }
    }, [setHasEndScrollShadow]),
    10,
  );

  useEffect(() => {
    if (!isScrollable) {
      return undefined;
    }

    const reviewBodyEl = reviewBodyRef.current;

    if (reviewBodyEl) {
      reviewBodyEl.addEventListener('scroll', handleBodyScroll);
    }

    return () => {
      if (reviewBodyEl) {
        reviewBodyEl.removeEventListener('scroll', handleBodyScroll);
      }
    };
  }, [isScrollable, handleBodyScroll]);

  return (
    <div className={styles.storeReviewItem}>
      <div className={styles.storeReviewItemHeader}>
        <div className={styles.storeReviewAuthor}>{review.author.name}</div>
        {review.starRating ? (
          <div className={styles.storeReviewRating}>
            <Stars rating={review.starRating} />
          </div>
        ) : null}
      </div>
      <div
        className={classnames(styles.storeReviewItemShadowWrapper, {
          [styles.isScrollable!]: isScrollable,
          [styles.endShadow!]: isScrollable && hasEndScrollShadow,
        })}
      >
        <div className={classnames(styles.storeReviewItemBody)} ref={reviewBodyRef}>
          <div ref={reviewBodyContentRef}>{getSocialPostText(review)}</div>
        </div>
      </div>
    </div>
  );
};

export const Store = React.memo(function Store({store, productId}: Props): React.ReactElement {
  const {id, context} = store;
  const intl = useIntl();

  const lang = useLanguage();
  const client = useApiClient();
  const scope = useScope();
  const rootRef = useRef(null);
  const isMobile = Boolean(useMatchMediaBreakpointDown('sm')?.matches);
  const user = client.device.getUser();
  const hasLink = Boolean(scope.is(Scope.GLOBAL) || user?.admin);

  // Analytics
  usePreviewEvent(
    {
      rootRef,
      previewEvent: {
        type: 'storePreview',
        payload: {
          storeId: store.id,
          source: 'product',
          ...(context ? {context} : null),
        },
        params: eventParamsList(store.eventParams),
      } as ClientApi.AnalyticsEventStorePreview,
    },
    {
      clickEvent: {
        type: 'storeClick',
        payload: {
          storeId: store.id,
          source: 'product',
        },
        params: eventParamsList(store.eventParams),
      },
    },
  );

  // Link generation
  const contextSeed = useContextSeed();
  const [interactionEnabled, setInteractionEnabled] = useState(false);
  const handleInteraction = useCallback(() => {
    if (context) {
      setInteractionEnabled(true);
    }
  }, [context]);
  const link = useMemo(() => {
    const linkWithoutContext = getUrl('store', {lang, scope, storeId: id});

    if (context && interactionEnabled) {
      return createUrl(linkWithoutContext, {
        context: JSON.stringify(context),
        contextSeed,
      });
    }

    return linkWithoutContext;
  }, [id, scope, context, contextSeed, lang, interactionEnabled]);

  const storeCard = (
    <>
      <Locator id="StoreLogoOnProductPage">
        <div className={styles.logo}>
          <StoreLogo circle name={store.name} image={store.image} size="fontSize" />
        </div>
      </Locator>
      <Locator id="StoreNameOnProductPage">
        <div className={styles.name}>
          <div className={styles.nameText}>{store.name}</div>
          {store.verified && (
            <Locator id="StoreVerifiedCheckMark">
              <div className={styles.nameVerifiedMark} />
            </Locator>
          )}
        </div>
      </Locator>
      {store.description && !store.fullDescriptionButton && (
        <div className={styles.description}>
          <Description block description={store.description} />
        </div>
      )}
      <div className={styles.badges}>
        {isNumber(store.rating) && (
          <div className={classnames(styles.badge, styles.badgeRating)}>
            <Locator id="StoreStarsdOnProductPage">
              <StarsTiny
                rating={store.rating}
                reviewsCount={isMobile || store.reviews?.length ? undefined : store.reviewsCount}
              />
            </Locator>
          </div>
        )}
        {store.specialStoreBadge ? (
          <div className={styles.badge}>
            <Image
              className={styles.specialStoreBadgeIcon}
              image={store.specialStoreBadge.image}
              fullSizePreloader
            />
            <span
              className={styles.specialStoreBadgeText}
              style={{
                color: convertBackendColorToCSSValue(store.specialStoreBadge.title.color),
              }}
            >
              {store.specialStoreBadge.title.text}
            </span>
          </div>
        ) : null}
        <div className={styles.badge}>
          <FullDescriptionButton
            fullDescriptionButton={store.fullDescriptionButton}
            description={store.description}
            responsibilityInfo={store.responsibilityInfo}
            productId={productId}
            storeId={id}
          />
        </div>
      </div>
      {hasLink ? (
        <div className={styles.arrow}>
          <Icon name="chevron-right-linear-24" type="mono" width="24px" height="24px" />
        </div>
      ) : null}
    </>
  );

  return (
    <div className={styles.storeBlock}>
      <div className={styles.store}>
        <Locator id="StoreLinkOnProductPage">
          {hasLink ? (
            <Link
              ref={rootRef}
              className={styles.inner}
              to={link}
              onMouseDown={handleInteraction}
              onTouchStart={handleInteraction}
            >
              {storeCard}
            </Link>
          ) : (
            <div ref={rootRef} className={styles.inner}>
              {storeCard}
            </div>
          )}
        </Locator>
      </div>
      {store.reviews?.length ? (
        <div className={styles.storeReviews}>
          <h3 className={styles.storeReviewsHeader}>
            {intl.formatMessage(messages.storeReviews)}{' '}
            {store.reviewsCount ? (
              <span className={styles.storeReviewsCount}>
                {intl.formatNumber(
                  !isNumber(store.reviewsCount) ? store.reviewsCount.value : store.reviewsCount,
                )}
              </span>
            ) : null}
          </h3>
          <div className={styles.storeReviewsList}>
            {store.reviews.map((item) =>
              item ? <StoreReview review={item} key={item.id} /> : null,
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
});

export const StoreSkeleton = (): React.ReactElement => (
  <div className={styles.link}>
    <div className={styles.logo}>
      <Skeleton style={{width: 40, height: 40, borderRadius: 40}} />
    </div>
    <div className={styles.content}>
      <div className={styles.name}>
        <Skeleton text style={{width: 155}} />
      </div>
    </div>
  </div>
);
