import {POPUPS_PRIORITY, usePopupInQueue} from 'components/UIKit/Popup/PopupProvider';
import {PopupParams, useClientPopupView} from 'containers/ClientPopup';

type Props = {
  popupId: string;
  popupParams?: PopupParams;
};

export function InnerLocationPopup({popupId, popupParams}: Props): null {
  const view = useClientPopupView({popupId, popupParams, priority: POPUPS_PRIORITY.LOCATION_POPUP});

  usePopupInQueue(Boolean(popupId) && view);

  return null;
}
