import React from 'react';
import {type Address as AddressType} from 'types/Address';
import {getValueByPath} from 'utils/object';

import styles from './index.scss';

type Props = {
  address: AddressType;
};

export const Address = React.memo(function Address({address}: Props): JSX.Element | null {
  const text = getValueByPath(address, 'description', 'full');

  if (!text) {
    return null;
  }

  return (
    <div className={styles.address}>
      {text.split('\n').map((line) => (
        <p className={styles.line} key={line}>
          {line}
        </p>
      ))}
    </div>
  );
});
