import PropTypes from 'prop-types';
import React, {memo} from 'react';
import WhiteList from 'components/WhiteList';
import {FormattedMessage} from 'react-intl';
import {OkButton} from './OkButton';
import styles from './ThankYou.scss';

export const ThankYou = memo(({onClose}) => {
  return (
    <WhiteList>
      <WhiteList.Item>
        <div className={styles.content}>
          <p className={styles.header}>
            <FormattedMessage
              defaultMessage="Thank you!"
              description="Parcel review thank you header"
            />
          </p>
          <p className={styles.text}>
            <FormattedMessage
              defaultMessage="Our support team will contact you shortly"
              description="Parcel review thank you text"
            />
          </p>
          <OkButton onClick={onClose}>
            <FormattedMessage defaultMessage="Close" description="Parcel review thank you button" />
          </OkButton>
        </div>
      </WhiteList.Item>
    </WhiteList>
  );
});

ThankYou.propTypes = {
  onClose: PropTypes.func.isRequired,
};
