import {isContentListEmpty} from 'helpers/contentList';
import {START_PAGE_TOKEN} from 'store/consts';
import {getItemsToShow} from 'store/enhancer/contentList';
import {createSelectorFactory, createStateGetter} from 'store/utils/selectors';

const selector = createSelectorFactory(createStateGetter('couponsFull'));

export const isCouponsFullLoaded = selector(
  ({state}, pageToken?: string) => !pageToken || !!state.loaded[pageToken],
);

export const isCouponsFullLoading = selector(({state}) => state.loading || false);

export const getCouponsFullError = selector(({state}) => state.error || undefined);

export const getCouponsFullNextPageToken = selector(({state, globalState}) =>
  isCouponsFullLoaded(globalState) ? state.nextPageToken : undefined,
);

export const isCouponsFullOutdated = selector(
  ({globalState}) =>
    isCouponsFullLoaded(globalState) && !isCouponsFullLoaded(globalState, START_PAGE_TOKEN),
);

export const isCouponsFullEmpty = selector(
  ({state, globalState}) =>
    isCouponsFullLoaded(globalState) &&
    !getCouponsFullNextPageToken(globalState) &&
    isContentListEmpty(state.itemsData?.items),
);

export const getCouponsFull = selector(({globalState, state}) =>
  isCouponsFullLoaded(globalState) ? getItemsToShow(state.itemsData) : undefined,
);
