import cn from 'classnames';
import {TextList} from 'components/PageMetadata/TextBlock/TextList';
import {SeoReviewsList} from 'components/SeoReviewsList';
import {useBot} from 'hooks/useBot';
import React, {
  ReactElement,
  ReactNode,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';
import {Item, TextBlock as TextBlockType} from 'types/PageMetadata/TextBlock';

import {Header} from './Header';
import {Images} from './Images';
import styles from './index.scss';
import {Links} from './Links';
import {List} from './List';
import {Table} from './Table';
import {Text} from './Text';

const messages = defineMessages({
  expandText: {
    description: 'Развернуть SEO текст на странице',
    defaultMessage: 'Show full description',
  },
  collapseText: {
    description: 'Свернуть SEO текст на странице',
    defaultMessage: 'Hide full description',
  },
});

export type CutOption = 'none' | 'byHeight' | 'withoutHeader';

type Props = {
  data?: TextBlockType;
  cut?: CutOption;
};

function renderItem(item: Item, key: number | string): ReactNode[] | ReactNode | null {
  if ('header' in item) {
    return <Header key={key} header={item.header} />;
  }
  if ('h1' in item) {
    return <Header key={key} header={item.h1} type="h1" />;
  }

  if ('h2' in item) {
    return <Header key={key} header={item.h2} type="h2" />;
  }

  if ('h3' in item) {
    return <Header key={key} header={item.h3} type="h3" />;
  }

  if ('h4' in item) {
    return <Header key={key} header={item.h4} type="h4" />;
  }

  if ('h5' in item) {
    return <Header key={key} header={item.h5} type="h5" />;
  }

  if ('h6' in item) {
    return <Header key={key} header={item.h6} type="h6" />;
  }

  if ('text' in item) {
    return <Text key={key}>{item.text}</Text>;
  }

  if ('linksList' in item) {
    return <Links key={key} links={item.linksList} />;
  }

  if ('brand_links' in item) {
    return <Links key={key} links={item.brand_links} />;
  }

  if ('textList' in item) {
    const items = item.textList.map((text, index) => renderItem({text}, `${key}.${index}`));

    return <TextList key={key} items={items} />;
  }

  if ('ol' in item) {
    return <List key={key} list={item.ol} type="ol" />;
  }

  if ('ul' in item) {
    return <List key={key} list={item.ul} type="ul" />;
  }

  if ('table' in item) {
    return <Table key={key} table={item.table} />;
  }

  if ('imageGallery' in item) {
    return <Images key={key} images={item.imageGallery} />;
  }

  if ('imageLinksGallery' in item) {
    return <Images key={key} images={item.imageLinksGallery} />;
  }

  if ('reviews' in item) {
    return <SeoReviewsList key={key} items={item.reviews} />;
  }

  return null;
}

function CutByHeightTextBlock({data}: {data: TextBlockType}) {
  const ref = useRef<HTMLDivElement>(null);
  const [showExpandButton, setShowExpandButton] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const handleClick = useCallback(() => setExpanded((expanded) => !expanded), []);

  useLayoutEffect(() => {
    if (ref.current && ref.current.scrollHeight > ref.current.clientHeight) {
      setShowExpandButton(true);
    }
  }, []);

  return (
    <>
      <div ref={ref} className={cn(styles.textBlock, !expanded && styles.cutByHeight)}>
        {data.items.map((item, index) => renderItem(item, index))}
      </div>
      {showExpandButton && (
        <button className={styles.expandButton} type="button" onClick={handleClick}>
          <FormattedMessage {...(expanded ? messages.collapseText : messages.expandText)} />
        </button>
      )}
    </>
  );
}

function CutWithoutHeaderTextBlock({data}: {data: TextBlockType}) {
  const [expanded, setExpanded] = useState(false);

  const handleClick = useCallback(() => setExpanded((expanded) => !expanded), []);

  const header = data.items[0];
  const items = data.items.slice(1);

  return (
    <>
      <div className={styles.textBlock}>
        {header ? renderItem(header, 0) : null}
        {expanded && items.map((item, index) => renderItem(item, index))}
      </div>
      {items.length > 0 && (
        <button className={styles.expandButton} type="button" onClick={handleClick}>
          <FormattedMessage {...(expanded ? messages.collapseText : messages.expandText)} />
        </button>
      )}
    </>
  );
}

export function TextBlock({data, cut}: Props): ReactElement | null {
  const isBot = useBot();

  if (!data?.items?.length) {
    return null;
  }

  if (!isBot && cut === 'byHeight') {
    return <CutByHeightTextBlock data={data} />;
  }

  if (!isBot && cut === 'withoutHeader') {
    return <CutWithoutHeaderTextBlock data={data} />;
  }

  return (
    <div className={styles.textBlock}>
      {data.items.map((item, index) => renderItem(item, index))}
    </div>
  );
}
