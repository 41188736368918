import {createRequestAction} from 'store/utils/requestActions';
import {PersonalDataFormOrigin} from 'types/PersonalData';

export type LoadPersonalDataExistsRequest = {
  origin: PersonalDataFormOrigin;
};
export type LoadPersonalDataExistsResponse = {
  exists: boolean;
};

export const loadPersonalDataExists = createRequestAction(
  '@personalDataExists/EXISTS_REQUEST',
  '@personalDataExists/EXISTS_RESPONSE',
  '@personalDataExists/EXISTS_FAILURE',
)<LoadPersonalDataExistsRequest, LoadPersonalDataExistsResponse>();
