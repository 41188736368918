import classnames from 'classnames/bind';
import HtmlContent from 'components/HtmlContent';
import {Locator} from 'components/Locator';
import {Button} from 'components/UIKit/Button';
import {Popup} from 'components/UIKit/Popup';
import {Content} from 'components/UIKit/Popup/Content';
import {Footer} from 'components/UIKit/Popup/Footer';
import {Header} from 'components/UIKit/Popup/Header';
import {
  PopupType,
  PopupViewRender,
  usePopup,
  usePopupManager,
} from 'components/UIKit/Popup/PopupProvider';
import {FormPopup} from 'connectors/Faq/FormPopup';
import React, {useCallback} from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';
import {ReturnStatusType} from 'shapes/ReturnInfo';
import {ReturnInfo} from 'types/ReturnInfo';
import {Expandable} from 'types/utility';
import {getValueByPath} from 'utils/object';

import AlertIcon from './icons/alert.jsx.svg';
import CalendarIcon from './icons/calendar.jsx.svg';
import styles from './index.scss';

const cn = classnames.bind(styles);

const icons: Partial<Record<string, React.ComponentType>> = {
  [ReturnStatusType.DECLINED]: AlertIcon,
  [ReturnStatusType.EXPIRED]: CalendarIcon,
};

const statusTitleMessages = defineMessages({
  [ReturnStatusType.DECLINED]: {
    description: 'Subtitle for declined return popup',
    defaultMessage: 'The seller declined your return',
  },
  [ReturnStatusType.EXPIRED]: {
    description: 'Subtitle for declined return popup',
    defaultMessage: 'Return is not available',
  },
});

function useReturnInfoPopupRender({returnInfo}: {returnInfo: ReturnInfo}) {
  const popupManager = usePopupManager();

  const handleSupportFormClick = useCallback(() => {
    popupManager.open((props) => <FormPopup {...props} />);
  }, [popupManager]);

  return useCallback<PopupViewRender>(
    ({onBack, onClose}) => {
      const text = getValueByPath(returnInfo, 'message', 'text');
      const title = getValueByPath(returnInfo, 'message', 'title');
      const defaultTitleMessage =
        (statusTitleMessages as Expandable<typeof statusTitleMessages>)[returnInfo.status] ||
        statusTitleMessages[ReturnStatusType.DECLINED];
      const Illustration = icons[returnInfo.status] || AlertIcon;

      return (
        <Popup width="600px">
          <Header onBack={onBack} onClose={onClose}>
            <FormattedMessage
              description="Title for declined return popup"
              defaultMessage="Return declined"
            />
          </Header>
          <Content>
            <div className={cn('content')}>
              {!!Illustration && <Illustration className={cn('illustration')} />}

              <div className={cn('title')}>
                {title ? (
                  <HtmlContent html={title} />
                ) : (
                  <FormattedMessage {...defaultTitleMessage} />
                )}
              </div>

              <Locator id="ReturnInfoPopupText">
                <HtmlContent className={cn('text')} html={text || ''} />
              </Locator>
            </div>
          </Content>
          <Footer>
            {returnInfo.status === ReturnStatusType.DECLINED ? (
              <Button tag="button" color="accent" size="large" onClick={handleSupportFormClick}>
                <FormattedMessage
                  description="Support link in declined return popup"
                  defaultMessage="Contact Support"
                />
              </Button>
            ) : (
              <Button tag="button" color="accent" onClick={onClose}>
                <FormattedMessage
                  description="OK button in declined return popup"
                  defaultMessage="OK"
                />
              </Button>
            )}
          </Footer>
        </Popup>
      );
    },
    [handleSupportFormClick, returnInfo],
  );
}

type Props = {
  returnInfo: ReturnInfo;
  onClose?: () => void;
};

export function useReturnInfoPopup({returnInfo, onClose}: Props): PopupType {
  const render = useReturnInfoPopupRender({returnInfo});

  return usePopup({onClose, render});
}

export function ReturnInfoPopup(props: Props): null {
  useReturnInfoPopup(props);

  return null;
}
