import {ApiClient} from 'helpers/ApiClient';
import {CheckoutSession, CheckoutSessionGroup, PurchaseState} from 'types/CheckoutSession';
import {FuncAction} from 'typesafe-actions';
import {getAnalyticsPurchaseCompleteEvent} from 'utils/analytics/purchaseComplete';
import {hashCode} from 'utils/string';

const COOKIE_MAX_LENGTH = 6;

function send(client: ApiClient, sessionGroup: CheckoutSessionGroup): void {
  // NB! Backend sends order data to Google Analytics
  // https://joom.myjetbrains.com/youtrack/issue/WEB-665
  const {analytics, cookiesRegistry} = client;

  const hash = hashCode(sessionGroup.id).toString(36);
  const sentHashes = (cookiesRegistry.analyticsPurchase.restore() || '').split(',');

  if (sentHashes.indexOf(hash) !== -1) {
    return;
  }

  if (sentHashes.unshift(hash) > COOKIE_MAX_LENGTH) {
    sentHashes.length = COOKIE_MAX_LENGTH;
  }

  cookiesRegistry.analyticsPurchase.store(sentHashes.join(','));

  analytics.dataLayer(getAnalyticsPurchaseCompleteEvent(sessionGroup));
}

export function sendSessionGroupAnalytics(sessionGroup: CheckoutSessionGroup): FuncAction {
  return (store, client) => send(client, sessionGroup);
}

export function sendSessionCompleteAnalytics(session: CheckoutSession): FuncAction {
  return (store, client) =>
    session.groups
      .filter(({purchaseState}) => purchaseState === PurchaseState.COMPLETE)
      .forEach((sessionGroup) => send(client, sessionGroup));
}
