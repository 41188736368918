/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
import classnames from 'classnames';
import {Icon} from 'components/UIKit/Icon';
import config from 'config';
import {useApiClient} from 'hooks/useApiClient';
import React, {ReactElement, useCallback, useState} from 'react';
import {voidFunction} from 'utils/function';
import {rootLocator} from 'utils/rootLocator';

import styles from './index.scss';

const locator = rootLocator.commonPage.footer.debugInfo;

export function UserAndDeviceInfo(): ReactElement | null {
  const client = useApiClient();
  const {id, deviceId} = client.device.getUser() ?? {};
  const {version} = config ?? {};

  const [copySuccess, setCopySuccess] = useState({userId: false, deviceId: false, version: false});

  const getHandleCopy = useCallback(
    (value: string, type: keyof typeof copySuccess) => () => {
      navigator.clipboard.writeText(value).then(() => {
        setCopySuccess({...copySuccess, [type]: true});
        setTimeout(() => setCopySuccess({...copySuccess, [type]: false}), 1000);
      });
    },
    [setCopySuccess, copySuccess],
  );

  return (
    <pre className={styles.info}>
      <span className={styles.item} onClick={id ? getHandleCopy(id, 'userId') : voidFunction}>
        <span>
          UserId: <span className={styles.value}>{id ?? '—'}</span>
        </span>
        {id ? (
          <span
            className={classnames(
              styles.copyIcon,
              copySuccess.userId ? styles.success : styles.neutral,
            )}
          >
            {copySuccess.userId ? (
              <Icon type="mono" name="check-linear-24" />
            ) : (
              <Icon type="mono" name="copy-linear-24" />
            )}
          </span>
        ) : null}
      </span>
      <span
        className={styles.item}
        onClick={deviceId ? getHandleCopy(deviceId, 'deviceId') : voidFunction}
      >
        <span>
          DeviceId: <span className={styles.value}>{deviceId ?? '—'}</span>
        </span>
        {deviceId ? (
          <span
            className={classnames(
              styles.copyIcon,
              copySuccess.deviceId ? styles.success : styles.neutral,
            )}
          >
            {copySuccess.deviceId ? (
              <Icon type="mono" name="check-linear-24" />
            ) : (
              <Icon type="mono" name="copy-linear-24" />
            )}
          </span>
        ) : null}
      </span>
      <span
        className={styles.item}
        onClick={version ? getHandleCopy(version, 'version') : voidFunction}
      >
        <span>
          Build version:{' '}
          <span className={styles.value} {...locator.appVersion()}>
            {version ?? '—'}
          </span>
        </span>
        {version ? (
          <span
            className={classnames(
              styles.copyIcon,
              copySuccess.version ? styles.success : styles.neutral,
            )}
          >
            {copySuccess.version ? (
              <Icon type="mono" name="check-linear-24" />
            ) : (
              <Icon type="mono" name="copy-linear-24" />
            )}
          </span>
        ) : null}
      </span>
    </pre>
  );
}
