import {BottomSheet} from 'components/BottomSheet';
import {useMatchMediaBreakpointUp} from 'hooks/useMatchMedia';
import React from 'react';

type Props = {
  children: React.ReactNode;
  onClose: (event?: React.MouseEvent | KeyboardEvent) => void;
  fullScreen?: boolean;
  onApply?: () => void;
  onDiscard?: () => void;
};

export const MobileBottomSheet = React.memo(function MobileBottomSheet(props: Props) {
  const {children, onClose, fullScreen = false, onApply, onDiscard} = props;
  const mdBreakpoint = useMatchMediaBreakpointUp('md');

  if (mdBreakpoint && mdBreakpoint.matches) {
    return null;
  }

  return (
    <BottomSheet fullScreen={fullScreen} onClose={onClose} onApply={onApply} onDiscard={onDiscard}>
      {children}
    </BottomSheet>
  );
});
