import cn from 'classnames/bind';
import {Html} from 'components/HyperText/Html';
import React, {ReactElement} from 'react';
import {HtmlBlockItem} from 'types/ContentList/ContentListHtmlBlock';

import styles from './index.scss';

const getClassName = cn.bind(styles);

export function HtmlBlock({html}: HtmlBlockItem): ReactElement {
  return (
    <div className={styles.root}>
      <Html html={html} getClassName={getClassName} />
    </div>
  );
}
