import {ApiClient} from 'helpers/ApiClient';
import type {Location} from 'history';

import {IKeyValueStorage} from '../KeyValueStorage/types';
import {LOCATION_TO_SESSION} from './consts';

export class LocationToSession {
  id = LOCATION_TO_SESSION;

  storage: IKeyValueStorage;

  constructor(client: ApiClient) {
    this.storage = client.storages.session;
  }

  getSessionByLocation(key: Location['key']): string | undefined {
    const item = this.storage.getItem(this.id) as string;

    try {
      const parsedItem = item ? JSON.parse(item) : {};
      return key ? parsedItem[key] : undefined;
    } catch {
      return undefined;
    }
  }

  discard(): void {
    this.storage?.removeItem(this.id);
  }

  setSessionForLocation(key: Location['key'], sessionId: string): void {
    if (!key) {
      return;
    }

    const item = this.storage.getItem(this.id) as string;

    let parsedValue;
    try {
      parsedValue = JSON.parse(item);
    } catch {
      parsedValue = {};
    }

    this.storage?.setItem(
      this.id,
      JSON.stringify({
        ...parsedValue,
        [key]: sessionId,
      }),
    );
  }
}
