import PropTypes from 'prop-types';

export default PropTypes.shape({
  balance: PropTypes.number.isRequired,
  spendPermission: PropTypes.shape({
    allowed: PropTypes.bool,
    reason: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
    }),
  }),
});
