import PropTypes from 'prop-types';

const SupportParams = PropTypes.shape({
  metadata: PropTypes.shape({
    origin: PropTypes.string,
  }),
  tags: PropTypes.arrayOf(PropTypes.string),
  fields: PropTypes.shape({
    rejection_reason: PropTypes.arrayOf(PropTypes.string),
  }),
  message: PropTypes.string,
});

export const OrderSupportShape = PropTypes.shape({
  generalTree: SupportParams,
  returnOrderTree: SupportParams,
  notDeliveredTree: SupportParams,
  refundable: PropTypes.bool.isRequired,
});
