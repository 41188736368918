import classnames from 'classnames/bind';
import {SlimBanner} from 'components/ContentList/SlimBanner';
import {NoIndex} from 'components/NoIndex';
import {PageLoading} from 'components/PageLoading';
import {ProductLite} from 'connectors/ProductLite';
import {ScrollLoader, ScrollLoaderActionType} from 'components/ScrollLoader';
import {
  ContentListItemView,
  getContentListItemType,
  getContentListItemView,
} from 'helpers/contentList';
import PropTypes from 'prop-types';
import React, {PureComponent} from 'react';
import {FormattedMessage} from 'react-intl';
import {Link} from 'react-router-dom';
import AnalyticsShape from 'shapes/Analytics';
import {ContentListItemShape} from 'shapes/ContentListItem';
import {DeviceVarsShape} from 'shapes/Devicevars';
import {ContentListItemType} from 'types/ContentList';
import {useAnalytics} from 'hooks/useAnalytics';
import {useDeviceVars} from 'hooks/useDeviceVars';
import omit from 'lodash/omit';

import {CouponCard} from 'connectors/CouponCard';
import {contentListGroup} from 'types/ContentList/ContentListGroup';
import {AttributesBlock} from 'components/ClientProduct/Attributes';
import {Reviews} from 'components/ClientProduct/Reviews';
import {Pagination} from 'components/Pagination';
import {ConditionalWrapper} from 'components/ConditionalWrapper';
import {createLocator} from 'create-locator';
import {useIsBreakpointMobile} from 'hooks/useIsBreakpointMobile';
import {ContentListLayoutView} from 'components/ContentList/ContentListLayout';
import {BannersList, canRenderBannersList} from './BannersList';
import {NARROW4, NARROW4HALF, WIDE4, WIDE4HALF, WIDE6, WIDE6HALF} from './imageSizes';
import styles from './index.scss';
import {ProductCardGroup, canRenderProductCardGroup} from './ProductCardGroup';
import {Row} from './Row';
import {SectionHeader} from './SectionHeader';
import {AlertNothingFound} from './AlertNothingFound';
import {canRenderSideBannerGroup, SideBannerGroup} from './SideBannerGroup';
import {TermsHeader} from './TermsHeader';
import {TermsList} from './TermsList';
import {SearchStoreList} from './SearchStoreList';
import {Group} from './Group';
import {RowList} from './RowList';
import {ScreenHeader} from './ScreenHeader';
import {ProductBrand} from './ProductBrand';
import {ProductsList} from './ProductsList';
import {TermsItem} from './TermsItem';
import {ProductCollectionBanner} from './ProductCollectionBanner';
import {HtmlBlock} from './HtmlBlock';
import {CountdownRowV2} from './CountdownRowV2';
import {Parcel} from './Parcel';
import {AwaitingPaymentOrderGroup} from './AwaitingPaymentOrderGroup';
import {PaymentRequisites} from './PaymentRequisites';
import {ProductListPurchaseItems} from './ProductListPurchaseItems';

const cn = classnames.bind(styles);

const MaxColsClassName = {
  4: 'maxFourCols',
  6: 'maxSixCols',
};

class ContentListBase extends PureComponent {
  static propTypes = {
    isNarrow: PropTypes.bool,
    isSpacious: PropTypes.bool,
    loading: PropTypes.bool,
    moreUrl: PropTypes.string,
    onFavorite: PropTypes.func,
    onMore: PropTypes.func,
    onUnfavorite: PropTypes.func,
    outdatedUrl: PropTypes.string,
    removeLoadMoreLink: PropTypes.bool,
    source: PropTypes.string,
    items: PropTypes.arrayOf(ContentListItemShape.isRequired),
    renderItemByType: PropTypes.func,
    analytics: AnalyticsShape.isRequired,
    deviceVars: DeviceVarsShape.isRequired,
    getFirstByKind: PropTypes.func,
    preloadProductImage: PropTypes.bool,
    from: PropTypes.string,
    showPagination: PropTypes.bool,
    noTopMargin: PropTypes.bool,
    smallRowGap: PropTypes.bool,
    mobile: PropTypes.bool.isRequired,
    meta: PropTypes.object,
  };

  static defaultProps = {
    loading: false,
    isNarrow: false,
    isSpacious: false,
    moreUrl: null,
    onFavorite: null,
    onMore: null,
    onUnfavorite: null,
    outdatedUrl: null,
    removeLoadMoreLink: false,
    source: null,
    items: null,
    renderItemByType: null,
    getFirstByKind: null,
    preloadProductImage: false,
    from: undefined,
    showPagination: false,
    noTopMargin: false,
    smallRowGap: false,
    meta: undefined,
  };

  getImageProps() {
    const {isNarrow} = this.props;
    const maxCols = this.getMaxCols();

    if (isNarrow) {
      return NARROW4;
    }

    return maxCols === 6 ? WIDE6 : WIDE4;
  }

  getImageHalfProps() {
    const {isNarrow} = this.props;
    const maxCols = this.getMaxCols();

    if (isNarrow) {
      return NARROW4HALF;
    }

    return maxCols === 6 ? WIDE6HALF : WIDE4HALF;
  }

  getMaxCols() {
    const {isNarrow} = this.props;

    return isNarrow ? 4 : 6;
  }

  handleMore = (evt, action) => {
    if (action === ScrollLoaderActionType.CLICK) {
      const {from} = this.props;
      this.props.analytics.sendEvent({
        type: 'moreProductsClick',
        payload: {
          from,
        },
      });
      this.props.analytics.dataLayer({
        event: 'Product List. More Click',
      });
    }
    return this.props.onMore();
  };

  renderOutdated() {
    const {outdatedUrl} = this.props;
    if (!outdatedUrl) {
      return null;
    }

    return (
      <div className={styles.outdated}>
        <NoIndex>
          <p className={styles.text}>
            <FormattedMessage
              description="Text when products list is outdated"
              defaultMessage="Product list is outdated"
            />
          </p>
          <Link className={styles.button} to={outdatedUrl}>
            <FormattedMessage
              description="Update outdated products list button"
              defaultMessage="Refresh"
            />
          </Link>
        </NoIndex>
      </div>
    );
  }

  renderItem = (item) => {
    const itemContent = this.renderItemContent(item);
    if (!itemContent) {
      return null;
    }
    const view = getContentListItemView(item);
    const type = getContentListItemType(item);

    if (view === ContentListItemView.FREEFORM) {
      return itemContent;
    }

    let className;

    const noBottomMargin = [
      ContentListItemType.ROW,
      ContentListItemType.COUNTDOWN_ROW_V2,
      ContentListItemType.SEARCH_FILTERS,
      ContentListItemType.PROMOTIONS_CAROUSEL,
      ContentListItemType.POWER_SHOP,
      ContentListItemType.SEARCH_STORE_LIST,
      ContentListItemType.SECTION_HEADER,
      ContentListItemType.SLIM_BANNER,
      ContentListItemType.TERMS_HEADER,
      ContentListItemType.TERMS_ITEM,
      ContentListItemType.SCREEN_HEADER,
      ContentListItemType.HTML_BLOCK,
      ContentListItemType.PARCEL,
      ContentListItemType.PAYMENT_REQUISITES,
      ContentListItemType.AWAITING_PAYMENT_ORDER_GROUP,
      ContentListItemType.PRODUCT_LIST_PURCHASE_ITEMS,
    ].includes(type);

    switch (view) {
      case ContentListItemView.ROW: {
        className = cn('row', {noBottomMargin});
        break;
      }
      case ContentListItemView.INLINE_ROW: {
        className = cn('row', {noBottomMargin});
        break;
      }
      case ContentListItemView.DOUBLE_CELL: {
        className = cn('doubleCell', {noBottomMargin});
        break;
      }
      default: {
        className = cn('cell', {noBottomMargin});
      }
    }

    return (
      <div className={className} key={item.pdid || item.id}>
        {itemContent}
      </div>
    );
  };

  renderItemContent = (item) => {
    const type = getContentListItemType(item);
    const locator = createLocator(this.props);

    const {renderItemByType, onFavorite, onUnfavorite, meta} = this.props;
    const renderedItem = renderItemByType && renderItemByType(type, item);
    if (renderedItem) {
      return renderedItem;
    }

    const {mobile, source} = this.props;

    switch (type) {
      case ContentListItemType.BANNERS_LIST:
        if (canRenderBannersList(item.content.bannersList, {mobile})) {
          return (
            <BannersList
              imageProps={this.getImageProps()}
              content={item.content.bannersList}
              id={item.id}
              source={source}
              {...locator.bannersList()}
            />
          );
        }

        return null;

      case ContentListItemType.COUPON:
        return <CouponCard content={item.content.coupon} id={item.id} source="couponList" />;

      case ContentListItemType.PRODUCT:
        return this.renderProduct(item.content.product);

      case ContentListItemType.PRODUCT_FROM_COLLECTIONS:
        return this.renderProduct(item.content.productLiteFromCollections.productLite);

      case ContentListItemType.PRODUCT_COLLECTION_BANNER:
        return (
          <ProductCollectionBanner
            {...this.getImageHalfProps()}
            content={item.content.productCollectionBanner}
            source={source}
          />
        );

      case ContentListItemType.PRODUCT_CARD_GROUP:
        if (canRenderProductCardGroup(item.content.productCardGroup)) {
          return (
            <ProductCardGroup
              content={item.content.productCardGroup}
              onFavorite={onFavorite}
              onUnfavorite={onUnfavorite}
              id={item.id}
            />
          );
        }

        return null;

      case ContentListItemType.ROW:
        return <Row content={item.content.row} id={item.id} />;

      case ContentListItemType.SIDE_BANNER_GROUP:
        if (canRenderSideBannerGroup(item.content.sideBannerGroup)) {
          return (
            <SideBannerGroup
              content={item.content.sideBannerGroup}
              id={item.id}
              source={source}
              onFavorite={onFavorite}
              onUnfavorite={onUnfavorite}
            />
          );
        }

        return null;

      case ContentListItemType.SLIM_BANNER:
        return <SlimBanner content={item.content.slimBanner} id={item.id} source={source} />;

      case ContentListItemType.COUNTDOWN_ROW_V2:
        return (
          <CountdownRowV2
            content={item.content.countdownRowV2}
            id={item.id}
            source={source}
            loadedTimeMs={item.loadedTimeMs}
            withExtraTopMargin
          />
        );

      case ContentListItemType.TERMS_HEADER:
        return <TermsHeader content={item.content.termsHeader} />;

      case ContentListItemType.TERMS_ITEM:
        return <TermsItem content={item.content.termsItem} />;

      case ContentListItemType.TERMS_LIST:
        return <TermsList content={item.content.termsList} id={item.id} />;

      case ContentListItemType.SECTION_HEADER: {
        return (
          <SectionHeader {...locator.sectionHeader()}>{item.content.header.title}</SectionHeader>
        );
      }

      case ContentListItemType.ALERT_NOTHING_FOUND: {
        return (
          <AlertNothingFound
            title={item.content.alert.text}
            description={item.content.alert.description}
            {...locator.nothingFound()}
          />
        );
      }
      case ContentListItemType.PRODUCT_BRAND:
        return <ProductBrand productBrand={item.content.productBrand} />;

      case ContentListItemType.PRODUCTS_LIST:
        return (
          <ProductsList
            content={item.content.productsList}
            contentListProps={omit(this.props, 'items', 'loadMore')}
            id={item.id}
          />
        );

      case ContentListItemType.PRODUCT_REVIEWS:
        return (
          <Reviews
            reviews={item.content.productReviews.reviews}
            reviewFilters={item.content.productReviews.filters}
          />
        );

      case ContentListItemType.PRODUCT_ATTRIBUTES:
        return <AttributesBlock attributes={item.content.productAttributes} />;

      case ContentListItemType.ROW_LIST:
        return <RowList rowList={item.content.rowList} />;

      case ContentListItemType.SEARCH_STORE_LIST:
        return <SearchStoreList storeList={item.content} />;

      case ContentListItemType.GROUP:
        return (
          <Group type={item.content.type} groupId={item.content.groupId}>
            {item.content.items.map(this.renderItemContent)}
          </Group>
        );

      case ContentListItemType.SCREEN_HEADER:
        return <ScreenHeader screenHeader={item.content.screenHeader} />;

      case ContentListItemType.HTML_BLOCK:
        return <HtmlBlock html={item.content.htmlBlock.html} />;

      case ContentListItemType.PARCEL:
        return <Parcel activeFilter={meta?.activeFilter} parcel={item.content.parcel} />;

      case ContentListItemType.PAYMENT_REQUISITES:
        return (
          <PaymentRequisites
            loadedTimeMs={item.loadedTimeMs}
            paymentRequisites={item.content.paymentRequisites}
          />
        );

      case ContentListItemType.AWAITING_PAYMENT_ORDER_GROUP:
        return (
          <AwaitingPaymentOrderGroup
            loadedTimeMs={item.loadedTimeMs}
            awaitingPaymentOrderGroup={item.content.awaitingPaymentOrderGroup}
          />
        );

      case ContentListItemType.PRODUCT_LIST_PURCHASE_ITEMS:
        return (
          <ProductListPurchaseItems
            productListPurchaseItems={item.content.productListPurchaseItems}
          />
        );

      case ContentListItemType.LAYOUT:
        return <ContentListLayoutView item={item} />;

      // these are duplicated in ProductHeader and do not exist outside of the ProductCard
      case ContentListItemType.PRODUCT_STORE:
      case ContentListItemType.VARIANT_PARAMETERS:
      case ContentListItemType.PRE_OFFER:
      case ContentListItemType.PURCHASE_STATS:
        return null;

      default:
        return null;
    }
  };

  renderProduct = (product) => {
    const locator = createLocator(this.props);
    const {onFavorite, onUnfavorite, source} = this.props;
    const imageProps = this.getImageProps();

    return (
      <ProductLite
        className={styles.product}
        expandable
        onFavorite={onFavorite}
        onUnfavorite={onUnfavorite}
        product={product}
        source={source}
        {...imageProps}
        {...locator.productLite()}
      />
    );
  };

  isGroupable(item) {
    return [ContentListItemType.COUPON].includes(getContentListItemType(item));
  }

  formGroups(items) {
    let currentGroup;

    return items.reduce((acc, item) => {
      if (!this.isGroupable(item)) {
        currentGroup = null;

        acc.push(item);
        return acc;
      }

      if (!currentGroup) {
        const group = contentListGroup({
          id: item.id,
          groupId: item.groupId,
          type: [ContentListItemType.COUPON].includes(getContentListItemType(item))
            ? 'grid'
            : 'flex',
          items: [item],
        });

        currentGroup = group;

        acc.push(group);
      } else {
        currentGroup.content.items.push(item);
      }

      return acc;
    }, []);
  }

  renderContentList({items, loading, moreUrl, handleMore}) {
    const {isNarrow, isSpacious, removeLoadMoreLink, showPagination, noTopMargin, smallRowGap} =
      this.props;
    const locator = createLocator(this.props);

    if (!items) {
      return loading ? <PageLoading /> : null;
    }

    const maxCols = this.getMaxCols();
    const className = cn('container', 'revision', styles[MaxColsClassName[maxCols]], {
      narrow: isNarrow,
      wide: !isNarrow,
      spacious: isSpacious,
      noTopMargin,
      smallRowGap,
    });

    const itemsWithGrouping = this.formGroups(items);

    return (
      <ConditionalWrapper
        condition={!showPagination}
        wrapper={(children) => (
          <ScrollLoader removeLoadMoreLink={removeLoadMoreLink} to={moreUrl} onLoad={handleMore}>
            {children}
          </ScrollLoader>
        )}
      >
        <div className={className} {...locator()}>
          {this.renderOutdated()}
          <div className={styles.inner}>
            {itemsWithGrouping.map((item) => this.renderItem(item))}
          </div>
        </div>
        {showPagination ? <Pagination count={10} urlPrefix={moreUrl} /> : null}
      </ConditionalWrapper>
    );
  }

  render() {
    const {items, loading, moreUrl, onMore} = this.props;

    return this.renderContentList({
      items,
      loading,
      moreUrl,
      handleMore: onMore ? this.handleMore : null,
    });
  }
}

export function ContentList(props) {
  const mobile = useIsBreakpointMobile('sm');
  const analytics = useAnalytics();
  const deviceVars = useDeviceVars();

  return (
    <ContentListBase {...props} analytics={analytics} deviceVars={deviceVars} mobile={mobile} />
  );
}
