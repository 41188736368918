import React, {useCallback, memo} from 'react';
import {useLanguage} from 'hooks/useLanguage';
import {useAnalytics} from 'hooks/useAnalytics';
import {useScope} from 'hooks/useScope';
import {defineMessages, useIntl} from 'react-intl';
import {getUrl} from 'routes';
import {rootLocator} from 'utils/rootLocator';
import {Icon} from 'components/UIKit/Icon';
import {HeaderButton, HeaderButtonContainer} from '../HeaderButton';

const messages = defineMessages({
  favorites: {
    description: 'Favorites button in header',
    defaultMessage: 'Favourites',
  },
});

const locator = rootLocator.commonPage.header.favorites;

export const Favorites = memo(function Favorites(props) {
  const intl = useIntl();
  const lang = useLanguage();
  const scope = useScope();
  const analytics = useAnalytics();
  const to = getUrl('favorites', {lang, scope});

  const onClick = useCallback(() => {
    analytics.sendEvent({
      type: 'favoritesLinkClick',
      payload: {from: 'header'},
    });
  }, [analytics]);

  return (
    <HeaderButtonContainer>
      <HeaderButton
        onClick={onClick}
        icon={<Icon type="mono" name="heart-linear-24" width="24px" height="24px" />}
        to={to}
        text={intl.formatMessage(messages.favorites)}
        {...locator.button()}
      />
    </HeaderButtonContainer>
  );
});
