import PropTypes from 'prop-types';

import Address from './Address';
import Gradient from './Gradient';

const Button = PropTypes.shape({
  backgroundGradient: Gradient.isRequired,
  text: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
});

export const CancelBehavior = PropTypes.shape({
  changeAddressSuggestion: PropTypes.shape({
    cancelButton: Button.isRequired,
    changeAddressButton: Button.isRequired,
    subtitle: PropTypes.string.isRequired,
    suggestedAddress: Address.isRequired,
    title: PropTypes.string.isRequired,
  }),
});
