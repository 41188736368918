import classnames from 'classnames/bind';
import {ResizeListener} from 'components/ResizeListener';
import {Column as LayoutColumn} from 'components/Layout/Column';
import PropTypes from 'prop-types';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {getPassiveEventOptions} from 'utils/dom';
import throttle from 'utils/throttle';
import {getViewportHeight} from 'utils/viewport';
import {requestAnimationFrame} from 'utils/raf';
import {hasStickyInStickyBug} from 'utils/styles';
import {useMutableCallback} from 'hooks/useMutableCallback';
import {StickyEnabledContext} from './StickyEnabledContext';
import styles from './Column.scss';

const cn = classnames.bind(styles);

const STICKY = 'sticky';
const EQUAL_TO_PARENT = 'equalToParent';
const STICKY_WITH_OVERFLOW = 'stickyWithOverflow';

const SCROLL_UP = 'scrollUp';
const SCROLL_DOWN = 'scrollDown';

let globalDirection = null;
let lastScrollTop = -1;

function updateGlobalDirection() {
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  if (lastScrollTop === scrollTop) {
    return;
  }

  globalDirection = lastScrollTop < scrollTop ? SCROLL_DOWN : SCROLL_UP;
  lastScrollTop = scrollTop;
}

function getMode(parentElement, innerElement) {
  if (getViewportHeight() > innerElement.clientHeight) {
    return STICKY;
  }

  if (innerElement.clientHeight === parentElement.clientHeight) {
    return EQUAL_TO_PARENT;
  }

  return STICKY_WITH_OVERFLOW;
}

export function Column(props) {
  const {children, className, hasStickyElementInside, ...columnProps} = props;
  const enabled = useContext(StickyEnabledContext);
  const [wrapperStyle, setWrapperStyle] = useState(null);
  const [mode, setMode] = useState(STICKY);
  const [scrollDirection, setScrollDirection] = useState(globalDirection);
  const innerRef = useRef(null);
  const parentRef = useRef(null);

  const handleUpdate = useMutableCallback(function handleUpdate() {
    if (!innerRef.current || !parentRef.current) {
      return;
    }

    const newMode = getMode(parentRef.current, innerRef.current);
    if (mode !== newMode) {
      setMode(newMode);
    }

    updateGlobalDirection();

    if (globalDirection !== scrollDirection) {
      if (newMode === STICKY_WITH_OVERFLOW) {
        const parentRect = parentRef.current.getBoundingClientRect();
        const innerRect = innerRef.current.getBoundingClientRect();

        const getPadding = (padding) => `${padding}px`;

        if (globalDirection === SCROLL_DOWN) {
          const top = parseFloat(getComputedStyle(innerRef.current).top);

          setWrapperStyle(
            parentRect.top < innerRect.top && innerRect.top <= top
              ? {
                  paddingTop: getPadding(innerRect.top - parentRect.top),
                }
              : null,
          );
        } else if (globalDirection === SCROLL_UP) {
          const bottom = parseFloat(getComputedStyle(innerRef.current).bottom);

          setWrapperStyle(
            parentRect.bottom > innerRect.bottom && innerRect.bottom >= getViewportHeight() - bottom
              ? {
                  paddingBottom: getPadding(parentRect.bottom - innerRect.bottom),
                }
              : null,
          );
        }
      } else {
        setWrapperStyle(null);
      }
      setScrollDirection(globalDirection);
    }
  });

  useEffect(() => {
    const resizeHandler = throttle(handleUpdate);
    global.addEventListener('scroll', handleUpdate, getPassiveEventOptions());
    global.addEventListener('resize', resizeHandler, getPassiveEventOptions());

    return () => {
      global.removeEventListener('scroll', handleUpdate, getPassiveEventOptions());
      global.removeEventListener('resize', resizeHandler, getPassiveEventOptions());
    };
  }, [handleUpdate, scrollDirection]);

  useEffect(() => {
    if (typeof ResizeObserver !== 'undefined' && innerRef.current) {
      const resizeObserver = new ResizeObserver(handleUpdate);
      resizeObserver.observe(innerRef.current);

      return () => {
        resizeObserver.disconnect();
      };
    }

    return undefined;
  }, [handleUpdate]);

  const applyStickyBehaviour = enabled && !(hasStickyElementInside && hasStickyInStickyBug());

  const wrapperClassName = cn('column', {
    enabled: applyStickyBehaviour,
    [mode]: applyStickyBehaviour,
    [scrollDirection]: applyStickyBehaviour && mode === STICKY_WITH_OVERFLOW,
  });

  return (
    <LayoutColumn ref={parentRef} marginBottom {...columnProps}>
      <div style={applyStickyBehaviour ? wrapperStyle : null} className={wrapperClassName}>
        {props.nonStickyTop}
        <div ref={innerRef} className={cn('inner')}>
          <ResizeListener onResize={() => requestAnimationFrame(handleUpdate)}>
            <div className={className}>{children}</div>
          </ResizeListener>
        </div>
      </div>
    </LayoutColumn>
  );
}

Column.propTypes = {
  ...LayoutColumn.propTypes,
  children: PropTypes.node,
  className: PropTypes.string,
  hasStickyElementInside: PropTypes.bool,
  fullWidth: PropTypes.bool,
  nonStickyTop: PropTypes.node,
};

Column.defaultProps = {
  ...LayoutColumn.defaultProps,
  children: null,
  className: '',
  hasStickyElementInside: false,
  fullWidth: false,
  nonStickyTop: null,
};
