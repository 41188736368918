import {AddressFormStaticLocator} from 'components/AddressForm/AddressFormStaticLocator';
import type {AddressPopupStaticLocator} from 'components/AddressPopup/AddressPopupStaticLocator';
import {AdultPopupStaticLocator} from 'components/AdultPopup/AdultPopupStaticLocator';
import type {AuthStaticLocator} from 'components/Auth/AuthStaticLocator';
import {ClientPopupStaticLocator} from 'components/ClientPopup/ClientPopupStaticLocator';
import type {HeaderCloseStaticLocator} from 'components/WhiteList/HeaderClose';
import config from 'config';
import type {PageBaseStaticLocator} from 'containers/PageBaseStaticLocator';
import type {BloggerTermsPageStaticLocator} from 'containers/pages/BloggerTermsPage/BloggerTermsPageStaticLocator';
import type {CheckoutSessionPageStaticLocator} from 'containers/pages/CheckoutSessionPage/CheckoutSessionPageStaticLocator';
import type {CheckoutSuccessPageStaticLocator} from 'containers/pages/CheckoutSuccessPage/CheckoutSuccessPageStaticLocator';
import type {FaqPageStaticLocator} from 'containers/pages/FaqPage/FaqPageStaticLocator';
import type {FavoritesPageStaticLocator} from 'containers/pages/FavoritesPage/FavoritesPageStaticLocator';
import type {GroupedCartPageStaticLocator} from 'containers/pages/GroupedCartPage/GroupedCartPageStaticLocator';
import type {IprProtectionPageStaticLocator} from 'containers/pages/IprProtectionPage/IprProtectionPageStaticLocator';
import {LegalDocPageStaticLocator} from 'containers/pages/LegalDocPage/LegalDocPageStaticLocator';
import type {MainPageStaticLocator} from 'containers/pages/Main/MainPageStaticLocator';
import type {ParcelPageStaticLocator} from 'containers/pages/ParcelPage/ParcelPageStaticLocator';
import type {ParcelsPageStaticLocator} from 'containers/pages/ParcelsPage/ParcelsPageStaticLocator';
import type {PointsPageStaticLocator} from 'containers/pages/PointsPage/PointsPageStaticLocator';
import type {PrivacyPageStaticLocator} from 'containers/pages/PrivacyPage/PrivacyPageStaticLocator';
import type {ProductPageStaticLocator} from 'containers/pages/Product/ProductPageStaticLocator';
import type {ProfileSecurityPageStaticLocator} from 'containers/pages/ProfileSecurityPage/ProfileSecurityPageStaticLocator';
import type {PromotionPageStaticLocator} from 'containers/pages/PromotionPage/PromotionPageStaticLocator';
import type {SearchPageStaticLocator} from 'containers/pages/SearchPage/SearchPageStaticLocator';
import type {SocialFeedPageStaticLocator} from 'containers/pages/SocialFeedPage/SocialFeedPageStaticLocator';
import type {SocialUserPageStaticLocator} from 'containers/pages/SocialUserPage/SocialUserPageStaticLocator';
import type {StorePageStaticLocator} from 'containers/pages/StorePage/StorePageStaticLocator';
import type {TermsPageStaticLocator} from 'containers/pages/TermsPage/TermsPageStaticLocator';
import type {VatPageStaticLocator} from 'containers/pages/VatPage/VatPageStaticLocator';
import {
  type Locator,
  type Node,
  createRootLocator,
  setGlobalProductionMode,
  setOptions,
} from 'create-locator';

import {options} from './locatorOptions';

/**
 * Root application component locator type.
 */
export type RootLocator = Locator<{
  addressPopup: AddressPopupStaticLocator;
  addressForm: AddressFormStaticLocator;
  adultPopup: AdultPopupStaticLocator;
  authPopup: AuthStaticLocator;
  bloggerTermsPage: BloggerTermsPageStaticLocator;
  cartPage: GroupedCartPageStaticLocator;
  checkoutPage: CheckoutSessionPageStaticLocator;
  clientPopup: ClientPopupStaticLocator;
  commonPage: PageBaseStaticLocator;
  faqPage: FaqPageStaticLocator;
  favoritesPage: FavoritesPageStaticLocator;
  invoicePage: VatPageStaticLocator;
  iprProtectionPage: IprProtectionPageStaticLocator;
  legalDocPage: LegalDocPageStaticLocator;
  mainPage: MainPageStaticLocator;
  orderPage: ParcelPageStaticLocator;
  parcelsPage: ParcelsPageStaticLocator;
  pointsPage: PointsPageStaticLocator;
  privacyPage: PrivacyPageStaticLocator;
  productPage: ProductPageStaticLocator;
  promotionPage: PromotionPageStaticLocator;
  searchPage: SearchPageStaticLocator;
  securityPage: ProfileSecurityPageStaticLocator;
  socialPage: SocialFeedPageStaticLocator;
  socialUserPage: SocialUserPageStaticLocator;
  static: Node<{
    headerClose: HeaderCloseStaticLocator;
  }>;
  storePage: StorePageStaticLocator;
  successPage: CheckoutSuccessPageStaticLocator;
  termsOfServicePage: TermsPageStaticLocator;
}>;

const isProduction = config.releaseStage === 'prod';

if (isProduction) {
  setGlobalProductionMode();
} else {
  setOptions(options);
}

/**
 * Root locator (locator of app root component).
 */
export const rootLocator = createRootLocator<RootLocator>('app', {
  isProduction,
});
