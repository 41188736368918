import {createRequestAction} from 'store/utils/requestActions';
import {Banner, Fields, Part, ValidationMessages} from 'types/AddressMetainfo';
import {PersonalDataFormOrigin} from 'types/PersonalData';

export type PersonalDataForm = {
  banners?: Banner[];
  fields: Fields;
  parts: Part[];
  title?: string;
};

export type LoadPersonalDataFormRequest = {
  origin: PersonalDataFormOrigin;
};
export type LoadPersonalDataFormResponse = PersonalDataForm;

export const loadPersonalDataForm = createRequestAction(
  '@personalDataForm/LOAD_FORM_REQUEST',
  '@personalDataForm/LOAD_FORM_RESPONSE',
  '@personalDataForm/LOAD_FORM_FAILURE',
)<LoadPersonalDataFormRequest, LoadPersonalDataFormResponse>();

export type ValidatePartialPersonalDataFormRequest = {
  fields: Fields;
  origin: PersonalDataFormOrigin;
};
export type ValidatePartialPersonalDataFormResponse = {
  message: ValidationMessages;
};

export const validatePartialPersonalDataForm = createRequestAction(
  '@personalDataForm/VALIDATE_PARTIAL_FORM_REQUEST',
  '@personalDataForm/VALIDATE_PARTIAL_FORM_RESPONSE',
  '@personalDataForm/VALIDATE_PARTIAL_FORM_FAILURE',
)<ValidatePartialPersonalDataFormRequest, ValidatePartialPersonalDataFormResponse>();

export type ValidatePersonalDataFormRequest = {
  fields: Fields;
  origin: PersonalDataFormOrigin;
};
export type ValidatePersonalDataFormResponse = {
  message: ValidationMessages;
};

export const validatePersonalDataForm = createRequestAction(
  '@personalDataForm/VALIDATE_FORM_REQUEST',
  '@personalDataForm/VALIDATE_FORM_RESPONSE',
  '@personalDataForm/VALIDATE_FORM_FAILURE',
)<ValidatePersonalDataFormRequest, ValidatePersonalDataFormResponse>();

export type UpdatePersonalDataFormRequest = {
  fields: Fields;
  origin: PersonalDataFormOrigin;
};
export type UpdatePersonalDataFormResponse = PersonalDataForm;

export const updatePersonalDataForm = createRequestAction(
  '@personalDataForm/UPDATE_FORM_REQUEST',
  '@personalDataForm/UPDATE_FORM_RESPONSE',
  '@personalDataForm/UPDATE_FORM_FAILURE',
)<UpdatePersonalDataFormRequest, UpdatePersonalDataFormResponse>();
