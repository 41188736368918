import classnames from 'classnames/bind';
import {ConditionalWrapper} from 'components/ConditionalWrapper';
import {Portal} from 'components/Portal';
import {useLayoutEffectOnce} from 'hooks/useEffectOnce';
import {useOnClickOutside} from 'hooks/useOnClickOutside';
import React, {memo, MutableRefObject, RefObject, useRef, useState} from 'react';
import {Modifier, usePopper} from 'react-popper';
import {CSSTransition} from 'react-transition-group';
import {DEFAULT_MODIFIERS} from 'utils/popper';

import styles from './index.scss';

const cn = classnames.bind(styles);

export enum ThemeType {
  DEFAULT = 'default',
  PROMO = 'promo',
  ACCENT = 'accent',
  DARK = 'dark',
  PRIMARY = 'primary',
}

const APPEAR_PROPS = {
  classNames: {
    appear: styles.appear,
    appearActive: styles.appearActive,
  },
  timeout: 400,
  in: true,
  appear: true,
};

const ARROW_PADDING = 7;

const computeRatioModifier: Modifier<'computeRatio'> = {
  name: 'computeRatio',
  enabled: true,
  phase: 'main',
  fn: ({state}) => {
    const step = 20;
    const ratio = (state.modifiersData.arrow?.x || 0) / state.rects.popper.width;
    (state as typeof state & {ratio?: number}).ratio = Math.round((ratio * 100) / step) * step;
  },
  requires: ['arrow'],
};

const getModifiers = (theme: ThemeType) => {
  if (theme === ThemeType.PROMO) {
    return [computeRatioModifier];
  }

  return [];
};

type Props = {
  children: React.ReactNode;
  placement?: 'top' | 'bottom' | 'right' | 'left';
  theme?: ThemeType;
  usePortal?: boolean;
  referenceElement?: HTMLElement | null;
  darkOverlay?: boolean;
  onOutsideClick?: () => void;
  borderRadius?: 'default' | 'large';
  popperRef?: RefObject<ReturnType<typeof usePopper> | null>;
  offset?: [number, number];
  useCloseButton?: boolean;
  customPopperOptions?: Parameters<typeof usePopper>[2];
  customZIndex?: number;
};

export const Tooltip = memo(function Tooltip({
  children,
  placement = 'bottom',
  theme = ThemeType.DEFAULT,
  usePortal = false,
  referenceElement,
  onOutsideClick,
  darkOverlay,
  borderRadius = 'default',
  popperRef,
  useCloseButton,
  offset = [0, 12],
  customZIndex,
  customPopperOptions = {},
}: Props) {
  const [reference, setReference] = useState(referenceElement);
  const anchorRef = useRef<HTMLDivElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);

  useOnClickOutside(tooltipRef, onOutsideClick);

  const popper = usePopper(reference, popperElement, {
    placement,
    ...customPopperOptions,
    modifiers: [
      ...DEFAULT_MODIFIERS,
      {name: 'offset', options: {offset}},
      {name: 'arrow', options: {element: arrowElement, padding: ARROW_PADDING}},
      {name: 'flip', options: {padding: 8, fallbackPlacements: ['right', 'bottom', 'top', 'left']}},
      ...getModifiers(theme),
      ...(customPopperOptions?.modifiers || []),
    ],
  });

  if (popperRef && 'current' in popperRef) {
    // It's a ref, so...
    (popperRef as MutableRefObject<ReturnType<typeof usePopper> | null>).current = popper;
  }

  const {styles: popperStyles, state} = popper as typeof popper & {state: {ratio?: number}};

  useLayoutEffectOnce(() => {
    if (referenceElement) {
      return;
    }
    setReference(anchorRef.current && anchorRef.current.parentElement);
  });

  return (
    <div ref={anchorRef}>
      <ConditionalWrapper
        condition={usePortal}
        // eslint-disable-next-line react/jsx-no-bind
        wrapper={(innerChildren) => <Portal>{innerChildren}</Portal>}
      >
        <>
          {!!onOutsideClick && <div className={cn('overlay', {dark: darkOverlay})} />}
          {!!useCloseButton && <div className={cn('closeButton')} />}
          <div
            ref={setPopperElement}
            style={{...popperStyles.popper, ...(customZIndex ? {zIndex: customZIndex} : {})}}
            className={cn(
              'container',
              state && state.ratio ? `weight-${state.ratio}` : null,
              state && state.placement,
            )}
          >
            <CSSTransition {...APPEAR_PROPS} nodeRef={tooltipRef}>
              <div
                ref={tooltipRef}
                className={cn('tooltip', theme, {radiusLarge: borderRadius === 'large'})}
              >
                <div className={styles.content}>{children}</div>
                <div ref={setArrowElement} style={popperStyles.arrow} className={styles.arrow} />
              </div>
            </CSSTransition>
          </div>
        </>
      </ConditionalWrapper>
    </div>
  );
});
