import cn from 'classnames';
import ChevronRightIcon from 'components/icons/chevronRight.jsx.svg';
import PercentBadgeFilledIcon from 'components/icons/percentBadgeFilled.jsx.svg';
import {Reducer} from 'components/Layout/Reducer';
import {Locator} from 'components/Locator';
import {useSelector} from 'hooks/redux';
import {useSendAnalytics} from 'hooks/useAnalytics';
import {useDeviceVar} from 'hooks/useDeviceVars';
import {usePreviewEvent} from 'hooks/usePreviewEvent';
import {useSignin} from 'hooks/useSignin';
import React, {ReactElement, useCallback, useRef} from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';
import {isUnauthorized} from 'store/modules/auth';

import styles from './SignupPromo.scss';

const DEFAULT_DISCOUNT = 10;

const messages = defineMessages({
  textShort: {
    description: 'текст для баннера со скидкой',
    defaultMessage: 'Grab {value, number}% off your first order',
  },
  textLong: {
    description: 'текст для баннера со скидкой',
    defaultMessage: 'First time here? Get {value, number}% off your first order! Click here',
  },
});

export const SignupPromo = React.memo(function SignupPromo({
  banner,
}: {
  banner?: boolean;
}): ReactElement | null {
  const isAuthorized = !useSelector(isUnauthorized);
  const isEnabled = useDeviceVar('newSignupPromoEnabled');
  const discount = useDeviceVar('signUpCouponPercent') || DEFAULT_DISCOUNT;

  const signin = useSignin();
  const sendEvent = useSendAnalytics();
  const handleClick = useCallback(() => {
    signin.start('Signup promo');
    sendEvent('lineBannerClick', {lineBannerType: 'firstPurchaseDiscount'});
  }, [sendEvent, signin]);

  const rootRef = useRef<HTMLButtonElement>(null);
  usePreviewEvent({
    rootRef,
    previewEvent: {
      type: 'lineBannerPreview',
      payload: {lineBannerType: 'firstPurchaseDiscount'},
    },
  });

  if (!isEnabled || isAuthorized) {
    return null;
  }

  const content = (
    <Locator id="FirstTimeHereOnSignupPromo">
      <button
        ref={rootRef}
        type="button"
        onClick={handleClick}
        className={cn(styles.root, banner && styles.banner)}
      >
        <PercentBadgeFilledIcon className={styles.startIcon} />
        <span className={styles.textMobile}>
          <FormattedMessage {...messages.textShort} values={{value: discount}} />
        </span>
        <span className={styles.textDesktop}>
          <FormattedMessage {...messages.textLong} values={{value: discount}} />
        </span>
        <ChevronRightIcon className={styles.endIcon} />
      </button>
    </Locator>
  );

  return banner ? <Reducer smallFullWidthOnly>{content}</Reducer> : content;
});
